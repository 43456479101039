/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';
export const HOST = "http://localhost:3000/api/typegraphql"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : never, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	DateTime?: ScalarResolver;
	JSON?: ScalarResolver;
}
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["Query"]: AliasType<{
aggregateAdBoard?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateAdBoard"]],
findFirstAdBoard?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
findFirstAdBoardOrThrow?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
adBoard?: [{	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoard"]],
getAdBoard?: [{	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoard"]],
groupByAdBoard?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["AdBoardScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["AdBoardScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroupBy"]],
aggregateAdBoardGroup?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateAdBoardGroup"]],
findFirstAdBoardGroup?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroup"]],
findFirstAdBoardGroupOrThrow?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroup"]],
adBoardGroups?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroup"]],
adBoardGroup?: [{	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardGroup"]],
getAdBoardGroup?: [{	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardGroup"]],
groupByAdBoardGroup?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardGroupOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["AdBoardGroupScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["AdBoardGroupScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroupGroupBy"]],
aggregateAdBoardType?: [{	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardTypeOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardTypeWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateAdBoardType"]],
findFirstAdBoardType?: [{	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardTypeOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardTypeWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardTypeScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardType"]],
findFirstAdBoardTypeOrThrow?: [{	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardTypeOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardTypeWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardTypeScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardType"]],
adBoardTypes?: [{	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardTypeOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardTypeWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardTypeScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardType"]],
adBoardType?: [{	where: ValueTypes["AdBoardTypeWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardType"]],
getAdBoardType?: [{	where: ValueTypes["AdBoardTypeWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardType"]],
groupByAdBoardType?: [{	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardTypeOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["AdBoardTypeScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["AdBoardTypeScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AdBoardTypeGroupBy"]],
aggregateAdvertisementCategory?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateAdvertisementCategory"]],
findFirstAdvertisementCategory?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
findFirstAdvertisementCategoryOrThrow?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
advertisementCategories?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
advertisementCategory?: [{	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
getAdvertisementCategory?: [{	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
groupByAdvertisementCategory?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdvertisementCategoryOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["AdvertisementCategoryScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AdvertisementCategoryGroupBy"]],
aggregateAudit?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuditOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AuditWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateAudit"]],
findFirstAudit?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuditOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AuditWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AuditScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Audit"]],
findFirstAuditOrThrow?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuditOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AuditWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AuditScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Audit"]],
audits?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuditOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AuditWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AuditScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Audit"]],
audit?: [{	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>},ValueTypes["Audit"]],
getAudit?: [{	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>},ValueTypes["Audit"]],
groupByAudit?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuditOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["AuditScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["AuditScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AuditGroupBy"]],
aggregateBooking?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateBooking"]],
findFirstBooking?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
findFirstBookingOrThrow?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
booking?: [{	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>},ValueTypes["Booking"]],
getBooking?: [{	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>},ValueTypes["Booking"]],
groupByBooking?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["BookingScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["BookingScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["BookingGroupBy"]],
aggregateBusinessCategory?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateBusinessCategory"]],
findFirstBusinessCategory?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BusinessCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["BusinessCategory"]],
findFirstBusinessCategoryOrThrow?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BusinessCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["BusinessCategory"]],
businessCategories?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BusinessCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["BusinessCategory"]],
businessCategory?: [{	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>},ValueTypes["BusinessCategory"]],
getBusinessCategory?: [{	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>},ValueTypes["BusinessCategory"]],
groupByBusinessCategory?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCategoryOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["BusinessCategoryScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["BusinessCategoryScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["BusinessCategoryGroupBy"]],
aggregateCamera?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateCamera"]],
findFirstCamera?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Camera"]],
findFirstCameraOrThrow?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Camera"]],
cameras?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Camera"]],
camera?: [{	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>},ValueTypes["Camera"]],
getCamera?: [{	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>},ValueTypes["Camera"]],
groupByCamera?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["CameraScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["CameraScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["CameraGroupBy"]],
aggregateCameraFeed?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraFeedWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateCameraFeed"]],
findFirstCameraFeed?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraFeedWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraFeedScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraFeed"]],
findFirstCameraFeedOrThrow?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraFeedWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraFeedScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraFeed"]],
cameraFeeds?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraFeedWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraFeedScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraFeed"]],
cameraFeed?: [{	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>},ValueTypes["CameraFeed"]],
getCameraFeed?: [{	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>},ValueTypes["CameraFeed"]],
groupByCameraFeed?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraFeedOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["CameraFeedScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["CameraFeedScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["CameraFeedGroupBy"]],
aggregateCameraServer?: [{	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraServerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateCameraServer"]],
findFirstCameraServer?: [{	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraServerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraServerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraServer"]],
findFirstCameraServerOrThrow?: [{	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraServerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraServerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraServer"]],
cameraServers?: [{	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraServerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraServerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraServer"]],
cameraServer?: [{	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>},ValueTypes["CameraServer"]],
getCameraServer?: [{	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>},ValueTypes["CameraServer"]],
groupByCameraServer?: [{	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraServerOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["CameraServerScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["CameraServerScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["CameraServerGroupBy"]],
aggregateCampaign?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateCampaign"]],
findFirstCampaign?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CampaignScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
findFirstCampaignOrThrow?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CampaignScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CampaignScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
campaign?: [{	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>},ValueTypes["Campaign"]],
getCampaign?: [{	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>},ValueTypes["Campaign"]],
groupByCampaign?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["CampaignScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["CampaignScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["CampaignGroupBy"]],
aggregateFile?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateFile"]],
findFirstFile?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
findFirstFileOrThrow?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
files?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
file?: [{	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>},ValueTypes["File"]],
getFile?: [{	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>},ValueTypes["File"]],
groupByFile?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["FileScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["FileScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["FileGroupBy"]],
aggregateMembership?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MembershipOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["MembershipWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateMembership"]],
findFirstMembership?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MembershipOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["MembershipWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["MembershipScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Membership"]],
findFirstMembershipOrThrow?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MembershipOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["MembershipWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["MembershipScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Membership"]],
memberships?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MembershipOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["MembershipWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["MembershipScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Membership"]],
membership?: [{	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>},ValueTypes["Membership"]],
getMembership?: [{	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>},ValueTypes["Membership"]],
groupByMembership?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MembershipOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["MembershipScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["MembershipScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["MembershipGroupBy"]],
aggregateNotification?: [{	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["NotificationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["NotificationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateNotification"]],
findFirstNotification?: [{	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["NotificationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["NotificationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["NotificationScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Notification"]],
findFirstNotificationOrThrow?: [{	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["NotificationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["NotificationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["NotificationScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Notification"]],
notifications?: [{	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["NotificationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["NotificationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["NotificationScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Notification"]],
notification?: [{	where: ValueTypes["NotificationWhereUniqueInput"] | Variable<any, string>},ValueTypes["Notification"]],
getNotification?: [{	where: ValueTypes["NotificationWhereUniqueInput"] | Variable<any, string>},ValueTypes["Notification"]],
groupByNotification?: [{	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["NotificationOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["NotificationScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["NotificationScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["NotificationGroupBy"]],
aggregateOrder?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["OrderWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateOrder"]],
findFirstOrder?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["OrderWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["OrderScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Order"]],
findFirstOrderOrThrow?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["OrderWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["OrderScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Order"]],
orders?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["OrderWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["OrderScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Order"]],
order?: [{	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>},ValueTypes["Order"]],
getOrder?: [{	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>},ValueTypes["Order"]],
groupByOrder?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["OrderScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["OrderScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["OrderGroupBy"]],
aggregatePlayer?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregatePlayer"]],
findFirstPlayer?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Player"]],
findFirstPlayerOrThrow?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Player"]],
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Player"]],
player?: [{	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>},ValueTypes["Player"]],
getPlayer?: [{	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>},ValueTypes["Player"]],
groupByPlayer?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["PlayerScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["PlayerScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["PlayerGroupBy"]],
aggregatePlaylist?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregatePlaylist"]],
findFirstPlaylist?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlaylistScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
findFirstPlaylistOrThrow?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlaylistScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
playlists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlaylistScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
playlist?: [{	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>},ValueTypes["Playlist"]],
getPlaylist?: [{	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>},ValueTypes["Playlist"]],
groupByPlaylist?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["PlaylistScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["PlaylistScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["PlaylistGroupBy"]],
aggregateSlotConfiguration?: [{	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SlotConfigurationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateSlotConfiguration"]],
findFirstSlotConfiguration?: [{	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SlotConfigurationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["SlotConfigurationScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["SlotConfiguration"]],
findFirstSlotConfigurationOrThrow?: [{	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SlotConfigurationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["SlotConfigurationScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["SlotConfiguration"]],
slotConfigurations?: [{	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SlotConfigurationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["SlotConfigurationScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["SlotConfiguration"]],
slotConfiguration?: [{	where: ValueTypes["SlotConfigurationWhereUniqueInput"] | Variable<any, string>},ValueTypes["SlotConfiguration"]],
getSlotConfiguration?: [{	where: ValueTypes["SlotConfigurationWhereUniqueInput"] | Variable<any, string>},ValueTypes["SlotConfiguration"]],
groupBySlotConfiguration?: [{	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SlotConfigurationOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["SlotConfigurationScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["SlotConfigurationScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["SlotConfigurationGroupBy"]],
aggregateSubscription?: [{	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscriptionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SubscriptionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateSubscription"]],
findFirstSubscription?: [{	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscriptionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SubscriptionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["SubscriptionScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Subscription"]],
findFirstSubscriptionOrThrow?: [{	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscriptionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SubscriptionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["SubscriptionScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Subscription"]],
subscriptions?: [{	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscriptionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SubscriptionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["SubscriptionScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Subscription"]],
subscription?: [{	where: ValueTypes["SubscriptionWhereUniqueInput"] | Variable<any, string>},ValueTypes["Subscription"]],
getSubscription?: [{	where: ValueTypes["SubscriptionWhereUniqueInput"] | Variable<any, string>},ValueTypes["Subscription"]],
groupBySubscription?: [{	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SubscriptionOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["SubscriptionScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["SubscriptionScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["SubscriptionGroupBy"]],
aggregateUser?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateUser"]],
findFirstUser?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["User"]],
findFirstUserOrThrow?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["User"]],
users?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["User"]],
user?: [{	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>},ValueTypes["User"]],
getUser?: [{	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>},ValueTypes["User"]],
groupByUser?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["UserScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["UserScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["UserGroupBy"]],
aggregateUserPosition?: [{	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserPositionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserPositionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateUserPosition"]],
findFirstUserPosition?: [{	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserPositionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserPositionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserPositionScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["UserPosition"]],
findFirstUserPositionOrThrow?: [{	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserPositionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserPositionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserPositionScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["UserPosition"]],
userPositions?: [{	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserPositionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserPositionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserPositionScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["UserPosition"]],
userPosition?: [{	where: ValueTypes["UserPositionWhereUniqueInput"] | Variable<any, string>},ValueTypes["UserPosition"]],
getUserPosition?: [{	where: ValueTypes["UserPositionWhereUniqueInput"] | Variable<any, string>},ValueTypes["UserPosition"]],
groupByUserPosition?: [{	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserPositionOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["UserPositionScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["UserPositionScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["UserPositionGroupBy"]],
aggregateWorkspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateWorkspace"]],
findFirstWorkspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
findFirstWorkspaceOrThrow?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
workspaces?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
workspace?: [{	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>},ValueTypes["Workspace"]],
getWorkspace?: [{	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>},ValueTypes["Workspace"]],
groupByWorkspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["WorkspaceScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["WorkspaceScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["WorkspaceGroupBy"]],
aggregateWorkspaceRole?: [{	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["AggregateWorkspaceRole"]],
findFirstWorkspaceRole?: [{	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceRoleScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["WorkspaceRole"]],
findFirstWorkspaceRoleOrThrow?: [{	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceRoleScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["WorkspaceRole"]],
workspaceRoles?: [{	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceRoleScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["WorkspaceRole"]],
workspaceRole?: [{	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>},ValueTypes["WorkspaceRole"]],
getWorkspaceRole?: [{	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>},ValueTypes["WorkspaceRole"]],
groupByWorkspaceRole?: [{	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceRoleOrderByWithAggregationInput"]> | undefined | null | Variable<any, string>,	by: Array<ValueTypes["WorkspaceRoleScalarFieldEnum"]> | Variable<any, string>,	having?: ValueTypes["WorkspaceRoleScalarWhereWithAggregatesInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>},ValueTypes["WorkspaceRoleGroupBy"]],
getAdBoardAvailability?: [{	month: number | Variable<any, string>,	year: number | Variable<any, string>,	adBoardId: string | Variable<any, string>},ValueTypes["Slot"]],
		__typename?: boolean | `@${string}`
}>;
	["AggregateAdBoard"]: AliasType<{
	_count?:ValueTypes["AdBoardCountAggregate"],
	_avg?:ValueTypes["AdBoardAvgAggregate"],
	_sum?:ValueTypes["AdBoardSumAggregate"],
	_min?:ValueTypes["AdBoardMinAggregate"],
	_max?:ValueTypes["AdBoardMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adBoardTypeId?:boolean | `@${string}`,
	tags?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	galleryImgs?:boolean | `@${string}`,
	notes?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	categoryId?:boolean | `@${string}`,
	restrictedBusinessIds?:boolean | `@${string}`,
	targetAudiences?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	playerIds?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	additionalData?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardAvgAggregate"]: AliasType<{
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardSumAggregate"]: AliasType<{
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adBoardTypeId?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	categoryId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardStatus"]:AdBoardStatus;
	["AdboardDraftStatus"]:AdboardDraftStatus;
	/** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
["DateTime"]:unknown;
	["AdBoardMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adBoardTypeId?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	categoryId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCompositeFilter"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	galleryImgs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	notes?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	targetAudiences?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["EnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	playerIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	publishedVersionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeNullableRelationFilter"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderListRelationFilter"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationListRelationFilter"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupNullableRelationFilter"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraListRelationFilter"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardNullableRelationFilter"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardNullableRelationFilter"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryListRelationFilter"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentListRelationFilter"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewListRelationFilter"] | undefined | null | Variable<any, string>
};
	["StringFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	mode?: ValueTypes["QueryMode"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedStringFilter"] | undefined | null | Variable<any, string>
};
	["QueryMode"]:QueryMode;
	["NestedStringFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedStringFilter"] | undefined | null | Variable<any, string>
};
	["MapCompositeFilter"]: {
	equals?: ValueTypes["MapObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["MapWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["MapWhereInput"] | undefined | null | Variable<any, string>
};
	["MapObjectEqualityInput"]: {
	lat: number | Variable<any, string>,
	lng: number | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>
};
	["MapWhereInput"]: {
	AND?: Array<ValueTypes["MapWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MapWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["MapWhereInput"]> | undefined | null | Variable<any, string>,
	lat?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	lng?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["FloatFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedFloatFilter"] | undefined | null | Variable<any, string>
};
	["NestedFloatFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedFloatFilter"] | undefined | null | Variable<any, string>
};
	["StringNullableFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	mode?: ValueTypes["QueryMode"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedStringNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedStringNullableFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedStringNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["BoolFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedBoolFilter"] | undefined | null | Variable<any, string>
};
	["NestedBoolFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedBoolFilter"] | undefined | null | Variable<any, string>
};
	["IntFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>
};
	["NestedIntFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>
};
	["IntNullableFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedIntNullableFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["SpecificationObjectEqualityInput"]: {
	name: string | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>
};
	["StringNullableListFilter"]: {
	equals?: Array<string> | undefined | null | Variable<any, string>,
	has?: string | undefined | null | Variable<any, string>,
	hasEvery?: Array<string> | undefined | null | Variable<any, string>,
	hasSome?: Array<string> | undefined | null | Variable<any, string>,
	isEmpty?: boolean | undefined | null | Variable<any, string>
};
	["FloatNullableFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedFloatNullableFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["ResolutionNullableCompositeFilter"]: {
	equals?: ValueTypes["ResolutionObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["ResolutionWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["ResolutionWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["ResolutionObjectEqualityInput"]: {
	height: number | Variable<any, string>,
	width: number | Variable<any, string>
};
	["ResolutionWhereInput"]: {
	AND?: Array<ValueTypes["ResolutionWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ResolutionWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["ResolutionWhereInput"]> | undefined | null | Variable<any, string>,
	height?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>
};
	["ScreenSizeNullableCompositeFilter"]: {
	equals?: ValueTypes["ScreenSizeObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["ScreenSizeWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["ScreenSizeWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["ScreenSizeObjectEqualityInput"]: {
	height: number | Variable<any, string>,
	unit: ValueTypes["MeasurementUnit"] | Variable<any, string>,
	width: number | Variable<any, string>
};
	["MeasurementUnit"]:MeasurementUnit;
	["ScreenSizeWhereInput"]: {
	AND?: Array<ValueTypes["ScreenSizeWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ScreenSizeWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["ScreenSizeWhereInput"]> | undefined | null | Variable<any, string>,
	height?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	unit?: ValueTypes["EnumMeasurementUnitFilter"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>
};
	["EnumMeasurementUnitFilter"]: {
	equals?: ValueTypes["MeasurementUnit"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["MeasurementUnit"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["MeasurementUnit"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumMeasurementUnitFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumMeasurementUnitFilter"]: {
	equals?: ValueTypes["MeasurementUnit"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["MeasurementUnit"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["MeasurementUnit"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumMeasurementUnitFilter"] | undefined | null | Variable<any, string>
};
	["PlaybackTimeObjectEqualityInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	startTime: string | Variable<any, string>,
	endTime: string | Variable<any, string>
};
	["EnumAdBoardStatusFilter"]: {
	equals?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdBoardStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdBoardStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumAdBoardStatusFilter"]: {
	equals?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdBoardStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdBoardStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>
};
	["EnumAdboardDraftStatusNullableFilter"]: {
	equals?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdboardDraftStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdboardDraftStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedEnumAdboardDraftStatusNullableFilter"]: {
	equals?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdboardDraftStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdboardDraftStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["WebhookObjectEqualityInput"]: {
	url: string | Variable<any, string>,
	headers?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	body?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	method: string | Variable<any, string>
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["DateTimeNullableFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedDateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedDateTimeNullableFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedDateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["JsonNullableFilter"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["LocationCategoryNullableRelationFilter"]: {
	is?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryWhereInput"]: {
	AND?: Array<ValueTypes["LocationCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["LocationCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["LocationCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryListRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardListRelationFilter"]: {
	every?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryListRelationFilter"]: {
	every?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryWhereInput"]: {
	AND?: Array<ValueTypes["LocationSubCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["LocationSubCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["LocationSubCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryNullableRelationFilter"]: {
	is?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeNullableRelationFilter"]: {
	is?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardTypeWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardTypeWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardTypeWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryNullableRelationFilter"]: {
	is?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryWhereInput"]: {
	AND?: Array<ValueTypes["BusinessCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BusinessCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BusinessCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	restrictedByAdBoardIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>
};
	["CampaignListRelationFilter"]: {
	every?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>
};
	["CampaignWhereInput"]: {
	AND?: Array<ValueTypes["CampaignWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CampaignWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CampaignWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["EnumPaymentStatusFilter"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCompositeFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryRelationFilter"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentListRelationFilter"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>
};
	["DateTimeFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedDateTimeFilter"] | undefined | null | Variable<any, string>
};
	["NestedDateTimeFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedDateTimeFilter"] | undefined | null | Variable<any, string>
};
	["BookingPaymentNullableCompositeFilter"]: {
	equals?: ValueTypes["BookingPaymentObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["BookingPaymentWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BookingPaymentWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["BookingPaymentObjectEqualityInput"]: {
	liveMode: boolean | Variable<any, string>,
	checkoutSessionId?: string | undefined | null | Variable<any, string>,
	customer?: ValueTypes["BookingCustomerObjectEqualityInput"] | undefined | null | Variable<any, string>,
	paymentGateway: ValueTypes["PaymentGatewayName"] | Variable<any, string>,
	paymentId: string | Variable<any, string>,
	sessionId?: string | undefined | null | Variable<any, string>,
	totalDetails?: ValueTypes["PaymentTotalDetailsObjectEqualityInput"] | undefined | null | Variable<any, string>,
	razorpay?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["BookingCustomerObjectEqualityInput"]: {
	customerId: string | Variable<any, string>,
	customerDetails?: ValueTypes["PaymentCustomerDetailsObjectEqualityInput"] | undefined | null | Variable<any, string>
};
	["PaymentCustomerDetailsObjectEqualityInput"]: {
	email: string | Variable<any, string>,
	name: string | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>
};
	["PaymentGatewayName"]:PaymentGatewayName;
	["PaymentTotalDetailsObjectEqualityInput"]: {
	discount: number | Variable<any, string>,
	shipping: number | Variable<any, string>,
	subtotal: number | Variable<any, string>,
	tax: number | Variable<any, string>,
	total: number | Variable<any, string>
};
	["BookingPaymentWhereInput"]: {
	AND?: Array<ValueTypes["BookingPaymentWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingPaymentWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingPaymentWhereInput"]> | undefined | null | Variable<any, string>,
	liveMode?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	checkoutSessionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	customer?: ValueTypes["BookingCustomerNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	paymentGateway?: ValueTypes["EnumPaymentGatewayNameFilter"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	sessionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	totalDetails?: ValueTypes["PaymentTotalDetailsNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	razorpay?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>
};
	["BookingCustomerNullableCompositeFilter"]: {
	equals?: ValueTypes["BookingCustomerObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["BookingCustomerWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BookingCustomerWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["BookingCustomerWhereInput"]: {
	AND?: Array<ValueTypes["BookingCustomerWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingCustomerWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingCustomerWhereInput"]> | undefined | null | Variable<any, string>,
	customerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	customerDetails?: ValueTypes["PaymentCustomerDetailsNullableCompositeFilter"] | undefined | null | Variable<any, string>
};
	["PaymentCustomerDetailsNullableCompositeFilter"]: {
	equals?: ValueTypes["PaymentCustomerDetailsObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["PaymentCustomerDetailsWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["PaymentCustomerDetailsWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["PaymentCustomerDetailsWhereInput"]: {
	AND?: Array<ValueTypes["PaymentCustomerDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PaymentCustomerDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PaymentCustomerDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["EnumPaymentGatewayNameFilter"]: {
	equals?: ValueTypes["PaymentGatewayName"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentGatewayName"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentGatewayName"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentGatewayNameFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumPaymentGatewayNameFilter"]: {
	equals?: ValueTypes["PaymentGatewayName"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentGatewayName"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentGatewayName"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentGatewayNameFilter"] | undefined | null | Variable<any, string>
};
	["PaymentTotalDetailsNullableCompositeFilter"]: {
	equals?: ValueTypes["PaymentTotalDetailsObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["PaymentTotalDetailsWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["PaymentTotalDetailsWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["PaymentTotalDetailsWhereInput"]: {
	AND?: Array<ValueTypes["PaymentTotalDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PaymentTotalDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PaymentTotalDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	discount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	shipping?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	subtotal?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	tax?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	total?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>
};
	["EnumPaymentStatusFilter"]: {
	equals?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentStatusFilter"] | undefined | null | Variable<any, string>
};
	["PaymentStatus"]:PaymentStatus;
	["NestedEnumPaymentStatusFilter"]: {
	equals?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentStatusFilter"] | undefined | null | Variable<any, string>
};
	["PriceSummaryNullableCompositeFilter"]: {
	equals?: ValueTypes["PriceSummaryObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["PriceSummaryWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["PriceSummaryWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["PriceSummaryObjectEqualityInput"]: {
	days: number | Variable<any, string>,
	discount?: number | undefined | null | Variable<any, string>,
	payableRent: number | Variable<any, string>,
	rentPerDay: number | Variable<any, string>,
	serviceCharge: number | Variable<any, string>,
	totalRent: number | Variable<any, string>
};
	["PriceSummaryWhereInput"]: {
	AND?: Array<ValueTypes["PriceSummaryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PriceSummaryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PriceSummaryWhereInput"]> | undefined | null | Variable<any, string>,
	days?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	payableRent?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	rentPerDay?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	serviceCharge?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	totalRent?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>
};
	["OrderSelectedDateCompositeFilter"]: {
	equals?: ValueTypes["OrderSelectedDateObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["OrderSelectedDateWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["OrderSelectedDateWhereInput"] | undefined | null | Variable<any, string>
};
	["OrderSelectedDateObjectEqualityInput"]: {
	endDate: ValueTypes["DateTime"] | Variable<any, string>,
	startDate: ValueTypes["DateTime"] | Variable<any, string>
};
	["OrderSelectedDateWhereInput"]: {
	AND?: Array<ValueTypes["OrderSelectedDateWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["OrderSelectedDateWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["OrderSelectedDateWhereInput"]> | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["UserRelationFilter"]: {
	is?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserWhereInput"]: {
	AND?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	uid?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["EnumRoleNullableListFilter"] | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	sharedBookingIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	leadWorkspaceIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderListRelationFilter"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditListRelationFilter"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignListRelationFilter"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipListRelationFilter"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationListRelationFilter"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryListRelationFilter"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentListRelationFilter"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceListRelationFilter"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceListRelationFilter"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewListRelationFilter"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionListRelationFilter"] | undefined | null | Variable<any, string>
};
	["UserStripeNullableCompositeFilter"]: {
	equals?: ValueTypes["UserStripeObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["UserStripeWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["UserStripeWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["UserStripeObjectEqualityInput"]: {
	customerId: string | Variable<any, string>
};
	["UserStripeWhereInput"]: {
	AND?: Array<ValueTypes["UserStripeWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserStripeWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserStripeWhereInput"]> | undefined | null | Variable<any, string>,
	customerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["EnumRoleNullableListFilter"]: {
	equals?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	has?: ValueTypes["Role"] | undefined | null | Variable<any, string>,
	hasEvery?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	hasSome?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	isEmpty?: boolean | undefined | null | Variable<any, string>
};
	["Role"]:Role;
	["BookingRefundObjectEqualityInput"]: {
	id: string | Variable<any, string>,
	razorpay?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdByUserId: string | Variable<any, string>
};
	["NotificationPreferenceNullableCompositeFilter"]: {
	equals?: ValueTypes["NotificationPreferenceObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["NotificationPreferenceWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["NotificationPreferenceWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NotificationPreferenceObjectEqualityInput"]: {
	type?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>,
	pushSetting?: ValueTypes["PushSettingObjectEqualityInput"] | undefined | null | Variable<any, string>,
	enabled: boolean | Variable<any, string>
};
	["NotificationType"]:NotificationType;
	["NotificationChannel"]:NotificationChannel;
	["PushSettingObjectEqualityInput"]: {
	fcmToken: string | Variable<any, string>
};
	["NotificationPreferenceWhereInput"]: {
	AND?: Array<ValueTypes["NotificationPreferenceWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["NotificationPreferenceWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["NotificationPreferenceWhereInput"]> | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumNotificationTypeNullableListFilter"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["EnumNotificationChannelNullableListFilter"] | undefined | null | Variable<any, string>,
	pushSetting?: ValueTypes["PushSettingNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	enabled?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>
};
	["EnumNotificationTypeNullableListFilter"]: {
	equals?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	has?: ValueTypes["NotificationType"] | undefined | null | Variable<any, string>,
	hasEvery?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	hasSome?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	isEmpty?: boolean | undefined | null | Variable<any, string>
};
	["EnumNotificationChannelNullableListFilter"]: {
	equals?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>,
	has?: ValueTypes["NotificationChannel"] | undefined | null | Variable<any, string>,
	hasEvery?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>,
	hasSome?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>,
	isEmpty?: boolean | undefined | null | Variable<any, string>
};
	["PushSettingNullableCompositeFilter"]: {
	equals?: ValueTypes["PushSettingObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["PushSettingWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["PushSettingWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["PushSettingWhereInput"]: {
	AND?: Array<ValueTypes["PushSettingWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PushSettingWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PushSettingWhereInput"]> | undefined | null | Variable<any, string>,
	fcmToken?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["BookingListRelationFilter"]: {
	every?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>
};
	["BookingWhereInput"]: {
	AND?: Array<ValueTypes["BookingWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingCode?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCompositeFilter"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["EnumBookingStatusFilter"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["EnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["EnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["EnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCompositeFilter"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	sharedUserIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryListRelationFilter"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogListRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignNullableRelationFilter"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutListRelationFilter"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserListRelationFilter"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogListRelationFilter"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageListRelationFilter"] | undefined | null | Variable<any, string>
};
	["BookingDetailsCompositeFilter"]: {
	equals?: ValueTypes["BookingDetailsObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["BookingDetailsWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BookingDetailsWhereInput"] | undefined | null | Variable<any, string>
};
	["BookingDetailsObjectEqualityInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	name: string | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>
};
	["BookingDetailsWhereInput"]: {
	AND?: Array<ValueTypes["BookingDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["EnumBookingStatusFilter"]: {
	equals?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["BookingStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["BookingStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumBookingStatusFilter"] | undefined | null | Variable<any, string>
};
	["BookingStatus"]:BookingStatus;
	["NestedEnumBookingStatusFilter"]: {
	equals?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["BookingStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["BookingStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumBookingStatusFilter"] | undefined | null | Variable<any, string>
};
	["EnumPaymentStatusNullableFilter"]: {
	equals?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedEnumPaymentStatusNullableFilter"]: {
	equals?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["EnumDeploymentStatusFilter"]: {
	equals?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DeploymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DeploymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>
};
	["DeploymentStatus"]:DeploymentStatus;
	["NestedEnumDeploymentStatusFilter"]: {
	equals?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DeploymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DeploymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>
};
	["EnumContentApprovalStatusFilter"]: {
	equals?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentApprovalStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentApprovalStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>
};
	["ContentApprovalStatus"]:ContentApprovalStatus;
	["NestedEnumContentApprovalStatusFilter"]: {
	equals?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentApprovalStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentApprovalStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>
};
	["BookingPisignageDataNullableCompositeFilter"]: {
	equals?: ValueTypes["BookingPisignageDataObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["BookingPisignageDataWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BookingPisignageDataWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["BookingPisignageDataObjectEqualityInput"]: {
	deployedFileName?: string | undefined | null | Variable<any, string>,
	playlistId?: string | undefined | null | Variable<any, string>
};
	["BookingPisignageDataWhereInput"]: {
	AND?: Array<ValueTypes["BookingPisignageDataWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingPisignageDataWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingPisignageDataWhereInput"]> | undefined | null | Variable<any, string>,
	deployedFileName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	playlistId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["BookingRefundNullableCompositeFilter"]: {
	equals?: ValueTypes["BookingRefundObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["BookingRefundWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BookingRefundWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["BookingRefundWhereInput"]: {
	AND?: Array<ValueTypes["BookingRefundWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingRefundWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingRefundWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	razorpay?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["FileListRelationFilter"]: {
	every?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>
};
	["FileWhereInput"]: {
	AND?: Array<ValueTypes["FileWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["FileWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["FileWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCompositeFilter"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingNullableRelationFilter"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignListRelationFilter"] | undefined | null | Variable<any, string>
};
	["FileMetaCompositeFilter"]: {
	equals?: ValueTypes["FileMetaObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["FileMetaWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["FileMetaWhereInput"] | undefined | null | Variable<any, string>
};
	["FileMetaObjectEqualityInput"]: {
	duration: number | Variable<any, string>,
	height: number | Variable<any, string>,
	width: number | Variable<any, string>
};
	["FileMetaWhereInput"]: {
	AND?: Array<ValueTypes["FileMetaWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["FileMetaWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["FileMetaWhereInput"]> | undefined | null | Variable<any, string>,
	duration?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>
};
	["EnumFileStatusNullableFilter"]: {
	equals?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["FileStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["FileStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["FileStatus"]:FileStatus;
	["NestedEnumFileStatusNullableFilter"]: {
	equals?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["FileStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["FileStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["UserNullableRelationFilter"]: {
	is?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceNullableRelationFilter"]: {
	is?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceWhereInput"]: {
	AND?: Array<ValueTypes["WorkspaceWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WorkspaceWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WorkspaceWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	leadIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupListRelationFilter"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionListRelationFilter"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionNullableRelationFilter"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerListRelationFilter"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleListRelationFilter"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogListRelationFilter"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserListRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumWorkspaceTypeFilter"]: {
	equals?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["WorkspaceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["WorkspaceType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>
};
	["WorkspaceType"]:WorkspaceType;
	["NestedEnumWorkspaceTypeFilter"]: {
	equals?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["WorkspaceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["WorkspaceType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>
};
	["MembershipListRelationFilter"]: {
	every?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>
};
	["MembershipWhereInput"]: {
	AND?: Array<ValueTypes["MembershipWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MembershipWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["MembershipWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["WorkspaceRoleRelationFilter"] | undefined | null | Variable<any, string>
};
	["WorkspaceRelationFilter"]: {
	is?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleRelationFilter"]: {
	is?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleWhereInput"]: {
	AND?: Array<ValueTypes["WorkspaceRoleWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WorkspaceRoleWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WorkspaceRoleWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	permissions?: ValueTypes["EnumPermissionNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipListRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumPermissionNullableListFilter"]: {
	equals?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	has?: ValueTypes["Permission"] | undefined | null | Variable<any, string>,
	hasEvery?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	hasSome?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	isEmpty?: boolean | undefined | null | Variable<any, string>
};
	["Permission"]:Permission;
	["PlaylistListRelationFilter"]: {
	every?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>
};
	["PlaylistWhereInput"]: {
	AND?: Array<ValueTypes["PlaylistWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlaylistWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlaylistWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["EnumScreenLayoutFilter"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistNullableRelationFilter"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistNullableRelationFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupListRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistListRelationFilter"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistListRelationFilter"] | undefined | null | Variable<any, string>
};
	["PisignageFileObjectEqualityInput"]: {
	name: string | Variable<any, string>,
	duration?: number | undefined | null | Variable<any, string>,
	muteAudio?: boolean | undefined | null | Variable<any, string>,
	isVideo?: boolean | undefined | null | Variable<any, string>
};
	["BoolNullableFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedBoolNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedBoolNullableFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedBoolNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["EnumScreenLayoutFilter"]: {
	equals?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ScreenLayout"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ScreenLayout"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumScreenLayoutFilter"] | undefined | null | Variable<any, string>
};
	["ScreenLayout"]:ScreenLayout;
	["NestedEnumScreenLayoutFilter"]: {
	equals?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ScreenLayout"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ScreenLayout"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumScreenLayoutFilter"] | undefined | null | Variable<any, string>
};
	["PlaylistNullableRelationFilter"]: {
	is?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupListRelationFilter"]: {
	every?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardGroupWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardGroupWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardGroupWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["EnumFitTypeFilter"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["EnumFitTypeFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardNullableRelationFilter"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistListRelationFilter"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistNullableRelationFilter"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>
};
	["ScheduleObjectEqualityInput"]: {
	playlistId: string | Variable<any, string>,
	playlistType?: string | undefined | null | Variable<any, string>,
	skipForSchedule?: boolean | undefined | null | Variable<any, string>,
	startTime?: string | undefined | null | Variable<any, string>,
	endTime?: string | undefined | null | Variable<any, string>
};
	["EnumFitTypeFilter"]: {
	equals?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["FitType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["FitType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumFitTypeFilter"] | undefined | null | Variable<any, string>
};
	["FitType"]:FitType;
	["NestedEnumFitTypeFilter"]: {
	equals?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["FitType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["FitType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumFitTypeFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardNullableRelationFilter"]: {
	is?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["PlayerListRelationFilter"]: {
	every?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>
};
	["PlayerWhereInput"]: {
	AND?: Array<ValueTypes["PlayerWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlayerWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlayerWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	playlistIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	audienceTypeId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["EnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	adBoardIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupNullableRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusListRelationFilter"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraListRelationFilter"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageNullableRelationFilter"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumAspectRatioTypeFilter"]: {
	equals?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AspectRatioType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AspectRatioType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>
};
	["AspectRatioType"]:AspectRatioType;
	["NestedEnumAspectRatioTypeFilter"]: {
	equals?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AspectRatioType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AspectRatioType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>
};
	["GalleryFileObjectEqualityInput"]: {
	name: string | Variable<any, string>,
	url: string | Variable<any, string>,
	contentType: string | Variable<any, string>,
	width: number | Variable<any, string>,
	height: number | Variable<any, string>,
	duration?: number | undefined | null | Variable<any, string>
};
	["MapNullableCompositeFilter"]: {
	equals?: ValueTypes["MapObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["MapWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["MapWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["AdBoardGroupNullableRelationFilter"]: {
	is?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusListRelationFilter"]: {
	every?: ValueTypes["PlayerUptimeStatusWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["PlayerUptimeStatusWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["PlayerUptimeStatusWhereInput"] | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusWhereInput"]: {
	AND?: Array<ValueTypes["PlayerUptimeStatusWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlayerUptimeStatusWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlayerUptimeStatusWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	uptime?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerRelationFilter"] | undefined | null | Variable<any, string>
};
	["PlayerRelationFilter"]: {
	is?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>
};
	["CameraListRelationFilter"]: {
	every?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>
};
	["CameraWhereInput"]: {
	AND?: Array<ValueTypes["CameraWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cameraUrl?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerRelationFilter"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerNullableRelationFilter"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["CameraServerRelationFilter"]: {
	is?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>
};
	["CameraServerWhereInput"]: {
	AND?: Array<ValueTypes["CameraServerWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraServerWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraServerWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	deviceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraListRelationFilter"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedListRelationFilter"] | undefined | null | Variable<any, string>
};
	["CameraFeedListRelationFilter"]: {
	every?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>
};
	["CameraFeedWhereInput"]: {
	AND?: Array<ValueTypes["CameraFeedWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraFeedWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraFeedWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	data?: ValueTypes["CameraFeedDataCompositeFilter"] | undefined | null | Variable<any, string>,
	camera?: ValueTypes["CameraRelationFilter"] | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerRelationFilter"] | undefined | null | Variable<any, string>
};
	["CameraFeedDataCompositeFilter"]: {
	equals?: ValueTypes["CameraFeedDataObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["CameraFeedDataWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["CameraFeedDataWhereInput"] | undefined | null | Variable<any, string>
};
	["CameraFeedDataObjectEqualityInput"]: {
	person?: number | undefined | null | Variable<any, string>,
	car?: number | undefined | null | Variable<any, string>,
	truck?: number | undefined | null | Variable<any, string>,
	motorcycle?: number | undefined | null | Variable<any, string>
};
	["CameraFeedDataWhereInput"]: {
	AND?: Array<ValueTypes["CameraFeedDataWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraFeedDataWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraFeedDataWhereInput"]> | undefined | null | Variable<any, string>,
	person?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	car?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	truck?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	motorcycle?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>
};
	["CameraRelationFilter"]: {
	is?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>
};
	["PlayerNullableRelationFilter"]: {
	is?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>
};
	["DigitalSignageNullableRelationFilter"]: {
	is?: ValueTypes["DigitalSignageWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["DigitalSignageWhereInput"] | undefined | null | Variable<any, string>
};
	["DigitalSignageWhereInput"]: {
	AND?: Array<ValueTypes["DigitalSignageWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["DigitalSignageWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["DigitalSignageWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumSignageTypeFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumSignageTypeFilter"]: {
	equals?: ValueTypes["SignageType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["SignageType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["SignageType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumSignageTypeFilter"] | undefined | null | Variable<any, string>
};
	["SignageType"]:SignageType;
	["NestedEnumSignageTypeFilter"]: {
	equals?: ValueTypes["SignageType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["SignageType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["SignageType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumSignageTypeFilter"] | undefined | null | Variable<any, string>
};
	["AudienceListRelationFilter"]: {
	every?: ValueTypes["AudienceWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["AudienceWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["AudienceWhereInput"] | undefined | null | Variable<any, string>
};
	["AudienceWhereInput"]: {
	AND?: Array<ValueTypes["AudienceWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AudienceWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AudienceWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumAudienceTypeFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	playerIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumAudienceTypeFilter"]: {
	equals?: ValueTypes["AudienceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AudienceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AudienceType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAudienceTypeFilter"] | undefined | null | Variable<any, string>
};
	["AudienceType"]:AudienceType;
	["NestedEnumAudienceTypeFilter"]: {
	equals?: ValueTypes["AudienceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AudienceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AudienceType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAudienceTypeFilter"] | undefined | null | Variable<any, string>
};
	["AuditListRelationFilter"]: {
	every?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>
};
	["AuditWhereInput"]: {
	AND?: Array<ValueTypes["AuditWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AuditWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AuditWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	entity?: ValueTypes["EntityNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["EntityNullableCompositeFilter"]: {
	equals?: ValueTypes["EntityObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["EntityWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["EntityWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["EntityObjectEqualityInput"]: {
	type: string | Variable<any, string>,
	id: string | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>
};
	["EntityWhereInput"]: {
	AND?: Array<ValueTypes["EntityWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EntityWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["EntityWhereInput"]> | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["TransactionListRelationFilter"]: {
	every?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>
};
	["TransactionWhereInput"]: {
	AND?: Array<ValueTypes["TransactionWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["TransactionWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["TransactionWhereInput"]> | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	amount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	createAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	relatedEntityId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	transactionStatus?: ValueTypes["EnumTransactionStatusFilter"] | undefined | null | Variable<any, string>,
	details?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutListRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumTransactionStatusFilter"]: {
	equals?: ValueTypes["TransactionStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["TransactionStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["TransactionStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumTransactionStatusFilter"] | undefined | null | Variable<any, string>
};
	["TransactionStatus"]:TransactionStatus;
	["NestedEnumTransactionStatusFilter"]: {
	equals?: ValueTypes["TransactionStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["TransactionStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["TransactionStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumTransactionStatusFilter"] | undefined | null | Variable<any, string>
};
	["PayoutListRelationFilter"]: {
	every?: ValueTypes["PayoutWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["PayoutWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["PayoutWhereInput"] | undefined | null | Variable<any, string>
};
	["PayoutWhereInput"]: {
	AND?: Array<ValueTypes["PayoutWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PayoutWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PayoutWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	totalAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	commissionRate?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	commissionAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	netAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumPayoutStatusFilter"] | undefined | null | Variable<any, string>,
	transactionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingRelationFilter"] | undefined | null | Variable<any, string>,
	transaction?: ValueTypes["TransactionNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumPayoutStatusFilter"]: {
	equals?: ValueTypes["PayoutStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PayoutStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PayoutStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPayoutStatusFilter"] | undefined | null | Variable<any, string>
};
	["PayoutStatus"]:PayoutStatus;
	["NestedEnumPayoutStatusFilter"]: {
	equals?: ValueTypes["PayoutStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PayoutStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PayoutStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPayoutStatusFilter"] | undefined | null | Variable<any, string>
};
	["BookingRelationFilter"]: {
	is?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>
};
	["TransactionNullableRelationFilter"]: {
	is?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionNullableRelationFilter"]: {
	is?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionWhereInput"]: {
	AND?: Array<ValueTypes["SubscriptionWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SubscriptionWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SubscriptionWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	packageId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	packageName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	priceLookupKey?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	activeWorkspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	activeWorkspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>
};
	["CancellationDetailsNullableCompositeFilter"]: {
	equals?: ValueTypes["CancellationDetailsObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["CancellationDetailsWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["CancellationDetailsWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["CancellationDetailsObjectEqualityInput"]: {
	canceledAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	cancelAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	cancelAtPeriodEnd: boolean | Variable<any, string>,
	reason?: string | undefined | null | Variable<any, string>
};
	["CancellationDetailsWhereInput"]: {
	AND?: Array<ValueTypes["CancellationDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CancellationDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CancellationDetailsWhereInput"]> | undefined | null | Variable<any, string>,
	canceledAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	cancelAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	cancelAtPeriodEnd?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	reason?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["CameraServerListRelationFilter"]: {
	every?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleListRelationFilter"]: {
	every?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>
};
	["WebhookLogListRelationFilter"]: {
	every?: ValueTypes["WebhookLogWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["WebhookLogWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["WebhookLogWhereInput"] | undefined | null | Variable<any, string>
};
	["WebhookLogWhereInput"]: {
	AND?: Array<ValueTypes["WebhookLogWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WebhookLogWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WebhookLogWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	response?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["UserListRelationFilter"]: {
	every?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["BookingNullableRelationFilter"]: {
	is?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryListRelationFilter"]: {
	every?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryWhereInput"]: {
	AND?: Array<ValueTypes["AdvertisementCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdvertisementCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdvertisementCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumAdvertisementCategoryEnumFilter"]: {
	equals?: ValueTypes["AdvertisementCategoryEnum"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdvertisementCategoryEnum"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdvertisementCategoryEnum"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryEnum"]:AdvertisementCategoryEnum;
	["NestedEnumAdvertisementCategoryEnumFilter"]: {
	equals?: ValueTypes["AdvertisementCategoryEnum"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdvertisementCategoryEnum"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdvertisementCategoryEnum"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardRelationFilter"]: {
	is?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["ChangelogListRelationFilter"]: {
	every?: ValueTypes["ChangelogWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["ChangelogWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["ChangelogWhereInput"] | undefined | null | Variable<any, string>
};
	["ChangelogWhereInput"]: {
	AND?: Array<ValueTypes["ChangelogWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ChangelogWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["ChangelogWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingNullableRelationFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["CampaignNullableRelationFilter"]: {
	is?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>
};
	["SlotUsageListRelationFilter"]: {
	every?: ValueTypes["SlotUsageWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["SlotUsageWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["SlotUsageWhereInput"] | undefined | null | Variable<any, string>
};
	["SlotUsageWhereInput"]: {
	AND?: Array<ValueTypes["SlotUsageWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SlotUsageWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SlotUsageWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	slotUsed?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingRelationFilter"] | undefined | null | Variable<any, string>
};
	["OrderListRelationFilter"]: {
	every?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>
};
	["OrderWhereInput"]: {
	AND?: Array<ValueTypes["OrderWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["OrderWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["OrderWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCompositeFilter"] | undefined | null | Variable<any, string>,
	selectedDate?: ValueTypes["OrderSelectedDateCompositeFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["PriceSummaryCompositeFilter"]: {
	equals?: ValueTypes["PriceSummaryObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["PriceSummaryWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["PriceSummaryWhereInput"] | undefined | null | Variable<any, string>
};
	["NotificationListRelationFilter"]: {
	every?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>
};
	["NotificationWhereInput"]: {
	AND?: Array<ValueTypes["NotificationWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["NotificationWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["NotificationWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumNotificationTypeFilter"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["EnumNotificationChannelNullableListFilter"] | undefined | null | Variable<any, string>,
	recipient?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumNotificationTypeFilter"]: {
	equals?: ValueTypes["NotificationType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumNotificationTypeFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumNotificationTypeFilter"]: {
	equals?: ValueTypes["NotificationType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumNotificationTypeFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryListRelationFilter"]: {
	every?: ValueTypes["AdBoardPublishHistoryWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["AdBoardPublishHistoryWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["AdBoardPublishHistoryWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	publishedByUserId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	changesSnapshot?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	publishedBy?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["JsonFilter"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentListRelationFilter"]: {
	every?: ValueTypes["AdBoardReviewCommentWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["AdBoardReviewCommentWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["AdBoardReviewCommentWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardReviewCommentWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardReviewCommentWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardReviewCommentWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	comment?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["EnumReviewActionFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["EnumReviewActionFilter"]: {
	equals?: ValueTypes["ReviewAction"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ReviewAction"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ReviewAction"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumReviewActionFilter"] | undefined | null | Variable<any, string>
};
	["ReviewAction"]:ReviewAction;
	["NestedEnumReviewActionFilter"]: {
	equals?: ValueTypes["ReviewAction"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ReviewAction"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ReviewAction"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumReviewActionFilter"] | undefined | null | Variable<any, string>
};
	["WorkspaceListRelationFilter"]: {
	every?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewListRelationFilter"]: {
	every?: ValueTypes["AdBoardPublicReviewWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["AdBoardPublicReviewWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["AdBoardPublicReviewWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardPublicReviewWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardPublicReviewWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardPublicReviewWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	rating?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	comment?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["UserPositionListRelationFilter"]: {
	every?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>
};
	["UserPositionWhereInput"]: {
	AND?: Array<ValueTypes["UserPositionWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserPositionWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserPositionWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	coords?: ValueTypes["UserPositionCoordsCompositeFilter"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["UserPositionCoordsCompositeFilter"]: {
	equals?: ValueTypes["UserPositionCoordsObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["UserPositionCoordsWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["UserPositionCoordsWhereInput"] | undefined | null | Variable<any, string>
};
	["UserPositionCoordsObjectEqualityInput"]: {
	accuracy?: number | undefined | null | Variable<any, string>,
	altitude?: number | undefined | null | Variable<any, string>,
	altitudeAccuracy?: number | undefined | null | Variable<any, string>,
	heading?: number | undefined | null | Variable<any, string>,
	latitude: number | Variable<any, string>,
	longitude: number | Variable<any, string>,
	speed?: number | undefined | null | Variable<any, string>
};
	["UserPositionCoordsWhereInput"]: {
	AND?: Array<ValueTypes["UserPositionCoordsWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserPositionCoordsWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserPositionCoordsWhereInput"]> | undefined | null | Variable<any, string>,
	accuracy?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	altitude?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	altitudeAccuracy?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	heading?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	latitude?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	longitude?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	speed?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>
};
	["UserPositionLocationNullableCompositeFilter"]: {
	equals?: ValueTypes["UserPositionLocationObjectEqualityInput"] | undefined | null | Variable<any, string>,
	is?: ValueTypes["UserPositionLocationWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["UserPositionLocationWhereInput"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["UserPositionLocationObjectEqualityInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>
};
	["UserPositionLocationWhereInput"]: {
	AND?: Array<ValueTypes["UserPositionLocationWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserPositionLocationWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserPositionLocationWhereInput"]> | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryRelationFilter"]: {
	is?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	isNot?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["PaymentListRelationFilter"]: {
	every?: ValueTypes["PaymentWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["PaymentWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["PaymentWhereInput"] | undefined | null | Variable<any, string>
};
	["PaymentWhereInput"]: {
	AND?: Array<ValueTypes["PaymentWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PaymentWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PaymentWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	paymentIntentId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	checkoutSessionId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	eventId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	liveMode?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	paymentGateway?: ValueTypes["EnumPaymentGatewayNameFilter"] | undefined | null | Variable<any, string>,
	subtotal?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	total?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	tax?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryListRelationFilter"]: {
	every?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationListRelationFilter"]: {
	every?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationWhereInput"]: {
	AND?: Array<ValueTypes["SlotConfigurationWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SlotConfigurationWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SlotConfigurationWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapOrderByInput"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	aboutSpecifications?: ValueTypes["SpecificationOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	galleryImgs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	specifications?: ValueTypes["SpecificationOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	notes?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionOrderByInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeOrderByInput"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	targetAudiences?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playbackTimes?: ValueTypes["PlaybackTimeOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	webhooks?: ValueTypes["WebhookOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publishedVersionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["SortOrder"]:SortOrder;
	["MapOrderByInput"]: {
	lat?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	lng?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SpecificationOrderByCompositeAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["ResolutionOrderByInput"]: {
	height?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["ScreenSizeOrderByInput"]: {
	height?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	unit?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlaybackTimeOrderByCompositeAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WebhookOrderByCompositeAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["LocationCategoryOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["AdBoardOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	restrictedByAdBoardIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["CampaignOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["OrderOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	uid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeOrderByInput"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	refunds?: ValueTypes["BookingRefundOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	sharedBookingIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceOrderByInput"] | undefined | null | Variable<any, string>,
	leadWorkspaceIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["UserStripeOrderByInput"]: {
	customerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingRefundOrderByCompositeAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["NotificationPreferenceOrderByInput"]: {
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pushSetting?: ValueTypes["PushSettingOrderByInput"] | undefined | null | Variable<any, string>,
	enabled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PushSettingOrderByInput"]: {
	fcmToken?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FileOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["ChangelogOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AuditOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["MembershipOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["NotificationOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserPositionOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	leadIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["PlaylistOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlayerOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["TransactionOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SubscriptionOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	priceLookupKey?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsOrderByInput"] | undefined | null | Variable<any, string>,
	activeWorkspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	activeWorkspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["CancellationDetailsOrderByInput"]: {
	canceledAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cancelAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cancelAtPeriodEnd?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	reason?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraServerOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WebhookLogOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	schedules?: ValueTypes["ScheduleOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["ScheduleOrderByCompositeAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlaylistOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["PisignageFileOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroupIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["PisignageFileOrderByCompositeAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	workspaceId_alias?: ValueTypes["AdBoardWorkspaceIdAliasCompoundUniqueInput"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AdBoardWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardWhereInput"]> | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCompositeFilter"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	galleryImgs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	notes?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	targetAudiences?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["EnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	playerIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeNullableRelationFilter"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderListRelationFilter"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationListRelationFilter"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupNullableRelationFilter"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraListRelationFilter"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardNullableRelationFilter"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardNullableRelationFilter"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryListRelationFilter"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentListRelationFilter"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewListRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardWorkspaceIdAliasCompoundUniqueInput"]: {
	workspaceId: string | Variable<any, string>,
	alias: string | Variable<any, string>
};
	["AdBoard"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	map?:ValueTypes["Map"],
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	aboutSpecifications?:ValueTypes["Specification"],
	adBoardTypeId?:boolean | `@${string}`,
	tags?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	galleryImgs?:boolean | `@${string}`,
	/** @dperecated Use `targetAudiences` instead. */
	specifications?:ValueTypes["Specification"],
	notes?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	resolution?:ValueTypes["Resolution"],
	screenSize?:ValueTypes["ScreenSize"],
	categoryId?:boolean | `@${string}`,
	restrictedBusinessIds?:boolean | `@${string}`,
	targetAudiences?:boolean | `@${string}`,
	playbackTimes?:ValueTypes["PlaybackTime"],
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	webhooks?:ValueTypes["Webhook"],
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	playerIds?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	additionalData?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ValueTypes["AdBoardCount"],
locationCategory?: [{	where?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["LocationCategory"]],
locationSubCategory?: [{	where?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["LocationSubCategory"]],
type?: [{	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AdBoardType"]],
category?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCategory"]],
restrictedBusines?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BusinessCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["BusinessCategory"]],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
orders?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["OrderWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["OrderScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Order"]],
slotConfigurations?: [{	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SlotConfigurationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["SlotConfigurationScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["SlotConfiguration"]],
offlineBookingsContact?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
createdBy?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	workspace?:ValueTypes["Workspace"],
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Player"]],
adBoardGroup?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroup"]],
cameras?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Camera"]],
publishedVersion?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
draftAdboard?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
history?: [{	where?: ValueTypes["AdBoardPublishHistoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardPublishHistoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardPublishHistoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardPublishHistory"]],
reviewComments?: [{	where?: ValueTypes["AdBoardReviewCommentWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardReviewCommentOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardReviewCommentWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardReviewCommentScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardReviewComment"]],
archivedBy?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
publicReviews?: [{	where?: ValueTypes["AdBoardPublicReviewWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardPublicReviewOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardPublicReviewWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardPublicReviewScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardPublicReview"]],
		__typename?: boolean | `@${string}`
}>;
	["Map"]: AliasType<{
	lat?:boolean | `@${string}`,
	lng?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Specification"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Resolution"]: AliasType<{
	height?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ScreenSize"]: AliasType<{
	height?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaybackTime"]: AliasType<{
	name?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Webhook"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:boolean | `@${string}`,
	body?:boolean | `@${string}`,
	method?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardCount"]: AliasType<{
restrictedBusines?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
orders?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
slotConfigurations?: [{	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
cameras?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
history?: [{	where?: ValueTypes["AdBoardPublishHistoryWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
reviewComments?: [{	where?: ValueTypes["AdBoardReviewCommentWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
publicReviews?: [{	where?: ValueTypes["AdBoardPublicReviewWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["LocationCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	_count?:ValueTypes["LocationCategoryCount"],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
locationSubCategory?: [{	where?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocationSubCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["LocationSubCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["LocationSubCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["LocationSubCategory"]],
		__typename?: boolean | `@${string}`
}>;
	["LocationCategoryCount"]: AliasType<{
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
locationSubCategory?: [{	where?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardScalarFieldEnum"]:AdBoardScalarFieldEnum;
	["LocationSubCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	_count?:ValueTypes["LocationSubCategoryCount"],
locationCategory?: [{	where?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["LocationCategory"]],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
		__typename?: boolean | `@${string}`
}>;
	["LocationSubCategoryCount"]: AliasType<{
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["LocationSubCategoryWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["LocationSubCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["LocationSubCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["LocationSubCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryScalarFieldEnum"]:LocationSubCategoryScalarFieldEnum;
	["AdBoardType"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	_count?:ValueTypes["AdBoardTypeCount"],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeCount"]: AliasType<{
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	restrictedByAdBoardIds?:boolean | `@${string}`,
	_count?:ValueTypes["BusinessCategoryCount"],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
restrictedByAdBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CampaignScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryCount"]: AliasType<{
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
restrictedByAdBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Campaign"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	payment?:ValueTypes["BookingPayment"],
	paymentStatus?:boolean | `@${string}`,
	priceSummary?:ValueTypes["PriceSummary"],
	selectedDates?:ValueTypes["OrderSelectedDate"],
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	_count?:ValueTypes["CampaignCount"],
	user?:ValueTypes["User"],
	businessCategory?:ValueTypes["BusinessCategory"],
adCategory?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
payments?: [{	where?: ValueTypes["PaymentWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PaymentOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PaymentWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PaymentScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Payment"]],
assets?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
		__typename?: boolean | `@${string}`
}>;
	["BookingPayment"]: AliasType<{
	liveMode?:boolean | `@${string}`,
	checkoutSessionId?:boolean | `@${string}`,
	customer?:ValueTypes["BookingCustomer"],
	paymentGateway?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
	sessionId?:boolean | `@${string}`,
	totalDetails?:ValueTypes["PaymentTotalDetails"],
	razorpay?:boolean | `@${string}`,
	stripe?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingCustomer"]: AliasType<{
	customerId?:boolean | `@${string}`,
	customerDetails?:ValueTypes["PaymentCustomerDetails"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentCustomerDetails"]: AliasType<{
	email?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PaymentTotalDetails"]: AliasType<{
	discount?:boolean | `@${string}`,
	shipping?:boolean | `@${string}`,
	subtotal?:boolean | `@${string}`,
	tax?:boolean | `@${string}`,
	total?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PriceSummary"]: AliasType<{
	days?:boolean | `@${string}`,
	discount?:boolean | `@${string}`,
	payableRent?:boolean | `@${string}`,
	rentPerDay?:boolean | `@${string}`,
	serviceCharge?:boolean | `@${string}`,
	totalRent?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderSelectedDate"]: AliasType<{
	endDate?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignCount"]: AliasType<{
adCategory?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
payments?: [{	where?: ValueTypes["PaymentWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
assets?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["User"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	otherDetails?:boolean | `@${string}`,
	stripe?:ValueTypes["UserStripe"],
	roles?:boolean | `@${string}`,
	refunds?:ValueTypes["BookingRefund"],
	sharedBookingIds?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
	notificationPreference?:ValueTypes["NotificationPreference"],
	leadWorkspaceIds?:boolean | `@${string}`,
	_count?:ValueTypes["UserCount"],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
createdFiles?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
updatedFiles?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
orders?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["OrderWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["OrderScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Order"]],
changelogs?: [{	where?: ValueTypes["ChangelogWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ChangelogOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["ChangelogWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["ChangelogScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Changelog"]],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
audits?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuditOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AuditWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AuditScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Audit"]],
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CampaignScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
sharedBookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
memberships?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MembershipOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["MembershipWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["MembershipScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Membership"]],
notifications?: [{	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["NotificationOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["NotificationWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["NotificationScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Notification"]],
publishAdboardVersion?: [{	where?: ValueTypes["AdBoardPublishHistoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardPublishHistoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardPublishHistoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardPublishHistory"]],
offlineBookingAdboards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
reviewComments?: [{	where?: ValueTypes["AdBoardReviewCommentWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardReviewCommentOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardReviewCommentWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardReviewCommentScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardReviewComment"]],
archivedAdboards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
ownedWorkspaces?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
leadWorspaces?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
publicReviews?: [{	where?: ValueTypes["AdBoardPublicReviewWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardPublicReviewOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardPublicReviewWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardPublicReviewScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardPublicReview"]],
userPositions?: [{	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserPositionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserPositionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserPositionScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["UserPosition"]],
		__typename?: boolean | `@${string}`
}>;
	["UserStripe"]: AliasType<{
	customerId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingRefund"]: AliasType<{
	id?:boolean | `@${string}`,
	razorpay?:boolean | `@${string}`,
	stripe?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationPreference"]: AliasType<{
	type?:boolean | `@${string}`,
	channels?:boolean | `@${string}`,
	pushSetting?:ValueTypes["PushSetting"],
	enabled?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PushSetting"]: AliasType<{
	fcmToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserCount"]: AliasType<{
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
createdFiles?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
updatedFiles?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
orders?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
changelogs?: [{	where?: ValueTypes["ChangelogWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
audits?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
sharedBookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
memberships?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
notifications?: [{	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
publishAdboardVersion?: [{	where?: ValueTypes["AdBoardPublishHistoryWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
offlineBookingAdboards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
reviewComments?: [{	where?: ValueTypes["AdBoardReviewCommentWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
archivedAdboards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
ownedWorkspaces?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
leadWorspaces?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
publicReviews?: [{	where?: ValueTypes["AdBoardPublicReviewWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
userPositions?: [{	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Booking"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	bookingDetails?:ValueTypes["BookingDetails"],
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	selectedDates?:ValueTypes["OrderSelectedDate"],
	payment?:ValueTypes["BookingPayment"],
	priceSummary?:ValueTypes["PriceSummary"],
	pisignage?:ValueTypes["BookingPisignageData"],
	userId?:boolean | `@${string}`,
	refund?:ValueTypes["BookingRefund"],
	sharedUserIds?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
	_count?:ValueTypes["BookingCount"],
assets?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
businessCategory?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["BusinessCategory"]],
adCategory?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
proofs?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
	user?:ValueTypes["User"],
	adBoard?:ValueTypes["AdBoard"],
changelogs?: [{	where?: ValueTypes["ChangelogWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ChangelogOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["ChangelogWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["ChangelogScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Changelog"]],
workspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
campaign?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
payouts?: [{	where?: ValueTypes["PayoutWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PayoutOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PayoutWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PayoutScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Payout"]],
sharedUsers?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["User"]],
webhookLogs?: [{	where?: ValueTypes["WebhookLogWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WebhookLogOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WebhookLogWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WebhookLogScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["WebhookLog"]],
slotUsage?: [{	where?: ValueTypes["SlotUsageWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SlotUsageOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["SlotUsageWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["SlotUsageScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["SlotUsage"]],
		__typename?: boolean | `@${string}`
}>;
	["BookingDetails"]: AliasType<{
	additionalInformation?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingPisignageData"]: AliasType<{
	deployedFileName?:boolean | `@${string}`,
	playlistId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingCount"]: AliasType<{
assets?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
adCategory?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
proofs?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
changelogs?: [{	where?: ValueTypes["ChangelogWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
payouts?: [{	where?: ValueTypes["PayoutWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
sharedUsers?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
webhookLogs?: [{	where?: ValueTypes["WebhookLogWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
slotUsage?: [{	where?: ValueTypes["SlotUsageWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["File"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	meta?:ValueTypes["FileMeta"],
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	bookingsIDs?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	_count?:ValueTypes["FileCount"],
updatedBy?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	createdBy?:ValueTypes["User"],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
workspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
proofBooking?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CampaignScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
		__typename?: boolean | `@${string}`
}>;
	["FileMeta"]: AliasType<{
	duration?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileCount"]: AliasType<{
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["BookingOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingCode?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsOrderByInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateOrderByInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentOrderByInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryOrderByInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataOrderByInput"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundOrderByInput"] | undefined | null | Variable<any, string>,
	sharedUserIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["BookingDetailsOrderByInput"]: {
	additionalInformation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["OrderSelectedDateOrderByInput"]: {
	endDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingPaymentOrderByInput"]: {
	liveMode?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	checkoutSessionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customer?: ValueTypes["BookingCustomerOrderByInput"] | undefined | null | Variable<any, string>,
	paymentGateway?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sessionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	totalDetails?: ValueTypes["PaymentTotalDetailsOrderByInput"] | undefined | null | Variable<any, string>,
	razorpay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingCustomerOrderByInput"]: {
	customerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customerDetails?: ValueTypes["PaymentCustomerDetailsOrderByInput"] | undefined | null | Variable<any, string>
};
	["PaymentCustomerDetailsOrderByInput"]: {
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PaymentTotalDetailsOrderByInput"]: {
	discount?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	shipping?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	subtotal?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	tax?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	total?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PriceSummaryOrderByInput"]: {
	days?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	payableRent?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	rentPerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	serviceCharge?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	totalRent?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingPisignageDataOrderByInput"]: {
	deployedFileName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playlistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingRefundOrderByInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	razorpay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CampaignOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentOrderByInput"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryOrderByInput"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateOrderByInput"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["PaymentOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PayoutOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SlotUsageOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BookingWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingWhereInput"]> | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCompositeFilter"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["EnumBookingStatusFilter"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["EnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["EnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["EnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCompositeFilter"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	sharedUserIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryNullableRelationFilter"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryListRelationFilter"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogListRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignNullableRelationFilter"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutListRelationFilter"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserListRelationFilter"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogListRelationFilter"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageListRelationFilter"] | undefined | null | Variable<any, string>
};
	["BookingScalarFieldEnum"]:BookingScalarFieldEnum;
	["Workspace"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	userIds?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	leadIds?:boolean | `@${string}`,
	_count?:ValueTypes["WorkspaceCount"],
owner?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
memberships?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MembershipOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["MembershipWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["MembershipScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Membership"]],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
playlists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlaylistScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
adBoardGroups?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroup"]],
audits?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AuditOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AuditWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AuditScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Audit"]],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
files?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["FileWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["FileScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["File"]],
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Player"]],
transactions?: [{	where?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TransactionOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["TransactionWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["TransactionScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Transaction"]],
activeSubscription?: [{	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Subscription"]],
cameraServers?: [{	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraServerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraServerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraServer"]],
workspaceRoles?: [{	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WorkspaceRoleScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["WorkspaceRole"]],
webhookLogs?: [{	where?: ValueTypes["WebhookLogWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["WebhookLogOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["WebhookLogWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["WebhookLogScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["WebhookLog"]],
leads?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["UserOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["UserScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["User"]],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceCount"]: AliasType<{
memberships?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
playlists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
adBoardGroups?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
audits?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
files?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
transactions?: [{	where?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
cameraServers?: [{	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
workspaceRoles?: [{	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
webhookLogs?: [{	where?: ValueTypes["WebhookLogWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
leads?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Membership"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
	user?:ValueTypes["User"],
	workspace?:ValueTypes["Workspace"],
	role?:ValueTypes["WorkspaceRole"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRole"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ValueTypes["WorkspaceRoleCount"],
workspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
memberships?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["MembershipOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["MembershipWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["MembershipScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Membership"]],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleCount"]: AliasType<{
memberships?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["MembershipOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["WorkspaceRoleOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	permissions?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["MembershipWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	userId_workspaceId?: ValueTypes["MembershipUserIdWorkspaceIdCompoundUniqueInput"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["MembershipWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MembershipWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["MembershipWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["WorkspaceRoleRelationFilter"] | undefined | null | Variable<any, string>
};
	["MembershipUserIdWorkspaceIdCompoundUniqueInput"]: {
	userId: string | Variable<any, string>,
	workspaceId: string | Variable<any, string>
};
	["MembershipScalarFieldEnum"]:MembershipScalarFieldEnum;
	["Playlist"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	assets?:ValueTypes["PisignageFile"],
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	adBoardGroupIds?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_count?:ValueTypes["PlaylistCount"],
footerPlaylist?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
sidebarPlaylist?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
adBoardGroupsForAds?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroup"]],
adBoardGroups?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroup"]],
	workspace?:ValueTypes["Workspace"],
footerParentPlaylists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlaylistScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
sidebarParentPlaylists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlaylistScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
		__typename?: boolean | `@${string}`
}>;
	["PisignageFile"]: AliasType<{
	name?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	muteAudio?:boolean | `@${string}`,
	isVideo?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaylistCount"]: AliasType<{
adBoardGroupsForAds?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
adBoardGroups?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
footerParentPlaylists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
sidebarParentPlaylists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroup"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	schedules?:ValueTypes["Schedule"],
	scheduledPlaylistsIDs?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_count?:ValueTypes["AdBoardGroupCount"],
adBoard?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
scheduledPlaylists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlaylistOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlaylistScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
adsPlaylist?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Playlist"]],
pisignagePlayers?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Player"]],
	workspace?:ValueTypes["Workspace"],
		__typename?: boolean | `@${string}`
}>;
	["Schedule"]: AliasType<{
	playlistId?:boolean | `@${string}`,
	playlistType?:boolean | `@${string}`,
	skipForSchedule?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupCount"]: AliasType<{
scheduledPlaylists?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
pisignagePlayers?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["PlaylistWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PlaylistWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlaylistWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlaylistWhereInput"]> | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["EnumScreenLayoutFilter"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistNullableRelationFilter"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistNullableRelationFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupListRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistListRelationFilter"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistListRelationFilter"] | undefined | null | Variable<any, string>
};
	["PlaylistScalarFieldEnum"]:PlaylistScalarFieldEnum;
	["Player"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	playlistIds?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	resolution?:ValueTypes["Resolution"],
	screenSize?:ValueTypes["ScreenSize"],
	signageTypeId?:boolean | `@${string}`,
	audienceTypeId?:boolean | `@${string}`,
	playbackDetails?:ValueTypes["PlaybackTime"],
	aspectRatioType?:boolean | `@${string}`,
	galleryImgs?:ValueTypes["GalleryFile"],
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	map?:ValueTypes["Map"],
	isOnline?:boolean | `@${string}`,
	adBoardIds?:boolean | `@${string}`,
	_count?:ValueTypes["PlayerCount"],
adBoardGroup?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AdBoardGroup"]],
workspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
status?: [{	where?: ValueTypes["PlayerUptimeStatusWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerUptimeStatusOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerUptimeStatusWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerUptimeStatusScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["PlayerUptimeStatus"]],
cameras?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Camera"]],
signageType?: [{	where?: ValueTypes["DigitalSignageWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["DigitalSignage"]],
audienceType?: [{	where?: ValueTypes["AudienceWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AudienceOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AudienceWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AudienceScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Audience"]],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AdBoardOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["AdBoardScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryFile"]: AliasType<{
	name?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerCount"]: AliasType<{
status?: [{	where?: ValueTypes["PlayerUptimeStatusWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
cameras?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
audienceType?: [{	where?: ValueTypes["AudienceWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
adBoards?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["PlayerUptimeStatus"]: AliasType<{
	id?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	uptime?:boolean | `@${string}`,
	player?:ValueTypes["Player"],
		__typename?: boolean | `@${string}`
}>;
	["PlayerUptimeStatusOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	uptime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["PlayerOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playlistIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionOrderByInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeOrderByInput"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	audienceTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playbackDetails?: ValueTypes["PlaybackTimeOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	galleryImgs?: ValueTypes["GalleryFileOrderByCompositeAggregateInput"] | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapOrderByInput"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["GalleryFileOrderByCompositeAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["DigitalSignageOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["AudienceOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber_date?: ValueTypes["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PlayerUptimeStatusWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlayerUptimeStatusWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlayerUptimeStatusWhereInput"]> | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	uptime?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerRelationFilter"] | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"]: {
	cpuSerialNumber: string | Variable<any, string>,
	date: string | Variable<any, string>
};
	["PlayerUptimeStatusScalarFieldEnum"]:PlayerUptimeStatusScalarFieldEnum;
	["Camera"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	connectedCpuSerialNumbers?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	_count?:ValueTypes["CameraCount"],
	cameraServer?:ValueTypes["CameraServer"],
player?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Player"]],
feeds?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraFeedWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraFeedScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraFeed"]],
adBoard?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AdBoard"]],
		__typename?: boolean | `@${string}`
}>;
	["CameraCount"]: AliasType<{
feeds?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["CameraServer"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
	_count?:ValueTypes["CameraServerCount"],
workspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
cameras?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Camera"]],
feeds?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CameraFeedWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CameraFeedScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["CameraFeed"]],
		__typename?: boolean | `@${string}`
}>;
	["CameraServerCount"]: AliasType<{
cameras?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
feeds?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["CameraOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["CameraServerOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deviceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["CameraFeedOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	cameraUrl?: string | undefined | null | Variable<any, string>,
	cameraIp_cameraServerId?: ValueTypes["CameraCameraIpCameraServerIdCompoundUniqueInput"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CameraWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraWhereInput"]> | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerRelationFilter"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerNullableRelationFilter"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["CameraCameraIpCameraServerIdCompoundUniqueInput"]: {
	cameraIp: string | Variable<any, string>,
	cameraServerId: string | Variable<any, string>
};
	["CameraScalarFieldEnum"]:CameraScalarFieldEnum;
	["CameraFeed"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	data?:ValueTypes["CameraFeedData"],
	camera?:ValueTypes["Camera"],
	cameraServer?:ValueTypes["CameraServer"],
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedData"]: AliasType<{
	person?:boolean | `@${string}`,
	car?:boolean | `@${string}`,
	truck?:boolean | `@${string}`,
	motorcycle?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	data?: ValueTypes["CameraFeedDataOrderByInput"] | undefined | null | Variable<any, string>,
	camera?: ValueTypes["CameraOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["CameraFeedDataOrderByInput"]: {
	person?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	car?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	truck?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	motorcycle?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraFeedWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	cameraId_timestamp?: ValueTypes["CameraFeedCameraIdTimestampCompoundUniqueInput"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CameraFeedWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraFeedWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraFeedWhereInput"]> | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	data?: ValueTypes["CameraFeedDataCompositeFilter"] | undefined | null | Variable<any, string>,
	camera?: ValueTypes["CameraRelationFilter"] | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerRelationFilter"] | undefined | null | Variable<any, string>
};
	["CameraFeedCameraIdTimestampCompoundUniqueInput"]: {
	cameraId: string | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>
};
	["CameraFeedScalarFieldEnum"]:CameraFeedScalarFieldEnum;
	["DigitalSignage"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ValueTypes["DigitalSignageCount"],
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Player"]],
		__typename?: boolean | `@${string}`
}>;
	["DigitalSignageCount"]: AliasType<{
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["PlayerWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PlayerWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlayerWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlayerWhereInput"]> | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	playlistIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	audienceTypeId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["EnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	adBoardIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupNullableRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusListRelationFilter"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraListRelationFilter"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageNullableRelationFilter"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>
};
	["PlayerScalarFieldEnum"]:PlayerScalarFieldEnum;
	["Audience"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	playerIDs?:boolean | `@${string}`,
	_count?:ValueTypes["AudienceCount"],
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PlayerOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PlayerScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Player"]],
		__typename?: boolean | `@${string}`
}>;
	["AudienceCount"]: AliasType<{
players?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["AudienceOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["AudienceWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AudienceWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AudienceWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AudienceWhereInput"]> | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumAudienceTypeFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	playerIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>
};
	["AudienceScalarFieldEnum"]:AudienceScalarFieldEnum;
	["AdBoardGroupWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	adBoardId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AdBoardGroupWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardGroupWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardGroupWhereInput"]> | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["EnumFitTypeFilter"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["EnumFitTypeFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardNullableRelationFilter"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistListRelationFilter"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistNullableRelationFilter"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupScalarFieldEnum"]:AdBoardGroupScalarFieldEnum;
	["Audit"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	entity?:ValueTypes["Entity"],
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	updates?:boolean | `@${string}`,
	user?:ValueTypes["User"],
workspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
		__typename?: boolean | `@${string}`
}>;
	["Entity"]: AliasType<{
	type?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuditOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	entity?: ValueTypes["EntityOrderByInput"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["EntityOrderByInput"]: {
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AuditWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AuditWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AuditWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AuditWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	entity?: ValueTypes["EntityNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["AuditScalarFieldEnum"]:AuditScalarFieldEnum;
	["FileOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaOrderByInput"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["FileMetaOrderByInput"]: {
	duration?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FileWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["FileWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["FileWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["FileWhereInput"]> | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCompositeFilter"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingNullableRelationFilter"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignListRelationFilter"] | undefined | null | Variable<any, string>
};
	["FileScalarFieldEnum"]:FileScalarFieldEnum;
	["Transaction"]: AliasType<{
	workspaceId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	createAt?:boolean | `@${string}`,
	processedAt?:boolean | `@${string}`,
	relatedEntityId?:boolean | `@${string}`,
	transactionStatus?:boolean | `@${string}`,
	details?:boolean | `@${string}`,
	_count?:ValueTypes["TransactionCount"],
workspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
payouts?: [{	where?: ValueTypes["PayoutWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["PayoutOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["PayoutWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["PayoutScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Payout"]],
		__typename?: boolean | `@${string}`
}>;
	["TransactionCount"]: AliasType<{
payouts?: [{	where?: ValueTypes["PayoutWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Payout"]: AliasType<{
	id?:boolean | `@${string}`,
	totalAmount?:boolean | `@${string}`,
	commissionRate?:boolean | `@${string}`,
	commissionAmount?:boolean | `@${string}`,
	netAmount?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	bookingId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	transactionId?:boolean | `@${string}`,
	booking?:ValueTypes["Booking"],
transaction?: [{	where?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Transaction"]],
		__typename?: boolean | `@${string}`
}>;
	["PayoutOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	totalAmount?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	commissionRate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	commissionAmount?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	netAmount?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	transactionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	transaction?: ValueTypes["TransactionOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["TransactionOrderByWithRelationInput"]: {
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	amount?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	relatedEntityId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	transactionStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	details?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["PayoutWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PayoutWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PayoutWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PayoutWhereInput"]> | undefined | null | Variable<any, string>,
	totalAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	commissionRate?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	commissionAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	netAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumPayoutStatusFilter"] | undefined | null | Variable<any, string>,
	transactionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingRelationFilter"] | undefined | null | Variable<any, string>,
	transaction?: ValueTypes["TransactionNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["PayoutScalarFieldEnum"]:PayoutScalarFieldEnum;
	["TransactionWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["TransactionWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["TransactionWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["TransactionWhereInput"]> | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	amount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	createAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	relatedEntityId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	transactionStatus?: ValueTypes["EnumTransactionStatusFilter"] | undefined | null | Variable<any, string>,
	details?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutListRelationFilter"] | undefined | null | Variable<any, string>
};
	["TransactionScalarFieldEnum"]:TransactionScalarFieldEnum;
	["Subscription"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	cancellationDetails?:ValueTypes["CancellationDetails"],
	activeWorkspaceId?:boolean | `@${string}`,
	activeWorkspace?:ValueTypes["Workspace"],
		__typename?: boolean | `@${string}`
}>;
	["CancellationDetails"]: AliasType<{
	canceledAt?:boolean | `@${string}`,
	cancelAt?:boolean | `@${string}`,
	cancelAtPeriodEnd?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraServerWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	deviceId?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CameraServerWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraServerWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraServerWhereInput"]> | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraListRelationFilter"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedListRelationFilter"] | undefined | null | Variable<any, string>
};
	["CameraServerScalarFieldEnum"]:CameraServerScalarFieldEnum;
	["WorkspaceRoleWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	name_workspaceId?: ValueTypes["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["WorkspaceRoleWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WorkspaceRoleWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WorkspaceRoleWhereInput"]> | undefined | null | Variable<any, string>,
	permissions?: ValueTypes["EnumPermissionNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipListRelationFilter"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"]: {
	name: string | Variable<any, string>,
	workspaceId: string | Variable<any, string>
};
	["WorkspaceRoleScalarFieldEnum"]:WorkspaceRoleScalarFieldEnum;
	["WebhookLog"]: AliasType<{
	id?:boolean | `@${string}`,
	bookingId?:boolean | `@${string}`,
	response?:boolean | `@${string}`,
	request?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	booking?:ValueTypes["Booking"],
workspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Workspace"]],
		__typename?: boolean | `@${string}`
}>;
	["WebhookLogOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	response?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["WebhookLogWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["WebhookLogWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WebhookLogWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WebhookLogWhereInput"]> | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	response?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingRelationFilter"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["WebhookLogScalarFieldEnum"]:WebhookLogScalarFieldEnum;
	["UserWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	image?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["EnumRoleNullableListFilter"] | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundObjectEqualityInput"]> | undefined | null | Variable<any, string>,
	sharedBookingIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	leadWorkspaceIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderListRelationFilter"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditListRelationFilter"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignListRelationFilter"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipListRelationFilter"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationListRelationFilter"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryListRelationFilter"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentListRelationFilter"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceListRelationFilter"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceListRelationFilter"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewListRelationFilter"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionListRelationFilter"] | undefined | null | Variable<any, string>
};
	["UserScalarFieldEnum"]:UserScalarFieldEnum;
	["CampaignWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CampaignWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CampaignWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CampaignWhereInput"]> | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["EnumPaymentStatusFilter"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCompositeFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryRelationFilter"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentListRelationFilter"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>
};
	["CampaignScalarFieldEnum"]:CampaignScalarFieldEnum;
	["AdvertisementCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	bookingIds?:boolean | `@${string}`,
	_count?:ValueTypes["AdvertisementCategoryCount"],
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["CampaignOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["CampaignScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["BookingOrderByWithRelationInput"]> | undefined | null | Variable<any, string>,	cursor?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	distinct?: Array<ValueTypes["BookingScalarFieldEnum"]> | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryCount"]: AliasType<{
campaigns?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
bookings?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AdvertisementCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdvertisementCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdvertisementCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryScalarFieldEnum"]:AdvertisementCategoryScalarFieldEnum;
	["Changelog"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	bookingId?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	updates?:boolean | `@${string}`,
booking?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Booking"]],
	user?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["ChangelogOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["ChangelogWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["ChangelogWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ChangelogWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["ChangelogWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingNullableRelationFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["ChangelogScalarFieldEnum"]:ChangelogScalarFieldEnum;
	["SlotUsage"]: AliasType<{
	id?:boolean | `@${string}`,
	bookingId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	slotUsed?:boolean | `@${string}`,
	booking?:ValueTypes["Booking"],
		__typename?: boolean | `@${string}`
}>;
	["SlotUsageOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	slotUsed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["SlotUsageWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	bookingId_date?: ValueTypes["SlotUsageBookingIdDateCompoundUniqueInput"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["SlotUsageWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SlotUsageWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SlotUsageWhereInput"]> | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	slotUsed?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingRelationFilter"] | undefined | null | Variable<any, string>
};
	["SlotUsageBookingIdDateCompoundUniqueInput"]: {
	bookingId: string | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>
};
	["SlotUsageScalarFieldEnum"]:SlotUsageScalarFieldEnum;
	["Order"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
	priceSummary?:ValueTypes["PriceSummary"],
	selectedDate?:ValueTypes["OrderSelectedDate"],
	adBoard?:ValueTypes["AdBoard"],
createdBy?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryOrderByInput"] | undefined | null | Variable<any, string>,
	selectedDate?: ValueTypes["OrderSelectedDateOrderByInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["OrderWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["OrderWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["OrderWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["OrderWhereInput"]> | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCompositeFilter"] | undefined | null | Variable<any, string>,
	selectedDate?: ValueTypes["OrderSelectedDateCompositeFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["OrderScalarFieldEnum"]:OrderScalarFieldEnum;
	["Notification"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	metadata?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
	channels?:boolean | `@${string}`,
	recipient?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	recipient?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["NotificationWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["NotificationWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["NotificationWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["NotificationWhereInput"]> | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumNotificationTypeFilter"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["EnumNotificationChannelNullableListFilter"] | undefined | null | Variable<any, string>,
	recipient?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["NotificationScalarFieldEnum"]:NotificationScalarFieldEnum;
	["AdBoardPublishHistory"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
	publishedByUserId?:boolean | `@${string}`,
	changesSnapshot?:boolean | `@${string}`,
	adBoard?:ValueTypes["AdBoard"],
	publishedBy?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardPublishHistoryOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publishedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	changesSnapshot?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	publishedBy?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	publishedByUserId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	changesSnapshot?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	publishedBy?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryScalarFieldEnum"]:AdBoardPublishHistoryScalarFieldEnum;
	["AdBoardReviewComment"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	adBoard?:ValueTypes["AdBoard"],
	user?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardReviewCommentOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	comment?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AdBoardReviewCommentWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardReviewCommentWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardReviewCommentWhereInput"]> | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	comment?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["EnumReviewActionFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentScalarFieldEnum"]:AdBoardReviewCommentScalarFieldEnum;
	["WorkspaceWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["WorkspaceWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WorkspaceWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WorkspaceWhereInput"]> | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	leadIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserNullableRelationFilter"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistListRelationFilter"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupListRelationFilter"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileListRelationFilter"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionListRelationFilter"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionNullableRelationFilter"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerListRelationFilter"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleListRelationFilter"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogListRelationFilter"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserListRelationFilter"] | undefined | null | Variable<any, string>
};
	["WorkspaceScalarFieldEnum"]:WorkspaceScalarFieldEnum;
	["AdBoardPublicReview"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	rating?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	adBoard?:ValueTypes["AdBoard"],
	user?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardPublicReviewOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	rating?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	comment?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AdBoardPublicReviewWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardPublicReviewWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardPublicReviewWhereInput"]> | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	rating?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	comment?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewScalarFieldEnum"]:AdBoardPublicReviewScalarFieldEnum;
	["UserPosition"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	coords?:ValueTypes["UserPositionCoords"],
	location?:ValueTypes["UserPositionLocation"],
	user?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["UserPositionCoords"]: AliasType<{
	accuracy?:boolean | `@${string}`,
	altitude?:boolean | `@${string}`,
	altitudeAccuracy?:boolean | `@${string}`,
	heading?:boolean | `@${string}`,
	latitude?:boolean | `@${string}`,
	longitude?:boolean | `@${string}`,
	speed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionLocation"]: AliasType<{
	address?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	coords?: ValueTypes["UserPositionCoordsOrderByInput"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationOrderByInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["UserPositionCoordsOrderByInput"]: {
	accuracy?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	altitude?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	altitudeAccuracy?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	heading?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	latitude?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	longitude?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	speed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserPositionLocationOrderByInput"]: {
	address?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserPositionWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["UserPositionWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserPositionWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserPositionWhereInput"]> | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	coords?: ValueTypes["UserPositionCoordsCompositeFilter"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserRelationFilter"] | undefined | null | Variable<any, string>
};
	["UserPositionScalarFieldEnum"]:UserPositionScalarFieldEnum;
	["Payment"]: AliasType<{
	id?:boolean | `@${string}`,
	paymentIntentId?:boolean | `@${string}`,
	checkoutSessionId?:boolean | `@${string}`,
	eventId?:boolean | `@${string}`,
	liveMode?:boolean | `@${string}`,
	paymentGateway?:boolean | `@${string}`,
	subtotal?:boolean | `@${string}`,
	total?:boolean | `@${string}`,
	tax?:boolean | `@${string}`,
	discount?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
campaign?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
		__typename?: boolean | `@${string}`
}>;
	["PaymentOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentIntentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	checkoutSessionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	eventId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	liveMode?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentGateway?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	subtotal?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	total?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	tax?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["PaymentWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	paymentIntentId?: string | undefined | null | Variable<any, string>,
	checkoutSessionId?: string | undefined | null | Variable<any, string>,
	eventId?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PaymentWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PaymentWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PaymentWhereInput"]> | undefined | null | Variable<any, string>,
	liveMode?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	paymentGateway?: ValueTypes["EnumPaymentGatewayNameFilter"] | undefined | null | Variable<any, string>,
	subtotal?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	total?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	tax?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignNullableRelationFilter"] | undefined | null | Variable<any, string>
};
	["PaymentScalarFieldEnum"]:PaymentScalarFieldEnum;
	["BusinessCategoryWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BusinessCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BusinessCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BusinessCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	restrictedByAdBoardIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignListRelationFilter"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingListRelationFilter"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryScalarFieldEnum"]:BusinessCategoryScalarFieldEnum;
	["SlotConfiguration"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
	adBoard?:ValueTypes["AdBoard"],
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationOrderByWithRelationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardOrderByWithRelationInput"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	adBoardId_date?: ValueTypes["SlotConfigurationAdBoardIdDateCompoundUniqueInput"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["SlotConfigurationWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SlotConfigurationWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SlotConfigurationWhereInput"]> | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardRelationFilter"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationAdBoardIdDateCompoundUniqueInput"]: {
	adBoardId: string | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>
};
	["SlotConfigurationScalarFieldEnum"]:SlotConfigurationScalarFieldEnum;
	["AdBoardGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adBoardTypeId?:boolean | `@${string}`,
	tags?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	galleryImgs?:boolean | `@${string}`,
	notes?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	categoryId?:boolean | `@${string}`,
	restrictedBusinessIds?:boolean | `@${string}`,
	targetAudiences?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	playerIds?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	additionalData?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ValueTypes["AdBoardCountAggregate"],
	_avg?:ValueTypes["AdBoardAvgAggregate"],
	_sum?:ValueTypes["AdBoardSumAggregate"],
	_min?:ValueTypes["AdBoardMinAggregate"],
	_max?:ValueTypes["AdBoardMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	galleryImgs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	notes?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	targetAudiences?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publishedVersionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["AdBoardCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["AdBoardAvgOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["AdBoardMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["AdBoardMinOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["AdBoardSumOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	galleryImgs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	notes?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	targetAudiences?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publishedVersionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardAvgOrderByAggregateInput"]: {
	pricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publishedVersionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publishedVersionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardSumOrderByAggregateInput"]: {
	pricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["IntWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["IntNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	galleryImgs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	notes?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["FloatNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	targetAudiences?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumAdBoardStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["EnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["IntWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["IntWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["IntWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["IntWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["FloatNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	playerIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JsonNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	publishedVersionId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["StringWithAggregatesFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	mode?: ValueTypes["QueryMode"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedStringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedStringFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedStringFilter"] | undefined | null | Variable<any, string>
};
	["NestedStringWithAggregatesFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedStringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedStringFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedStringFilter"] | undefined | null | Variable<any, string>
};
	["BoolWithAggregatesFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedBoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedBoolFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedBoolFilter"] | undefined | null | Variable<any, string>
};
	["NestedBoolWithAggregatesFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedBoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedBoolFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedBoolFilter"] | undefined | null | Variable<any, string>
};
	["StringNullableWithAggregatesFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	mode?: ValueTypes["QueryMode"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedStringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedStringNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedStringNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedStringNullableWithAggregatesFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedStringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedStringNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedStringNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["IntWithAggregatesFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedIntWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["NestedFloatFilter"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>
};
	["NestedIntWithAggregatesFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedIntWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["NestedFloatFilter"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>
};
	["IntNullableWithAggregatesFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedIntNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedIntNullableWithAggregatesFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedIntNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["FloatNullableWithAggregatesFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedFloatNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedFloatNullableWithAggregatesFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedFloatNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedFloatNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["EnumAdBoardStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdBoardStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdBoardStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdBoardStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumAdBoardStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdBoardStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdBoardStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdBoardStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>
};
	["EnumAdboardDraftStatusNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdboardDraftStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdboardDraftStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdboardDraftStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdboardDraftStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["DateTimeNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedDateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedDateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedDateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedDateTimeNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedDateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedDateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedDateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["JsonNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedJsonNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedJsonNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedJsonNullableFilter"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["AggregateAdBoardGroup"]: AliasType<{
	_count?:ValueTypes["AdBoardGroupCountAggregate"],
	_min?:ValueTypes["AdBoardGroupMinAggregate"],
	_max?:ValueTypes["AdBoardGroupMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	scheduledPlaylistsIDs?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	scheduledPlaylistsIDs?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_count?:ValueTypes["AdBoardGroupCountAggregate"],
	_min?:ValueTypes["AdBoardGroupMinAggregate"],
	_max?:ValueTypes["AdBoardGroupMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["AdBoardGroupCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["AdBoardGroupMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["AdBoardGroupMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["EnumFitTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["EnumFitTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["EnumFitTypeWithAggregatesFilter"]: {
	equals?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["FitType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["FitType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumFitTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumFitTypeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumFitTypeFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumFitTypeWithAggregatesFilter"]: {
	equals?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["FitType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["FitType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumFitTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumFitTypeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumFitTypeFilter"] | undefined | null | Variable<any, string>
};
	["AggregateAdBoardType"]: AliasType<{
	_count?:ValueTypes["AdBoardTypeCountAggregate"],
	_min?:ValueTypes["AdBoardTypeMinAggregate"],
	_max?:ValueTypes["AdBoardTypeMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AdBoardTypeWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardTypeWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardTypeWhereInput"]> | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeScalarFieldEnum"]:AdBoardTypeScalarFieldEnum;
	["AdBoardTypeGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	_count?:ValueTypes["AdBoardTypeCountAggregate"],
	_min?:ValueTypes["AdBoardTypeMinAggregate"],
	_max?:ValueTypes["AdBoardTypeMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["AdBoardTypeCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["AdBoardTypeMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["AdBoardTypeMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregateAdvertisementCategory"]: AliasType<{
	_count?:ValueTypes["AdvertisementCategoryCountAggregate"],
	_min?:ValueTypes["AdvertisementCategoryMinAggregate"],
	_max?:ValueTypes["AdvertisementCategoryMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	bookingIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	bookingIds?:boolean | `@${string}`,
	_count?:ValueTypes["AdvertisementCategoryCountAggregate"],
	_min?:ValueTypes["AdvertisementCategoryMinAggregate"],
	_max?:ValueTypes["AdvertisementCategoryMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["AdvertisementCategoryCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["AdvertisementCategoryMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["AdvertisementCategoryMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["EnumAdvertisementCategoryEnumWithAggregatesFilter"]: {
	equals?: ValueTypes["AdvertisementCategoryEnum"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdvertisementCategoryEnum"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdvertisementCategoryEnum"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"]: {
	equals?: ValueTypes["AdvertisementCategoryEnum"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AdvertisementCategoryEnum"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AdvertisementCategoryEnum"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>
};
	["DateTimeWithAggregatesFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedDateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedDateTimeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedDateTimeFilter"] | undefined | null | Variable<any, string>
};
	["NestedDateTimeWithAggregatesFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedDateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedDateTimeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedDateTimeFilter"] | undefined | null | Variable<any, string>
};
	["AggregateAudit"]: AliasType<{
	_count?:ValueTypes["AuditCountAggregate"],
	_min?:ValueTypes["AuditMinAggregate"],
	_max?:ValueTypes["AuditMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AuditCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	updates?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuditMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuditMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuditGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	updates?:boolean | `@${string}`,
	_count?:ValueTypes["AuditCountAggregate"],
	_min?:ValueTypes["AuditMinAggregate"],
	_max?:ValueTypes["AuditMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AuditOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["AuditCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["AuditMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["AuditMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["AuditCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AuditMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AuditMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["AuditScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["AuditScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AuditScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AuditScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JsonNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregateBooking"]: AliasType<{
	_count?:ValueTypes["BookingCountAggregate"],
	_min?:ValueTypes["BookingMinAggregate"],
	_max?:ValueTypes["BookingMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["BookingCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	sharedUserIds?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	sharedUserIds?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
	_count?:ValueTypes["BookingCountAggregate"],
	_min?:ValueTypes["BookingMinAggregate"],
	_max?:ValueTypes["BookingMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["BookingOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingCode?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sharedUserIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["BookingCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["BookingMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["BookingMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["BookingCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingCode?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sharedUserIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingCode?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingCode?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BookingScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["BookingScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingCode?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["EnumBookingStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["EnumPaymentStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["EnumDeploymentStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["EnumContentApprovalStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	sharedUserIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["EnumBookingStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["BookingStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["BookingStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumBookingStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumBookingStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumBookingStatusFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumBookingStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["BookingStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["BookingStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumBookingStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumBookingStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumBookingStatusFilter"] | undefined | null | Variable<any, string>
};
	["EnumPaymentStatusNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedEnumPaymentStatusNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["EnumDeploymentStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DeploymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DeploymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumDeploymentStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumDeploymentStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DeploymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DeploymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumDeploymentStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>
};
	["EnumContentApprovalStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentApprovalStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentApprovalStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumContentApprovalStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentApprovalStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentApprovalStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>
};
	["AggregateBusinessCategory"]: AliasType<{
	_count?:ValueTypes["BusinessCategoryCountAggregate"],
	_min?:ValueTypes["BusinessCategoryMinAggregate"],
	_max?:ValueTypes["BusinessCategoryMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	restrictedByAdBoardIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	restrictedByAdBoardIds?:boolean | `@${string}`,
	_count?:ValueTypes["BusinessCategoryCountAggregate"],
	_min?:ValueTypes["BusinessCategoryMinAggregate"],
	_max?:ValueTypes["BusinessCategoryMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	restrictedByAdBoardIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["BusinessCategoryCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["BusinessCategoryMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["BusinessCategoryMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	restrictedByAdBoardIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	restrictedByAdBoardIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["AggregateCamera"]: AliasType<{
	_count?:ValueTypes["CameraCountAggregate"],
	_min?:ValueTypes["CameraMinAggregate"],
	_max?:ValueTypes["CameraMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	connectedCpuSerialNumbers?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	connectedCpuSerialNumbers?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	_count?:ValueTypes["CameraCountAggregate"],
	_min?:ValueTypes["CameraMinAggregate"],
	_max?:ValueTypes["CameraMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["CameraCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["CameraMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["CameraMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["CameraCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["CameraScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	cameraUrl?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregateCameraFeed"]: AliasType<{
	_count?:ValueTypes["CameraFeedCountAggregate"],
	_min?:ValueTypes["CameraFeedMinAggregate"],
	_max?:ValueTypes["CameraFeedMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	_count?:ValueTypes["CameraFeedCountAggregate"],
	_min?:ValueTypes["CameraFeedMinAggregate"],
	_max?:ValueTypes["CameraFeedMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["CameraFeedCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["CameraFeedMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["CameraFeedMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["CameraFeedCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraFeedMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraFeedMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraFeedScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregateCameraServer"]: AliasType<{
	_count?:ValueTypes["CameraServerCountAggregate"],
	_min?:ValueTypes["CameraServerMinAggregate"],
	_max?:ValueTypes["CameraServerMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraServerCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraServerMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraServerMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraServerGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
	_count?:ValueTypes["CameraServerCountAggregate"],
	_min?:ValueTypes["CameraServerMinAggregate"],
	_max?:ValueTypes["CameraServerMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraServerOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deviceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["CameraServerCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["CameraServerMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["CameraServerMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["CameraServerCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deviceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraServerMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deviceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraServerMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	deviceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CameraServerScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	deviceId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["BoolNullableWithAggregatesFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedBoolNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedBoolNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedBoolNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedBoolNullableWithAggregatesFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedBoolNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedBoolNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedBoolNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["AggregateCampaign"]: AliasType<{
	_count?:ValueTypes["CampaignCountAggregate"],
	_min?:ValueTypes["CampaignMinAggregate"],
	_max?:ValueTypes["CampaignMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	_count?:ValueTypes["CampaignCountAggregate"],
	_min?:ValueTypes["CampaignMinAggregate"],
	_max?:ValueTypes["CampaignMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["CampaignCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["CampaignMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["CampaignMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["CampaignCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CampaignMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CampaignMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["CampaignScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["EnumPaymentStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["EnumPaymentStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumPaymentStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumPaymentStatusFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumPaymentStatusWithAggregatesFilter"]: {
	equals?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["PaymentStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumPaymentStatusWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumPaymentStatusFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumPaymentStatusFilter"] | undefined | null | Variable<any, string>
};
	["AggregateFile"]: AliasType<{
	_count?:ValueTypes["FileCountAggregate"],
	_avg?:ValueTypes["FileAvgAggregate"],
	_sum?:ValueTypes["FileSumAggregate"],
	_min?:ValueTypes["FileMinAggregate"],
	_max?:ValueTypes["FileMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["FileCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	bookingsIDs?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileAvgAggregate"]: AliasType<{
	size?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileSumAggregate"]: AliasType<{
	size?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	bookingsIDs?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	_count?:ValueTypes["FileCountAggregate"],
	_avg?:ValueTypes["FileAvgAggregate"],
	_sum?:ValueTypes["FileSumAggregate"],
	_min?:ValueTypes["FileMinAggregate"],
	_max?:ValueTypes["FileMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["FileOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["FileCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["FileAvgOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["FileMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["FileMinOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["FileSumOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["FileCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	bookingsIDs?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FileAvgOrderByAggregateInput"]: {
	size?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FileMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FileMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FileSumOrderByAggregateInput"]: {
	size?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FileScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["FileScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["FileScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["FileScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumFileStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	bookingsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["EnumFileStatusNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["FileStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["FileStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumFileStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["NestedEnumFileStatusNullableWithAggregatesFilter"]: {
	equals?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["FileStatus"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["FileStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumFileStatusNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntNullableFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isSet?: boolean | undefined | null | Variable<any, string>
};
	["AggregateMembership"]: AliasType<{
	_count?:ValueTypes["MembershipCountAggregate"],
	_min?:ValueTypes["MembershipMinAggregate"],
	_max?:ValueTypes["MembershipMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["MembershipCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MembershipMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MembershipMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MembershipGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
	_count?:ValueTypes["MembershipCountAggregate"],
	_min?:ValueTypes["MembershipMinAggregate"],
	_max?:ValueTypes["MembershipMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["MembershipOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["MembershipCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["MembershipMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["MembershipMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["MembershipCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["MembershipMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["MembershipMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["MembershipScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregateNotification"]: AliasType<{
	_count?:ValueTypes["NotificationCountAggregate"],
	_min?:ValueTypes["NotificationMinAggregate"],
	_max?:ValueTypes["NotificationMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	metadata?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
	channels?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	metadata?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
	channels?:boolean | `@${string}`,
	_count?:ValueTypes["NotificationCountAggregate"],
	_min?:ValueTypes["NotificationMinAggregate"],
	_max?:ValueTypes["NotificationMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NotificationCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NotificationMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NotificationMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["NotificationCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["NotificationMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["NotificationMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["NotificationScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumNotificationTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["JsonNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["EnumNotificationChannelNullableListFilter"] | undefined | null | Variable<any, string>
};
	["EnumNotificationTypeWithAggregatesFilter"]: {
	equals?: ValueTypes["NotificationType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumNotificationTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumNotificationTypeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumNotificationTypeFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumNotificationTypeWithAggregatesFilter"]: {
	equals?: ValueTypes["NotificationType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumNotificationTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumNotificationTypeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumNotificationTypeFilter"] | undefined | null | Variable<any, string>
};
	["AggregateOrder"]: AliasType<{
	_count?:ValueTypes["OrderCountAggregate"],
	_min?:ValueTypes["OrderMinAggregate"],
	_max?:ValueTypes["OrderMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["OrderCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
	_count?:ValueTypes["OrderCountAggregate"],
	_min?:ValueTypes["OrderMinAggregate"],
	_max?:ValueTypes["OrderMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["OrderOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["OrderCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["OrderMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["OrderMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["OrderCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["OrderMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["OrderMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["OrderScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["OrderScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["OrderScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["OrderScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregatePlayer"]: AliasType<{
	_count?:ValueTypes["PlayerCountAggregate"],
	_avg?:ValueTypes["PlayerAvgAggregate"],
	_sum?:ValueTypes["PlayerSumAggregate"],
	_min?:ValueTypes["PlayerMinAggregate"],
	_max?:ValueTypes["PlayerMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["PlayerCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	playlistIds?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	signageTypeId?:boolean | `@${string}`,
	audienceTypeId?:boolean | `@${string}`,
	aspectRatioType?:boolean | `@${string}`,
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	adBoardIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerAvgAggregate"]: AliasType<{
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerSumAggregate"]: AliasType<{
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	signageTypeId?:boolean | `@${string}`,
	aspectRatioType?:boolean | `@${string}`,
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	signageTypeId?:boolean | `@${string}`,
	aspectRatioType?:boolean | `@${string}`,
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	playlistIds?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	signageTypeId?:boolean | `@${string}`,
	audienceTypeId?:boolean | `@${string}`,
	aspectRatioType?:boolean | `@${string}`,
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	adBoardIds?:boolean | `@${string}`,
	_count?:ValueTypes["PlayerCountAggregate"],
	_avg?:ValueTypes["PlayerAvgAggregate"],
	_sum?:ValueTypes["PlayerSumAggregate"],
	_min?:ValueTypes["PlayerMinAggregate"],
	_max?:ValueTypes["PlayerMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["PlayerOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playlistIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	audienceTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["PlayerCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["PlayerAvgOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["PlayerMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["PlayerMinOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["PlayerSumOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["PlayerCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	playlistIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	audienceTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlayerAvgOrderByAggregateInput"]: {
	customAspectRatioWidth?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlayerMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlayerMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlayerSumOrderByAggregateInput"]: {
	customAspectRatioWidth?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlayerScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	playlistIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	audienceTypeId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["EnumAspectRatioTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["IntNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["IntNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["EnumAspectRatioTypeWithAggregatesFilter"]: {
	equals?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AspectRatioType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AspectRatioType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumAspectRatioTypeWithAggregatesFilter"]: {
	equals?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["AspectRatioType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["AspectRatioType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>
};
	["AggregatePlaylist"]: AliasType<{
	_count?:ValueTypes["PlaylistCountAggregate"],
	_min?:ValueTypes["PlaylistMinAggregate"],
	_max?:ValueTypes["PlaylistMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["PlaylistCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	adBoardGroupIds?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaylistMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaylistMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaylistGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	adBoardGroupIds?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_count?:ValueTypes["PlaylistCountAggregate"],
	_min?:ValueTypes["PlaylistMinAggregate"],
	_max?:ValueTypes["PlaylistMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["PlaylistOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroupIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["PlaylistCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["PlaylistMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["PlaylistMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardGroupIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlaylistMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlaylistMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlaylistScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["BoolNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["EnumScreenLayoutWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["BoolNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["EnumScreenLayoutWithAggregatesFilter"]: {
	equals?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ScreenLayout"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ScreenLayout"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumScreenLayoutWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumScreenLayoutFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumScreenLayoutFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumScreenLayoutWithAggregatesFilter"]: {
	equals?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ScreenLayout"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ScreenLayout"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumScreenLayoutWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumScreenLayoutFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumScreenLayoutFilter"] | undefined | null | Variable<any, string>
};
	["AggregateSlotConfiguration"]: AliasType<{
	_count?:ValueTypes["SlotConfigurationCountAggregate"],
	_avg?:ValueTypes["SlotConfigurationAvgAggregate"],
	_sum?:ValueTypes["SlotConfigurationSumAggregate"],
	_min?:ValueTypes["SlotConfigurationMinAggregate"],
	_max?:ValueTypes["SlotConfigurationMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationAvgAggregate"]: AliasType<{
	availableSlots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationSumAggregate"]: AliasType<{
	availableSlots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
	_count?:ValueTypes["SlotConfigurationCountAggregate"],
	_avg?:ValueTypes["SlotConfigurationAvgAggregate"],
	_sum?:ValueTypes["SlotConfigurationSumAggregate"],
	_min?:ValueTypes["SlotConfigurationMinAggregate"],
	_max?:ValueTypes["SlotConfigurationMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["SlotConfigurationCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_avg?: ValueTypes["SlotConfigurationAvgOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["SlotConfigurationMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["SlotConfigurationMinOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_sum?: ValueTypes["SlotConfigurationSumOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationAvgOrderByAggregateInput"]: {
	availableSlots?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationSumOrderByAggregateInput"]: {
	availableSlots?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["IntWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregateSubscription"]: AliasType<{
	_count?:ValueTypes["SubscriptionCountAggregate"],
	_min?:ValueTypes["SubscriptionMinAggregate"],
	_max?:ValueTypes["SubscriptionMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	activeWorkspaceId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	activeWorkspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	activeWorkspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: string | undefined | null | Variable<any, string>,
	activeWorkspaceId?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["SubscriptionWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SubscriptionWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SubscriptionWhereInput"]> | undefined | null | Variable<any, string>,
	packageId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	packageName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	priceLookupKey?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsNullableCompositeFilter"] | undefined | null | Variable<any, string>,
	activeWorkspace?: ValueTypes["WorkspaceRelationFilter"] | undefined | null | Variable<any, string>
};
	["SubscriptionScalarFieldEnum"]:SubscriptionScalarFieldEnum;
	["SubscriptionGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	activeWorkspaceId?:boolean | `@${string}`,
	_count?:ValueTypes["SubscriptionCountAggregate"],
	_min?:ValueTypes["SubscriptionMinAggregate"],
	_max?:ValueTypes["SubscriptionMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	priceLookupKey?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	activeWorkspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["SubscriptionCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["SubscriptionMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["SubscriptionMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	priceLookupKey?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	activeWorkspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SubscriptionMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	priceLookupKey?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	activeWorkspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SubscriptionMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	packageName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	priceLookupKey?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	activeWorkspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["SubscriptionScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	packageId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	packageName?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	priceLookupKey?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	activeWorkspaceId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregateUser"]: AliasType<{
	_count?:ValueTypes["UserCountAggregate"],
	_min?:ValueTypes["UserMinAggregate"],
	_max?:ValueTypes["UserMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["UserCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	otherDetails?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
	sharedBookingIds?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
	leadWorkspaceIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	otherDetails?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
	sharedBookingIds?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
	leadWorkspaceIds?:boolean | `@${string}`,
	_count?:ValueTypes["UserCountAggregate"],
	_min?:ValueTypes["UserMinAggregate"],
	_max?:ValueTypes["UserMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["UserOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	uid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sharedBookingIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	leadWorkspaceIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["UserCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["UserMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["UserMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["UserCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	uid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	sharedBookingIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	leadWorkspaceIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	uid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	uid?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["UserScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	uid?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JsonNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["EnumRoleNullableListFilter"] | undefined | null | Variable<any, string>,
	sharedBookingIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["BoolWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	leadWorkspaceIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["AggregateUserPosition"]: AliasType<{
	_count?:ValueTypes["UserPositionCountAggregate"],
	_min?:ValueTypes["UserPositionMinAggregate"],
	_max?:ValueTypes["UserPositionMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["UserPositionCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	_count?:ValueTypes["UserPositionCountAggregate"],
	_min?:ValueTypes["UserPositionMinAggregate"],
	_max?:ValueTypes["UserPositionMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["UserPositionOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["UserPositionCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["UserPositionMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["UserPositionMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["UserPositionCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserPositionMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserPositionMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserPositionScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["AggregateWorkspace"]: AliasType<{
	_count?:ValueTypes["WorkspaceCountAggregate"],
	_min?:ValueTypes["WorkspaceMinAggregate"],
	_max?:ValueTypes["WorkspaceMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	userIds?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	leadIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	userIds?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	leadIds?:boolean | `@${string}`,
	_count?:ValueTypes["WorkspaceCountAggregate"],
	_min?:ValueTypes["WorkspaceMinAggregate"],
	_max?:ValueTypes["WorkspaceMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	leadIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["WorkspaceCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["WorkspaceMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["WorkspaceMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	leadIds?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumWorkspaceTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	userIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	leadIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["EnumWorkspaceTypeWithAggregatesFilter"]: {
	equals?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["WorkspaceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["WorkspaceType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>
};
	["NestedEnumWorkspaceTypeWithAggregatesFilter"]: {
	equals?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["WorkspaceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["WorkspaceType"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["NestedIntFilter"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>
};
	["AggregateWorkspaceRole"]: AliasType<{
	_count?:ValueTypes["WorkspaceRoleCountAggregate"],
	_min?:ValueTypes["WorkspaceRoleMinAggregate"],
	_max?:ValueTypes["WorkspaceRoleMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ValueTypes["WorkspaceRoleCountAggregate"],
	_min?:ValueTypes["WorkspaceRoleMinAggregate"],
	_max?:ValueTypes["WorkspaceRoleMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleOrderByWithAggregationInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	permissions?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	_count?: ValueTypes["WorkspaceRoleCountOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_max?: ValueTypes["WorkspaceRoleMaxOrderByAggregateInput"] | undefined | null | Variable<any, string>,
	_min?: ValueTypes["WorkspaceRoleMinOrderByAggregateInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleCountOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	permissions?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleMaxOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleMinOrderByAggregateInput"]: {
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleScalarWhereWithAggregatesInput"]: {
	AND?: Array<ValueTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	permissions?: ValueTypes["EnumPermissionNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeWithAggregatesFilter"] | undefined | null | Variable<any, string>
};
	["Slot"]: AliasType<{
	date?:boolean | `@${string}`,
	slots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createManyAdBoard?: [{	data: Array<ValueTypes["AdBoardCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneAdBoard?: [{	data: ValueTypes["AdBoardCreateInput"] | Variable<any, string>},ValueTypes["AdBoard"]],
deleteManyAdBoard?: [{	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneAdBoard?: [{	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoard"]],
updateManyAdBoard?: [{	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneAdBoard?: [{	data: ValueTypes["AdBoardUpdateInput"] | Variable<any, string>,	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoard"]],
upsertOneAdBoard?: [{	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["AdBoardCreateInput"] | Variable<any, string>,	update: ValueTypes["AdBoardUpdateInput"] | Variable<any, string>},ValueTypes["AdBoard"]],
createManyAdBoardGroup?: [{	data: Array<ValueTypes["AdBoardGroupCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneAdBoardGroup?: [{	data: ValueTypes["AdBoardGroupCreateInput"] | Variable<any, string>},ValueTypes["AdBoardGroup"]],
deleteManyAdBoardGroup?: [{	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneAdBoardGroup?: [{	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardGroup"]],
updateManyAdBoardGroup?: [{	data: ValueTypes["AdBoardGroupUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneAdBoardGroup?: [{	data: ValueTypes["AdBoardGroupUpdateInput"] | Variable<any, string>,	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardGroup"]],
upsertOneAdBoardGroup?: [{	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["AdBoardGroupCreateInput"] | Variable<any, string>,	update: ValueTypes["AdBoardGroupUpdateInput"] | Variable<any, string>},ValueTypes["AdBoardGroup"]],
createManyAdBoardType?: [{	data: Array<ValueTypes["AdBoardTypeCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneAdBoardType?: [{	data: ValueTypes["AdBoardTypeCreateInput"] | Variable<any, string>},ValueTypes["AdBoardType"]],
deleteManyAdBoardType?: [{	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneAdBoardType?: [{	where: ValueTypes["AdBoardTypeWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardType"]],
updateManyAdBoardType?: [{	data: ValueTypes["AdBoardTypeUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneAdBoardType?: [{	data: ValueTypes["AdBoardTypeUpdateInput"] | Variable<any, string>,	where: ValueTypes["AdBoardTypeWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardType"]],
upsertOneAdBoardType?: [{	where: ValueTypes["AdBoardTypeWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["AdBoardTypeCreateInput"] | Variable<any, string>,	update: ValueTypes["AdBoardTypeUpdateInput"] | Variable<any, string>},ValueTypes["AdBoardType"]],
createManyAdvertisementCategory?: [{	data: Array<ValueTypes["AdvertisementCategoryCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneAdvertisementCategory?: [{	data: ValueTypes["AdvertisementCategoryCreateInput"] | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
deleteManyAdvertisementCategory?: [{	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneAdvertisementCategory?: [{	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
updateManyAdvertisementCategory?: [{	data: ValueTypes["AdvertisementCategoryUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["AdvertisementCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneAdvertisementCategory?: [{	data: ValueTypes["AdvertisementCategoryUpdateInput"] | Variable<any, string>,	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
upsertOneAdvertisementCategory?: [{	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["AdvertisementCategoryCreateInput"] | Variable<any, string>,	update: ValueTypes["AdvertisementCategoryUpdateInput"] | Variable<any, string>},ValueTypes["AdvertisementCategory"]],
createManyAudit?: [{	data: Array<ValueTypes["AuditCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneAudit?: [{	data: ValueTypes["AuditCreateInput"] | Variable<any, string>},ValueTypes["Audit"]],
deleteManyAudit?: [{	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneAudit?: [{	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>},ValueTypes["Audit"]],
updateManyAudit?: [{	data: ValueTypes["AuditUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["AuditWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneAudit?: [{	data: ValueTypes["AuditUpdateInput"] | Variable<any, string>,	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>},ValueTypes["Audit"]],
upsertOneAudit?: [{	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["AuditCreateInput"] | Variable<any, string>,	update: ValueTypes["AuditUpdateInput"] | Variable<any, string>},ValueTypes["Audit"]],
createManyBooking?: [{	data: Array<ValueTypes["BookingCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneBooking?: [{	data: ValueTypes["BookingCreateInput"] | Variable<any, string>},ValueTypes["Booking"]],
deleteManyBooking?: [{	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneBooking?: [{	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>},ValueTypes["Booking"]],
updateManyBooking?: [{	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneBooking?: [{	data: ValueTypes["BookingUpdateInput"] | Variable<any, string>,	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>},ValueTypes["Booking"]],
upsertOneBooking?: [{	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["BookingCreateInput"] | Variable<any, string>,	update: ValueTypes["BookingUpdateInput"] | Variable<any, string>},ValueTypes["Booking"]],
createManyBusinessCategory?: [{	data: Array<ValueTypes["BusinessCategoryCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneBusinessCategory?: [{	data: ValueTypes["BusinessCategoryCreateInput"] | Variable<any, string>},ValueTypes["BusinessCategory"]],
deleteManyBusinessCategory?: [{	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneBusinessCategory?: [{	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>},ValueTypes["BusinessCategory"]],
updateManyBusinessCategory?: [{	data: ValueTypes["BusinessCategoryUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneBusinessCategory?: [{	data: ValueTypes["BusinessCategoryUpdateInput"] | Variable<any, string>,	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>},ValueTypes["BusinessCategory"]],
upsertOneBusinessCategory?: [{	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["BusinessCategoryCreateInput"] | Variable<any, string>,	update: ValueTypes["BusinessCategoryUpdateInput"] | Variable<any, string>},ValueTypes["BusinessCategory"]],
createManyCamera?: [{	data: Array<ValueTypes["CameraCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneCamera?: [{	data: ValueTypes["CameraCreateInput"] | Variable<any, string>},ValueTypes["Camera"]],
deleteManyCamera?: [{	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneCamera?: [{	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>},ValueTypes["Camera"]],
updateManyCamera?: [{	data: ValueTypes["CameraUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneCamera?: [{	data: ValueTypes["CameraUpdateInput"] | Variable<any, string>,	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>},ValueTypes["Camera"]],
upsertOneCamera?: [{	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["CameraCreateInput"] | Variable<any, string>,	update: ValueTypes["CameraUpdateInput"] | Variable<any, string>},ValueTypes["Camera"]],
createManyCameraFeed?: [{	data: Array<ValueTypes["CameraFeedCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneCameraFeed?: [{	data: ValueTypes["CameraFeedCreateInput"] | Variable<any, string>},ValueTypes["CameraFeed"]],
deleteManyCameraFeed?: [{	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneCameraFeed?: [{	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>},ValueTypes["CameraFeed"]],
updateManyCameraFeed?: [{	data: ValueTypes["CameraFeedUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["CameraFeedWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneCameraFeed?: [{	data: ValueTypes["CameraFeedUpdateInput"] | Variable<any, string>,	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>},ValueTypes["CameraFeed"]],
upsertOneCameraFeed?: [{	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["CameraFeedCreateInput"] | Variable<any, string>,	update: ValueTypes["CameraFeedUpdateInput"] | Variable<any, string>},ValueTypes["CameraFeed"]],
createManyCameraServer?: [{	data: Array<ValueTypes["CameraServerCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneCameraServer?: [{	data: ValueTypes["CameraServerCreateInput"] | Variable<any, string>},ValueTypes["CameraServer"]],
deleteManyCameraServer?: [{	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneCameraServer?: [{	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>},ValueTypes["CameraServer"]],
updateManyCameraServer?: [{	data: ValueTypes["CameraServerUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneCameraServer?: [{	data: ValueTypes["CameraServerUpdateInput"] | Variable<any, string>,	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>},ValueTypes["CameraServer"]],
upsertOneCameraServer?: [{	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["CameraServerCreateInput"] | Variable<any, string>,	update: ValueTypes["CameraServerUpdateInput"] | Variable<any, string>},ValueTypes["CameraServer"]],
createManyCampaign?: [{	data: Array<ValueTypes["CampaignCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneCampaign?: [{	data: ValueTypes["CampaignCreateInput"] | Variable<any, string>},ValueTypes["Campaign"]],
deleteManyCampaign?: [{	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneCampaign?: [{	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>},ValueTypes["Campaign"]],
updateManyCampaign?: [{	data: ValueTypes["CampaignUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneCampaign?: [{	data: ValueTypes["CampaignUpdateInput"] | Variable<any, string>,	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>},ValueTypes["Campaign"]],
upsertOneCampaign?: [{	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["CampaignCreateInput"] | Variable<any, string>,	update: ValueTypes["CampaignUpdateInput"] | Variable<any, string>},ValueTypes["Campaign"]],
createManyFile?: [{	data: Array<ValueTypes["FileCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneFile?: [{	data: ValueTypes["FileCreateInput"] | Variable<any, string>},ValueTypes["File"]],
deleteManyFile?: [{	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneFile?: [{	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>},ValueTypes["File"]],
updateManyFile?: [{	data: ValueTypes["FileUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["FileWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneFile?: [{	data: ValueTypes["FileUpdateInput"] | Variable<any, string>,	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>},ValueTypes["File"]],
upsertOneFile?: [{	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["FileCreateInput"] | Variable<any, string>,	update: ValueTypes["FileUpdateInput"] | Variable<any, string>},ValueTypes["File"]],
createManyMembership?: [{	data: Array<ValueTypes["MembershipCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneMembership?: [{	data: ValueTypes["MembershipCreateInput"] | Variable<any, string>},ValueTypes["Membership"]],
deleteManyMembership?: [{	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneMembership?: [{	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>},ValueTypes["Membership"]],
updateManyMembership?: [{	data: ValueTypes["MembershipUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["MembershipWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneMembership?: [{	data: ValueTypes["MembershipUpdateInput"] | Variable<any, string>,	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>},ValueTypes["Membership"]],
upsertOneMembership?: [{	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["MembershipCreateInput"] | Variable<any, string>,	update: ValueTypes["MembershipUpdateInput"] | Variable<any, string>},ValueTypes["Membership"]],
createManyNotification?: [{	data: Array<ValueTypes["NotificationCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneNotification?: [{	data: ValueTypes["NotificationCreateInput"] | Variable<any, string>},ValueTypes["Notification"]],
deleteManyNotification?: [{	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneNotification?: [{	where: ValueTypes["NotificationWhereUniqueInput"] | Variable<any, string>},ValueTypes["Notification"]],
updateManyNotification?: [{	data: ValueTypes["NotificationUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["NotificationWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneNotification?: [{	data: ValueTypes["NotificationUpdateInput"] | Variable<any, string>,	where: ValueTypes["NotificationWhereUniqueInput"] | Variable<any, string>},ValueTypes["Notification"]],
upsertOneNotification?: [{	where: ValueTypes["NotificationWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["NotificationCreateInput"] | Variable<any, string>,	update: ValueTypes["NotificationUpdateInput"] | Variable<any, string>},ValueTypes["Notification"]],
createManyOrder?: [{	data: Array<ValueTypes["OrderCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneOrder?: [{	data: ValueTypes["OrderCreateInput"] | Variable<any, string>},ValueTypes["Order"]],
deleteManyOrder?: [{	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneOrder?: [{	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>},ValueTypes["Order"]],
updateManyOrder?: [{	data: ValueTypes["OrderUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["OrderWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneOrder?: [{	data: ValueTypes["OrderUpdateInput"] | Variable<any, string>,	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>},ValueTypes["Order"]],
upsertOneOrder?: [{	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["OrderCreateInput"] | Variable<any, string>,	update: ValueTypes["OrderUpdateInput"] | Variable<any, string>},ValueTypes["Order"]],
createManyPlayer?: [{	data: Array<ValueTypes["PlayerCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOnePlayer?: [{	data: ValueTypes["PlayerCreateInput"] | Variable<any, string>},ValueTypes["Player"]],
deleteManyPlayer?: [{	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOnePlayer?: [{	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>},ValueTypes["Player"]],
updateManyPlayer?: [{	data: ValueTypes["PlayerUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOnePlayer?: [{	data: ValueTypes["PlayerUpdateInput"] | Variable<any, string>,	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>},ValueTypes["Player"]],
upsertOnePlayer?: [{	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["PlayerCreateInput"] | Variable<any, string>,	update: ValueTypes["PlayerUpdateInput"] | Variable<any, string>},ValueTypes["Player"]],
createManyPlaylist?: [{	data: Array<ValueTypes["PlaylistCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOnePlaylist?: [{	data: ValueTypes["PlaylistCreateInput"] | Variable<any, string>},ValueTypes["Playlist"]],
deleteManyPlaylist?: [{	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOnePlaylist?: [{	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>},ValueTypes["Playlist"]],
updateManyPlaylist?: [{	data: ValueTypes["PlaylistUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOnePlaylist?: [{	data: ValueTypes["PlaylistUpdateInput"] | Variable<any, string>,	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>},ValueTypes["Playlist"]],
upsertOnePlaylist?: [{	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["PlaylistCreateInput"] | Variable<any, string>,	update: ValueTypes["PlaylistUpdateInput"] | Variable<any, string>},ValueTypes["Playlist"]],
createManySlotConfiguration?: [{	data: Array<ValueTypes["SlotConfigurationCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneSlotConfiguration?: [{	data: ValueTypes["SlotConfigurationCreateInput"] | Variable<any, string>},ValueTypes["SlotConfiguration"]],
deleteManySlotConfiguration?: [{	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneSlotConfiguration?: [{	where: ValueTypes["SlotConfigurationWhereUniqueInput"] | Variable<any, string>},ValueTypes["SlotConfiguration"]],
updateManySlotConfiguration?: [{	data: ValueTypes["SlotConfigurationUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["SlotConfigurationWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneSlotConfiguration?: [{	data: ValueTypes["SlotConfigurationUpdateInput"] | Variable<any, string>,	where: ValueTypes["SlotConfigurationWhereUniqueInput"] | Variable<any, string>},ValueTypes["SlotConfiguration"]],
upsertOneSlotConfiguration?: [{	where: ValueTypes["SlotConfigurationWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["SlotConfigurationCreateInput"] | Variable<any, string>,	update: ValueTypes["SlotConfigurationUpdateInput"] | Variable<any, string>},ValueTypes["SlotConfiguration"]],
createManySubscription?: [{	data: Array<ValueTypes["SubscriptionCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneSubscription?: [{	data: ValueTypes["SubscriptionCreateInput"] | Variable<any, string>},ValueTypes["Subscription"]],
deleteManySubscription?: [{	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneSubscription?: [{	where: ValueTypes["SubscriptionWhereUniqueInput"] | Variable<any, string>},ValueTypes["Subscription"]],
updateManySubscription?: [{	data: ValueTypes["SubscriptionUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneSubscription?: [{	data: ValueTypes["SubscriptionUpdateInput"] | Variable<any, string>,	where: ValueTypes["SubscriptionWhereUniqueInput"] | Variable<any, string>},ValueTypes["Subscription"]],
upsertOneSubscription?: [{	where: ValueTypes["SubscriptionWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["SubscriptionCreateInput"] | Variable<any, string>,	update: ValueTypes["SubscriptionUpdateInput"] | Variable<any, string>},ValueTypes["Subscription"]],
createManyUser?: [{	data: Array<ValueTypes["UserCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneUser?: [{	data: ValueTypes["UserCreateInput"] | Variable<any, string>},ValueTypes["User"]],
deleteManyUser?: [{	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneUser?: [{	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>},ValueTypes["User"]],
updateManyUser?: [{	data: ValueTypes["UserUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneUser?: [{	data: ValueTypes["UserUpdateInput"] | Variable<any, string>,	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>},ValueTypes["User"]],
upsertOneUser?: [{	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["UserCreateInput"] | Variable<any, string>,	update: ValueTypes["UserUpdateInput"] | Variable<any, string>},ValueTypes["User"]],
createManyUserPosition?: [{	data: Array<ValueTypes["UserPositionCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneUserPosition?: [{	data: ValueTypes["UserPositionCreateInput"] | Variable<any, string>},ValueTypes["UserPosition"]],
deleteManyUserPosition?: [{	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneUserPosition?: [{	where: ValueTypes["UserPositionWhereUniqueInput"] | Variable<any, string>},ValueTypes["UserPosition"]],
updateManyUserPosition?: [{	data: ValueTypes["UserPositionUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["UserPositionWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneUserPosition?: [{	data: ValueTypes["UserPositionUpdateInput"] | Variable<any, string>,	where: ValueTypes["UserPositionWhereUniqueInput"] | Variable<any, string>},ValueTypes["UserPosition"]],
upsertOneUserPosition?: [{	where: ValueTypes["UserPositionWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["UserPositionCreateInput"] | Variable<any, string>,	update: ValueTypes["UserPositionUpdateInput"] | Variable<any, string>},ValueTypes["UserPosition"]],
createManyWorkspace?: [{	data: Array<ValueTypes["WorkspaceCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneWorkspace?: [{	data: ValueTypes["WorkspaceCreateInput"] | Variable<any, string>},ValueTypes["Workspace"]],
deleteManyWorkspace?: [{	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneWorkspace?: [{	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>},ValueTypes["Workspace"]],
updateManyWorkspace?: [{	data: ValueTypes["WorkspaceUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneWorkspace?: [{	data: ValueTypes["WorkspaceUpdateInput"] | Variable<any, string>,	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>},ValueTypes["Workspace"]],
upsertOneWorkspace?: [{	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["WorkspaceCreateInput"] | Variable<any, string>,	update: ValueTypes["WorkspaceUpdateInput"] | Variable<any, string>},ValueTypes["Workspace"]],
createManyWorkspaceRole?: [{	data: Array<ValueTypes["WorkspaceRoleCreateManyInput"]> | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
createOneWorkspaceRole?: [{	data: ValueTypes["WorkspaceRoleCreateInput"] | Variable<any, string>},ValueTypes["WorkspaceRole"]],
deleteManyWorkspaceRole?: [{	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
deleteOneWorkspaceRole?: [{	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>},ValueTypes["WorkspaceRole"]],
updateManyWorkspaceRole?: [{	data: ValueTypes["WorkspaceRoleUpdateManyMutationInput"] | Variable<any, string>,	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["AffectedRowsOutput"]],
updateOneWorkspaceRole?: [{	data: ValueTypes["WorkspaceRoleUpdateInput"] | Variable<any, string>,	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>},ValueTypes["WorkspaceRole"]],
upsertOneWorkspaceRole?: [{	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>,	create: ValueTypes["WorkspaceRoleCreateInput"] | Variable<any, string>,	update: ValueTypes["WorkspaceRoleUpdateInput"] | Variable<any, string>},ValueTypes["WorkspaceRole"]],
updateUser?: [{	accountDetails: ValueTypes["AccountDetails"] | Variable<any, string>},ValueTypes["User"]],
createCustomUser?: [{	user: ValueTypes["UserInput"] | Variable<any, string>},ValueTypes["User"]],
deleteUserCustom?: [{	email: string | Variable<any, string>},ValueTypes["GenericResponse"]],
sendMail?: [{	to: Array<string> | Variable<any, string>,	templateId: ValueTypes["TemplateIds"] | Variable<any, string>,	bookingId: string | Variable<any, string>},ValueTypes["EmailOutput"]],
createOneCampaignBooking?: [{	data: ValueTypes["CampaignCreateInput"] | Variable<any, string>},ValueTypes["Campaign"]],
createOneAdboardPublicReview?: [{	data: ValueTypes["AdBoardPublicReviewCreateInput"] | Variable<any, string>},ValueTypes["AdBoardPublicReview"]],
refreshSubscription?: [{	workspaceId: string | Variable<any, string>},ValueTypes["TypedGenericResponse"]],
cancelSubscription?: [{	subscriptionId: string | Variable<any, string>},ValueTypes["TypedGenericResponse"]],
updateBookingStatus?: [{	comment?: string | undefined | null | Variable<any, string>,	status: ValueTypes["BookingStatus"] | Variable<any, string>,	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>},ValueTypes["Booking"]],
updateBookingAsset?: [{	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,	assetId: string | Variable<any, string>},boolean | `@${string}`],
prepareBooking?: [{	bookingRequest: ValueTypes["BookingRequest"] | Variable<any, string>},ValueTypes["Booking"]],
cancelBooking?: [{	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>},ValueTypes["Booking"]],
deployBooking?: [{	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>},ValueTypes["Booking"]],
updateDraft?: [{	data: ValueTypes["AdBoardUpdateInput"] | Variable<any, string>,	adBoardId: string | Variable<any, string>},ValueTypes["AdBoard"]],
deleteDraft?: [{	draftId: string | Variable<any, string>},boolean | `@${string}`],
addReviewComment?: [{	action: ValueTypes["ReviewAction"] | Variable<any, string>,	comment: string | Variable<any, string>,	adBoardId: string | Variable<any, string>},ValueTypes["AdBoardReviewComment"]],
updateAdBoardStatus?: [{	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,	id: string | Variable<any, string>},ValueTypes["AdBoard"]],
publishApprovedDraft?: [{	comment: string | Variable<any, string>,	draftId: string | Variable<any, string>},ValueTypes["AdBoard"]],
addPlayersToAdboard?: [{	adboardId: string | Variable<any, string>,	playerIds: Array<string> | Variable<any, string>},ValueTypes["AdBoard"]],
createOneAdboardWithGroup?: [{	data: ValueTypes["AdBoardCreateInput"] | Variable<any, string>},ValueTypes["AdBoard"]],
deleteOneAdboardWithGroup?: [{	adBoardId: string | Variable<any, string>},ValueTypes["AdBoard"]],
updateOneScheduler?: [{	data: ValueTypes["AdBoardGroupUpdateInput"] | Variable<any, string>,	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>},ValueTypes["AdBoardGroup"]],
setupOnePlayer?: [{	playerId: string | Variable<any, string>},ValueTypes["Player"]],
updatePlayerCode?: [{	cpuSerialNumber: string | Variable<any, string>,	playerId: string | Variable<any, string>},ValueTypes["Player"]],
addPlaylistToPlayer?: [{	playlistId: string | Variable<any, string>,	playerId: string | Variable<any, string>},ValueTypes["Player"]],
deletePlaylistInPlayer?: [{	index: number | Variable<any, string>,	playerId: string | Variable<any, string>},ValueTypes["Player"]],
		__typename?: boolean | `@${string}`
}>;
	["AffectedRowsOutput"]: AliasType<{
	count?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationCategoryId?: string | undefined | null | Variable<any, string>,
	locationSubCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: string | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	categoryId?: string | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	offlineBookingContactId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	archivedByUserId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["MapCreateInput"]: {
	lat: number | Variable<any, string>,
	lng: number | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>
};
	["SpecificationCreateInput"]: {
	name: string | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>
};
	["ResolutionCreateInput"]: {
	height: number | Variable<any, string>,
	width: number | Variable<any, string>
};
	["ScreenSizeCreateInput"]: {
	height: number | Variable<any, string>,
	unit: ValueTypes["MeasurementUnit"] | Variable<any, string>,
	width: number | Variable<any, string>
};
	["PlaybackTimeCreateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	startTime: string | Variable<any, string>,
	endTime: string | Variable<any, string>
};
	["WebhookCreateInput"]: {
	url: string | Variable<any, string>,
	headers?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	body?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	method: string | Variable<any, string>
};
	["AdBoardCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ValueTypes["LocationCategoryCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["LocationCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"]: {
	create?: Array<ValueTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["LocationSubCategoryCreateWithoutLocationCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutLocationSubCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutLocationSubCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ValueTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardTypeWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"]: {
	where: ValueTypes["AdBoardTypeWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | Variable<any, string>
};
	["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ValueTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutRestrictedBusinesInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutRestrictedBusinesInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ValueTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["LocationSubCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"]: {
	create?: ValueTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["LocationCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryCreateWithoutLocationSubCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutLocationCategoryInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutLocationCategoryInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyLocationCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutLocationCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"]: {
	create?: Array<ValueTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutCategoryInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["BookingDetailsCreateInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	name: string | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>
};
	["OrderSelectedDateCreateInput"]: {
	endDate: ValueTypes["DateTime"] | Variable<any, string>,
	startDate: ValueTypes["DateTime"] | Variable<any, string>
};
	["BookingPaymentCreateInput"]: {
	liveMode?: boolean | undefined | null | Variable<any, string>,
	checkoutSessionId?: string | undefined | null | Variable<any, string>,
	customer?: ValueTypes["BookingCustomerCreateInput"] | undefined | null | Variable<any, string>,
	paymentGateway: ValueTypes["PaymentGatewayName"] | Variable<any, string>,
	paymentId: string | Variable<any, string>,
	sessionId?: string | undefined | null | Variable<any, string>,
	totalDetails?: ValueTypes["PaymentTotalDetailsCreateInput"] | undefined | null | Variable<any, string>,
	razorpay?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["BookingCustomerCreateInput"]: {
	customerId: string | Variable<any, string>,
	customerDetails?: ValueTypes["PaymentCustomerDetailsCreateInput"] | undefined | null | Variable<any, string>
};
	["PaymentCustomerDetailsCreateInput"]: {
	email: string | Variable<any, string>,
	name: string | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>
};
	["PaymentTotalDetailsCreateInput"]: {
	discount: number | Variable<any, string>,
	shipping: number | Variable<any, string>,
	subtotal: number | Variable<any, string>,
	tax: number | Variable<any, string>,
	total: number | Variable<any, string>
};
	["PriceSummaryCreateInput"]: {
	days: number | Variable<any, string>,
	discount?: number | undefined | null | Variable<any, string>,
	payableRent: number | Variable<any, string>,
	rentPerDay: number | Variable<any, string>,
	serviceCharge: number | Variable<any, string>,
	totalRent: number | Variable<any, string>
};
	["BookingPisignageDataCreateInput"]: {
	deployedFileName?: string | undefined | null | Variable<any, string>,
	playlistId?: string | undefined | null | Variable<any, string>
};
	["BookingRefundCreateInput"]: {
	id: string | Variable<any, string>,
	razorpay?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdByUserId: string | Variable<any, string>
};
	["FileCreateNestedManyWithoutBookingsInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["FileCreateWithoutBookingsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>,
	updatedBy?: ValueTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	createdBy: ValueTypes["UserCreateNestedOneWithoutCreatedFilesInput"] | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>
};
	["FileMetaCreateInput"]: {
	duration: number | Variable<any, string>,
	height: number | Variable<any, string>,
	width: number | Variable<any, string>
};
	["UserCreateNestedOneWithoutUpdatedFilesInput"]: {
	create?: ValueTypes["UserCreateWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutUpdatedFilesInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["UserStripeCreateInput"]: {
	customerId: string | Variable<any, string>
};
	["NotificationPreferenceCreateInput"]: {
	type?: Array<ValueTypes["NotificationType"]> | undefined | null | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>,
	pushSetting?: ValueTypes["PushSettingCreateInput"] | undefined | null | Variable<any, string>,
	enabled?: boolean | undefined | null | Variable<any, string>
};
	["PushSettingCreateInput"]: {
	fcmToken: string | Variable<any, string>
};
	["BookingCreateNestedManyWithoutUserInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateNestedOneWithoutBookingsInput"]: {
	create?: ValueTypes["BusinessCategoryCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateWithoutBookingsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null | Variable<any, string>
};
	["CampaignCreateNestedManyWithoutBusinessCategoryInput"]: {
	create?: Array<ValueTypes["CampaignCreateWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CampaignCreateManyBusinessCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CampaignCreateWithoutBusinessCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>
};
	["UserCreateNestedOneWithoutCampaignsInput"]: {
	create?: ValueTypes["UserCreateWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutCampaignsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["FileCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["FileCreateManyCreatedByInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["FileCreateWithoutCreatedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>,
	updatedBy?: ValueTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedManyWithoutAssetsInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutAssetsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"]: {
	create?: Array<ValueTypes["AdvertisementCategoryCreateWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryCreateWithoutBookingsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	type: ValueTypes["AdvertisementCategoryEnum"] | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutAdCategoryInput"] | undefined | null | Variable<any, string>
};
	["CampaignCreateNestedManyWithoutAdCategoryInput"]: {
	create?: Array<ValueTypes["CampaignCreateWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CampaignCreateOrConnectWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CampaignCreateWithoutAdCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	businessCategory: ValueTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateNestedOneWithoutCampaignsInput"]: {
	create?: ValueTypes["BusinessCategoryCreateWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateWithoutCampaignsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedManyWithoutBusinessCategoryInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyBusinessCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutBusinessCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["FileCreateNestedManyWithoutProofBookingInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutProofBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutProofBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["FileCreateManyProofBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["FileCreateWithoutProofBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>,
	updatedBy?: ValueTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	createdBy: ValueTypes["UserCreateNestedOneWithoutCreatedFilesInput"] | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>
};
	["UserCreateNestedOneWithoutCreatedFilesInput"]: {
	create?: ValueTypes["UserCreateWithoutCreatedFilesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutCreatedFilesInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutCreatedFilesInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["FileCreateNestedManyWithoutUpdatedByInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutUpdatedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutUpdatedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["FileCreateManyUpdatedByInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["FileCreateWithoutUpdatedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>,
	createdBy: ValueTypes["UserCreateNestedOneWithoutCreatedFilesInput"] | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutFilesInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutFilesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutFilesInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutFilesInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["UserCreateNestedOneWithoutOwnedWorkspacesInput"]: {
	create?: ValueTypes["UserCreateWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutOwnedWorkspacesInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["OrderCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ValueTypes["OrderCreateWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["OrderCreateOrConnectWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["OrderCreateManyCreatedByInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["OrderCreateWithoutCreatedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	origin: string | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary: ValueTypes["PriceSummaryCreateInput"] | Variable<any, string>,
	selectedDate: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutOrdersInput"] | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutOrdersInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutOrdersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ValueTypes["SlotConfigurationCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["SlotConfigurationCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	availableSlots: number | Variable<any, string>
};
	["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]: {
	where: ValueTypes["SlotConfigurationWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["SlotConfigurationCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["SlotConfigurationCreateManyAdBoardInputEnvelope"]: {
	data: Array<ValueTypes["SlotConfigurationCreateManyAdBoardInput"]> | Variable<any, string>
};
	["SlotConfigurationCreateManyAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	availableSlots: number | Variable<any, string>
};
	["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"]: {
	create?: ValueTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutOfflineBookingAdboardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["ChangelogCreateNestedManyWithoutUserInput"]: {
	create?: Array<ValueTypes["ChangelogCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["ChangelogCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["ChangelogCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["ChangelogCreateWithoutUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingCreateNestedOneWithoutChangelogsInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedOneWithoutChangelogsInput"]: {
	create?: ValueTypes["BookingCreateWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BookingCreateOrConnectWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutChangelogsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["UserCreateNestedOneWithoutBookingsInput"]: {
	create?: ValueTypes["UserCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutBookingsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyCreatedByInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutCreatedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["OrderCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ValueTypes["OrderCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["OrderCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["OrderCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["OrderCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	origin: string | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary: ValueTypes["PriceSummaryCreateInput"] | Variable<any, string>,
	selectedDate: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutOrdersInput"] | undefined | null | Variable<any, string>
};
	["UserCreateNestedOneWithoutOrdersInput"]: {
	create?: ValueTypes["UserCreateWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutOrdersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["AuditCreateNestedManyWithoutUserInput"]: {
	create?: Array<ValueTypes["AuditCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AuditCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AuditCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AuditCreateWithoutUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutAuditsInput"] | undefined | null | Variable<any, string>
};
	["EntityCreateInput"]: {
	type: string | Variable<any, string>,
	id: string | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutAuditsInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutAuditsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["MembershipCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["MembershipCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["MembershipCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["MembershipCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["MembershipCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>,
	role: ValueTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>
};
	["UserCreateNestedOneWithoutMembershipsInput"]: {
	create?: ValueTypes["UserCreateWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutMembershipsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["CampaignCreateNestedManyWithoutUserInput"]: {
	create?: Array<ValueTypes["CampaignCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CampaignCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CampaignCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CampaignCreateWithoutUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	businessCategory: ValueTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"]: {
	create?: Array<ValueTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryCreateWithoutCampaignsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	type: ValueTypes["AdvertisementCategoryEnum"] | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdCategoryInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedManyWithoutAdCategoryInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutAdCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutBookingsInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutBookingsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["UserCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ValueTypes["UserCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedManyWithoutSharedUsersInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutSharedUsersInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutSharedUsersInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutSharedUsersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["ChangelogCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ValueTypes["ChangelogCreateWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["ChangelogCreateOrConnectWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["ChangelogCreateManyBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["ChangelogCreateWithoutBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutChangelogsInput"] | Variable<any, string>
};
	["UserCreateNestedOneWithoutChangelogsInput"]: {
	create?: ValueTypes["UserCreateWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutChangelogsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["MembershipCreateNestedManyWithoutUserInput"]: {
	create?: Array<ValueTypes["MembershipCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["MembershipCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["MembershipCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["MembershipCreateWithoutUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>,
	role: ValueTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutMembershipsInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutMembershipsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["PlayerCreateNestedManyWithoutAdBoardsInput"]: {
	create?: Array<ValueTypes["PlayerCreateWithoutAdBoardsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlayerCreateOrConnectWithoutAdBoardsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PlayerCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>
};
	["GalleryFileCreateInput"]: {
	name: string | Variable<any, string>,
	url: string | Variable<any, string>,
	contentType: string | Variable<any, string>,
	width: number | Variable<any, string>,
	height: number | Variable<any, string>,
	duration?: number | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"]: {
	create?: ValueTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateWithoutPisignagePlayersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["ScheduleCreateInput"]: {
	playlistId: string | Variable<any, string>,
	playlistType?: string | undefined | null | Variable<any, string>,
	skipForSchedule?: boolean | undefined | null | Variable<any, string>,
	startTime?: string | undefined | null | Variable<any, string>,
	endTime?: string | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutAdBoardGroupInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutAdBoardGroupInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["PlaylistCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlaylistCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PlaylistCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null | Variable<any, string>
};
	["PisignageFileCreateInput"]: {
	name: string | Variable<any, string>,
	duration?: number | undefined | null | Variable<any, string>,
	muteAudio?: boolean | undefined | null | Variable<any, string>,
	isVideo?: boolean | undefined | null | Variable<any, string>
};
	["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"]: {
	create?: ValueTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateWithoutFooterParentPlaylistsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"] | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"]: {
	create?: ValueTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateWithoutSidebarParentPlaylistsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"] | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"]: {
	create?: Array<ValueTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateWithoutAdsPlaylistInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"]: {
	create?: Array<ValueTypes["PlaylistCreateWithoutAdBoardGroupsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PlaylistCreateWithoutAdBoardGroupsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"] | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutPlaylistsInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutPlaylistsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutPlaylistsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["AdBoardGroupCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"]: {
	create?: ValueTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateWithoutAdBoardGroupsForAdsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"] | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"]: {
	create?: Array<ValueTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["PlayerCreateNestedManyWithoutAdBoardGroupInput"]: {
	create?: Array<ValueTypes["PlayerCreateWithoutAdBoardGroupInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlayerCreateManyAdBoardGroupInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PlayerCreateWithoutAdBoardGroupInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutPlayersInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutPlayersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["AuditCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["AuditCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AuditCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AuditCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AuditCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutAuditsInput"] | Variable<any, string>
};
	["UserCreateNestedOneWithoutAuditsInput"]: {
	create?: ValueTypes["UserCreateWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutAuditsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["NotificationCreateNestedManyWithoutRecipientInput"]: {
	create?: Array<ValueTypes["NotificationCreateWithoutRecipientInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["NotificationCreateOrConnectWithoutRecipientInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["NotificationCreateManyRecipientInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["NotificationWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["NotificationCreateWithoutRecipientInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["NotificationType"] | Variable<any, string>,
	title: string | Variable<any, string>,
	content: string | Variable<any, string>,
	metadata?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>
};
	["NotificationCreateOrConnectWithoutRecipientInput"]: {
	where: ValueTypes["NotificationWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["NotificationCreateWithoutRecipientInput"] | Variable<any, string>
};
	["NotificationCreateManyRecipientInputEnvelope"]: {
	data: Array<ValueTypes["NotificationCreateManyRecipientInput"]> | Variable<any, string>
};
	["NotificationCreateManyRecipientInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["NotificationType"] | Variable<any, string>,
	title: string | Variable<any, string>,
	content: string | Variable<any, string>,
	metadata?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"]: {
	create?: Array<ValueTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryCreateWithoutPublishedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	changesSnapshot: ValueTypes["JSON"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutHistoryInput"] | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutHistoryInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutHistoryInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutHistoryInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutHistoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateNestedOneWithoutAdBoardInput"]: {
	create?: ValueTypes["AdBoardGroupCreateWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutAdBoardGroupsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["CampaignCreateNestedOneWithoutBookingsInput"]: {
	create?: ValueTypes["CampaignCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["CampaignCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["CampaignCreateWithoutBookingsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	businessCategory: ValueTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>
};
	["PaymentCreateNestedManyWithoutCampaignInput"]: {
	create?: Array<ValueTypes["PaymentCreateWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PaymentCreateOrConnectWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PaymentCreateManyCampaignInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PaymentWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PaymentCreateWithoutCampaignInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	paymentIntentId: string | Variable<any, string>,
	checkoutSessionId: string | Variable<any, string>,
	eventId: string | Variable<any, string>,
	liveMode?: boolean | undefined | null | Variable<any, string>,
	paymentGateway: ValueTypes["PaymentGatewayName"] | Variable<any, string>,
	subtotal: number | Variable<any, string>,
	total: number | Variable<any, string>,
	tax: number | Variable<any, string>,
	discount: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["PaymentCreateOrConnectWithoutCampaignInput"]: {
	where: ValueTypes["PaymentWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PaymentCreateWithoutCampaignInput"] | Variable<any, string>
};
	["PaymentCreateManyCampaignInputEnvelope"]: {
	data: Array<ValueTypes["PaymentCreateManyCampaignInput"]> | Variable<any, string>
};
	["PaymentCreateManyCampaignInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	paymentIntentId: string | Variable<any, string>,
	checkoutSessionId: string | Variable<any, string>,
	eventId: string | Variable<any, string>,
	liveMode?: boolean | undefined | null | Variable<any, string>,
	paymentGateway: ValueTypes["PaymentGatewayName"] | Variable<any, string>,
	subtotal: number | Variable<any, string>,
	total: number | Variable<any, string>,
	tax: number | Variable<any, string>,
	discount: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["FileCreateNestedManyWithoutCampaignsInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["FileCreateWithoutCampaignsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>,
	updatedBy?: ValueTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	createdBy: ValueTypes["UserCreateNestedOneWithoutCreatedFilesInput"] | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedOneWithoutProofsInput"]: {
	create?: ValueTypes["BookingCreateWithoutProofsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BookingCreateOrConnectWithoutProofsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutProofsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutBookingsInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutBookingsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["FileCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["FileCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["FileCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>,
	updatedBy?: ValueTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	createdBy: ValueTypes["UserCreateNestedOneWithoutCreatedFilesInput"] | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>
};
	["CampaignCreateNestedManyWithoutAssetsInput"]: {
	create?: Array<ValueTypes["CampaignCreateWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CampaignCreateOrConnectWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CampaignCreateWithoutAssetsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	businessCategory: ValueTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateNestedManyWithoutCampaignInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyCampaignInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutCampaignInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["PayoutCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ValueTypes["PayoutCreateWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PayoutCreateOrConnectWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PayoutCreateManyBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PayoutCreateWithoutBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	totalAmount: number | Variable<any, string>,
	commissionRate: number | Variable<any, string>,
	commissionAmount: number | Variable<any, string>,
	netAmount: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status: ValueTypes["PayoutStatus"] | Variable<any, string>,
	transaction?: ValueTypes["TransactionCreateNestedOneWithoutPayoutsInput"] | undefined | null | Variable<any, string>
};
	["TransactionCreateNestedOneWithoutPayoutsInput"]: {
	create?: ValueTypes["TransactionCreateWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["TransactionCreateOrConnectWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["TransactionWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["TransactionCreateWithoutPayoutsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	amount: number | Variable<any, string>,
	createAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	relatedEntityId: string | Variable<any, string>,
	transactionStatus: ValueTypes["TransactionStatus"] | Variable<any, string>,
	details?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutTransactionsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutTransactionsInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutTransactionsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutTransactionsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["PlayerCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["PlayerCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlayerCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlayerCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PlayerCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"]: {
	create?: Array<ValueTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusCreateWithoutPlayerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber: string | Variable<any, string>,
	date: string | Variable<any, string>,
	uptime: number | Variable<any, string>
};
	["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]: {
	where: ValueTypes["PlayerUptimeStatusWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlayerUptimeStatusCreateWithoutPlayerInput"] | Variable<any, string>
};
	["PlayerUptimeStatusCreateManyPlayerInputEnvelope"]: {
	data: Array<ValueTypes["PlayerUptimeStatusCreateManyPlayerInput"]> | Variable<any, string>
};
	["PlayerUptimeStatusCreateManyPlayerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber: string | Variable<any, string>,
	date: string | Variable<any, string>,
	uptime: number | Variable<any, string>
};
	["CameraCreateNestedManyWithoutPlayerInput"]: {
	create?: Array<ValueTypes["CameraCreateWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraCreateOrConnectWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraCreateManyPlayerInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CameraCreateWithoutPlayerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	cameraServer: ValueTypes["CameraServerCreateNestedOneWithoutCamerasInput"] | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined | null | Variable<any, string>
};
	["CameraServerCreateNestedOneWithoutCamerasInput"]: {
	create?: ValueTypes["CameraServerCreateWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["CameraServerCreateOrConnectWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["CameraServerCreateWithoutCamerasInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId: string | Variable<any, string>,
	publicIp: string | Variable<any, string>,
	privateIp: string | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutCameraServersInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutCameraServersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutCameraServersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["TransactionCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["TransactionCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["TransactionCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["TransactionCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["TransactionWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["TransactionCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	amount: number | Variable<any, string>,
	createAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	relatedEntityId: string | Variable<any, string>,
	transactionStatus: ValueTypes["TransactionStatus"] | Variable<any, string>,
	details?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutTransactionInput"] | undefined | null | Variable<any, string>
};
	["PayoutCreateNestedManyWithoutTransactionInput"]: {
	create?: Array<ValueTypes["PayoutCreateWithoutTransactionInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PayoutCreateOrConnectWithoutTransactionInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PayoutCreateManyTransactionInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PayoutCreateWithoutTransactionInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	totalAmount: number | Variable<any, string>,
	commissionRate: number | Variable<any, string>,
	commissionAmount: number | Variable<any, string>,
	netAmount: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status: ValueTypes["PayoutStatus"] | Variable<any, string>,
	booking: ValueTypes["BookingCreateNestedOneWithoutPayoutsInput"] | Variable<any, string>
};
	["BookingCreateNestedOneWithoutPayoutsInput"]: {
	create?: ValueTypes["BookingCreateWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BookingCreateOrConnectWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutPayoutsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["UserCreateNestedManyWithoutSharedBookingsInput"]: {
	create?: Array<ValueTypes["UserCreateWithoutSharedBookingsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["UserCreateOrConnectWithoutSharedBookingsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["UserCreateWithoutSharedBookingsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutOfflineBookingsContactInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["CameraCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ValueTypes["CameraCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CameraCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	cameraServer: ValueTypes["CameraServerCreateNestedOneWithoutCamerasInput"] | Variable<any, string>,
	player?: ValueTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined | null | Variable<any, string>
};
	["PlayerCreateNestedOneWithoutCamerasInput"]: {
	create?: ValueTypes["PlayerCreateWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["PlayerCreateOrConnectWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["PlayerCreateWithoutCamerasInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>
};
	["DigitalSignageCreateNestedOneWithoutPlayersInput"]: {
	create?: ValueTypes["DigitalSignageCreateWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["DigitalSignageWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["DigitalSignageCreateWithoutPlayersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["SignageType"] | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["DigitalSignageCreateOrConnectWithoutPlayersInput"]: {
	where: ValueTypes["DigitalSignageWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["DigitalSignageCreateWithoutPlayersInput"] | Variable<any, string>
};
	["DigitalSignageWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["DigitalSignageWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["DigitalSignageWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["DigitalSignageWhereInput"]> | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumSignageTypeFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerListRelationFilter"] | undefined | null | Variable<any, string>
};
	["AudienceCreateNestedManyWithoutPlayersInput"]: {
	create?: Array<ValueTypes["AudienceCreateWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AudienceCreateOrConnectWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AudienceWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AudienceCreateWithoutPlayersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["AudienceType"] | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["AudienceCreateOrConnectWithoutPlayersInput"]: {
	where: ValueTypes["AudienceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AudienceCreateWithoutPlayersInput"] | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutPlayersInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutPlayersInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutDraftAdboardInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutDraftAdboardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ValueTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	changesSnapshot: ValueTypes["JSON"] | Variable<any, string>,
	publishedBy: ValueTypes["UserCreateNestedOneWithoutPublishAdboardVersionInput"] | Variable<any, string>
};
	["UserCreateNestedOneWithoutPublishAdboardVersionInput"]: {
	create?: ValueTypes["UserCreateWithoutPublishAdboardVersionInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutPublishAdboardVersionInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentCreateNestedManyWithoutUserInput"]: {
	create?: Array<ValueTypes["AdBoardReviewCommentCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentCreateWithoutUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	comment: string | Variable<any, string>,
	action: ValueTypes["ReviewAction"] | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutReviewCommentsInput"] | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutReviewCommentsInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutReviewCommentsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutPublishedVersionInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutPublishedVersionInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ValueTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	comment: string | Variable<any, string>,
	action: ValueTypes["ReviewAction"] | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutReviewCommentsInput"] | Variable<any, string>
};
	["UserCreateNestedOneWithoutReviewCommentsInput"]: {
	create?: ValueTypes["UserCreateWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutReviewCommentsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutArchivedByInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutArchivedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutArchivedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyArchivedByInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutArchivedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ValueTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	rating: number | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutPublicReviewsInput"] | Variable<any, string>
};
	["UserCreateNestedOneWithoutPublicReviewsInput"]: {
	create?: ValueTypes["UserCreateWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutPublicReviewsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedManyWithoutOwnerInput"]: {
	create?: Array<ValueTypes["WorkspaceCreateWithoutOwnerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WorkspaceCreateOrConnectWithoutOwnerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["WorkspaceCreateManyOwnerInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutOwnerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"]: {
	create?: ValueTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["SubscriptionWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionCreateWithoutActiveWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	packageId?: string | undefined | null | Variable<any, string>,
	packageName?: string | undefined | null | Variable<any, string>,
	priceLookupKey?: string | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: string | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: string | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsCreateInput"] | undefined | null | Variable<any, string>
};
	["CancellationDetailsCreateInput"]: {
	canceledAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	cancelAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	cancelAtPeriodEnd: boolean | Variable<any, string>,
	reason?: string | undefined | null | Variable<any, string>
};
	["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"]: {
	where: ValueTypes["SubscriptionWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | Variable<any, string>
};
	["CameraServerCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["CameraServerCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraServerCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraServerWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CameraServerCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId: string | Variable<any, string>,
	publicIp: string | Variable<any, string>,
	privateIp: string | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined | null | Variable<any, string>
};
	["CameraCreateNestedManyWithoutCameraServerInput"]: {
	create?: Array<ValueTypes["CameraCreateWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraCreateOrConnectWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraCreateManyCameraServerInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CameraCreateWithoutCameraServerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined | null | Variable<any, string>
};
	["CameraFeedCreateNestedManyWithoutCameraInput"]: {
	create?: Array<ValueTypes["CameraFeedCreateWithoutCameraInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraFeedCreateOrConnectWithoutCameraInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraFeedCreateManyCameraInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CameraFeedCreateWithoutCameraInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	data: ValueTypes["CameraFeedDataCreateInput"] | Variable<any, string>,
	cameraServer: ValueTypes["CameraServerCreateNestedOneWithoutFeedsInput"] | Variable<any, string>
};
	["CameraFeedDataCreateInput"]: {
	person?: number | undefined | null | Variable<any, string>,
	car?: number | undefined | null | Variable<any, string>,
	truck?: number | undefined | null | Variable<any, string>,
	motorcycle?: number | undefined | null | Variable<any, string>
};
	["CameraServerCreateNestedOneWithoutFeedsInput"]: {
	create?: ValueTypes["CameraServerCreateWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["CameraServerCreateOrConnectWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["CameraServerCreateWithoutFeedsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId: string | Variable<any, string>,
	publicIp: string | Variable<any, string>,
	privateIp: string | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined | null | Variable<any, string>
};
	["CameraServerCreateOrConnectWithoutFeedsInput"]: {
	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraServerCreateWithoutFeedsInput"] | Variable<any, string>
};
	["CameraFeedCreateOrConnectWithoutCameraInput"]: {
	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraFeedCreateWithoutCameraInput"] | Variable<any, string>
};
	["CameraFeedCreateManyCameraInputEnvelope"]: {
	data: Array<ValueTypes["CameraFeedCreateManyCameraInput"]> | Variable<any, string>
};
	["CameraFeedCreateManyCameraInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	cameraServerId: string | Variable<any, string>,
	data: ValueTypes["CameraFeedDataCreateInput"] | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutCamerasInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutCamerasInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["UserCreateNestedOneWithoutArchivedAdboardsInput"]: {
	create?: ValueTypes["UserCreateWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutArchivedAdboardsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedManyWithoutLeadsInput"]: {
	create?: Array<ValueTypes["WorkspaceCreateWithoutLeadsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WorkspaceCreateOrConnectWithoutLeadsInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutLeadsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["WorkspaceRoleCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutRoleInput"] | undefined | null | Variable<any, string>
};
	["MembershipCreateNestedManyWithoutRoleInput"]: {
	create?: Array<ValueTypes["MembershipCreateWithoutRoleInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["MembershipCreateOrConnectWithoutRoleInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["MembershipCreateManyRoleInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["MembershipCreateWithoutRoleInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>
};
	["MembershipCreateOrConnectWithoutRoleInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["MembershipCreateWithoutRoleInput"] | Variable<any, string>
};
	["MembershipCreateManyRoleInputEnvelope"]: {
	data: Array<ValueTypes["MembershipCreateManyRoleInput"]> | Variable<any, string>
};
	["MembershipCreateManyRoleInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	workspaceId: string | Variable<any, string>
};
	["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceRoleCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["WorkspaceRoleCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["WorkspaceRoleCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["WorkspaceRoleCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["WebhookLogCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ValueTypes["WebhookLogCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["WebhookLogCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["WebhookLogCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	response?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	status: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	booking: ValueTypes["BookingCreateNestedOneWithoutWebhookLogsInput"] | Variable<any, string>
};
	["BookingCreateNestedOneWithoutWebhookLogsInput"]: {
	create?: ValueTypes["BookingCreateWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BookingCreateOrConnectWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateWithoutWebhookLogsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["SlotUsageCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ValueTypes["SlotUsageCreateWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["SlotUsageCreateOrConnectWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["SlotUsageCreateManyBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["SlotUsageWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["SlotUsageCreateWithoutBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	slotUsed?: number | undefined | null | Variable<any, string>
};
	["SlotUsageCreateOrConnectWithoutBookingInput"]: {
	where: ValueTypes["SlotUsageWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["SlotUsageCreateWithoutBookingInput"] | Variable<any, string>
};
	["SlotUsageCreateManyBookingInputEnvelope"]: {
	data: Array<ValueTypes["SlotUsageCreateManyBookingInput"]> | Variable<any, string>
};
	["SlotUsageCreateManyBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	slotUsed?: number | undefined | null | Variable<any, string>
};
	["BookingCreateOrConnectWithoutWebhookLogsInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutWebhookLogsInput"] | Variable<any, string>
};
	["WebhookLogCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["WebhookLogWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WebhookLogCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["WebhookLogCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["WebhookLogCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["WebhookLogCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	bookingId: string | Variable<any, string>,
	response?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	status: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutLeadsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutLeadsInput"] | Variable<any, string>
};
	["AdBoardPublicReviewCreateNestedManyWithoutUserInput"]: {
	create?: Array<ValueTypes["AdBoardPublicReviewCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewCreateWithoutUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	rating: number | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"] | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutPublicReviewsInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutPublicReviewsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutPublicReviewsInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutPublicReviewsInput"] | Variable<any, string>
};
	["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]: {
	where: ValueTypes["AdBoardPublicReviewWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardPublicReviewCreateWithoutUserInput"] | Variable<any, string>
};
	["AdBoardPublicReviewCreateManyUserInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardPublicReviewCreateManyUserInput"]> | Variable<any, string>
};
	["AdBoardPublicReviewCreateManyUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	rating: number | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["UserPositionCreateNestedManyWithoutUserInput"]: {
	create?: Array<ValueTypes["UserPositionCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["UserPositionCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["UserPositionCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["UserPositionWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["UserPositionCreateWithoutUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	coords: ValueTypes["UserPositionCoordsCreateInput"] | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationCreateInput"] | undefined | null | Variable<any, string>
};
	["UserPositionCoordsCreateInput"]: {
	accuracy?: number | undefined | null | Variable<any, string>,
	altitude?: number | undefined | null | Variable<any, string>,
	altitudeAccuracy?: number | undefined | null | Variable<any, string>,
	heading?: number | undefined | null | Variable<any, string>,
	latitude: number | Variable<any, string>,
	longitude: number | Variable<any, string>,
	speed?: number | undefined | null | Variable<any, string>
};
	["UserPositionLocationCreateInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>
};
	["UserPositionCreateOrConnectWithoutUserInput"]: {
	where: ValueTypes["UserPositionWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserPositionCreateWithoutUserInput"] | Variable<any, string>
};
	["UserPositionCreateManyUserInputEnvelope"]: {
	data: Array<ValueTypes["UserPositionCreateManyUserInput"]> | Variable<any, string>
};
	["UserPositionCreateManyUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	coords: ValueTypes["UserPositionCoordsCreateInput"] | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationCreateInput"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutArchivedAdboardsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutArchivedAdboardsInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutCamerasInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutCamerasInput"] | Variable<any, string>
};
	["CameraCreateOrConnectWithoutCameraServerInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraCreateWithoutCameraServerInput"] | Variable<any, string>
};
	["CameraCreateManyCameraServerInputEnvelope"]: {
	data: Array<ValueTypes["CameraCreateManyCameraServerInput"]> | Variable<any, string>
};
	["CameraCreateManyCameraServerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	playerId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	adBoardId?: string | undefined | null | Variable<any, string>
};
	["CameraFeedCreateNestedManyWithoutCameraServerInput"]: {
	create?: Array<ValueTypes["CameraFeedCreateWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraFeedCreateManyCameraServerInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["CameraFeedCreateWithoutCameraServerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	data: ValueTypes["CameraFeedDataCreateInput"] | Variable<any, string>,
	camera: ValueTypes["CameraCreateNestedOneWithoutFeedsInput"] | Variable<any, string>
};
	["CameraCreateNestedOneWithoutFeedsInput"]: {
	create?: ValueTypes["CameraCreateWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["CameraCreateOrConnectWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["CameraCreateWithoutFeedsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	cameraServer: ValueTypes["CameraServerCreateNestedOneWithoutCamerasInput"] | Variable<any, string>,
	player?: ValueTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined | null | Variable<any, string>
};
	["CameraCreateOrConnectWithoutFeedsInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraCreateWithoutFeedsInput"] | Variable<any, string>
};
	["CameraFeedCreateOrConnectWithoutCameraServerInput"]: {
	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraFeedCreateWithoutCameraServerInput"] | Variable<any, string>
};
	["CameraFeedCreateManyCameraServerInputEnvelope"]: {
	data: Array<ValueTypes["CameraFeedCreateManyCameraServerInput"]> | Variable<any, string>
};
	["CameraFeedCreateManyCameraServerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	cameraId: string | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	data: ValueTypes["CameraFeedDataCreateInput"] | Variable<any, string>
};
	["CameraServerCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraServerCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["CameraServerCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["CameraServerCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["CameraServerCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId: string | Variable<any, string>,
	publicIp: string | Variable<any, string>,
	privateIp: string | Variable<any, string>
};
	["UserCreateNestedManyWithoutLeadWorspacesInput"]: {
	create?: Array<ValueTypes["UserCreateWithoutLeadWorspacesInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["UserCreateOrConnectWithoutLeadWorspacesInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["UserCreateWithoutLeadWorspacesInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutLeadWorspacesInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutLeadWorspacesInput"] | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutOwnerInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutOwnerInput"] | Variable<any, string>
};
	["WorkspaceCreateManyOwnerInputEnvelope"]: {
	data: Array<ValueTypes["WorkspaceCreateManyOwnerInput"]> | Variable<any, string>
};
	["WorkspaceCreateManyOwnerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	leadIds?: Array<string> | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutPublicReviewsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutPublicReviewsInput"] | Variable<any, string>
};
	["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardPublicReviewWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardPublicReviewCreateManyAdBoardInput"]> | Variable<any, string>
};
	["AdBoardPublicReviewCreateManyAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	rating: number | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutArchivedByInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutArchivedByInput"] | Variable<any, string>
};
	["AdBoardCreateManyArchivedByInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardCreateManyArchivedByInput"]> | Variable<any, string>
};
	["AdBoardCreateManyArchivedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationCategoryId?: string | undefined | null | Variable<any, string>,
	locationSubCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: string | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	categoryId?: string | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	offlineBookingContactId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutReviewCommentsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutReviewCommentsInput"] | Variable<any, string>
};
	["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardReviewCommentWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardReviewCommentCreateManyAdBoardInput"]> | Variable<any, string>
};
	["AdBoardReviewCommentCreateManyAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	comment: string | Variable<any, string>,
	action: ValueTypes["ReviewAction"] | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutPublishedVersionInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutPublishedVersionInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutReviewCommentsInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutReviewCommentsInput"] | Variable<any, string>
};
	["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]: {
	where: ValueTypes["AdBoardReviewCommentWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardReviewCommentCreateWithoutUserInput"] | Variable<any, string>
};
	["AdBoardReviewCommentCreateManyUserInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardReviewCommentCreateManyUserInput"]> | Variable<any, string>
};
	["AdBoardReviewCommentCreateManyUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	comment: string | Variable<any, string>,
	action: ValueTypes["ReviewAction"] | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutPublishAdboardVersionInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutPublishAdboardVersionInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardPublishHistoryCreateManyAdBoardInput"]> | Variable<any, string>
};
	["AdBoardPublishHistoryCreateManyAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	publishedByUserId: string | Variable<any, string>,
	changesSnapshot: ValueTypes["JSON"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutDraftAdboardInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutDraftAdboardInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutPlayersInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutPlayersInput"] | Variable<any, string>
};
	["PlayerCreateOrConnectWithoutCamerasInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlayerCreateWithoutCamerasInput"] | Variable<any, string>
};
	["CameraCreateOrConnectWithoutAdBoardInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["CameraCreateManyAdBoardInputEnvelope"]: {
	data: Array<ValueTypes["CameraCreateManyAdBoardInput"]> | Variable<any, string>
};
	["CameraCreateManyAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraServerId: string | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	playerId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutOfflineBookingsContactInput"] | Variable<any, string>
};
	["AdBoardCreateManyOfflineBookingsContactInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardCreateManyOfflineBookingsContactInput"]> | Variable<any, string>
};
	["AdBoardCreateManyOfflineBookingsContactInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationCategoryId?: string | undefined | null | Variable<any, string>,
	locationSubCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: string | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	categoryId?: string | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	archivedByUserId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutSharedBookingsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutSharedBookingsInput"] | Variable<any, string>
};
	["WebhookLogCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ValueTypes["WebhookLogCreateWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WebhookLogCreateOrConnectWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["WebhookLogCreateManyBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["WebhookLogCreateWithoutBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	response?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	status: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutWebhookLogsInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutWebhookLogsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutWebhookLogsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutWebhookLogsInput"] | Variable<any, string>
};
	["WebhookLogCreateOrConnectWithoutBookingInput"]: {
	where: ValueTypes["WebhookLogWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WebhookLogCreateWithoutBookingInput"] | Variable<any, string>
};
	["WebhookLogCreateManyBookingInputEnvelope"]: {
	data: Array<ValueTypes["WebhookLogCreateManyBookingInput"]> | Variable<any, string>
};
	["WebhookLogCreateManyBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	response?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	status: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>
};
	["BookingCreateOrConnectWithoutPayoutsInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutPayoutsInput"] | Variable<any, string>
};
	["PayoutCreateOrConnectWithoutTransactionInput"]: {
	where: ValueTypes["PayoutWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PayoutCreateWithoutTransactionInput"] | Variable<any, string>
};
	["PayoutCreateManyTransactionInputEnvelope"]: {
	data: Array<ValueTypes["PayoutCreateManyTransactionInput"]> | Variable<any, string>
};
	["PayoutCreateManyTransactionInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	totalAmount: number | Variable<any, string>,
	commissionRate: number | Variable<any, string>,
	commissionAmount: number | Variable<any, string>,
	netAmount: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingId: string | Variable<any, string>,
	status: ValueTypes["PayoutStatus"] | Variable<any, string>
};
	["TransactionCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["TransactionWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["TransactionCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["TransactionCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["TransactionCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["TransactionCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	amount: number | Variable<any, string>,
	createAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	relatedEntityId: string | Variable<any, string>,
	transactionStatus: ValueTypes["TransactionStatus"] | Variable<any, string>,
	details?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutCameraServersInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutCameraServersInput"] | Variable<any, string>
};
	["CameraServerCreateOrConnectWithoutCamerasInput"]: {
	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraServerCreateWithoutCamerasInput"] | Variable<any, string>
};
	["CameraCreateOrConnectWithoutPlayerInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CameraCreateWithoutPlayerInput"] | Variable<any, string>
};
	["CameraCreateManyPlayerInputEnvelope"]: {
	data: Array<ValueTypes["CameraCreateManyPlayerInput"]> | Variable<any, string>
};
	["CameraCreateManyPlayerInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraServerId: string | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	adBoardId?: string | undefined | null | Variable<any, string>
};
	["PlayerCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlayerCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["PlayerCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["PlayerCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["PlayerCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	adBoardGroupId?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	signageTypeId?: string | undefined | null | Variable<any, string>,
	audienceTypeId?: Array<string> | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardIds?: Array<string> | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutTransactionsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutTransactionsInput"] | Variable<any, string>
};
	["TransactionCreateOrConnectWithoutPayoutsInput"]: {
	where: ValueTypes["TransactionWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["TransactionCreateWithoutPayoutsInput"] | Variable<any, string>
};
	["PayoutCreateOrConnectWithoutBookingInput"]: {
	where: ValueTypes["PayoutWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PayoutCreateWithoutBookingInput"] | Variable<any, string>
};
	["PayoutCreateManyBookingInputEnvelope"]: {
	data: Array<ValueTypes["PayoutCreateManyBookingInput"]> | Variable<any, string>
};
	["PayoutCreateManyBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	totalAmount: number | Variable<any, string>,
	commissionRate: number | Variable<any, string>,
	commissionAmount: number | Variable<any, string>,
	netAmount: number | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status: ValueTypes["PayoutStatus"] | Variable<any, string>,
	transactionId?: string | undefined | null | Variable<any, string>
};
	["BookingCreateOrConnectWithoutCampaignInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutCampaignInput"] | Variable<any, string>
};
	["BookingCreateManyCampaignInputEnvelope"]: {
	data: Array<ValueTypes["BookingCreateManyCampaignInput"]> | Variable<any, string>
};
	["BookingCreateManyCampaignInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	businessCategoryId?: string | undefined | null | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	sharedUserIds?: Array<string> | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>
};
	["CampaignCreateOrConnectWithoutAssetsInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutAssetsInput"] | Variable<any, string>
};
	["FileCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["FileCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["FileCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["FileCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedByUserId?: string | undefined | null | Variable<any, string>,
	createdByUserId: string | Variable<any, string>,
	url: string | Variable<any, string>,
	bookingsIDs?: Array<string> | undefined | null | Variable<any, string>,
	proofBookingId?: string | undefined | null | Variable<any, string>,
	campaignIds?: Array<string> | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutBookingsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingCreateOrConnectWithoutProofsInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutProofsInput"] | Variable<any, string>
};
	["FileCreateOrConnectWithoutCampaignsInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutCampaignsInput"] | Variable<any, string>
};
	["CampaignCreateOrConnectWithoutBookingsInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["BookingCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["BookingCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["BookingCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	campaignId?: string | undefined | null | Variable<any, string>,
	businessCategoryId?: string | undefined | null | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	sharedUserIds?: Array<string> | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["AdBoardGroupCreateOrConnectWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutHistoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutHistoryInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]: {
	where: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardPublishHistoryCreateManyPublishedByInput"]> | Variable<any, string>
};
	["AdBoardPublishHistoryCreateManyPublishedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	changesSnapshot: ValueTypes["JSON"] | Variable<any, string>
};
	["UserCreateOrConnectWithoutAuditsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutAuditsInput"] | Variable<any, string>
};
	["AuditCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AuditCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AuditCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["AuditCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["AuditCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutPlayersInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutPlayersInput"] | Variable<any, string>
};
	["PlayerCreateOrConnectWithoutAdBoardGroupInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlayerCreateWithoutAdBoardGroupInput"] | Variable<any, string>
};
	["PlayerCreateManyAdBoardGroupInputEnvelope"]: {
	data: Array<ValueTypes["PlayerCreateManyAdBoardGroupInput"]> | Variable<any, string>
};
	["PlayerCreateManyAdBoardGroupInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	workpaceId?: string | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	signageTypeId?: string | undefined | null | Variable<any, string>,
	audienceTypeId?: Array<string> | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardIds?: Array<string> | undefined | null | Variable<any, string>
};
	["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"] | Variable<any, string>
};
	["PlaylistCreateNestedManyWithoutFooterPlaylistInput"]: {
	create?: Array<ValueTypes["PlaylistCreateWithoutFooterPlaylistInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PlaylistCreateWithoutFooterPlaylistInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"] | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"]: {
	create?: Array<ValueTypes["PlaylistCreateWithoutSidebarPlaylistInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PlaylistCreateWithoutSidebarPlaylistInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"] | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutSidebarPlaylistInput"] | Variable<any, string>
};
	["PlaylistCreateManySidebarPlaylistInputEnvelope"]: {
	data: Array<ValueTypes["PlaylistCreateManySidebarPlaylistInput"]> | Variable<any, string>
};
	["PlaylistCreateManySidebarPlaylistInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	footerPlaylistId?: string | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	adBoardGroupIds?: Array<string> | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>
};
	["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutFooterPlaylistInput"] | Variable<any, string>
};
	["PlaylistCreateManyFooterPlaylistInputEnvelope"]: {
	data: Array<ValueTypes["PlaylistCreateManyFooterPlaylistInput"]> | Variable<any, string>
};
	["PlaylistCreateManyFooterPlaylistInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: string | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	adBoardGroupIds?: Array<string> | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>
};
	["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | Variable<any, string>
};
	["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AdBoardGroupCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardGroupCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["AdBoardGroupCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	adBoardId?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: Array<string> | undefined | null | Variable<any, string>,
	adsPlaylistID?: string | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutPlaylistsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutPlaylistsInput"] | Variable<any, string>
};
	["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"] | Variable<any, string>
};
	["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardGroupCreateManyAdsPlaylistInput"]> | Variable<any, string>
};
	["AdBoardGroupCreateManyAdsPlaylistInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	adBoardId?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: Array<string> | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>
};
	["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | Variable<any, string>
};
	["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | Variable<any, string>
};
	["PlaylistCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["PlaylistCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["PlaylistCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["PlaylistCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	footerPlaylistId?: string | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: string | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	adBoardGroupIds?: Array<string> | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutAdBoardsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutAdBoardGroupInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutAdBoardGroupInput"] | Variable<any, string>
};
	["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | Variable<any, string>
};
	["PlayerCreateOrConnectWithoutAdBoardsInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["PlayerCreateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AdBoardCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["AdBoardCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationCategoryId?: string | undefined | null | Variable<any, string>,
	locationSubCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: string | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	categoryId?: string | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	offlineBookingContactId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	archivedByUserId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutMembershipsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutMembershipsInput"] | Variable<any, string>
};
	["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]: {
	create?: ValueTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleCreateWithoutMembershipsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutWorkspaceRolesInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | Variable<any, string>
};
	["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"]: {
	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | Variable<any, string>
};
	["MembershipCreateOrConnectWithoutUserInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["MembershipCreateWithoutUserInput"] | Variable<any, string>
};
	["MembershipCreateManyUserInputEnvelope"]: {
	data: Array<ValueTypes["MembershipCreateManyUserInput"]> | Variable<any, string>
};
	["MembershipCreateManyUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	roleId: string | Variable<any, string>
};
	["UserCreateOrConnectWithoutChangelogsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutChangelogsInput"] | Variable<any, string>
};
	["ChangelogCreateOrConnectWithoutBookingInput"]: {
	where: ValueTypes["ChangelogWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["ChangelogCreateWithoutBookingInput"] | Variable<any, string>
};
	["ChangelogCreateManyBookingInputEnvelope"]: {
	data: Array<ValueTypes["ChangelogCreateManyBookingInput"]> | Variable<any, string>
};
	["ChangelogCreateManyBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	userId: string | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["BookingCreateOrConnectWithoutSharedUsersInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutSharedUsersInput"] | Variable<any, string>
};
	["UserCreateOrConnectWithoutAdBoardsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutBookingsInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingCreateOrConnectWithoutAdCategoryInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutAdCategoryInput"] | Variable<any, string>
};
	["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]: {
	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdvertisementCategoryCreateWithoutCampaignsInput"] | Variable<any, string>
};
	["CampaignCreateOrConnectWithoutUserInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutUserInput"] | Variable<any, string>
};
	["CampaignCreateManyUserInputEnvelope"]: {
	data: Array<ValueTypes["CampaignCreateManyUserInput"]> | Variable<any, string>
};
	["CampaignCreateManyUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	businessCategoryId: string | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutMembershipsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutMembershipsInput"] | Variable<any, string>
};
	["MembershipCreateOrConnectWithoutWorkspaceInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["MembershipCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["MembershipCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ValueTypes["MembershipCreateManyWorkspaceInput"]> | Variable<any, string>
};
	["MembershipCreateManyWorkspaceInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	roleId: string | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutAuditsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutAuditsInput"] | Variable<any, string>
};
	["AuditCreateOrConnectWithoutUserInput"]: {
	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AuditCreateWithoutUserInput"] | Variable<any, string>
};
	["AuditCreateManyUserInputEnvelope"]: {
	data: Array<ValueTypes["AuditCreateManyUserInput"]> | Variable<any, string>
};
	["AuditCreateManyUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutOrdersInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutOrdersInput"] | Variable<any, string>
};
	["OrderCreateOrConnectWithoutAdBoardInput"]: {
	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["OrderCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["OrderCreateManyAdBoardInputEnvelope"]: {
	data: Array<ValueTypes["OrderCreateManyAdBoardInput"]> | Variable<any, string>
};
	["OrderCreateManyAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	origin: string | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary: ValueTypes["PriceSummaryCreateInput"] | Variable<any, string>,
	selectedDate: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutCreatedByInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutCreatedByInput"] | Variable<any, string>
};
	["AdBoardCreateManyCreatedByInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardCreateManyCreatedByInput"]> | Variable<any, string>
};
	["AdBoardCreateManyCreatedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationCategoryId?: string | undefined | null | Variable<any, string>,
	locationSubCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: string | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	categoryId?: string | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	offlineBookingContactId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	archivedByUserId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutBookingsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingCreateOrConnectWithoutChangelogsInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutChangelogsInput"] | Variable<any, string>
};
	["ChangelogCreateOrConnectWithoutUserInput"]: {
	where: ValueTypes["ChangelogWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["ChangelogCreateWithoutUserInput"] | Variable<any, string>
};
	["ChangelogCreateManyUserInputEnvelope"]: {
	data: Array<ValueTypes["ChangelogCreateManyUserInput"]> | Variable<any, string>
};
	["ChangelogCreateManyUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	bookingId?: string | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutOrdersInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutOrdersInput"] | Variable<any, string>
};
	["OrderCreateOrConnectWithoutCreatedByInput"]: {
	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["OrderCreateWithoutCreatedByInput"] | Variable<any, string>
};
	["OrderCreateManyCreatedByInputEnvelope"]: {
	data: Array<ValueTypes["OrderCreateManyCreatedByInput"]> | Variable<any, string>
};
	["OrderCreateManyCreatedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	origin: string | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary: ValueTypes["PriceSummaryCreateInput"] | Variable<any, string>,
	selectedDate: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>
};
	["UserCreateOrConnectWithoutOwnedWorkspacesInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutOwnedWorkspacesInput"] | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutFilesInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutFilesInput"] | Variable<any, string>
};
	["FileCreateOrConnectWithoutUpdatedByInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutUpdatedByInput"] | Variable<any, string>
};
	["FileCreateManyUpdatedByInputEnvelope"]: {
	data: Array<ValueTypes["FileCreateManyUpdatedByInput"]> | Variable<any, string>
};
	["FileCreateManyUpdatedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId: string | Variable<any, string>,
	url: string | Variable<any, string>,
	bookingsIDs?: Array<string> | undefined | null | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	proofBookingId?: string | undefined | null | Variable<any, string>,
	campaignIds?: Array<string> | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutCreatedFilesInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutCreatedFilesInput"] | Variable<any, string>
};
	["FileCreateOrConnectWithoutProofBookingInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutProofBookingInput"] | Variable<any, string>
};
	["FileCreateManyProofBookingInputEnvelope"]: {
	data: Array<ValueTypes["FileCreateManyProofBookingInput"]> | Variable<any, string>
};
	["FileCreateManyProofBookingInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedByUserId?: string | undefined | null | Variable<any, string>,
	createdByUserId: string | Variable<any, string>,
	url: string | Variable<any, string>,
	bookingsIDs?: Array<string> | undefined | null | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	campaignIds?: Array<string> | undefined | null | Variable<any, string>
};
	["BookingCreateOrConnectWithoutBusinessCategoryInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutBusinessCategoryInput"] | Variable<any, string>
};
	["BookingCreateManyBusinessCategoryInputEnvelope"]: {
	data: Array<ValueTypes["BookingCreateManyBusinessCategoryInput"]> | Variable<any, string>
};
	["BookingCreateManyBusinessCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	campaignId?: string | undefined | null | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	sharedUserIds?: Array<string> | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateOrConnectWithoutCampaignsInput"]: {
	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BusinessCategoryCreateWithoutCampaignsInput"] | Variable<any, string>
};
	["CampaignCreateOrConnectWithoutAdCategoryInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutAdCategoryInput"] | Variable<any, string>
};
	["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]: {
	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdvertisementCategoryCreateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingCreateOrConnectWithoutAssetsInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutAssetsInput"] | Variable<any, string>
};
	["FileCreateOrConnectWithoutCreatedByInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutCreatedByInput"] | Variable<any, string>
};
	["FileCreateManyCreatedByInputEnvelope"]: {
	data: Array<ValueTypes["FileCreateManyCreatedByInput"]> | Variable<any, string>
};
	["FileCreateManyCreatedByInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedByUserId?: string | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>,
	bookingsIDs?: Array<string> | undefined | null | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	proofBookingId?: string | undefined | null | Variable<any, string>,
	campaignIds?: Array<string> | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutCampaignsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutCampaignsInput"] | Variable<any, string>
};
	["CampaignCreateOrConnectWithoutBusinessCategoryInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutBusinessCategoryInput"] | Variable<any, string>
};
	["CampaignCreateManyBusinessCategoryInputEnvelope"]: {
	data: Array<ValueTypes["CampaignCreateManyBusinessCategoryInput"]> | Variable<any, string>
};
	["CampaignCreateManyBusinessCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	userId: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateOrConnectWithoutBookingsInput"]: {
	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BusinessCategoryCreateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingCreateOrConnectWithoutUserInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutUserInput"] | Variable<any, string>
};
	["BookingCreateManyUserInputEnvelope"]: {
	data: Array<ValueTypes["BookingCreateManyUserInput"]> | Variable<any, string>
};
	["BookingCreateManyUserInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	campaignId?: string | undefined | null | Variable<any, string>,
	businessCategoryId?: string | undefined | null | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	sharedUserIds?: Array<string> | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutUpdatedFilesInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutUpdatedFilesInput"] | Variable<any, string>
};
	["FileCreateOrConnectWithoutBookingsInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingCreateOrConnectWithoutAdBoardInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["BookingCreateManyAdBoardInputEnvelope"]: {
	data: Array<ValueTypes["BookingCreateManyAdBoardInput"]> | Variable<any, string>
};
	["BookingCreateManyAdBoardInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	campaignId?: string | undefined | null | Variable<any, string>,
	businessCategoryId?: string | undefined | null | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	sharedUserIds?: Array<string> | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutCategoryInput"] | Variable<any, string>
};
	["AdBoardCreateManyCategoryInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardCreateManyCategoryInput"]> | Variable<any, string>
};
	["AdBoardCreateManyCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationCategoryId?: string | undefined | null | Variable<any, string>,
	locationSubCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: string | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	offlineBookingContactId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	archivedByUserId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]: {
	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutLocationCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutLocationCategoryInput"] | Variable<any, string>
};
	["AdBoardCreateManyLocationCategoryInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardCreateManyLocationCategoryInput"]> | Variable<any, string>
};
	["AdBoardCreateManyLocationCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationSubCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: string | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	categoryId?: string | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	offlineBookingContactId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	archivedByUserId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"]: {
	where: ValueTypes["LocationCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | Variable<any, string>
};
	["LocationCategoryWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["LocationCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["LocationCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["LocationCategoryWhereInput"]> | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardListRelationFilter"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryListRelationFilter"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ValueTypes["LocationSubCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutRestrictedBusinesInput"] | Variable<any, string>
};
	["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutLocationSubCategoryInput"] | Variable<any, string>
};
	["AdBoardCreateManyLocationSubCategoryInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardCreateManyLocationSubCategoryInput"]> | Variable<any, string>
};
	["AdBoardCreateManyLocationSubCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	adBoardTypeId?: string | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	categoryId?: string | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	offlineBookingContactId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	archivedByUserId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]: {
	where: ValueTypes["LocationSubCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"] | Variable<any, string>
};
	["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"]: {
	data: Array<ValueTypes["LocationSubCategoryCreateManyLocationCategoryInput"]> | Variable<any, string>
};
	["LocationSubCategoryCreateManyLocationCategoryInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>
};
	["LocationCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ValueTypes["LocationCategoryWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["LocationCategoryCreateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardUpdateManyMutationInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ValueTypes["LocationCategoryCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["LocationCategoryUpsertWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["LocationCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryUpsertWithoutAdBoardsInput"]: {
	update: ValueTypes["LocationCategoryUpdateWithoutAdBoardsInput"] | Variable<any, string>,
	create: ValueTypes["LocationCategoryCreateWithoutAdBoardsInput"] | Variable<any, string>,
	where?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"]: {
	create?: Array<ValueTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["LocationSubCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ValueTypes["LocationSubCategoryWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"] | Variable<any, string>,
	create: ValueTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"] | Variable<any, string>
};
	["LocationSubCategoryUpdateWithoutLocationCategoryInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutLocationSubCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutLocationSubCategoryInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutLocationSubCategoryInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutLocationSubCategoryInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ValueTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardTypeUpsertWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardTypeWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeUpsertWithoutAdBoardsInput"]: {
	update: ValueTypes["AdBoardTypeUpdateWithoutAdBoardsInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ValueTypes["AdBoardTypeWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardTypeUpdateWithoutAdBoardsInput"] | Variable<any, string>
};
	["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ValueTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["BusinessCategoryUpsertWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpsertWithoutAdBoardsInput"]: {
	update: ValueTypes["BusinessCategoryUpdateWithoutAdBoardsInput"] | Variable<any, string>,
	create: ValueTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | Variable<any, string>,
	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutRestrictedBusinesInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutRestrictedBusinesInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutRestrictedBusinesInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutRestrictedBusinesInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ValueTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["LocationSubCategoryUpsertWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["LocationSubCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryUpsertWithoutAdBoardsInput"]: {
	update: ValueTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"] | Variable<any, string>,
	create: ValueTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | Variable<any, string>,
	where?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"]: {
	create?: ValueTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["LocationCategoryUpsertWithoutLocationSubCategoryInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["LocationCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryUpsertWithoutLocationSubCategoryInput"]: {
	update: ValueTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"] | Variable<any, string>,
	create: ValueTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | Variable<any, string>,
	where?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["LocationCategoryUpdateWithoutLocationSubCategoryInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutLocationCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutLocationCategoryNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyLocationCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutLocationCategoryInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutLocationCategoryInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutLocationCategoryInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"]: {
	create?: Array<ValueTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BusinessCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: {
	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"] | Variable<any, string>,
	create: ValueTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"] | Variable<any, string>
};
	["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutCategoryNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutCategoryInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutCategoryInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutCategoryInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutCategoryInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutCategoryInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BookingUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BookingUpdateWithoutAdBoardInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["BookingUpdateWithoutAdBoardInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["FileUpdateManyWithoutBookingsNestedInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["FileUpsertWithWhereUniqueWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["FileUpdateWithWhereUniqueWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["FileUpdateManyWithWhereWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["FileUpsertWithWhereUniqueWithoutBookingsInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["FileUpdateWithoutBookingsInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutBookingsInput"] | Variable<any, string>
};
	["FileUpdateWithoutBookingsInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	ctime?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory?: boolean | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCreateInput"] | undefined | null | Variable<any, string>,
	mtime?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneWithoutUpdatedFilesNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutUpdatedFilesInput"]: {
	update: ValueTypes["UserUpdateWithoutUpdatedFilesInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutUpdatedFilesInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutUpdatedFilesInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BookingUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BookingUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BookingUpdateManyWithWhereWithoutUserInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BookingUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BookingUpdateWithoutUserInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutUserInput"] | Variable<any, string>
};
	["BookingUpdateWithoutUserInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpdateOneWithoutBookingsNestedInput"]: {
	create?: ValueTypes["BusinessCategoryCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["BusinessCategoryUpsertWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpsertWithoutBookingsInput"]: {
	update: ValueTypes["BusinessCategoryUpdateWithoutBookingsInput"] | Variable<any, string>,
	create: ValueTypes["BusinessCategoryCreateWithoutBookingsInput"] | Variable<any, string>,
	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpdateWithoutBookingsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateManyWithoutBusinessCategoryNestedInput"]: {
	create?: Array<ValueTypes["CampaignCreateWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CampaignCreateManyBusinessCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CampaignScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CampaignUpdateWithoutBusinessCategoryInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutBusinessCategoryInput"] | Variable<any, string>
};
	["CampaignUpdateWithoutBusinessCategoryInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutCampaignsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutCampaignsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutCampaignsInput"]: {
	update: ValueTypes["UserUpdateWithoutCampaignsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutCampaignsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutCampaignsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["FileUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["FileUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["FileCreateManyCreatedByInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["FileUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["FileUpdateManyWithWhereWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["FileUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["FileUpdateWithoutCreatedByInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutCreatedByInput"] | Variable<any, string>
};
	["FileUpdateWithoutCreatedByInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	ctime?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory?: boolean | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCreateInput"] | undefined | null | Variable<any, string>,
	mtime?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyWithoutAssetsNestedInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BookingUpsertWithWhereUniqueWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BookingUpdateWithWhereUniqueWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BookingUpdateManyWithWhereWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BookingUpsertWithWhereUniqueWithoutAssetsInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BookingUpdateWithoutAssetsInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutAssetsInput"] | Variable<any, string>
};
	["BookingUpdateWithoutAssetsInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"]: {
	create?: Array<ValueTypes["AdvertisementCategoryCreateWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]: {
	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdvertisementCategoryUpdateWithoutBookingsInput"] | Variable<any, string>,
	create: ValueTypes["AdvertisementCategoryCreateWithoutBookingsInput"] | Variable<any, string>
};
	["AdvertisementCategoryUpdateWithoutBookingsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdvertisementCategoryEnum"] | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateManyWithoutAdCategoryNestedInput"]: {
	create?: Array<ValueTypes["CampaignCreateWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CampaignCreateOrConnectWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CampaignScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CampaignUpdateWithoutAdCategoryInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutAdCategoryInput"] | Variable<any, string>
};
	["CampaignUpdateWithoutAdCategoryInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"]: {
	create?: ValueTypes["BusinessCategoryCreateWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["BusinessCategoryUpsertWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BusinessCategoryWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpsertWithoutCampaignsInput"]: {
	update: ValueTypes["BusinessCategoryUpdateWithoutCampaignsInput"] | Variable<any, string>,
	create: ValueTypes["BusinessCategoryCreateWithoutCampaignsInput"] | Variable<any, string>,
	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpdateWithoutCampaignsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyWithoutBusinessCategoryNestedInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyBusinessCategoryInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BookingUpdateWithoutBusinessCategoryInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutBusinessCategoryInput"] | Variable<any, string>
};
	["BookingUpdateWithoutBusinessCategoryInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["FileUpdateManyWithoutProofBookingNestedInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutProofBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutProofBookingInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["FileUpsertWithWhereUniqueWithoutProofBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["FileCreateManyProofBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["FileUpdateWithWhereUniqueWithoutProofBookingInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["FileUpdateManyWithWhereWithoutProofBookingInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["FileUpsertWithWhereUniqueWithoutProofBookingInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["FileUpdateWithoutProofBookingInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutProofBookingInput"] | Variable<any, string>
};
	["FileUpdateWithoutProofBookingInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	ctime?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory?: boolean | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCreateInput"] | undefined | null | Variable<any, string>,
	mtime?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutCreatedFilesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutCreatedFilesInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutCreatedFilesInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutCreatedFilesInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutCreatedFilesInput"]: {
	update: ValueTypes["UserUpdateWithoutCreatedFilesInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutCreatedFilesInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutCreatedFilesInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["FileUpdateManyWithoutUpdatedByNestedInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutUpdatedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutUpdatedByInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["FileCreateManyUpdatedByInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["FileUpdateManyWithWhereWithoutUpdatedByInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["FileUpdateWithoutUpdatedByInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutUpdatedByInput"] | Variable<any, string>
};
	["FileUpdateWithoutUpdatedByInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	ctime?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory?: boolean | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCreateInput"] | undefined | null | Variable<any, string>,
	mtime?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneWithoutFilesNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutFilesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutFilesInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutFilesInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutFilesInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutFilesInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutFilesInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutFilesInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutFilesInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneWithoutOwnedWorkspacesNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutOwnedWorkspacesInput"]: {
	update: ValueTypes["UserUpdateWithoutOwnedWorkspacesInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutOwnedWorkspacesInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutOwnedWorkspacesInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["OrderUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ValueTypes["OrderCreateWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["OrderCreateOrConnectWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["OrderCreateManyCreatedByInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["OrderUpdateManyWithWhereWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["OrderScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["OrderUpdateWithoutCreatedByInput"] | Variable<any, string>,
	create: ValueTypes["OrderCreateWithoutCreatedByInput"] | Variable<any, string>
};
	["OrderUpdateWithoutCreatedByInput"]: {
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDate?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutOrdersInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutOrdersInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutOrdersInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutOrdersInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutOrdersInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ValueTypes["SlotConfigurationCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["SlotConfigurationScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["SlotConfigurationWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["SlotConfigurationUpdateWithoutAdBoardInput"] | Variable<any, string>,
	create: ValueTypes["SlotConfigurationCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["SlotConfigurationUpdateWithoutAdBoardInput"]: {
	date?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	availableSlots?: number | undefined | null | Variable<any, string>
};
	["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["SlotConfigurationWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["SlotConfigurationUpdateWithoutAdBoardInput"] | Variable<any, string>
};
	["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ValueTypes["SlotConfigurationScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["SlotConfigurationUpdateManyMutationInput"] | Variable<any, string>
};
	["SlotConfigurationScalarWhereInput"]: {
	AND?: Array<ValueTypes["SlotConfigurationScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SlotConfigurationScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SlotConfigurationScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	availableSlots?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationUpdateManyMutationInput"]: {
	date?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	availableSlots?: number | undefined | null | Variable<any, string>
};
	["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutOfflineBookingAdboardsInput"]: {
	update: ValueTypes["UserUpdateWithoutOfflineBookingAdboardsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutOfflineBookingAdboardsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["ChangelogUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ValueTypes["ChangelogCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["ChangelogCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["ChangelogUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["ChangelogCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["ChangelogUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["ChangelogUpdateManyWithWhereWithoutUserInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["ChangelogScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["ChangelogUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["ChangelogWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["ChangelogUpdateWithoutUserInput"] | Variable<any, string>,
	create: ValueTypes["ChangelogCreateWithoutUserInput"] | Variable<any, string>
};
	["ChangelogUpdateWithoutUserInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	action?: string | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingUpdateOneWithoutChangelogsNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateOneWithoutChangelogsNestedInput"]: {
	create?: ValueTypes["BookingCreateWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BookingCreateOrConnectWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["BookingUpsertWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["BookingUpdateToOneWithWhereWithoutChangelogsInput"] | undefined | null | Variable<any, string>
};
	["BookingUpsertWithoutChangelogsInput"]: {
	update: ValueTypes["BookingUpdateWithoutChangelogsInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutChangelogsInput"] | Variable<any, string>,
	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateWithoutChangelogsInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutBookingsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutBookingsInput"]: {
	update: ValueTypes["UserUpdateWithoutBookingsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutBookingsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutBookingsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyCreatedByInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutCreatedByInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutCreatedByInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutCreatedByInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["OrderUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ValueTypes["OrderCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["OrderCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["OrderCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["OrderWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["OrderUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["OrderScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["OrderUpdateWithoutAdBoardInput"] | Variable<any, string>,
	create: ValueTypes["OrderCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["OrderUpdateWithoutAdBoardInput"]: {
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDate?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutOrdersNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneWithoutOrdersNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutOrdersInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutOrdersInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutOrdersInput"]: {
	update: ValueTypes["UserUpdateWithoutOrdersInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutOrdersInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutOrdersInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["AuditUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ValueTypes["AuditCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AuditCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AuditUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AuditCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AuditUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AuditUpdateManyWithWhereWithoutUserInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AuditScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AuditUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AuditUpdateWithoutUserInput"] | Variable<any, string>,
	create: ValueTypes["AuditCreateWithoutUserInput"] | Variable<any, string>
};
	["AuditUpdateWithoutUserInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	action?: string | undefined | null | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneWithoutAuditsNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutAuditsInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutAuditsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutAuditsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutAuditsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["MembershipUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["MembershipCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["MembershipCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["MembershipCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["MembershipScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["MembershipUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["MembershipCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["MembershipUpdateWithoutWorkspaceInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutMembershipsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutMembershipsInput"]: {
	update: ValueTypes["UserUpdateWithoutMembershipsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutMembershipsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutMembershipsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ValueTypes["CampaignCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CampaignCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CampaignUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CampaignCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CampaignUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CampaignUpdateManyWithWhereWithoutUserInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CampaignScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CampaignUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CampaignUpdateWithoutUserInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutUserInput"] | Variable<any, string>
};
	["CampaignUpdateWithoutUserInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"]: {
	create?: Array<ValueTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]: {
	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"] | Variable<any, string>,
	create: ValueTypes["AdvertisementCategoryCreateWithoutCampaignsInput"] | Variable<any, string>
};
	["AdvertisementCategoryUpdateWithoutCampaignsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdvertisementCategoryEnum"] | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyWithoutAdCategoryNestedInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BookingUpdateManyWithWhereWithoutAdCategoryInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BookingUpdateWithoutAdCategoryInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutAdCategoryInput"] | Variable<any, string>
};
	["BookingUpdateWithoutAdCategoryInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutBookingsInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutBookingsInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutBookingsInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutBookingsInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutAdBoardsInput"]: {
	update: ValueTypes["UserUpdateWithoutAdBoardsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutAdBoardsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutAdBoardsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyWithoutSharedUsersNestedInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutSharedUsersInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutSharedUsersInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BookingUpdateManyWithWhereWithoutSharedUsersInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BookingUpdateWithoutSharedUsersInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutSharedUsersInput"] | Variable<any, string>
};
	["BookingUpdateWithoutSharedUsersInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["ChangelogUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ValueTypes["ChangelogCreateWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["ChangelogCreateOrConnectWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["ChangelogCreateManyBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["ChangelogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["ChangelogUpdateManyWithWhereWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["ChangelogScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ValueTypes["ChangelogWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["ChangelogUpdateWithoutBookingInput"] | Variable<any, string>,
	create: ValueTypes["ChangelogCreateWithoutBookingInput"] | Variable<any, string>
};
	["ChangelogUpdateWithoutBookingInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	action?: string | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutChangelogsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutChangelogsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutChangelogsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutChangelogsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutChangelogsInput"]: {
	update: ValueTypes["UserUpdateWithoutChangelogsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutChangelogsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutChangelogsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["MembershipUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ValueTypes["MembershipCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["MembershipCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["MembershipUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["MembershipCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["MembershipUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["MembershipUpdateManyWithWhereWithoutUserInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["MembershipScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["MembershipUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["MembershipUpdateWithoutUserInput"] | Variable<any, string>,
	create: ValueTypes["MembershipCreateWithoutUserInput"] | Variable<any, string>
};
	["MembershipUpdateWithoutUserInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutMembershipsInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutMembershipsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutMembershipsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutMembershipsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutWorkspaceInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["PlayerUpdateManyWithoutAdBoardsNestedInput"]: {
	create?: Array<ValueTypes["PlayerCreateWithoutAdBoardsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlayerCreateOrConnectWithoutAdBoardsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PlayerScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PlayerUpdateWithoutAdBoardsInput"] | Variable<any, string>,
	create: ValueTypes["PlayerCreateWithoutAdBoardsInput"] | Variable<any, string>
};
	["PlayerUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"]: {
	create?: ValueTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardGroupUpsertWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpsertWithoutPisignagePlayersInput"]: {
	update: ValueTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpdateWithoutPisignagePlayersInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutAdBoardGroupInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutAdBoardGroupInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutAdBoardGroupInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutAdBoardGroupInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutAdBoardsInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutAdBoardsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutAdBoardsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutAdBoardsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["PlaylistCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlaylistCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PlaylistScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PlaylistUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["PlaylistUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"]: {
	create?: ValueTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["PlaylistUpsertWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpsertWithoutFooterParentPlaylistsInput"]: {
	update: ValueTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | Variable<any, string>,
	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateWithoutFooterParentPlaylistsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"]: {
	create?: ValueTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["PlaylistUpsertWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpsertWithoutSidebarParentPlaylistsInput"]: {
	update: ValueTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | Variable<any, string>,
	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateWithoutSidebarParentPlaylistsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"]: {
	create?: Array<ValueTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardGroupScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateWithoutAdsPlaylistInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"]: {
	create?: Array<ValueTypes["PlaylistCreateWithoutAdBoardGroupsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PlaylistScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PlaylistUpdateWithoutAdBoardGroupsInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["PlaylistUpdateWithoutAdBoardGroupsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutPlaylistsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutPlaylistsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutPlaylistsInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutPlaylistsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutPlaylistsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutPlaylistsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["AdBoardGroupCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardGroupScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardGroupUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"]: {
	create?: ValueTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["PlaylistWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"]: {
	update: ValueTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | Variable<any, string>,
	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"]: {
	create?: Array<ValueTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardGroupScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>
};
	["PlayerUpdateManyWithoutAdBoardGroupNestedInput"]: {
	create?: Array<ValueTypes["PlayerCreateWithoutAdBoardGroupInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlayerCreateManyAdBoardGroupInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PlayerScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PlayerUpdateWithoutAdBoardGroupInput"] | Variable<any, string>,
	create: ValueTypes["PlayerCreateWithoutAdBoardGroupInput"] | Variable<any, string>
};
	["PlayerUpdateWithoutAdBoardGroupInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneWithoutPlayersNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutPlayersInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutPlayersInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutPlayersInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutPlayersInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["AuditUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["AuditCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AuditCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AuditCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AuditWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AuditUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AuditScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AuditUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["AuditCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AuditUpdateWithoutWorkspaceInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	action?: string | undefined | null | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutAuditsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutAuditsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutAuditsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutAuditsInput"]: {
	update: ValueTypes["UserUpdateWithoutAuditsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutAuditsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutAuditsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["NotificationUpdateManyWithoutRecipientNestedInput"]: {
	create?: Array<ValueTypes["NotificationCreateWithoutRecipientInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["NotificationCreateOrConnectWithoutRecipientInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["NotificationCreateManyRecipientInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["NotificationWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["NotificationWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["NotificationWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["NotificationWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["NotificationUpdateManyWithWhereWithoutRecipientInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["NotificationScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]: {
	where: ValueTypes["NotificationWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["NotificationUpdateWithoutRecipientInput"] | Variable<any, string>,
	create: ValueTypes["NotificationCreateWithoutRecipientInput"] | Variable<any, string>
};
	["NotificationUpdateWithoutRecipientInput"]: {
	type?: ValueTypes["NotificationType"] | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	content?: string | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>
};
	["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]: {
	where: ValueTypes["NotificationWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["NotificationUpdateWithoutRecipientInput"] | Variable<any, string>
};
	["NotificationUpdateManyWithWhereWithoutRecipientInput"]: {
	where: ValueTypes["NotificationScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["NotificationUpdateManyMutationInput"] | Variable<any, string>
};
	["NotificationScalarWhereInput"]: {
	AND?: Array<ValueTypes["NotificationScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["NotificationScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["NotificationScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumNotificationTypeFilter"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	recipientId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	channels?: ValueTypes["EnumNotificationChannelNullableListFilter"] | undefined | null | Variable<any, string>
};
	["NotificationUpdateManyMutationInput"]: {
	type?: ValueTypes["NotificationType"] | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	content?: string | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"]: {
	create?: Array<ValueTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]: {
	where: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateWithoutPublishedByInput"]: {
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	changesSnapshot?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutHistoryInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutHistoryInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutHistoryInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutHistoryInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutHistoryInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutHistoryInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutHistoryInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutHistoryInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"]: {
	create?: ValueTypes["AdBoardGroupCreateWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardGroupUpsertWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	disconnect?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,
	delete?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardGroupWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpsertWithoutAdBoardInput"]: {
	update: ValueTypes["AdBoardGroupUpdateWithoutAdBoardInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardGroupCreateWithoutAdBoardInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpdateWithoutAdBoardInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutAdBoardGroupsInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutAdBoardGroupsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BookingUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BookingUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["BookingUpdateWithoutWorkspaceInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateOneWithoutBookingsNestedInput"]: {
	create?: ValueTypes["CampaignCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["CampaignCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["CampaignUpsertWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["CampaignWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["CampaignUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null | Variable<any, string>
};
	["CampaignUpsertWithoutBookingsInput"]: {
	update: ValueTypes["CampaignUpdateWithoutBookingsInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutBookingsInput"] | Variable<any, string>,
	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateWithoutBookingsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>
};
	["PaymentUpdateManyWithoutCampaignNestedInput"]: {
	create?: Array<ValueTypes["PaymentCreateWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PaymentCreateOrConnectWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PaymentCreateManyCampaignInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PaymentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PaymentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PaymentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PaymentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PaymentUpdateManyWithWhereWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PaymentScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]: {
	where: ValueTypes["PaymentWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PaymentUpdateWithoutCampaignInput"] | Variable<any, string>,
	create: ValueTypes["PaymentCreateWithoutCampaignInput"] | Variable<any, string>
};
	["PaymentUpdateWithoutCampaignInput"]: {
	paymentIntentId?: string | undefined | null | Variable<any, string>,
	checkoutSessionId?: string | undefined | null | Variable<any, string>,
	eventId?: string | undefined | null | Variable<any, string>,
	liveMode?: boolean | undefined | null | Variable<any, string>,
	paymentGateway?: ValueTypes["PaymentGatewayName"] | undefined | null | Variable<any, string>,
	subtotal?: number | undefined | null | Variable<any, string>,
	total?: number | undefined | null | Variable<any, string>,
	tax?: number | undefined | null | Variable<any, string>,
	discount?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]: {
	where: ValueTypes["PaymentWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PaymentUpdateWithoutCampaignInput"] | Variable<any, string>
};
	["PaymentUpdateManyWithWhereWithoutCampaignInput"]: {
	where: ValueTypes["PaymentScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PaymentUpdateManyMutationInput"] | Variable<any, string>
};
	["PaymentScalarWhereInput"]: {
	AND?: Array<ValueTypes["PaymentScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PaymentScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PaymentScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	paymentIntentId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	checkoutSessionId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	eventId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	liveMode?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	paymentGateway?: ValueTypes["EnumPaymentGatewayNameFilter"] | undefined | null | Variable<any, string>,
	subtotal?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	total?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	tax?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["PaymentUpdateManyMutationInput"]: {
	paymentIntentId?: string | undefined | null | Variable<any, string>,
	checkoutSessionId?: string | undefined | null | Variable<any, string>,
	eventId?: string | undefined | null | Variable<any, string>,
	liveMode?: boolean | undefined | null | Variable<any, string>,
	paymentGateway?: ValueTypes["PaymentGatewayName"] | undefined | null | Variable<any, string>,
	subtotal?: number | undefined | null | Variable<any, string>,
	total?: number | undefined | null | Variable<any, string>,
	tax?: number | undefined | null | Variable<any, string>,
	discount?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["FileUpdateManyWithoutCampaignsNestedInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["FileUpsertWithWhereUniqueWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["FileUpdateWithWhereUniqueWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["FileUpdateManyWithWhereWithoutCampaignsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["FileUpsertWithWhereUniqueWithoutCampaignsInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["FileUpdateWithoutCampaignsInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutCampaignsInput"] | Variable<any, string>
};
	["FileUpdateWithoutCampaignsInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	ctime?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory?: boolean | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCreateInput"] | undefined | null | Variable<any, string>,
	mtime?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateOneWithoutProofsNestedInput"]: {
	create?: ValueTypes["BookingCreateWithoutProofsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BookingCreateOrConnectWithoutProofsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["BookingUpsertWithoutProofsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["BookingUpdateToOneWithWhereWithoutProofsInput"] | undefined | null | Variable<any, string>
};
	["BookingUpsertWithoutProofsInput"]: {
	update: ValueTypes["BookingUpdateWithoutProofsInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutProofsInput"] | Variable<any, string>,
	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateWithoutProofsInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneWithoutBookingsNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutBookingsInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutBookingsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutBookingsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutBookingsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["FileUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["FileCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["FileCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["FileCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["FileWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["FileUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["FileUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["FileCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["FileUpdateWithoutWorkspaceInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	ctime?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory?: boolean | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCreateInput"] | undefined | null | Variable<any, string>,
	mtime?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateManyWithoutAssetsNestedInput"]: {
	create?: Array<ValueTypes["CampaignCreateWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CampaignCreateOrConnectWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CampaignWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CampaignUpdateManyWithWhereWithoutAssetsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CampaignScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CampaignUpdateWithoutAssetsInput"] | Variable<any, string>,
	create: ValueTypes["CampaignCreateWithoutAssetsInput"] | Variable<any, string>
};
	["CampaignUpdateWithoutAssetsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyWithoutCampaignNestedInput"]: {
	create?: Array<ValueTypes["BookingCreateWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["BookingCreateOrConnectWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["BookingUpsertWithWhereUniqueWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["BookingCreateManyCampaignInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["BookingWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["BookingUpdateWithWhereUniqueWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["BookingUpdateManyWithWhereWithoutCampaignInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["BookingUpsertWithWhereUniqueWithoutCampaignInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["BookingUpdateWithoutCampaignInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutCampaignInput"] | Variable<any, string>
};
	["BookingUpdateWithoutCampaignInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["PayoutUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ValueTypes["PayoutCreateWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PayoutCreateOrConnectWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PayoutUpsertWithWhereUniqueWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PayoutCreateManyBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PayoutUpdateWithWhereUniqueWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PayoutUpdateManyWithWhereWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PayoutScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PayoutUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ValueTypes["PayoutWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PayoutUpdateWithoutBookingInput"] | Variable<any, string>,
	create: ValueTypes["PayoutCreateWithoutBookingInput"] | Variable<any, string>
};
	["PayoutUpdateWithoutBookingInput"]: {
	totalAmount?: number | undefined | null | Variable<any, string>,
	commissionRate?: number | undefined | null | Variable<any, string>,
	commissionAmount?: number | undefined | null | Variable<any, string>,
	netAmount?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PayoutStatus"] | undefined | null | Variable<any, string>,
	transaction?: ValueTypes["TransactionUpdateOneWithoutPayoutsNestedInput"] | undefined | null | Variable<any, string>
};
	["TransactionUpdateOneWithoutPayoutsNestedInput"]: {
	create?: ValueTypes["TransactionCreateWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["TransactionCreateOrConnectWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["TransactionUpsertWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["TransactionWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["TransactionUpdateToOneWithWhereWithoutPayoutsInput"] | undefined | null | Variable<any, string>
};
	["TransactionUpsertWithoutPayoutsInput"]: {
	update: ValueTypes["TransactionUpdateWithoutPayoutsInput"] | Variable<any, string>,
	create: ValueTypes["TransactionCreateWithoutPayoutsInput"] | Variable<any, string>,
	where?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>
};
	["TransactionUpdateWithoutPayoutsInput"]: {
	amount?: number | undefined | null | Variable<any, string>,
	createAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	relatedEntityId?: string | undefined | null | Variable<any, string>,
	transactionStatus?: ValueTypes["TransactionStatus"] | undefined | null | Variable<any, string>,
	details?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutTransactionsNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneWithoutTransactionsNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutTransactionsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutTransactionsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutTransactionsInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutTransactionsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutTransactionsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutTransactionsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["PlayerUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["PlayerCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlayerCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlayerCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlayerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PlayerScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PlayerUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["PlayerCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["PlayerUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"]: {
	create?: Array<ValueTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]: {
	where: ValueTypes["PlayerUptimeStatusWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"] | Variable<any, string>,
	create: ValueTypes["PlayerUptimeStatusCreateWithoutPlayerInput"] | Variable<any, string>
};
	["PlayerUptimeStatusUpdateWithoutPlayerInput"]: {
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	date?: string | undefined | null | Variable<any, string>,
	uptime?: number | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]: {
	where: ValueTypes["PlayerUptimeStatusWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"] | Variable<any, string>
};
	["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]: {
	where: ValueTypes["PlayerUptimeStatusScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PlayerUptimeStatusUpdateManyMutationInput"] | Variable<any, string>
};
	["PlayerUptimeStatusScalarWhereInput"]: {
	AND?: Array<ValueTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	uptime?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>
};
	["PlayerUptimeStatusUpdateManyMutationInput"]: {
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	date?: string | undefined | null | Variable<any, string>,
	uptime?: number | undefined | null | Variable<any, string>
};
	["CameraUpdateManyWithoutPlayerNestedInput"]: {
	create?: Array<ValueTypes["CameraCreateWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraCreateOrConnectWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CameraUpsertWithWhereUniqueWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraCreateManyPlayerInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CameraUpdateWithWhereUniqueWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CameraUpdateManyWithWhereWithoutPlayerInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CameraScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CameraUpsertWithWhereUniqueWithoutPlayerInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CameraUpdateWithoutPlayerInput"] | Variable<any, string>,
	create: ValueTypes["CameraCreateWithoutPlayerInput"] | Variable<any, string>
};
	["CameraUpdateWithoutPlayerInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	cameraUrl?: string | undefined | null | Variable<any, string>,
	rtspUrl?: string | undefined | null | Variable<any, string>,
	cameraIp?: string | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"]: {
	create?: ValueTypes["CameraServerCreateWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["CameraServerCreateOrConnectWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["CameraServerUpsertWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["CameraServerUpdateToOneWithWhereWithoutCamerasInput"] | undefined | null | Variable<any, string>
};
	["CameraServerUpsertWithoutCamerasInput"]: {
	update: ValueTypes["CameraServerUpdateWithoutCamerasInput"] | Variable<any, string>,
	create: ValueTypes["CameraServerCreateWithoutCamerasInput"] | Variable<any, string>,
	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>
};
	["CameraServerUpdateWithoutCamerasInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId?: string | undefined | null | Variable<any, string>,
	publicIp?: string | undefined | null | Variable<any, string>,
	privateIp?: string | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneWithoutCameraServersNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutCameraServersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutCameraServersInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutCameraServersInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutCameraServersInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutCameraServersInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutCameraServersInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["TransactionUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["TransactionCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["TransactionCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["TransactionCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["TransactionWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["TransactionWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["TransactionWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["TransactionWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["TransactionScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["TransactionWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["TransactionUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["TransactionCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["TransactionUpdateWithoutWorkspaceInput"]: {
	amount?: number | undefined | null | Variable<any, string>,
	createAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	relatedEntityId?: string | undefined | null | Variable<any, string>,
	transactionStatus?: ValueTypes["TransactionStatus"] | undefined | null | Variable<any, string>,
	details?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutTransactionNestedInput"] | undefined | null | Variable<any, string>
};
	["PayoutUpdateManyWithoutTransactionNestedInput"]: {
	create?: Array<ValueTypes["PayoutCreateWithoutTransactionInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PayoutCreateOrConnectWithoutTransactionInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PayoutCreateManyTransactionInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PayoutWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PayoutUpdateManyWithWhereWithoutTransactionInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PayoutScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]: {
	where: ValueTypes["PayoutWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PayoutUpdateWithoutTransactionInput"] | Variable<any, string>,
	create: ValueTypes["PayoutCreateWithoutTransactionInput"] | Variable<any, string>
};
	["PayoutUpdateWithoutTransactionInput"]: {
	totalAmount?: number | undefined | null | Variable<any, string>,
	commissionRate?: number | undefined | null | Variable<any, string>,
	commissionAmount?: number | undefined | null | Variable<any, string>,
	netAmount?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PayoutStatus"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingUpdateOneRequiredWithoutPayoutsNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateOneRequiredWithoutPayoutsNestedInput"]: {
	create?: ValueTypes["BookingCreateWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BookingCreateOrConnectWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["BookingUpsertWithoutPayoutsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["BookingUpdateToOneWithWhereWithoutPayoutsInput"] | undefined | null | Variable<any, string>
};
	["BookingUpsertWithoutPayoutsInput"]: {
	update: ValueTypes["BookingUpdateWithoutPayoutsInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutPayoutsInput"] | Variable<any, string>,
	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateWithoutPayoutsInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateManyWithoutSharedBookingsNestedInput"]: {
	create?: Array<ValueTypes["UserCreateWithoutSharedBookingsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["UserCreateOrConnectWithoutSharedBookingsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["UserUpdateManyWithWhereWithoutSharedBookingsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["UserScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["UserUpdateWithoutSharedBookingsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutSharedBookingsInput"] | Variable<any, string>
};
	["UserUpdateWithoutSharedBookingsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutOfflineBookingsContactInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutOfflineBookingsContactInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ValueTypes["CameraCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CameraUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CameraScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CameraUpdateWithoutAdBoardInput"] | Variable<any, string>,
	create: ValueTypes["CameraCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["CameraUpdateWithoutAdBoardInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	cameraUrl?: string | undefined | null | Variable<any, string>,
	rtspUrl?: string | undefined | null | Variable<any, string>,
	cameraIp?: string | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined | null | Variable<any, string>
};
	["PlayerUpdateOneWithoutCamerasNestedInput"]: {
	create?: ValueTypes["PlayerCreateWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["PlayerCreateOrConnectWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["PlayerUpsertWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["PlayerWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["PlayerUpdateToOneWithWhereWithoutCamerasInput"] | undefined | null | Variable<any, string>
};
	["PlayerUpsertWithoutCamerasInput"]: {
	update: ValueTypes["PlayerUpdateWithoutCamerasInput"] | Variable<any, string>,
	create: ValueTypes["PlayerCreateWithoutCamerasInput"] | Variable<any, string>,
	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>
};
	["PlayerUpdateWithoutCamerasInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>
};
	["DigitalSignageUpdateOneWithoutPlayersNestedInput"]: {
	create?: ValueTypes["DigitalSignageCreateWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["DigitalSignageUpsertWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["DigitalSignageWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["DigitalSignageWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"] | undefined | null | Variable<any, string>
};
	["DigitalSignageUpsertWithoutPlayersInput"]: {
	update: ValueTypes["DigitalSignageUpdateWithoutPlayersInput"] | Variable<any, string>,
	create: ValueTypes["DigitalSignageCreateWithoutPlayersInput"] | Variable<any, string>,
	where?: ValueTypes["DigitalSignageWhereInput"] | undefined | null | Variable<any, string>
};
	["DigitalSignageUpdateWithoutPlayersInput"]: {
	type?: ValueTypes["SignageType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"]: {
	where?: ValueTypes["DigitalSignageWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["DigitalSignageUpdateWithoutPlayersInput"] | Variable<any, string>
};
	["AudienceUpdateManyWithoutPlayersNestedInput"]: {
	create?: Array<ValueTypes["AudienceCreateWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AudienceCreateOrConnectWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AudienceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AudienceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AudienceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AudienceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AudienceUpdateManyWithWhereWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AudienceScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]: {
	where: ValueTypes["AudienceWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AudienceUpdateWithoutPlayersInput"] | Variable<any, string>,
	create: ValueTypes["AudienceCreateWithoutPlayersInput"] | Variable<any, string>
};
	["AudienceUpdateWithoutPlayersInput"]: {
	type?: ValueTypes["AudienceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]: {
	where: ValueTypes["AudienceWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AudienceUpdateWithoutPlayersInput"] | Variable<any, string>
};
	["AudienceUpdateManyWithWhereWithoutPlayersInput"]: {
	where: ValueTypes["AudienceScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AudienceUpdateManyMutationInput"] | Variable<any, string>
};
	["AudienceScalarWhereInput"]: {
	AND?: Array<ValueTypes["AudienceScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AudienceScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AudienceScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumAudienceTypeFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	playerIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["AudienceUpdateManyMutationInput"]: {
	type?: ValueTypes["AudienceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutPlayersNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutPlayersInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutPlayersInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutPlayersInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutPlayersInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneWithoutDraftAdboardNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutDraftAdboardInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutDraftAdboardInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutDraftAdboardInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutDraftAdboardInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ValueTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateWithoutAdBoardInput"]: {
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	changesSnapshot?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	publishedBy?: ValueTypes["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutPublishAdboardVersionInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutPublishAdboardVersionInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutPublishAdboardVersionInput"]: {
	update: ValueTypes["UserUpdateWithoutPublishAdboardVersionInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutPublishAdboardVersionInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutPublishAdboardVersionInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ValueTypes["AdBoardReviewCommentCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["AdBoardReviewCommentWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardReviewCommentUpdateWithoutUserInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardReviewCommentCreateWithoutUserInput"] | Variable<any, string>
};
	["AdBoardReviewCommentUpdateWithoutUserInput"]: {
	comment?: string | undefined | null | Variable<any, string>,
	action?: ValueTypes["ReviewAction"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutReviewCommentsInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutReviewCommentsInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutReviewCommentsInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutReviewCommentsInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneWithoutPublishedVersionNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	disconnect?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	delete?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutPublishedVersionInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutPublishedVersionInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutPublishedVersionInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutPublishedVersionInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ValueTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardReviewCommentWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardReviewCommentUpdateWithoutAdBoardInput"]: {
	comment?: string | undefined | null | Variable<any, string>,
	action?: ValueTypes["ReviewAction"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutReviewCommentsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutReviewCommentsInput"]: {
	update: ValueTypes["UserUpdateWithoutReviewCommentsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutReviewCommentsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutReviewCommentsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutArchivedByNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutArchivedByInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutArchivedByInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyArchivedByInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutArchivedByInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutArchivedByInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutArchivedByInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ValueTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardPublicReviewWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardPublicReviewUpdateWithoutAdBoardInput"]: {
	rating?: number | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutPublicReviewsInput"]: {
	update: ValueTypes["UserUpdateWithoutPublicReviewsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutPublicReviewsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutPublicReviewsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateManyWithoutOwnerNestedInput"]: {
	create?: Array<ValueTypes["WorkspaceCreateWithoutOwnerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WorkspaceCreateOrConnectWithoutOwnerInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["WorkspaceCreateManyOwnerInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["WorkspaceScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["WorkspaceUpdateWithoutOwnerInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutOwnerInput"] | Variable<any, string>
};
	["WorkspaceUpdateWithoutOwnerInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"]: {
	create?: ValueTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["SubscriptionUpsertWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	disconnect?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,
	delete?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["SubscriptionWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionUpsertWithoutActiveWorkspaceInput"]: {
	update: ValueTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | Variable<any, string>,
	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionUpdateWithoutActiveWorkspaceInput"]: {
	packageId?: string | undefined | null | Variable<any, string>,
	packageName?: string | undefined | null | Variable<any, string>,
	priceLookupKey?: string | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: string | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: string | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsCreateInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"]: {
	where?: ValueTypes["SubscriptionWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"] | Variable<any, string>
};
	["CameraServerUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["CameraServerCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraServerCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CameraServerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CameraServerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CameraServerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraServerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CameraServerScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CameraServerUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["CameraServerCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["CameraServerUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId?: string | undefined | null | Variable<any, string>,
	publicIp?: string | undefined | null | Variable<any, string>,
	privateIp?: string | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraUpdateManyWithoutCameraServerNestedInput"]: {
	create?: Array<ValueTypes["CameraCreateWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraCreateOrConnectWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraCreateManyCameraServerInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CameraUpdateManyWithWhereWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CameraScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CameraUpdateWithoutCameraServerInput"] | Variable<any, string>,
	create: ValueTypes["CameraCreateWithoutCameraServerInput"] | Variable<any, string>
};
	["CameraUpdateWithoutCameraServerInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	cameraUrl?: string | undefined | null | Variable<any, string>,
	rtspUrl?: string | undefined | null | Variable<any, string>,
	cameraIp?: string | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraFeedUpdateManyWithoutCameraNestedInput"]: {
	create?: Array<ValueTypes["CameraFeedCreateWithoutCameraInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraFeedCreateOrConnectWithoutCameraInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraFeedCreateManyCameraInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CameraFeedUpdateManyWithWhereWithoutCameraInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CameraFeedScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]: {
	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CameraFeedUpdateWithoutCameraInput"] | Variable<any, string>,
	create: ValueTypes["CameraFeedCreateWithoutCameraInput"] | Variable<any, string>
};
	["CameraFeedUpdateWithoutCameraInput"]: {
	timestamp?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	data?: ValueTypes["CameraFeedDataCreateInput"] | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"]: {
	create?: ValueTypes["CameraServerCreateWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["CameraServerCreateOrConnectWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["CameraServerUpsertWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["CameraServerWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["CameraServerUpdateToOneWithWhereWithoutFeedsInput"] | undefined | null | Variable<any, string>
};
	["CameraServerUpsertWithoutFeedsInput"]: {
	update: ValueTypes["CameraServerUpdateWithoutFeedsInput"] | Variable<any, string>,
	create: ValueTypes["CameraServerCreateWithoutFeedsInput"] | Variable<any, string>,
	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>
};
	["CameraServerUpdateWithoutFeedsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId?: string | undefined | null | Variable<any, string>,
	publicIp?: string | undefined | null | Variable<any, string>,
	privateIp?: string | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraServerUpdateToOneWithWhereWithoutFeedsInput"]: {
	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["CameraServerUpdateWithoutFeedsInput"] | Variable<any, string>
};
	["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]: {
	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CameraFeedUpdateWithoutCameraInput"] | Variable<any, string>
};
	["CameraFeedUpdateManyWithWhereWithoutCameraInput"]: {
	where: ValueTypes["CameraFeedScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CameraFeedUpdateManyMutationInput"] | Variable<any, string>
};
	["CameraFeedScalarWhereInput"]: {
	AND?: Array<ValueTypes["CameraFeedScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraFeedScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraFeedScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cameraId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["CameraFeedUpdateManyMutationInput"]: {
	timestamp?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	data?: ValueTypes["CameraFeedDataCreateInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneWithoutCamerasNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutCamerasInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutCamerasInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutCamerasInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutCamerasInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutCamerasInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneWithoutArchivedAdboardsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutArchivedAdboardsInput"]: {
	update: ValueTypes["UserUpdateWithoutArchivedAdboardsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutArchivedAdboardsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutArchivedAdboardsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateManyWithoutLeadsNestedInput"]: {
	create?: Array<ValueTypes["WorkspaceCreateWithoutLeadsInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WorkspaceCreateOrConnectWithoutLeadsInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WorkspaceWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["WorkspaceScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["WorkspaceUpdateWithoutLeadsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutLeadsInput"] | Variable<any, string>
};
	["WorkspaceUpdateWithoutLeadsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["WorkspaceRoleScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceRoleCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["WorkspaceRoleUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutRoleNestedInput"] | undefined | null | Variable<any, string>
};
	["MembershipUpdateManyWithoutRoleNestedInput"]: {
	create?: Array<ValueTypes["MembershipCreateWithoutRoleInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["MembershipCreateOrConnectWithoutRoleInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["MembershipUpsertWithWhereUniqueWithoutRoleInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["MembershipCreateManyRoleInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["MembershipWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["MembershipUpdateWithWhereUniqueWithoutRoleInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["MembershipUpdateManyWithWhereWithoutRoleInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["MembershipScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["MembershipUpsertWithWhereUniqueWithoutRoleInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["MembershipUpdateWithoutRoleInput"] | Variable<any, string>,
	create: ValueTypes["MembershipCreateWithoutRoleInput"] | Variable<any, string>
};
	["MembershipUpdateWithoutRoleInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>
};
	["MembershipUpdateWithWhereUniqueWithoutRoleInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["MembershipUpdateWithoutRoleInput"] | Variable<any, string>
};
	["MembershipUpdateManyWithWhereWithoutRoleInput"]: {
	where: ValueTypes["MembershipScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["MembershipUpdateManyMutationInput"] | Variable<any, string>
};
	["MembershipScalarWhereInput"]: {
	AND?: Array<ValueTypes["MembershipScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MembershipScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["MembershipScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	roleId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["MembershipUpdateManyMutationInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["WorkspaceRoleWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["WorkspaceRoleScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["WorkspaceRoleUpdateManyMutationInput"] | Variable<any, string>
};
	["WorkspaceRoleScalarWhereInput"]: {
	AND?: Array<ValueTypes["WorkspaceRoleScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WorkspaceRoleScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WorkspaceRoleScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	permissions?: ValueTypes["EnumPermissionNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["WebhookLogUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ValueTypes["WebhookLogCreateWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["WebhookLogCreateManyWorkspaceInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["WebhookLogScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["WebhookLogWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["WebhookLogUpdateWithoutWorkspaceInput"] | Variable<any, string>,
	create: ValueTypes["WebhookLogCreateWithoutWorkspaceInput"] | Variable<any, string>
};
	["WebhookLogUpdateWithoutWorkspaceInput"]: {
	response?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	status?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	booking?: ValueTypes["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"]: {
	create?: ValueTypes["BookingCreateWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["BookingCreateOrConnectWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["BookingUpsertWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["BookingWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>
};
	["BookingUpsertWithoutWebhookLogsInput"]: {
	update: ValueTypes["BookingUpdateWithoutWebhookLogsInput"] | Variable<any, string>,
	create: ValueTypes["BookingCreateWithoutWebhookLogsInput"] | Variable<any, string>,
	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateWithoutWebhookLogsInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["SlotUsageUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ValueTypes["SlotUsageCreateWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["SlotUsageCreateOrConnectWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["SlotUsageCreateManyBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["SlotUsageWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["SlotUsageWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["SlotUsageWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["SlotUsageWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["SlotUsageUpdateManyWithWhereWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["SlotUsageScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ValueTypes["SlotUsageWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["SlotUsageUpdateWithoutBookingInput"] | Variable<any, string>,
	create: ValueTypes["SlotUsageCreateWithoutBookingInput"] | Variable<any, string>
};
	["SlotUsageUpdateWithoutBookingInput"]: {
	date?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	slotUsed?: number | undefined | null | Variable<any, string>
};
	["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ValueTypes["SlotUsageWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["SlotUsageUpdateWithoutBookingInput"] | Variable<any, string>
};
	["SlotUsageUpdateManyWithWhereWithoutBookingInput"]: {
	where: ValueTypes["SlotUsageScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["SlotUsageUpdateManyMutationInput"] | Variable<any, string>
};
	["SlotUsageScalarWhereInput"]: {
	AND?: Array<ValueTypes["SlotUsageScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SlotUsageScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["SlotUsageScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	date?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	slotUsed?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>
};
	["SlotUsageUpdateManyMutationInput"]: {
	date?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	slotUsed?: number | undefined | null | Variable<any, string>
};
	["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"]: {
	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutWebhookLogsInput"] | Variable<any, string>
};
	["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["WebhookLogWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["WebhookLogUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["WebhookLogScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["WebhookLogUpdateManyMutationInput"] | Variable<any, string>
};
	["WebhookLogScalarWhereInput"]: {
	AND?: Array<ValueTypes["WebhookLogScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WebhookLogScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WebhookLogScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	response?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["WebhookLogUpdateManyMutationInput"]: {
	response?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	status?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutLeadsInput"] | Variable<any, string>
};
	["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]: {
	where: ValueTypes["WorkspaceScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateManyMutationInput"] | Variable<any, string>
};
	["WorkspaceScalarWhereInput"]: {
	AND?: Array<ValueTypes["WorkspaceScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WorkspaceScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WorkspaceScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumWorkspaceTypeFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	ownerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	avatar?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	leadIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateManyMutationInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ValueTypes["AdBoardPublicReviewCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["AdBoardPublicReviewWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardPublicReviewUpdateWithoutUserInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardPublicReviewCreateWithoutUserInput"] | Variable<any, string>
};
	["AdBoardPublicReviewUpdateWithoutUserInput"]: {
	rating?: number | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutPublicReviewsInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutPublicReviewsInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutPublicReviewsInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutPublicReviewsInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutPublicReviewsInput"] | Variable<any, string>
};
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["AdBoardPublicReviewWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardPublicReviewUpdateWithoutUserInput"] | Variable<any, string>
};
	["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]: {
	where: ValueTypes["AdBoardPublicReviewScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardPublicReviewUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardPublicReviewScalarWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	rating?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	comment?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardPublicReviewUpdateManyMutationInput"]: {
	rating?: number | undefined | null | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["UserPositionUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ValueTypes["UserPositionCreateWithoutUserInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["UserPositionCreateOrConnectWithoutUserInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["UserPositionUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["UserPositionCreateManyUserInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["UserPositionWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["UserPositionWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["UserPositionWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["UserPositionWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["UserPositionUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["UserPositionUpdateManyWithWhereWithoutUserInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["UserPositionScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["UserPositionUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["UserPositionWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["UserPositionUpdateWithoutUserInput"] | Variable<any, string>,
	create: ValueTypes["UserPositionCreateWithoutUserInput"] | Variable<any, string>
};
	["UserPositionUpdateWithoutUserInput"]: {
	timestamp?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	coords?: ValueTypes["UserPositionCoordsCreateInput"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationCreateInput"] | undefined | null | Variable<any, string>
};
	["UserPositionUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["UserPositionWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["UserPositionUpdateWithoutUserInput"] | Variable<any, string>
};
	["UserPositionUpdateManyWithWhereWithoutUserInput"]: {
	where: ValueTypes["UserPositionScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["UserPositionUpdateManyMutationInput"] | Variable<any, string>
};
	["UserPositionScalarWhereInput"]: {
	AND?: Array<ValueTypes["UserPositionScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserPositionScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserPositionScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	timestamp?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["UserPositionUpdateManyMutationInput"]: {
	timestamp?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	coords?: ValueTypes["UserPositionCoordsCreateInput"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationCreateInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutArchivedAdboardsInput"] | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutCamerasInput"] | Variable<any, string>
};
	["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CameraUpdateWithoutCameraServerInput"] | Variable<any, string>
};
	["CameraUpdateManyWithWhereWithoutCameraServerInput"]: {
	where: ValueTypes["CameraScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CameraUpdateManyMutationInput"] | Variable<any, string>
};
	["CameraScalarWhereInput"]: {
	AND?: Array<ValueTypes["CameraScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	cameraServerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cameraUrl?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	rtspUrl?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	cameraIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	playerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	isPublic?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["CameraUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	cameraUrl?: string | undefined | null | Variable<any, string>,
	rtspUrl?: string | undefined | null | Variable<any, string>,
	cameraIp?: string | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>
};
	["CameraFeedUpdateManyWithoutCameraServerNestedInput"]: {
	create?: Array<ValueTypes["CameraFeedCreateWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["CameraFeedCreateManyCameraServerInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["CameraFeedWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["CameraFeedScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]: {
	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["CameraFeedUpdateWithoutCameraServerInput"] | Variable<any, string>,
	create: ValueTypes["CameraFeedCreateWithoutCameraServerInput"] | Variable<any, string>
};
	["CameraFeedUpdateWithoutCameraServerInput"]: {
	timestamp?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	data?: ValueTypes["CameraFeedDataCreateInput"] | undefined | null | Variable<any, string>,
	camera?: ValueTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraUpdateOneRequiredWithoutFeedsNestedInput"]: {
	create?: ValueTypes["CameraCreateWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["CameraCreateOrConnectWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["CameraUpsertWithoutFeedsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["CameraWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["CameraUpdateToOneWithWhereWithoutFeedsInput"] | undefined | null | Variable<any, string>
};
	["CameraUpsertWithoutFeedsInput"]: {
	update: ValueTypes["CameraUpdateWithoutFeedsInput"] | Variable<any, string>,
	create: ValueTypes["CameraCreateWithoutFeedsInput"] | Variable<any, string>,
	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>
};
	["CameraUpdateWithoutFeedsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	cameraUrl?: string | undefined | null | Variable<any, string>,
	rtspUrl?: string | undefined | null | Variable<any, string>,
	cameraIp?: string | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraUpdateToOneWithWhereWithoutFeedsInput"]: {
	where?: ValueTypes["CameraWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["CameraUpdateWithoutFeedsInput"] | Variable<any, string>
};
	["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]: {
	where: ValueTypes["CameraFeedWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CameraFeedUpdateWithoutCameraServerInput"] | Variable<any, string>
};
	["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]: {
	where: ValueTypes["CameraFeedScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CameraFeedUpdateManyMutationInput"] | Variable<any, string>
};
	["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["CameraServerWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CameraServerUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["CameraServerScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CameraServerUpdateManyMutationInput"] | Variable<any, string>
};
	["CameraServerScalarWhereInput"]: {
	AND?: Array<ValueTypes["CameraServerScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CameraServerScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CameraServerScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	hostName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	userName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	deviceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	publicIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	privateIp?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["CameraServerUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId?: string | undefined | null | Variable<any, string>,
	publicIp?: string | undefined | null | Variable<any, string>,
	privateIp?: string | undefined | null | Variable<any, string>
};
	["UserUpdateManyWithoutLeadWorspacesNestedInput"]: {
	create?: Array<ValueTypes["UserCreateWithoutLeadWorspacesInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["UserCreateOrConnectWithoutLeadWorspacesInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["UserWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["UserScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["UserUpdateWithoutLeadWorspacesInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutLeadWorspacesInput"] | Variable<any, string>
};
	["UserUpdateWithoutLeadWorspacesInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutLeadWorspacesInput"] | Variable<any, string>
};
	["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]: {
	where: ValueTypes["UserScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["UserUpdateManyMutationInput"] | Variable<any, string>
};
	["UserScalarWhereInput"]: {
	AND?: Array<ValueTypes["UserScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	uid?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["EnumRoleNullableListFilter"] | undefined | null | Variable<any, string>,
	sharedBookingIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	disclaimerAccepted?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	leadWorkspaceIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["UserUpdateManyMutationInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutOwnerInput"] | Variable<any, string>
};
	["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]: {
	where: ValueTypes["WorkspaceScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutPublicReviewsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutPublicReviewsInput"] | Variable<any, string>
};
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardPublicReviewWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardPublicReviewScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardPublicReviewUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutArchivedByInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardScalarWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	state?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	city?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	outdoor?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	locationSubCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	alias?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	pricePerDay?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	originalPricePerDay?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adBoardTypeId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	featuredImage?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	galleryImgs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	notes?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	impressionMultiplier?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	categoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	targetAudiences?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumAdBoardStatusFilter"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["EnumAdboardDraftStatusNullableFilter"] | undefined | null | Variable<any, string>,
	isLive?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	numberOfDisplays?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	dailyOperationHours?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	adsPerLoop?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	adsDuration?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	monthlyPrice?: ValueTypes["FloatNullableFilter"] | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	offlineBookingContactId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	href?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	playerIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	publisherScreenId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>,
	isDraft?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	publishedVersionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	isArchived?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	archivedByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutReviewCommentsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutReviewCommentsInput"] | Variable<any, string>
};
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardReviewCommentWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardReviewCommentScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardReviewCommentUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardReviewCommentScalarWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	comment?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["EnumReviewActionFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardReviewCommentUpdateManyMutationInput"]: {
	comment?: string | undefined | null | Variable<any, string>,
	action?: ValueTypes["ReviewAction"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutPublishedVersionInput"] | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutReviewCommentsInput"] | Variable<any, string>
};
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["AdBoardReviewCommentWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardReviewCommentUpdateWithoutUserInput"] | Variable<any, string>
};
	["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]: {
	where: ValueTypes["AdBoardReviewCommentScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardReviewCommentUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutPublishAdboardVersionInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ValueTypes["AdBoardPublishHistoryScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardPublishHistoryUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryScalarWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	publishedByUserId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	changesSnapshot?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateManyMutationInput"]: {
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	changesSnapshot?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutDraftAdboardInput"] | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutPlayersInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutPlayersInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["PlayerUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ValueTypes["PlayerWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["PlayerUpdateWithoutCamerasInput"] | Variable<any, string>
};
	["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CameraUpdateWithoutAdBoardInput"] | Variable<any, string>
};
	["CameraUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ValueTypes["CameraScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CameraUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutSharedBookingsInput"] | Variable<any, string>
};
	["UserUpdateManyWithWhereWithoutSharedBookingsInput"]: {
	where: ValueTypes["UserScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["UserUpdateManyMutationInput"] | Variable<any, string>
};
	["WebhookLogUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ValueTypes["WebhookLogCreateWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["WebhookLogCreateOrConnectWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["WebhookLogCreateManyBookingInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["WebhookLogWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["WebhookLogUpdateManyWithWhereWithoutBookingInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["WebhookLogScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ValueTypes["WebhookLogWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["WebhookLogUpdateWithoutBookingInput"] | Variable<any, string>,
	create: ValueTypes["WebhookLogCreateWithoutBookingInput"] | Variable<any, string>
};
	["WebhookLogUpdateWithoutBookingInput"]: {
	response?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	request?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	status?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutWebhookLogsInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutWebhookLogsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutWebhookLogsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutWebhookLogsInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutWebhookLogsInput"] | Variable<any, string>
};
	["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ValueTypes["WebhookLogWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["WebhookLogUpdateWithoutBookingInput"] | Variable<any, string>
};
	["WebhookLogUpdateManyWithWhereWithoutBookingInput"]: {
	where: ValueTypes["WebhookLogScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["WebhookLogUpdateManyMutationInput"] | Variable<any, string>
};
	["BookingUpdateToOneWithWhereWithoutPayoutsInput"]: {
	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutPayoutsInput"] | Variable<any, string>
};
	["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]: {
	where: ValueTypes["PayoutWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PayoutUpdateWithoutTransactionInput"] | Variable<any, string>
};
	["PayoutUpdateManyWithWhereWithoutTransactionInput"]: {
	where: ValueTypes["PayoutScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PayoutUpdateManyMutationInput"] | Variable<any, string>
};
	["PayoutScalarWhereInput"]: {
	AND?: Array<ValueTypes["PayoutScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PayoutScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PayoutScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	totalAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	commissionRate?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	commissionAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	netAmount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumPayoutStatusFilter"] | undefined | null | Variable<any, string>,
	transactionId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["PayoutUpdateManyMutationInput"]: {
	totalAmount?: number | undefined | null | Variable<any, string>,
	commissionRate?: number | undefined | null | Variable<any, string>,
	commissionAmount?: number | undefined | null | Variable<any, string>,
	netAmount?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PayoutStatus"] | undefined | null | Variable<any, string>
};
	["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["TransactionWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["TransactionUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["TransactionScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["TransactionUpdateManyMutationInput"] | Variable<any, string>
};
	["TransactionScalarWhereInput"]: {
	AND?: Array<ValueTypes["TransactionScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["TransactionScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["TransactionScalarWhereInput"]> | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	amount?: ValueTypes["FloatFilter"] | undefined | null | Variable<any, string>,
	createAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTimeNullableFilter"] | undefined | null | Variable<any, string>,
	relatedEntityId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	transactionStatus?: ValueTypes["EnumTransactionStatusFilter"] | undefined | null | Variable<any, string>,
	details?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>
};
	["TransactionUpdateManyMutationInput"]: {
	amount?: number | undefined | null | Variable<any, string>,
	createAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	processedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	relatedEntityId?: string | undefined | null | Variable<any, string>,
	transactionStatus?: ValueTypes["TransactionStatus"] | undefined | null | Variable<any, string>,
	details?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutCameraServersInput"] | Variable<any, string>
};
	["CameraServerUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ValueTypes["CameraServerWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["CameraServerUpdateWithoutCamerasInput"] | Variable<any, string>
};
	["CameraUpdateWithWhereUniqueWithoutPlayerInput"]: {
	where: ValueTypes["CameraWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CameraUpdateWithoutPlayerInput"] | Variable<any, string>
};
	["CameraUpdateManyWithWhereWithoutPlayerInput"]: {
	where: ValueTypes["CameraScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CameraUpdateManyMutationInput"] | Variable<any, string>
};
	["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PlayerUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["PlayerScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PlayerUpdateManyMutationInput"] | Variable<any, string>
};
	["PlayerScalarWhereInput"]: {
	AND?: Array<ValueTypes["PlayerScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlayerScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlayerScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	configLocation?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	pisignagePlayerId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	cpuSerialNumber?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	playlistIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workpaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	signageTypeId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	audienceTypeId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["EnumAspectRatioTypeFilter"] | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: ValueTypes["IntNullableFilter"] | undefined | null | Variable<any, string>,
	isOnline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	adBoardIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["PlayerUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutTransactionsInput"] | Variable<any, string>
};
	["TransactionUpdateToOneWithWhereWithoutPayoutsInput"]: {
	where?: ValueTypes["TransactionWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["TransactionUpdateWithoutPayoutsInput"] | Variable<any, string>
};
	["PayoutUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ValueTypes["PayoutWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PayoutUpdateWithoutBookingInput"] | Variable<any, string>
};
	["PayoutUpdateManyWithWhereWithoutBookingInput"]: {
	where: ValueTypes["PayoutScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PayoutUpdateManyMutationInput"] | Variable<any, string>
};
	["BookingUpdateWithWhereUniqueWithoutCampaignInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutCampaignInput"] | Variable<any, string>
};
	["BookingUpdateManyWithWhereWithoutCampaignInput"]: {
	where: ValueTypes["BookingScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>
};
	["BookingScalarWhereInput"]: {
	AND?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BookingScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	additionalInformation?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingCode?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["EnumBookingStatusFilter"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["EnumPaymentStatusNullableFilter"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["EnumDeploymentStatusFilter"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["EnumContentApprovalStatusFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	sharedUserIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	isOffline?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isPaid?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isScheduled?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	isCompleted?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>
};
	["BookingUpdateManyMutationInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>
};
	["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CampaignUpdateWithoutAssetsInput"] | Variable<any, string>
};
	["CampaignUpdateManyWithWhereWithoutAssetsInput"]: {
	where: ValueTypes["CampaignScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CampaignUpdateManyMutationInput"] | Variable<any, string>
};
	["CampaignScalarWhereInput"]: {
	AND?: Array<ValueTypes["CampaignScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CampaignScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["CampaignScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	bookingFor?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	purpose?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	businessCategoryId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adCategoryId?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["EnumPaymentStatusFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	assetsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>
};
	["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["FileUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["FileScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateManyMutationInput"] | Variable<any, string>
};
	["FileScalarWhereInput"]: {
	AND?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["FileScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	ctime?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	filepath?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isDirectory?: ValueTypes["BoolFilter"] | undefined | null | Variable<any, string>,
	mtime?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["EnumFileStatusNullableFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	proofBookingId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["FileUpdateManyMutationInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	ctime?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory?: boolean | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCreateInput"] | undefined | null | Variable<any, string>,
	mtime?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingUpdateToOneWithWhereWithoutProofsInput"]: {
	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutProofsInput"] | Variable<any, string>
};
	["FileUpdateWithWhereUniqueWithoutCampaignsInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateWithoutCampaignsInput"] | Variable<any, string>
};
	["FileUpdateManyWithWhereWithoutCampaignsInput"]: {
	where: ValueTypes["FileScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateManyMutationInput"] | Variable<any, string>
};
	["CampaignUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ValueTypes["CampaignWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["CampaignUpdateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["BookingUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["BookingScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"]: {
	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardGroupUpdateWithoutAdBoardInput"] | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutHistoryInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutHistoryInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]: {
	where: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"] | Variable<any, string>
};
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]: {
	where: ValueTypes["AdBoardPublishHistoryScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardPublishHistoryUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutAuditsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutAuditsInput"] | Variable<any, string>
};
	["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AuditUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AuditUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["AuditScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AuditUpdateManyMutationInput"] | Variable<any, string>
};
	["AuditScalarWhereInput"]: {
	AND?: Array<ValueTypes["AuditScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AuditScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AuditScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>
};
	["AuditUpdateManyMutationInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	action?: string | undefined | null | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutPlayersInput"] | Variable<any, string>
};
	["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PlayerUpdateWithoutAdBoardGroupInput"] | Variable<any, string>
};
	["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]: {
	where: ValueTypes["PlayerScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PlayerUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]: {
	where: ValueTypes["AdBoardGroupScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardGroupUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardGroupScalarWhereInput"]: {
	AND?: Array<ValueTypes["AdBoardGroupScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdBoardGroupScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdBoardGroupScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	adsPlaylistID?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	pisignageGroupId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["EnumFitTypeFilter"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["EnumFitTypeFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["AdBoardGroupUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"]: {
	create?: Array<ValueTypes["PlaylistCreateWithoutFooterPlaylistInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PlaylistScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PlaylistUpdateWithoutFooterPlaylistInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutFooterPlaylistInput"] | Variable<any, string>
};
	["PlaylistUpdateWithoutFooterPlaylistInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"]: {
	create?: Array<ValueTypes["PlaylistCreateWithoutSidebarPlaylistInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["PlaylistWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["PlaylistScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["PlaylistUpdateWithoutSidebarPlaylistInput"] | Variable<any, string>,
	create: ValueTypes["PlaylistCreateWithoutSidebarPlaylistInput"] | Variable<any, string>
};
	["PlaylistUpdateWithoutSidebarPlaylistInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateWithoutSidebarPlaylistInput"] | Variable<any, string>
};
	["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]: {
	where: ValueTypes["PlaylistScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateManyMutationInput"] | Variable<any, string>
};
	["PlaylistScalarWhereInput"]: {
	AND?: Array<ValueTypes["PlaylistScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlaylistScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlaylistScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	displayName?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	isAdsPlaylist?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	footerPlaylistId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	layout?: ValueTypes["EnumScreenLayoutFilter"] | undefined | null | Variable<any, string>,
	isDeployed?: ValueTypes["BoolNullableFilter"] | undefined | null | Variable<any, string>,
	adBoardGroupIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	workspaceId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>
};
	["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateWithoutFooterPlaylistInput"] | Variable<any, string>
};
	["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]: {
	where: ValueTypes["PlaylistScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateManyMutationInput"] | Variable<any, string>
};
	["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"]: {
	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardGroupUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["AdBoardGroupScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardGroupUpdateManyMutationInput"] | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutPlaylistsInput"] | Variable<any, string>
};
	["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]: {
	where: ValueTypes["PlaylistScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]: {
	where: ValueTypes["AdBoardGroupWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]: {
	where: ValueTypes["AdBoardGroupScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardGroupUpdateManyMutationInput"] | Variable<any, string>
};
	["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"]: {
	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"] | Variable<any, string>
};
	["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"]: {
	where?: ValueTypes["PlaylistWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"] | Variable<any, string>
};
	["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["PlaylistWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["PlaylistScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PlaylistUpdateManyMutationInput"] | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutAdBoardGroupInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"]: {
	where?: ValueTypes["AdBoardGroupWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"] | Variable<any, string>
};
	["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]: {
	where: ValueTypes["PlayerWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["PlayerUpdateWithoutAdBoardsInput"] | Variable<any, string>
};
	["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]: {
	where: ValueTypes["PlayerScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["PlayerUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutMembershipsInput"] | Variable<any, string>
};
	["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ValueTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceRoleUpsertWithoutMembershipsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleUpsertWithoutMembershipsInput"]: {
	update: ValueTypes["WorkspaceRoleUpdateWithoutMembershipsInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleUpdateWithoutMembershipsInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutWorkspaceRolesInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>,
	delete?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutWorkspaceRolesInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutWorkspaceRolesInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"] | Variable<any, string>
};
	["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ValueTypes["WorkspaceRoleWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceRoleUpdateWithoutMembershipsInput"] | Variable<any, string>
};
	["MembershipUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["MembershipUpdateWithoutUserInput"] | Variable<any, string>
};
	["MembershipUpdateManyWithWhereWithoutUserInput"]: {
	where: ValueTypes["MembershipScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["MembershipUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutChangelogsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutChangelogsInput"] | Variable<any, string>
};
	["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ValueTypes["ChangelogWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["ChangelogUpdateWithoutBookingInput"] | Variable<any, string>
};
	["ChangelogUpdateManyWithWhereWithoutBookingInput"]: {
	where: ValueTypes["ChangelogScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["ChangelogUpdateManyMutationInput"] | Variable<any, string>
};
	["ChangelogScalarWhereInput"]: {
	AND?: Array<ValueTypes["ChangelogScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ChangelogScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["ChangelogScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	action?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	bookingId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	userId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JsonNullableFilter"] | undefined | null | Variable<any, string>
};
	["ChangelogUpdateManyMutationInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	action?: string | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutSharedUsersInput"] | Variable<any, string>
};
	["BookingUpdateManyWithWhereWithoutSharedUsersInput"]: {
	where: ValueTypes["BookingScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutAdCategoryInput"] | Variable<any, string>
};
	["BookingUpdateManyWithWhereWithoutAdCategoryInput"]: {
	where: ValueTypes["BookingScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>
};
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]: {
	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"] | Variable<any, string>
};
	["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]: {
	where: ValueTypes["AdvertisementCategoryScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdvertisementCategoryUpdateManyMutationInput"] | Variable<any, string>
};
	["AdvertisementCategoryScalarWhereInput"]: {
	AND?: Array<ValueTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["EnumAdvertisementCategoryEnumFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	campaignIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>,
	bookingIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdvertisementCategoryEnum"] | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CampaignUpdateWithoutUserInput"] | Variable<any, string>
};
	["CampaignUpdateManyWithWhereWithoutUserInput"]: {
	where: ValueTypes["CampaignScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CampaignUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutMembershipsInput"] | Variable<any, string>
};
	["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ValueTypes["MembershipWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["MembershipUpdateWithoutWorkspaceInput"] | Variable<any, string>
};
	["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ValueTypes["MembershipScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["MembershipUpdateManyMutationInput"] | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutAuditsInput"] | Variable<any, string>
};
	["AuditUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["AuditWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AuditUpdateWithoutUserInput"] | Variable<any, string>
};
	["AuditUpdateManyWithWhereWithoutUserInput"]: {
	where: ValueTypes["AuditScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AuditUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutOrdersInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutOrdersInput"] | Variable<any, string>
};
	["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["OrderUpdateWithoutAdBoardInput"] | Variable<any, string>
};
	["OrderUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ValueTypes["OrderScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["OrderUpdateManyMutationInput"] | Variable<any, string>
};
	["OrderScalarWhereInput"]: {
	AND?: Array<ValueTypes["OrderScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["OrderScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["OrderScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	adBoardId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdByUserId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	origin?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	paymentId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["OrderUpdateManyMutationInput"]: {
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDate?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutCreatedByInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingUpdateToOneWithWhereWithoutChangelogsInput"]: {
	where?: ValueTypes["BookingWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutChangelogsInput"] | Variable<any, string>
};
	["ChangelogUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["ChangelogWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["ChangelogUpdateWithoutUserInput"] | Variable<any, string>
};
	["ChangelogUpdateManyWithWhereWithoutUserInput"]: {
	where: ValueTypes["ChangelogScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["ChangelogUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutOfflineBookingAdboardsInput"] | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutOrdersInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutOrdersInput"] | Variable<any, string>
};
	["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ValueTypes["OrderWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["OrderUpdateWithoutCreatedByInput"] | Variable<any, string>
};
	["OrderUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ValueTypes["OrderScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["OrderUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutOwnedWorkspacesInput"] | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutFilesInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutFilesInput"] | Variable<any, string>
};
	["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateWithoutUpdatedByInput"] | Variable<any, string>
};
	["FileUpdateManyWithWhereWithoutUpdatedByInput"]: {
	where: ValueTypes["FileScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutCreatedFilesInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutCreatedFilesInput"] | Variable<any, string>
};
	["FileUpdateWithWhereUniqueWithoutProofBookingInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateWithoutProofBookingInput"] | Variable<any, string>
};
	["FileUpdateManyWithWhereWithoutProofBookingInput"]: {
	where: ValueTypes["FileScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateManyMutationInput"] | Variable<any, string>
};
	["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutBusinessCategoryInput"] | Variable<any, string>
};
	["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]: {
	where: ValueTypes["BookingScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>
};
	["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"]: {
	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["BusinessCategoryUpdateWithoutCampaignsInput"] | Variable<any, string>
};
	["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CampaignUpdateWithoutAdCategoryInput"] | Variable<any, string>
};
	["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]: {
	where: ValueTypes["CampaignScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CampaignUpdateManyMutationInput"] | Variable<any, string>
};
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]: {
	where: ValueTypes["AdvertisementCategoryWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdvertisementCategoryUpdateWithoutBookingsInput"] | Variable<any, string>
};
	["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]: {
	where: ValueTypes["AdvertisementCategoryScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdvertisementCategoryUpdateManyMutationInput"] | Variable<any, string>
};
	["BookingUpdateWithWhereUniqueWithoutAssetsInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutAssetsInput"] | Variable<any, string>
};
	["BookingUpdateManyWithWhereWithoutAssetsInput"]: {
	where: ValueTypes["BookingScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>
};
	["FileUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateWithoutCreatedByInput"] | Variable<any, string>
};
	["FileUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ValueTypes["FileScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutCampaignsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutCampaignsInput"] | Variable<any, string>
};
	["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ValueTypes["CampaignWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["CampaignUpdateWithoutBusinessCategoryInput"] | Variable<any, string>
};
	["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]: {
	where: ValueTypes["CampaignScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["CampaignUpdateManyMutationInput"] | Variable<any, string>
};
	["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["BusinessCategoryUpdateWithoutBookingsInput"] | Variable<any, string>
};
	["BookingUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutUserInput"] | Variable<any, string>
};
	["BookingUpdateManyWithWhereWithoutUserInput"]: {
	where: ValueTypes["BookingScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutUpdatedFilesInput"] | Variable<any, string>
};
	["FileUpdateWithWhereUniqueWithoutBookingsInput"]: {
	where: ValueTypes["FileWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateWithoutBookingsInput"] | Variable<any, string>
};
	["FileUpdateManyWithWhereWithoutBookingsInput"]: {
	where: ValueTypes["FileScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["FileUpdateManyMutationInput"] | Variable<any, string>
};
	["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ValueTypes["BookingWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateWithoutAdBoardInput"] | Variable<any, string>
};
	["BookingUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ValueTypes["BookingScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BookingUpdateManyMutationInput"] | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutCategoryInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutCategoryInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: {
	where: ValueTypes["BusinessCategoryWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"] | Variable<any, string>
};
	["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]: {
	where: ValueTypes["BusinessCategoryScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["BusinessCategoryUpdateManyMutationInput"] | Variable<any, string>
};
	["BusinessCategoryScalarWhereInput"]: {
	AND?: Array<ValueTypes["BusinessCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BusinessCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BusinessCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>,
	restrictedByAdBoardIds?: ValueTypes["StringNullableListFilter"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutLocationCategoryInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"]: {
	where?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"] | Variable<any, string>
};
	["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ValueTypes["LocationSubCategoryWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutRestrictedBusinesInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ValueTypes["BusinessCategoryWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["BusinessCategoryUpdateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutLocationSubCategoryInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ValueTypes["LocationSubCategoryWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"] | Variable<any, string>
};
	["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]: {
	where: ValueTypes["LocationSubCategoryScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["LocationSubCategoryUpdateManyMutationInput"] | Variable<any, string>
};
	["LocationSubCategoryScalarWhereInput"]: {
	AND?: Array<ValueTypes["LocationSubCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["LocationSubCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["LocationSubCategoryScalarWhereInput"]> | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	locationCategoryId?: ValueTypes["StringNullableFilter"] | undefined | null | Variable<any, string>
};
	["LocationSubCategoryUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>
};
	["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ValueTypes["LocationCategoryWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["LocationCategoryUpdateWithoutAdBoardsInput"] | Variable<any, string>
};
	["AdBoardGroupCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	adBoardId?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	scheduledPlaylistsIDs?: Array<string> | undefined | null | Variable<any, string>,
	adsPlaylistID?: string | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>
};
	["AdBoardGroupCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId: string | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"] | Variable<any, string>
};
	["AdBoardGroupUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	schedules?: Array<ValueTypes["ScheduleCreateInput"]> | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	fitImage?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	fitVideo?: ValueTypes["FitType"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	scheduledPlaylists?: ValueTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>,
	adsPlaylist?: ValueTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null | Variable<any, string>,
	pisignagePlayers?: ValueTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["AdBoardTypeCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutTypeInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateNestedManyWithoutTypeInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutTypeInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutTypeInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyTypeInputEnvelope"] | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutTypeInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutTypeInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutTypeInput"] | Variable<any, string>
};
	["AdBoardCreateManyTypeInputEnvelope"]: {
	data: Array<ValueTypes["AdBoardCreateManyTypeInput"]> | Variable<any, string>
};
	["AdBoardCreateManyTypeInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	locationCategoryId?: string | undefined | null | Variable<any, string>,
	locationSubCategoryId?: string | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	categoryId?: string | undefined | null | Variable<any, string>,
	restrictedBusinessIds?: Array<string> | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	offlineBookingContactId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	playerIds?: Array<string> | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	publishedVersionId?: string | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	archivedByUserId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["AdBoardTypeUpdateManyMutationInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["AdBoardTypeUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutTypeNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateManyWithoutTypeNestedInput"]: {
	create?: Array<ValueTypes["AdBoardCreateWithoutTypeInput"]> | undefined | null | Variable<any, string>,
	connectOrCreate?: Array<ValueTypes["AdBoardCreateOrConnectWithoutTypeInput"]> | undefined | null | Variable<any, string>,
	upsert?: Array<ValueTypes["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]> | undefined | null | Variable<any, string>,
	createMany?: ValueTypes["AdBoardCreateManyTypeInputEnvelope"] | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	connect?: Array<ValueTypes["AdBoardWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	update?: Array<ValueTypes["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]> | undefined | null | Variable<any, string>,
	updateMany?: Array<ValueTypes["AdBoardUpdateManyWithWhereWithoutTypeInput"]> | undefined | null | Variable<any, string>,
	deleteMany?: Array<ValueTypes["AdBoardScalarWhereInput"]> | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	update: ValueTypes["AdBoardUpdateWithoutTypeInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutTypeInput"] | Variable<any, string>
};
	["AdBoardUpdateWithoutTypeInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	slotConfigurations?: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutTypeInput"] | Variable<any, string>
};
	["AdBoardUpdateManyWithWhereWithoutTypeInput"]: {
	where: ValueTypes["AdBoardScalarWhereInput"] | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateManyMutationInput"] | Variable<any, string>
};
	["AdvertisementCategoryCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	type: ValueTypes["AdvertisementCategoryEnum"] | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	campaignIds?: Array<string> | undefined | null | Variable<any, string>,
	bookingIds?: Array<string> | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	type: ValueTypes["AdvertisementCategoryEnum"] | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutAdCategoryInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdCategoryInput"] | undefined | null | Variable<any, string>
};
	["AdvertisementCategoryUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdvertisementCategoryEnum"] | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["AuditCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["AuditCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message: string | Variable<any, string>,
	action: string | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutAuditsInput"] | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutAuditsInput"] | undefined | null | Variable<any, string>
};
	["AuditUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	action?: string | undefined | null | Variable<any, string>,
	entity?: ValueTypes["EntityCreateInput"] | undefined | null | Variable<any, string>,
	updates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"] | undefined | null | Variable<any, string>
};
	["BookingCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	campaignId?: string | undefined | null | Variable<any, string>,
	businessCategoryId?: string | undefined | null | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	sharedUserIds?: Array<string> | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>
};
	["BookingCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode: string | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsCreateInput"] | Variable<any, string>,
	bookingStatus: ValueTypes["BookingStatus"] | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus: ValueTypes["DeploymentStatus"] | Variable<any, string>,
	contentApprovalStatus: ValueTypes["ContentApprovalStatus"] | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"] | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null | Variable<any, string>
};
	["BookingUpdateInput"]: {
	additionalInformation?: string | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	bookingCode?: string | undefined | null | Variable<any, string>,
	bookingDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	bookingDetails?: ValueTypes["BookingDetailsCreateInput"] | undefined | null | Variable<any, string>,
	bookingStatus?: ValueTypes["BookingStatus"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	deploymentStatus?: ValueTypes["DeploymentStatus"] | undefined | null | Variable<any, string>,
	contentApprovalStatus?: ValueTypes["ContentApprovalStatus"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	pisignage?: ValueTypes["BookingPisignageDataCreateInput"] | undefined | null | Variable<any, string>,
	refund?: ValueTypes["BookingRefundCreateInput"] | undefined | null | Variable<any, string>,
	isOffline?: boolean | undefined | null | Variable<any, string>,
	isPaid?: boolean | undefined | null | Variable<any, string>,
	isScheduled?: boolean | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	isCompleted?: boolean | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	proofs?: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	campaign?: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null | Variable<any, string>,
	payouts?: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	sharedUsers?: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>,
	slotUsage?: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	restrictedByAdBoardIds?: Array<string> | undefined | null | Variable<any, string>
};
	["BusinessCategoryCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null | Variable<any, string>
};
	["BusinessCategoryUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined | null | Variable<any, string>,
	restrictedByAdBoards?: ValueTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraServerId: string | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	playerId?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	adBoardId?: string | undefined | null | Variable<any, string>
};
	["CameraCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline: boolean | Variable<any, string>,
	cameraUrl: string | Variable<any, string>,
	rtspUrl: string | Variable<any, string>,
	cameraIp: string | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	cameraServer: ValueTypes["CameraServerCreateNestedOneWithoutCamerasInput"] | Variable<any, string>,
	player?: ValueTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined | null | Variable<any, string>
};
	["CameraUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	cameraUrl?: string | undefined | null | Variable<any, string>,
	rtspUrl?: string | undefined | null | Variable<any, string>,
	cameraIp?: string | undefined | null | Variable<any, string>,
	connectedCpuSerialNumbers?: Array<string> | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isPublic?: boolean | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>,
	player?: ValueTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraFeedCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	cameraId: string | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	cameraServerId: string | Variable<any, string>,
	data: ValueTypes["CameraFeedDataCreateInput"] | Variable<any, string>
};
	["CameraFeedCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	data: ValueTypes["CameraFeedDataCreateInput"] | Variable<any, string>,
	camera: ValueTypes["CameraCreateNestedOneWithoutFeedsInput"] | Variable<any, string>,
	cameraServer: ValueTypes["CameraServerCreateNestedOneWithoutFeedsInput"] | Variable<any, string>
};
	["CameraFeedUpdateInput"]: {
	timestamp?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	data?: ValueTypes["CameraFeedDataCreateInput"] | undefined | null | Variable<any, string>,
	camera?: ValueTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"] | undefined | null | Variable<any, string>,
	cameraServer?: ValueTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"] | undefined | null | Variable<any, string>
};
	["CameraServerCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId: string | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	publicIp: string | Variable<any, string>,
	privateIp: string | Variable<any, string>
};
	["CameraServerCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId: string | Variable<any, string>,
	publicIp: string | Variable<any, string>,
	privateIp: string | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined | null | Variable<any, string>
};
	["CameraServerUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	hostName?: string | undefined | null | Variable<any, string>,
	userName?: string | undefined | null | Variable<any, string>,
	deviceId?: string | undefined | null | Variable<any, string>,
	publicIp?: string | undefined | null | Variable<any, string>,
	privateIp?: string | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined | null | Variable<any, string>,
	feeds?: ValueTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined | null | Variable<any, string>
};
	["CampaignCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	userId: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	businessCategoryId: string | Variable<any, string>,
	adCategoryId?: Array<string> | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	assetsIDs?: Array<string> | undefined | null | Variable<any, string>
};
	["CampaignCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus: ValueTypes["PaymentStatus"] | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	origin: string | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	businessCategory: ValueTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"] | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null | Variable<any, string>
};
	["CampaignUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	bookingFor?: string | undefined | null | Variable<any, string>,
	purpose?: string | undefined | null | Variable<any, string>,
	payment?: ValueTypes["BookingPaymentCreateInput"] | undefined | null | Variable<any, string>,
	paymentStatus?: ValueTypes["PaymentStatus"] | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDates?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	businessCategory?: ValueTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	adCategory?: ValueTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	payments?: ValueTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null | Variable<any, string>,
	assets?: ValueTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null | Variable<any, string>
};
	["FileCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedByUserId?: string | undefined | null | Variable<any, string>,
	createdByUserId: string | Variable<any, string>,
	url: string | Variable<any, string>,
	bookingsIDs?: Array<string> | undefined | null | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	proofBookingId?: string | undefined | null | Variable<any, string>,
	campaignIds?: Array<string> | undefined | null | Variable<any, string>
};
	["FileCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	contentType: string | Variable<any, string>,
	ctime: string | Variable<any, string>,
	filename: string | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory: boolean | Variable<any, string>,
	meta: ValueTypes["FileMetaCreateInput"] | Variable<any, string>,
	mtime: string | Variable<any, string>,
	name: string | Variable<any, string>,
	size: number | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type: string | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>,
	updatedBy?: ValueTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null | Variable<any, string>,
	createdBy: ValueTypes["UserCreateNestedOneWithoutCreatedFilesInput"] | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null | Variable<any, string>
};
	["FileUpdateInput"]: {
	contentType?: string | undefined | null | Variable<any, string>,
	ctime?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	filepath?: string | undefined | null | Variable<any, string>,
	isDirectory?: boolean | undefined | null | Variable<any, string>,
	meta?: ValueTypes["FileMetaCreateInput"] | undefined | null | Variable<any, string>,
	mtime?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	size?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["FileStatus"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	updatedBy?: ValueTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null | Variable<any, string>,
	proofBooking?: ValueTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null | Variable<any, string>
};
	["MembershipCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	workspaceId: string | Variable<any, string>,
	roleId: string | Variable<any, string>
};
	["MembershipCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>,
	role: ValueTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"] | Variable<any, string>
};
	["MembershipUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null | Variable<any, string>
};
	["NotificationCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["NotificationType"] | Variable<any, string>,
	title: string | Variable<any, string>,
	content: string | Variable<any, string>,
	metadata?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	recipientId: string | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>
};
	["NotificationCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["NotificationType"] | Variable<any, string>,
	title: string | Variable<any, string>,
	content: string | Variable<any, string>,
	metadata?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>,
	recipient: ValueTypes["UserCreateNestedOneWithoutNotificationsInput"] | Variable<any, string>
};
	["UserCreateNestedOneWithoutNotificationsInput"]: {
	create?: ValueTypes["UserCreateWithoutNotificationsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutNotificationsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutNotificationsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutNotificationsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutNotificationsInput"] | Variable<any, string>
};
	["NotificationUpdateInput"]: {
	type?: ValueTypes["NotificationType"] | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	content?: string | undefined | null | Variable<any, string>,
	metadata?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	readAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	channels?: Array<ValueTypes["NotificationChannel"]> | undefined | null | Variable<any, string>,
	recipient?: ValueTypes["UserUpdateOneRequiredWithoutNotificationsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutNotificationsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutNotificationsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutNotificationsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutNotificationsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutNotificationsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutNotificationsInput"]: {
	update: ValueTypes["UserUpdateWithoutNotificationsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutNotificationsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutNotificationsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutNotificationsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutNotificationsInput"] | Variable<any, string>
};
	["OrderCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdByUserId?: string | undefined | null | Variable<any, string>,
	origin: string | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary: ValueTypes["PriceSummaryCreateInput"] | Variable<any, string>,
	selectedDate: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>
};
	["OrderCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	origin: string | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary: ValueTypes["PriceSummaryCreateInput"] | Variable<any, string>,
	selectedDate: ValueTypes["OrderSelectedDateCreateInput"] | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutOrdersInput"] | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutOrdersInput"] | undefined | null | Variable<any, string>
};
	["OrderUpdateInput"]: {
	createdDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	origin?: string | undefined | null | Variable<any, string>,
	paymentId?: string | undefined | null | Variable<any, string>,
	priceSummary?: ValueTypes["PriceSummaryCreateInput"] | undefined | null | Variable<any, string>,
	selectedDate?: ValueTypes["OrderSelectedDateCreateInput"] | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutOrdersNestedInput"] | undefined | null | Variable<any, string>
};
	["PlayerCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	adBoardGroupId?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	workpaceId?: string | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	signageTypeId?: string | undefined | null | Variable<any, string>,
	audienceTypeId?: Array<string> | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardIds?: Array<string> | undefined | null | Variable<any, string>
};
	["PlayerCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined | null | Variable<any, string>
};
	["PlayerUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	configLocation?: string | undefined | null | Variable<any, string>,
	pisignagePlayerId?: string | undefined | null | Variable<any, string>,
	pisignageGroupId?: string | undefined | null | Variable<any, string>,
	cpuSerialNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	playlistIds?: Array<string> | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	playbackDetails?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	aspectRatioType?: ValueTypes["AspectRatioType"] | undefined | null | Variable<any, string>,
	galleryImgs?: Array<ValueTypes["GalleryFileCreateInput"]> | undefined | null | Variable<any, string>,
	customAspectRatioWidth?: number | undefined | null | Variable<any, string>,
	customAspectRatioHeight?: number | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	isOnline?: boolean | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined | null | Variable<any, string>,
	signageType?: ValueTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	audienceType?: ValueTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined | null | Variable<any, string>
};
	["PlaylistCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	footerPlaylistId?: string | undefined | null | Variable<any, string>,
	sidebarPlaylistId?: string | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	adBoardGroupIds?: Array<string> | undefined | null | Variable<any, string>,
	workspaceId: string | Variable<any, string>
};
	["PlaylistCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"] | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null | Variable<any, string>
};
	["PlaylistUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	assets?: Array<ValueTypes["PisignageFileCreateInput"]> | undefined | null | Variable<any, string>,
	isAdsPlaylist?: boolean | undefined | null | Variable<any, string>,
	layout?: ValueTypes["ScreenLayout"] | undefined | null | Variable<any, string>,
	isDeployed?: boolean | undefined | null | Variable<any, string>,
	footerPlaylist?: ValueTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	sidebarPlaylist?: ValueTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroupsForAds?: ValueTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null | Variable<any, string>,
	footerParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null | Variable<any, string>,
	sidebarParentPlaylists?: ValueTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null | Variable<any, string>
};
	["SlotConfigurationCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	adBoardId: string | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	availableSlots: number | Variable<any, string>
};
	["SlotConfigurationCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	availableSlots: number | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"] | Variable<any, string>
};
	["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateWithoutSlotConfigurationsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	address: string | Variable<any, string>,
	state: string | Variable<any, string>,
	city: string | Variable<any, string>,
	country: string | Variable<any, string>,
	map: ValueTypes["MapCreateInput"] | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>,
	pricePerDay: number | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status: ValueTypes["AdBoardStatus"] | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	workspace: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"] | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null | Variable<any, string>
};
	["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"]: {
	where: ValueTypes["AdBoardWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | Variable<any, string>
};
	["SlotConfigurationUpdateInput"]: {
	date?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	availableSlots?: number | undefined | null | Variable<any, string>,
	adBoard?: ValueTypes["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"]: {
	create?: ValueTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["AdBoardUpsertWithoutSlotConfigurationsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["AdBoardWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpsertWithoutSlotConfigurationsInput"]: {
	update: ValueTypes["AdBoardUpdateWithoutSlotConfigurationsInput"] | Variable<any, string>,
	create: ValueTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | Variable<any, string>,
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateWithoutSlotConfigurationsInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	state?: string | undefined | null | Variable<any, string>,
	city?: string | undefined | null | Variable<any, string>,
	country?: string | undefined | null | Variable<any, string>,
	map?: ValueTypes["MapCreateInput"] | undefined | null | Variable<any, string>,
	outdoor?: boolean | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	pricePerDay?: number | undefined | null | Variable<any, string>,
	originalPricePerDay?: number | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	aboutSpecifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	tags?: Array<string> | undefined | null | Variable<any, string>,
	featuredImage?: string | undefined | null | Variable<any, string>,
	galleryImgs?: Array<string> | undefined | null | Variable<any, string>,
	specifications?: Array<ValueTypes["SpecificationCreateInput"]> | undefined | null | Variable<any, string>,
	notes?: Array<string> | undefined | null | Variable<any, string>,
	impressionMultiplier?: number | undefined | null | Variable<any, string>,
	resolution?: ValueTypes["ResolutionCreateInput"] | undefined | null | Variable<any, string>,
	screenSize?: ValueTypes["ScreenSizeCreateInput"] | undefined | null | Variable<any, string>,
	targetAudiences?: Array<string> | undefined | null | Variable<any, string>,
	playbackTimes?: Array<ValueTypes["PlaybackTimeCreateInput"]> | undefined | null | Variable<any, string>,
	status?: ValueTypes["AdBoardStatus"] | undefined | null | Variable<any, string>,
	adboardDraftStatus?: ValueTypes["AdboardDraftStatus"] | undefined | null | Variable<any, string>,
	isLive?: boolean | undefined | null | Variable<any, string>,
	webhooks?: Array<ValueTypes["WebhookCreateInput"]> | undefined | null | Variable<any, string>,
	numberOfDisplays?: number | undefined | null | Variable<any, string>,
	dailyOperationHours?: number | undefined | null | Variable<any, string>,
	adsPerLoop?: number | undefined | null | Variable<any, string>,
	adsDuration?: number | undefined | null | Variable<any, string>,
	monthlyPrice?: number | undefined | null | Variable<any, string>,
	offlineBookingsEnabled?: boolean | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	href?: string | undefined | null | Variable<any, string>,
	publisherScreenId?: string | undefined | null | Variable<any, string>,
	additionalData?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	isDraft?: boolean | undefined | null | Variable<any, string>,
	lastPublishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	isArchived?: boolean | undefined | null | Variable<any, string>,
	archivedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	locationCategory?: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	locationSubCategory?: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	restrictedBusines?: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingsContact?: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	createdBy?: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroup?: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	cameras?: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	publishedVersion?: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null | Variable<any, string>,
	draftAdboard?: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null | Variable<any, string>,
	history?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>,
	archivedBy?: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null | Variable<any, string>
};
	["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"]: {
	where?: ValueTypes["AdBoardWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["AdBoardUpdateWithoutSlotConfigurationsInput"] | Variable<any, string>
};
	["SubscriptionCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	packageId?: string | undefined | null | Variable<any, string>,
	packageName?: string | undefined | null | Variable<any, string>,
	priceLookupKey?: string | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: string | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: string | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsCreateInput"] | undefined | null | Variable<any, string>,
	activeWorkspaceId: string | Variable<any, string>
};
	["SubscriptionCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	packageId?: string | undefined | null | Variable<any, string>,
	packageName?: string | undefined | null | Variable<any, string>,
	priceLookupKey?: string | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: string | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: string | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsCreateInput"] | undefined | null | Variable<any, string>,
	activeWorkspace: ValueTypes["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"] | Variable<any, string>
};
	["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateWithoutActiveSubscriptionInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"]: {
	where: ValueTypes["WorkspaceWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | Variable<any, string>
};
	["SubscriptionUpdateManyMutationInput"]: {
	packageId?: string | undefined | null | Variable<any, string>,
	packageName?: string | undefined | null | Variable<any, string>,
	priceLookupKey?: string | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: string | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: string | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsCreateInput"] | undefined | null | Variable<any, string>
};
	["SubscriptionUpdateInput"]: {
	packageId?: string | undefined | null | Variable<any, string>,
	packageName?: string | undefined | null | Variable<any, string>,
	priceLookupKey?: string | undefined | null | Variable<any, string>,
	startDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	endDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	stripePaymentIntentId?: string | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	stripeCheckoutSessionId?: string | undefined | null | Variable<any, string>,
	cancellationDetails?: ValueTypes["CancellationDetailsCreateInput"] | undefined | null | Variable<any, string>,
	activeWorkspace?: ValueTypes["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"]: {
	create?: ValueTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["WorkspaceUpsertWithoutActiveSubscriptionInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["WorkspaceWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpsertWithoutActiveSubscriptionInput"]: {
	update: ValueTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"] | Variable<any, string>,
	create: ValueTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | Variable<any, string>,
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateWithoutActiveSubscriptionInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"]: {
	where?: ValueTypes["WorkspaceWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"] | Variable<any, string>
};
	["UserCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	sharedBookingIds?: Array<string> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	leadWorkspaceIds?: Array<string> | undefined | null | Variable<any, string>
};
	["UserCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	userPositions?: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["UserPositionCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	userId: string | Variable<any, string>,
	coords: ValueTypes["UserPositionCoordsCreateInput"] | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationCreateInput"] | undefined | null | Variable<any, string>
};
	["UserPositionCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	timestamp: ValueTypes["DateTime"] | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	coords: ValueTypes["UserPositionCoordsCreateInput"] | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationCreateInput"] | undefined | null | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutUserPositionsInput"] | Variable<any, string>
};
	["UserCreateNestedOneWithoutUserPositionsInput"]: {
	create?: ValueTypes["UserCreateWithoutUserPositionsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutUserPositionsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>
};
	["UserCreateWithoutUserPositionsInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	uid?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null | Variable<any, string>
};
	["UserCreateOrConnectWithoutUserPositionsInput"]: {
	where: ValueTypes["UserWhereUniqueInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutUserPositionsInput"] | Variable<any, string>
};
	["UserPositionUpdateInput"]: {
	timestamp?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	coords?: ValueTypes["UserPositionCoordsCreateInput"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["UserPositionLocationCreateInput"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["UserUpdateOneRequiredWithoutUserPositionsNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateOneRequiredWithoutUserPositionsNestedInput"]: {
	create?: ValueTypes["UserCreateWithoutUserPositionsInput"] | undefined | null | Variable<any, string>,
	connectOrCreate?: ValueTypes["UserCreateOrConnectWithoutUserPositionsInput"] | undefined | null | Variable<any, string>,
	upsert?: ValueTypes["UserUpsertWithoutUserPositionsInput"] | undefined | null | Variable<any, string>,
	connect?: ValueTypes["UserWhereUniqueInput"] | undefined | null | Variable<any, string>,
	update?: ValueTypes["UserUpdateToOneWithWhereWithoutUserPositionsInput"] | undefined | null | Variable<any, string>
};
	["UserUpsertWithoutUserPositionsInput"]: {
	update: ValueTypes["UserUpdateWithoutUserPositionsInput"] | Variable<any, string>,
	create: ValueTypes["UserCreateWithoutUserPositionsInput"] | Variable<any, string>,
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateWithoutUserPositionsInput"]: {
	uid?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	displayName?: string | undefined | null | Variable<any, string>,
	otherDetails?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	stripe?: ValueTypes["UserStripeCreateInput"] | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["Role"]> | undefined | null | Variable<any, string>,
	refunds?: Array<ValueTypes["BookingRefundCreateInput"]> | undefined | null | Variable<any, string>,
	disclaimerAccepted?: boolean | undefined | null | Variable<any, string>,
	notificationPreference?: ValueTypes["NotificationPreferenceCreateInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	createdFiles?: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	updatedFiles?: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null | Variable<any, string>,
	orders?: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	changelogs?: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	campaigns?: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	sharedBookings?: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	notifications?: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null | Variable<any, string>,
	publishAdboardVersion?: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null | Variable<any, string>,
	offlineBookingAdboards?: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null | Variable<any, string>,
	reviewComments?: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>,
	archivedAdboards?: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null | Variable<any, string>,
	ownedWorkspaces?: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null | Variable<any, string>,
	leadWorspaces?: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null | Variable<any, string>,
	publicReviews?: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null | Variable<any, string>
};
	["UserUpdateToOneWithWhereWithoutUserPositionsInput"]: {
	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>,
	data: ValueTypes["UserUpdateWithoutUserPositionsInput"] | Variable<any, string>
};
	["WorkspaceCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	ownerId?: string | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	leadIds?: Array<string> | undefined | null | Variable<any, string>
};
	["WorkspaceCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	key: string | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceUpdateInput"]: {
	type?: ValueTypes["WorkspaceType"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	userIds?: Array<string> | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
	owner?: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoards?: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	playlists?: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	adBoardGroups?: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	audits?: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	bookings?: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	files?: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	players?: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	transactions?: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	activeSubscription?: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	cameraServers?: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	workspaceRoles?: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	webhookLogs?: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null | Variable<any, string>,
	leads?: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleCreateManyInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	workspaceId?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipCreateNestedManyWithoutRoleInput"] | undefined | null | Variable<any, string>
};
	["WorkspaceRoleUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	permissions?: Array<ValueTypes["Permission"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	workspace?: ValueTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"] | undefined | null | Variable<any, string>,
	memberships?: ValueTypes["MembershipUpdateManyWithoutRoleNestedInput"] | undefined | null | Variable<any, string>
};
	["AccountDetails"]: {
	displayName: string | Variable<any, string>,
	phoneNumber: string | Variable<any, string>,
	password: string | Variable<any, string>
};
	["UserInput"]: {
	displayName: string | Variable<any, string>,
	phoneNumber: string | Variable<any, string>,
	password: string | Variable<any, string>,
	email: string | Variable<any, string>
};
	["GenericResponse"]: AliasType<{
	success?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EmailOutput"]: AliasType<{
	success?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TemplateIds"]:TemplateIds;
	["AdBoardPublicReviewCreateInput"]: {
	id?: string | undefined | null | Variable<any, string>,
	rating: number | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	adBoard: ValueTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"] | Variable<any, string>,
	user: ValueTypes["UserCreateNestedOneWithoutPublicReviewsInput"] | Variable<any, string>
};
	/** The recipe model */
["TypedGenericResponse"]: AliasType<{
	success?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingRequest"]: {
	assets: Array<string> | Variable<any, string>,
	bookingDetails: ValueTypes["BookingDetailsInput"] | Variable<any, string>,
	orderId: string | Variable<any, string>
};
	["BookingDetailsInput"]: {
	email: string | Variable<any, string>,
	name: string | Variable<any, string>,
	phone: string | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["Query"]: AliasType<{
aggregateAdBoard?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateAdBoard"]],
findFirstAdBoard?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
findFirstAdBoardOrThrow?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
adBoard?: [{	where: ResolverInputTypes["AdBoardWhereUniqueInput"]},ResolverInputTypes["AdBoard"]],
getAdBoard?: [{	where: ResolverInputTypes["AdBoardWhereUniqueInput"]},ResolverInputTypes["AdBoard"]],
groupByAdBoard?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]>,	having?: ResolverInputTypes["AdBoardScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AdBoardGroupBy"]],
aggregateAdBoardGroup?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateAdBoardGroup"]],
findFirstAdBoardGroup?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardGroup"]],
findFirstAdBoardGroupOrThrow?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardGroup"]],
adBoardGroups?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardGroup"]],
adBoardGroup?: [{	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"]},ResolverInputTypes["AdBoardGroup"]],
getAdBoardGroup?: [{	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"]},ResolverInputTypes["AdBoardGroup"]],
groupByAdBoardGroup?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardGroupOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["AdBoardGroupScalarFieldEnum"]>,	having?: ResolverInputTypes["AdBoardGroupScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AdBoardGroupGroupBy"]],
aggregateAdBoardType?: [{	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardTypeOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardTypeWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateAdBoardType"]],
findFirstAdBoardType?: [{	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardTypeOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardTypeWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardTypeScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardType"]],
findFirstAdBoardTypeOrThrow?: [{	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardTypeOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardTypeWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardTypeScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardType"]],
adBoardTypes?: [{	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardTypeOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardTypeWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardTypeScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardType"]],
adBoardType?: [{	where: ResolverInputTypes["AdBoardTypeWhereUniqueInput"]},ResolverInputTypes["AdBoardType"]],
getAdBoardType?: [{	where: ResolverInputTypes["AdBoardTypeWhereUniqueInput"]},ResolverInputTypes["AdBoardType"]],
groupByAdBoardType?: [{	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardTypeOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["AdBoardTypeScalarFieldEnum"]>,	having?: ResolverInputTypes["AdBoardTypeScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AdBoardTypeGroupBy"]],
aggregateAdvertisementCategory?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateAdvertisementCategory"]],
findFirstAdvertisementCategory?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdvertisementCategory"]],
findFirstAdvertisementCategoryOrThrow?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdvertisementCategory"]],
advertisementCategories?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdvertisementCategory"]],
advertisementCategory?: [{	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]},ResolverInputTypes["AdvertisementCategory"]],
getAdvertisementCategory?: [{	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]},ResolverInputTypes["AdvertisementCategory"]],
groupByAdvertisementCategory?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdvertisementCategoryOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["AdvertisementCategoryScalarFieldEnum"]>,	having?: ResolverInputTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AdvertisementCategoryGroupBy"]],
aggregateAudit?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuditOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AuditWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateAudit"]],
findFirstAudit?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuditOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AuditWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AuditScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Audit"]],
findFirstAuditOrThrow?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuditOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AuditWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AuditScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Audit"]],
audits?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuditOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AuditWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AuditScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Audit"]],
audit?: [{	where: ResolverInputTypes["AuditWhereUniqueInput"]},ResolverInputTypes["Audit"]],
getAudit?: [{	where: ResolverInputTypes["AuditWhereUniqueInput"]},ResolverInputTypes["Audit"]],
groupByAudit?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuditOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["AuditScalarFieldEnum"]>,	having?: ResolverInputTypes["AuditScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AuditGroupBy"]],
aggregateBooking?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateBooking"]],
findFirstBooking?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
findFirstBookingOrThrow?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
booking?: [{	where: ResolverInputTypes["BookingWhereUniqueInput"]},ResolverInputTypes["Booking"]],
getBooking?: [{	where: ResolverInputTypes["BookingWhereUniqueInput"]},ResolverInputTypes["Booking"]],
groupByBooking?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["BookingScalarFieldEnum"]>,	having?: ResolverInputTypes["BookingScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["BookingGroupBy"]],
aggregateBusinessCategory?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateBusinessCategory"]],
findFirstBusinessCategory?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BusinessCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["BusinessCategory"]],
findFirstBusinessCategoryOrThrow?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BusinessCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["BusinessCategory"]],
businessCategories?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BusinessCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["BusinessCategory"]],
businessCategory?: [{	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"]},ResolverInputTypes["BusinessCategory"]],
getBusinessCategory?: [{	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"]},ResolverInputTypes["BusinessCategory"]],
groupByBusinessCategory?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCategoryOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["BusinessCategoryScalarFieldEnum"]>,	having?: ResolverInputTypes["BusinessCategoryScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["BusinessCategoryGroupBy"]],
aggregateCamera?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateCamera"]],
findFirstCamera?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Camera"]],
findFirstCameraOrThrow?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Camera"]],
cameras?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Camera"]],
camera?: [{	where: ResolverInputTypes["CameraWhereUniqueInput"]},ResolverInputTypes["Camera"]],
getCamera?: [{	where: ResolverInputTypes["CameraWhereUniqueInput"]},ResolverInputTypes["Camera"]],
groupByCamera?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["CameraScalarFieldEnum"]>,	having?: ResolverInputTypes["CameraScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["CameraGroupBy"]],
aggregateCameraFeed?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraFeedWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateCameraFeed"]],
findFirstCameraFeed?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraFeedWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraFeedScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraFeed"]],
findFirstCameraFeedOrThrow?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraFeedWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraFeedScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraFeed"]],
cameraFeeds?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraFeedWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraFeedScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraFeed"]],
cameraFeed?: [{	where: ResolverInputTypes["CameraFeedWhereUniqueInput"]},ResolverInputTypes["CameraFeed"]],
getCameraFeed?: [{	where: ResolverInputTypes["CameraFeedWhereUniqueInput"]},ResolverInputTypes["CameraFeed"]],
groupByCameraFeed?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraFeedOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["CameraFeedScalarFieldEnum"]>,	having?: ResolverInputTypes["CameraFeedScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["CameraFeedGroupBy"]],
aggregateCameraServer?: [{	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraServerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateCameraServer"]],
findFirstCameraServer?: [{	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraServerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraServerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraServer"]],
findFirstCameraServerOrThrow?: [{	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraServerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraServerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraServer"]],
cameraServers?: [{	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraServerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraServerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraServer"]],
cameraServer?: [{	where: ResolverInputTypes["CameraServerWhereUniqueInput"]},ResolverInputTypes["CameraServer"]],
getCameraServer?: [{	where: ResolverInputTypes["CameraServerWhereUniqueInput"]},ResolverInputTypes["CameraServer"]],
groupByCameraServer?: [{	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraServerOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["CameraServerScalarFieldEnum"]>,	having?: ResolverInputTypes["CameraServerScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["CameraServerGroupBy"]],
aggregateCampaign?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateCampaign"]],
findFirstCampaign?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CampaignScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Campaign"]],
findFirstCampaignOrThrow?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CampaignScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Campaign"]],
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CampaignScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Campaign"]],
campaign?: [{	where: ResolverInputTypes["CampaignWhereUniqueInput"]},ResolverInputTypes["Campaign"]],
getCampaign?: [{	where: ResolverInputTypes["CampaignWhereUniqueInput"]},ResolverInputTypes["Campaign"]],
groupByCampaign?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["CampaignScalarFieldEnum"]>,	having?: ResolverInputTypes["CampaignScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["CampaignGroupBy"]],
aggregateFile?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateFile"]],
findFirstFile?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
findFirstFileOrThrow?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
files?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
file?: [{	where: ResolverInputTypes["FileWhereUniqueInput"]},ResolverInputTypes["File"]],
getFile?: [{	where: ResolverInputTypes["FileWhereUniqueInput"]},ResolverInputTypes["File"]],
groupByFile?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["FileScalarFieldEnum"]>,	having?: ResolverInputTypes["FileScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["FileGroupBy"]],
aggregateMembership?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MembershipOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["MembershipWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateMembership"]],
findFirstMembership?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MembershipOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["MembershipWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["MembershipScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Membership"]],
findFirstMembershipOrThrow?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MembershipOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["MembershipWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["MembershipScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Membership"]],
memberships?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MembershipOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["MembershipWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["MembershipScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Membership"]],
membership?: [{	where: ResolverInputTypes["MembershipWhereUniqueInput"]},ResolverInputTypes["Membership"]],
getMembership?: [{	where: ResolverInputTypes["MembershipWhereUniqueInput"]},ResolverInputTypes["Membership"]],
groupByMembership?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MembershipOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["MembershipScalarFieldEnum"]>,	having?: ResolverInputTypes["MembershipScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["MembershipGroupBy"]],
aggregateNotification?: [{	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["NotificationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["NotificationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateNotification"]],
findFirstNotification?: [{	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["NotificationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["NotificationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["NotificationScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Notification"]],
findFirstNotificationOrThrow?: [{	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["NotificationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["NotificationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["NotificationScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Notification"]],
notifications?: [{	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["NotificationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["NotificationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["NotificationScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Notification"]],
notification?: [{	where: ResolverInputTypes["NotificationWhereUniqueInput"]},ResolverInputTypes["Notification"]],
getNotification?: [{	where: ResolverInputTypes["NotificationWhereUniqueInput"]},ResolverInputTypes["Notification"]],
groupByNotification?: [{	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["NotificationOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["NotificationScalarFieldEnum"]>,	having?: ResolverInputTypes["NotificationScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["NotificationGroupBy"]],
aggregateOrder?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["OrderWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateOrder"]],
findFirstOrder?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["OrderWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["OrderScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Order"]],
findFirstOrderOrThrow?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["OrderWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["OrderScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Order"]],
orders?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["OrderWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["OrderScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Order"]],
order?: [{	where: ResolverInputTypes["OrderWhereUniqueInput"]},ResolverInputTypes["Order"]],
getOrder?: [{	where: ResolverInputTypes["OrderWhereUniqueInput"]},ResolverInputTypes["Order"]],
groupByOrder?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["OrderScalarFieldEnum"]>,	having?: ResolverInputTypes["OrderScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["OrderGroupBy"]],
aggregatePlayer?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregatePlayer"]],
findFirstPlayer?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Player"]],
findFirstPlayerOrThrow?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Player"]],
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Player"]],
player?: [{	where: ResolverInputTypes["PlayerWhereUniqueInput"]},ResolverInputTypes["Player"]],
getPlayer?: [{	where: ResolverInputTypes["PlayerWhereUniqueInput"]},ResolverInputTypes["Player"]],
groupByPlayer?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["PlayerScalarFieldEnum"]>,	having?: ResolverInputTypes["PlayerScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["PlayerGroupBy"]],
aggregatePlaylist?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregatePlaylist"]],
findFirstPlaylist?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlaylistScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Playlist"]],
findFirstPlaylistOrThrow?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlaylistScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Playlist"]],
playlists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlaylistScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Playlist"]],
playlist?: [{	where: ResolverInputTypes["PlaylistWhereUniqueInput"]},ResolverInputTypes["Playlist"]],
getPlaylist?: [{	where: ResolverInputTypes["PlaylistWhereUniqueInput"]},ResolverInputTypes["Playlist"]],
groupByPlaylist?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["PlaylistScalarFieldEnum"]>,	having?: ResolverInputTypes["PlaylistScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["PlaylistGroupBy"]],
aggregateSlotConfiguration?: [{	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SlotConfigurationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateSlotConfiguration"]],
findFirstSlotConfiguration?: [{	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SlotConfigurationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["SlotConfigurationScalarFieldEnum"]> | undefined | null},ResolverInputTypes["SlotConfiguration"]],
findFirstSlotConfigurationOrThrow?: [{	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SlotConfigurationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["SlotConfigurationScalarFieldEnum"]> | undefined | null},ResolverInputTypes["SlotConfiguration"]],
slotConfigurations?: [{	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SlotConfigurationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["SlotConfigurationScalarFieldEnum"]> | undefined | null},ResolverInputTypes["SlotConfiguration"]],
slotConfiguration?: [{	where: ResolverInputTypes["SlotConfigurationWhereUniqueInput"]},ResolverInputTypes["SlotConfiguration"]],
getSlotConfiguration?: [{	where: ResolverInputTypes["SlotConfigurationWhereUniqueInput"]},ResolverInputTypes["SlotConfiguration"]],
groupBySlotConfiguration?: [{	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SlotConfigurationOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["SlotConfigurationScalarFieldEnum"]>,	having?: ResolverInputTypes["SlotConfigurationScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["SlotConfigurationGroupBy"]],
aggregateSubscription?: [{	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscriptionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SubscriptionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateSubscription"]],
findFirstSubscription?: [{	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscriptionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SubscriptionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["SubscriptionScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Subscription"]],
findFirstSubscriptionOrThrow?: [{	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscriptionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SubscriptionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["SubscriptionScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Subscription"]],
subscriptions?: [{	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscriptionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SubscriptionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["SubscriptionScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Subscription"]],
subscription?: [{	where: ResolverInputTypes["SubscriptionWhereUniqueInput"]},ResolverInputTypes["Subscription"]],
getSubscription?: [{	where: ResolverInputTypes["SubscriptionWhereUniqueInput"]},ResolverInputTypes["Subscription"]],
groupBySubscription?: [{	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SubscriptionOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["SubscriptionScalarFieldEnum"]>,	having?: ResolverInputTypes["SubscriptionScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["SubscriptionGroupBy"]],
aggregateUser?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateUser"]],
findFirstUser?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserScalarFieldEnum"]> | undefined | null},ResolverInputTypes["User"]],
findFirstUserOrThrow?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserScalarFieldEnum"]> | undefined | null},ResolverInputTypes["User"]],
users?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserScalarFieldEnum"]> | undefined | null},ResolverInputTypes["User"]],
user?: [{	where: ResolverInputTypes["UserWhereUniqueInput"]},ResolverInputTypes["User"]],
getUser?: [{	where: ResolverInputTypes["UserWhereUniqueInput"]},ResolverInputTypes["User"]],
groupByUser?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["UserScalarFieldEnum"]>,	having?: ResolverInputTypes["UserScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["UserGroupBy"]],
aggregateUserPosition?: [{	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserPositionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserPositionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateUserPosition"]],
findFirstUserPosition?: [{	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserPositionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserPositionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserPositionScalarFieldEnum"]> | undefined | null},ResolverInputTypes["UserPosition"]],
findFirstUserPositionOrThrow?: [{	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserPositionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserPositionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserPositionScalarFieldEnum"]> | undefined | null},ResolverInputTypes["UserPosition"]],
userPositions?: [{	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserPositionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserPositionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserPositionScalarFieldEnum"]> | undefined | null},ResolverInputTypes["UserPosition"]],
userPosition?: [{	where: ResolverInputTypes["UserPositionWhereUniqueInput"]},ResolverInputTypes["UserPosition"]],
getUserPosition?: [{	where: ResolverInputTypes["UserPositionWhereUniqueInput"]},ResolverInputTypes["UserPosition"]],
groupByUserPosition?: [{	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserPositionOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["UserPositionScalarFieldEnum"]>,	having?: ResolverInputTypes["UserPositionScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["UserPositionGroupBy"]],
aggregateWorkspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateWorkspace"]],
findFirstWorkspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Workspace"]],
findFirstWorkspaceOrThrow?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Workspace"]],
workspaces?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Workspace"]],
workspace?: [{	where: ResolverInputTypes["WorkspaceWhereUniqueInput"]},ResolverInputTypes["Workspace"]],
getWorkspace?: [{	where: ResolverInputTypes["WorkspaceWhereUniqueInput"]},ResolverInputTypes["Workspace"]],
groupByWorkspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["WorkspaceScalarFieldEnum"]>,	having?: ResolverInputTypes["WorkspaceScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["WorkspaceGroupBy"]],
aggregateWorkspaceRole?: [{	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["AggregateWorkspaceRole"]],
findFirstWorkspaceRole?: [{	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceRoleScalarFieldEnum"]> | undefined | null},ResolverInputTypes["WorkspaceRole"]],
findFirstWorkspaceRoleOrThrow?: [{	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceRoleScalarFieldEnum"]> | undefined | null},ResolverInputTypes["WorkspaceRole"]],
workspaceRoles?: [{	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceRoleScalarFieldEnum"]> | undefined | null},ResolverInputTypes["WorkspaceRole"]],
workspaceRole?: [{	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]},ResolverInputTypes["WorkspaceRole"]],
getWorkspaceRole?: [{	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]},ResolverInputTypes["WorkspaceRole"]],
groupByWorkspaceRole?: [{	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceRoleOrderByWithAggregationInput"]> | undefined | null,	by: Array<ResolverInputTypes["WorkspaceRoleScalarFieldEnum"]>,	having?: ResolverInputTypes["WorkspaceRoleScalarWhereWithAggregatesInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null},ResolverInputTypes["WorkspaceRoleGroupBy"]],
getAdBoardAvailability?: [{	month: number,	year: number,	adBoardId: string},ResolverInputTypes["Slot"]],
		__typename?: boolean | `@${string}`
}>;
	["AggregateAdBoard"]: AliasType<{
	_count?:ResolverInputTypes["AdBoardCountAggregate"],
	_avg?:ResolverInputTypes["AdBoardAvgAggregate"],
	_sum?:ResolverInputTypes["AdBoardSumAggregate"],
	_min?:ResolverInputTypes["AdBoardMinAggregate"],
	_max?:ResolverInputTypes["AdBoardMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adBoardTypeId?:boolean | `@${string}`,
	tags?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	galleryImgs?:boolean | `@${string}`,
	notes?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	categoryId?:boolean | `@${string}`,
	restrictedBusinessIds?:boolean | `@${string}`,
	targetAudiences?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	playerIds?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	additionalData?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardAvgAggregate"]: AliasType<{
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardSumAggregate"]: AliasType<{
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adBoardTypeId?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	categoryId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardStatus"]:AdBoardStatus;
	["AdboardDraftStatus"]:AdboardDraftStatus;
	/** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
["DateTime"]:unknown;
	["AdBoardMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adBoardTypeId?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	categoryId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	address?: ResolverInputTypes["StringFilter"] | undefined | null,
	state?: ResolverInputTypes["StringFilter"] | undefined | null,
	city?: ResolverInputTypes["StringFilter"] | undefined | null,
	country?: ResolverInputTypes["StringFilter"] | undefined | null,
	map?: ResolverInputTypes["MapCompositeFilter"] | undefined | null,
	outdoor?: ResolverInputTypes["BoolFilter"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	alias?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	title?: ResolverInputTypes["StringFilter"] | undefined | null,
	pricePerDay?: ResolverInputTypes["IntFilter"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationObjectEqualityInput"]> | undefined | null,
	adBoardTypeId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	tags?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	featuredImage?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	galleryImgs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationObjectEqualityInput"]> | undefined | null,
	notes?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	resolution?: ResolverInputTypes["ResolutionNullableCompositeFilter"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeNullableCompositeFilter"] | undefined | null,
	categoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	restrictedBusinessIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	targetAudiences?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeObjectEqualityInput"]> | undefined | null,
	status?: ResolverInputTypes["EnumAdBoardStatusFilter"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["EnumAdboardDraftStatusNullableFilter"] | undefined | null,
	isLive?: ResolverInputTypes["BoolFilter"] | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookObjectEqualityInput"]> | undefined | null,
	numberOfDisplays?: ResolverInputTypes["IntFilter"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["IntFilter"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["IntFilter"] | undefined | null,
	adsDuration?: ResolverInputTypes["IntFilter"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["BoolFilter"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	href?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	playerIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	additionalData?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	isDraft?: ResolverInputTypes["BoolFilter"] | undefined | null,
	publishedVersionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	isArchived?: ResolverInputTypes["BoolFilter"] | undefined | null,
	archivedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryNullableRelationFilter"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryNullableRelationFilter"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeNullableRelationFilter"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryNullableRelationFilter"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	orders?: ResolverInputTypes["OrderListRelationFilter"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationListRelationFilter"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	createdBy?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null,
	players?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupNullableRelationFilter"] | undefined | null,
	cameras?: ResolverInputTypes["CameraListRelationFilter"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardNullableRelationFilter"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardNullableRelationFilter"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryListRelationFilter"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentListRelationFilter"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewListRelationFilter"] | undefined | null
};
	["StringFilter"]: {
	equals?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	mode?: ResolverInputTypes["QueryMode"] | undefined | null,
	not?: ResolverInputTypes["NestedStringFilter"] | undefined | null
};
	["QueryMode"]:QueryMode;
	["NestedStringFilter"]: {
	equals?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	not?: ResolverInputTypes["NestedStringFilter"] | undefined | null
};
	["MapCompositeFilter"]: {
	equals?: ResolverInputTypes["MapObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["MapWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["MapWhereInput"] | undefined | null
};
	["MapObjectEqualityInput"]: {
	lat: number,
	lng: number,
	address?: string | undefined | null,
	city?: string | undefined | null,
	state?: string | undefined | null,
	country?: string | undefined | null
};
	["MapWhereInput"]: {
	AND?: Array<ResolverInputTypes["MapWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["MapWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["MapWhereInput"]> | undefined | null,
	lat?: ResolverInputTypes["FloatFilter"] | undefined | null,
	lng?: ResolverInputTypes["FloatFilter"] | undefined | null,
	address?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	city?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	state?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	country?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["FloatFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedFloatFilter"] | undefined | null
};
	["NestedFloatFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedFloatFilter"] | undefined | null
};
	["StringNullableFilter"]: {
	equals?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	mode?: ResolverInputTypes["QueryMode"] | undefined | null,
	not?: ResolverInputTypes["NestedStringNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedStringNullableFilter"]: {
	equals?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	not?: ResolverInputTypes["NestedStringNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["BoolFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["NestedBoolFilter"] | undefined | null
};
	["NestedBoolFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["NestedBoolFilter"] | undefined | null
};
	["IntFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedIntFilter"] | undefined | null
};
	["NestedIntFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedIntFilter"] | undefined | null
};
	["IntNullableFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedIntNullableFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["SpecificationObjectEqualityInput"]: {
	name: string,
	value?: string | undefined | null
};
	["StringNullableListFilter"]: {
	equals?: Array<string> | undefined | null,
	has?: string | undefined | null,
	hasEvery?: Array<string> | undefined | null,
	hasSome?: Array<string> | undefined | null,
	isEmpty?: boolean | undefined | null
};
	["FloatNullableFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedFloatNullableFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["ResolutionNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["ResolutionObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["ResolutionWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["ResolutionWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["ResolutionObjectEqualityInput"]: {
	height: number,
	width: number
};
	["ResolutionWhereInput"]: {
	AND?: Array<ResolverInputTypes["ResolutionWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["ResolutionWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["ResolutionWhereInput"]> | undefined | null,
	height?: ResolverInputTypes["IntFilter"] | undefined | null,
	width?: ResolverInputTypes["IntFilter"] | undefined | null
};
	["ScreenSizeNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["ScreenSizeObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["ScreenSizeWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["ScreenSizeWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["ScreenSizeObjectEqualityInput"]: {
	height: number,
	unit: ResolverInputTypes["MeasurementUnit"],
	width: number
};
	["MeasurementUnit"]:MeasurementUnit;
	["ScreenSizeWhereInput"]: {
	AND?: Array<ResolverInputTypes["ScreenSizeWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["ScreenSizeWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["ScreenSizeWhereInput"]> | undefined | null,
	height?: ResolverInputTypes["FloatFilter"] | undefined | null,
	unit?: ResolverInputTypes["EnumMeasurementUnitFilter"] | undefined | null,
	width?: ResolverInputTypes["FloatFilter"] | undefined | null
};
	["EnumMeasurementUnitFilter"]: {
	equals?: ResolverInputTypes["MeasurementUnit"] | undefined | null,
	in?: Array<ResolverInputTypes["MeasurementUnit"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["MeasurementUnit"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumMeasurementUnitFilter"] | undefined | null
};
	["NestedEnumMeasurementUnitFilter"]: {
	equals?: ResolverInputTypes["MeasurementUnit"] | undefined | null,
	in?: Array<ResolverInputTypes["MeasurementUnit"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["MeasurementUnit"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumMeasurementUnitFilter"] | undefined | null
};
	["PlaybackTimeObjectEqualityInput"]: {
	name?: string | undefined | null,
	startTime: string,
	endTime: string
};
	["EnumAdBoardStatusFilter"]: {
	equals?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["AdBoardStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdBoardStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdBoardStatusFilter"] | undefined | null
};
	["NestedEnumAdBoardStatusFilter"]: {
	equals?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["AdBoardStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdBoardStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdBoardStatusFilter"] | undefined | null
};
	["EnumAdboardDraftStatusNullableFilter"]: {
	equals?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["AdboardDraftStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdboardDraftStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedEnumAdboardDraftStatusNullableFilter"]: {
	equals?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["AdboardDraftStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdboardDraftStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["WebhookObjectEqualityInput"]: {
	url: string,
	headers?: ResolverInputTypes["JSON"] | undefined | null,
	body?: ResolverInputTypes["JSON"] | undefined | null,
	method: string
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["DateTimeNullableFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["NestedDateTimeNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedDateTimeNullableFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["NestedDateTimeNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["JsonNullableFilter"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not?: ResolverInputTypes["JSON"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["LocationCategoryNullableRelationFilter"]: {
	is?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null
};
	["LocationCategoryWhereInput"]: {
	AND?: Array<ResolverInputTypes["LocationCategoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["LocationCategoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["LocationCategoryWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryListRelationFilter"] | undefined | null
};
	["AdBoardListRelationFilter"]: {
	every?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	some?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	none?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["LocationSubCategoryListRelationFilter"]: {
	every?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null,
	some?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null,
	none?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null
};
	["LocationSubCategoryWhereInput"]: {
	AND?: Array<ResolverInputTypes["LocationSubCategoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["LocationSubCategoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["LocationSubCategoryWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryNullableRelationFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null
};
	["LocationSubCategoryNullableRelationFilter"]: {
	is?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null
};
	["AdBoardTypeNullableRelationFilter"]: {
	is?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null
};
	["AdBoardTypeWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardTypeWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardTypeWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardTypeWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null
};
	["BusinessCategoryNullableRelationFilter"]: {
	is?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null
};
	["BusinessCategoryWhereInput"]: {
	AND?: Array<ResolverInputTypes["BusinessCategoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BusinessCategoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BusinessCategoryWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	restrictedByAdBoardIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null
};
	["CampaignListRelationFilter"]: {
	every?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,
	some?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,
	none?: ResolverInputTypes["CampaignWhereInput"] | undefined | null
};
	["CampaignWhereInput"]: {
	AND?: Array<ResolverInputTypes["CampaignWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CampaignWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CampaignWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingFor?: ResolverInputTypes["StringFilter"] | undefined | null,
	purpose?: ResolverInputTypes["StringFilter"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["StringFilter"] | undefined | null,
	adCategoryId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentNullableCompositeFilter"] | undefined | null,
	paymentStatus?: ResolverInputTypes["EnumPaymentStatusFilter"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryNullableCompositeFilter"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCompositeFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringFilter"] | undefined | null,
	assetsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryRelationFilter"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	payments?: ResolverInputTypes["PaymentListRelationFilter"] | undefined | null,
	assets?: ResolverInputTypes["FileListRelationFilter"] | undefined | null
};
	["DateTimeFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["NestedDateTimeFilter"] | undefined | null
};
	["NestedDateTimeFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["NestedDateTimeFilter"] | undefined | null
};
	["BookingPaymentNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["BookingPaymentObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["BookingPaymentWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BookingPaymentWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["BookingPaymentObjectEqualityInput"]: {
	liveMode: boolean,
	checkoutSessionId?: string | undefined | null,
	customer?: ResolverInputTypes["BookingCustomerObjectEqualityInput"] | undefined | null,
	paymentGateway: ResolverInputTypes["PaymentGatewayName"],
	paymentId: string,
	sessionId?: string | undefined | null,
	totalDetails?: ResolverInputTypes["PaymentTotalDetailsObjectEqualityInput"] | undefined | null,
	razorpay?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["JSON"] | undefined | null
};
	["BookingCustomerObjectEqualityInput"]: {
	customerId: string,
	customerDetails?: ResolverInputTypes["PaymentCustomerDetailsObjectEqualityInput"] | undefined | null
};
	["PaymentCustomerDetailsObjectEqualityInput"]: {
	email: string,
	name: string,
	phone?: string | undefined | null
};
	["PaymentGatewayName"]:PaymentGatewayName;
	["PaymentTotalDetailsObjectEqualityInput"]: {
	discount: number,
	shipping: number,
	subtotal: number,
	tax: number,
	total: number
};
	["BookingPaymentWhereInput"]: {
	AND?: Array<ResolverInputTypes["BookingPaymentWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingPaymentWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingPaymentWhereInput"]> | undefined | null,
	liveMode?: ResolverInputTypes["BoolFilter"] | undefined | null,
	checkoutSessionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	customer?: ResolverInputTypes["BookingCustomerNullableCompositeFilter"] | undefined | null,
	paymentGateway?: ResolverInputTypes["EnumPaymentGatewayNameFilter"] | undefined | null,
	paymentId?: ResolverInputTypes["StringFilter"] | undefined | null,
	sessionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	totalDetails?: ResolverInputTypes["PaymentTotalDetailsNullableCompositeFilter"] | undefined | null,
	razorpay?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	stripe?: ResolverInputTypes["JsonNullableFilter"] | undefined | null
};
	["BookingCustomerNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["BookingCustomerObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["BookingCustomerWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BookingCustomerWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["BookingCustomerWhereInput"]: {
	AND?: Array<ResolverInputTypes["BookingCustomerWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingCustomerWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingCustomerWhereInput"]> | undefined | null,
	customerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	customerDetails?: ResolverInputTypes["PaymentCustomerDetailsNullableCompositeFilter"] | undefined | null
};
	["PaymentCustomerDetailsNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["PaymentCustomerDetailsObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["PaymentCustomerDetailsWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["PaymentCustomerDetailsWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["PaymentCustomerDetailsWhereInput"]: {
	AND?: Array<ResolverInputTypes["PaymentCustomerDetailsWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PaymentCustomerDetailsWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PaymentCustomerDetailsWhereInput"]> | undefined | null,
	email?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	phone?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["EnumPaymentGatewayNameFilter"]: {
	equals?: ResolverInputTypes["PaymentGatewayName"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentGatewayName"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentGatewayName"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentGatewayNameFilter"] | undefined | null
};
	["NestedEnumPaymentGatewayNameFilter"]: {
	equals?: ResolverInputTypes["PaymentGatewayName"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentGatewayName"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentGatewayName"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentGatewayNameFilter"] | undefined | null
};
	["PaymentTotalDetailsNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["PaymentTotalDetailsObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["PaymentTotalDetailsWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["PaymentTotalDetailsWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["PaymentTotalDetailsWhereInput"]: {
	AND?: Array<ResolverInputTypes["PaymentTotalDetailsWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PaymentTotalDetailsWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PaymentTotalDetailsWhereInput"]> | undefined | null,
	discount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	shipping?: ResolverInputTypes["FloatFilter"] | undefined | null,
	subtotal?: ResolverInputTypes["FloatFilter"] | undefined | null,
	tax?: ResolverInputTypes["FloatFilter"] | undefined | null,
	total?: ResolverInputTypes["FloatFilter"] | undefined | null
};
	["EnumPaymentStatusFilter"]: {
	equals?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentStatusFilter"] | undefined | null
};
	["PaymentStatus"]:PaymentStatus;
	["NestedEnumPaymentStatusFilter"]: {
	equals?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentStatusFilter"] | undefined | null
};
	["PriceSummaryNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["PriceSummaryObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["PriceSummaryWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["PriceSummaryWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["PriceSummaryObjectEqualityInput"]: {
	days: number,
	discount?: number | undefined | null,
	payableRent: number,
	rentPerDay: number,
	serviceCharge: number,
	totalRent: number
};
	["PriceSummaryWhereInput"]: {
	AND?: Array<ResolverInputTypes["PriceSummaryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PriceSummaryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PriceSummaryWhereInput"]> | undefined | null,
	days?: ResolverInputTypes["IntFilter"] | undefined | null,
	discount?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	payableRent?: ResolverInputTypes["FloatFilter"] | undefined | null,
	rentPerDay?: ResolverInputTypes["IntFilter"] | undefined | null,
	serviceCharge?: ResolverInputTypes["FloatFilter"] | undefined | null,
	totalRent?: ResolverInputTypes["IntFilter"] | undefined | null
};
	["OrderSelectedDateCompositeFilter"]: {
	equals?: ResolverInputTypes["OrderSelectedDateObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["OrderSelectedDateWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["OrderSelectedDateWhereInput"] | undefined | null
};
	["OrderSelectedDateObjectEqualityInput"]: {
	endDate: ResolverInputTypes["DateTime"],
	startDate: ResolverInputTypes["DateTime"]
};
	["OrderSelectedDateWhereInput"]: {
	AND?: Array<ResolverInputTypes["OrderSelectedDateWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["OrderSelectedDateWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["OrderSelectedDateWhereInput"]> | undefined | null,
	endDate?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	startDate?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["UserRelationFilter"]: {
	is?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserWhereInput"]: {
	AND?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	uid?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	email?: ResolverInputTypes["StringFilter"] | undefined | null,
	image?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	phoneNumber?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	otherDetails?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeNullableCompositeFilter"] | undefined | null,
	roles?: ResolverInputTypes["EnumRoleNullableListFilter"] | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundObjectEqualityInput"]> | undefined | null,
	sharedBookingIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["BoolFilter"] | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceNullableCompositeFilter"] | undefined | null,
	leadWorkspaceIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	orders?: ResolverInputTypes["OrderListRelationFilter"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogListRelationFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	audits?: ResolverInputTypes["AuditListRelationFilter"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignListRelationFilter"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipListRelationFilter"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationListRelationFilter"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryListRelationFilter"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentListRelationFilter"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceListRelationFilter"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceListRelationFilter"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewListRelationFilter"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionListRelationFilter"] | undefined | null
};
	["UserStripeNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["UserStripeObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["UserStripeWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["UserStripeWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["UserStripeObjectEqualityInput"]: {
	customerId: string
};
	["UserStripeWhereInput"]: {
	AND?: Array<ResolverInputTypes["UserStripeWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserStripeWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserStripeWhereInput"]> | undefined | null,
	customerId?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["EnumRoleNullableListFilter"]: {
	equals?: Array<ResolverInputTypes["Role"]> | undefined | null,
	has?: ResolverInputTypes["Role"] | undefined | null,
	hasEvery?: Array<ResolverInputTypes["Role"]> | undefined | null,
	hasSome?: Array<ResolverInputTypes["Role"]> | undefined | null,
	isEmpty?: boolean | undefined | null
};
	["Role"]:Role;
	["BookingRefundObjectEqualityInput"]: {
	id: string,
	razorpay?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["JSON"] | undefined | null,
	createdByUserId: string
};
	["NotificationPreferenceNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["NotificationPreferenceObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["NotificationPreferenceWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["NotificationPreferenceWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NotificationPreferenceObjectEqualityInput"]: {
	type?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null,
	pushSetting?: ResolverInputTypes["PushSettingObjectEqualityInput"] | undefined | null,
	enabled: boolean
};
	["NotificationType"]:NotificationType;
	["NotificationChannel"]:NotificationChannel;
	["PushSettingObjectEqualityInput"]: {
	fcmToken: string
};
	["NotificationPreferenceWhereInput"]: {
	AND?: Array<ResolverInputTypes["NotificationPreferenceWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["NotificationPreferenceWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["NotificationPreferenceWhereInput"]> | undefined | null,
	type?: ResolverInputTypes["EnumNotificationTypeNullableListFilter"] | undefined | null,
	channels?: ResolverInputTypes["EnumNotificationChannelNullableListFilter"] | undefined | null,
	pushSetting?: ResolverInputTypes["PushSettingNullableCompositeFilter"] | undefined | null,
	enabled?: ResolverInputTypes["BoolFilter"] | undefined | null
};
	["EnumNotificationTypeNullableListFilter"]: {
	equals?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	has?: ResolverInputTypes["NotificationType"] | undefined | null,
	hasEvery?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	hasSome?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	isEmpty?: boolean | undefined | null
};
	["EnumNotificationChannelNullableListFilter"]: {
	equals?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null,
	has?: ResolverInputTypes["NotificationChannel"] | undefined | null,
	hasEvery?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null,
	hasSome?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null,
	isEmpty?: boolean | undefined | null
};
	["PushSettingNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["PushSettingObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["PushSettingWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["PushSettingWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["PushSettingWhereInput"]: {
	AND?: Array<ResolverInputTypes["PushSettingWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PushSettingWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PushSettingWhereInput"]> | undefined | null,
	fcmToken?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["BookingListRelationFilter"]: {
	every?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	some?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	none?: ResolverInputTypes["BookingWhereInput"] | undefined | null
};
	["BookingWhereInput"]: {
	AND?: Array<ResolverInputTypes["BookingWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	additionalInformation?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	assetsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingCode?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingDate?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingFor?: ResolverInputTypes["StringFilter"] | undefined | null,
	purpose?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	campaignId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adCategoryId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCompositeFilter"] | undefined | null,
	bookingStatus?: ResolverInputTypes["EnumBookingStatusFilter"] | undefined | null,
	paymentStatus?: ResolverInputTypes["EnumPaymentStatusNullableFilter"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["EnumDeploymentStatusFilter"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["EnumContentApprovalStatusFilter"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCompositeFilter"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentNullableCompositeFilter"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryNullableCompositeFilter"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataNullableCompositeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundNullableCompositeFilter"] | undefined | null,
	sharedUserIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	isOffline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isPaid?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isScheduled?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isDeployed?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isCompleted?: ResolverInputTypes["BoolFilter"] | undefined | null,
	assets?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryNullableRelationFilter"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryListRelationFilter"] | undefined | null,
	proofs?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogListRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignNullableRelationFilter"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutListRelationFilter"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserListRelationFilter"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogListRelationFilter"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageListRelationFilter"] | undefined | null
};
	["BookingDetailsCompositeFilter"]: {
	equals?: ResolverInputTypes["BookingDetailsObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["BookingDetailsWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BookingDetailsWhereInput"] | undefined | null
};
	["BookingDetailsObjectEqualityInput"]: {
	additionalInformation?: string | undefined | null,
	email: string,
	name: string,
	phone?: string | undefined | null
};
	["BookingDetailsWhereInput"]: {
	AND?: Array<ResolverInputTypes["BookingDetailsWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingDetailsWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingDetailsWhereInput"]> | undefined | null,
	additionalInformation?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	email?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	phone?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["EnumBookingStatusFilter"]: {
	equals?: ResolverInputTypes["BookingStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["BookingStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["BookingStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumBookingStatusFilter"] | undefined | null
};
	["BookingStatus"]:BookingStatus;
	["NestedEnumBookingStatusFilter"]: {
	equals?: ResolverInputTypes["BookingStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["BookingStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["BookingStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumBookingStatusFilter"] | undefined | null
};
	["EnumPaymentStatusNullableFilter"]: {
	equals?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedEnumPaymentStatusNullableFilter"]: {
	equals?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["EnumDeploymentStatusFilter"]: {
	equals?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["DeploymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DeploymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumDeploymentStatusFilter"] | undefined | null
};
	["DeploymentStatus"]:DeploymentStatus;
	["NestedEnumDeploymentStatusFilter"]: {
	equals?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["DeploymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DeploymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumDeploymentStatusFilter"] | undefined | null
};
	["EnumContentApprovalStatusFilter"]: {
	equals?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentApprovalStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentApprovalStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null
};
	["ContentApprovalStatus"]:ContentApprovalStatus;
	["NestedEnumContentApprovalStatusFilter"]: {
	equals?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentApprovalStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentApprovalStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null
};
	["BookingPisignageDataNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["BookingPisignageDataObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["BookingPisignageDataWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BookingPisignageDataWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["BookingPisignageDataObjectEqualityInput"]: {
	deployedFileName?: string | undefined | null,
	playlistId?: string | undefined | null
};
	["BookingPisignageDataWhereInput"]: {
	AND?: Array<ResolverInputTypes["BookingPisignageDataWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingPisignageDataWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingPisignageDataWhereInput"]> | undefined | null,
	deployedFileName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	playlistId?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["BookingRefundNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["BookingRefundObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["BookingRefundWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BookingRefundWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["BookingRefundWhereInput"]: {
	AND?: Array<ResolverInputTypes["BookingRefundWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingRefundWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingRefundWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	razorpay?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	stripe?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["FileListRelationFilter"]: {
	every?: ResolverInputTypes["FileWhereInput"] | undefined | null,
	some?: ResolverInputTypes["FileWhereInput"] | undefined | null,
	none?: ResolverInputTypes["FileWhereInput"] | undefined | null
};
	["FileWhereInput"]: {
	AND?: Array<ResolverInputTypes["FileWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["FileWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["FileWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	contentType?: ResolverInputTypes["StringFilter"] | undefined | null,
	ctime?: ResolverInputTypes["StringFilter"] | undefined | null,
	filename?: ResolverInputTypes["StringFilter"] | undefined | null,
	filepath?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isDirectory?: ResolverInputTypes["BoolFilter"] | undefined | null,
	meta?: ResolverInputTypes["FileMetaCompositeFilter"] | undefined | null,
	mtime?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	size?: ResolverInputTypes["IntFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumFileStatusNullableFilter"] | undefined | null,
	type?: ResolverInputTypes["StringFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringFilter"] | undefined | null,
	url?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	proofBookingId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	campaignIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	updatedBy?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	createdBy?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingNullableRelationFilter"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignListRelationFilter"] | undefined | null
};
	["FileMetaCompositeFilter"]: {
	equals?: ResolverInputTypes["FileMetaObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["FileMetaWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["FileMetaWhereInput"] | undefined | null
};
	["FileMetaObjectEqualityInput"]: {
	duration: number,
	height: number,
	width: number
};
	["FileMetaWhereInput"]: {
	AND?: Array<ResolverInputTypes["FileMetaWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["FileMetaWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["FileMetaWhereInput"]> | undefined | null,
	duration?: ResolverInputTypes["FloatFilter"] | undefined | null,
	height?: ResolverInputTypes["IntFilter"] | undefined | null,
	width?: ResolverInputTypes["IntFilter"] | undefined | null
};
	["EnumFileStatusNullableFilter"]: {
	equals?: ResolverInputTypes["FileStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["FileStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["FileStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumFileStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["FileStatus"]:FileStatus;
	["NestedEnumFileStatusNullableFilter"]: {
	equals?: ResolverInputTypes["FileStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["FileStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["FileStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumFileStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["UserNullableRelationFilter"]: {
	is?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["WorkspaceNullableRelationFilter"]: {
	is?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceWhereInput"]: {
	AND?: Array<ResolverInputTypes["WorkspaceWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WorkspaceWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WorkspaceWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumWorkspaceTypeFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	key?: ResolverInputTypes["StringFilter"] | undefined | null,
	userIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	ownerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	avatar?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	leadIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	owner?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipListRelationFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistListRelationFilter"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupListRelationFilter"] | undefined | null,
	audits?: ResolverInputTypes["AuditListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	files?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	players?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionListRelationFilter"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionNullableRelationFilter"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerListRelationFilter"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleListRelationFilter"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogListRelationFilter"] | undefined | null,
	leads?: ResolverInputTypes["UserListRelationFilter"] | undefined | null
};
	["EnumWorkspaceTypeFilter"]: {
	equals?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	in?: Array<ResolverInputTypes["WorkspaceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["WorkspaceType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null
};
	["WorkspaceType"]:WorkspaceType;
	["NestedEnumWorkspaceTypeFilter"]: {
	equals?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	in?: Array<ResolverInputTypes["WorkspaceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["WorkspaceType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null
};
	["MembershipListRelationFilter"]: {
	every?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,
	some?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,
	none?: ResolverInputTypes["MembershipWhereInput"] | undefined | null
};
	["MembershipWhereInput"]: {
	AND?: Array<ResolverInputTypes["MembershipWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["MembershipWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["MembershipWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	roleId?: ResolverInputTypes["StringFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null,
	role?: ResolverInputTypes["WorkspaceRoleRelationFilter"] | undefined | null
};
	["WorkspaceRelationFilter"]: {
	is?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceRoleRelationFilter"]: {
	is?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null
};
	["WorkspaceRoleWhereInput"]: {
	AND?: Array<ResolverInputTypes["WorkspaceRoleWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WorkspaceRoleWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WorkspaceRoleWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	permissions?: ResolverInputTypes["EnumPermissionNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipListRelationFilter"] | undefined | null
};
	["EnumPermissionNullableListFilter"]: {
	equals?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	has?: ResolverInputTypes["Permission"] | undefined | null,
	hasEvery?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	hasSome?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	isEmpty?: boolean | undefined | null
};
	["Permission"]:Permission;
	["PlaylistListRelationFilter"]: {
	every?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	some?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	none?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null
};
	["PlaylistWhereInput"]: {
	AND?: Array<ResolverInputTypes["PlaylistWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlaylistWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlaylistWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileObjectEqualityInput"]> | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	layout?: ResolverInputTypes["EnumScreenLayoutFilter"] | undefined | null,
	isDeployed?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	adBoardGroupIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistNullableRelationFilter"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistNullableRelationFilter"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupListRelationFilter"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupListRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistListRelationFilter"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistListRelationFilter"] | undefined | null
};
	["PisignageFileObjectEqualityInput"]: {
	name: string,
	duration?: number | undefined | null,
	muteAudio?: boolean | undefined | null,
	isVideo?: boolean | undefined | null
};
	["BoolNullableFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["NestedBoolNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedBoolNullableFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["NestedBoolNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["EnumScreenLayoutFilter"]: {
	equals?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	in?: Array<ResolverInputTypes["ScreenLayout"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ScreenLayout"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumScreenLayoutFilter"] | undefined | null
};
	["ScreenLayout"]:ScreenLayout;
	["NestedEnumScreenLayoutFilter"]: {
	equals?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	in?: Array<ResolverInputTypes["ScreenLayout"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ScreenLayout"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumScreenLayoutFilter"] | undefined | null
};
	["PlaylistNullableRelationFilter"]: {
	is?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null
};
	["AdBoardGroupListRelationFilter"]: {
	every?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,
	some?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,
	none?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null
};
	["AdBoardGroupWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardGroupWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardGroupWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardGroupWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleObjectEqualityInput"]> | undefined | null,
	scheduledPlaylistsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["StringFilter"] | undefined | null,
	fitImage?: ResolverInputTypes["EnumFitTypeFilter"] | undefined | null,
	fitVideo?: ResolverInputTypes["EnumFitTypeFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardNullableRelationFilter"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistListRelationFilter"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistNullableRelationFilter"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null
};
	["ScheduleObjectEqualityInput"]: {
	playlistId: string,
	playlistType?: string | undefined | null,
	skipForSchedule?: boolean | undefined | null,
	startTime?: string | undefined | null,
	endTime?: string | undefined | null
};
	["EnumFitTypeFilter"]: {
	equals?: ResolverInputTypes["FitType"] | undefined | null,
	in?: Array<ResolverInputTypes["FitType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["FitType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumFitTypeFilter"] | undefined | null
};
	["FitType"]:FitType;
	["NestedEnumFitTypeFilter"]: {
	equals?: ResolverInputTypes["FitType"] | undefined | null,
	in?: Array<ResolverInputTypes["FitType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["FitType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumFitTypeFilter"] | undefined | null
};
	["AdBoardNullableRelationFilter"]: {
	is?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["PlayerListRelationFilter"]: {
	every?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,
	some?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,
	none?: ResolverInputTypes["PlayerWhereInput"] | undefined | null
};
	["PlayerWhereInput"]: {
	AND?: Array<ResolverInputTypes["PlayerWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlayerWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlayerWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	configLocation?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	playlistIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workpaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	resolution?: ResolverInputTypes["ResolutionNullableCompositeFilter"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeNullableCompositeFilter"] | undefined | null,
	signageTypeId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	audienceTypeId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeObjectEqualityInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["EnumAspectRatioTypeFilter"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileObjectEqualityInput"]> | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	map?: ResolverInputTypes["MapNullableCompositeFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	adBoardIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupNullableRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusListRelationFilter"] | undefined | null,
	cameras?: ResolverInputTypes["CameraListRelationFilter"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageNullableRelationFilter"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceListRelationFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null
};
	["EnumAspectRatioTypeFilter"]: {
	equals?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	in?: Array<ResolverInputTypes["AspectRatioType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AspectRatioType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null
};
	["AspectRatioType"]:AspectRatioType;
	["NestedEnumAspectRatioTypeFilter"]: {
	equals?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	in?: Array<ResolverInputTypes["AspectRatioType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AspectRatioType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null
};
	["GalleryFileObjectEqualityInput"]: {
	name: string,
	url: string,
	contentType: string,
	width: number,
	height: number,
	duration?: number | undefined | null
};
	["MapNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["MapObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["MapWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["MapWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["AdBoardGroupNullableRelationFilter"]: {
	is?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null
};
	["PlayerUptimeStatusListRelationFilter"]: {
	every?: ResolverInputTypes["PlayerUptimeStatusWhereInput"] | undefined | null,
	some?: ResolverInputTypes["PlayerUptimeStatusWhereInput"] | undefined | null,
	none?: ResolverInputTypes["PlayerUptimeStatusWhereInput"] | undefined | null
};
	["PlayerUptimeStatusWhereInput"]: {
	AND?: Array<ResolverInputTypes["PlayerUptimeStatusWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlayerUptimeStatusWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlayerUptimeStatusWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	playerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["StringFilter"] | undefined | null,
	uptime?: ResolverInputTypes["FloatFilter"] | undefined | null,
	player?: ResolverInputTypes["PlayerRelationFilter"] | undefined | null
};
	["PlayerRelationFilter"]: {
	is?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["PlayerWhereInput"] | undefined | null
};
	["CameraListRelationFilter"]: {
	every?: ResolverInputTypes["CameraWhereInput"] | undefined | null,
	some?: ResolverInputTypes["CameraWhereInput"] | undefined | null,
	none?: ResolverInputTypes["CameraWhereInput"] | undefined | null
};
	["CameraWhereInput"]: {
	AND?: Array<ResolverInputTypes["CameraWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	cameraServerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	cameraUrl?: ResolverInputTypes["StringFilter"] | undefined | null,
	rtspUrl?: ResolverInputTypes["StringFilter"] | undefined | null,
	cameraIp?: ResolverInputTypes["StringFilter"] | undefined | null,
	connectedCpuSerialNumbers?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	playerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	isPublic?: ResolverInputTypes["BoolFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerRelationFilter"] | undefined | null,
	player?: ResolverInputTypes["PlayerNullableRelationFilter"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedListRelationFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardNullableRelationFilter"] | undefined | null
};
	["CameraServerRelationFilter"]: {
	is?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null
};
	["CameraServerWhereInput"]: {
	AND?: Array<ResolverInputTypes["CameraServerWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraServerWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraServerWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	hostName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	userName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	deviceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	publicIp?: ResolverInputTypes["StringFilter"] | undefined | null,
	privateIp?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	cameras?: ResolverInputTypes["CameraListRelationFilter"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedListRelationFilter"] | undefined | null
};
	["CameraFeedListRelationFilter"]: {
	every?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,
	some?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,
	none?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null
};
	["CameraFeedWhereInput"]: {
	AND?: Array<ResolverInputTypes["CameraFeedWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraFeedWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraFeedWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	cameraId?: ResolverInputTypes["StringFilter"] | undefined | null,
	timestamp?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	cameraServerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	data?: ResolverInputTypes["CameraFeedDataCompositeFilter"] | undefined | null,
	camera?: ResolverInputTypes["CameraRelationFilter"] | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerRelationFilter"] | undefined | null
};
	["CameraFeedDataCompositeFilter"]: {
	equals?: ResolverInputTypes["CameraFeedDataObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["CameraFeedDataWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["CameraFeedDataWhereInput"] | undefined | null
};
	["CameraFeedDataObjectEqualityInput"]: {
	person?: number | undefined | null,
	car?: number | undefined | null,
	truck?: number | undefined | null,
	motorcycle?: number | undefined | null
};
	["CameraFeedDataWhereInput"]: {
	AND?: Array<ResolverInputTypes["CameraFeedDataWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraFeedDataWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraFeedDataWhereInput"]> | undefined | null,
	person?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	car?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	truck?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	motorcycle?: ResolverInputTypes["IntNullableFilter"] | undefined | null
};
	["CameraRelationFilter"]: {
	is?: ResolverInputTypes["CameraWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["CameraWhereInput"] | undefined | null
};
	["PlayerNullableRelationFilter"]: {
	is?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["PlayerWhereInput"] | undefined | null
};
	["DigitalSignageNullableRelationFilter"]: {
	is?: ResolverInputTypes["DigitalSignageWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["DigitalSignageWhereInput"] | undefined | null
};
	["DigitalSignageWhereInput"]: {
	AND?: Array<ResolverInputTypes["DigitalSignageWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["DigitalSignageWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["DigitalSignageWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumSignageTypeFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	players?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null
};
	["EnumSignageTypeFilter"]: {
	equals?: ResolverInputTypes["SignageType"] | undefined | null,
	in?: Array<ResolverInputTypes["SignageType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["SignageType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumSignageTypeFilter"] | undefined | null
};
	["SignageType"]:SignageType;
	["NestedEnumSignageTypeFilter"]: {
	equals?: ResolverInputTypes["SignageType"] | undefined | null,
	in?: Array<ResolverInputTypes["SignageType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["SignageType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumSignageTypeFilter"] | undefined | null
};
	["AudienceListRelationFilter"]: {
	every?: ResolverInputTypes["AudienceWhereInput"] | undefined | null,
	some?: ResolverInputTypes["AudienceWhereInput"] | undefined | null,
	none?: ResolverInputTypes["AudienceWhereInput"] | undefined | null
};
	["AudienceWhereInput"]: {
	AND?: Array<ResolverInputTypes["AudienceWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AudienceWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AudienceWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumAudienceTypeFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	playerIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	players?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null
};
	["EnumAudienceTypeFilter"]: {
	equals?: ResolverInputTypes["AudienceType"] | undefined | null,
	in?: Array<ResolverInputTypes["AudienceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AudienceType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAudienceTypeFilter"] | undefined | null
};
	["AudienceType"]:AudienceType;
	["NestedEnumAudienceTypeFilter"]: {
	equals?: ResolverInputTypes["AudienceType"] | undefined | null,
	in?: Array<ResolverInputTypes["AudienceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AudienceType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAudienceTypeFilter"] | undefined | null
};
	["AuditListRelationFilter"]: {
	every?: ResolverInputTypes["AuditWhereInput"] | undefined | null,
	some?: ResolverInputTypes["AuditWhereInput"] | undefined | null,
	none?: ResolverInputTypes["AuditWhereInput"] | undefined | null
};
	["AuditWhereInput"]: {
	AND?: Array<ResolverInputTypes["AuditWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AuditWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AuditWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	message?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["StringFilter"] | undefined | null,
	entity?: ResolverInputTypes["EntityNullableCompositeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updates?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null
};
	["EntityNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["EntityObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["EntityWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["EntityWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["EntityObjectEqualityInput"]: {
	type: string,
	id: string,
	name?: string | undefined | null
};
	["EntityWhereInput"]: {
	AND?: Array<ResolverInputTypes["EntityWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["EntityWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["EntityWhereInput"]> | undefined | null,
	type?: ResolverInputTypes["StringFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["TransactionListRelationFilter"]: {
	every?: ResolverInputTypes["TransactionWhereInput"] | undefined | null,
	some?: ResolverInputTypes["TransactionWhereInput"] | undefined | null,
	none?: ResolverInputTypes["TransactionWhereInput"] | undefined | null
};
	["TransactionWhereInput"]: {
	AND?: Array<ResolverInputTypes["TransactionWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["TransactionWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["TransactionWhereInput"]> | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	amount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	createAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	relatedEntityId?: ResolverInputTypes["StringFilter"] | undefined | null,
	transactionStatus?: ResolverInputTypes["EnumTransactionStatusFilter"] | undefined | null,
	details?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutListRelationFilter"] | undefined | null
};
	["EnumTransactionStatusFilter"]: {
	equals?: ResolverInputTypes["TransactionStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["TransactionStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["TransactionStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumTransactionStatusFilter"] | undefined | null
};
	["TransactionStatus"]:TransactionStatus;
	["NestedEnumTransactionStatusFilter"]: {
	equals?: ResolverInputTypes["TransactionStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["TransactionStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["TransactionStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumTransactionStatusFilter"] | undefined | null
};
	["PayoutListRelationFilter"]: {
	every?: ResolverInputTypes["PayoutWhereInput"] | undefined | null,
	some?: ResolverInputTypes["PayoutWhereInput"] | undefined | null,
	none?: ResolverInputTypes["PayoutWhereInput"] | undefined | null
};
	["PayoutWhereInput"]: {
	AND?: Array<ResolverInputTypes["PayoutWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PayoutWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PayoutWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	totalAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	commissionRate?: ResolverInputTypes["FloatFilter"] | undefined | null,
	commissionAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	netAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumPayoutStatusFilter"] | undefined | null,
	transactionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	booking?: ResolverInputTypes["BookingRelationFilter"] | undefined | null,
	transaction?: ResolverInputTypes["TransactionNullableRelationFilter"] | undefined | null
};
	["EnumPayoutStatusFilter"]: {
	equals?: ResolverInputTypes["PayoutStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PayoutStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PayoutStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPayoutStatusFilter"] | undefined | null
};
	["PayoutStatus"]:PayoutStatus;
	["NestedEnumPayoutStatusFilter"]: {
	equals?: ResolverInputTypes["PayoutStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PayoutStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PayoutStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPayoutStatusFilter"] | undefined | null
};
	["BookingRelationFilter"]: {
	is?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BookingWhereInput"] | undefined | null
};
	["TransactionNullableRelationFilter"]: {
	is?: ResolverInputTypes["TransactionWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["TransactionWhereInput"] | undefined | null
};
	["SubscriptionNullableRelationFilter"]: {
	is?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null
};
	["SubscriptionWhereInput"]: {
	AND?: Array<ResolverInputTypes["SubscriptionWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SubscriptionWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SubscriptionWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	packageId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	packageName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	priceLookupKey?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	startDate?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	endDate?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	status?: ResolverInputTypes["StringFilter"] | undefined | null,
	stripePaymentIntentId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["StringFilter"] | undefined | null,
	stripeCheckoutSessionId?: ResolverInputTypes["StringFilter"] | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsNullableCompositeFilter"] | undefined | null,
	activeWorkspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	activeWorkspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null
};
	["CancellationDetailsNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["CancellationDetailsObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["CancellationDetailsWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["CancellationDetailsWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["CancellationDetailsObjectEqualityInput"]: {
	canceledAt?: ResolverInputTypes["DateTime"] | undefined | null,
	cancelAt?: ResolverInputTypes["DateTime"] | undefined | null,
	cancelAtPeriodEnd: boolean,
	reason?: string | undefined | null
};
	["CancellationDetailsWhereInput"]: {
	AND?: Array<ResolverInputTypes["CancellationDetailsWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CancellationDetailsWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CancellationDetailsWhereInput"]> | undefined | null,
	canceledAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	cancelAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	cancelAtPeriodEnd?: ResolverInputTypes["BoolFilter"] | undefined | null,
	reason?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["CameraServerListRelationFilter"]: {
	every?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,
	some?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,
	none?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null
};
	["WorkspaceRoleListRelationFilter"]: {
	every?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,
	some?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,
	none?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null
};
	["WebhookLogListRelationFilter"]: {
	every?: ResolverInputTypes["WebhookLogWhereInput"] | undefined | null,
	some?: ResolverInputTypes["WebhookLogWhereInput"] | undefined | null,
	none?: ResolverInputTypes["WebhookLogWhereInput"] | undefined | null
};
	["WebhookLogWhereInput"]: {
	AND?: Array<ResolverInputTypes["WebhookLogWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WebhookLogWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WebhookLogWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	response?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	request?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	status?: ResolverInputTypes["IntFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	booking?: ResolverInputTypes["BookingRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null
};
	["UserListRelationFilter"]: {
	every?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	some?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	none?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["BookingNullableRelationFilter"]: {
	is?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BookingWhereInput"] | undefined | null
};
	["AdvertisementCategoryListRelationFilter"]: {
	every?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,
	some?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,
	none?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null
};
	["AdvertisementCategoryWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdvertisementCategoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdvertisementCategoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdvertisementCategoryWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumAdvertisementCategoryEnumFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	campaignIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null
};
	["EnumAdvertisementCategoryEnumFilter"]: {
	equals?: ResolverInputTypes["AdvertisementCategoryEnum"] | undefined | null,
	in?: Array<ResolverInputTypes["AdvertisementCategoryEnum"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdvertisementCategoryEnum"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null
};
	["AdvertisementCategoryEnum"]:AdvertisementCategoryEnum;
	["NestedEnumAdvertisementCategoryEnumFilter"]: {
	equals?: ResolverInputTypes["AdvertisementCategoryEnum"] | undefined | null,
	in?: Array<ResolverInputTypes["AdvertisementCategoryEnum"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdvertisementCategoryEnum"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null
};
	["AdBoardRelationFilter"]: {
	is?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["ChangelogListRelationFilter"]: {
	every?: ResolverInputTypes["ChangelogWhereInput"] | undefined | null,
	some?: ResolverInputTypes["ChangelogWhereInput"] | undefined | null,
	none?: ResolverInputTypes["ChangelogWhereInput"] | undefined | null
};
	["ChangelogWhereInput"]: {
	AND?: Array<ResolverInputTypes["ChangelogWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["ChangelogWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["ChangelogWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	message?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	updates?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	booking?: ResolverInputTypes["BookingNullableRelationFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["CampaignNullableRelationFilter"]: {
	is?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["CampaignWhereInput"] | undefined | null
};
	["SlotUsageListRelationFilter"]: {
	every?: ResolverInputTypes["SlotUsageWhereInput"] | undefined | null,
	some?: ResolverInputTypes["SlotUsageWhereInput"] | undefined | null,
	none?: ResolverInputTypes["SlotUsageWhereInput"] | undefined | null
};
	["SlotUsageWhereInput"]: {
	AND?: Array<ResolverInputTypes["SlotUsageWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SlotUsageWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SlotUsageWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	slotUsed?: ResolverInputTypes["IntFilter"] | undefined | null,
	booking?: ResolverInputTypes["BookingRelationFilter"] | undefined | null
};
	["OrderListRelationFilter"]: {
	every?: ResolverInputTypes["OrderWhereInput"] | undefined | null,
	some?: ResolverInputTypes["OrderWhereInput"] | undefined | null,
	none?: ResolverInputTypes["OrderWhereInput"] | undefined | null
};
	["OrderWhereInput"]: {
	AND?: Array<ResolverInputTypes["OrderWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["OrderWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["OrderWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdDate?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringFilter"] | undefined | null,
	paymentId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCompositeFilter"] | undefined | null,
	selectedDate?: ResolverInputTypes["OrderSelectedDateCompositeFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	createdBy?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null
};
	["PriceSummaryCompositeFilter"]: {
	equals?: ResolverInputTypes["PriceSummaryObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["PriceSummaryWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["PriceSummaryWhereInput"] | undefined | null
};
	["NotificationListRelationFilter"]: {
	every?: ResolverInputTypes["NotificationWhereInput"] | undefined | null,
	some?: ResolverInputTypes["NotificationWhereInput"] | undefined | null,
	none?: ResolverInputTypes["NotificationWhereInput"] | undefined | null
};
	["NotificationWhereInput"]: {
	AND?: Array<ResolverInputTypes["NotificationWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["NotificationWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["NotificationWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumNotificationTypeFilter"] | undefined | null,
	title?: ResolverInputTypes["StringFilter"] | undefined | null,
	content?: ResolverInputTypes["StringFilter"] | undefined | null,
	metadata?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	readAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	recipientId?: ResolverInputTypes["StringFilter"] | undefined | null,
	channels?: ResolverInputTypes["EnumNotificationChannelNullableListFilter"] | undefined | null,
	recipient?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["EnumNotificationTypeFilter"]: {
	equals?: ResolverInputTypes["NotificationType"] | undefined | null,
	in?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumNotificationTypeFilter"] | undefined | null
};
	["NestedEnumNotificationTypeFilter"]: {
	equals?: ResolverInputTypes["NotificationType"] | undefined | null,
	in?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumNotificationTypeFilter"] | undefined | null
};
	["AdBoardPublishHistoryListRelationFilter"]: {
	every?: ResolverInputTypes["AdBoardPublishHistoryWhereInput"] | undefined | null,
	some?: ResolverInputTypes["AdBoardPublishHistoryWhereInput"] | undefined | null,
	none?: ResolverInputTypes["AdBoardPublishHistoryWhereInput"] | undefined | null
};
	["AdBoardPublishHistoryWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	publishedByUserId?: ResolverInputTypes["StringFilter"] | undefined | null,
	changesSnapshot?: ResolverInputTypes["JsonFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	publishedBy?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["JsonFilter"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not?: ResolverInputTypes["JSON"] | undefined | null
};
	["AdBoardReviewCommentListRelationFilter"]: {
	every?: ResolverInputTypes["AdBoardReviewCommentWhereInput"] | undefined | null,
	some?: ResolverInputTypes["AdBoardReviewCommentWhereInput"] | undefined | null,
	none?: ResolverInputTypes["AdBoardReviewCommentWhereInput"] | undefined | null
};
	["AdBoardReviewCommentWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardReviewCommentWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardReviewCommentWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardReviewCommentWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	comment?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["EnumReviewActionFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["EnumReviewActionFilter"]: {
	equals?: ResolverInputTypes["ReviewAction"] | undefined | null,
	in?: Array<ResolverInputTypes["ReviewAction"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ReviewAction"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumReviewActionFilter"] | undefined | null
};
	["ReviewAction"]:ReviewAction;
	["NestedEnumReviewActionFilter"]: {
	equals?: ResolverInputTypes["ReviewAction"] | undefined | null,
	in?: Array<ResolverInputTypes["ReviewAction"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ReviewAction"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumReviewActionFilter"] | undefined | null
};
	["WorkspaceListRelationFilter"]: {
	every?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	some?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	none?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["AdBoardPublicReviewListRelationFilter"]: {
	every?: ResolverInputTypes["AdBoardPublicReviewWhereInput"] | undefined | null,
	some?: ResolverInputTypes["AdBoardPublicReviewWhereInput"] | undefined | null,
	none?: ResolverInputTypes["AdBoardPublicReviewWhereInput"] | undefined | null
};
	["AdBoardPublicReviewWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardPublicReviewWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardPublicReviewWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardPublicReviewWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	rating?: ResolverInputTypes["IntFilter"] | undefined | null,
	comment?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["UserPositionListRelationFilter"]: {
	every?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null,
	some?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null,
	none?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null
};
	["UserPositionWhereInput"]: {
	AND?: Array<ResolverInputTypes["UserPositionWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserPositionWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserPositionWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	timestamp?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	coords?: ResolverInputTypes["UserPositionCoordsCompositeFilter"] | undefined | null,
	location?: ResolverInputTypes["UserPositionLocationNullableCompositeFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["UserPositionCoordsCompositeFilter"]: {
	equals?: ResolverInputTypes["UserPositionCoordsObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["UserPositionCoordsWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["UserPositionCoordsWhereInput"] | undefined | null
};
	["UserPositionCoordsObjectEqualityInput"]: {
	accuracy?: number | undefined | null,
	altitude?: number | undefined | null,
	altitudeAccuracy?: number | undefined | null,
	heading?: number | undefined | null,
	latitude: number,
	longitude: number,
	speed?: number | undefined | null
};
	["UserPositionCoordsWhereInput"]: {
	AND?: Array<ResolverInputTypes["UserPositionCoordsWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserPositionCoordsWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserPositionCoordsWhereInput"]> | undefined | null,
	accuracy?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	altitude?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	altitudeAccuracy?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	heading?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	latitude?: ResolverInputTypes["FloatFilter"] | undefined | null,
	longitude?: ResolverInputTypes["FloatFilter"] | undefined | null,
	speed?: ResolverInputTypes["FloatNullableFilter"] | undefined | null
};
	["UserPositionLocationNullableCompositeFilter"]: {
	equals?: ResolverInputTypes["UserPositionLocationObjectEqualityInput"] | undefined | null,
	is?: ResolverInputTypes["UserPositionLocationWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["UserPositionLocationWhereInput"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["UserPositionLocationObjectEqualityInput"]: {
	address?: string | undefined | null,
	city?: string | undefined | null,
	state?: string | undefined | null,
	country?: string | undefined | null
};
	["UserPositionLocationWhereInput"]: {
	AND?: Array<ResolverInputTypes["UserPositionLocationWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserPositionLocationWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserPositionLocationWhereInput"]> | undefined | null,
	address?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	city?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	state?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	country?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["BusinessCategoryRelationFilter"]: {
	is?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	isNot?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null
};
	["PaymentListRelationFilter"]: {
	every?: ResolverInputTypes["PaymentWhereInput"] | undefined | null,
	some?: ResolverInputTypes["PaymentWhereInput"] | undefined | null,
	none?: ResolverInputTypes["PaymentWhereInput"] | undefined | null
};
	["PaymentWhereInput"]: {
	AND?: Array<ResolverInputTypes["PaymentWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PaymentWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PaymentWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	paymentIntentId?: ResolverInputTypes["StringFilter"] | undefined | null,
	checkoutSessionId?: ResolverInputTypes["StringFilter"] | undefined | null,
	eventId?: ResolverInputTypes["StringFilter"] | undefined | null,
	liveMode?: ResolverInputTypes["BoolFilter"] | undefined | null,
	paymentGateway?: ResolverInputTypes["EnumPaymentGatewayNameFilter"] | undefined | null,
	subtotal?: ResolverInputTypes["FloatFilter"] | undefined | null,
	total?: ResolverInputTypes["FloatFilter"] | undefined | null,
	tax?: ResolverInputTypes["FloatFilter"] | undefined | null,
	discount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	campaignId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignNullableRelationFilter"] | undefined | null
};
	["BusinessCategoryListRelationFilter"]: {
	every?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	some?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	none?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null
};
	["SlotConfigurationListRelationFilter"]: {
	every?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null,
	some?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null,
	none?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null
};
	["SlotConfigurationWhereInput"]: {
	AND?: Array<ResolverInputTypes["SlotConfigurationWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SlotConfigurationWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SlotConfigurationWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	availableSlots?: ResolverInputTypes["IntFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null
};
	["AdBoardOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	address?: ResolverInputTypes["SortOrder"] | undefined | null,
	state?: ResolverInputTypes["SortOrder"] | undefined | null,
	city?: ResolverInputTypes["SortOrder"] | undefined | null,
	country?: ResolverInputTypes["SortOrder"] | undefined | null,
	map?: ResolverInputTypes["MapOrderByInput"] | undefined | null,
	outdoor?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	alias?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	pricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	aboutSpecifications?: ResolverInputTypes["SpecificationOrderByCompositeAggregateInput"] | undefined | null,
	adBoardTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	tags?: ResolverInputTypes["SortOrder"] | undefined | null,
	featuredImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	galleryImgs?: ResolverInputTypes["SortOrder"] | undefined | null,
	specifications?: ResolverInputTypes["SpecificationOrderByCompositeAggregateInput"] | undefined | null,
	notes?: ResolverInputTypes["SortOrder"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["SortOrder"] | undefined | null,
	resolution?: ResolverInputTypes["ResolutionOrderByInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeOrderByInput"] | undefined | null,
	categoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	restrictedBusinessIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	targetAudiences?: ResolverInputTypes["SortOrder"] | undefined | null,
	playbackTimes?: ResolverInputTypes["PlaybackTimeOrderByCompositeAggregateInput"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	isLive?: ResolverInputTypes["SortOrder"] | undefined | null,
	webhooks?: ResolverInputTypes["WebhookOrderByCompositeAggregateInput"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["SortOrder"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsDuration?: ResolverInputTypes["SortOrder"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	href?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["SortOrder"] | undefined | null,
	additionalData?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDraft?: ResolverInputTypes["SortOrder"] | undefined | null,
	publishedVersionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isArchived?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryOrderByWithRelationInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryOrderByWithRelationInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeOrderByWithRelationInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryOrderByWithRelationInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryOrderByRelationAggregateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingOrderByRelationAggregateInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderOrderByRelationAggregateInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationOrderByRelationAggregateInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerOrderByRelationAggregateInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraOrderByRelationAggregateInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentOrderByRelationAggregateInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewOrderByRelationAggregateInput"] | undefined | null
};
	["SortOrder"]:SortOrder;
	["MapOrderByInput"]: {
	lat?: ResolverInputTypes["SortOrder"] | undefined | null,
	lng?: ResolverInputTypes["SortOrder"] | undefined | null,
	address?: ResolverInputTypes["SortOrder"] | undefined | null,
	city?: ResolverInputTypes["SortOrder"] | undefined | null,
	state?: ResolverInputTypes["SortOrder"] | undefined | null,
	country?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SpecificationOrderByCompositeAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["ResolutionOrderByInput"]: {
	height?: ResolverInputTypes["SortOrder"] | undefined | null,
	width?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["ScreenSizeOrderByInput"]: {
	height?: ResolverInputTypes["SortOrder"] | undefined | null,
	unit?: ResolverInputTypes["SortOrder"] | undefined | null,
	width?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlaybackTimeOrderByCompositeAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WebhookOrderByCompositeAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["LocationCategoryOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryOrderByRelationAggregateInput"] | undefined | null
};
	["AdBoardOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["LocationSubCategoryOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["LocationSubCategoryOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryOrderByWithRelationInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null
};
	["AdBoardTypeOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null
};
	["BusinessCategoryOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	restrictedByAdBoardIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignOrderByRelationAggregateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingOrderByRelationAggregateInput"] | undefined | null
};
	["CampaignOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BusinessCategoryOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["OrderOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SlotConfigurationOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	uid?: ResolverInputTypes["SortOrder"] | undefined | null,
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	image?: ResolverInputTypes["SortOrder"] | undefined | null,
	phoneNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	otherDetails?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeOrderByInput"] | undefined | null,
	roles?: ResolverInputTypes["SortOrder"] | undefined | null,
	refunds?: ResolverInputTypes["BookingRefundOrderByCompositeAggregateInput"] | undefined | null,
	sharedBookingIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["SortOrder"] | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceOrderByInput"] | undefined | null,
	leadWorkspaceIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookings?: ResolverInputTypes["BookingOrderByRelationAggregateInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileOrderByRelationAggregateInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileOrderByRelationAggregateInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderOrderByRelationAggregateInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogOrderByRelationAggregateInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditOrderByRelationAggregateInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignOrderByRelationAggregateInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingOrderByRelationAggregateInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipOrderByRelationAggregateInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationOrderByRelationAggregateInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentOrderByRelationAggregateInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceOrderByRelationAggregateInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceOrderByRelationAggregateInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewOrderByRelationAggregateInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionOrderByRelationAggregateInput"] | undefined | null
};
	["UserStripeOrderByInput"]: {
	customerId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingRefundOrderByCompositeAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["NotificationPreferenceOrderByInput"]: {
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	channels?: ResolverInputTypes["SortOrder"] | undefined | null,
	pushSetting?: ResolverInputTypes["PushSettingOrderByInput"] | undefined | null,
	enabled?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PushSettingOrderByInput"]: {
	fcmToken?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FileOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["ChangelogOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AuditOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["MembershipOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["NotificationOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardPublishHistoryOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardReviewCommentOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardPublicReviewOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserPositionOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	key?: ResolverInputTypes["SortOrder"] | undefined | null,
	userIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	ownerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	avatar?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	leadIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	owner?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipOrderByRelationAggregateInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistOrderByRelationAggregateInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditOrderByRelationAggregateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingOrderByRelationAggregateInput"] | undefined | null,
	files?: ResolverInputTypes["FileOrderByRelationAggregateInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerOrderByRelationAggregateInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionOrderByRelationAggregateInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionOrderByWithRelationInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerOrderByRelationAggregateInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleOrderByRelationAggregateInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogOrderByRelationAggregateInput"] | undefined | null,
	leads?: ResolverInputTypes["UserOrderByRelationAggregateInput"] | undefined | null
};
	["PlaylistOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardGroupOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlayerOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["TransactionOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SubscriptionOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageId?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageName?: ResolverInputTypes["SortOrder"] | undefined | null,
	priceLookupKey?: ResolverInputTypes["SortOrder"] | undefined | null,
	startDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	endDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripePaymentIntentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeCheckoutSessionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsOrderByInput"] | undefined | null,
	activeWorkspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	activeWorkspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null
};
	["CancellationDetailsOrderByInput"]: {
	canceledAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	cancelAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	cancelAtPeriodEnd?: ResolverInputTypes["SortOrder"] | undefined | null,
	reason?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraServerOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceRoleOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WebhookLogOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardGroupOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	schedules?: ResolverInputTypes["ScheduleOrderByCompositeAggregateInput"] | undefined | null,
	scheduledPlaylistsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitVideo?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistOrderByRelationAggregateInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistOrderByWithRelationInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerOrderByRelationAggregateInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null
};
	["ScheduleOrderByCompositeAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlaylistOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	assets?: ResolverInputTypes["PisignageFileOrderByCompositeAggregateInput"] | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["SortOrder"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	layout?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroupIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistOrderByWithRelationInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistOrderByWithRelationInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistOrderByRelationAggregateInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistOrderByRelationAggregateInput"] | undefined | null
};
	["PisignageFileOrderByCompositeAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardWhereUniqueInput"]: {
	id?: string | undefined | null,
	publishedVersionId?: string | undefined | null,
	workspaceId_alias?: ResolverInputTypes["AdBoardWorkspaceIdAliasCompoundUniqueInput"] | undefined | null,
	AND?: Array<ResolverInputTypes["AdBoardWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardWhereInput"]> | undefined | null,
	address?: ResolverInputTypes["StringFilter"] | undefined | null,
	state?: ResolverInputTypes["StringFilter"] | undefined | null,
	city?: ResolverInputTypes["StringFilter"] | undefined | null,
	country?: ResolverInputTypes["StringFilter"] | undefined | null,
	map?: ResolverInputTypes["MapCompositeFilter"] | undefined | null,
	outdoor?: ResolverInputTypes["BoolFilter"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	alias?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	title?: ResolverInputTypes["StringFilter"] | undefined | null,
	pricePerDay?: ResolverInputTypes["IntFilter"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationObjectEqualityInput"]> | undefined | null,
	adBoardTypeId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	tags?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	featuredImage?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	galleryImgs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationObjectEqualityInput"]> | undefined | null,
	notes?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	resolution?: ResolverInputTypes["ResolutionNullableCompositeFilter"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeNullableCompositeFilter"] | undefined | null,
	categoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	restrictedBusinessIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	targetAudiences?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeObjectEqualityInput"]> | undefined | null,
	status?: ResolverInputTypes["EnumAdBoardStatusFilter"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["EnumAdboardDraftStatusNullableFilter"] | undefined | null,
	isLive?: ResolverInputTypes["BoolFilter"] | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookObjectEqualityInput"]> | undefined | null,
	numberOfDisplays?: ResolverInputTypes["IntFilter"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["IntFilter"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["IntFilter"] | undefined | null,
	adsDuration?: ResolverInputTypes["IntFilter"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["BoolFilter"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	href?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	playerIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	additionalData?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	isDraft?: ResolverInputTypes["BoolFilter"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	isArchived?: ResolverInputTypes["BoolFilter"] | undefined | null,
	archivedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryNullableRelationFilter"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryNullableRelationFilter"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeNullableRelationFilter"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryNullableRelationFilter"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	orders?: ResolverInputTypes["OrderListRelationFilter"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationListRelationFilter"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	createdBy?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null,
	players?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupNullableRelationFilter"] | undefined | null,
	cameras?: ResolverInputTypes["CameraListRelationFilter"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardNullableRelationFilter"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardNullableRelationFilter"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryListRelationFilter"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentListRelationFilter"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewListRelationFilter"] | undefined | null
};
	["AdBoardWorkspaceIdAliasCompoundUniqueInput"]: {
	workspaceId: string,
	alias: string
};
	["AdBoard"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	map?:ResolverInputTypes["Map"],
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	aboutSpecifications?:ResolverInputTypes["Specification"],
	adBoardTypeId?:boolean | `@${string}`,
	tags?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	galleryImgs?:boolean | `@${string}`,
	/** @dperecated Use `targetAudiences` instead. */
	specifications?:ResolverInputTypes["Specification"],
	notes?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	resolution?:ResolverInputTypes["Resolution"],
	screenSize?:ResolverInputTypes["ScreenSize"],
	categoryId?:boolean | `@${string}`,
	restrictedBusinessIds?:boolean | `@${string}`,
	targetAudiences?:boolean | `@${string}`,
	playbackTimes?:ResolverInputTypes["PlaybackTime"],
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	webhooks?:ResolverInputTypes["Webhook"],
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	playerIds?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	additionalData?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AdBoardCount"],
locationCategory?: [{	where?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null},ResolverInputTypes["LocationCategory"]],
locationSubCategory?: [{	where?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null},ResolverInputTypes["LocationSubCategory"]],
type?: [{	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null},ResolverInputTypes["AdBoardType"]],
category?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null},ResolverInputTypes["BusinessCategory"]],
restrictedBusines?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BusinessCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BusinessCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["BusinessCategory"]],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
orders?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["OrderWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["OrderScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Order"]],
slotConfigurations?: [{	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SlotConfigurationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SlotConfigurationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["SlotConfigurationScalarFieldEnum"]> | undefined | null},ResolverInputTypes["SlotConfiguration"]],
offlineBookingsContact?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["User"]],
createdBy?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["User"]],
	workspace?:ResolverInputTypes["Workspace"],
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Player"]],
adBoardGroup?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null},ResolverInputTypes["AdBoardGroup"]],
cameras?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Camera"]],
publishedVersion?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},ResolverInputTypes["AdBoard"]],
draftAdboard?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},ResolverInputTypes["AdBoard"]],
history?: [{	where?: ResolverInputTypes["AdBoardPublishHistoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardPublishHistoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardPublishHistoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardPublishHistory"]],
reviewComments?: [{	where?: ResolverInputTypes["AdBoardReviewCommentWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardReviewCommentOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardReviewCommentScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardReviewComment"]],
archivedBy?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["User"]],
publicReviews?: [{	where?: ResolverInputTypes["AdBoardPublicReviewWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardPublicReviewOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardPublicReviewScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardPublicReview"]],
		__typename?: boolean | `@${string}`
}>;
	["Map"]: AliasType<{
	lat?:boolean | `@${string}`,
	lng?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Specification"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Resolution"]: AliasType<{
	height?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ScreenSize"]: AliasType<{
	height?:boolean | `@${string}`,
	unit?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaybackTime"]: AliasType<{
	name?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Webhook"]: AliasType<{
	url?:boolean | `@${string}`,
	headers?:boolean | `@${string}`,
	body?:boolean | `@${string}`,
	method?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardCount"]: AliasType<{
restrictedBusines?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null},boolean | `@${string}`],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},boolean | `@${string}`],
orders?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null},boolean | `@${string}`],
slotConfigurations?: [{	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null},boolean | `@${string}`],
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null},boolean | `@${string}`],
cameras?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null},boolean | `@${string}`],
history?: [{	where?: ResolverInputTypes["AdBoardPublishHistoryWhereInput"] | undefined | null},boolean | `@${string}`],
reviewComments?: [{	where?: ResolverInputTypes["AdBoardReviewCommentWhereInput"] | undefined | null},boolean | `@${string}`],
publicReviews?: [{	where?: ResolverInputTypes["AdBoardPublicReviewWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["LocationCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	_count?:ResolverInputTypes["LocationCategoryCount"],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
locationSubCategory?: [{	where?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocationSubCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["LocationSubCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["LocationSubCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["LocationSubCategory"]],
		__typename?: boolean | `@${string}`
}>;
	["LocationCategoryCount"]: AliasType<{
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
locationSubCategory?: [{	where?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardScalarFieldEnum"]:AdBoardScalarFieldEnum;
	["LocationSubCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["LocationSubCategoryCount"],
locationCategory?: [{	where?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null},ResolverInputTypes["LocationCategory"]],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
		__typename?: boolean | `@${string}`
}>;
	["LocationSubCategoryCount"]: AliasType<{
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["LocationSubCategoryWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["LocationSubCategoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["LocationSubCategoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["LocationSubCategoryWhereInput"]> | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryNullableRelationFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null
};
	["LocationSubCategoryScalarFieldEnum"]:LocationSubCategoryScalarFieldEnum;
	["AdBoardType"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AdBoardTypeCount"],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeCount"]: AliasType<{
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	restrictedByAdBoardIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["BusinessCategoryCount"],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
restrictedByAdBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CampaignScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Campaign"]],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryCount"]: AliasType<{
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
restrictedByAdBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null},boolean | `@${string}`],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Campaign"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	payment?:ResolverInputTypes["BookingPayment"],
	paymentStatus?:boolean | `@${string}`,
	priceSummary?:ResolverInputTypes["PriceSummary"],
	selectedDates?:ResolverInputTypes["OrderSelectedDate"],
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	_count?:ResolverInputTypes["CampaignCount"],
	user?:ResolverInputTypes["User"],
	businessCategory?:ResolverInputTypes["BusinessCategory"],
adCategory?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdvertisementCategory"]],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
payments?: [{	where?: ResolverInputTypes["PaymentWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PaymentOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PaymentWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PaymentScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Payment"]],
assets?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
		__typename?: boolean | `@${string}`
}>;
	["BookingPayment"]: AliasType<{
	liveMode?:boolean | `@${string}`,
	checkoutSessionId?:boolean | `@${string}`,
	customer?:ResolverInputTypes["BookingCustomer"],
	paymentGateway?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
	sessionId?:boolean | `@${string}`,
	totalDetails?:ResolverInputTypes["PaymentTotalDetails"],
	razorpay?:boolean | `@${string}`,
	stripe?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingCustomer"]: AliasType<{
	customerId?:boolean | `@${string}`,
	customerDetails?:ResolverInputTypes["PaymentCustomerDetails"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentCustomerDetails"]: AliasType<{
	email?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PaymentTotalDetails"]: AliasType<{
	discount?:boolean | `@${string}`,
	shipping?:boolean | `@${string}`,
	subtotal?:boolean | `@${string}`,
	tax?:boolean | `@${string}`,
	total?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PriceSummary"]: AliasType<{
	days?:boolean | `@${string}`,
	discount?:boolean | `@${string}`,
	payableRent?:boolean | `@${string}`,
	rentPerDay?:boolean | `@${string}`,
	serviceCharge?:boolean | `@${string}`,
	totalRent?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderSelectedDate"]: AliasType<{
	endDate?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignCount"]: AliasType<{
adCategory?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null},boolean | `@${string}`],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},boolean | `@${string}`],
payments?: [{	where?: ResolverInputTypes["PaymentWhereInput"] | undefined | null},boolean | `@${string}`],
assets?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["User"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	otherDetails?:boolean | `@${string}`,
	stripe?:ResolverInputTypes["UserStripe"],
	roles?:boolean | `@${string}`,
	refunds?:ResolverInputTypes["BookingRefund"],
	sharedBookingIds?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
	notificationPreference?:ResolverInputTypes["NotificationPreference"],
	leadWorkspaceIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["UserCount"],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
createdFiles?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
updatedFiles?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
orders?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["OrderWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["OrderScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Order"]],
changelogs?: [{	where?: ResolverInputTypes["ChangelogWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ChangelogOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["ChangelogWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["ChangelogScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Changelog"]],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
audits?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuditOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AuditWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AuditScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Audit"]],
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CampaignScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Campaign"]],
sharedBookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
memberships?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MembershipOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["MembershipWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["MembershipScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Membership"]],
notifications?: [{	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["NotificationOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["NotificationWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["NotificationScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Notification"]],
publishAdboardVersion?: [{	where?: ResolverInputTypes["AdBoardPublishHistoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardPublishHistoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardPublishHistoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardPublishHistory"]],
offlineBookingAdboards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
reviewComments?: [{	where?: ResolverInputTypes["AdBoardReviewCommentWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardReviewCommentOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardReviewCommentScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardReviewComment"]],
archivedAdboards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
ownedWorkspaces?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Workspace"]],
leadWorspaces?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Workspace"]],
publicReviews?: [{	where?: ResolverInputTypes["AdBoardPublicReviewWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardPublicReviewOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardPublicReviewScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardPublicReview"]],
userPositions?: [{	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserPositionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserPositionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserPositionScalarFieldEnum"]> | undefined | null},ResolverInputTypes["UserPosition"]],
		__typename?: boolean | `@${string}`
}>;
	["UserStripe"]: AliasType<{
	customerId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingRefund"]: AliasType<{
	id?:boolean | `@${string}`,
	razorpay?:boolean | `@${string}`,
	stripe?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationPreference"]: AliasType<{
	type?:boolean | `@${string}`,
	channels?:boolean | `@${string}`,
	pushSetting?:ResolverInputTypes["PushSetting"],
	enabled?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PushSetting"]: AliasType<{
	fcmToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserCount"]: AliasType<{
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},boolean | `@${string}`],
createdFiles?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null},boolean | `@${string}`],
updatedFiles?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null},boolean | `@${string}`],
orders?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null},boolean | `@${string}`],
changelogs?: [{	where?: ResolverInputTypes["ChangelogWhereInput"] | undefined | null},boolean | `@${string}`],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
audits?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null},boolean | `@${string}`],
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null},boolean | `@${string}`],
sharedBookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},boolean | `@${string}`],
memberships?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null},boolean | `@${string}`],
notifications?: [{	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null},boolean | `@${string}`],
publishAdboardVersion?: [{	where?: ResolverInputTypes["AdBoardPublishHistoryWhereInput"] | undefined | null},boolean | `@${string}`],
offlineBookingAdboards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
reviewComments?: [{	where?: ResolverInputTypes["AdBoardReviewCommentWhereInput"] | undefined | null},boolean | `@${string}`],
archivedAdboards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
ownedWorkspaces?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},boolean | `@${string}`],
leadWorspaces?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},boolean | `@${string}`],
publicReviews?: [{	where?: ResolverInputTypes["AdBoardPublicReviewWhereInput"] | undefined | null},boolean | `@${string}`],
userPositions?: [{	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Booking"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	bookingDetails?:ResolverInputTypes["BookingDetails"],
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	selectedDates?:ResolverInputTypes["OrderSelectedDate"],
	payment?:ResolverInputTypes["BookingPayment"],
	priceSummary?:ResolverInputTypes["PriceSummary"],
	pisignage?:ResolverInputTypes["BookingPisignageData"],
	userId?:boolean | `@${string}`,
	refund?:ResolverInputTypes["BookingRefund"],
	sharedUserIds?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
	_count?:ResolverInputTypes["BookingCount"],
assets?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
businessCategory?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null},ResolverInputTypes["BusinessCategory"]],
adCategory?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdvertisementCategoryOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdvertisementCategoryScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdvertisementCategory"]],
proofs?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
	user?:ResolverInputTypes["User"],
	adBoard?:ResolverInputTypes["AdBoard"],
changelogs?: [{	where?: ResolverInputTypes["ChangelogWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ChangelogOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["ChangelogWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["ChangelogScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Changelog"]],
workspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["Workspace"]],
campaign?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null},ResolverInputTypes["Campaign"]],
payouts?: [{	where?: ResolverInputTypes["PayoutWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PayoutOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PayoutWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PayoutScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Payout"]],
sharedUsers?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserScalarFieldEnum"]> | undefined | null},ResolverInputTypes["User"]],
webhookLogs?: [{	where?: ResolverInputTypes["WebhookLogWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WebhookLogOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WebhookLogWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WebhookLogScalarFieldEnum"]> | undefined | null},ResolverInputTypes["WebhookLog"]],
slotUsage?: [{	where?: ResolverInputTypes["SlotUsageWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SlotUsageOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["SlotUsageWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["SlotUsageScalarFieldEnum"]> | undefined | null},ResolverInputTypes["SlotUsage"]],
		__typename?: boolean | `@${string}`
}>;
	["BookingDetails"]: AliasType<{
	additionalInformation?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingPisignageData"]: AliasType<{
	deployedFileName?:boolean | `@${string}`,
	playlistId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingCount"]: AliasType<{
assets?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null},boolean | `@${string}`],
adCategory?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null},boolean | `@${string}`],
proofs?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null},boolean | `@${string}`],
changelogs?: [{	where?: ResolverInputTypes["ChangelogWhereInput"] | undefined | null},boolean | `@${string}`],
payouts?: [{	where?: ResolverInputTypes["PayoutWhereInput"] | undefined | null},boolean | `@${string}`],
sharedUsers?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},boolean | `@${string}`],
webhookLogs?: [{	where?: ResolverInputTypes["WebhookLogWhereInput"] | undefined | null},boolean | `@${string}`],
slotUsage?: [{	where?: ResolverInputTypes["SlotUsageWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["File"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	meta?:ResolverInputTypes["FileMeta"],
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	bookingsIDs?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["FileCount"],
updatedBy?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["User"]],
	createdBy?:ResolverInputTypes["User"],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
workspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["Workspace"]],
proofBooking?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},ResolverInputTypes["Booking"]],
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CampaignScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Campaign"]],
		__typename?: boolean | `@${string}`
}>;
	["FileMeta"]: AliasType<{
	duration?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileCount"]: AliasType<{
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},boolean | `@${string}`],
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["BookingOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	additionalInformation?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	assetsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingCode?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignId?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsOrderByInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateOrderByInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentOrderByInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryOrderByInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataOrderByInput"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundOrderByInput"] | undefined | null,
	sharedUserIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOffline?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPaid?: ResolverInputTypes["SortOrder"] | undefined | null,
	isScheduled?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	isCompleted?: ResolverInputTypes["SortOrder"] | undefined | null,
	assets?: ResolverInputTypes["FileOrderByRelationAggregateInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryOrderByWithRelationInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryOrderByRelationAggregateInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileOrderByRelationAggregateInput"] | undefined | null,
	user?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogOrderByRelationAggregateInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignOrderByWithRelationInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutOrderByRelationAggregateInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserOrderByRelationAggregateInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogOrderByRelationAggregateInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageOrderByRelationAggregateInput"] | undefined | null
};
	["BookingDetailsOrderByInput"]: {
	additionalInformation?: ResolverInputTypes["SortOrder"] | undefined | null,
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	phone?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["OrderSelectedDateOrderByInput"]: {
	endDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	startDate?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingPaymentOrderByInput"]: {
	liveMode?: ResolverInputTypes["SortOrder"] | undefined | null,
	checkoutSessionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	customer?: ResolverInputTypes["BookingCustomerOrderByInput"] | undefined | null,
	paymentGateway?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	sessionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	totalDetails?: ResolverInputTypes["PaymentTotalDetailsOrderByInput"] | undefined | null,
	razorpay?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripe?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingCustomerOrderByInput"]: {
	customerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	customerDetails?: ResolverInputTypes["PaymentCustomerDetailsOrderByInput"] | undefined | null
};
	["PaymentCustomerDetailsOrderByInput"]: {
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	phone?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PaymentTotalDetailsOrderByInput"]: {
	discount?: ResolverInputTypes["SortOrder"] | undefined | null,
	shipping?: ResolverInputTypes["SortOrder"] | undefined | null,
	subtotal?: ResolverInputTypes["SortOrder"] | undefined | null,
	tax?: ResolverInputTypes["SortOrder"] | undefined | null,
	total?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PriceSummaryOrderByInput"]: {
	days?: ResolverInputTypes["SortOrder"] | undefined | null,
	discount?: ResolverInputTypes["SortOrder"] | undefined | null,
	payableRent?: ResolverInputTypes["SortOrder"] | undefined | null,
	rentPerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	serviceCharge?: ResolverInputTypes["SortOrder"] | undefined | null,
	totalRent?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingPisignageDataOrderByInput"]: {
	deployedFileName?: ResolverInputTypes["SortOrder"] | undefined | null,
	playlistId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingRefundOrderByInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	razorpay?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripe?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdvertisementCategoryOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CampaignOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentOrderByInput"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryOrderByInput"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateOrderByInput"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	assetsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	user?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryOrderByWithRelationInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryOrderByRelationAggregateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingOrderByRelationAggregateInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentOrderByRelationAggregateInput"] | undefined | null,
	assets?: ResolverInputTypes["FileOrderByRelationAggregateInput"] | undefined | null
};
	["PaymentOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PayoutOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SlotUsageOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingWhereUniqueInput"]: {
	id?: string | undefined | null,
	bookingCode?: string | undefined | null,
	AND?: Array<ResolverInputTypes["BookingWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingWhereInput"]> | undefined | null,
	additionalInformation?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	assetsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingDate?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingFor?: ResolverInputTypes["StringFilter"] | undefined | null,
	purpose?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	campaignId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adCategoryId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCompositeFilter"] | undefined | null,
	bookingStatus?: ResolverInputTypes["EnumBookingStatusFilter"] | undefined | null,
	paymentStatus?: ResolverInputTypes["EnumPaymentStatusNullableFilter"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["EnumDeploymentStatusFilter"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["EnumContentApprovalStatusFilter"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCompositeFilter"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentNullableCompositeFilter"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryNullableCompositeFilter"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataNullableCompositeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundNullableCompositeFilter"] | undefined | null,
	sharedUserIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	isOffline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isPaid?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isScheduled?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isDeployed?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isCompleted?: ResolverInputTypes["BoolFilter"] | undefined | null,
	assets?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryNullableRelationFilter"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryListRelationFilter"] | undefined | null,
	proofs?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogListRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignNullableRelationFilter"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutListRelationFilter"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserListRelationFilter"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogListRelationFilter"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageListRelationFilter"] | undefined | null
};
	["BookingScalarFieldEnum"]:BookingScalarFieldEnum;
	["Workspace"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	userIds?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	leadIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["WorkspaceCount"],
owner?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["User"]],
memberships?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MembershipOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["MembershipWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["MembershipScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Membership"]],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
playlists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlaylistScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Playlist"]],
adBoardGroups?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardGroup"]],
audits?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AuditOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AuditWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AuditScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Audit"]],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
files?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["FileWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["FileScalarFieldEnum"]> | undefined | null},ResolverInputTypes["File"]],
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Player"]],
transactions?: [{	where?: ResolverInputTypes["TransactionWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TransactionOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["TransactionWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["TransactionScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Transaction"]],
activeSubscription?: [{	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null},ResolverInputTypes["Subscription"]],
cameraServers?: [{	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraServerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraServerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraServer"]],
workspaceRoles?: [{	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WorkspaceRoleOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WorkspaceRoleScalarFieldEnum"]> | undefined | null},ResolverInputTypes["WorkspaceRole"]],
webhookLogs?: [{	where?: ResolverInputTypes["WebhookLogWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["WebhookLogOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["WebhookLogWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["WebhookLogScalarFieldEnum"]> | undefined | null},ResolverInputTypes["WebhookLog"]],
leads?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["UserOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["UserScalarFieldEnum"]> | undefined | null},ResolverInputTypes["User"]],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceCount"]: AliasType<{
memberships?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null},boolean | `@${string}`],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
playlists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},boolean | `@${string}`],
adBoardGroups?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null},boolean | `@${string}`],
audits?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null},boolean | `@${string}`],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},boolean | `@${string}`],
files?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null},boolean | `@${string}`],
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null},boolean | `@${string}`],
transactions?: [{	where?: ResolverInputTypes["TransactionWhereInput"] | undefined | null},boolean | `@${string}`],
cameraServers?: [{	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null},boolean | `@${string}`],
workspaceRoles?: [{	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null},boolean | `@${string}`],
webhookLogs?: [{	where?: ResolverInputTypes["WebhookLogWhereInput"] | undefined | null},boolean | `@${string}`],
leads?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Membership"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
	user?:ResolverInputTypes["User"],
	workspace?:ResolverInputTypes["Workspace"],
	role?:ResolverInputTypes["WorkspaceRole"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRole"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ResolverInputTypes["WorkspaceRoleCount"],
workspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["Workspace"]],
memberships?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["MembershipOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["MembershipWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["MembershipScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Membership"]],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleCount"]: AliasType<{
memberships?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["MembershipOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	roleId?: ResolverInputTypes["SortOrder"] | undefined | null,
	user?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	role?: ResolverInputTypes["WorkspaceRoleOrderByWithRelationInput"] | undefined | null
};
	["WorkspaceRoleOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	permissions?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipOrderByRelationAggregateInput"] | undefined | null
};
	["MembershipWhereUniqueInput"]: {
	id?: string | undefined | null,
	userId_workspaceId?: ResolverInputTypes["MembershipUserIdWorkspaceIdCompoundUniqueInput"] | undefined | null,
	AND?: Array<ResolverInputTypes["MembershipWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["MembershipWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["MembershipWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	roleId?: ResolverInputTypes["StringFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null,
	role?: ResolverInputTypes["WorkspaceRoleRelationFilter"] | undefined | null
};
	["MembershipUserIdWorkspaceIdCompoundUniqueInput"]: {
	userId: string,
	workspaceId: string
};
	["MembershipScalarFieldEnum"]:MembershipScalarFieldEnum;
	["Playlist"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	assets?:ResolverInputTypes["PisignageFile"],
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	adBoardGroupIds?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["PlaylistCount"],
footerPlaylist?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},ResolverInputTypes["Playlist"]],
sidebarPlaylist?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},ResolverInputTypes["Playlist"]],
adBoardGroupsForAds?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardGroup"]],
adBoardGroups?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardGroupScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoardGroup"]],
	workspace?:ResolverInputTypes["Workspace"],
footerParentPlaylists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlaylistScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Playlist"]],
sidebarParentPlaylists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlaylistScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Playlist"]],
		__typename?: boolean | `@${string}`
}>;
	["PisignageFile"]: AliasType<{
	name?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	muteAudio?:boolean | `@${string}`,
	isVideo?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaylistCount"]: AliasType<{
adBoardGroupsForAds?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null},boolean | `@${string}`],
adBoardGroups?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null},boolean | `@${string}`],
footerParentPlaylists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},boolean | `@${string}`],
sidebarParentPlaylists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroup"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	schedules?:ResolverInputTypes["Schedule"],
	scheduledPlaylistsIDs?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AdBoardGroupCount"],
adBoard?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},ResolverInputTypes["AdBoard"]],
scheduledPlaylists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlaylistOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlaylistScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Playlist"]],
adsPlaylist?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},ResolverInputTypes["Playlist"]],
pisignagePlayers?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Player"]],
	workspace?:ResolverInputTypes["Workspace"],
		__typename?: boolean | `@${string}`
}>;
	["Schedule"]: AliasType<{
	playlistId?:boolean | `@${string}`,
	playlistType?:boolean | `@${string}`,
	skipForSchedule?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupCount"]: AliasType<{
scheduledPlaylists?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},boolean | `@${string}`],
pisignagePlayers?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["PlaylistWhereUniqueInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	AND?: Array<ResolverInputTypes["PlaylistWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlaylistWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlaylistWhereInput"]> | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileObjectEqualityInput"]> | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	layout?: ResolverInputTypes["EnumScreenLayoutFilter"] | undefined | null,
	isDeployed?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	adBoardGroupIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistNullableRelationFilter"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistNullableRelationFilter"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupListRelationFilter"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupListRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistListRelationFilter"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistListRelationFilter"] | undefined | null
};
	["PlaylistScalarFieldEnum"]:PlaylistScalarFieldEnum;
	["Player"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	playlistIds?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	resolution?:ResolverInputTypes["Resolution"],
	screenSize?:ResolverInputTypes["ScreenSize"],
	signageTypeId?:boolean | `@${string}`,
	audienceTypeId?:boolean | `@${string}`,
	playbackDetails?:ResolverInputTypes["PlaybackTime"],
	aspectRatioType?:boolean | `@${string}`,
	galleryImgs?:ResolverInputTypes["GalleryFile"],
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	map?:ResolverInputTypes["Map"],
	isOnline?:boolean | `@${string}`,
	adBoardIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["PlayerCount"],
adBoardGroup?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null},ResolverInputTypes["AdBoardGroup"]],
workspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["Workspace"]],
status?: [{	where?: ResolverInputTypes["PlayerUptimeStatusWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerUptimeStatusOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerUptimeStatusScalarFieldEnum"]> | undefined | null},ResolverInputTypes["PlayerUptimeStatus"]],
cameras?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Camera"]],
signageType?: [{	where?: ResolverInputTypes["DigitalSignageWhereInput"] | undefined | null},ResolverInputTypes["DigitalSignage"]],
audienceType?: [{	where?: ResolverInputTypes["AudienceWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AudienceOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AudienceWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AudienceScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Audience"]],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AdBoardOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["AdBoardScalarFieldEnum"]> | undefined | null},ResolverInputTypes["AdBoard"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryFile"]: AliasType<{
	name?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerCount"]: AliasType<{
status?: [{	where?: ResolverInputTypes["PlayerUptimeStatusWhereInput"] | undefined | null},boolean | `@${string}`],
cameras?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null},boolean | `@${string}`],
audienceType?: [{	where?: ResolverInputTypes["AudienceWhereInput"] | undefined | null},boolean | `@${string}`],
adBoards?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["PlayerUptimeStatus"]: AliasType<{
	id?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	uptime?:boolean | `@${string}`,
	player?:ResolverInputTypes["Player"],
		__typename?: boolean | `@${string}`
}>;
	["PlayerUptimeStatusOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	date?: ResolverInputTypes["SortOrder"] | undefined | null,
	uptime?: ResolverInputTypes["SortOrder"] | undefined | null,
	player?: ResolverInputTypes["PlayerOrderByWithRelationInput"] | undefined | null
};
	["PlayerOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	configLocation?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	playlistIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	workpaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	resolution?: ResolverInputTypes["ResolutionOrderByInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeOrderByInput"] | undefined | null,
	signageTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	audienceTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	playbackDetails?: ResolverInputTypes["PlaybackTimeOrderByCompositeAggregateInput"] | undefined | null,
	aspectRatioType?: ResolverInputTypes["SortOrder"] | undefined | null,
	galleryImgs?: ResolverInputTypes["GalleryFileOrderByCompositeAggregateInput"] | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["SortOrder"] | undefined | null,
	map?: ResolverInputTypes["MapOrderByInput"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupOrderByWithRelationInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusOrderByRelationAggregateInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraOrderByRelationAggregateInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageOrderByWithRelationInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceOrderByRelationAggregateInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardOrderByRelationAggregateInput"] | undefined | null
};
	["GalleryFileOrderByCompositeAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlayerUptimeStatusOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["DigitalSignageOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	players?: ResolverInputTypes["PlayerOrderByRelationAggregateInput"] | undefined | null
};
	["AudienceOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlayerUptimeStatusWhereUniqueInput"]: {
	id?: string | undefined | null,
	cpuSerialNumber_date?: ResolverInputTypes["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"] | undefined | null,
	AND?: Array<ResolverInputTypes["PlayerUptimeStatusWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlayerUptimeStatusWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlayerUptimeStatusWhereInput"]> | undefined | null,
	playerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["StringFilter"] | undefined | null,
	uptime?: ResolverInputTypes["FloatFilter"] | undefined | null,
	player?: ResolverInputTypes["PlayerRelationFilter"] | undefined | null
};
	["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"]: {
	cpuSerialNumber: string,
	date: string
};
	["PlayerUptimeStatusScalarFieldEnum"]:PlayerUptimeStatusScalarFieldEnum;
	["Camera"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	connectedCpuSerialNumbers?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["CameraCount"],
	cameraServer?:ResolverInputTypes["CameraServer"],
player?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null},ResolverInputTypes["Player"]],
feeds?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraFeedWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraFeedScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraFeed"]],
adBoard?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},ResolverInputTypes["AdBoard"]],
		__typename?: boolean | `@${string}`
}>;
	["CameraCount"]: AliasType<{
feeds?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["CameraServer"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
	_count?:ResolverInputTypes["CameraServerCount"],
workspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["Workspace"]],
cameras?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Camera"]],
feeds?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CameraFeedOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CameraFeedWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CameraFeedScalarFieldEnum"]> | undefined | null},ResolverInputTypes["CameraFeed"]],
		__typename?: boolean | `@${string}`
}>;
	["CameraServerCount"]: AliasType<{
cameras?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null},boolean | `@${string}`],
feeds?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["CameraOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	rtspUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	connectedCpuSerialNumbers?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPublic?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerOrderByWithRelationInput"] | undefined | null,
	player?: ResolverInputTypes["PlayerOrderByWithRelationInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedOrderByRelationAggregateInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null
};
	["CameraServerOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	hostName?: ResolverInputTypes["SortOrder"] | undefined | null,
	userName?: ResolverInputTypes["SortOrder"] | undefined | null,
	deviceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	publicIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	privateIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraOrderByRelationAggregateInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedOrderByRelationAggregateInput"] | undefined | null
};
	["CameraFeedOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraWhereUniqueInput"]: {
	id?: string | undefined | null,
	cameraUrl?: string | undefined | null,
	cameraIp_cameraServerId?: ResolverInputTypes["CameraCameraIpCameraServerIdCompoundUniqueInput"] | undefined | null,
	AND?: Array<ResolverInputTypes["CameraWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraWhereInput"]> | undefined | null,
	name?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	cameraServerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	rtspUrl?: ResolverInputTypes["StringFilter"] | undefined | null,
	cameraIp?: ResolverInputTypes["StringFilter"] | undefined | null,
	connectedCpuSerialNumbers?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	playerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	isPublic?: ResolverInputTypes["BoolFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerRelationFilter"] | undefined | null,
	player?: ResolverInputTypes["PlayerNullableRelationFilter"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedListRelationFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardNullableRelationFilter"] | undefined | null
};
	["CameraCameraIpCameraServerIdCompoundUniqueInput"]: {
	cameraIp: string,
	cameraServerId: string
};
	["CameraScalarFieldEnum"]:CameraScalarFieldEnum;
	["CameraFeed"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	data?:ResolverInputTypes["CameraFeedData"],
	camera?:ResolverInputTypes["Camera"],
	cameraServer?:ResolverInputTypes["CameraServer"],
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedData"]: AliasType<{
	person?:boolean | `@${string}`,
	car?:boolean | `@${string}`,
	truck?:boolean | `@${string}`,
	motorcycle?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraId?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	data?: ResolverInputTypes["CameraFeedDataOrderByInput"] | undefined | null,
	camera?: ResolverInputTypes["CameraOrderByWithRelationInput"] | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerOrderByWithRelationInput"] | undefined | null
};
	["CameraFeedDataOrderByInput"]: {
	person?: ResolverInputTypes["SortOrder"] | undefined | null,
	car?: ResolverInputTypes["SortOrder"] | undefined | null,
	truck?: ResolverInputTypes["SortOrder"] | undefined | null,
	motorcycle?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraFeedWhereUniqueInput"]: {
	id?: string | undefined | null,
	cameraId_timestamp?: ResolverInputTypes["CameraFeedCameraIdTimestampCompoundUniqueInput"] | undefined | null,
	AND?: Array<ResolverInputTypes["CameraFeedWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraFeedWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraFeedWhereInput"]> | undefined | null,
	cameraId?: ResolverInputTypes["StringFilter"] | undefined | null,
	timestamp?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	cameraServerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	data?: ResolverInputTypes["CameraFeedDataCompositeFilter"] | undefined | null,
	camera?: ResolverInputTypes["CameraRelationFilter"] | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerRelationFilter"] | undefined | null
};
	["CameraFeedCameraIdTimestampCompoundUniqueInput"]: {
	cameraId: string,
	timestamp: ResolverInputTypes["DateTime"]
};
	["CameraFeedScalarFieldEnum"]:CameraFeedScalarFieldEnum;
	["DigitalSignage"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ResolverInputTypes["DigitalSignageCount"],
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Player"]],
		__typename?: boolean | `@${string}`
}>;
	["DigitalSignageCount"]: AliasType<{
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["PlayerWhereUniqueInput"]: {
	id?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	AND?: Array<ResolverInputTypes["PlayerWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlayerWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlayerWhereInput"]> | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	configLocation?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	playlistIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workpaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	resolution?: ResolverInputTypes["ResolutionNullableCompositeFilter"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeNullableCompositeFilter"] | undefined | null,
	signageTypeId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	audienceTypeId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeObjectEqualityInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["EnumAspectRatioTypeFilter"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileObjectEqualityInput"]> | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	map?: ResolverInputTypes["MapNullableCompositeFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	adBoardIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupNullableRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusListRelationFilter"] | undefined | null,
	cameras?: ResolverInputTypes["CameraListRelationFilter"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageNullableRelationFilter"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceListRelationFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null
};
	["PlayerScalarFieldEnum"]:PlayerScalarFieldEnum;
	["Audience"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	playerIDs?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AudienceCount"],
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PlayerOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PlayerScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Player"]],
		__typename?: boolean | `@${string}`
}>;
	["AudienceCount"]: AliasType<{
players?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["AudienceOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	players?: ResolverInputTypes["PlayerOrderByRelationAggregateInput"] | undefined | null
};
	["AudienceWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["AudienceWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AudienceWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AudienceWhereInput"]> | undefined | null,
	type?: ResolverInputTypes["EnumAudienceTypeFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	playerIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	players?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null
};
	["AudienceScalarFieldEnum"]:AudienceScalarFieldEnum;
	["AdBoardGroupWhereUniqueInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	adBoardId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	AND?: Array<ResolverInputTypes["AdBoardGroupWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardGroupWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardGroupWhereInput"]> | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleObjectEqualityInput"]> | undefined | null,
	scheduledPlaylistsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	fitImage?: ResolverInputTypes["EnumFitTypeFilter"] | undefined | null,
	fitVideo?: ResolverInputTypes["EnumFitTypeFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardNullableRelationFilter"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistListRelationFilter"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistNullableRelationFilter"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null
};
	["AdBoardGroupScalarFieldEnum"]:AdBoardGroupScalarFieldEnum;
	["Audit"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	entity?:ResolverInputTypes["Entity"],
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	updates?:boolean | `@${string}`,
	user?:ResolverInputTypes["User"],
workspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["Workspace"]],
		__typename?: boolean | `@${string}`
}>;
	["Entity"]: AliasType<{
	type?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuditOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	message?: ResolverInputTypes["SortOrder"] | undefined | null,
	action?: ResolverInputTypes["SortOrder"] | undefined | null,
	entity?: ResolverInputTypes["EntityOrderByInput"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updates?: ResolverInputTypes["SortOrder"] | undefined | null,
	user?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null
};
	["EntityOrderByInput"]: {
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AuditWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["AuditWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AuditWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AuditWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	message?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["StringFilter"] | undefined | null,
	entity?: ResolverInputTypes["EntityNullableCompositeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updates?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null
};
	["AuditScalarFieldEnum"]:AuditScalarFieldEnum;
	["FileOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentType?: ResolverInputTypes["SortOrder"] | undefined | null,
	ctime?: ResolverInputTypes["SortOrder"] | undefined | null,
	filename?: ResolverInputTypes["SortOrder"] | undefined | null,
	filepath?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDirectory?: ResolverInputTypes["SortOrder"] | undefined | null,
	meta?: ResolverInputTypes["FileMetaOrderByInput"] | undefined | null,
	mtime?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	size?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	url?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	proofBookingId?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedBy?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingOrderByRelationAggregateInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingOrderByWithRelationInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignOrderByRelationAggregateInput"] | undefined | null
};
	["FileMetaOrderByInput"]: {
	duration?: ResolverInputTypes["SortOrder"] | undefined | null,
	height?: ResolverInputTypes["SortOrder"] | undefined | null,
	width?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FileWhereUniqueInput"]: {
	id?: string | undefined | null,
	url?: string | undefined | null,
	AND?: Array<ResolverInputTypes["FileWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["FileWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["FileWhereInput"]> | undefined | null,
	contentType?: ResolverInputTypes["StringFilter"] | undefined | null,
	ctime?: ResolverInputTypes["StringFilter"] | undefined | null,
	filename?: ResolverInputTypes["StringFilter"] | undefined | null,
	filepath?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isDirectory?: ResolverInputTypes["BoolFilter"] | undefined | null,
	meta?: ResolverInputTypes["FileMetaCompositeFilter"] | undefined | null,
	mtime?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	size?: ResolverInputTypes["IntFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumFileStatusNullableFilter"] | undefined | null,
	type?: ResolverInputTypes["StringFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	proofBookingId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	campaignIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	updatedBy?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	createdBy?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingNullableRelationFilter"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignListRelationFilter"] | undefined | null
};
	["FileScalarFieldEnum"]:FileScalarFieldEnum;
	["Transaction"]: AliasType<{
	workspaceId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	createAt?:boolean | `@${string}`,
	processedAt?:boolean | `@${string}`,
	relatedEntityId?:boolean | `@${string}`,
	transactionStatus?:boolean | `@${string}`,
	details?:boolean | `@${string}`,
	_count?:ResolverInputTypes["TransactionCount"],
workspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["Workspace"]],
payouts?: [{	where?: ResolverInputTypes["PayoutWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["PayoutOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["PayoutWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["PayoutScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Payout"]],
		__typename?: boolean | `@${string}`
}>;
	["TransactionCount"]: AliasType<{
payouts?: [{	where?: ResolverInputTypes["PayoutWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Payout"]: AliasType<{
	id?:boolean | `@${string}`,
	totalAmount?:boolean | `@${string}`,
	commissionRate?:boolean | `@${string}`,
	commissionAmount?:boolean | `@${string}`,
	netAmount?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	bookingId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	transactionId?:boolean | `@${string}`,
	booking?:ResolverInputTypes["Booking"],
transaction?: [{	where?: ResolverInputTypes["TransactionWhereInput"] | undefined | null},ResolverInputTypes["Transaction"]],
		__typename?: boolean | `@${string}`
}>;
	["PayoutOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	totalAmount?: ResolverInputTypes["SortOrder"] | undefined | null,
	commissionRate?: ResolverInputTypes["SortOrder"] | undefined | null,
	commissionAmount?: ResolverInputTypes["SortOrder"] | undefined | null,
	netAmount?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingId?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	transactionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	booking?: ResolverInputTypes["BookingOrderByWithRelationInput"] | undefined | null,
	transaction?: ResolverInputTypes["TransactionOrderByWithRelationInput"] | undefined | null
};
	["TransactionOrderByWithRelationInput"]: {
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	amount?: ResolverInputTypes["SortOrder"] | undefined | null,
	createAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	processedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	relatedEntityId?: ResolverInputTypes["SortOrder"] | undefined | null,
	transactionStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	details?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutOrderByRelationAggregateInput"] | undefined | null
};
	["PayoutWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["PayoutWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PayoutWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PayoutWhereInput"]> | undefined | null,
	totalAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	commissionRate?: ResolverInputTypes["FloatFilter"] | undefined | null,
	commissionAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	netAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumPayoutStatusFilter"] | undefined | null,
	transactionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	booking?: ResolverInputTypes["BookingRelationFilter"] | undefined | null,
	transaction?: ResolverInputTypes["TransactionNullableRelationFilter"] | undefined | null
};
	["PayoutScalarFieldEnum"]:PayoutScalarFieldEnum;
	["TransactionWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["TransactionWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["TransactionWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["TransactionWhereInput"]> | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	amount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	createAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	relatedEntityId?: ResolverInputTypes["StringFilter"] | undefined | null,
	transactionStatus?: ResolverInputTypes["EnumTransactionStatusFilter"] | undefined | null,
	details?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutListRelationFilter"] | undefined | null
};
	["TransactionScalarFieldEnum"]:TransactionScalarFieldEnum;
	["Subscription"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	cancellationDetails?:ResolverInputTypes["CancellationDetails"],
	activeWorkspaceId?:boolean | `@${string}`,
	activeWorkspace?:ResolverInputTypes["Workspace"],
		__typename?: boolean | `@${string}`
}>;
	["CancellationDetails"]: AliasType<{
	canceledAt?:boolean | `@${string}`,
	cancelAt?:boolean | `@${string}`,
	cancelAtPeriodEnd?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraServerWhereUniqueInput"]: {
	id?: string | undefined | null,
	deviceId?: string | undefined | null,
	AND?: Array<ResolverInputTypes["CameraServerWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraServerWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraServerWhereInput"]> | undefined | null,
	name?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	hostName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	userName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	publicIp?: ResolverInputTypes["StringFilter"] | undefined | null,
	privateIp?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	cameras?: ResolverInputTypes["CameraListRelationFilter"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedListRelationFilter"] | undefined | null
};
	["CameraServerScalarFieldEnum"]:CameraServerScalarFieldEnum;
	["WorkspaceRoleWhereUniqueInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	name_workspaceId?: ResolverInputTypes["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"] | undefined | null,
	AND?: Array<ResolverInputTypes["WorkspaceRoleWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WorkspaceRoleWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WorkspaceRoleWhereInput"]> | undefined | null,
	permissions?: ResolverInputTypes["EnumPermissionNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipListRelationFilter"] | undefined | null
};
	["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"]: {
	name: string,
	workspaceId: string
};
	["WorkspaceRoleScalarFieldEnum"]:WorkspaceRoleScalarFieldEnum;
	["WebhookLog"]: AliasType<{
	id?:boolean | `@${string}`,
	bookingId?:boolean | `@${string}`,
	response?:boolean | `@${string}`,
	request?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	booking?:ResolverInputTypes["Booking"],
workspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["Workspace"]],
		__typename?: boolean | `@${string}`
}>;
	["WebhookLogOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingId?: ResolverInputTypes["SortOrder"] | undefined | null,
	response?: ResolverInputTypes["SortOrder"] | undefined | null,
	request?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	booking?: ResolverInputTypes["BookingOrderByWithRelationInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceOrderByWithRelationInput"] | undefined | null
};
	["WebhookLogWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["WebhookLogWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WebhookLogWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WebhookLogWhereInput"]> | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	response?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	request?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	status?: ResolverInputTypes["IntFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	booking?: ResolverInputTypes["BookingRelationFilter"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceNullableRelationFilter"] | undefined | null
};
	["WebhookLogScalarFieldEnum"]:WebhookLogScalarFieldEnum;
	["UserWhereUniqueInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email?: string | undefined | null,
	AND?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	image?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	phoneNumber?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	otherDetails?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeNullableCompositeFilter"] | undefined | null,
	roles?: ResolverInputTypes["EnumRoleNullableListFilter"] | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundObjectEqualityInput"]> | undefined | null,
	sharedBookingIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["BoolFilter"] | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceNullableCompositeFilter"] | undefined | null,
	leadWorkspaceIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	orders?: ResolverInputTypes["OrderListRelationFilter"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogListRelationFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	audits?: ResolverInputTypes["AuditListRelationFilter"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignListRelationFilter"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipListRelationFilter"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationListRelationFilter"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryListRelationFilter"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentListRelationFilter"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceListRelationFilter"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceListRelationFilter"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewListRelationFilter"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionListRelationFilter"] | undefined | null
};
	["UserScalarFieldEnum"]:UserScalarFieldEnum;
	["CampaignWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["CampaignWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CampaignWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CampaignWhereInput"]> | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingFor?: ResolverInputTypes["StringFilter"] | undefined | null,
	purpose?: ResolverInputTypes["StringFilter"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["StringFilter"] | undefined | null,
	adCategoryId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentNullableCompositeFilter"] | undefined | null,
	paymentStatus?: ResolverInputTypes["EnumPaymentStatusFilter"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryNullableCompositeFilter"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCompositeFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringFilter"] | undefined | null,
	assetsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryRelationFilter"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	payments?: ResolverInputTypes["PaymentListRelationFilter"] | undefined | null,
	assets?: ResolverInputTypes["FileListRelationFilter"] | undefined | null
};
	["CampaignScalarFieldEnum"]:CampaignScalarFieldEnum;
	["AdvertisementCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	bookingIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AdvertisementCategoryCount"],
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["CampaignOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["CampaignScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Campaign"]],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,	orderBy?: Array<ResolverInputTypes["BookingOrderByWithRelationInput"]> | undefined | null,	cursor?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,	take?: number | undefined | null,	skip?: number | undefined | null,	distinct?: Array<ResolverInputTypes["BookingScalarFieldEnum"]> | undefined | null},ResolverInputTypes["Booking"]],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryCount"]: AliasType<{
campaigns?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null},boolean | `@${string}`],
bookings?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignOrderByRelationAggregateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingOrderByRelationAggregateInput"] | undefined | null
};
	["AdvertisementCategoryWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["AdvertisementCategoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdvertisementCategoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdvertisementCategoryWhereInput"]> | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumAdvertisementCategoryEnumFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	campaignIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null
};
	["AdvertisementCategoryScalarFieldEnum"]:AdvertisementCategoryScalarFieldEnum;
	["Changelog"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	bookingId?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	updates?:boolean | `@${string}`,
booking?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},ResolverInputTypes["Booking"]],
	user?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["ChangelogOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	message?: ResolverInputTypes["SortOrder"] | undefined | null,
	action?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingId?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updates?: ResolverInputTypes["SortOrder"] | undefined | null,
	booking?: ResolverInputTypes["BookingOrderByWithRelationInput"] | undefined | null,
	user?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null
};
	["ChangelogWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["ChangelogWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["ChangelogWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["ChangelogWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	message?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	updates?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	booking?: ResolverInputTypes["BookingNullableRelationFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["ChangelogScalarFieldEnum"]:ChangelogScalarFieldEnum;
	["SlotUsage"]: AliasType<{
	id?:boolean | `@${string}`,
	bookingId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	slotUsed?:boolean | `@${string}`,
	booking?:ResolverInputTypes["Booking"],
		__typename?: boolean | `@${string}`
}>;
	["SlotUsageOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingId?: ResolverInputTypes["SortOrder"] | undefined | null,
	date?: ResolverInputTypes["SortOrder"] | undefined | null,
	slotUsed?: ResolverInputTypes["SortOrder"] | undefined | null,
	booking?: ResolverInputTypes["BookingOrderByWithRelationInput"] | undefined | null
};
	["SlotUsageWhereUniqueInput"]: {
	id?: string | undefined | null,
	bookingId_date?: ResolverInputTypes["SlotUsageBookingIdDateCompoundUniqueInput"] | undefined | null,
	AND?: Array<ResolverInputTypes["SlotUsageWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SlotUsageWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SlotUsageWhereInput"]> | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	slotUsed?: ResolverInputTypes["IntFilter"] | undefined | null,
	booking?: ResolverInputTypes["BookingRelationFilter"] | undefined | null
};
	["SlotUsageBookingIdDateCompoundUniqueInput"]: {
	bookingId: string,
	date: ResolverInputTypes["DateTime"]
};
	["SlotUsageScalarFieldEnum"]:SlotUsageScalarFieldEnum;
	["Order"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
	priceSummary?:ResolverInputTypes["PriceSummary"],
	selectedDate?:ResolverInputTypes["OrderSelectedDate"],
	adBoard?:ResolverInputTypes["AdBoard"],
createdBy?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["User"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryOrderByInput"] | undefined | null,
	selectedDate?: ResolverInputTypes["OrderSelectedDateOrderByInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null
};
	["OrderWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["OrderWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["OrderWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["OrderWhereInput"]> | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdDate?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringFilter"] | undefined | null,
	paymentId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCompositeFilter"] | undefined | null,
	selectedDate?: ResolverInputTypes["OrderSelectedDateCompositeFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	createdBy?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null
};
	["OrderScalarFieldEnum"]:OrderScalarFieldEnum;
	["Notification"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	metadata?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
	channels?:boolean | `@${string}`,
	recipient?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	content?: ResolverInputTypes["SortOrder"] | undefined | null,
	metadata?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	readAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	recipientId?: ResolverInputTypes["SortOrder"] | undefined | null,
	channels?: ResolverInputTypes["SortOrder"] | undefined | null,
	recipient?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null
};
	["NotificationWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["NotificationWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["NotificationWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["NotificationWhereInput"]> | undefined | null,
	type?: ResolverInputTypes["EnumNotificationTypeFilter"] | undefined | null,
	title?: ResolverInputTypes["StringFilter"] | undefined | null,
	content?: ResolverInputTypes["StringFilter"] | undefined | null,
	metadata?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	readAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	recipientId?: ResolverInputTypes["StringFilter"] | undefined | null,
	channels?: ResolverInputTypes["EnumNotificationChannelNullableListFilter"] | undefined | null,
	recipient?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["NotificationScalarFieldEnum"]:NotificationScalarFieldEnum;
	["AdBoardPublishHistory"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
	publishedByUserId?:boolean | `@${string}`,
	changesSnapshot?:boolean | `@${string}`,
	adBoard?:ResolverInputTypes["AdBoard"],
	publishedBy?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardPublishHistoryOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	publishedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	publishedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	changesSnapshot?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null,
	publishedBy?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null
};
	["AdBoardPublishHistoryWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereInput"]> | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	publishedByUserId?: ResolverInputTypes["StringFilter"] | undefined | null,
	changesSnapshot?: ResolverInputTypes["JsonFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	publishedBy?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["AdBoardPublishHistoryScalarFieldEnum"]:AdBoardPublishHistoryScalarFieldEnum;
	["AdBoardReviewComment"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	adBoard?:ResolverInputTypes["AdBoard"],
	user?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardReviewCommentOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	comment?: ResolverInputTypes["SortOrder"] | undefined | null,
	action?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null,
	user?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null
};
	["AdBoardReviewCommentWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["AdBoardReviewCommentWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardReviewCommentWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardReviewCommentWhereInput"]> | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	comment?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["EnumReviewActionFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["AdBoardReviewCommentScalarFieldEnum"]:AdBoardReviewCommentScalarFieldEnum;
	["WorkspaceWhereUniqueInput"]: {
	id?: string | undefined | null,
	key?: string | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	AND?: Array<ResolverInputTypes["WorkspaceWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WorkspaceWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WorkspaceWhereInput"]> | undefined | null,
	type?: ResolverInputTypes["EnumWorkspaceTypeFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	userIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	ownerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	avatar?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	leadIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	owner?: ResolverInputTypes["UserNullableRelationFilter"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipListRelationFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistListRelationFilter"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupListRelationFilter"] | undefined | null,
	audits?: ResolverInputTypes["AuditListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null,
	files?: ResolverInputTypes["FileListRelationFilter"] | undefined | null,
	players?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionListRelationFilter"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionNullableRelationFilter"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerListRelationFilter"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleListRelationFilter"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogListRelationFilter"] | undefined | null,
	leads?: ResolverInputTypes["UserListRelationFilter"] | undefined | null
};
	["WorkspaceScalarFieldEnum"]:WorkspaceScalarFieldEnum;
	["AdBoardPublicReview"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	rating?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	adBoard?:ResolverInputTypes["AdBoard"],
	user?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardPublicReviewOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	rating?: ResolverInputTypes["SortOrder"] | undefined | null,
	comment?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null,
	user?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null
};
	["AdBoardPublicReviewWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["AdBoardPublicReviewWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardPublicReviewWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardPublicReviewWhereInput"]> | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	rating?: ResolverInputTypes["IntFilter"] | undefined | null,
	comment?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["AdBoardPublicReviewScalarFieldEnum"]:AdBoardPublicReviewScalarFieldEnum;
	["UserPosition"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	coords?:ResolverInputTypes["UserPositionCoords"],
	location?:ResolverInputTypes["UserPositionLocation"],
	user?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["UserPositionCoords"]: AliasType<{
	accuracy?:boolean | `@${string}`,
	altitude?:boolean | `@${string}`,
	altitudeAccuracy?:boolean | `@${string}`,
	heading?:boolean | `@${string}`,
	latitude?:boolean | `@${string}`,
	longitude?:boolean | `@${string}`,
	speed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionLocation"]: AliasType<{
	address?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	coords?: ResolverInputTypes["UserPositionCoordsOrderByInput"] | undefined | null,
	location?: ResolverInputTypes["UserPositionLocationOrderByInput"] | undefined | null,
	user?: ResolverInputTypes["UserOrderByWithRelationInput"] | undefined | null
};
	["UserPositionCoordsOrderByInput"]: {
	accuracy?: ResolverInputTypes["SortOrder"] | undefined | null,
	altitude?: ResolverInputTypes["SortOrder"] | undefined | null,
	altitudeAccuracy?: ResolverInputTypes["SortOrder"] | undefined | null,
	heading?: ResolverInputTypes["SortOrder"] | undefined | null,
	latitude?: ResolverInputTypes["SortOrder"] | undefined | null,
	longitude?: ResolverInputTypes["SortOrder"] | undefined | null,
	speed?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserPositionLocationOrderByInput"]: {
	address?: ResolverInputTypes["SortOrder"] | undefined | null,
	city?: ResolverInputTypes["SortOrder"] | undefined | null,
	state?: ResolverInputTypes["SortOrder"] | undefined | null,
	country?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserPositionWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["UserPositionWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserPositionWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserPositionWhereInput"]> | undefined | null,
	timestamp?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	coords?: ResolverInputTypes["UserPositionCoordsCompositeFilter"] | undefined | null,
	location?: ResolverInputTypes["UserPositionLocationNullableCompositeFilter"] | undefined | null,
	user?: ResolverInputTypes["UserRelationFilter"] | undefined | null
};
	["UserPositionScalarFieldEnum"]:UserPositionScalarFieldEnum;
	["Payment"]: AliasType<{
	id?:boolean | `@${string}`,
	paymentIntentId?:boolean | `@${string}`,
	checkoutSessionId?:boolean | `@${string}`,
	eventId?:boolean | `@${string}`,
	liveMode?:boolean | `@${string}`,
	paymentGateway?:boolean | `@${string}`,
	subtotal?:boolean | `@${string}`,
	total?:boolean | `@${string}`,
	tax?:boolean | `@${string}`,
	discount?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
campaign?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null},ResolverInputTypes["Campaign"]],
		__typename?: boolean | `@${string}`
}>;
	["PaymentOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentIntentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	checkoutSessionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	eventId?: ResolverInputTypes["SortOrder"] | undefined | null,
	liveMode?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentGateway?: ResolverInputTypes["SortOrder"] | undefined | null,
	subtotal?: ResolverInputTypes["SortOrder"] | undefined | null,
	total?: ResolverInputTypes["SortOrder"] | undefined | null,
	tax?: ResolverInputTypes["SortOrder"] | undefined | null,
	discount?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignOrderByWithRelationInput"] | undefined | null
};
	["PaymentWhereUniqueInput"]: {
	id?: string | undefined | null,
	paymentIntentId?: string | undefined | null,
	checkoutSessionId?: string | undefined | null,
	eventId?: string | undefined | null,
	AND?: Array<ResolverInputTypes["PaymentWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PaymentWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PaymentWhereInput"]> | undefined | null,
	liveMode?: ResolverInputTypes["BoolFilter"] | undefined | null,
	paymentGateway?: ResolverInputTypes["EnumPaymentGatewayNameFilter"] | undefined | null,
	subtotal?: ResolverInputTypes["FloatFilter"] | undefined | null,
	total?: ResolverInputTypes["FloatFilter"] | undefined | null,
	tax?: ResolverInputTypes["FloatFilter"] | undefined | null,
	discount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	campaignId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignNullableRelationFilter"] | undefined | null
};
	["PaymentScalarFieldEnum"]:PaymentScalarFieldEnum;
	["BusinessCategoryWhereUniqueInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	AND?: Array<ResolverInputTypes["BusinessCategoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BusinessCategoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BusinessCategoryWhereInput"]> | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	restrictedByAdBoardIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignListRelationFilter"] | undefined | null,
	bookings?: ResolverInputTypes["BookingListRelationFilter"] | undefined | null
};
	["BusinessCategoryScalarFieldEnum"]:BusinessCategoryScalarFieldEnum;
	["SlotConfiguration"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
	adBoard?:ResolverInputTypes["AdBoard"],
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationOrderByWithRelationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	date?: ResolverInputTypes["SortOrder"] | undefined | null,
	availableSlots?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardOrderByWithRelationInput"] | undefined | null
};
	["SlotConfigurationWhereUniqueInput"]: {
	id?: string | undefined | null,
	adBoardId_date?: ResolverInputTypes["SlotConfigurationAdBoardIdDateCompoundUniqueInput"] | undefined | null,
	AND?: Array<ResolverInputTypes["SlotConfigurationWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SlotConfigurationWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SlotConfigurationWhereInput"]> | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	availableSlots?: ResolverInputTypes["IntFilter"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardRelationFilter"] | undefined | null
};
	["SlotConfigurationAdBoardIdDateCompoundUniqueInput"]: {
	adBoardId: string,
	date: ResolverInputTypes["DateTime"]
};
	["SlotConfigurationScalarFieldEnum"]:SlotConfigurationScalarFieldEnum;
	["AdBoardGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	country?:boolean | `@${string}`,
	outdoor?:boolean | `@${string}`,
	locationCategoryId?:boolean | `@${string}`,
	locationSubCategoryId?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	pricePerDay?:boolean | `@${string}`,
	originalPricePerDay?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	adBoardTypeId?:boolean | `@${string}`,
	tags?:boolean | `@${string}`,
	featuredImage?:boolean | `@${string}`,
	galleryImgs?:boolean | `@${string}`,
	notes?:boolean | `@${string}`,
	impressionMultiplier?:boolean | `@${string}`,
	categoryId?:boolean | `@${string}`,
	restrictedBusinessIds?:boolean | `@${string}`,
	targetAudiences?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	adboardDraftStatus?:boolean | `@${string}`,
	isLive?:boolean | `@${string}`,
	numberOfDisplays?:boolean | `@${string}`,
	dailyOperationHours?:boolean | `@${string}`,
	adsPerLoop?:boolean | `@${string}`,
	adsDuration?:boolean | `@${string}`,
	monthlyPrice?:boolean | `@${string}`,
	offlineBookingsEnabled?:boolean | `@${string}`,
	offlineBookingContactId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	href?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	playerIds?:boolean | `@${string}`,
	publisherScreenId?:boolean | `@${string}`,
	additionalData?:boolean | `@${string}`,
	isDraft?:boolean | `@${string}`,
	publishedVersionId?:boolean | `@${string}`,
	lastPublishedAt?:boolean | `@${string}`,
	isArchived?:boolean | `@${string}`,
	archivedAt?:boolean | `@${string}`,
	archivedByUserId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AdBoardCountAggregate"],
	_avg?:ResolverInputTypes["AdBoardAvgAggregate"],
	_sum?:ResolverInputTypes["AdBoardSumAggregate"],
	_min?:ResolverInputTypes["AdBoardMinAggregate"],
	_max?:ResolverInputTypes["AdBoardMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	address?: ResolverInputTypes["SortOrder"] | undefined | null,
	state?: ResolverInputTypes["SortOrder"] | undefined | null,
	city?: ResolverInputTypes["SortOrder"] | undefined | null,
	country?: ResolverInputTypes["SortOrder"] | undefined | null,
	outdoor?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	alias?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	pricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	tags?: ResolverInputTypes["SortOrder"] | undefined | null,
	featuredImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	galleryImgs?: ResolverInputTypes["SortOrder"] | undefined | null,
	notes?: ResolverInputTypes["SortOrder"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["SortOrder"] | undefined | null,
	categoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	restrictedBusinessIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	targetAudiences?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	isLive?: ResolverInputTypes["SortOrder"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["SortOrder"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsDuration?: ResolverInputTypes["SortOrder"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	href?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["SortOrder"] | undefined | null,
	additionalData?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDraft?: ResolverInputTypes["SortOrder"] | undefined | null,
	publishedVersionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isArchived?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["AdBoardCountOrderByAggregateInput"] | undefined | null,
	_avg?: ResolverInputTypes["AdBoardAvgOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["AdBoardMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["AdBoardMinOrderByAggregateInput"] | undefined | null,
	_sum?: ResolverInputTypes["AdBoardSumOrderByAggregateInput"] | undefined | null
};
	["AdBoardCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	address?: ResolverInputTypes["SortOrder"] | undefined | null,
	state?: ResolverInputTypes["SortOrder"] | undefined | null,
	city?: ResolverInputTypes["SortOrder"] | undefined | null,
	country?: ResolverInputTypes["SortOrder"] | undefined | null,
	outdoor?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	alias?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	pricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	tags?: ResolverInputTypes["SortOrder"] | undefined | null,
	featuredImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	galleryImgs?: ResolverInputTypes["SortOrder"] | undefined | null,
	notes?: ResolverInputTypes["SortOrder"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["SortOrder"] | undefined | null,
	categoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	restrictedBusinessIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	targetAudiences?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	isLive?: ResolverInputTypes["SortOrder"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["SortOrder"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsDuration?: ResolverInputTypes["SortOrder"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	href?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["SortOrder"] | undefined | null,
	additionalData?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDraft?: ResolverInputTypes["SortOrder"] | undefined | null,
	publishedVersionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isArchived?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardAvgOrderByAggregateInput"]: {
	pricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["SortOrder"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["SortOrder"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsDuration?: ResolverInputTypes["SortOrder"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	address?: ResolverInputTypes["SortOrder"] | undefined | null,
	state?: ResolverInputTypes["SortOrder"] | undefined | null,
	city?: ResolverInputTypes["SortOrder"] | undefined | null,
	country?: ResolverInputTypes["SortOrder"] | undefined | null,
	outdoor?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	alias?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	pricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	featuredImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["SortOrder"] | undefined | null,
	categoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	isLive?: ResolverInputTypes["SortOrder"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["SortOrder"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsDuration?: ResolverInputTypes["SortOrder"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	href?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDraft?: ResolverInputTypes["SortOrder"] | undefined | null,
	publishedVersionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isArchived?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	address?: ResolverInputTypes["SortOrder"] | undefined | null,
	state?: ResolverInputTypes["SortOrder"] | undefined | null,
	city?: ResolverInputTypes["SortOrder"] | undefined | null,
	country?: ResolverInputTypes["SortOrder"] | undefined | null,
	outdoor?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	alias?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	pricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	featuredImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["SortOrder"] | undefined | null,
	categoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	isLive?: ResolverInputTypes["SortOrder"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["SortOrder"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsDuration?: ResolverInputTypes["SortOrder"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["SortOrder"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	href?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDraft?: ResolverInputTypes["SortOrder"] | undefined | null,
	publishedVersionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isArchived?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardSumOrderByAggregateInput"]: {
	pricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["SortOrder"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["SortOrder"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["SortOrder"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsDuration?: ResolverInputTypes["SortOrder"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	address?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	state?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	city?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	country?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	outdoor?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	alias?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	title?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	pricePerDay?: ResolverInputTypes["IntWithAggregatesFilter"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["IntNullableWithAggregatesFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	adBoardTypeId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	tags?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	featuredImage?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	galleryImgs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	notes?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["FloatNullableWithAggregatesFilter"] | undefined | null,
	categoryId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	restrictedBusinessIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	targetAudiences?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumAdBoardStatusWithAggregatesFilter"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["EnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined | null,
	isLive?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["IntWithAggregatesFilter"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["IntWithAggregatesFilter"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["IntWithAggregatesFilter"] | undefined | null,
	adsDuration?: ResolverInputTypes["IntWithAggregatesFilter"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["FloatNullableWithAggregatesFilter"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	href?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	playerIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	additionalData?: ResolverInputTypes["JsonNullableWithAggregatesFilter"] | undefined | null,
	isDraft?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	publishedVersionId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null,
	isArchived?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	archivedAt?: ResolverInputTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null
};
	["StringWithAggregatesFilter"]: {
	equals?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	mode?: ResolverInputTypes["QueryMode"] | undefined | null,
	not?: ResolverInputTypes["NestedStringWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedStringFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedStringFilter"] | undefined | null
};
	["NestedStringWithAggregatesFilter"]: {
	equals?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	not?: ResolverInputTypes["NestedStringWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedStringFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedStringFilter"] | undefined | null
};
	["BoolWithAggregatesFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["NestedBoolWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedBoolFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedBoolFilter"] | undefined | null
};
	["NestedBoolWithAggregatesFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["NestedBoolWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedBoolFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedBoolFilter"] | undefined | null
};
	["StringNullableWithAggregatesFilter"]: {
	equals?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	mode?: ResolverInputTypes["QueryMode"] | undefined | null,
	not?: ResolverInputTypes["NestedStringNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedStringNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedStringNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedStringNullableWithAggregatesFilter"]: {
	equals?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	not?: ResolverInputTypes["NestedStringNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedStringNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedStringNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["IntWithAggregatesFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedIntWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_avg?: ResolverInputTypes["NestedFloatFilter"] | undefined | null,
	_sum?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedIntFilter"] | undefined | null
};
	["NestedIntWithAggregatesFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedIntWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_avg?: ResolverInputTypes["NestedFloatFilter"] | undefined | null,
	_sum?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedIntFilter"] | undefined | null
};
	["IntNullableWithAggregatesFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedIntNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_avg?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	_sum?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedIntNullableWithAggregatesFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedIntNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_avg?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	_sum?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["FloatNullableWithAggregatesFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedFloatNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_avg?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	_sum?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedFloatNullableWithAggregatesFilter"]: {
	equals?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	not?: ResolverInputTypes["NestedFloatNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_avg?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	_sum?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedFloatNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["EnumAdBoardStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["AdBoardStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdBoardStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdBoardStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumAdBoardStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumAdBoardStatusFilter"] | undefined | null
};
	["NestedEnumAdBoardStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["AdBoardStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdBoardStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdBoardStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumAdBoardStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumAdBoardStatusFilter"] | undefined | null
};
	["EnumAdboardDraftStatusNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["AdboardDraftStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdboardDraftStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["AdboardDraftStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdboardDraftStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["DateTimeNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["NestedDateTimeNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedDateTimeNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedDateTimeNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedDateTimeNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["NestedDateTimeNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedDateTimeNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedDateTimeNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["JsonNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not?: ResolverInputTypes["JSON"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedJsonNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedJsonNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedJsonNullableFilter"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not?: ResolverInputTypes["JSON"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["AggregateAdBoardGroup"]: AliasType<{
	_count?:ResolverInputTypes["AdBoardGroupCountAggregate"],
	_min?:ResolverInputTypes["AdBoardGroupMinAggregate"],
	_max?:ResolverInputTypes["AdBoardGroupMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	scheduledPlaylistsIDs?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	scheduledPlaylistsIDs?:boolean | `@${string}`,
	adsPlaylistID?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	fitImage?:boolean | `@${string}`,
	fitVideo?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AdBoardGroupCountAggregate"],
	_min?:ResolverInputTypes["AdBoardGroupMinAggregate"],
	_max?:ResolverInputTypes["AdBoardGroupMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardGroupOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	scheduledPlaylistsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitVideo?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["AdBoardGroupCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["AdBoardGroupMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["AdBoardGroupMinOrderByAggregateInput"] | undefined | null
};
	["AdBoardGroupCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	scheduledPlaylistsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitVideo?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardGroupMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitVideo?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardGroupMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitImage?: ResolverInputTypes["SortOrder"] | undefined | null,
	fitVideo?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardGroupScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	scheduledPlaylistsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	fitImage?: ResolverInputTypes["EnumFitTypeWithAggregatesFilter"] | undefined | null,
	fitVideo?: ResolverInputTypes["EnumFitTypeWithAggregatesFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null
};
	["EnumFitTypeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["FitType"] | undefined | null,
	in?: Array<ResolverInputTypes["FitType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["FitType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumFitTypeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumFitTypeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumFitTypeFilter"] | undefined | null
};
	["NestedEnumFitTypeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["FitType"] | undefined | null,
	in?: Array<ResolverInputTypes["FitType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["FitType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumFitTypeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumFitTypeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumFitTypeFilter"] | undefined | null
};
	["AggregateAdBoardType"]: AliasType<{
	_count?:ResolverInputTypes["AdBoardTypeCountAggregate"],
	_min?:ResolverInputTypes["AdBoardTypeMinAggregate"],
	_max?:ResolverInputTypes["AdBoardTypeMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["AdBoardTypeWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardTypeWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardTypeWhereInput"]> | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null
};
	["AdBoardTypeScalarFieldEnum"]:AdBoardTypeScalarFieldEnum;
	["AdBoardTypeGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AdBoardTypeCountAggregate"],
	_min?:ResolverInputTypes["AdBoardTypeMinAggregate"],
	_max?:ResolverInputTypes["AdBoardTypeMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdBoardTypeOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["AdBoardTypeCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["AdBoardTypeMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["AdBoardTypeMinOrderByAggregateInput"] | undefined | null
};
	["AdBoardTypeCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardTypeMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardTypeMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdBoardTypeScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null
};
	["AggregateAdvertisementCategory"]: AliasType<{
	_count?:ResolverInputTypes["AdvertisementCategoryCountAggregate"],
	_min?:ResolverInputTypes["AdvertisementCategoryMinAggregate"],
	_max?:ResolverInputTypes["AdvertisementCategoryMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	bookingIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	bookingIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AdvertisementCategoryCountAggregate"],
	_min?:ResolverInputTypes["AdvertisementCategoryMinAggregate"],
	_max?:ResolverInputTypes["AdvertisementCategoryMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisementCategoryOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["AdvertisementCategoryCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["AdvertisementCategoryMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["AdvertisementCategoryMinOrderByAggregateInput"] | undefined | null
};
	["AdvertisementCategoryCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingIds?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdvertisementCategoryMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdvertisementCategoryMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AdvertisementCategoryScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	campaignIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["EnumAdvertisementCategoryEnumWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["AdvertisementCategoryEnum"] | undefined | null,
	in?: Array<ResolverInputTypes["AdvertisementCategoryEnum"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdvertisementCategoryEnum"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null
};
	["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["AdvertisementCategoryEnum"] | undefined | null,
	in?: Array<ResolverInputTypes["AdvertisementCategoryEnum"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AdvertisementCategoryEnum"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined | null
};
	["DateTimeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["NestedDateTimeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedDateTimeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedDateTimeFilter"] | undefined | null
};
	["NestedDateTimeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["NestedDateTimeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedDateTimeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedDateTimeFilter"] | undefined | null
};
	["AggregateAudit"]: AliasType<{
	_count?:ResolverInputTypes["AuditCountAggregate"],
	_min?:ResolverInputTypes["AuditMinAggregate"],
	_max?:ResolverInputTypes["AuditMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AuditCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	updates?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuditMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuditMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AuditGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	action?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	updates?:boolean | `@${string}`,
	_count?:ResolverInputTypes["AuditCountAggregate"],
	_min?:ResolverInputTypes["AuditMinAggregate"],
	_max?:ResolverInputTypes["AuditMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["AuditOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	message?: ResolverInputTypes["SortOrder"] | undefined | null,
	action?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updates?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["AuditCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["AuditMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["AuditMinOrderByAggregateInput"] | undefined | null
};
	["AuditCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	message?: ResolverInputTypes["SortOrder"] | undefined | null,
	action?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updates?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AuditMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	message?: ResolverInputTypes["SortOrder"] | undefined | null,
	action?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AuditMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	message?: ResolverInputTypes["SortOrder"] | undefined | null,
	action?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["AuditScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["AuditScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AuditScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AuditScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	message?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	action?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	updates?: ResolverInputTypes["JsonNullableWithAggregatesFilter"] | undefined | null
};
	["AggregateBooking"]: AliasType<{
	_count?:ResolverInputTypes["BookingCountAggregate"],
	_min?:ResolverInputTypes["BookingMinAggregate"],
	_max?:ResolverInputTypes["BookingMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["BookingCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	sharedUserIds?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	additionalInformation?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	bookingCode?:boolean | `@${string}`,
	bookingDate?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	bookingStatus?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	deploymentStatus?:boolean | `@${string}`,
	contentApprovalStatus?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	sharedUserIds?:boolean | `@${string}`,
	isOffline?:boolean | `@${string}`,
	isPaid?:boolean | `@${string}`,
	isScheduled?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	isCompleted?:boolean | `@${string}`,
	_count?:ResolverInputTypes["BookingCountAggregate"],
	_min?:ResolverInputTypes["BookingMinAggregate"],
	_max?:ResolverInputTypes["BookingMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["BookingOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	additionalInformation?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	assetsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingCode?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignId?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	sharedUserIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOffline?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPaid?: ResolverInputTypes["SortOrder"] | undefined | null,
	isScheduled?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	isCompleted?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["BookingCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["BookingMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["BookingMinOrderByAggregateInput"] | undefined | null
};
	["BookingCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	additionalInformation?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	assetsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingCode?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignId?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	sharedUserIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOffline?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPaid?: ResolverInputTypes["SortOrder"] | undefined | null,
	isScheduled?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	isCompleted?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	additionalInformation?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingCode?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignId?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOffline?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPaid?: ResolverInputTypes["SortOrder"] | undefined | null,
	isScheduled?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	isCompleted?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	additionalInformation?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingCode?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignId?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOffline?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPaid?: ResolverInputTypes["SortOrder"] | undefined | null,
	isScheduled?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	isCompleted?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BookingScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["BookingScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	additionalInformation?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	assetsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingCode?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	bookingDate?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	bookingFor?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	purpose?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	campaignId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	adCategoryId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingStatus?: ResolverInputTypes["EnumBookingStatusWithAggregatesFilter"] | undefined | null,
	paymentStatus?: ResolverInputTypes["EnumPaymentStatusNullableWithAggregatesFilter"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["EnumDeploymentStatusWithAggregatesFilter"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["EnumContentApprovalStatusWithAggregatesFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	sharedUserIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	isOffline?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	isPaid?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	isScheduled?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	isDeployed?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	isCompleted?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null
};
	["EnumBookingStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["BookingStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["BookingStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["BookingStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumBookingStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumBookingStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumBookingStatusFilter"] | undefined | null
};
	["NestedEnumBookingStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["BookingStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["BookingStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["BookingStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumBookingStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumBookingStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumBookingStatusFilter"] | undefined | null
};
	["EnumPaymentStatusNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedEnumPaymentStatusNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumPaymentStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["EnumDeploymentStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["DeploymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DeploymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumDeploymentStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumDeploymentStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumDeploymentStatusFilter"] | undefined | null
};
	["NestedEnumDeploymentStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["DeploymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DeploymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumDeploymentStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumDeploymentStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumDeploymentStatusFilter"] | undefined | null
};
	["EnumContentApprovalStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentApprovalStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentApprovalStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null
};
	["NestedEnumContentApprovalStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentApprovalStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentApprovalStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumContentApprovalStatusFilter"] | undefined | null
};
	["AggregateBusinessCategory"]: AliasType<{
	_count?:ResolverInputTypes["BusinessCategoryCountAggregate"],
	_min?:ResolverInputTypes["BusinessCategoryMinAggregate"],
	_max?:ResolverInputTypes["BusinessCategoryMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	restrictedByAdBoardIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	restrictedByAdBoardIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["BusinessCategoryCountAggregate"],
	_min?:ResolverInputTypes["BusinessCategoryMinAggregate"],
	_max?:ResolverInputTypes["BusinessCategoryMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	restrictedByAdBoardIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["BusinessCategoryCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["BusinessCategoryMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["BusinessCategoryMinOrderByAggregateInput"] | undefined | null
};
	["BusinessCategoryCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null,
	restrictedByAdBoardIds?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BusinessCategoryMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BusinessCategoryMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	description?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BusinessCategoryScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	restrictedByAdBoardIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["AggregateCamera"]: AliasType<{
	_count?:ResolverInputTypes["CameraCountAggregate"],
	_min?:ResolverInputTypes["CameraMinAggregate"],
	_max?:ResolverInputTypes["CameraMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	connectedCpuSerialNumbers?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	cameraUrl?:boolean | `@${string}`,
	rtspUrl?:boolean | `@${string}`,
	cameraIp?:boolean | `@${string}`,
	connectedCpuSerialNumbers?:boolean | `@${string}`,
	playerId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	isPublic?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["CameraCountAggregate"],
	_min?:ResolverInputTypes["CameraMinAggregate"],
	_max?:ResolverInputTypes["CameraMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	rtspUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	connectedCpuSerialNumbers?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPublic?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["CameraCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["CameraMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["CameraMinOrderByAggregateInput"] | undefined | null
};
	["CameraCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	rtspUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	connectedCpuSerialNumbers?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPublic?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	rtspUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPublic?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	rtspUrl?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	playerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	isPublic?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["CameraScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	cameraServerId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	cameraUrl?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	rtspUrl?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	cameraIp?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	connectedCpuSerialNumbers?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	playerId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	isPublic?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null
};
	["AggregateCameraFeed"]: AliasType<{
	_count?:ResolverInputTypes["CameraFeedCountAggregate"],
	_min?:ResolverInputTypes["CameraFeedMinAggregate"],
	_max?:ResolverInputTypes["CameraFeedMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	cameraId?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	cameraServerId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["CameraFeedCountAggregate"],
	_min?:ResolverInputTypes["CameraFeedMinAggregate"],
	_max?:ResolverInputTypes["CameraFeedMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraFeedOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraId?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["CameraFeedCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["CameraFeedMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["CameraFeedMinOrderByAggregateInput"] | undefined | null
};
	["CameraFeedCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraId?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraFeedMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraId?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraFeedMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraId?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	cameraServerId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraFeedScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	cameraId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	timestamp?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	cameraServerId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null
};
	["AggregateCameraServer"]: AliasType<{
	_count?:ResolverInputTypes["CameraServerCountAggregate"],
	_min?:ResolverInputTypes["CameraServerMinAggregate"],
	_max?:ResolverInputTypes["CameraServerMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraServerCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraServerMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraServerMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CameraServerGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	hostName?:boolean | `@${string}`,
	userName?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	publicIp?:boolean | `@${string}`,
	privateIp?:boolean | `@${string}`,
	_count?:ResolverInputTypes["CameraServerCountAggregate"],
	_min?:ResolverInputTypes["CameraServerMinAggregate"],
	_max?:ResolverInputTypes["CameraServerMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CameraServerOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	hostName?: ResolverInputTypes["SortOrder"] | undefined | null,
	userName?: ResolverInputTypes["SortOrder"] | undefined | null,
	deviceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	publicIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	privateIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["CameraServerCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["CameraServerMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["CameraServerMinOrderByAggregateInput"] | undefined | null
};
	["CameraServerCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	hostName?: ResolverInputTypes["SortOrder"] | undefined | null,
	userName?: ResolverInputTypes["SortOrder"] | undefined | null,
	deviceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	publicIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	privateIp?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraServerMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	hostName?: ResolverInputTypes["SortOrder"] | undefined | null,
	userName?: ResolverInputTypes["SortOrder"] | undefined | null,
	deviceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	publicIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	privateIp?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraServerMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	hostName?: ResolverInputTypes["SortOrder"] | undefined | null,
	userName?: ResolverInputTypes["SortOrder"] | undefined | null,
	deviceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	publicIp?: ResolverInputTypes["SortOrder"] | undefined | null,
	privateIp?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CameraServerScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolNullableWithAggregatesFilter"] | undefined | null,
	hostName?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	userName?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	deviceId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	publicIp?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	privateIp?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null
};
	["BoolNullableWithAggregatesFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["NestedBoolNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedBoolNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedBoolNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedBoolNullableWithAggregatesFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["NestedBoolNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedBoolNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedBoolNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["AggregateCampaign"]: AliasType<{
	_count?:ResolverInputTypes["CampaignCountAggregate"],
	_min?:ResolverInputTypes["CampaignMinAggregate"],
	_max?:ResolverInputTypes["CampaignMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	bookingFor?:boolean | `@${string}`,
	purpose?:boolean | `@${string}`,
	businessCategoryId?:boolean | `@${string}`,
	adCategoryId?:boolean | `@${string}`,
	paymentStatus?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	assetsIDs?:boolean | `@${string}`,
	_count?:ResolverInputTypes["CampaignCountAggregate"],
	_min?:ResolverInputTypes["CampaignMinAggregate"],
	_max?:ResolverInputTypes["CampaignMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	assetsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["CampaignCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["CampaignMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["CampaignMinOrderByAggregateInput"] | undefined | null
};
	["CampaignCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	adCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	assetsIDs?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CampaignMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CampaignMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingFor?: ResolverInputTypes["SortOrder"] | undefined | null,
	purpose?: ResolverInputTypes["SortOrder"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["CampaignScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	bookingFor?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	purpose?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	adCategoryId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	paymentStatus?: ResolverInputTypes["EnumPaymentStatusWithAggregatesFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	assetsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["EnumPaymentStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumPaymentStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumPaymentStatusFilter"] | undefined | null
};
	["NestedEnumPaymentStatusWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["PaymentStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumPaymentStatusWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumPaymentStatusFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumPaymentStatusFilter"] | undefined | null
};
	["AggregateFile"]: AliasType<{
	_count?:ResolverInputTypes["FileCountAggregate"],
	_avg?:ResolverInputTypes["FileAvgAggregate"],
	_sum?:ResolverInputTypes["FileSumAggregate"],
	_min?:ResolverInputTypes["FileMinAggregate"],
	_max?:ResolverInputTypes["FileMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["FileCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	bookingsIDs?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileAvgAggregate"]: AliasType<{
	size?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileSumAggregate"]: AliasType<{
	size?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	ctime?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	filepath?:boolean | `@${string}`,
	isDirectory?:boolean | `@${string}`,
	mtime?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedByUserId?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	bookingsIDs?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	proofBookingId?:boolean | `@${string}`,
	campaignIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["FileCountAggregate"],
	_avg?:ResolverInputTypes["FileAvgAggregate"],
	_sum?:ResolverInputTypes["FileSumAggregate"],
	_min?:ResolverInputTypes["FileMinAggregate"],
	_max?:ResolverInputTypes["FileMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["FileOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentType?: ResolverInputTypes["SortOrder"] | undefined | null,
	ctime?: ResolverInputTypes["SortOrder"] | undefined | null,
	filename?: ResolverInputTypes["SortOrder"] | undefined | null,
	filepath?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDirectory?: ResolverInputTypes["SortOrder"] | undefined | null,
	mtime?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	size?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	url?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	proofBookingId?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["FileCountOrderByAggregateInput"] | undefined | null,
	_avg?: ResolverInputTypes["FileAvgOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["FileMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["FileMinOrderByAggregateInput"] | undefined | null,
	_sum?: ResolverInputTypes["FileSumOrderByAggregateInput"] | undefined | null
};
	["FileCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentType?: ResolverInputTypes["SortOrder"] | undefined | null,
	ctime?: ResolverInputTypes["SortOrder"] | undefined | null,
	filename?: ResolverInputTypes["SortOrder"] | undefined | null,
	filepath?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDirectory?: ResolverInputTypes["SortOrder"] | undefined | null,
	mtime?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	size?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	url?: ResolverInputTypes["SortOrder"] | undefined | null,
	bookingsIDs?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	proofBookingId?: ResolverInputTypes["SortOrder"] | undefined | null,
	campaignIds?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FileAvgOrderByAggregateInput"]: {
	size?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FileMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentType?: ResolverInputTypes["SortOrder"] | undefined | null,
	ctime?: ResolverInputTypes["SortOrder"] | undefined | null,
	filename?: ResolverInputTypes["SortOrder"] | undefined | null,
	filepath?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDirectory?: ResolverInputTypes["SortOrder"] | undefined | null,
	mtime?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	size?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	url?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	proofBookingId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FileMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	contentType?: ResolverInputTypes["SortOrder"] | undefined | null,
	ctime?: ResolverInputTypes["SortOrder"] | undefined | null,
	filename?: ResolverInputTypes["SortOrder"] | undefined | null,
	filepath?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDirectory?: ResolverInputTypes["SortOrder"] | undefined | null,
	mtime?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	size?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	url?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	proofBookingId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FileSumOrderByAggregateInput"]: {
	size?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FileScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["FileScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["FileScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["FileScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	contentType?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	ctime?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	filename?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	filepath?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	isDirectory?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	mtime?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	size?: ResolverInputTypes["IntWithAggregatesFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumFileStatusNullableWithAggregatesFilter"] | undefined | null,
	type?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	url?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	bookingsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	proofBookingId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	campaignIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["EnumFileStatusNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["FileStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["FileStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["FileStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumFileStatusNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumFileStatusNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumFileStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["NestedEnumFileStatusNullableWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["FileStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["FileStatus"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["FileStatus"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumFileStatusNullableWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntNullableFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumFileStatusNullableFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumFileStatusNullableFilter"] | undefined | null,
	isSet?: boolean | undefined | null
};
	["AggregateMembership"]: AliasType<{
	_count?:ResolverInputTypes["MembershipCountAggregate"],
	_min?:ResolverInputTypes["MembershipMinAggregate"],
	_max?:ResolverInputTypes["MembershipMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["MembershipCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MembershipMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MembershipMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MembershipGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["MembershipCountAggregate"],
	_min?:ResolverInputTypes["MembershipMinAggregate"],
	_max?:ResolverInputTypes["MembershipMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["MembershipOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	roleId?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["MembershipCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["MembershipMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["MembershipMinOrderByAggregateInput"] | undefined | null
};
	["MembershipCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	roleId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["MembershipMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	roleId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["MembershipMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	roleId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["MembershipScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	roleId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null
};
	["AggregateNotification"]: AliasType<{
	_count?:ResolverInputTypes["NotificationCountAggregate"],
	_min?:ResolverInputTypes["NotificationMinAggregate"],
	_max?:ResolverInputTypes["NotificationMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	metadata?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
	channels?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	metadata?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	readAt?:boolean | `@${string}`,
	recipientId?:boolean | `@${string}`,
	channels?:boolean | `@${string}`,
	_count?:ResolverInputTypes["NotificationCountAggregate"],
	_min?:ResolverInputTypes["NotificationMinAggregate"],
	_max?:ResolverInputTypes["NotificationMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	content?: ResolverInputTypes["SortOrder"] | undefined | null,
	metadata?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	readAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	recipientId?: ResolverInputTypes["SortOrder"] | undefined | null,
	channels?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["NotificationCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["NotificationMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["NotificationMinOrderByAggregateInput"] | undefined | null
};
	["NotificationCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	content?: ResolverInputTypes["SortOrder"] | undefined | null,
	metadata?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	readAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	recipientId?: ResolverInputTypes["SortOrder"] | undefined | null,
	channels?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["NotificationMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	content?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	readAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	recipientId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["NotificationMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	title?: ResolverInputTypes["SortOrder"] | undefined | null,
	content?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	readAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	recipientId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["NotificationScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumNotificationTypeWithAggregatesFilter"] | undefined | null,
	title?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	content?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	metadata?: ResolverInputTypes["JsonNullableWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	readAt?: ResolverInputTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null,
	recipientId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	channels?: ResolverInputTypes["EnumNotificationChannelNullableListFilter"] | undefined | null
};
	["EnumNotificationTypeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["NotificationType"] | undefined | null,
	in?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumNotificationTypeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumNotificationTypeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumNotificationTypeFilter"] | undefined | null
};
	["NestedEnumNotificationTypeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["NotificationType"] | undefined | null,
	in?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumNotificationTypeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumNotificationTypeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumNotificationTypeFilter"] | undefined | null
};
	["AggregateOrder"]: AliasType<{
	_count?:ResolverInputTypes["OrderCountAggregate"],
	_min?:ResolverInputTypes["OrderMinAggregate"],
	_max?:ResolverInputTypes["OrderMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["OrderCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	createdDate?:boolean | `@${string}`,
	createdByUserId?:boolean | `@${string}`,
	origin?:boolean | `@${string}`,
	paymentId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["OrderCountAggregate"],
	_min?:ResolverInputTypes["OrderMinAggregate"],
	_max?:ResolverInputTypes["OrderMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["OrderOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["OrderCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["OrderMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["OrderMinOrderByAggregateInput"] | undefined | null
};
	["OrderCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["OrderMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["OrderMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdByUserId?: ResolverInputTypes["SortOrder"] | undefined | null,
	origin?: ResolverInputTypes["SortOrder"] | undefined | null,
	paymentId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["OrderScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["OrderScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["OrderScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["OrderScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	createdDate?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	paymentId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null
};
	["AggregatePlayer"]: AliasType<{
	_count?:ResolverInputTypes["PlayerCountAggregate"],
	_avg?:ResolverInputTypes["PlayerAvgAggregate"],
	_sum?:ResolverInputTypes["PlayerSumAggregate"],
	_min?:ResolverInputTypes["PlayerMinAggregate"],
	_max?:ResolverInputTypes["PlayerMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["PlayerCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	playlistIds?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	signageTypeId?:boolean | `@${string}`,
	audienceTypeId?:boolean | `@${string}`,
	aspectRatioType?:boolean | `@${string}`,
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	adBoardIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerAvgAggregate"]: AliasType<{
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerSumAggregate"]: AliasType<{
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	signageTypeId?:boolean | `@${string}`,
	aspectRatioType?:boolean | `@${string}`,
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	signageTypeId?:boolean | `@${string}`,
	aspectRatioType?:boolean | `@${string}`,
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlayerGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	configLocation?:boolean | `@${string}`,
	pisignagePlayerId?:boolean | `@${string}`,
	pisignageGroupId?:boolean | `@${string}`,
	cpuSerialNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	adBoardGroupId?:boolean | `@${string}`,
	playlistIds?:boolean | `@${string}`,
	workpaceId?:boolean | `@${string}`,
	signageTypeId?:boolean | `@${string}`,
	audienceTypeId?:boolean | `@${string}`,
	aspectRatioType?:boolean | `@${string}`,
	customAspectRatioWidth?:boolean | `@${string}`,
	customAspectRatioHeight?:boolean | `@${string}`,
	isOnline?:boolean | `@${string}`,
	adBoardIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["PlayerCountAggregate"],
	_avg?:ResolverInputTypes["PlayerAvgAggregate"],
	_sum?:ResolverInputTypes["PlayerSumAggregate"],
	_min?:ResolverInputTypes["PlayerMinAggregate"],
	_max?:ResolverInputTypes["PlayerMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["PlayerOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	configLocation?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	playlistIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	workpaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	signageTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	audienceTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	aspectRatioType?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["PlayerCountOrderByAggregateInput"] | undefined | null,
	_avg?: ResolverInputTypes["PlayerAvgOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["PlayerMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["PlayerMinOrderByAggregateInput"] | undefined | null,
	_sum?: ResolverInputTypes["PlayerSumOrderByAggregateInput"] | undefined | null
};
	["PlayerCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	configLocation?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	playlistIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	workpaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	signageTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	audienceTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	aspectRatioType?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardIds?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlayerAvgOrderByAggregateInput"]: {
	customAspectRatioWidth?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlayerMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	configLocation?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workpaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	signageTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	aspectRatioType?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlayerMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	configLocation?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["SortOrder"] | undefined | null,
	workpaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	signageTypeId?: ResolverInputTypes["SortOrder"] | undefined | null,
	aspectRatioType?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["SortOrder"] | undefined | null,
	isOnline?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlayerSumOrderByAggregateInput"]: {
	customAspectRatioWidth?: ResolverInputTypes["SortOrder"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlayerScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	configLocation?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	playlistIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workpaceId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	signageTypeId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	audienceTypeId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	aspectRatioType?: ResolverInputTypes["EnumAspectRatioTypeWithAggregatesFilter"] | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["IntNullableWithAggregatesFilter"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["IntNullableWithAggregatesFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	adBoardIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["EnumAspectRatioTypeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	in?: Array<ResolverInputTypes["AspectRatioType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AspectRatioType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null
};
	["NestedEnumAspectRatioTypeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	in?: Array<ResolverInputTypes["AspectRatioType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["AspectRatioType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumAspectRatioTypeFilter"] | undefined | null
};
	["AggregatePlaylist"]: AliasType<{
	_count?:ResolverInputTypes["PlaylistCountAggregate"],
	_min?:ResolverInputTypes["PlaylistMinAggregate"],
	_max?:ResolverInputTypes["PlaylistMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["PlaylistCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	adBoardGroupIds?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaylistMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaylistMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlaylistGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	isAdsPlaylist?:boolean | `@${string}`,
	footerPlaylistId?:boolean | `@${string}`,
	sidebarPlaylistId?:boolean | `@${string}`,
	layout?:boolean | `@${string}`,
	isDeployed?:boolean | `@${string}`,
	adBoardGroupIds?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["PlaylistCountAggregate"],
	_min?:ResolverInputTypes["PlaylistMinAggregate"],
	_max?:ResolverInputTypes["PlaylistMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["PlaylistOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["SortOrder"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	layout?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroupIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["PlaylistCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["PlaylistMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["PlaylistMinOrderByAggregateInput"] | undefined | null
};
	["PlaylistCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["SortOrder"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	layout?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardGroupIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlaylistMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["SortOrder"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	layout?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlaylistMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["SortOrder"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["SortOrder"] | undefined | null,
	layout?: ResolverInputTypes["SortOrder"] | undefined | null,
	isDeployed?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlaylistScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["BoolNullableWithAggregatesFilter"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	layout?: ResolverInputTypes["EnumScreenLayoutWithAggregatesFilter"] | undefined | null,
	isDeployed?: ResolverInputTypes["BoolNullableWithAggregatesFilter"] | undefined | null,
	adBoardGroupIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null
};
	["EnumScreenLayoutWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	in?: Array<ResolverInputTypes["ScreenLayout"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ScreenLayout"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumScreenLayoutWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumScreenLayoutFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumScreenLayoutFilter"] | undefined | null
};
	["NestedEnumScreenLayoutWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	in?: Array<ResolverInputTypes["ScreenLayout"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ScreenLayout"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumScreenLayoutWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumScreenLayoutFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumScreenLayoutFilter"] | undefined | null
};
	["AggregateSlotConfiguration"]: AliasType<{
	_count?:ResolverInputTypes["SlotConfigurationCountAggregate"],
	_avg?:ResolverInputTypes["SlotConfigurationAvgAggregate"],
	_sum?:ResolverInputTypes["SlotConfigurationSumAggregate"],
	_min?:ResolverInputTypes["SlotConfigurationMinAggregate"],
	_max?:ResolverInputTypes["SlotConfigurationMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationAvgAggregate"]: AliasType<{
	availableSlots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationSumAggregate"]: AliasType<{
	availableSlots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	adBoardId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	availableSlots?:boolean | `@${string}`,
	_count?:ResolverInputTypes["SlotConfigurationCountAggregate"],
	_avg?:ResolverInputTypes["SlotConfigurationAvgAggregate"],
	_sum?:ResolverInputTypes["SlotConfigurationSumAggregate"],
	_min?:ResolverInputTypes["SlotConfigurationMinAggregate"],
	_max?:ResolverInputTypes["SlotConfigurationMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["SlotConfigurationOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	date?: ResolverInputTypes["SortOrder"] | undefined | null,
	availableSlots?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["SlotConfigurationCountOrderByAggregateInput"] | undefined | null,
	_avg?: ResolverInputTypes["SlotConfigurationAvgOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["SlotConfigurationMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["SlotConfigurationMinOrderByAggregateInput"] | undefined | null,
	_sum?: ResolverInputTypes["SlotConfigurationSumOrderByAggregateInput"] | undefined | null
};
	["SlotConfigurationCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	date?: ResolverInputTypes["SortOrder"] | undefined | null,
	availableSlots?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SlotConfigurationAvgOrderByAggregateInput"]: {
	availableSlots?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SlotConfigurationMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	date?: ResolverInputTypes["SortOrder"] | undefined | null,
	availableSlots?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SlotConfigurationMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	adBoardId?: ResolverInputTypes["SortOrder"] | undefined | null,
	date?: ResolverInputTypes["SortOrder"] | undefined | null,
	availableSlots?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SlotConfigurationSumOrderByAggregateInput"]: {
	availableSlots?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SlotConfigurationScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	date?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	availableSlots?: ResolverInputTypes["IntWithAggregatesFilter"] | undefined | null
};
	["AggregateSubscription"]: AliasType<{
	_count?:ResolverInputTypes["SubscriptionCountAggregate"],
	_min?:ResolverInputTypes["SubscriptionMinAggregate"],
	_max?:ResolverInputTypes["SubscriptionMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	activeWorkspaceId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	activeWorkspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	activeWorkspaceId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionWhereUniqueInput"]: {
	id?: string | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	stripeCheckoutSessionId?: string | undefined | null,
	activeWorkspaceId?: string | undefined | null,
	AND?: Array<ResolverInputTypes["SubscriptionWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SubscriptionWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SubscriptionWhereInput"]> | undefined | null,
	packageId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	packageName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	priceLookupKey?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	startDate?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	endDate?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	status?: ResolverInputTypes["StringFilter"] | undefined | null,
	stripePaymentIntentId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsNullableCompositeFilter"] | undefined | null,
	activeWorkspace?: ResolverInputTypes["WorkspaceRelationFilter"] | undefined | null
};
	["SubscriptionScalarFieldEnum"]:SubscriptionScalarFieldEnum;
	["SubscriptionGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	packageId?:boolean | `@${string}`,
	packageName?:boolean | `@${string}`,
	priceLookupKey?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	stripePaymentIntentId?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	stripeCheckoutSessionId?:boolean | `@${string}`,
	activeWorkspaceId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["SubscriptionCountAggregate"],
	_min?:ResolverInputTypes["SubscriptionMinAggregate"],
	_max?:ResolverInputTypes["SubscriptionMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageId?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageName?: ResolverInputTypes["SortOrder"] | undefined | null,
	priceLookupKey?: ResolverInputTypes["SortOrder"] | undefined | null,
	startDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	endDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripePaymentIntentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeCheckoutSessionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	activeWorkspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["SubscriptionCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["SubscriptionMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["SubscriptionMinOrderByAggregateInput"] | undefined | null
};
	["SubscriptionCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageId?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageName?: ResolverInputTypes["SortOrder"] | undefined | null,
	priceLookupKey?: ResolverInputTypes["SortOrder"] | undefined | null,
	startDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	endDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripePaymentIntentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeCheckoutSessionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	activeWorkspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SubscriptionMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageId?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageName?: ResolverInputTypes["SortOrder"] | undefined | null,
	priceLookupKey?: ResolverInputTypes["SortOrder"] | undefined | null,
	startDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	endDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripePaymentIntentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeCheckoutSessionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	activeWorkspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SubscriptionMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageId?: ResolverInputTypes["SortOrder"] | undefined | null,
	packageName?: ResolverInputTypes["SortOrder"] | undefined | null,
	priceLookupKey?: ResolverInputTypes["SortOrder"] | undefined | null,
	startDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	endDate?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripePaymentIntentId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeCheckoutSessionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	activeWorkspaceId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["SubscriptionScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	packageId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	packageName?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	priceLookupKey?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	startDate?: ResolverInputTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null,
	endDate?: ResolverInputTypes["DateTimeNullableWithAggregatesFilter"] | undefined | null,
	status?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	stripePaymentIntentId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	stripeCheckoutSessionId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	activeWorkspaceId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null
};
	["AggregateUser"]: AliasType<{
	_count?:ResolverInputTypes["UserCountAggregate"],
	_min?:ResolverInputTypes["UserMinAggregate"],
	_max?:ResolverInputTypes["UserMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["UserCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	otherDetails?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
	sharedBookingIds?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
	leadWorkspaceIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	uid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	displayName?:boolean | `@${string}`,
	otherDetails?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
	sharedBookingIds?:boolean | `@${string}`,
	disclaimerAccepted?:boolean | `@${string}`,
	leadWorkspaceIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["UserCountAggregate"],
	_min?:ResolverInputTypes["UserMinAggregate"],
	_max?:ResolverInputTypes["UserMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["UserOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	uid?: ResolverInputTypes["SortOrder"] | undefined | null,
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	image?: ResolverInputTypes["SortOrder"] | undefined | null,
	phoneNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	otherDetails?: ResolverInputTypes["SortOrder"] | undefined | null,
	roles?: ResolverInputTypes["SortOrder"] | undefined | null,
	sharedBookingIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["SortOrder"] | undefined | null,
	leadWorkspaceIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["UserCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["UserMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["UserMinOrderByAggregateInput"] | undefined | null
};
	["UserCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	uid?: ResolverInputTypes["SortOrder"] | undefined | null,
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	image?: ResolverInputTypes["SortOrder"] | undefined | null,
	phoneNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	otherDetails?: ResolverInputTypes["SortOrder"] | undefined | null,
	roles?: ResolverInputTypes["SortOrder"] | undefined | null,
	sharedBookingIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["SortOrder"] | undefined | null,
	leadWorkspaceIds?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	uid?: ResolverInputTypes["SortOrder"] | undefined | null,
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	image?: ResolverInputTypes["SortOrder"] | undefined | null,
	phoneNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	uid?: ResolverInputTypes["SortOrder"] | undefined | null,
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	image?: ResolverInputTypes["SortOrder"] | undefined | null,
	phoneNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	displayName?: ResolverInputTypes["SortOrder"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["UserScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	uid?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	email?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	image?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	phoneNumber?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	otherDetails?: ResolverInputTypes["JsonNullableWithAggregatesFilter"] | undefined | null,
	roles?: ResolverInputTypes["EnumRoleNullableListFilter"] | undefined | null,
	sharedBookingIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["BoolWithAggregatesFilter"] | undefined | null,
	leadWorkspaceIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["AggregateUserPosition"]: AliasType<{
	_count?:ResolverInputTypes["UserPositionCountAggregate"],
	_min?:ResolverInputTypes["UserPositionMinAggregate"],
	_max?:ResolverInputTypes["UserPositionMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["UserPositionCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPositionGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	timestamp?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	_count?:ResolverInputTypes["UserPositionCountAggregate"],
	_min?:ResolverInputTypes["UserPositionMinAggregate"],
	_max?:ResolverInputTypes["UserPositionMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["UserPositionOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["UserPositionCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["UserPositionMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["UserPositionMinOrderByAggregateInput"] | undefined | null
};
	["UserPositionCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserPositionMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserPositionMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	timestamp?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserPositionScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	timestamp?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null
};
	["AggregateWorkspace"]: AliasType<{
	_count?:ResolverInputTypes["WorkspaceCountAggregate"],
	_min?:ResolverInputTypes["WorkspaceMinAggregate"],
	_max?:ResolverInputTypes["WorkspaceMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	userIds?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	leadIds?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	userIds?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
	avatar?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	stripeSubscriptionId?:boolean | `@${string}`,
	leadIds?:boolean | `@${string}`,
	_count?:ResolverInputTypes["WorkspaceCountAggregate"],
	_min?:ResolverInputTypes["WorkspaceMinAggregate"],
	_max?:ResolverInputTypes["WorkspaceMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	key?: ResolverInputTypes["SortOrder"] | undefined | null,
	userIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	ownerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	avatar?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	leadIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["WorkspaceCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["WorkspaceMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["WorkspaceMinOrderByAggregateInput"] | undefined | null
};
	["WorkspaceCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	key?: ResolverInputTypes["SortOrder"] | undefined | null,
	userIds?: ResolverInputTypes["SortOrder"] | undefined | null,
	ownerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	avatar?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null,
	leadIds?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	key?: ResolverInputTypes["SortOrder"] | undefined | null,
	ownerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	avatar?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	type?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	key?: ResolverInputTypes["SortOrder"] | undefined | null,
	ownerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	avatar?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumWorkspaceTypeWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	key?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	userIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	ownerId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	avatar?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	leadIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["EnumWorkspaceTypeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	in?: Array<ResolverInputTypes["WorkspaceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["WorkspaceType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null
};
	["NestedEnumWorkspaceTypeWithAggregatesFilter"]: {
	equals?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	in?: Array<ResolverInputTypes["WorkspaceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["WorkspaceType"]> | undefined | null,
	not?: ResolverInputTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"] | undefined | null,
	_count?: ResolverInputTypes["NestedIntFilter"] | undefined | null,
	_min?: ResolverInputTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null,
	_max?: ResolverInputTypes["NestedEnumWorkspaceTypeFilter"] | undefined | null
};
	["AggregateWorkspaceRole"]: AliasType<{
	_count?:ResolverInputTypes["WorkspaceRoleCountAggregate"],
	_min?:ResolverInputTypes["WorkspaceRoleMinAggregate"],
	_max?:ResolverInputTypes["WorkspaceRoleMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleCountAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_all?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleMinAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleMaxAggregate"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleGroupBy"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:boolean | `@${string}`,
	workspaceId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	_count?:ResolverInputTypes["WorkspaceRoleCountAggregate"],
	_min?:ResolverInputTypes["WorkspaceRoleMinAggregate"],
	_max?:ResolverInputTypes["WorkspaceRoleMaxAggregate"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceRoleOrderByWithAggregationInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	permissions?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	_count?: ResolverInputTypes["WorkspaceRoleCountOrderByAggregateInput"] | undefined | null,
	_max?: ResolverInputTypes["WorkspaceRoleMaxOrderByAggregateInput"] | undefined | null,
	_min?: ResolverInputTypes["WorkspaceRoleMinOrderByAggregateInput"] | undefined | null
};
	["WorkspaceRoleCountOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	permissions?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceRoleMaxOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceRoleMinOrderByAggregateInput"]: {
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	workspaceId?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WorkspaceRoleScalarWhereWithAggregatesInput"]: {
	AND?: Array<ResolverInputTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined | null,
	id?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	name?: ResolverInputTypes["StringWithAggregatesFilter"] | undefined | null,
	permissions?: ResolverInputTypes["EnumPermissionNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableWithAggregatesFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeWithAggregatesFilter"] | undefined | null
};
	["Slot"]: AliasType<{
	date?:boolean | `@${string}`,
	slots?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createManyAdBoard?: [{	data: Array<ResolverInputTypes["AdBoardCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneAdBoard?: [{	data: ResolverInputTypes["AdBoardCreateInput"]},ResolverInputTypes["AdBoard"]],
deleteManyAdBoard?: [{	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneAdBoard?: [{	where: ResolverInputTypes["AdBoardWhereUniqueInput"]},ResolverInputTypes["AdBoard"]],
updateManyAdBoard?: [{	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"],	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneAdBoard?: [{	data: ResolverInputTypes["AdBoardUpdateInput"],	where: ResolverInputTypes["AdBoardWhereUniqueInput"]},ResolverInputTypes["AdBoard"]],
upsertOneAdBoard?: [{	where: ResolverInputTypes["AdBoardWhereUniqueInput"],	create: ResolverInputTypes["AdBoardCreateInput"],	update: ResolverInputTypes["AdBoardUpdateInput"]},ResolverInputTypes["AdBoard"]],
createManyAdBoardGroup?: [{	data: Array<ResolverInputTypes["AdBoardGroupCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneAdBoardGroup?: [{	data: ResolverInputTypes["AdBoardGroupCreateInput"]},ResolverInputTypes["AdBoardGroup"]],
deleteManyAdBoardGroup?: [{	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneAdBoardGroup?: [{	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"]},ResolverInputTypes["AdBoardGroup"]],
updateManyAdBoardGroup?: [{	data: ResolverInputTypes["AdBoardGroupUpdateManyMutationInput"],	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneAdBoardGroup?: [{	data: ResolverInputTypes["AdBoardGroupUpdateInput"],	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"]},ResolverInputTypes["AdBoardGroup"]],
upsertOneAdBoardGroup?: [{	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],	create: ResolverInputTypes["AdBoardGroupCreateInput"],	update: ResolverInputTypes["AdBoardGroupUpdateInput"]},ResolverInputTypes["AdBoardGroup"]],
createManyAdBoardType?: [{	data: Array<ResolverInputTypes["AdBoardTypeCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneAdBoardType?: [{	data: ResolverInputTypes["AdBoardTypeCreateInput"]},ResolverInputTypes["AdBoardType"]],
deleteManyAdBoardType?: [{	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneAdBoardType?: [{	where: ResolverInputTypes["AdBoardTypeWhereUniqueInput"]},ResolverInputTypes["AdBoardType"]],
updateManyAdBoardType?: [{	data: ResolverInputTypes["AdBoardTypeUpdateManyMutationInput"],	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneAdBoardType?: [{	data: ResolverInputTypes["AdBoardTypeUpdateInput"],	where: ResolverInputTypes["AdBoardTypeWhereUniqueInput"]},ResolverInputTypes["AdBoardType"]],
upsertOneAdBoardType?: [{	where: ResolverInputTypes["AdBoardTypeWhereUniqueInput"],	create: ResolverInputTypes["AdBoardTypeCreateInput"],	update: ResolverInputTypes["AdBoardTypeUpdateInput"]},ResolverInputTypes["AdBoardType"]],
createManyAdvertisementCategory?: [{	data: Array<ResolverInputTypes["AdvertisementCategoryCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneAdvertisementCategory?: [{	data: ResolverInputTypes["AdvertisementCategoryCreateInput"]},ResolverInputTypes["AdvertisementCategory"]],
deleteManyAdvertisementCategory?: [{	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneAdvertisementCategory?: [{	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]},ResolverInputTypes["AdvertisementCategory"]],
updateManyAdvertisementCategory?: [{	data: ResolverInputTypes["AdvertisementCategoryUpdateManyMutationInput"],	where?: ResolverInputTypes["AdvertisementCategoryWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneAdvertisementCategory?: [{	data: ResolverInputTypes["AdvertisementCategoryUpdateInput"],	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]},ResolverInputTypes["AdvertisementCategory"]],
upsertOneAdvertisementCategory?: [{	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"],	create: ResolverInputTypes["AdvertisementCategoryCreateInput"],	update: ResolverInputTypes["AdvertisementCategoryUpdateInput"]},ResolverInputTypes["AdvertisementCategory"]],
createManyAudit?: [{	data: Array<ResolverInputTypes["AuditCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneAudit?: [{	data: ResolverInputTypes["AuditCreateInput"]},ResolverInputTypes["Audit"]],
deleteManyAudit?: [{	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneAudit?: [{	where: ResolverInputTypes["AuditWhereUniqueInput"]},ResolverInputTypes["Audit"]],
updateManyAudit?: [{	data: ResolverInputTypes["AuditUpdateManyMutationInput"],	where?: ResolverInputTypes["AuditWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneAudit?: [{	data: ResolverInputTypes["AuditUpdateInput"],	where: ResolverInputTypes["AuditWhereUniqueInput"]},ResolverInputTypes["Audit"]],
upsertOneAudit?: [{	where: ResolverInputTypes["AuditWhereUniqueInput"],	create: ResolverInputTypes["AuditCreateInput"],	update: ResolverInputTypes["AuditUpdateInput"]},ResolverInputTypes["Audit"]],
createManyBooking?: [{	data: Array<ResolverInputTypes["BookingCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneBooking?: [{	data: ResolverInputTypes["BookingCreateInput"]},ResolverInputTypes["Booking"]],
deleteManyBooking?: [{	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneBooking?: [{	where: ResolverInputTypes["BookingWhereUniqueInput"]},ResolverInputTypes["Booking"]],
updateManyBooking?: [{	data: ResolverInputTypes["BookingUpdateManyMutationInput"],	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneBooking?: [{	data: ResolverInputTypes["BookingUpdateInput"],	where: ResolverInputTypes["BookingWhereUniqueInput"]},ResolverInputTypes["Booking"]],
upsertOneBooking?: [{	where: ResolverInputTypes["BookingWhereUniqueInput"],	create: ResolverInputTypes["BookingCreateInput"],	update: ResolverInputTypes["BookingUpdateInput"]},ResolverInputTypes["Booking"]],
createManyBusinessCategory?: [{	data: Array<ResolverInputTypes["BusinessCategoryCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneBusinessCategory?: [{	data: ResolverInputTypes["BusinessCategoryCreateInput"]},ResolverInputTypes["BusinessCategory"]],
deleteManyBusinessCategory?: [{	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneBusinessCategory?: [{	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"]},ResolverInputTypes["BusinessCategory"]],
updateManyBusinessCategory?: [{	data: ResolverInputTypes["BusinessCategoryUpdateManyMutationInput"],	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneBusinessCategory?: [{	data: ResolverInputTypes["BusinessCategoryUpdateInput"],	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"]},ResolverInputTypes["BusinessCategory"]],
upsertOneBusinessCategory?: [{	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"],	create: ResolverInputTypes["BusinessCategoryCreateInput"],	update: ResolverInputTypes["BusinessCategoryUpdateInput"]},ResolverInputTypes["BusinessCategory"]],
createManyCamera?: [{	data: Array<ResolverInputTypes["CameraCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneCamera?: [{	data: ResolverInputTypes["CameraCreateInput"]},ResolverInputTypes["Camera"]],
deleteManyCamera?: [{	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneCamera?: [{	where: ResolverInputTypes["CameraWhereUniqueInput"]},ResolverInputTypes["Camera"]],
updateManyCamera?: [{	data: ResolverInputTypes["CameraUpdateManyMutationInput"],	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneCamera?: [{	data: ResolverInputTypes["CameraUpdateInput"],	where: ResolverInputTypes["CameraWhereUniqueInput"]},ResolverInputTypes["Camera"]],
upsertOneCamera?: [{	where: ResolverInputTypes["CameraWhereUniqueInput"],	create: ResolverInputTypes["CameraCreateInput"],	update: ResolverInputTypes["CameraUpdateInput"]},ResolverInputTypes["Camera"]],
createManyCameraFeed?: [{	data: Array<ResolverInputTypes["CameraFeedCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneCameraFeed?: [{	data: ResolverInputTypes["CameraFeedCreateInput"]},ResolverInputTypes["CameraFeed"]],
deleteManyCameraFeed?: [{	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneCameraFeed?: [{	where: ResolverInputTypes["CameraFeedWhereUniqueInput"]},ResolverInputTypes["CameraFeed"]],
updateManyCameraFeed?: [{	data: ResolverInputTypes["CameraFeedUpdateManyMutationInput"],	where?: ResolverInputTypes["CameraFeedWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneCameraFeed?: [{	data: ResolverInputTypes["CameraFeedUpdateInput"],	where: ResolverInputTypes["CameraFeedWhereUniqueInput"]},ResolverInputTypes["CameraFeed"]],
upsertOneCameraFeed?: [{	where: ResolverInputTypes["CameraFeedWhereUniqueInput"],	create: ResolverInputTypes["CameraFeedCreateInput"],	update: ResolverInputTypes["CameraFeedUpdateInput"]},ResolverInputTypes["CameraFeed"]],
createManyCameraServer?: [{	data: Array<ResolverInputTypes["CameraServerCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneCameraServer?: [{	data: ResolverInputTypes["CameraServerCreateInput"]},ResolverInputTypes["CameraServer"]],
deleteManyCameraServer?: [{	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneCameraServer?: [{	where: ResolverInputTypes["CameraServerWhereUniqueInput"]},ResolverInputTypes["CameraServer"]],
updateManyCameraServer?: [{	data: ResolverInputTypes["CameraServerUpdateManyMutationInput"],	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneCameraServer?: [{	data: ResolverInputTypes["CameraServerUpdateInput"],	where: ResolverInputTypes["CameraServerWhereUniqueInput"]},ResolverInputTypes["CameraServer"]],
upsertOneCameraServer?: [{	where: ResolverInputTypes["CameraServerWhereUniqueInput"],	create: ResolverInputTypes["CameraServerCreateInput"],	update: ResolverInputTypes["CameraServerUpdateInput"]},ResolverInputTypes["CameraServer"]],
createManyCampaign?: [{	data: Array<ResolverInputTypes["CampaignCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneCampaign?: [{	data: ResolverInputTypes["CampaignCreateInput"]},ResolverInputTypes["Campaign"]],
deleteManyCampaign?: [{	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneCampaign?: [{	where: ResolverInputTypes["CampaignWhereUniqueInput"]},ResolverInputTypes["Campaign"]],
updateManyCampaign?: [{	data: ResolverInputTypes["CampaignUpdateManyMutationInput"],	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneCampaign?: [{	data: ResolverInputTypes["CampaignUpdateInput"],	where: ResolverInputTypes["CampaignWhereUniqueInput"]},ResolverInputTypes["Campaign"]],
upsertOneCampaign?: [{	where: ResolverInputTypes["CampaignWhereUniqueInput"],	create: ResolverInputTypes["CampaignCreateInput"],	update: ResolverInputTypes["CampaignUpdateInput"]},ResolverInputTypes["Campaign"]],
createManyFile?: [{	data: Array<ResolverInputTypes["FileCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneFile?: [{	data: ResolverInputTypes["FileCreateInput"]},ResolverInputTypes["File"]],
deleteManyFile?: [{	where?: ResolverInputTypes["FileWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneFile?: [{	where: ResolverInputTypes["FileWhereUniqueInput"]},ResolverInputTypes["File"]],
updateManyFile?: [{	data: ResolverInputTypes["FileUpdateManyMutationInput"],	where?: ResolverInputTypes["FileWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneFile?: [{	data: ResolverInputTypes["FileUpdateInput"],	where: ResolverInputTypes["FileWhereUniqueInput"]},ResolverInputTypes["File"]],
upsertOneFile?: [{	where: ResolverInputTypes["FileWhereUniqueInput"],	create: ResolverInputTypes["FileCreateInput"],	update: ResolverInputTypes["FileUpdateInput"]},ResolverInputTypes["File"]],
createManyMembership?: [{	data: Array<ResolverInputTypes["MembershipCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneMembership?: [{	data: ResolverInputTypes["MembershipCreateInput"]},ResolverInputTypes["Membership"]],
deleteManyMembership?: [{	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneMembership?: [{	where: ResolverInputTypes["MembershipWhereUniqueInput"]},ResolverInputTypes["Membership"]],
updateManyMembership?: [{	data: ResolverInputTypes["MembershipUpdateManyMutationInput"],	where?: ResolverInputTypes["MembershipWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneMembership?: [{	data: ResolverInputTypes["MembershipUpdateInput"],	where: ResolverInputTypes["MembershipWhereUniqueInput"]},ResolverInputTypes["Membership"]],
upsertOneMembership?: [{	where: ResolverInputTypes["MembershipWhereUniqueInput"],	create: ResolverInputTypes["MembershipCreateInput"],	update: ResolverInputTypes["MembershipUpdateInput"]},ResolverInputTypes["Membership"]],
createManyNotification?: [{	data: Array<ResolverInputTypes["NotificationCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneNotification?: [{	data: ResolverInputTypes["NotificationCreateInput"]},ResolverInputTypes["Notification"]],
deleteManyNotification?: [{	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneNotification?: [{	where: ResolverInputTypes["NotificationWhereUniqueInput"]},ResolverInputTypes["Notification"]],
updateManyNotification?: [{	data: ResolverInputTypes["NotificationUpdateManyMutationInput"],	where?: ResolverInputTypes["NotificationWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneNotification?: [{	data: ResolverInputTypes["NotificationUpdateInput"],	where: ResolverInputTypes["NotificationWhereUniqueInput"]},ResolverInputTypes["Notification"]],
upsertOneNotification?: [{	where: ResolverInputTypes["NotificationWhereUniqueInput"],	create: ResolverInputTypes["NotificationCreateInput"],	update: ResolverInputTypes["NotificationUpdateInput"]},ResolverInputTypes["Notification"]],
createManyOrder?: [{	data: Array<ResolverInputTypes["OrderCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneOrder?: [{	data: ResolverInputTypes["OrderCreateInput"]},ResolverInputTypes["Order"]],
deleteManyOrder?: [{	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneOrder?: [{	where: ResolverInputTypes["OrderWhereUniqueInput"]},ResolverInputTypes["Order"]],
updateManyOrder?: [{	data: ResolverInputTypes["OrderUpdateManyMutationInput"],	where?: ResolverInputTypes["OrderWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneOrder?: [{	data: ResolverInputTypes["OrderUpdateInput"],	where: ResolverInputTypes["OrderWhereUniqueInput"]},ResolverInputTypes["Order"]],
upsertOneOrder?: [{	where: ResolverInputTypes["OrderWhereUniqueInput"],	create: ResolverInputTypes["OrderCreateInput"],	update: ResolverInputTypes["OrderUpdateInput"]},ResolverInputTypes["Order"]],
createManyPlayer?: [{	data: Array<ResolverInputTypes["PlayerCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOnePlayer?: [{	data: ResolverInputTypes["PlayerCreateInput"]},ResolverInputTypes["Player"]],
deleteManyPlayer?: [{	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOnePlayer?: [{	where: ResolverInputTypes["PlayerWhereUniqueInput"]},ResolverInputTypes["Player"]],
updateManyPlayer?: [{	data: ResolverInputTypes["PlayerUpdateManyMutationInput"],	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOnePlayer?: [{	data: ResolverInputTypes["PlayerUpdateInput"],	where: ResolverInputTypes["PlayerWhereUniqueInput"]},ResolverInputTypes["Player"]],
upsertOnePlayer?: [{	where: ResolverInputTypes["PlayerWhereUniqueInput"],	create: ResolverInputTypes["PlayerCreateInput"],	update: ResolverInputTypes["PlayerUpdateInput"]},ResolverInputTypes["Player"]],
createManyPlaylist?: [{	data: Array<ResolverInputTypes["PlaylistCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOnePlaylist?: [{	data: ResolverInputTypes["PlaylistCreateInput"]},ResolverInputTypes["Playlist"]],
deleteManyPlaylist?: [{	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOnePlaylist?: [{	where: ResolverInputTypes["PlaylistWhereUniqueInput"]},ResolverInputTypes["Playlist"]],
updateManyPlaylist?: [{	data: ResolverInputTypes["PlaylistUpdateManyMutationInput"],	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOnePlaylist?: [{	data: ResolverInputTypes["PlaylistUpdateInput"],	where: ResolverInputTypes["PlaylistWhereUniqueInput"]},ResolverInputTypes["Playlist"]],
upsertOnePlaylist?: [{	where: ResolverInputTypes["PlaylistWhereUniqueInput"],	create: ResolverInputTypes["PlaylistCreateInput"],	update: ResolverInputTypes["PlaylistUpdateInput"]},ResolverInputTypes["Playlist"]],
createManySlotConfiguration?: [{	data: Array<ResolverInputTypes["SlotConfigurationCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneSlotConfiguration?: [{	data: ResolverInputTypes["SlotConfigurationCreateInput"]},ResolverInputTypes["SlotConfiguration"]],
deleteManySlotConfiguration?: [{	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneSlotConfiguration?: [{	where: ResolverInputTypes["SlotConfigurationWhereUniqueInput"]},ResolverInputTypes["SlotConfiguration"]],
updateManySlotConfiguration?: [{	data: ResolverInputTypes["SlotConfigurationUpdateManyMutationInput"],	where?: ResolverInputTypes["SlotConfigurationWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneSlotConfiguration?: [{	data: ResolverInputTypes["SlotConfigurationUpdateInput"],	where: ResolverInputTypes["SlotConfigurationWhereUniqueInput"]},ResolverInputTypes["SlotConfiguration"]],
upsertOneSlotConfiguration?: [{	where: ResolverInputTypes["SlotConfigurationWhereUniqueInput"],	create: ResolverInputTypes["SlotConfigurationCreateInput"],	update: ResolverInputTypes["SlotConfigurationUpdateInput"]},ResolverInputTypes["SlotConfiguration"]],
createManySubscription?: [{	data: Array<ResolverInputTypes["SubscriptionCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneSubscription?: [{	data: ResolverInputTypes["SubscriptionCreateInput"]},ResolverInputTypes["Subscription"]],
deleteManySubscription?: [{	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneSubscription?: [{	where: ResolverInputTypes["SubscriptionWhereUniqueInput"]},ResolverInputTypes["Subscription"]],
updateManySubscription?: [{	data: ResolverInputTypes["SubscriptionUpdateManyMutationInput"],	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneSubscription?: [{	data: ResolverInputTypes["SubscriptionUpdateInput"],	where: ResolverInputTypes["SubscriptionWhereUniqueInput"]},ResolverInputTypes["Subscription"]],
upsertOneSubscription?: [{	where: ResolverInputTypes["SubscriptionWhereUniqueInput"],	create: ResolverInputTypes["SubscriptionCreateInput"],	update: ResolverInputTypes["SubscriptionUpdateInput"]},ResolverInputTypes["Subscription"]],
createManyUser?: [{	data: Array<ResolverInputTypes["UserCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneUser?: [{	data: ResolverInputTypes["UserCreateInput"]},ResolverInputTypes["User"]],
deleteManyUser?: [{	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneUser?: [{	where: ResolverInputTypes["UserWhereUniqueInput"]},ResolverInputTypes["User"]],
updateManyUser?: [{	data: ResolverInputTypes["UserUpdateManyMutationInput"],	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneUser?: [{	data: ResolverInputTypes["UserUpdateInput"],	where: ResolverInputTypes["UserWhereUniqueInput"]},ResolverInputTypes["User"]],
upsertOneUser?: [{	where: ResolverInputTypes["UserWhereUniqueInput"],	create: ResolverInputTypes["UserCreateInput"],	update: ResolverInputTypes["UserUpdateInput"]},ResolverInputTypes["User"]],
createManyUserPosition?: [{	data: Array<ResolverInputTypes["UserPositionCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneUserPosition?: [{	data: ResolverInputTypes["UserPositionCreateInput"]},ResolverInputTypes["UserPosition"]],
deleteManyUserPosition?: [{	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneUserPosition?: [{	where: ResolverInputTypes["UserPositionWhereUniqueInput"]},ResolverInputTypes["UserPosition"]],
updateManyUserPosition?: [{	data: ResolverInputTypes["UserPositionUpdateManyMutationInput"],	where?: ResolverInputTypes["UserPositionWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneUserPosition?: [{	data: ResolverInputTypes["UserPositionUpdateInput"],	where: ResolverInputTypes["UserPositionWhereUniqueInput"]},ResolverInputTypes["UserPosition"]],
upsertOneUserPosition?: [{	where: ResolverInputTypes["UserPositionWhereUniqueInput"],	create: ResolverInputTypes["UserPositionCreateInput"],	update: ResolverInputTypes["UserPositionUpdateInput"]},ResolverInputTypes["UserPosition"]],
createManyWorkspace?: [{	data: Array<ResolverInputTypes["WorkspaceCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneWorkspace?: [{	data: ResolverInputTypes["WorkspaceCreateInput"]},ResolverInputTypes["Workspace"]],
deleteManyWorkspace?: [{	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneWorkspace?: [{	where: ResolverInputTypes["WorkspaceWhereUniqueInput"]},ResolverInputTypes["Workspace"]],
updateManyWorkspace?: [{	data: ResolverInputTypes["WorkspaceUpdateManyMutationInput"],	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneWorkspace?: [{	data: ResolverInputTypes["WorkspaceUpdateInput"],	where: ResolverInputTypes["WorkspaceWhereUniqueInput"]},ResolverInputTypes["Workspace"]],
upsertOneWorkspace?: [{	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],	create: ResolverInputTypes["WorkspaceCreateInput"],	update: ResolverInputTypes["WorkspaceUpdateInput"]},ResolverInputTypes["Workspace"]],
createManyWorkspaceRole?: [{	data: Array<ResolverInputTypes["WorkspaceRoleCreateManyInput"]>},ResolverInputTypes["AffectedRowsOutput"]],
createOneWorkspaceRole?: [{	data: ResolverInputTypes["WorkspaceRoleCreateInput"]},ResolverInputTypes["WorkspaceRole"]],
deleteManyWorkspaceRole?: [{	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
deleteOneWorkspaceRole?: [{	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]},ResolverInputTypes["WorkspaceRole"]],
updateManyWorkspaceRole?: [{	data: ResolverInputTypes["WorkspaceRoleUpdateManyMutationInput"],	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null},ResolverInputTypes["AffectedRowsOutput"]],
updateOneWorkspaceRole?: [{	data: ResolverInputTypes["WorkspaceRoleUpdateInput"],	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]},ResolverInputTypes["WorkspaceRole"]],
upsertOneWorkspaceRole?: [{	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"],	create: ResolverInputTypes["WorkspaceRoleCreateInput"],	update: ResolverInputTypes["WorkspaceRoleUpdateInput"]},ResolverInputTypes["WorkspaceRole"]],
updateUser?: [{	accountDetails: ResolverInputTypes["AccountDetails"]},ResolverInputTypes["User"]],
createCustomUser?: [{	user: ResolverInputTypes["UserInput"]},ResolverInputTypes["User"]],
deleteUserCustom?: [{	email: string},ResolverInputTypes["GenericResponse"]],
sendMail?: [{	to: Array<string>,	templateId: ResolverInputTypes["TemplateIds"],	bookingId: string},ResolverInputTypes["EmailOutput"]],
createOneCampaignBooking?: [{	data: ResolverInputTypes["CampaignCreateInput"]},ResolverInputTypes["Campaign"]],
createOneAdboardPublicReview?: [{	data: ResolverInputTypes["AdBoardPublicReviewCreateInput"]},ResolverInputTypes["AdBoardPublicReview"]],
refreshSubscription?: [{	workspaceId: string},ResolverInputTypes["TypedGenericResponse"]],
cancelSubscription?: [{	subscriptionId: string},ResolverInputTypes["TypedGenericResponse"]],
updateBookingStatus?: [{	comment?: string | undefined | null,	status: ResolverInputTypes["BookingStatus"],	where: ResolverInputTypes["BookingWhereUniqueInput"]},ResolverInputTypes["Booking"]],
updateBookingAsset?: [{	where: ResolverInputTypes["BookingWhereUniqueInput"],	assetId: string},boolean | `@${string}`],
prepareBooking?: [{	bookingRequest: ResolverInputTypes["BookingRequest"]},ResolverInputTypes["Booking"]],
cancelBooking?: [{	where: ResolverInputTypes["BookingWhereUniqueInput"]},ResolverInputTypes["Booking"]],
deployBooking?: [{	where: ResolverInputTypes["BookingWhereUniqueInput"]},ResolverInputTypes["Booking"]],
updateDraft?: [{	data: ResolverInputTypes["AdBoardUpdateInput"],	adBoardId: string},ResolverInputTypes["AdBoard"]],
deleteDraft?: [{	draftId: string},boolean | `@${string}`],
addReviewComment?: [{	action: ResolverInputTypes["ReviewAction"],	comment: string,	adBoardId: string},ResolverInputTypes["AdBoardReviewComment"]],
updateAdBoardStatus?: [{	status: ResolverInputTypes["AdBoardStatus"],	id: string},ResolverInputTypes["AdBoard"]],
publishApprovedDraft?: [{	comment: string,	draftId: string},ResolverInputTypes["AdBoard"]],
addPlayersToAdboard?: [{	adboardId: string,	playerIds: Array<string>},ResolverInputTypes["AdBoard"]],
createOneAdboardWithGroup?: [{	data: ResolverInputTypes["AdBoardCreateInput"]},ResolverInputTypes["AdBoard"]],
deleteOneAdboardWithGroup?: [{	adBoardId: string},ResolverInputTypes["AdBoard"]],
updateOneScheduler?: [{	data: ResolverInputTypes["AdBoardGroupUpdateInput"],	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"]},ResolverInputTypes["AdBoardGroup"]],
setupOnePlayer?: [{	playerId: string},ResolverInputTypes["Player"]],
updatePlayerCode?: [{	cpuSerialNumber: string,	playerId: string},ResolverInputTypes["Player"]],
addPlaylistToPlayer?: [{	playlistId: string,	playerId: string},ResolverInputTypes["Player"]],
deletePlaylistInPlayer?: [{	index: number,	playerId: string},ResolverInputTypes["Player"]],
		__typename?: boolean | `@${string}`
}>;
	["AffectedRowsOutput"]: AliasType<{
	count?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdBoardCreateManyInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationCategoryId?: string | undefined | null,
	locationSubCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	adBoardTypeId?: string | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	categoryId?: string | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	offlineBookingContactId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	href?: string | undefined | null,
	workspaceId: string,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	archivedByUserId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["MapCreateInput"]: {
	lat: number,
	lng: number,
	address?: string | undefined | null,
	city?: string | undefined | null,
	state?: string | undefined | null,
	country?: string | undefined | null
};
	["SpecificationCreateInput"]: {
	name: string,
	value?: string | undefined | null
};
	["ResolutionCreateInput"]: {
	height: number,
	width: number
};
	["ScreenSizeCreateInput"]: {
	height: number,
	unit: ResolverInputTypes["MeasurementUnit"],
	width: number
};
	["PlaybackTimeCreateInput"]: {
	name?: string | undefined | null,
	startTime: string,
	endTime: string
};
	["WebhookCreateInput"]: {
	url: string,
	headers?: ResolverInputTypes["JSON"] | undefined | null,
	body?: ResolverInputTypes["JSON"] | undefined | null,
	method: string
};
	["AdBoardCreateInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["LocationCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ResolverInputTypes["LocationCategoryCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	connect?: ResolverInputTypes["LocationCategoryWhereUniqueInput"] | undefined | null
};
	["LocationCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null,
	name: string,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"] | undefined | null
};
	["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"]: {
	create?: Array<ResolverInputTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null
};
	["LocationSubCategoryCreateWithoutLocationCategoryInput"]: {
	id?: string | undefined | null,
	name: string,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutLocationSubCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutLocationSubCategoryInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ResolverInputTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardTypeWhereUniqueInput"] | undefined | null
};
	["AdBoardTypeCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null
};
	["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["AdBoardTypeWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardTypeCreateWithoutAdBoardsInput"]
};
	["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ResolverInputTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	connect?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null
};
	["BusinessCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutRestrictedBusinesInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutRestrictedBusinesInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ResolverInputTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	connect?: ResolverInputTypes["LocationSubCategoryWhereUniqueInput"] | undefined | null
};
	["LocationSubCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null,
	name: string,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"] | undefined | null
};
	["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"]: {
	create?: ResolverInputTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"] | undefined | null,
	connect?: ResolverInputTypes["LocationCategoryWhereUniqueInput"] | undefined | null
};
	["LocationCategoryCreateWithoutLocationSubCategoryInput"]: {
	id?: string | undefined | null,
	name: string,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutLocationCategoryInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutLocationCategoryInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutLocationCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyLocationCategoryInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutLocationCategoryInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"]: {
	create?: Array<ResolverInputTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null
};
	["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutCategoryInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyCategoryInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutCategoryInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["BookingCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyAdBoardInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null
};
	["BookingCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["BookingDetailsCreateInput"]: {
	additionalInformation?: string | undefined | null,
	email: string,
	name: string,
	phone?: string | undefined | null
};
	["OrderSelectedDateCreateInput"]: {
	endDate: ResolverInputTypes["DateTime"],
	startDate: ResolverInputTypes["DateTime"]
};
	["BookingPaymentCreateInput"]: {
	liveMode?: boolean | undefined | null,
	checkoutSessionId?: string | undefined | null,
	customer?: ResolverInputTypes["BookingCustomerCreateInput"] | undefined | null,
	paymentGateway: ResolverInputTypes["PaymentGatewayName"],
	paymentId: string,
	sessionId?: string | undefined | null,
	totalDetails?: ResolverInputTypes["PaymentTotalDetailsCreateInput"] | undefined | null,
	razorpay?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["JSON"] | undefined | null
};
	["BookingCustomerCreateInput"]: {
	customerId: string,
	customerDetails?: ResolverInputTypes["PaymentCustomerDetailsCreateInput"] | undefined | null
};
	["PaymentCustomerDetailsCreateInput"]: {
	email: string,
	name: string,
	phone?: string | undefined | null
};
	["PaymentTotalDetailsCreateInput"]: {
	discount: number,
	shipping: number,
	subtotal: number,
	tax: number,
	total: number
};
	["PriceSummaryCreateInput"]: {
	days: number,
	discount?: number | undefined | null,
	payableRent: number,
	rentPerDay: number,
	serviceCharge: number,
	totalRent: number
};
	["BookingPisignageDataCreateInput"]: {
	deployedFileName?: string | undefined | null,
	playlistId?: string | undefined | null
};
	["BookingRefundCreateInput"]: {
	id: string,
	razorpay?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["JSON"] | undefined | null,
	createdByUserId: string
};
	["FileCreateNestedManyWithoutBookingsInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutBookingsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutBookingsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null
};
	["FileCreateWithoutBookingsInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url: string,
	updatedBy?: ResolverInputTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null,
	createdBy: ResolverInputTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null
};
	["FileMetaCreateInput"]: {
	duration: number,
	height: number,
	width: number
};
	["UserCreateNestedOneWithoutUpdatedFilesInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutUpdatedFilesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutUpdatedFilesInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutUpdatedFilesInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["UserStripeCreateInput"]: {
	customerId: string
};
	["NotificationPreferenceCreateInput"]: {
	type?: Array<ResolverInputTypes["NotificationType"]> | undefined | null,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null,
	pushSetting?: ResolverInputTypes["PushSettingCreateInput"] | undefined | null,
	enabled?: boolean | undefined | null
};
	["PushSettingCreateInput"]: {
	fcmToken: string
};
	["BookingCreateNestedManyWithoutUserInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyUserInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null
};
	["BookingCreateWithoutUserInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["BusinessCategoryCreateNestedOneWithoutBookingsInput"]: {
	create?: ResolverInputTypes["BusinessCategoryCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"] | undefined | null,
	connect?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null
};
	["BusinessCategoryCreateWithoutBookingsInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null
};
	["CampaignCreateNestedManyWithoutBusinessCategoryInput"]: {
	create?: Array<ResolverInputTypes["CampaignCreateWithoutBusinessCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CampaignCreateManyBusinessCategoryInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null
};
	["CampaignCreateWithoutBusinessCategoryInput"]: {
	id?: string | undefined | null,
	name: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ResolverInputTypes["UserCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null
};
	["UserCreateNestedOneWithoutCampaignsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutCampaignsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutCampaignsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutCampaignsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["FileCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutCreatedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutCreatedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["FileCreateManyCreatedByInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null
};
	["FileCreateWithoutCreatedByInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url: string,
	updatedBy?: ResolverInputTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null
};
	["BookingCreateNestedManyWithoutAssetsInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutAssetsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutAssetsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null
};
	["BookingCreateWithoutAssetsInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"]: {
	create?: Array<ResolverInputTypes["AdvertisementCategoryCreateWithoutBookingsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null
};
	["AdvertisementCategoryCreateWithoutBookingsInput"]: {
	id?: string | undefined | null,
	name: string,
	type: ResolverInputTypes["AdvertisementCategoryEnum"],
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutAdCategoryInput"] | undefined | null
};
	["CampaignCreateNestedManyWithoutAdCategoryInput"]: {
	create?: Array<ResolverInputTypes["CampaignCreateWithoutAdCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CampaignCreateOrConnectWithoutAdCategoryInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null
};
	["CampaignCreateWithoutAdCategoryInput"]: {
	id?: string | undefined | null,
	name: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ResolverInputTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null
};
	["BusinessCategoryCreateNestedOneWithoutCampaignsInput"]: {
	create?: ResolverInputTypes["BusinessCategoryCreateWithoutCampaignsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"] | undefined | null,
	connect?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null
};
	["BusinessCategoryCreateWithoutCampaignsInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null
};
	["BookingCreateNestedManyWithoutBusinessCategoryInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutBusinessCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyBusinessCategoryInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null
};
	["BookingCreateWithoutBusinessCategoryInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["FileCreateNestedManyWithoutProofBookingInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutProofBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutProofBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["FileCreateManyProofBookingInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null
};
	["FileCreateWithoutProofBookingInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url: string,
	updatedBy?: ResolverInputTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null,
	createdBy: ResolverInputTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null
};
	["UserCreateNestedOneWithoutCreatedFilesInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutCreatedFilesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutCreatedFilesInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutCreatedFilesInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["FileCreateNestedManyWithoutUpdatedByInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutUpdatedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutUpdatedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["FileCreateManyUpdatedByInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null
};
	["FileCreateWithoutUpdatedByInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url: string,
	createdBy: ResolverInputTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutFilesInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutFilesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutFilesInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutFilesInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["UserCreateNestedOneWithoutOwnedWorkspacesInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutOwnedWorkspacesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutOwnedWorkspacesInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["OrderCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ResolverInputTypes["OrderCreateWithoutCreatedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["OrderCreateOrConnectWithoutCreatedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["OrderCreateManyCreatedByInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null
};
	["OrderCreateWithoutCreatedByInput"]: {
	id?: string | undefined | null,
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	origin: string,
	paymentId?: string | undefined | null,
	priceSummary: ResolverInputTypes["PriceSummaryCreateInput"],
	selectedDate: ResolverInputTypes["OrderSelectedDateCreateInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutOrdersInput"]
};
	["AdBoardCreateNestedOneWithoutOrdersInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutOrdersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutOrdersInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutOrdersInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["SlotConfigurationCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ResolverInputTypes["SlotConfigurationCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null
};
	["SlotConfigurationCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null,
	date: ResolverInputTypes["DateTime"],
	availableSlots: number
};
	["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]: {
	where: ResolverInputTypes["SlotConfigurationWhereUniqueInput"],
	create: ResolverInputTypes["SlotConfigurationCreateWithoutAdBoardInput"]
};
	["SlotConfigurationCreateManyAdBoardInputEnvelope"]: {
	data: Array<ResolverInputTypes["SlotConfigurationCreateManyAdBoardInput"]>
};
	["SlotConfigurationCreateManyAdBoardInput"]: {
	id?: string | undefined | null,
	date: ResolverInputTypes["DateTime"],
	availableSlots: number
};
	["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutOfflineBookingAdboardsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["ChangelogCreateNestedManyWithoutUserInput"]: {
	create?: Array<ResolverInputTypes["ChangelogCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["ChangelogCreateOrConnectWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["ChangelogCreateManyUserInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null
};
	["ChangelogCreateWithoutUserInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	booking?: ResolverInputTypes["BookingCreateNestedOneWithoutChangelogsInput"] | undefined | null
};
	["BookingCreateNestedOneWithoutChangelogsInput"]: {
	create?: ResolverInputTypes["BookingCreateWithoutChangelogsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BookingCreateOrConnectWithoutChangelogsInput"] | undefined | null,
	connect?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null
};
	["BookingCreateWithoutChangelogsInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["UserCreateNestedOneWithoutBookingsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutBookingsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutBookingsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutCreatedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutCreatedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyCreatedByInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutCreatedByInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["OrderCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ResolverInputTypes["OrderCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["OrderCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["OrderCreateManyAdBoardInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null
};
	["OrderCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null,
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	origin: string,
	paymentId?: string | undefined | null,
	priceSummary: ResolverInputTypes["PriceSummaryCreateInput"],
	selectedDate: ResolverInputTypes["OrderSelectedDateCreateInput"],
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutOrdersInput"] | undefined | null
};
	["UserCreateNestedOneWithoutOrdersInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutOrdersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutOrdersInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutOrdersInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["AuditCreateNestedManyWithoutUserInput"]: {
	create?: Array<ResolverInputTypes["AuditCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AuditCreateOrConnectWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AuditCreateManyUserInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null
};
	["AuditCreateWithoutUserInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAuditsInput"] | undefined | null
};
	["EntityCreateInput"]: {
	type: string,
	id: string,
	name?: string | undefined | null
};
	["WorkspaceCreateNestedOneWithoutAuditsInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutAuditsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutAuditsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutAuditsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["MembershipCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["MembershipCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["MembershipCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["MembershipCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null
};
	["MembershipCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutMembershipsInput"],
	role: ResolverInputTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["UserCreateNestedOneWithoutMembershipsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutMembershipsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutMembershipsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutMembershipsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["CampaignCreateNestedManyWithoutUserInput"]: {
	create?: Array<ResolverInputTypes["CampaignCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CampaignCreateOrConnectWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CampaignCreateManyUserInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null
};
	["CampaignCreateWithoutUserInput"]: {
	id?: string | undefined | null,
	name: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	businessCategory: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null
};
	["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"]: {
	create?: Array<ResolverInputTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null
};
	["AdvertisementCategoryCreateWithoutCampaignsInput"]: {
	id?: string | undefined | null,
	name: string,
	type: ResolverInputTypes["AdvertisementCategoryEnum"],
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdCategoryInput"] | undefined | null
};
	["BookingCreateNestedManyWithoutAdCategoryInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutAdCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutAdCategoryInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null
};
	["BookingCreateWithoutAdCategoryInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["AdBoardCreateNestedOneWithoutBookingsInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutBookingsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutBookingsInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["UserCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["BookingCreateNestedManyWithoutSharedUsersInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutSharedUsersInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutSharedUsersInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null
};
	["BookingCreateWithoutSharedUsersInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["ChangelogCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ResolverInputTypes["ChangelogCreateWithoutBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["ChangelogCreateOrConnectWithoutBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["ChangelogCreateManyBookingInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null
};
	["ChangelogCreateWithoutBookingInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutChangelogsInput"]
};
	["UserCreateNestedOneWithoutChangelogsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutChangelogsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutChangelogsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutChangelogsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["MembershipCreateNestedManyWithoutUserInput"]: {
	create?: Array<ResolverInputTypes["MembershipCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["MembershipCreateOrConnectWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["MembershipCreateManyUserInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null
};
	["MembershipCreateWithoutUserInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"],
	role: ResolverInputTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["WorkspaceCreateNestedOneWithoutMembershipsInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutMembershipsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutMembershipsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["PlayerCreateNestedManyWithoutAdBoardsInput"]: {
	create?: Array<ResolverInputTypes["PlayerCreateWithoutAdBoardsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlayerCreateOrConnectWithoutAdBoardsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null
};
	["PlayerCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null,
	name: string,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null
};
	["GalleryFileCreateInput"]: {
	name: string,
	url: string,
	contentType: string,
	width: number,
	height: number,
	duration?: number | undefined | null
};
	["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"]: {
	create?: ResolverInputTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null
};
	["AdBoardGroupCreateWithoutPisignagePlayersInput"]: {
	id?: string | undefined | null,
	name: string,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["ScheduleCreateInput"]: {
	playlistId: string,
	playlistType?: string | undefined | null,
	skipForSchedule?: boolean | undefined | null,
	startTime?: string | undefined | null,
	endTime?: string | undefined | null
};
	["AdBoardCreateNestedOneWithoutAdBoardGroupInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutAdBoardGroupInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutAdBoardGroupInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutAdBoardsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["PlaylistCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["PlaylistCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlaylistCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null
};
	["PlaylistCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null
};
	["PisignageFileCreateInput"]: {
	name: string,
	duration?: number | undefined | null,
	muteAudio?: boolean | undefined | null,
	isVideo?: boolean | undefined | null
};
	["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"]: {
	create?: ResolverInputTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"] | undefined | null,
	connect?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null
};
	["PlaylistCreateWithoutFooterParentPlaylistsInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null
};
	["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"]: {
	create?: ResolverInputTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"] | undefined | null,
	connect?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null
};
	["PlaylistCreateWithoutSidebarParentPlaylistsInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null
};
	["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"]: {
	create?: Array<ResolverInputTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null
};
	["AdBoardGroupCreateWithoutAdsPlaylistInput"]: {
	id?: string | undefined | null,
	name: string,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"]: {
	create?: Array<ResolverInputTypes["PlaylistCreateWithoutAdBoardGroupsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null
};
	["PlaylistCreateWithoutAdBoardGroupsInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutPlaylistsInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutPlaylistsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutPlaylistsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["AdBoardGroupCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null
};
	["AdBoardGroupCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	name: string,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null
};
	["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"]: {
	create?: ResolverInputTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	connect?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null
};
	["PlaylistCreateWithoutAdBoardGroupsForAdsInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null
};
	["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"]: {
	create?: Array<ResolverInputTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null
};
	["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]: {
	id?: string | undefined | null,
	name: string,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["PlayerCreateNestedManyWithoutAdBoardGroupInput"]: {
	create?: Array<ResolverInputTypes["PlayerCreateWithoutAdBoardGroupInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlayerCreateManyAdBoardGroupInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null
};
	["PlayerCreateWithoutAdBoardGroupInput"]: {
	id?: string | undefined | null,
	name: string,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutPlayersInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutPlayersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutPlayersInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutPlayersInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["AuditCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["AuditCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AuditCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AuditCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null
};
	["AuditCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutAuditsInput"]
};
	["UserCreateNestedOneWithoutAuditsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutAuditsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutAuditsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutAuditsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["NotificationCreateNestedManyWithoutRecipientInput"]: {
	create?: Array<ResolverInputTypes["NotificationCreateWithoutRecipientInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["NotificationCreateOrConnectWithoutRecipientInput"]> | undefined | null,
	createMany?: ResolverInputTypes["NotificationCreateManyRecipientInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["NotificationWhereUniqueInput"]> | undefined | null
};
	["NotificationCreateWithoutRecipientInput"]: {
	id?: string | undefined | null,
	type: ResolverInputTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ResolverInputTypes["JSON"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	readAt?: ResolverInputTypes["DateTime"] | undefined | null,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null
};
	["NotificationCreateOrConnectWithoutRecipientInput"]: {
	where: ResolverInputTypes["NotificationWhereUniqueInput"],
	create: ResolverInputTypes["NotificationCreateWithoutRecipientInput"]
};
	["NotificationCreateManyRecipientInputEnvelope"]: {
	data: Array<ResolverInputTypes["NotificationCreateManyRecipientInput"]>
};
	["NotificationCreateManyRecipientInput"]: {
	id?: string | undefined | null,
	type: ResolverInputTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ResolverInputTypes["JSON"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	readAt?: ResolverInputTypes["DateTime"] | undefined | null,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null
};
	["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"]: {
	create?: Array<ResolverInputTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null
};
	["AdBoardPublishHistoryCreateWithoutPublishedByInput"]: {
	id?: string | undefined | null,
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	changesSnapshot: ResolverInputTypes["JSON"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutHistoryInput"]
};
	["AdBoardCreateNestedOneWithoutHistoryInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutHistoryInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutHistoryInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutHistoryInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardGroupCreateNestedOneWithoutAdBoardInput"]: {
	create?: ResolverInputTypes["AdBoardGroupCreateWithoutAdBoardInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null
};
	["AdBoardGroupCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null,
	name: string,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutAdBoardGroupsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["BookingCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null
};
	["BookingCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["CampaignCreateNestedOneWithoutBookingsInput"]: {
	create?: ResolverInputTypes["CampaignCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["CampaignCreateOrConnectWithoutBookingsInput"] | undefined | null,
	connect?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null
};
	["CampaignCreateWithoutBookingsInput"]: {
	id?: string | undefined | null,
	name: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ResolverInputTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null
};
	["PaymentCreateNestedManyWithoutCampaignInput"]: {
	create?: Array<ResolverInputTypes["PaymentCreateWithoutCampaignInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PaymentCreateOrConnectWithoutCampaignInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PaymentCreateManyCampaignInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PaymentWhereUniqueInput"]> | undefined | null
};
	["PaymentCreateWithoutCampaignInput"]: {
	id?: string | undefined | null,
	paymentIntentId: string,
	checkoutSessionId: string,
	eventId: string,
	liveMode?: boolean | undefined | null,
	paymentGateway: ResolverInputTypes["PaymentGatewayName"],
	subtotal: number,
	total: number,
	tax: number,
	discount: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["PaymentCreateOrConnectWithoutCampaignInput"]: {
	where: ResolverInputTypes["PaymentWhereUniqueInput"],
	create: ResolverInputTypes["PaymentCreateWithoutCampaignInput"]
};
	["PaymentCreateManyCampaignInputEnvelope"]: {
	data: Array<ResolverInputTypes["PaymentCreateManyCampaignInput"]>
};
	["PaymentCreateManyCampaignInput"]: {
	id?: string | undefined | null,
	paymentIntentId: string,
	checkoutSessionId: string,
	eventId: string,
	liveMode?: boolean | undefined | null,
	paymentGateway: ResolverInputTypes["PaymentGatewayName"],
	subtotal: number,
	total: number,
	tax: number,
	discount: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["FileCreateNestedManyWithoutCampaignsInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutCampaignsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutCampaignsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null
};
	["FileCreateWithoutCampaignsInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url: string,
	updatedBy?: ResolverInputTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null,
	createdBy: ResolverInputTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null
};
	["BookingCreateNestedOneWithoutProofsInput"]: {
	create?: ResolverInputTypes["BookingCreateWithoutProofsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BookingCreateOrConnectWithoutProofsInput"] | undefined | null,
	connect?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null
};
	["BookingCreateWithoutProofsInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutBookingsInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutBookingsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutBookingsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["FileCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["FileCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null
};
	["FileCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url: string,
	updatedBy?: ResolverInputTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null,
	createdBy: ResolverInputTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null
};
	["CampaignCreateNestedManyWithoutAssetsInput"]: {
	create?: Array<ResolverInputTypes["CampaignCreateWithoutAssetsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CampaignCreateOrConnectWithoutAssetsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null
};
	["CampaignCreateWithoutAssetsInput"]: {
	id?: string | undefined | null,
	name: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ResolverInputTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null
};
	["BookingCreateNestedManyWithoutCampaignInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutCampaignInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutCampaignInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyCampaignInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null
};
	["BookingCreateWithoutCampaignInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["PayoutCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ResolverInputTypes["PayoutCreateWithoutBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PayoutCreateOrConnectWithoutBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PayoutCreateManyBookingInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null
};
	["PayoutCreateWithoutBookingInput"]: {
	id?: string | undefined | null,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	status: ResolverInputTypes["PayoutStatus"],
	transaction?: ResolverInputTypes["TransactionCreateNestedOneWithoutPayoutsInput"] | undefined | null
};
	["TransactionCreateNestedOneWithoutPayoutsInput"]: {
	create?: ResolverInputTypes["TransactionCreateWithoutPayoutsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["TransactionCreateOrConnectWithoutPayoutsInput"] | undefined | null,
	connect?: ResolverInputTypes["TransactionWhereUniqueInput"] | undefined | null
};
	["TransactionCreateWithoutPayoutsInput"]: {
	id?: string | undefined | null,
	amount: number,
	createAt?: ResolverInputTypes["DateTime"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	relatedEntityId: string,
	transactionStatus: ResolverInputTypes["TransactionStatus"],
	details?: ResolverInputTypes["JSON"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutTransactionsInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutTransactionsInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutTransactionsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutTransactionsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["PlayerCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["PlayerCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlayerCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlayerCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null
};
	["PlayerCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	name: string,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined | null
};
	["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"]: {
	create?: Array<ResolverInputTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null
};
	["PlayerUptimeStatusCreateWithoutPlayerInput"]: {
	id?: string | undefined | null,
	cpuSerialNumber: string,
	date: string,
	uptime: number
};
	["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]: {
	where: ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"],
	create: ResolverInputTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]
};
	["PlayerUptimeStatusCreateManyPlayerInputEnvelope"]: {
	data: Array<ResolverInputTypes["PlayerUptimeStatusCreateManyPlayerInput"]>
};
	["PlayerUptimeStatusCreateManyPlayerInput"]: {
	id?: string | undefined | null,
	cpuSerialNumber: string,
	date: string,
	uptime: number
};
	["CameraCreateNestedManyWithoutPlayerInput"]: {
	create?: Array<ResolverInputTypes["CameraCreateWithoutPlayerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraCreateOrConnectWithoutPlayerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraCreateManyPlayerInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null
};
	["CameraCreateWithoutPlayerInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	cameraServer: ResolverInputTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	feeds?: ResolverInputTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined | null
};
	["CameraServerCreateNestedOneWithoutCamerasInput"]: {
	create?: ResolverInputTypes["CameraServerCreateWithoutCamerasInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["CameraServerCreateOrConnectWithoutCamerasInput"] | undefined | null,
	connect?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null
};
	["CameraServerCreateWithoutCamerasInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutCameraServersInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutCameraServersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutCameraServersInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["TransactionCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["TransactionCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["TransactionCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["TransactionCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["TransactionWhereUniqueInput"]> | undefined | null
};
	["TransactionCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	amount: number,
	createAt?: ResolverInputTypes["DateTime"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	relatedEntityId: string,
	transactionStatus: ResolverInputTypes["TransactionStatus"],
	details?: ResolverInputTypes["JSON"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutTransactionInput"] | undefined | null
};
	["PayoutCreateNestedManyWithoutTransactionInput"]: {
	create?: Array<ResolverInputTypes["PayoutCreateWithoutTransactionInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PayoutCreateOrConnectWithoutTransactionInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PayoutCreateManyTransactionInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null
};
	["PayoutCreateWithoutTransactionInput"]: {
	id?: string | undefined | null,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	status: ResolverInputTypes["PayoutStatus"],
	booking: ResolverInputTypes["BookingCreateNestedOneWithoutPayoutsInput"]
};
	["BookingCreateNestedOneWithoutPayoutsInput"]: {
	create?: ResolverInputTypes["BookingCreateWithoutPayoutsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BookingCreateOrConnectWithoutPayoutsInput"] | undefined | null,
	connect?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null
};
	["BookingCreateWithoutPayoutsInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["UserCreateNestedManyWithoutSharedBookingsInput"]: {
	create?: Array<ResolverInputTypes["UserCreateWithoutSharedBookingsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["UserCreateOrConnectWithoutSharedBookingsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null
};
	["UserCreateWithoutSharedBookingsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutOfflineBookingsContactInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["CameraCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ResolverInputTypes["CameraCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraCreateManyAdBoardInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null
};
	["CameraCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	cameraServer: ResolverInputTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: ResolverInputTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined | null
};
	["PlayerCreateNestedOneWithoutCamerasInput"]: {
	create?: ResolverInputTypes["PlayerCreateWithoutCamerasInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["PlayerCreateOrConnectWithoutCamerasInput"] | undefined | null,
	connect?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null
};
	["PlayerCreateWithoutCamerasInput"]: {
	id?: string | undefined | null,
	name: string,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined | null
};
	["DigitalSignageCreateNestedOneWithoutPlayersInput"]: {
	create?: ResolverInputTypes["DigitalSignageCreateWithoutPlayersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"] | undefined | null,
	connect?: ResolverInputTypes["DigitalSignageWhereUniqueInput"] | undefined | null
};
	["DigitalSignageCreateWithoutPlayersInput"]: {
	id?: string | undefined | null,
	type: ResolverInputTypes["SignageType"],
	name: string,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["DigitalSignageCreateOrConnectWithoutPlayersInput"]: {
	where: ResolverInputTypes["DigitalSignageWhereUniqueInput"],
	create: ResolverInputTypes["DigitalSignageCreateWithoutPlayersInput"]
};
	["DigitalSignageWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["DigitalSignageWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["DigitalSignageWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["DigitalSignageWhereInput"]> | undefined | null,
	type?: ResolverInputTypes["EnumSignageTypeFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	players?: ResolverInputTypes["PlayerListRelationFilter"] | undefined | null
};
	["AudienceCreateNestedManyWithoutPlayersInput"]: {
	create?: Array<ResolverInputTypes["AudienceCreateWithoutPlayersInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AudienceCreateOrConnectWithoutPlayersInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AudienceWhereUniqueInput"]> | undefined | null
};
	["AudienceCreateWithoutPlayersInput"]: {
	id?: string | undefined | null,
	type: ResolverInputTypes["AudienceType"],
	name: string,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["AudienceCreateOrConnectWithoutPlayersInput"]: {
	where: ResolverInputTypes["AudienceWhereUniqueInput"],
	create: ResolverInputTypes["AudienceCreateWithoutPlayersInput"]
};
	["AdBoardCreateNestedManyWithoutPlayersInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutPlayersInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutPlayersInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutPlayersInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardCreateNestedOneWithoutDraftAdboardInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutDraftAdboardInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutDraftAdboardInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ResolverInputTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null
};
	["AdBoardPublishHistoryCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null,
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	changesSnapshot: ResolverInputTypes["JSON"],
	publishedBy: ResolverInputTypes["UserCreateNestedOneWithoutPublishAdboardVersionInput"]
};
	["UserCreateNestedOneWithoutPublishAdboardVersionInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutPublishAdboardVersionInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutPublishAdboardVersionInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["AdBoardReviewCommentCreateNestedManyWithoutUserInput"]: {
	create?: Array<ResolverInputTypes["AdBoardReviewCommentCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null
};
	["AdBoardReviewCommentCreateWithoutUserInput"]: {
	id?: string | undefined | null,
	comment: string,
	action: ResolverInputTypes["ReviewAction"],
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutReviewCommentsInput"]
};
	["AdBoardCreateNestedOneWithoutReviewCommentsInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutReviewCommentsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutReviewCommentsInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardCreateNestedOneWithoutPublishedVersionInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutPublishedVersionInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutPublishedVersionInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ResolverInputTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null
};
	["AdBoardReviewCommentCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null,
	comment: string,
	action: ResolverInputTypes["ReviewAction"],
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutReviewCommentsInput"]
};
	["UserCreateNestedOneWithoutReviewCommentsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutReviewCommentsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutReviewCommentsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutReviewCommentsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutArchivedByInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutArchivedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutArchivedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyArchivedByInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutArchivedByInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ResolverInputTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null
};
	["AdBoardPublicReviewCreateWithoutAdBoardInput"]: {
	id?: string | undefined | null,
	rating: number,
	comment?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutPublicReviewsInput"]
};
	["UserCreateNestedOneWithoutPublicReviewsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutPublicReviewsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutPublicReviewsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutPublicReviewsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["WorkspaceCreateNestedManyWithoutOwnerInput"]: {
	create?: Array<ResolverInputTypes["WorkspaceCreateWithoutOwnerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WorkspaceCreateOrConnectWithoutOwnerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["WorkspaceCreateManyOwnerInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null
};
	["WorkspaceCreateWithoutOwnerInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"]: {
	create?: ResolverInputTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"] | undefined | null,
	connect?: ResolverInputTypes["SubscriptionWhereUniqueInput"] | undefined | null
};
	["SubscriptionCreateWithoutActiveWorkspaceInput"]: {
	id?: string | undefined | null,
	packageId?: string | undefined | null,
	packageName?: string | undefined | null,
	priceLookupKey?: string | undefined | null,
	startDate?: ResolverInputTypes["DateTime"] | undefined | null,
	endDate?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: string | undefined | null,
	stripePaymentIntentId?: string | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	stripeCheckoutSessionId?: string | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsCreateInput"] | undefined | null
};
	["CancellationDetailsCreateInput"]: {
	canceledAt?: ResolverInputTypes["DateTime"] | undefined | null,
	cancelAt?: ResolverInputTypes["DateTime"] | undefined | null,
	cancelAtPeriodEnd: boolean,
	reason?: string | undefined | null
};
	["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"]: {
	where: ResolverInputTypes["SubscriptionWhereUniqueInput"],
	create: ResolverInputTypes["SubscriptionCreateWithoutActiveWorkspaceInput"]
};
	["CameraServerCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["CameraServerCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraServerCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["CameraServerWhereUniqueInput"]> | undefined | null
};
	["CameraServerCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined | null
};
	["CameraCreateNestedManyWithoutCameraServerInput"]: {
	create?: Array<ResolverInputTypes["CameraCreateWithoutCameraServerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraCreateOrConnectWithoutCameraServerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraCreateManyCameraServerInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null
};
	["CameraCreateWithoutCameraServerInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	player?: ResolverInputTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined | null
};
	["CameraFeedCreateNestedManyWithoutCameraInput"]: {
	create?: Array<ResolverInputTypes["CameraFeedCreateWithoutCameraInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraFeedCreateOrConnectWithoutCameraInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraFeedCreateManyCameraInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null
};
	["CameraFeedCreateWithoutCameraInput"]: {
	id?: string | undefined | null,
	timestamp: ResolverInputTypes["DateTime"],
	data: ResolverInputTypes["CameraFeedDataCreateInput"],
	cameraServer: ResolverInputTypes["CameraServerCreateNestedOneWithoutFeedsInput"]
};
	["CameraFeedDataCreateInput"]: {
	person?: number | undefined | null,
	car?: number | undefined | null,
	truck?: number | undefined | null,
	motorcycle?: number | undefined | null
};
	["CameraServerCreateNestedOneWithoutFeedsInput"]: {
	create?: ResolverInputTypes["CameraServerCreateWithoutFeedsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["CameraServerCreateOrConnectWithoutFeedsInput"] | undefined | null,
	connect?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null
};
	["CameraServerCreateWithoutFeedsInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined | null
};
	["CameraServerCreateOrConnectWithoutFeedsInput"]: {
	where: ResolverInputTypes["CameraServerWhereUniqueInput"],
	create: ResolverInputTypes["CameraServerCreateWithoutFeedsInput"]
};
	["CameraFeedCreateOrConnectWithoutCameraInput"]: {
	where: ResolverInputTypes["CameraFeedWhereUniqueInput"],
	create: ResolverInputTypes["CameraFeedCreateWithoutCameraInput"]
};
	["CameraFeedCreateManyCameraInputEnvelope"]: {
	data: Array<ResolverInputTypes["CameraFeedCreateManyCameraInput"]>
};
	["CameraFeedCreateManyCameraInput"]: {
	id?: string | undefined | null,
	timestamp: ResolverInputTypes["DateTime"],
	cameraServerId: string,
	data: ResolverInputTypes["CameraFeedDataCreateInput"]
};
	["AdBoardCreateNestedOneWithoutCamerasInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutCamerasInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutCamerasInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutCamerasInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["UserCreateNestedOneWithoutArchivedAdboardsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutArchivedAdboardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutArchivedAdboardsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["WorkspaceCreateNestedManyWithoutLeadsInput"]: {
	create?: Array<ResolverInputTypes["WorkspaceCreateWithoutLeadsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WorkspaceCreateOrConnectWithoutLeadsInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null
};
	["WorkspaceCreateWithoutLeadsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null
};
	["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null
};
	["WorkspaceRoleCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	name: string,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutRoleInput"] | undefined | null
};
	["MembershipCreateNestedManyWithoutRoleInput"]: {
	create?: Array<ResolverInputTypes["MembershipCreateWithoutRoleInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["MembershipCreateOrConnectWithoutRoleInput"]> | undefined | null,
	createMany?: ResolverInputTypes["MembershipCreateManyRoleInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null
};
	["MembershipCreateWithoutRoleInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutMembershipsInput"],
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutRoleInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	create: ResolverInputTypes["MembershipCreateWithoutRoleInput"]
};
	["MembershipCreateManyRoleInputEnvelope"]: {
	data: Array<ResolverInputTypes["MembershipCreateManyRoleInput"]>
};
	["MembershipCreateManyRoleInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	userId: string,
	workspaceId: string
};
	["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]
};
	["WorkspaceRoleCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["WorkspaceRoleCreateManyWorkspaceInput"]>
};
	["WorkspaceRoleCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	name: string,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["WebhookLogCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ResolverInputTypes["WebhookLogCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["WebhookLogCreateManyWorkspaceInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null
};
	["WebhookLogCreateWithoutWorkspaceInput"]: {
	id?: string | undefined | null,
	response?: ResolverInputTypes["JSON"] | undefined | null,
	request?: ResolverInputTypes["JSON"] | undefined | null,
	status: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	booking: ResolverInputTypes["BookingCreateNestedOneWithoutWebhookLogsInput"]
};
	["BookingCreateNestedOneWithoutWebhookLogsInput"]: {
	create?: ResolverInputTypes["BookingCreateWithoutWebhookLogsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BookingCreateOrConnectWithoutWebhookLogsInput"] | undefined | null,
	connect?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null
};
	["BookingCreateWithoutWebhookLogsInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["SlotUsageCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ResolverInputTypes["SlotUsageCreateWithoutBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["SlotUsageCreateOrConnectWithoutBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["SlotUsageCreateManyBookingInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["SlotUsageWhereUniqueInput"]> | undefined | null
};
	["SlotUsageCreateWithoutBookingInput"]: {
	id?: string | undefined | null,
	date: ResolverInputTypes["DateTime"],
	slotUsed?: number | undefined | null
};
	["SlotUsageCreateOrConnectWithoutBookingInput"]: {
	where: ResolverInputTypes["SlotUsageWhereUniqueInput"],
	create: ResolverInputTypes["SlotUsageCreateWithoutBookingInput"]
};
	["SlotUsageCreateManyBookingInputEnvelope"]: {
	data: Array<ResolverInputTypes["SlotUsageCreateManyBookingInput"]>
};
	["SlotUsageCreateManyBookingInput"]: {
	id?: string | undefined | null,
	date: ResolverInputTypes["DateTime"],
	slotUsed?: number | undefined | null
};
	["BookingCreateOrConnectWithoutWebhookLogsInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutWebhookLogsInput"]
};
	["WebhookLogCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["WebhookLogWhereUniqueInput"],
	create: ResolverInputTypes["WebhookLogCreateWithoutWorkspaceInput"]
};
	["WebhookLogCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["WebhookLogCreateManyWorkspaceInput"]>
};
	["WebhookLogCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	bookingId: string,
	response?: ResolverInputTypes["JSON"] | undefined | null,
	request?: ResolverInputTypes["JSON"] | undefined | null,
	status: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["WorkspaceCreateOrConnectWithoutLeadsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutLeadsInput"]
};
	["AdBoardPublicReviewCreateNestedManyWithoutUserInput"]: {
	create?: Array<ResolverInputTypes["AdBoardPublicReviewCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null
};
	["AdBoardPublicReviewCreateWithoutUserInput"]: {
	id?: string | undefined | null,
	rating: number,
	comment?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"]
};
	["AdBoardCreateNestedOneWithoutPublicReviewsInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutPublicReviewsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutPublicReviewsInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null
};
	["AdBoardCreateOrConnectWithoutPublicReviewsInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]: {
	where: ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardPublicReviewCreateWithoutUserInput"]
};
	["AdBoardPublicReviewCreateManyUserInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardPublicReviewCreateManyUserInput"]>
};
	["AdBoardPublicReviewCreateManyUserInput"]: {
	id?: string | undefined | null,
	adBoardId: string,
	rating: number,
	comment?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["UserPositionCreateNestedManyWithoutUserInput"]: {
	create?: Array<ResolverInputTypes["UserPositionCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["UserPositionCreateOrConnectWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["UserPositionCreateManyUserInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["UserPositionWhereUniqueInput"]> | undefined | null
};
	["UserPositionCreateWithoutUserInput"]: {
	id?: string | undefined | null,
	timestamp: ResolverInputTypes["DateTime"],
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	coords: ResolverInputTypes["UserPositionCoordsCreateInput"],
	location?: ResolverInputTypes["UserPositionLocationCreateInput"] | undefined | null
};
	["UserPositionCoordsCreateInput"]: {
	accuracy?: number | undefined | null,
	altitude?: number | undefined | null,
	altitudeAccuracy?: number | undefined | null,
	heading?: number | undefined | null,
	latitude: number,
	longitude: number,
	speed?: number | undefined | null
};
	["UserPositionLocationCreateInput"]: {
	address?: string | undefined | null,
	city?: string | undefined | null,
	state?: string | undefined | null,
	country?: string | undefined | null
};
	["UserPositionCreateOrConnectWithoutUserInput"]: {
	where: ResolverInputTypes["UserPositionWhereUniqueInput"],
	create: ResolverInputTypes["UserPositionCreateWithoutUserInput"]
};
	["UserPositionCreateManyUserInputEnvelope"]: {
	data: Array<ResolverInputTypes["UserPositionCreateManyUserInput"]>
};
	["UserPositionCreateManyUserInput"]: {
	id?: string | undefined | null,
	timestamp: ResolverInputTypes["DateTime"],
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	coords: ResolverInputTypes["UserPositionCoordsCreateInput"],
	location?: ResolverInputTypes["UserPositionLocationCreateInput"] | undefined | null
};
	["UserCreateOrConnectWithoutArchivedAdboardsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutArchivedAdboardsInput"]
};
	["AdBoardCreateOrConnectWithoutCamerasInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutCameraServerInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	create: ResolverInputTypes["CameraCreateWithoutCameraServerInput"]
};
	["CameraCreateManyCameraServerInputEnvelope"]: {
	data: Array<ResolverInputTypes["CameraCreateManyCameraServerInput"]>
};
	["CameraCreateManyCameraServerInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	playerId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	adBoardId?: string | undefined | null
};
	["CameraFeedCreateNestedManyWithoutCameraServerInput"]: {
	create?: Array<ResolverInputTypes["CameraFeedCreateWithoutCameraServerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraFeedCreateManyCameraServerInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null
};
	["CameraFeedCreateWithoutCameraServerInput"]: {
	id?: string | undefined | null,
	timestamp: ResolverInputTypes["DateTime"],
	data: ResolverInputTypes["CameraFeedDataCreateInput"],
	camera: ResolverInputTypes["CameraCreateNestedOneWithoutFeedsInput"]
};
	["CameraCreateNestedOneWithoutFeedsInput"]: {
	create?: ResolverInputTypes["CameraCreateWithoutFeedsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["CameraCreateOrConnectWithoutFeedsInput"] | undefined | null,
	connect?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null
};
	["CameraCreateWithoutFeedsInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	cameraServer: ResolverInputTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: ResolverInputTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined | null
};
	["CameraCreateOrConnectWithoutFeedsInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	create: ResolverInputTypes["CameraCreateWithoutFeedsInput"]
};
	["CameraFeedCreateOrConnectWithoutCameraServerInput"]: {
	where: ResolverInputTypes["CameraFeedWhereUniqueInput"],
	create: ResolverInputTypes["CameraFeedCreateWithoutCameraServerInput"]
};
	["CameraFeedCreateManyCameraServerInputEnvelope"]: {
	data: Array<ResolverInputTypes["CameraFeedCreateManyCameraServerInput"]>
};
	["CameraFeedCreateManyCameraServerInput"]: {
	id?: string | undefined | null,
	cameraId: string,
	timestamp: ResolverInputTypes["DateTime"],
	data: ResolverInputTypes["CameraFeedDataCreateInput"]
};
	["CameraServerCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["CameraServerWhereUniqueInput"],
	create: ResolverInputTypes["CameraServerCreateWithoutWorkspaceInput"]
};
	["CameraServerCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["CameraServerCreateManyWorkspaceInput"]>
};
	["CameraServerCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId: string,
	publicIp: string,
	privateIp: string
};
	["UserCreateNestedManyWithoutLeadWorspacesInput"]: {
	create?: Array<ResolverInputTypes["UserCreateWithoutLeadWorspacesInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["UserCreateOrConnectWithoutLeadWorspacesInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null
};
	["UserCreateWithoutLeadWorspacesInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["UserCreateOrConnectWithoutLeadWorspacesInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutLeadWorspacesInput"]
};
	["WorkspaceCreateOrConnectWithoutOwnerInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutOwnerInput"]
};
	["WorkspaceCreateManyOwnerInputEnvelope"]: {
	data: Array<ResolverInputTypes["WorkspaceCreateManyOwnerInput"]>
};
	["WorkspaceCreateManyOwnerInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	leadIds?: Array<string> | undefined | null
};
	["UserCreateOrConnectWithoutPublicReviewsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardPublicReviewCreateManyAdBoardInput"]>
};
	["AdBoardPublicReviewCreateManyAdBoardInput"]: {
	id?: string | undefined | null,
	userId: string,
	rating: number,
	comment?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["AdBoardCreateOrConnectWithoutArchivedByInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutArchivedByInput"]
};
	["AdBoardCreateManyArchivedByInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardCreateManyArchivedByInput"]>
};
	["AdBoardCreateManyArchivedByInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationCategoryId?: string | undefined | null,
	locationSubCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	adBoardTypeId?: string | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	categoryId?: string | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	offlineBookingContactId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	href?: string | undefined | null,
	workspaceId: string,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["UserCreateOrConnectWithoutReviewCommentsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardReviewCommentCreateManyAdBoardInput"]>
};
	["AdBoardReviewCommentCreateManyAdBoardInput"]: {
	id?: string | undefined | null,
	userId: string,
	comment: string,
	action: ResolverInputTypes["ReviewAction"],
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["AdBoardCreateOrConnectWithoutPublishedVersionInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutPublishedVersionInput"]
};
	["AdBoardCreateOrConnectWithoutReviewCommentsInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]: {
	where: ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardReviewCommentCreateWithoutUserInput"]
};
	["AdBoardReviewCommentCreateManyUserInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardReviewCommentCreateManyUserInput"]>
};
	["AdBoardReviewCommentCreateManyUserInput"]: {
	id?: string | undefined | null,
	adBoardId: string,
	comment: string,
	action: ResolverInputTypes["ReviewAction"],
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["UserCreateOrConnectWithoutPublishAdboardVersionInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutPublishAdboardVersionInput"]
};
	["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardPublishHistoryCreateManyAdBoardInput"]>
};
	["AdBoardPublishHistoryCreateManyAdBoardInput"]: {
	id?: string | undefined | null,
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	publishedByUserId: string,
	changesSnapshot: ResolverInputTypes["JSON"]
};
	["AdBoardCreateOrConnectWithoutDraftAdboardInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutDraftAdboardInput"]
};
	["AdBoardCreateOrConnectWithoutPlayersInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutPlayersInput"]
};
	["PlayerCreateOrConnectWithoutCamerasInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	create: ResolverInputTypes["PlayerCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutAdBoardInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	create: ResolverInputTypes["CameraCreateWithoutAdBoardInput"]
};
	["CameraCreateManyAdBoardInputEnvelope"]: {
	data: Array<ResolverInputTypes["CameraCreateManyAdBoardInput"]>
};
	["CameraCreateManyAdBoardInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	playerId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null
};
	["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]
};
	["AdBoardCreateManyOfflineBookingsContactInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardCreateManyOfflineBookingsContactInput"]>
};
	["AdBoardCreateManyOfflineBookingsContactInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationCategoryId?: string | undefined | null,
	locationSubCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	adBoardTypeId?: string | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	categoryId?: string | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	href?: string | undefined | null,
	workspaceId: string,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	archivedByUserId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["UserCreateOrConnectWithoutSharedBookingsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutSharedBookingsInput"]
};
	["WebhookLogCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ResolverInputTypes["WebhookLogCreateWithoutBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WebhookLogCreateOrConnectWithoutBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["WebhookLogCreateManyBookingInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null
};
	["WebhookLogCreateWithoutBookingInput"]: {
	id?: string | undefined | null,
	response?: ResolverInputTypes["JSON"] | undefined | null,
	request?: ResolverInputTypes["JSON"] | undefined | null,
	status: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutWebhookLogsInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutWebhookLogsInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutWebhookLogsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutWebhookLogsInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["WorkspaceCreateOrConnectWithoutWebhookLogsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutWebhookLogsInput"]
};
	["WebhookLogCreateOrConnectWithoutBookingInput"]: {
	where: ResolverInputTypes["WebhookLogWhereUniqueInput"],
	create: ResolverInputTypes["WebhookLogCreateWithoutBookingInput"]
};
	["WebhookLogCreateManyBookingInputEnvelope"]: {
	data: Array<ResolverInputTypes["WebhookLogCreateManyBookingInput"]>
};
	["WebhookLogCreateManyBookingInput"]: {
	id?: string | undefined | null,
	response?: ResolverInputTypes["JSON"] | undefined | null,
	request?: ResolverInputTypes["JSON"] | undefined | null,
	status: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspaceId?: string | undefined | null
};
	["BookingCreateOrConnectWithoutPayoutsInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutPayoutsInput"]
};
	["PayoutCreateOrConnectWithoutTransactionInput"]: {
	where: ResolverInputTypes["PayoutWhereUniqueInput"],
	create: ResolverInputTypes["PayoutCreateWithoutTransactionInput"]
};
	["PayoutCreateManyTransactionInputEnvelope"]: {
	data: Array<ResolverInputTypes["PayoutCreateManyTransactionInput"]>
};
	["PayoutCreateManyTransactionInput"]: {
	id?: string | undefined | null,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingId: string,
	status: ResolverInputTypes["PayoutStatus"]
};
	["TransactionCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["TransactionWhereUniqueInput"],
	create: ResolverInputTypes["TransactionCreateWithoutWorkspaceInput"]
};
	["TransactionCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["TransactionCreateManyWorkspaceInput"]>
};
	["TransactionCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	amount: number,
	createAt?: ResolverInputTypes["DateTime"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	relatedEntityId: string,
	transactionStatus: ResolverInputTypes["TransactionStatus"],
	details?: ResolverInputTypes["JSON"] | undefined | null
};
	["WorkspaceCreateOrConnectWithoutCameraServersInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutCameraServersInput"]
};
	["CameraServerCreateOrConnectWithoutCamerasInput"]: {
	where: ResolverInputTypes["CameraServerWhereUniqueInput"],
	create: ResolverInputTypes["CameraServerCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutPlayerInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	create: ResolverInputTypes["CameraCreateWithoutPlayerInput"]
};
	["CameraCreateManyPlayerInputEnvelope"]: {
	data: Array<ResolverInputTypes["CameraCreateManyPlayerInput"]>
};
	["CameraCreateManyPlayerInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	adBoardId?: string | undefined | null
};
	["PlayerCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	create: ResolverInputTypes["PlayerCreateWithoutWorkspaceInput"]
};
	["PlayerCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["PlayerCreateManyWorkspaceInput"]>
};
	["PlayerCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	name: string,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	adBoardGroupId?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	signageTypeId?: string | undefined | null,
	audienceTypeId?: Array<string> | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardIds?: Array<string> | undefined | null
};
	["WorkspaceCreateOrConnectWithoutTransactionsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutTransactionsInput"]
};
	["TransactionCreateOrConnectWithoutPayoutsInput"]: {
	where: ResolverInputTypes["TransactionWhereUniqueInput"],
	create: ResolverInputTypes["TransactionCreateWithoutPayoutsInput"]
};
	["PayoutCreateOrConnectWithoutBookingInput"]: {
	where: ResolverInputTypes["PayoutWhereUniqueInput"],
	create: ResolverInputTypes["PayoutCreateWithoutBookingInput"]
};
	["PayoutCreateManyBookingInputEnvelope"]: {
	data: Array<ResolverInputTypes["PayoutCreateManyBookingInput"]>
};
	["PayoutCreateManyBookingInput"]: {
	id?: string | undefined | null,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	status: ResolverInputTypes["PayoutStatus"],
	transactionId?: string | undefined | null
};
	["BookingCreateOrConnectWithoutCampaignInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutCampaignInput"]
};
	["BookingCreateManyCampaignInputEnvelope"]: {
	data: Array<ResolverInputTypes["BookingCreateManyCampaignInput"]>
};
	["BookingCreateManyCampaignInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	assetsIDs?: Array<string> | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	adBoardId: string,
	workspaceId?: string | undefined | null,
	businessCategoryId?: string | undefined | null,
	adCategoryId?: Array<string> | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	userId: string,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	sharedUserIds?: Array<string> | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null
};
	["CampaignCreateOrConnectWithoutAssetsInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	create: ResolverInputTypes["CampaignCreateWithoutAssetsInput"]
};
	["FileCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	create: ResolverInputTypes["FileCreateWithoutWorkspaceInput"]
};
	["FileCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["FileCreateManyWorkspaceInput"]>
};
	["FileCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedByUserId?: string | undefined | null,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined | null,
	proofBookingId?: string | undefined | null,
	campaignIds?: Array<string> | undefined | null
};
	["WorkspaceCreateOrConnectWithoutBookingsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutProofsInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutProofsInput"]
};
	["FileCreateOrConnectWithoutCampaignsInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	create: ResolverInputTypes["FileCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutBookingsInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	create: ResolverInputTypes["CampaignCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutWorkspaceInput"]
};
	["BookingCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["BookingCreateManyWorkspaceInput"]>
};
	["BookingCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	assetsIDs?: Array<string> | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	adBoardId: string,
	campaignId?: string | undefined | null,
	businessCategoryId?: string | undefined | null,
	adCategoryId?: Array<string> | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	userId: string,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	sharedUserIds?: Array<string> | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null
};
	["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutAdBoardInput"]
};
	["AdBoardCreateOrConnectWithoutHistoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutHistoryInput"]
};
	["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]: {
	where: ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardPublishHistoryCreateManyPublishedByInput"]>
};
	["AdBoardPublishHistoryCreateManyPublishedByInput"]: {
	id?: string | undefined | null,
	adBoardId: string,
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	changesSnapshot: ResolverInputTypes["JSON"]
};
	["UserCreateOrConnectWithoutAuditsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutAuditsInput"]
};
	["AuditCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AuditWhereUniqueInput"],
	create: ResolverInputTypes["AuditCreateWithoutWorkspaceInput"]
};
	["AuditCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["AuditCreateManyWorkspaceInput"]>
};
	["AuditCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	userId: string,
	updates?: ResolverInputTypes["JSON"] | undefined | null
};
	["WorkspaceCreateOrConnectWithoutPlayersInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutPlayersInput"]
};
	["PlayerCreateOrConnectWithoutAdBoardGroupInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	create: ResolverInputTypes["PlayerCreateWithoutAdBoardGroupInput"]
};
	["PlayerCreateManyAdBoardGroupInputEnvelope"]: {
	data: Array<ResolverInputTypes["PlayerCreateManyAdBoardGroupInput"]>
};
	["PlayerCreateManyAdBoardGroupInput"]: {
	id?: string | undefined | null,
	name: string,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	workpaceId?: string | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	signageTypeId?: string | undefined | null,
	audienceTypeId?: Array<string> | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardIds?: Array<string> | undefined | null
};
	["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]
};
	["PlaylistCreateNestedManyWithoutFooterPlaylistInput"]: {
	create?: Array<ResolverInputTypes["PlaylistCreateWithoutFooterPlaylistInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null
};
	["PlaylistCreateWithoutFooterPlaylistInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null
};
	["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"]: {
	create?: Array<ResolverInputTypes["PlaylistCreateWithoutSidebarPlaylistInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null
};
	["PlaylistCreateWithoutSidebarPlaylistInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null
};
	["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutSidebarPlaylistInput"]
};
	["PlaylistCreateManySidebarPlaylistInputEnvelope"]: {
	data: Array<ResolverInputTypes["PlaylistCreateManySidebarPlaylistInput"]>
};
	["PlaylistCreateManySidebarPlaylistInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	footerPlaylistId?: string | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	adBoardGroupIds?: Array<string> | undefined | null,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutFooterPlaylistInput"]
};
	["PlaylistCreateManyFooterPlaylistInputEnvelope"]: {
	data: Array<ResolverInputTypes["PlaylistCreateManyFooterPlaylistInput"]>
};
	["PlaylistCreateManyFooterPlaylistInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	sidebarPlaylistId?: string | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	adBoardGroupIds?: Array<string> | undefined | null,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutWorkspaceInput"]
};
	["AdBoardGroupCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardGroupCreateManyWorkspaceInput"]>
};
	["AdBoardGroupCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	name: string,
	adBoardId?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	scheduledPlaylistsIDs?: Array<string> | undefined | null,
	adsPlaylistID?: string | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null
};
	["WorkspaceCreateOrConnectWithoutPlaylistsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardGroupCreateManyAdsPlaylistInput"]>
};
	["AdBoardGroupCreateManyAdsPlaylistInput"]: {
	id?: string | undefined | null,
	name: string,
	adBoardId?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	scheduledPlaylistsIDs?: Array<string> | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutWorkspaceInput"]
};
	["PlaylistCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["PlaylistCreateManyWorkspaceInput"]>
};
	["PlaylistCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	footerPlaylistId?: string | undefined | null,
	sidebarPlaylistId?: string | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	adBoardGroupIds?: Array<string> | undefined | null
};
	["WorkspaceCreateOrConnectWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutAdBoardGroupInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutAdBoardGroupInput"]
};
	["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"]
};
	["PlayerCreateOrConnectWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	create: ResolverInputTypes["PlayerCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutWorkspaceInput"]
};
	["AdBoardCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardCreateManyWorkspaceInput"]>
};
	["AdBoardCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationCategoryId?: string | undefined | null,
	locationSubCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	adBoardTypeId?: string | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	categoryId?: string | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	offlineBookingContactId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	href?: string | undefined | null,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	archivedByUserId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["WorkspaceCreateOrConnectWithoutMembershipsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutMembershipsInput"]
};
	["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]: {
	create?: ResolverInputTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null
};
	["WorkspaceRoleCreateWithoutMembershipsInput"]: {
	id?: string | undefined | null,
	name: string,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"] | undefined | null
};
	["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutWorkspaceRolesInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutWorkspaceRolesInput"]
};
	["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"]: {
	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceRoleCreateWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutUserInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	create: ResolverInputTypes["MembershipCreateWithoutUserInput"]
};
	["MembershipCreateManyUserInputEnvelope"]: {
	data: Array<ResolverInputTypes["MembershipCreateManyUserInput"]>
};
	["MembershipCreateManyUserInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspaceId: string,
	roleId: string
};
	["UserCreateOrConnectWithoutChangelogsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutChangelogsInput"]
};
	["ChangelogCreateOrConnectWithoutBookingInput"]: {
	where: ResolverInputTypes["ChangelogWhereUniqueInput"],
	create: ResolverInputTypes["ChangelogCreateWithoutBookingInput"]
};
	["ChangelogCreateManyBookingInputEnvelope"]: {
	data: Array<ResolverInputTypes["ChangelogCreateManyBookingInput"]>
};
	["ChangelogCreateManyBookingInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	userId: string,
	updates?: ResolverInputTypes["JSON"] | undefined | null
};
	["BookingCreateOrConnectWithoutSharedUsersInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutSharedUsersInput"]
};
	["UserCreateOrConnectWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutBookingsInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAdCategoryInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutAdCategoryInput"]
};
	["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]: {
	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"],
	create: ResolverInputTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutUserInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	create: ResolverInputTypes["CampaignCreateWithoutUserInput"]
};
	["CampaignCreateManyUserInputEnvelope"]: {
	data: Array<ResolverInputTypes["CampaignCreateManyUserInput"]>
};
	["CampaignCreateManyUserInput"]: {
	id?: string | undefined | null,
	name: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	businessCategoryId: string,
	adCategoryId?: Array<string> | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined | null
};
	["UserCreateOrConnectWithoutMembershipsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	create: ResolverInputTypes["MembershipCreateWithoutWorkspaceInput"]
};
	["MembershipCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ResolverInputTypes["MembershipCreateManyWorkspaceInput"]>
};
	["MembershipCreateManyWorkspaceInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	userId: string,
	roleId: string
};
	["WorkspaceCreateOrConnectWithoutAuditsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutAuditsInput"]
};
	["AuditCreateOrConnectWithoutUserInput"]: {
	where: ResolverInputTypes["AuditWhereUniqueInput"],
	create: ResolverInputTypes["AuditCreateWithoutUserInput"]
};
	["AuditCreateManyUserInputEnvelope"]: {
	data: Array<ResolverInputTypes["AuditCreateManyUserInput"]>
};
	["AuditCreateManyUserInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	workspaceId?: string | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null
};
	["UserCreateOrConnectWithoutOrdersInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutOrdersInput"]
};
	["OrderCreateOrConnectWithoutAdBoardInput"]: {
	where: ResolverInputTypes["OrderWhereUniqueInput"],
	create: ResolverInputTypes["OrderCreateWithoutAdBoardInput"]
};
	["OrderCreateManyAdBoardInputEnvelope"]: {
	data: Array<ResolverInputTypes["OrderCreateManyAdBoardInput"]>
};
	["OrderCreateManyAdBoardInput"]: {
	id?: string | undefined | null,
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	origin: string,
	paymentId?: string | undefined | null,
	priceSummary: ResolverInputTypes["PriceSummaryCreateInput"],
	selectedDate: ResolverInputTypes["OrderSelectedDateCreateInput"]
};
	["AdBoardCreateOrConnectWithoutCreatedByInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutCreatedByInput"]
};
	["AdBoardCreateManyCreatedByInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardCreateManyCreatedByInput"]>
};
	["AdBoardCreateManyCreatedByInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationCategoryId?: string | undefined | null,
	locationSubCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	adBoardTypeId?: string | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	categoryId?: string | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	offlineBookingContactId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	workspaceId: string,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	archivedByUserId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["UserCreateOrConnectWithoutBookingsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutChangelogsInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutChangelogsInput"]
};
	["ChangelogCreateOrConnectWithoutUserInput"]: {
	where: ResolverInputTypes["ChangelogWhereUniqueInput"],
	create: ResolverInputTypes["ChangelogCreateWithoutUserInput"]
};
	["ChangelogCreateManyUserInputEnvelope"]: {
	data: Array<ResolverInputTypes["ChangelogCreateManyUserInput"]>
};
	["ChangelogCreateManyUserInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	bookingId?: string | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null
};
	["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutOfflineBookingAdboardsInput"]
};
	["AdBoardCreateOrConnectWithoutOrdersInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutOrdersInput"]
};
	["OrderCreateOrConnectWithoutCreatedByInput"]: {
	where: ResolverInputTypes["OrderWhereUniqueInput"],
	create: ResolverInputTypes["OrderCreateWithoutCreatedByInput"]
};
	["OrderCreateManyCreatedByInputEnvelope"]: {
	data: Array<ResolverInputTypes["OrderCreateManyCreatedByInput"]>
};
	["OrderCreateManyCreatedByInput"]: {
	id?: string | undefined | null,
	adBoardId: string,
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	origin: string,
	paymentId?: string | undefined | null,
	priceSummary: ResolverInputTypes["PriceSummaryCreateInput"],
	selectedDate: ResolverInputTypes["OrderSelectedDateCreateInput"]
};
	["UserCreateOrConnectWithoutOwnedWorkspacesInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutOwnedWorkspacesInput"]
};
	["WorkspaceCreateOrConnectWithoutFilesInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutFilesInput"]
};
	["FileCreateOrConnectWithoutUpdatedByInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	create: ResolverInputTypes["FileCreateWithoutUpdatedByInput"]
};
	["FileCreateManyUpdatedByInputEnvelope"]: {
	data: Array<ResolverInputTypes["FileCreateManyUpdatedByInput"]>
};
	["FileCreateManyUpdatedByInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined | null,
	workspaceId?: string | undefined | null,
	proofBookingId?: string | undefined | null,
	campaignIds?: Array<string> | undefined | null
};
	["UserCreateOrConnectWithoutCreatedFilesInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutCreatedFilesInput"]
};
	["FileCreateOrConnectWithoutProofBookingInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	create: ResolverInputTypes["FileCreateWithoutProofBookingInput"]
};
	["FileCreateManyProofBookingInputEnvelope"]: {
	data: Array<ResolverInputTypes["FileCreateManyProofBookingInput"]>
};
	["FileCreateManyProofBookingInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedByUserId?: string | undefined | null,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined | null,
	workspaceId?: string | undefined | null,
	campaignIds?: Array<string> | undefined | null
};
	["BookingCreateOrConnectWithoutBusinessCategoryInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutBusinessCategoryInput"]
};
	["BookingCreateManyBusinessCategoryInputEnvelope"]: {
	data: Array<ResolverInputTypes["BookingCreateManyBusinessCategoryInput"]>
};
	["BookingCreateManyBusinessCategoryInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	assetsIDs?: Array<string> | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	adBoardId: string,
	workspaceId?: string | undefined | null,
	campaignId?: string | undefined | null,
	adCategoryId?: Array<string> | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	userId: string,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	sharedUserIds?: Array<string> | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null
};
	["BusinessCategoryCreateOrConnectWithoutCampaignsInput"]: {
	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"],
	create: ResolverInputTypes["BusinessCategoryCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutAdCategoryInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	create: ResolverInputTypes["CampaignCreateWithoutAdCategoryInput"]
};
	["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]: {
	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"],
	create: ResolverInputTypes["AdvertisementCategoryCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAssetsInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutAssetsInput"]
};
	["FileCreateOrConnectWithoutCreatedByInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	create: ResolverInputTypes["FileCreateWithoutCreatedByInput"]
};
	["FileCreateManyCreatedByInputEnvelope"]: {
	data: Array<ResolverInputTypes["FileCreateManyCreatedByInput"]>
};
	["FileCreateManyCreatedByInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedByUserId?: string | undefined | null,
	url: string,
	bookingsIDs?: Array<string> | undefined | null,
	workspaceId?: string | undefined | null,
	proofBookingId?: string | undefined | null,
	campaignIds?: Array<string> | undefined | null
};
	["UserCreateOrConnectWithoutCampaignsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutBusinessCategoryInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	create: ResolverInputTypes["CampaignCreateWithoutBusinessCategoryInput"]
};
	["CampaignCreateManyBusinessCategoryInputEnvelope"]: {
	data: Array<ResolverInputTypes["CampaignCreateManyBusinessCategoryInput"]>
};
	["CampaignCreateManyBusinessCategoryInput"]: {
	id?: string | undefined | null,
	name: string,
	userId: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	adCategoryId?: Array<string> | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined | null
};
	["BusinessCategoryCreateOrConnectWithoutBookingsInput"]: {
	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"],
	create: ResolverInputTypes["BusinessCategoryCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutUserInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutUserInput"]
};
	["BookingCreateManyUserInputEnvelope"]: {
	data: Array<ResolverInputTypes["BookingCreateManyUserInput"]>
};
	["BookingCreateManyUserInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	assetsIDs?: Array<string> | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	adBoardId: string,
	workspaceId?: string | undefined | null,
	campaignId?: string | undefined | null,
	businessCategoryId?: string | undefined | null,
	adCategoryId?: Array<string> | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	sharedUserIds?: Array<string> | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null
};
	["UserCreateOrConnectWithoutUpdatedFilesInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutUpdatedFilesInput"]
};
	["FileCreateOrConnectWithoutBookingsInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	create: ResolverInputTypes["FileCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAdBoardInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	create: ResolverInputTypes["BookingCreateWithoutAdBoardInput"]
};
	["BookingCreateManyAdBoardInputEnvelope"]: {
	data: Array<ResolverInputTypes["BookingCreateManyAdBoardInput"]>
};
	["BookingCreateManyAdBoardInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	assetsIDs?: Array<string> | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	workspaceId?: string | undefined | null,
	campaignId?: string | undefined | null,
	businessCategoryId?: string | undefined | null,
	adCategoryId?: Array<string> | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	userId: string,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	sharedUserIds?: Array<string> | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null
};
	["AdBoardCreateOrConnectWithoutCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutCategoryInput"]
};
	["AdBoardCreateManyCategoryInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardCreateManyCategoryInput"]>
};
	["AdBoardCreateManyCategoryInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationCategoryId?: string | undefined | null,
	locationSubCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	adBoardTypeId?: string | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	offlineBookingContactId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	href?: string | undefined | null,
	workspaceId: string,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	archivedByUserId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]: {
	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"],
	create: ResolverInputTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutLocationCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutLocationCategoryInput"]
};
	["AdBoardCreateManyLocationCategoryInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardCreateManyLocationCategoryInput"]>
};
	["AdBoardCreateManyLocationCategoryInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationSubCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	adBoardTypeId?: string | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	categoryId?: string | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	offlineBookingContactId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	href?: string | undefined | null,
	workspaceId: string,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	archivedByUserId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"]: {
	where: ResolverInputTypes["LocationCategoryWhereUniqueInput"],
	create: ResolverInputTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"]
};
	["LocationCategoryWhereUniqueInput"]: {
	id?: string | undefined | null,
	AND?: Array<ResolverInputTypes["LocationCategoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["LocationCategoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["LocationCategoryWhereInput"]> | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardListRelationFilter"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryListRelationFilter"] | undefined | null
};
	["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["LocationSubCategoryWhereUniqueInput"],
	create: ResolverInputTypes["LocationSubCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutRestrictedBusinesInput"]
};
	["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"],
	create: ResolverInputTypes["BusinessCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutLocationSubCategoryInput"]
};
	["AdBoardCreateManyLocationSubCategoryInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardCreateManyLocationSubCategoryInput"]>
};
	["AdBoardCreateManyLocationSubCategoryInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	adBoardTypeId?: string | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	categoryId?: string | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	offlineBookingContactId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	href?: string | undefined | null,
	workspaceId: string,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	archivedByUserId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]: {
	where: ResolverInputTypes["LocationSubCategoryWhereUniqueInput"],
	create: ResolverInputTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"]: {
	data: Array<ResolverInputTypes["LocationSubCategoryCreateManyLocationCategoryInput"]>
};
	["LocationSubCategoryCreateManyLocationCategoryInput"]: {
	id?: string | undefined | null,
	name: string
};
	["LocationCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["LocationCategoryWhereUniqueInput"],
	create: ResolverInputTypes["LocationCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardUpdateManyMutationInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["AdBoardUpdateInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ResolverInputTypes["LocationCategoryCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	upsert?: ResolverInputTypes["LocationCategoryUpsertWithoutAdBoardsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["LocationCategoryWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null
};
	["LocationCategoryUpsertWithoutAdBoardsInput"]: {
	update: ResolverInputTypes["LocationCategoryUpdateWithoutAdBoardsInput"],
	create: ResolverInputTypes["LocationCategoryCreateWithoutAdBoardsInput"],
	where?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null
};
	["LocationCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"] | undefined | null
};
	["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"]: {
	create?: Array<ResolverInputTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["LocationSubCategoryWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["LocationSubCategoryScalarWhereInput"]> | undefined | null
};
	["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ResolverInputTypes["LocationSubCategoryWhereUniqueInput"],
	update: ResolverInputTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"],
	create: ResolverInputTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryUpdateWithoutLocationCategoryInput"]: {
	name?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutLocationSubCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutLocationSubCategoryInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutLocationSubCategoryInput"]
};
	["AdBoardUpdateWithoutLocationSubCategoryInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ResolverInputTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardTypeUpsertWithoutAdBoardsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardTypeWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null
};
	["AdBoardTypeUpsertWithoutAdBoardsInput"]: {
	update: ResolverInputTypes["AdBoardTypeUpdateWithoutAdBoardsInput"],
	create: ResolverInputTypes["AdBoardTypeCreateWithoutAdBoardsInput"],
	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null
};
	["AdBoardTypeUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null
};
	["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ResolverInputTypes["AdBoardTypeWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardTypeUpdateWithoutAdBoardsInput"]
};
	["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ResolverInputTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	upsert?: ResolverInputTypes["BusinessCategoryUpsertWithoutAdBoardsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null
};
	["BusinessCategoryUpsertWithoutAdBoardsInput"]: {
	update: ResolverInputTypes["BusinessCategoryUpdateWithoutAdBoardsInput"],
	create: ResolverInputTypes["BusinessCategoryCreateWithoutAdBoardsInput"],
	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null
};
	["BusinessCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutRestrictedBusinesInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutRestrictedBusinesInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutRestrictedBusinesInput"]
};
	["AdBoardUpdateWithoutRestrictedBusinesInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ResolverInputTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	upsert?: ResolverInputTypes["LocationSubCategoryUpsertWithoutAdBoardsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["LocationSubCategoryWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null
};
	["LocationSubCategoryUpsertWithoutAdBoardsInput"]: {
	update: ResolverInputTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"],
	create: ResolverInputTypes["LocationSubCategoryCreateWithoutAdBoardsInput"],
	where?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null
};
	["LocationSubCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"] | undefined | null
};
	["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"]: {
	create?: ResolverInputTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"] | undefined | null,
	upsert?: ResolverInputTypes["LocationCategoryUpsertWithoutLocationSubCategoryInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["LocationCategoryWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"] | undefined | null
};
	["LocationCategoryUpsertWithoutLocationSubCategoryInput"]: {
	update: ResolverInputTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"],
	create: ResolverInputTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"],
	where?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null
};
	["LocationCategoryUpdateWithoutLocationSubCategoryInput"]: {
	name?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutLocationCategoryNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutLocationCategoryNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutLocationCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyLocationCategoryInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutLocationCategoryInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutLocationCategoryInput"]
};
	["AdBoardUpdateWithoutLocationCategoryInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"]: {
	create?: Array<ResolverInputTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BusinessCategoryWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BusinessCategoryScalarWhereInput"]> | undefined | null
};
	["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: {
	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"],
	update: ResolverInputTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"],
	create: ResolverInputTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]
};
	["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutCategoryNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutCategoryInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyCategoryInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutCategoryInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutCategoryInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutCategoryInput"]
};
	["AdBoardUpdateWithoutCategoryInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["BookingUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyAdBoardInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BookingUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null
};
	["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	update: ResolverInputTypes["BookingUpdateWithoutAdBoardInput"],
	create: ResolverInputTypes["BookingCreateWithoutAdBoardInput"]
};
	["BookingUpdateWithoutAdBoardInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["FileUpdateManyWithoutBookingsNestedInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutBookingsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutBookingsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["FileUpsertWithWhereUniqueWithoutBookingsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["FileUpdateWithWhereUniqueWithoutBookingsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["FileUpdateManyWithWhereWithoutBookingsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null
};
	["FileUpsertWithWhereUniqueWithoutBookingsInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	update: ResolverInputTypes["FileUpdateWithoutBookingsInput"],
	create: ResolverInputTypes["FileCreateWithoutBookingsInput"]
};
	["FileUpdateWithoutBookingsInput"]: {
	contentType?: string | undefined | null,
	ctime?: string | undefined | null,
	filename?: string | undefined | null,
	filepath?: string | undefined | null,
	isDirectory?: boolean | undefined | null,
	meta?: ResolverInputTypes["FileMetaCreateInput"] | undefined | null,
	mtime?: string | undefined | null,
	name?: string | undefined | null,
	size?: number | undefined | null,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url?: string | undefined | null,
	updatedBy?: ResolverInputTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null
};
	["UserUpdateOneWithoutUpdatedFilesNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutUpdatedFilesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutUpdatedFilesInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutUpdatedFilesInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"] | undefined | null
};
	["UserUpsertWithoutUpdatedFilesInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutUpdatedFilesInput"],
	create: ResolverInputTypes["UserCreateWithoutUpdatedFilesInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutUpdatedFilesInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["BookingUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutUserInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BookingUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyUserInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BookingUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BookingUpdateManyWithWhereWithoutUserInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null
};
	["BookingUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	update: ResolverInputTypes["BookingUpdateWithoutUserInput"],
	create: ResolverInputTypes["BookingCreateWithoutUserInput"]
};
	["BookingUpdateWithoutUserInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["BusinessCategoryUpdateOneWithoutBookingsNestedInput"]: {
	create?: ResolverInputTypes["BusinessCategoryCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"] | undefined | null,
	upsert?: ResolverInputTypes["BusinessCategoryUpsertWithoutBookingsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null
};
	["BusinessCategoryUpsertWithoutBookingsInput"]: {
	update: ResolverInputTypes["BusinessCategoryUpdateWithoutBookingsInput"],
	create: ResolverInputTypes["BusinessCategoryCreateWithoutBookingsInput"],
	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null
};
	["BusinessCategoryUpdateWithoutBookingsInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null
};
	["CampaignUpdateManyWithoutBusinessCategoryNestedInput"]: {
	create?: Array<ResolverInputTypes["CampaignCreateWithoutBusinessCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CampaignCreateManyBusinessCategoryInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CampaignScalarWhereInput"]> | undefined | null
};
	["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	update: ResolverInputTypes["CampaignUpdateWithoutBusinessCategoryInput"],
	create: ResolverInputTypes["CampaignCreateWithoutBusinessCategoryInput"]
};
	["CampaignUpdateWithoutBusinessCategoryInput"]: {
	name?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	origin?: string | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutCampaignsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutCampaignsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutCampaignsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutCampaignsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutCampaignsInput"] | undefined | null
};
	["UserUpsertWithoutCampaignsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutCampaignsInput"],
	create: ResolverInputTypes["UserCreateWithoutCampaignsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutCampaignsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["FileUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutCreatedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutCreatedByInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["FileUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["FileCreateManyCreatedByInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["FileUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["FileUpdateManyWithWhereWithoutCreatedByInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null
};
	["FileUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	update: ResolverInputTypes["FileUpdateWithoutCreatedByInput"],
	create: ResolverInputTypes["FileCreateWithoutCreatedByInput"]
};
	["FileUpdateWithoutCreatedByInput"]: {
	contentType?: string | undefined | null,
	ctime?: string | undefined | null,
	filename?: string | undefined | null,
	filepath?: string | undefined | null,
	isDirectory?: boolean | undefined | null,
	meta?: ResolverInputTypes["FileMetaCreateInput"] | undefined | null,
	mtime?: string | undefined | null,
	name?: string | undefined | null,
	size?: number | undefined | null,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url?: string | undefined | null,
	updatedBy?: ResolverInputTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null
};
	["BookingUpdateManyWithoutAssetsNestedInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutAssetsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutAssetsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BookingUpsertWithWhereUniqueWithoutAssetsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BookingUpdateWithWhereUniqueWithoutAssetsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BookingUpdateManyWithWhereWithoutAssetsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null
};
	["BookingUpsertWithWhereUniqueWithoutAssetsInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	update: ResolverInputTypes["BookingUpdateWithoutAssetsInput"],
	create: ResolverInputTypes["BookingCreateWithoutAssetsInput"]
};
	["BookingUpdateWithoutAssetsInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"]: {
	create?: Array<ResolverInputTypes["AdvertisementCategoryCreateWithoutBookingsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null
};
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]: {
	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"],
	update: ResolverInputTypes["AdvertisementCategoryUpdateWithoutBookingsInput"],
	create: ResolverInputTypes["AdvertisementCategoryCreateWithoutBookingsInput"]
};
	["AdvertisementCategoryUpdateWithoutBookingsInput"]: {
	name?: string | undefined | null,
	type?: ResolverInputTypes["AdvertisementCategoryEnum"] | undefined | null,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"] | undefined | null
};
	["CampaignUpdateManyWithoutAdCategoryNestedInput"]: {
	create?: Array<ResolverInputTypes["CampaignCreateWithoutAdCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CampaignCreateOrConnectWithoutAdCategoryInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CampaignScalarWhereInput"]> | undefined | null
};
	["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	update: ResolverInputTypes["CampaignUpdateWithoutAdCategoryInput"],
	create: ResolverInputTypes["CampaignCreateWithoutAdCategoryInput"]
};
	["CampaignUpdateWithoutAdCategoryInput"]: {
	name?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	origin?: string | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null
};
	["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"]: {
	create?: ResolverInputTypes["BusinessCategoryCreateWithoutCampaignsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"] | undefined | null,
	upsert?: ResolverInputTypes["BusinessCategoryUpsertWithoutCampaignsInput"] | undefined | null,
	connect?: ResolverInputTypes["BusinessCategoryWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"] | undefined | null
};
	["BusinessCategoryUpsertWithoutCampaignsInput"]: {
	update: ResolverInputTypes["BusinessCategoryUpdateWithoutCampaignsInput"],
	create: ResolverInputTypes["BusinessCategoryCreateWithoutCampaignsInput"],
	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null
};
	["BusinessCategoryUpdateWithoutCampaignsInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null
};
	["BookingUpdateManyWithoutBusinessCategoryNestedInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutBusinessCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyBusinessCategoryInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null
};
	["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	update: ResolverInputTypes["BookingUpdateWithoutBusinessCategoryInput"],
	create: ResolverInputTypes["BookingCreateWithoutBusinessCategoryInput"]
};
	["BookingUpdateWithoutBusinessCategoryInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["FileUpdateManyWithoutProofBookingNestedInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutProofBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutProofBookingInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["FileUpsertWithWhereUniqueWithoutProofBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["FileCreateManyProofBookingInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["FileUpdateWithWhereUniqueWithoutProofBookingInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["FileUpdateManyWithWhereWithoutProofBookingInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null
};
	["FileUpsertWithWhereUniqueWithoutProofBookingInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	update: ResolverInputTypes["FileUpdateWithoutProofBookingInput"],
	create: ResolverInputTypes["FileCreateWithoutProofBookingInput"]
};
	["FileUpdateWithoutProofBookingInput"]: {
	contentType?: string | undefined | null,
	ctime?: string | undefined | null,
	filename?: string | undefined | null,
	filepath?: string | undefined | null,
	isDirectory?: boolean | undefined | null,
	meta?: ResolverInputTypes["FileMetaCreateInput"] | undefined | null,
	mtime?: string | undefined | null,
	name?: string | undefined | null,
	size?: number | undefined | null,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url?: string | undefined | null,
	updatedBy?: ResolverInputTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutCreatedFilesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutCreatedFilesInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutCreatedFilesInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutCreatedFilesInput"] | undefined | null
};
	["UserUpsertWithoutCreatedFilesInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutCreatedFilesInput"],
	create: ResolverInputTypes["UserCreateWithoutCreatedFilesInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutCreatedFilesInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["FileUpdateManyWithoutUpdatedByNestedInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutUpdatedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutUpdatedByInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["FileCreateManyUpdatedByInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["FileUpdateManyWithWhereWithoutUpdatedByInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null
};
	["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	update: ResolverInputTypes["FileUpdateWithoutUpdatedByInput"],
	create: ResolverInputTypes["FileCreateWithoutUpdatedByInput"]
};
	["FileUpdateWithoutUpdatedByInput"]: {
	contentType?: string | undefined | null,
	ctime?: string | undefined | null,
	filename?: string | undefined | null,
	filepath?: string | undefined | null,
	isDirectory?: boolean | undefined | null,
	meta?: ResolverInputTypes["FileMetaCreateInput"] | undefined | null,
	mtime?: string | undefined | null,
	name?: string | undefined | null,
	size?: number | undefined | null,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url?: string | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneWithoutFilesNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutFilesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutFilesInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutFilesInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutFilesInput"] | undefined | null
};
	["WorkspaceUpsertWithoutFilesInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutFilesInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutFilesInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutFilesInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["UserUpdateOneWithoutOwnedWorkspacesNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutOwnedWorkspacesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutOwnedWorkspacesInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"] | undefined | null
};
	["UserUpsertWithoutOwnedWorkspacesInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutOwnedWorkspacesInput"],
	create: ResolverInputTypes["UserCreateWithoutOwnedWorkspacesInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutOwnedWorkspacesInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["OrderUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ResolverInputTypes["OrderCreateWithoutCreatedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["OrderCreateOrConnectWithoutCreatedByInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["OrderCreateManyCreatedByInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["OrderUpdateManyWithWhereWithoutCreatedByInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["OrderScalarWhereInput"]> | undefined | null
};
	["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ResolverInputTypes["OrderWhereUniqueInput"],
	update: ResolverInputTypes["OrderUpdateWithoutCreatedByInput"],
	create: ResolverInputTypes["OrderCreateWithoutCreatedByInput"]
};
	["OrderUpdateWithoutCreatedByInput"]: {
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	origin?: string | undefined | null,
	paymentId?: string | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDate?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"] | undefined | null
};
	["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutOrdersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutOrdersInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutOrdersInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutOrdersInput"] | undefined | null
};
	["AdBoardUpsertWithoutOrdersInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutOrdersInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutOrdersInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutOrdersInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ResolverInputTypes["SlotConfigurationCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["SlotConfigurationWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["SlotConfigurationScalarWhereInput"]> | undefined | null
};
	["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["SlotConfigurationWhereUniqueInput"],
	update: ResolverInputTypes["SlotConfigurationUpdateWithoutAdBoardInput"],
	create: ResolverInputTypes["SlotConfigurationCreateWithoutAdBoardInput"]
};
	["SlotConfigurationUpdateWithoutAdBoardInput"]: {
	date?: ResolverInputTypes["DateTime"] | undefined | null,
	availableSlots?: number | undefined | null
};
	["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["SlotConfigurationWhereUniqueInput"],
	data: ResolverInputTypes["SlotConfigurationUpdateWithoutAdBoardInput"]
};
	["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ResolverInputTypes["SlotConfigurationScalarWhereInput"],
	data: ResolverInputTypes["SlotConfigurationUpdateManyMutationInput"]
};
	["SlotConfigurationScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["SlotConfigurationScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SlotConfigurationScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SlotConfigurationScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	availableSlots?: ResolverInputTypes["IntFilter"] | undefined | null
};
	["SlotConfigurationUpdateManyMutationInput"]: {
	date?: ResolverInputTypes["DateTime"] | undefined | null,
	availableSlots?: number | undefined | null
};
	["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutOfflineBookingAdboardsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"] | undefined | null
};
	["UserUpsertWithoutOfflineBookingAdboardsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutOfflineBookingAdboardsInput"],
	create: ResolverInputTypes["UserCreateWithoutOfflineBookingAdboardsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutOfflineBookingAdboardsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["ChangelogUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ResolverInputTypes["ChangelogCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["ChangelogCreateOrConnectWithoutUserInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["ChangelogUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["ChangelogCreateManyUserInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["ChangelogUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["ChangelogUpdateManyWithWhereWithoutUserInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["ChangelogScalarWhereInput"]> | undefined | null
};
	["ChangelogUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["ChangelogWhereUniqueInput"],
	update: ResolverInputTypes["ChangelogUpdateWithoutUserInput"],
	create: ResolverInputTypes["ChangelogCreateWithoutUserInput"]
};
	["ChangelogUpdateWithoutUserInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message?: string | undefined | null,
	action?: string | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	booking?: ResolverInputTypes["BookingUpdateOneWithoutChangelogsNestedInput"] | undefined | null
};
	["BookingUpdateOneWithoutChangelogsNestedInput"]: {
	create?: ResolverInputTypes["BookingCreateWithoutChangelogsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BookingCreateOrConnectWithoutChangelogsInput"] | undefined | null,
	upsert?: ResolverInputTypes["BookingUpsertWithoutChangelogsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["BookingUpdateToOneWithWhereWithoutChangelogsInput"] | undefined | null
};
	["BookingUpsertWithoutChangelogsInput"]: {
	update: ResolverInputTypes["BookingUpdateWithoutChangelogsInput"],
	create: ResolverInputTypes["BookingCreateWithoutChangelogsInput"],
	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null
};
	["BookingUpdateWithoutChangelogsInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutBookingsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutBookingsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutBookingsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null
};
	["UserUpsertWithoutBookingsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutBookingsInput"],
	create: ResolverInputTypes["UserCreateWithoutBookingsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutBookingsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutCreatedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutCreatedByInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyCreatedByInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutCreatedByInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutCreatedByInput"]
};
	["AdBoardUpdateWithoutCreatedByInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["OrderUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ResolverInputTypes["OrderCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["OrderCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["OrderCreateManyAdBoardInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["OrderWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["OrderUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["OrderScalarWhereInput"]> | undefined | null
};
	["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["OrderWhereUniqueInput"],
	update: ResolverInputTypes["OrderUpdateWithoutAdBoardInput"],
	create: ResolverInputTypes["OrderCreateWithoutAdBoardInput"]
};
	["OrderUpdateWithoutAdBoardInput"]: {
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	origin?: string | undefined | null,
	paymentId?: string | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDate?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutOrdersNestedInput"] | undefined | null
};
	["UserUpdateOneWithoutOrdersNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutOrdersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutOrdersInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutOrdersInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutOrdersInput"] | undefined | null
};
	["UserUpsertWithoutOrdersInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutOrdersInput"],
	create: ResolverInputTypes["UserCreateWithoutOrdersInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutOrdersInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["AuditUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ResolverInputTypes["AuditCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AuditCreateOrConnectWithoutUserInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AuditUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AuditCreateManyUserInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AuditUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AuditUpdateManyWithWhereWithoutUserInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AuditScalarWhereInput"]> | undefined | null
};
	["AuditUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["AuditWhereUniqueInput"],
	update: ResolverInputTypes["AuditUpdateWithoutUserInput"],
	create: ResolverInputTypes["AuditCreateWithoutUserInput"]
};
	["AuditUpdateWithoutUserInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message?: string | undefined | null,
	action?: string | undefined | null,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneWithoutAuditsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutAuditsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutAuditsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutAuditsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"] | undefined | null
};
	["WorkspaceUpsertWithoutAuditsInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutAuditsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutAuditsInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutAuditsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["MembershipUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["MembershipCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["MembershipCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["MembershipCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["MembershipScalarWhereInput"]> | undefined | null
};
	["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	update: ResolverInputTypes["MembershipUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["MembershipCreateWithoutWorkspaceInput"]
};
	["MembershipUpdateWithoutWorkspaceInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null,
	role?: ResolverInputTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutMembershipsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutMembershipsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutMembershipsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutMembershipsInput"] | undefined | null
};
	["UserUpsertWithoutMembershipsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutMembershipsInput"],
	create: ResolverInputTypes["UserCreateWithoutMembershipsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutMembershipsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["CampaignUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ResolverInputTypes["CampaignCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CampaignCreateOrConnectWithoutUserInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CampaignUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CampaignCreateManyUserInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CampaignUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CampaignUpdateManyWithWhereWithoutUserInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CampaignScalarWhereInput"]> | undefined | null
};
	["CampaignUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	update: ResolverInputTypes["CampaignUpdateWithoutUserInput"],
	create: ResolverInputTypes["CampaignCreateWithoutUserInput"]
};
	["CampaignUpdateWithoutUserInput"]: {
	name?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	origin?: string | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null
};
	["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"]: {
	create?: Array<ResolverInputTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null
};
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]: {
	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"],
	update: ResolverInputTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"],
	create: ResolverInputTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]
};
	["AdvertisementCategoryUpdateWithoutCampaignsInput"]: {
	name?: string | undefined | null,
	type?: ResolverInputTypes["AdvertisementCategoryEnum"] | undefined | null,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdCategoryNestedInput"] | undefined | null
};
	["BookingUpdateManyWithoutAdCategoryNestedInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutAdCategoryInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutAdCategoryInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BookingUpdateManyWithWhereWithoutAdCategoryInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null
};
	["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	update: ResolverInputTypes["BookingUpdateWithoutAdCategoryInput"],
	create: ResolverInputTypes["BookingCreateWithoutAdCategoryInput"]
};
	["BookingUpdateWithoutAdCategoryInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutBookingsInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutBookingsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null
};
	["AdBoardUpsertWithoutBookingsInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutBookingsInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutBookingsInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutBookingsInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["UserUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutAdBoardsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null
};
	["UserUpsertWithoutAdBoardsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutAdBoardsInput"],
	create: ResolverInputTypes["UserCreateWithoutAdBoardsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutAdBoardsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["BookingUpdateManyWithoutSharedUsersNestedInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutSharedUsersInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutSharedUsersInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BookingUpdateManyWithWhereWithoutSharedUsersInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null
};
	["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	update: ResolverInputTypes["BookingUpdateWithoutSharedUsersInput"],
	create: ResolverInputTypes["BookingCreateWithoutSharedUsersInput"]
};
	["BookingUpdateWithoutSharedUsersInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["ChangelogUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ResolverInputTypes["ChangelogCreateWithoutBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["ChangelogCreateOrConnectWithoutBookingInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["ChangelogCreateManyBookingInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["ChangelogWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["ChangelogUpdateManyWithWhereWithoutBookingInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["ChangelogScalarWhereInput"]> | undefined | null
};
	["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ResolverInputTypes["ChangelogWhereUniqueInput"],
	update: ResolverInputTypes["ChangelogUpdateWithoutBookingInput"],
	create: ResolverInputTypes["ChangelogCreateWithoutBookingInput"]
};
	["ChangelogUpdateWithoutBookingInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message?: string | undefined | null,
	action?: string | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutChangelogsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutChangelogsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutChangelogsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutChangelogsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutChangelogsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutChangelogsInput"] | undefined | null
};
	["UserUpsertWithoutChangelogsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutChangelogsInput"],
	create: ResolverInputTypes["UserCreateWithoutChangelogsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutChangelogsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["MembershipUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ResolverInputTypes["MembershipCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["MembershipCreateOrConnectWithoutUserInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["MembershipUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["MembershipCreateManyUserInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["MembershipUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["MembershipUpdateManyWithWhereWithoutUserInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["MembershipScalarWhereInput"]> | undefined | null
};
	["MembershipUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	update: ResolverInputTypes["MembershipUpdateWithoutUserInput"],
	create: ResolverInputTypes["MembershipCreateWithoutUserInput"]
};
	["MembershipUpdateWithoutUserInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null,
	role?: ResolverInputTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutMembershipsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutMembershipsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"] | undefined | null
};
	["WorkspaceUpsertWithoutMembershipsInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutMembershipsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutMembershipsInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutMembershipsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutWorkspaceInput"]
};
	["AdBoardUpdateWithoutWorkspaceInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["PlayerUpdateManyWithoutAdBoardsNestedInput"]: {
	create?: Array<ResolverInputTypes["PlayerCreateWithoutAdBoardsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlayerCreateOrConnectWithoutAdBoardsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PlayerScalarWhereInput"]> | undefined | null
};
	["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	update: ResolverInputTypes["PlayerUpdateWithoutAdBoardsInput"],
	create: ResolverInputTypes["PlayerCreateWithoutAdBoardsInput"]
};
	["PlayerUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined | null,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null
};
	["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"]: {
	create?: ResolverInputTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardGroupUpsertWithoutPisignagePlayersInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"] | undefined | null
};
	["AdBoardGroupUpsertWithoutPisignagePlayersInput"]: {
	update: ResolverInputTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"],
	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null
};
	["AdBoardGroupUpdateWithoutPisignagePlayersInput"]: {
	name?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId?: string | undefined | null,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null
};
	["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutAdBoardGroupInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutAdBoardGroupInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"] | undefined | null
};
	["AdBoardUpsertWithoutAdBoardGroupInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutAdBoardGroupInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutAdBoardGroupInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutAdBoardGroupInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutAdBoardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutAdBoardsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined | null
};
	["WorkspaceUpsertWithoutAdBoardsInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutAdBoardsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutAdBoardsInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutAdBoardsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["PlaylistUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["PlaylistCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlaylistCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PlaylistScalarWhereInput"]> | undefined | null
};
	["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	update: ResolverInputTypes["PlaylistUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutWorkspaceInput"]
};
	["PlaylistUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null
};
	["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"]: {
	create?: ResolverInputTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"] | undefined | null,
	upsert?: ResolverInputTypes["PlaylistUpsertWithoutFooterParentPlaylistsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"] | undefined | null
};
	["PlaylistUpsertWithoutFooterParentPlaylistsInput"]: {
	update: ResolverInputTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"],
	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null
};
	["PlaylistUpdateWithoutFooterParentPlaylistsInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null
};
	["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"]: {
	create?: ResolverInputTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"] | undefined | null,
	upsert?: ResolverInputTypes["PlaylistUpsertWithoutSidebarParentPlaylistsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"] | undefined | null
};
	["PlaylistUpsertWithoutSidebarParentPlaylistsInput"]: {
	update: ResolverInputTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"],
	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null
};
	["PlaylistUpdateWithoutSidebarParentPlaylistsInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null
};
	["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardGroupScalarWhereInput"]> | undefined | null
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupUpdateWithoutAdsPlaylistInput"]: {
	name?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId?: string | undefined | null,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null
};
	["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"]: {
	create?: Array<ResolverInputTypes["PlaylistCreateWithoutAdBoardGroupsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PlaylistScalarWhereInput"]> | undefined | null
};
	["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	update: ResolverInputTypes["PlaylistUpdateWithoutAdBoardGroupsInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutAdBoardGroupsInput"]
};
	["PlaylistUpdateWithoutAdBoardGroupsInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutPlaylistsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutPlaylistsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"] | undefined | null
};
	["WorkspaceUpsertWithoutPlaylistsInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutPlaylistsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutPlaylistsInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutPlaylistsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardGroupCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardGroupScalarWhereInput"]> | undefined | null
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardGroupUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutWorkspaceInput"]
};
	["AdBoardGroupUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId?: string | undefined | null,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null
};
	["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"]: {
	create?: ResolverInputTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	upsert?: ResolverInputTypes["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["PlaylistWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"] | undefined | null
};
	["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"]: {
	update: ResolverInputTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"],
	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null
};
	["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null
};
	["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardGroupWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardGroupScalarWhereInput"]> | undefined | null
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]
};
	["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"]: {
	name?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId?: string | undefined | null,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null
};
	["PlayerUpdateManyWithoutAdBoardGroupNestedInput"]: {
	create?: Array<ResolverInputTypes["PlayerCreateWithoutAdBoardGroupInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlayerCreateManyAdBoardGroupInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PlayerScalarWhereInput"]> | undefined | null
};
	["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	update: ResolverInputTypes["PlayerUpdateWithoutAdBoardGroupInput"],
	create: ResolverInputTypes["PlayerCreateWithoutAdBoardGroupInput"]
};
	["PlayerUpdateWithoutAdBoardGroupInput"]: {
	name?: string | undefined | null,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneWithoutPlayersNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutPlayersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutPlayersInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutPlayersInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"] | undefined | null
};
	["WorkspaceUpsertWithoutPlayersInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutPlayersInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutPlayersInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutPlayersInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["AuditUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["AuditCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AuditCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AuditCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AuditWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AuditUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AuditScalarWhereInput"]> | undefined | null
};
	["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AuditWhereUniqueInput"],
	update: ResolverInputTypes["AuditUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["AuditCreateWithoutWorkspaceInput"]
};
	["AuditUpdateWithoutWorkspaceInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message?: string | undefined | null,
	action?: string | undefined | null,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutAuditsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutAuditsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutAuditsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutAuditsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutAuditsInput"] | undefined | null
};
	["UserUpsertWithoutAuditsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutAuditsInput"],
	create: ResolverInputTypes["UserCreateWithoutAuditsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutAuditsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["NotificationUpdateManyWithoutRecipientNestedInput"]: {
	create?: Array<ResolverInputTypes["NotificationCreateWithoutRecipientInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["NotificationCreateOrConnectWithoutRecipientInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]> | undefined | null,
	createMany?: ResolverInputTypes["NotificationCreateManyRecipientInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["NotificationWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["NotificationWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["NotificationWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["NotificationWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["NotificationUpdateManyWithWhereWithoutRecipientInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["NotificationScalarWhereInput"]> | undefined | null
};
	["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]: {
	where: ResolverInputTypes["NotificationWhereUniqueInput"],
	update: ResolverInputTypes["NotificationUpdateWithoutRecipientInput"],
	create: ResolverInputTypes["NotificationCreateWithoutRecipientInput"]
};
	["NotificationUpdateWithoutRecipientInput"]: {
	type?: ResolverInputTypes["NotificationType"] | undefined | null,
	title?: string | undefined | null,
	content?: string | undefined | null,
	metadata?: ResolverInputTypes["JSON"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	readAt?: ResolverInputTypes["DateTime"] | undefined | null,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null
};
	["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]: {
	where: ResolverInputTypes["NotificationWhereUniqueInput"],
	data: ResolverInputTypes["NotificationUpdateWithoutRecipientInput"]
};
	["NotificationUpdateManyWithWhereWithoutRecipientInput"]: {
	where: ResolverInputTypes["NotificationScalarWhereInput"],
	data: ResolverInputTypes["NotificationUpdateManyMutationInput"]
};
	["NotificationScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["NotificationScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["NotificationScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["NotificationScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumNotificationTypeFilter"] | undefined | null,
	title?: ResolverInputTypes["StringFilter"] | undefined | null,
	content?: ResolverInputTypes["StringFilter"] | undefined | null,
	metadata?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	readAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	recipientId?: ResolverInputTypes["StringFilter"] | undefined | null,
	channels?: ResolverInputTypes["EnumNotificationChannelNullableListFilter"] | undefined | null
};
	["NotificationUpdateManyMutationInput"]: {
	type?: ResolverInputTypes["NotificationType"] | undefined | null,
	title?: string | undefined | null,
	content?: string | undefined | null,
	metadata?: ResolverInputTypes["JSON"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	readAt?: ResolverInputTypes["DateTime"] | undefined | null,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null
};
	["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null
};
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]: {
	where: ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"],
	create: ResolverInputTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryUpdateWithoutPublishedByInput"]: {
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	changesSnapshot?: ResolverInputTypes["JSON"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"] | undefined | null
};
	["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutHistoryInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutHistoryInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutHistoryInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutHistoryInput"] | undefined | null
};
	["AdBoardUpsertWithoutHistoryInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutHistoryInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutHistoryInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutHistoryInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"]: {
	create?: ResolverInputTypes["AdBoardGroupCreateWithoutAdBoardInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardGroupUpsertWithoutAdBoardInput"] | undefined | null,
	disconnect?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,
	delete?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardGroupWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"] | undefined | null
};
	["AdBoardGroupUpsertWithoutAdBoardInput"]: {
	update: ResolverInputTypes["AdBoardGroupUpdateWithoutAdBoardInput"],
	create: ResolverInputTypes["AdBoardGroupCreateWithoutAdBoardInput"],
	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null
};
	["AdBoardGroupUpdateWithoutAdBoardInput"]: {
	name?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId?: string | undefined | null,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutAdBoardGroupsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"] | undefined | null
};
	["WorkspaceUpsertWithoutAdBoardGroupsInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutAdBoardGroupsInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutAdBoardGroupsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["BookingUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BookingUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null
};
	["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	update: ResolverInputTypes["BookingUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["BookingCreateWithoutWorkspaceInput"]
};
	["BookingUpdateWithoutWorkspaceInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["CampaignUpdateOneWithoutBookingsNestedInput"]: {
	create?: ResolverInputTypes["CampaignCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["CampaignCreateOrConnectWithoutBookingsInput"] | undefined | null,
	upsert?: ResolverInputTypes["CampaignUpsertWithoutBookingsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["CampaignWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["CampaignUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null
};
	["CampaignUpsertWithoutBookingsInput"]: {
	update: ResolverInputTypes["CampaignUpdateWithoutBookingsInput"],
	create: ResolverInputTypes["CampaignCreateWithoutBookingsInput"],
	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null
};
	["CampaignUpdateWithoutBookingsInput"]: {
	name?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	origin?: string | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null
};
	["PaymentUpdateManyWithoutCampaignNestedInput"]: {
	create?: Array<ResolverInputTypes["PaymentCreateWithoutCampaignInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PaymentCreateOrConnectWithoutCampaignInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PaymentCreateManyCampaignInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PaymentWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PaymentWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PaymentWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PaymentWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PaymentUpdateManyWithWhereWithoutCampaignInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PaymentScalarWhereInput"]> | undefined | null
};
	["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]: {
	where: ResolverInputTypes["PaymentWhereUniqueInput"],
	update: ResolverInputTypes["PaymentUpdateWithoutCampaignInput"],
	create: ResolverInputTypes["PaymentCreateWithoutCampaignInput"]
};
	["PaymentUpdateWithoutCampaignInput"]: {
	paymentIntentId?: string | undefined | null,
	checkoutSessionId?: string | undefined | null,
	eventId?: string | undefined | null,
	liveMode?: boolean | undefined | null,
	paymentGateway?: ResolverInputTypes["PaymentGatewayName"] | undefined | null,
	subtotal?: number | undefined | null,
	total?: number | undefined | null,
	tax?: number | undefined | null,
	discount?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]: {
	where: ResolverInputTypes["PaymentWhereUniqueInput"],
	data: ResolverInputTypes["PaymentUpdateWithoutCampaignInput"]
};
	["PaymentUpdateManyWithWhereWithoutCampaignInput"]: {
	where: ResolverInputTypes["PaymentScalarWhereInput"],
	data: ResolverInputTypes["PaymentUpdateManyMutationInput"]
};
	["PaymentScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["PaymentScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PaymentScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PaymentScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	paymentIntentId?: ResolverInputTypes["StringFilter"] | undefined | null,
	checkoutSessionId?: ResolverInputTypes["StringFilter"] | undefined | null,
	eventId?: ResolverInputTypes["StringFilter"] | undefined | null,
	liveMode?: ResolverInputTypes["BoolFilter"] | undefined | null,
	paymentGateway?: ResolverInputTypes["EnumPaymentGatewayNameFilter"] | undefined | null,
	subtotal?: ResolverInputTypes["FloatFilter"] | undefined | null,
	total?: ResolverInputTypes["FloatFilter"] | undefined | null,
	tax?: ResolverInputTypes["FloatFilter"] | undefined | null,
	discount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	campaignId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["PaymentUpdateManyMutationInput"]: {
	paymentIntentId?: string | undefined | null,
	checkoutSessionId?: string | undefined | null,
	eventId?: string | undefined | null,
	liveMode?: boolean | undefined | null,
	paymentGateway?: ResolverInputTypes["PaymentGatewayName"] | undefined | null,
	subtotal?: number | undefined | null,
	total?: number | undefined | null,
	tax?: number | undefined | null,
	discount?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["FileUpdateManyWithoutCampaignsNestedInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutCampaignsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutCampaignsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["FileUpsertWithWhereUniqueWithoutCampaignsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["FileUpdateWithWhereUniqueWithoutCampaignsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["FileUpdateManyWithWhereWithoutCampaignsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null
};
	["FileUpsertWithWhereUniqueWithoutCampaignsInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	update: ResolverInputTypes["FileUpdateWithoutCampaignsInput"],
	create: ResolverInputTypes["FileCreateWithoutCampaignsInput"]
};
	["FileUpdateWithoutCampaignsInput"]: {
	contentType?: string | undefined | null,
	ctime?: string | undefined | null,
	filename?: string | undefined | null,
	filepath?: string | undefined | null,
	isDirectory?: boolean | undefined | null,
	meta?: ResolverInputTypes["FileMetaCreateInput"] | undefined | null,
	mtime?: string | undefined | null,
	name?: string | undefined | null,
	size?: number | undefined | null,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url?: string | undefined | null,
	updatedBy?: ResolverInputTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null
};
	["BookingUpdateOneWithoutProofsNestedInput"]: {
	create?: ResolverInputTypes["BookingCreateWithoutProofsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BookingCreateOrConnectWithoutProofsInput"] | undefined | null,
	upsert?: ResolverInputTypes["BookingUpsertWithoutProofsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["BookingUpdateToOneWithWhereWithoutProofsInput"] | undefined | null
};
	["BookingUpsertWithoutProofsInput"]: {
	update: ResolverInputTypes["BookingUpdateWithoutProofsInput"],
	create: ResolverInputTypes["BookingCreateWithoutProofsInput"],
	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null
};
	["BookingUpdateWithoutProofsInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneWithoutBookingsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutBookingsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutBookingsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutBookingsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"] | undefined | null
};
	["WorkspaceUpsertWithoutBookingsInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutBookingsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutBookingsInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutBookingsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["FileUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["FileCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["FileCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["FileCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["FileWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["FileUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null
};
	["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	update: ResolverInputTypes["FileUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["FileCreateWithoutWorkspaceInput"]
};
	["FileUpdateWithoutWorkspaceInput"]: {
	contentType?: string | undefined | null,
	ctime?: string | undefined | null,
	filename?: string | undefined | null,
	filepath?: string | undefined | null,
	isDirectory?: boolean | undefined | null,
	meta?: ResolverInputTypes["FileMetaCreateInput"] | undefined | null,
	mtime?: string | undefined | null,
	name?: string | undefined | null,
	size?: number | undefined | null,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url?: string | undefined | null,
	updatedBy?: ResolverInputTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null
};
	["CampaignUpdateManyWithoutAssetsNestedInput"]: {
	create?: Array<ResolverInputTypes["CampaignCreateWithoutAssetsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CampaignCreateOrConnectWithoutAssetsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CampaignWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CampaignUpdateManyWithWhereWithoutAssetsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CampaignScalarWhereInput"]> | undefined | null
};
	["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	update: ResolverInputTypes["CampaignUpdateWithoutAssetsInput"],
	create: ResolverInputTypes["CampaignCreateWithoutAssetsInput"]
};
	["CampaignUpdateWithoutAssetsInput"]: {
	name?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	origin?: string | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null
};
	["BookingUpdateManyWithoutCampaignNestedInput"]: {
	create?: Array<ResolverInputTypes["BookingCreateWithoutCampaignInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["BookingCreateOrConnectWithoutCampaignInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["BookingUpsertWithWhereUniqueWithoutCampaignInput"]> | undefined | null,
	createMany?: ResolverInputTypes["BookingCreateManyCampaignInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["BookingWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["BookingUpdateWithWhereUniqueWithoutCampaignInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["BookingUpdateManyWithWhereWithoutCampaignInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null
};
	["BookingUpsertWithWhereUniqueWithoutCampaignInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	update: ResolverInputTypes["BookingUpdateWithoutCampaignInput"],
	create: ResolverInputTypes["BookingCreateWithoutCampaignInput"]
};
	["BookingUpdateWithoutCampaignInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["PayoutUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ResolverInputTypes["PayoutCreateWithoutBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PayoutCreateOrConnectWithoutBookingInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PayoutUpsertWithWhereUniqueWithoutBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PayoutCreateManyBookingInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PayoutUpdateWithWhereUniqueWithoutBookingInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PayoutUpdateManyWithWhereWithoutBookingInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PayoutScalarWhereInput"]> | undefined | null
};
	["PayoutUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ResolverInputTypes["PayoutWhereUniqueInput"],
	update: ResolverInputTypes["PayoutUpdateWithoutBookingInput"],
	create: ResolverInputTypes["PayoutCreateWithoutBookingInput"]
};
	["PayoutUpdateWithoutBookingInput"]: {
	totalAmount?: number | undefined | null,
	commissionRate?: number | undefined | null,
	commissionAmount?: number | undefined | null,
	netAmount?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: ResolverInputTypes["PayoutStatus"] | undefined | null,
	transaction?: ResolverInputTypes["TransactionUpdateOneWithoutPayoutsNestedInput"] | undefined | null
};
	["TransactionUpdateOneWithoutPayoutsNestedInput"]: {
	create?: ResolverInputTypes["TransactionCreateWithoutPayoutsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["TransactionCreateOrConnectWithoutPayoutsInput"] | undefined | null,
	upsert?: ResolverInputTypes["TransactionUpsertWithoutPayoutsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["TransactionWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["TransactionWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["TransactionUpdateToOneWithWhereWithoutPayoutsInput"] | undefined | null
};
	["TransactionUpsertWithoutPayoutsInput"]: {
	update: ResolverInputTypes["TransactionUpdateWithoutPayoutsInput"],
	create: ResolverInputTypes["TransactionCreateWithoutPayoutsInput"],
	where?: ResolverInputTypes["TransactionWhereInput"] | undefined | null
};
	["TransactionUpdateWithoutPayoutsInput"]: {
	amount?: number | undefined | null,
	createAt?: ResolverInputTypes["DateTime"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	relatedEntityId?: string | undefined | null,
	transactionStatus?: ResolverInputTypes["TransactionStatus"] | undefined | null,
	details?: ResolverInputTypes["JSON"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutTransactionsNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneWithoutTransactionsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutTransactionsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutTransactionsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"] | undefined | null
};
	["WorkspaceUpsertWithoutTransactionsInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutTransactionsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutTransactionsInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutTransactionsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["PlayerUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["PlayerCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlayerCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlayerCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlayerWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PlayerScalarWhereInput"]> | undefined | null
};
	["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	update: ResolverInputTypes["PlayerUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["PlayerCreateWithoutWorkspaceInput"]
};
	["PlayerUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined | null
};
	["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"]: {
	create?: Array<ResolverInputTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined | null
};
	["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]: {
	where: ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"],
	update: ResolverInputTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"],
	create: ResolverInputTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]
};
	["PlayerUptimeStatusUpdateWithoutPlayerInput"]: {
	cpuSerialNumber?: string | undefined | null,
	date?: string | undefined | null,
	uptime?: number | undefined | null
};
	["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]: {
	where: ResolverInputTypes["PlayerUptimeStatusWhereUniqueInput"],
	data: ResolverInputTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"]
};
	["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]: {
	where: ResolverInputTypes["PlayerUptimeStatusScalarWhereInput"],
	data: ResolverInputTypes["PlayerUptimeStatusUpdateManyMutationInput"]
};
	["PlayerUptimeStatusScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	playerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["StringFilter"] | undefined | null,
	uptime?: ResolverInputTypes["FloatFilter"] | undefined | null
};
	["PlayerUptimeStatusUpdateManyMutationInput"]: {
	cpuSerialNumber?: string | undefined | null,
	date?: string | undefined | null,
	uptime?: number | undefined | null
};
	["CameraUpdateManyWithoutPlayerNestedInput"]: {
	create?: Array<ResolverInputTypes["CameraCreateWithoutPlayerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraCreateOrConnectWithoutPlayerInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CameraUpsertWithWhereUniqueWithoutPlayerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraCreateManyPlayerInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CameraUpdateWithWhereUniqueWithoutPlayerInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CameraUpdateManyWithWhereWithoutPlayerInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CameraScalarWhereInput"]> | undefined | null
};
	["CameraUpsertWithWhereUniqueWithoutPlayerInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	update: ResolverInputTypes["CameraUpdateWithoutPlayerInput"],
	create: ResolverInputTypes["CameraCreateWithoutPlayerInput"]
};
	["CameraUpdateWithoutPlayerInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	cameraUrl?: string | undefined | null,
	rtspUrl?: string | undefined | null,
	cameraIp?: string | undefined | null,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined | null
};
	["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"]: {
	create?: ResolverInputTypes["CameraServerCreateWithoutCamerasInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["CameraServerCreateOrConnectWithoutCamerasInput"] | undefined | null,
	upsert?: ResolverInputTypes["CameraServerUpsertWithoutCamerasInput"] | undefined | null,
	connect?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["CameraServerUpdateToOneWithWhereWithoutCamerasInput"] | undefined | null
};
	["CameraServerUpsertWithoutCamerasInput"]: {
	update: ResolverInputTypes["CameraServerUpdateWithoutCamerasInput"],
	create: ResolverInputTypes["CameraServerCreateWithoutCamerasInput"],
	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null
};
	["CameraServerUpdateWithoutCamerasInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId?: string | undefined | null,
	publicIp?: string | undefined | null,
	privateIp?: string | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneWithoutCameraServersNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutCameraServersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutCameraServersInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"] | undefined | null
};
	["WorkspaceUpsertWithoutCameraServersInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutCameraServersInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutCameraServersInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutCameraServersInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["TransactionUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["TransactionCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["TransactionCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["TransactionCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["TransactionWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["TransactionWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["TransactionWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["TransactionWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["TransactionScalarWhereInput"]> | undefined | null
};
	["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["TransactionWhereUniqueInput"],
	update: ResolverInputTypes["TransactionUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["TransactionCreateWithoutWorkspaceInput"]
};
	["TransactionUpdateWithoutWorkspaceInput"]: {
	amount?: number | undefined | null,
	createAt?: ResolverInputTypes["DateTime"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	relatedEntityId?: string | undefined | null,
	transactionStatus?: ResolverInputTypes["TransactionStatus"] | undefined | null,
	details?: ResolverInputTypes["JSON"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutTransactionNestedInput"] | undefined | null
};
	["PayoutUpdateManyWithoutTransactionNestedInput"]: {
	create?: Array<ResolverInputTypes["PayoutCreateWithoutTransactionInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PayoutCreateOrConnectWithoutTransactionInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PayoutCreateManyTransactionInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PayoutWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PayoutUpdateManyWithWhereWithoutTransactionInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PayoutScalarWhereInput"]> | undefined | null
};
	["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]: {
	where: ResolverInputTypes["PayoutWhereUniqueInput"],
	update: ResolverInputTypes["PayoutUpdateWithoutTransactionInput"],
	create: ResolverInputTypes["PayoutCreateWithoutTransactionInput"]
};
	["PayoutUpdateWithoutTransactionInput"]: {
	totalAmount?: number | undefined | null,
	commissionRate?: number | undefined | null,
	commissionAmount?: number | undefined | null,
	netAmount?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: ResolverInputTypes["PayoutStatus"] | undefined | null,
	booking?: ResolverInputTypes["BookingUpdateOneRequiredWithoutPayoutsNestedInput"] | undefined | null
};
	["BookingUpdateOneRequiredWithoutPayoutsNestedInput"]: {
	create?: ResolverInputTypes["BookingCreateWithoutPayoutsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BookingCreateOrConnectWithoutPayoutsInput"] | undefined | null,
	upsert?: ResolverInputTypes["BookingUpsertWithoutPayoutsInput"] | undefined | null,
	connect?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["BookingUpdateToOneWithWhereWithoutPayoutsInput"] | undefined | null
};
	["BookingUpsertWithoutPayoutsInput"]: {
	update: ResolverInputTypes["BookingUpdateWithoutPayoutsInput"],
	create: ResolverInputTypes["BookingCreateWithoutPayoutsInput"],
	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null
};
	["BookingUpdateWithoutPayoutsInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["UserUpdateManyWithoutSharedBookingsNestedInput"]: {
	create?: Array<ResolverInputTypes["UserCreateWithoutSharedBookingsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["UserCreateOrConnectWithoutSharedBookingsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["UserUpdateManyWithWhereWithoutSharedBookingsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["UserScalarWhereInput"]> | undefined | null
};
	["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	update: ResolverInputTypes["UserUpdateWithoutSharedBookingsInput"],
	create: ResolverInputTypes["UserCreateWithoutSharedBookingsInput"]
};
	["UserUpdateWithoutSharedBookingsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]
};
	["AdBoardUpdateWithoutOfflineBookingsContactInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["CameraUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ResolverInputTypes["CameraCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraCreateManyAdBoardInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CameraUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CameraScalarWhereInput"]> | undefined | null
};
	["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	update: ResolverInputTypes["CameraUpdateWithoutAdBoardInput"],
	create: ResolverInputTypes["CameraCreateWithoutAdBoardInput"]
};
	["CameraUpdateWithoutAdBoardInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	cameraUrl?: string | undefined | null,
	rtspUrl?: string | undefined | null,
	cameraIp?: string | undefined | null,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined | null,
	player?: ResolverInputTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined | null
};
	["PlayerUpdateOneWithoutCamerasNestedInput"]: {
	create?: ResolverInputTypes["PlayerCreateWithoutCamerasInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["PlayerCreateOrConnectWithoutCamerasInput"] | undefined | null,
	upsert?: ResolverInputTypes["PlayerUpsertWithoutCamerasInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["PlayerWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["PlayerUpdateToOneWithWhereWithoutCamerasInput"] | undefined | null
};
	["PlayerUpsertWithoutCamerasInput"]: {
	update: ResolverInputTypes["PlayerUpdateWithoutCamerasInput"],
	create: ResolverInputTypes["PlayerCreateWithoutCamerasInput"],
	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null
};
	["PlayerUpdateWithoutCamerasInput"]: {
	name?: string | undefined | null,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined | null
};
	["DigitalSignageUpdateOneWithoutPlayersNestedInput"]: {
	create?: ResolverInputTypes["DigitalSignageCreateWithoutPlayersInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"] | undefined | null,
	upsert?: ResolverInputTypes["DigitalSignageUpsertWithoutPlayersInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["DigitalSignageWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["DigitalSignageWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"] | undefined | null
};
	["DigitalSignageUpsertWithoutPlayersInput"]: {
	update: ResolverInputTypes["DigitalSignageUpdateWithoutPlayersInput"],
	create: ResolverInputTypes["DigitalSignageCreateWithoutPlayersInput"],
	where?: ResolverInputTypes["DigitalSignageWhereInput"] | undefined | null
};
	["DigitalSignageUpdateWithoutPlayersInput"]: {
	type?: ResolverInputTypes["SignageType"] | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"]: {
	where?: ResolverInputTypes["DigitalSignageWhereInput"] | undefined | null,
	data: ResolverInputTypes["DigitalSignageUpdateWithoutPlayersInput"]
};
	["AudienceUpdateManyWithoutPlayersNestedInput"]: {
	create?: Array<ResolverInputTypes["AudienceCreateWithoutPlayersInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AudienceCreateOrConnectWithoutPlayersInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["AudienceWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AudienceWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AudienceWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AudienceWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AudienceUpdateManyWithWhereWithoutPlayersInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AudienceScalarWhereInput"]> | undefined | null
};
	["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]: {
	where: ResolverInputTypes["AudienceWhereUniqueInput"],
	update: ResolverInputTypes["AudienceUpdateWithoutPlayersInput"],
	create: ResolverInputTypes["AudienceCreateWithoutPlayersInput"]
};
	["AudienceUpdateWithoutPlayersInput"]: {
	type?: ResolverInputTypes["AudienceType"] | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]: {
	where: ResolverInputTypes["AudienceWhereUniqueInput"],
	data: ResolverInputTypes["AudienceUpdateWithoutPlayersInput"]
};
	["AudienceUpdateManyWithWhereWithoutPlayersInput"]: {
	where: ResolverInputTypes["AudienceScalarWhereInput"],
	data: ResolverInputTypes["AudienceUpdateManyMutationInput"]
};
	["AudienceScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["AudienceScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AudienceScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AudienceScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumAudienceTypeFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	playerIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["AudienceUpdateManyMutationInput"]: {
	type?: ResolverInputTypes["AudienceType"] | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["AdBoardUpdateManyWithoutPlayersNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutPlayersInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutPlayersInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutPlayersInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutPlayersInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutPlayersInput"]
};
	["AdBoardUpdateWithoutPlayersInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardUpdateOneWithoutDraftAdboardNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutDraftAdboardInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutDraftAdboardInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"] | undefined | null
};
	["AdBoardUpsertWithoutDraftAdboardInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutDraftAdboardInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutDraftAdboardInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutDraftAdboardInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null
};
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"],
	create: ResolverInputTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryUpdateWithoutAdBoardInput"]: {
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	changesSnapshot?: ResolverInputTypes["JSON"] | undefined | null,
	publishedBy?: ResolverInputTypes["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutPublishAdboardVersionInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutPublishAdboardVersionInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"] | undefined | null
};
	["UserUpsertWithoutPublishAdboardVersionInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutPublishAdboardVersionInput"],
	create: ResolverInputTypes["UserCreateWithoutPublishAdboardVersionInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutPublishAdboardVersionInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardReviewCommentCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null
};
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardReviewCommentUpdateWithoutUserInput"],
	create: ResolverInputTypes["AdBoardReviewCommentCreateWithoutUserInput"]
};
	["AdBoardReviewCommentUpdateWithoutUserInput"]: {
	comment?: string | undefined | null,
	action?: ResolverInputTypes["ReviewAction"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"] | undefined | null
};
	["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutReviewCommentsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutReviewCommentsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"] | undefined | null
};
	["AdBoardUpsertWithoutReviewCommentsInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutReviewCommentsInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutReviewCommentsInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutReviewCommentsInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardUpdateOneWithoutPublishedVersionNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutPublishedVersionInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutPublishedVersionInput"] | undefined | null,
	disconnect?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	delete?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"] | undefined | null
};
	["AdBoardUpsertWithoutPublishedVersionInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutPublishedVersionInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutPublishedVersionInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutPublishedVersionInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null
};
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"],
	create: ResolverInputTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentUpdateWithoutAdBoardInput"]: {
	comment?: string | undefined | null,
	action?: ResolverInputTypes["ReviewAction"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutReviewCommentsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutReviewCommentsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutReviewCommentsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutReviewCommentsInput"] | undefined | null
};
	["UserUpsertWithoutReviewCommentsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutReviewCommentsInput"],
	create: ResolverInputTypes["UserCreateWithoutReviewCommentsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutReviewCommentsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutArchivedByNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutArchivedByInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutArchivedByInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyArchivedByInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutArchivedByInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutArchivedByInput"]
};
	["AdBoardUpdateWithoutArchivedByInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null
};
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"],
	create: ResolverInputTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewUpdateWithoutAdBoardInput"]: {
	rating?: number | undefined | null,
	comment?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutPublicReviewsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutPublicReviewsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutPublicReviewsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutPublicReviewsInput"] | undefined | null
};
	["UserUpsertWithoutPublicReviewsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutPublicReviewsInput"],
	create: ResolverInputTypes["UserCreateWithoutPublicReviewsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutPublicReviewsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["WorkspaceUpdateManyWithoutOwnerNestedInput"]: {
	create?: Array<ResolverInputTypes["WorkspaceCreateWithoutOwnerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WorkspaceCreateOrConnectWithoutOwnerInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["WorkspaceCreateManyOwnerInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["WorkspaceScalarWhereInput"]> | undefined | null
};
	["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	update: ResolverInputTypes["WorkspaceUpdateWithoutOwnerInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutOwnerInput"]
};
	["WorkspaceUpdateWithoutOwnerInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"]: {
	create?: ResolverInputTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"] | undefined | null,
	upsert?: ResolverInputTypes["SubscriptionUpsertWithoutActiveWorkspaceInput"] | undefined | null,
	disconnect?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,
	delete?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["SubscriptionWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"] | undefined | null
};
	["SubscriptionUpsertWithoutActiveWorkspaceInput"]: {
	update: ResolverInputTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"],
	create: ResolverInputTypes["SubscriptionCreateWithoutActiveWorkspaceInput"],
	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null
};
	["SubscriptionUpdateWithoutActiveWorkspaceInput"]: {
	packageId?: string | undefined | null,
	packageName?: string | undefined | null,
	priceLookupKey?: string | undefined | null,
	startDate?: ResolverInputTypes["DateTime"] | undefined | null,
	endDate?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: string | undefined | null,
	stripePaymentIntentId?: string | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	stripeCheckoutSessionId?: string | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsCreateInput"] | undefined | null
};
	["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"]: {
	where?: ResolverInputTypes["SubscriptionWhereInput"] | undefined | null,
	data: ResolverInputTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"]
};
	["CameraServerUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["CameraServerCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraServerCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["CameraServerWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CameraServerWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CameraServerWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CameraServerWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CameraServerScalarWhereInput"]> | undefined | null
};
	["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["CameraServerWhereUniqueInput"],
	update: ResolverInputTypes["CameraServerUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["CameraServerCreateWithoutWorkspaceInput"]
};
	["CameraServerUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId?: string | undefined | null,
	publicIp?: string | undefined | null,
	privateIp?: string | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined | null
};
	["CameraUpdateManyWithoutCameraServerNestedInput"]: {
	create?: Array<ResolverInputTypes["CameraCreateWithoutCameraServerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraCreateOrConnectWithoutCameraServerInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraCreateManyCameraServerInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CameraWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CameraUpdateManyWithWhereWithoutCameraServerInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CameraScalarWhereInput"]> | undefined | null
};
	["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	update: ResolverInputTypes["CameraUpdateWithoutCameraServerInput"],
	create: ResolverInputTypes["CameraCreateWithoutCameraServerInput"]
};
	["CameraUpdateWithoutCameraServerInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	cameraUrl?: string | undefined | null,
	rtspUrl?: string | undefined | null,
	cameraIp?: string | undefined | null,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	player?: ResolverInputTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined | null
};
	["CameraFeedUpdateManyWithoutCameraNestedInput"]: {
	create?: Array<ResolverInputTypes["CameraFeedCreateWithoutCameraInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraFeedCreateOrConnectWithoutCameraInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraFeedCreateManyCameraInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CameraFeedUpdateManyWithWhereWithoutCameraInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CameraFeedScalarWhereInput"]> | undefined | null
};
	["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]: {
	where: ResolverInputTypes["CameraFeedWhereUniqueInput"],
	update: ResolverInputTypes["CameraFeedUpdateWithoutCameraInput"],
	create: ResolverInputTypes["CameraFeedCreateWithoutCameraInput"]
};
	["CameraFeedUpdateWithoutCameraInput"]: {
	timestamp?: ResolverInputTypes["DateTime"] | undefined | null,
	data?: ResolverInputTypes["CameraFeedDataCreateInput"] | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"] | undefined | null
};
	["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"]: {
	create?: ResolverInputTypes["CameraServerCreateWithoutFeedsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["CameraServerCreateOrConnectWithoutFeedsInput"] | undefined | null,
	upsert?: ResolverInputTypes["CameraServerUpsertWithoutFeedsInput"] | undefined | null,
	connect?: ResolverInputTypes["CameraServerWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["CameraServerUpdateToOneWithWhereWithoutFeedsInput"] | undefined | null
};
	["CameraServerUpsertWithoutFeedsInput"]: {
	update: ResolverInputTypes["CameraServerUpdateWithoutFeedsInput"],
	create: ResolverInputTypes["CameraServerCreateWithoutFeedsInput"],
	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null
};
	["CameraServerUpdateWithoutFeedsInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId?: string | undefined | null,
	publicIp?: string | undefined | null,
	privateIp?: string | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined | null
};
	["CameraServerUpdateToOneWithWhereWithoutFeedsInput"]: {
	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,
	data: ResolverInputTypes["CameraServerUpdateWithoutFeedsInput"]
};
	["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]: {
	where: ResolverInputTypes["CameraFeedWhereUniqueInput"],
	data: ResolverInputTypes["CameraFeedUpdateWithoutCameraInput"]
};
	["CameraFeedUpdateManyWithWhereWithoutCameraInput"]: {
	where: ResolverInputTypes["CameraFeedScalarWhereInput"],
	data: ResolverInputTypes["CameraFeedUpdateManyMutationInput"]
};
	["CameraFeedScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["CameraFeedScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraFeedScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraFeedScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	cameraId?: ResolverInputTypes["StringFilter"] | undefined | null,
	timestamp?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	cameraServerId?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["CameraFeedUpdateManyMutationInput"]: {
	timestamp?: ResolverInputTypes["DateTime"] | undefined | null,
	data?: ResolverInputTypes["CameraFeedDataCreateInput"] | undefined | null
};
	["AdBoardUpdateOneWithoutCamerasNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutCamerasInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutCamerasInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutCamerasInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutCamerasInput"] | undefined | null
};
	["AdBoardUpsertWithoutCamerasInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutCamerasInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutCamerasInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutCamerasInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["UserUpdateOneWithoutArchivedAdboardsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutArchivedAdboardsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutArchivedAdboardsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"] | undefined | null
};
	["UserUpsertWithoutArchivedAdboardsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutArchivedAdboardsInput"],
	create: ResolverInputTypes["UserCreateWithoutArchivedAdboardsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutArchivedAdboardsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["WorkspaceUpdateManyWithoutLeadsNestedInput"]: {
	create?: Array<ResolverInputTypes["WorkspaceCreateWithoutLeadsInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WorkspaceCreateOrConnectWithoutLeadsInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["WorkspaceWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["WorkspaceScalarWhereInput"]> | undefined | null
};
	["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	update: ResolverInputTypes["WorkspaceUpdateWithoutLeadsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutLeadsInput"]
};
	["WorkspaceUpdateWithoutLeadsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null
};
	["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["WorkspaceRoleWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["WorkspaceRoleScalarWhereInput"]> | undefined | null
};
	["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"],
	update: ResolverInputTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]
};
	["WorkspaceRoleUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined | null,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutRoleNestedInput"] | undefined | null
};
	["MembershipUpdateManyWithoutRoleNestedInput"]: {
	create?: Array<ResolverInputTypes["MembershipCreateWithoutRoleInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["MembershipCreateOrConnectWithoutRoleInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["MembershipUpsertWithWhereUniqueWithoutRoleInput"]> | undefined | null,
	createMany?: ResolverInputTypes["MembershipCreateManyRoleInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["MembershipWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["MembershipUpdateWithWhereUniqueWithoutRoleInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["MembershipUpdateManyWithWhereWithoutRoleInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["MembershipScalarWhereInput"]> | undefined | null
};
	["MembershipUpsertWithWhereUniqueWithoutRoleInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	update: ResolverInputTypes["MembershipUpdateWithoutRoleInput"],
	create: ResolverInputTypes["MembershipCreateWithoutRoleInput"]
};
	["MembershipUpdateWithoutRoleInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null
};
	["MembershipUpdateWithWhereUniqueWithoutRoleInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	data: ResolverInputTypes["MembershipUpdateWithoutRoleInput"]
};
	["MembershipUpdateManyWithWhereWithoutRoleInput"]: {
	where: ResolverInputTypes["MembershipScalarWhereInput"],
	data: ResolverInputTypes["MembershipUpdateManyMutationInput"]
};
	["MembershipScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["MembershipScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["MembershipScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["MembershipScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	roleId?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["MembershipUpdateManyMutationInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"],
	data: ResolverInputTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"]
};
	["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["WorkspaceRoleScalarWhereInput"],
	data: ResolverInputTypes["WorkspaceRoleUpdateManyMutationInput"]
};
	["WorkspaceRoleScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["WorkspaceRoleScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WorkspaceRoleScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WorkspaceRoleScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	permissions?: ResolverInputTypes["EnumPermissionNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["WorkspaceRoleUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["WebhookLogUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ResolverInputTypes["WebhookLogCreateWithoutWorkspaceInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	createMany?: ResolverInputTypes["WebhookLogCreateManyWorkspaceInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["WebhookLogScalarWhereInput"]> | undefined | null
};
	["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["WebhookLogWhereUniqueInput"],
	update: ResolverInputTypes["WebhookLogUpdateWithoutWorkspaceInput"],
	create: ResolverInputTypes["WebhookLogCreateWithoutWorkspaceInput"]
};
	["WebhookLogUpdateWithoutWorkspaceInput"]: {
	response?: ResolverInputTypes["JSON"] | undefined | null,
	request?: ResolverInputTypes["JSON"] | undefined | null,
	status?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	booking?: ResolverInputTypes["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"] | undefined | null
};
	["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"]: {
	create?: ResolverInputTypes["BookingCreateWithoutWebhookLogsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["BookingCreateOrConnectWithoutWebhookLogsInput"] | undefined | null,
	upsert?: ResolverInputTypes["BookingUpsertWithoutWebhookLogsInput"] | undefined | null,
	connect?: ResolverInputTypes["BookingWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"] | undefined | null
};
	["BookingUpsertWithoutWebhookLogsInput"]: {
	update: ResolverInputTypes["BookingUpdateWithoutWebhookLogsInput"],
	create: ResolverInputTypes["BookingCreateWithoutWebhookLogsInput"],
	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null
};
	["BookingUpdateWithoutWebhookLogsInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["SlotUsageUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ResolverInputTypes["SlotUsageCreateWithoutBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["SlotUsageCreateOrConnectWithoutBookingInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["SlotUsageCreateManyBookingInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["SlotUsageWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["SlotUsageWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["SlotUsageWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["SlotUsageWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["SlotUsageUpdateManyWithWhereWithoutBookingInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["SlotUsageScalarWhereInput"]> | undefined | null
};
	["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ResolverInputTypes["SlotUsageWhereUniqueInput"],
	update: ResolverInputTypes["SlotUsageUpdateWithoutBookingInput"],
	create: ResolverInputTypes["SlotUsageCreateWithoutBookingInput"]
};
	["SlotUsageUpdateWithoutBookingInput"]: {
	date?: ResolverInputTypes["DateTime"] | undefined | null,
	slotUsed?: number | undefined | null
};
	["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ResolverInputTypes["SlotUsageWhereUniqueInput"],
	data: ResolverInputTypes["SlotUsageUpdateWithoutBookingInput"]
};
	["SlotUsageUpdateManyWithWhereWithoutBookingInput"]: {
	where: ResolverInputTypes["SlotUsageScalarWhereInput"],
	data: ResolverInputTypes["SlotUsageUpdateManyMutationInput"]
};
	["SlotUsageScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["SlotUsageScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["SlotUsageScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["SlotUsageScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	date?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	slotUsed?: ResolverInputTypes["IntFilter"] | undefined | null
};
	["SlotUsageUpdateManyMutationInput"]: {
	date?: ResolverInputTypes["DateTime"] | undefined | null,
	slotUsed?: number | undefined | null
};
	["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"]: {
	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	data: ResolverInputTypes["BookingUpdateWithoutWebhookLogsInput"]
};
	["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["WebhookLogWhereUniqueInput"],
	data: ResolverInputTypes["WebhookLogUpdateWithoutWorkspaceInput"]
};
	["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["WebhookLogScalarWhereInput"],
	data: ResolverInputTypes["WebhookLogUpdateManyMutationInput"]
};
	["WebhookLogScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["WebhookLogScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WebhookLogScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WebhookLogScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	response?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	request?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	status?: ResolverInputTypes["IntFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["WebhookLogUpdateManyMutationInput"]: {
	response?: ResolverInputTypes["JSON"] | undefined | null,
	request?: ResolverInputTypes["JSON"] | undefined | null,
	status?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	data: ResolverInputTypes["WorkspaceUpdateWithoutLeadsInput"]
};
	["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]: {
	where: ResolverInputTypes["WorkspaceScalarWhereInput"],
	data: ResolverInputTypes["WorkspaceUpdateManyMutationInput"]
};
	["WorkspaceScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["WorkspaceScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WorkspaceScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WorkspaceScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumWorkspaceTypeFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	key?: ResolverInputTypes["StringFilter"] | undefined | null,
	userIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	ownerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	avatar?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	stripeSubscriptionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	leadIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["WorkspaceUpdateManyMutationInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null
};
	["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardPublicReviewCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null
};
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardPublicReviewUpdateWithoutUserInput"],
	create: ResolverInputTypes["AdBoardPublicReviewCreateWithoutUserInput"]
};
	["AdBoardPublicReviewUpdateWithoutUserInput"]: {
	rating?: number | undefined | null,
	comment?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"] | undefined | null
};
	["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutPublicReviewsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutPublicReviewsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"] | undefined | null
};
	["AdBoardUpsertWithoutPublicReviewsInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutPublicReviewsInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutPublicReviewsInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutPublicReviewsInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null
};
	["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardPublicReviewUpdateWithoutUserInput"]
};
	["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]: {
	where: ResolverInputTypes["AdBoardPublicReviewScalarWhereInput"],
	data: ResolverInputTypes["AdBoardPublicReviewUpdateManyMutationInput"]
};
	["AdBoardPublicReviewScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	rating?: ResolverInputTypes["IntFilter"] | undefined | null,
	comment?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["AdBoardPublicReviewUpdateManyMutationInput"]: {
	rating?: number | undefined | null,
	comment?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["UserPositionUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ResolverInputTypes["UserPositionCreateWithoutUserInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["UserPositionCreateOrConnectWithoutUserInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["UserPositionUpsertWithWhereUniqueWithoutUserInput"]> | undefined | null,
	createMany?: ResolverInputTypes["UserPositionCreateManyUserInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["UserPositionWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["UserPositionWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["UserPositionWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["UserPositionWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["UserPositionUpdateWithWhereUniqueWithoutUserInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["UserPositionUpdateManyWithWhereWithoutUserInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["UserPositionScalarWhereInput"]> | undefined | null
};
	["UserPositionUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["UserPositionWhereUniqueInput"],
	update: ResolverInputTypes["UserPositionUpdateWithoutUserInput"],
	create: ResolverInputTypes["UserPositionCreateWithoutUserInput"]
};
	["UserPositionUpdateWithoutUserInput"]: {
	timestamp?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	coords?: ResolverInputTypes["UserPositionCoordsCreateInput"] | undefined | null,
	location?: ResolverInputTypes["UserPositionLocationCreateInput"] | undefined | null
};
	["UserPositionUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["UserPositionWhereUniqueInput"],
	data: ResolverInputTypes["UserPositionUpdateWithoutUserInput"]
};
	["UserPositionUpdateManyWithWhereWithoutUserInput"]: {
	where: ResolverInputTypes["UserPositionScalarWhereInput"],
	data: ResolverInputTypes["UserPositionUpdateManyMutationInput"]
};
	["UserPositionScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["UserPositionScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserPositionScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserPositionScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	timestamp?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["UserPositionUpdateManyMutationInput"]: {
	timestamp?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	coords?: ResolverInputTypes["UserPositionCoordsCreateInput"] | undefined | null,
	location?: ResolverInputTypes["UserPositionLocationCreateInput"] | undefined | null
};
	["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutArchivedAdboardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	data: ResolverInputTypes["CameraUpdateWithoutCameraServerInput"]
};
	["CameraUpdateManyWithWhereWithoutCameraServerInput"]: {
	where: ResolverInputTypes["CameraScalarWhereInput"],
	data: ResolverInputTypes["CameraUpdateManyMutationInput"]
};
	["CameraScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["CameraScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	cameraServerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	cameraUrl?: ResolverInputTypes["StringFilter"] | undefined | null,
	rtspUrl?: ResolverInputTypes["StringFilter"] | undefined | null,
	cameraIp?: ResolverInputTypes["StringFilter"] | undefined | null,
	connectedCpuSerialNumbers?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	playerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	isPublic?: ResolverInputTypes["BoolFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["CameraUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	cameraUrl?: string | undefined | null,
	rtspUrl?: string | undefined | null,
	cameraIp?: string | undefined | null,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null
};
	["CameraFeedUpdateManyWithoutCameraServerNestedInput"]: {
	create?: Array<ResolverInputTypes["CameraFeedCreateWithoutCameraServerInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]> | undefined | null,
	createMany?: ResolverInputTypes["CameraFeedCreateManyCameraServerInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["CameraFeedWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["CameraFeedScalarWhereInput"]> | undefined | null
};
	["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]: {
	where: ResolverInputTypes["CameraFeedWhereUniqueInput"],
	update: ResolverInputTypes["CameraFeedUpdateWithoutCameraServerInput"],
	create: ResolverInputTypes["CameraFeedCreateWithoutCameraServerInput"]
};
	["CameraFeedUpdateWithoutCameraServerInput"]: {
	timestamp?: ResolverInputTypes["DateTime"] | undefined | null,
	data?: ResolverInputTypes["CameraFeedDataCreateInput"] | undefined | null,
	camera?: ResolverInputTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"] | undefined | null
};
	["CameraUpdateOneRequiredWithoutFeedsNestedInput"]: {
	create?: ResolverInputTypes["CameraCreateWithoutFeedsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["CameraCreateOrConnectWithoutFeedsInput"] | undefined | null,
	upsert?: ResolverInputTypes["CameraUpsertWithoutFeedsInput"] | undefined | null,
	connect?: ResolverInputTypes["CameraWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["CameraUpdateToOneWithWhereWithoutFeedsInput"] | undefined | null
};
	["CameraUpsertWithoutFeedsInput"]: {
	update: ResolverInputTypes["CameraUpdateWithoutFeedsInput"],
	create: ResolverInputTypes["CameraCreateWithoutFeedsInput"],
	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null
};
	["CameraUpdateWithoutFeedsInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	cameraUrl?: string | undefined | null,
	rtspUrl?: string | undefined | null,
	cameraIp?: string | undefined | null,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined | null,
	player?: ResolverInputTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined | null
};
	["CameraUpdateToOneWithWhereWithoutFeedsInput"]: {
	where?: ResolverInputTypes["CameraWhereInput"] | undefined | null,
	data: ResolverInputTypes["CameraUpdateWithoutFeedsInput"]
};
	["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]: {
	where: ResolverInputTypes["CameraFeedWhereUniqueInput"],
	data: ResolverInputTypes["CameraFeedUpdateWithoutCameraServerInput"]
};
	["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]: {
	where: ResolverInputTypes["CameraFeedScalarWhereInput"],
	data: ResolverInputTypes["CameraFeedUpdateManyMutationInput"]
};
	["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["CameraServerWhereUniqueInput"],
	data: ResolverInputTypes["CameraServerUpdateWithoutWorkspaceInput"]
};
	["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["CameraServerScalarWhereInput"],
	data: ResolverInputTypes["CameraServerUpdateManyMutationInput"]
};
	["CameraServerScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["CameraServerScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CameraServerScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CameraServerScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	hostName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	userName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	deviceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	publicIp?: ResolverInputTypes["StringFilter"] | undefined | null,
	privateIp?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["CameraServerUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId?: string | undefined | null,
	publicIp?: string | undefined | null,
	privateIp?: string | undefined | null
};
	["UserUpdateManyWithoutLeadWorspacesNestedInput"]: {
	create?: Array<ResolverInputTypes["UserCreateWithoutLeadWorspacesInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["UserCreateOrConnectWithoutLeadWorspacesInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["UserWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["UserScalarWhereInput"]> | undefined | null
};
	["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	update: ResolverInputTypes["UserUpdateWithoutLeadWorspacesInput"],
	create: ResolverInputTypes["UserCreateWithoutLeadWorspacesInput"]
};
	["UserUpdateWithoutLeadWorspacesInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	data: ResolverInputTypes["UserUpdateWithoutLeadWorspacesInput"]
};
	["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]: {
	where: ResolverInputTypes["UserScalarWhereInput"],
	data: ResolverInputTypes["UserUpdateManyMutationInput"]
};
	["UserScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["UserScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	uid?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	email?: ResolverInputTypes["StringFilter"] | undefined | null,
	image?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	phoneNumber?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	otherDetails?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	roles?: ResolverInputTypes["EnumRoleNullableListFilter"] | undefined | null,
	sharedBookingIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	disclaimerAccepted?: ResolverInputTypes["BoolFilter"] | undefined | null,
	leadWorkspaceIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["UserUpdateManyMutationInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null
};
	["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	data: ResolverInputTypes["WorkspaceUpdateWithoutOwnerInput"]
};
	["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]: {
	where: ResolverInputTypes["WorkspaceScalarWhereInput"],
	data: ResolverInputTypes["WorkspaceUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutPublicReviewsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardPublicReviewWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardPublicReviewScalarWhereInput"],
	data: ResolverInputTypes["AdBoardPublicReviewUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutArchivedByInput"]
};
	["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["AdBoardScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	address?: ResolverInputTypes["StringFilter"] | undefined | null,
	state?: ResolverInputTypes["StringFilter"] | undefined | null,
	city?: ResolverInputTypes["StringFilter"] | undefined | null,
	country?: ResolverInputTypes["StringFilter"] | undefined | null,
	outdoor?: ResolverInputTypes["BoolFilter"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	locationSubCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	alias?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	title?: ResolverInputTypes["StringFilter"] | undefined | null,
	pricePerDay?: ResolverInputTypes["IntFilter"] | undefined | null,
	originalPricePerDay?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adBoardTypeId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	tags?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	featuredImage?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	galleryImgs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	notes?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	impressionMultiplier?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	categoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	restrictedBusinessIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	targetAudiences?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumAdBoardStatusFilter"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["EnumAdboardDraftStatusNullableFilter"] | undefined | null,
	isLive?: ResolverInputTypes["BoolFilter"] | undefined | null,
	numberOfDisplays?: ResolverInputTypes["IntFilter"] | undefined | null,
	dailyOperationHours?: ResolverInputTypes["IntFilter"] | undefined | null,
	adsPerLoop?: ResolverInputTypes["IntFilter"] | undefined | null,
	adsDuration?: ResolverInputTypes["IntFilter"] | undefined | null,
	monthlyPrice?: ResolverInputTypes["FloatNullableFilter"] | undefined | null,
	offlineBookingsEnabled?: ResolverInputTypes["BoolFilter"] | undefined | null,
	offlineBookingContactId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	href?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null,
	playerIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	publisherScreenId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	additionalData?: ResolverInputTypes["JsonNullableFilter"] | undefined | null,
	isDraft?: ResolverInputTypes["BoolFilter"] | undefined | null,
	publishedVersionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	isArchived?: ResolverInputTypes["BoolFilter"] | undefined | null,
	archivedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	archivedByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null
};
	["UserUpdateToOneWithWhereWithoutReviewCommentsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardReviewCommentScalarWhereInput"],
	data: ResolverInputTypes["AdBoardReviewCommentUpdateManyMutationInput"]
};
	["AdBoardReviewCommentScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	comment?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["EnumReviewActionFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["AdBoardReviewCommentUpdateManyMutationInput"]: {
	comment?: string | undefined | null,
	action?: ResolverInputTypes["ReviewAction"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutPublishedVersionInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["AdBoardReviewCommentWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardReviewCommentUpdateWithoutUserInput"]
};
	["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]: {
	where: ResolverInputTypes["AdBoardReviewCommentScalarWhereInput"],
	data: ResolverInputTypes["AdBoardReviewCommentUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutPublishAdboardVersionInput"]
};
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ResolverInputTypes["AdBoardPublishHistoryScalarWhereInput"],
	data: ResolverInputTypes["AdBoardPublishHistoryUpdateManyMutationInput"]
};
	["AdBoardPublishHistoryScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	publishedByUserId?: ResolverInputTypes["StringFilter"] | undefined | null,
	changesSnapshot?: ResolverInputTypes["JsonFilter"] | undefined | null
};
	["AdBoardPublishHistoryUpdateManyMutationInput"]: {
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	changesSnapshot?: ResolverInputTypes["JSON"] | undefined | null
};
	["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutDraftAdboardInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutPlayersInput"]
};
	["AdBoardUpdateManyWithWhereWithoutPlayersInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["PlayerUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ResolverInputTypes["PlayerWhereInput"] | undefined | null,
	data: ResolverInputTypes["PlayerUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	data: ResolverInputTypes["CameraUpdateWithoutAdBoardInput"]
};
	["CameraUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ResolverInputTypes["CameraScalarWhereInput"],
	data: ResolverInputTypes["CameraUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"]
};
	["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	data: ResolverInputTypes["UserUpdateWithoutSharedBookingsInput"]
};
	["UserUpdateManyWithWhereWithoutSharedBookingsInput"]: {
	where: ResolverInputTypes["UserScalarWhereInput"],
	data: ResolverInputTypes["UserUpdateManyMutationInput"]
};
	["WebhookLogUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ResolverInputTypes["WebhookLogCreateWithoutBookingInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["WebhookLogCreateOrConnectWithoutBookingInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]> | undefined | null,
	createMany?: ResolverInputTypes["WebhookLogCreateManyBookingInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["WebhookLogWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["WebhookLogUpdateManyWithWhereWithoutBookingInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["WebhookLogScalarWhereInput"]> | undefined | null
};
	["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ResolverInputTypes["WebhookLogWhereUniqueInput"],
	update: ResolverInputTypes["WebhookLogUpdateWithoutBookingInput"],
	create: ResolverInputTypes["WebhookLogCreateWithoutBookingInput"]
};
	["WebhookLogUpdateWithoutBookingInput"]: {
	response?: ResolverInputTypes["JSON"] | undefined | null,
	request?: ResolverInputTypes["JSON"] | undefined | null,
	status?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutWebhookLogsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutWebhookLogsInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"] | undefined | null
};
	["WorkspaceUpsertWithoutWebhookLogsInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutWebhookLogsInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutWebhookLogsInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutWebhookLogsInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutWebhookLogsInput"]
};
	["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ResolverInputTypes["WebhookLogWhereUniqueInput"],
	data: ResolverInputTypes["WebhookLogUpdateWithoutBookingInput"]
};
	["WebhookLogUpdateManyWithWhereWithoutBookingInput"]: {
	where: ResolverInputTypes["WebhookLogScalarWhereInput"],
	data: ResolverInputTypes["WebhookLogUpdateManyMutationInput"]
};
	["BookingUpdateToOneWithWhereWithoutPayoutsInput"]: {
	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	data: ResolverInputTypes["BookingUpdateWithoutPayoutsInput"]
};
	["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]: {
	where: ResolverInputTypes["PayoutWhereUniqueInput"],
	data: ResolverInputTypes["PayoutUpdateWithoutTransactionInput"]
};
	["PayoutUpdateManyWithWhereWithoutTransactionInput"]: {
	where: ResolverInputTypes["PayoutScalarWhereInput"],
	data: ResolverInputTypes["PayoutUpdateManyMutationInput"]
};
	["PayoutScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["PayoutScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PayoutScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PayoutScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	totalAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	commissionRate?: ResolverInputTypes["FloatFilter"] | undefined | null,
	commissionAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	netAmount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumPayoutStatusFilter"] | undefined | null,
	transactionId?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["PayoutUpdateManyMutationInput"]: {
	totalAmount?: number | undefined | null,
	commissionRate?: number | undefined | null,
	commissionAmount?: number | undefined | null,
	netAmount?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: ResolverInputTypes["PayoutStatus"] | undefined | null
};
	["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["TransactionWhereUniqueInput"],
	data: ResolverInputTypes["TransactionUpdateWithoutWorkspaceInput"]
};
	["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["TransactionScalarWhereInput"],
	data: ResolverInputTypes["TransactionUpdateManyMutationInput"]
};
	["TransactionScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["TransactionScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["TransactionScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["TransactionScalarWhereInput"]> | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	amount?: ResolverInputTypes["FloatFilter"] | undefined | null,
	createAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTimeNullableFilter"] | undefined | null,
	relatedEntityId?: ResolverInputTypes["StringFilter"] | undefined | null,
	transactionStatus?: ResolverInputTypes["EnumTransactionStatusFilter"] | undefined | null,
	details?: ResolverInputTypes["JsonNullableFilter"] | undefined | null
};
	["TransactionUpdateManyMutationInput"]: {
	amount?: number | undefined | null,
	createAt?: ResolverInputTypes["DateTime"] | undefined | null,
	processedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	relatedEntityId?: string | undefined | null,
	transactionStatus?: ResolverInputTypes["TransactionStatus"] | undefined | null,
	details?: ResolverInputTypes["JSON"] | undefined | null
};
	["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutCameraServersInput"]
};
	["CameraServerUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ResolverInputTypes["CameraServerWhereInput"] | undefined | null,
	data: ResolverInputTypes["CameraServerUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutPlayerInput"]: {
	where: ResolverInputTypes["CameraWhereUniqueInput"],
	data: ResolverInputTypes["CameraUpdateWithoutPlayerInput"]
};
	["CameraUpdateManyWithWhereWithoutPlayerInput"]: {
	where: ResolverInputTypes["CameraScalarWhereInput"],
	data: ResolverInputTypes["CameraUpdateManyMutationInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	data: ResolverInputTypes["PlayerUpdateWithoutWorkspaceInput"]
};
	["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["PlayerScalarWhereInput"],
	data: ResolverInputTypes["PlayerUpdateManyMutationInput"]
};
	["PlayerScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["PlayerScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlayerScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlayerScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	configLocation?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	pisignagePlayerId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	cpuSerialNumber?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adBoardGroupId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	playlistIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workpaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	signageTypeId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	audienceTypeId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	aspectRatioType?: ResolverInputTypes["EnumAspectRatioTypeFilter"] | undefined | null,
	customAspectRatioWidth?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	customAspectRatioHeight?: ResolverInputTypes["IntNullableFilter"] | undefined | null,
	isOnline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	adBoardIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["PlayerUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null
};
	["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutTransactionsInput"]
};
	["TransactionUpdateToOneWithWhereWithoutPayoutsInput"]: {
	where?: ResolverInputTypes["TransactionWhereInput"] | undefined | null,
	data: ResolverInputTypes["TransactionUpdateWithoutPayoutsInput"]
};
	["PayoutUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ResolverInputTypes["PayoutWhereUniqueInput"],
	data: ResolverInputTypes["PayoutUpdateWithoutBookingInput"]
};
	["PayoutUpdateManyWithWhereWithoutBookingInput"]: {
	where: ResolverInputTypes["PayoutScalarWhereInput"],
	data: ResolverInputTypes["PayoutUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutCampaignInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	data: ResolverInputTypes["BookingUpdateWithoutCampaignInput"]
};
	["BookingUpdateManyWithWhereWithoutCampaignInput"]: {
	where: ResolverInputTypes["BookingScalarWhereInput"],
	data: ResolverInputTypes["BookingUpdateManyMutationInput"]
};
	["BookingScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BookingScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	additionalInformation?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	assetsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingCode?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingDate?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingFor?: ResolverInputTypes["StringFilter"] | undefined | null,
	purpose?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	campaignId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	adCategoryId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingStatus?: ResolverInputTypes["EnumBookingStatusFilter"] | undefined | null,
	paymentStatus?: ResolverInputTypes["EnumPaymentStatusNullableFilter"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["EnumDeploymentStatusFilter"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["EnumContentApprovalStatusFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	sharedUserIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	isOffline?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isPaid?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isScheduled?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isDeployed?: ResolverInputTypes["BoolFilter"] | undefined | null,
	isCompleted?: ResolverInputTypes["BoolFilter"] | undefined | null
};
	["BookingUpdateManyMutationInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null
};
	["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	data: ResolverInputTypes["CampaignUpdateWithoutAssetsInput"]
};
	["CampaignUpdateManyWithWhereWithoutAssetsInput"]: {
	where: ResolverInputTypes["CampaignScalarWhereInput"],
	data: ResolverInputTypes["CampaignUpdateManyMutationInput"]
};
	["CampaignScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["CampaignScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["CampaignScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["CampaignScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	bookingFor?: ResolverInputTypes["StringFilter"] | undefined | null,
	purpose?: ResolverInputTypes["StringFilter"] | undefined | null,
	businessCategoryId?: ResolverInputTypes["StringFilter"] | undefined | null,
	adCategoryId?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	paymentStatus?: ResolverInputTypes["EnumPaymentStatusFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringFilter"] | undefined | null,
	assetsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["CampaignUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	origin?: string | undefined | null
};
	["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	data: ResolverInputTypes["FileUpdateWithoutWorkspaceInput"]
};
	["FileUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["FileScalarWhereInput"],
	data: ResolverInputTypes["FileUpdateManyMutationInput"]
};
	["FileScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["FileScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	contentType?: ResolverInputTypes["StringFilter"] | undefined | null,
	ctime?: ResolverInputTypes["StringFilter"] | undefined | null,
	filename?: ResolverInputTypes["StringFilter"] | undefined | null,
	filepath?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isDirectory?: ResolverInputTypes["BoolFilter"] | undefined | null,
	mtime?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	size?: ResolverInputTypes["IntFilter"] | undefined | null,
	status?: ResolverInputTypes["EnumFileStatusNullableFilter"] | undefined | null,
	type?: ResolverInputTypes["StringFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringFilter"] | undefined | null,
	url?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	proofBookingId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	campaignIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["FileUpdateManyMutationInput"]: {
	contentType?: string | undefined | null,
	ctime?: string | undefined | null,
	filename?: string | undefined | null,
	filepath?: string | undefined | null,
	isDirectory?: boolean | undefined | null,
	meta?: ResolverInputTypes["FileMetaCreateInput"] | undefined | null,
	mtime?: string | undefined | null,
	name?: string | undefined | null,
	size?: number | undefined | null,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url?: string | undefined | null
};
	["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutBookingsInput"]
};
	["BookingUpdateToOneWithWhereWithoutProofsInput"]: {
	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	data: ResolverInputTypes["BookingUpdateWithoutProofsInput"]
};
	["FileUpdateWithWhereUniqueWithoutCampaignsInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	data: ResolverInputTypes["FileUpdateWithoutCampaignsInput"]
};
	["FileUpdateManyWithWhereWithoutCampaignsInput"]: {
	where: ResolverInputTypes["FileScalarWhereInput"],
	data: ResolverInputTypes["FileUpdateManyMutationInput"]
};
	["CampaignUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ResolverInputTypes["CampaignWhereInput"] | undefined | null,
	data: ResolverInputTypes["CampaignUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	data: ResolverInputTypes["BookingUpdateWithoutWorkspaceInput"]
};
	["BookingUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["BookingScalarWhereInput"],
	data: ResolverInputTypes["BookingUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"]: {
	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardGroupUpdateWithoutAdBoardInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutHistoryInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutHistoryInput"]
};
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]: {
	where: ResolverInputTypes["AdBoardPublishHistoryWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]: {
	where: ResolverInputTypes["AdBoardPublishHistoryScalarWhereInput"],
	data: ResolverInputTypes["AdBoardPublishHistoryUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutAuditsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutAuditsInput"]
};
	["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AuditWhereUniqueInput"],
	data: ResolverInputTypes["AuditUpdateWithoutWorkspaceInput"]
};
	["AuditUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AuditScalarWhereInput"],
	data: ResolverInputTypes["AuditUpdateManyMutationInput"]
};
	["AuditScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["AuditScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AuditScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AuditScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	message?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["StringFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	updates?: ResolverInputTypes["JsonNullableFilter"] | undefined | null
};
	["AuditUpdateManyMutationInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message?: string | undefined | null,
	action?: string | undefined | null,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null
};
	["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutPlayersInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	data: ResolverInputTypes["PlayerUpdateWithoutAdBoardGroupInput"]
};
	["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]: {
	where: ResolverInputTypes["PlayerScalarWhereInput"],
	data: ResolverInputTypes["PlayerUpdateManyMutationInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]: {
	where: ResolverInputTypes["AdBoardGroupScalarWhereInput"],
	data: ResolverInputTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["AdBoardGroupScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdBoardGroupScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdBoardGroupScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdBoardGroupScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	scheduledPlaylistsIDs?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	adsPlaylistID?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	pisignageGroupId?: ResolverInputTypes["StringFilter"] | undefined | null,
	fitImage?: ResolverInputTypes["EnumFitTypeFilter"] | undefined | null,
	fitVideo?: ResolverInputTypes["EnumFitTypeFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["AdBoardGroupUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId?: string | undefined | null,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null
};
	["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"]: {
	create?: Array<ResolverInputTypes["PlaylistCreateWithoutFooterPlaylistInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PlaylistScalarWhereInput"]> | undefined | null
};
	["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	update: ResolverInputTypes["PlaylistUpdateWithoutFooterPlaylistInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutFooterPlaylistInput"]
};
	["PlaylistUpdateWithoutFooterPlaylistInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null
};
	["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"]: {
	create?: Array<ResolverInputTypes["PlaylistCreateWithoutSidebarPlaylistInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]> | undefined | null,
	createMany?: ResolverInputTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["PlaylistWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["PlaylistScalarWhereInput"]> | undefined | null
};
	["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	update: ResolverInputTypes["PlaylistUpdateWithoutSidebarPlaylistInput"],
	create: ResolverInputTypes["PlaylistCreateWithoutSidebarPlaylistInput"]
};
	["PlaylistUpdateWithoutSidebarPlaylistInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null
};
	["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	data: ResolverInputTypes["PlaylistUpdateWithoutSidebarPlaylistInput"]
};
	["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]: {
	where: ResolverInputTypes["PlaylistScalarWhereInput"],
	data: ResolverInputTypes["PlaylistUpdateManyMutationInput"]
};
	["PlaylistScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["PlaylistScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlaylistScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlaylistScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	displayName?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	isAdsPlaylist?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	footerPlaylistId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	sidebarPlaylistId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	layout?: ResolverInputTypes["EnumScreenLayoutFilter"] | undefined | null,
	isDeployed?: ResolverInputTypes["BoolNullableFilter"] | undefined | null,
	adBoardGroupIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	workspaceId?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["PlaylistUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null
};
	["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	data: ResolverInputTypes["PlaylistUpdateWithoutFooterPlaylistInput"]
};
	["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]: {
	where: ResolverInputTypes["PlaylistScalarWhereInput"],
	data: ResolverInputTypes["PlaylistUpdateManyMutationInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"]: {
	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	data: ResolverInputTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardGroupUpdateWithoutWorkspaceInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AdBoardGroupScalarWhereInput"],
	data: ResolverInputTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutPlaylistsInput"]
};
	["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	data: ResolverInputTypes["PlaylistUpdateWithoutAdBoardGroupsInput"]
};
	["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]: {
	where: ResolverInputTypes["PlaylistScalarWhereInput"],
	data: ResolverInputTypes["PlaylistUpdateManyMutationInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]: {
	where: ResolverInputTypes["AdBoardGroupWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]: {
	where: ResolverInputTypes["AdBoardGroupScalarWhereInput"],
	data: ResolverInputTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"]: {
	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	data: ResolverInputTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"]: {
	where?: ResolverInputTypes["PlaylistWhereInput"] | undefined | null,
	data: ResolverInputTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"]
};
	["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["PlaylistWhereUniqueInput"],
	data: ResolverInputTypes["PlaylistUpdateWithoutWorkspaceInput"]
};
	["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["PlaylistScalarWhereInput"],
	data: ResolverInputTypes["PlaylistUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutAdBoardGroupInput"]
};
	["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"]: {
	where?: ResolverInputTypes["AdBoardGroupWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["PlayerWhereUniqueInput"],
	data: ResolverInputTypes["PlayerUpdateWithoutAdBoardsInput"]
};
	["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]: {
	where: ResolverInputTypes["PlayerScalarWhereInput"],
	data: ResolverInputTypes["PlayerUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutWorkspaceInput"]
};
	["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutMembershipsInput"]
};
	["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceRoleUpsertWithoutMembershipsInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceRoleWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"] | undefined | null
};
	["WorkspaceRoleUpsertWithoutMembershipsInput"]: {
	update: ResolverInputTypes["WorkspaceRoleUpdateWithoutMembershipsInput"],
	create: ResolverInputTypes["WorkspaceRoleCreateWithoutMembershipsInput"],
	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null
};
	["WorkspaceRoleUpdateWithoutMembershipsInput"]: {
	name?: string | undefined | null,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutWorkspaceRolesInput"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"] | undefined | null
};
	["WorkspaceUpsertWithoutWorkspaceRolesInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutWorkspaceRolesInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutWorkspaceRolesInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"]
};
	["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ResolverInputTypes["WorkspaceRoleWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceRoleUpdateWithoutMembershipsInput"]
};
	["MembershipUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	data: ResolverInputTypes["MembershipUpdateWithoutUserInput"]
};
	["MembershipUpdateManyWithWhereWithoutUserInput"]: {
	where: ResolverInputTypes["MembershipScalarWhereInput"],
	data: ResolverInputTypes["MembershipUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutChangelogsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutChangelogsInput"]
};
	["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ResolverInputTypes["ChangelogWhereUniqueInput"],
	data: ResolverInputTypes["ChangelogUpdateWithoutBookingInput"]
};
	["ChangelogUpdateManyWithWhereWithoutBookingInput"]: {
	where: ResolverInputTypes["ChangelogScalarWhereInput"],
	data: ResolverInputTypes["ChangelogUpdateManyMutationInput"]
};
	["ChangelogScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["ChangelogScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["ChangelogScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["ChangelogScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	message?: ResolverInputTypes["StringFilter"] | undefined | null,
	action?: ResolverInputTypes["StringFilter"] | undefined | null,
	bookingId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	userId?: ResolverInputTypes["StringFilter"] | undefined | null,
	updates?: ResolverInputTypes["JsonNullableFilter"] | undefined | null
};
	["ChangelogUpdateManyMutationInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message?: string | undefined | null,
	action?: string | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null
};
	["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	data: ResolverInputTypes["BookingUpdateWithoutSharedUsersInput"]
};
	["BookingUpdateManyWithWhereWithoutSharedUsersInput"]: {
	where: ResolverInputTypes["BookingScalarWhereInput"],
	data: ResolverInputTypes["BookingUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	data: ResolverInputTypes["BookingUpdateWithoutAdCategoryInput"]
};
	["BookingUpdateManyWithWhereWithoutAdCategoryInput"]: {
	where: ResolverInputTypes["BookingScalarWhereInput"],
	data: ResolverInputTypes["BookingUpdateManyMutationInput"]
};
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]: {
	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"],
	data: ResolverInputTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"]
};
	["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]: {
	where: ResolverInputTypes["AdvertisementCategoryScalarWhereInput"],
	data: ResolverInputTypes["AdvertisementCategoryUpdateManyMutationInput"]
};
	["AdvertisementCategoryScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["AdvertisementCategoryScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["EnumAdvertisementCategoryEnumFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	campaignIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null,
	bookingIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["AdvertisementCategoryUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	type?: ResolverInputTypes["AdvertisementCategoryEnum"] | undefined | null,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["CampaignUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	data: ResolverInputTypes["CampaignUpdateWithoutUserInput"]
};
	["CampaignUpdateManyWithWhereWithoutUserInput"]: {
	where: ResolverInputTypes["CampaignScalarWhereInput"],
	data: ResolverInputTypes["CampaignUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutMembershipsInput"]
};
	["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["MembershipWhereUniqueInput"],
	data: ResolverInputTypes["MembershipUpdateWithoutWorkspaceInput"]
};
	["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ResolverInputTypes["MembershipScalarWhereInput"],
	data: ResolverInputTypes["MembershipUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutAuditsInput"]
};
	["AuditUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["AuditWhereUniqueInput"],
	data: ResolverInputTypes["AuditUpdateWithoutUserInput"]
};
	["AuditUpdateManyWithWhereWithoutUserInput"]: {
	where: ResolverInputTypes["AuditScalarWhereInput"],
	data: ResolverInputTypes["AuditUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOrdersInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutOrdersInput"]
};
	["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["OrderWhereUniqueInput"],
	data: ResolverInputTypes["OrderUpdateWithoutAdBoardInput"]
};
	["OrderUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ResolverInputTypes["OrderScalarWhereInput"],
	data: ResolverInputTypes["OrderUpdateManyMutationInput"]
};
	["OrderScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["OrderScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["OrderScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["OrderScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	adBoardId?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdDate?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdByUserId?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	origin?: ResolverInputTypes["StringFilter"] | undefined | null,
	paymentId?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["OrderUpdateManyMutationInput"]: {
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	origin?: string | undefined | null,
	paymentId?: string | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDate?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null
};
	["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutCreatedByInput"]
};
	["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutBookingsInput"]
};
	["BookingUpdateToOneWithWhereWithoutChangelogsInput"]: {
	where?: ResolverInputTypes["BookingWhereInput"] | undefined | null,
	data: ResolverInputTypes["BookingUpdateWithoutChangelogsInput"]
};
	["ChangelogUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["ChangelogWhereUniqueInput"],
	data: ResolverInputTypes["ChangelogUpdateWithoutUserInput"]
};
	["ChangelogUpdateManyWithWhereWithoutUserInput"]: {
	where: ResolverInputTypes["ChangelogScalarWhereInput"],
	data: ResolverInputTypes["ChangelogUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutOfflineBookingAdboardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutOrdersInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutOrdersInput"]
};
	["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ResolverInputTypes["OrderWhereUniqueInput"],
	data: ResolverInputTypes["OrderUpdateWithoutCreatedByInput"]
};
	["OrderUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ResolverInputTypes["OrderScalarWhereInput"],
	data: ResolverInputTypes["OrderUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutOwnedWorkspacesInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutFilesInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	data: ResolverInputTypes["FileUpdateWithoutUpdatedByInput"]
};
	["FileUpdateManyWithWhereWithoutUpdatedByInput"]: {
	where: ResolverInputTypes["FileScalarWhereInput"],
	data: ResolverInputTypes["FileUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutCreatedFilesInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutCreatedFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutProofBookingInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	data: ResolverInputTypes["FileUpdateWithoutProofBookingInput"]
};
	["FileUpdateManyWithWhereWithoutProofBookingInput"]: {
	where: ResolverInputTypes["FileScalarWhereInput"],
	data: ResolverInputTypes["FileUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	data: ResolverInputTypes["BookingUpdateWithoutBusinessCategoryInput"]
};
	["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]: {
	where: ResolverInputTypes["BookingScalarWhereInput"],
	data: ResolverInputTypes["BookingUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"]: {
	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	data: ResolverInputTypes["BusinessCategoryUpdateWithoutCampaignsInput"]
};
	["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	data: ResolverInputTypes["CampaignUpdateWithoutAdCategoryInput"]
};
	["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]: {
	where: ResolverInputTypes["CampaignScalarWhereInput"],
	data: ResolverInputTypes["CampaignUpdateManyMutationInput"]
};
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]: {
	where: ResolverInputTypes["AdvertisementCategoryWhereUniqueInput"],
	data: ResolverInputTypes["AdvertisementCategoryUpdateWithoutBookingsInput"]
};
	["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]: {
	where: ResolverInputTypes["AdvertisementCategoryScalarWhereInput"],
	data: ResolverInputTypes["AdvertisementCategoryUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAssetsInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	data: ResolverInputTypes["BookingUpdateWithoutAssetsInput"]
};
	["BookingUpdateManyWithWhereWithoutAssetsInput"]: {
	where: ResolverInputTypes["BookingScalarWhereInput"],
	data: ResolverInputTypes["BookingUpdateManyMutationInput"]
};
	["FileUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	data: ResolverInputTypes["FileUpdateWithoutCreatedByInput"]
};
	["FileUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ResolverInputTypes["FileScalarWhereInput"],
	data: ResolverInputTypes["FileUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutCampaignsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutCampaignsInput"]
};
	["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ResolverInputTypes["CampaignWhereUniqueInput"],
	data: ResolverInputTypes["CampaignUpdateWithoutBusinessCategoryInput"]
};
	["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]: {
	where: ResolverInputTypes["CampaignScalarWhereInput"],
	data: ResolverInputTypes["CampaignUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	data: ResolverInputTypes["BusinessCategoryUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	data: ResolverInputTypes["BookingUpdateWithoutUserInput"]
};
	["BookingUpdateManyWithWhereWithoutUserInput"]: {
	where: ResolverInputTypes["BookingScalarWhereInput"],
	data: ResolverInputTypes["BookingUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutUpdatedFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutBookingsInput"]: {
	where: ResolverInputTypes["FileWhereUniqueInput"],
	data: ResolverInputTypes["FileUpdateWithoutBookingsInput"]
};
	["FileUpdateManyWithWhereWithoutBookingsInput"]: {
	where: ResolverInputTypes["FileScalarWhereInput"],
	data: ResolverInputTypes["FileUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ResolverInputTypes["BookingWhereUniqueInput"],
	data: ResolverInputTypes["BookingUpdateWithoutAdBoardInput"]
};
	["BookingUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ResolverInputTypes["BookingScalarWhereInput"],
	data: ResolverInputTypes["BookingUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutCategoryInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: {
	where: ResolverInputTypes["BusinessCategoryWhereUniqueInput"],
	data: ResolverInputTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"]
};
	["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]: {
	where: ResolverInputTypes["BusinessCategoryScalarWhereInput"],
	data: ResolverInputTypes["BusinessCategoryUpdateManyMutationInput"]
};
	["BusinessCategoryScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["BusinessCategoryScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BusinessCategoryScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BusinessCategoryScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	description?: ResolverInputTypes["StringNullableFilter"] | undefined | null,
	restrictedByAdBoardIds?: ResolverInputTypes["StringNullableListFilter"] | undefined | null
};
	["BusinessCategoryUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null
};
	["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutLocationCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"]: {
	where?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null,
	data: ResolverInputTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"]
};
	["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ResolverInputTypes["LocationSubCategoryWhereInput"] | undefined | null,
	data: ResolverInputTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutRestrictedBusinesInput"]
};
	["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ResolverInputTypes["BusinessCategoryWhereInput"] | undefined | null,
	data: ResolverInputTypes["BusinessCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutLocationSubCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ResolverInputTypes["LocationSubCategoryWhereUniqueInput"],
	data: ResolverInputTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]: {
	where: ResolverInputTypes["LocationSubCategoryScalarWhereInput"],
	data: ResolverInputTypes["LocationSubCategoryUpdateManyMutationInput"]
};
	["LocationSubCategoryScalarWhereInput"]: {
	AND?: Array<ResolverInputTypes["LocationSubCategoryScalarWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["LocationSubCategoryScalarWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["LocationSubCategoryScalarWhereInput"]> | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	locationCategoryId?: ResolverInputTypes["StringNullableFilter"] | undefined | null
};
	["LocationSubCategoryUpdateManyMutationInput"]: {
	name?: string | undefined | null
};
	["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ResolverInputTypes["LocationCategoryWhereInput"] | undefined | null,
	data: ResolverInputTypes["LocationCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardGroupCreateManyInput"]: {
	id?: string | undefined | null,
	name: string,
	adBoardId?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	scheduledPlaylistsIDs?: Array<string> | undefined | null,
	adsPlaylistID?: string | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	workspaceId: string
};
	["AdBoardGroupCreateInput"]: {
	id?: string | undefined | null,
	name: string,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId: string,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupUpdateInput"]: {
	name?: string | undefined | null,
	schedules?: Array<ResolverInputTypes["ScheduleCreateInput"]> | undefined | null,
	pisignageGroupId?: string | undefined | null,
	fitImage?: ResolverInputTypes["FitType"] | undefined | null,
	fitVideo?: ResolverInputTypes["FitType"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined | null,
	scheduledPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined | null,
	adsPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined | null,
	pisignagePlayers?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined | null
};
	["AdBoardTypeCreateManyInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null
};
	["AdBoardTypeCreateInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutTypeInput"] | undefined | null
};
	["AdBoardCreateNestedManyWithoutTypeInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutTypeInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutTypeInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyTypeInputEnvelope"] | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null
};
	["AdBoardCreateWithoutTypeInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardCreateOrConnectWithoutTypeInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutTypeInput"]
};
	["AdBoardCreateManyTypeInputEnvelope"]: {
	data: Array<ResolverInputTypes["AdBoardCreateManyTypeInput"]>
};
	["AdBoardCreateManyTypeInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	locationCategoryId?: string | undefined | null,
	locationSubCategoryId?: string | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	categoryId?: string | undefined | null,
	restrictedBusinessIds?: Array<string> | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	offlineBookingContactId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	href?: string | undefined | null,
	workspaceId: string,
	playerIds?: Array<string> | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	publishedVersionId?: string | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	archivedByUserId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["AdBoardTypeUpdateManyMutationInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null
};
	["AdBoardTypeUpdateInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutTypeNestedInput"] | undefined | null
};
	["AdBoardUpdateManyWithoutTypeNestedInput"]: {
	create?: Array<ResolverInputTypes["AdBoardCreateWithoutTypeInput"]> | undefined | null,
	connectOrCreate?: Array<ResolverInputTypes["AdBoardCreateOrConnectWithoutTypeInput"]> | undefined | null,
	upsert?: Array<ResolverInputTypes["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]> | undefined | null,
	createMany?: ResolverInputTypes["AdBoardCreateManyTypeInputEnvelope"] | undefined | null,
	set?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	connect?: Array<ResolverInputTypes["AdBoardWhereUniqueInput"]> | undefined | null,
	update?: Array<ResolverInputTypes["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]> | undefined | null,
	updateMany?: Array<ResolverInputTypes["AdBoardUpdateManyWithWhereWithoutTypeInput"]> | undefined | null,
	deleteMany?: Array<ResolverInputTypes["AdBoardScalarWhereInput"]> | undefined | null
};
	["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	update: ResolverInputTypes["AdBoardUpdateWithoutTypeInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutTypeInput"]
};
	["AdBoardUpdateWithoutTypeInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	slotConfigurations?: ResolverInputTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	data: ResolverInputTypes["AdBoardUpdateWithoutTypeInput"]
};
	["AdBoardUpdateManyWithWhereWithoutTypeInput"]: {
	where: ResolverInputTypes["AdBoardScalarWhereInput"],
	data: ResolverInputTypes["AdBoardUpdateManyMutationInput"]
};
	["AdvertisementCategoryCreateManyInput"]: {
	id?: string | undefined | null,
	name: string,
	type: ResolverInputTypes["AdvertisementCategoryEnum"],
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	campaignIds?: Array<string> | undefined | null,
	bookingIds?: Array<string> | undefined | null
};
	["AdvertisementCategoryCreateInput"]: {
	id?: string | undefined | null,
	name: string,
	type: ResolverInputTypes["AdvertisementCategoryEnum"],
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutAdCategoryInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdCategoryInput"] | undefined | null
};
	["AdvertisementCategoryUpdateInput"]: {
	name?: string | undefined | null,
	type?: ResolverInputTypes["AdvertisementCategoryEnum"] | undefined | null,
	description?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdCategoryNestedInput"] | undefined | null
};
	["AuditCreateManyInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	userId: string,
	workspaceId?: string | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null
};
	["AuditCreateInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message: string,
	action: string,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutAuditsInput"],
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAuditsInput"] | undefined | null
};
	["AuditUpdateInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	message?: string | undefined | null,
	action?: string | undefined | null,
	entity?: ResolverInputTypes["EntityCreateInput"] | undefined | null,
	updates?: ResolverInputTypes["JSON"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"] | undefined | null
};
	["BookingCreateManyInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	assetsIDs?: Array<string> | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	adBoardId: string,
	workspaceId?: string | undefined | null,
	campaignId?: string | undefined | null,
	businessCategoryId?: string | undefined | null,
	adCategoryId?: Array<string> | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	userId: string,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	sharedUserIds?: Array<string> | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null
};
	["BookingCreateInput"]: {
	id?: string | undefined | null,
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode: string,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails: ResolverInputTypes["BookingDetailsCreateInput"],
	bookingStatus: ResolverInputTypes["BookingStatus"],
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus: ResolverInputTypes["DeploymentStatus"],
	contentApprovalStatus: ResolverInputTypes["ContentApprovalStatus"],
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined | null
};
	["BookingUpdateInput"]: {
	additionalInformation?: string | undefined | null,
	origin?: string | undefined | null,
	bookingCode?: string | undefined | null,
	bookingDate?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	bookingDetails?: ResolverInputTypes["BookingDetailsCreateInput"] | undefined | null,
	bookingStatus?: ResolverInputTypes["BookingStatus"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	deploymentStatus?: ResolverInputTypes["DeploymentStatus"] | undefined | null,
	contentApprovalStatus?: ResolverInputTypes["ContentApprovalStatus"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	pisignage?: ResolverInputTypes["BookingPisignageDataCreateInput"] | undefined | null,
	refund?: ResolverInputTypes["BookingRefundCreateInput"] | undefined | null,
	isOffline?: boolean | undefined | null,
	isPaid?: boolean | undefined | null,
	isScheduled?: boolean | undefined | null,
	isDeployed?: boolean | undefined | null,
	isCompleted?: boolean | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined | null,
	proofs?: ResolverInputTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	campaign?: ResolverInputTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined | null,
	payouts?: ResolverInputTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined | null,
	sharedUsers?: ResolverInputTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined | null,
	slotUsage?: ResolverInputTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined | null
};
	["BusinessCategoryCreateManyInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null,
	restrictedByAdBoardIds?: Array<string> | undefined | null
};
	["BusinessCategoryCreateInput"]: {
	id?: string | undefined | null,
	name: string,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined | null
};
	["BusinessCategoryUpdateInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined | null,
	restrictedByAdBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined | null
};
	["CameraCreateManyInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	playerId?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	adBoardId?: string | undefined | null
};
	["CameraCreateInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	cameraServer: ResolverInputTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: ResolverInputTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined | null
};
	["CameraUpdateInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	cameraUrl?: string | undefined | null,
	rtspUrl?: string | undefined | null,
	cameraIp?: string | undefined | null,
	connectedCpuSerialNumbers?: Array<string> | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isPublic?: boolean | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined | null,
	player?: ResolverInputTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined | null
};
	["CameraFeedCreateManyInput"]: {
	id?: string | undefined | null,
	cameraId: string,
	timestamp: ResolverInputTypes["DateTime"],
	cameraServerId: string,
	data: ResolverInputTypes["CameraFeedDataCreateInput"]
};
	["CameraFeedCreateInput"]: {
	id?: string | undefined | null,
	timestamp: ResolverInputTypes["DateTime"],
	data: ResolverInputTypes["CameraFeedDataCreateInput"],
	camera: ResolverInputTypes["CameraCreateNestedOneWithoutFeedsInput"],
	cameraServer: ResolverInputTypes["CameraServerCreateNestedOneWithoutFeedsInput"]
};
	["CameraFeedUpdateInput"]: {
	timestamp?: ResolverInputTypes["DateTime"] | undefined | null,
	data?: ResolverInputTypes["CameraFeedDataCreateInput"] | undefined | null,
	camera?: ResolverInputTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"] | undefined | null,
	cameraServer?: ResolverInputTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"] | undefined | null
};
	["CameraServerCreateManyInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId: string,
	workspaceId?: string | undefined | null,
	publicIp: string,
	privateIp: string
};
	["CameraServerCreateInput"]: {
	id?: string | undefined | null,
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined | null
};
	["CameraServerUpdateInput"]: {
	name?: string | undefined | null,
	isOnline?: boolean | undefined | null,
	hostName?: string | undefined | null,
	userName?: string | undefined | null,
	deviceId?: string | undefined | null,
	publicIp?: string | undefined | null,
	privateIp?: string | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined | null,
	feeds?: ResolverInputTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined | null
};
	["CampaignCreateManyInput"]: {
	id?: string | undefined | null,
	name: string,
	userId: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	businessCategoryId: string,
	adCategoryId?: Array<string> | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined | null
};
	["CampaignCreateInput"]: {
	id?: string | undefined | null,
	name: string,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus: ResolverInputTypes["PaymentStatus"],
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates: ResolverInputTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ResolverInputTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ResolverInputTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined | null,
	assets?: ResolverInputTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined | null
};
	["CampaignUpdateInput"]: {
	name?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	bookingFor?: string | undefined | null,
	purpose?: string | undefined | null,
	payment?: ResolverInputTypes["BookingPaymentCreateInput"] | undefined | null,
	paymentStatus?: ResolverInputTypes["PaymentStatus"] | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDates?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	origin?: string | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	businessCategory?: ResolverInputTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined | null,
	adCategory?: ResolverInputTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	payments?: ResolverInputTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined | null,
	assets?: ResolverInputTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined | null
};
	["FileCreateManyInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedByUserId?: string | undefined | null,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined | null,
	workspaceId?: string | undefined | null,
	proofBookingId?: string | undefined | null,
	campaignIds?: Array<string> | undefined | null
};
	["FileCreateInput"]: {
	id?: string | undefined | null,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined | null,
	isDirectory: boolean,
	meta: ResolverInputTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type: string,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url: string,
	updatedBy?: ResolverInputTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined | null,
	createdBy: ResolverInputTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined | null
};
	["FileUpdateInput"]: {
	contentType?: string | undefined | null,
	ctime?: string | undefined | null,
	filename?: string | undefined | null,
	filepath?: string | undefined | null,
	isDirectory?: boolean | undefined | null,
	meta?: ResolverInputTypes["FileMetaCreateInput"] | undefined | null,
	mtime?: string | undefined | null,
	name?: string | undefined | null,
	size?: number | undefined | null,
	status?: ResolverInputTypes["FileStatus"] | undefined | null,
	type?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	url?: string | undefined | null,
	updatedBy?: ResolverInputTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined | null,
	proofBooking?: ResolverInputTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined | null
};
	["MembershipCreateManyInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	userId: string,
	workspaceId: string,
	roleId: string
};
	["MembershipCreateInput"]: {
	id?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutMembershipsInput"],
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"],
	role: ResolverInputTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["MembershipUpdateInput"]: {
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null,
	role?: ResolverInputTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined | null
};
	["NotificationCreateManyInput"]: {
	id?: string | undefined | null,
	type: ResolverInputTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ResolverInputTypes["JSON"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	readAt?: ResolverInputTypes["DateTime"] | undefined | null,
	recipientId: string,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null
};
	["NotificationCreateInput"]: {
	id?: string | undefined | null,
	type: ResolverInputTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ResolverInputTypes["JSON"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	readAt?: ResolverInputTypes["DateTime"] | undefined | null,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null,
	recipient: ResolverInputTypes["UserCreateNestedOneWithoutNotificationsInput"]
};
	["UserCreateNestedOneWithoutNotificationsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutNotificationsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutNotificationsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutNotificationsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["UserCreateOrConnectWithoutNotificationsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutNotificationsInput"]
};
	["NotificationUpdateInput"]: {
	type?: ResolverInputTypes["NotificationType"] | undefined | null,
	title?: string | undefined | null,
	content?: string | undefined | null,
	metadata?: ResolverInputTypes["JSON"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	readAt?: ResolverInputTypes["DateTime"] | undefined | null,
	channels?: Array<ResolverInputTypes["NotificationChannel"]> | undefined | null,
	recipient?: ResolverInputTypes["UserUpdateOneRequiredWithoutNotificationsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutNotificationsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutNotificationsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutNotificationsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutNotificationsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutNotificationsInput"] | undefined | null
};
	["UserUpsertWithoutNotificationsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutNotificationsInput"],
	create: ResolverInputTypes["UserCreateWithoutNotificationsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutNotificationsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["UserUpdateToOneWithWhereWithoutNotificationsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutNotificationsInput"]
};
	["OrderCreateManyInput"]: {
	id?: string | undefined | null,
	adBoardId: string,
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	createdByUserId?: string | undefined | null,
	origin: string,
	paymentId?: string | undefined | null,
	priceSummary: ResolverInputTypes["PriceSummaryCreateInput"],
	selectedDate: ResolverInputTypes["OrderSelectedDateCreateInput"]
};
	["OrderCreateInput"]: {
	id?: string | undefined | null,
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	origin: string,
	paymentId?: string | undefined | null,
	priceSummary: ResolverInputTypes["PriceSummaryCreateInput"],
	selectedDate: ResolverInputTypes["OrderSelectedDateCreateInput"],
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutOrdersInput"],
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutOrdersInput"] | undefined | null
};
	["OrderUpdateInput"]: {
	createdDate?: ResolverInputTypes["DateTime"] | undefined | null,
	origin?: string | undefined | null,
	paymentId?: string | undefined | null,
	priceSummary?: ResolverInputTypes["PriceSummaryCreateInput"] | undefined | null,
	selectedDate?: ResolverInputTypes["OrderSelectedDateCreateInput"] | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutOrdersNestedInput"] | undefined | null
};
	["PlayerCreateManyInput"]: {
	id?: string | undefined | null,
	name: string,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	adBoardGroupId?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	workpaceId?: string | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	signageTypeId?: string | undefined | null,
	audienceTypeId?: Array<string> | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardIds?: Array<string> | undefined | null
};
	["PlayerCreateInput"]: {
	id?: string | undefined | null,
	name: string,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined | null
};
	["PlayerUpdateInput"]: {
	name?: string | undefined | null,
	configLocation?: string | undefined | null,
	pisignagePlayerId?: string | undefined | null,
	pisignageGroupId?: string | undefined | null,
	cpuSerialNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	playlistIds?: Array<string> | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	playbackDetails?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	aspectRatioType?: ResolverInputTypes["AspectRatioType"] | undefined | null,
	galleryImgs?: Array<ResolverInputTypes["GalleryFileCreateInput"]> | undefined | null,
	customAspectRatioWidth?: number | undefined | null,
	customAspectRatioHeight?: number | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	isOnline?: boolean | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	status?: ResolverInputTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined | null,
	signageType?: ResolverInputTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined | null,
	audienceType?: ResolverInputTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined | null
};
	["PlaylistCreateManyInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	footerPlaylistId?: string | undefined | null,
	sidebarPlaylistId?: string | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	adBoardGroupIds?: Array<string> | undefined | null,
	workspaceId: string
};
	["PlaylistCreateInput"]: {
	id?: string | undefined | null,
	name: string,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined | null
};
	["PlaylistUpdateInput"]: {
	name?: string | undefined | null,
	displayName?: string | undefined | null,
	assets?: Array<ResolverInputTypes["PisignageFileCreateInput"]> | undefined | null,
	isAdsPlaylist?: boolean | undefined | null,
	layout?: ResolverInputTypes["ScreenLayout"] | undefined | null,
	isDeployed?: boolean | undefined | null,
	footerPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined | null,
	sidebarPlaylist?: ResolverInputTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined | null,
	adBoardGroupsForAds?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined | null,
	footerParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined | null,
	sidebarParentPlaylists?: ResolverInputTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined | null
};
	["SlotConfigurationCreateManyInput"]: {
	id?: string | undefined | null,
	adBoardId: string,
	date: ResolverInputTypes["DateTime"],
	availableSlots: number
};
	["SlotConfigurationCreateInput"]: {
	id?: string | undefined | null,
	date: ResolverInputTypes["DateTime"],
	availableSlots: number,
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"]
};
	["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null
};
	["AdBoardCreateWithoutSlotConfigurationsInput"]: {
	id?: string | undefined | null,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ResolverInputTypes["MapCreateInput"],
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status: ResolverInputTypes["AdBoardStatus"],
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined | null,
	workspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined | null
};
	["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"]: {
	where: ResolverInputTypes["AdBoardWhereUniqueInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutSlotConfigurationsInput"]
};
	["SlotConfigurationUpdateInput"]: {
	date?: ResolverInputTypes["DateTime"] | undefined | null,
	availableSlots?: number | undefined | null,
	adBoard?: ResolverInputTypes["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"] | undefined | null
};
	["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"]: {
	create?: ResolverInputTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"] | undefined | null,
	upsert?: ResolverInputTypes["AdBoardUpsertWithoutSlotConfigurationsInput"] | undefined | null,
	connect?: ResolverInputTypes["AdBoardWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"] | undefined | null
};
	["AdBoardUpsertWithoutSlotConfigurationsInput"]: {
	update: ResolverInputTypes["AdBoardUpdateWithoutSlotConfigurationsInput"],
	create: ResolverInputTypes["AdBoardCreateWithoutSlotConfigurationsInput"],
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null
};
	["AdBoardUpdateWithoutSlotConfigurationsInput"]: {
	address?: string | undefined | null,
	state?: string | undefined | null,
	city?: string | undefined | null,
	country?: string | undefined | null,
	map?: ResolverInputTypes["MapCreateInput"] | undefined | null,
	outdoor?: boolean | undefined | null,
	alias?: string | undefined | null,
	title?: string | undefined | null,
	pricePerDay?: number | undefined | null,
	originalPricePerDay?: number | undefined | null,
	description?: string | undefined | null,
	aboutSpecifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	tags?: Array<string> | undefined | null,
	featuredImage?: string | undefined | null,
	galleryImgs?: Array<string> | undefined | null,
	specifications?: Array<ResolverInputTypes["SpecificationCreateInput"]> | undefined | null,
	notes?: Array<string> | undefined | null,
	impressionMultiplier?: number | undefined | null,
	resolution?: ResolverInputTypes["ResolutionCreateInput"] | undefined | null,
	screenSize?: ResolverInputTypes["ScreenSizeCreateInput"] | undefined | null,
	targetAudiences?: Array<string> | undefined | null,
	playbackTimes?: Array<ResolverInputTypes["PlaybackTimeCreateInput"]> | undefined | null,
	status?: ResolverInputTypes["AdBoardStatus"] | undefined | null,
	adboardDraftStatus?: ResolverInputTypes["AdboardDraftStatus"] | undefined | null,
	isLive?: boolean | undefined | null,
	webhooks?: Array<ResolverInputTypes["WebhookCreateInput"]> | undefined | null,
	numberOfDisplays?: number | undefined | null,
	dailyOperationHours?: number | undefined | null,
	adsPerLoop?: number | undefined | null,
	adsDuration?: number | undefined | null,
	monthlyPrice?: number | undefined | null,
	offlineBookingsEnabled?: boolean | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	href?: string | undefined | null,
	publisherScreenId?: string | undefined | null,
	additionalData?: ResolverInputTypes["JSON"] | undefined | null,
	isDraft?: boolean | undefined | null,
	lastPublishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	isArchived?: boolean | undefined | null,
	archivedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	locationCategory?: ResolverInputTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	locationSubCategory?: ResolverInputTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	type?: ResolverInputTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	category?: ResolverInputTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	restrictedBusines?: ResolverInputTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	offlineBookingsContact?: ResolverInputTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined | null,
	createdBy?: ResolverInputTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined | null,
	adBoardGroup?: ResolverInputTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined | null,
	cameras?: ResolverInputTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	publishedVersion?: ResolverInputTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined | null,
	draftAdboard?: ResolverInputTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined | null,
	history?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined | null,
	archivedBy?: ResolverInputTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined | null
};
	["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"]: {
	where?: ResolverInputTypes["AdBoardWhereInput"] | undefined | null,
	data: ResolverInputTypes["AdBoardUpdateWithoutSlotConfigurationsInput"]
};
	["SubscriptionCreateManyInput"]: {
	id?: string | undefined | null,
	packageId?: string | undefined | null,
	packageName?: string | undefined | null,
	priceLookupKey?: string | undefined | null,
	startDate?: ResolverInputTypes["DateTime"] | undefined | null,
	endDate?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: string | undefined | null,
	stripePaymentIntentId?: string | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	stripeCheckoutSessionId?: string | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsCreateInput"] | undefined | null,
	activeWorkspaceId: string
};
	["SubscriptionCreateInput"]: {
	id?: string | undefined | null,
	packageId?: string | undefined | null,
	packageName?: string | undefined | null,
	priceLookupKey?: string | undefined | null,
	startDate?: ResolverInputTypes["DateTime"] | undefined | null,
	endDate?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: string | undefined | null,
	stripePaymentIntentId?: string | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	stripeCheckoutSessionId?: string | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsCreateInput"] | undefined | null,
	activeWorkspace: ResolverInputTypes["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"]
};
	["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null
};
	["WorkspaceCreateWithoutActiveSubscriptionInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"]: {
	where: ResolverInputTypes["WorkspaceWhereUniqueInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutActiveSubscriptionInput"]
};
	["SubscriptionUpdateManyMutationInput"]: {
	packageId?: string | undefined | null,
	packageName?: string | undefined | null,
	priceLookupKey?: string | undefined | null,
	startDate?: ResolverInputTypes["DateTime"] | undefined | null,
	endDate?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: string | undefined | null,
	stripePaymentIntentId?: string | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	stripeCheckoutSessionId?: string | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsCreateInput"] | undefined | null
};
	["SubscriptionUpdateInput"]: {
	packageId?: string | undefined | null,
	packageName?: string | undefined | null,
	priceLookupKey?: string | undefined | null,
	startDate?: ResolverInputTypes["DateTime"] | undefined | null,
	endDate?: ResolverInputTypes["DateTime"] | undefined | null,
	status?: string | undefined | null,
	stripePaymentIntentId?: string | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	stripeCheckoutSessionId?: string | undefined | null,
	cancellationDetails?: ResolverInputTypes["CancellationDetailsCreateInput"] | undefined | null,
	activeWorkspace?: ResolverInputTypes["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"] | undefined | null
};
	["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"]: {
	create?: ResolverInputTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"] | undefined | null,
	upsert?: ResolverInputTypes["WorkspaceUpsertWithoutActiveSubscriptionInput"] | undefined | null,
	connect?: ResolverInputTypes["WorkspaceWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"] | undefined | null
};
	["WorkspaceUpsertWithoutActiveSubscriptionInput"]: {
	update: ResolverInputTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"],
	create: ResolverInputTypes["WorkspaceCreateWithoutActiveSubscriptionInput"],
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null
};
	["WorkspaceUpdateWithoutActiveSubscriptionInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"]: {
	where?: ResolverInputTypes["WorkspaceWhereInput"] | undefined | null,
	data: ResolverInputTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"]
};
	["UserCreateManyInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	sharedBookingIds?: Array<string> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	leadWorkspaceIds?: Array<string> | undefined | null
};
	["UserCreateInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined | null
};
	["UserUpdateInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null,
	userPositions?: ResolverInputTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["UserPositionCreateManyInput"]: {
	id?: string | undefined | null,
	timestamp: ResolverInputTypes["DateTime"],
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	userId: string,
	coords: ResolverInputTypes["UserPositionCoordsCreateInput"],
	location?: ResolverInputTypes["UserPositionLocationCreateInput"] | undefined | null
};
	["UserPositionCreateInput"]: {
	id?: string | undefined | null,
	timestamp: ResolverInputTypes["DateTime"],
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	coords: ResolverInputTypes["UserPositionCoordsCreateInput"],
	location?: ResolverInputTypes["UserPositionLocationCreateInput"] | undefined | null,
	user: ResolverInputTypes["UserCreateNestedOneWithoutUserPositionsInput"]
};
	["UserCreateNestedOneWithoutUserPositionsInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutUserPositionsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutUserPositionsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null
};
	["UserCreateWithoutUserPositionsInput"]: {
	id?: string | undefined | null,
	uid?: string | undefined | null,
	email: string,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutUserInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutUserInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined | null
};
	["UserCreateOrConnectWithoutUserPositionsInput"]: {
	where: ResolverInputTypes["UserWhereUniqueInput"],
	create: ResolverInputTypes["UserCreateWithoutUserPositionsInput"]
};
	["UserPositionUpdateInput"]: {
	timestamp?: ResolverInputTypes["DateTime"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	coords?: ResolverInputTypes["UserPositionCoordsCreateInput"] | undefined | null,
	location?: ResolverInputTypes["UserPositionLocationCreateInput"] | undefined | null,
	user?: ResolverInputTypes["UserUpdateOneRequiredWithoutUserPositionsNestedInput"] | undefined | null
};
	["UserUpdateOneRequiredWithoutUserPositionsNestedInput"]: {
	create?: ResolverInputTypes["UserCreateWithoutUserPositionsInput"] | undefined | null,
	connectOrCreate?: ResolverInputTypes["UserCreateOrConnectWithoutUserPositionsInput"] | undefined | null,
	upsert?: ResolverInputTypes["UserUpsertWithoutUserPositionsInput"] | undefined | null,
	connect?: ResolverInputTypes["UserWhereUniqueInput"] | undefined | null,
	update?: ResolverInputTypes["UserUpdateToOneWithWhereWithoutUserPositionsInput"] | undefined | null
};
	["UserUpsertWithoutUserPositionsInput"]: {
	update: ResolverInputTypes["UserUpdateWithoutUserPositionsInput"],
	create: ResolverInputTypes["UserCreateWithoutUserPositionsInput"],
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null
};
	["UserUpdateWithoutUserPositionsInput"]: {
	uid?: string | undefined | null,
	email?: string | undefined | null,
	image?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	displayName?: string | undefined | null,
	otherDetails?: ResolverInputTypes["JSON"] | undefined | null,
	stripe?: ResolverInputTypes["UserStripeCreateInput"] | undefined | null,
	roles?: Array<ResolverInputTypes["Role"]> | undefined | null,
	refunds?: Array<ResolverInputTypes["BookingRefundCreateInput"]> | undefined | null,
	disclaimerAccepted?: boolean | undefined | null,
	notificationPreference?: ResolverInputTypes["NotificationPreferenceCreateInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined | null,
	createdFiles?: ResolverInputTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	updatedFiles?: ResolverInputTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined | null,
	orders?: ResolverInputTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	changelogs?: ResolverInputTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined | null,
	campaigns?: ResolverInputTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined | null,
	sharedBookings?: ResolverInputTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined | null,
	notifications?: ResolverInputTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined | null,
	publishAdboardVersion?: ResolverInputTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined | null,
	offlineBookingAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined | null,
	reviewComments?: ResolverInputTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined | null,
	archivedAdboards?: ResolverInputTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined | null,
	ownedWorkspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined | null,
	leadWorspaces?: ResolverInputTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined | null,
	publicReviews?: ResolverInputTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined | null
};
	["UserUpdateToOneWithWhereWithoutUserPositionsInput"]: {
	where?: ResolverInputTypes["UserWhereInput"] | undefined | null,
	data: ResolverInputTypes["UserUpdateWithoutUserPositionsInput"]
};
	["WorkspaceCreateManyInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	ownerId?: string | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	leadIds?: Array<string> | undefined | null
};
	["WorkspaceCreateInput"]: {
	id?: string | undefined | null,
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name: string,
	key: string,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	files?: ResolverInputTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined | null,
	leads?: ResolverInputTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined | null
};
	["WorkspaceUpdateInput"]: {
	type?: ResolverInputTypes["WorkspaceType"] | undefined | null,
	name?: string | undefined | null,
	key?: string | undefined | null,
	userIds?: Array<string> | undefined | null,
	avatar?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	stripeSubscriptionId?: string | undefined | null,
	owner?: ResolverInputTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoards?: ResolverInputTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	playlists?: ResolverInputTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	adBoardGroups?: ResolverInputTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	audits?: ResolverInputTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	bookings?: ResolverInputTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	files?: ResolverInputTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	players?: ResolverInputTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	transactions?: ResolverInputTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	activeSubscription?: ResolverInputTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined | null,
	cameraServers?: ResolverInputTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	workspaceRoles?: ResolverInputTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	webhookLogs?: ResolverInputTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined | null,
	leads?: ResolverInputTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined | null
};
	["WorkspaceRoleCreateManyInput"]: {
	id?: string | undefined | null,
	name: string,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	workspaceId?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null
};
	["WorkspaceRoleCreateInput"]: {
	id?: string | undefined | null,
	name: string,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipCreateNestedManyWithoutRoleInput"] | undefined | null
};
	["WorkspaceRoleUpdateInput"]: {
	name?: string | undefined | null,
	permissions?: Array<ResolverInputTypes["Permission"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	workspace?: ResolverInputTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"] | undefined | null,
	memberships?: ResolverInputTypes["MembershipUpdateManyWithoutRoleNestedInput"] | undefined | null
};
	["AccountDetails"]: {
	displayName: string,
	phoneNumber: string,
	password: string
};
	["UserInput"]: {
	displayName: string,
	phoneNumber: string,
	password: string,
	email: string
};
	["GenericResponse"]: AliasType<{
	success?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EmailOutput"]: AliasType<{
	success?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TemplateIds"]:TemplateIds;
	["AdBoardPublicReviewCreateInput"]: {
	id?: string | undefined | null,
	rating: number,
	comment?: string | undefined | null,
	createdAt?: ResolverInputTypes["DateTime"] | undefined | null,
	adBoard: ResolverInputTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"],
	user: ResolverInputTypes["UserCreateNestedOneWithoutPublicReviewsInput"]
};
	/** The recipe model */
["TypedGenericResponse"]: AliasType<{
	success?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookingRequest"]: {
	assets: Array<string>,
	bookingDetails: ResolverInputTypes["BookingDetailsInput"],
	orderId: string
};
	["BookingDetailsInput"]: {
	email: string,
	name: string,
	phone: string
};
	["schema"]: AliasType<{
	query?:ResolverInputTypes["Query"],
	mutation?:ResolverInputTypes["Mutation"],
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Query"]: {
		aggregateAdBoard: ModelTypes["AggregateAdBoard"],
	findFirstAdBoard?: ModelTypes["AdBoard"] | undefined,
	findFirstAdBoardOrThrow?: ModelTypes["AdBoard"] | undefined,
	adBoards: Array<ModelTypes["AdBoard"]>,
	adBoard?: ModelTypes["AdBoard"] | undefined,
	getAdBoard?: ModelTypes["AdBoard"] | undefined,
	groupByAdBoard: Array<ModelTypes["AdBoardGroupBy"]>,
	aggregateAdBoardGroup: ModelTypes["AggregateAdBoardGroup"],
	findFirstAdBoardGroup?: ModelTypes["AdBoardGroup"] | undefined,
	findFirstAdBoardGroupOrThrow?: ModelTypes["AdBoardGroup"] | undefined,
	adBoardGroups: Array<ModelTypes["AdBoardGroup"]>,
	adBoardGroup?: ModelTypes["AdBoardGroup"] | undefined,
	getAdBoardGroup?: ModelTypes["AdBoardGroup"] | undefined,
	groupByAdBoardGroup: Array<ModelTypes["AdBoardGroupGroupBy"]>,
	aggregateAdBoardType: ModelTypes["AggregateAdBoardType"],
	findFirstAdBoardType?: ModelTypes["AdBoardType"] | undefined,
	findFirstAdBoardTypeOrThrow?: ModelTypes["AdBoardType"] | undefined,
	adBoardTypes: Array<ModelTypes["AdBoardType"]>,
	adBoardType?: ModelTypes["AdBoardType"] | undefined,
	getAdBoardType?: ModelTypes["AdBoardType"] | undefined,
	groupByAdBoardType: Array<ModelTypes["AdBoardTypeGroupBy"]>,
	aggregateAdvertisementCategory: ModelTypes["AggregateAdvertisementCategory"],
	findFirstAdvertisementCategory?: ModelTypes["AdvertisementCategory"] | undefined,
	findFirstAdvertisementCategoryOrThrow?: ModelTypes["AdvertisementCategory"] | undefined,
	advertisementCategories: Array<ModelTypes["AdvertisementCategory"]>,
	advertisementCategory?: ModelTypes["AdvertisementCategory"] | undefined,
	getAdvertisementCategory?: ModelTypes["AdvertisementCategory"] | undefined,
	groupByAdvertisementCategory: Array<ModelTypes["AdvertisementCategoryGroupBy"]>,
	aggregateAudit: ModelTypes["AggregateAudit"],
	findFirstAudit?: ModelTypes["Audit"] | undefined,
	findFirstAuditOrThrow?: ModelTypes["Audit"] | undefined,
	audits: Array<ModelTypes["Audit"]>,
	audit?: ModelTypes["Audit"] | undefined,
	getAudit?: ModelTypes["Audit"] | undefined,
	groupByAudit: Array<ModelTypes["AuditGroupBy"]>,
	aggregateBooking: ModelTypes["AggregateBooking"],
	findFirstBooking?: ModelTypes["Booking"] | undefined,
	findFirstBookingOrThrow?: ModelTypes["Booking"] | undefined,
	bookings: Array<ModelTypes["Booking"]>,
	booking?: ModelTypes["Booking"] | undefined,
	getBooking?: ModelTypes["Booking"] | undefined,
	groupByBooking: Array<ModelTypes["BookingGroupBy"]>,
	aggregateBusinessCategory: ModelTypes["AggregateBusinessCategory"],
	findFirstBusinessCategory?: ModelTypes["BusinessCategory"] | undefined,
	findFirstBusinessCategoryOrThrow?: ModelTypes["BusinessCategory"] | undefined,
	businessCategories: Array<ModelTypes["BusinessCategory"]>,
	businessCategory?: ModelTypes["BusinessCategory"] | undefined,
	getBusinessCategory?: ModelTypes["BusinessCategory"] | undefined,
	groupByBusinessCategory: Array<ModelTypes["BusinessCategoryGroupBy"]>,
	aggregateCamera: ModelTypes["AggregateCamera"],
	findFirstCamera?: ModelTypes["Camera"] | undefined,
	findFirstCameraOrThrow?: ModelTypes["Camera"] | undefined,
	cameras: Array<ModelTypes["Camera"]>,
	camera?: ModelTypes["Camera"] | undefined,
	getCamera?: ModelTypes["Camera"] | undefined,
	groupByCamera: Array<ModelTypes["CameraGroupBy"]>,
	aggregateCameraFeed: ModelTypes["AggregateCameraFeed"],
	findFirstCameraFeed?: ModelTypes["CameraFeed"] | undefined,
	findFirstCameraFeedOrThrow?: ModelTypes["CameraFeed"] | undefined,
	cameraFeeds: Array<ModelTypes["CameraFeed"]>,
	cameraFeed?: ModelTypes["CameraFeed"] | undefined,
	getCameraFeed?: ModelTypes["CameraFeed"] | undefined,
	groupByCameraFeed: Array<ModelTypes["CameraFeedGroupBy"]>,
	aggregateCameraServer: ModelTypes["AggregateCameraServer"],
	findFirstCameraServer?: ModelTypes["CameraServer"] | undefined,
	findFirstCameraServerOrThrow?: ModelTypes["CameraServer"] | undefined,
	cameraServers: Array<ModelTypes["CameraServer"]>,
	cameraServer?: ModelTypes["CameraServer"] | undefined,
	getCameraServer?: ModelTypes["CameraServer"] | undefined,
	groupByCameraServer: Array<ModelTypes["CameraServerGroupBy"]>,
	aggregateCampaign: ModelTypes["AggregateCampaign"],
	findFirstCampaign?: ModelTypes["Campaign"] | undefined,
	findFirstCampaignOrThrow?: ModelTypes["Campaign"] | undefined,
	campaigns: Array<ModelTypes["Campaign"]>,
	campaign?: ModelTypes["Campaign"] | undefined,
	getCampaign?: ModelTypes["Campaign"] | undefined,
	groupByCampaign: Array<ModelTypes["CampaignGroupBy"]>,
	aggregateFile: ModelTypes["AggregateFile"],
	findFirstFile?: ModelTypes["File"] | undefined,
	findFirstFileOrThrow?: ModelTypes["File"] | undefined,
	files: Array<ModelTypes["File"]>,
	file?: ModelTypes["File"] | undefined,
	getFile?: ModelTypes["File"] | undefined,
	groupByFile: Array<ModelTypes["FileGroupBy"]>,
	aggregateMembership: ModelTypes["AggregateMembership"],
	findFirstMembership?: ModelTypes["Membership"] | undefined,
	findFirstMembershipOrThrow?: ModelTypes["Membership"] | undefined,
	memberships: Array<ModelTypes["Membership"]>,
	membership?: ModelTypes["Membership"] | undefined,
	getMembership?: ModelTypes["Membership"] | undefined,
	groupByMembership: Array<ModelTypes["MembershipGroupBy"]>,
	aggregateNotification: ModelTypes["AggregateNotification"],
	findFirstNotification?: ModelTypes["Notification"] | undefined,
	findFirstNotificationOrThrow?: ModelTypes["Notification"] | undefined,
	notifications: Array<ModelTypes["Notification"]>,
	notification?: ModelTypes["Notification"] | undefined,
	getNotification?: ModelTypes["Notification"] | undefined,
	groupByNotification: Array<ModelTypes["NotificationGroupBy"]>,
	aggregateOrder: ModelTypes["AggregateOrder"],
	findFirstOrder?: ModelTypes["Order"] | undefined,
	findFirstOrderOrThrow?: ModelTypes["Order"] | undefined,
	orders: Array<ModelTypes["Order"]>,
	order?: ModelTypes["Order"] | undefined,
	getOrder?: ModelTypes["Order"] | undefined,
	groupByOrder: Array<ModelTypes["OrderGroupBy"]>,
	aggregatePlayer: ModelTypes["AggregatePlayer"],
	findFirstPlayer?: ModelTypes["Player"] | undefined,
	findFirstPlayerOrThrow?: ModelTypes["Player"] | undefined,
	players: Array<ModelTypes["Player"]>,
	player?: ModelTypes["Player"] | undefined,
	getPlayer?: ModelTypes["Player"] | undefined,
	groupByPlayer: Array<ModelTypes["PlayerGroupBy"]>,
	aggregatePlaylist: ModelTypes["AggregatePlaylist"],
	findFirstPlaylist?: ModelTypes["Playlist"] | undefined,
	findFirstPlaylistOrThrow?: ModelTypes["Playlist"] | undefined,
	playlists: Array<ModelTypes["Playlist"]>,
	playlist?: ModelTypes["Playlist"] | undefined,
	getPlaylist?: ModelTypes["Playlist"] | undefined,
	groupByPlaylist: Array<ModelTypes["PlaylistGroupBy"]>,
	aggregateSlotConfiguration: ModelTypes["AggregateSlotConfiguration"],
	findFirstSlotConfiguration?: ModelTypes["SlotConfiguration"] | undefined,
	findFirstSlotConfigurationOrThrow?: ModelTypes["SlotConfiguration"] | undefined,
	slotConfigurations: Array<ModelTypes["SlotConfiguration"]>,
	slotConfiguration?: ModelTypes["SlotConfiguration"] | undefined,
	getSlotConfiguration?: ModelTypes["SlotConfiguration"] | undefined,
	groupBySlotConfiguration: Array<ModelTypes["SlotConfigurationGroupBy"]>,
	aggregateSubscription: ModelTypes["AggregateSubscription"],
	findFirstSubscription?: ModelTypes["Subscription"] | undefined,
	findFirstSubscriptionOrThrow?: ModelTypes["Subscription"] | undefined,
	subscriptions: Array<ModelTypes["Subscription"]>,
	subscription?: ModelTypes["Subscription"] | undefined,
	getSubscription?: ModelTypes["Subscription"] | undefined,
	groupBySubscription: Array<ModelTypes["SubscriptionGroupBy"]>,
	aggregateUser: ModelTypes["AggregateUser"],
	findFirstUser?: ModelTypes["User"] | undefined,
	findFirstUserOrThrow?: ModelTypes["User"] | undefined,
	users: Array<ModelTypes["User"]>,
	user?: ModelTypes["User"] | undefined,
	getUser?: ModelTypes["User"] | undefined,
	groupByUser: Array<ModelTypes["UserGroupBy"]>,
	aggregateUserPosition: ModelTypes["AggregateUserPosition"],
	findFirstUserPosition?: ModelTypes["UserPosition"] | undefined,
	findFirstUserPositionOrThrow?: ModelTypes["UserPosition"] | undefined,
	userPositions: Array<ModelTypes["UserPosition"]>,
	userPosition?: ModelTypes["UserPosition"] | undefined,
	getUserPosition?: ModelTypes["UserPosition"] | undefined,
	groupByUserPosition: Array<ModelTypes["UserPositionGroupBy"]>,
	aggregateWorkspace: ModelTypes["AggregateWorkspace"],
	findFirstWorkspace?: ModelTypes["Workspace"] | undefined,
	findFirstWorkspaceOrThrow?: ModelTypes["Workspace"] | undefined,
	workspaces: Array<ModelTypes["Workspace"]>,
	workspace?: ModelTypes["Workspace"] | undefined,
	getWorkspace?: ModelTypes["Workspace"] | undefined,
	groupByWorkspace: Array<ModelTypes["WorkspaceGroupBy"]>,
	aggregateWorkspaceRole: ModelTypes["AggregateWorkspaceRole"],
	findFirstWorkspaceRole?: ModelTypes["WorkspaceRole"] | undefined,
	findFirstWorkspaceRoleOrThrow?: ModelTypes["WorkspaceRole"] | undefined,
	workspaceRoles: Array<ModelTypes["WorkspaceRole"]>,
	workspaceRole?: ModelTypes["WorkspaceRole"] | undefined,
	getWorkspaceRole?: ModelTypes["WorkspaceRole"] | undefined,
	groupByWorkspaceRole: Array<ModelTypes["WorkspaceRoleGroupBy"]>,
	getAdBoardAvailability: Array<ModelTypes["Slot"]>
};
	["AggregateAdBoard"]: {
		_count?: ModelTypes["AdBoardCountAggregate"] | undefined,
	_avg?: ModelTypes["AdBoardAvgAggregate"] | undefined,
	_sum?: ModelTypes["AdBoardSumAggregate"] | undefined,
	_min?: ModelTypes["AdBoardMinAggregate"] | undefined,
	_max?: ModelTypes["AdBoardMaxAggregate"] | undefined
};
	["AdBoardCountAggregate"]: {
		id: number,
	address: number,
	state: number,
	city: number,
	country: number,
	outdoor: number,
	locationCategoryId: number,
	locationSubCategoryId: number,
	alias: number,
	title: number,
	pricePerDay: number,
	originalPricePerDay: number,
	description: number,
	adBoardTypeId: number,
	tags: number,
	featuredImage: number,
	galleryImgs: number,
	notes: number,
	impressionMultiplier: number,
	categoryId: number,
	restrictedBusinessIds: number,
	targetAudiences: number,
	status: number,
	adboardDraftStatus: number,
	isLive: number,
	numberOfDisplays: number,
	dailyOperationHours: number,
	adsPerLoop: number,
	adsDuration: number,
	monthlyPrice: number,
	offlineBookingsEnabled: number,
	offlineBookingContactId: number,
	createdAt: number,
	createdByUserId: number,
	href: number,
	workspaceId: number,
	playerIds: number,
	publisherScreenId: number,
	additionalData: number,
	isDraft: number,
	publishedVersionId: number,
	lastPublishedAt: number,
	isArchived: number,
	archivedAt: number,
	archivedByUserId: number,
	updatedAt: number,
	_all: number
};
	["AdBoardAvgAggregate"]: {
		pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	impressionMultiplier?: number | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined
};
	["AdBoardSumAggregate"]: {
		pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	impressionMultiplier?: number | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined
};
	["AdBoardMinAggregate"]: {
		id?: string | undefined,
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	adBoardTypeId?: string | undefined,
	featuredImage?: string | undefined,
	impressionMultiplier?: number | undefined,
	categoryId?: string | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId?: string | undefined,
	publisherScreenId?: string | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AdBoardStatus"]:AdBoardStatus;
	["AdboardDraftStatus"]:AdboardDraftStatus;
	/** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
["DateTime"]:any;
	["AdBoardMaxAggregate"]: {
		id?: string | undefined,
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	adBoardTypeId?: string | undefined,
	featuredImage?: string | undefined,
	impressionMultiplier?: number | undefined,
	categoryId?: string | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId?: string | undefined,
	publisherScreenId?: string | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AdBoardWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	address?: ModelTypes["StringFilter"] | undefined,
	state?: ModelTypes["StringFilter"] | undefined,
	city?: ModelTypes["StringFilter"] | undefined,
	country?: ModelTypes["StringFilter"] | undefined,
	map?: ModelTypes["MapCompositeFilter"] | undefined,
	outdoor?: ModelTypes["BoolFilter"] | undefined,
	locationCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	locationSubCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	alias?: ModelTypes["StringNullableFilter"] | undefined,
	title?: ModelTypes["StringFilter"] | undefined,
	pricePerDay?: ModelTypes["IntFilter"] | undefined,
	originalPricePerDay?: ModelTypes["IntNullableFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationObjectEqualityInput"]> | undefined,
	adBoardTypeId?: ModelTypes["StringNullableFilter"] | undefined,
	tags?: ModelTypes["StringNullableListFilter"] | undefined,
	featuredImage?: ModelTypes["StringNullableFilter"] | undefined,
	galleryImgs?: ModelTypes["StringNullableListFilter"] | undefined,
	specifications?: Array<ModelTypes["SpecificationObjectEqualityInput"]> | undefined,
	notes?: ModelTypes["StringNullableListFilter"] | undefined,
	impressionMultiplier?: ModelTypes["FloatNullableFilter"] | undefined,
	resolution?: ModelTypes["ResolutionNullableCompositeFilter"] | undefined,
	screenSize?: ModelTypes["ScreenSizeNullableCompositeFilter"] | undefined,
	categoryId?: ModelTypes["StringNullableFilter"] | undefined,
	restrictedBusinessIds?: ModelTypes["StringNullableListFilter"] | undefined,
	targetAudiences?: ModelTypes["StringNullableListFilter"] | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeObjectEqualityInput"]> | undefined,
	status?: ModelTypes["EnumAdBoardStatusFilter"] | undefined,
	adboardDraftStatus?: ModelTypes["EnumAdboardDraftStatusNullableFilter"] | undefined,
	isLive?: ModelTypes["BoolFilter"] | undefined,
	webhooks?: Array<ModelTypes["WebhookObjectEqualityInput"]> | undefined,
	numberOfDisplays?: ModelTypes["IntFilter"] | undefined,
	dailyOperationHours?: ModelTypes["IntFilter"] | undefined,
	adsPerLoop?: ModelTypes["IntFilter"] | undefined,
	adsDuration?: ModelTypes["IntFilter"] | undefined,
	monthlyPrice?: ModelTypes["FloatNullableFilter"] | undefined,
	offlineBookingsEnabled?: ModelTypes["BoolFilter"] | undefined,
	offlineBookingContactId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	createdByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	href?: ModelTypes["StringNullableFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	playerIds?: ModelTypes["StringNullableListFilter"] | undefined,
	publisherScreenId?: ModelTypes["StringNullableFilter"] | undefined,
	additionalData?: ModelTypes["JsonNullableFilter"] | undefined,
	isDraft?: ModelTypes["BoolFilter"] | undefined,
	publishedVersionId?: ModelTypes["StringNullableFilter"] | undefined,
	lastPublishedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	isArchived?: ModelTypes["BoolFilter"] | undefined,
	archivedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	archivedByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryNullableRelationFilter"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryNullableRelationFilter"] | undefined,
	type?: ModelTypes["AdBoardTypeNullableRelationFilter"] | undefined,
	category?: ModelTypes["BusinessCategoryNullableRelationFilter"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	orders?: ModelTypes["OrderListRelationFilter"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationListRelationFilter"] | undefined,
	offlineBookingsContact?: ModelTypes["UserNullableRelationFilter"] | undefined,
	createdBy?: ModelTypes["UserNullableRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceRelationFilter"] | undefined,
	players?: ModelTypes["PlayerListRelationFilter"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupNullableRelationFilter"] | undefined,
	cameras?: ModelTypes["CameraListRelationFilter"] | undefined,
	publishedVersion?: ModelTypes["AdBoardNullableRelationFilter"] | undefined,
	draftAdboard?: ModelTypes["AdBoardNullableRelationFilter"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryListRelationFilter"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentListRelationFilter"] | undefined,
	archivedBy?: ModelTypes["UserNullableRelationFilter"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewListRelationFilter"] | undefined
};
	["StringFilter"]: {
	equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	mode?: ModelTypes["QueryMode"] | undefined,
	not?: ModelTypes["NestedStringFilter"] | undefined
};
	["QueryMode"]:QueryMode;
	["NestedStringFilter"]: {
	equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	not?: ModelTypes["NestedStringFilter"] | undefined
};
	["MapCompositeFilter"]: {
	equals?: ModelTypes["MapObjectEqualityInput"] | undefined,
	is?: ModelTypes["MapWhereInput"] | undefined,
	isNot?: ModelTypes["MapWhereInput"] | undefined
};
	["MapObjectEqualityInput"]: {
	lat: number,
	lng: number,
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["MapWhereInput"]: {
	AND?: Array<ModelTypes["MapWhereInput"]> | undefined,
	OR?: Array<ModelTypes["MapWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["MapWhereInput"]> | undefined,
	lat?: ModelTypes["FloatFilter"] | undefined,
	lng?: ModelTypes["FloatFilter"] | undefined,
	address?: ModelTypes["StringNullableFilter"] | undefined,
	city?: ModelTypes["StringNullableFilter"] | undefined,
	state?: ModelTypes["StringNullableFilter"] | undefined,
	country?: ModelTypes["StringNullableFilter"] | undefined
};
	["FloatFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedFloatFilter"] | undefined
};
	["NestedFloatFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedFloatFilter"] | undefined
};
	["StringNullableFilter"]: {
	equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	mode?: ModelTypes["QueryMode"] | undefined,
	not?: ModelTypes["NestedStringNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedStringNullableFilter"]: {
	equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	not?: ModelTypes["NestedStringNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["BoolFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["NestedBoolFilter"] | undefined
};
	["NestedBoolFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["NestedBoolFilter"] | undefined
};
	["IntFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedIntFilter"] | undefined
};
	["NestedIntFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedIntFilter"] | undefined
};
	["IntNullableFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedIntNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedIntNullableFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedIntNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["SpecificationObjectEqualityInput"]: {
	name: string,
	value?: string | undefined
};
	["StringNullableListFilter"]: {
	equals?: Array<string> | undefined,
	has?: string | undefined,
	hasEvery?: Array<string> | undefined,
	hasSome?: Array<string> | undefined,
	isEmpty?: boolean | undefined
};
	["FloatNullableFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedFloatNullableFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["ResolutionNullableCompositeFilter"]: {
	equals?: ModelTypes["ResolutionObjectEqualityInput"] | undefined,
	is?: ModelTypes["ResolutionWhereInput"] | undefined,
	isNot?: ModelTypes["ResolutionWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["ResolutionObjectEqualityInput"]: {
	height: number,
	width: number
};
	["ResolutionWhereInput"]: {
	AND?: Array<ModelTypes["ResolutionWhereInput"]> | undefined,
	OR?: Array<ModelTypes["ResolutionWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["ResolutionWhereInput"]> | undefined,
	height?: ModelTypes["IntFilter"] | undefined,
	width?: ModelTypes["IntFilter"] | undefined
};
	["ScreenSizeNullableCompositeFilter"]: {
	equals?: ModelTypes["ScreenSizeObjectEqualityInput"] | undefined,
	is?: ModelTypes["ScreenSizeWhereInput"] | undefined,
	isNot?: ModelTypes["ScreenSizeWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["ScreenSizeObjectEqualityInput"]: {
	height: number,
	unit: ModelTypes["MeasurementUnit"],
	width: number
};
	["MeasurementUnit"]:MeasurementUnit;
	["ScreenSizeWhereInput"]: {
	AND?: Array<ModelTypes["ScreenSizeWhereInput"]> | undefined,
	OR?: Array<ModelTypes["ScreenSizeWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["ScreenSizeWhereInput"]> | undefined,
	height?: ModelTypes["FloatFilter"] | undefined,
	unit?: ModelTypes["EnumMeasurementUnitFilter"] | undefined,
	width?: ModelTypes["FloatFilter"] | undefined
};
	["EnumMeasurementUnitFilter"]: {
	equals?: ModelTypes["MeasurementUnit"] | undefined,
	in?: Array<ModelTypes["MeasurementUnit"]> | undefined,
	notIn?: Array<ModelTypes["MeasurementUnit"]> | undefined,
	not?: ModelTypes["NestedEnumMeasurementUnitFilter"] | undefined
};
	["NestedEnumMeasurementUnitFilter"]: {
	equals?: ModelTypes["MeasurementUnit"] | undefined,
	in?: Array<ModelTypes["MeasurementUnit"]> | undefined,
	notIn?: Array<ModelTypes["MeasurementUnit"]> | undefined,
	not?: ModelTypes["NestedEnumMeasurementUnitFilter"] | undefined
};
	["PlaybackTimeObjectEqualityInput"]: {
	name?: string | undefined,
	startTime: string,
	endTime: string
};
	["EnumAdBoardStatusFilter"]: {
	equals?: ModelTypes["AdBoardStatus"] | undefined,
	in?: Array<ModelTypes["AdBoardStatus"]> | undefined,
	notIn?: Array<ModelTypes["AdBoardStatus"]> | undefined,
	not?: ModelTypes["NestedEnumAdBoardStatusFilter"] | undefined
};
	["NestedEnumAdBoardStatusFilter"]: {
	equals?: ModelTypes["AdBoardStatus"] | undefined,
	in?: Array<ModelTypes["AdBoardStatus"]> | undefined,
	notIn?: Array<ModelTypes["AdBoardStatus"]> | undefined,
	not?: ModelTypes["NestedEnumAdBoardStatusFilter"] | undefined
};
	["EnumAdboardDraftStatusNullableFilter"]: {
	equals?: ModelTypes["AdboardDraftStatus"] | undefined,
	in?: Array<ModelTypes["AdboardDraftStatus"]> | undefined,
	notIn?: Array<ModelTypes["AdboardDraftStatus"]> | undefined,
	not?: ModelTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumAdboardDraftStatusNullableFilter"]: {
	equals?: ModelTypes["AdboardDraftStatus"] | undefined,
	in?: Array<ModelTypes["AdboardDraftStatus"]> | undefined,
	notIn?: Array<ModelTypes["AdboardDraftStatus"]> | undefined,
	not?: ModelTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["WebhookObjectEqualityInput"]: {
	url: string,
	headers?: ModelTypes["JSON"] | undefined,
	body?: ModelTypes["JSON"] | undefined,
	method: string
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["DateTimeNullableFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["NestedDateTimeNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedDateTimeNullableFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["NestedDateTimeNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["JsonNullableFilter"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not?: ModelTypes["JSON"] | undefined,
	isSet?: boolean | undefined
};
	["LocationCategoryNullableRelationFilter"]: {
	is?: ModelTypes["LocationCategoryWhereInput"] | undefined,
	isNot?: ModelTypes["LocationCategoryWhereInput"] | undefined
};
	["LocationCategoryWhereInput"]: {
	AND?: Array<ModelTypes["LocationCategoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["LocationCategoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["LocationCategoryWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryListRelationFilter"] | undefined
};
	["AdBoardListRelationFilter"]: {
	every?: ModelTypes["AdBoardWhereInput"] | undefined,
	some?: ModelTypes["AdBoardWhereInput"] | undefined,
	none?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["LocationSubCategoryListRelationFilter"]: {
	every?: ModelTypes["LocationSubCategoryWhereInput"] | undefined,
	some?: ModelTypes["LocationSubCategoryWhereInput"] | undefined,
	none?: ModelTypes["LocationSubCategoryWhereInput"] | undefined
};
	["LocationSubCategoryWhereInput"]: {
	AND?: Array<ModelTypes["LocationSubCategoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["LocationSubCategoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["LocationSubCategoryWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	locationCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryNullableRelationFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined
};
	["LocationSubCategoryNullableRelationFilter"]: {
	is?: ModelTypes["LocationSubCategoryWhereInput"] | undefined,
	isNot?: ModelTypes["LocationSubCategoryWhereInput"] | undefined
};
	["AdBoardTypeNullableRelationFilter"]: {
	is?: ModelTypes["AdBoardTypeWhereInput"] | undefined,
	isNot?: ModelTypes["AdBoardTypeWhereInput"] | undefined
};
	["AdBoardTypeWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardTypeWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardTypeWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardTypeWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined
};
	["BusinessCategoryNullableRelationFilter"]: {
	is?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	isNot?: ModelTypes["BusinessCategoryWhereInput"] | undefined
};
	["BusinessCategoryWhereInput"]: {
	AND?: Array<ModelTypes["BusinessCategoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BusinessCategoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BusinessCategoryWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	restrictedByAdBoardIds?: ModelTypes["StringNullableListFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	campaigns?: ModelTypes["CampaignListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined
};
	["CampaignListRelationFilter"]: {
	every?: ModelTypes["CampaignWhereInput"] | undefined,
	some?: ModelTypes["CampaignWhereInput"] | undefined,
	none?: ModelTypes["CampaignWhereInput"] | undefined
};
	["CampaignWhereInput"]: {
	AND?: Array<ModelTypes["CampaignWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CampaignWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CampaignWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	bookingFor?: ModelTypes["StringFilter"] | undefined,
	purpose?: ModelTypes["StringFilter"] | undefined,
	businessCategoryId?: ModelTypes["StringFilter"] | undefined,
	adCategoryId?: ModelTypes["StringNullableListFilter"] | undefined,
	payment?: ModelTypes["BookingPaymentNullableCompositeFilter"] | undefined,
	paymentStatus?: ModelTypes["EnumPaymentStatusFilter"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryNullableCompositeFilter"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCompositeFilter"] | undefined,
	origin?: ModelTypes["StringFilter"] | undefined,
	assetsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryRelationFilter"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	payments?: ModelTypes["PaymentListRelationFilter"] | undefined,
	assets?: ModelTypes["FileListRelationFilter"] | undefined
};
	["DateTimeFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["NestedDateTimeFilter"] | undefined
};
	["NestedDateTimeFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["NestedDateTimeFilter"] | undefined
};
	["BookingPaymentNullableCompositeFilter"]: {
	equals?: ModelTypes["BookingPaymentObjectEqualityInput"] | undefined,
	is?: ModelTypes["BookingPaymentWhereInput"] | undefined,
	isNot?: ModelTypes["BookingPaymentWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["BookingPaymentObjectEqualityInput"]: {
	liveMode: boolean,
	checkoutSessionId?: string | undefined,
	customer?: ModelTypes["BookingCustomerObjectEqualityInput"] | undefined,
	paymentGateway: ModelTypes["PaymentGatewayName"],
	paymentId: string,
	sessionId?: string | undefined,
	totalDetails?: ModelTypes["PaymentTotalDetailsObjectEqualityInput"] | undefined,
	razorpay?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["JSON"] | undefined
};
	["BookingCustomerObjectEqualityInput"]: {
	customerId: string,
	customerDetails?: ModelTypes["PaymentCustomerDetailsObjectEqualityInput"] | undefined
};
	["PaymentCustomerDetailsObjectEqualityInput"]: {
	email: string,
	name: string,
	phone?: string | undefined
};
	["PaymentGatewayName"]:PaymentGatewayName;
	["PaymentTotalDetailsObjectEqualityInput"]: {
	discount: number,
	shipping: number,
	subtotal: number,
	tax: number,
	total: number
};
	["BookingPaymentWhereInput"]: {
	AND?: Array<ModelTypes["BookingPaymentWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BookingPaymentWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingPaymentWhereInput"]> | undefined,
	liveMode?: ModelTypes["BoolFilter"] | undefined,
	checkoutSessionId?: ModelTypes["StringNullableFilter"] | undefined,
	customer?: ModelTypes["BookingCustomerNullableCompositeFilter"] | undefined,
	paymentGateway?: ModelTypes["EnumPaymentGatewayNameFilter"] | undefined,
	paymentId?: ModelTypes["StringFilter"] | undefined,
	sessionId?: ModelTypes["StringNullableFilter"] | undefined,
	totalDetails?: ModelTypes["PaymentTotalDetailsNullableCompositeFilter"] | undefined,
	razorpay?: ModelTypes["JsonNullableFilter"] | undefined,
	stripe?: ModelTypes["JsonNullableFilter"] | undefined
};
	["BookingCustomerNullableCompositeFilter"]: {
	equals?: ModelTypes["BookingCustomerObjectEqualityInput"] | undefined,
	is?: ModelTypes["BookingCustomerWhereInput"] | undefined,
	isNot?: ModelTypes["BookingCustomerWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["BookingCustomerWhereInput"]: {
	AND?: Array<ModelTypes["BookingCustomerWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BookingCustomerWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingCustomerWhereInput"]> | undefined,
	customerId?: ModelTypes["StringFilter"] | undefined,
	customerDetails?: ModelTypes["PaymentCustomerDetailsNullableCompositeFilter"] | undefined
};
	["PaymentCustomerDetailsNullableCompositeFilter"]: {
	equals?: ModelTypes["PaymentCustomerDetailsObjectEqualityInput"] | undefined,
	is?: ModelTypes["PaymentCustomerDetailsWhereInput"] | undefined,
	isNot?: ModelTypes["PaymentCustomerDetailsWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["PaymentCustomerDetailsWhereInput"]: {
	AND?: Array<ModelTypes["PaymentCustomerDetailsWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PaymentCustomerDetailsWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PaymentCustomerDetailsWhereInput"]> | undefined,
	email?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	phone?: ModelTypes["StringNullableFilter"] | undefined
};
	["EnumPaymentGatewayNameFilter"]: {
	equals?: ModelTypes["PaymentGatewayName"] | undefined,
	in?: Array<ModelTypes["PaymentGatewayName"]> | undefined,
	notIn?: Array<ModelTypes["PaymentGatewayName"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentGatewayNameFilter"] | undefined
};
	["NestedEnumPaymentGatewayNameFilter"]: {
	equals?: ModelTypes["PaymentGatewayName"] | undefined,
	in?: Array<ModelTypes["PaymentGatewayName"]> | undefined,
	notIn?: Array<ModelTypes["PaymentGatewayName"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentGatewayNameFilter"] | undefined
};
	["PaymentTotalDetailsNullableCompositeFilter"]: {
	equals?: ModelTypes["PaymentTotalDetailsObjectEqualityInput"] | undefined,
	is?: ModelTypes["PaymentTotalDetailsWhereInput"] | undefined,
	isNot?: ModelTypes["PaymentTotalDetailsWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["PaymentTotalDetailsWhereInput"]: {
	AND?: Array<ModelTypes["PaymentTotalDetailsWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PaymentTotalDetailsWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PaymentTotalDetailsWhereInput"]> | undefined,
	discount?: ModelTypes["FloatFilter"] | undefined,
	shipping?: ModelTypes["FloatFilter"] | undefined,
	subtotal?: ModelTypes["FloatFilter"] | undefined,
	tax?: ModelTypes["FloatFilter"] | undefined,
	total?: ModelTypes["FloatFilter"] | undefined
};
	["EnumPaymentStatusFilter"]: {
	equals?: ModelTypes["PaymentStatus"] | undefined,
	in?: Array<ModelTypes["PaymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["PaymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentStatusFilter"] | undefined
};
	["PaymentStatus"]:PaymentStatus;
	["NestedEnumPaymentStatusFilter"]: {
	equals?: ModelTypes["PaymentStatus"] | undefined,
	in?: Array<ModelTypes["PaymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["PaymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentStatusFilter"] | undefined
};
	["PriceSummaryNullableCompositeFilter"]: {
	equals?: ModelTypes["PriceSummaryObjectEqualityInput"] | undefined,
	is?: ModelTypes["PriceSummaryWhereInput"] | undefined,
	isNot?: ModelTypes["PriceSummaryWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["PriceSummaryObjectEqualityInput"]: {
	days: number,
	discount?: number | undefined,
	payableRent: number,
	rentPerDay: number,
	serviceCharge: number,
	totalRent: number
};
	["PriceSummaryWhereInput"]: {
	AND?: Array<ModelTypes["PriceSummaryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PriceSummaryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PriceSummaryWhereInput"]> | undefined,
	days?: ModelTypes["IntFilter"] | undefined,
	discount?: ModelTypes["FloatNullableFilter"] | undefined,
	payableRent?: ModelTypes["FloatFilter"] | undefined,
	rentPerDay?: ModelTypes["IntFilter"] | undefined,
	serviceCharge?: ModelTypes["FloatFilter"] | undefined,
	totalRent?: ModelTypes["IntFilter"] | undefined
};
	["OrderSelectedDateCompositeFilter"]: {
	equals?: ModelTypes["OrderSelectedDateObjectEqualityInput"] | undefined,
	is?: ModelTypes["OrderSelectedDateWhereInput"] | undefined,
	isNot?: ModelTypes["OrderSelectedDateWhereInput"] | undefined
};
	["OrderSelectedDateObjectEqualityInput"]: {
	endDate: ModelTypes["DateTime"],
	startDate: ModelTypes["DateTime"]
};
	["OrderSelectedDateWhereInput"]: {
	AND?: Array<ModelTypes["OrderSelectedDateWhereInput"]> | undefined,
	OR?: Array<ModelTypes["OrderSelectedDateWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["OrderSelectedDateWhereInput"]> | undefined,
	endDate?: ModelTypes["DateTimeFilter"] | undefined,
	startDate?: ModelTypes["DateTimeFilter"] | undefined
};
	["UserRelationFilter"]: {
	is?: ModelTypes["UserWhereInput"] | undefined,
	isNot?: ModelTypes["UserWhereInput"] | undefined
};
	["UserWhereInput"]: {
	AND?: Array<ModelTypes["UserWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	uid?: ModelTypes["StringNullableFilter"] | undefined,
	email?: ModelTypes["StringFilter"] | undefined,
	image?: ModelTypes["StringNullableFilter"] | undefined,
	phoneNumber?: ModelTypes["StringNullableFilter"] | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	otherDetails?: ModelTypes["JsonNullableFilter"] | undefined,
	stripe?: ModelTypes["UserStripeNullableCompositeFilter"] | undefined,
	roles?: ModelTypes["EnumRoleNullableListFilter"] | undefined,
	refunds?: Array<ModelTypes["BookingRefundObjectEqualityInput"]> | undefined,
	sharedBookingIds?: ModelTypes["StringNullableListFilter"] | undefined,
	disclaimerAccepted?: ModelTypes["BoolFilter"] | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceNullableCompositeFilter"] | undefined,
	leadWorkspaceIds?: ModelTypes["StringNullableListFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	createdFiles?: ModelTypes["FileListRelationFilter"] | undefined,
	updatedFiles?: ModelTypes["FileListRelationFilter"] | undefined,
	orders?: ModelTypes["OrderListRelationFilter"] | undefined,
	changelogs?: ModelTypes["ChangelogListRelationFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	audits?: ModelTypes["AuditListRelationFilter"] | undefined,
	campaigns?: ModelTypes["CampaignListRelationFilter"] | undefined,
	sharedBookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	memberships?: ModelTypes["MembershipListRelationFilter"] | undefined,
	notifications?: ModelTypes["NotificationListRelationFilter"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryListRelationFilter"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentListRelationFilter"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceListRelationFilter"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceListRelationFilter"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewListRelationFilter"] | undefined,
	userPositions?: ModelTypes["UserPositionListRelationFilter"] | undefined
};
	["UserStripeNullableCompositeFilter"]: {
	equals?: ModelTypes["UserStripeObjectEqualityInput"] | undefined,
	is?: ModelTypes["UserStripeWhereInput"] | undefined,
	isNot?: ModelTypes["UserStripeWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["UserStripeObjectEqualityInput"]: {
	customerId: string
};
	["UserStripeWhereInput"]: {
	AND?: Array<ModelTypes["UserStripeWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserStripeWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserStripeWhereInput"]> | undefined,
	customerId?: ModelTypes["StringFilter"] | undefined
};
	["EnumRoleNullableListFilter"]: {
	equals?: Array<ModelTypes["Role"]> | undefined,
	has?: ModelTypes["Role"] | undefined,
	hasEvery?: Array<ModelTypes["Role"]> | undefined,
	hasSome?: Array<ModelTypes["Role"]> | undefined,
	isEmpty?: boolean | undefined
};
	["Role"]:Role;
	["BookingRefundObjectEqualityInput"]: {
	id: string,
	razorpay?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["JSON"] | undefined,
	createdByUserId: string
};
	["NotificationPreferenceNullableCompositeFilter"]: {
	equals?: ModelTypes["NotificationPreferenceObjectEqualityInput"] | undefined,
	is?: ModelTypes["NotificationPreferenceWhereInput"] | undefined,
	isNot?: ModelTypes["NotificationPreferenceWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["NotificationPreferenceObjectEqualityInput"]: {
	type?: Array<ModelTypes["NotificationType"]> | undefined,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined,
	pushSetting?: ModelTypes["PushSettingObjectEqualityInput"] | undefined,
	enabled: boolean
};
	["NotificationType"]:NotificationType;
	["NotificationChannel"]:NotificationChannel;
	["PushSettingObjectEqualityInput"]: {
	fcmToken: string
};
	["NotificationPreferenceWhereInput"]: {
	AND?: Array<ModelTypes["NotificationPreferenceWhereInput"]> | undefined,
	OR?: Array<ModelTypes["NotificationPreferenceWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["NotificationPreferenceWhereInput"]> | undefined,
	type?: ModelTypes["EnumNotificationTypeNullableListFilter"] | undefined,
	channels?: ModelTypes["EnumNotificationChannelNullableListFilter"] | undefined,
	pushSetting?: ModelTypes["PushSettingNullableCompositeFilter"] | undefined,
	enabled?: ModelTypes["BoolFilter"] | undefined
};
	["EnumNotificationTypeNullableListFilter"]: {
	equals?: Array<ModelTypes["NotificationType"]> | undefined,
	has?: ModelTypes["NotificationType"] | undefined,
	hasEvery?: Array<ModelTypes["NotificationType"]> | undefined,
	hasSome?: Array<ModelTypes["NotificationType"]> | undefined,
	isEmpty?: boolean | undefined
};
	["EnumNotificationChannelNullableListFilter"]: {
	equals?: Array<ModelTypes["NotificationChannel"]> | undefined,
	has?: ModelTypes["NotificationChannel"] | undefined,
	hasEvery?: Array<ModelTypes["NotificationChannel"]> | undefined,
	hasSome?: Array<ModelTypes["NotificationChannel"]> | undefined,
	isEmpty?: boolean | undefined
};
	["PushSettingNullableCompositeFilter"]: {
	equals?: ModelTypes["PushSettingObjectEqualityInput"] | undefined,
	is?: ModelTypes["PushSettingWhereInput"] | undefined,
	isNot?: ModelTypes["PushSettingWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["PushSettingWhereInput"]: {
	AND?: Array<ModelTypes["PushSettingWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PushSettingWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PushSettingWhereInput"]> | undefined,
	fcmToken?: ModelTypes["StringFilter"] | undefined
};
	["BookingListRelationFilter"]: {
	every?: ModelTypes["BookingWhereInput"] | undefined,
	some?: ModelTypes["BookingWhereInput"] | undefined,
	none?: ModelTypes["BookingWhereInput"] | undefined
};
	["BookingWhereInput"]: {
	AND?: Array<ModelTypes["BookingWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BookingWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	additionalInformation?: ModelTypes["StringNullableFilter"] | undefined,
	origin?: ModelTypes["StringNullableFilter"] | undefined,
	assetsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingCode?: ModelTypes["StringFilter"] | undefined,
	bookingDate?: ModelTypes["DateTimeFilter"] | undefined,
	bookingFor?: ModelTypes["StringFilter"] | undefined,
	purpose?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	campaignId?: ModelTypes["StringNullableFilter"] | undefined,
	businessCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	adCategoryId?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCompositeFilter"] | undefined,
	bookingStatus?: ModelTypes["EnumBookingStatusFilter"] | undefined,
	paymentStatus?: ModelTypes["EnumPaymentStatusNullableFilter"] | undefined,
	deploymentStatus?: ModelTypes["EnumDeploymentStatusFilter"] | undefined,
	contentApprovalStatus?: ModelTypes["EnumContentApprovalStatusFilter"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCompositeFilter"] | undefined,
	payment?: ModelTypes["BookingPaymentNullableCompositeFilter"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryNullableCompositeFilter"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataNullableCompositeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	refund?: ModelTypes["BookingRefundNullableCompositeFilter"] | undefined,
	sharedUserIds?: ModelTypes["StringNullableListFilter"] | undefined,
	isOffline?: ModelTypes["BoolFilter"] | undefined,
	isPaid?: ModelTypes["BoolFilter"] | undefined,
	isScheduled?: ModelTypes["BoolFilter"] | undefined,
	isDeployed?: ModelTypes["BoolFilter"] | undefined,
	isCompleted?: ModelTypes["BoolFilter"] | undefined,
	assets?: ModelTypes["FileListRelationFilter"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryNullableRelationFilter"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryListRelationFilter"] | undefined,
	proofs?: ModelTypes["FileListRelationFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	changelogs?: ModelTypes["ChangelogListRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	campaign?: ModelTypes["CampaignNullableRelationFilter"] | undefined,
	payouts?: ModelTypes["PayoutListRelationFilter"] | undefined,
	sharedUsers?: ModelTypes["UserListRelationFilter"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogListRelationFilter"] | undefined,
	slotUsage?: ModelTypes["SlotUsageListRelationFilter"] | undefined
};
	["BookingDetailsCompositeFilter"]: {
	equals?: ModelTypes["BookingDetailsObjectEqualityInput"] | undefined,
	is?: ModelTypes["BookingDetailsWhereInput"] | undefined,
	isNot?: ModelTypes["BookingDetailsWhereInput"] | undefined
};
	["BookingDetailsObjectEqualityInput"]: {
	additionalInformation?: string | undefined,
	email: string,
	name: string,
	phone?: string | undefined
};
	["BookingDetailsWhereInput"]: {
	AND?: Array<ModelTypes["BookingDetailsWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BookingDetailsWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingDetailsWhereInput"]> | undefined,
	additionalInformation?: ModelTypes["StringNullableFilter"] | undefined,
	email?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	phone?: ModelTypes["StringNullableFilter"] | undefined
};
	["EnumBookingStatusFilter"]: {
	equals?: ModelTypes["BookingStatus"] | undefined,
	in?: Array<ModelTypes["BookingStatus"]> | undefined,
	notIn?: Array<ModelTypes["BookingStatus"]> | undefined,
	not?: ModelTypes["NestedEnumBookingStatusFilter"] | undefined
};
	["BookingStatus"]:BookingStatus;
	["NestedEnumBookingStatusFilter"]: {
	equals?: ModelTypes["BookingStatus"] | undefined,
	in?: Array<ModelTypes["BookingStatus"]> | undefined,
	notIn?: Array<ModelTypes["BookingStatus"]> | undefined,
	not?: ModelTypes["NestedEnumBookingStatusFilter"] | undefined
};
	["EnumPaymentStatusNullableFilter"]: {
	equals?: ModelTypes["PaymentStatus"] | undefined,
	in?: Array<ModelTypes["PaymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["PaymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumPaymentStatusNullableFilter"]: {
	equals?: ModelTypes["PaymentStatus"] | undefined,
	in?: Array<ModelTypes["PaymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["PaymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["EnumDeploymentStatusFilter"]: {
	equals?: ModelTypes["DeploymentStatus"] | undefined,
	in?: Array<ModelTypes["DeploymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["DeploymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumDeploymentStatusFilter"] | undefined
};
	["DeploymentStatus"]:DeploymentStatus;
	["NestedEnumDeploymentStatusFilter"]: {
	equals?: ModelTypes["DeploymentStatus"] | undefined,
	in?: Array<ModelTypes["DeploymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["DeploymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumDeploymentStatusFilter"] | undefined
};
	["EnumContentApprovalStatusFilter"]: {
	equals?: ModelTypes["ContentApprovalStatus"] | undefined,
	in?: Array<ModelTypes["ContentApprovalStatus"]> | undefined,
	notIn?: Array<ModelTypes["ContentApprovalStatus"]> | undefined,
	not?: ModelTypes["NestedEnumContentApprovalStatusFilter"] | undefined
};
	["ContentApprovalStatus"]:ContentApprovalStatus;
	["NestedEnumContentApprovalStatusFilter"]: {
	equals?: ModelTypes["ContentApprovalStatus"] | undefined,
	in?: Array<ModelTypes["ContentApprovalStatus"]> | undefined,
	notIn?: Array<ModelTypes["ContentApprovalStatus"]> | undefined,
	not?: ModelTypes["NestedEnumContentApprovalStatusFilter"] | undefined
};
	["BookingPisignageDataNullableCompositeFilter"]: {
	equals?: ModelTypes["BookingPisignageDataObjectEqualityInput"] | undefined,
	is?: ModelTypes["BookingPisignageDataWhereInput"] | undefined,
	isNot?: ModelTypes["BookingPisignageDataWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["BookingPisignageDataObjectEqualityInput"]: {
	deployedFileName?: string | undefined,
	playlistId?: string | undefined
};
	["BookingPisignageDataWhereInput"]: {
	AND?: Array<ModelTypes["BookingPisignageDataWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BookingPisignageDataWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingPisignageDataWhereInput"]> | undefined,
	deployedFileName?: ModelTypes["StringNullableFilter"] | undefined,
	playlistId?: ModelTypes["StringNullableFilter"] | undefined
};
	["BookingRefundNullableCompositeFilter"]: {
	equals?: ModelTypes["BookingRefundObjectEqualityInput"] | undefined,
	is?: ModelTypes["BookingRefundWhereInput"] | undefined,
	isNot?: ModelTypes["BookingRefundWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["BookingRefundWhereInput"]: {
	AND?: Array<ModelTypes["BookingRefundWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BookingRefundWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingRefundWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	razorpay?: ModelTypes["JsonNullableFilter"] | undefined,
	stripe?: ModelTypes["JsonNullableFilter"] | undefined,
	createdByUserId?: ModelTypes["StringFilter"] | undefined
};
	["FileListRelationFilter"]: {
	every?: ModelTypes["FileWhereInput"] | undefined,
	some?: ModelTypes["FileWhereInput"] | undefined,
	none?: ModelTypes["FileWhereInput"] | undefined
};
	["FileWhereInput"]: {
	AND?: Array<ModelTypes["FileWhereInput"]> | undefined,
	OR?: Array<ModelTypes["FileWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["FileWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	contentType?: ModelTypes["StringFilter"] | undefined,
	ctime?: ModelTypes["StringFilter"] | undefined,
	filename?: ModelTypes["StringFilter"] | undefined,
	filepath?: ModelTypes["StringNullableFilter"] | undefined,
	isDirectory?: ModelTypes["BoolFilter"] | undefined,
	meta?: ModelTypes["FileMetaCompositeFilter"] | undefined,
	mtime?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	size?: ModelTypes["IntFilter"] | undefined,
	status?: ModelTypes["EnumFileStatusNullableFilter"] | undefined,
	type?: ModelTypes["StringFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	createdByUserId?: ModelTypes["StringFilter"] | undefined,
	url?: ModelTypes["StringFilter"] | undefined,
	bookingsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	proofBookingId?: ModelTypes["StringNullableFilter"] | undefined,
	campaignIds?: ModelTypes["StringNullableListFilter"] | undefined,
	updatedBy?: ModelTypes["UserNullableRelationFilter"] | undefined,
	createdBy?: ModelTypes["UserRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	proofBooking?: ModelTypes["BookingNullableRelationFilter"] | undefined,
	campaigns?: ModelTypes["CampaignListRelationFilter"] | undefined
};
	["FileMetaCompositeFilter"]: {
	equals?: ModelTypes["FileMetaObjectEqualityInput"] | undefined,
	is?: ModelTypes["FileMetaWhereInput"] | undefined,
	isNot?: ModelTypes["FileMetaWhereInput"] | undefined
};
	["FileMetaObjectEqualityInput"]: {
	duration: number,
	height: number,
	width: number
};
	["FileMetaWhereInput"]: {
	AND?: Array<ModelTypes["FileMetaWhereInput"]> | undefined,
	OR?: Array<ModelTypes["FileMetaWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["FileMetaWhereInput"]> | undefined,
	duration?: ModelTypes["FloatFilter"] | undefined,
	height?: ModelTypes["IntFilter"] | undefined,
	width?: ModelTypes["IntFilter"] | undefined
};
	["EnumFileStatusNullableFilter"]: {
	equals?: ModelTypes["FileStatus"] | undefined,
	in?: Array<ModelTypes["FileStatus"]> | undefined,
	notIn?: Array<ModelTypes["FileStatus"]> | undefined,
	not?: ModelTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["FileStatus"]:FileStatus;
	["NestedEnumFileStatusNullableFilter"]: {
	equals?: ModelTypes["FileStatus"] | undefined,
	in?: Array<ModelTypes["FileStatus"]> | undefined,
	notIn?: Array<ModelTypes["FileStatus"]> | undefined,
	not?: ModelTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["UserNullableRelationFilter"]: {
	is?: ModelTypes["UserWhereInput"] | undefined,
	isNot?: ModelTypes["UserWhereInput"] | undefined
};
	["WorkspaceNullableRelationFilter"]: {
	is?: ModelTypes["WorkspaceWhereInput"] | undefined,
	isNot?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceWhereInput"]: {
	AND?: Array<ModelTypes["WorkspaceWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WorkspaceWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WorkspaceWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumWorkspaceTypeFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	key?: ModelTypes["StringFilter"] | undefined,
	userIds?: ModelTypes["StringNullableListFilter"] | undefined,
	ownerId?: ModelTypes["StringNullableFilter"] | undefined,
	avatar?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	stripeSubscriptionId?: ModelTypes["StringNullableFilter"] | undefined,
	leadIds?: ModelTypes["StringNullableListFilter"] | undefined,
	owner?: ModelTypes["UserNullableRelationFilter"] | undefined,
	memberships?: ModelTypes["MembershipListRelationFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	playlists?: ModelTypes["PlaylistListRelationFilter"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupListRelationFilter"] | undefined,
	audits?: ModelTypes["AuditListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	files?: ModelTypes["FileListRelationFilter"] | undefined,
	players?: ModelTypes["PlayerListRelationFilter"] | undefined,
	transactions?: ModelTypes["TransactionListRelationFilter"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionNullableRelationFilter"] | undefined,
	cameraServers?: ModelTypes["CameraServerListRelationFilter"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleListRelationFilter"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogListRelationFilter"] | undefined,
	leads?: ModelTypes["UserListRelationFilter"] | undefined
};
	["EnumWorkspaceTypeFilter"]: {
	equals?: ModelTypes["WorkspaceType"] | undefined,
	in?: Array<ModelTypes["WorkspaceType"]> | undefined,
	notIn?: Array<ModelTypes["WorkspaceType"]> | undefined,
	not?: ModelTypes["NestedEnumWorkspaceTypeFilter"] | undefined
};
	["WorkspaceType"]:WorkspaceType;
	["NestedEnumWorkspaceTypeFilter"]: {
	equals?: ModelTypes["WorkspaceType"] | undefined,
	in?: Array<ModelTypes["WorkspaceType"]> | undefined,
	notIn?: Array<ModelTypes["WorkspaceType"]> | undefined,
	not?: ModelTypes["NestedEnumWorkspaceTypeFilter"] | undefined
};
	["MembershipListRelationFilter"]: {
	every?: ModelTypes["MembershipWhereInput"] | undefined,
	some?: ModelTypes["MembershipWhereInput"] | undefined,
	none?: ModelTypes["MembershipWhereInput"] | undefined
};
	["MembershipWhereInput"]: {
	AND?: Array<ModelTypes["MembershipWhereInput"]> | undefined,
	OR?: Array<ModelTypes["MembershipWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["MembershipWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	roleId?: ModelTypes["StringFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceRelationFilter"] | undefined,
	role?: ModelTypes["WorkspaceRoleRelationFilter"] | undefined
};
	["WorkspaceRelationFilter"]: {
	is?: ModelTypes["WorkspaceWhereInput"] | undefined,
	isNot?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceRoleRelationFilter"]: {
	is?: ModelTypes["WorkspaceRoleWhereInput"] | undefined,
	isNot?: ModelTypes["WorkspaceRoleWhereInput"] | undefined
};
	["WorkspaceRoleWhereInput"]: {
	AND?: Array<ModelTypes["WorkspaceRoleWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WorkspaceRoleWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WorkspaceRoleWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	permissions?: ModelTypes["EnumPermissionNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	memberships?: ModelTypes["MembershipListRelationFilter"] | undefined
};
	["EnumPermissionNullableListFilter"]: {
	equals?: Array<ModelTypes["Permission"]> | undefined,
	has?: ModelTypes["Permission"] | undefined,
	hasEvery?: Array<ModelTypes["Permission"]> | undefined,
	hasSome?: Array<ModelTypes["Permission"]> | undefined,
	isEmpty?: boolean | undefined
};
	["Permission"]:Permission;
	["PlaylistListRelationFilter"]: {
	every?: ModelTypes["PlaylistWhereInput"] | undefined,
	some?: ModelTypes["PlaylistWhereInput"] | undefined,
	none?: ModelTypes["PlaylistWhereInput"] | undefined
};
	["PlaylistWhereInput"]: {
	AND?: Array<ModelTypes["PlaylistWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlaylistWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlaylistWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	assets?: Array<ModelTypes["PisignageFileObjectEqualityInput"]> | undefined,
	isAdsPlaylist?: ModelTypes["BoolNullableFilter"] | undefined,
	footerPlaylistId?: ModelTypes["StringNullableFilter"] | undefined,
	sidebarPlaylistId?: ModelTypes["StringNullableFilter"] | undefined,
	layout?: ModelTypes["EnumScreenLayoutFilter"] | undefined,
	isDeployed?: ModelTypes["BoolNullableFilter"] | undefined,
	adBoardGroupIds?: ModelTypes["StringNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	footerPlaylist?: ModelTypes["PlaylistNullableRelationFilter"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistNullableRelationFilter"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupListRelationFilter"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupListRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceRelationFilter"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistListRelationFilter"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistListRelationFilter"] | undefined
};
	["PisignageFileObjectEqualityInput"]: {
	name: string,
	duration?: number | undefined,
	muteAudio?: boolean | undefined,
	isVideo?: boolean | undefined
};
	["BoolNullableFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["NestedBoolNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedBoolNullableFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["NestedBoolNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["EnumScreenLayoutFilter"]: {
	equals?: ModelTypes["ScreenLayout"] | undefined,
	in?: Array<ModelTypes["ScreenLayout"]> | undefined,
	notIn?: Array<ModelTypes["ScreenLayout"]> | undefined,
	not?: ModelTypes["NestedEnumScreenLayoutFilter"] | undefined
};
	["ScreenLayout"]:ScreenLayout;
	["NestedEnumScreenLayoutFilter"]: {
	equals?: ModelTypes["ScreenLayout"] | undefined,
	in?: Array<ModelTypes["ScreenLayout"]> | undefined,
	notIn?: Array<ModelTypes["ScreenLayout"]> | undefined,
	not?: ModelTypes["NestedEnumScreenLayoutFilter"] | undefined
};
	["PlaylistNullableRelationFilter"]: {
	is?: ModelTypes["PlaylistWhereInput"] | undefined,
	isNot?: ModelTypes["PlaylistWhereInput"] | undefined
};
	["AdBoardGroupListRelationFilter"]: {
	every?: ModelTypes["AdBoardGroupWhereInput"] | undefined,
	some?: ModelTypes["AdBoardGroupWhereInput"] | undefined,
	none?: ModelTypes["AdBoardGroupWhereInput"] | undefined
};
	["AdBoardGroupWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardGroupWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardGroupWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardGroupWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringNullableFilter"] | undefined,
	schedules?: Array<ModelTypes["ScheduleObjectEqualityInput"]> | undefined,
	scheduledPlaylistsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	adsPlaylistID?: ModelTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: ModelTypes["StringFilter"] | undefined,
	fitImage?: ModelTypes["EnumFitTypeFilter"] | undefined,
	fitVideo?: ModelTypes["EnumFitTypeFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardNullableRelationFilter"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistListRelationFilter"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistNullableRelationFilter"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerListRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceRelationFilter"] | undefined
};
	["ScheduleObjectEqualityInput"]: {
	playlistId: string,
	playlistType?: string | undefined,
	skipForSchedule?: boolean | undefined,
	startTime?: string | undefined,
	endTime?: string | undefined
};
	["EnumFitTypeFilter"]: {
	equals?: ModelTypes["FitType"] | undefined,
	in?: Array<ModelTypes["FitType"]> | undefined,
	notIn?: Array<ModelTypes["FitType"]> | undefined,
	not?: ModelTypes["NestedEnumFitTypeFilter"] | undefined
};
	["FitType"]:FitType;
	["NestedEnumFitTypeFilter"]: {
	equals?: ModelTypes["FitType"] | undefined,
	in?: Array<ModelTypes["FitType"]> | undefined,
	notIn?: Array<ModelTypes["FitType"]> | undefined,
	not?: ModelTypes["NestedEnumFitTypeFilter"] | undefined
};
	["AdBoardNullableRelationFilter"]: {
	is?: ModelTypes["AdBoardWhereInput"] | undefined,
	isNot?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["PlayerListRelationFilter"]: {
	every?: ModelTypes["PlayerWhereInput"] | undefined,
	some?: ModelTypes["PlayerWhereInput"] | undefined,
	none?: ModelTypes["PlayerWhereInput"] | undefined
};
	["PlayerWhereInput"]: {
	AND?: Array<ModelTypes["PlayerWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlayerWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlayerWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	configLocation?: ModelTypes["StringNullableFilter"] | undefined,
	pisignagePlayerId?: ModelTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: ModelTypes["StringNullableFilter"] | undefined,
	cpuSerialNumber?: ModelTypes["StringNullableFilter"] | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	adBoardGroupId?: ModelTypes["StringNullableFilter"] | undefined,
	playlistIds?: ModelTypes["StringNullableListFilter"] | undefined,
	workpaceId?: ModelTypes["StringNullableFilter"] | undefined,
	resolution?: ModelTypes["ResolutionNullableCompositeFilter"] | undefined,
	screenSize?: ModelTypes["ScreenSizeNullableCompositeFilter"] | undefined,
	signageTypeId?: ModelTypes["StringNullableFilter"] | undefined,
	audienceTypeId?: ModelTypes["StringNullableListFilter"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeObjectEqualityInput"]> | undefined,
	aspectRatioType?: ModelTypes["EnumAspectRatioTypeFilter"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileObjectEqualityInput"]> | undefined,
	customAspectRatioWidth?: ModelTypes["IntNullableFilter"] | undefined,
	customAspectRatioHeight?: ModelTypes["IntNullableFilter"] | undefined,
	map?: ModelTypes["MapNullableCompositeFilter"] | undefined,
	isOnline?: ModelTypes["BoolFilter"] | undefined,
	adBoardIds?: ModelTypes["StringNullableListFilter"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupNullableRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusListRelationFilter"] | undefined,
	cameras?: ModelTypes["CameraListRelationFilter"] | undefined,
	signageType?: ModelTypes["DigitalSignageNullableRelationFilter"] | undefined,
	audienceType?: ModelTypes["AudienceListRelationFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined
};
	["EnumAspectRatioTypeFilter"]: {
	equals?: ModelTypes["AspectRatioType"] | undefined,
	in?: Array<ModelTypes["AspectRatioType"]> | undefined,
	notIn?: Array<ModelTypes["AspectRatioType"]> | undefined,
	not?: ModelTypes["NestedEnumAspectRatioTypeFilter"] | undefined
};
	["AspectRatioType"]:AspectRatioType;
	["NestedEnumAspectRatioTypeFilter"]: {
	equals?: ModelTypes["AspectRatioType"] | undefined,
	in?: Array<ModelTypes["AspectRatioType"]> | undefined,
	notIn?: Array<ModelTypes["AspectRatioType"]> | undefined,
	not?: ModelTypes["NestedEnumAspectRatioTypeFilter"] | undefined
};
	["GalleryFileObjectEqualityInput"]: {
	name: string,
	url: string,
	contentType: string,
	width: number,
	height: number,
	duration?: number | undefined
};
	["MapNullableCompositeFilter"]: {
	equals?: ModelTypes["MapObjectEqualityInput"] | undefined,
	is?: ModelTypes["MapWhereInput"] | undefined,
	isNot?: ModelTypes["MapWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["AdBoardGroupNullableRelationFilter"]: {
	is?: ModelTypes["AdBoardGroupWhereInput"] | undefined,
	isNot?: ModelTypes["AdBoardGroupWhereInput"] | undefined
};
	["PlayerUptimeStatusListRelationFilter"]: {
	every?: ModelTypes["PlayerUptimeStatusWhereInput"] | undefined,
	some?: ModelTypes["PlayerUptimeStatusWhereInput"] | undefined,
	none?: ModelTypes["PlayerUptimeStatusWhereInput"] | undefined
};
	["PlayerUptimeStatusWhereInput"]: {
	AND?: Array<ModelTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	playerId?: ModelTypes["StringFilter"] | undefined,
	cpuSerialNumber?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["StringFilter"] | undefined,
	uptime?: ModelTypes["FloatFilter"] | undefined,
	player?: ModelTypes["PlayerRelationFilter"] | undefined
};
	["PlayerRelationFilter"]: {
	is?: ModelTypes["PlayerWhereInput"] | undefined,
	isNot?: ModelTypes["PlayerWhereInput"] | undefined
};
	["CameraListRelationFilter"]: {
	every?: ModelTypes["CameraWhereInput"] | undefined,
	some?: ModelTypes["CameraWhereInput"] | undefined,
	none?: ModelTypes["CameraWhereInput"] | undefined
};
	["CameraWhereInput"]: {
	AND?: Array<ModelTypes["CameraWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringNullableFilter"] | undefined,
	isOnline?: ModelTypes["BoolFilter"] | undefined,
	cameraServerId?: ModelTypes["StringFilter"] | undefined,
	cameraUrl?: ModelTypes["StringFilter"] | undefined,
	rtspUrl?: ModelTypes["StringFilter"] | undefined,
	cameraIp?: ModelTypes["StringFilter"] | undefined,
	connectedCpuSerialNumbers?: ModelTypes["StringNullableListFilter"] | undefined,
	playerId?: ModelTypes["StringNullableFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	isPublic?: ModelTypes["BoolFilter"] | undefined,
	adBoardId?: ModelTypes["StringNullableFilter"] | undefined,
	cameraServer?: ModelTypes["CameraServerRelationFilter"] | undefined,
	player?: ModelTypes["PlayerNullableRelationFilter"] | undefined,
	feeds?: ModelTypes["CameraFeedListRelationFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardNullableRelationFilter"] | undefined
};
	["CameraServerRelationFilter"]: {
	is?: ModelTypes["CameraServerWhereInput"] | undefined,
	isNot?: ModelTypes["CameraServerWhereInput"] | undefined
};
	["CameraServerWhereInput"]: {
	AND?: Array<ModelTypes["CameraServerWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraServerWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraServerWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringNullableFilter"] | undefined,
	isOnline?: ModelTypes["BoolNullableFilter"] | undefined,
	hostName?: ModelTypes["StringNullableFilter"] | undefined,
	userName?: ModelTypes["StringNullableFilter"] | undefined,
	deviceId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	publicIp?: ModelTypes["StringFilter"] | undefined,
	privateIp?: ModelTypes["StringFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	cameras?: ModelTypes["CameraListRelationFilter"] | undefined,
	feeds?: ModelTypes["CameraFeedListRelationFilter"] | undefined
};
	["CameraFeedListRelationFilter"]: {
	every?: ModelTypes["CameraFeedWhereInput"] | undefined,
	some?: ModelTypes["CameraFeedWhereInput"] | undefined,
	none?: ModelTypes["CameraFeedWhereInput"] | undefined
};
	["CameraFeedWhereInput"]: {
	AND?: Array<ModelTypes["CameraFeedWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraFeedWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraFeedWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	cameraId?: ModelTypes["StringFilter"] | undefined,
	timestamp?: ModelTypes["DateTimeFilter"] | undefined,
	cameraServerId?: ModelTypes["StringFilter"] | undefined,
	data?: ModelTypes["CameraFeedDataCompositeFilter"] | undefined,
	camera?: ModelTypes["CameraRelationFilter"] | undefined,
	cameraServer?: ModelTypes["CameraServerRelationFilter"] | undefined
};
	["CameraFeedDataCompositeFilter"]: {
	equals?: ModelTypes["CameraFeedDataObjectEqualityInput"] | undefined,
	is?: ModelTypes["CameraFeedDataWhereInput"] | undefined,
	isNot?: ModelTypes["CameraFeedDataWhereInput"] | undefined
};
	["CameraFeedDataObjectEqualityInput"]: {
	person?: number | undefined,
	car?: number | undefined,
	truck?: number | undefined,
	motorcycle?: number | undefined
};
	["CameraFeedDataWhereInput"]: {
	AND?: Array<ModelTypes["CameraFeedDataWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraFeedDataWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraFeedDataWhereInput"]> | undefined,
	person?: ModelTypes["IntNullableFilter"] | undefined,
	car?: ModelTypes["IntNullableFilter"] | undefined,
	truck?: ModelTypes["IntNullableFilter"] | undefined,
	motorcycle?: ModelTypes["IntNullableFilter"] | undefined
};
	["CameraRelationFilter"]: {
	is?: ModelTypes["CameraWhereInput"] | undefined,
	isNot?: ModelTypes["CameraWhereInput"] | undefined
};
	["PlayerNullableRelationFilter"]: {
	is?: ModelTypes["PlayerWhereInput"] | undefined,
	isNot?: ModelTypes["PlayerWhereInput"] | undefined
};
	["DigitalSignageNullableRelationFilter"]: {
	is?: ModelTypes["DigitalSignageWhereInput"] | undefined,
	isNot?: ModelTypes["DigitalSignageWhereInput"] | undefined
};
	["DigitalSignageWhereInput"]: {
	AND?: Array<ModelTypes["DigitalSignageWhereInput"]> | undefined,
	OR?: Array<ModelTypes["DigitalSignageWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["DigitalSignageWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumSignageTypeFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	players?: ModelTypes["PlayerListRelationFilter"] | undefined
};
	["EnumSignageTypeFilter"]: {
	equals?: ModelTypes["SignageType"] | undefined,
	in?: Array<ModelTypes["SignageType"]> | undefined,
	notIn?: Array<ModelTypes["SignageType"]> | undefined,
	not?: ModelTypes["NestedEnumSignageTypeFilter"] | undefined
};
	["SignageType"]:SignageType;
	["NestedEnumSignageTypeFilter"]: {
	equals?: ModelTypes["SignageType"] | undefined,
	in?: Array<ModelTypes["SignageType"]> | undefined,
	notIn?: Array<ModelTypes["SignageType"]> | undefined,
	not?: ModelTypes["NestedEnumSignageTypeFilter"] | undefined
};
	["AudienceListRelationFilter"]: {
	every?: ModelTypes["AudienceWhereInput"] | undefined,
	some?: ModelTypes["AudienceWhereInput"] | undefined,
	none?: ModelTypes["AudienceWhereInput"] | undefined
};
	["AudienceWhereInput"]: {
	AND?: Array<ModelTypes["AudienceWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AudienceWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AudienceWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumAudienceTypeFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	playerIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	players?: ModelTypes["PlayerListRelationFilter"] | undefined
};
	["EnumAudienceTypeFilter"]: {
	equals?: ModelTypes["AudienceType"] | undefined,
	in?: Array<ModelTypes["AudienceType"]> | undefined,
	notIn?: Array<ModelTypes["AudienceType"]> | undefined,
	not?: ModelTypes["NestedEnumAudienceTypeFilter"] | undefined
};
	["AudienceType"]:AudienceType;
	["NestedEnumAudienceTypeFilter"]: {
	equals?: ModelTypes["AudienceType"] | undefined,
	in?: Array<ModelTypes["AudienceType"]> | undefined,
	notIn?: Array<ModelTypes["AudienceType"]> | undefined,
	not?: ModelTypes["NestedEnumAudienceTypeFilter"] | undefined
};
	["AuditListRelationFilter"]: {
	every?: ModelTypes["AuditWhereInput"] | undefined,
	some?: ModelTypes["AuditWhereInput"] | undefined,
	none?: ModelTypes["AuditWhereInput"] | undefined
};
	["AuditWhereInput"]: {
	AND?: Array<ModelTypes["AuditWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AuditWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AuditWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	message?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["StringFilter"] | undefined,
	entity?: ModelTypes["EntityNullableCompositeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	updates?: ModelTypes["JsonNullableFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined
};
	["EntityNullableCompositeFilter"]: {
	equals?: ModelTypes["EntityObjectEqualityInput"] | undefined,
	is?: ModelTypes["EntityWhereInput"] | undefined,
	isNot?: ModelTypes["EntityWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["EntityObjectEqualityInput"]: {
	type: string,
	id: string,
	name?: string | undefined
};
	["EntityWhereInput"]: {
	AND?: Array<ModelTypes["EntityWhereInput"]> | undefined,
	OR?: Array<ModelTypes["EntityWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["EntityWhereInput"]> | undefined,
	type?: ModelTypes["StringFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringNullableFilter"] | undefined
};
	["TransactionListRelationFilter"]: {
	every?: ModelTypes["TransactionWhereInput"] | undefined,
	some?: ModelTypes["TransactionWhereInput"] | undefined,
	none?: ModelTypes["TransactionWhereInput"] | undefined
};
	["TransactionWhereInput"]: {
	AND?: Array<ModelTypes["TransactionWhereInput"]> | undefined,
	OR?: Array<ModelTypes["TransactionWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["TransactionWhereInput"]> | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	amount?: ModelTypes["FloatFilter"] | undefined,
	createAt?: ModelTypes["DateTimeFilter"] | undefined,
	processedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	relatedEntityId?: ModelTypes["StringFilter"] | undefined,
	transactionStatus?: ModelTypes["EnumTransactionStatusFilter"] | undefined,
	details?: ModelTypes["JsonNullableFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	payouts?: ModelTypes["PayoutListRelationFilter"] | undefined
};
	["EnumTransactionStatusFilter"]: {
	equals?: ModelTypes["TransactionStatus"] | undefined,
	in?: Array<ModelTypes["TransactionStatus"]> | undefined,
	notIn?: Array<ModelTypes["TransactionStatus"]> | undefined,
	not?: ModelTypes["NestedEnumTransactionStatusFilter"] | undefined
};
	["TransactionStatus"]:TransactionStatus;
	["NestedEnumTransactionStatusFilter"]: {
	equals?: ModelTypes["TransactionStatus"] | undefined,
	in?: Array<ModelTypes["TransactionStatus"]> | undefined,
	notIn?: Array<ModelTypes["TransactionStatus"]> | undefined,
	not?: ModelTypes["NestedEnumTransactionStatusFilter"] | undefined
};
	["PayoutListRelationFilter"]: {
	every?: ModelTypes["PayoutWhereInput"] | undefined,
	some?: ModelTypes["PayoutWhereInput"] | undefined,
	none?: ModelTypes["PayoutWhereInput"] | undefined
};
	["PayoutWhereInput"]: {
	AND?: Array<ModelTypes["PayoutWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PayoutWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PayoutWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	totalAmount?: ModelTypes["FloatFilter"] | undefined,
	commissionRate?: ModelTypes["FloatFilter"] | undefined,
	commissionAmount?: ModelTypes["FloatFilter"] | undefined,
	netAmount?: ModelTypes["FloatFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	status?: ModelTypes["EnumPayoutStatusFilter"] | undefined,
	transactionId?: ModelTypes["StringNullableFilter"] | undefined,
	booking?: ModelTypes["BookingRelationFilter"] | undefined,
	transaction?: ModelTypes["TransactionNullableRelationFilter"] | undefined
};
	["EnumPayoutStatusFilter"]: {
	equals?: ModelTypes["PayoutStatus"] | undefined,
	in?: Array<ModelTypes["PayoutStatus"]> | undefined,
	notIn?: Array<ModelTypes["PayoutStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPayoutStatusFilter"] | undefined
};
	["PayoutStatus"]:PayoutStatus;
	["NestedEnumPayoutStatusFilter"]: {
	equals?: ModelTypes["PayoutStatus"] | undefined,
	in?: Array<ModelTypes["PayoutStatus"]> | undefined,
	notIn?: Array<ModelTypes["PayoutStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPayoutStatusFilter"] | undefined
};
	["BookingRelationFilter"]: {
	is?: ModelTypes["BookingWhereInput"] | undefined,
	isNot?: ModelTypes["BookingWhereInput"] | undefined
};
	["TransactionNullableRelationFilter"]: {
	is?: ModelTypes["TransactionWhereInput"] | undefined,
	isNot?: ModelTypes["TransactionWhereInput"] | undefined
};
	["SubscriptionNullableRelationFilter"]: {
	is?: ModelTypes["SubscriptionWhereInput"] | undefined,
	isNot?: ModelTypes["SubscriptionWhereInput"] | undefined
};
	["SubscriptionWhereInput"]: {
	AND?: Array<ModelTypes["SubscriptionWhereInput"]> | undefined,
	OR?: Array<ModelTypes["SubscriptionWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["SubscriptionWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	packageId?: ModelTypes["StringNullableFilter"] | undefined,
	packageName?: ModelTypes["StringNullableFilter"] | undefined,
	priceLookupKey?: ModelTypes["StringNullableFilter"] | undefined,
	startDate?: ModelTypes["DateTimeNullableFilter"] | undefined,
	endDate?: ModelTypes["DateTimeNullableFilter"] | undefined,
	status?: ModelTypes["StringFilter"] | undefined,
	stripePaymentIntentId?: ModelTypes["StringNullableFilter"] | undefined,
	stripeSubscriptionId?: ModelTypes["StringFilter"] | undefined,
	stripeCheckoutSessionId?: ModelTypes["StringFilter"] | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsNullableCompositeFilter"] | undefined,
	activeWorkspaceId?: ModelTypes["StringFilter"] | undefined,
	activeWorkspace?: ModelTypes["WorkspaceRelationFilter"] | undefined
};
	["CancellationDetailsNullableCompositeFilter"]: {
	equals?: ModelTypes["CancellationDetailsObjectEqualityInput"] | undefined,
	is?: ModelTypes["CancellationDetailsWhereInput"] | undefined,
	isNot?: ModelTypes["CancellationDetailsWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["CancellationDetailsObjectEqualityInput"]: {
	canceledAt?: ModelTypes["DateTime"] | undefined,
	cancelAt?: ModelTypes["DateTime"] | undefined,
	cancelAtPeriodEnd: boolean,
	reason?: string | undefined
};
	["CancellationDetailsWhereInput"]: {
	AND?: Array<ModelTypes["CancellationDetailsWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CancellationDetailsWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CancellationDetailsWhereInput"]> | undefined,
	canceledAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	cancelAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	cancelAtPeriodEnd?: ModelTypes["BoolFilter"] | undefined,
	reason?: ModelTypes["StringNullableFilter"] | undefined
};
	["CameraServerListRelationFilter"]: {
	every?: ModelTypes["CameraServerWhereInput"] | undefined,
	some?: ModelTypes["CameraServerWhereInput"] | undefined,
	none?: ModelTypes["CameraServerWhereInput"] | undefined
};
	["WorkspaceRoleListRelationFilter"]: {
	every?: ModelTypes["WorkspaceRoleWhereInput"] | undefined,
	some?: ModelTypes["WorkspaceRoleWhereInput"] | undefined,
	none?: ModelTypes["WorkspaceRoleWhereInput"] | undefined
};
	["WebhookLogListRelationFilter"]: {
	every?: ModelTypes["WebhookLogWhereInput"] | undefined,
	some?: ModelTypes["WebhookLogWhereInput"] | undefined,
	none?: ModelTypes["WebhookLogWhereInput"] | undefined
};
	["WebhookLogWhereInput"]: {
	AND?: Array<ModelTypes["WebhookLogWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WebhookLogWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WebhookLogWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	response?: ModelTypes["JsonNullableFilter"] | undefined,
	request?: ModelTypes["JsonNullableFilter"] | undefined,
	status?: ModelTypes["IntFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	booking?: ModelTypes["BookingRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined
};
	["UserListRelationFilter"]: {
	every?: ModelTypes["UserWhereInput"] | undefined,
	some?: ModelTypes["UserWhereInput"] | undefined,
	none?: ModelTypes["UserWhereInput"] | undefined
};
	["BookingNullableRelationFilter"]: {
	is?: ModelTypes["BookingWhereInput"] | undefined,
	isNot?: ModelTypes["BookingWhereInput"] | undefined
};
	["AdvertisementCategoryListRelationFilter"]: {
	every?: ModelTypes["AdvertisementCategoryWhereInput"] | undefined,
	some?: ModelTypes["AdvertisementCategoryWhereInput"] | undefined,
	none?: ModelTypes["AdvertisementCategoryWhereInput"] | undefined
};
	["AdvertisementCategoryWhereInput"]: {
	AND?: Array<ModelTypes["AdvertisementCategoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdvertisementCategoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdvertisementCategoryWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumAdvertisementCategoryEnumFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	campaignIds?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingIds?: ModelTypes["StringNullableListFilter"] | undefined,
	campaigns?: ModelTypes["CampaignListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined
};
	["EnumAdvertisementCategoryEnumFilter"]: {
	equals?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	in?: Array<ModelTypes["AdvertisementCategoryEnum"]> | undefined,
	notIn?: Array<ModelTypes["AdvertisementCategoryEnum"]> | undefined,
	not?: ModelTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined
};
	["AdvertisementCategoryEnum"]:AdvertisementCategoryEnum;
	["NestedEnumAdvertisementCategoryEnumFilter"]: {
	equals?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	in?: Array<ModelTypes["AdvertisementCategoryEnum"]> | undefined,
	notIn?: Array<ModelTypes["AdvertisementCategoryEnum"]> | undefined,
	not?: ModelTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined
};
	["AdBoardRelationFilter"]: {
	is?: ModelTypes["AdBoardWhereInput"] | undefined,
	isNot?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["ChangelogListRelationFilter"]: {
	every?: ModelTypes["ChangelogWhereInput"] | undefined,
	some?: ModelTypes["ChangelogWhereInput"] | undefined,
	none?: ModelTypes["ChangelogWhereInput"] | undefined
};
	["ChangelogWhereInput"]: {
	AND?: Array<ModelTypes["ChangelogWhereInput"]> | undefined,
	OR?: Array<ModelTypes["ChangelogWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["ChangelogWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	message?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["StringFilter"] | undefined,
	bookingId?: ModelTypes["StringNullableFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	updates?: ModelTypes["JsonNullableFilter"] | undefined,
	booking?: ModelTypes["BookingNullableRelationFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined
};
	["CampaignNullableRelationFilter"]: {
	is?: ModelTypes["CampaignWhereInput"] | undefined,
	isNot?: ModelTypes["CampaignWhereInput"] | undefined
};
	["SlotUsageListRelationFilter"]: {
	every?: ModelTypes["SlotUsageWhereInput"] | undefined,
	some?: ModelTypes["SlotUsageWhereInput"] | undefined,
	none?: ModelTypes["SlotUsageWhereInput"] | undefined
};
	["SlotUsageWhereInput"]: {
	AND?: Array<ModelTypes["SlotUsageWhereInput"]> | undefined,
	OR?: Array<ModelTypes["SlotUsageWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["SlotUsageWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["DateTimeFilter"] | undefined,
	slotUsed?: ModelTypes["IntFilter"] | undefined,
	booking?: ModelTypes["BookingRelationFilter"] | undefined
};
	["OrderListRelationFilter"]: {
	every?: ModelTypes["OrderWhereInput"] | undefined,
	some?: ModelTypes["OrderWhereInput"] | undefined,
	none?: ModelTypes["OrderWhereInput"] | undefined
};
	["OrderWhereInput"]: {
	AND?: Array<ModelTypes["OrderWhereInput"]> | undefined,
	OR?: Array<ModelTypes["OrderWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["OrderWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	createdDate?: ModelTypes["DateTimeFilter"] | undefined,
	createdByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	origin?: ModelTypes["StringFilter"] | undefined,
	paymentId?: ModelTypes["StringNullableFilter"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCompositeFilter"] | undefined,
	selectedDate?: ModelTypes["OrderSelectedDateCompositeFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	createdBy?: ModelTypes["UserNullableRelationFilter"] | undefined
};
	["PriceSummaryCompositeFilter"]: {
	equals?: ModelTypes["PriceSummaryObjectEqualityInput"] | undefined,
	is?: ModelTypes["PriceSummaryWhereInput"] | undefined,
	isNot?: ModelTypes["PriceSummaryWhereInput"] | undefined
};
	["NotificationListRelationFilter"]: {
	every?: ModelTypes["NotificationWhereInput"] | undefined,
	some?: ModelTypes["NotificationWhereInput"] | undefined,
	none?: ModelTypes["NotificationWhereInput"] | undefined
};
	["NotificationWhereInput"]: {
	AND?: Array<ModelTypes["NotificationWhereInput"]> | undefined,
	OR?: Array<ModelTypes["NotificationWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["NotificationWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumNotificationTypeFilter"] | undefined,
	title?: ModelTypes["StringFilter"] | undefined,
	content?: ModelTypes["StringFilter"] | undefined,
	metadata?: ModelTypes["JsonNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	readAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	recipientId?: ModelTypes["StringFilter"] | undefined,
	channels?: ModelTypes["EnumNotificationChannelNullableListFilter"] | undefined,
	recipient?: ModelTypes["UserRelationFilter"] | undefined
};
	["EnumNotificationTypeFilter"]: {
	equals?: ModelTypes["NotificationType"] | undefined,
	in?: Array<ModelTypes["NotificationType"]> | undefined,
	notIn?: Array<ModelTypes["NotificationType"]> | undefined,
	not?: ModelTypes["NestedEnumNotificationTypeFilter"] | undefined
};
	["NestedEnumNotificationTypeFilter"]: {
	equals?: ModelTypes["NotificationType"] | undefined,
	in?: Array<ModelTypes["NotificationType"]> | undefined,
	notIn?: Array<ModelTypes["NotificationType"]> | undefined,
	not?: ModelTypes["NestedEnumNotificationTypeFilter"] | undefined
};
	["AdBoardPublishHistoryListRelationFilter"]: {
	every?: ModelTypes["AdBoardPublishHistoryWhereInput"] | undefined,
	some?: ModelTypes["AdBoardPublishHistoryWhereInput"] | undefined,
	none?: ModelTypes["AdBoardPublishHistoryWhereInput"] | undefined
};
	["AdBoardPublishHistoryWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	publishedAt?: ModelTypes["DateTimeFilter"] | undefined,
	publishedByUserId?: ModelTypes["StringFilter"] | undefined,
	changesSnapshot?: ModelTypes["JsonFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	publishedBy?: ModelTypes["UserRelationFilter"] | undefined
};
	["JsonFilter"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not?: ModelTypes["JSON"] | undefined
};
	["AdBoardReviewCommentListRelationFilter"]: {
	every?: ModelTypes["AdBoardReviewCommentWhereInput"] | undefined,
	some?: ModelTypes["AdBoardReviewCommentWhereInput"] | undefined,
	none?: ModelTypes["AdBoardReviewCommentWhereInput"] | undefined
};
	["AdBoardReviewCommentWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	comment?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["EnumReviewActionFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined
};
	["EnumReviewActionFilter"]: {
	equals?: ModelTypes["ReviewAction"] | undefined,
	in?: Array<ModelTypes["ReviewAction"]> | undefined,
	notIn?: Array<ModelTypes["ReviewAction"]> | undefined,
	not?: ModelTypes["NestedEnumReviewActionFilter"] | undefined
};
	["ReviewAction"]:ReviewAction;
	["NestedEnumReviewActionFilter"]: {
	equals?: ModelTypes["ReviewAction"] | undefined,
	in?: Array<ModelTypes["ReviewAction"]> | undefined,
	notIn?: Array<ModelTypes["ReviewAction"]> | undefined,
	not?: ModelTypes["NestedEnumReviewActionFilter"] | undefined
};
	["WorkspaceListRelationFilter"]: {
	every?: ModelTypes["WorkspaceWhereInput"] | undefined,
	some?: ModelTypes["WorkspaceWhereInput"] | undefined,
	none?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["AdBoardPublicReviewListRelationFilter"]: {
	every?: ModelTypes["AdBoardPublicReviewWhereInput"] | undefined,
	some?: ModelTypes["AdBoardPublicReviewWhereInput"] | undefined,
	none?: ModelTypes["AdBoardPublicReviewWhereInput"] | undefined
};
	["AdBoardPublicReviewWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	rating?: ModelTypes["IntFilter"] | undefined,
	comment?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined
};
	["UserPositionListRelationFilter"]: {
	every?: ModelTypes["UserPositionWhereInput"] | undefined,
	some?: ModelTypes["UserPositionWhereInput"] | undefined,
	none?: ModelTypes["UserPositionWhereInput"] | undefined
};
	["UserPositionWhereInput"]: {
	AND?: Array<ModelTypes["UserPositionWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserPositionWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserPositionWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	timestamp?: ModelTypes["DateTimeFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	coords?: ModelTypes["UserPositionCoordsCompositeFilter"] | undefined,
	location?: ModelTypes["UserPositionLocationNullableCompositeFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined
};
	["UserPositionCoordsCompositeFilter"]: {
	equals?: ModelTypes["UserPositionCoordsObjectEqualityInput"] | undefined,
	is?: ModelTypes["UserPositionCoordsWhereInput"] | undefined,
	isNot?: ModelTypes["UserPositionCoordsWhereInput"] | undefined
};
	["UserPositionCoordsObjectEqualityInput"]: {
	accuracy?: number | undefined,
	altitude?: number | undefined,
	altitudeAccuracy?: number | undefined,
	heading?: number | undefined,
	latitude: number,
	longitude: number,
	speed?: number | undefined
};
	["UserPositionCoordsWhereInput"]: {
	AND?: Array<ModelTypes["UserPositionCoordsWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserPositionCoordsWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserPositionCoordsWhereInput"]> | undefined,
	accuracy?: ModelTypes["FloatNullableFilter"] | undefined,
	altitude?: ModelTypes["FloatNullableFilter"] | undefined,
	altitudeAccuracy?: ModelTypes["FloatNullableFilter"] | undefined,
	heading?: ModelTypes["FloatNullableFilter"] | undefined,
	latitude?: ModelTypes["FloatFilter"] | undefined,
	longitude?: ModelTypes["FloatFilter"] | undefined,
	speed?: ModelTypes["FloatNullableFilter"] | undefined
};
	["UserPositionLocationNullableCompositeFilter"]: {
	equals?: ModelTypes["UserPositionLocationObjectEqualityInput"] | undefined,
	is?: ModelTypes["UserPositionLocationWhereInput"] | undefined,
	isNot?: ModelTypes["UserPositionLocationWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["UserPositionLocationObjectEqualityInput"]: {
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["UserPositionLocationWhereInput"]: {
	AND?: Array<ModelTypes["UserPositionLocationWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserPositionLocationWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserPositionLocationWhereInput"]> | undefined,
	address?: ModelTypes["StringNullableFilter"] | undefined,
	city?: ModelTypes["StringNullableFilter"] | undefined,
	state?: ModelTypes["StringNullableFilter"] | undefined,
	country?: ModelTypes["StringNullableFilter"] | undefined
};
	["BusinessCategoryRelationFilter"]: {
	is?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	isNot?: ModelTypes["BusinessCategoryWhereInput"] | undefined
};
	["PaymentListRelationFilter"]: {
	every?: ModelTypes["PaymentWhereInput"] | undefined,
	some?: ModelTypes["PaymentWhereInput"] | undefined,
	none?: ModelTypes["PaymentWhereInput"] | undefined
};
	["PaymentWhereInput"]: {
	AND?: Array<ModelTypes["PaymentWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PaymentWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PaymentWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	paymentIntentId?: ModelTypes["StringFilter"] | undefined,
	checkoutSessionId?: ModelTypes["StringFilter"] | undefined,
	eventId?: ModelTypes["StringFilter"] | undefined,
	liveMode?: ModelTypes["BoolFilter"] | undefined,
	paymentGateway?: ModelTypes["EnumPaymentGatewayNameFilter"] | undefined,
	subtotal?: ModelTypes["FloatFilter"] | undefined,
	total?: ModelTypes["FloatFilter"] | undefined,
	tax?: ModelTypes["FloatFilter"] | undefined,
	discount?: ModelTypes["FloatFilter"] | undefined,
	campaignId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	campaign?: ModelTypes["CampaignNullableRelationFilter"] | undefined
};
	["BusinessCategoryListRelationFilter"]: {
	every?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	some?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	none?: ModelTypes["BusinessCategoryWhereInput"] | undefined
};
	["SlotConfigurationListRelationFilter"]: {
	every?: ModelTypes["SlotConfigurationWhereInput"] | undefined,
	some?: ModelTypes["SlotConfigurationWhereInput"] | undefined,
	none?: ModelTypes["SlotConfigurationWhereInput"] | undefined
};
	["SlotConfigurationWhereInput"]: {
	AND?: Array<ModelTypes["SlotConfigurationWhereInput"]> | undefined,
	OR?: Array<ModelTypes["SlotConfigurationWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["SlotConfigurationWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["DateTimeFilter"] | undefined,
	availableSlots?: ModelTypes["IntFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined
};
	["AdBoardOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	address?: ModelTypes["SortOrder"] | undefined,
	state?: ModelTypes["SortOrder"] | undefined,
	city?: ModelTypes["SortOrder"] | undefined,
	country?: ModelTypes["SortOrder"] | undefined,
	map?: ModelTypes["MapOrderByInput"] | undefined,
	outdoor?: ModelTypes["SortOrder"] | undefined,
	locationCategoryId?: ModelTypes["SortOrder"] | undefined,
	locationSubCategoryId?: ModelTypes["SortOrder"] | undefined,
	alias?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	pricePerDay?: ModelTypes["SortOrder"] | undefined,
	originalPricePerDay?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	aboutSpecifications?: ModelTypes["SpecificationOrderByCompositeAggregateInput"] | undefined,
	adBoardTypeId?: ModelTypes["SortOrder"] | undefined,
	tags?: ModelTypes["SortOrder"] | undefined,
	featuredImage?: ModelTypes["SortOrder"] | undefined,
	galleryImgs?: ModelTypes["SortOrder"] | undefined,
	specifications?: ModelTypes["SpecificationOrderByCompositeAggregateInput"] | undefined,
	notes?: ModelTypes["SortOrder"] | undefined,
	impressionMultiplier?: ModelTypes["SortOrder"] | undefined,
	resolution?: ModelTypes["ResolutionOrderByInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeOrderByInput"] | undefined,
	categoryId?: ModelTypes["SortOrder"] | undefined,
	restrictedBusinessIds?: ModelTypes["SortOrder"] | undefined,
	targetAudiences?: ModelTypes["SortOrder"] | undefined,
	playbackTimes?: ModelTypes["PlaybackTimeOrderByCompositeAggregateInput"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	adboardDraftStatus?: ModelTypes["SortOrder"] | undefined,
	isLive?: ModelTypes["SortOrder"] | undefined,
	webhooks?: ModelTypes["WebhookOrderByCompositeAggregateInput"] | undefined,
	numberOfDisplays?: ModelTypes["SortOrder"] | undefined,
	dailyOperationHours?: ModelTypes["SortOrder"] | undefined,
	adsPerLoop?: ModelTypes["SortOrder"] | undefined,
	adsDuration?: ModelTypes["SortOrder"] | undefined,
	monthlyPrice?: ModelTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: ModelTypes["SortOrder"] | undefined,
	offlineBookingContactId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	href?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	playerIds?: ModelTypes["SortOrder"] | undefined,
	publisherScreenId?: ModelTypes["SortOrder"] | undefined,
	additionalData?: ModelTypes["SortOrder"] | undefined,
	isDraft?: ModelTypes["SortOrder"] | undefined,
	publishedVersionId?: ModelTypes["SortOrder"] | undefined,
	lastPublishedAt?: ModelTypes["SortOrder"] | undefined,
	isArchived?: ModelTypes["SortOrder"] | undefined,
	archivedAt?: ModelTypes["SortOrder"] | undefined,
	archivedByUserId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryOrderByWithRelationInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryOrderByWithRelationInput"] | undefined,
	type?: ModelTypes["AdBoardTypeOrderByWithRelationInput"] | undefined,
	category?: ModelTypes["BusinessCategoryOrderByWithRelationInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryOrderByRelationAggregateInput"] | undefined,
	bookings?: ModelTypes["BookingOrderByRelationAggregateInput"] | undefined,
	orders?: ModelTypes["OrderOrderByRelationAggregateInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationOrderByRelationAggregateInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	createdBy?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	players?: ModelTypes["PlayerOrderByRelationAggregateInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupOrderByWithRelationInput"] | undefined,
	cameras?: ModelTypes["CameraOrderByRelationAggregateInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentOrderByRelationAggregateInput"] | undefined,
	archivedBy?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewOrderByRelationAggregateInput"] | undefined
};
	["SortOrder"]:SortOrder;
	["MapOrderByInput"]: {
	lat?: ModelTypes["SortOrder"] | undefined,
	lng?: ModelTypes["SortOrder"] | undefined,
	address?: ModelTypes["SortOrder"] | undefined,
	city?: ModelTypes["SortOrder"] | undefined,
	state?: ModelTypes["SortOrder"] | undefined,
	country?: ModelTypes["SortOrder"] | undefined
};
	["SpecificationOrderByCompositeAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["ResolutionOrderByInput"]: {
	height?: ModelTypes["SortOrder"] | undefined,
	width?: ModelTypes["SortOrder"] | undefined
};
	["ScreenSizeOrderByInput"]: {
	height?: ModelTypes["SortOrder"] | undefined,
	unit?: ModelTypes["SortOrder"] | undefined,
	width?: ModelTypes["SortOrder"] | undefined
};
	["PlaybackTimeOrderByCompositeAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["WebhookOrderByCompositeAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["LocationCategoryOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	adBoards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryOrderByRelationAggregateInput"] | undefined
};
	["AdBoardOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["LocationSubCategoryOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["LocationSubCategoryOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	locationCategoryId?: ModelTypes["SortOrder"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryOrderByWithRelationInput"] | undefined,
	adBoards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined
};
	["AdBoardTypeOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	adBoards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined
};
	["BusinessCategoryOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	restrictedByAdBoardIds?: ModelTypes["SortOrder"] | undefined,
	adBoards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	campaigns?: ModelTypes["CampaignOrderByRelationAggregateInput"] | undefined,
	bookings?: ModelTypes["BookingOrderByRelationAggregateInput"] | undefined
};
	["CampaignOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["BookingOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["BusinessCategoryOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["OrderOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["SlotConfigurationOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["UserOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	uid?: ModelTypes["SortOrder"] | undefined,
	email?: ModelTypes["SortOrder"] | undefined,
	image?: ModelTypes["SortOrder"] | undefined,
	phoneNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	otherDetails?: ModelTypes["SortOrder"] | undefined,
	stripe?: ModelTypes["UserStripeOrderByInput"] | undefined,
	roles?: ModelTypes["SortOrder"] | undefined,
	refunds?: ModelTypes["BookingRefundOrderByCompositeAggregateInput"] | undefined,
	sharedBookingIds?: ModelTypes["SortOrder"] | undefined,
	disclaimerAccepted?: ModelTypes["SortOrder"] | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceOrderByInput"] | undefined,
	leadWorkspaceIds?: ModelTypes["SortOrder"] | undefined,
	bookings?: ModelTypes["BookingOrderByRelationAggregateInput"] | undefined,
	createdFiles?: ModelTypes["FileOrderByRelationAggregateInput"] | undefined,
	updatedFiles?: ModelTypes["FileOrderByRelationAggregateInput"] | undefined,
	orders?: ModelTypes["OrderOrderByRelationAggregateInput"] | undefined,
	changelogs?: ModelTypes["ChangelogOrderByRelationAggregateInput"] | undefined,
	adBoards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	audits?: ModelTypes["AuditOrderByRelationAggregateInput"] | undefined,
	campaigns?: ModelTypes["CampaignOrderByRelationAggregateInput"] | undefined,
	sharedBookings?: ModelTypes["BookingOrderByRelationAggregateInput"] | undefined,
	memberships?: ModelTypes["MembershipOrderByRelationAggregateInput"] | undefined,
	notifications?: ModelTypes["NotificationOrderByRelationAggregateInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentOrderByRelationAggregateInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceOrderByRelationAggregateInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceOrderByRelationAggregateInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewOrderByRelationAggregateInput"] | undefined,
	userPositions?: ModelTypes["UserPositionOrderByRelationAggregateInput"] | undefined
};
	["UserStripeOrderByInput"]: {
	customerId?: ModelTypes["SortOrder"] | undefined
};
	["BookingRefundOrderByCompositeAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["NotificationPreferenceOrderByInput"]: {
	type?: ModelTypes["SortOrder"] | undefined,
	channels?: ModelTypes["SortOrder"] | undefined,
	pushSetting?: ModelTypes["PushSettingOrderByInput"] | undefined,
	enabled?: ModelTypes["SortOrder"] | undefined
};
	["PushSettingOrderByInput"]: {
	fcmToken?: ModelTypes["SortOrder"] | undefined
};
	["FileOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["ChangelogOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["AuditOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["MembershipOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["NotificationOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardPublishHistoryOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardReviewCommentOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardPublicReviewOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["UserPositionOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	key?: ModelTypes["SortOrder"] | undefined,
	userIds?: ModelTypes["SortOrder"] | undefined,
	ownerId?: ModelTypes["SortOrder"] | undefined,
	avatar?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined,
	leadIds?: ModelTypes["SortOrder"] | undefined,
	owner?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	memberships?: ModelTypes["MembershipOrderByRelationAggregateInput"] | undefined,
	adBoards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	playlists?: ModelTypes["PlaylistOrderByRelationAggregateInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined,
	audits?: ModelTypes["AuditOrderByRelationAggregateInput"] | undefined,
	bookings?: ModelTypes["BookingOrderByRelationAggregateInput"] | undefined,
	files?: ModelTypes["FileOrderByRelationAggregateInput"] | undefined,
	players?: ModelTypes["PlayerOrderByRelationAggregateInput"] | undefined,
	transactions?: ModelTypes["TransactionOrderByRelationAggregateInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionOrderByWithRelationInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerOrderByRelationAggregateInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleOrderByRelationAggregateInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogOrderByRelationAggregateInput"] | undefined,
	leads?: ModelTypes["UserOrderByRelationAggregateInput"] | undefined
};
	["PlaylistOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardGroupOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["PlayerOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["TransactionOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["SubscriptionOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	packageId?: ModelTypes["SortOrder"] | undefined,
	packageName?: ModelTypes["SortOrder"] | undefined,
	priceLookupKey?: ModelTypes["SortOrder"] | undefined,
	startDate?: ModelTypes["SortOrder"] | undefined,
	endDate?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: ModelTypes["SortOrder"] | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsOrderByInput"] | undefined,
	activeWorkspaceId?: ModelTypes["SortOrder"] | undefined,
	activeWorkspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined
};
	["CancellationDetailsOrderByInput"]: {
	canceledAt?: ModelTypes["SortOrder"] | undefined,
	cancelAt?: ModelTypes["SortOrder"] | undefined,
	cancelAtPeriodEnd?: ModelTypes["SortOrder"] | undefined,
	reason?: ModelTypes["SortOrder"] | undefined
};
	["CameraServerOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceRoleOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["WebhookLogOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["UserOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardGroupOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	schedules?: ModelTypes["ScheduleOrderByCompositeAggregateInput"] | undefined,
	scheduledPlaylistsIDs?: ModelTypes["SortOrder"] | undefined,
	adsPlaylistID?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	fitImage?: ModelTypes["SortOrder"] | undefined,
	fitVideo?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	adBoard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistOrderByRelationAggregateInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistOrderByWithRelationInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerOrderByRelationAggregateInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined
};
	["ScheduleOrderByCompositeAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["PlaylistOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	assets?: ModelTypes["PisignageFileOrderByCompositeAggregateInput"] | undefined,
	isAdsPlaylist?: ModelTypes["SortOrder"] | undefined,
	footerPlaylistId?: ModelTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: ModelTypes["SortOrder"] | undefined,
	layout?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	adBoardGroupIds?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	footerPlaylist?: ModelTypes["PlaylistOrderByWithRelationInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistOrderByWithRelationInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistOrderByRelationAggregateInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistOrderByRelationAggregateInput"] | undefined
};
	["PisignageFileOrderByCompositeAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["CameraOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardWhereUniqueInput"]: {
	id?: string | undefined,
	publishedVersionId?: string | undefined,
	workspaceId_alias?: ModelTypes["AdBoardWorkspaceIdAliasCompoundUniqueInput"] | undefined,
	AND?: Array<ModelTypes["AdBoardWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardWhereInput"]> | undefined,
	address?: ModelTypes["StringFilter"] | undefined,
	state?: ModelTypes["StringFilter"] | undefined,
	city?: ModelTypes["StringFilter"] | undefined,
	country?: ModelTypes["StringFilter"] | undefined,
	map?: ModelTypes["MapCompositeFilter"] | undefined,
	outdoor?: ModelTypes["BoolFilter"] | undefined,
	locationCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	locationSubCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	alias?: ModelTypes["StringNullableFilter"] | undefined,
	title?: ModelTypes["StringFilter"] | undefined,
	pricePerDay?: ModelTypes["IntFilter"] | undefined,
	originalPricePerDay?: ModelTypes["IntNullableFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationObjectEqualityInput"]> | undefined,
	adBoardTypeId?: ModelTypes["StringNullableFilter"] | undefined,
	tags?: ModelTypes["StringNullableListFilter"] | undefined,
	featuredImage?: ModelTypes["StringNullableFilter"] | undefined,
	galleryImgs?: ModelTypes["StringNullableListFilter"] | undefined,
	specifications?: Array<ModelTypes["SpecificationObjectEqualityInput"]> | undefined,
	notes?: ModelTypes["StringNullableListFilter"] | undefined,
	impressionMultiplier?: ModelTypes["FloatNullableFilter"] | undefined,
	resolution?: ModelTypes["ResolutionNullableCompositeFilter"] | undefined,
	screenSize?: ModelTypes["ScreenSizeNullableCompositeFilter"] | undefined,
	categoryId?: ModelTypes["StringNullableFilter"] | undefined,
	restrictedBusinessIds?: ModelTypes["StringNullableListFilter"] | undefined,
	targetAudiences?: ModelTypes["StringNullableListFilter"] | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeObjectEqualityInput"]> | undefined,
	status?: ModelTypes["EnumAdBoardStatusFilter"] | undefined,
	adboardDraftStatus?: ModelTypes["EnumAdboardDraftStatusNullableFilter"] | undefined,
	isLive?: ModelTypes["BoolFilter"] | undefined,
	webhooks?: Array<ModelTypes["WebhookObjectEqualityInput"]> | undefined,
	numberOfDisplays?: ModelTypes["IntFilter"] | undefined,
	dailyOperationHours?: ModelTypes["IntFilter"] | undefined,
	adsPerLoop?: ModelTypes["IntFilter"] | undefined,
	adsDuration?: ModelTypes["IntFilter"] | undefined,
	monthlyPrice?: ModelTypes["FloatNullableFilter"] | undefined,
	offlineBookingsEnabled?: ModelTypes["BoolFilter"] | undefined,
	offlineBookingContactId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	createdByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	href?: ModelTypes["StringNullableFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	playerIds?: ModelTypes["StringNullableListFilter"] | undefined,
	publisherScreenId?: ModelTypes["StringNullableFilter"] | undefined,
	additionalData?: ModelTypes["JsonNullableFilter"] | undefined,
	isDraft?: ModelTypes["BoolFilter"] | undefined,
	lastPublishedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	isArchived?: ModelTypes["BoolFilter"] | undefined,
	archivedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	archivedByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryNullableRelationFilter"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryNullableRelationFilter"] | undefined,
	type?: ModelTypes["AdBoardTypeNullableRelationFilter"] | undefined,
	category?: ModelTypes["BusinessCategoryNullableRelationFilter"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	orders?: ModelTypes["OrderListRelationFilter"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationListRelationFilter"] | undefined,
	offlineBookingsContact?: ModelTypes["UserNullableRelationFilter"] | undefined,
	createdBy?: ModelTypes["UserNullableRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceRelationFilter"] | undefined,
	players?: ModelTypes["PlayerListRelationFilter"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupNullableRelationFilter"] | undefined,
	cameras?: ModelTypes["CameraListRelationFilter"] | undefined,
	publishedVersion?: ModelTypes["AdBoardNullableRelationFilter"] | undefined,
	draftAdboard?: ModelTypes["AdBoardNullableRelationFilter"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryListRelationFilter"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentListRelationFilter"] | undefined,
	archivedBy?: ModelTypes["UserNullableRelationFilter"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewListRelationFilter"] | undefined
};
	["AdBoardWorkspaceIdAliasCompoundUniqueInput"]: {
	workspaceId: string,
	alias: string
};
	["AdBoard"]: {
		id: string,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["Map"],
	outdoor: boolean,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications: Array<ModelTypes["Specification"]>,
	adBoardTypeId?: string | undefined,
	tags: Array<string>,
	featuredImage?: string | undefined,
	galleryImgs: Array<string>,
	/** @dperecated Use `targetAudiences` instead. */
	specifications: Array<ModelTypes["Specification"]>,
	notes: Array<string>,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["Resolution"] | undefined,
	screenSize?: ModelTypes["ScreenSize"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds: Array<string>,
	targetAudiences: Array<string>,
	playbackTimes: Array<ModelTypes["PlaybackTime"]>,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive: boolean,
	webhooks: Array<ModelTypes["Webhook"]>,
	numberOfDisplays: number,
	dailyOperationHours: number,
	adsPerLoop: number,
	adsDuration: number,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled: boolean,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds: Array<string>,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft: boolean,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived: boolean,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	_count?: ModelTypes["AdBoardCount"] | undefined,
	locationCategory?: ModelTypes["LocationCategory"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategory"] | undefined,
	type?: ModelTypes["AdBoardType"] | undefined,
	category?: ModelTypes["BusinessCategory"] | undefined,
	restrictedBusines: Array<ModelTypes["BusinessCategory"]>,
	bookings: Array<ModelTypes["Booking"]>,
	orders: Array<ModelTypes["Order"]>,
	slotConfigurations: Array<ModelTypes["SlotConfiguration"]>,
	offlineBookingsContact?: ModelTypes["User"] | undefined,
	createdBy?: ModelTypes["User"] | undefined,
	workspace: ModelTypes["Workspace"],
	players: Array<ModelTypes["Player"]>,
	adBoardGroup?: ModelTypes["AdBoardGroup"] | undefined,
	cameras: Array<ModelTypes["Camera"]>,
	publishedVersion?: ModelTypes["AdBoard"] | undefined,
	draftAdboard?: ModelTypes["AdBoard"] | undefined,
	history: Array<ModelTypes["AdBoardPublishHistory"]>,
	reviewComments: Array<ModelTypes["AdBoardReviewComment"]>,
	archivedBy?: ModelTypes["User"] | undefined,
	publicReviews: Array<ModelTypes["AdBoardPublicReview"]>
};
	["Map"]: {
		lat: number,
	lng: number,
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["Specification"]: {
		name: string,
	value?: string | undefined
};
	["Resolution"]: {
		height: number,
	width: number
};
	["ScreenSize"]: {
		height: number,
	unit: ModelTypes["MeasurementUnit"],
	width: number
};
	["PlaybackTime"]: {
		name?: string | undefined,
	startTime: string,
	endTime: string
};
	["Webhook"]: {
		url: string,
	headers?: ModelTypes["JSON"] | undefined,
	body?: ModelTypes["JSON"] | undefined,
	method: string
};
	["AdBoardCount"]: {
		restrictedBusines: number,
	bookings: number,
	orders: number,
	slotConfigurations: number,
	players: number,
	cameras: number,
	history: number,
	reviewComments: number,
	publicReviews: number
};
	["LocationCategory"]: {
		id: string,
	name: string,
	_count?: ModelTypes["LocationCategoryCount"] | undefined,
	adBoards: Array<ModelTypes["AdBoard"]>,
	locationSubCategory: Array<ModelTypes["LocationSubCategory"]>
};
	["LocationCategoryCount"]: {
		adBoards: number,
	locationSubCategory: number
};
	["AdBoardScalarFieldEnum"]:AdBoardScalarFieldEnum;
	["LocationSubCategory"]: {
		id: string,
	name: string,
	locationCategoryId?: string | undefined,
	_count?: ModelTypes["LocationSubCategoryCount"] | undefined,
	locationCategory?: ModelTypes["LocationCategory"] | undefined,
	adBoards: Array<ModelTypes["AdBoard"]>
};
	["LocationSubCategoryCount"]: {
		adBoards: number
};
	["LocationSubCategoryWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["LocationSubCategoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["LocationSubCategoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["LocationSubCategoryWhereInput"]> | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	locationCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryNullableRelationFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined
};
	["LocationSubCategoryScalarFieldEnum"]:LocationSubCategoryScalarFieldEnum;
	["AdBoardType"]: {
		id: string,
	name: string,
	description?: string | undefined,
	_count?: ModelTypes["AdBoardTypeCount"] | undefined,
	adBoards: Array<ModelTypes["AdBoard"]>
};
	["AdBoardTypeCount"]: {
		adBoards: number
};
	["BusinessCategory"]: {
		id: string,
	name: string,
	description?: string | undefined,
	restrictedByAdBoardIds: Array<string>,
	_count?: ModelTypes["BusinessCategoryCount"] | undefined,
	adBoards: Array<ModelTypes["AdBoard"]>,
	restrictedByAdBoards: Array<ModelTypes["AdBoard"]>,
	campaigns: Array<ModelTypes["Campaign"]>,
	bookings: Array<ModelTypes["Booking"]>
};
	["BusinessCategoryCount"]: {
		adBoards: number,
	restrictedByAdBoards: number,
	campaigns: number,
	bookings: number
};
	["Campaign"]: {
		id: string,
	name: string,
	userId: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	bookingFor: string,
	purpose: string,
	businessCategoryId: string,
	adCategoryId: Array<string>,
	payment?: ModelTypes["BookingPayment"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummary"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDate"],
	origin: string,
	assetsIDs: Array<string>,
	_count?: ModelTypes["CampaignCount"] | undefined,
	user: ModelTypes["User"],
	businessCategory: ModelTypes["BusinessCategory"],
	adCategory: Array<ModelTypes["AdvertisementCategory"]>,
	bookings: Array<ModelTypes["Booking"]>,
	payments: Array<ModelTypes["Payment"]>,
	assets: Array<ModelTypes["File"]>
};
	["BookingPayment"]: {
		liveMode: boolean,
	checkoutSessionId?: string | undefined,
	customer?: ModelTypes["BookingCustomer"] | undefined,
	paymentGateway: ModelTypes["PaymentGatewayName"],
	paymentId: string,
	sessionId?: string | undefined,
	totalDetails?: ModelTypes["PaymentTotalDetails"] | undefined,
	razorpay?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["JSON"] | undefined
};
	["BookingCustomer"]: {
		customerId: string,
	customerDetails?: ModelTypes["PaymentCustomerDetails"] | undefined
};
	["PaymentCustomerDetails"]: {
		email: string,
	name: string,
	phone?: string | undefined
};
	["PaymentTotalDetails"]: {
		discount: number,
	shipping: number,
	subtotal: number,
	tax: number,
	total: number
};
	["PriceSummary"]: {
		days: number,
	discount?: number | undefined,
	payableRent: number,
	rentPerDay: number,
	serviceCharge: number,
	totalRent: number
};
	["OrderSelectedDate"]: {
		endDate: ModelTypes["DateTime"],
	startDate: ModelTypes["DateTime"]
};
	["CampaignCount"]: {
		adCategory: number,
	bookings: number,
	payments: number,
	assets: number
};
	["User"]: {
		id: string,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripe"] | undefined,
	roles: Array<ModelTypes["Role"]>,
	refunds: Array<ModelTypes["BookingRefund"]>,
	sharedBookingIds: Array<string>,
	disclaimerAccepted: boolean,
	notificationPreference?: ModelTypes["NotificationPreference"] | undefined,
	leadWorkspaceIds: Array<string>,
	_count?: ModelTypes["UserCount"] | undefined,
	bookings: Array<ModelTypes["Booking"]>,
	createdFiles: Array<ModelTypes["File"]>,
	updatedFiles: Array<ModelTypes["File"]>,
	orders: Array<ModelTypes["Order"]>,
	changelogs: Array<ModelTypes["Changelog"]>,
	adBoards: Array<ModelTypes["AdBoard"]>,
	audits: Array<ModelTypes["Audit"]>,
	campaigns: Array<ModelTypes["Campaign"]>,
	sharedBookings: Array<ModelTypes["Booking"]>,
	memberships: Array<ModelTypes["Membership"]>,
	notifications: Array<ModelTypes["Notification"]>,
	publishAdboardVersion: Array<ModelTypes["AdBoardPublishHistory"]>,
	offlineBookingAdboards: Array<ModelTypes["AdBoard"]>,
	reviewComments: Array<ModelTypes["AdBoardReviewComment"]>,
	archivedAdboards: Array<ModelTypes["AdBoard"]>,
	ownedWorkspaces: Array<ModelTypes["Workspace"]>,
	leadWorspaces: Array<ModelTypes["Workspace"]>,
	publicReviews: Array<ModelTypes["AdBoardPublicReview"]>,
	userPositions: Array<ModelTypes["UserPosition"]>
};
	["UserStripe"]: {
		customerId: string
};
	["BookingRefund"]: {
		id: string,
	razorpay?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["JSON"] | undefined,
	createdByUserId: string
};
	["NotificationPreference"]: {
		type: Array<ModelTypes["NotificationType"]>,
	channels: Array<ModelTypes["NotificationChannel"]>,
	pushSetting?: ModelTypes["PushSetting"] | undefined,
	enabled: boolean
};
	["PushSetting"]: {
		fcmToken: string
};
	["UserCount"]: {
		bookings: number,
	createdFiles: number,
	updatedFiles: number,
	orders: number,
	changelogs: number,
	adBoards: number,
	audits: number,
	campaigns: number,
	sharedBookings: number,
	memberships: number,
	notifications: number,
	publishAdboardVersion: number,
	offlineBookingAdboards: number,
	reviewComments: number,
	archivedAdboards: number,
	ownedWorkspaces: number,
	leadWorspaces: number,
	publicReviews: number,
	userPositions: number
};
	["Booking"]: {
		id: string,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs: Array<string>,
	bookingCode: string,
	bookingDate: ModelTypes["DateTime"],
	bookingFor: string,
	purpose: string,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId: Array<string>,
	bookingDetails: ModelTypes["BookingDetails"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDate"],
	payment?: ModelTypes["BookingPayment"] | undefined,
	priceSummary?: ModelTypes["PriceSummary"] | undefined,
	pisignage?: ModelTypes["BookingPisignageData"] | undefined,
	userId: string,
	refund?: ModelTypes["BookingRefund"] | undefined,
	sharedUserIds: Array<string>,
	isOffline: boolean,
	isPaid: boolean,
	isScheduled: boolean,
	isDeployed: boolean,
	isCompleted: boolean,
	_count?: ModelTypes["BookingCount"] | undefined,
	assets: Array<ModelTypes["File"]>,
	businessCategory?: ModelTypes["BusinessCategory"] | undefined,
	adCategory: Array<ModelTypes["AdvertisementCategory"]>,
	proofs: Array<ModelTypes["File"]>,
	user: ModelTypes["User"],
	adBoard: ModelTypes["AdBoard"],
	changelogs: Array<ModelTypes["Changelog"]>,
	workspace?: ModelTypes["Workspace"] | undefined,
	campaign?: ModelTypes["Campaign"] | undefined,
	payouts: Array<ModelTypes["Payout"]>,
	sharedUsers: Array<ModelTypes["User"]>,
	webhookLogs: Array<ModelTypes["WebhookLog"]>,
	slotUsage: Array<ModelTypes["SlotUsage"]>
};
	["BookingDetails"]: {
		additionalInformation?: string | undefined,
	email: string,
	name: string,
	phone?: string | undefined
};
	["BookingPisignageData"]: {
		deployedFileName?: string | undefined,
	playlistId?: string | undefined
};
	["BookingCount"]: {
		assets: number,
	adCategory: number,
	proofs: number,
	changelogs: number,
	payouts: number,
	sharedUsers: number,
	webhookLogs: number,
	slotUsage: number
};
	["File"]: {
		id: string,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMeta"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt: ModelTypes["DateTime"],
	createdAt: ModelTypes["DateTime"],
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs: Array<string>,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds: Array<string>,
	_count?: ModelTypes["FileCount"] | undefined,
	updatedBy?: ModelTypes["User"] | undefined,
	createdBy: ModelTypes["User"],
	bookings: Array<ModelTypes["Booking"]>,
	workspace?: ModelTypes["Workspace"] | undefined,
	proofBooking?: ModelTypes["Booking"] | undefined,
	campaigns: Array<ModelTypes["Campaign"]>
};
	["FileMeta"]: {
		duration: number,
	height: number,
	width: number
};
	["FileCount"]: {
		bookings: number,
	campaigns: number
};
	["BookingOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	additionalInformation?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	assetsIDs?: ModelTypes["SortOrder"] | undefined,
	bookingCode?: ModelTypes["SortOrder"] | undefined,
	bookingDate?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	campaignId?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	adCategoryId?: ModelTypes["SortOrder"] | undefined,
	bookingDetails?: ModelTypes["BookingDetailsOrderByInput"] | undefined,
	bookingStatus?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	deploymentStatus?: ModelTypes["SortOrder"] | undefined,
	contentApprovalStatus?: ModelTypes["SortOrder"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateOrderByInput"] | undefined,
	payment?: ModelTypes["BookingPaymentOrderByInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryOrderByInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataOrderByInput"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	refund?: ModelTypes["BookingRefundOrderByInput"] | undefined,
	sharedUserIds?: ModelTypes["SortOrder"] | undefined,
	isOffline?: ModelTypes["SortOrder"] | undefined,
	isPaid?: ModelTypes["SortOrder"] | undefined,
	isScheduled?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	isCompleted?: ModelTypes["SortOrder"] | undefined,
	assets?: ModelTypes["FileOrderByRelationAggregateInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryOrderByWithRelationInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryOrderByRelationAggregateInput"] | undefined,
	proofs?: ModelTypes["FileOrderByRelationAggregateInput"] | undefined,
	user?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	adBoard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined,
	changelogs?: ModelTypes["ChangelogOrderByRelationAggregateInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	campaign?: ModelTypes["CampaignOrderByWithRelationInput"] | undefined,
	payouts?: ModelTypes["PayoutOrderByRelationAggregateInput"] | undefined,
	sharedUsers?: ModelTypes["UserOrderByRelationAggregateInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogOrderByRelationAggregateInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageOrderByRelationAggregateInput"] | undefined
};
	["BookingDetailsOrderByInput"]: {
	additionalInformation?: ModelTypes["SortOrder"] | undefined,
	email?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	phone?: ModelTypes["SortOrder"] | undefined
};
	["OrderSelectedDateOrderByInput"]: {
	endDate?: ModelTypes["SortOrder"] | undefined,
	startDate?: ModelTypes["SortOrder"] | undefined
};
	["BookingPaymentOrderByInput"]: {
	liveMode?: ModelTypes["SortOrder"] | undefined,
	checkoutSessionId?: ModelTypes["SortOrder"] | undefined,
	customer?: ModelTypes["BookingCustomerOrderByInput"] | undefined,
	paymentGateway?: ModelTypes["SortOrder"] | undefined,
	paymentId?: ModelTypes["SortOrder"] | undefined,
	sessionId?: ModelTypes["SortOrder"] | undefined,
	totalDetails?: ModelTypes["PaymentTotalDetailsOrderByInput"] | undefined,
	razorpay?: ModelTypes["SortOrder"] | undefined,
	stripe?: ModelTypes["SortOrder"] | undefined
};
	["BookingCustomerOrderByInput"]: {
	customerId?: ModelTypes["SortOrder"] | undefined,
	customerDetails?: ModelTypes["PaymentCustomerDetailsOrderByInput"] | undefined
};
	["PaymentCustomerDetailsOrderByInput"]: {
	email?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	phone?: ModelTypes["SortOrder"] | undefined
};
	["PaymentTotalDetailsOrderByInput"]: {
	discount?: ModelTypes["SortOrder"] | undefined,
	shipping?: ModelTypes["SortOrder"] | undefined,
	subtotal?: ModelTypes["SortOrder"] | undefined,
	tax?: ModelTypes["SortOrder"] | undefined,
	total?: ModelTypes["SortOrder"] | undefined
};
	["PriceSummaryOrderByInput"]: {
	days?: ModelTypes["SortOrder"] | undefined,
	discount?: ModelTypes["SortOrder"] | undefined,
	payableRent?: ModelTypes["SortOrder"] | undefined,
	rentPerDay?: ModelTypes["SortOrder"] | undefined,
	serviceCharge?: ModelTypes["SortOrder"] | undefined,
	totalRent?: ModelTypes["SortOrder"] | undefined
};
	["BookingPisignageDataOrderByInput"]: {
	deployedFileName?: ModelTypes["SortOrder"] | undefined,
	playlistId?: ModelTypes["SortOrder"] | undefined
};
	["BookingRefundOrderByInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	razorpay?: ModelTypes["SortOrder"] | undefined,
	stripe?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined
};
	["AdvertisementCategoryOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["CampaignOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	adCategoryId?: ModelTypes["SortOrder"] | undefined,
	payment?: ModelTypes["BookingPaymentOrderByInput"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryOrderByInput"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateOrderByInput"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	assetsIDs?: ModelTypes["SortOrder"] | undefined,
	user?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryOrderByWithRelationInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryOrderByRelationAggregateInput"] | undefined,
	bookings?: ModelTypes["BookingOrderByRelationAggregateInput"] | undefined,
	payments?: ModelTypes["PaymentOrderByRelationAggregateInput"] | undefined,
	assets?: ModelTypes["FileOrderByRelationAggregateInput"] | undefined
};
	["PaymentOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["PayoutOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["SlotUsageOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["BookingWhereUniqueInput"]: {
	id?: string | undefined,
	bookingCode?: string | undefined,
	AND?: Array<ModelTypes["BookingWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BookingWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingWhereInput"]> | undefined,
	additionalInformation?: ModelTypes["StringNullableFilter"] | undefined,
	origin?: ModelTypes["StringNullableFilter"] | undefined,
	assetsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingDate?: ModelTypes["DateTimeFilter"] | undefined,
	bookingFor?: ModelTypes["StringFilter"] | undefined,
	purpose?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	campaignId?: ModelTypes["StringNullableFilter"] | undefined,
	businessCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	adCategoryId?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCompositeFilter"] | undefined,
	bookingStatus?: ModelTypes["EnumBookingStatusFilter"] | undefined,
	paymentStatus?: ModelTypes["EnumPaymentStatusNullableFilter"] | undefined,
	deploymentStatus?: ModelTypes["EnumDeploymentStatusFilter"] | undefined,
	contentApprovalStatus?: ModelTypes["EnumContentApprovalStatusFilter"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCompositeFilter"] | undefined,
	payment?: ModelTypes["BookingPaymentNullableCompositeFilter"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryNullableCompositeFilter"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataNullableCompositeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	refund?: ModelTypes["BookingRefundNullableCompositeFilter"] | undefined,
	sharedUserIds?: ModelTypes["StringNullableListFilter"] | undefined,
	isOffline?: ModelTypes["BoolFilter"] | undefined,
	isPaid?: ModelTypes["BoolFilter"] | undefined,
	isScheduled?: ModelTypes["BoolFilter"] | undefined,
	isDeployed?: ModelTypes["BoolFilter"] | undefined,
	isCompleted?: ModelTypes["BoolFilter"] | undefined,
	assets?: ModelTypes["FileListRelationFilter"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryNullableRelationFilter"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryListRelationFilter"] | undefined,
	proofs?: ModelTypes["FileListRelationFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	changelogs?: ModelTypes["ChangelogListRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	campaign?: ModelTypes["CampaignNullableRelationFilter"] | undefined,
	payouts?: ModelTypes["PayoutListRelationFilter"] | undefined,
	sharedUsers?: ModelTypes["UserListRelationFilter"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogListRelationFilter"] | undefined,
	slotUsage?: ModelTypes["SlotUsageListRelationFilter"] | undefined
};
	["BookingScalarFieldEnum"]:BookingScalarFieldEnum;
	["Workspace"]: {
		id: string,
	type: ModelTypes["WorkspaceType"],
	name: string,
	key: string,
	userIds: Array<string>,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	stripeSubscriptionId?: string | undefined,
	leadIds: Array<string>,
	_count?: ModelTypes["WorkspaceCount"] | undefined,
	owner?: ModelTypes["User"] | undefined,
	memberships: Array<ModelTypes["Membership"]>,
	adBoards: Array<ModelTypes["AdBoard"]>,
	playlists: Array<ModelTypes["Playlist"]>,
	adBoardGroups: Array<ModelTypes["AdBoardGroup"]>,
	audits: Array<ModelTypes["Audit"]>,
	bookings: Array<ModelTypes["Booking"]>,
	files: Array<ModelTypes["File"]>,
	players: Array<ModelTypes["Player"]>,
	transactions: Array<ModelTypes["Transaction"]>,
	activeSubscription?: ModelTypes["Subscription"] | undefined,
	cameraServers: Array<ModelTypes["CameraServer"]>,
	workspaceRoles: Array<ModelTypes["WorkspaceRole"]>,
	webhookLogs: Array<ModelTypes["WebhookLog"]>,
	leads: Array<ModelTypes["User"]>
};
	["WorkspaceCount"]: {
		memberships: number,
	adBoards: number,
	playlists: number,
	adBoardGroups: number,
	audits: number,
	bookings: number,
	files: number,
	players: number,
	transactions: number,
	cameraServers: number,
	workspaceRoles: number,
	webhookLogs: number,
	leads: number
};
	["Membership"]: {
		id: string,
	createdAt: ModelTypes["DateTime"],
	userId: string,
	workspaceId: string,
	roleId: string,
	user: ModelTypes["User"],
	workspace: ModelTypes["Workspace"],
	role: ModelTypes["WorkspaceRole"]
};
	["WorkspaceRole"]: {
		id: string,
	name: string,
	permissions: Array<ModelTypes["Permission"]>,
	workspaceId?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	_count?: ModelTypes["WorkspaceRoleCount"] | undefined,
	workspace?: ModelTypes["Workspace"] | undefined,
	memberships: Array<ModelTypes["Membership"]>
};
	["WorkspaceRoleCount"]: {
		memberships: number
};
	["MembershipOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	roleId?: ModelTypes["SortOrder"] | undefined,
	user?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	role?: ModelTypes["WorkspaceRoleOrderByWithRelationInput"] | undefined
};
	["WorkspaceRoleOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	permissions?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	memberships?: ModelTypes["MembershipOrderByRelationAggregateInput"] | undefined
};
	["MembershipWhereUniqueInput"]: {
	id?: string | undefined,
	userId_workspaceId?: ModelTypes["MembershipUserIdWorkspaceIdCompoundUniqueInput"] | undefined,
	AND?: Array<ModelTypes["MembershipWhereInput"]> | undefined,
	OR?: Array<ModelTypes["MembershipWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["MembershipWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	roleId?: ModelTypes["StringFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceRelationFilter"] | undefined,
	role?: ModelTypes["WorkspaceRoleRelationFilter"] | undefined
};
	["MembershipUserIdWorkspaceIdCompoundUniqueInput"]: {
	userId: string,
	workspaceId: string
};
	["MembershipScalarFieldEnum"]:MembershipScalarFieldEnum;
	["Playlist"]: {
		id: string,
	name: string,
	displayName?: string | undefined,
	assets: Array<ModelTypes["PisignageFile"]>,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout: ModelTypes["ScreenLayout"],
	isDeployed?: boolean | undefined,
	adBoardGroupIds: Array<string>,
	workspaceId: string,
	_count?: ModelTypes["PlaylistCount"] | undefined,
	footerPlaylist?: ModelTypes["Playlist"] | undefined,
	sidebarPlaylist?: ModelTypes["Playlist"] | undefined,
	adBoardGroupsForAds: Array<ModelTypes["AdBoardGroup"]>,
	adBoardGroups: Array<ModelTypes["AdBoardGroup"]>,
	workspace: ModelTypes["Workspace"],
	footerParentPlaylists: Array<ModelTypes["Playlist"]>,
	sidebarParentPlaylists: Array<ModelTypes["Playlist"]>
};
	["PisignageFile"]: {
		name: string,
	duration?: number | undefined,
	muteAudio?: boolean | undefined,
	isVideo?: boolean | undefined
};
	["PlaylistCount"]: {
		adBoardGroupsForAds: number,
	adBoardGroups: number,
	footerParentPlaylists: number,
	sidebarParentPlaylists: number
};
	["AdBoardGroup"]: {
		id: string,
	name: string,
	adBoardId?: string | undefined,
	schedules: Array<ModelTypes["Schedule"]>,
	scheduledPlaylistsIDs: Array<string>,
	adsPlaylistID?: string | undefined,
	pisignageGroupId: string,
	fitImage: ModelTypes["FitType"],
	fitVideo: ModelTypes["FitType"],
	workspaceId: string,
	_count?: ModelTypes["AdBoardGroupCount"] | undefined,
	adBoard?: ModelTypes["AdBoard"] | undefined,
	scheduledPlaylists: Array<ModelTypes["Playlist"]>,
	adsPlaylist?: ModelTypes["Playlist"] | undefined,
	pisignagePlayers: Array<ModelTypes["Player"]>,
	workspace: ModelTypes["Workspace"]
};
	["Schedule"]: {
		playlistId: string,
	playlistType?: string | undefined,
	skipForSchedule?: boolean | undefined,
	startTime?: string | undefined,
	endTime?: string | undefined
};
	["AdBoardGroupCount"]: {
		scheduledPlaylists: number,
	pisignagePlayers: number
};
	["PlaylistWhereUniqueInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	AND?: Array<ModelTypes["PlaylistWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlaylistWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlaylistWhereInput"]> | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	assets?: Array<ModelTypes["PisignageFileObjectEqualityInput"]> | undefined,
	isAdsPlaylist?: ModelTypes["BoolNullableFilter"] | undefined,
	footerPlaylistId?: ModelTypes["StringNullableFilter"] | undefined,
	sidebarPlaylistId?: ModelTypes["StringNullableFilter"] | undefined,
	layout?: ModelTypes["EnumScreenLayoutFilter"] | undefined,
	isDeployed?: ModelTypes["BoolNullableFilter"] | undefined,
	adBoardGroupIds?: ModelTypes["StringNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	footerPlaylist?: ModelTypes["PlaylistNullableRelationFilter"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistNullableRelationFilter"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupListRelationFilter"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupListRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceRelationFilter"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistListRelationFilter"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistListRelationFilter"] | undefined
};
	["PlaylistScalarFieldEnum"]:PlaylistScalarFieldEnum;
	["Player"]: {
		id: string,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	playlistIds: Array<string>,
	workpaceId?: string | undefined,
	resolution?: ModelTypes["Resolution"] | undefined,
	screenSize?: ModelTypes["ScreenSize"] | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId: Array<string>,
	playbackDetails: Array<ModelTypes["PlaybackTime"]>,
	aspectRatioType: ModelTypes["AspectRatioType"],
	galleryImgs: Array<ModelTypes["GalleryFile"]>,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["Map"] | undefined,
	isOnline: boolean,
	adBoardIds: Array<string>,
	_count?: ModelTypes["PlayerCount"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroup"] | undefined,
	workspace?: ModelTypes["Workspace"] | undefined,
	status: Array<ModelTypes["PlayerUptimeStatus"]>,
	cameras: Array<ModelTypes["Camera"]>,
	signageType?: ModelTypes["DigitalSignage"] | undefined,
	audienceType: Array<ModelTypes["Audience"]>,
	adBoards: Array<ModelTypes["AdBoard"]>
};
	["GalleryFile"]: {
		name: string,
	url: string,
	contentType: string,
	width: number,
	height: number,
	duration?: number | undefined
};
	["PlayerCount"]: {
		status: number,
	cameras: number,
	audienceType: number,
	adBoards: number
};
	["PlayerUptimeStatus"]: {
		id: string,
	playerId: string,
	cpuSerialNumber: string,
	date: string,
	uptime: number,
	player: ModelTypes["Player"]
};
	["PlayerUptimeStatusOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	playerId?: ModelTypes["SortOrder"] | undefined,
	cpuSerialNumber?: ModelTypes["SortOrder"] | undefined,
	date?: ModelTypes["SortOrder"] | undefined,
	uptime?: ModelTypes["SortOrder"] | undefined,
	player?: ModelTypes["PlayerOrderByWithRelationInput"] | undefined
};
	["PlayerOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	configLocation?: ModelTypes["SortOrder"] | undefined,
	pisignagePlayerId?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	cpuSerialNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	adBoardGroupId?: ModelTypes["SortOrder"] | undefined,
	playlistIds?: ModelTypes["SortOrder"] | undefined,
	workpaceId?: ModelTypes["SortOrder"] | undefined,
	resolution?: ModelTypes["ResolutionOrderByInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeOrderByInput"] | undefined,
	signageTypeId?: ModelTypes["SortOrder"] | undefined,
	audienceTypeId?: ModelTypes["SortOrder"] | undefined,
	playbackDetails?: ModelTypes["PlaybackTimeOrderByCompositeAggregateInput"] | undefined,
	aspectRatioType?: ModelTypes["SortOrder"] | undefined,
	galleryImgs?: ModelTypes["GalleryFileOrderByCompositeAggregateInput"] | undefined,
	customAspectRatioWidth?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: ModelTypes["SortOrder"] | undefined,
	map?: ModelTypes["MapOrderByInput"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	adBoardIds?: ModelTypes["SortOrder"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupOrderByWithRelationInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusOrderByRelationAggregateInput"] | undefined,
	cameras?: ModelTypes["CameraOrderByRelationAggregateInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageOrderByWithRelationInput"] | undefined,
	audienceType?: ModelTypes["AudienceOrderByRelationAggregateInput"] | undefined,
	adBoards?: ModelTypes["AdBoardOrderByRelationAggregateInput"] | undefined
};
	["GalleryFileOrderByCompositeAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["PlayerUptimeStatusOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["DigitalSignageOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	players?: ModelTypes["PlayerOrderByRelationAggregateInput"] | undefined
};
	["AudienceOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["PlayerUptimeStatusWhereUniqueInput"]: {
	id?: string | undefined,
	cpuSerialNumber_date?: ModelTypes["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"] | undefined,
	AND?: Array<ModelTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	playerId?: ModelTypes["StringFilter"] | undefined,
	cpuSerialNumber?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["StringFilter"] | undefined,
	uptime?: ModelTypes["FloatFilter"] | undefined,
	player?: ModelTypes["PlayerRelationFilter"] | undefined
};
	["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"]: {
	cpuSerialNumber: string,
	date: string
};
	["PlayerUptimeStatusScalarFieldEnum"]:PlayerUptimeStatusScalarFieldEnum;
	["Camera"]: {
		id: string,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers: Array<string>,
	playerId?: string | undefined,
	updatedAt: ModelTypes["DateTime"],
	isPublic: boolean,
	adBoardId?: string | undefined,
	_count?: ModelTypes["CameraCount"] | undefined,
	cameraServer: ModelTypes["CameraServer"],
	player?: ModelTypes["Player"] | undefined,
	feeds: Array<ModelTypes["CameraFeed"]>,
	adBoard?: ModelTypes["AdBoard"] | undefined
};
	["CameraCount"]: {
		feeds: number
};
	["CameraServer"]: {
		id: string,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	workspaceId?: string | undefined,
	publicIp: string,
	privateIp: string,
	_count?: ModelTypes["CameraServerCount"] | undefined,
	workspace?: ModelTypes["Workspace"] | undefined,
	cameras: Array<ModelTypes["Camera"]>,
	feeds: Array<ModelTypes["CameraFeed"]>
};
	["CameraServerCount"]: {
		cameras: number,
	feeds: number
};
	["CameraOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined,
	cameraUrl?: ModelTypes["SortOrder"] | undefined,
	rtspUrl?: ModelTypes["SortOrder"] | undefined,
	cameraIp?: ModelTypes["SortOrder"] | undefined,
	connectedCpuSerialNumbers?: ModelTypes["SortOrder"] | undefined,
	playerId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	isPublic?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	cameraServer?: ModelTypes["CameraServerOrderByWithRelationInput"] | undefined,
	player?: ModelTypes["PlayerOrderByWithRelationInput"] | undefined,
	feeds?: ModelTypes["CameraFeedOrderByRelationAggregateInput"] | undefined,
	adBoard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined
};
	["CameraServerOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	hostName?: ModelTypes["SortOrder"] | undefined,
	userName?: ModelTypes["SortOrder"] | undefined,
	deviceId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	publicIp?: ModelTypes["SortOrder"] | undefined,
	privateIp?: ModelTypes["SortOrder"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	cameras?: ModelTypes["CameraOrderByRelationAggregateInput"] | undefined,
	feeds?: ModelTypes["CameraFeedOrderByRelationAggregateInput"] | undefined
};
	["CameraFeedOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["CameraWhereUniqueInput"]: {
	id?: string | undefined,
	cameraUrl?: string | undefined,
	cameraIp_cameraServerId?: ModelTypes["CameraCameraIpCameraServerIdCompoundUniqueInput"] | undefined,
	AND?: Array<ModelTypes["CameraWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraWhereInput"]> | undefined,
	name?: ModelTypes["StringNullableFilter"] | undefined,
	isOnline?: ModelTypes["BoolFilter"] | undefined,
	cameraServerId?: ModelTypes["StringFilter"] | undefined,
	rtspUrl?: ModelTypes["StringFilter"] | undefined,
	cameraIp?: ModelTypes["StringFilter"] | undefined,
	connectedCpuSerialNumbers?: ModelTypes["StringNullableListFilter"] | undefined,
	playerId?: ModelTypes["StringNullableFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	isPublic?: ModelTypes["BoolFilter"] | undefined,
	adBoardId?: ModelTypes["StringNullableFilter"] | undefined,
	cameraServer?: ModelTypes["CameraServerRelationFilter"] | undefined,
	player?: ModelTypes["PlayerNullableRelationFilter"] | undefined,
	feeds?: ModelTypes["CameraFeedListRelationFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardNullableRelationFilter"] | undefined
};
	["CameraCameraIpCameraServerIdCompoundUniqueInput"]: {
	cameraIp: string,
	cameraServerId: string
};
	["CameraScalarFieldEnum"]:CameraScalarFieldEnum;
	["CameraFeed"]: {
		id: string,
	cameraId: string,
	timestamp: ModelTypes["DateTime"],
	cameraServerId: string,
	data: ModelTypes["CameraFeedData"],
	camera: ModelTypes["Camera"],
	cameraServer: ModelTypes["CameraServer"]
};
	["CameraFeedData"]: {
		person?: number | undefined,
	car?: number | undefined,
	truck?: number | undefined,
	motorcycle?: number | undefined
};
	["CameraFeedOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	cameraId?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined,
	data?: ModelTypes["CameraFeedDataOrderByInput"] | undefined,
	camera?: ModelTypes["CameraOrderByWithRelationInput"] | undefined,
	cameraServer?: ModelTypes["CameraServerOrderByWithRelationInput"] | undefined
};
	["CameraFeedDataOrderByInput"]: {
	person?: ModelTypes["SortOrder"] | undefined,
	car?: ModelTypes["SortOrder"] | undefined,
	truck?: ModelTypes["SortOrder"] | undefined,
	motorcycle?: ModelTypes["SortOrder"] | undefined
};
	["CameraFeedWhereUniqueInput"]: {
	id?: string | undefined,
	cameraId_timestamp?: ModelTypes["CameraFeedCameraIdTimestampCompoundUniqueInput"] | undefined,
	AND?: Array<ModelTypes["CameraFeedWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraFeedWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraFeedWhereInput"]> | undefined,
	cameraId?: ModelTypes["StringFilter"] | undefined,
	timestamp?: ModelTypes["DateTimeFilter"] | undefined,
	cameraServerId?: ModelTypes["StringFilter"] | undefined,
	data?: ModelTypes["CameraFeedDataCompositeFilter"] | undefined,
	camera?: ModelTypes["CameraRelationFilter"] | undefined,
	cameraServer?: ModelTypes["CameraServerRelationFilter"] | undefined
};
	["CameraFeedCameraIdTimestampCompoundUniqueInput"]: {
	cameraId: string,
	timestamp: ModelTypes["DateTime"]
};
	["CameraFeedScalarFieldEnum"]:CameraFeedScalarFieldEnum;
	["DigitalSignage"]: {
		id: string,
	type: ModelTypes["SignageType"],
	name: string,
	description?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	_count?: ModelTypes["DigitalSignageCount"] | undefined,
	players: Array<ModelTypes["Player"]>
};
	["DigitalSignageCount"]: {
		players: number
};
	["PlayerWhereUniqueInput"]: {
	id?: string | undefined,
	cpuSerialNumber?: string | undefined,
	AND?: Array<ModelTypes["PlayerWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlayerWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlayerWhereInput"]> | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	configLocation?: ModelTypes["StringNullableFilter"] | undefined,
	pisignagePlayerId?: ModelTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: ModelTypes["StringNullableFilter"] | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	adBoardGroupId?: ModelTypes["StringNullableFilter"] | undefined,
	playlistIds?: ModelTypes["StringNullableListFilter"] | undefined,
	workpaceId?: ModelTypes["StringNullableFilter"] | undefined,
	resolution?: ModelTypes["ResolutionNullableCompositeFilter"] | undefined,
	screenSize?: ModelTypes["ScreenSizeNullableCompositeFilter"] | undefined,
	signageTypeId?: ModelTypes["StringNullableFilter"] | undefined,
	audienceTypeId?: ModelTypes["StringNullableListFilter"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeObjectEqualityInput"]> | undefined,
	aspectRatioType?: ModelTypes["EnumAspectRatioTypeFilter"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileObjectEqualityInput"]> | undefined,
	customAspectRatioWidth?: ModelTypes["IntNullableFilter"] | undefined,
	customAspectRatioHeight?: ModelTypes["IntNullableFilter"] | undefined,
	map?: ModelTypes["MapNullableCompositeFilter"] | undefined,
	isOnline?: ModelTypes["BoolFilter"] | undefined,
	adBoardIds?: ModelTypes["StringNullableListFilter"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupNullableRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusListRelationFilter"] | undefined,
	cameras?: ModelTypes["CameraListRelationFilter"] | undefined,
	signageType?: ModelTypes["DigitalSignageNullableRelationFilter"] | undefined,
	audienceType?: ModelTypes["AudienceListRelationFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined
};
	["PlayerScalarFieldEnum"]:PlayerScalarFieldEnum;
	["Audience"]: {
		id: string,
	type: ModelTypes["AudienceType"],
	name: string,
	description?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	playerIDs: Array<string>,
	_count?: ModelTypes["AudienceCount"] | undefined,
	players: Array<ModelTypes["Player"]>
};
	["AudienceCount"]: {
		players: number
};
	["AudienceOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	playerIDs?: ModelTypes["SortOrder"] | undefined,
	players?: ModelTypes["PlayerOrderByRelationAggregateInput"] | undefined
};
	["AudienceWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["AudienceWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AudienceWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AudienceWhereInput"]> | undefined,
	type?: ModelTypes["EnumAudienceTypeFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	playerIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	players?: ModelTypes["PlayerListRelationFilter"] | undefined
};
	["AudienceScalarFieldEnum"]:AudienceScalarFieldEnum;
	["AdBoardGroupWhereUniqueInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	adBoardId?: string | undefined,
	pisignageGroupId?: string | undefined,
	AND?: Array<ModelTypes["AdBoardGroupWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardGroupWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardGroupWhereInput"]> | undefined,
	schedules?: Array<ModelTypes["ScheduleObjectEqualityInput"]> | undefined,
	scheduledPlaylistsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	adsPlaylistID?: ModelTypes["StringNullableFilter"] | undefined,
	fitImage?: ModelTypes["EnumFitTypeFilter"] | undefined,
	fitVideo?: ModelTypes["EnumFitTypeFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardNullableRelationFilter"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistListRelationFilter"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistNullableRelationFilter"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerListRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceRelationFilter"] | undefined
};
	["AdBoardGroupScalarFieldEnum"]:AdBoardGroupScalarFieldEnum;
	["Audit"]: {
		id: string,
	createdAt: ModelTypes["DateTime"],
	message: string,
	action: string,
	entity?: ModelTypes["Entity"] | undefined,
	userId: string,
	workspaceId?: string | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	user: ModelTypes["User"],
	workspace?: ModelTypes["Workspace"] | undefined
};
	["Entity"]: {
		type: string,
	id: string,
	name?: string | undefined
};
	["AuditOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	message?: ModelTypes["SortOrder"] | undefined,
	action?: ModelTypes["SortOrder"] | undefined,
	entity?: ModelTypes["EntityOrderByInput"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	updates?: ModelTypes["SortOrder"] | undefined,
	user?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined
};
	["EntityOrderByInput"]: {
	type?: ModelTypes["SortOrder"] | undefined,
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined
};
	["AuditWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["AuditWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AuditWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AuditWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	message?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["StringFilter"] | undefined,
	entity?: ModelTypes["EntityNullableCompositeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	updates?: ModelTypes["JsonNullableFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined
};
	["AuditScalarFieldEnum"]:AuditScalarFieldEnum;
	["FileOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	contentType?: ModelTypes["SortOrder"] | undefined,
	ctime?: ModelTypes["SortOrder"] | undefined,
	filename?: ModelTypes["SortOrder"] | undefined,
	filepath?: ModelTypes["SortOrder"] | undefined,
	isDirectory?: ModelTypes["SortOrder"] | undefined,
	meta?: ModelTypes["FileMetaOrderByInput"] | undefined,
	mtime?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	size?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedByUserId?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	url?: ModelTypes["SortOrder"] | undefined,
	bookingsIDs?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	proofBookingId?: ModelTypes["SortOrder"] | undefined,
	campaignIds?: ModelTypes["SortOrder"] | undefined,
	updatedBy?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	createdBy?: ModelTypes["UserOrderByWithRelationInput"] | undefined,
	bookings?: ModelTypes["BookingOrderByRelationAggregateInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	proofBooking?: ModelTypes["BookingOrderByWithRelationInput"] | undefined,
	campaigns?: ModelTypes["CampaignOrderByRelationAggregateInput"] | undefined
};
	["FileMetaOrderByInput"]: {
	duration?: ModelTypes["SortOrder"] | undefined,
	height?: ModelTypes["SortOrder"] | undefined,
	width?: ModelTypes["SortOrder"] | undefined
};
	["FileWhereUniqueInput"]: {
	id?: string | undefined,
	url?: string | undefined,
	AND?: Array<ModelTypes["FileWhereInput"]> | undefined,
	OR?: Array<ModelTypes["FileWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["FileWhereInput"]> | undefined,
	contentType?: ModelTypes["StringFilter"] | undefined,
	ctime?: ModelTypes["StringFilter"] | undefined,
	filename?: ModelTypes["StringFilter"] | undefined,
	filepath?: ModelTypes["StringNullableFilter"] | undefined,
	isDirectory?: ModelTypes["BoolFilter"] | undefined,
	meta?: ModelTypes["FileMetaCompositeFilter"] | undefined,
	mtime?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	size?: ModelTypes["IntFilter"] | undefined,
	status?: ModelTypes["EnumFileStatusNullableFilter"] | undefined,
	type?: ModelTypes["StringFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	createdByUserId?: ModelTypes["StringFilter"] | undefined,
	bookingsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	proofBookingId?: ModelTypes["StringNullableFilter"] | undefined,
	campaignIds?: ModelTypes["StringNullableListFilter"] | undefined,
	updatedBy?: ModelTypes["UserNullableRelationFilter"] | undefined,
	createdBy?: ModelTypes["UserRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	proofBooking?: ModelTypes["BookingNullableRelationFilter"] | undefined,
	campaigns?: ModelTypes["CampaignListRelationFilter"] | undefined
};
	["FileScalarFieldEnum"]:FileScalarFieldEnum;
	["Transaction"]: {
		workspaceId?: string | undefined,
	id: string,
	amount: number,
	createAt: ModelTypes["DateTime"],
	processedAt?: ModelTypes["DateTime"] | undefined,
	relatedEntityId: string,
	transactionStatus: ModelTypes["TransactionStatus"],
	details?: ModelTypes["JSON"] | undefined,
	_count?: ModelTypes["TransactionCount"] | undefined,
	workspace?: ModelTypes["Workspace"] | undefined,
	payouts: Array<ModelTypes["Payout"]>
};
	["TransactionCount"]: {
		payouts: number
};
	["Payout"]: {
		id: string,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt: ModelTypes["DateTime"],
	bookingId: string,
	status: ModelTypes["PayoutStatus"],
	transactionId?: string | undefined,
	booking: ModelTypes["Booking"],
	transaction?: ModelTypes["Transaction"] | undefined
};
	["PayoutOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	totalAmount?: ModelTypes["SortOrder"] | undefined,
	commissionRate?: ModelTypes["SortOrder"] | undefined,
	commissionAmount?: ModelTypes["SortOrder"] | undefined,
	netAmount?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	bookingId?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	transactionId?: ModelTypes["SortOrder"] | undefined,
	booking?: ModelTypes["BookingOrderByWithRelationInput"] | undefined,
	transaction?: ModelTypes["TransactionOrderByWithRelationInput"] | undefined
};
	["TransactionOrderByWithRelationInput"]: {
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	id?: ModelTypes["SortOrder"] | undefined,
	amount?: ModelTypes["SortOrder"] | undefined,
	createAt?: ModelTypes["SortOrder"] | undefined,
	processedAt?: ModelTypes["SortOrder"] | undefined,
	relatedEntityId?: ModelTypes["SortOrder"] | undefined,
	transactionStatus?: ModelTypes["SortOrder"] | undefined,
	details?: ModelTypes["SortOrder"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	payouts?: ModelTypes["PayoutOrderByRelationAggregateInput"] | undefined
};
	["PayoutWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["PayoutWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PayoutWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PayoutWhereInput"]> | undefined,
	totalAmount?: ModelTypes["FloatFilter"] | undefined,
	commissionRate?: ModelTypes["FloatFilter"] | undefined,
	commissionAmount?: ModelTypes["FloatFilter"] | undefined,
	netAmount?: ModelTypes["FloatFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	status?: ModelTypes["EnumPayoutStatusFilter"] | undefined,
	transactionId?: ModelTypes["StringNullableFilter"] | undefined,
	booking?: ModelTypes["BookingRelationFilter"] | undefined,
	transaction?: ModelTypes["TransactionNullableRelationFilter"] | undefined
};
	["PayoutScalarFieldEnum"]:PayoutScalarFieldEnum;
	["TransactionWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["TransactionWhereInput"]> | undefined,
	OR?: Array<ModelTypes["TransactionWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["TransactionWhereInput"]> | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	amount?: ModelTypes["FloatFilter"] | undefined,
	createAt?: ModelTypes["DateTimeFilter"] | undefined,
	processedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	relatedEntityId?: ModelTypes["StringFilter"] | undefined,
	transactionStatus?: ModelTypes["EnumTransactionStatusFilter"] | undefined,
	details?: ModelTypes["JsonNullableFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	payouts?: ModelTypes["PayoutListRelationFilter"] | undefined
};
	["TransactionScalarFieldEnum"]:TransactionScalarFieldEnum;
	["Subscription"]: {
		id: string,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status: string,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId: string,
	stripeCheckoutSessionId: string,
	cancellationDetails?: ModelTypes["CancellationDetails"] | undefined,
	activeWorkspaceId: string,
	activeWorkspace: ModelTypes["Workspace"]
};
	["CancellationDetails"]: {
		canceledAt?: ModelTypes["DateTime"] | undefined,
	cancelAt?: ModelTypes["DateTime"] | undefined,
	cancelAtPeriodEnd: boolean,
	reason?: string | undefined
};
	["CameraServerWhereUniqueInput"]: {
	id?: string | undefined,
	deviceId?: string | undefined,
	AND?: Array<ModelTypes["CameraServerWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraServerWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraServerWhereInput"]> | undefined,
	name?: ModelTypes["StringNullableFilter"] | undefined,
	isOnline?: ModelTypes["BoolNullableFilter"] | undefined,
	hostName?: ModelTypes["StringNullableFilter"] | undefined,
	userName?: ModelTypes["StringNullableFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	publicIp?: ModelTypes["StringFilter"] | undefined,
	privateIp?: ModelTypes["StringFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	cameras?: ModelTypes["CameraListRelationFilter"] | undefined,
	feeds?: ModelTypes["CameraFeedListRelationFilter"] | undefined
};
	["CameraServerScalarFieldEnum"]:CameraServerScalarFieldEnum;
	["WorkspaceRoleWhereUniqueInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	name_workspaceId?: ModelTypes["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"] | undefined,
	AND?: Array<ModelTypes["WorkspaceRoleWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WorkspaceRoleWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WorkspaceRoleWhereInput"]> | undefined,
	permissions?: ModelTypes["EnumPermissionNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined,
	memberships?: ModelTypes["MembershipListRelationFilter"] | undefined
};
	["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"]: {
	name: string,
	workspaceId: string
};
	["WorkspaceRoleScalarFieldEnum"]:WorkspaceRoleScalarFieldEnum;
	["WebhookLog"]: {
		id: string,
	bookingId: string,
	response?: ModelTypes["JSON"] | undefined,
	request?: ModelTypes["JSON"] | undefined,
	status: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	workspaceId?: string | undefined,
	booking: ModelTypes["Booking"],
	workspace?: ModelTypes["Workspace"] | undefined
};
	["WebhookLogOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	bookingId?: ModelTypes["SortOrder"] | undefined,
	response?: ModelTypes["SortOrder"] | undefined,
	request?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	booking?: ModelTypes["BookingOrderByWithRelationInput"] | undefined,
	workspace?: ModelTypes["WorkspaceOrderByWithRelationInput"] | undefined
};
	["WebhookLogWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["WebhookLogWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WebhookLogWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WebhookLogWhereInput"]> | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	response?: ModelTypes["JsonNullableFilter"] | undefined,
	request?: ModelTypes["JsonNullableFilter"] | undefined,
	status?: ModelTypes["IntFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	booking?: ModelTypes["BookingRelationFilter"] | undefined,
	workspace?: ModelTypes["WorkspaceNullableRelationFilter"] | undefined
};
	["WebhookLogScalarFieldEnum"]:WebhookLogScalarFieldEnum;
	["UserWhereUniqueInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email?: string | undefined,
	AND?: Array<ModelTypes["UserWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserWhereInput"]> | undefined,
	image?: ModelTypes["StringNullableFilter"] | undefined,
	phoneNumber?: ModelTypes["StringNullableFilter"] | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	otherDetails?: ModelTypes["JsonNullableFilter"] | undefined,
	stripe?: ModelTypes["UserStripeNullableCompositeFilter"] | undefined,
	roles?: ModelTypes["EnumRoleNullableListFilter"] | undefined,
	refunds?: Array<ModelTypes["BookingRefundObjectEqualityInput"]> | undefined,
	sharedBookingIds?: ModelTypes["StringNullableListFilter"] | undefined,
	disclaimerAccepted?: ModelTypes["BoolFilter"] | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceNullableCompositeFilter"] | undefined,
	leadWorkspaceIds?: ModelTypes["StringNullableListFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	createdFiles?: ModelTypes["FileListRelationFilter"] | undefined,
	updatedFiles?: ModelTypes["FileListRelationFilter"] | undefined,
	orders?: ModelTypes["OrderListRelationFilter"] | undefined,
	changelogs?: ModelTypes["ChangelogListRelationFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	audits?: ModelTypes["AuditListRelationFilter"] | undefined,
	campaigns?: ModelTypes["CampaignListRelationFilter"] | undefined,
	sharedBookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	memberships?: ModelTypes["MembershipListRelationFilter"] | undefined,
	notifications?: ModelTypes["NotificationListRelationFilter"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryListRelationFilter"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentListRelationFilter"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceListRelationFilter"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceListRelationFilter"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewListRelationFilter"] | undefined,
	userPositions?: ModelTypes["UserPositionListRelationFilter"] | undefined
};
	["UserScalarFieldEnum"]:UserScalarFieldEnum;
	["CampaignWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["CampaignWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CampaignWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CampaignWhereInput"]> | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	bookingFor?: ModelTypes["StringFilter"] | undefined,
	purpose?: ModelTypes["StringFilter"] | undefined,
	businessCategoryId?: ModelTypes["StringFilter"] | undefined,
	adCategoryId?: ModelTypes["StringNullableListFilter"] | undefined,
	payment?: ModelTypes["BookingPaymentNullableCompositeFilter"] | undefined,
	paymentStatus?: ModelTypes["EnumPaymentStatusFilter"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryNullableCompositeFilter"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCompositeFilter"] | undefined,
	origin?: ModelTypes["StringFilter"] | undefined,
	assetsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryRelationFilter"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	payments?: ModelTypes["PaymentListRelationFilter"] | undefined,
	assets?: ModelTypes["FileListRelationFilter"] | undefined
};
	["CampaignScalarFieldEnum"]:CampaignScalarFieldEnum;
	["AdvertisementCategory"]: {
		id: string,
	name: string,
	type: ModelTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	campaignIds: Array<string>,
	bookingIds: Array<string>,
	_count?: ModelTypes["AdvertisementCategoryCount"] | undefined,
	campaigns: Array<ModelTypes["Campaign"]>,
	bookings: Array<ModelTypes["Booking"]>
};
	["AdvertisementCategoryCount"]: {
		campaigns: number,
	bookings: number
};
	["AdvertisementCategoryOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	campaignIds?: ModelTypes["SortOrder"] | undefined,
	bookingIds?: ModelTypes["SortOrder"] | undefined,
	campaigns?: ModelTypes["CampaignOrderByRelationAggregateInput"] | undefined,
	bookings?: ModelTypes["BookingOrderByRelationAggregateInput"] | undefined
};
	["AdvertisementCategoryWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["AdvertisementCategoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdvertisementCategoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdvertisementCategoryWhereInput"]> | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumAdvertisementCategoryEnumFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	campaignIds?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingIds?: ModelTypes["StringNullableListFilter"] | undefined,
	campaigns?: ModelTypes["CampaignListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined
};
	["AdvertisementCategoryScalarFieldEnum"]:AdvertisementCategoryScalarFieldEnum;
	["Changelog"]: {
		id: string,
	createdAt: ModelTypes["DateTime"],
	message: string,
	action: string,
	bookingId?: string | undefined,
	userId: string,
	updates?: ModelTypes["JSON"] | undefined,
	booking?: ModelTypes["Booking"] | undefined,
	user: ModelTypes["User"]
};
	["ChangelogOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	message?: ModelTypes["SortOrder"] | undefined,
	action?: ModelTypes["SortOrder"] | undefined,
	bookingId?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	updates?: ModelTypes["SortOrder"] | undefined,
	booking?: ModelTypes["BookingOrderByWithRelationInput"] | undefined,
	user?: ModelTypes["UserOrderByWithRelationInput"] | undefined
};
	["ChangelogWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["ChangelogWhereInput"]> | undefined,
	OR?: Array<ModelTypes["ChangelogWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["ChangelogWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	message?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["StringFilter"] | undefined,
	bookingId?: ModelTypes["StringNullableFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	updates?: ModelTypes["JsonNullableFilter"] | undefined,
	booking?: ModelTypes["BookingNullableRelationFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined
};
	["ChangelogScalarFieldEnum"]:ChangelogScalarFieldEnum;
	["SlotUsage"]: {
		id: string,
	bookingId: string,
	date: ModelTypes["DateTime"],
	slotUsed: number,
	booking: ModelTypes["Booking"]
};
	["SlotUsageOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	bookingId?: ModelTypes["SortOrder"] | undefined,
	date?: ModelTypes["SortOrder"] | undefined,
	slotUsed?: ModelTypes["SortOrder"] | undefined,
	booking?: ModelTypes["BookingOrderByWithRelationInput"] | undefined
};
	["SlotUsageWhereUniqueInput"]: {
	id?: string | undefined,
	bookingId_date?: ModelTypes["SlotUsageBookingIdDateCompoundUniqueInput"] | undefined,
	AND?: Array<ModelTypes["SlotUsageWhereInput"]> | undefined,
	OR?: Array<ModelTypes["SlotUsageWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["SlotUsageWhereInput"]> | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["DateTimeFilter"] | undefined,
	slotUsed?: ModelTypes["IntFilter"] | undefined,
	booking?: ModelTypes["BookingRelationFilter"] | undefined
};
	["SlotUsageBookingIdDateCompoundUniqueInput"]: {
	bookingId: string,
	date: ModelTypes["DateTime"]
};
	["SlotUsageScalarFieldEnum"]:SlotUsageScalarFieldEnum;
	["Order"]: {
		id: string,
	adBoardId: string,
	createdDate: ModelTypes["DateTime"],
	createdByUserId?: string | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: ModelTypes["PriceSummary"],
	selectedDate: ModelTypes["OrderSelectedDate"],
	adBoard: ModelTypes["AdBoard"],
	createdBy?: ModelTypes["User"] | undefined
};
	["OrderOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	createdDate?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	paymentId?: ModelTypes["SortOrder"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryOrderByInput"] | undefined,
	selectedDate?: ModelTypes["OrderSelectedDateOrderByInput"] | undefined,
	adBoard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined,
	createdBy?: ModelTypes["UserOrderByWithRelationInput"] | undefined
};
	["OrderWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["OrderWhereInput"]> | undefined,
	OR?: Array<ModelTypes["OrderWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["OrderWhereInput"]> | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	createdDate?: ModelTypes["DateTimeFilter"] | undefined,
	createdByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	origin?: ModelTypes["StringFilter"] | undefined,
	paymentId?: ModelTypes["StringNullableFilter"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCompositeFilter"] | undefined,
	selectedDate?: ModelTypes["OrderSelectedDateCompositeFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	createdBy?: ModelTypes["UserNullableRelationFilter"] | undefined
};
	["OrderScalarFieldEnum"]:OrderScalarFieldEnum;
	["Notification"]: {
		id: string,
	type: ModelTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt: ModelTypes["DateTime"],
	readAt?: ModelTypes["DateTime"] | undefined,
	recipientId: string,
	channels: Array<ModelTypes["NotificationChannel"]>,
	recipient: ModelTypes["User"]
};
	["NotificationOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	content?: ModelTypes["SortOrder"] | undefined,
	metadata?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	readAt?: ModelTypes["SortOrder"] | undefined,
	recipientId?: ModelTypes["SortOrder"] | undefined,
	channels?: ModelTypes["SortOrder"] | undefined,
	recipient?: ModelTypes["UserOrderByWithRelationInput"] | undefined
};
	["NotificationWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["NotificationWhereInput"]> | undefined,
	OR?: Array<ModelTypes["NotificationWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["NotificationWhereInput"]> | undefined,
	type?: ModelTypes["EnumNotificationTypeFilter"] | undefined,
	title?: ModelTypes["StringFilter"] | undefined,
	content?: ModelTypes["StringFilter"] | undefined,
	metadata?: ModelTypes["JsonNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	readAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	recipientId?: ModelTypes["StringFilter"] | undefined,
	channels?: ModelTypes["EnumNotificationChannelNullableListFilter"] | undefined,
	recipient?: ModelTypes["UserRelationFilter"] | undefined
};
	["NotificationScalarFieldEnum"]:NotificationScalarFieldEnum;
	["AdBoardPublishHistory"]: {
		id: string,
	adBoardId: string,
	publishedAt: ModelTypes["DateTime"],
	publishedByUserId: string,
	changesSnapshot: ModelTypes["JSON"],
	adBoard: ModelTypes["AdBoard"],
	publishedBy: ModelTypes["User"]
};
	["AdBoardPublishHistoryOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	publishedAt?: ModelTypes["SortOrder"] | undefined,
	publishedByUserId?: ModelTypes["SortOrder"] | undefined,
	changesSnapshot?: ModelTypes["SortOrder"] | undefined,
	adBoard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined,
	publishedBy?: ModelTypes["UserOrderByWithRelationInput"] | undefined
};
	["AdBoardPublishHistoryWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	publishedAt?: ModelTypes["DateTimeFilter"] | undefined,
	publishedByUserId?: ModelTypes["StringFilter"] | undefined,
	changesSnapshot?: ModelTypes["JsonFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	publishedBy?: ModelTypes["UserRelationFilter"] | undefined
};
	["AdBoardPublishHistoryScalarFieldEnum"]:AdBoardPublishHistoryScalarFieldEnum;
	["AdBoardReviewComment"]: {
		id: string,
	adBoardId: string,
	userId: string,
	comment: string,
	action: ModelTypes["ReviewAction"],
	createdAt: ModelTypes["DateTime"],
	adBoard: ModelTypes["AdBoard"],
	user: ModelTypes["User"]
};
	["AdBoardReviewCommentOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	comment?: ModelTypes["SortOrder"] | undefined,
	action?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	adBoard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined,
	user?: ModelTypes["UserOrderByWithRelationInput"] | undefined
};
	["AdBoardReviewCommentWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	comment?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["EnumReviewActionFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined
};
	["AdBoardReviewCommentScalarFieldEnum"]:AdBoardReviewCommentScalarFieldEnum;
	["WorkspaceWhereUniqueInput"]: {
	id?: string | undefined,
	key?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	AND?: Array<ModelTypes["WorkspaceWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WorkspaceWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WorkspaceWhereInput"]> | undefined,
	type?: ModelTypes["EnumWorkspaceTypeFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	userIds?: ModelTypes["StringNullableListFilter"] | undefined,
	ownerId?: ModelTypes["StringNullableFilter"] | undefined,
	avatar?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	leadIds?: ModelTypes["StringNullableListFilter"] | undefined,
	owner?: ModelTypes["UserNullableRelationFilter"] | undefined,
	memberships?: ModelTypes["MembershipListRelationFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	playlists?: ModelTypes["PlaylistListRelationFilter"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupListRelationFilter"] | undefined,
	audits?: ModelTypes["AuditListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined,
	files?: ModelTypes["FileListRelationFilter"] | undefined,
	players?: ModelTypes["PlayerListRelationFilter"] | undefined,
	transactions?: ModelTypes["TransactionListRelationFilter"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionNullableRelationFilter"] | undefined,
	cameraServers?: ModelTypes["CameraServerListRelationFilter"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleListRelationFilter"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogListRelationFilter"] | undefined,
	leads?: ModelTypes["UserListRelationFilter"] | undefined
};
	["WorkspaceScalarFieldEnum"]:WorkspaceScalarFieldEnum;
	["AdBoardPublicReview"]: {
		id: string,
	adBoardId: string,
	userId: string,
	rating: number,
	comment?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	adBoard: ModelTypes["AdBoard"],
	user: ModelTypes["User"]
};
	["AdBoardPublicReviewOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	rating?: ModelTypes["SortOrder"] | undefined,
	comment?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	adBoard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined,
	user?: ModelTypes["UserOrderByWithRelationInput"] | undefined
};
	["AdBoardPublicReviewWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	rating?: ModelTypes["IntFilter"] | undefined,
	comment?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined
};
	["AdBoardPublicReviewScalarFieldEnum"]:AdBoardPublicReviewScalarFieldEnum;
	["UserPosition"]: {
		id: string,
	timestamp: ModelTypes["DateTime"],
	createdAt: ModelTypes["DateTime"],
	userId: string,
	coords: ModelTypes["UserPositionCoords"],
	location?: ModelTypes["UserPositionLocation"] | undefined,
	user: ModelTypes["User"]
};
	["UserPositionCoords"]: {
		accuracy?: number | undefined,
	altitude?: number | undefined,
	altitudeAccuracy?: number | undefined,
	heading?: number | undefined,
	latitude: number,
	longitude: number,
	speed?: number | undefined
};
	["UserPositionLocation"]: {
		address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["UserPositionOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	coords?: ModelTypes["UserPositionCoordsOrderByInput"] | undefined,
	location?: ModelTypes["UserPositionLocationOrderByInput"] | undefined,
	user?: ModelTypes["UserOrderByWithRelationInput"] | undefined
};
	["UserPositionCoordsOrderByInput"]: {
	accuracy?: ModelTypes["SortOrder"] | undefined,
	altitude?: ModelTypes["SortOrder"] | undefined,
	altitudeAccuracy?: ModelTypes["SortOrder"] | undefined,
	heading?: ModelTypes["SortOrder"] | undefined,
	latitude?: ModelTypes["SortOrder"] | undefined,
	longitude?: ModelTypes["SortOrder"] | undefined,
	speed?: ModelTypes["SortOrder"] | undefined
};
	["UserPositionLocationOrderByInput"]: {
	address?: ModelTypes["SortOrder"] | undefined,
	city?: ModelTypes["SortOrder"] | undefined,
	state?: ModelTypes["SortOrder"] | undefined,
	country?: ModelTypes["SortOrder"] | undefined
};
	["UserPositionWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["UserPositionWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserPositionWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserPositionWhereInput"]> | undefined,
	timestamp?: ModelTypes["DateTimeFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	coords?: ModelTypes["UserPositionCoordsCompositeFilter"] | undefined,
	location?: ModelTypes["UserPositionLocationNullableCompositeFilter"] | undefined,
	user?: ModelTypes["UserRelationFilter"] | undefined
};
	["UserPositionScalarFieldEnum"]:UserPositionScalarFieldEnum;
	["Payment"]: {
		id: string,
	paymentIntentId: string,
	checkoutSessionId: string,
	eventId: string,
	liveMode: boolean,
	paymentGateway: ModelTypes["PaymentGatewayName"],
	subtotal: number,
	total: number,
	tax: number,
	discount: number,
	campaignId?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	campaign?: ModelTypes["Campaign"] | undefined
};
	["PaymentOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	paymentIntentId?: ModelTypes["SortOrder"] | undefined,
	checkoutSessionId?: ModelTypes["SortOrder"] | undefined,
	eventId?: ModelTypes["SortOrder"] | undefined,
	liveMode?: ModelTypes["SortOrder"] | undefined,
	paymentGateway?: ModelTypes["SortOrder"] | undefined,
	subtotal?: ModelTypes["SortOrder"] | undefined,
	total?: ModelTypes["SortOrder"] | undefined,
	tax?: ModelTypes["SortOrder"] | undefined,
	discount?: ModelTypes["SortOrder"] | undefined,
	campaignId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	campaign?: ModelTypes["CampaignOrderByWithRelationInput"] | undefined
};
	["PaymentWhereUniqueInput"]: {
	id?: string | undefined,
	paymentIntentId?: string | undefined,
	checkoutSessionId?: string | undefined,
	eventId?: string | undefined,
	AND?: Array<ModelTypes["PaymentWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PaymentWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PaymentWhereInput"]> | undefined,
	liveMode?: ModelTypes["BoolFilter"] | undefined,
	paymentGateway?: ModelTypes["EnumPaymentGatewayNameFilter"] | undefined,
	subtotal?: ModelTypes["FloatFilter"] | undefined,
	total?: ModelTypes["FloatFilter"] | undefined,
	tax?: ModelTypes["FloatFilter"] | undefined,
	discount?: ModelTypes["FloatFilter"] | undefined,
	campaignId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	campaign?: ModelTypes["CampaignNullableRelationFilter"] | undefined
};
	["PaymentScalarFieldEnum"]:PaymentScalarFieldEnum;
	["BusinessCategoryWhereUniqueInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	AND?: Array<ModelTypes["BusinessCategoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BusinessCategoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BusinessCategoryWhereInput"]> | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	restrictedByAdBoardIds?: ModelTypes["StringNullableListFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	campaigns?: ModelTypes["CampaignListRelationFilter"] | undefined,
	bookings?: ModelTypes["BookingListRelationFilter"] | undefined
};
	["BusinessCategoryScalarFieldEnum"]:BusinessCategoryScalarFieldEnum;
	["SlotConfiguration"]: {
		id: string,
	adBoardId: string,
	date: ModelTypes["DateTime"],
	availableSlots: number,
	adBoard: ModelTypes["AdBoard"]
};
	["SlotConfigurationOrderByWithRelationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	date?: ModelTypes["SortOrder"] | undefined,
	availableSlots?: ModelTypes["SortOrder"] | undefined,
	adBoard?: ModelTypes["AdBoardOrderByWithRelationInput"] | undefined
};
	["SlotConfigurationWhereUniqueInput"]: {
	id?: string | undefined,
	adBoardId_date?: ModelTypes["SlotConfigurationAdBoardIdDateCompoundUniqueInput"] | undefined,
	AND?: Array<ModelTypes["SlotConfigurationWhereInput"]> | undefined,
	OR?: Array<ModelTypes["SlotConfigurationWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["SlotConfigurationWhereInput"]> | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["DateTimeFilter"] | undefined,
	availableSlots?: ModelTypes["IntFilter"] | undefined,
	adBoard?: ModelTypes["AdBoardRelationFilter"] | undefined
};
	["SlotConfigurationAdBoardIdDateCompoundUniqueInput"]: {
	adBoardId: string,
	date: ModelTypes["DateTime"]
};
	["SlotConfigurationScalarFieldEnum"]:SlotConfigurationScalarFieldEnum;
	["AdBoardGroupBy"]: {
		id: string,
	address: string,
	state: string,
	city: string,
	country: string,
	outdoor: boolean,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive: boolean,
	numberOfDisplays: number,
	dailyOperationHours: number,
	adsPerLoop: number,
	adsDuration: number,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled: boolean,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft: boolean,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived: boolean,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	_count?: ModelTypes["AdBoardCountAggregate"] | undefined,
	_avg?: ModelTypes["AdBoardAvgAggregate"] | undefined,
	_sum?: ModelTypes["AdBoardSumAggregate"] | undefined,
	_min?: ModelTypes["AdBoardMinAggregate"] | undefined,
	_max?: ModelTypes["AdBoardMaxAggregate"] | undefined
};
	["AdBoardOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	address?: ModelTypes["SortOrder"] | undefined,
	state?: ModelTypes["SortOrder"] | undefined,
	city?: ModelTypes["SortOrder"] | undefined,
	country?: ModelTypes["SortOrder"] | undefined,
	outdoor?: ModelTypes["SortOrder"] | undefined,
	locationCategoryId?: ModelTypes["SortOrder"] | undefined,
	locationSubCategoryId?: ModelTypes["SortOrder"] | undefined,
	alias?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	pricePerDay?: ModelTypes["SortOrder"] | undefined,
	originalPricePerDay?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	adBoardTypeId?: ModelTypes["SortOrder"] | undefined,
	tags?: ModelTypes["SortOrder"] | undefined,
	featuredImage?: ModelTypes["SortOrder"] | undefined,
	galleryImgs?: ModelTypes["SortOrder"] | undefined,
	notes?: ModelTypes["SortOrder"] | undefined,
	impressionMultiplier?: ModelTypes["SortOrder"] | undefined,
	categoryId?: ModelTypes["SortOrder"] | undefined,
	restrictedBusinessIds?: ModelTypes["SortOrder"] | undefined,
	targetAudiences?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	adboardDraftStatus?: ModelTypes["SortOrder"] | undefined,
	isLive?: ModelTypes["SortOrder"] | undefined,
	numberOfDisplays?: ModelTypes["SortOrder"] | undefined,
	dailyOperationHours?: ModelTypes["SortOrder"] | undefined,
	adsPerLoop?: ModelTypes["SortOrder"] | undefined,
	adsDuration?: ModelTypes["SortOrder"] | undefined,
	monthlyPrice?: ModelTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: ModelTypes["SortOrder"] | undefined,
	offlineBookingContactId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	href?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	playerIds?: ModelTypes["SortOrder"] | undefined,
	publisherScreenId?: ModelTypes["SortOrder"] | undefined,
	additionalData?: ModelTypes["SortOrder"] | undefined,
	isDraft?: ModelTypes["SortOrder"] | undefined,
	publishedVersionId?: ModelTypes["SortOrder"] | undefined,
	lastPublishedAt?: ModelTypes["SortOrder"] | undefined,
	isArchived?: ModelTypes["SortOrder"] | undefined,
	archivedAt?: ModelTypes["SortOrder"] | undefined,
	archivedByUserId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["AdBoardCountOrderByAggregateInput"] | undefined,
	_avg?: ModelTypes["AdBoardAvgOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["AdBoardMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["AdBoardMinOrderByAggregateInput"] | undefined,
	_sum?: ModelTypes["AdBoardSumOrderByAggregateInput"] | undefined
};
	["AdBoardCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	address?: ModelTypes["SortOrder"] | undefined,
	state?: ModelTypes["SortOrder"] | undefined,
	city?: ModelTypes["SortOrder"] | undefined,
	country?: ModelTypes["SortOrder"] | undefined,
	outdoor?: ModelTypes["SortOrder"] | undefined,
	locationCategoryId?: ModelTypes["SortOrder"] | undefined,
	locationSubCategoryId?: ModelTypes["SortOrder"] | undefined,
	alias?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	pricePerDay?: ModelTypes["SortOrder"] | undefined,
	originalPricePerDay?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	adBoardTypeId?: ModelTypes["SortOrder"] | undefined,
	tags?: ModelTypes["SortOrder"] | undefined,
	featuredImage?: ModelTypes["SortOrder"] | undefined,
	galleryImgs?: ModelTypes["SortOrder"] | undefined,
	notes?: ModelTypes["SortOrder"] | undefined,
	impressionMultiplier?: ModelTypes["SortOrder"] | undefined,
	categoryId?: ModelTypes["SortOrder"] | undefined,
	restrictedBusinessIds?: ModelTypes["SortOrder"] | undefined,
	targetAudiences?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	adboardDraftStatus?: ModelTypes["SortOrder"] | undefined,
	isLive?: ModelTypes["SortOrder"] | undefined,
	numberOfDisplays?: ModelTypes["SortOrder"] | undefined,
	dailyOperationHours?: ModelTypes["SortOrder"] | undefined,
	adsPerLoop?: ModelTypes["SortOrder"] | undefined,
	adsDuration?: ModelTypes["SortOrder"] | undefined,
	monthlyPrice?: ModelTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: ModelTypes["SortOrder"] | undefined,
	offlineBookingContactId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	href?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	playerIds?: ModelTypes["SortOrder"] | undefined,
	publisherScreenId?: ModelTypes["SortOrder"] | undefined,
	additionalData?: ModelTypes["SortOrder"] | undefined,
	isDraft?: ModelTypes["SortOrder"] | undefined,
	publishedVersionId?: ModelTypes["SortOrder"] | undefined,
	lastPublishedAt?: ModelTypes["SortOrder"] | undefined,
	isArchived?: ModelTypes["SortOrder"] | undefined,
	archivedAt?: ModelTypes["SortOrder"] | undefined,
	archivedByUserId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardAvgOrderByAggregateInput"]: {
	pricePerDay?: ModelTypes["SortOrder"] | undefined,
	originalPricePerDay?: ModelTypes["SortOrder"] | undefined,
	impressionMultiplier?: ModelTypes["SortOrder"] | undefined,
	numberOfDisplays?: ModelTypes["SortOrder"] | undefined,
	dailyOperationHours?: ModelTypes["SortOrder"] | undefined,
	adsPerLoop?: ModelTypes["SortOrder"] | undefined,
	adsDuration?: ModelTypes["SortOrder"] | undefined,
	monthlyPrice?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	address?: ModelTypes["SortOrder"] | undefined,
	state?: ModelTypes["SortOrder"] | undefined,
	city?: ModelTypes["SortOrder"] | undefined,
	country?: ModelTypes["SortOrder"] | undefined,
	outdoor?: ModelTypes["SortOrder"] | undefined,
	locationCategoryId?: ModelTypes["SortOrder"] | undefined,
	locationSubCategoryId?: ModelTypes["SortOrder"] | undefined,
	alias?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	pricePerDay?: ModelTypes["SortOrder"] | undefined,
	originalPricePerDay?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	adBoardTypeId?: ModelTypes["SortOrder"] | undefined,
	featuredImage?: ModelTypes["SortOrder"] | undefined,
	impressionMultiplier?: ModelTypes["SortOrder"] | undefined,
	categoryId?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	adboardDraftStatus?: ModelTypes["SortOrder"] | undefined,
	isLive?: ModelTypes["SortOrder"] | undefined,
	numberOfDisplays?: ModelTypes["SortOrder"] | undefined,
	dailyOperationHours?: ModelTypes["SortOrder"] | undefined,
	adsPerLoop?: ModelTypes["SortOrder"] | undefined,
	adsDuration?: ModelTypes["SortOrder"] | undefined,
	monthlyPrice?: ModelTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: ModelTypes["SortOrder"] | undefined,
	offlineBookingContactId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	href?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	publisherScreenId?: ModelTypes["SortOrder"] | undefined,
	isDraft?: ModelTypes["SortOrder"] | undefined,
	publishedVersionId?: ModelTypes["SortOrder"] | undefined,
	lastPublishedAt?: ModelTypes["SortOrder"] | undefined,
	isArchived?: ModelTypes["SortOrder"] | undefined,
	archivedAt?: ModelTypes["SortOrder"] | undefined,
	archivedByUserId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	address?: ModelTypes["SortOrder"] | undefined,
	state?: ModelTypes["SortOrder"] | undefined,
	city?: ModelTypes["SortOrder"] | undefined,
	country?: ModelTypes["SortOrder"] | undefined,
	outdoor?: ModelTypes["SortOrder"] | undefined,
	locationCategoryId?: ModelTypes["SortOrder"] | undefined,
	locationSubCategoryId?: ModelTypes["SortOrder"] | undefined,
	alias?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	pricePerDay?: ModelTypes["SortOrder"] | undefined,
	originalPricePerDay?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	adBoardTypeId?: ModelTypes["SortOrder"] | undefined,
	featuredImage?: ModelTypes["SortOrder"] | undefined,
	impressionMultiplier?: ModelTypes["SortOrder"] | undefined,
	categoryId?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	adboardDraftStatus?: ModelTypes["SortOrder"] | undefined,
	isLive?: ModelTypes["SortOrder"] | undefined,
	numberOfDisplays?: ModelTypes["SortOrder"] | undefined,
	dailyOperationHours?: ModelTypes["SortOrder"] | undefined,
	adsPerLoop?: ModelTypes["SortOrder"] | undefined,
	adsDuration?: ModelTypes["SortOrder"] | undefined,
	monthlyPrice?: ModelTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: ModelTypes["SortOrder"] | undefined,
	offlineBookingContactId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	href?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	publisherScreenId?: ModelTypes["SortOrder"] | undefined,
	isDraft?: ModelTypes["SortOrder"] | undefined,
	publishedVersionId?: ModelTypes["SortOrder"] | undefined,
	lastPublishedAt?: ModelTypes["SortOrder"] | undefined,
	isArchived?: ModelTypes["SortOrder"] | undefined,
	archivedAt?: ModelTypes["SortOrder"] | undefined,
	archivedByUserId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardSumOrderByAggregateInput"]: {
	pricePerDay?: ModelTypes["SortOrder"] | undefined,
	originalPricePerDay?: ModelTypes["SortOrder"] | undefined,
	impressionMultiplier?: ModelTypes["SortOrder"] | undefined,
	numberOfDisplays?: ModelTypes["SortOrder"] | undefined,
	dailyOperationHours?: ModelTypes["SortOrder"] | undefined,
	adsPerLoop?: ModelTypes["SortOrder"] | undefined,
	adsDuration?: ModelTypes["SortOrder"] | undefined,
	monthlyPrice?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	address?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	state?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	city?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	country?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	outdoor?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	locationCategoryId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	locationSubCategoryId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	alias?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	title?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	pricePerDay?: ModelTypes["IntWithAggregatesFilter"] | undefined,
	originalPricePerDay?: ModelTypes["IntNullableWithAggregatesFilter"] | undefined,
	description?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	adBoardTypeId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	tags?: ModelTypes["StringNullableListFilter"] | undefined,
	featuredImage?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	galleryImgs?: ModelTypes["StringNullableListFilter"] | undefined,
	notes?: ModelTypes["StringNullableListFilter"] | undefined,
	impressionMultiplier?: ModelTypes["FloatNullableWithAggregatesFilter"] | undefined,
	categoryId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	restrictedBusinessIds?: ModelTypes["StringNullableListFilter"] | undefined,
	targetAudiences?: ModelTypes["StringNullableListFilter"] | undefined,
	status?: ModelTypes["EnumAdBoardStatusWithAggregatesFilter"] | undefined,
	adboardDraftStatus?: ModelTypes["EnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined,
	isLive?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	numberOfDisplays?: ModelTypes["IntWithAggregatesFilter"] | undefined,
	dailyOperationHours?: ModelTypes["IntWithAggregatesFilter"] | undefined,
	adsPerLoop?: ModelTypes["IntWithAggregatesFilter"] | undefined,
	adsDuration?: ModelTypes["IntWithAggregatesFilter"] | undefined,
	monthlyPrice?: ModelTypes["FloatNullableWithAggregatesFilter"] | undefined,
	offlineBookingsEnabled?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	offlineBookingContactId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	createdByUserId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	href?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	workspaceId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	playerIds?: ModelTypes["StringNullableListFilter"] | undefined,
	publisherScreenId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	additionalData?: ModelTypes["JsonNullableWithAggregatesFilter"] | undefined,
	isDraft?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	publishedVersionId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	lastPublishedAt?: ModelTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	isArchived?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	archivedAt?: ModelTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	archivedByUserId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeNullableWithAggregatesFilter"] | undefined
};
	["StringWithAggregatesFilter"]: {
	equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	mode?: ModelTypes["QueryMode"] | undefined,
	not?: ModelTypes["NestedStringWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedStringFilter"] | undefined,
	_max?: ModelTypes["NestedStringFilter"] | undefined
};
	["NestedStringWithAggregatesFilter"]: {
	equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	not?: ModelTypes["NestedStringWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedStringFilter"] | undefined,
	_max?: ModelTypes["NestedStringFilter"] | undefined
};
	["BoolWithAggregatesFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["NestedBoolWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedBoolFilter"] | undefined,
	_max?: ModelTypes["NestedBoolFilter"] | undefined
};
	["NestedBoolWithAggregatesFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["NestedBoolWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedBoolFilter"] | undefined,
	_max?: ModelTypes["NestedBoolFilter"] | undefined
};
	["StringNullableWithAggregatesFilter"]: {
	equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	mode?: ModelTypes["QueryMode"] | undefined,
	not?: ModelTypes["NestedStringNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedStringNullableFilter"] | undefined,
	_max?: ModelTypes["NestedStringNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedStringNullableWithAggregatesFilter"]: {
	equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	not?: ModelTypes["NestedStringNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedStringNullableFilter"] | undefined,
	_max?: ModelTypes["NestedStringNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["IntWithAggregatesFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedIntWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_avg?: ModelTypes["NestedFloatFilter"] | undefined,
	_sum?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedIntFilter"] | undefined,
	_max?: ModelTypes["NestedIntFilter"] | undefined
};
	["NestedIntWithAggregatesFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedIntWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_avg?: ModelTypes["NestedFloatFilter"] | undefined,
	_sum?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedIntFilter"] | undefined,
	_max?: ModelTypes["NestedIntFilter"] | undefined
};
	["IntNullableWithAggregatesFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedIntNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_avg?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	_sum?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_max?: ModelTypes["NestedIntNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedIntNullableWithAggregatesFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedIntNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_avg?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	_sum?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_max?: ModelTypes["NestedIntNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["FloatNullableWithAggregatesFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedFloatNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_avg?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	_sum?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	_min?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	_max?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedFloatNullableWithAggregatesFilter"]: {
	equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: ModelTypes["NestedFloatNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_avg?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	_sum?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	_min?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	_max?: ModelTypes["NestedFloatNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["EnumAdBoardStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["AdBoardStatus"] | undefined,
	in?: Array<ModelTypes["AdBoardStatus"]> | undefined,
	notIn?: Array<ModelTypes["AdBoardStatus"]> | undefined,
	not?: ModelTypes["NestedEnumAdBoardStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumAdBoardStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumAdBoardStatusFilter"] | undefined
};
	["NestedEnumAdBoardStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["AdBoardStatus"] | undefined,
	in?: Array<ModelTypes["AdBoardStatus"]> | undefined,
	notIn?: Array<ModelTypes["AdBoardStatus"]> | undefined,
	not?: ModelTypes["NestedEnumAdBoardStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumAdBoardStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumAdBoardStatusFilter"] | undefined
};
	["EnumAdboardDraftStatusNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["AdboardDraftStatus"] | undefined,
	in?: Array<ModelTypes["AdboardDraftStatus"]> | undefined,
	notIn?: Array<ModelTypes["AdboardDraftStatus"]> | undefined,
	not?: ModelTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	_max?: ModelTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["AdboardDraftStatus"] | undefined,
	in?: Array<ModelTypes["AdboardDraftStatus"]> | undefined,
	notIn?: Array<ModelTypes["AdboardDraftStatus"]> | undefined,
	not?: ModelTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	_max?: ModelTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["DateTimeNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["NestedDateTimeNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedDateTimeNullableFilter"] | undefined,
	_max?: ModelTypes["NestedDateTimeNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedDateTimeNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["NestedDateTimeNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedDateTimeNullableFilter"] | undefined,
	_max?: ModelTypes["NestedDateTimeNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["JsonNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not?: ModelTypes["JSON"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedJsonNullableFilter"] | undefined,
	_max?: ModelTypes["NestedJsonNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedJsonNullableFilter"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not?: ModelTypes["JSON"] | undefined,
	isSet?: boolean | undefined
};
	["AggregateAdBoardGroup"]: {
		_count?: ModelTypes["AdBoardGroupCountAggregate"] | undefined,
	_min?: ModelTypes["AdBoardGroupMinAggregate"] | undefined,
	_max?: ModelTypes["AdBoardGroupMaxAggregate"] | undefined
};
	["AdBoardGroupCountAggregate"]: {
		id: number,
	name: number,
	adBoardId: number,
	scheduledPlaylistsIDs: number,
	adsPlaylistID: number,
	pisignageGroupId: number,
	fitImage: number,
	fitVideo: number,
	workspaceId: number,
	_all: number
};
	["AdBoardGroupMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	adBoardId?: string | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	workspaceId?: string | undefined
};
	["AdBoardGroupMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	adBoardId?: string | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	workspaceId?: string | undefined
};
	["AdBoardGroupGroupBy"]: {
		id: string,
	name: string,
	adBoardId?: string | undefined,
	scheduledPlaylistsIDs?: Array<string> | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId: string,
	fitImage: ModelTypes["FitType"],
	fitVideo: ModelTypes["FitType"],
	workspaceId: string,
	_count?: ModelTypes["AdBoardGroupCountAggregate"] | undefined,
	_min?: ModelTypes["AdBoardGroupMinAggregate"] | undefined,
	_max?: ModelTypes["AdBoardGroupMaxAggregate"] | undefined
};
	["AdBoardGroupOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	scheduledPlaylistsIDs?: ModelTypes["SortOrder"] | undefined,
	adsPlaylistID?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	fitImage?: ModelTypes["SortOrder"] | undefined,
	fitVideo?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["AdBoardGroupCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["AdBoardGroupMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["AdBoardGroupMinOrderByAggregateInput"] | undefined
};
	["AdBoardGroupCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	scheduledPlaylistsIDs?: ModelTypes["SortOrder"] | undefined,
	adsPlaylistID?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	fitImage?: ModelTypes["SortOrder"] | undefined,
	fitVideo?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardGroupMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	adsPlaylistID?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	fitImage?: ModelTypes["SortOrder"] | undefined,
	fitVideo?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardGroupMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	adsPlaylistID?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	fitImage?: ModelTypes["SortOrder"] | undefined,
	fitVideo?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardGroupScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	adBoardId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	scheduledPlaylistsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	adsPlaylistID?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	pisignageGroupId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	fitImage?: ModelTypes["EnumFitTypeWithAggregatesFilter"] | undefined,
	fitVideo?: ModelTypes["EnumFitTypeWithAggregatesFilter"] | undefined,
	workspaceId?: ModelTypes["StringWithAggregatesFilter"] | undefined
};
	["EnumFitTypeWithAggregatesFilter"]: {
	equals?: ModelTypes["FitType"] | undefined,
	in?: Array<ModelTypes["FitType"]> | undefined,
	notIn?: Array<ModelTypes["FitType"]> | undefined,
	not?: ModelTypes["NestedEnumFitTypeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumFitTypeFilter"] | undefined,
	_max?: ModelTypes["NestedEnumFitTypeFilter"] | undefined
};
	["NestedEnumFitTypeWithAggregatesFilter"]: {
	equals?: ModelTypes["FitType"] | undefined,
	in?: Array<ModelTypes["FitType"]> | undefined,
	notIn?: Array<ModelTypes["FitType"]> | undefined,
	not?: ModelTypes["NestedEnumFitTypeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumFitTypeFilter"] | undefined,
	_max?: ModelTypes["NestedEnumFitTypeFilter"] | undefined
};
	["AggregateAdBoardType"]: {
		_count?: ModelTypes["AdBoardTypeCountAggregate"] | undefined,
	_min?: ModelTypes["AdBoardTypeMinAggregate"] | undefined,
	_max?: ModelTypes["AdBoardTypeMaxAggregate"] | undefined
};
	["AdBoardTypeCountAggregate"]: {
		id: number,
	name: number,
	description: number,
	_all: number
};
	["AdBoardTypeMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["AdBoardTypeMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["AdBoardTypeWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["AdBoardTypeWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardTypeWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardTypeWhereInput"]> | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined
};
	["AdBoardTypeScalarFieldEnum"]:AdBoardTypeScalarFieldEnum;
	["AdBoardTypeGroupBy"]: {
		id: string,
	name: string,
	description?: string | undefined,
	_count?: ModelTypes["AdBoardTypeCountAggregate"] | undefined,
	_min?: ModelTypes["AdBoardTypeMinAggregate"] | undefined,
	_max?: ModelTypes["AdBoardTypeMaxAggregate"] | undefined
};
	["AdBoardTypeOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["AdBoardTypeCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["AdBoardTypeMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["AdBoardTypeMinOrderByAggregateInput"] | undefined
};
	["AdBoardTypeCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardTypeMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardTypeMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined
};
	["AdBoardTypeScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	description?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined
};
	["AggregateAdvertisementCategory"]: {
		_count?: ModelTypes["AdvertisementCategoryCountAggregate"] | undefined,
	_min?: ModelTypes["AdvertisementCategoryMinAggregate"] | undefined,
	_max?: ModelTypes["AdvertisementCategoryMaxAggregate"] | undefined
};
	["AdvertisementCategoryCountAggregate"]: {
		id: number,
	name: number,
	type: number,
	description: number,
	createdAt: number,
	updatedAt: number,
	campaignIds: number,
	bookingIds: number,
	_all: number
};
	["AdvertisementCategoryMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	type?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AdvertisementCategoryMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	type?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AdvertisementCategoryGroupBy"]: {
		id: string,
	name: string,
	type: ModelTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	campaignIds?: Array<string> | undefined,
	bookingIds?: Array<string> | undefined,
	_count?: ModelTypes["AdvertisementCategoryCountAggregate"] | undefined,
	_min?: ModelTypes["AdvertisementCategoryMinAggregate"] | undefined,
	_max?: ModelTypes["AdvertisementCategoryMaxAggregate"] | undefined
};
	["AdvertisementCategoryOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	campaignIds?: ModelTypes["SortOrder"] | undefined,
	bookingIds?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["AdvertisementCategoryCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["AdvertisementCategoryMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["AdvertisementCategoryMinOrderByAggregateInput"] | undefined
};
	["AdvertisementCategoryCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	campaignIds?: ModelTypes["SortOrder"] | undefined,
	bookingIds?: ModelTypes["SortOrder"] | undefined
};
	["AdvertisementCategoryMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["AdvertisementCategoryMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["AdvertisementCategoryScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	type?: ModelTypes["EnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined,
	description?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	campaignIds?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["EnumAdvertisementCategoryEnumWithAggregatesFilter"]: {
	equals?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	in?: Array<ModelTypes["AdvertisementCategoryEnum"]> | undefined,
	notIn?: Array<ModelTypes["AdvertisementCategoryEnum"]> | undefined,
	not?: ModelTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined,
	_max?: ModelTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined
};
	["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"]: {
	equals?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	in?: Array<ModelTypes["AdvertisementCategoryEnum"]> | undefined,
	notIn?: Array<ModelTypes["AdvertisementCategoryEnum"]> | undefined,
	not?: ModelTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined,
	_max?: ModelTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined
};
	["DateTimeWithAggregatesFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["NestedDateTimeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedDateTimeFilter"] | undefined,
	_max?: ModelTypes["NestedDateTimeFilter"] | undefined
};
	["NestedDateTimeWithAggregatesFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["NestedDateTimeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedDateTimeFilter"] | undefined,
	_max?: ModelTypes["NestedDateTimeFilter"] | undefined
};
	["AggregateAudit"]: {
		_count?: ModelTypes["AuditCountAggregate"] | undefined,
	_min?: ModelTypes["AuditMinAggregate"] | undefined,
	_max?: ModelTypes["AuditMaxAggregate"] | undefined
};
	["AuditCountAggregate"]: {
		id: number,
	createdAt: number,
	message: number,
	action: number,
	userId: number,
	workspaceId: number,
	updates: number,
	_all: number
};
	["AuditMinAggregate"]: {
		id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	userId?: string | undefined,
	workspaceId?: string | undefined
};
	["AuditMaxAggregate"]: {
		id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	userId?: string | undefined,
	workspaceId?: string | undefined
};
	["AuditGroupBy"]: {
		id: string,
	createdAt: ModelTypes["DateTime"],
	message: string,
	action: string,
	userId: string,
	workspaceId?: string | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	_count?: ModelTypes["AuditCountAggregate"] | undefined,
	_min?: ModelTypes["AuditMinAggregate"] | undefined,
	_max?: ModelTypes["AuditMaxAggregate"] | undefined
};
	["AuditOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	message?: ModelTypes["SortOrder"] | undefined,
	action?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	updates?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["AuditCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["AuditMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["AuditMinOrderByAggregateInput"] | undefined
};
	["AuditCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	message?: ModelTypes["SortOrder"] | undefined,
	action?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	updates?: ModelTypes["SortOrder"] | undefined
};
	["AuditMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	message?: ModelTypes["SortOrder"] | undefined,
	action?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined
};
	["AuditMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	message?: ModelTypes["SortOrder"] | undefined,
	action?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined
};
	["AuditScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["AuditScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["AuditScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["AuditScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	message?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	action?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	userId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	updates?: ModelTypes["JsonNullableWithAggregatesFilter"] | undefined
};
	["AggregateBooking"]: {
		_count?: ModelTypes["BookingCountAggregate"] | undefined,
	_min?: ModelTypes["BookingMinAggregate"] | undefined,
	_max?: ModelTypes["BookingMaxAggregate"] | undefined
};
	["BookingCountAggregate"]: {
		id: number,
	additionalInformation: number,
	origin: number,
	assetsIDs: number,
	bookingCode: number,
	bookingDate: number,
	bookingFor: number,
	purpose: number,
	adBoardId: number,
	workspaceId: number,
	campaignId: number,
	businessCategoryId: number,
	adCategoryId: number,
	bookingStatus: number,
	paymentStatus: number,
	deploymentStatus: number,
	contentApprovalStatus: number,
	userId: number,
	sharedUserIds: number,
	isOffline: number,
	isPaid: number,
	isScheduled: number,
	isDeployed: number,
	isCompleted: number,
	_all: number
};
	["BookingMinAggregate"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId?: string | undefined,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	userId?: string | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["BookingMaxAggregate"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId?: string | undefined,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	userId?: string | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["BookingGroupBy"]: {
		id: string,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate: ModelTypes["DateTime"],
	bookingFor: string,
	purpose: string,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	userId: string,
	sharedUserIds?: Array<string> | undefined,
	isOffline: boolean,
	isPaid: boolean,
	isScheduled: boolean,
	isDeployed: boolean,
	isCompleted: boolean,
	_count?: ModelTypes["BookingCountAggregate"] | undefined,
	_min?: ModelTypes["BookingMinAggregate"] | undefined,
	_max?: ModelTypes["BookingMaxAggregate"] | undefined
};
	["BookingOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	additionalInformation?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	assetsIDs?: ModelTypes["SortOrder"] | undefined,
	bookingCode?: ModelTypes["SortOrder"] | undefined,
	bookingDate?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	campaignId?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	adCategoryId?: ModelTypes["SortOrder"] | undefined,
	bookingStatus?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	deploymentStatus?: ModelTypes["SortOrder"] | undefined,
	contentApprovalStatus?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	sharedUserIds?: ModelTypes["SortOrder"] | undefined,
	isOffline?: ModelTypes["SortOrder"] | undefined,
	isPaid?: ModelTypes["SortOrder"] | undefined,
	isScheduled?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	isCompleted?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["BookingCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["BookingMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["BookingMinOrderByAggregateInput"] | undefined
};
	["BookingCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	additionalInformation?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	assetsIDs?: ModelTypes["SortOrder"] | undefined,
	bookingCode?: ModelTypes["SortOrder"] | undefined,
	bookingDate?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	campaignId?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	adCategoryId?: ModelTypes["SortOrder"] | undefined,
	bookingStatus?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	deploymentStatus?: ModelTypes["SortOrder"] | undefined,
	contentApprovalStatus?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	sharedUserIds?: ModelTypes["SortOrder"] | undefined,
	isOffline?: ModelTypes["SortOrder"] | undefined,
	isPaid?: ModelTypes["SortOrder"] | undefined,
	isScheduled?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	isCompleted?: ModelTypes["SortOrder"] | undefined
};
	["BookingMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	additionalInformation?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	bookingCode?: ModelTypes["SortOrder"] | undefined,
	bookingDate?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	campaignId?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	bookingStatus?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	deploymentStatus?: ModelTypes["SortOrder"] | undefined,
	contentApprovalStatus?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	isOffline?: ModelTypes["SortOrder"] | undefined,
	isPaid?: ModelTypes["SortOrder"] | undefined,
	isScheduled?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	isCompleted?: ModelTypes["SortOrder"] | undefined
};
	["BookingMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	additionalInformation?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	bookingCode?: ModelTypes["SortOrder"] | undefined,
	bookingDate?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	campaignId?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	bookingStatus?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	deploymentStatus?: ModelTypes["SortOrder"] | undefined,
	contentApprovalStatus?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	isOffline?: ModelTypes["SortOrder"] | undefined,
	isPaid?: ModelTypes["SortOrder"] | undefined,
	isScheduled?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	isCompleted?: ModelTypes["SortOrder"] | undefined
};
	["BookingScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["BookingScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["BookingScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	additionalInformation?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	origin?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	assetsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingCode?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	bookingDate?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	bookingFor?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	purpose?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	adBoardId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	campaignId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	businessCategoryId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	adCategoryId?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingStatus?: ModelTypes["EnumBookingStatusWithAggregatesFilter"] | undefined,
	paymentStatus?: ModelTypes["EnumPaymentStatusNullableWithAggregatesFilter"] | undefined,
	deploymentStatus?: ModelTypes["EnumDeploymentStatusWithAggregatesFilter"] | undefined,
	contentApprovalStatus?: ModelTypes["EnumContentApprovalStatusWithAggregatesFilter"] | undefined,
	userId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	sharedUserIds?: ModelTypes["StringNullableListFilter"] | undefined,
	isOffline?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	isPaid?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	isScheduled?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	isDeployed?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	isCompleted?: ModelTypes["BoolWithAggregatesFilter"] | undefined
};
	["EnumBookingStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["BookingStatus"] | undefined,
	in?: Array<ModelTypes["BookingStatus"]> | undefined,
	notIn?: Array<ModelTypes["BookingStatus"]> | undefined,
	not?: ModelTypes["NestedEnumBookingStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumBookingStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumBookingStatusFilter"] | undefined
};
	["NestedEnumBookingStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["BookingStatus"] | undefined,
	in?: Array<ModelTypes["BookingStatus"]> | undefined,
	notIn?: Array<ModelTypes["BookingStatus"]> | undefined,
	not?: ModelTypes["NestedEnumBookingStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumBookingStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumBookingStatusFilter"] | undefined
};
	["EnumPaymentStatusNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["PaymentStatus"] | undefined,
	in?: Array<ModelTypes["PaymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["PaymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	_max?: ModelTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumPaymentStatusNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["PaymentStatus"] | undefined,
	in?: Array<ModelTypes["PaymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["PaymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	_max?: ModelTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["EnumDeploymentStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["DeploymentStatus"] | undefined,
	in?: Array<ModelTypes["DeploymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["DeploymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumDeploymentStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumDeploymentStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumDeploymentStatusFilter"] | undefined
};
	["NestedEnumDeploymentStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["DeploymentStatus"] | undefined,
	in?: Array<ModelTypes["DeploymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["DeploymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumDeploymentStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumDeploymentStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumDeploymentStatusFilter"] | undefined
};
	["EnumContentApprovalStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["ContentApprovalStatus"] | undefined,
	in?: Array<ModelTypes["ContentApprovalStatus"]> | undefined,
	notIn?: Array<ModelTypes["ContentApprovalStatus"]> | undefined,
	not?: ModelTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumContentApprovalStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumContentApprovalStatusFilter"] | undefined
};
	["NestedEnumContentApprovalStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["ContentApprovalStatus"] | undefined,
	in?: Array<ModelTypes["ContentApprovalStatus"]> | undefined,
	notIn?: Array<ModelTypes["ContentApprovalStatus"]> | undefined,
	not?: ModelTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumContentApprovalStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumContentApprovalStatusFilter"] | undefined
};
	["AggregateBusinessCategory"]: {
		_count?: ModelTypes["BusinessCategoryCountAggregate"] | undefined,
	_min?: ModelTypes["BusinessCategoryMinAggregate"] | undefined,
	_max?: ModelTypes["BusinessCategoryMaxAggregate"] | undefined
};
	["BusinessCategoryCountAggregate"]: {
		id: number,
	name: number,
	description: number,
	restrictedByAdBoardIds: number,
	_all: number
};
	["BusinessCategoryMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["BusinessCategoryMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["BusinessCategoryGroupBy"]: {
		id: string,
	name: string,
	description?: string | undefined,
	restrictedByAdBoardIds?: Array<string> | undefined,
	_count?: ModelTypes["BusinessCategoryCountAggregate"] | undefined,
	_min?: ModelTypes["BusinessCategoryMinAggregate"] | undefined,
	_max?: ModelTypes["BusinessCategoryMaxAggregate"] | undefined
};
	["BusinessCategoryOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	restrictedByAdBoardIds?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["BusinessCategoryCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["BusinessCategoryMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["BusinessCategoryMinOrderByAggregateInput"] | undefined
};
	["BusinessCategoryCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined,
	restrictedByAdBoardIds?: ModelTypes["SortOrder"] | undefined
};
	["BusinessCategoryMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined
};
	["BusinessCategoryMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	description?: ModelTypes["SortOrder"] | undefined
};
	["BusinessCategoryScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	description?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	restrictedByAdBoardIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["AggregateCamera"]: {
		_count?: ModelTypes["CameraCountAggregate"] | undefined,
	_min?: ModelTypes["CameraMinAggregate"] | undefined,
	_max?: ModelTypes["CameraMaxAggregate"] | undefined
};
	["CameraCountAggregate"]: {
		id: number,
	name: number,
	isOnline: number,
	cameraServerId: number,
	cameraUrl: number,
	rtspUrl: number,
	cameraIp: number,
	connectedCpuSerialNumbers: number,
	playerId: number,
	updatedAt: number,
	isPublic: number,
	adBoardId: number,
	_all: number
};
	["CameraMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraServerId?: string | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	playerId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["CameraMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraServerId?: string | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	playerId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["CameraGroupBy"]: {
		id: string,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	playerId?: string | undefined,
	updatedAt: ModelTypes["DateTime"],
	isPublic: boolean,
	adBoardId?: string | undefined,
	_count?: ModelTypes["CameraCountAggregate"] | undefined,
	_min?: ModelTypes["CameraMinAggregate"] | undefined,
	_max?: ModelTypes["CameraMaxAggregate"] | undefined
};
	["CameraOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined,
	cameraUrl?: ModelTypes["SortOrder"] | undefined,
	rtspUrl?: ModelTypes["SortOrder"] | undefined,
	cameraIp?: ModelTypes["SortOrder"] | undefined,
	connectedCpuSerialNumbers?: ModelTypes["SortOrder"] | undefined,
	playerId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	isPublic?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["CameraCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["CameraMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["CameraMinOrderByAggregateInput"] | undefined
};
	["CameraCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined,
	cameraUrl?: ModelTypes["SortOrder"] | undefined,
	rtspUrl?: ModelTypes["SortOrder"] | undefined,
	cameraIp?: ModelTypes["SortOrder"] | undefined,
	connectedCpuSerialNumbers?: ModelTypes["SortOrder"] | undefined,
	playerId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	isPublic?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined
};
	["CameraMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined,
	cameraUrl?: ModelTypes["SortOrder"] | undefined,
	rtspUrl?: ModelTypes["SortOrder"] | undefined,
	cameraIp?: ModelTypes["SortOrder"] | undefined,
	playerId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	isPublic?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined
};
	["CameraMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined,
	cameraUrl?: ModelTypes["SortOrder"] | undefined,
	rtspUrl?: ModelTypes["SortOrder"] | undefined,
	cameraIp?: ModelTypes["SortOrder"] | undefined,
	playerId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	isPublic?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined
};
	["CameraScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["CameraScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["CameraScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	isOnline?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	cameraServerId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	cameraUrl?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	rtspUrl?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	cameraIp?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	connectedCpuSerialNumbers?: ModelTypes["StringNullableListFilter"] | undefined,
	playerId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	isPublic?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	adBoardId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined
};
	["AggregateCameraFeed"]: {
		_count?: ModelTypes["CameraFeedCountAggregate"] | undefined,
	_min?: ModelTypes["CameraFeedMinAggregate"] | undefined,
	_max?: ModelTypes["CameraFeedMaxAggregate"] | undefined
};
	["CameraFeedCountAggregate"]: {
		id: number,
	cameraId: number,
	timestamp: number,
	cameraServerId: number,
	_all: number
};
	["CameraFeedMinAggregate"]: {
		id?: string | undefined,
	cameraId?: string | undefined,
	timestamp?: ModelTypes["DateTime"] | undefined,
	cameraServerId?: string | undefined
};
	["CameraFeedMaxAggregate"]: {
		id?: string | undefined,
	cameraId?: string | undefined,
	timestamp?: ModelTypes["DateTime"] | undefined,
	cameraServerId?: string | undefined
};
	["CameraFeedGroupBy"]: {
		id: string,
	cameraId: string,
	timestamp: ModelTypes["DateTime"],
	cameraServerId: string,
	_count?: ModelTypes["CameraFeedCountAggregate"] | undefined,
	_min?: ModelTypes["CameraFeedMinAggregate"] | undefined,
	_max?: ModelTypes["CameraFeedMaxAggregate"] | undefined
};
	["CameraFeedOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	cameraId?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["CameraFeedCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["CameraFeedMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["CameraFeedMinOrderByAggregateInput"] | undefined
};
	["CameraFeedCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	cameraId?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined
};
	["CameraFeedMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	cameraId?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined
};
	["CameraFeedMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	cameraId?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	cameraServerId?: ModelTypes["SortOrder"] | undefined
};
	["CameraFeedScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	cameraId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	timestamp?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	cameraServerId?: ModelTypes["StringWithAggregatesFilter"] | undefined
};
	["AggregateCameraServer"]: {
		_count?: ModelTypes["CameraServerCountAggregate"] | undefined,
	_min?: ModelTypes["CameraServerMinAggregate"] | undefined,
	_max?: ModelTypes["CameraServerMaxAggregate"] | undefined
};
	["CameraServerCountAggregate"]: {
		id: number,
	name: number,
	isOnline: number,
	hostName: number,
	userName: number,
	deviceId: number,
	workspaceId: number,
	publicIp: number,
	privateIp: number,
	_all: number
};
	["CameraServerMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	workspaceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined
};
	["CameraServerMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	workspaceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined
};
	["CameraServerGroupBy"]: {
		id: string,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	workspaceId?: string | undefined,
	publicIp: string,
	privateIp: string,
	_count?: ModelTypes["CameraServerCountAggregate"] | undefined,
	_min?: ModelTypes["CameraServerMinAggregate"] | undefined,
	_max?: ModelTypes["CameraServerMaxAggregate"] | undefined
};
	["CameraServerOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	hostName?: ModelTypes["SortOrder"] | undefined,
	userName?: ModelTypes["SortOrder"] | undefined,
	deviceId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	publicIp?: ModelTypes["SortOrder"] | undefined,
	privateIp?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["CameraServerCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["CameraServerMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["CameraServerMinOrderByAggregateInput"] | undefined
};
	["CameraServerCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	hostName?: ModelTypes["SortOrder"] | undefined,
	userName?: ModelTypes["SortOrder"] | undefined,
	deviceId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	publicIp?: ModelTypes["SortOrder"] | undefined,
	privateIp?: ModelTypes["SortOrder"] | undefined
};
	["CameraServerMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	hostName?: ModelTypes["SortOrder"] | undefined,
	userName?: ModelTypes["SortOrder"] | undefined,
	deviceId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	publicIp?: ModelTypes["SortOrder"] | undefined,
	privateIp?: ModelTypes["SortOrder"] | undefined
};
	["CameraServerMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	hostName?: ModelTypes["SortOrder"] | undefined,
	userName?: ModelTypes["SortOrder"] | undefined,
	deviceId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	publicIp?: ModelTypes["SortOrder"] | undefined,
	privateIp?: ModelTypes["SortOrder"] | undefined
};
	["CameraServerScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	isOnline?: ModelTypes["BoolNullableWithAggregatesFilter"] | undefined,
	hostName?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	userName?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	deviceId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	publicIp?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	privateIp?: ModelTypes["StringWithAggregatesFilter"] | undefined
};
	["BoolNullableWithAggregatesFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["NestedBoolNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedBoolNullableFilter"] | undefined,
	_max?: ModelTypes["NestedBoolNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedBoolNullableWithAggregatesFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["NestedBoolNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedBoolNullableFilter"] | undefined,
	_max?: ModelTypes["NestedBoolNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["AggregateCampaign"]: {
		_count?: ModelTypes["CampaignCountAggregate"] | undefined,
	_min?: ModelTypes["CampaignMinAggregate"] | undefined,
	_max?: ModelTypes["CampaignMaxAggregate"] | undefined
};
	["CampaignCountAggregate"]: {
		id: number,
	name: number,
	userId: number,
	createdAt: number,
	updatedAt: number,
	bookingFor: number,
	purpose: number,
	businessCategoryId: number,
	adCategoryId: number,
	paymentStatus: number,
	origin: number,
	assetsIDs: number,
	_all: number
};
	["CampaignMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	userId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	businessCategoryId?: string | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	origin?: string | undefined
};
	["CampaignMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	userId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	businessCategoryId?: string | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	origin?: string | undefined
};
	["CampaignGroupBy"]: {
		id: string,
	name: string,
	userId: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	bookingFor: string,
	purpose: string,
	businessCategoryId: string,
	adCategoryId?: Array<string> | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	origin: string,
	assetsIDs?: Array<string> | undefined,
	_count?: ModelTypes["CampaignCountAggregate"] | undefined,
	_min?: ModelTypes["CampaignMinAggregate"] | undefined,
	_max?: ModelTypes["CampaignMaxAggregate"] | undefined
};
	["CampaignOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	adCategoryId?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	assetsIDs?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["CampaignCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["CampaignMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["CampaignMinOrderByAggregateInput"] | undefined
};
	["CampaignCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	adCategoryId?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	assetsIDs?: ModelTypes["SortOrder"] | undefined
};
	["CampaignMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined
};
	["CampaignMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	bookingFor?: ModelTypes["SortOrder"] | undefined,
	purpose?: ModelTypes["SortOrder"] | undefined,
	businessCategoryId?: ModelTypes["SortOrder"] | undefined,
	paymentStatus?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined
};
	["CampaignScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	userId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	bookingFor?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	purpose?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	businessCategoryId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	adCategoryId?: ModelTypes["StringNullableListFilter"] | undefined,
	paymentStatus?: ModelTypes["EnumPaymentStatusWithAggregatesFilter"] | undefined,
	origin?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	assetsIDs?: ModelTypes["StringNullableListFilter"] | undefined
};
	["EnumPaymentStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["PaymentStatus"] | undefined,
	in?: Array<ModelTypes["PaymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["PaymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumPaymentStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumPaymentStatusFilter"] | undefined
};
	["NestedEnumPaymentStatusWithAggregatesFilter"]: {
	equals?: ModelTypes["PaymentStatus"] | undefined,
	in?: Array<ModelTypes["PaymentStatus"]> | undefined,
	notIn?: Array<ModelTypes["PaymentStatus"]> | undefined,
	not?: ModelTypes["NestedEnumPaymentStatusWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumPaymentStatusFilter"] | undefined,
	_max?: ModelTypes["NestedEnumPaymentStatusFilter"] | undefined
};
	["AggregateFile"]: {
		_count?: ModelTypes["FileCountAggregate"] | undefined,
	_avg?: ModelTypes["FileAvgAggregate"] | undefined,
	_sum?: ModelTypes["FileSumAggregate"] | undefined,
	_min?: ModelTypes["FileMinAggregate"] | undefined,
	_max?: ModelTypes["FileMaxAggregate"] | undefined
};
	["FileCountAggregate"]: {
		id: number,
	contentType: number,
	ctime: number,
	filename: number,
	filepath: number,
	isDirectory: number,
	mtime: number,
	name: number,
	size: number,
	status: number,
	type: number,
	updatedAt: number,
	createdAt: number,
	updatedByUserId: number,
	createdByUserId: number,
	url: number,
	bookingsIDs: number,
	workspaceId: number,
	proofBookingId: number,
	campaignIds: number,
	_all: number
};
	["FileAvgAggregate"]: {
		size?: number | undefined
};
	["FileSumAggregate"]: {
		size?: number | undefined
};
	["FileMinAggregate"]: {
		id?: string | undefined,
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId?: string | undefined,
	url?: string | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined
};
	["FileMaxAggregate"]: {
		id?: string | undefined,
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId?: string | undefined,
	url?: string | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined
};
	["FileGroupBy"]: {
		id: string,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt: ModelTypes["DateTime"],
	createdAt: ModelTypes["DateTime"],
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined,
	_count?: ModelTypes["FileCountAggregate"] | undefined,
	_avg?: ModelTypes["FileAvgAggregate"] | undefined,
	_sum?: ModelTypes["FileSumAggregate"] | undefined,
	_min?: ModelTypes["FileMinAggregate"] | undefined,
	_max?: ModelTypes["FileMaxAggregate"] | undefined
};
	["FileOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	contentType?: ModelTypes["SortOrder"] | undefined,
	ctime?: ModelTypes["SortOrder"] | undefined,
	filename?: ModelTypes["SortOrder"] | undefined,
	filepath?: ModelTypes["SortOrder"] | undefined,
	isDirectory?: ModelTypes["SortOrder"] | undefined,
	mtime?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	size?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedByUserId?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	url?: ModelTypes["SortOrder"] | undefined,
	bookingsIDs?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	proofBookingId?: ModelTypes["SortOrder"] | undefined,
	campaignIds?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["FileCountOrderByAggregateInput"] | undefined,
	_avg?: ModelTypes["FileAvgOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["FileMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["FileMinOrderByAggregateInput"] | undefined,
	_sum?: ModelTypes["FileSumOrderByAggregateInput"] | undefined
};
	["FileCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	contentType?: ModelTypes["SortOrder"] | undefined,
	ctime?: ModelTypes["SortOrder"] | undefined,
	filename?: ModelTypes["SortOrder"] | undefined,
	filepath?: ModelTypes["SortOrder"] | undefined,
	isDirectory?: ModelTypes["SortOrder"] | undefined,
	mtime?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	size?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedByUserId?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	url?: ModelTypes["SortOrder"] | undefined,
	bookingsIDs?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	proofBookingId?: ModelTypes["SortOrder"] | undefined,
	campaignIds?: ModelTypes["SortOrder"] | undefined
};
	["FileAvgOrderByAggregateInput"]: {
	size?: ModelTypes["SortOrder"] | undefined
};
	["FileMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	contentType?: ModelTypes["SortOrder"] | undefined,
	ctime?: ModelTypes["SortOrder"] | undefined,
	filename?: ModelTypes["SortOrder"] | undefined,
	filepath?: ModelTypes["SortOrder"] | undefined,
	isDirectory?: ModelTypes["SortOrder"] | undefined,
	mtime?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	size?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedByUserId?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	url?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	proofBookingId?: ModelTypes["SortOrder"] | undefined
};
	["FileMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	contentType?: ModelTypes["SortOrder"] | undefined,
	ctime?: ModelTypes["SortOrder"] | undefined,
	filename?: ModelTypes["SortOrder"] | undefined,
	filepath?: ModelTypes["SortOrder"] | undefined,
	isDirectory?: ModelTypes["SortOrder"] | undefined,
	mtime?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	size?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedByUserId?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	url?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	proofBookingId?: ModelTypes["SortOrder"] | undefined
};
	["FileSumOrderByAggregateInput"]: {
	size?: ModelTypes["SortOrder"] | undefined
};
	["FileScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["FileScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["FileScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["FileScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	contentType?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	ctime?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	filename?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	filepath?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	isDirectory?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	mtime?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	size?: ModelTypes["IntWithAggregatesFilter"] | undefined,
	status?: ModelTypes["EnumFileStatusNullableWithAggregatesFilter"] | undefined,
	type?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedByUserId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdByUserId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	url?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	bookingsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	proofBookingId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	campaignIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["EnumFileStatusNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["FileStatus"] | undefined,
	in?: Array<ModelTypes["FileStatus"]> | undefined,
	notIn?: Array<ModelTypes["FileStatus"]> | undefined,
	not?: ModelTypes["NestedEnumFileStatusNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	_max?: ModelTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumFileStatusNullableWithAggregatesFilter"]: {
	equals?: ModelTypes["FileStatus"] | undefined,
	in?: Array<ModelTypes["FileStatus"]> | undefined,
	notIn?: Array<ModelTypes["FileStatus"]> | undefined,
	not?: ModelTypes["NestedEnumFileStatusNullableWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntNullableFilter"] | undefined,
	_min?: ModelTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	_max?: ModelTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["AggregateMembership"]: {
		_count?: ModelTypes["MembershipCountAggregate"] | undefined,
	_min?: ModelTypes["MembershipMinAggregate"] | undefined,
	_max?: ModelTypes["MembershipMaxAggregate"] | undefined
};
	["MembershipCountAggregate"]: {
		id: number,
	createdAt: number,
	userId: number,
	workspaceId: number,
	roleId: number,
	_all: number
};
	["MembershipMinAggregate"]: {
		id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	userId?: string | undefined,
	workspaceId?: string | undefined,
	roleId?: string | undefined
};
	["MembershipMaxAggregate"]: {
		id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	userId?: string | undefined,
	workspaceId?: string | undefined,
	roleId?: string | undefined
};
	["MembershipGroupBy"]: {
		id: string,
	createdAt: ModelTypes["DateTime"],
	userId: string,
	workspaceId: string,
	roleId: string,
	_count?: ModelTypes["MembershipCountAggregate"] | undefined,
	_min?: ModelTypes["MembershipMinAggregate"] | undefined,
	_max?: ModelTypes["MembershipMaxAggregate"] | undefined
};
	["MembershipOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	roleId?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["MembershipCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["MembershipMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["MembershipMinOrderByAggregateInput"] | undefined
};
	["MembershipCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	roleId?: ModelTypes["SortOrder"] | undefined
};
	["MembershipMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	roleId?: ModelTypes["SortOrder"] | undefined
};
	["MembershipMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	roleId?: ModelTypes["SortOrder"] | undefined
};
	["MembershipScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	userId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	workspaceId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	roleId?: ModelTypes["StringWithAggregatesFilter"] | undefined
};
	["AggregateNotification"]: {
		_count?: ModelTypes["NotificationCountAggregate"] | undefined,
	_min?: ModelTypes["NotificationMinAggregate"] | undefined,
	_max?: ModelTypes["NotificationMaxAggregate"] | undefined
};
	["NotificationCountAggregate"]: {
		id: number,
	type: number,
	title: number,
	content: number,
	metadata: number,
	createdAt: number,
	readAt: number,
	recipientId: number,
	channels: number,
	_all: number
};
	["NotificationMinAggregate"]: {
		id?: string | undefined,
	type?: ModelTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	recipientId?: string | undefined
};
	["NotificationMaxAggregate"]: {
		id?: string | undefined,
	type?: ModelTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	recipientId?: string | undefined
};
	["NotificationGroupBy"]: {
		id: string,
	type: ModelTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt: ModelTypes["DateTime"],
	readAt?: ModelTypes["DateTime"] | undefined,
	recipientId: string,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined,
	_count?: ModelTypes["NotificationCountAggregate"] | undefined,
	_min?: ModelTypes["NotificationMinAggregate"] | undefined,
	_max?: ModelTypes["NotificationMaxAggregate"] | undefined
};
	["NotificationOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	content?: ModelTypes["SortOrder"] | undefined,
	metadata?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	readAt?: ModelTypes["SortOrder"] | undefined,
	recipientId?: ModelTypes["SortOrder"] | undefined,
	channels?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["NotificationCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["NotificationMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["NotificationMinOrderByAggregateInput"] | undefined
};
	["NotificationCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	content?: ModelTypes["SortOrder"] | undefined,
	metadata?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	readAt?: ModelTypes["SortOrder"] | undefined,
	recipientId?: ModelTypes["SortOrder"] | undefined,
	channels?: ModelTypes["SortOrder"] | undefined
};
	["NotificationMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	content?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	readAt?: ModelTypes["SortOrder"] | undefined,
	recipientId?: ModelTypes["SortOrder"] | undefined
};
	["NotificationMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	title?: ModelTypes["SortOrder"] | undefined,
	content?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	readAt?: ModelTypes["SortOrder"] | undefined,
	recipientId?: ModelTypes["SortOrder"] | undefined
};
	["NotificationScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	type?: ModelTypes["EnumNotificationTypeWithAggregatesFilter"] | undefined,
	title?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	content?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	metadata?: ModelTypes["JsonNullableWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	readAt?: ModelTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	recipientId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	channels?: ModelTypes["EnumNotificationChannelNullableListFilter"] | undefined
};
	["EnumNotificationTypeWithAggregatesFilter"]: {
	equals?: ModelTypes["NotificationType"] | undefined,
	in?: Array<ModelTypes["NotificationType"]> | undefined,
	notIn?: Array<ModelTypes["NotificationType"]> | undefined,
	not?: ModelTypes["NestedEnumNotificationTypeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumNotificationTypeFilter"] | undefined,
	_max?: ModelTypes["NestedEnumNotificationTypeFilter"] | undefined
};
	["NestedEnumNotificationTypeWithAggregatesFilter"]: {
	equals?: ModelTypes["NotificationType"] | undefined,
	in?: Array<ModelTypes["NotificationType"]> | undefined,
	notIn?: Array<ModelTypes["NotificationType"]> | undefined,
	not?: ModelTypes["NestedEnumNotificationTypeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumNotificationTypeFilter"] | undefined,
	_max?: ModelTypes["NestedEnumNotificationTypeFilter"] | undefined
};
	["AggregateOrder"]: {
		_count?: ModelTypes["OrderCountAggregate"] | undefined,
	_min?: ModelTypes["OrderMinAggregate"] | undefined,
	_max?: ModelTypes["OrderMaxAggregate"] | undefined
};
	["OrderCountAggregate"]: {
		id: number,
	adBoardId: number,
	createdDate: number,
	createdByUserId: number,
	origin: number,
	paymentId: number,
	_all: number
};
	["OrderMinAggregate"]: {
		id?: string | undefined,
	adBoardId?: string | undefined,
	createdDate?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined
};
	["OrderMaxAggregate"]: {
		id?: string | undefined,
	adBoardId?: string | undefined,
	createdDate?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined
};
	["OrderGroupBy"]: {
		id: string,
	adBoardId: string,
	createdDate: ModelTypes["DateTime"],
	createdByUserId?: string | undefined,
	origin: string,
	paymentId?: string | undefined,
	_count?: ModelTypes["OrderCountAggregate"] | undefined,
	_min?: ModelTypes["OrderMinAggregate"] | undefined,
	_max?: ModelTypes["OrderMaxAggregate"] | undefined
};
	["OrderOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	createdDate?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	paymentId?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["OrderCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["OrderMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["OrderMinOrderByAggregateInput"] | undefined
};
	["OrderCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	createdDate?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	paymentId?: ModelTypes["SortOrder"] | undefined
};
	["OrderMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	createdDate?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	paymentId?: ModelTypes["SortOrder"] | undefined
};
	["OrderMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	createdDate?: ModelTypes["SortOrder"] | undefined,
	createdByUserId?: ModelTypes["SortOrder"] | undefined,
	origin?: ModelTypes["SortOrder"] | undefined,
	paymentId?: ModelTypes["SortOrder"] | undefined
};
	["OrderScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["OrderScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["OrderScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["OrderScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	adBoardId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	createdDate?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	createdByUserId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	origin?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	paymentId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined
};
	["AggregatePlayer"]: {
		_count?: ModelTypes["PlayerCountAggregate"] | undefined,
	_avg?: ModelTypes["PlayerAvgAggregate"] | undefined,
	_sum?: ModelTypes["PlayerSumAggregate"] | undefined,
	_min?: ModelTypes["PlayerMinAggregate"] | undefined,
	_max?: ModelTypes["PlayerMaxAggregate"] | undefined
};
	["PlayerCountAggregate"]: {
		id: number,
	name: number,
	configLocation: number,
	pisignagePlayerId: number,
	pisignageGroupId: number,
	cpuSerialNumber: number,
	displayName: number,
	adBoardGroupId: number,
	playlistIds: number,
	workpaceId: number,
	signageTypeId: number,
	audienceTypeId: number,
	aspectRatioType: number,
	customAspectRatioWidth: number,
	customAspectRatioHeight: number,
	isOnline: number,
	adBoardIds: number,
	_all: number
};
	["PlayerAvgAggregate"]: {
		customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined
};
	["PlayerSumAggregate"]: {
		customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined
};
	["PlayerMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	workpaceId?: string | undefined,
	signageTypeId?: string | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	isOnline?: boolean | undefined
};
	["PlayerMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	workpaceId?: string | undefined,
	signageTypeId?: string | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	isOnline?: boolean | undefined
};
	["PlayerGroupBy"]: {
		id: string,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	playlistIds?: Array<string> | undefined,
	workpaceId?: string | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId?: Array<string> | undefined,
	aspectRatioType: ModelTypes["AspectRatioType"],
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	isOnline: boolean,
	adBoardIds?: Array<string> | undefined,
	_count?: ModelTypes["PlayerCountAggregate"] | undefined,
	_avg?: ModelTypes["PlayerAvgAggregate"] | undefined,
	_sum?: ModelTypes["PlayerSumAggregate"] | undefined,
	_min?: ModelTypes["PlayerMinAggregate"] | undefined,
	_max?: ModelTypes["PlayerMaxAggregate"] | undefined
};
	["PlayerOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	configLocation?: ModelTypes["SortOrder"] | undefined,
	pisignagePlayerId?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	cpuSerialNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	adBoardGroupId?: ModelTypes["SortOrder"] | undefined,
	playlistIds?: ModelTypes["SortOrder"] | undefined,
	workpaceId?: ModelTypes["SortOrder"] | undefined,
	signageTypeId?: ModelTypes["SortOrder"] | undefined,
	audienceTypeId?: ModelTypes["SortOrder"] | undefined,
	aspectRatioType?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioWidth?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	adBoardIds?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["PlayerCountOrderByAggregateInput"] | undefined,
	_avg?: ModelTypes["PlayerAvgOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["PlayerMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["PlayerMinOrderByAggregateInput"] | undefined,
	_sum?: ModelTypes["PlayerSumOrderByAggregateInput"] | undefined
};
	["PlayerCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	configLocation?: ModelTypes["SortOrder"] | undefined,
	pisignagePlayerId?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	cpuSerialNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	adBoardGroupId?: ModelTypes["SortOrder"] | undefined,
	playlistIds?: ModelTypes["SortOrder"] | undefined,
	workpaceId?: ModelTypes["SortOrder"] | undefined,
	signageTypeId?: ModelTypes["SortOrder"] | undefined,
	audienceTypeId?: ModelTypes["SortOrder"] | undefined,
	aspectRatioType?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioWidth?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined,
	adBoardIds?: ModelTypes["SortOrder"] | undefined
};
	["PlayerAvgOrderByAggregateInput"]: {
	customAspectRatioWidth?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: ModelTypes["SortOrder"] | undefined
};
	["PlayerMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	configLocation?: ModelTypes["SortOrder"] | undefined,
	pisignagePlayerId?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	cpuSerialNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	adBoardGroupId?: ModelTypes["SortOrder"] | undefined,
	workpaceId?: ModelTypes["SortOrder"] | undefined,
	signageTypeId?: ModelTypes["SortOrder"] | undefined,
	aspectRatioType?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioWidth?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined
};
	["PlayerMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	configLocation?: ModelTypes["SortOrder"] | undefined,
	pisignagePlayerId?: ModelTypes["SortOrder"] | undefined,
	pisignageGroupId?: ModelTypes["SortOrder"] | undefined,
	cpuSerialNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	adBoardGroupId?: ModelTypes["SortOrder"] | undefined,
	workpaceId?: ModelTypes["SortOrder"] | undefined,
	signageTypeId?: ModelTypes["SortOrder"] | undefined,
	aspectRatioType?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioWidth?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: ModelTypes["SortOrder"] | undefined,
	isOnline?: ModelTypes["SortOrder"] | undefined
};
	["PlayerSumOrderByAggregateInput"]: {
	customAspectRatioWidth?: ModelTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: ModelTypes["SortOrder"] | undefined
};
	["PlayerScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	configLocation?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	pisignagePlayerId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	pisignageGroupId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	cpuSerialNumber?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	displayName?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	adBoardGroupId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	playlistIds?: ModelTypes["StringNullableListFilter"] | undefined,
	workpaceId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	signageTypeId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	audienceTypeId?: ModelTypes["StringNullableListFilter"] | undefined,
	aspectRatioType?: ModelTypes["EnumAspectRatioTypeWithAggregatesFilter"] | undefined,
	customAspectRatioWidth?: ModelTypes["IntNullableWithAggregatesFilter"] | undefined,
	customAspectRatioHeight?: ModelTypes["IntNullableWithAggregatesFilter"] | undefined,
	isOnline?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	adBoardIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["EnumAspectRatioTypeWithAggregatesFilter"]: {
	equals?: ModelTypes["AspectRatioType"] | undefined,
	in?: Array<ModelTypes["AspectRatioType"]> | undefined,
	notIn?: Array<ModelTypes["AspectRatioType"]> | undefined,
	not?: ModelTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumAspectRatioTypeFilter"] | undefined,
	_max?: ModelTypes["NestedEnumAspectRatioTypeFilter"] | undefined
};
	["NestedEnumAspectRatioTypeWithAggregatesFilter"]: {
	equals?: ModelTypes["AspectRatioType"] | undefined,
	in?: Array<ModelTypes["AspectRatioType"]> | undefined,
	notIn?: Array<ModelTypes["AspectRatioType"]> | undefined,
	not?: ModelTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumAspectRatioTypeFilter"] | undefined,
	_max?: ModelTypes["NestedEnumAspectRatioTypeFilter"] | undefined
};
	["AggregatePlaylist"]: {
		_count?: ModelTypes["PlaylistCountAggregate"] | undefined,
	_min?: ModelTypes["PlaylistMinAggregate"] | undefined,
	_max?: ModelTypes["PlaylistMaxAggregate"] | undefined
};
	["PlaylistCountAggregate"]: {
		id: number,
	name: number,
	displayName: number,
	isAdsPlaylist: number,
	footerPlaylistId: number,
	sidebarPlaylistId: number,
	layout: number,
	isDeployed: number,
	adBoardGroupIds: number,
	workspaceId: number,
	_all: number
};
	["PlaylistMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	displayName?: string | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	workspaceId?: string | undefined
};
	["PlaylistMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	displayName?: string | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	workspaceId?: string | undefined
};
	["PlaylistGroupBy"]: {
		id: string,
	name: string,
	displayName?: string | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout: ModelTypes["ScreenLayout"],
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined,
	workspaceId: string,
	_count?: ModelTypes["PlaylistCountAggregate"] | undefined,
	_min?: ModelTypes["PlaylistMinAggregate"] | undefined,
	_max?: ModelTypes["PlaylistMaxAggregate"] | undefined
};
	["PlaylistOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	isAdsPlaylist?: ModelTypes["SortOrder"] | undefined,
	footerPlaylistId?: ModelTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: ModelTypes["SortOrder"] | undefined,
	layout?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	adBoardGroupIds?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["PlaylistCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["PlaylistMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["PlaylistMinOrderByAggregateInput"] | undefined
};
	["PlaylistCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	isAdsPlaylist?: ModelTypes["SortOrder"] | undefined,
	footerPlaylistId?: ModelTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: ModelTypes["SortOrder"] | undefined,
	layout?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	adBoardGroupIds?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined
};
	["PlaylistMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	isAdsPlaylist?: ModelTypes["SortOrder"] | undefined,
	footerPlaylistId?: ModelTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: ModelTypes["SortOrder"] | undefined,
	layout?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined
};
	["PlaylistMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	isAdsPlaylist?: ModelTypes["SortOrder"] | undefined,
	footerPlaylistId?: ModelTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: ModelTypes["SortOrder"] | undefined,
	layout?: ModelTypes["SortOrder"] | undefined,
	isDeployed?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined
};
	["PlaylistScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	displayName?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	isAdsPlaylist?: ModelTypes["BoolNullableWithAggregatesFilter"] | undefined,
	footerPlaylistId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	sidebarPlaylistId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	layout?: ModelTypes["EnumScreenLayoutWithAggregatesFilter"] | undefined,
	isDeployed?: ModelTypes["BoolNullableWithAggregatesFilter"] | undefined,
	adBoardGroupIds?: ModelTypes["StringNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringWithAggregatesFilter"] | undefined
};
	["EnumScreenLayoutWithAggregatesFilter"]: {
	equals?: ModelTypes["ScreenLayout"] | undefined,
	in?: Array<ModelTypes["ScreenLayout"]> | undefined,
	notIn?: Array<ModelTypes["ScreenLayout"]> | undefined,
	not?: ModelTypes["NestedEnumScreenLayoutWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumScreenLayoutFilter"] | undefined,
	_max?: ModelTypes["NestedEnumScreenLayoutFilter"] | undefined
};
	["NestedEnumScreenLayoutWithAggregatesFilter"]: {
	equals?: ModelTypes["ScreenLayout"] | undefined,
	in?: Array<ModelTypes["ScreenLayout"]> | undefined,
	notIn?: Array<ModelTypes["ScreenLayout"]> | undefined,
	not?: ModelTypes["NestedEnumScreenLayoutWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumScreenLayoutFilter"] | undefined,
	_max?: ModelTypes["NestedEnumScreenLayoutFilter"] | undefined
};
	["AggregateSlotConfiguration"]: {
		_count?: ModelTypes["SlotConfigurationCountAggregate"] | undefined,
	_avg?: ModelTypes["SlotConfigurationAvgAggregate"] | undefined,
	_sum?: ModelTypes["SlotConfigurationSumAggregate"] | undefined,
	_min?: ModelTypes["SlotConfigurationMinAggregate"] | undefined,
	_max?: ModelTypes["SlotConfigurationMaxAggregate"] | undefined
};
	["SlotConfigurationCountAggregate"]: {
		id: number,
	adBoardId: number,
	date: number,
	availableSlots: number,
	_all: number
};
	["SlotConfigurationAvgAggregate"]: {
		availableSlots?: number | undefined
};
	["SlotConfigurationSumAggregate"]: {
		availableSlots?: number | undefined
};
	["SlotConfigurationMinAggregate"]: {
		id?: string | undefined,
	adBoardId?: string | undefined,
	date?: ModelTypes["DateTime"] | undefined,
	availableSlots?: number | undefined
};
	["SlotConfigurationMaxAggregate"]: {
		id?: string | undefined,
	adBoardId?: string | undefined,
	date?: ModelTypes["DateTime"] | undefined,
	availableSlots?: number | undefined
};
	["SlotConfigurationGroupBy"]: {
		id: string,
	adBoardId: string,
	date: ModelTypes["DateTime"],
	availableSlots: number,
	_count?: ModelTypes["SlotConfigurationCountAggregate"] | undefined,
	_avg?: ModelTypes["SlotConfigurationAvgAggregate"] | undefined,
	_sum?: ModelTypes["SlotConfigurationSumAggregate"] | undefined,
	_min?: ModelTypes["SlotConfigurationMinAggregate"] | undefined,
	_max?: ModelTypes["SlotConfigurationMaxAggregate"] | undefined
};
	["SlotConfigurationOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	date?: ModelTypes["SortOrder"] | undefined,
	availableSlots?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["SlotConfigurationCountOrderByAggregateInput"] | undefined,
	_avg?: ModelTypes["SlotConfigurationAvgOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["SlotConfigurationMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["SlotConfigurationMinOrderByAggregateInput"] | undefined,
	_sum?: ModelTypes["SlotConfigurationSumOrderByAggregateInput"] | undefined
};
	["SlotConfigurationCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	date?: ModelTypes["SortOrder"] | undefined,
	availableSlots?: ModelTypes["SortOrder"] | undefined
};
	["SlotConfigurationAvgOrderByAggregateInput"]: {
	availableSlots?: ModelTypes["SortOrder"] | undefined
};
	["SlotConfigurationMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	date?: ModelTypes["SortOrder"] | undefined,
	availableSlots?: ModelTypes["SortOrder"] | undefined
};
	["SlotConfigurationMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	adBoardId?: ModelTypes["SortOrder"] | undefined,
	date?: ModelTypes["SortOrder"] | undefined,
	availableSlots?: ModelTypes["SortOrder"] | undefined
};
	["SlotConfigurationSumOrderByAggregateInput"]: {
	availableSlots?: ModelTypes["SortOrder"] | undefined
};
	["SlotConfigurationScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	adBoardId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	date?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	availableSlots?: ModelTypes["IntWithAggregatesFilter"] | undefined
};
	["AggregateSubscription"]: {
		_count?: ModelTypes["SubscriptionCountAggregate"] | undefined,
	_min?: ModelTypes["SubscriptionMinAggregate"] | undefined,
	_max?: ModelTypes["SubscriptionMaxAggregate"] | undefined
};
	["SubscriptionCountAggregate"]: {
		id: number,
	packageId: number,
	packageName: number,
	priceLookupKey: number,
	startDate: number,
	endDate: number,
	status: number,
	stripePaymentIntentId: number,
	stripeSubscriptionId: number,
	stripeCheckoutSessionId: number,
	activeWorkspaceId: number,
	_all: number
};
	["SubscriptionMinAggregate"]: {
		id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	activeWorkspaceId?: string | undefined
};
	["SubscriptionMaxAggregate"]: {
		id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	activeWorkspaceId?: string | undefined
};
	["SubscriptionWhereUniqueInput"]: {
	id?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	activeWorkspaceId?: string | undefined,
	AND?: Array<ModelTypes["SubscriptionWhereInput"]> | undefined,
	OR?: Array<ModelTypes["SubscriptionWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["SubscriptionWhereInput"]> | undefined,
	packageId?: ModelTypes["StringNullableFilter"] | undefined,
	packageName?: ModelTypes["StringNullableFilter"] | undefined,
	priceLookupKey?: ModelTypes["StringNullableFilter"] | undefined,
	startDate?: ModelTypes["DateTimeNullableFilter"] | undefined,
	endDate?: ModelTypes["DateTimeNullableFilter"] | undefined,
	status?: ModelTypes["StringFilter"] | undefined,
	stripePaymentIntentId?: ModelTypes["StringNullableFilter"] | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsNullableCompositeFilter"] | undefined,
	activeWorkspace?: ModelTypes["WorkspaceRelationFilter"] | undefined
};
	["SubscriptionScalarFieldEnum"]:SubscriptionScalarFieldEnum;
	["SubscriptionGroupBy"]: {
		id: string,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status: string,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId: string,
	stripeCheckoutSessionId: string,
	activeWorkspaceId: string,
	_count?: ModelTypes["SubscriptionCountAggregate"] | undefined,
	_min?: ModelTypes["SubscriptionMinAggregate"] | undefined,
	_max?: ModelTypes["SubscriptionMaxAggregate"] | undefined
};
	["SubscriptionOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	packageId?: ModelTypes["SortOrder"] | undefined,
	packageName?: ModelTypes["SortOrder"] | undefined,
	priceLookupKey?: ModelTypes["SortOrder"] | undefined,
	startDate?: ModelTypes["SortOrder"] | undefined,
	endDate?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: ModelTypes["SortOrder"] | undefined,
	activeWorkspaceId?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["SubscriptionCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["SubscriptionMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["SubscriptionMinOrderByAggregateInput"] | undefined
};
	["SubscriptionCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	packageId?: ModelTypes["SortOrder"] | undefined,
	packageName?: ModelTypes["SortOrder"] | undefined,
	priceLookupKey?: ModelTypes["SortOrder"] | undefined,
	startDate?: ModelTypes["SortOrder"] | undefined,
	endDate?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: ModelTypes["SortOrder"] | undefined,
	activeWorkspaceId?: ModelTypes["SortOrder"] | undefined
};
	["SubscriptionMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	packageId?: ModelTypes["SortOrder"] | undefined,
	packageName?: ModelTypes["SortOrder"] | undefined,
	priceLookupKey?: ModelTypes["SortOrder"] | undefined,
	startDate?: ModelTypes["SortOrder"] | undefined,
	endDate?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: ModelTypes["SortOrder"] | undefined,
	activeWorkspaceId?: ModelTypes["SortOrder"] | undefined
};
	["SubscriptionMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	packageId?: ModelTypes["SortOrder"] | undefined,
	packageName?: ModelTypes["SortOrder"] | undefined,
	priceLookupKey?: ModelTypes["SortOrder"] | undefined,
	startDate?: ModelTypes["SortOrder"] | undefined,
	endDate?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: ModelTypes["SortOrder"] | undefined,
	activeWorkspaceId?: ModelTypes["SortOrder"] | undefined
};
	["SubscriptionScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	packageId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	packageName?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	priceLookupKey?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	startDate?: ModelTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	endDate?: ModelTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	status?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	stripePaymentIntentId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	stripeSubscriptionId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	stripeCheckoutSessionId?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	activeWorkspaceId?: ModelTypes["StringWithAggregatesFilter"] | undefined
};
	["AggregateUser"]: {
		_count?: ModelTypes["UserCountAggregate"] | undefined,
	_min?: ModelTypes["UserMinAggregate"] | undefined,
	_max?: ModelTypes["UserMaxAggregate"] | undefined
};
	["UserCountAggregate"]: {
		id: number,
	uid: number,
	email: number,
	image: number,
	phoneNumber: number,
	displayName: number,
	otherDetails: number,
	roles: number,
	sharedBookingIds: number,
	disclaimerAccepted: number,
	leadWorkspaceIds: number,
	_all: number
};
	["UserMinAggregate"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	disclaimerAccepted?: boolean | undefined
};
	["UserMaxAggregate"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	disclaimerAccepted?: boolean | undefined
};
	["UserGroupBy"]: {
		id: string,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	sharedBookingIds?: Array<string> | undefined,
	disclaimerAccepted: boolean,
	leadWorkspaceIds?: Array<string> | undefined,
	_count?: ModelTypes["UserCountAggregate"] | undefined,
	_min?: ModelTypes["UserMinAggregate"] | undefined,
	_max?: ModelTypes["UserMaxAggregate"] | undefined
};
	["UserOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	uid?: ModelTypes["SortOrder"] | undefined,
	email?: ModelTypes["SortOrder"] | undefined,
	image?: ModelTypes["SortOrder"] | undefined,
	phoneNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	otherDetails?: ModelTypes["SortOrder"] | undefined,
	roles?: ModelTypes["SortOrder"] | undefined,
	sharedBookingIds?: ModelTypes["SortOrder"] | undefined,
	disclaimerAccepted?: ModelTypes["SortOrder"] | undefined,
	leadWorkspaceIds?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["UserCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["UserMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["UserMinOrderByAggregateInput"] | undefined
};
	["UserCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	uid?: ModelTypes["SortOrder"] | undefined,
	email?: ModelTypes["SortOrder"] | undefined,
	image?: ModelTypes["SortOrder"] | undefined,
	phoneNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	otherDetails?: ModelTypes["SortOrder"] | undefined,
	roles?: ModelTypes["SortOrder"] | undefined,
	sharedBookingIds?: ModelTypes["SortOrder"] | undefined,
	disclaimerAccepted?: ModelTypes["SortOrder"] | undefined,
	leadWorkspaceIds?: ModelTypes["SortOrder"] | undefined
};
	["UserMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	uid?: ModelTypes["SortOrder"] | undefined,
	email?: ModelTypes["SortOrder"] | undefined,
	image?: ModelTypes["SortOrder"] | undefined,
	phoneNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	disclaimerAccepted?: ModelTypes["SortOrder"] | undefined
};
	["UserMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	uid?: ModelTypes["SortOrder"] | undefined,
	email?: ModelTypes["SortOrder"] | undefined,
	image?: ModelTypes["SortOrder"] | undefined,
	phoneNumber?: ModelTypes["SortOrder"] | undefined,
	displayName?: ModelTypes["SortOrder"] | undefined,
	disclaimerAccepted?: ModelTypes["SortOrder"] | undefined
};
	["UserScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["UserScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["UserScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["UserScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	uid?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	email?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	image?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	phoneNumber?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	displayName?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	otherDetails?: ModelTypes["JsonNullableWithAggregatesFilter"] | undefined,
	roles?: ModelTypes["EnumRoleNullableListFilter"] | undefined,
	sharedBookingIds?: ModelTypes["StringNullableListFilter"] | undefined,
	disclaimerAccepted?: ModelTypes["BoolWithAggregatesFilter"] | undefined,
	leadWorkspaceIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["AggregateUserPosition"]: {
		_count?: ModelTypes["UserPositionCountAggregate"] | undefined,
	_min?: ModelTypes["UserPositionMinAggregate"] | undefined,
	_max?: ModelTypes["UserPositionMaxAggregate"] | undefined
};
	["UserPositionCountAggregate"]: {
		id: number,
	timestamp: number,
	createdAt: number,
	userId: number,
	_all: number
};
	["UserPositionMinAggregate"]: {
		id?: string | undefined,
	timestamp?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	userId?: string | undefined
};
	["UserPositionMaxAggregate"]: {
		id?: string | undefined,
	timestamp?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	userId?: string | undefined
};
	["UserPositionGroupBy"]: {
		id: string,
	timestamp: ModelTypes["DateTime"],
	createdAt: ModelTypes["DateTime"],
	userId: string,
	_count?: ModelTypes["UserPositionCountAggregate"] | undefined,
	_min?: ModelTypes["UserPositionMinAggregate"] | undefined,
	_max?: ModelTypes["UserPositionMaxAggregate"] | undefined
};
	["UserPositionOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["UserPositionCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["UserPositionMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["UserPositionMinOrderByAggregateInput"] | undefined
};
	["UserPositionCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined
};
	["UserPositionMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined
};
	["UserPositionMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	timestamp?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	userId?: ModelTypes["SortOrder"] | undefined
};
	["UserPositionScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	timestamp?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	userId?: ModelTypes["StringWithAggregatesFilter"] | undefined
};
	["AggregateWorkspace"]: {
		_count?: ModelTypes["WorkspaceCountAggregate"] | undefined,
	_min?: ModelTypes["WorkspaceMinAggregate"] | undefined,
	_max?: ModelTypes["WorkspaceMaxAggregate"] | undefined
};
	["WorkspaceCountAggregate"]: {
		id: number,
	type: number,
	name: number,
	key: number,
	userIds: number,
	ownerId: number,
	avatar: number,
	createdAt: number,
	updatedAt: number,
	stripeSubscriptionId: number,
	leadIds: number,
	_all: number
};
	["WorkspaceMinAggregate"]: {
		id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined
};
	["WorkspaceMaxAggregate"]: {
		id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined
};
	["WorkspaceGroupBy"]: {
		id: string,
	type: ModelTypes["WorkspaceType"],
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	stripeSubscriptionId?: string | undefined,
	leadIds?: Array<string> | undefined,
	_count?: ModelTypes["WorkspaceCountAggregate"] | undefined,
	_min?: ModelTypes["WorkspaceMinAggregate"] | undefined,
	_max?: ModelTypes["WorkspaceMaxAggregate"] | undefined
};
	["WorkspaceOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	key?: ModelTypes["SortOrder"] | undefined,
	userIds?: ModelTypes["SortOrder"] | undefined,
	ownerId?: ModelTypes["SortOrder"] | undefined,
	avatar?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined,
	leadIds?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["WorkspaceCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["WorkspaceMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["WorkspaceMinOrderByAggregateInput"] | undefined
};
	["WorkspaceCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	key?: ModelTypes["SortOrder"] | undefined,
	userIds?: ModelTypes["SortOrder"] | undefined,
	ownerId?: ModelTypes["SortOrder"] | undefined,
	avatar?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined,
	leadIds?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	key?: ModelTypes["SortOrder"] | undefined,
	ownerId?: ModelTypes["SortOrder"] | undefined,
	avatar?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	type?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	key?: ModelTypes["SortOrder"] | undefined,
	ownerId?: ModelTypes["SortOrder"] | undefined,
	avatar?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	type?: ModelTypes["EnumWorkspaceTypeWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	key?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	userIds?: ModelTypes["StringNullableListFilter"] | undefined,
	ownerId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	avatar?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	stripeSubscriptionId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	leadIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["EnumWorkspaceTypeWithAggregatesFilter"]: {
	equals?: ModelTypes["WorkspaceType"] | undefined,
	in?: Array<ModelTypes["WorkspaceType"]> | undefined,
	notIn?: Array<ModelTypes["WorkspaceType"]> | undefined,
	not?: ModelTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumWorkspaceTypeFilter"] | undefined,
	_max?: ModelTypes["NestedEnumWorkspaceTypeFilter"] | undefined
};
	["NestedEnumWorkspaceTypeWithAggregatesFilter"]: {
	equals?: ModelTypes["WorkspaceType"] | undefined,
	in?: Array<ModelTypes["WorkspaceType"]> | undefined,
	notIn?: Array<ModelTypes["WorkspaceType"]> | undefined,
	not?: ModelTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"] | undefined,
	_count?: ModelTypes["NestedIntFilter"] | undefined,
	_min?: ModelTypes["NestedEnumWorkspaceTypeFilter"] | undefined,
	_max?: ModelTypes["NestedEnumWorkspaceTypeFilter"] | undefined
};
	["AggregateWorkspaceRole"]: {
		_count?: ModelTypes["WorkspaceRoleCountAggregate"] | undefined,
	_min?: ModelTypes["WorkspaceRoleMinAggregate"] | undefined,
	_max?: ModelTypes["WorkspaceRoleMaxAggregate"] | undefined
};
	["WorkspaceRoleCountAggregate"]: {
		id: number,
	name: number,
	permissions: number,
	workspaceId: number,
	createdAt: number,
	updatedAt: number,
	_all: number
};
	["WorkspaceRoleMinAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	workspaceId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["WorkspaceRoleMaxAggregate"]: {
		id?: string | undefined,
	name?: string | undefined,
	workspaceId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["WorkspaceRoleGroupBy"]: {
		id: string,
	name: string,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	workspaceId?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	_count?: ModelTypes["WorkspaceRoleCountAggregate"] | undefined,
	_min?: ModelTypes["WorkspaceRoleMinAggregate"] | undefined,
	_max?: ModelTypes["WorkspaceRoleMaxAggregate"] | undefined
};
	["WorkspaceRoleOrderByWithAggregationInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	permissions?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	_count?: ModelTypes["WorkspaceRoleCountOrderByAggregateInput"] | undefined,
	_max?: ModelTypes["WorkspaceRoleMaxOrderByAggregateInput"] | undefined,
	_min?: ModelTypes["WorkspaceRoleMinOrderByAggregateInput"] | undefined
};
	["WorkspaceRoleCountOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	permissions?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceRoleMaxOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceRoleMinOrderByAggregateInput"]: {
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	workspaceId?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["WorkspaceRoleScalarWhereWithAggregatesInput"]: {
	AND?: Array<ModelTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<ModelTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<ModelTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined,
	id?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	name?: ModelTypes["StringWithAggregatesFilter"] | undefined,
	permissions?: ModelTypes["EnumPermissionNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeWithAggregatesFilter"] | undefined
};
	["Slot"]: {
		date: string,
	slots: number
};
	["Mutation"]: {
		createManyAdBoard: ModelTypes["AffectedRowsOutput"],
	createOneAdBoard: ModelTypes["AdBoard"],
	deleteManyAdBoard: ModelTypes["AffectedRowsOutput"],
	deleteOneAdBoard?: ModelTypes["AdBoard"] | undefined,
	updateManyAdBoard: ModelTypes["AffectedRowsOutput"],
	updateOneAdBoard?: ModelTypes["AdBoard"] | undefined,
	upsertOneAdBoard: ModelTypes["AdBoard"],
	createManyAdBoardGroup: ModelTypes["AffectedRowsOutput"],
	createOneAdBoardGroup: ModelTypes["AdBoardGroup"],
	deleteManyAdBoardGroup: ModelTypes["AffectedRowsOutput"],
	deleteOneAdBoardGroup?: ModelTypes["AdBoardGroup"] | undefined,
	updateManyAdBoardGroup: ModelTypes["AffectedRowsOutput"],
	updateOneAdBoardGroup?: ModelTypes["AdBoardGroup"] | undefined,
	upsertOneAdBoardGroup: ModelTypes["AdBoardGroup"],
	createManyAdBoardType: ModelTypes["AffectedRowsOutput"],
	createOneAdBoardType: ModelTypes["AdBoardType"],
	deleteManyAdBoardType: ModelTypes["AffectedRowsOutput"],
	deleteOneAdBoardType?: ModelTypes["AdBoardType"] | undefined,
	updateManyAdBoardType: ModelTypes["AffectedRowsOutput"],
	updateOneAdBoardType?: ModelTypes["AdBoardType"] | undefined,
	upsertOneAdBoardType: ModelTypes["AdBoardType"],
	createManyAdvertisementCategory: ModelTypes["AffectedRowsOutput"],
	createOneAdvertisementCategory: ModelTypes["AdvertisementCategory"],
	deleteManyAdvertisementCategory: ModelTypes["AffectedRowsOutput"],
	deleteOneAdvertisementCategory?: ModelTypes["AdvertisementCategory"] | undefined,
	updateManyAdvertisementCategory: ModelTypes["AffectedRowsOutput"],
	updateOneAdvertisementCategory?: ModelTypes["AdvertisementCategory"] | undefined,
	upsertOneAdvertisementCategory: ModelTypes["AdvertisementCategory"],
	createManyAudit: ModelTypes["AffectedRowsOutput"],
	createOneAudit: ModelTypes["Audit"],
	deleteManyAudit: ModelTypes["AffectedRowsOutput"],
	deleteOneAudit?: ModelTypes["Audit"] | undefined,
	updateManyAudit: ModelTypes["AffectedRowsOutput"],
	updateOneAudit?: ModelTypes["Audit"] | undefined,
	upsertOneAudit: ModelTypes["Audit"],
	createManyBooking: ModelTypes["AffectedRowsOutput"],
	createOneBooking: ModelTypes["Booking"],
	deleteManyBooking: ModelTypes["AffectedRowsOutput"],
	deleteOneBooking?: ModelTypes["Booking"] | undefined,
	updateManyBooking: ModelTypes["AffectedRowsOutput"],
	updateOneBooking?: ModelTypes["Booking"] | undefined,
	upsertOneBooking: ModelTypes["Booking"],
	createManyBusinessCategory: ModelTypes["AffectedRowsOutput"],
	createOneBusinessCategory: ModelTypes["BusinessCategory"],
	deleteManyBusinessCategory: ModelTypes["AffectedRowsOutput"],
	deleteOneBusinessCategory?: ModelTypes["BusinessCategory"] | undefined,
	updateManyBusinessCategory: ModelTypes["AffectedRowsOutput"],
	updateOneBusinessCategory?: ModelTypes["BusinessCategory"] | undefined,
	upsertOneBusinessCategory: ModelTypes["BusinessCategory"],
	createManyCamera: ModelTypes["AffectedRowsOutput"],
	createOneCamera: ModelTypes["Camera"],
	deleteManyCamera: ModelTypes["AffectedRowsOutput"],
	deleteOneCamera?: ModelTypes["Camera"] | undefined,
	updateManyCamera: ModelTypes["AffectedRowsOutput"],
	updateOneCamera?: ModelTypes["Camera"] | undefined,
	upsertOneCamera: ModelTypes["Camera"],
	createManyCameraFeed: ModelTypes["AffectedRowsOutput"],
	createOneCameraFeed: ModelTypes["CameraFeed"],
	deleteManyCameraFeed: ModelTypes["AffectedRowsOutput"],
	deleteOneCameraFeed?: ModelTypes["CameraFeed"] | undefined,
	updateManyCameraFeed: ModelTypes["AffectedRowsOutput"],
	updateOneCameraFeed?: ModelTypes["CameraFeed"] | undefined,
	upsertOneCameraFeed: ModelTypes["CameraFeed"],
	createManyCameraServer: ModelTypes["AffectedRowsOutput"],
	createOneCameraServer: ModelTypes["CameraServer"],
	deleteManyCameraServer: ModelTypes["AffectedRowsOutput"],
	deleteOneCameraServer?: ModelTypes["CameraServer"] | undefined,
	updateManyCameraServer: ModelTypes["AffectedRowsOutput"],
	updateOneCameraServer?: ModelTypes["CameraServer"] | undefined,
	upsertOneCameraServer: ModelTypes["CameraServer"],
	createManyCampaign: ModelTypes["AffectedRowsOutput"],
	createOneCampaign: ModelTypes["Campaign"],
	deleteManyCampaign: ModelTypes["AffectedRowsOutput"],
	deleteOneCampaign?: ModelTypes["Campaign"] | undefined,
	updateManyCampaign: ModelTypes["AffectedRowsOutput"],
	updateOneCampaign?: ModelTypes["Campaign"] | undefined,
	upsertOneCampaign: ModelTypes["Campaign"],
	createManyFile: ModelTypes["AffectedRowsOutput"],
	createOneFile: ModelTypes["File"],
	deleteManyFile: ModelTypes["AffectedRowsOutput"],
	deleteOneFile?: ModelTypes["File"] | undefined,
	updateManyFile: ModelTypes["AffectedRowsOutput"],
	updateOneFile?: ModelTypes["File"] | undefined,
	upsertOneFile: ModelTypes["File"],
	createManyMembership: ModelTypes["AffectedRowsOutput"],
	createOneMembership?: ModelTypes["Membership"] | undefined,
	deleteManyMembership: ModelTypes["AffectedRowsOutput"],
	deleteOneMembership?: ModelTypes["Membership"] | undefined,
	updateManyMembership: ModelTypes["AffectedRowsOutput"],
	updateOneMembership?: ModelTypes["Membership"] | undefined,
	upsertOneMembership: ModelTypes["Membership"],
	createManyNotification: ModelTypes["AffectedRowsOutput"],
	createOneNotification: ModelTypes["Notification"],
	deleteManyNotification: ModelTypes["AffectedRowsOutput"],
	deleteOneNotification?: ModelTypes["Notification"] | undefined,
	updateManyNotification: ModelTypes["AffectedRowsOutput"],
	updateOneNotification?: ModelTypes["Notification"] | undefined,
	upsertOneNotification: ModelTypes["Notification"],
	createManyOrder: ModelTypes["AffectedRowsOutput"],
	createOneOrder: ModelTypes["Order"],
	deleteManyOrder: ModelTypes["AffectedRowsOutput"],
	deleteOneOrder?: ModelTypes["Order"] | undefined,
	updateManyOrder: ModelTypes["AffectedRowsOutput"],
	updateOneOrder?: ModelTypes["Order"] | undefined,
	upsertOneOrder: ModelTypes["Order"],
	createManyPlayer: ModelTypes["AffectedRowsOutput"],
	createOnePlayer: ModelTypes["Player"],
	deleteManyPlayer: ModelTypes["AffectedRowsOutput"],
	deleteOnePlayer?: ModelTypes["Player"] | undefined,
	updateManyPlayer: ModelTypes["AffectedRowsOutput"],
	updateOnePlayer?: ModelTypes["Player"] | undefined,
	upsertOnePlayer: ModelTypes["Player"],
	createManyPlaylist: ModelTypes["AffectedRowsOutput"],
	createOnePlaylist: ModelTypes["Playlist"],
	deleteManyPlaylist: ModelTypes["AffectedRowsOutput"],
	deleteOnePlaylist?: ModelTypes["Playlist"] | undefined,
	updateManyPlaylist: ModelTypes["AffectedRowsOutput"],
	updateOnePlaylist: ModelTypes["Playlist"],
	upsertOnePlaylist: ModelTypes["Playlist"],
	createManySlotConfiguration: ModelTypes["AffectedRowsOutput"],
	createOneSlotConfiguration: ModelTypes["SlotConfiguration"],
	deleteManySlotConfiguration: ModelTypes["AffectedRowsOutput"],
	deleteOneSlotConfiguration?: ModelTypes["SlotConfiguration"] | undefined,
	updateManySlotConfiguration: ModelTypes["AffectedRowsOutput"],
	updateOneSlotConfiguration?: ModelTypes["SlotConfiguration"] | undefined,
	upsertOneSlotConfiguration: ModelTypes["SlotConfiguration"],
	createManySubscription: ModelTypes["AffectedRowsOutput"],
	createOneSubscription: ModelTypes["Subscription"],
	deleteManySubscription: ModelTypes["AffectedRowsOutput"],
	deleteOneSubscription?: ModelTypes["Subscription"] | undefined,
	updateManySubscription: ModelTypes["AffectedRowsOutput"],
	updateOneSubscription?: ModelTypes["Subscription"] | undefined,
	upsertOneSubscription: ModelTypes["Subscription"],
	createManyUser: ModelTypes["AffectedRowsOutput"],
	createOneUser: ModelTypes["User"],
	deleteManyUser: ModelTypes["AffectedRowsOutput"],
	deleteOneUser?: ModelTypes["User"] | undefined,
	updateManyUser: ModelTypes["AffectedRowsOutput"],
	updateOneUser?: ModelTypes["User"] | undefined,
	upsertOneUser: ModelTypes["User"],
	createManyUserPosition: ModelTypes["AffectedRowsOutput"],
	createOneUserPosition: ModelTypes["UserPosition"],
	deleteManyUserPosition: ModelTypes["AffectedRowsOutput"],
	deleteOneUserPosition?: ModelTypes["UserPosition"] | undefined,
	updateManyUserPosition: ModelTypes["AffectedRowsOutput"],
	updateOneUserPosition?: ModelTypes["UserPosition"] | undefined,
	upsertOneUserPosition: ModelTypes["UserPosition"],
	createManyWorkspace: ModelTypes["AffectedRowsOutput"],
	createOneWorkspace: ModelTypes["Workspace"],
	deleteManyWorkspace: ModelTypes["AffectedRowsOutput"],
	deleteOneWorkspace?: ModelTypes["Workspace"] | undefined,
	updateManyWorkspace: ModelTypes["AffectedRowsOutput"],
	updateOneWorkspace?: ModelTypes["Workspace"] | undefined,
	upsertOneWorkspace: ModelTypes["Workspace"],
	createManyWorkspaceRole: ModelTypes["AffectedRowsOutput"],
	createOneWorkspaceRole: ModelTypes["WorkspaceRole"],
	deleteManyWorkspaceRole: ModelTypes["AffectedRowsOutput"],
	deleteOneWorkspaceRole?: ModelTypes["WorkspaceRole"] | undefined,
	updateManyWorkspaceRole: ModelTypes["AffectedRowsOutput"],
	updateOneWorkspaceRole?: ModelTypes["WorkspaceRole"] | undefined,
	upsertOneWorkspaceRole: ModelTypes["WorkspaceRole"],
	updateUser?: ModelTypes["User"] | undefined,
	createCustomUser?: ModelTypes["User"] | undefined,
	deleteUserCustom?: ModelTypes["GenericResponse"] | undefined,
	sendMail?: ModelTypes["EmailOutput"] | undefined,
	createOneCampaignBooking: ModelTypes["Campaign"],
	createOneAdboardPublicReview: ModelTypes["AdBoardPublicReview"],
	refreshSubscription: ModelTypes["TypedGenericResponse"],
	cancelSubscription: ModelTypes["TypedGenericResponse"],
	updateBookingStatus: ModelTypes["Booking"],
	updateBookingAsset: boolean,
	prepareBooking: ModelTypes["Booking"],
	cancelBooking: ModelTypes["Booking"],
	deployBooking: ModelTypes["Booking"],
	updateDraft: ModelTypes["AdBoard"],
	deleteDraft: boolean,
	addReviewComment: ModelTypes["AdBoardReviewComment"],
	updateAdBoardStatus: ModelTypes["AdBoard"],
	publishApprovedDraft: ModelTypes["AdBoard"],
	addPlayersToAdboard: ModelTypes["AdBoard"],
	createOneAdboardWithGroup: ModelTypes["AdBoard"],
	deleteOneAdboardWithGroup: ModelTypes["AdBoard"],
	updateOneScheduler?: ModelTypes["AdBoardGroup"] | undefined,
	setupOnePlayer: ModelTypes["Player"],
	updatePlayerCode: ModelTypes["Player"],
	addPlaylistToPlayer: ModelTypes["Player"],
	deletePlaylistInPlayer: ModelTypes["Player"]
};
	["AffectedRowsOutput"]: {
		count: number
};
	["AdBoardCreateManyInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["MapCreateInput"]: {
	lat: number,
	lng: number,
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["SpecificationCreateInput"]: {
	name: string,
	value?: string | undefined
};
	["ResolutionCreateInput"]: {
	height: number,
	width: number
};
	["ScreenSizeCreateInput"]: {
	height: number,
	unit: ModelTypes["MeasurementUnit"],
	width: number
};
	["PlaybackTimeCreateInput"]: {
	name?: string | undefined,
	startTime: string,
	endTime: string
};
	["WebhookCreateInput"]: {
	url: string,
	headers?: ModelTypes["JSON"] | undefined,
	body?: ModelTypes["JSON"] | undefined,
	method: string
};
	["AdBoardCreateInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["LocationCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ModelTypes["LocationCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: ModelTypes["LocationCategoryWhereUniqueInput"] | undefined
};
	["LocationCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined,
	name: string,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"] | undefined
};
	["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"]: {
	create?: Array<ModelTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]> | undefined,
	createMany?: ModelTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["LocationSubCategoryWhereUniqueInput"]> | undefined
};
	["LocationSubCategoryCreateWithoutLocationCategoryInput"]: {
	id?: string | undefined,
	name: string,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutLocationSubCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutLocationSubCategoryInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ModelTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: ModelTypes["AdBoardTypeWhereUniqueInput"] | undefined
};
	["AdBoardTypeCreateWithoutAdBoardsInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined
};
	["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"]: {
	where: ModelTypes["AdBoardTypeWhereUniqueInput"],
	create: ModelTypes["AdBoardTypeCreateWithoutAdBoardsInput"]
};
	["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ModelTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: ModelTypes["BusinessCategoryWhereUniqueInput"] | undefined
};
	["BusinessCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutRestrictedBusinesInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutRestrictedBusinesInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ModelTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: ModelTypes["LocationSubCategoryWhereUniqueInput"] | undefined
};
	["LocationSubCategoryCreateWithoutAdBoardsInput"]: {
	id?: string | undefined,
	name: string,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"] | undefined
};
	["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"]: {
	create?: ModelTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | undefined,
	connectOrCreate?: ModelTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"] | undefined,
	connect?: ModelTypes["LocationCategoryWhereUniqueInput"] | undefined
};
	["LocationCategoryCreateWithoutLocationSubCategoryInput"]: {
	id?: string | undefined,
	name: string,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutLocationCategoryInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutLocationCategoryInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutLocationCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyLocationCategoryInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutLocationCategoryInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"]: {
	create?: Array<ModelTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]> | undefined,
	connect?: Array<ModelTypes["BusinessCategoryWhereUniqueInput"]> | undefined
};
	["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutCategoryInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutCategoryInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyCategoryInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutCategoryInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["BookingCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutAdBoardInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["BookingDetailsCreateInput"]: {
	additionalInformation?: string | undefined,
	email: string,
	name: string,
	phone?: string | undefined
};
	["OrderSelectedDateCreateInput"]: {
	endDate: ModelTypes["DateTime"],
	startDate: ModelTypes["DateTime"]
};
	["BookingPaymentCreateInput"]: {
	liveMode?: boolean | undefined,
	checkoutSessionId?: string | undefined,
	customer?: ModelTypes["BookingCustomerCreateInput"] | undefined,
	paymentGateway: ModelTypes["PaymentGatewayName"],
	paymentId: string,
	sessionId?: string | undefined,
	totalDetails?: ModelTypes["PaymentTotalDetailsCreateInput"] | undefined,
	razorpay?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["JSON"] | undefined
};
	["BookingCustomerCreateInput"]: {
	customerId: string,
	customerDetails?: ModelTypes["PaymentCustomerDetailsCreateInput"] | undefined
};
	["PaymentCustomerDetailsCreateInput"]: {
	email: string,
	name: string,
	phone?: string | undefined
};
	["PaymentTotalDetailsCreateInput"]: {
	discount: number,
	shipping: number,
	subtotal: number,
	tax: number,
	total: number
};
	["PriceSummaryCreateInput"]: {
	days: number,
	discount?: number | undefined,
	payableRent: number,
	rentPerDay: number,
	serviceCharge: number,
	totalRent: number
};
	["BookingPisignageDataCreateInput"]: {
	deployedFileName?: string | undefined,
	playlistId?: string | undefined
};
	["BookingRefundCreateInput"]: {
	id: string,
	razorpay?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["JSON"] | undefined,
	createdByUserId: string
};
	["FileCreateNestedManyWithoutBookingsInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutBookingsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutBookingsInput"]> | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutBookingsInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: ModelTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: ModelTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: ModelTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["FileMetaCreateInput"]: {
	duration: number,
	height: number,
	width: number
};
	["UserCreateNestedOneWithoutUpdatedFilesInput"]: {
	create?: ModelTypes["UserCreateWithoutUpdatedFilesInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutUpdatedFilesInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutUpdatedFilesInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["UserStripeCreateInput"]: {
	customerId: string
};
	["NotificationPreferenceCreateInput"]: {
	type?: Array<ModelTypes["NotificationType"]> | undefined,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined,
	pushSetting?: ModelTypes["PushSettingCreateInput"] | undefined,
	enabled?: boolean | undefined
};
	["PushSettingCreateInput"]: {
	fcmToken: string
};
	["BookingCreateNestedManyWithoutUserInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutUserInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["BusinessCategoryCreateNestedOneWithoutBookingsInput"]: {
	create?: ModelTypes["BusinessCategoryCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: ModelTypes["BusinessCategoryWhereUniqueInput"] | undefined
};
	["BusinessCategoryCreateWithoutBookingsInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["CampaignCreateNestedManyWithoutBusinessCategoryInput"]: {
	create?: Array<ModelTypes["CampaignCreateWithoutBusinessCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"]> | undefined,
	createMany?: ModelTypes["CampaignCreateManyBusinessCategoryInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined
};
	["CampaignCreateWithoutBusinessCategoryInput"]: {
	id?: string | undefined,
	name: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ModelTypes["UserCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: ModelTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["UserCreateNestedOneWithoutCampaignsInput"]: {
	create?: ModelTypes["UserCreateWithoutCampaignsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutCampaignsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutCampaignsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["FileCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutCreatedByInput"]> | undefined,
	createMany?: ModelTypes["FileCreateManyCreatedByInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutCreatedByInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: ModelTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: ModelTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["BookingCreateNestedManyWithoutAssetsInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutAssetsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutAssetsInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutAssetsInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"]: {
	create?: Array<ModelTypes["AdvertisementCategoryCreateWithoutBookingsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]> | undefined,
	connect?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined
};
	["AdvertisementCategoryCreateWithoutBookingsInput"]: {
	id?: string | undefined,
	name: string,
	type: ModelTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutAdCategoryInput"] | undefined
};
	["CampaignCreateNestedManyWithoutAdCategoryInput"]: {
	create?: Array<ModelTypes["CampaignCreateWithoutAdCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CampaignCreateOrConnectWithoutAdCategoryInput"]> | undefined,
	connect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined
};
	["CampaignCreateWithoutAdCategoryInput"]: {
	id?: string | undefined,
	name: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ModelTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: ModelTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	bookings?: ModelTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: ModelTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["BusinessCategoryCreateNestedOneWithoutCampaignsInput"]: {
	create?: ModelTypes["BusinessCategoryCreateWithoutCampaignsInput"] | undefined,
	connectOrCreate?: ModelTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"] | undefined,
	connect?: ModelTypes["BusinessCategoryWhereUniqueInput"] | undefined
};
	["BusinessCategoryCreateWithoutCampaignsInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["BookingCreateNestedManyWithoutBusinessCategoryInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutBusinessCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyBusinessCategoryInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutBusinessCategoryInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["FileCreateNestedManyWithoutProofBookingInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutProofBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutProofBookingInput"]> | undefined,
	createMany?: ModelTypes["FileCreateManyProofBookingInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutProofBookingInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: ModelTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: ModelTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["UserCreateNestedOneWithoutCreatedFilesInput"]: {
	create?: ModelTypes["UserCreateWithoutCreatedFilesInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutCreatedFilesInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutCreatedFilesInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["FileCreateNestedManyWithoutUpdatedByInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutUpdatedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutUpdatedByInput"]> | undefined,
	createMany?: ModelTypes["FileCreateManyUpdatedByInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutUpdatedByInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url: string,
	createdBy: ModelTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: ModelTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutFilesInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutFilesInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutFilesInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutFilesInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["UserCreateNestedOneWithoutOwnedWorkspacesInput"]: {
	create?: ModelTypes["UserCreateWithoutOwnedWorkspacesInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutOwnedWorkspacesInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["OrderCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ModelTypes["OrderCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["OrderCreateOrConnectWithoutCreatedByInput"]> | undefined,
	createMany?: ModelTypes["OrderCreateManyCreatedByInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined
};
	["OrderCreateWithoutCreatedByInput"]: {
	id?: string | undefined,
	createdDate?: ModelTypes["DateTime"] | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: ModelTypes["PriceSummaryCreateInput"],
	selectedDate: ModelTypes["OrderSelectedDateCreateInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutOrdersInput"]
};
	["AdBoardCreateNestedOneWithoutOrdersInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutOrdersInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutOrdersInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutOrdersInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["SlotConfigurationCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ModelTypes["SlotConfigurationCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["SlotConfigurationWhereUniqueInput"]> | undefined
};
	["SlotConfigurationCreateWithoutAdBoardInput"]: {
	id?: string | undefined,
	date: ModelTypes["DateTime"],
	availableSlots: number
};
	["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]: {
	where: ModelTypes["SlotConfigurationWhereUniqueInput"],
	create: ModelTypes["SlotConfigurationCreateWithoutAdBoardInput"]
};
	["SlotConfigurationCreateManyAdBoardInputEnvelope"]: {
	data: Array<ModelTypes["SlotConfigurationCreateManyAdBoardInput"]>
};
	["SlotConfigurationCreateManyAdBoardInput"]: {
	id?: string | undefined,
	date: ModelTypes["DateTime"],
	availableSlots: number
};
	["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"]: {
	create?: ModelTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutOfflineBookingAdboardsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["ChangelogCreateNestedManyWithoutUserInput"]: {
	create?: Array<ModelTypes["ChangelogCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["ChangelogCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["ChangelogCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined
};
	["ChangelogCreateWithoutUserInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	updates?: ModelTypes["JSON"] | undefined,
	booking?: ModelTypes["BookingCreateNestedOneWithoutChangelogsInput"] | undefined
};
	["BookingCreateNestedOneWithoutChangelogsInput"]: {
	create?: ModelTypes["BookingCreateWithoutChangelogsInput"] | undefined,
	connectOrCreate?: ModelTypes["BookingCreateOrConnectWithoutChangelogsInput"] | undefined,
	connect?: ModelTypes["BookingWhereUniqueInput"] | undefined
};
	["BookingCreateWithoutChangelogsInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["UserCreateNestedOneWithoutBookingsInput"]: {
	create?: ModelTypes["UserCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutBookingsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutCreatedByInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutCreatedByInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyCreatedByInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutCreatedByInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["OrderCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ModelTypes["OrderCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["OrderCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["OrderCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined
};
	["OrderCreateWithoutAdBoardInput"]: {
	id?: string | undefined,
	createdDate?: ModelTypes["DateTime"] | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: ModelTypes["PriceSummaryCreateInput"],
	selectedDate: ModelTypes["OrderSelectedDateCreateInput"],
	createdBy?: ModelTypes["UserCreateNestedOneWithoutOrdersInput"] | undefined
};
	["UserCreateNestedOneWithoutOrdersInput"]: {
	create?: ModelTypes["UserCreateWithoutOrdersInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutOrdersInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutOrdersInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AuditCreateNestedManyWithoutUserInput"]: {
	create?: Array<ModelTypes["AuditCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AuditCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["AuditCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined
};
	["AuditCreateWithoutUserInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutAuditsInput"] | undefined
};
	["EntityCreateInput"]: {
	type: string,
	id: string,
	name?: string | undefined
};
	["WorkspaceCreateNestedOneWithoutAuditsInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutAuditsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutAuditsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutAuditsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["MembershipCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["MembershipCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["MembershipCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["MembershipCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined
};
	["MembershipCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutMembershipsInput"],
	role: ModelTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["UserCreateNestedOneWithoutMembershipsInput"]: {
	create?: ModelTypes["UserCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutMembershipsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutMembershipsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["CampaignCreateNestedManyWithoutUserInput"]: {
	create?: Array<ModelTypes["CampaignCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CampaignCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["CampaignCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined
};
	["CampaignCreateWithoutUserInput"]: {
	id?: string | undefined,
	name: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	businessCategory: ModelTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: ModelTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"]: {
	create?: Array<ModelTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]> | undefined,
	connect?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined
};
	["AdvertisementCategoryCreateWithoutCampaignsInput"]: {
	id?: string | undefined,
	name: string,
	type: ModelTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdCategoryInput"] | undefined
};
	["BookingCreateNestedManyWithoutAdCategoryInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutAdCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutAdCategoryInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutAdCategoryInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["AdBoardCreateNestedOneWithoutBookingsInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutBookingsInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["UserCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ModelTypes["UserCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutAdBoardsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["BookingCreateNestedManyWithoutSharedUsersInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutSharedUsersInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutSharedUsersInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutSharedUsersInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["ChangelogCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ModelTypes["ChangelogCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["ChangelogCreateOrConnectWithoutBookingInput"]> | undefined,
	createMany?: ModelTypes["ChangelogCreateManyBookingInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined
};
	["ChangelogCreateWithoutBookingInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	updates?: ModelTypes["JSON"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutChangelogsInput"]
};
	["UserCreateNestedOneWithoutChangelogsInput"]: {
	create?: ModelTypes["UserCreateWithoutChangelogsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutChangelogsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutChangelogsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["MembershipCreateNestedManyWithoutUserInput"]: {
	create?: Array<ModelTypes["MembershipCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["MembershipCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["MembershipCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined
};
	["MembershipCreateWithoutUserInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"],
	role: ModelTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["WorkspaceCreateNestedOneWithoutMembershipsInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutMembershipsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["PlayerCreateNestedManyWithoutAdBoardsInput"]: {
	create?: Array<ModelTypes["PlayerCreateWithoutAdBoardsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlayerCreateOrConnectWithoutAdBoardsInput"]> | undefined,
	connect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined
};
	["PlayerCreateWithoutAdBoardsInput"]: {
	id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: ModelTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined
};
	["GalleryFileCreateInput"]: {
	name: string,
	url: string,
	contentType: string,
	width: number,
	height: number,
	duration?: number | undefined
};
	["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"]: {
	create?: ModelTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"] | undefined,
	connect?: ModelTypes["AdBoardGroupWhereUniqueInput"] | undefined
};
	["AdBoardGroupCreateWithoutPisignagePlayersInput"]: {
	id?: string | undefined,
	name: string,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["ScheduleCreateInput"]: {
	playlistId: string,
	playlistType?: string | undefined,
	skipForSchedule?: boolean | undefined,
	startTime?: string | undefined,
	endTime?: string | undefined
};
	["AdBoardCreateNestedOneWithoutAdBoardGroupInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutAdBoardGroupInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutAdBoardGroupInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutAdBoardsInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutAdBoardsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["PlaylistCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["PlaylistCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["PlaylistCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined
};
	["PlaylistCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PisignageFileCreateInput"]: {
	name: string,
	duration?: number | undefined,
	muteAudio?: boolean | undefined,
	isVideo?: boolean | undefined
};
	["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"]: {
	create?: ModelTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | undefined,
	connectOrCreate?: ModelTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"] | undefined,
	connect?: ModelTypes["PlaylistWhereUniqueInput"] | undefined
};
	["PlaylistCreateWithoutFooterParentPlaylistsInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	sidebarParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"]: {
	create?: ModelTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | undefined,
	connectOrCreate?: ModelTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"] | undefined,
	connect?: ModelTypes["PlaylistWhereUniqueInput"] | undefined
};
	["PlaylistCreateWithoutSidebarParentPlaylistsInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined
};
	["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"]: {
	create?: Array<ModelTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]> | undefined,
	createMany?: ModelTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined
};
	["AdBoardGroupCreateWithoutAdsPlaylistInput"]: {
	id?: string | undefined,
	name: string,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"]: {
	create?: Array<ModelTypes["PlaylistCreateWithoutAdBoardGroupsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]> | undefined,
	connect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined
};
	["PlaylistCreateWithoutAdBoardGroupsInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutPlaylistsInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutPlaylistsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutPlaylistsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["AdBoardGroupCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined
};
	["AdBoardGroupCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	name: string,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined
};
	["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"]: {
	create?: ModelTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | undefined,
	connectOrCreate?: ModelTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"] | undefined,
	connect?: ModelTypes["PlaylistWhereUniqueInput"] | undefined
};
	["PlaylistCreateWithoutAdBoardGroupsForAdsInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"]: {
	create?: Array<ModelTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined
};
	["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]: {
	id?: string | undefined,
	name: string,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["PlayerCreateNestedManyWithoutAdBoardGroupInput"]: {
	create?: Array<ModelTypes["PlayerCreateWithoutAdBoardGroupInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"]> | undefined,
	createMany?: ModelTypes["PlayerCreateManyAdBoardGroupInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined
};
	["PlayerCreateWithoutAdBoardGroupInput"]: {
	id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: ModelTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutPlayersInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutPlayersInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutPlayersInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutPlayersInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["AuditCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["AuditCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AuditCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["AuditCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined
};
	["AuditCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutAuditsInput"]
};
	["UserCreateNestedOneWithoutAuditsInput"]: {
	create?: ModelTypes["UserCreateWithoutAuditsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutAuditsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutAuditsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["NotificationCreateNestedManyWithoutRecipientInput"]: {
	create?: Array<ModelTypes["NotificationCreateWithoutRecipientInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["NotificationCreateOrConnectWithoutRecipientInput"]> | undefined,
	createMany?: ModelTypes["NotificationCreateManyRecipientInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["NotificationWhereUniqueInput"]> | undefined
};
	["NotificationCreateWithoutRecipientInput"]: {
	id?: string | undefined,
	type: ModelTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined
};
	["NotificationCreateOrConnectWithoutRecipientInput"]: {
	where: ModelTypes["NotificationWhereUniqueInput"],
	create: ModelTypes["NotificationCreateWithoutRecipientInput"]
};
	["NotificationCreateManyRecipientInputEnvelope"]: {
	data: Array<ModelTypes["NotificationCreateManyRecipientInput"]>
};
	["NotificationCreateManyRecipientInput"]: {
	id?: string | undefined,
	type: ModelTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined
};
	["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"]: {
	create?: Array<ModelTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]> | undefined,
	createMany?: ModelTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined
};
	["AdBoardPublishHistoryCreateWithoutPublishedByInput"]: {
	id?: string | undefined,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	changesSnapshot: ModelTypes["JSON"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutHistoryInput"]
};
	["AdBoardCreateNestedOneWithoutHistoryInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutHistoryInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutHistoryInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutHistoryInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardGroupCreateNestedOneWithoutAdBoardInput"]: {
	create?: ModelTypes["AdBoardGroupCreateWithoutAdBoardInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"] | undefined,
	connect?: ModelTypes["AdBoardGroupWhereUniqueInput"] | undefined
};
	["AdBoardGroupCreateWithoutAdBoardInput"]: {
	id?: string | undefined,
	name: string,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutAdBoardGroupsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["BookingCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["CampaignCreateNestedOneWithoutBookingsInput"]: {
	create?: ModelTypes["CampaignCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["CampaignCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: ModelTypes["CampaignWhereUniqueInput"] | undefined
};
	["CampaignCreateWithoutBookingsInput"]: {
	id?: string | undefined,
	name: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ModelTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: ModelTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	payments?: ModelTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["PaymentCreateNestedManyWithoutCampaignInput"]: {
	create?: Array<ModelTypes["PaymentCreateWithoutCampaignInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PaymentCreateOrConnectWithoutCampaignInput"]> | undefined,
	createMany?: ModelTypes["PaymentCreateManyCampaignInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PaymentWhereUniqueInput"]> | undefined
};
	["PaymentCreateWithoutCampaignInput"]: {
	id?: string | undefined,
	paymentIntentId: string,
	checkoutSessionId: string,
	eventId: string,
	liveMode?: boolean | undefined,
	paymentGateway: ModelTypes["PaymentGatewayName"],
	subtotal: number,
	total: number,
	tax: number,
	discount: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["PaymentCreateOrConnectWithoutCampaignInput"]: {
	where: ModelTypes["PaymentWhereUniqueInput"],
	create: ModelTypes["PaymentCreateWithoutCampaignInput"]
};
	["PaymentCreateManyCampaignInputEnvelope"]: {
	data: Array<ModelTypes["PaymentCreateManyCampaignInput"]>
};
	["PaymentCreateManyCampaignInput"]: {
	id?: string | undefined,
	paymentIntentId: string,
	checkoutSessionId: string,
	eventId: string,
	liveMode?: boolean | undefined,
	paymentGateway: ModelTypes["PaymentGatewayName"],
	subtotal: number,
	total: number,
	tax: number,
	discount: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["FileCreateNestedManyWithoutCampaignsInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutCampaignsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutCampaignsInput"]> | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutCampaignsInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: ModelTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: ModelTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: ModelTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined
};
	["BookingCreateNestedOneWithoutProofsInput"]: {
	create?: ModelTypes["BookingCreateWithoutProofsInput"] | undefined,
	connectOrCreate?: ModelTypes["BookingCreateOrConnectWithoutProofsInput"] | undefined,
	connect?: ModelTypes["BookingWhereUniqueInput"] | undefined
};
	["BookingCreateWithoutProofsInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutBookingsInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutBookingsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["FileCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["FileCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: ModelTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: ModelTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	proofBooking?: ModelTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["CampaignCreateNestedManyWithoutAssetsInput"]: {
	create?: Array<ModelTypes["CampaignCreateWithoutAssetsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CampaignCreateOrConnectWithoutAssetsInput"]> | undefined,
	connect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined
};
	["CampaignCreateWithoutAssetsInput"]: {
	id?: string | undefined,
	name: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ModelTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: ModelTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: ModelTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined
};
	["BookingCreateNestedManyWithoutCampaignInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutCampaignInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutCampaignInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyCampaignInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutCampaignInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["PayoutCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ModelTypes["PayoutCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PayoutCreateOrConnectWithoutBookingInput"]> | undefined,
	createMany?: ModelTypes["PayoutCreateManyBookingInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined
};
	["PayoutCreateWithoutBookingInput"]: {
	id?: string | undefined,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	status: ModelTypes["PayoutStatus"],
	transaction?: ModelTypes["TransactionCreateNestedOneWithoutPayoutsInput"] | undefined
};
	["TransactionCreateNestedOneWithoutPayoutsInput"]: {
	create?: ModelTypes["TransactionCreateWithoutPayoutsInput"] | undefined,
	connectOrCreate?: ModelTypes["TransactionCreateOrConnectWithoutPayoutsInput"] | undefined,
	connect?: ModelTypes["TransactionWhereUniqueInput"] | undefined
};
	["TransactionCreateWithoutPayoutsInput"]: {
	id?: string | undefined,
	amount: number,
	createAt?: ModelTypes["DateTime"] | undefined,
	processedAt?: ModelTypes["DateTime"] | undefined,
	relatedEntityId: string,
	transactionStatus: ModelTypes["TransactionStatus"],
	details?: ModelTypes["JSON"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutTransactionsInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutTransactionsInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutTransactionsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutTransactionsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["PlayerCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["PlayerCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlayerCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["PlayerCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined
};
	["PlayerCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: ModelTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined
};
	["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"]: {
	create?: Array<ModelTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]> | undefined,
	createMany?: ModelTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined
};
	["PlayerUptimeStatusCreateWithoutPlayerInput"]: {
	id?: string | undefined,
	cpuSerialNumber: string,
	date: string,
	uptime: number
};
	["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]: {
	where: ModelTypes["PlayerUptimeStatusWhereUniqueInput"],
	create: ModelTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]
};
	["PlayerUptimeStatusCreateManyPlayerInputEnvelope"]: {
	data: Array<ModelTypes["PlayerUptimeStatusCreateManyPlayerInput"]>
};
	["PlayerUptimeStatusCreateManyPlayerInput"]: {
	id?: string | undefined,
	cpuSerialNumber: string,
	date: string,
	uptime: number
};
	["CameraCreateNestedManyWithoutPlayerInput"]: {
	create?: Array<ModelTypes["CameraCreateWithoutPlayerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraCreateOrConnectWithoutPlayerInput"]> | undefined,
	createMany?: ModelTypes["CameraCreateManyPlayerInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined
};
	["CameraCreateWithoutPlayerInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer: ModelTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	feeds?: ModelTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined
};
	["CameraServerCreateNestedOneWithoutCamerasInput"]: {
	create?: ModelTypes["CameraServerCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: ModelTypes["CameraServerCreateOrConnectWithoutCamerasInput"] | undefined,
	connect?: ModelTypes["CameraServerWhereUniqueInput"] | undefined
};
	["CameraServerCreateWithoutCamerasInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined,
	feeds?: ModelTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutCameraServersInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutCameraServersInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutCameraServersInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["TransactionCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["TransactionCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["TransactionCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["TransactionCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["TransactionWhereUniqueInput"]> | undefined
};
	["TransactionCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	amount: number,
	createAt?: ModelTypes["DateTime"] | undefined,
	processedAt?: ModelTypes["DateTime"] | undefined,
	relatedEntityId: string,
	transactionStatus: ModelTypes["TransactionStatus"],
	details?: ModelTypes["JSON"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutTransactionInput"] | undefined
};
	["PayoutCreateNestedManyWithoutTransactionInput"]: {
	create?: Array<ModelTypes["PayoutCreateWithoutTransactionInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PayoutCreateOrConnectWithoutTransactionInput"]> | undefined,
	createMany?: ModelTypes["PayoutCreateManyTransactionInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined
};
	["PayoutCreateWithoutTransactionInput"]: {
	id?: string | undefined,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	status: ModelTypes["PayoutStatus"],
	booking: ModelTypes["BookingCreateNestedOneWithoutPayoutsInput"]
};
	["BookingCreateNestedOneWithoutPayoutsInput"]: {
	create?: ModelTypes["BookingCreateWithoutPayoutsInput"] | undefined,
	connectOrCreate?: ModelTypes["BookingCreateOrConnectWithoutPayoutsInput"] | undefined,
	connect?: ModelTypes["BookingWhereUniqueInput"] | undefined
};
	["BookingCreateWithoutPayoutsInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["UserCreateNestedManyWithoutSharedBookingsInput"]: {
	create?: Array<ModelTypes["UserCreateWithoutSharedBookingsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["UserCreateOrConnectWithoutSharedBookingsInput"]> | undefined,
	connect?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined
};
	["UserCreateWithoutSharedBookingsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutOfflineBookingsContactInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["CameraCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ModelTypes["CameraCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["CameraCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined
};
	["CameraCreateWithoutAdBoardInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer: ModelTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: ModelTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined,
	feeds?: ModelTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined
};
	["PlayerCreateNestedOneWithoutCamerasInput"]: {
	create?: ModelTypes["PlayerCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: ModelTypes["PlayerCreateOrConnectWithoutCamerasInput"] | undefined,
	connect?: ModelTypes["PlayerWhereUniqueInput"] | undefined
};
	["PlayerCreateWithoutCamerasInput"]: {
	id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: ModelTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined
};
	["DigitalSignageCreateNestedOneWithoutPlayersInput"]: {
	create?: ModelTypes["DigitalSignageCreateWithoutPlayersInput"] | undefined,
	connectOrCreate?: ModelTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"] | undefined,
	connect?: ModelTypes["DigitalSignageWhereUniqueInput"] | undefined
};
	["DigitalSignageCreateWithoutPlayersInput"]: {
	id?: string | undefined,
	type: ModelTypes["SignageType"],
	name: string,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["DigitalSignageCreateOrConnectWithoutPlayersInput"]: {
	where: ModelTypes["DigitalSignageWhereUniqueInput"],
	create: ModelTypes["DigitalSignageCreateWithoutPlayersInput"]
};
	["DigitalSignageWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["DigitalSignageWhereInput"]> | undefined,
	OR?: Array<ModelTypes["DigitalSignageWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["DigitalSignageWhereInput"]> | undefined,
	type?: ModelTypes["EnumSignageTypeFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	players?: ModelTypes["PlayerListRelationFilter"] | undefined
};
	["AudienceCreateNestedManyWithoutPlayersInput"]: {
	create?: Array<ModelTypes["AudienceCreateWithoutPlayersInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AudienceCreateOrConnectWithoutPlayersInput"]> | undefined,
	connect?: Array<ModelTypes["AudienceWhereUniqueInput"]> | undefined
};
	["AudienceCreateWithoutPlayersInput"]: {
	id?: string | undefined,
	type: ModelTypes["AudienceType"],
	name: string,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AudienceCreateOrConnectWithoutPlayersInput"]: {
	where: ModelTypes["AudienceWhereUniqueInput"],
	create: ModelTypes["AudienceCreateWithoutPlayersInput"]
};
	["AdBoardCreateNestedManyWithoutPlayersInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutPlayersInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutPlayersInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutPlayersInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardCreateNestedOneWithoutDraftAdboardInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutDraftAdboardInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutDraftAdboardInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ModelTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined
};
	["AdBoardPublishHistoryCreateWithoutAdBoardInput"]: {
	id?: string | undefined,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	changesSnapshot: ModelTypes["JSON"],
	publishedBy: ModelTypes["UserCreateNestedOneWithoutPublishAdboardVersionInput"]
};
	["UserCreateNestedOneWithoutPublishAdboardVersionInput"]: {
	create?: ModelTypes["UserCreateWithoutPublishAdboardVersionInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutPublishAdboardVersionInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AdBoardReviewCommentCreateNestedManyWithoutUserInput"]: {
	create?: Array<ModelTypes["AdBoardReviewCommentCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined
};
	["AdBoardReviewCommentCreateWithoutUserInput"]: {
	id?: string | undefined,
	comment: string,
	action: ModelTypes["ReviewAction"],
	createdAt?: ModelTypes["DateTime"] | undefined,
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutReviewCommentsInput"]
};
	["AdBoardCreateNestedOneWithoutReviewCommentsInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutReviewCommentsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutReviewCommentsInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardCreateNestedOneWithoutPublishedVersionInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutPublishedVersionInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutPublishedVersionInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ModelTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined
};
	["AdBoardReviewCommentCreateWithoutAdBoardInput"]: {
	id?: string | undefined,
	comment: string,
	action: ModelTypes["ReviewAction"],
	createdAt?: ModelTypes["DateTime"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutReviewCommentsInput"]
};
	["UserCreateNestedOneWithoutReviewCommentsInput"]: {
	create?: ModelTypes["UserCreateWithoutReviewCommentsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutReviewCommentsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutReviewCommentsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutArchivedByInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutArchivedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutArchivedByInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyArchivedByInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutArchivedByInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"]: {
	create?: Array<ModelTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined
};
	["AdBoardPublicReviewCreateWithoutAdBoardInput"]: {
	id?: string | undefined,
	rating: number,
	comment?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutPublicReviewsInput"]
};
	["UserCreateNestedOneWithoutPublicReviewsInput"]: {
	create?: ModelTypes["UserCreateWithoutPublicReviewsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutPublicReviewsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutPublicReviewsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["WorkspaceCreateNestedManyWithoutOwnerInput"]: {
	create?: Array<ModelTypes["WorkspaceCreateWithoutOwnerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WorkspaceCreateOrConnectWithoutOwnerInput"]> | undefined,
	createMany?: ModelTypes["WorkspaceCreateManyOwnerInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined
};
	["WorkspaceCreateWithoutOwnerInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"]: {
	create?: ModelTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | undefined,
	connectOrCreate?: ModelTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"] | undefined,
	connect?: ModelTypes["SubscriptionWhereUniqueInput"] | undefined
};
	["SubscriptionCreateWithoutActiveWorkspaceInput"]: {
	id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsCreateInput"] | undefined
};
	["CancellationDetailsCreateInput"]: {
	canceledAt?: ModelTypes["DateTime"] | undefined,
	cancelAt?: ModelTypes["DateTime"] | undefined,
	cancelAtPeriodEnd: boolean,
	reason?: string | undefined
};
	["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"]: {
	where: ModelTypes["SubscriptionWhereUniqueInput"],
	create: ModelTypes["SubscriptionCreateWithoutActiveWorkspaceInput"]
};
	["CameraServerCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["CameraServerCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["CameraServerCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["CameraServerWhereUniqueInput"]> | undefined
};
	["CameraServerCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined,
	feeds?: ModelTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined
};
	["CameraCreateNestedManyWithoutCameraServerInput"]: {
	create?: Array<ModelTypes["CameraCreateWithoutCameraServerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraCreateOrConnectWithoutCameraServerInput"]> | undefined,
	createMany?: ModelTypes["CameraCreateManyCameraServerInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined
};
	["CameraCreateWithoutCameraServerInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	player?: ModelTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined,
	feeds?: ModelTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined
};
	["CameraFeedCreateNestedManyWithoutCameraInput"]: {
	create?: Array<ModelTypes["CameraFeedCreateWithoutCameraInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraFeedCreateOrConnectWithoutCameraInput"]> | undefined,
	createMany?: ModelTypes["CameraFeedCreateManyCameraInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined
};
	["CameraFeedCreateWithoutCameraInput"]: {
	id?: string | undefined,
	timestamp: ModelTypes["DateTime"],
	data: ModelTypes["CameraFeedDataCreateInput"],
	cameraServer: ModelTypes["CameraServerCreateNestedOneWithoutFeedsInput"]
};
	["CameraFeedDataCreateInput"]: {
	person?: number | undefined,
	car?: number | undefined,
	truck?: number | undefined,
	motorcycle?: number | undefined
};
	["CameraServerCreateNestedOneWithoutFeedsInput"]: {
	create?: ModelTypes["CameraServerCreateWithoutFeedsInput"] | undefined,
	connectOrCreate?: ModelTypes["CameraServerCreateOrConnectWithoutFeedsInput"] | undefined,
	connect?: ModelTypes["CameraServerWhereUniqueInput"] | undefined
};
	["CameraServerCreateWithoutFeedsInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined
};
	["CameraServerCreateOrConnectWithoutFeedsInput"]: {
	where: ModelTypes["CameraServerWhereUniqueInput"],
	create: ModelTypes["CameraServerCreateWithoutFeedsInput"]
};
	["CameraFeedCreateOrConnectWithoutCameraInput"]: {
	where: ModelTypes["CameraFeedWhereUniqueInput"],
	create: ModelTypes["CameraFeedCreateWithoutCameraInput"]
};
	["CameraFeedCreateManyCameraInputEnvelope"]: {
	data: Array<ModelTypes["CameraFeedCreateManyCameraInput"]>
};
	["CameraFeedCreateManyCameraInput"]: {
	id?: string | undefined,
	timestamp: ModelTypes["DateTime"],
	cameraServerId: string,
	data: ModelTypes["CameraFeedDataCreateInput"]
};
	["AdBoardCreateNestedOneWithoutCamerasInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutCamerasInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutCamerasInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["UserCreateNestedOneWithoutArchivedAdboardsInput"]: {
	create?: ModelTypes["UserCreateWithoutArchivedAdboardsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutArchivedAdboardsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["WorkspaceCreateNestedManyWithoutLeadsInput"]: {
	create?: Array<ModelTypes["WorkspaceCreateWithoutLeadsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WorkspaceCreateOrConnectWithoutLeadsInput"]> | undefined,
	connect?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined
};
	["WorkspaceCreateWithoutLeadsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined
};
	["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["WorkspaceRoleWhereUniqueInput"]> | undefined
};
	["WorkspaceRoleCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	name: string,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutRoleInput"] | undefined
};
	["MembershipCreateNestedManyWithoutRoleInput"]: {
	create?: Array<ModelTypes["MembershipCreateWithoutRoleInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["MembershipCreateOrConnectWithoutRoleInput"]> | undefined,
	createMany?: ModelTypes["MembershipCreateManyRoleInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined
};
	["MembershipCreateWithoutRoleInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutMembershipsInput"],
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutRoleInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	create: ModelTypes["MembershipCreateWithoutRoleInput"]
};
	["MembershipCreateManyRoleInputEnvelope"]: {
	data: Array<ModelTypes["MembershipCreateManyRoleInput"]>
};
	["MembershipCreateManyRoleInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	userId: string,
	workspaceId: string
};
	["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["WorkspaceRoleWhereUniqueInput"],
	create: ModelTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]
};
	["WorkspaceRoleCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["WorkspaceRoleCreateManyWorkspaceInput"]>
};
	["WorkspaceRoleCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	name: string,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["WebhookLogCreateNestedManyWithoutWorkspaceInput"]: {
	create?: Array<ModelTypes["WebhookLogCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["WebhookLogCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined
};
	["WebhookLogCreateWithoutWorkspaceInput"]: {
	id?: string | undefined,
	response?: ModelTypes["JSON"] | undefined,
	request?: ModelTypes["JSON"] | undefined,
	status: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	booking: ModelTypes["BookingCreateNestedOneWithoutWebhookLogsInput"]
};
	["BookingCreateNestedOneWithoutWebhookLogsInput"]: {
	create?: ModelTypes["BookingCreateWithoutWebhookLogsInput"] | undefined,
	connectOrCreate?: ModelTypes["BookingCreateOrConnectWithoutWebhookLogsInput"] | undefined,
	connect?: ModelTypes["BookingWhereUniqueInput"] | undefined
};
	["BookingCreateWithoutWebhookLogsInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["SlotUsageCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ModelTypes["SlotUsageCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["SlotUsageCreateOrConnectWithoutBookingInput"]> | undefined,
	createMany?: ModelTypes["SlotUsageCreateManyBookingInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["SlotUsageWhereUniqueInput"]> | undefined
};
	["SlotUsageCreateWithoutBookingInput"]: {
	id?: string | undefined,
	date: ModelTypes["DateTime"],
	slotUsed?: number | undefined
};
	["SlotUsageCreateOrConnectWithoutBookingInput"]: {
	where: ModelTypes["SlotUsageWhereUniqueInput"],
	create: ModelTypes["SlotUsageCreateWithoutBookingInput"]
};
	["SlotUsageCreateManyBookingInputEnvelope"]: {
	data: Array<ModelTypes["SlotUsageCreateManyBookingInput"]>
};
	["SlotUsageCreateManyBookingInput"]: {
	id?: string | undefined,
	date: ModelTypes["DateTime"],
	slotUsed?: number | undefined
};
	["BookingCreateOrConnectWithoutWebhookLogsInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutWebhookLogsInput"]
};
	["WebhookLogCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["WebhookLogWhereUniqueInput"],
	create: ModelTypes["WebhookLogCreateWithoutWorkspaceInput"]
};
	["WebhookLogCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["WebhookLogCreateManyWorkspaceInput"]>
};
	["WebhookLogCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	bookingId: string,
	response?: ModelTypes["JSON"] | undefined,
	request?: ModelTypes["JSON"] | undefined,
	status: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["WorkspaceCreateOrConnectWithoutLeadsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutLeadsInput"]
};
	["AdBoardPublicReviewCreateNestedManyWithoutUserInput"]: {
	create?: Array<ModelTypes["AdBoardPublicReviewCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined
};
	["AdBoardPublicReviewCreateWithoutUserInput"]: {
	id?: string | undefined,
	rating: number,
	comment?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"]
};
	["AdBoardCreateNestedOneWithoutPublicReviewsInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutPublicReviewsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutPublicReviewsInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined
};
	["AdBoardCreateOrConnectWithoutPublicReviewsInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]: {
	where: ModelTypes["AdBoardPublicReviewWhereUniqueInput"],
	create: ModelTypes["AdBoardPublicReviewCreateWithoutUserInput"]
};
	["AdBoardPublicReviewCreateManyUserInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardPublicReviewCreateManyUserInput"]>
};
	["AdBoardPublicReviewCreateManyUserInput"]: {
	id?: string | undefined,
	adBoardId: string,
	rating: number,
	comment?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["UserPositionCreateNestedManyWithoutUserInput"]: {
	create?: Array<ModelTypes["UserPositionCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["UserPositionCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["UserPositionCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["UserPositionWhereUniqueInput"]> | undefined
};
	["UserPositionCreateWithoutUserInput"]: {
	id?: string | undefined,
	timestamp: ModelTypes["DateTime"],
	createdAt?: ModelTypes["DateTime"] | undefined,
	coords: ModelTypes["UserPositionCoordsCreateInput"],
	location?: ModelTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserPositionCoordsCreateInput"]: {
	accuracy?: number | undefined,
	altitude?: number | undefined,
	altitudeAccuracy?: number | undefined,
	heading?: number | undefined,
	latitude: number,
	longitude: number,
	speed?: number | undefined
};
	["UserPositionLocationCreateInput"]: {
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["UserPositionCreateOrConnectWithoutUserInput"]: {
	where: ModelTypes["UserPositionWhereUniqueInput"],
	create: ModelTypes["UserPositionCreateWithoutUserInput"]
};
	["UserPositionCreateManyUserInputEnvelope"]: {
	data: Array<ModelTypes["UserPositionCreateManyUserInput"]>
};
	["UserPositionCreateManyUserInput"]: {
	id?: string | undefined,
	timestamp: ModelTypes["DateTime"],
	createdAt?: ModelTypes["DateTime"] | undefined,
	coords: ModelTypes["UserPositionCoordsCreateInput"],
	location?: ModelTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserCreateOrConnectWithoutArchivedAdboardsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutArchivedAdboardsInput"]
};
	["AdBoardCreateOrConnectWithoutCamerasInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutCameraServerInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	create: ModelTypes["CameraCreateWithoutCameraServerInput"]
};
	["CameraCreateManyCameraServerInputEnvelope"]: {
	data: Array<ModelTypes["CameraCreateManyCameraServerInput"]>
};
	["CameraCreateManyCameraServerInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	playerId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["CameraFeedCreateNestedManyWithoutCameraServerInput"]: {
	create?: Array<ModelTypes["CameraFeedCreateWithoutCameraServerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"]> | undefined,
	createMany?: ModelTypes["CameraFeedCreateManyCameraServerInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined
};
	["CameraFeedCreateWithoutCameraServerInput"]: {
	id?: string | undefined,
	timestamp: ModelTypes["DateTime"],
	data: ModelTypes["CameraFeedDataCreateInput"],
	camera: ModelTypes["CameraCreateNestedOneWithoutFeedsInput"]
};
	["CameraCreateNestedOneWithoutFeedsInput"]: {
	create?: ModelTypes["CameraCreateWithoutFeedsInput"] | undefined,
	connectOrCreate?: ModelTypes["CameraCreateOrConnectWithoutFeedsInput"] | undefined,
	connect?: ModelTypes["CameraWhereUniqueInput"] | undefined
};
	["CameraCreateWithoutFeedsInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer: ModelTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: ModelTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined
};
	["CameraCreateOrConnectWithoutFeedsInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	create: ModelTypes["CameraCreateWithoutFeedsInput"]
};
	["CameraFeedCreateOrConnectWithoutCameraServerInput"]: {
	where: ModelTypes["CameraFeedWhereUniqueInput"],
	create: ModelTypes["CameraFeedCreateWithoutCameraServerInput"]
};
	["CameraFeedCreateManyCameraServerInputEnvelope"]: {
	data: Array<ModelTypes["CameraFeedCreateManyCameraServerInput"]>
};
	["CameraFeedCreateManyCameraServerInput"]: {
	id?: string | undefined,
	cameraId: string,
	timestamp: ModelTypes["DateTime"],
	data: ModelTypes["CameraFeedDataCreateInput"]
};
	["CameraServerCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["CameraServerWhereUniqueInput"],
	create: ModelTypes["CameraServerCreateWithoutWorkspaceInput"]
};
	["CameraServerCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["CameraServerCreateManyWorkspaceInput"]>
};
	["CameraServerCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string
};
	["UserCreateNestedManyWithoutLeadWorspacesInput"]: {
	create?: Array<ModelTypes["UserCreateWithoutLeadWorspacesInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["UserCreateOrConnectWithoutLeadWorspacesInput"]> | undefined,
	connect?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined
};
	["UserCreateWithoutLeadWorspacesInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["UserCreateOrConnectWithoutLeadWorspacesInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutLeadWorspacesInput"]
};
	["WorkspaceCreateOrConnectWithoutOwnerInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutOwnerInput"]
};
	["WorkspaceCreateManyOwnerInputEnvelope"]: {
	data: Array<ModelTypes["WorkspaceCreateManyOwnerInput"]>
};
	["WorkspaceCreateManyOwnerInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	leadIds?: Array<string> | undefined
};
	["UserCreateOrConnectWithoutPublicReviewsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardPublicReviewWhereUniqueInput"],
	create: ModelTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardPublicReviewCreateManyAdBoardInput"]>
};
	["AdBoardPublicReviewCreateManyAdBoardInput"]: {
	id?: string | undefined,
	userId: string,
	rating: number,
	comment?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["AdBoardCreateOrConnectWithoutArchivedByInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutArchivedByInput"]
};
	["AdBoardCreateManyArchivedByInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardCreateManyArchivedByInput"]>
};
	["AdBoardCreateManyArchivedByInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["UserCreateOrConnectWithoutReviewCommentsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardReviewCommentWhereUniqueInput"],
	create: ModelTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardReviewCommentCreateManyAdBoardInput"]>
};
	["AdBoardReviewCommentCreateManyAdBoardInput"]: {
	id?: string | undefined,
	userId: string,
	comment: string,
	action: ModelTypes["ReviewAction"],
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["AdBoardCreateOrConnectWithoutPublishedVersionInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutPublishedVersionInput"]
};
	["AdBoardCreateOrConnectWithoutReviewCommentsInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]: {
	where: ModelTypes["AdBoardReviewCommentWhereUniqueInput"],
	create: ModelTypes["AdBoardReviewCommentCreateWithoutUserInput"]
};
	["AdBoardReviewCommentCreateManyUserInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardReviewCommentCreateManyUserInput"]>
};
	["AdBoardReviewCommentCreateManyUserInput"]: {
	id?: string | undefined,
	adBoardId: string,
	comment: string,
	action: ModelTypes["ReviewAction"],
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["UserCreateOrConnectWithoutPublishAdboardVersionInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutPublishAdboardVersionInput"]
};
	["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardPublishHistoryWhereUniqueInput"],
	create: ModelTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardPublishHistoryCreateManyAdBoardInput"]>
};
	["AdBoardPublishHistoryCreateManyAdBoardInput"]: {
	id?: string | undefined,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	publishedByUserId: string,
	changesSnapshot: ModelTypes["JSON"]
};
	["AdBoardCreateOrConnectWithoutDraftAdboardInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutDraftAdboardInput"]
};
	["AdBoardCreateOrConnectWithoutPlayersInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutPlayersInput"]
};
	["PlayerCreateOrConnectWithoutCamerasInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	create: ModelTypes["PlayerCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutAdBoardInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	create: ModelTypes["CameraCreateWithoutAdBoardInput"]
};
	["CameraCreateManyAdBoardInputEnvelope"]: {
	data: Array<ModelTypes["CameraCreateManyAdBoardInput"]>
};
	["CameraCreateManyAdBoardInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	playerId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined
};
	["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]
};
	["AdBoardCreateManyOfflineBookingsContactInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardCreateManyOfflineBookingsContactInput"]>
};
	["AdBoardCreateManyOfflineBookingsContactInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["UserCreateOrConnectWithoutSharedBookingsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutSharedBookingsInput"]
};
	["WebhookLogCreateNestedManyWithoutBookingInput"]: {
	create?: Array<ModelTypes["WebhookLogCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WebhookLogCreateOrConnectWithoutBookingInput"]> | undefined,
	createMany?: ModelTypes["WebhookLogCreateManyBookingInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined
};
	["WebhookLogCreateWithoutBookingInput"]: {
	id?: string | undefined,
	response?: ModelTypes["JSON"] | undefined,
	request?: ModelTypes["JSON"] | undefined,
	status: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutWebhookLogsInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutWebhookLogsInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutWebhookLogsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutWebhookLogsInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["WorkspaceCreateOrConnectWithoutWebhookLogsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutWebhookLogsInput"]
};
	["WebhookLogCreateOrConnectWithoutBookingInput"]: {
	where: ModelTypes["WebhookLogWhereUniqueInput"],
	create: ModelTypes["WebhookLogCreateWithoutBookingInput"]
};
	["WebhookLogCreateManyBookingInputEnvelope"]: {
	data: Array<ModelTypes["WebhookLogCreateManyBookingInput"]>
};
	["WebhookLogCreateManyBookingInput"]: {
	id?: string | undefined,
	response?: ModelTypes["JSON"] | undefined,
	request?: ModelTypes["JSON"] | undefined,
	status: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	workspaceId?: string | undefined
};
	["BookingCreateOrConnectWithoutPayoutsInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutPayoutsInput"]
};
	["PayoutCreateOrConnectWithoutTransactionInput"]: {
	where: ModelTypes["PayoutWhereUniqueInput"],
	create: ModelTypes["PayoutCreateWithoutTransactionInput"]
};
	["PayoutCreateManyTransactionInputEnvelope"]: {
	data: Array<ModelTypes["PayoutCreateManyTransactionInput"]>
};
	["PayoutCreateManyTransactionInput"]: {
	id?: string | undefined,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	bookingId: string,
	status: ModelTypes["PayoutStatus"]
};
	["TransactionCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["TransactionWhereUniqueInput"],
	create: ModelTypes["TransactionCreateWithoutWorkspaceInput"]
};
	["TransactionCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["TransactionCreateManyWorkspaceInput"]>
};
	["TransactionCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	amount: number,
	createAt?: ModelTypes["DateTime"] | undefined,
	processedAt?: ModelTypes["DateTime"] | undefined,
	relatedEntityId: string,
	transactionStatus: ModelTypes["TransactionStatus"],
	details?: ModelTypes["JSON"] | undefined
};
	["WorkspaceCreateOrConnectWithoutCameraServersInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutCameraServersInput"]
};
	["CameraServerCreateOrConnectWithoutCamerasInput"]: {
	where: ModelTypes["CameraServerWhereUniqueInput"],
	create: ModelTypes["CameraServerCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutPlayerInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	create: ModelTypes["CameraCreateWithoutPlayerInput"]
};
	["CameraCreateManyPlayerInputEnvelope"]: {
	data: Array<ModelTypes["CameraCreateManyPlayerInput"]>
};
	["CameraCreateManyPlayerInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["PlayerCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	create: ModelTypes["PlayerCreateWithoutWorkspaceInput"]
};
	["PlayerCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["PlayerCreateManyWorkspaceInput"]>
};
	["PlayerCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId?: Array<string> | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardIds?: Array<string> | undefined
};
	["WorkspaceCreateOrConnectWithoutTransactionsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutTransactionsInput"]
};
	["TransactionCreateOrConnectWithoutPayoutsInput"]: {
	where: ModelTypes["TransactionWhereUniqueInput"],
	create: ModelTypes["TransactionCreateWithoutPayoutsInput"]
};
	["PayoutCreateOrConnectWithoutBookingInput"]: {
	where: ModelTypes["PayoutWhereUniqueInput"],
	create: ModelTypes["PayoutCreateWithoutBookingInput"]
};
	["PayoutCreateManyBookingInputEnvelope"]: {
	data: Array<ModelTypes["PayoutCreateManyBookingInput"]>
};
	["PayoutCreateManyBookingInput"]: {
	id?: string | undefined,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: ModelTypes["DateTime"] | undefined,
	status: ModelTypes["PayoutStatus"],
	transactionId?: string | undefined
};
	["BookingCreateOrConnectWithoutCampaignInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutCampaignInput"]
};
	["BookingCreateManyCampaignInputEnvelope"]: {
	data: Array<ModelTypes["BookingCreateManyCampaignInput"]>
};
	["BookingCreateManyCampaignInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	workspaceId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["CampaignCreateOrConnectWithoutAssetsInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	create: ModelTypes["CampaignCreateWithoutAssetsInput"]
};
	["FileCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	create: ModelTypes["FileCreateWithoutWorkspaceInput"]
};
	["FileCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["FileCreateManyWorkspaceInput"]>
};
	["FileCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["WorkspaceCreateOrConnectWithoutBookingsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutProofsInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutProofsInput"]
};
	["FileCreateOrConnectWithoutCampaignsInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	create: ModelTypes["FileCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutBookingsInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	create: ModelTypes["CampaignCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutWorkspaceInput"]
};
	["BookingCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["BookingCreateManyWorkspaceInput"]>
};
	["BookingCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutAdBoardInput"]
};
	["AdBoardCreateOrConnectWithoutHistoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutHistoryInput"]
};
	["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]: {
	where: ModelTypes["AdBoardPublishHistoryWhereUniqueInput"],
	create: ModelTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardPublishHistoryCreateManyPublishedByInput"]>
};
	["AdBoardPublishHistoryCreateManyPublishedByInput"]: {
	id?: string | undefined,
	adBoardId: string,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	changesSnapshot: ModelTypes["JSON"]
};
	["UserCreateOrConnectWithoutAuditsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutAuditsInput"]
};
	["AuditCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["AuditWhereUniqueInput"],
	create: ModelTypes["AuditCreateWithoutWorkspaceInput"]
};
	["AuditCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["AuditCreateManyWorkspaceInput"]>
};
	["AuditCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	userId: string,
	updates?: ModelTypes["JSON"] | undefined
};
	["WorkspaceCreateOrConnectWithoutPlayersInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutPlayersInput"]
};
	["PlayerCreateOrConnectWithoutAdBoardGroupInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	create: ModelTypes["PlayerCreateWithoutAdBoardGroupInput"]
};
	["PlayerCreateManyAdBoardGroupInputEnvelope"]: {
	data: Array<ModelTypes["PlayerCreateManyAdBoardGroupInput"]>
};
	["PlayerCreateManyAdBoardGroupInput"]: {
	id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	workpaceId?: string | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId?: Array<string> | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardIds?: Array<string> | undefined
};
	["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]
};
	["PlaylistCreateNestedManyWithoutFooterPlaylistInput"]: {
	create?: Array<ModelTypes["PlaylistCreateWithoutFooterPlaylistInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]> | undefined,
	createMany?: ModelTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined
};
	["PlaylistCreateWithoutFooterPlaylistInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"]: {
	create?: Array<ModelTypes["PlaylistCreateWithoutSidebarPlaylistInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]> | undefined,
	createMany?: ModelTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined
};
	["PlaylistCreateWithoutSidebarPlaylistInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	create: ModelTypes["PlaylistCreateWithoutSidebarPlaylistInput"]
};
	["PlaylistCreateManySidebarPlaylistInputEnvelope"]: {
	data: Array<ModelTypes["PlaylistCreateManySidebarPlaylistInput"]>
};
	["PlaylistCreateManySidebarPlaylistInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	create: ModelTypes["PlaylistCreateWithoutFooterPlaylistInput"]
};
	["PlaylistCreateManyFooterPlaylistInputEnvelope"]: {
	data: Array<ModelTypes["PlaylistCreateManyFooterPlaylistInput"]>
};
	["PlaylistCreateManyFooterPlaylistInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	create: ModelTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutWorkspaceInput"]
};
	["AdBoardGroupCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardGroupCreateManyWorkspaceInput"]>
};
	["AdBoardGroupCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	name: string,
	adBoardId?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	scheduledPlaylistsIDs?: Array<string> | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined
};
	["WorkspaceCreateOrConnectWithoutPlaylistsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	create: ModelTypes["PlaylistCreateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardGroupCreateManyAdsPlaylistInput"]>
};
	["AdBoardGroupCreateManyAdsPlaylistInput"]: {
	id?: string | undefined,
	name: string,
	adBoardId?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	scheduledPlaylistsIDs?: Array<string> | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	create: ModelTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	create: ModelTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	create: ModelTypes["PlaylistCreateWithoutWorkspaceInput"]
};
	["PlaylistCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["PlaylistCreateManyWorkspaceInput"]>
};
	["PlaylistCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined
};
	["WorkspaceCreateOrConnectWithoutAdBoardsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutAdBoardGroupInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutAdBoardGroupInput"]
};
	["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"]
};
	["PlayerCreateOrConnectWithoutAdBoardsInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	create: ModelTypes["PlayerCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutWorkspaceInput"]
};
	["AdBoardCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardCreateManyWorkspaceInput"]>
};
	["AdBoardCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["WorkspaceCreateOrConnectWithoutMembershipsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutMembershipsInput"]
};
	["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]: {
	create?: ModelTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"] | undefined,
	connect?: ModelTypes["WorkspaceRoleWhereUniqueInput"] | undefined
};
	["WorkspaceRoleCreateWithoutMembershipsInput"]: {
	id?: string | undefined,
	name: string,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutWorkspaceRolesInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutWorkspaceRolesInput"]
};
	["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"]: {
	where: ModelTypes["WorkspaceRoleWhereUniqueInput"],
	create: ModelTypes["WorkspaceRoleCreateWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutUserInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	create: ModelTypes["MembershipCreateWithoutUserInput"]
};
	["MembershipCreateManyUserInputEnvelope"]: {
	data: Array<ModelTypes["MembershipCreateManyUserInput"]>
};
	["MembershipCreateManyUserInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspaceId: string,
	roleId: string
};
	["UserCreateOrConnectWithoutChangelogsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutChangelogsInput"]
};
	["ChangelogCreateOrConnectWithoutBookingInput"]: {
	where: ModelTypes["ChangelogWhereUniqueInput"],
	create: ModelTypes["ChangelogCreateWithoutBookingInput"]
};
	["ChangelogCreateManyBookingInputEnvelope"]: {
	data: Array<ModelTypes["ChangelogCreateManyBookingInput"]>
};
	["ChangelogCreateManyBookingInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	userId: string,
	updates?: ModelTypes["JSON"] | undefined
};
	["BookingCreateOrConnectWithoutSharedUsersInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutSharedUsersInput"]
};
	["UserCreateOrConnectWithoutAdBoardsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutBookingsInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAdCategoryInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutAdCategoryInput"]
};
	["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]: {
	where: ModelTypes["AdvertisementCategoryWhereUniqueInput"],
	create: ModelTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutUserInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	create: ModelTypes["CampaignCreateWithoutUserInput"]
};
	["CampaignCreateManyUserInputEnvelope"]: {
	data: Array<ModelTypes["CampaignCreateManyUserInput"]>
};
	["CampaignCreateManyUserInput"]: {
	id?: string | undefined,
	name: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	businessCategoryId: string,
	adCategoryId?: Array<string> | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined
};
	["UserCreateOrConnectWithoutMembershipsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutWorkspaceInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	create: ModelTypes["MembershipCreateWithoutWorkspaceInput"]
};
	["MembershipCreateManyWorkspaceInputEnvelope"]: {
	data: Array<ModelTypes["MembershipCreateManyWorkspaceInput"]>
};
	["MembershipCreateManyWorkspaceInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	userId: string,
	roleId: string
};
	["WorkspaceCreateOrConnectWithoutAuditsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutAuditsInput"]
};
	["AuditCreateOrConnectWithoutUserInput"]: {
	where: ModelTypes["AuditWhereUniqueInput"],
	create: ModelTypes["AuditCreateWithoutUserInput"]
};
	["AuditCreateManyUserInputEnvelope"]: {
	data: Array<ModelTypes["AuditCreateManyUserInput"]>
};
	["AuditCreateManyUserInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	workspaceId?: string | undefined,
	updates?: ModelTypes["JSON"] | undefined
};
	["UserCreateOrConnectWithoutOrdersInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutOrdersInput"]
};
	["OrderCreateOrConnectWithoutAdBoardInput"]: {
	where: ModelTypes["OrderWhereUniqueInput"],
	create: ModelTypes["OrderCreateWithoutAdBoardInput"]
};
	["OrderCreateManyAdBoardInputEnvelope"]: {
	data: Array<ModelTypes["OrderCreateManyAdBoardInput"]>
};
	["OrderCreateManyAdBoardInput"]: {
	id?: string | undefined,
	createdDate?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: ModelTypes["PriceSummaryCreateInput"],
	selectedDate: ModelTypes["OrderSelectedDateCreateInput"]
};
	["AdBoardCreateOrConnectWithoutCreatedByInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutCreatedByInput"]
};
	["AdBoardCreateManyCreatedByInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardCreateManyCreatedByInput"]>
};
	["AdBoardCreateManyCreatedByInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["UserCreateOrConnectWithoutBookingsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutChangelogsInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutChangelogsInput"]
};
	["ChangelogCreateOrConnectWithoutUserInput"]: {
	where: ModelTypes["ChangelogWhereUniqueInput"],
	create: ModelTypes["ChangelogCreateWithoutUserInput"]
};
	["ChangelogCreateManyUserInputEnvelope"]: {
	data: Array<ModelTypes["ChangelogCreateManyUserInput"]>
};
	["ChangelogCreateManyUserInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	bookingId?: string | undefined,
	updates?: ModelTypes["JSON"] | undefined
};
	["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutOfflineBookingAdboardsInput"]
};
	["AdBoardCreateOrConnectWithoutOrdersInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutOrdersInput"]
};
	["OrderCreateOrConnectWithoutCreatedByInput"]: {
	where: ModelTypes["OrderWhereUniqueInput"],
	create: ModelTypes["OrderCreateWithoutCreatedByInput"]
};
	["OrderCreateManyCreatedByInputEnvelope"]: {
	data: Array<ModelTypes["OrderCreateManyCreatedByInput"]>
};
	["OrderCreateManyCreatedByInput"]: {
	id?: string | undefined,
	adBoardId: string,
	createdDate?: ModelTypes["DateTime"] | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: ModelTypes["PriceSummaryCreateInput"],
	selectedDate: ModelTypes["OrderSelectedDateCreateInput"]
};
	["UserCreateOrConnectWithoutOwnedWorkspacesInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutOwnedWorkspacesInput"]
};
	["WorkspaceCreateOrConnectWithoutFilesInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutFilesInput"]
};
	["FileCreateOrConnectWithoutUpdatedByInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	create: ModelTypes["FileCreateWithoutUpdatedByInput"]
};
	["FileCreateManyUpdatedByInputEnvelope"]: {
	data: Array<ModelTypes["FileCreateManyUpdatedByInput"]>
};
	["FileCreateManyUpdatedByInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["UserCreateOrConnectWithoutCreatedFilesInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutCreatedFilesInput"]
};
	["FileCreateOrConnectWithoutProofBookingInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	create: ModelTypes["FileCreateWithoutProofBookingInput"]
};
	["FileCreateManyProofBookingInputEnvelope"]: {
	data: Array<ModelTypes["FileCreateManyProofBookingInput"]>
};
	["FileCreateManyProofBookingInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["BookingCreateOrConnectWithoutBusinessCategoryInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutBusinessCategoryInput"]
};
	["BookingCreateManyBusinessCategoryInputEnvelope"]: {
	data: Array<ModelTypes["BookingCreateManyBusinessCategoryInput"]>
};
	["BookingCreateManyBusinessCategoryInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["BusinessCategoryCreateOrConnectWithoutCampaignsInput"]: {
	where: ModelTypes["BusinessCategoryWhereUniqueInput"],
	create: ModelTypes["BusinessCategoryCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutAdCategoryInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	create: ModelTypes["CampaignCreateWithoutAdCategoryInput"]
};
	["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]: {
	where: ModelTypes["AdvertisementCategoryWhereUniqueInput"],
	create: ModelTypes["AdvertisementCategoryCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAssetsInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutAssetsInput"]
};
	["FileCreateOrConnectWithoutCreatedByInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	create: ModelTypes["FileCreateWithoutCreatedByInput"]
};
	["FileCreateManyCreatedByInputEnvelope"]: {
	data: Array<ModelTypes["FileCreateManyCreatedByInput"]>
};
	["FileCreateManyCreatedByInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["UserCreateOrConnectWithoutCampaignsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutBusinessCategoryInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	create: ModelTypes["CampaignCreateWithoutBusinessCategoryInput"]
};
	["CampaignCreateManyBusinessCategoryInputEnvelope"]: {
	data: Array<ModelTypes["CampaignCreateManyBusinessCategoryInput"]>
};
	["CampaignCreateManyBusinessCategoryInput"]: {
	id?: string | undefined,
	name: string,
	userId: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined
};
	["BusinessCategoryCreateOrConnectWithoutBookingsInput"]: {
	where: ModelTypes["BusinessCategoryWhereUniqueInput"],
	create: ModelTypes["BusinessCategoryCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutUserInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutUserInput"]
};
	["BookingCreateManyUserInputEnvelope"]: {
	data: Array<ModelTypes["BookingCreateManyUserInput"]>
};
	["BookingCreateManyUserInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["UserCreateOrConnectWithoutUpdatedFilesInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutUpdatedFilesInput"]
};
	["FileCreateOrConnectWithoutBookingsInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	create: ModelTypes["FileCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAdBoardInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	create: ModelTypes["BookingCreateWithoutAdBoardInput"]
};
	["BookingCreateManyAdBoardInputEnvelope"]: {
	data: Array<ModelTypes["BookingCreateManyAdBoardInput"]>
};
	["BookingCreateManyAdBoardInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["AdBoardCreateOrConnectWithoutCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutCategoryInput"]
};
	["AdBoardCreateManyCategoryInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardCreateManyCategoryInput"]>
};
	["AdBoardCreateManyCategoryInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]: {
	where: ModelTypes["BusinessCategoryWhereUniqueInput"],
	create: ModelTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutLocationCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutLocationCategoryInput"]
};
	["AdBoardCreateManyLocationCategoryInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardCreateManyLocationCategoryInput"]>
};
	["AdBoardCreateManyLocationCategoryInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"]: {
	where: ModelTypes["LocationCategoryWhereUniqueInput"],
	create: ModelTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"]
};
	["LocationCategoryWhereUniqueInput"]: {
	id?: string | undefined,
	AND?: Array<ModelTypes["LocationCategoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["LocationCategoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["LocationCategoryWhereInput"]> | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	adBoards?: ModelTypes["AdBoardListRelationFilter"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryListRelationFilter"] | undefined
};
	["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ModelTypes["LocationSubCategoryWhereUniqueInput"],
	create: ModelTypes["LocationSubCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutRestrictedBusinesInput"]
};
	["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ModelTypes["BusinessCategoryWhereUniqueInput"],
	create: ModelTypes["BusinessCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutLocationSubCategoryInput"]
};
	["AdBoardCreateManyLocationSubCategoryInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardCreateManyLocationSubCategoryInput"]>
};
	["AdBoardCreateManyLocationSubCategoryInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]: {
	where: ModelTypes["LocationSubCategoryWhereUniqueInput"],
	create: ModelTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"]: {
	data: Array<ModelTypes["LocationSubCategoryCreateManyLocationCategoryInput"]>
};
	["LocationSubCategoryCreateManyLocationCategoryInput"]: {
	id?: string | undefined,
	name: string
};
	["LocationCategoryCreateOrConnectWithoutAdBoardsInput"]: {
	where: ModelTypes["LocationCategoryWhereUniqueInput"],
	create: ModelTypes["LocationCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardUpdateManyMutationInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AdBoardUpdateInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ModelTypes["LocationCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: ModelTypes["LocationCategoryUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["LocationCategoryWhereInput"] | undefined,
	connect?: ModelTypes["LocationCategoryWhereUniqueInput"] | undefined,
	update?: ModelTypes["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["LocationCategoryUpsertWithoutAdBoardsInput"]: {
	update: ModelTypes["LocationCategoryUpdateWithoutAdBoardsInput"],
	create: ModelTypes["LocationCategoryCreateWithoutAdBoardsInput"],
	where?: ModelTypes["LocationCategoryWhereInput"] | undefined
};
	["LocationCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"] | undefined
};
	["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"]: {
	create?: Array<ModelTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]> | undefined,
	upsert?: Array<ModelTypes["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]> | undefined,
	createMany?: ModelTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"] | undefined,
	set?: Array<ModelTypes["LocationSubCategoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["LocationSubCategoryWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["LocationSubCategoryWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["LocationSubCategoryWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]> | undefined,
	updateMany?: Array<ModelTypes["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]> | undefined,
	deleteMany?: Array<ModelTypes["LocationSubCategoryScalarWhereInput"]> | undefined
};
	["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ModelTypes["LocationSubCategoryWhereUniqueInput"],
	update: ModelTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"],
	create: ModelTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryUpdateWithoutLocationCategoryInput"]: {
	name?: string | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutLocationSubCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutLocationSubCategoryInput"],
	create: ModelTypes["AdBoardCreateWithoutLocationSubCategoryInput"]
};
	["AdBoardUpdateWithoutLocationSubCategoryInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ModelTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: ModelTypes["AdBoardTypeUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["AdBoardTypeWhereInput"] | undefined,
	connect?: ModelTypes["AdBoardTypeWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["AdBoardTypeUpsertWithoutAdBoardsInput"]: {
	update: ModelTypes["AdBoardTypeUpdateWithoutAdBoardsInput"],
	create: ModelTypes["AdBoardTypeCreateWithoutAdBoardsInput"],
	where?: ModelTypes["AdBoardTypeWhereInput"] | undefined
};
	["AdBoardTypeUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined,
	description?: string | undefined
};
	["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ModelTypes["AdBoardTypeWhereInput"] | undefined,
	data: ModelTypes["AdBoardTypeUpdateWithoutAdBoardsInput"]
};
	["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ModelTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: ModelTypes["BusinessCategoryUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	connect?: ModelTypes["BusinessCategoryWhereUniqueInput"] | undefined,
	update?: ModelTypes["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["BusinessCategoryUpsertWithoutAdBoardsInput"]: {
	update: ModelTypes["BusinessCategoryUpdateWithoutAdBoardsInput"],
	create: ModelTypes["BusinessCategoryCreateWithoutAdBoardsInput"],
	where?: ModelTypes["BusinessCategoryWhereInput"] | undefined
};
	["BusinessCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutRestrictedBusinesInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]> | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutRestrictedBusinesInput"],
	create: ModelTypes["AdBoardCreateWithoutRestrictedBusinesInput"]
};
	["AdBoardUpdateWithoutRestrictedBusinesInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ModelTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: ModelTypes["LocationSubCategoryUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["LocationSubCategoryWhereInput"] | undefined,
	connect?: ModelTypes["LocationSubCategoryWhereUniqueInput"] | undefined,
	update?: ModelTypes["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["LocationSubCategoryUpsertWithoutAdBoardsInput"]: {
	update: ModelTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"],
	create: ModelTypes["LocationSubCategoryCreateWithoutAdBoardsInput"],
	where?: ModelTypes["LocationSubCategoryWhereInput"] | undefined
};
	["LocationSubCategoryUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"] | undefined
};
	["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"]: {
	create?: ModelTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | undefined,
	connectOrCreate?: ModelTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"] | undefined,
	upsert?: ModelTypes["LocationCategoryUpsertWithoutLocationSubCategoryInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["LocationCategoryWhereInput"] | undefined,
	connect?: ModelTypes["LocationCategoryWhereUniqueInput"] | undefined,
	update?: ModelTypes["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"] | undefined
};
	["LocationCategoryUpsertWithoutLocationSubCategoryInput"]: {
	update: ModelTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"],
	create: ModelTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"],
	where?: ModelTypes["LocationCategoryWhereInput"] | undefined
};
	["LocationCategoryUpdateWithoutLocationSubCategoryInput"]: {
	name?: string | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutLocationCategoryNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutLocationCategoryNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutLocationCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyLocationCategoryInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutLocationCategoryInput"],
	create: ModelTypes["AdBoardCreateWithoutLocationCategoryInput"]
};
	["AdBoardUpdateWithoutLocationCategoryInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"]: {
	create?: Array<ModelTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]> | undefined,
	upsert?: Array<ModelTypes["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]> | undefined,
	set?: Array<ModelTypes["BusinessCategoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BusinessCategoryWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BusinessCategoryWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BusinessCategoryWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]> | undefined,
	updateMany?: Array<ModelTypes["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BusinessCategoryScalarWhereInput"]> | undefined
};
	["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: {
	where: ModelTypes["BusinessCategoryWhereUniqueInput"],
	update: ModelTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"],
	create: ModelTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]
};
	["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutCategoryNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutCategoryInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyCategoryInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutCategoryInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutCategoryInput"],
	create: ModelTypes["AdBoardCreateWithoutCategoryInput"]
};
	["AdBoardUpdateWithoutCategoryInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["BookingUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<ModelTypes["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<ModelTypes["BookingUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	update: ModelTypes["BookingUpdateWithoutAdBoardInput"],
	create: ModelTypes["BookingCreateWithoutAdBoardInput"]
};
	["BookingUpdateWithoutAdBoardInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["FileUpdateManyWithoutBookingsNestedInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutBookingsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutBookingsInput"]> | undefined,
	upsert?: Array<ModelTypes["FileUpsertWithWhereUniqueWithoutBookingsInput"]> | undefined,
	set?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["FileUpdateWithWhereUniqueWithoutBookingsInput"]> | undefined,
	updateMany?: Array<ModelTypes["FileUpdateManyWithWhereWithoutBookingsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutBookingsInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	update: ModelTypes["FileUpdateWithoutBookingsInput"],
	create: ModelTypes["FileCreateWithoutBookingsInput"]
};
	["FileUpdateWithoutBookingsInput"]: {
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: ModelTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: ModelTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: ModelTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["UserUpdateOneWithoutUpdatedFilesNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutUpdatedFilesInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutUpdatedFilesInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutUpdatedFilesInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["UserWhereInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"] | undefined
};
	["UserUpsertWithoutUpdatedFilesInput"]: {
	update: ModelTypes["UserUpdateWithoutUpdatedFilesInput"],
	create: ModelTypes["UserCreateWithoutUpdatedFilesInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutUpdatedFilesInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["BookingUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<ModelTypes["BookingUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyUserInputEnvelope"] | undefined,
	set?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BookingUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<ModelTypes["BookingUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	update: ModelTypes["BookingUpdateWithoutUserInput"],
	create: ModelTypes["BookingCreateWithoutUserInput"]
};
	["BookingUpdateWithoutUserInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["BusinessCategoryUpdateOneWithoutBookingsNestedInput"]: {
	create?: ModelTypes["BusinessCategoryCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: ModelTypes["BusinessCategoryUpsertWithoutBookingsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	connect?: ModelTypes["BusinessCategoryWhereUniqueInput"] | undefined,
	update?: ModelTypes["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["BusinessCategoryUpsertWithoutBookingsInput"]: {
	update: ModelTypes["BusinessCategoryUpdateWithoutBookingsInput"],
	create: ModelTypes["BusinessCategoryCreateWithoutBookingsInput"],
	where?: ModelTypes["BusinessCategoryWhereInput"] | undefined
};
	["BusinessCategoryUpdateWithoutBookingsInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["CampaignUpdateManyWithoutBusinessCategoryNestedInput"]: {
	create?: Array<ModelTypes["CampaignCreateWithoutBusinessCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"]> | undefined,
	upsert?: Array<ModelTypes["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined,
	createMany?: ModelTypes["CampaignCreateManyBusinessCategoryInputEnvelope"] | undefined,
	set?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined,
	updateMany?: Array<ModelTypes["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CampaignScalarWhereInput"]> | undefined
};
	["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	update: ModelTypes["CampaignUpdateWithoutBusinessCategoryInput"],
	create: ModelTypes["CampaignCreateWithoutBusinessCategoryInput"]
};
	["CampaignUpdateWithoutBusinessCategoryInput"]: {
	name?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: ModelTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutCampaignsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutCampaignsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutCampaignsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutCampaignsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutCampaignsInput"] | undefined
};
	["UserUpsertWithoutCampaignsInput"]: {
	update: ModelTypes["UserUpdateWithoutCampaignsInput"],
	create: ModelTypes["UserCreateWithoutCampaignsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutCampaignsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["FileUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutCreatedByInput"]> | undefined,
	upsert?: Array<ModelTypes["FileUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	createMany?: ModelTypes["FileCreateManyCreatedByInputEnvelope"] | undefined,
	set?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["FileUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	updateMany?: Array<ModelTypes["FileUpdateManyWithWhereWithoutCreatedByInput"]> | undefined,
	deleteMany?: Array<ModelTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	update: ModelTypes["FileUpdateWithoutCreatedByInput"],
	create: ModelTypes["FileCreateWithoutCreatedByInput"]
};
	["FileUpdateWithoutCreatedByInput"]: {
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: ModelTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: ModelTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: ModelTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["BookingUpdateManyWithoutAssetsNestedInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutAssetsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutAssetsInput"]> | undefined,
	upsert?: Array<ModelTypes["BookingUpsertWithWhereUniqueWithoutAssetsInput"]> | undefined,
	set?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BookingUpdateWithWhereUniqueWithoutAssetsInput"]> | undefined,
	updateMany?: Array<ModelTypes["BookingUpdateManyWithWhereWithoutAssetsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutAssetsInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	update: ModelTypes["BookingUpdateWithoutAssetsInput"],
	create: ModelTypes["BookingCreateWithoutAssetsInput"]
};
	["BookingUpdateWithoutAssetsInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"]: {
	create?: Array<ModelTypes["AdvertisementCategoryCreateWithoutBookingsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]> | undefined,
	upsert?: Array<ModelTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]> | undefined,
	set?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdvertisementCategoryScalarWhereInput"]> | undefined
};
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]: {
	where: ModelTypes["AdvertisementCategoryWhereUniqueInput"],
	update: ModelTypes["AdvertisementCategoryUpdateWithoutBookingsInput"],
	create: ModelTypes["AdvertisementCategoryCreateWithoutBookingsInput"]
};
	["AdvertisementCategoryUpdateWithoutBookingsInput"]: {
	name?: string | undefined,
	type?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"] | undefined
};
	["CampaignUpdateManyWithoutAdCategoryNestedInput"]: {
	create?: Array<ModelTypes["CampaignCreateWithoutAdCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CampaignCreateOrConnectWithoutAdCategoryInput"]> | undefined,
	upsert?: Array<ModelTypes["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]> | undefined,
	set?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]> | undefined,
	updateMany?: Array<ModelTypes["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CampaignScalarWhereInput"]> | undefined
};
	["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	update: ModelTypes["CampaignUpdateWithoutAdCategoryInput"],
	create: ModelTypes["CampaignCreateWithoutAdCategoryInput"]
};
	["CampaignUpdateWithoutAdCategoryInput"]: {
	name?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: ModelTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"]: {
	create?: ModelTypes["BusinessCategoryCreateWithoutCampaignsInput"] | undefined,
	connectOrCreate?: ModelTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"] | undefined,
	upsert?: ModelTypes["BusinessCategoryUpsertWithoutCampaignsInput"] | undefined,
	connect?: ModelTypes["BusinessCategoryWhereUniqueInput"] | undefined,
	update?: ModelTypes["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"] | undefined
};
	["BusinessCategoryUpsertWithoutCampaignsInput"]: {
	update: ModelTypes["BusinessCategoryUpdateWithoutCampaignsInput"],
	create: ModelTypes["BusinessCategoryCreateWithoutCampaignsInput"],
	where?: ModelTypes["BusinessCategoryWhereInput"] | undefined
};
	["BusinessCategoryUpdateWithoutCampaignsInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["BookingUpdateManyWithoutBusinessCategoryNestedInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutBusinessCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"]> | undefined,
	upsert?: Array<ModelTypes["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyBusinessCategoryInputEnvelope"] | undefined,
	set?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined,
	updateMany?: Array<ModelTypes["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	update: ModelTypes["BookingUpdateWithoutBusinessCategoryInput"],
	create: ModelTypes["BookingCreateWithoutBusinessCategoryInput"]
};
	["BookingUpdateWithoutBusinessCategoryInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["FileUpdateManyWithoutProofBookingNestedInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutProofBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutProofBookingInput"]> | undefined,
	upsert?: Array<ModelTypes["FileUpsertWithWhereUniqueWithoutProofBookingInput"]> | undefined,
	createMany?: ModelTypes["FileCreateManyProofBookingInputEnvelope"] | undefined,
	set?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["FileUpdateWithWhereUniqueWithoutProofBookingInput"]> | undefined,
	updateMany?: Array<ModelTypes["FileUpdateManyWithWhereWithoutProofBookingInput"]> | undefined,
	deleteMany?: Array<ModelTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutProofBookingInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	update: ModelTypes["FileUpdateWithoutProofBookingInput"],
	create: ModelTypes["FileCreateWithoutProofBookingInput"]
};
	["FileUpdateWithoutProofBookingInput"]: {
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: ModelTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: ModelTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutCreatedFilesInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutCreatedFilesInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutCreatedFilesInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutCreatedFilesInput"] | undefined
};
	["UserUpsertWithoutCreatedFilesInput"]: {
	update: ModelTypes["UserUpdateWithoutCreatedFilesInput"],
	create: ModelTypes["UserCreateWithoutCreatedFilesInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutCreatedFilesInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["FileUpdateManyWithoutUpdatedByNestedInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutUpdatedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutUpdatedByInput"]> | undefined,
	upsert?: Array<ModelTypes["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]> | undefined,
	createMany?: ModelTypes["FileCreateManyUpdatedByInputEnvelope"] | undefined,
	set?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]> | undefined,
	updateMany?: Array<ModelTypes["FileUpdateManyWithWhereWithoutUpdatedByInput"]> | undefined,
	deleteMany?: Array<ModelTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	update: ModelTypes["FileUpdateWithoutUpdatedByInput"],
	create: ModelTypes["FileCreateWithoutUpdatedByInput"]
};
	["FileUpdateWithoutUpdatedByInput"]: {
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: ModelTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	createdBy?: ModelTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: ModelTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutFilesNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutFilesInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutFilesInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutFilesInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["WorkspaceWhereInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutFilesInput"] | undefined
};
	["WorkspaceUpsertWithoutFilesInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutFilesInput"],
	create: ModelTypes["WorkspaceCreateWithoutFilesInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutFilesInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["UserUpdateOneWithoutOwnedWorkspacesNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutOwnedWorkspacesInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutOwnedWorkspacesInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["UserWhereInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"] | undefined
};
	["UserUpsertWithoutOwnedWorkspacesInput"]: {
	update: ModelTypes["UserUpdateWithoutOwnedWorkspacesInput"],
	create: ModelTypes["UserCreateWithoutOwnedWorkspacesInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutOwnedWorkspacesInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["OrderUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ModelTypes["OrderCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["OrderCreateOrConnectWithoutCreatedByInput"]> | undefined,
	upsert?: Array<ModelTypes["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	createMany?: ModelTypes["OrderCreateManyCreatedByInputEnvelope"] | undefined,
	set?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	updateMany?: Array<ModelTypes["OrderUpdateManyWithWhereWithoutCreatedByInput"]> | undefined,
	deleteMany?: Array<ModelTypes["OrderScalarWhereInput"]> | undefined
};
	["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ModelTypes["OrderWhereUniqueInput"],
	update: ModelTypes["OrderUpdateWithoutCreatedByInput"],
	create: ModelTypes["OrderCreateWithoutCreatedByInput"]
};
	["OrderUpdateWithoutCreatedByInput"]: {
	createdDate?: ModelTypes["DateTime"] | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDate?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutOrdersInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutOrdersInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutOrdersInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutOrdersInput"] | undefined
};
	["AdBoardUpsertWithoutOrdersInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutOrdersInput"],
	create: ModelTypes["AdBoardCreateWithoutOrdersInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutOrdersInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ModelTypes["SlotConfigurationCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<ModelTypes["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<ModelTypes["SlotConfigurationWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["SlotConfigurationWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["SlotConfigurationWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["SlotConfigurationWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<ModelTypes["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<ModelTypes["SlotConfigurationScalarWhereInput"]> | undefined
};
	["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["SlotConfigurationWhereUniqueInput"],
	update: ModelTypes["SlotConfigurationUpdateWithoutAdBoardInput"],
	create: ModelTypes["SlotConfigurationCreateWithoutAdBoardInput"]
};
	["SlotConfigurationUpdateWithoutAdBoardInput"]: {
	date?: ModelTypes["DateTime"] | undefined,
	availableSlots?: number | undefined
};
	["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["SlotConfigurationWhereUniqueInput"],
	data: ModelTypes["SlotConfigurationUpdateWithoutAdBoardInput"]
};
	["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ModelTypes["SlotConfigurationScalarWhereInput"],
	data: ModelTypes["SlotConfigurationUpdateManyMutationInput"]
};
	["SlotConfigurationScalarWhereInput"]: {
	AND?: Array<ModelTypes["SlotConfigurationScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["SlotConfigurationScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["SlotConfigurationScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["DateTimeFilter"] | undefined,
	availableSlots?: ModelTypes["IntFilter"] | undefined
};
	["SlotConfigurationUpdateManyMutationInput"]: {
	date?: ModelTypes["DateTime"] | undefined,
	availableSlots?: number | undefined
};
	["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutOfflineBookingAdboardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["UserWhereInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"] | undefined
};
	["UserUpsertWithoutOfflineBookingAdboardsInput"]: {
	update: ModelTypes["UserUpdateWithoutOfflineBookingAdboardsInput"],
	create: ModelTypes["UserCreateWithoutOfflineBookingAdboardsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutOfflineBookingAdboardsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["ChangelogUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ModelTypes["ChangelogCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["ChangelogCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<ModelTypes["ChangelogUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["ChangelogCreateManyUserInputEnvelope"] | undefined,
	set?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["ChangelogUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<ModelTypes["ChangelogUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<ModelTypes["ChangelogScalarWhereInput"]> | undefined
};
	["ChangelogUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["ChangelogWhereUniqueInput"],
	update: ModelTypes["ChangelogUpdateWithoutUserInput"],
	create: ModelTypes["ChangelogCreateWithoutUserInput"]
};
	["ChangelogUpdateWithoutUserInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	booking?: ModelTypes["BookingUpdateOneWithoutChangelogsNestedInput"] | undefined
};
	["BookingUpdateOneWithoutChangelogsNestedInput"]: {
	create?: ModelTypes["BookingCreateWithoutChangelogsInput"] | undefined,
	connectOrCreate?: ModelTypes["BookingCreateOrConnectWithoutChangelogsInput"] | undefined,
	upsert?: ModelTypes["BookingUpsertWithoutChangelogsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["BookingWhereInput"] | undefined,
	connect?: ModelTypes["BookingWhereUniqueInput"] | undefined,
	update?: ModelTypes["BookingUpdateToOneWithWhereWithoutChangelogsInput"] | undefined
};
	["BookingUpsertWithoutChangelogsInput"]: {
	update: ModelTypes["BookingUpdateWithoutChangelogsInput"],
	create: ModelTypes["BookingCreateWithoutChangelogsInput"],
	where?: ModelTypes["BookingWhereInput"] | undefined
};
	["BookingUpdateWithoutChangelogsInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutBookingsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutBookingsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["UserUpsertWithoutBookingsInput"]: {
	update: ModelTypes["UserUpdateWithoutBookingsInput"],
	create: ModelTypes["UserCreateWithoutBookingsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutBookingsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutCreatedByNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutCreatedByInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyCreatedByInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutCreatedByInput"],
	create: ModelTypes["AdBoardCreateWithoutCreatedByInput"]
};
	["AdBoardUpdateWithoutCreatedByInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["OrderUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ModelTypes["OrderCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["OrderCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<ModelTypes["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["OrderCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["OrderWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<ModelTypes["OrderUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<ModelTypes["OrderScalarWhereInput"]> | undefined
};
	["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["OrderWhereUniqueInput"],
	update: ModelTypes["OrderUpdateWithoutAdBoardInput"],
	create: ModelTypes["OrderCreateWithoutAdBoardInput"]
};
	["OrderUpdateWithoutAdBoardInput"]: {
	createdDate?: ModelTypes["DateTime"] | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDate?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutOrdersNestedInput"] | undefined
};
	["UserUpdateOneWithoutOrdersNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutOrdersInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutOrdersInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutOrdersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["UserWhereInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutOrdersInput"] | undefined
};
	["UserUpsertWithoutOrdersInput"]: {
	update: ModelTypes["UserUpdateWithoutOrdersInput"],
	create: ModelTypes["UserCreateWithoutOrdersInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutOrdersInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AuditUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ModelTypes["AuditCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AuditCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<ModelTypes["AuditUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["AuditCreateManyUserInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AuditUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<ModelTypes["AuditUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AuditScalarWhereInput"]> | undefined
};
	["AuditUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["AuditWhereUniqueInput"],
	update: ModelTypes["AuditUpdateWithoutUserInput"],
	create: ModelTypes["AuditCreateWithoutUserInput"]
};
	["AuditUpdateWithoutUserInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutAuditsNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutAuditsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutAuditsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutAuditsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["WorkspaceWhereInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"] | undefined
};
	["WorkspaceUpsertWithoutAuditsInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutAuditsInput"],
	create: ModelTypes["WorkspaceCreateWithoutAuditsInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutAuditsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["MembershipUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["MembershipCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["MembershipCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["MembershipCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["MembershipScalarWhereInput"]> | undefined
};
	["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	update: ModelTypes["MembershipUpdateWithoutWorkspaceInput"],
	create: ModelTypes["MembershipCreateWithoutWorkspaceInput"]
};
	["MembershipUpdateWithoutWorkspaceInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	role?: ModelTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutMembershipsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutMembershipsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutMembershipsInput"] | undefined
};
	["UserUpsertWithoutMembershipsInput"]: {
	update: ModelTypes["UserUpdateWithoutMembershipsInput"],
	create: ModelTypes["UserCreateWithoutMembershipsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutMembershipsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["CampaignUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ModelTypes["CampaignCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CampaignCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<ModelTypes["CampaignUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["CampaignCreateManyUserInputEnvelope"] | undefined,
	set?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CampaignUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<ModelTypes["CampaignUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CampaignScalarWhereInput"]> | undefined
};
	["CampaignUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	update: ModelTypes["CampaignUpdateWithoutUserInput"],
	create: ModelTypes["CampaignCreateWithoutUserInput"]
};
	["CampaignUpdateWithoutUserInput"]: {
	name?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: ModelTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"]: {
	create?: Array<ModelTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]> | undefined,
	upsert?: Array<ModelTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]> | undefined,
	set?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdvertisementCategoryScalarWhereInput"]> | undefined
};
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]: {
	where: ModelTypes["AdvertisementCategoryWhereUniqueInput"],
	update: ModelTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"],
	create: ModelTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]
};
	["AdvertisementCategoryUpdateWithoutCampaignsInput"]: {
	name?: string | undefined,
	type?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdCategoryNestedInput"] | undefined
};
	["BookingUpdateManyWithoutAdCategoryNestedInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutAdCategoryInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutAdCategoryInput"]> | undefined,
	upsert?: Array<ModelTypes["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]> | undefined,
	set?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]> | undefined,
	updateMany?: Array<ModelTypes["BookingUpdateManyWithWhereWithoutAdCategoryInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	update: ModelTypes["BookingUpdateWithoutAdCategoryInput"],
	create: ModelTypes["BookingCreateWithoutAdCategoryInput"]
};
	["BookingUpdateWithoutAdCategoryInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutBookingsInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["AdBoardUpsertWithoutBookingsInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutBookingsInput"],
	create: ModelTypes["AdBoardCreateWithoutBookingsInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutBookingsInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["UserUpdateOneWithoutAdBoardsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["UserWhereInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["UserUpsertWithoutAdBoardsInput"]: {
	update: ModelTypes["UserUpdateWithoutAdBoardsInput"],
	create: ModelTypes["UserCreateWithoutAdBoardsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutAdBoardsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["BookingUpdateManyWithoutSharedUsersNestedInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutSharedUsersInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutSharedUsersInput"]> | undefined,
	upsert?: Array<ModelTypes["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]> | undefined,
	set?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]> | undefined,
	updateMany?: Array<ModelTypes["BookingUpdateManyWithWhereWithoutSharedUsersInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	update: ModelTypes["BookingUpdateWithoutSharedUsersInput"],
	create: ModelTypes["BookingCreateWithoutSharedUsersInput"]
};
	["BookingUpdateWithoutSharedUsersInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["ChangelogUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ModelTypes["ChangelogCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["ChangelogCreateOrConnectWithoutBookingInput"]> | undefined,
	upsert?: Array<ModelTypes["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]> | undefined,
	createMany?: ModelTypes["ChangelogCreateManyBookingInputEnvelope"] | undefined,
	set?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["ChangelogWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]> | undefined,
	updateMany?: Array<ModelTypes["ChangelogUpdateManyWithWhereWithoutBookingInput"]> | undefined,
	deleteMany?: Array<ModelTypes["ChangelogScalarWhereInput"]> | undefined
};
	["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ModelTypes["ChangelogWhereUniqueInput"],
	update: ModelTypes["ChangelogUpdateWithoutBookingInput"],
	create: ModelTypes["ChangelogCreateWithoutBookingInput"]
};
	["ChangelogUpdateWithoutBookingInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutChangelogsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutChangelogsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutChangelogsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutChangelogsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutChangelogsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutChangelogsInput"] | undefined
};
	["UserUpsertWithoutChangelogsInput"]: {
	update: ModelTypes["UserUpdateWithoutChangelogsInput"],
	create: ModelTypes["UserCreateWithoutChangelogsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutChangelogsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["MembershipUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ModelTypes["MembershipCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["MembershipCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<ModelTypes["MembershipUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["MembershipCreateManyUserInputEnvelope"] | undefined,
	set?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["MembershipUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<ModelTypes["MembershipUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<ModelTypes["MembershipScalarWhereInput"]> | undefined
};
	["MembershipUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	update: ModelTypes["MembershipUpdateWithoutUserInput"],
	create: ModelTypes["MembershipCreateWithoutUserInput"]
};
	["MembershipUpdateWithoutUserInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	role?: ModelTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutMembershipsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"] | undefined
};
	["WorkspaceUpsertWithoutMembershipsInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutMembershipsInput"],
	create: ModelTypes["WorkspaceCreateWithoutMembershipsInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutMembershipsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutWorkspaceInput"],
	create: ModelTypes["AdBoardCreateWithoutWorkspaceInput"]
};
	["AdBoardUpdateWithoutWorkspaceInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["PlayerUpdateManyWithoutAdBoardsNestedInput"]: {
	create?: Array<ModelTypes["PlayerCreateWithoutAdBoardsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlayerCreateOrConnectWithoutAdBoardsInput"]> | undefined,
	upsert?: Array<ModelTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]> | undefined,
	set?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]> | undefined,
	updateMany?: Array<ModelTypes["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PlayerScalarWhereInput"]> | undefined
};
	["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	update: ModelTypes["PlayerUpdateWithoutAdBoardsInput"],
	create: ModelTypes["PlayerCreateWithoutAdBoardsInput"]
};
	["PlayerUpdateWithoutAdBoardsInput"]: {
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: ModelTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"]: {
	create?: ModelTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"] | undefined,
	upsert?: ModelTypes["AdBoardGroupUpsertWithoutPisignagePlayersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["AdBoardGroupWhereInput"] | undefined,
	connect?: ModelTypes["AdBoardGroupWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"] | undefined
};
	["AdBoardGroupUpsertWithoutPisignagePlayersInput"]: {
	update: ModelTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"],
	where?: ModelTypes["AdBoardGroupWhereInput"] | undefined
};
	["AdBoardGroupUpdateWithoutPisignagePlayersInput"]: {
	name?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutAdBoardGroupInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutAdBoardGroupInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["AdBoardWhereInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"] | undefined
};
	["AdBoardUpsertWithoutAdBoardGroupInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutAdBoardGroupInput"],
	create: ModelTypes["AdBoardCreateWithoutAdBoardGroupInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutAdBoardGroupInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutAdBoardsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["WorkspaceUpsertWithoutAdBoardsInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutAdBoardsInput"],
	create: ModelTypes["WorkspaceCreateWithoutAdBoardsInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutAdBoardsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["PlaylistUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["PlaylistCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["PlaylistCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PlaylistScalarWhereInput"]> | undefined
};
	["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	update: ModelTypes["PlaylistUpdateWithoutWorkspaceInput"],
	create: ModelTypes["PlaylistCreateWithoutWorkspaceInput"]
};
	["PlaylistUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"]: {
	create?: ModelTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | undefined,
	connectOrCreate?: ModelTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"] | undefined,
	upsert?: ModelTypes["PlaylistUpsertWithoutFooterParentPlaylistsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["PlaylistWhereInput"] | undefined,
	connect?: ModelTypes["PlaylistWhereUniqueInput"] | undefined,
	update?: ModelTypes["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"] | undefined
};
	["PlaylistUpsertWithoutFooterParentPlaylistsInput"]: {
	update: ModelTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"],
	create: ModelTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"],
	where?: ModelTypes["PlaylistWhereInput"] | undefined
};
	["PlaylistUpdateWithoutFooterParentPlaylistsInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"]: {
	create?: ModelTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | undefined,
	connectOrCreate?: ModelTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"] | undefined,
	upsert?: ModelTypes["PlaylistUpsertWithoutSidebarParentPlaylistsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["PlaylistWhereInput"] | undefined,
	connect?: ModelTypes["PlaylistWhereUniqueInput"] | undefined,
	update?: ModelTypes["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"] | undefined
};
	["PlaylistUpsertWithoutSidebarParentPlaylistsInput"]: {
	update: ModelTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"],
	create: ModelTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"],
	where?: ModelTypes["PlaylistWhereInput"] | undefined
};
	["PlaylistUpdateWithoutSidebarParentPlaylistsInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined
};
	["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"]: {
	create?: Array<ModelTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]> | undefined,
	createMany?: ModelTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardGroupScalarWhereInput"]> | undefined
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	update: ModelTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupUpdateWithoutAdsPlaylistInput"]: {
	name?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"]: {
	create?: Array<ModelTypes["PlaylistCreateWithoutAdBoardGroupsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]> | undefined,
	upsert?: Array<ModelTypes["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]> | undefined,
	set?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]> | undefined,
	updateMany?: Array<ModelTypes["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PlaylistScalarWhereInput"]> | undefined
};
	["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	update: ModelTypes["PlaylistUpdateWithoutAdBoardGroupsInput"],
	create: ModelTypes["PlaylistCreateWithoutAdBoardGroupsInput"]
};
	["PlaylistUpdateWithoutAdBoardGroupsInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutPlaylistsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutPlaylistsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"] | undefined
};
	["WorkspaceUpsertWithoutPlaylistsInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutPlaylistsInput"],
	create: ModelTypes["WorkspaceCreateWithoutPlaylistsInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutPlaylistsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["AdBoardGroupCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardGroupScalarWhereInput"]> | undefined
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	update: ModelTypes["AdBoardGroupUpdateWithoutWorkspaceInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutWorkspaceInput"]
};
	["AdBoardGroupUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined
};
	["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"]: {
	create?: ModelTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | undefined,
	connectOrCreate?: ModelTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"] | undefined,
	upsert?: ModelTypes["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["PlaylistWhereInput"] | undefined,
	connect?: ModelTypes["PlaylistWhereUniqueInput"] | undefined,
	update?: ModelTypes["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"] | undefined
};
	["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"]: {
	update: ModelTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"],
	create: ModelTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"],
	where?: ModelTypes["PlaylistWhereInput"] | undefined
};
	["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"]: {
	create?: Array<ModelTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]> | undefined,
	set?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardGroupScalarWhereInput"]> | undefined
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	update: ModelTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]
};
	["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"]: {
	name?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["PlayerUpdateManyWithoutAdBoardGroupNestedInput"]: {
	create?: Array<ModelTypes["PlayerCreateWithoutAdBoardGroupInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"]> | undefined,
	upsert?: Array<ModelTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]> | undefined,
	createMany?: ModelTypes["PlayerCreateManyAdBoardGroupInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]> | undefined,
	updateMany?: Array<ModelTypes["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PlayerScalarWhereInput"]> | undefined
};
	["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	update: ModelTypes["PlayerUpdateWithoutAdBoardGroupInput"],
	create: ModelTypes["PlayerCreateWithoutAdBoardGroupInput"]
};
	["PlayerUpdateWithoutAdBoardGroupInput"]: {
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: ModelTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutPlayersNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutPlayersInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutPlayersInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutPlayersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["WorkspaceWhereInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"] | undefined
};
	["WorkspaceUpsertWithoutPlayersInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutPlayersInput"],
	create: ModelTypes["WorkspaceCreateWithoutPlayersInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutPlayersInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["AuditUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["AuditCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AuditCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["AuditCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AuditWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["AuditUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AuditScalarWhereInput"]> | undefined
};
	["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["AuditWhereUniqueInput"],
	update: ModelTypes["AuditUpdateWithoutWorkspaceInput"],
	create: ModelTypes["AuditCreateWithoutWorkspaceInput"]
};
	["AuditUpdateWithoutWorkspaceInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutAuditsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutAuditsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutAuditsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutAuditsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutAuditsInput"] | undefined
};
	["UserUpsertWithoutAuditsInput"]: {
	update: ModelTypes["UserUpdateWithoutAuditsInput"],
	create: ModelTypes["UserCreateWithoutAuditsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutAuditsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["NotificationUpdateManyWithoutRecipientNestedInput"]: {
	create?: Array<ModelTypes["NotificationCreateWithoutRecipientInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["NotificationCreateOrConnectWithoutRecipientInput"]> | undefined,
	upsert?: Array<ModelTypes["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]> | undefined,
	createMany?: ModelTypes["NotificationCreateManyRecipientInputEnvelope"] | undefined,
	set?: Array<ModelTypes["NotificationWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["NotificationWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["NotificationWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["NotificationWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]> | undefined,
	updateMany?: Array<ModelTypes["NotificationUpdateManyWithWhereWithoutRecipientInput"]> | undefined,
	deleteMany?: Array<ModelTypes["NotificationScalarWhereInput"]> | undefined
};
	["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]: {
	where: ModelTypes["NotificationWhereUniqueInput"],
	update: ModelTypes["NotificationUpdateWithoutRecipientInput"],
	create: ModelTypes["NotificationCreateWithoutRecipientInput"]
};
	["NotificationUpdateWithoutRecipientInput"]: {
	type?: ModelTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined
};
	["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]: {
	where: ModelTypes["NotificationWhereUniqueInput"],
	data: ModelTypes["NotificationUpdateWithoutRecipientInput"]
};
	["NotificationUpdateManyWithWhereWithoutRecipientInput"]: {
	where: ModelTypes["NotificationScalarWhereInput"],
	data: ModelTypes["NotificationUpdateManyMutationInput"]
};
	["NotificationScalarWhereInput"]: {
	AND?: Array<ModelTypes["NotificationScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["NotificationScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["NotificationScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumNotificationTypeFilter"] | undefined,
	title?: ModelTypes["StringFilter"] | undefined,
	content?: ModelTypes["StringFilter"] | undefined,
	metadata?: ModelTypes["JsonNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	readAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	recipientId?: ModelTypes["StringFilter"] | undefined,
	channels?: ModelTypes["EnumNotificationChannelNullableListFilter"] | undefined
};
	["NotificationUpdateManyMutationInput"]: {
	type?: ModelTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined
};
	["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"]: {
	create?: Array<ModelTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]> | undefined,
	createMany?: ModelTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined
};
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]: {
	where: ModelTypes["AdBoardPublishHistoryWhereUniqueInput"],
	update: ModelTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"],
	create: ModelTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryUpdateWithoutPublishedByInput"]: {
	publishedAt?: ModelTypes["DateTime"] | undefined,
	changesSnapshot?: ModelTypes["JSON"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutHistoryInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutHistoryInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutHistoryInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutHistoryInput"] | undefined
};
	["AdBoardUpsertWithoutHistoryInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutHistoryInput"],
	create: ModelTypes["AdBoardCreateWithoutHistoryInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutHistoryInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"]: {
	create?: ModelTypes["AdBoardGroupCreateWithoutAdBoardInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"] | undefined,
	upsert?: ModelTypes["AdBoardGroupUpsertWithoutAdBoardInput"] | undefined,
	disconnect?: ModelTypes["AdBoardGroupWhereInput"] | undefined,
	delete?: ModelTypes["AdBoardGroupWhereInput"] | undefined,
	connect?: ModelTypes["AdBoardGroupWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"] | undefined
};
	["AdBoardGroupUpsertWithoutAdBoardInput"]: {
	update: ModelTypes["AdBoardGroupUpdateWithoutAdBoardInput"],
	create: ModelTypes["AdBoardGroupCreateWithoutAdBoardInput"],
	where?: ModelTypes["AdBoardGroupWhereInput"] | undefined
};
	["AdBoardGroupUpdateWithoutAdBoardInput"]: {
	name?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutAdBoardGroupsInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"] | undefined
};
	["WorkspaceUpsertWithoutAdBoardGroupsInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"],
	create: ModelTypes["WorkspaceCreateWithoutAdBoardGroupsInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutAdBoardGroupsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["BookingUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["BookingUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	update: ModelTypes["BookingUpdateWithoutWorkspaceInput"],
	create: ModelTypes["BookingCreateWithoutWorkspaceInput"]
};
	["BookingUpdateWithoutWorkspaceInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["CampaignUpdateOneWithoutBookingsNestedInput"]: {
	create?: ModelTypes["CampaignCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["CampaignCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: ModelTypes["CampaignUpsertWithoutBookingsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["CampaignWhereInput"] | undefined,
	connect?: ModelTypes["CampaignWhereUniqueInput"] | undefined,
	update?: ModelTypes["CampaignUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["CampaignUpsertWithoutBookingsInput"]: {
	update: ModelTypes["CampaignUpdateWithoutBookingsInput"],
	create: ModelTypes["CampaignCreateWithoutBookingsInput"],
	where?: ModelTypes["CampaignWhereInput"] | undefined
};
	["CampaignUpdateWithoutBookingsInput"]: {
	name?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	payments?: ModelTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["PaymentUpdateManyWithoutCampaignNestedInput"]: {
	create?: Array<ModelTypes["PaymentCreateWithoutCampaignInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PaymentCreateOrConnectWithoutCampaignInput"]> | undefined,
	upsert?: Array<ModelTypes["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]> | undefined,
	createMany?: ModelTypes["PaymentCreateManyCampaignInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PaymentWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PaymentWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PaymentWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PaymentWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]> | undefined,
	updateMany?: Array<ModelTypes["PaymentUpdateManyWithWhereWithoutCampaignInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PaymentScalarWhereInput"]> | undefined
};
	["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]: {
	where: ModelTypes["PaymentWhereUniqueInput"],
	update: ModelTypes["PaymentUpdateWithoutCampaignInput"],
	create: ModelTypes["PaymentCreateWithoutCampaignInput"]
};
	["PaymentUpdateWithoutCampaignInput"]: {
	paymentIntentId?: string | undefined,
	checkoutSessionId?: string | undefined,
	eventId?: string | undefined,
	liveMode?: boolean | undefined,
	paymentGateway?: ModelTypes["PaymentGatewayName"] | undefined,
	subtotal?: number | undefined,
	total?: number | undefined,
	tax?: number | undefined,
	discount?: number | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]: {
	where: ModelTypes["PaymentWhereUniqueInput"],
	data: ModelTypes["PaymentUpdateWithoutCampaignInput"]
};
	["PaymentUpdateManyWithWhereWithoutCampaignInput"]: {
	where: ModelTypes["PaymentScalarWhereInput"],
	data: ModelTypes["PaymentUpdateManyMutationInput"]
};
	["PaymentScalarWhereInput"]: {
	AND?: Array<ModelTypes["PaymentScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PaymentScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PaymentScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	paymentIntentId?: ModelTypes["StringFilter"] | undefined,
	checkoutSessionId?: ModelTypes["StringFilter"] | undefined,
	eventId?: ModelTypes["StringFilter"] | undefined,
	liveMode?: ModelTypes["BoolFilter"] | undefined,
	paymentGateway?: ModelTypes["EnumPaymentGatewayNameFilter"] | undefined,
	subtotal?: ModelTypes["FloatFilter"] | undefined,
	total?: ModelTypes["FloatFilter"] | undefined,
	tax?: ModelTypes["FloatFilter"] | undefined,
	discount?: ModelTypes["FloatFilter"] | undefined,
	campaignId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["PaymentUpdateManyMutationInput"]: {
	paymentIntentId?: string | undefined,
	checkoutSessionId?: string | undefined,
	eventId?: string | undefined,
	liveMode?: boolean | undefined,
	paymentGateway?: ModelTypes["PaymentGatewayName"] | undefined,
	subtotal?: number | undefined,
	total?: number | undefined,
	tax?: number | undefined,
	discount?: number | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["FileUpdateManyWithoutCampaignsNestedInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutCampaignsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutCampaignsInput"]> | undefined,
	upsert?: Array<ModelTypes["FileUpsertWithWhereUniqueWithoutCampaignsInput"]> | undefined,
	set?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["FileUpdateWithWhereUniqueWithoutCampaignsInput"]> | undefined,
	updateMany?: Array<ModelTypes["FileUpdateManyWithWhereWithoutCampaignsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutCampaignsInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	update: ModelTypes["FileUpdateWithoutCampaignsInput"],
	create: ModelTypes["FileCreateWithoutCampaignsInput"]
};
	["FileUpdateWithoutCampaignsInput"]: {
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: ModelTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: ModelTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: ModelTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined
};
	["BookingUpdateOneWithoutProofsNestedInput"]: {
	create?: ModelTypes["BookingCreateWithoutProofsInput"] | undefined,
	connectOrCreate?: ModelTypes["BookingCreateOrConnectWithoutProofsInput"] | undefined,
	upsert?: ModelTypes["BookingUpsertWithoutProofsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["BookingWhereInput"] | undefined,
	connect?: ModelTypes["BookingWhereUniqueInput"] | undefined,
	update?: ModelTypes["BookingUpdateToOneWithWhereWithoutProofsInput"] | undefined
};
	["BookingUpsertWithoutProofsInput"]: {
	update: ModelTypes["BookingUpdateWithoutProofsInput"],
	create: ModelTypes["BookingCreateWithoutProofsInput"],
	where?: ModelTypes["BookingWhereInput"] | undefined
};
	["BookingUpdateWithoutProofsInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutBookingsNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutBookingsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["WorkspaceWhereInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["WorkspaceUpsertWithoutBookingsInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutBookingsInput"],
	create: ModelTypes["WorkspaceCreateWithoutBookingsInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutBookingsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["FileUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["FileCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["FileCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["FileCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["FileUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	update: ModelTypes["FileUpdateWithoutWorkspaceInput"],
	create: ModelTypes["FileCreateWithoutWorkspaceInput"]
};
	["FileUpdateWithoutWorkspaceInput"]: {
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: ModelTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: ModelTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	proofBooking?: ModelTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["CampaignUpdateManyWithoutAssetsNestedInput"]: {
	create?: Array<ModelTypes["CampaignCreateWithoutAssetsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CampaignCreateOrConnectWithoutAssetsInput"]> | undefined,
	upsert?: Array<ModelTypes["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]> | undefined,
	set?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CampaignWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]> | undefined,
	updateMany?: Array<ModelTypes["CampaignUpdateManyWithWhereWithoutAssetsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CampaignScalarWhereInput"]> | undefined
};
	["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	update: ModelTypes["CampaignUpdateWithoutAssetsInput"],
	create: ModelTypes["CampaignCreateWithoutAssetsInput"]
};
	["CampaignUpdateWithoutAssetsInput"]: {
	name?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: ModelTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined
};
	["BookingUpdateManyWithoutCampaignNestedInput"]: {
	create?: Array<ModelTypes["BookingCreateWithoutCampaignInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["BookingCreateOrConnectWithoutCampaignInput"]> | undefined,
	upsert?: Array<ModelTypes["BookingUpsertWithWhereUniqueWithoutCampaignInput"]> | undefined,
	createMany?: ModelTypes["BookingCreateManyCampaignInputEnvelope"] | undefined,
	set?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["BookingUpdateWithWhereUniqueWithoutCampaignInput"]> | undefined,
	updateMany?: Array<ModelTypes["BookingUpdateManyWithWhereWithoutCampaignInput"]> | undefined,
	deleteMany?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutCampaignInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	update: ModelTypes["BookingUpdateWithoutCampaignInput"],
	create: ModelTypes["BookingCreateWithoutCampaignInput"]
};
	["BookingUpdateWithoutCampaignInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["PayoutUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ModelTypes["PayoutCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PayoutCreateOrConnectWithoutBookingInput"]> | undefined,
	upsert?: Array<ModelTypes["PayoutUpsertWithWhereUniqueWithoutBookingInput"]> | undefined,
	createMany?: ModelTypes["PayoutCreateManyBookingInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PayoutUpdateWithWhereUniqueWithoutBookingInput"]> | undefined,
	updateMany?: Array<ModelTypes["PayoutUpdateManyWithWhereWithoutBookingInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PayoutScalarWhereInput"]> | undefined
};
	["PayoutUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ModelTypes["PayoutWhereUniqueInput"],
	update: ModelTypes["PayoutUpdateWithoutBookingInput"],
	create: ModelTypes["PayoutCreateWithoutBookingInput"]
};
	["PayoutUpdateWithoutBookingInput"]: {
	totalAmount?: number | undefined,
	commissionRate?: number | undefined,
	commissionAmount?: number | undefined,
	netAmount?: number | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	status?: ModelTypes["PayoutStatus"] | undefined,
	transaction?: ModelTypes["TransactionUpdateOneWithoutPayoutsNestedInput"] | undefined
};
	["TransactionUpdateOneWithoutPayoutsNestedInput"]: {
	create?: ModelTypes["TransactionCreateWithoutPayoutsInput"] | undefined,
	connectOrCreate?: ModelTypes["TransactionCreateOrConnectWithoutPayoutsInput"] | undefined,
	upsert?: ModelTypes["TransactionUpsertWithoutPayoutsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["TransactionWhereInput"] | undefined,
	connect?: ModelTypes["TransactionWhereUniqueInput"] | undefined,
	update?: ModelTypes["TransactionUpdateToOneWithWhereWithoutPayoutsInput"] | undefined
};
	["TransactionUpsertWithoutPayoutsInput"]: {
	update: ModelTypes["TransactionUpdateWithoutPayoutsInput"],
	create: ModelTypes["TransactionCreateWithoutPayoutsInput"],
	where?: ModelTypes["TransactionWhereInput"] | undefined
};
	["TransactionUpdateWithoutPayoutsInput"]: {
	amount?: number | undefined,
	createAt?: ModelTypes["DateTime"] | undefined,
	processedAt?: ModelTypes["DateTime"] | undefined,
	relatedEntityId?: string | undefined,
	transactionStatus?: ModelTypes["TransactionStatus"] | undefined,
	details?: ModelTypes["JSON"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutTransactionsNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutTransactionsNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutTransactionsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutTransactionsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["WorkspaceWhereInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"] | undefined
};
	["WorkspaceUpsertWithoutTransactionsInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutTransactionsInput"],
	create: ModelTypes["WorkspaceCreateWithoutTransactionsInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutTransactionsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["PlayerUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["PlayerCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlayerCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["PlayerCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PlayerWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PlayerScalarWhereInput"]> | undefined
};
	["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	update: ModelTypes["PlayerUpdateWithoutWorkspaceInput"],
	create: ModelTypes["PlayerCreateWithoutWorkspaceInput"]
};
	["PlayerUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: ModelTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"]: {
	create?: Array<ModelTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]> | undefined,
	upsert?: Array<ModelTypes["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]> | undefined,
	createMany?: ModelTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]> | undefined,
	updateMany?: Array<ModelTypes["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined
};
	["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]: {
	where: ModelTypes["PlayerUptimeStatusWhereUniqueInput"],
	update: ModelTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"],
	create: ModelTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]
};
	["PlayerUptimeStatusUpdateWithoutPlayerInput"]: {
	cpuSerialNumber?: string | undefined,
	date?: string | undefined,
	uptime?: number | undefined
};
	["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]: {
	where: ModelTypes["PlayerUptimeStatusWhereUniqueInput"],
	data: ModelTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"]
};
	["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]: {
	where: ModelTypes["PlayerUptimeStatusScalarWhereInput"],
	data: ModelTypes["PlayerUptimeStatusUpdateManyMutationInput"]
};
	["PlayerUptimeStatusScalarWhereInput"]: {
	AND?: Array<ModelTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	playerId?: ModelTypes["StringFilter"] | undefined,
	cpuSerialNumber?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["StringFilter"] | undefined,
	uptime?: ModelTypes["FloatFilter"] | undefined
};
	["PlayerUptimeStatusUpdateManyMutationInput"]: {
	cpuSerialNumber?: string | undefined,
	date?: string | undefined,
	uptime?: number | undefined
};
	["CameraUpdateManyWithoutPlayerNestedInput"]: {
	create?: Array<ModelTypes["CameraCreateWithoutPlayerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraCreateOrConnectWithoutPlayerInput"]> | undefined,
	upsert?: Array<ModelTypes["CameraUpsertWithWhereUniqueWithoutPlayerInput"]> | undefined,
	createMany?: ModelTypes["CameraCreateManyPlayerInputEnvelope"] | undefined,
	set?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CameraUpdateWithWhereUniqueWithoutPlayerInput"]> | undefined,
	updateMany?: Array<ModelTypes["CameraUpdateManyWithWhereWithoutPlayerInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CameraScalarWhereInput"]> | undefined
};
	["CameraUpsertWithWhereUniqueWithoutPlayerInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	update: ModelTypes["CameraUpdateWithoutPlayerInput"],
	create: ModelTypes["CameraCreateWithoutPlayerInput"]
};
	["CameraUpdateWithoutPlayerInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer?: ModelTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined,
	feeds?: ModelTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined
};
	["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"]: {
	create?: ModelTypes["CameraServerCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: ModelTypes["CameraServerCreateOrConnectWithoutCamerasInput"] | undefined,
	upsert?: ModelTypes["CameraServerUpsertWithoutCamerasInput"] | undefined,
	connect?: ModelTypes["CameraServerWhereUniqueInput"] | undefined,
	update?: ModelTypes["CameraServerUpdateToOneWithWhereWithoutCamerasInput"] | undefined
};
	["CameraServerUpsertWithoutCamerasInput"]: {
	update: ModelTypes["CameraServerUpdateWithoutCamerasInput"],
	create: ModelTypes["CameraServerCreateWithoutCamerasInput"],
	where?: ModelTypes["CameraServerWhereInput"] | undefined
};
	["CameraServerUpdateWithoutCamerasInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined,
	feeds?: ModelTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutCameraServersNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutCameraServersInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutCameraServersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["WorkspaceWhereInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"] | undefined
};
	["WorkspaceUpsertWithoutCameraServersInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutCameraServersInput"],
	create: ModelTypes["WorkspaceCreateWithoutCameraServersInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutCameraServersInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["TransactionUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["TransactionCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["TransactionCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["TransactionCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["TransactionWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["TransactionWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["TransactionWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["TransactionWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["TransactionScalarWhereInput"]> | undefined
};
	["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["TransactionWhereUniqueInput"],
	update: ModelTypes["TransactionUpdateWithoutWorkspaceInput"],
	create: ModelTypes["TransactionCreateWithoutWorkspaceInput"]
};
	["TransactionUpdateWithoutWorkspaceInput"]: {
	amount?: number | undefined,
	createAt?: ModelTypes["DateTime"] | undefined,
	processedAt?: ModelTypes["DateTime"] | undefined,
	relatedEntityId?: string | undefined,
	transactionStatus?: ModelTypes["TransactionStatus"] | undefined,
	details?: ModelTypes["JSON"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutTransactionNestedInput"] | undefined
};
	["PayoutUpdateManyWithoutTransactionNestedInput"]: {
	create?: Array<ModelTypes["PayoutCreateWithoutTransactionInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PayoutCreateOrConnectWithoutTransactionInput"]> | undefined,
	upsert?: Array<ModelTypes["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]> | undefined,
	createMany?: ModelTypes["PayoutCreateManyTransactionInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PayoutWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]> | undefined,
	updateMany?: Array<ModelTypes["PayoutUpdateManyWithWhereWithoutTransactionInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PayoutScalarWhereInput"]> | undefined
};
	["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]: {
	where: ModelTypes["PayoutWhereUniqueInput"],
	update: ModelTypes["PayoutUpdateWithoutTransactionInput"],
	create: ModelTypes["PayoutCreateWithoutTransactionInput"]
};
	["PayoutUpdateWithoutTransactionInput"]: {
	totalAmount?: number | undefined,
	commissionRate?: number | undefined,
	commissionAmount?: number | undefined,
	netAmount?: number | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	status?: ModelTypes["PayoutStatus"] | undefined,
	booking?: ModelTypes["BookingUpdateOneRequiredWithoutPayoutsNestedInput"] | undefined
};
	["BookingUpdateOneRequiredWithoutPayoutsNestedInput"]: {
	create?: ModelTypes["BookingCreateWithoutPayoutsInput"] | undefined,
	connectOrCreate?: ModelTypes["BookingCreateOrConnectWithoutPayoutsInput"] | undefined,
	upsert?: ModelTypes["BookingUpsertWithoutPayoutsInput"] | undefined,
	connect?: ModelTypes["BookingWhereUniqueInput"] | undefined,
	update?: ModelTypes["BookingUpdateToOneWithWhereWithoutPayoutsInput"] | undefined
};
	["BookingUpsertWithoutPayoutsInput"]: {
	update: ModelTypes["BookingUpdateWithoutPayoutsInput"],
	create: ModelTypes["BookingCreateWithoutPayoutsInput"],
	where?: ModelTypes["BookingWhereInput"] | undefined
};
	["BookingUpdateWithoutPayoutsInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["UserUpdateManyWithoutSharedBookingsNestedInput"]: {
	create?: Array<ModelTypes["UserCreateWithoutSharedBookingsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["UserCreateOrConnectWithoutSharedBookingsInput"]> | undefined,
	upsert?: Array<ModelTypes["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]> | undefined,
	set?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]> | undefined,
	updateMany?: Array<ModelTypes["UserUpdateManyWithWhereWithoutSharedBookingsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["UserScalarWhereInput"]> | undefined
};
	["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	update: ModelTypes["UserUpdateWithoutSharedBookingsInput"],
	create: ModelTypes["UserCreateWithoutSharedBookingsInput"]
};
	["UserUpdateWithoutSharedBookingsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"],
	create: ModelTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]
};
	["AdBoardUpdateWithoutOfflineBookingsContactInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["CameraUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ModelTypes["CameraCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<ModelTypes["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["CameraCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<ModelTypes["CameraUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CameraScalarWhereInput"]> | undefined
};
	["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	update: ModelTypes["CameraUpdateWithoutAdBoardInput"],
	create: ModelTypes["CameraCreateWithoutAdBoardInput"]
};
	["CameraUpdateWithoutAdBoardInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer?: ModelTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined,
	player?: ModelTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined,
	feeds?: ModelTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined
};
	["PlayerUpdateOneWithoutCamerasNestedInput"]: {
	create?: ModelTypes["PlayerCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: ModelTypes["PlayerCreateOrConnectWithoutCamerasInput"] | undefined,
	upsert?: ModelTypes["PlayerUpsertWithoutCamerasInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["PlayerWhereInput"] | undefined,
	connect?: ModelTypes["PlayerWhereUniqueInput"] | undefined,
	update?: ModelTypes["PlayerUpdateToOneWithWhereWithoutCamerasInput"] | undefined
};
	["PlayerUpsertWithoutCamerasInput"]: {
	update: ModelTypes["PlayerUpdateWithoutCamerasInput"],
	create: ModelTypes["PlayerCreateWithoutCamerasInput"],
	where?: ModelTypes["PlayerWhereInput"] | undefined
};
	["PlayerUpdateWithoutCamerasInput"]: {
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: ModelTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["DigitalSignageUpdateOneWithoutPlayersNestedInput"]: {
	create?: ModelTypes["DigitalSignageCreateWithoutPlayersInput"] | undefined,
	connectOrCreate?: ModelTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"] | undefined,
	upsert?: ModelTypes["DigitalSignageUpsertWithoutPlayersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["DigitalSignageWhereInput"] | undefined,
	connect?: ModelTypes["DigitalSignageWhereUniqueInput"] | undefined,
	update?: ModelTypes["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"] | undefined
};
	["DigitalSignageUpsertWithoutPlayersInput"]: {
	update: ModelTypes["DigitalSignageUpdateWithoutPlayersInput"],
	create: ModelTypes["DigitalSignageCreateWithoutPlayersInput"],
	where?: ModelTypes["DigitalSignageWhereInput"] | undefined
};
	["DigitalSignageUpdateWithoutPlayersInput"]: {
	type?: ModelTypes["SignageType"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"]: {
	where?: ModelTypes["DigitalSignageWhereInput"] | undefined,
	data: ModelTypes["DigitalSignageUpdateWithoutPlayersInput"]
};
	["AudienceUpdateManyWithoutPlayersNestedInput"]: {
	create?: Array<ModelTypes["AudienceCreateWithoutPlayersInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AudienceCreateOrConnectWithoutPlayersInput"]> | undefined,
	upsert?: Array<ModelTypes["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]> | undefined,
	set?: Array<ModelTypes["AudienceWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AudienceWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AudienceWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AudienceWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]> | undefined,
	updateMany?: Array<ModelTypes["AudienceUpdateManyWithWhereWithoutPlayersInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AudienceScalarWhereInput"]> | undefined
};
	["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]: {
	where: ModelTypes["AudienceWhereUniqueInput"],
	update: ModelTypes["AudienceUpdateWithoutPlayersInput"],
	create: ModelTypes["AudienceCreateWithoutPlayersInput"]
};
	["AudienceUpdateWithoutPlayersInput"]: {
	type?: ModelTypes["AudienceType"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]: {
	where: ModelTypes["AudienceWhereUniqueInput"],
	data: ModelTypes["AudienceUpdateWithoutPlayersInput"]
};
	["AudienceUpdateManyWithWhereWithoutPlayersInput"]: {
	where: ModelTypes["AudienceScalarWhereInput"],
	data: ModelTypes["AudienceUpdateManyMutationInput"]
};
	["AudienceScalarWhereInput"]: {
	AND?: Array<ModelTypes["AudienceScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AudienceScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AudienceScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumAudienceTypeFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	playerIDs?: ModelTypes["StringNullableListFilter"] | undefined
};
	["AudienceUpdateManyMutationInput"]: {
	type?: ModelTypes["AudienceType"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AdBoardUpdateManyWithoutPlayersNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutPlayersInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutPlayersInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]> | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutPlayersInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutPlayersInput"],
	create: ModelTypes["AdBoardCreateWithoutPlayersInput"]
};
	["AdBoardUpdateWithoutPlayersInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardUpdateOneWithoutDraftAdboardNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutDraftAdboardInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutDraftAdboardInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["AdBoardWhereInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"] | undefined
};
	["AdBoardUpsertWithoutDraftAdboardInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutDraftAdboardInput"],
	create: ModelTypes["AdBoardCreateWithoutDraftAdboardInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutDraftAdboardInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ModelTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined
};
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardPublishHistoryWhereUniqueInput"],
	update: ModelTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"],
	create: ModelTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryUpdateWithoutAdBoardInput"]: {
	publishedAt?: ModelTypes["DateTime"] | undefined,
	changesSnapshot?: ModelTypes["JSON"] | undefined,
	publishedBy?: ModelTypes["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutPublishAdboardVersionInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutPublishAdboardVersionInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"] | undefined
};
	["UserUpsertWithoutPublishAdboardVersionInput"]: {
	update: ModelTypes["UserUpdateWithoutPublishAdboardVersionInput"],
	create: ModelTypes["UserCreateWithoutPublishAdboardVersionInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutPublishAdboardVersionInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ModelTypes["AdBoardReviewCommentCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined
};
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["AdBoardReviewCommentWhereUniqueInput"],
	update: ModelTypes["AdBoardReviewCommentUpdateWithoutUserInput"],
	create: ModelTypes["AdBoardReviewCommentCreateWithoutUserInput"]
};
	["AdBoardReviewCommentUpdateWithoutUserInput"]: {
	comment?: string | undefined,
	action?: ModelTypes["ReviewAction"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutReviewCommentsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutReviewCommentsInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"] | undefined
};
	["AdBoardUpsertWithoutReviewCommentsInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutReviewCommentsInput"],
	create: ModelTypes["AdBoardCreateWithoutReviewCommentsInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutReviewCommentsInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardUpdateOneWithoutPublishedVersionNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutPublishedVersionInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutPublishedVersionInput"] | undefined,
	disconnect?: ModelTypes["AdBoardWhereInput"] | undefined,
	delete?: ModelTypes["AdBoardWhereInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"] | undefined
};
	["AdBoardUpsertWithoutPublishedVersionInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutPublishedVersionInput"],
	create: ModelTypes["AdBoardCreateWithoutPublishedVersionInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutPublishedVersionInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ModelTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined
};
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardReviewCommentWhereUniqueInput"],
	update: ModelTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"],
	create: ModelTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentUpdateWithoutAdBoardInput"]: {
	comment?: string | undefined,
	action?: ModelTypes["ReviewAction"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutReviewCommentsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutReviewCommentsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutReviewCommentsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutReviewCommentsInput"] | undefined
};
	["UserUpsertWithoutReviewCommentsInput"]: {
	update: ModelTypes["UserUpdateWithoutReviewCommentsInput"],
	create: ModelTypes["UserCreateWithoutReviewCommentsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutReviewCommentsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutArchivedByNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutArchivedByInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutArchivedByInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyArchivedByInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutArchivedByInput"],
	create: ModelTypes["AdBoardCreateWithoutArchivedByInput"]
};
	["AdBoardUpdateWithoutArchivedByInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"]: {
	create?: Array<ModelTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: ModelTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined
};
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardPublicReviewWhereUniqueInput"],
	update: ModelTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"],
	create: ModelTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewUpdateWithoutAdBoardInput"]: {
	rating?: number | undefined,
	comment?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutPublicReviewsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutPublicReviewsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutPublicReviewsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutPublicReviewsInput"] | undefined
};
	["UserUpsertWithoutPublicReviewsInput"]: {
	update: ModelTypes["UserUpdateWithoutPublicReviewsInput"],
	create: ModelTypes["UserCreateWithoutPublicReviewsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutPublicReviewsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["WorkspaceUpdateManyWithoutOwnerNestedInput"]: {
	create?: Array<ModelTypes["WorkspaceCreateWithoutOwnerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WorkspaceCreateOrConnectWithoutOwnerInput"]> | undefined,
	upsert?: Array<ModelTypes["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]> | undefined,
	createMany?: ModelTypes["WorkspaceCreateManyOwnerInputEnvelope"] | undefined,
	set?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]> | undefined,
	updateMany?: Array<ModelTypes["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]> | undefined,
	deleteMany?: Array<ModelTypes["WorkspaceScalarWhereInput"]> | undefined
};
	["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	update: ModelTypes["WorkspaceUpdateWithoutOwnerInput"],
	create: ModelTypes["WorkspaceCreateWithoutOwnerInput"]
};
	["WorkspaceUpdateWithoutOwnerInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"]: {
	create?: ModelTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | undefined,
	connectOrCreate?: ModelTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"] | undefined,
	upsert?: ModelTypes["SubscriptionUpsertWithoutActiveWorkspaceInput"] | undefined,
	disconnect?: ModelTypes["SubscriptionWhereInput"] | undefined,
	delete?: ModelTypes["SubscriptionWhereInput"] | undefined,
	connect?: ModelTypes["SubscriptionWhereUniqueInput"] | undefined,
	update?: ModelTypes["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"] | undefined
};
	["SubscriptionUpsertWithoutActiveWorkspaceInput"]: {
	update: ModelTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"],
	create: ModelTypes["SubscriptionCreateWithoutActiveWorkspaceInput"],
	where?: ModelTypes["SubscriptionWhereInput"] | undefined
};
	["SubscriptionUpdateWithoutActiveWorkspaceInput"]: {
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsCreateInput"] | undefined
};
	["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"]: {
	where?: ModelTypes["SubscriptionWhereInput"] | undefined,
	data: ModelTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"]
};
	["CameraServerUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["CameraServerCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["CameraServerCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["CameraServerWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CameraServerWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CameraServerWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CameraServerWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CameraServerScalarWhereInput"]> | undefined
};
	["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["CameraServerWhereUniqueInput"],
	update: ModelTypes["CameraServerUpdateWithoutWorkspaceInput"],
	create: ModelTypes["CameraServerCreateWithoutWorkspaceInput"]
};
	["CameraServerUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined,
	feeds?: ModelTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined
};
	["CameraUpdateManyWithoutCameraServerNestedInput"]: {
	create?: Array<ModelTypes["CameraCreateWithoutCameraServerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraCreateOrConnectWithoutCameraServerInput"]> | undefined,
	upsert?: Array<ModelTypes["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]> | undefined,
	createMany?: ModelTypes["CameraCreateManyCameraServerInputEnvelope"] | undefined,
	set?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CameraWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]> | undefined,
	updateMany?: Array<ModelTypes["CameraUpdateManyWithWhereWithoutCameraServerInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CameraScalarWhereInput"]> | undefined
};
	["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	update: ModelTypes["CameraUpdateWithoutCameraServerInput"],
	create: ModelTypes["CameraCreateWithoutCameraServerInput"]
};
	["CameraUpdateWithoutCameraServerInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	player?: ModelTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined,
	feeds?: ModelTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined
};
	["CameraFeedUpdateManyWithoutCameraNestedInput"]: {
	create?: Array<ModelTypes["CameraFeedCreateWithoutCameraInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraFeedCreateOrConnectWithoutCameraInput"]> | undefined,
	upsert?: Array<ModelTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]> | undefined,
	createMany?: ModelTypes["CameraFeedCreateManyCameraInputEnvelope"] | undefined,
	set?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]> | undefined,
	updateMany?: Array<ModelTypes["CameraFeedUpdateManyWithWhereWithoutCameraInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CameraFeedScalarWhereInput"]> | undefined
};
	["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]: {
	where: ModelTypes["CameraFeedWhereUniqueInput"],
	update: ModelTypes["CameraFeedUpdateWithoutCameraInput"],
	create: ModelTypes["CameraFeedCreateWithoutCameraInput"]
};
	["CameraFeedUpdateWithoutCameraInput"]: {
	timestamp?: ModelTypes["DateTime"] | undefined,
	data?: ModelTypes["CameraFeedDataCreateInput"] | undefined,
	cameraServer?: ModelTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"] | undefined
};
	["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"]: {
	create?: ModelTypes["CameraServerCreateWithoutFeedsInput"] | undefined,
	connectOrCreate?: ModelTypes["CameraServerCreateOrConnectWithoutFeedsInput"] | undefined,
	upsert?: ModelTypes["CameraServerUpsertWithoutFeedsInput"] | undefined,
	connect?: ModelTypes["CameraServerWhereUniqueInput"] | undefined,
	update?: ModelTypes["CameraServerUpdateToOneWithWhereWithoutFeedsInput"] | undefined
};
	["CameraServerUpsertWithoutFeedsInput"]: {
	update: ModelTypes["CameraServerUpdateWithoutFeedsInput"],
	create: ModelTypes["CameraServerCreateWithoutFeedsInput"],
	where?: ModelTypes["CameraServerWhereInput"] | undefined
};
	["CameraServerUpdateWithoutFeedsInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined
};
	["CameraServerUpdateToOneWithWhereWithoutFeedsInput"]: {
	where?: ModelTypes["CameraServerWhereInput"] | undefined,
	data: ModelTypes["CameraServerUpdateWithoutFeedsInput"]
};
	["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]: {
	where: ModelTypes["CameraFeedWhereUniqueInput"],
	data: ModelTypes["CameraFeedUpdateWithoutCameraInput"]
};
	["CameraFeedUpdateManyWithWhereWithoutCameraInput"]: {
	where: ModelTypes["CameraFeedScalarWhereInput"],
	data: ModelTypes["CameraFeedUpdateManyMutationInput"]
};
	["CameraFeedScalarWhereInput"]: {
	AND?: Array<ModelTypes["CameraFeedScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraFeedScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraFeedScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	cameraId?: ModelTypes["StringFilter"] | undefined,
	timestamp?: ModelTypes["DateTimeFilter"] | undefined,
	cameraServerId?: ModelTypes["StringFilter"] | undefined
};
	["CameraFeedUpdateManyMutationInput"]: {
	timestamp?: ModelTypes["DateTime"] | undefined,
	data?: ModelTypes["CameraFeedDataCreateInput"] | undefined
};
	["AdBoardUpdateOneWithoutCamerasNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutCamerasInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutCamerasInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["AdBoardWhereInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutCamerasInput"] | undefined
};
	["AdBoardUpsertWithoutCamerasInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutCamerasInput"],
	create: ModelTypes["AdBoardCreateWithoutCamerasInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutCamerasInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["UserUpdateOneWithoutArchivedAdboardsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutArchivedAdboardsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutArchivedAdboardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["UserWhereInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"] | undefined
};
	["UserUpsertWithoutArchivedAdboardsInput"]: {
	update: ModelTypes["UserUpdateWithoutArchivedAdboardsInput"],
	create: ModelTypes["UserCreateWithoutArchivedAdboardsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutArchivedAdboardsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["WorkspaceUpdateManyWithoutLeadsNestedInput"]: {
	create?: Array<ModelTypes["WorkspaceCreateWithoutLeadsInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WorkspaceCreateOrConnectWithoutLeadsInput"]> | undefined,
	upsert?: Array<ModelTypes["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]> | undefined,
	set?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["WorkspaceWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]> | undefined,
	updateMany?: Array<ModelTypes["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]> | undefined,
	deleteMany?: Array<ModelTypes["WorkspaceScalarWhereInput"]> | undefined
};
	["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	update: ModelTypes["WorkspaceUpdateWithoutLeadsInput"],
	create: ModelTypes["WorkspaceCreateWithoutLeadsInput"]
};
	["WorkspaceUpdateWithoutLeadsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined
};
	["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["WorkspaceRoleWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["WorkspaceRoleWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["WorkspaceRoleWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["WorkspaceRoleWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["WorkspaceRoleScalarWhereInput"]> | undefined
};
	["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["WorkspaceRoleWhereUniqueInput"],
	update: ModelTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"],
	create: ModelTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]
};
	["WorkspaceRoleUpdateWithoutWorkspaceInput"]: {
	name?: string | undefined,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutRoleNestedInput"] | undefined
};
	["MembershipUpdateManyWithoutRoleNestedInput"]: {
	create?: Array<ModelTypes["MembershipCreateWithoutRoleInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["MembershipCreateOrConnectWithoutRoleInput"]> | undefined,
	upsert?: Array<ModelTypes["MembershipUpsertWithWhereUniqueWithoutRoleInput"]> | undefined,
	createMany?: ModelTypes["MembershipCreateManyRoleInputEnvelope"] | undefined,
	set?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["MembershipWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["MembershipUpdateWithWhereUniqueWithoutRoleInput"]> | undefined,
	updateMany?: Array<ModelTypes["MembershipUpdateManyWithWhereWithoutRoleInput"]> | undefined,
	deleteMany?: Array<ModelTypes["MembershipScalarWhereInput"]> | undefined
};
	["MembershipUpsertWithWhereUniqueWithoutRoleInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	update: ModelTypes["MembershipUpdateWithoutRoleInput"],
	create: ModelTypes["MembershipCreateWithoutRoleInput"]
};
	["MembershipUpdateWithoutRoleInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined
};
	["MembershipUpdateWithWhereUniqueWithoutRoleInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	data: ModelTypes["MembershipUpdateWithoutRoleInput"]
};
	["MembershipUpdateManyWithWhereWithoutRoleInput"]: {
	where: ModelTypes["MembershipScalarWhereInput"],
	data: ModelTypes["MembershipUpdateManyMutationInput"]
};
	["MembershipScalarWhereInput"]: {
	AND?: Array<ModelTypes["MembershipScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["MembershipScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["MembershipScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	roleId?: ModelTypes["StringFilter"] | undefined
};
	["MembershipUpdateManyMutationInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["WorkspaceRoleWhereUniqueInput"],
	data: ModelTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"]
};
	["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["WorkspaceRoleScalarWhereInput"],
	data: ModelTypes["WorkspaceRoleUpdateManyMutationInput"]
};
	["WorkspaceRoleScalarWhereInput"]: {
	AND?: Array<ModelTypes["WorkspaceRoleScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WorkspaceRoleScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WorkspaceRoleScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	permissions?: ModelTypes["EnumPermissionNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["WorkspaceRoleUpdateManyMutationInput"]: {
	name?: string | undefined,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["WebhookLogUpdateManyWithoutWorkspaceNestedInput"]: {
	create?: Array<ModelTypes["WebhookLogCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<ModelTypes["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: ModelTypes["WebhookLogCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<ModelTypes["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<ModelTypes["WebhookLogScalarWhereInput"]> | undefined
};
	["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["WebhookLogWhereUniqueInput"],
	update: ModelTypes["WebhookLogUpdateWithoutWorkspaceInput"],
	create: ModelTypes["WebhookLogCreateWithoutWorkspaceInput"]
};
	["WebhookLogUpdateWithoutWorkspaceInput"]: {
	response?: ModelTypes["JSON"] | undefined,
	request?: ModelTypes["JSON"] | undefined,
	status?: number | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	booking?: ModelTypes["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"] | undefined
};
	["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"]: {
	create?: ModelTypes["BookingCreateWithoutWebhookLogsInput"] | undefined,
	connectOrCreate?: ModelTypes["BookingCreateOrConnectWithoutWebhookLogsInput"] | undefined,
	upsert?: ModelTypes["BookingUpsertWithoutWebhookLogsInput"] | undefined,
	connect?: ModelTypes["BookingWhereUniqueInput"] | undefined,
	update?: ModelTypes["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"] | undefined
};
	["BookingUpsertWithoutWebhookLogsInput"]: {
	update: ModelTypes["BookingUpdateWithoutWebhookLogsInput"],
	create: ModelTypes["BookingCreateWithoutWebhookLogsInput"],
	where?: ModelTypes["BookingWhereInput"] | undefined
};
	["BookingUpdateWithoutWebhookLogsInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["SlotUsageUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ModelTypes["SlotUsageCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["SlotUsageCreateOrConnectWithoutBookingInput"]> | undefined,
	upsert?: Array<ModelTypes["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]> | undefined,
	createMany?: ModelTypes["SlotUsageCreateManyBookingInputEnvelope"] | undefined,
	set?: Array<ModelTypes["SlotUsageWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["SlotUsageWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["SlotUsageWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["SlotUsageWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]> | undefined,
	updateMany?: Array<ModelTypes["SlotUsageUpdateManyWithWhereWithoutBookingInput"]> | undefined,
	deleteMany?: Array<ModelTypes["SlotUsageScalarWhereInput"]> | undefined
};
	["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ModelTypes["SlotUsageWhereUniqueInput"],
	update: ModelTypes["SlotUsageUpdateWithoutBookingInput"],
	create: ModelTypes["SlotUsageCreateWithoutBookingInput"]
};
	["SlotUsageUpdateWithoutBookingInput"]: {
	date?: ModelTypes["DateTime"] | undefined,
	slotUsed?: number | undefined
};
	["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ModelTypes["SlotUsageWhereUniqueInput"],
	data: ModelTypes["SlotUsageUpdateWithoutBookingInput"]
};
	["SlotUsageUpdateManyWithWhereWithoutBookingInput"]: {
	where: ModelTypes["SlotUsageScalarWhereInput"],
	data: ModelTypes["SlotUsageUpdateManyMutationInput"]
};
	["SlotUsageScalarWhereInput"]: {
	AND?: Array<ModelTypes["SlotUsageScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["SlotUsageScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["SlotUsageScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	date?: ModelTypes["DateTimeFilter"] | undefined,
	slotUsed?: ModelTypes["IntFilter"] | undefined
};
	["SlotUsageUpdateManyMutationInput"]: {
	date?: ModelTypes["DateTime"] | undefined,
	slotUsed?: number | undefined
};
	["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"]: {
	where?: ModelTypes["BookingWhereInput"] | undefined,
	data: ModelTypes["BookingUpdateWithoutWebhookLogsInput"]
};
	["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["WebhookLogWhereUniqueInput"],
	data: ModelTypes["WebhookLogUpdateWithoutWorkspaceInput"]
};
	["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["WebhookLogScalarWhereInput"],
	data: ModelTypes["WebhookLogUpdateManyMutationInput"]
};
	["WebhookLogScalarWhereInput"]: {
	AND?: Array<ModelTypes["WebhookLogScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WebhookLogScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WebhookLogScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	response?: ModelTypes["JsonNullableFilter"] | undefined,
	request?: ModelTypes["JsonNullableFilter"] | undefined,
	status?: ModelTypes["IntFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined
};
	["WebhookLogUpdateManyMutationInput"]: {
	response?: ModelTypes["JSON"] | undefined,
	request?: ModelTypes["JSON"] | undefined,
	status?: number | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	data: ModelTypes["WorkspaceUpdateWithoutLeadsInput"]
};
	["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]: {
	where: ModelTypes["WorkspaceScalarWhereInput"],
	data: ModelTypes["WorkspaceUpdateManyMutationInput"]
};
	["WorkspaceScalarWhereInput"]: {
	AND?: Array<ModelTypes["WorkspaceScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WorkspaceScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WorkspaceScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumWorkspaceTypeFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	key?: ModelTypes["StringFilter"] | undefined,
	userIds?: ModelTypes["StringNullableListFilter"] | undefined,
	ownerId?: ModelTypes["StringNullableFilter"] | undefined,
	avatar?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	stripeSubscriptionId?: ModelTypes["StringNullableFilter"] | undefined,
	leadIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["WorkspaceUpdateManyMutationInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined
};
	["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ModelTypes["AdBoardPublicReviewCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined
};
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["AdBoardPublicReviewWhereUniqueInput"],
	update: ModelTypes["AdBoardPublicReviewUpdateWithoutUserInput"],
	create: ModelTypes["AdBoardPublicReviewCreateWithoutUserInput"]
};
	["AdBoardPublicReviewUpdateWithoutUserInput"]: {
	rating?: number | undefined,
	comment?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutPublicReviewsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutPublicReviewsInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"] | undefined
};
	["AdBoardUpsertWithoutPublicReviewsInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutPublicReviewsInput"],
	create: ModelTypes["AdBoardCreateWithoutPublicReviewsInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutPublicReviewsInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined
};
	["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["AdBoardPublicReviewWhereUniqueInput"],
	data: ModelTypes["AdBoardPublicReviewUpdateWithoutUserInput"]
};
	["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]: {
	where: ModelTypes["AdBoardPublicReviewScalarWhereInput"],
	data: ModelTypes["AdBoardPublicReviewUpdateManyMutationInput"]
};
	["AdBoardPublicReviewScalarWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	rating?: ModelTypes["IntFilter"] | undefined,
	comment?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["AdBoardPublicReviewUpdateManyMutationInput"]: {
	rating?: number | undefined,
	comment?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["UserPositionUpdateManyWithoutUserNestedInput"]: {
	create?: Array<ModelTypes["UserPositionCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["UserPositionCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<ModelTypes["UserPositionUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: ModelTypes["UserPositionCreateManyUserInputEnvelope"] | undefined,
	set?: Array<ModelTypes["UserPositionWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["UserPositionWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["UserPositionWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["UserPositionWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["UserPositionUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<ModelTypes["UserPositionUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<ModelTypes["UserPositionScalarWhereInput"]> | undefined
};
	["UserPositionUpsertWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["UserPositionWhereUniqueInput"],
	update: ModelTypes["UserPositionUpdateWithoutUserInput"],
	create: ModelTypes["UserPositionCreateWithoutUserInput"]
};
	["UserPositionUpdateWithoutUserInput"]: {
	timestamp?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	coords?: ModelTypes["UserPositionCoordsCreateInput"] | undefined,
	location?: ModelTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserPositionUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["UserPositionWhereUniqueInput"],
	data: ModelTypes["UserPositionUpdateWithoutUserInput"]
};
	["UserPositionUpdateManyWithWhereWithoutUserInput"]: {
	where: ModelTypes["UserPositionScalarWhereInput"],
	data: ModelTypes["UserPositionUpdateManyMutationInput"]
};
	["UserPositionScalarWhereInput"]: {
	AND?: Array<ModelTypes["UserPositionScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserPositionScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserPositionScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	timestamp?: ModelTypes["DateTimeFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined
};
	["UserPositionUpdateManyMutationInput"]: {
	timestamp?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	coords?: ModelTypes["UserPositionCoordsCreateInput"] | undefined,
	location?: ModelTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutArchivedAdboardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	data: ModelTypes["CameraUpdateWithoutCameraServerInput"]
};
	["CameraUpdateManyWithWhereWithoutCameraServerInput"]: {
	where: ModelTypes["CameraScalarWhereInput"],
	data: ModelTypes["CameraUpdateManyMutationInput"]
};
	["CameraScalarWhereInput"]: {
	AND?: Array<ModelTypes["CameraScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringNullableFilter"] | undefined,
	isOnline?: ModelTypes["BoolFilter"] | undefined,
	cameraServerId?: ModelTypes["StringFilter"] | undefined,
	cameraUrl?: ModelTypes["StringFilter"] | undefined,
	rtspUrl?: ModelTypes["StringFilter"] | undefined,
	cameraIp?: ModelTypes["StringFilter"] | undefined,
	connectedCpuSerialNumbers?: ModelTypes["StringNullableListFilter"] | undefined,
	playerId?: ModelTypes["StringNullableFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	isPublic?: ModelTypes["BoolFilter"] | undefined,
	adBoardId?: ModelTypes["StringNullableFilter"] | undefined
};
	["CameraUpdateManyMutationInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined
};
	["CameraFeedUpdateManyWithoutCameraServerNestedInput"]: {
	create?: Array<ModelTypes["CameraFeedCreateWithoutCameraServerInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"]> | undefined,
	upsert?: Array<ModelTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]> | undefined,
	createMany?: ModelTypes["CameraFeedCreateManyCameraServerInputEnvelope"] | undefined,
	set?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["CameraFeedWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]> | undefined,
	updateMany?: Array<ModelTypes["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]> | undefined,
	deleteMany?: Array<ModelTypes["CameraFeedScalarWhereInput"]> | undefined
};
	["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]: {
	where: ModelTypes["CameraFeedWhereUniqueInput"],
	update: ModelTypes["CameraFeedUpdateWithoutCameraServerInput"],
	create: ModelTypes["CameraFeedCreateWithoutCameraServerInput"]
};
	["CameraFeedUpdateWithoutCameraServerInput"]: {
	timestamp?: ModelTypes["DateTime"] | undefined,
	data?: ModelTypes["CameraFeedDataCreateInput"] | undefined,
	camera?: ModelTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"] | undefined
};
	["CameraUpdateOneRequiredWithoutFeedsNestedInput"]: {
	create?: ModelTypes["CameraCreateWithoutFeedsInput"] | undefined,
	connectOrCreate?: ModelTypes["CameraCreateOrConnectWithoutFeedsInput"] | undefined,
	upsert?: ModelTypes["CameraUpsertWithoutFeedsInput"] | undefined,
	connect?: ModelTypes["CameraWhereUniqueInput"] | undefined,
	update?: ModelTypes["CameraUpdateToOneWithWhereWithoutFeedsInput"] | undefined
};
	["CameraUpsertWithoutFeedsInput"]: {
	update: ModelTypes["CameraUpdateWithoutFeedsInput"],
	create: ModelTypes["CameraCreateWithoutFeedsInput"],
	where?: ModelTypes["CameraWhereInput"] | undefined
};
	["CameraUpdateWithoutFeedsInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer?: ModelTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined,
	player?: ModelTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined
};
	["CameraUpdateToOneWithWhereWithoutFeedsInput"]: {
	where?: ModelTypes["CameraWhereInput"] | undefined,
	data: ModelTypes["CameraUpdateWithoutFeedsInput"]
};
	["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]: {
	where: ModelTypes["CameraFeedWhereUniqueInput"],
	data: ModelTypes["CameraFeedUpdateWithoutCameraServerInput"]
};
	["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]: {
	where: ModelTypes["CameraFeedScalarWhereInput"],
	data: ModelTypes["CameraFeedUpdateManyMutationInput"]
};
	["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["CameraServerWhereUniqueInput"],
	data: ModelTypes["CameraServerUpdateWithoutWorkspaceInput"]
};
	["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["CameraServerScalarWhereInput"],
	data: ModelTypes["CameraServerUpdateManyMutationInput"]
};
	["CameraServerScalarWhereInput"]: {
	AND?: Array<ModelTypes["CameraServerScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CameraServerScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CameraServerScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringNullableFilter"] | undefined,
	isOnline?: ModelTypes["BoolNullableFilter"] | undefined,
	hostName?: ModelTypes["StringNullableFilter"] | undefined,
	userName?: ModelTypes["StringNullableFilter"] | undefined,
	deviceId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	publicIp?: ModelTypes["StringFilter"] | undefined,
	privateIp?: ModelTypes["StringFilter"] | undefined
};
	["CameraServerUpdateManyMutationInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined
};
	["UserUpdateManyWithoutLeadWorspacesNestedInput"]: {
	create?: Array<ModelTypes["UserCreateWithoutLeadWorspacesInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["UserCreateOrConnectWithoutLeadWorspacesInput"]> | undefined,
	upsert?: Array<ModelTypes["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]> | undefined,
	set?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["UserWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]> | undefined,
	updateMany?: Array<ModelTypes["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]> | undefined,
	deleteMany?: Array<ModelTypes["UserScalarWhereInput"]> | undefined
};
	["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	update: ModelTypes["UserUpdateWithoutLeadWorspacesInput"],
	create: ModelTypes["UserCreateWithoutLeadWorspacesInput"]
};
	["UserUpdateWithoutLeadWorspacesInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	data: ModelTypes["UserUpdateWithoutLeadWorspacesInput"]
};
	["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]: {
	where: ModelTypes["UserScalarWhereInput"],
	data: ModelTypes["UserUpdateManyMutationInput"]
};
	["UserScalarWhereInput"]: {
	AND?: Array<ModelTypes["UserScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	uid?: ModelTypes["StringNullableFilter"] | undefined,
	email?: ModelTypes["StringFilter"] | undefined,
	image?: ModelTypes["StringNullableFilter"] | undefined,
	phoneNumber?: ModelTypes["StringNullableFilter"] | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	otherDetails?: ModelTypes["JsonNullableFilter"] | undefined,
	roles?: ModelTypes["EnumRoleNullableListFilter"] | undefined,
	sharedBookingIds?: ModelTypes["StringNullableListFilter"] | undefined,
	disclaimerAccepted?: ModelTypes["BoolFilter"] | undefined,
	leadWorkspaceIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["UserUpdateManyMutationInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined
};
	["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	data: ModelTypes["WorkspaceUpdateWithoutOwnerInput"]
};
	["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]: {
	where: ModelTypes["WorkspaceScalarWhereInput"],
	data: ModelTypes["WorkspaceUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutPublicReviewsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardPublicReviewWhereUniqueInput"],
	data: ModelTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardPublicReviewScalarWhereInput"],
	data: ModelTypes["AdBoardPublicReviewUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutArchivedByInput"]
};
	["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["AdBoardScalarWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	address?: ModelTypes["StringFilter"] | undefined,
	state?: ModelTypes["StringFilter"] | undefined,
	city?: ModelTypes["StringFilter"] | undefined,
	country?: ModelTypes["StringFilter"] | undefined,
	outdoor?: ModelTypes["BoolFilter"] | undefined,
	locationCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	locationSubCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	alias?: ModelTypes["StringNullableFilter"] | undefined,
	title?: ModelTypes["StringFilter"] | undefined,
	pricePerDay?: ModelTypes["IntFilter"] | undefined,
	originalPricePerDay?: ModelTypes["IntNullableFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	adBoardTypeId?: ModelTypes["StringNullableFilter"] | undefined,
	tags?: ModelTypes["StringNullableListFilter"] | undefined,
	featuredImage?: ModelTypes["StringNullableFilter"] | undefined,
	galleryImgs?: ModelTypes["StringNullableListFilter"] | undefined,
	notes?: ModelTypes["StringNullableListFilter"] | undefined,
	impressionMultiplier?: ModelTypes["FloatNullableFilter"] | undefined,
	categoryId?: ModelTypes["StringNullableFilter"] | undefined,
	restrictedBusinessIds?: ModelTypes["StringNullableListFilter"] | undefined,
	targetAudiences?: ModelTypes["StringNullableListFilter"] | undefined,
	status?: ModelTypes["EnumAdBoardStatusFilter"] | undefined,
	adboardDraftStatus?: ModelTypes["EnumAdboardDraftStatusNullableFilter"] | undefined,
	isLive?: ModelTypes["BoolFilter"] | undefined,
	numberOfDisplays?: ModelTypes["IntFilter"] | undefined,
	dailyOperationHours?: ModelTypes["IntFilter"] | undefined,
	adsPerLoop?: ModelTypes["IntFilter"] | undefined,
	adsDuration?: ModelTypes["IntFilter"] | undefined,
	monthlyPrice?: ModelTypes["FloatNullableFilter"] | undefined,
	offlineBookingsEnabled?: ModelTypes["BoolFilter"] | undefined,
	offlineBookingContactId?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	createdByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	href?: ModelTypes["StringNullableFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined,
	playerIds?: ModelTypes["StringNullableListFilter"] | undefined,
	publisherScreenId?: ModelTypes["StringNullableFilter"] | undefined,
	additionalData?: ModelTypes["JsonNullableFilter"] | undefined,
	isDraft?: ModelTypes["BoolFilter"] | undefined,
	publishedVersionId?: ModelTypes["StringNullableFilter"] | undefined,
	lastPublishedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	isArchived?: ModelTypes["BoolFilter"] | undefined,
	archivedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	archivedByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeNullableFilter"] | undefined
};
	["UserUpdateToOneWithWhereWithoutReviewCommentsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardReviewCommentWhereUniqueInput"],
	data: ModelTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardReviewCommentScalarWhereInput"],
	data: ModelTypes["AdBoardReviewCommentUpdateManyMutationInput"]
};
	["AdBoardReviewCommentScalarWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	comment?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["EnumReviewActionFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["AdBoardReviewCommentUpdateManyMutationInput"]: {
	comment?: string | undefined,
	action?: ModelTypes["ReviewAction"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutPublishedVersionInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["AdBoardReviewCommentWhereUniqueInput"],
	data: ModelTypes["AdBoardReviewCommentUpdateWithoutUserInput"]
};
	["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]: {
	where: ModelTypes["AdBoardReviewCommentScalarWhereInput"],
	data: ModelTypes["AdBoardReviewCommentUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutPublishAdboardVersionInput"]
};
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardPublishHistoryWhereUniqueInput"],
	data: ModelTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ModelTypes["AdBoardPublishHistoryScalarWhereInput"],
	data: ModelTypes["AdBoardPublishHistoryUpdateManyMutationInput"]
};
	["AdBoardPublishHistoryScalarWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	publishedAt?: ModelTypes["DateTimeFilter"] | undefined,
	publishedByUserId?: ModelTypes["StringFilter"] | undefined,
	changesSnapshot?: ModelTypes["JsonFilter"] | undefined
};
	["AdBoardPublishHistoryUpdateManyMutationInput"]: {
	publishedAt?: ModelTypes["DateTime"] | undefined,
	changesSnapshot?: ModelTypes["JSON"] | undefined
};
	["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutDraftAdboardInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutPlayersInput"]
};
	["AdBoardUpdateManyWithWhereWithoutPlayersInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["PlayerUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ModelTypes["PlayerWhereInput"] | undefined,
	data: ModelTypes["PlayerUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	data: ModelTypes["CameraUpdateWithoutAdBoardInput"]
};
	["CameraUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ModelTypes["CameraScalarWhereInput"],
	data: ModelTypes["CameraUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"]
};
	["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	data: ModelTypes["UserUpdateWithoutSharedBookingsInput"]
};
	["UserUpdateManyWithWhereWithoutSharedBookingsInput"]: {
	where: ModelTypes["UserScalarWhereInput"],
	data: ModelTypes["UserUpdateManyMutationInput"]
};
	["WebhookLogUpdateManyWithoutBookingNestedInput"]: {
	create?: Array<ModelTypes["WebhookLogCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["WebhookLogCreateOrConnectWithoutBookingInput"]> | undefined,
	upsert?: Array<ModelTypes["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]> | undefined,
	createMany?: ModelTypes["WebhookLogCreateManyBookingInputEnvelope"] | undefined,
	set?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["WebhookLogWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]> | undefined,
	updateMany?: Array<ModelTypes["WebhookLogUpdateManyWithWhereWithoutBookingInput"]> | undefined,
	deleteMany?: Array<ModelTypes["WebhookLogScalarWhereInput"]> | undefined
};
	["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]: {
	where: ModelTypes["WebhookLogWhereUniqueInput"],
	update: ModelTypes["WebhookLogUpdateWithoutBookingInput"],
	create: ModelTypes["WebhookLogCreateWithoutBookingInput"]
};
	["WebhookLogUpdateWithoutBookingInput"]: {
	response?: ModelTypes["JSON"] | undefined,
	request?: ModelTypes["JSON"] | undefined,
	status?: number | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutWebhookLogsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutWebhookLogsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["WorkspaceWhereInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"] | undefined
};
	["WorkspaceUpsertWithoutWebhookLogsInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutWebhookLogsInput"],
	create: ModelTypes["WorkspaceCreateWithoutWebhookLogsInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutWebhookLogsInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutWebhookLogsInput"]
};
	["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ModelTypes["WebhookLogWhereUniqueInput"],
	data: ModelTypes["WebhookLogUpdateWithoutBookingInput"]
};
	["WebhookLogUpdateManyWithWhereWithoutBookingInput"]: {
	where: ModelTypes["WebhookLogScalarWhereInput"],
	data: ModelTypes["WebhookLogUpdateManyMutationInput"]
};
	["BookingUpdateToOneWithWhereWithoutPayoutsInput"]: {
	where?: ModelTypes["BookingWhereInput"] | undefined,
	data: ModelTypes["BookingUpdateWithoutPayoutsInput"]
};
	["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]: {
	where: ModelTypes["PayoutWhereUniqueInput"],
	data: ModelTypes["PayoutUpdateWithoutTransactionInput"]
};
	["PayoutUpdateManyWithWhereWithoutTransactionInput"]: {
	where: ModelTypes["PayoutScalarWhereInput"],
	data: ModelTypes["PayoutUpdateManyMutationInput"]
};
	["PayoutScalarWhereInput"]: {
	AND?: Array<ModelTypes["PayoutScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PayoutScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PayoutScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	totalAmount?: ModelTypes["FloatFilter"] | undefined,
	commissionRate?: ModelTypes["FloatFilter"] | undefined,
	commissionAmount?: ModelTypes["FloatFilter"] | undefined,
	netAmount?: ModelTypes["FloatFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	bookingId?: ModelTypes["StringFilter"] | undefined,
	status?: ModelTypes["EnumPayoutStatusFilter"] | undefined,
	transactionId?: ModelTypes["StringNullableFilter"] | undefined
};
	["PayoutUpdateManyMutationInput"]: {
	totalAmount?: number | undefined,
	commissionRate?: number | undefined,
	commissionAmount?: number | undefined,
	netAmount?: number | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	status?: ModelTypes["PayoutStatus"] | undefined
};
	["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["TransactionWhereUniqueInput"],
	data: ModelTypes["TransactionUpdateWithoutWorkspaceInput"]
};
	["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["TransactionScalarWhereInput"],
	data: ModelTypes["TransactionUpdateManyMutationInput"]
};
	["TransactionScalarWhereInput"]: {
	AND?: Array<ModelTypes["TransactionScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["TransactionScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["TransactionScalarWhereInput"]> | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	amount?: ModelTypes["FloatFilter"] | undefined,
	createAt?: ModelTypes["DateTimeFilter"] | undefined,
	processedAt?: ModelTypes["DateTimeNullableFilter"] | undefined,
	relatedEntityId?: ModelTypes["StringFilter"] | undefined,
	transactionStatus?: ModelTypes["EnumTransactionStatusFilter"] | undefined,
	details?: ModelTypes["JsonNullableFilter"] | undefined
};
	["TransactionUpdateManyMutationInput"]: {
	amount?: number | undefined,
	createAt?: ModelTypes["DateTime"] | undefined,
	processedAt?: ModelTypes["DateTime"] | undefined,
	relatedEntityId?: string | undefined,
	transactionStatus?: ModelTypes["TransactionStatus"] | undefined,
	details?: ModelTypes["JSON"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutCameraServersInput"]
};
	["CameraServerUpdateToOneWithWhereWithoutCamerasInput"]: {
	where?: ModelTypes["CameraServerWhereInput"] | undefined,
	data: ModelTypes["CameraServerUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutPlayerInput"]: {
	where: ModelTypes["CameraWhereUniqueInput"],
	data: ModelTypes["CameraUpdateWithoutPlayerInput"]
};
	["CameraUpdateManyWithWhereWithoutPlayerInput"]: {
	where: ModelTypes["CameraScalarWhereInput"],
	data: ModelTypes["CameraUpdateManyMutationInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	data: ModelTypes["PlayerUpdateWithoutWorkspaceInput"]
};
	["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["PlayerScalarWhereInput"],
	data: ModelTypes["PlayerUpdateManyMutationInput"]
};
	["PlayerScalarWhereInput"]: {
	AND?: Array<ModelTypes["PlayerScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlayerScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlayerScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	configLocation?: ModelTypes["StringNullableFilter"] | undefined,
	pisignagePlayerId?: ModelTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: ModelTypes["StringNullableFilter"] | undefined,
	cpuSerialNumber?: ModelTypes["StringNullableFilter"] | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	adBoardGroupId?: ModelTypes["StringNullableFilter"] | undefined,
	playlistIds?: ModelTypes["StringNullableListFilter"] | undefined,
	workpaceId?: ModelTypes["StringNullableFilter"] | undefined,
	signageTypeId?: ModelTypes["StringNullableFilter"] | undefined,
	audienceTypeId?: ModelTypes["StringNullableListFilter"] | undefined,
	aspectRatioType?: ModelTypes["EnumAspectRatioTypeFilter"] | undefined,
	customAspectRatioWidth?: ModelTypes["IntNullableFilter"] | undefined,
	customAspectRatioHeight?: ModelTypes["IntNullableFilter"] | undefined,
	isOnline?: ModelTypes["BoolFilter"] | undefined,
	adBoardIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["PlayerUpdateManyMutationInput"]: {
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutTransactionsInput"]
};
	["TransactionUpdateToOneWithWhereWithoutPayoutsInput"]: {
	where?: ModelTypes["TransactionWhereInput"] | undefined,
	data: ModelTypes["TransactionUpdateWithoutPayoutsInput"]
};
	["PayoutUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ModelTypes["PayoutWhereUniqueInput"],
	data: ModelTypes["PayoutUpdateWithoutBookingInput"]
};
	["PayoutUpdateManyWithWhereWithoutBookingInput"]: {
	where: ModelTypes["PayoutScalarWhereInput"],
	data: ModelTypes["PayoutUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutCampaignInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	data: ModelTypes["BookingUpdateWithoutCampaignInput"]
};
	["BookingUpdateManyWithWhereWithoutCampaignInput"]: {
	where: ModelTypes["BookingScalarWhereInput"],
	data: ModelTypes["BookingUpdateManyMutationInput"]
};
	["BookingScalarWhereInput"]: {
	AND?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BookingScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	additionalInformation?: ModelTypes["StringNullableFilter"] | undefined,
	origin?: ModelTypes["StringNullableFilter"] | undefined,
	assetsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingCode?: ModelTypes["StringFilter"] | undefined,
	bookingDate?: ModelTypes["DateTimeFilter"] | undefined,
	bookingFor?: ModelTypes["StringFilter"] | undefined,
	purpose?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	campaignId?: ModelTypes["StringNullableFilter"] | undefined,
	businessCategoryId?: ModelTypes["StringNullableFilter"] | undefined,
	adCategoryId?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingStatus?: ModelTypes["EnumBookingStatusFilter"] | undefined,
	paymentStatus?: ModelTypes["EnumPaymentStatusNullableFilter"] | undefined,
	deploymentStatus?: ModelTypes["EnumDeploymentStatusFilter"] | undefined,
	contentApprovalStatus?: ModelTypes["EnumContentApprovalStatusFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	sharedUserIds?: ModelTypes["StringNullableListFilter"] | undefined,
	isOffline?: ModelTypes["BoolFilter"] | undefined,
	isPaid?: ModelTypes["BoolFilter"] | undefined,
	isScheduled?: ModelTypes["BoolFilter"] | undefined,
	isDeployed?: ModelTypes["BoolFilter"] | undefined,
	isCompleted?: ModelTypes["BoolFilter"] | undefined
};
	["BookingUpdateManyMutationInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	data: ModelTypes["CampaignUpdateWithoutAssetsInput"]
};
	["CampaignUpdateManyWithWhereWithoutAssetsInput"]: {
	where: ModelTypes["CampaignScalarWhereInput"],
	data: ModelTypes["CampaignUpdateManyMutationInput"]
};
	["CampaignScalarWhereInput"]: {
	AND?: Array<ModelTypes["CampaignScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["CampaignScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["CampaignScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	bookingFor?: ModelTypes["StringFilter"] | undefined,
	purpose?: ModelTypes["StringFilter"] | undefined,
	businessCategoryId?: ModelTypes["StringFilter"] | undefined,
	adCategoryId?: ModelTypes["StringNullableListFilter"] | undefined,
	paymentStatus?: ModelTypes["EnumPaymentStatusFilter"] | undefined,
	origin?: ModelTypes["StringFilter"] | undefined,
	assetsIDs?: ModelTypes["StringNullableListFilter"] | undefined
};
	["CampaignUpdateManyMutationInput"]: {
	name?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined
};
	["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	data: ModelTypes["FileUpdateWithoutWorkspaceInput"]
};
	["FileUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["FileScalarWhereInput"],
	data: ModelTypes["FileUpdateManyMutationInput"]
};
	["FileScalarWhereInput"]: {
	AND?: Array<ModelTypes["FileScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["FileScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["FileScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	contentType?: ModelTypes["StringFilter"] | undefined,
	ctime?: ModelTypes["StringFilter"] | undefined,
	filename?: ModelTypes["StringFilter"] | undefined,
	filepath?: ModelTypes["StringNullableFilter"] | undefined,
	isDirectory?: ModelTypes["BoolFilter"] | undefined,
	mtime?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	size?: ModelTypes["IntFilter"] | undefined,
	status?: ModelTypes["EnumFileStatusNullableFilter"] | undefined,
	type?: ModelTypes["StringFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	createdByUserId?: ModelTypes["StringFilter"] | undefined,
	url?: ModelTypes["StringFilter"] | undefined,
	bookingsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	proofBookingId?: ModelTypes["StringNullableFilter"] | undefined,
	campaignIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["FileUpdateManyMutationInput"]: {
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: ModelTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutBookingsInput"]
};
	["BookingUpdateToOneWithWhereWithoutProofsInput"]: {
	where?: ModelTypes["BookingWhereInput"] | undefined,
	data: ModelTypes["BookingUpdateWithoutProofsInput"]
};
	["FileUpdateWithWhereUniqueWithoutCampaignsInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	data: ModelTypes["FileUpdateWithoutCampaignsInput"]
};
	["FileUpdateManyWithWhereWithoutCampaignsInput"]: {
	where: ModelTypes["FileScalarWhereInput"],
	data: ModelTypes["FileUpdateManyMutationInput"]
};
	["CampaignUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ModelTypes["CampaignWhereInput"] | undefined,
	data: ModelTypes["CampaignUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	data: ModelTypes["BookingUpdateWithoutWorkspaceInput"]
};
	["BookingUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["BookingScalarWhereInput"],
	data: ModelTypes["BookingUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"]: {
	where?: ModelTypes["AdBoardGroupWhereInput"] | undefined,
	data: ModelTypes["AdBoardGroupUpdateWithoutAdBoardInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutHistoryInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutHistoryInput"]
};
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]: {
	where: ModelTypes["AdBoardPublishHistoryWhereUniqueInput"],
	data: ModelTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]: {
	where: ModelTypes["AdBoardPublishHistoryScalarWhereInput"],
	data: ModelTypes["AdBoardPublishHistoryUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutAuditsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutAuditsInput"]
};
	["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["AuditWhereUniqueInput"],
	data: ModelTypes["AuditUpdateWithoutWorkspaceInput"]
};
	["AuditUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["AuditScalarWhereInput"],
	data: ModelTypes["AuditUpdateManyMutationInput"]
};
	["AuditScalarWhereInput"]: {
	AND?: Array<ModelTypes["AuditScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AuditScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AuditScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	message?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["StringFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	workspaceId?: ModelTypes["StringNullableFilter"] | undefined,
	updates?: ModelTypes["JsonNullableFilter"] | undefined
};
	["AuditUpdateManyMutationInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	updates?: ModelTypes["JSON"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutPlayersInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	data: ModelTypes["PlayerUpdateWithoutAdBoardGroupInput"]
};
	["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]: {
	where: ModelTypes["PlayerScalarWhereInput"],
	data: ModelTypes["PlayerUpdateManyMutationInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	data: ModelTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]: {
	where: ModelTypes["AdBoardGroupScalarWhereInput"],
	data: ModelTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["AdBoardGroupScalarWhereInput"]: {
	AND?: Array<ModelTypes["AdBoardGroupScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdBoardGroupScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdBoardGroupScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringNullableFilter"] | undefined,
	scheduledPlaylistsIDs?: ModelTypes["StringNullableListFilter"] | undefined,
	adsPlaylistID?: ModelTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: ModelTypes["StringFilter"] | undefined,
	fitImage?: ModelTypes["EnumFitTypeFilter"] | undefined,
	fitVideo?: ModelTypes["EnumFitTypeFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined
};
	["AdBoardGroupUpdateManyMutationInput"]: {
	name?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined
};
	["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"]: {
	create?: Array<ModelTypes["PlaylistCreateWithoutFooterPlaylistInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]> | undefined,
	upsert?: Array<ModelTypes["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]> | undefined,
	createMany?: ModelTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]> | undefined,
	updateMany?: Array<ModelTypes["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PlaylistScalarWhereInput"]> | undefined
};
	["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	update: ModelTypes["PlaylistUpdateWithoutFooterPlaylistInput"],
	create: ModelTypes["PlaylistCreateWithoutFooterPlaylistInput"]
};
	["PlaylistUpdateWithoutFooterPlaylistInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"]: {
	create?: Array<ModelTypes["PlaylistCreateWithoutSidebarPlaylistInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]> | undefined,
	upsert?: Array<ModelTypes["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]> | undefined,
	createMany?: ModelTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"] | undefined,
	set?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["PlaylistWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]> | undefined,
	updateMany?: Array<ModelTypes["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]> | undefined,
	deleteMany?: Array<ModelTypes["PlaylistScalarWhereInput"]> | undefined
};
	["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	update: ModelTypes["PlaylistUpdateWithoutSidebarPlaylistInput"],
	create: ModelTypes["PlaylistCreateWithoutSidebarPlaylistInput"]
};
	["PlaylistUpdateWithoutSidebarPlaylistInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	data: ModelTypes["PlaylistUpdateWithoutSidebarPlaylistInput"]
};
	["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]: {
	where: ModelTypes["PlaylistScalarWhereInput"],
	data: ModelTypes["PlaylistUpdateManyMutationInput"]
};
	["PlaylistScalarWhereInput"]: {
	AND?: Array<ModelTypes["PlaylistScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlaylistScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlaylistScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	displayName?: ModelTypes["StringNullableFilter"] | undefined,
	isAdsPlaylist?: ModelTypes["BoolNullableFilter"] | undefined,
	footerPlaylistId?: ModelTypes["StringNullableFilter"] | undefined,
	sidebarPlaylistId?: ModelTypes["StringNullableFilter"] | undefined,
	layout?: ModelTypes["EnumScreenLayoutFilter"] | undefined,
	isDeployed?: ModelTypes["BoolNullableFilter"] | undefined,
	adBoardGroupIds?: ModelTypes["StringNullableListFilter"] | undefined,
	workspaceId?: ModelTypes["StringFilter"] | undefined
};
	["PlaylistUpdateManyMutationInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined
};
	["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	data: ModelTypes["PlaylistUpdateWithoutFooterPlaylistInput"]
};
	["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]: {
	where: ModelTypes["PlaylistScalarWhereInput"],
	data: ModelTypes["PlaylistUpdateManyMutationInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"]: {
	where?: ModelTypes["PlaylistWhereInput"] | undefined,
	data: ModelTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	data: ModelTypes["AdBoardGroupUpdateWithoutWorkspaceInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["AdBoardGroupScalarWhereInput"],
	data: ModelTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutPlaylistsInput"]
};
	["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	data: ModelTypes["PlaylistUpdateWithoutAdBoardGroupsInput"]
};
	["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]: {
	where: ModelTypes["PlaylistScalarWhereInput"],
	data: ModelTypes["PlaylistUpdateManyMutationInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]: {
	where: ModelTypes["AdBoardGroupWhereUniqueInput"],
	data: ModelTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]: {
	where: ModelTypes["AdBoardGroupScalarWhereInput"],
	data: ModelTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"]: {
	where?: ModelTypes["PlaylistWhereInput"] | undefined,
	data: ModelTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"]: {
	where?: ModelTypes["PlaylistWhereInput"] | undefined,
	data: ModelTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"]
};
	["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["PlaylistWhereUniqueInput"],
	data: ModelTypes["PlaylistUpdateWithoutWorkspaceInput"]
};
	["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["PlaylistScalarWhereInput"],
	data: ModelTypes["PlaylistUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutAdBoardGroupInput"]
};
	["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"]: {
	where?: ModelTypes["AdBoardGroupWhereInput"] | undefined,
	data: ModelTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]: {
	where: ModelTypes["PlayerWhereUniqueInput"],
	data: ModelTypes["PlayerUpdateWithoutAdBoardsInput"]
};
	["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]: {
	where: ModelTypes["PlayerScalarWhereInput"],
	data: ModelTypes["PlayerUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutWorkspaceInput"]
};
	["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutMembershipsInput"]
};
	["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"]: {
	create?: ModelTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"] | undefined,
	upsert?: ModelTypes["WorkspaceRoleUpsertWithoutMembershipsInput"] | undefined,
	connect?: ModelTypes["WorkspaceRoleWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"] | undefined
};
	["WorkspaceRoleUpsertWithoutMembershipsInput"]: {
	update: ModelTypes["WorkspaceRoleUpdateWithoutMembershipsInput"],
	create: ModelTypes["WorkspaceRoleCreateWithoutMembershipsInput"],
	where?: ModelTypes["WorkspaceRoleWhereInput"] | undefined
};
	["WorkspaceRoleUpdateWithoutMembershipsInput"]: {
	name?: string | undefined,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutWorkspaceRolesInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: ModelTypes["WorkspaceWhereInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"] | undefined
};
	["WorkspaceUpsertWithoutWorkspaceRolesInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"],
	create: ModelTypes["WorkspaceCreateWithoutWorkspaceRolesInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutWorkspaceRolesInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"]
};
	["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ModelTypes["WorkspaceRoleWhereInput"] | undefined,
	data: ModelTypes["WorkspaceRoleUpdateWithoutMembershipsInput"]
};
	["MembershipUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	data: ModelTypes["MembershipUpdateWithoutUserInput"]
};
	["MembershipUpdateManyWithWhereWithoutUserInput"]: {
	where: ModelTypes["MembershipScalarWhereInput"],
	data: ModelTypes["MembershipUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutChangelogsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutChangelogsInput"]
};
	["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]: {
	where: ModelTypes["ChangelogWhereUniqueInput"],
	data: ModelTypes["ChangelogUpdateWithoutBookingInput"]
};
	["ChangelogUpdateManyWithWhereWithoutBookingInput"]: {
	where: ModelTypes["ChangelogScalarWhereInput"],
	data: ModelTypes["ChangelogUpdateManyMutationInput"]
};
	["ChangelogScalarWhereInput"]: {
	AND?: Array<ModelTypes["ChangelogScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["ChangelogScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["ChangelogScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	message?: ModelTypes["StringFilter"] | undefined,
	action?: ModelTypes["StringFilter"] | undefined,
	bookingId?: ModelTypes["StringNullableFilter"] | undefined,
	userId?: ModelTypes["StringFilter"] | undefined,
	updates?: ModelTypes["JsonNullableFilter"] | undefined
};
	["ChangelogUpdateManyMutationInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	updates?: ModelTypes["JSON"] | undefined
};
	["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	data: ModelTypes["BookingUpdateWithoutSharedUsersInput"]
};
	["BookingUpdateManyWithWhereWithoutSharedUsersInput"]: {
	where: ModelTypes["BookingScalarWhereInput"],
	data: ModelTypes["BookingUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	data: ModelTypes["BookingUpdateWithoutAdCategoryInput"]
};
	["BookingUpdateManyWithWhereWithoutAdCategoryInput"]: {
	where: ModelTypes["BookingScalarWhereInput"],
	data: ModelTypes["BookingUpdateManyMutationInput"]
};
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]: {
	where: ModelTypes["AdvertisementCategoryWhereUniqueInput"],
	data: ModelTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"]
};
	["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]: {
	where: ModelTypes["AdvertisementCategoryScalarWhereInput"],
	data: ModelTypes["AdvertisementCategoryUpdateManyMutationInput"]
};
	["AdvertisementCategoryScalarWhereInput"]: {
	AND?: Array<ModelTypes["AdvertisementCategoryScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["AdvertisementCategoryScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["AdvertisementCategoryScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["EnumAdvertisementCategoryEnumFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	campaignIds?: ModelTypes["StringNullableListFilter"] | undefined,
	bookingIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["AdvertisementCategoryUpdateManyMutationInput"]: {
	name?: string | undefined,
	type?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["CampaignUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	data: ModelTypes["CampaignUpdateWithoutUserInput"]
};
	["CampaignUpdateManyWithWhereWithoutUserInput"]: {
	where: ModelTypes["CampaignScalarWhereInput"],
	data: ModelTypes["CampaignUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutMembershipsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutMembershipsInput"]
};
	["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
	where: ModelTypes["MembershipWhereUniqueInput"],
	data: ModelTypes["MembershipUpdateWithoutWorkspaceInput"]
};
	["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]: {
	where: ModelTypes["MembershipScalarWhereInput"],
	data: ModelTypes["MembershipUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutAuditsInput"]
};
	["AuditUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["AuditWhereUniqueInput"],
	data: ModelTypes["AuditUpdateWithoutUserInput"]
};
	["AuditUpdateManyWithWhereWithoutUserInput"]: {
	where: ModelTypes["AuditScalarWhereInput"],
	data: ModelTypes["AuditUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOrdersInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutOrdersInput"]
};
	["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["OrderWhereUniqueInput"],
	data: ModelTypes["OrderUpdateWithoutAdBoardInput"]
};
	["OrderUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ModelTypes["OrderScalarWhereInput"],
	data: ModelTypes["OrderUpdateManyMutationInput"]
};
	["OrderScalarWhereInput"]: {
	AND?: Array<ModelTypes["OrderScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["OrderScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["OrderScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	adBoardId?: ModelTypes["StringFilter"] | undefined,
	createdDate?: ModelTypes["DateTimeFilter"] | undefined,
	createdByUserId?: ModelTypes["StringNullableFilter"] | undefined,
	origin?: ModelTypes["StringFilter"] | undefined,
	paymentId?: ModelTypes["StringNullableFilter"] | undefined
};
	["OrderUpdateManyMutationInput"]: {
	createdDate?: ModelTypes["DateTime"] | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDate?: ModelTypes["OrderSelectedDateCreateInput"] | undefined
};
	["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutCreatedByInput"]
};
	["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutBookingsInput"]
};
	["BookingUpdateToOneWithWhereWithoutChangelogsInput"]: {
	where?: ModelTypes["BookingWhereInput"] | undefined,
	data: ModelTypes["BookingUpdateWithoutChangelogsInput"]
};
	["ChangelogUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["ChangelogWhereUniqueInput"],
	data: ModelTypes["ChangelogUpdateWithoutUserInput"]
};
	["ChangelogUpdateManyWithWhereWithoutUserInput"]: {
	where: ModelTypes["ChangelogScalarWhereInput"],
	data: ModelTypes["ChangelogUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutOfflineBookingAdboardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutOrdersInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutOrdersInput"]
};
	["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ModelTypes["OrderWhereUniqueInput"],
	data: ModelTypes["OrderUpdateWithoutCreatedByInput"]
};
	["OrderUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ModelTypes["OrderScalarWhereInput"],
	data: ModelTypes["OrderUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutOwnedWorkspacesInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutFilesInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	data: ModelTypes["FileUpdateWithoutUpdatedByInput"]
};
	["FileUpdateManyWithWhereWithoutUpdatedByInput"]: {
	where: ModelTypes["FileScalarWhereInput"],
	data: ModelTypes["FileUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutCreatedFilesInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutCreatedFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutProofBookingInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	data: ModelTypes["FileUpdateWithoutProofBookingInput"]
};
	["FileUpdateManyWithWhereWithoutProofBookingInput"]: {
	where: ModelTypes["FileScalarWhereInput"],
	data: ModelTypes["FileUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	data: ModelTypes["BookingUpdateWithoutBusinessCategoryInput"]
};
	["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]: {
	where: ModelTypes["BookingScalarWhereInput"],
	data: ModelTypes["BookingUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"]: {
	where?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	data: ModelTypes["BusinessCategoryUpdateWithoutCampaignsInput"]
};
	["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	data: ModelTypes["CampaignUpdateWithoutAdCategoryInput"]
};
	["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]: {
	where: ModelTypes["CampaignScalarWhereInput"],
	data: ModelTypes["CampaignUpdateManyMutationInput"]
};
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]: {
	where: ModelTypes["AdvertisementCategoryWhereUniqueInput"],
	data: ModelTypes["AdvertisementCategoryUpdateWithoutBookingsInput"]
};
	["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]: {
	where: ModelTypes["AdvertisementCategoryScalarWhereInput"],
	data: ModelTypes["AdvertisementCategoryUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAssetsInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	data: ModelTypes["BookingUpdateWithoutAssetsInput"]
};
	["BookingUpdateManyWithWhereWithoutAssetsInput"]: {
	where: ModelTypes["BookingScalarWhereInput"],
	data: ModelTypes["BookingUpdateManyMutationInput"]
};
	["FileUpdateWithWhereUniqueWithoutCreatedByInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	data: ModelTypes["FileUpdateWithoutCreatedByInput"]
};
	["FileUpdateManyWithWhereWithoutCreatedByInput"]: {
	where: ModelTypes["FileScalarWhereInput"],
	data: ModelTypes["FileUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutCampaignsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutCampaignsInput"]
};
	["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: {
	where: ModelTypes["CampaignWhereUniqueInput"],
	data: ModelTypes["CampaignUpdateWithoutBusinessCategoryInput"]
};
	["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]: {
	where: ModelTypes["CampaignScalarWhereInput"],
	data: ModelTypes["CampaignUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"]: {
	where?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	data: ModelTypes["BusinessCategoryUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutUserInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	data: ModelTypes["BookingUpdateWithoutUserInput"]
};
	["BookingUpdateManyWithWhereWithoutUserInput"]: {
	where: ModelTypes["BookingScalarWhereInput"],
	data: ModelTypes["BookingUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutUpdatedFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutBookingsInput"]: {
	where: ModelTypes["FileWhereUniqueInput"],
	data: ModelTypes["FileUpdateWithoutBookingsInput"]
};
	["FileUpdateManyWithWhereWithoutBookingsInput"]: {
	where: ModelTypes["FileScalarWhereInput"],
	data: ModelTypes["FileUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]: {
	where: ModelTypes["BookingWhereUniqueInput"],
	data: ModelTypes["BookingUpdateWithoutAdBoardInput"]
};
	["BookingUpdateManyWithWhereWithoutAdBoardInput"]: {
	where: ModelTypes["BookingScalarWhereInput"],
	data: ModelTypes["BookingUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutCategoryInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: {
	where: ModelTypes["BusinessCategoryWhereUniqueInput"],
	data: ModelTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"]
};
	["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]: {
	where: ModelTypes["BusinessCategoryScalarWhereInput"],
	data: ModelTypes["BusinessCategoryUpdateManyMutationInput"]
};
	["BusinessCategoryScalarWhereInput"]: {
	AND?: Array<ModelTypes["BusinessCategoryScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BusinessCategoryScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BusinessCategoryScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	description?: ModelTypes["StringNullableFilter"] | undefined,
	restrictedByAdBoardIds?: ModelTypes["StringNullableListFilter"] | undefined
};
	["BusinessCategoryUpdateManyMutationInput"]: {
	name?: string | undefined,
	description?: string | undefined
};
	["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutLocationCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"]: {
	where?: ModelTypes["LocationCategoryWhereInput"] | undefined,
	data: ModelTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"]
};
	["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ModelTypes["LocationSubCategoryWhereInput"] | undefined,
	data: ModelTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutRestrictedBusinesInput"]
};
	["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ModelTypes["BusinessCategoryWhereInput"] | undefined,
	data: ModelTypes["BusinessCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutLocationSubCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]: {
	where: ModelTypes["LocationSubCategoryWhereUniqueInput"],
	data: ModelTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]: {
	where: ModelTypes["LocationSubCategoryScalarWhereInput"],
	data: ModelTypes["LocationSubCategoryUpdateManyMutationInput"]
};
	["LocationSubCategoryScalarWhereInput"]: {
	AND?: Array<ModelTypes["LocationSubCategoryScalarWhereInput"]> | undefined,
	OR?: Array<ModelTypes["LocationSubCategoryScalarWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["LocationSubCategoryScalarWhereInput"]> | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	locationCategoryId?: ModelTypes["StringNullableFilter"] | undefined
};
	["LocationSubCategoryUpdateManyMutationInput"]: {
	name?: string | undefined
};
	["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
	where?: ModelTypes["LocationCategoryWhereInput"] | undefined,
	data: ModelTypes["LocationCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardGroupCreateManyInput"]: {
	id?: string | undefined,
	name: string,
	adBoardId?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	scheduledPlaylistsIDs?: Array<string> | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	workspaceId: string
};
	["AdBoardGroupCreateInput"]: {
	id?: string | undefined,
	name: string,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupUpdateInput"]: {
	name?: string | undefined,
	schedules?: Array<ModelTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: ModelTypes["FitType"] | undefined,
	fitVideo?: ModelTypes["FitType"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	scheduledPlaylists?: ModelTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	adsPlaylist?: ModelTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	pisignagePlayers?: ModelTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["AdBoardTypeCreateManyInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined
};
	["AdBoardTypeCreateInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutTypeInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutTypeInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutTypeInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutTypeInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyTypeInputEnvelope"] | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutTypeInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardCreateOrConnectWithoutTypeInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutTypeInput"]
};
	["AdBoardCreateManyTypeInputEnvelope"]: {
	data: Array<ModelTypes["AdBoardCreateManyTypeInput"]>
};
	["AdBoardCreateManyTypeInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["AdBoardTypeUpdateManyMutationInput"]: {
	name?: string | undefined,
	description?: string | undefined
};
	["AdBoardTypeUpdateInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutTypeNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutTypeNestedInput"]: {
	create?: Array<ModelTypes["AdBoardCreateWithoutTypeInput"]> | undefined,
	connectOrCreate?: Array<ModelTypes["AdBoardCreateOrConnectWithoutTypeInput"]> | undefined,
	upsert?: Array<ModelTypes["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]> | undefined,
	createMany?: ModelTypes["AdBoardCreateManyTypeInputEnvelope"] | undefined,
	set?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<ModelTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<ModelTypes["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]> | undefined,
	updateMany?: Array<ModelTypes["AdBoardUpdateManyWithWhereWithoutTypeInput"]> | undefined,
	deleteMany?: Array<ModelTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	update: ModelTypes["AdBoardUpdateWithoutTypeInput"],
	create: ModelTypes["AdBoardCreateWithoutTypeInput"]
};
	["AdBoardUpdateWithoutTypeInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: ModelTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	data: ModelTypes["AdBoardUpdateWithoutTypeInput"]
};
	["AdBoardUpdateManyWithWhereWithoutTypeInput"]: {
	where: ModelTypes["AdBoardScalarWhereInput"],
	data: ModelTypes["AdBoardUpdateManyMutationInput"]
};
	["AdvertisementCategoryCreateManyInput"]: {
	id?: string | undefined,
	name: string,
	type: ModelTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	campaignIds?: Array<string> | undefined,
	bookingIds?: Array<string> | undefined
};
	["AdvertisementCategoryCreateInput"]: {
	id?: string | undefined,
	name: string,
	type: ModelTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutAdCategoryInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdCategoryInput"] | undefined
};
	["AdvertisementCategoryUpdateInput"]: {
	name?: string | undefined,
	type?: ModelTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdCategoryNestedInput"] | undefined
};
	["AuditCreateManyInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	userId: string,
	workspaceId?: string | undefined,
	updates?: ModelTypes["JSON"] | undefined
};
	["AuditCreateInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutAuditsInput"],
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutAuditsInput"] | undefined
};
	["AuditUpdateInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	entity?: ModelTypes["EntityCreateInput"] | undefined,
	updates?: ModelTypes["JSON"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"] | undefined
};
	["BookingCreateManyInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["BookingCreateInput"]: {
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: ModelTypes["BookingDetailsCreateInput"],
	bookingStatus: ModelTypes["BookingStatus"],
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus: ModelTypes["DeploymentStatus"],
	contentApprovalStatus: ModelTypes["ContentApprovalStatus"],
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: ModelTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: ModelTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: ModelTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: ModelTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["BookingUpdateInput"]: {
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: ModelTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: ModelTypes["BookingStatus"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	deploymentStatus?: ModelTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: ModelTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: ModelTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: ModelTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: ModelTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: ModelTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: ModelTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: ModelTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: ModelTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["BusinessCategoryCreateManyInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined,
	restrictedByAdBoardIds?: Array<string> | undefined
};
	["BusinessCategoryCreateInput"]: {
	id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["BusinessCategoryUpdateInput"]: {
	name?: string | undefined,
	description?: string | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined,
	restrictedByAdBoards?: ModelTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["CameraCreateManyInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	playerId?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["CameraCreateInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer: ModelTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: ModelTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined,
	feeds?: ModelTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined,
	adBoard?: ModelTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined
};
	["CameraUpdateInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer?: ModelTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined,
	player?: ModelTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined,
	feeds?: ModelTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined
};
	["CameraFeedCreateManyInput"]: {
	id?: string | undefined,
	cameraId: string,
	timestamp: ModelTypes["DateTime"],
	cameraServerId: string,
	data: ModelTypes["CameraFeedDataCreateInput"]
};
	["CameraFeedCreateInput"]: {
	id?: string | undefined,
	timestamp: ModelTypes["DateTime"],
	data: ModelTypes["CameraFeedDataCreateInput"],
	camera: ModelTypes["CameraCreateNestedOneWithoutFeedsInput"],
	cameraServer: ModelTypes["CameraServerCreateNestedOneWithoutFeedsInput"]
};
	["CameraFeedUpdateInput"]: {
	timestamp?: ModelTypes["DateTime"] | undefined,
	data?: ModelTypes["CameraFeedDataCreateInput"] | undefined,
	camera?: ModelTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"] | undefined,
	cameraServer?: ModelTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"] | undefined
};
	["CameraServerCreateManyInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	workspaceId?: string | undefined,
	publicIp: string,
	privateIp: string
};
	["CameraServerCreateInput"]: {
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined,
	feeds?: ModelTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined
};
	["CameraServerUpdateInput"]: {
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined,
	feeds?: ModelTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined
};
	["CampaignCreateManyInput"]: {
	id?: string | undefined,
	name: string,
	userId: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	businessCategoryId: string,
	adCategoryId?: Array<string> | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined
};
	["CampaignCreateInput"]: {
	id?: string | undefined,
	name: string,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: ModelTypes["PaymentStatus"],
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: ModelTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: ModelTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: ModelTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: ModelTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: ModelTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: ModelTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["CampaignUpdateInput"]: {
	name?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: ModelTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: ModelTypes["PaymentStatus"] | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	businessCategory?: ModelTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: ModelTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: ModelTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: ModelTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["FileCreateManyInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["FileCreateInput"]: {
	id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: ModelTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: ModelTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: ModelTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: ModelTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: ModelTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["FileUpdateInput"]: {
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: ModelTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: ModelTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: ModelTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: ModelTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["MembershipCreateManyInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	userId: string,
	workspaceId: string,
	roleId: string
};
	["MembershipCreateInput"]: {
	id?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutMembershipsInput"],
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"],
	role: ModelTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["MembershipUpdateInput"]: {
	createdAt?: ModelTypes["DateTime"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	role?: ModelTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined
};
	["NotificationCreateManyInput"]: {
	id?: string | undefined,
	type: ModelTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	recipientId: string,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined
};
	["NotificationCreateInput"]: {
	id?: string | undefined,
	type: ModelTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined,
	recipient: ModelTypes["UserCreateNestedOneWithoutNotificationsInput"]
};
	["UserCreateNestedOneWithoutNotificationsInput"]: {
	create?: ModelTypes["UserCreateWithoutNotificationsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutNotificationsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutNotificationsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["UserCreateOrConnectWithoutNotificationsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutNotificationsInput"]
};
	["NotificationUpdateInput"]: {
	type?: ModelTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	metadata?: ModelTypes["JSON"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	readAt?: ModelTypes["DateTime"] | undefined,
	channels?: Array<ModelTypes["NotificationChannel"]> | undefined,
	recipient?: ModelTypes["UserUpdateOneRequiredWithoutNotificationsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutNotificationsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutNotificationsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutNotificationsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutNotificationsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutNotificationsInput"] | undefined
};
	["UserUpsertWithoutNotificationsInput"]: {
	update: ModelTypes["UserUpdateWithoutNotificationsInput"],
	create: ModelTypes["UserCreateWithoutNotificationsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutNotificationsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["UserUpdateToOneWithWhereWithoutNotificationsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutNotificationsInput"]
};
	["OrderCreateManyInput"]: {
	id?: string | undefined,
	adBoardId: string,
	createdDate?: ModelTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: ModelTypes["PriceSummaryCreateInput"],
	selectedDate: ModelTypes["OrderSelectedDateCreateInput"]
};
	["OrderCreateInput"]: {
	id?: string | undefined,
	createdDate?: ModelTypes["DateTime"] | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: ModelTypes["PriceSummaryCreateInput"],
	selectedDate: ModelTypes["OrderSelectedDateCreateInput"],
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutOrdersInput"],
	createdBy?: ModelTypes["UserCreateNestedOneWithoutOrdersInput"] | undefined
};
	["OrderUpdateInput"]: {
	createdDate?: ModelTypes["DateTime"] | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined,
	priceSummary?: ModelTypes["PriceSummaryCreateInput"] | undefined,
	selectedDate?: ModelTypes["OrderSelectedDateCreateInput"] | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutOrdersNestedInput"] | undefined
};
	["PlayerCreateManyInput"]: {
	id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	playlistIds?: Array<string> | undefined,
	workpaceId?: string | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId?: Array<string> | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardIds?: Array<string> | undefined
};
	["PlayerCreateInput"]: {
	id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: ModelTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined
};
	["PlayerUpdateInput"]: {
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: ModelTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<ModelTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined,
	status?: ModelTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: ModelTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: ModelTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["PlaylistCreateManyInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined,
	workspaceId: string
};
	["PlaylistCreateInput"]: {
	id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PlaylistUpdateInput"]: {
	name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<ModelTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: ModelTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: ModelTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: ModelTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: ModelTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: ModelTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["SlotConfigurationCreateManyInput"]: {
	id?: string | undefined,
	adBoardId: string,
	date: ModelTypes["DateTime"],
	availableSlots: number
};
	["SlotConfigurationCreateInput"]: {
	id?: string | undefined,
	date: ModelTypes["DateTime"],
	availableSlots: number,
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"]
};
	["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutSlotConfigurationsInput"]: {
	id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: ModelTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status: ModelTypes["AdBoardStatus"],
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: ModelTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: ModelTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: ModelTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: ModelTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: ModelTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: ModelTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: ModelTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"]: {
	where: ModelTypes["AdBoardWhereUniqueInput"],
	create: ModelTypes["AdBoardCreateWithoutSlotConfigurationsInput"]
};
	["SlotConfigurationUpdateInput"]: {
	date?: ModelTypes["DateTime"] | undefined,
	availableSlots?: number | undefined,
	adBoard?: ModelTypes["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"]: {
	create?: ModelTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | undefined,
	connectOrCreate?: ModelTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"] | undefined,
	upsert?: ModelTypes["AdBoardUpsertWithoutSlotConfigurationsInput"] | undefined,
	connect?: ModelTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: ModelTypes["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"] | undefined
};
	["AdBoardUpsertWithoutSlotConfigurationsInput"]: {
	update: ModelTypes["AdBoardUpdateWithoutSlotConfigurationsInput"],
	create: ModelTypes["AdBoardCreateWithoutSlotConfigurationsInput"],
	where?: ModelTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutSlotConfigurationsInput"]: {
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: ModelTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<ModelTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: ModelTypes["ResolutionCreateInput"] | undefined,
	screenSize?: ModelTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<ModelTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: ModelTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: ModelTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<ModelTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: ModelTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: ModelTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	locationCategory?: ModelTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: ModelTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: ModelTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: ModelTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: ModelTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: ModelTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: ModelTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: ModelTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: ModelTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: ModelTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: ModelTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: ModelTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"]: {
	where?: ModelTypes["AdBoardWhereInput"] | undefined,
	data: ModelTypes["AdBoardUpdateWithoutSlotConfigurationsInput"]
};
	["SubscriptionCreateManyInput"]: {
	id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsCreateInput"] | undefined,
	activeWorkspaceId: string
};
	["SubscriptionCreateInput"]: {
	id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsCreateInput"] | undefined,
	activeWorkspace: ModelTypes["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"]
};
	["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutActiveSubscriptionInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"]: {
	where: ModelTypes["WorkspaceWhereUniqueInput"],
	create: ModelTypes["WorkspaceCreateWithoutActiveSubscriptionInput"]
};
	["SubscriptionUpdateManyMutationInput"]: {
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsCreateInput"] | undefined
};
	["SubscriptionUpdateInput"]: {
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: ModelTypes["CancellationDetailsCreateInput"] | undefined,
	activeWorkspace?: ModelTypes["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"]: {
	create?: ModelTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | undefined,
	connectOrCreate?: ModelTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"] | undefined,
	upsert?: ModelTypes["WorkspaceUpsertWithoutActiveSubscriptionInput"] | undefined,
	connect?: ModelTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: ModelTypes["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"] | undefined
};
	["WorkspaceUpsertWithoutActiveSubscriptionInput"]: {
	update: ModelTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"],
	create: ModelTypes["WorkspaceCreateWithoutActiveSubscriptionInput"],
	where?: ModelTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutActiveSubscriptionInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"]: {
	where?: ModelTypes["WorkspaceWhereInput"] | undefined,
	data: ModelTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"]
};
	["UserCreateManyInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	sharedBookingIds?: Array<string> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	leadWorkspaceIds?: Array<string> | undefined
};
	["UserCreateInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: ModelTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["UserUpdateInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: ModelTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["UserPositionCreateManyInput"]: {
	id?: string | undefined,
	timestamp: ModelTypes["DateTime"],
	createdAt?: ModelTypes["DateTime"] | undefined,
	userId: string,
	coords: ModelTypes["UserPositionCoordsCreateInput"],
	location?: ModelTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserPositionCreateInput"]: {
	id?: string | undefined,
	timestamp: ModelTypes["DateTime"],
	createdAt?: ModelTypes["DateTime"] | undefined,
	coords: ModelTypes["UserPositionCoordsCreateInput"],
	location?: ModelTypes["UserPositionLocationCreateInput"] | undefined,
	user: ModelTypes["UserCreateNestedOneWithoutUserPositionsInput"]
};
	["UserCreateNestedOneWithoutUserPositionsInput"]: {
	create?: ModelTypes["UserCreateWithoutUserPositionsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutUserPositionsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutUserPositionsInput"]: {
	id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: ModelTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: ModelTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: ModelTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: ModelTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: ModelTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: ModelTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: ModelTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined
};
	["UserCreateOrConnectWithoutUserPositionsInput"]: {
	where: ModelTypes["UserWhereUniqueInput"],
	create: ModelTypes["UserCreateWithoutUserPositionsInput"]
};
	["UserPositionUpdateInput"]: {
	timestamp?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	coords?: ModelTypes["UserPositionCoordsCreateInput"] | undefined,
	location?: ModelTypes["UserPositionLocationCreateInput"] | undefined,
	user?: ModelTypes["UserUpdateOneRequiredWithoutUserPositionsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutUserPositionsNestedInput"]: {
	create?: ModelTypes["UserCreateWithoutUserPositionsInput"] | undefined,
	connectOrCreate?: ModelTypes["UserCreateOrConnectWithoutUserPositionsInput"] | undefined,
	upsert?: ModelTypes["UserUpsertWithoutUserPositionsInput"] | undefined,
	connect?: ModelTypes["UserWhereUniqueInput"] | undefined,
	update?: ModelTypes["UserUpdateToOneWithWhereWithoutUserPositionsInput"] | undefined
};
	["UserUpsertWithoutUserPositionsInput"]: {
	update: ModelTypes["UserUpdateWithoutUserPositionsInput"],
	create: ModelTypes["UserCreateWithoutUserPositionsInput"],
	where?: ModelTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutUserPositionsInput"]: {
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: ModelTypes["JSON"] | undefined,
	stripe?: ModelTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<ModelTypes["Role"]> | undefined,
	refunds?: Array<ModelTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: ModelTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: ModelTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: ModelTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: ModelTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: ModelTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: ModelTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: ModelTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: ModelTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: ModelTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: ModelTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: ModelTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: ModelTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: ModelTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: ModelTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: ModelTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined
};
	["UserUpdateToOneWithWhereWithoutUserPositionsInput"]: {
	where?: ModelTypes["UserWhereInput"] | undefined,
	data: ModelTypes["UserUpdateWithoutUserPositionsInput"]
};
	["WorkspaceCreateManyInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	leadIds?: Array<string> | undefined
};
	["WorkspaceCreateInput"]: {
	id?: string | undefined,
	type?: ModelTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: ModelTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: ModelTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: ModelTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: ModelTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: ModelTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: ModelTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: ModelTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: ModelTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["WorkspaceUpdateInput"]: {
	type?: ModelTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: ModelTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: ModelTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: ModelTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: ModelTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: ModelTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: ModelTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: ModelTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: ModelTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: ModelTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: ModelTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: ModelTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: ModelTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: ModelTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: ModelTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["WorkspaceRoleCreateManyInput"]: {
	id?: string | undefined,
	name: string,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	workspaceId?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined
};
	["WorkspaceRoleCreateInput"]: {
	id?: string | undefined,
	name: string,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"] | undefined,
	memberships?: ModelTypes["MembershipCreateNestedManyWithoutRoleInput"] | undefined
};
	["WorkspaceRoleUpdateInput"]: {
	name?: string | undefined,
	permissions?: Array<ModelTypes["Permission"]> | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	workspace?: ModelTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"] | undefined,
	memberships?: ModelTypes["MembershipUpdateManyWithoutRoleNestedInput"] | undefined
};
	["AccountDetails"]: {
	displayName: string,
	phoneNumber: string,
	password: string
};
	["UserInput"]: {
	displayName: string,
	phoneNumber: string,
	password: string,
	email: string
};
	["GenericResponse"]: {
		success: boolean
};
	["EmailOutput"]: {
		success: boolean
};
	["TemplateIds"]:TemplateIds;
	["AdBoardPublicReviewCreateInput"]: {
	id?: string | undefined,
	rating: number,
	comment?: string | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	adBoard: ModelTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"],
	user: ModelTypes["UserCreateNestedOneWithoutPublicReviewsInput"]
};
	/** The recipe model */
["TypedGenericResponse"]: {
		success: boolean
};
	["BookingRequest"]: {
	assets: Array<string>,
	bookingDetails: ModelTypes["BookingDetailsInput"],
	orderId: string
};
	["BookingDetailsInput"]: {
	email: string,
	name: string,
	phone: string
};
	["schema"]: {
	query?: ModelTypes["Query"] | undefined,
	mutation?: ModelTypes["Mutation"] | undefined
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	aggregateAdBoard: GraphQLTypes["AggregateAdBoard"],
	findFirstAdBoard?: GraphQLTypes["AdBoard"] | undefined,
	findFirstAdBoardOrThrow?: GraphQLTypes["AdBoard"] | undefined,
	adBoards: Array<GraphQLTypes["AdBoard"]>,
	adBoard?: GraphQLTypes["AdBoard"] | undefined,
	getAdBoard?: GraphQLTypes["AdBoard"] | undefined,
	groupByAdBoard: Array<GraphQLTypes["AdBoardGroupBy"]>,
	aggregateAdBoardGroup: GraphQLTypes["AggregateAdBoardGroup"],
	findFirstAdBoardGroup?: GraphQLTypes["AdBoardGroup"] | undefined,
	findFirstAdBoardGroupOrThrow?: GraphQLTypes["AdBoardGroup"] | undefined,
	adBoardGroups: Array<GraphQLTypes["AdBoardGroup"]>,
	adBoardGroup?: GraphQLTypes["AdBoardGroup"] | undefined,
	getAdBoardGroup?: GraphQLTypes["AdBoardGroup"] | undefined,
	groupByAdBoardGroup: Array<GraphQLTypes["AdBoardGroupGroupBy"]>,
	aggregateAdBoardType: GraphQLTypes["AggregateAdBoardType"],
	findFirstAdBoardType?: GraphQLTypes["AdBoardType"] | undefined,
	findFirstAdBoardTypeOrThrow?: GraphQLTypes["AdBoardType"] | undefined,
	adBoardTypes: Array<GraphQLTypes["AdBoardType"]>,
	adBoardType?: GraphQLTypes["AdBoardType"] | undefined,
	getAdBoardType?: GraphQLTypes["AdBoardType"] | undefined,
	groupByAdBoardType: Array<GraphQLTypes["AdBoardTypeGroupBy"]>,
	aggregateAdvertisementCategory: GraphQLTypes["AggregateAdvertisementCategory"],
	findFirstAdvertisementCategory?: GraphQLTypes["AdvertisementCategory"] | undefined,
	findFirstAdvertisementCategoryOrThrow?: GraphQLTypes["AdvertisementCategory"] | undefined,
	advertisementCategories: Array<GraphQLTypes["AdvertisementCategory"]>,
	advertisementCategory?: GraphQLTypes["AdvertisementCategory"] | undefined,
	getAdvertisementCategory?: GraphQLTypes["AdvertisementCategory"] | undefined,
	groupByAdvertisementCategory: Array<GraphQLTypes["AdvertisementCategoryGroupBy"]>,
	aggregateAudit: GraphQLTypes["AggregateAudit"],
	findFirstAudit?: GraphQLTypes["Audit"] | undefined,
	findFirstAuditOrThrow?: GraphQLTypes["Audit"] | undefined,
	audits: Array<GraphQLTypes["Audit"]>,
	audit?: GraphQLTypes["Audit"] | undefined,
	getAudit?: GraphQLTypes["Audit"] | undefined,
	groupByAudit: Array<GraphQLTypes["AuditGroupBy"]>,
	aggregateBooking: GraphQLTypes["AggregateBooking"],
	findFirstBooking?: GraphQLTypes["Booking"] | undefined,
	findFirstBookingOrThrow?: GraphQLTypes["Booking"] | undefined,
	bookings: Array<GraphQLTypes["Booking"]>,
	booking?: GraphQLTypes["Booking"] | undefined,
	getBooking?: GraphQLTypes["Booking"] | undefined,
	groupByBooking: Array<GraphQLTypes["BookingGroupBy"]>,
	aggregateBusinessCategory: GraphQLTypes["AggregateBusinessCategory"],
	findFirstBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	findFirstBusinessCategoryOrThrow?: GraphQLTypes["BusinessCategory"] | undefined,
	businessCategories: Array<GraphQLTypes["BusinessCategory"]>,
	businessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	groupByBusinessCategory: Array<GraphQLTypes["BusinessCategoryGroupBy"]>,
	aggregateCamera: GraphQLTypes["AggregateCamera"],
	findFirstCamera?: GraphQLTypes["Camera"] | undefined,
	findFirstCameraOrThrow?: GraphQLTypes["Camera"] | undefined,
	cameras: Array<GraphQLTypes["Camera"]>,
	camera?: GraphQLTypes["Camera"] | undefined,
	getCamera?: GraphQLTypes["Camera"] | undefined,
	groupByCamera: Array<GraphQLTypes["CameraGroupBy"]>,
	aggregateCameraFeed: GraphQLTypes["AggregateCameraFeed"],
	findFirstCameraFeed?: GraphQLTypes["CameraFeed"] | undefined,
	findFirstCameraFeedOrThrow?: GraphQLTypes["CameraFeed"] | undefined,
	cameraFeeds: Array<GraphQLTypes["CameraFeed"]>,
	cameraFeed?: GraphQLTypes["CameraFeed"] | undefined,
	getCameraFeed?: GraphQLTypes["CameraFeed"] | undefined,
	groupByCameraFeed: Array<GraphQLTypes["CameraFeedGroupBy"]>,
	aggregateCameraServer: GraphQLTypes["AggregateCameraServer"],
	findFirstCameraServer?: GraphQLTypes["CameraServer"] | undefined,
	findFirstCameraServerOrThrow?: GraphQLTypes["CameraServer"] | undefined,
	cameraServers: Array<GraphQLTypes["CameraServer"]>,
	cameraServer?: GraphQLTypes["CameraServer"] | undefined,
	getCameraServer?: GraphQLTypes["CameraServer"] | undefined,
	groupByCameraServer: Array<GraphQLTypes["CameraServerGroupBy"]>,
	aggregateCampaign: GraphQLTypes["AggregateCampaign"],
	findFirstCampaign?: GraphQLTypes["Campaign"] | undefined,
	findFirstCampaignOrThrow?: GraphQLTypes["Campaign"] | undefined,
	campaigns: Array<GraphQLTypes["Campaign"]>,
	campaign?: GraphQLTypes["Campaign"] | undefined,
	getCampaign?: GraphQLTypes["Campaign"] | undefined,
	groupByCampaign: Array<GraphQLTypes["CampaignGroupBy"]>,
	aggregateFile: GraphQLTypes["AggregateFile"],
	findFirstFile?: GraphQLTypes["File"] | undefined,
	findFirstFileOrThrow?: GraphQLTypes["File"] | undefined,
	files: Array<GraphQLTypes["File"]>,
	file?: GraphQLTypes["File"] | undefined,
	getFile?: GraphQLTypes["File"] | undefined,
	groupByFile: Array<GraphQLTypes["FileGroupBy"]>,
	aggregateMembership: GraphQLTypes["AggregateMembership"],
	findFirstMembership?: GraphQLTypes["Membership"] | undefined,
	findFirstMembershipOrThrow?: GraphQLTypes["Membership"] | undefined,
	memberships: Array<GraphQLTypes["Membership"]>,
	membership?: GraphQLTypes["Membership"] | undefined,
	getMembership?: GraphQLTypes["Membership"] | undefined,
	groupByMembership: Array<GraphQLTypes["MembershipGroupBy"]>,
	aggregateNotification: GraphQLTypes["AggregateNotification"],
	findFirstNotification?: GraphQLTypes["Notification"] | undefined,
	findFirstNotificationOrThrow?: GraphQLTypes["Notification"] | undefined,
	notifications: Array<GraphQLTypes["Notification"]>,
	notification?: GraphQLTypes["Notification"] | undefined,
	getNotification?: GraphQLTypes["Notification"] | undefined,
	groupByNotification: Array<GraphQLTypes["NotificationGroupBy"]>,
	aggregateOrder: GraphQLTypes["AggregateOrder"],
	findFirstOrder?: GraphQLTypes["Order"] | undefined,
	findFirstOrderOrThrow?: GraphQLTypes["Order"] | undefined,
	orders: Array<GraphQLTypes["Order"]>,
	order?: GraphQLTypes["Order"] | undefined,
	getOrder?: GraphQLTypes["Order"] | undefined,
	groupByOrder: Array<GraphQLTypes["OrderGroupBy"]>,
	aggregatePlayer: GraphQLTypes["AggregatePlayer"],
	findFirstPlayer?: GraphQLTypes["Player"] | undefined,
	findFirstPlayerOrThrow?: GraphQLTypes["Player"] | undefined,
	players: Array<GraphQLTypes["Player"]>,
	player?: GraphQLTypes["Player"] | undefined,
	getPlayer?: GraphQLTypes["Player"] | undefined,
	groupByPlayer: Array<GraphQLTypes["PlayerGroupBy"]>,
	aggregatePlaylist: GraphQLTypes["AggregatePlaylist"],
	findFirstPlaylist?: GraphQLTypes["Playlist"] | undefined,
	findFirstPlaylistOrThrow?: GraphQLTypes["Playlist"] | undefined,
	playlists: Array<GraphQLTypes["Playlist"]>,
	playlist?: GraphQLTypes["Playlist"] | undefined,
	getPlaylist?: GraphQLTypes["Playlist"] | undefined,
	groupByPlaylist: Array<GraphQLTypes["PlaylistGroupBy"]>,
	aggregateSlotConfiguration: GraphQLTypes["AggregateSlotConfiguration"],
	findFirstSlotConfiguration?: GraphQLTypes["SlotConfiguration"] | undefined,
	findFirstSlotConfigurationOrThrow?: GraphQLTypes["SlotConfiguration"] | undefined,
	slotConfigurations: Array<GraphQLTypes["SlotConfiguration"]>,
	slotConfiguration?: GraphQLTypes["SlotConfiguration"] | undefined,
	getSlotConfiguration?: GraphQLTypes["SlotConfiguration"] | undefined,
	groupBySlotConfiguration: Array<GraphQLTypes["SlotConfigurationGroupBy"]>,
	aggregateSubscription: GraphQLTypes["AggregateSubscription"],
	findFirstSubscription?: GraphQLTypes["Subscription"] | undefined,
	findFirstSubscriptionOrThrow?: GraphQLTypes["Subscription"] | undefined,
	subscriptions: Array<GraphQLTypes["Subscription"]>,
	subscription?: GraphQLTypes["Subscription"] | undefined,
	getSubscription?: GraphQLTypes["Subscription"] | undefined,
	groupBySubscription: Array<GraphQLTypes["SubscriptionGroupBy"]>,
	aggregateUser: GraphQLTypes["AggregateUser"],
	findFirstUser?: GraphQLTypes["User"] | undefined,
	findFirstUserOrThrow?: GraphQLTypes["User"] | undefined,
	users: Array<GraphQLTypes["User"]>,
	user?: GraphQLTypes["User"] | undefined,
	getUser?: GraphQLTypes["User"] | undefined,
	groupByUser: Array<GraphQLTypes["UserGroupBy"]>,
	aggregateUserPosition: GraphQLTypes["AggregateUserPosition"],
	findFirstUserPosition?: GraphQLTypes["UserPosition"] | undefined,
	findFirstUserPositionOrThrow?: GraphQLTypes["UserPosition"] | undefined,
	userPositions: Array<GraphQLTypes["UserPosition"]>,
	userPosition?: GraphQLTypes["UserPosition"] | undefined,
	getUserPosition?: GraphQLTypes["UserPosition"] | undefined,
	groupByUserPosition: Array<GraphQLTypes["UserPositionGroupBy"]>,
	aggregateWorkspace: GraphQLTypes["AggregateWorkspace"],
	findFirstWorkspace?: GraphQLTypes["Workspace"] | undefined,
	findFirstWorkspaceOrThrow?: GraphQLTypes["Workspace"] | undefined,
	workspaces: Array<GraphQLTypes["Workspace"]>,
	workspace?: GraphQLTypes["Workspace"] | undefined,
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	groupByWorkspace: Array<GraphQLTypes["WorkspaceGroupBy"]>,
	aggregateWorkspaceRole: GraphQLTypes["AggregateWorkspaceRole"],
	findFirstWorkspaceRole?: GraphQLTypes["WorkspaceRole"] | undefined,
	findFirstWorkspaceRoleOrThrow?: GraphQLTypes["WorkspaceRole"] | undefined,
	workspaceRoles: Array<GraphQLTypes["WorkspaceRole"]>,
	workspaceRole?: GraphQLTypes["WorkspaceRole"] | undefined,
	getWorkspaceRole?: GraphQLTypes["WorkspaceRole"] | undefined,
	groupByWorkspaceRole: Array<GraphQLTypes["WorkspaceRoleGroupBy"]>,
	getAdBoardAvailability: Array<GraphQLTypes["Slot"]>
};
	["AggregateAdBoard"]: {
	__typename: "AggregateAdBoard",
	_count?: GraphQLTypes["AdBoardCountAggregate"] | undefined,
	_avg?: GraphQLTypes["AdBoardAvgAggregate"] | undefined,
	_sum?: GraphQLTypes["AdBoardSumAggregate"] | undefined,
	_min?: GraphQLTypes["AdBoardMinAggregate"] | undefined,
	_max?: GraphQLTypes["AdBoardMaxAggregate"] | undefined
};
	["AdBoardCountAggregate"]: {
	__typename: "AdBoardCountAggregate",
	id: number,
	address: number,
	state: number,
	city: number,
	country: number,
	outdoor: number,
	locationCategoryId: number,
	locationSubCategoryId: number,
	alias: number,
	title: number,
	pricePerDay: number,
	originalPricePerDay: number,
	description: number,
	adBoardTypeId: number,
	tags: number,
	featuredImage: number,
	galleryImgs: number,
	notes: number,
	impressionMultiplier: number,
	categoryId: number,
	restrictedBusinessIds: number,
	targetAudiences: number,
	status: number,
	adboardDraftStatus: number,
	isLive: number,
	numberOfDisplays: number,
	dailyOperationHours: number,
	adsPerLoop: number,
	adsDuration: number,
	monthlyPrice: number,
	offlineBookingsEnabled: number,
	offlineBookingContactId: number,
	createdAt: number,
	createdByUserId: number,
	href: number,
	workspaceId: number,
	playerIds: number,
	publisherScreenId: number,
	additionalData: number,
	isDraft: number,
	publishedVersionId: number,
	lastPublishedAt: number,
	isArchived: number,
	archivedAt: number,
	archivedByUserId: number,
	updatedAt: number,
	_all: number
};
	["AdBoardAvgAggregate"]: {
	__typename: "AdBoardAvgAggregate",
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	impressionMultiplier?: number | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined
};
	["AdBoardSumAggregate"]: {
	__typename: "AdBoardSumAggregate",
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	impressionMultiplier?: number | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined
};
	["AdBoardMinAggregate"]: {
	__typename: "AdBoardMinAggregate",
	id?: string | undefined,
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	adBoardTypeId?: string | undefined,
	featuredImage?: string | undefined,
	impressionMultiplier?: number | undefined,
	categoryId?: string | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId?: string | undefined,
	publisherScreenId?: string | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdBoardStatus"]: AdBoardStatus;
	["AdboardDraftStatus"]: AdboardDraftStatus;
	/** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
["DateTime"]: "scalar" & { name: "DateTime" };
	["AdBoardMaxAggregate"]: {
	__typename: "AdBoardMaxAggregate",
	id?: string | undefined,
	address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	adBoardTypeId?: string | undefined,
	featuredImage?: string | undefined,
	impressionMultiplier?: number | undefined,
	categoryId?: string | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId?: string | undefined,
	publisherScreenId?: string | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdBoardWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	address?: GraphQLTypes["StringFilter"] | undefined,
	state?: GraphQLTypes["StringFilter"] | undefined,
	city?: GraphQLTypes["StringFilter"] | undefined,
	country?: GraphQLTypes["StringFilter"] | undefined,
	map?: GraphQLTypes["MapCompositeFilter"] | undefined,
	outdoor?: GraphQLTypes["BoolFilter"] | undefined,
	locationCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	locationSubCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	alias?: GraphQLTypes["StringNullableFilter"] | undefined,
	title?: GraphQLTypes["StringFilter"] | undefined,
	pricePerDay?: GraphQLTypes["IntFilter"] | undefined,
	originalPricePerDay?: GraphQLTypes["IntNullableFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationObjectEqualityInput"]> | undefined,
	adBoardTypeId?: GraphQLTypes["StringNullableFilter"] | undefined,
	tags?: GraphQLTypes["StringNullableListFilter"] | undefined,
	featuredImage?: GraphQLTypes["StringNullableFilter"] | undefined,
	galleryImgs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	specifications?: Array<GraphQLTypes["SpecificationObjectEqualityInput"]> | undefined,
	notes?: GraphQLTypes["StringNullableListFilter"] | undefined,
	impressionMultiplier?: GraphQLTypes["FloatNullableFilter"] | undefined,
	resolution?: GraphQLTypes["ResolutionNullableCompositeFilter"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeNullableCompositeFilter"] | undefined,
	categoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	restrictedBusinessIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	targetAudiences?: GraphQLTypes["StringNullableListFilter"] | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeObjectEqualityInput"]> | undefined,
	status?: GraphQLTypes["EnumAdBoardStatusFilter"] | undefined,
	adboardDraftStatus?: GraphQLTypes["EnumAdboardDraftStatusNullableFilter"] | undefined,
	isLive?: GraphQLTypes["BoolFilter"] | undefined,
	webhooks?: Array<GraphQLTypes["WebhookObjectEqualityInput"]> | undefined,
	numberOfDisplays?: GraphQLTypes["IntFilter"] | undefined,
	dailyOperationHours?: GraphQLTypes["IntFilter"] | undefined,
	adsPerLoop?: GraphQLTypes["IntFilter"] | undefined,
	adsDuration?: GraphQLTypes["IntFilter"] | undefined,
	monthlyPrice?: GraphQLTypes["FloatNullableFilter"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["BoolFilter"] | undefined,
	offlineBookingContactId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	href?: GraphQLTypes["StringNullableFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	playerIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	publisherScreenId?: GraphQLTypes["StringNullableFilter"] | undefined,
	additionalData?: GraphQLTypes["JsonNullableFilter"] | undefined,
	isDraft?: GraphQLTypes["BoolFilter"] | undefined,
	publishedVersionId?: GraphQLTypes["StringNullableFilter"] | undefined,
	lastPublishedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	isArchived?: GraphQLTypes["BoolFilter"] | undefined,
	archivedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	archivedByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryNullableRelationFilter"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryNullableRelationFilter"] | undefined,
	type?: GraphQLTypes["AdBoardTypeNullableRelationFilter"] | undefined,
	category?: GraphQLTypes["BusinessCategoryNullableRelationFilter"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	orders?: GraphQLTypes["OrderListRelationFilter"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationListRelationFilter"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	createdBy?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined,
	players?: GraphQLTypes["PlayerListRelationFilter"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupNullableRelationFilter"] | undefined,
	cameras?: GraphQLTypes["CameraListRelationFilter"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardNullableRelationFilter"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardNullableRelationFilter"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryListRelationFilter"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentListRelationFilter"] | undefined,
	archivedBy?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewListRelationFilter"] | undefined
};
	["StringFilter"]: {
		equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	mode?: GraphQLTypes["QueryMode"] | undefined,
	not?: GraphQLTypes["NestedStringFilter"] | undefined
};
	["QueryMode"]: QueryMode;
	["NestedStringFilter"]: {
		equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	not?: GraphQLTypes["NestedStringFilter"] | undefined
};
	["MapCompositeFilter"]: {
		equals?: GraphQLTypes["MapObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["MapWhereInput"] | undefined,
	isNot?: GraphQLTypes["MapWhereInput"] | undefined
};
	["MapObjectEqualityInput"]: {
		lat: number,
	lng: number,
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["MapWhereInput"]: {
		AND?: Array<GraphQLTypes["MapWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["MapWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["MapWhereInput"]> | undefined,
	lat?: GraphQLTypes["FloatFilter"] | undefined,
	lng?: GraphQLTypes["FloatFilter"] | undefined,
	address?: GraphQLTypes["StringNullableFilter"] | undefined,
	city?: GraphQLTypes["StringNullableFilter"] | undefined,
	state?: GraphQLTypes["StringNullableFilter"] | undefined,
	country?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["FloatFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedFloatFilter"] | undefined
};
	["NestedFloatFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedFloatFilter"] | undefined
};
	["StringNullableFilter"]: {
		equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	mode?: GraphQLTypes["QueryMode"] | undefined,
	not?: GraphQLTypes["NestedStringNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedStringNullableFilter"]: {
		equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	not?: GraphQLTypes["NestedStringNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["BoolFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["NestedBoolFilter"] | undefined
};
	["NestedBoolFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["NestedBoolFilter"] | undefined
};
	["IntFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedIntFilter"] | undefined
};
	["NestedIntFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedIntFilter"] | undefined
};
	["IntNullableFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedIntNullableFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["SpecificationObjectEqualityInput"]: {
		name: string,
	value?: string | undefined
};
	["StringNullableListFilter"]: {
		equals?: Array<string> | undefined,
	has?: string | undefined,
	hasEvery?: Array<string> | undefined,
	hasSome?: Array<string> | undefined,
	isEmpty?: boolean | undefined
};
	["FloatNullableFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedFloatNullableFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["ResolutionNullableCompositeFilter"]: {
		equals?: GraphQLTypes["ResolutionObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["ResolutionWhereInput"] | undefined,
	isNot?: GraphQLTypes["ResolutionWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["ResolutionObjectEqualityInput"]: {
		height: number,
	width: number
};
	["ResolutionWhereInput"]: {
		AND?: Array<GraphQLTypes["ResolutionWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["ResolutionWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["ResolutionWhereInput"]> | undefined,
	height?: GraphQLTypes["IntFilter"] | undefined,
	width?: GraphQLTypes["IntFilter"] | undefined
};
	["ScreenSizeNullableCompositeFilter"]: {
		equals?: GraphQLTypes["ScreenSizeObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["ScreenSizeWhereInput"] | undefined,
	isNot?: GraphQLTypes["ScreenSizeWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["ScreenSizeObjectEqualityInput"]: {
		height: number,
	unit: GraphQLTypes["MeasurementUnit"],
	width: number
};
	["MeasurementUnit"]: MeasurementUnit;
	["ScreenSizeWhereInput"]: {
		AND?: Array<GraphQLTypes["ScreenSizeWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["ScreenSizeWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["ScreenSizeWhereInput"]> | undefined,
	height?: GraphQLTypes["FloatFilter"] | undefined,
	unit?: GraphQLTypes["EnumMeasurementUnitFilter"] | undefined,
	width?: GraphQLTypes["FloatFilter"] | undefined
};
	["EnumMeasurementUnitFilter"]: {
		equals?: GraphQLTypes["MeasurementUnit"] | undefined,
	in?: Array<GraphQLTypes["MeasurementUnit"]> | undefined,
	notIn?: Array<GraphQLTypes["MeasurementUnit"]> | undefined,
	not?: GraphQLTypes["NestedEnumMeasurementUnitFilter"] | undefined
};
	["NestedEnumMeasurementUnitFilter"]: {
		equals?: GraphQLTypes["MeasurementUnit"] | undefined,
	in?: Array<GraphQLTypes["MeasurementUnit"]> | undefined,
	notIn?: Array<GraphQLTypes["MeasurementUnit"]> | undefined,
	not?: GraphQLTypes["NestedEnumMeasurementUnitFilter"] | undefined
};
	["PlaybackTimeObjectEqualityInput"]: {
		name?: string | undefined,
	startTime: string,
	endTime: string
};
	["EnumAdBoardStatusFilter"]: {
		equals?: GraphQLTypes["AdBoardStatus"] | undefined,
	in?: Array<GraphQLTypes["AdBoardStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["AdBoardStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdBoardStatusFilter"] | undefined
};
	["NestedEnumAdBoardStatusFilter"]: {
		equals?: GraphQLTypes["AdBoardStatus"] | undefined,
	in?: Array<GraphQLTypes["AdBoardStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["AdBoardStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdBoardStatusFilter"] | undefined
};
	["EnumAdboardDraftStatusNullableFilter"]: {
		equals?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	in?: Array<GraphQLTypes["AdboardDraftStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["AdboardDraftStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumAdboardDraftStatusNullableFilter"]: {
		equals?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	in?: Array<GraphQLTypes["AdboardDraftStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["AdboardDraftStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["WebhookObjectEqualityInput"]: {
		url: string,
	headers?: GraphQLTypes["JSON"] | undefined,
	body?: GraphQLTypes["JSON"] | undefined,
	method: string
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]: "scalar" & { name: "JSON" };
	["DateTimeNullableFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["NestedDateTimeNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedDateTimeNullableFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["NestedDateTimeNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["JsonNullableFilter"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not?: GraphQLTypes["JSON"] | undefined,
	isSet?: boolean | undefined
};
	["LocationCategoryNullableRelationFilter"]: {
		is?: GraphQLTypes["LocationCategoryWhereInput"] | undefined,
	isNot?: GraphQLTypes["LocationCategoryWhereInput"] | undefined
};
	["LocationCategoryWhereInput"]: {
		AND?: Array<GraphQLTypes["LocationCategoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["LocationCategoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["LocationCategoryWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryListRelationFilter"] | undefined
};
	["AdBoardListRelationFilter"]: {
		every?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	some?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	none?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["LocationSubCategoryListRelationFilter"]: {
		every?: GraphQLTypes["LocationSubCategoryWhereInput"] | undefined,
	some?: GraphQLTypes["LocationSubCategoryWhereInput"] | undefined,
	none?: GraphQLTypes["LocationSubCategoryWhereInput"] | undefined
};
	["LocationSubCategoryWhereInput"]: {
		AND?: Array<GraphQLTypes["LocationSubCategoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["LocationSubCategoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["LocationSubCategoryWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	locationCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryNullableRelationFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined
};
	["LocationSubCategoryNullableRelationFilter"]: {
		is?: GraphQLTypes["LocationSubCategoryWhereInput"] | undefined,
	isNot?: GraphQLTypes["LocationSubCategoryWhereInput"] | undefined
};
	["AdBoardTypeNullableRelationFilter"]: {
		is?: GraphQLTypes["AdBoardTypeWhereInput"] | undefined,
	isNot?: GraphQLTypes["AdBoardTypeWhereInput"] | undefined
};
	["AdBoardTypeWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardTypeWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardTypeWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardTypeWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined
};
	["BusinessCategoryNullableRelationFilter"]: {
		is?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	isNot?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined
};
	["BusinessCategoryWhereInput"]: {
		AND?: Array<GraphQLTypes["BusinessCategoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BusinessCategoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BusinessCategoryWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	restrictedByAdBoardIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	campaigns?: GraphQLTypes["CampaignListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined
};
	["CampaignListRelationFilter"]: {
		every?: GraphQLTypes["CampaignWhereInput"] | undefined,
	some?: GraphQLTypes["CampaignWhereInput"] | undefined,
	none?: GraphQLTypes["CampaignWhereInput"] | undefined
};
	["CampaignWhereInput"]: {
		AND?: Array<GraphQLTypes["CampaignWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CampaignWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CampaignWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingFor?: GraphQLTypes["StringFilter"] | undefined,
	purpose?: GraphQLTypes["StringFilter"] | undefined,
	businessCategoryId?: GraphQLTypes["StringFilter"] | undefined,
	adCategoryId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	payment?: GraphQLTypes["BookingPaymentNullableCompositeFilter"] | undefined,
	paymentStatus?: GraphQLTypes["EnumPaymentStatusFilter"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryNullableCompositeFilter"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCompositeFilter"] | undefined,
	origin?: GraphQLTypes["StringFilter"] | undefined,
	assetsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryRelationFilter"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	payments?: GraphQLTypes["PaymentListRelationFilter"] | undefined,
	assets?: GraphQLTypes["FileListRelationFilter"] | undefined
};
	["DateTimeFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["NestedDateTimeFilter"] | undefined
};
	["NestedDateTimeFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["NestedDateTimeFilter"] | undefined
};
	["BookingPaymentNullableCompositeFilter"]: {
		equals?: GraphQLTypes["BookingPaymentObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["BookingPaymentWhereInput"] | undefined,
	isNot?: GraphQLTypes["BookingPaymentWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["BookingPaymentObjectEqualityInput"]: {
		liveMode: boolean,
	checkoutSessionId?: string | undefined,
	customer?: GraphQLTypes["BookingCustomerObjectEqualityInput"] | undefined,
	paymentGateway: GraphQLTypes["PaymentGatewayName"],
	paymentId: string,
	sessionId?: string | undefined,
	totalDetails?: GraphQLTypes["PaymentTotalDetailsObjectEqualityInput"] | undefined,
	razorpay?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["JSON"] | undefined
};
	["BookingCustomerObjectEqualityInput"]: {
		customerId: string,
	customerDetails?: GraphQLTypes["PaymentCustomerDetailsObjectEqualityInput"] | undefined
};
	["PaymentCustomerDetailsObjectEqualityInput"]: {
		email: string,
	name: string,
	phone?: string | undefined
};
	["PaymentGatewayName"]: PaymentGatewayName;
	["PaymentTotalDetailsObjectEqualityInput"]: {
		discount: number,
	shipping: number,
	subtotal: number,
	tax: number,
	total: number
};
	["BookingPaymentWhereInput"]: {
		AND?: Array<GraphQLTypes["BookingPaymentWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingPaymentWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingPaymentWhereInput"]> | undefined,
	liveMode?: GraphQLTypes["BoolFilter"] | undefined,
	checkoutSessionId?: GraphQLTypes["StringNullableFilter"] | undefined,
	customer?: GraphQLTypes["BookingCustomerNullableCompositeFilter"] | undefined,
	paymentGateway?: GraphQLTypes["EnumPaymentGatewayNameFilter"] | undefined,
	paymentId?: GraphQLTypes["StringFilter"] | undefined,
	sessionId?: GraphQLTypes["StringNullableFilter"] | undefined,
	totalDetails?: GraphQLTypes["PaymentTotalDetailsNullableCompositeFilter"] | undefined,
	razorpay?: GraphQLTypes["JsonNullableFilter"] | undefined,
	stripe?: GraphQLTypes["JsonNullableFilter"] | undefined
};
	["BookingCustomerNullableCompositeFilter"]: {
		equals?: GraphQLTypes["BookingCustomerObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["BookingCustomerWhereInput"] | undefined,
	isNot?: GraphQLTypes["BookingCustomerWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["BookingCustomerWhereInput"]: {
		AND?: Array<GraphQLTypes["BookingCustomerWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingCustomerWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingCustomerWhereInput"]> | undefined,
	customerId?: GraphQLTypes["StringFilter"] | undefined,
	customerDetails?: GraphQLTypes["PaymentCustomerDetailsNullableCompositeFilter"] | undefined
};
	["PaymentCustomerDetailsNullableCompositeFilter"]: {
		equals?: GraphQLTypes["PaymentCustomerDetailsObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["PaymentCustomerDetailsWhereInput"] | undefined,
	isNot?: GraphQLTypes["PaymentCustomerDetailsWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["PaymentCustomerDetailsWhereInput"]: {
		AND?: Array<GraphQLTypes["PaymentCustomerDetailsWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PaymentCustomerDetailsWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PaymentCustomerDetailsWhereInput"]> | undefined,
	email?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	phone?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["EnumPaymentGatewayNameFilter"]: {
		equals?: GraphQLTypes["PaymentGatewayName"] | undefined,
	in?: Array<GraphQLTypes["PaymentGatewayName"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentGatewayName"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentGatewayNameFilter"] | undefined
};
	["NestedEnumPaymentGatewayNameFilter"]: {
		equals?: GraphQLTypes["PaymentGatewayName"] | undefined,
	in?: Array<GraphQLTypes["PaymentGatewayName"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentGatewayName"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentGatewayNameFilter"] | undefined
};
	["PaymentTotalDetailsNullableCompositeFilter"]: {
		equals?: GraphQLTypes["PaymentTotalDetailsObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["PaymentTotalDetailsWhereInput"] | undefined,
	isNot?: GraphQLTypes["PaymentTotalDetailsWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["PaymentTotalDetailsWhereInput"]: {
		AND?: Array<GraphQLTypes["PaymentTotalDetailsWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PaymentTotalDetailsWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PaymentTotalDetailsWhereInput"]> | undefined,
	discount?: GraphQLTypes["FloatFilter"] | undefined,
	shipping?: GraphQLTypes["FloatFilter"] | undefined,
	subtotal?: GraphQLTypes["FloatFilter"] | undefined,
	tax?: GraphQLTypes["FloatFilter"] | undefined,
	total?: GraphQLTypes["FloatFilter"] | undefined
};
	["EnumPaymentStatusFilter"]: {
		equals?: GraphQLTypes["PaymentStatus"] | undefined,
	in?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentStatusFilter"] | undefined
};
	["PaymentStatus"]: PaymentStatus;
	["NestedEnumPaymentStatusFilter"]: {
		equals?: GraphQLTypes["PaymentStatus"] | undefined,
	in?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentStatusFilter"] | undefined
};
	["PriceSummaryNullableCompositeFilter"]: {
		equals?: GraphQLTypes["PriceSummaryObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["PriceSummaryWhereInput"] | undefined,
	isNot?: GraphQLTypes["PriceSummaryWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["PriceSummaryObjectEqualityInput"]: {
		days: number,
	discount?: number | undefined,
	payableRent: number,
	rentPerDay: number,
	serviceCharge: number,
	totalRent: number
};
	["PriceSummaryWhereInput"]: {
		AND?: Array<GraphQLTypes["PriceSummaryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PriceSummaryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PriceSummaryWhereInput"]> | undefined,
	days?: GraphQLTypes["IntFilter"] | undefined,
	discount?: GraphQLTypes["FloatNullableFilter"] | undefined,
	payableRent?: GraphQLTypes["FloatFilter"] | undefined,
	rentPerDay?: GraphQLTypes["IntFilter"] | undefined,
	serviceCharge?: GraphQLTypes["FloatFilter"] | undefined,
	totalRent?: GraphQLTypes["IntFilter"] | undefined
};
	["OrderSelectedDateCompositeFilter"]: {
		equals?: GraphQLTypes["OrderSelectedDateObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["OrderSelectedDateWhereInput"] | undefined,
	isNot?: GraphQLTypes["OrderSelectedDateWhereInput"] | undefined
};
	["OrderSelectedDateObjectEqualityInput"]: {
		endDate: GraphQLTypes["DateTime"],
	startDate: GraphQLTypes["DateTime"]
};
	["OrderSelectedDateWhereInput"]: {
		AND?: Array<GraphQLTypes["OrderSelectedDateWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["OrderSelectedDateWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["OrderSelectedDateWhereInput"]> | undefined,
	endDate?: GraphQLTypes["DateTimeFilter"] | undefined,
	startDate?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["UserRelationFilter"]: {
		is?: GraphQLTypes["UserWhereInput"] | undefined,
	isNot?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserWhereInput"]: {
		AND?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	uid?: GraphQLTypes["StringNullableFilter"] | undefined,
	email?: GraphQLTypes["StringFilter"] | undefined,
	image?: GraphQLTypes["StringNullableFilter"] | undefined,
	phoneNumber?: GraphQLTypes["StringNullableFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	otherDetails?: GraphQLTypes["JsonNullableFilter"] | undefined,
	stripe?: GraphQLTypes["UserStripeNullableCompositeFilter"] | undefined,
	roles?: GraphQLTypes["EnumRoleNullableListFilter"] | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundObjectEqualityInput"]> | undefined,
	sharedBookingIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	disclaimerAccepted?: GraphQLTypes["BoolFilter"] | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceNullableCompositeFilter"] | undefined,
	leadWorkspaceIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	createdFiles?: GraphQLTypes["FileListRelationFilter"] | undefined,
	updatedFiles?: GraphQLTypes["FileListRelationFilter"] | undefined,
	orders?: GraphQLTypes["OrderListRelationFilter"] | undefined,
	changelogs?: GraphQLTypes["ChangelogListRelationFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	audits?: GraphQLTypes["AuditListRelationFilter"] | undefined,
	campaigns?: GraphQLTypes["CampaignListRelationFilter"] | undefined,
	sharedBookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	memberships?: GraphQLTypes["MembershipListRelationFilter"] | undefined,
	notifications?: GraphQLTypes["NotificationListRelationFilter"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryListRelationFilter"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentListRelationFilter"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceListRelationFilter"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceListRelationFilter"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewListRelationFilter"] | undefined,
	userPositions?: GraphQLTypes["UserPositionListRelationFilter"] | undefined
};
	["UserStripeNullableCompositeFilter"]: {
		equals?: GraphQLTypes["UserStripeObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["UserStripeWhereInput"] | undefined,
	isNot?: GraphQLTypes["UserStripeWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["UserStripeObjectEqualityInput"]: {
		customerId: string
};
	["UserStripeWhereInput"]: {
		AND?: Array<GraphQLTypes["UserStripeWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserStripeWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserStripeWhereInput"]> | undefined,
	customerId?: GraphQLTypes["StringFilter"] | undefined
};
	["EnumRoleNullableListFilter"]: {
		equals?: Array<GraphQLTypes["Role"]> | undefined,
	has?: GraphQLTypes["Role"] | undefined,
	hasEvery?: Array<GraphQLTypes["Role"]> | undefined,
	hasSome?: Array<GraphQLTypes["Role"]> | undefined,
	isEmpty?: boolean | undefined
};
	["Role"]: Role;
	["BookingRefundObjectEqualityInput"]: {
		id: string,
	razorpay?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["JSON"] | undefined,
	createdByUserId: string
};
	["NotificationPreferenceNullableCompositeFilter"]: {
		equals?: GraphQLTypes["NotificationPreferenceObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["NotificationPreferenceWhereInput"] | undefined,
	isNot?: GraphQLTypes["NotificationPreferenceWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["NotificationPreferenceObjectEqualityInput"]: {
		type?: Array<GraphQLTypes["NotificationType"]> | undefined,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined,
	pushSetting?: GraphQLTypes["PushSettingObjectEqualityInput"] | undefined,
	enabled: boolean
};
	["NotificationType"]: NotificationType;
	["NotificationChannel"]: NotificationChannel;
	["PushSettingObjectEqualityInput"]: {
		fcmToken: string
};
	["NotificationPreferenceWhereInput"]: {
		AND?: Array<GraphQLTypes["NotificationPreferenceWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["NotificationPreferenceWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["NotificationPreferenceWhereInput"]> | undefined,
	type?: GraphQLTypes["EnumNotificationTypeNullableListFilter"] | undefined,
	channels?: GraphQLTypes["EnumNotificationChannelNullableListFilter"] | undefined,
	pushSetting?: GraphQLTypes["PushSettingNullableCompositeFilter"] | undefined,
	enabled?: GraphQLTypes["BoolFilter"] | undefined
};
	["EnumNotificationTypeNullableListFilter"]: {
		equals?: Array<GraphQLTypes["NotificationType"]> | undefined,
	has?: GraphQLTypes["NotificationType"] | undefined,
	hasEvery?: Array<GraphQLTypes["NotificationType"]> | undefined,
	hasSome?: Array<GraphQLTypes["NotificationType"]> | undefined,
	isEmpty?: boolean | undefined
};
	["EnumNotificationChannelNullableListFilter"]: {
		equals?: Array<GraphQLTypes["NotificationChannel"]> | undefined,
	has?: GraphQLTypes["NotificationChannel"] | undefined,
	hasEvery?: Array<GraphQLTypes["NotificationChannel"]> | undefined,
	hasSome?: Array<GraphQLTypes["NotificationChannel"]> | undefined,
	isEmpty?: boolean | undefined
};
	["PushSettingNullableCompositeFilter"]: {
		equals?: GraphQLTypes["PushSettingObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["PushSettingWhereInput"] | undefined,
	isNot?: GraphQLTypes["PushSettingWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["PushSettingWhereInput"]: {
		AND?: Array<GraphQLTypes["PushSettingWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PushSettingWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PushSettingWhereInput"]> | undefined,
	fcmToken?: GraphQLTypes["StringFilter"] | undefined
};
	["BookingListRelationFilter"]: {
		every?: GraphQLTypes["BookingWhereInput"] | undefined,
	some?: GraphQLTypes["BookingWhereInput"] | undefined,
	none?: GraphQLTypes["BookingWhereInput"] | undefined
};
	["BookingWhereInput"]: {
		AND?: Array<GraphQLTypes["BookingWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	additionalInformation?: GraphQLTypes["StringNullableFilter"] | undefined,
	origin?: GraphQLTypes["StringNullableFilter"] | undefined,
	assetsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingCode?: GraphQLTypes["StringFilter"] | undefined,
	bookingDate?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingFor?: GraphQLTypes["StringFilter"] | undefined,
	purpose?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	campaignId?: GraphQLTypes["StringNullableFilter"] | undefined,
	businessCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	adCategoryId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCompositeFilter"] | undefined,
	bookingStatus?: GraphQLTypes["EnumBookingStatusFilter"] | undefined,
	paymentStatus?: GraphQLTypes["EnumPaymentStatusNullableFilter"] | undefined,
	deploymentStatus?: GraphQLTypes["EnumDeploymentStatusFilter"] | undefined,
	contentApprovalStatus?: GraphQLTypes["EnumContentApprovalStatusFilter"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCompositeFilter"] | undefined,
	payment?: GraphQLTypes["BookingPaymentNullableCompositeFilter"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryNullableCompositeFilter"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataNullableCompositeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	refund?: GraphQLTypes["BookingRefundNullableCompositeFilter"] | undefined,
	sharedUserIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	isOffline?: GraphQLTypes["BoolFilter"] | undefined,
	isPaid?: GraphQLTypes["BoolFilter"] | undefined,
	isScheduled?: GraphQLTypes["BoolFilter"] | undefined,
	isDeployed?: GraphQLTypes["BoolFilter"] | undefined,
	isCompleted?: GraphQLTypes["BoolFilter"] | undefined,
	assets?: GraphQLTypes["FileListRelationFilter"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryNullableRelationFilter"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryListRelationFilter"] | undefined,
	proofs?: GraphQLTypes["FileListRelationFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	changelogs?: GraphQLTypes["ChangelogListRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	campaign?: GraphQLTypes["CampaignNullableRelationFilter"] | undefined,
	payouts?: GraphQLTypes["PayoutListRelationFilter"] | undefined,
	sharedUsers?: GraphQLTypes["UserListRelationFilter"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogListRelationFilter"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageListRelationFilter"] | undefined
};
	["BookingDetailsCompositeFilter"]: {
		equals?: GraphQLTypes["BookingDetailsObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["BookingDetailsWhereInput"] | undefined,
	isNot?: GraphQLTypes["BookingDetailsWhereInput"] | undefined
};
	["BookingDetailsObjectEqualityInput"]: {
		additionalInformation?: string | undefined,
	email: string,
	name: string,
	phone?: string | undefined
};
	["BookingDetailsWhereInput"]: {
		AND?: Array<GraphQLTypes["BookingDetailsWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingDetailsWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingDetailsWhereInput"]> | undefined,
	additionalInformation?: GraphQLTypes["StringNullableFilter"] | undefined,
	email?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	phone?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["EnumBookingStatusFilter"]: {
		equals?: GraphQLTypes["BookingStatus"] | undefined,
	in?: Array<GraphQLTypes["BookingStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["BookingStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumBookingStatusFilter"] | undefined
};
	["BookingStatus"]: BookingStatus;
	["NestedEnumBookingStatusFilter"]: {
		equals?: GraphQLTypes["BookingStatus"] | undefined,
	in?: Array<GraphQLTypes["BookingStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["BookingStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumBookingStatusFilter"] | undefined
};
	["EnumPaymentStatusNullableFilter"]: {
		equals?: GraphQLTypes["PaymentStatus"] | undefined,
	in?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumPaymentStatusNullableFilter"]: {
		equals?: GraphQLTypes["PaymentStatus"] | undefined,
	in?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["EnumDeploymentStatusFilter"]: {
		equals?: GraphQLTypes["DeploymentStatus"] | undefined,
	in?: Array<GraphQLTypes["DeploymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["DeploymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumDeploymentStatusFilter"] | undefined
};
	["DeploymentStatus"]: DeploymentStatus;
	["NestedEnumDeploymentStatusFilter"]: {
		equals?: GraphQLTypes["DeploymentStatus"] | undefined,
	in?: Array<GraphQLTypes["DeploymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["DeploymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumDeploymentStatusFilter"] | undefined
};
	["EnumContentApprovalStatusFilter"]: {
		equals?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	in?: Array<GraphQLTypes["ContentApprovalStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentApprovalStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumContentApprovalStatusFilter"] | undefined
};
	["ContentApprovalStatus"]: ContentApprovalStatus;
	["NestedEnumContentApprovalStatusFilter"]: {
		equals?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	in?: Array<GraphQLTypes["ContentApprovalStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentApprovalStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumContentApprovalStatusFilter"] | undefined
};
	["BookingPisignageDataNullableCompositeFilter"]: {
		equals?: GraphQLTypes["BookingPisignageDataObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["BookingPisignageDataWhereInput"] | undefined,
	isNot?: GraphQLTypes["BookingPisignageDataWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["BookingPisignageDataObjectEqualityInput"]: {
		deployedFileName?: string | undefined,
	playlistId?: string | undefined
};
	["BookingPisignageDataWhereInput"]: {
		AND?: Array<GraphQLTypes["BookingPisignageDataWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingPisignageDataWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingPisignageDataWhereInput"]> | undefined,
	deployedFileName?: GraphQLTypes["StringNullableFilter"] | undefined,
	playlistId?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["BookingRefundNullableCompositeFilter"]: {
		equals?: GraphQLTypes["BookingRefundObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["BookingRefundWhereInput"] | undefined,
	isNot?: GraphQLTypes["BookingRefundWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["BookingRefundWhereInput"]: {
		AND?: Array<GraphQLTypes["BookingRefundWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingRefundWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingRefundWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	razorpay?: GraphQLTypes["JsonNullableFilter"] | undefined,
	stripe?: GraphQLTypes["JsonNullableFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringFilter"] | undefined
};
	["FileListRelationFilter"]: {
		every?: GraphQLTypes["FileWhereInput"] | undefined,
	some?: GraphQLTypes["FileWhereInput"] | undefined,
	none?: GraphQLTypes["FileWhereInput"] | undefined
};
	["FileWhereInput"]: {
		AND?: Array<GraphQLTypes["FileWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["FileWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["FileWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	contentType?: GraphQLTypes["StringFilter"] | undefined,
	ctime?: GraphQLTypes["StringFilter"] | undefined,
	filename?: GraphQLTypes["StringFilter"] | undefined,
	filepath?: GraphQLTypes["StringNullableFilter"] | undefined,
	isDirectory?: GraphQLTypes["BoolFilter"] | undefined,
	meta?: GraphQLTypes["FileMetaCompositeFilter"] | undefined,
	mtime?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	size?: GraphQLTypes["IntFilter"] | undefined,
	status?: GraphQLTypes["EnumFileStatusNullableFilter"] | undefined,
	type?: GraphQLTypes["StringFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringFilter"] | undefined,
	url?: GraphQLTypes["StringFilter"] | undefined,
	bookingsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	proofBookingId?: GraphQLTypes["StringNullableFilter"] | undefined,
	campaignIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	updatedBy?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	createdBy?: GraphQLTypes["UserRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	proofBooking?: GraphQLTypes["BookingNullableRelationFilter"] | undefined,
	campaigns?: GraphQLTypes["CampaignListRelationFilter"] | undefined
};
	["FileMetaCompositeFilter"]: {
		equals?: GraphQLTypes["FileMetaObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["FileMetaWhereInput"] | undefined,
	isNot?: GraphQLTypes["FileMetaWhereInput"] | undefined
};
	["FileMetaObjectEqualityInput"]: {
		duration: number,
	height: number,
	width: number
};
	["FileMetaWhereInput"]: {
		AND?: Array<GraphQLTypes["FileMetaWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["FileMetaWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["FileMetaWhereInput"]> | undefined,
	duration?: GraphQLTypes["FloatFilter"] | undefined,
	height?: GraphQLTypes["IntFilter"] | undefined,
	width?: GraphQLTypes["IntFilter"] | undefined
};
	["EnumFileStatusNullableFilter"]: {
		equals?: GraphQLTypes["FileStatus"] | undefined,
	in?: Array<GraphQLTypes["FileStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["FileStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["FileStatus"]: FileStatus;
	["NestedEnumFileStatusNullableFilter"]: {
		equals?: GraphQLTypes["FileStatus"] | undefined,
	in?: Array<GraphQLTypes["FileStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["FileStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["UserNullableRelationFilter"]: {
		is?: GraphQLTypes["UserWhereInput"] | undefined,
	isNot?: GraphQLTypes["UserWhereInput"] | undefined
};
	["WorkspaceNullableRelationFilter"]: {
		is?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	isNot?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceWhereInput"]: {
		AND?: Array<GraphQLTypes["WorkspaceWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WorkspaceWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WorkspaceWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumWorkspaceTypeFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	key?: GraphQLTypes["StringFilter"] | undefined,
	userIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	ownerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	avatar?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["StringNullableFilter"] | undefined,
	leadIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	owner?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	memberships?: GraphQLTypes["MembershipListRelationFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	playlists?: GraphQLTypes["PlaylistListRelationFilter"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupListRelationFilter"] | undefined,
	audits?: GraphQLTypes["AuditListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	files?: GraphQLTypes["FileListRelationFilter"] | undefined,
	players?: GraphQLTypes["PlayerListRelationFilter"] | undefined,
	transactions?: GraphQLTypes["TransactionListRelationFilter"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionNullableRelationFilter"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerListRelationFilter"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleListRelationFilter"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogListRelationFilter"] | undefined,
	leads?: GraphQLTypes["UserListRelationFilter"] | undefined
};
	["EnumWorkspaceTypeFilter"]: {
		equals?: GraphQLTypes["WorkspaceType"] | undefined,
	in?: Array<GraphQLTypes["WorkspaceType"]> | undefined,
	notIn?: Array<GraphQLTypes["WorkspaceType"]> | undefined,
	not?: GraphQLTypes["NestedEnumWorkspaceTypeFilter"] | undefined
};
	["WorkspaceType"]: WorkspaceType;
	["NestedEnumWorkspaceTypeFilter"]: {
		equals?: GraphQLTypes["WorkspaceType"] | undefined,
	in?: Array<GraphQLTypes["WorkspaceType"]> | undefined,
	notIn?: Array<GraphQLTypes["WorkspaceType"]> | undefined,
	not?: GraphQLTypes["NestedEnumWorkspaceTypeFilter"] | undefined
};
	["MembershipListRelationFilter"]: {
		every?: GraphQLTypes["MembershipWhereInput"] | undefined,
	some?: GraphQLTypes["MembershipWhereInput"] | undefined,
	none?: GraphQLTypes["MembershipWhereInput"] | undefined
};
	["MembershipWhereInput"]: {
		AND?: Array<GraphQLTypes["MembershipWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["MembershipWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["MembershipWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	roleId?: GraphQLTypes["StringFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined,
	role?: GraphQLTypes["WorkspaceRoleRelationFilter"] | undefined
};
	["WorkspaceRelationFilter"]: {
		is?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	isNot?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceRoleRelationFilter"]: {
		is?: GraphQLTypes["WorkspaceRoleWhereInput"] | undefined,
	isNot?: GraphQLTypes["WorkspaceRoleWhereInput"] | undefined
};
	["WorkspaceRoleWhereInput"]: {
		AND?: Array<GraphQLTypes["WorkspaceRoleWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WorkspaceRoleWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WorkspaceRoleWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	permissions?: GraphQLTypes["EnumPermissionNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	memberships?: GraphQLTypes["MembershipListRelationFilter"] | undefined
};
	["EnumPermissionNullableListFilter"]: {
		equals?: Array<GraphQLTypes["Permission"]> | undefined,
	has?: GraphQLTypes["Permission"] | undefined,
	hasEvery?: Array<GraphQLTypes["Permission"]> | undefined,
	hasSome?: Array<GraphQLTypes["Permission"]> | undefined,
	isEmpty?: boolean | undefined
};
	["Permission"]: Permission;
	["PlaylistListRelationFilter"]: {
		every?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	some?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	none?: GraphQLTypes["PlaylistWhereInput"] | undefined
};
	["PlaylistWhereInput"]: {
		AND?: Array<GraphQLTypes["PlaylistWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlaylistWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlaylistWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	assets?: Array<GraphQLTypes["PisignageFileObjectEqualityInput"]> | undefined,
	isAdsPlaylist?: GraphQLTypes["BoolNullableFilter"] | undefined,
	footerPlaylistId?: GraphQLTypes["StringNullableFilter"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["StringNullableFilter"] | undefined,
	layout?: GraphQLTypes["EnumScreenLayoutFilter"] | undefined,
	isDeployed?: GraphQLTypes["BoolNullableFilter"] | undefined,
	adBoardGroupIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistNullableRelationFilter"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistNullableRelationFilter"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupListRelationFilter"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupListRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistListRelationFilter"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistListRelationFilter"] | undefined
};
	["PisignageFileObjectEqualityInput"]: {
		name: string,
	duration?: number | undefined,
	muteAudio?: boolean | undefined,
	isVideo?: boolean | undefined
};
	["BoolNullableFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["NestedBoolNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedBoolNullableFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["NestedBoolNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["EnumScreenLayoutFilter"]: {
		equals?: GraphQLTypes["ScreenLayout"] | undefined,
	in?: Array<GraphQLTypes["ScreenLayout"]> | undefined,
	notIn?: Array<GraphQLTypes["ScreenLayout"]> | undefined,
	not?: GraphQLTypes["NestedEnumScreenLayoutFilter"] | undefined
};
	["ScreenLayout"]: ScreenLayout;
	["NestedEnumScreenLayoutFilter"]: {
		equals?: GraphQLTypes["ScreenLayout"] | undefined,
	in?: Array<GraphQLTypes["ScreenLayout"]> | undefined,
	notIn?: Array<GraphQLTypes["ScreenLayout"]> | undefined,
	not?: GraphQLTypes["NestedEnumScreenLayoutFilter"] | undefined
};
	["PlaylistNullableRelationFilter"]: {
		is?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	isNot?: GraphQLTypes["PlaylistWhereInput"] | undefined
};
	["AdBoardGroupListRelationFilter"]: {
		every?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined,
	some?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined,
	none?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined
};
	["AdBoardGroupWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardGroupWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardGroupWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardGroupWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringNullableFilter"] | undefined,
	schedules?: Array<GraphQLTypes["ScheduleObjectEqualityInput"]> | undefined,
	scheduledPlaylistsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	adsPlaylistID?: GraphQLTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: GraphQLTypes["StringFilter"] | undefined,
	fitImage?: GraphQLTypes["EnumFitTypeFilter"] | undefined,
	fitVideo?: GraphQLTypes["EnumFitTypeFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardNullableRelationFilter"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistListRelationFilter"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistNullableRelationFilter"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerListRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined
};
	["ScheduleObjectEqualityInput"]: {
		playlistId: string,
	playlistType?: string | undefined,
	skipForSchedule?: boolean | undefined,
	startTime?: string | undefined,
	endTime?: string | undefined
};
	["EnumFitTypeFilter"]: {
		equals?: GraphQLTypes["FitType"] | undefined,
	in?: Array<GraphQLTypes["FitType"]> | undefined,
	notIn?: Array<GraphQLTypes["FitType"]> | undefined,
	not?: GraphQLTypes["NestedEnumFitTypeFilter"] | undefined
};
	["FitType"]: FitType;
	["NestedEnumFitTypeFilter"]: {
		equals?: GraphQLTypes["FitType"] | undefined,
	in?: Array<GraphQLTypes["FitType"]> | undefined,
	notIn?: Array<GraphQLTypes["FitType"]> | undefined,
	not?: GraphQLTypes["NestedEnumFitTypeFilter"] | undefined
};
	["AdBoardNullableRelationFilter"]: {
		is?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	isNot?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["PlayerListRelationFilter"]: {
		every?: GraphQLTypes["PlayerWhereInput"] | undefined,
	some?: GraphQLTypes["PlayerWhereInput"] | undefined,
	none?: GraphQLTypes["PlayerWhereInput"] | undefined
};
	["PlayerWhereInput"]: {
		AND?: Array<GraphQLTypes["PlayerWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlayerWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlayerWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	configLocation?: GraphQLTypes["StringNullableFilter"] | undefined,
	pisignagePlayerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: GraphQLTypes["StringNullableFilter"] | undefined,
	cpuSerialNumber?: GraphQLTypes["StringNullableFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	adBoardGroupId?: GraphQLTypes["StringNullableFilter"] | undefined,
	playlistIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workpaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	resolution?: GraphQLTypes["ResolutionNullableCompositeFilter"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeNullableCompositeFilter"] | undefined,
	signageTypeId?: GraphQLTypes["StringNullableFilter"] | undefined,
	audienceTypeId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeObjectEqualityInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["EnumAspectRatioTypeFilter"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileObjectEqualityInput"]> | undefined,
	customAspectRatioWidth?: GraphQLTypes["IntNullableFilter"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["IntNullableFilter"] | undefined,
	map?: GraphQLTypes["MapNullableCompositeFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolFilter"] | undefined,
	adBoardIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupNullableRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusListRelationFilter"] | undefined,
	cameras?: GraphQLTypes["CameraListRelationFilter"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageNullableRelationFilter"] | undefined,
	audienceType?: GraphQLTypes["AudienceListRelationFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined
};
	["EnumAspectRatioTypeFilter"]: {
		equals?: GraphQLTypes["AspectRatioType"] | undefined,
	in?: Array<GraphQLTypes["AspectRatioType"]> | undefined,
	notIn?: Array<GraphQLTypes["AspectRatioType"]> | undefined,
	not?: GraphQLTypes["NestedEnumAspectRatioTypeFilter"] | undefined
};
	["AspectRatioType"]: AspectRatioType;
	["NestedEnumAspectRatioTypeFilter"]: {
		equals?: GraphQLTypes["AspectRatioType"] | undefined,
	in?: Array<GraphQLTypes["AspectRatioType"]> | undefined,
	notIn?: Array<GraphQLTypes["AspectRatioType"]> | undefined,
	not?: GraphQLTypes["NestedEnumAspectRatioTypeFilter"] | undefined
};
	["GalleryFileObjectEqualityInput"]: {
		name: string,
	url: string,
	contentType: string,
	width: number,
	height: number,
	duration?: number | undefined
};
	["MapNullableCompositeFilter"]: {
		equals?: GraphQLTypes["MapObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["MapWhereInput"] | undefined,
	isNot?: GraphQLTypes["MapWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["AdBoardGroupNullableRelationFilter"]: {
		is?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined,
	isNot?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined
};
	["PlayerUptimeStatusListRelationFilter"]: {
		every?: GraphQLTypes["PlayerUptimeStatusWhereInput"] | undefined,
	some?: GraphQLTypes["PlayerUptimeStatusWhereInput"] | undefined,
	none?: GraphQLTypes["PlayerUptimeStatusWhereInput"] | undefined
};
	["PlayerUptimeStatusWhereInput"]: {
		AND?: Array<GraphQLTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	playerId?: GraphQLTypes["StringFilter"] | undefined,
	cpuSerialNumber?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["StringFilter"] | undefined,
	uptime?: GraphQLTypes["FloatFilter"] | undefined,
	player?: GraphQLTypes["PlayerRelationFilter"] | undefined
};
	["PlayerRelationFilter"]: {
		is?: GraphQLTypes["PlayerWhereInput"] | undefined,
	isNot?: GraphQLTypes["PlayerWhereInput"] | undefined
};
	["CameraListRelationFilter"]: {
		every?: GraphQLTypes["CameraWhereInput"] | undefined,
	some?: GraphQLTypes["CameraWhereInput"] | undefined,
	none?: GraphQLTypes["CameraWhereInput"] | undefined
};
	["CameraWhereInput"]: {
		AND?: Array<GraphQLTypes["CameraWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringNullableFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolFilter"] | undefined,
	cameraServerId?: GraphQLTypes["StringFilter"] | undefined,
	cameraUrl?: GraphQLTypes["StringFilter"] | undefined,
	rtspUrl?: GraphQLTypes["StringFilter"] | undefined,
	cameraIp?: GraphQLTypes["StringFilter"] | undefined,
	connectedCpuSerialNumbers?: GraphQLTypes["StringNullableListFilter"] | undefined,
	playerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	isPublic?: GraphQLTypes["BoolFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringNullableFilter"] | undefined,
	cameraServer?: GraphQLTypes["CameraServerRelationFilter"] | undefined,
	player?: GraphQLTypes["PlayerNullableRelationFilter"] | undefined,
	feeds?: GraphQLTypes["CameraFeedListRelationFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardNullableRelationFilter"] | undefined
};
	["CameraServerRelationFilter"]: {
		is?: GraphQLTypes["CameraServerWhereInput"] | undefined,
	isNot?: GraphQLTypes["CameraServerWhereInput"] | undefined
};
	["CameraServerWhereInput"]: {
		AND?: Array<GraphQLTypes["CameraServerWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraServerWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraServerWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringNullableFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolNullableFilter"] | undefined,
	hostName?: GraphQLTypes["StringNullableFilter"] | undefined,
	userName?: GraphQLTypes["StringNullableFilter"] | undefined,
	deviceId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	publicIp?: GraphQLTypes["StringFilter"] | undefined,
	privateIp?: GraphQLTypes["StringFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	cameras?: GraphQLTypes["CameraListRelationFilter"] | undefined,
	feeds?: GraphQLTypes["CameraFeedListRelationFilter"] | undefined
};
	["CameraFeedListRelationFilter"]: {
		every?: GraphQLTypes["CameraFeedWhereInput"] | undefined,
	some?: GraphQLTypes["CameraFeedWhereInput"] | undefined,
	none?: GraphQLTypes["CameraFeedWhereInput"] | undefined
};
	["CameraFeedWhereInput"]: {
		AND?: Array<GraphQLTypes["CameraFeedWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraFeedWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraFeedWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	cameraId?: GraphQLTypes["StringFilter"] | undefined,
	timestamp?: GraphQLTypes["DateTimeFilter"] | undefined,
	cameraServerId?: GraphQLTypes["StringFilter"] | undefined,
	data?: GraphQLTypes["CameraFeedDataCompositeFilter"] | undefined,
	camera?: GraphQLTypes["CameraRelationFilter"] | undefined,
	cameraServer?: GraphQLTypes["CameraServerRelationFilter"] | undefined
};
	["CameraFeedDataCompositeFilter"]: {
		equals?: GraphQLTypes["CameraFeedDataObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["CameraFeedDataWhereInput"] | undefined,
	isNot?: GraphQLTypes["CameraFeedDataWhereInput"] | undefined
};
	["CameraFeedDataObjectEqualityInput"]: {
		person?: number | undefined,
	car?: number | undefined,
	truck?: number | undefined,
	motorcycle?: number | undefined
};
	["CameraFeedDataWhereInput"]: {
		AND?: Array<GraphQLTypes["CameraFeedDataWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraFeedDataWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraFeedDataWhereInput"]> | undefined,
	person?: GraphQLTypes["IntNullableFilter"] | undefined,
	car?: GraphQLTypes["IntNullableFilter"] | undefined,
	truck?: GraphQLTypes["IntNullableFilter"] | undefined,
	motorcycle?: GraphQLTypes["IntNullableFilter"] | undefined
};
	["CameraRelationFilter"]: {
		is?: GraphQLTypes["CameraWhereInput"] | undefined,
	isNot?: GraphQLTypes["CameraWhereInput"] | undefined
};
	["PlayerNullableRelationFilter"]: {
		is?: GraphQLTypes["PlayerWhereInput"] | undefined,
	isNot?: GraphQLTypes["PlayerWhereInput"] | undefined
};
	["DigitalSignageNullableRelationFilter"]: {
		is?: GraphQLTypes["DigitalSignageWhereInput"] | undefined,
	isNot?: GraphQLTypes["DigitalSignageWhereInput"] | undefined
};
	["DigitalSignageWhereInput"]: {
		AND?: Array<GraphQLTypes["DigitalSignageWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["DigitalSignageWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["DigitalSignageWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumSignageTypeFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	players?: GraphQLTypes["PlayerListRelationFilter"] | undefined
};
	["EnumSignageTypeFilter"]: {
		equals?: GraphQLTypes["SignageType"] | undefined,
	in?: Array<GraphQLTypes["SignageType"]> | undefined,
	notIn?: Array<GraphQLTypes["SignageType"]> | undefined,
	not?: GraphQLTypes["NestedEnumSignageTypeFilter"] | undefined
};
	["SignageType"]: SignageType;
	["NestedEnumSignageTypeFilter"]: {
		equals?: GraphQLTypes["SignageType"] | undefined,
	in?: Array<GraphQLTypes["SignageType"]> | undefined,
	notIn?: Array<GraphQLTypes["SignageType"]> | undefined,
	not?: GraphQLTypes["NestedEnumSignageTypeFilter"] | undefined
};
	["AudienceListRelationFilter"]: {
		every?: GraphQLTypes["AudienceWhereInput"] | undefined,
	some?: GraphQLTypes["AudienceWhereInput"] | undefined,
	none?: GraphQLTypes["AudienceWhereInput"] | undefined
};
	["AudienceWhereInput"]: {
		AND?: Array<GraphQLTypes["AudienceWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AudienceWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AudienceWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumAudienceTypeFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	playerIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	players?: GraphQLTypes["PlayerListRelationFilter"] | undefined
};
	["EnumAudienceTypeFilter"]: {
		equals?: GraphQLTypes["AudienceType"] | undefined,
	in?: Array<GraphQLTypes["AudienceType"]> | undefined,
	notIn?: Array<GraphQLTypes["AudienceType"]> | undefined,
	not?: GraphQLTypes["NestedEnumAudienceTypeFilter"] | undefined
};
	["AudienceType"]: AudienceType;
	["NestedEnumAudienceTypeFilter"]: {
		equals?: GraphQLTypes["AudienceType"] | undefined,
	in?: Array<GraphQLTypes["AudienceType"]> | undefined,
	notIn?: Array<GraphQLTypes["AudienceType"]> | undefined,
	not?: GraphQLTypes["NestedEnumAudienceTypeFilter"] | undefined
};
	["AuditListRelationFilter"]: {
		every?: GraphQLTypes["AuditWhereInput"] | undefined,
	some?: GraphQLTypes["AuditWhereInput"] | undefined,
	none?: GraphQLTypes["AuditWhereInput"] | undefined
};
	["AuditWhereInput"]: {
		AND?: Array<GraphQLTypes["AuditWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AuditWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AuditWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	message?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["StringFilter"] | undefined,
	entity?: GraphQLTypes["EntityNullableCompositeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updates?: GraphQLTypes["JsonNullableFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined
};
	["EntityNullableCompositeFilter"]: {
		equals?: GraphQLTypes["EntityObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["EntityWhereInput"] | undefined,
	isNot?: GraphQLTypes["EntityWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["EntityObjectEqualityInput"]: {
		type: string,
	id: string,
	name?: string | undefined
};
	["EntityWhereInput"]: {
		AND?: Array<GraphQLTypes["EntityWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["EntityWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["EntityWhereInput"]> | undefined,
	type?: GraphQLTypes["StringFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["TransactionListRelationFilter"]: {
		every?: GraphQLTypes["TransactionWhereInput"] | undefined,
	some?: GraphQLTypes["TransactionWhereInput"] | undefined,
	none?: GraphQLTypes["TransactionWhereInput"] | undefined
};
	["TransactionWhereInput"]: {
		AND?: Array<GraphQLTypes["TransactionWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["TransactionWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["TransactionWhereInput"]> | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	amount?: GraphQLTypes["FloatFilter"] | undefined,
	createAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	processedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	relatedEntityId?: GraphQLTypes["StringFilter"] | undefined,
	transactionStatus?: GraphQLTypes["EnumTransactionStatusFilter"] | undefined,
	details?: GraphQLTypes["JsonNullableFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	payouts?: GraphQLTypes["PayoutListRelationFilter"] | undefined
};
	["EnumTransactionStatusFilter"]: {
		equals?: GraphQLTypes["TransactionStatus"] | undefined,
	in?: Array<GraphQLTypes["TransactionStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["TransactionStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumTransactionStatusFilter"] | undefined
};
	["TransactionStatus"]: TransactionStatus;
	["NestedEnumTransactionStatusFilter"]: {
		equals?: GraphQLTypes["TransactionStatus"] | undefined,
	in?: Array<GraphQLTypes["TransactionStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["TransactionStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumTransactionStatusFilter"] | undefined
};
	["PayoutListRelationFilter"]: {
		every?: GraphQLTypes["PayoutWhereInput"] | undefined,
	some?: GraphQLTypes["PayoutWhereInput"] | undefined,
	none?: GraphQLTypes["PayoutWhereInput"] | undefined
};
	["PayoutWhereInput"]: {
		AND?: Array<GraphQLTypes["PayoutWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PayoutWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PayoutWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	totalAmount?: GraphQLTypes["FloatFilter"] | undefined,
	commissionRate?: GraphQLTypes["FloatFilter"] | undefined,
	commissionAmount?: GraphQLTypes["FloatFilter"] | undefined,
	netAmount?: GraphQLTypes["FloatFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	status?: GraphQLTypes["EnumPayoutStatusFilter"] | undefined,
	transactionId?: GraphQLTypes["StringNullableFilter"] | undefined,
	booking?: GraphQLTypes["BookingRelationFilter"] | undefined,
	transaction?: GraphQLTypes["TransactionNullableRelationFilter"] | undefined
};
	["EnumPayoutStatusFilter"]: {
		equals?: GraphQLTypes["PayoutStatus"] | undefined,
	in?: Array<GraphQLTypes["PayoutStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PayoutStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPayoutStatusFilter"] | undefined
};
	["PayoutStatus"]: PayoutStatus;
	["NestedEnumPayoutStatusFilter"]: {
		equals?: GraphQLTypes["PayoutStatus"] | undefined,
	in?: Array<GraphQLTypes["PayoutStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PayoutStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPayoutStatusFilter"] | undefined
};
	["BookingRelationFilter"]: {
		is?: GraphQLTypes["BookingWhereInput"] | undefined,
	isNot?: GraphQLTypes["BookingWhereInput"] | undefined
};
	["TransactionNullableRelationFilter"]: {
		is?: GraphQLTypes["TransactionWhereInput"] | undefined,
	isNot?: GraphQLTypes["TransactionWhereInput"] | undefined
};
	["SubscriptionNullableRelationFilter"]: {
		is?: GraphQLTypes["SubscriptionWhereInput"] | undefined,
	isNot?: GraphQLTypes["SubscriptionWhereInput"] | undefined
};
	["SubscriptionWhereInput"]: {
		AND?: Array<GraphQLTypes["SubscriptionWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["SubscriptionWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SubscriptionWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	packageId?: GraphQLTypes["StringNullableFilter"] | undefined,
	packageName?: GraphQLTypes["StringNullableFilter"] | undefined,
	priceLookupKey?: GraphQLTypes["StringNullableFilter"] | undefined,
	startDate?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	endDate?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	status?: GraphQLTypes["StringFilter"] | undefined,
	stripePaymentIntentId?: GraphQLTypes["StringNullableFilter"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["StringFilter"] | undefined,
	stripeCheckoutSessionId?: GraphQLTypes["StringFilter"] | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsNullableCompositeFilter"] | undefined,
	activeWorkspaceId?: GraphQLTypes["StringFilter"] | undefined,
	activeWorkspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined
};
	["CancellationDetailsNullableCompositeFilter"]: {
		equals?: GraphQLTypes["CancellationDetailsObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["CancellationDetailsWhereInput"] | undefined,
	isNot?: GraphQLTypes["CancellationDetailsWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["CancellationDetailsObjectEqualityInput"]: {
		canceledAt?: GraphQLTypes["DateTime"] | undefined,
	cancelAt?: GraphQLTypes["DateTime"] | undefined,
	cancelAtPeriodEnd: boolean,
	reason?: string | undefined
};
	["CancellationDetailsWhereInput"]: {
		AND?: Array<GraphQLTypes["CancellationDetailsWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CancellationDetailsWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CancellationDetailsWhereInput"]> | undefined,
	canceledAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	cancelAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	cancelAtPeriodEnd?: GraphQLTypes["BoolFilter"] | undefined,
	reason?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["CameraServerListRelationFilter"]: {
		every?: GraphQLTypes["CameraServerWhereInput"] | undefined,
	some?: GraphQLTypes["CameraServerWhereInput"] | undefined,
	none?: GraphQLTypes["CameraServerWhereInput"] | undefined
};
	["WorkspaceRoleListRelationFilter"]: {
		every?: GraphQLTypes["WorkspaceRoleWhereInput"] | undefined,
	some?: GraphQLTypes["WorkspaceRoleWhereInput"] | undefined,
	none?: GraphQLTypes["WorkspaceRoleWhereInput"] | undefined
};
	["WebhookLogListRelationFilter"]: {
		every?: GraphQLTypes["WebhookLogWhereInput"] | undefined,
	some?: GraphQLTypes["WebhookLogWhereInput"] | undefined,
	none?: GraphQLTypes["WebhookLogWhereInput"] | undefined
};
	["WebhookLogWhereInput"]: {
		AND?: Array<GraphQLTypes["WebhookLogWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WebhookLogWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WebhookLogWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	response?: GraphQLTypes["JsonNullableFilter"] | undefined,
	request?: GraphQLTypes["JsonNullableFilter"] | undefined,
	status?: GraphQLTypes["IntFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	booking?: GraphQLTypes["BookingRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined
};
	["UserListRelationFilter"]: {
		every?: GraphQLTypes["UserWhereInput"] | undefined,
	some?: GraphQLTypes["UserWhereInput"] | undefined,
	none?: GraphQLTypes["UserWhereInput"] | undefined
};
	["BookingNullableRelationFilter"]: {
		is?: GraphQLTypes["BookingWhereInput"] | undefined,
	isNot?: GraphQLTypes["BookingWhereInput"] | undefined
};
	["AdvertisementCategoryListRelationFilter"]: {
		every?: GraphQLTypes["AdvertisementCategoryWhereInput"] | undefined,
	some?: GraphQLTypes["AdvertisementCategoryWhereInput"] | undefined,
	none?: GraphQLTypes["AdvertisementCategoryWhereInput"] | undefined
};
	["AdvertisementCategoryWhereInput"]: {
		AND?: Array<GraphQLTypes["AdvertisementCategoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdvertisementCategoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdvertisementCategoryWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumAdvertisementCategoryEnumFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	campaignIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	campaigns?: GraphQLTypes["CampaignListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined
};
	["EnumAdvertisementCategoryEnumFilter"]: {
		equals?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	in?: Array<GraphQLTypes["AdvertisementCategoryEnum"]> | undefined,
	notIn?: Array<GraphQLTypes["AdvertisementCategoryEnum"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined
};
	["AdvertisementCategoryEnum"]: AdvertisementCategoryEnum;
	["NestedEnumAdvertisementCategoryEnumFilter"]: {
		equals?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	in?: Array<GraphQLTypes["AdvertisementCategoryEnum"]> | undefined,
	notIn?: Array<GraphQLTypes["AdvertisementCategoryEnum"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined
};
	["AdBoardRelationFilter"]: {
		is?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	isNot?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["ChangelogListRelationFilter"]: {
		every?: GraphQLTypes["ChangelogWhereInput"] | undefined,
	some?: GraphQLTypes["ChangelogWhereInput"] | undefined,
	none?: GraphQLTypes["ChangelogWhereInput"] | undefined
};
	["ChangelogWhereInput"]: {
		AND?: Array<GraphQLTypes["ChangelogWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["ChangelogWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["ChangelogWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	message?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["StringFilter"] | undefined,
	bookingId?: GraphQLTypes["StringNullableFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	updates?: GraphQLTypes["JsonNullableFilter"] | undefined,
	booking?: GraphQLTypes["BookingNullableRelationFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["CampaignNullableRelationFilter"]: {
		is?: GraphQLTypes["CampaignWhereInput"] | undefined,
	isNot?: GraphQLTypes["CampaignWhereInput"] | undefined
};
	["SlotUsageListRelationFilter"]: {
		every?: GraphQLTypes["SlotUsageWhereInput"] | undefined,
	some?: GraphQLTypes["SlotUsageWhereInput"] | undefined,
	none?: GraphQLTypes["SlotUsageWhereInput"] | undefined
};
	["SlotUsageWhereInput"]: {
		AND?: Array<GraphQLTypes["SlotUsageWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["SlotUsageWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SlotUsageWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["DateTimeFilter"] | undefined,
	slotUsed?: GraphQLTypes["IntFilter"] | undefined,
	booking?: GraphQLTypes["BookingRelationFilter"] | undefined
};
	["OrderListRelationFilter"]: {
		every?: GraphQLTypes["OrderWhereInput"] | undefined,
	some?: GraphQLTypes["OrderWhereInput"] | undefined,
	none?: GraphQLTypes["OrderWhereInput"] | undefined
};
	["OrderWhereInput"]: {
		AND?: Array<GraphQLTypes["OrderWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["OrderWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["OrderWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	createdDate?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	origin?: GraphQLTypes["StringFilter"] | undefined,
	paymentId?: GraphQLTypes["StringNullableFilter"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCompositeFilter"] | undefined,
	selectedDate?: GraphQLTypes["OrderSelectedDateCompositeFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	createdBy?: GraphQLTypes["UserNullableRelationFilter"] | undefined
};
	["PriceSummaryCompositeFilter"]: {
		equals?: GraphQLTypes["PriceSummaryObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["PriceSummaryWhereInput"] | undefined,
	isNot?: GraphQLTypes["PriceSummaryWhereInput"] | undefined
};
	["NotificationListRelationFilter"]: {
		every?: GraphQLTypes["NotificationWhereInput"] | undefined,
	some?: GraphQLTypes["NotificationWhereInput"] | undefined,
	none?: GraphQLTypes["NotificationWhereInput"] | undefined
};
	["NotificationWhereInput"]: {
		AND?: Array<GraphQLTypes["NotificationWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["NotificationWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["NotificationWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumNotificationTypeFilter"] | undefined,
	title?: GraphQLTypes["StringFilter"] | undefined,
	content?: GraphQLTypes["StringFilter"] | undefined,
	metadata?: GraphQLTypes["JsonNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	readAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	recipientId?: GraphQLTypes["StringFilter"] | undefined,
	channels?: GraphQLTypes["EnumNotificationChannelNullableListFilter"] | undefined,
	recipient?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["EnumNotificationTypeFilter"]: {
		equals?: GraphQLTypes["NotificationType"] | undefined,
	in?: Array<GraphQLTypes["NotificationType"]> | undefined,
	notIn?: Array<GraphQLTypes["NotificationType"]> | undefined,
	not?: GraphQLTypes["NestedEnumNotificationTypeFilter"] | undefined
};
	["NestedEnumNotificationTypeFilter"]: {
		equals?: GraphQLTypes["NotificationType"] | undefined,
	in?: Array<GraphQLTypes["NotificationType"]> | undefined,
	notIn?: Array<GraphQLTypes["NotificationType"]> | undefined,
	not?: GraphQLTypes["NestedEnumNotificationTypeFilter"] | undefined
};
	["AdBoardPublishHistoryListRelationFilter"]: {
		every?: GraphQLTypes["AdBoardPublishHistoryWhereInput"] | undefined,
	some?: GraphQLTypes["AdBoardPublishHistoryWhereInput"] | undefined,
	none?: GraphQLTypes["AdBoardPublishHistoryWhereInput"] | undefined
};
	["AdBoardPublishHistoryWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	publishedByUserId?: GraphQLTypes["StringFilter"] | undefined,
	changesSnapshot?: GraphQLTypes["JsonFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	publishedBy?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["JsonFilter"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not?: GraphQLTypes["JSON"] | undefined
};
	["AdBoardReviewCommentListRelationFilter"]: {
		every?: GraphQLTypes["AdBoardReviewCommentWhereInput"] | undefined,
	some?: GraphQLTypes["AdBoardReviewCommentWhereInput"] | undefined,
	none?: GraphQLTypes["AdBoardReviewCommentWhereInput"] | undefined
};
	["AdBoardReviewCommentWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	comment?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["EnumReviewActionFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["EnumReviewActionFilter"]: {
		equals?: GraphQLTypes["ReviewAction"] | undefined,
	in?: Array<GraphQLTypes["ReviewAction"]> | undefined,
	notIn?: Array<GraphQLTypes["ReviewAction"]> | undefined,
	not?: GraphQLTypes["NestedEnumReviewActionFilter"] | undefined
};
	["ReviewAction"]: ReviewAction;
	["NestedEnumReviewActionFilter"]: {
		equals?: GraphQLTypes["ReviewAction"] | undefined,
	in?: Array<GraphQLTypes["ReviewAction"]> | undefined,
	notIn?: Array<GraphQLTypes["ReviewAction"]> | undefined,
	not?: GraphQLTypes["NestedEnumReviewActionFilter"] | undefined
};
	["WorkspaceListRelationFilter"]: {
		every?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	some?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	none?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["AdBoardPublicReviewListRelationFilter"]: {
		every?: GraphQLTypes["AdBoardPublicReviewWhereInput"] | undefined,
	some?: GraphQLTypes["AdBoardPublicReviewWhereInput"] | undefined,
	none?: GraphQLTypes["AdBoardPublicReviewWhereInput"] | undefined
};
	["AdBoardPublicReviewWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	rating?: GraphQLTypes["IntFilter"] | undefined,
	comment?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["UserPositionListRelationFilter"]: {
		every?: GraphQLTypes["UserPositionWhereInput"] | undefined,
	some?: GraphQLTypes["UserPositionWhereInput"] | undefined,
	none?: GraphQLTypes["UserPositionWhereInput"] | undefined
};
	["UserPositionWhereInput"]: {
		AND?: Array<GraphQLTypes["UserPositionWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserPositionWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserPositionWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	timestamp?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	coords?: GraphQLTypes["UserPositionCoordsCompositeFilter"] | undefined,
	location?: GraphQLTypes["UserPositionLocationNullableCompositeFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["UserPositionCoordsCompositeFilter"]: {
		equals?: GraphQLTypes["UserPositionCoordsObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["UserPositionCoordsWhereInput"] | undefined,
	isNot?: GraphQLTypes["UserPositionCoordsWhereInput"] | undefined
};
	["UserPositionCoordsObjectEqualityInput"]: {
		accuracy?: number | undefined,
	altitude?: number | undefined,
	altitudeAccuracy?: number | undefined,
	heading?: number | undefined,
	latitude: number,
	longitude: number,
	speed?: number | undefined
};
	["UserPositionCoordsWhereInput"]: {
		AND?: Array<GraphQLTypes["UserPositionCoordsWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserPositionCoordsWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserPositionCoordsWhereInput"]> | undefined,
	accuracy?: GraphQLTypes["FloatNullableFilter"] | undefined,
	altitude?: GraphQLTypes["FloatNullableFilter"] | undefined,
	altitudeAccuracy?: GraphQLTypes["FloatNullableFilter"] | undefined,
	heading?: GraphQLTypes["FloatNullableFilter"] | undefined,
	latitude?: GraphQLTypes["FloatFilter"] | undefined,
	longitude?: GraphQLTypes["FloatFilter"] | undefined,
	speed?: GraphQLTypes["FloatNullableFilter"] | undefined
};
	["UserPositionLocationNullableCompositeFilter"]: {
		equals?: GraphQLTypes["UserPositionLocationObjectEqualityInput"] | undefined,
	is?: GraphQLTypes["UserPositionLocationWhereInput"] | undefined,
	isNot?: GraphQLTypes["UserPositionLocationWhereInput"] | undefined,
	isSet?: boolean | undefined
};
	["UserPositionLocationObjectEqualityInput"]: {
		address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["UserPositionLocationWhereInput"]: {
		AND?: Array<GraphQLTypes["UserPositionLocationWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserPositionLocationWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserPositionLocationWhereInput"]> | undefined,
	address?: GraphQLTypes["StringNullableFilter"] | undefined,
	city?: GraphQLTypes["StringNullableFilter"] | undefined,
	state?: GraphQLTypes["StringNullableFilter"] | undefined,
	country?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["BusinessCategoryRelationFilter"]: {
		is?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	isNot?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined
};
	["PaymentListRelationFilter"]: {
		every?: GraphQLTypes["PaymentWhereInput"] | undefined,
	some?: GraphQLTypes["PaymentWhereInput"] | undefined,
	none?: GraphQLTypes["PaymentWhereInput"] | undefined
};
	["PaymentWhereInput"]: {
		AND?: Array<GraphQLTypes["PaymentWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PaymentWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PaymentWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	paymentIntentId?: GraphQLTypes["StringFilter"] | undefined,
	checkoutSessionId?: GraphQLTypes["StringFilter"] | undefined,
	eventId?: GraphQLTypes["StringFilter"] | undefined,
	liveMode?: GraphQLTypes["BoolFilter"] | undefined,
	paymentGateway?: GraphQLTypes["EnumPaymentGatewayNameFilter"] | undefined,
	subtotal?: GraphQLTypes["FloatFilter"] | undefined,
	total?: GraphQLTypes["FloatFilter"] | undefined,
	tax?: GraphQLTypes["FloatFilter"] | undefined,
	discount?: GraphQLTypes["FloatFilter"] | undefined,
	campaignId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	campaign?: GraphQLTypes["CampaignNullableRelationFilter"] | undefined
};
	["BusinessCategoryListRelationFilter"]: {
		every?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	some?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	none?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined
};
	["SlotConfigurationListRelationFilter"]: {
		every?: GraphQLTypes["SlotConfigurationWhereInput"] | undefined,
	some?: GraphQLTypes["SlotConfigurationWhereInput"] | undefined,
	none?: GraphQLTypes["SlotConfigurationWhereInput"] | undefined
};
	["SlotConfigurationWhereInput"]: {
		AND?: Array<GraphQLTypes["SlotConfigurationWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["SlotConfigurationWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SlotConfigurationWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["DateTimeFilter"] | undefined,
	availableSlots?: GraphQLTypes["IntFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined
};
	["AdBoardOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	address?: GraphQLTypes["SortOrder"] | undefined,
	state?: GraphQLTypes["SortOrder"] | undefined,
	city?: GraphQLTypes["SortOrder"] | undefined,
	country?: GraphQLTypes["SortOrder"] | undefined,
	map?: GraphQLTypes["MapOrderByInput"] | undefined,
	outdoor?: GraphQLTypes["SortOrder"] | undefined,
	locationCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	locationSubCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	alias?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	pricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	originalPricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	aboutSpecifications?: GraphQLTypes["SpecificationOrderByCompositeAggregateInput"] | undefined,
	adBoardTypeId?: GraphQLTypes["SortOrder"] | undefined,
	tags?: GraphQLTypes["SortOrder"] | undefined,
	featuredImage?: GraphQLTypes["SortOrder"] | undefined,
	galleryImgs?: GraphQLTypes["SortOrder"] | undefined,
	specifications?: GraphQLTypes["SpecificationOrderByCompositeAggregateInput"] | undefined,
	notes?: GraphQLTypes["SortOrder"] | undefined,
	impressionMultiplier?: GraphQLTypes["SortOrder"] | undefined,
	resolution?: GraphQLTypes["ResolutionOrderByInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeOrderByInput"] | undefined,
	categoryId?: GraphQLTypes["SortOrder"] | undefined,
	restrictedBusinessIds?: GraphQLTypes["SortOrder"] | undefined,
	targetAudiences?: GraphQLTypes["SortOrder"] | undefined,
	playbackTimes?: GraphQLTypes["PlaybackTimeOrderByCompositeAggregateInput"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	adboardDraftStatus?: GraphQLTypes["SortOrder"] | undefined,
	isLive?: GraphQLTypes["SortOrder"] | undefined,
	webhooks?: GraphQLTypes["WebhookOrderByCompositeAggregateInput"] | undefined,
	numberOfDisplays?: GraphQLTypes["SortOrder"] | undefined,
	dailyOperationHours?: GraphQLTypes["SortOrder"] | undefined,
	adsPerLoop?: GraphQLTypes["SortOrder"] | undefined,
	adsDuration?: GraphQLTypes["SortOrder"] | undefined,
	monthlyPrice?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingContactId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	href?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	playerIds?: GraphQLTypes["SortOrder"] | undefined,
	publisherScreenId?: GraphQLTypes["SortOrder"] | undefined,
	additionalData?: GraphQLTypes["SortOrder"] | undefined,
	isDraft?: GraphQLTypes["SortOrder"] | undefined,
	publishedVersionId?: GraphQLTypes["SortOrder"] | undefined,
	lastPublishedAt?: GraphQLTypes["SortOrder"] | undefined,
	isArchived?: GraphQLTypes["SortOrder"] | undefined,
	archivedAt?: GraphQLTypes["SortOrder"] | undefined,
	archivedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryOrderByWithRelationInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryOrderByWithRelationInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeOrderByWithRelationInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryOrderByWithRelationInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryOrderByRelationAggregateInput"] | undefined,
	bookings?: GraphQLTypes["BookingOrderByRelationAggregateInput"] | undefined,
	orders?: GraphQLTypes["OrderOrderByRelationAggregateInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationOrderByRelationAggregateInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	createdBy?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	players?: GraphQLTypes["PlayerOrderByRelationAggregateInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupOrderByWithRelationInput"] | undefined,
	cameras?: GraphQLTypes["CameraOrderByRelationAggregateInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentOrderByRelationAggregateInput"] | undefined,
	archivedBy?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewOrderByRelationAggregateInput"] | undefined
};
	["SortOrder"]: SortOrder;
	["MapOrderByInput"]: {
		lat?: GraphQLTypes["SortOrder"] | undefined,
	lng?: GraphQLTypes["SortOrder"] | undefined,
	address?: GraphQLTypes["SortOrder"] | undefined,
	city?: GraphQLTypes["SortOrder"] | undefined,
	state?: GraphQLTypes["SortOrder"] | undefined,
	country?: GraphQLTypes["SortOrder"] | undefined
};
	["SpecificationOrderByCompositeAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["ResolutionOrderByInput"]: {
		height?: GraphQLTypes["SortOrder"] | undefined,
	width?: GraphQLTypes["SortOrder"] | undefined
};
	["ScreenSizeOrderByInput"]: {
		height?: GraphQLTypes["SortOrder"] | undefined,
	unit?: GraphQLTypes["SortOrder"] | undefined,
	width?: GraphQLTypes["SortOrder"] | undefined
};
	["PlaybackTimeOrderByCompositeAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["WebhookOrderByCompositeAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["LocationCategoryOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	adBoards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryOrderByRelationAggregateInput"] | undefined
};
	["AdBoardOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["LocationSubCategoryOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["LocationSubCategoryOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	locationCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryOrderByWithRelationInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined
};
	["AdBoardTypeOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	adBoards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined
};
	["BusinessCategoryOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	restrictedByAdBoardIds?: GraphQLTypes["SortOrder"] | undefined,
	adBoards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignOrderByRelationAggregateInput"] | undefined,
	bookings?: GraphQLTypes["BookingOrderByRelationAggregateInput"] | undefined
};
	["CampaignOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["BusinessCategoryOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["OrderOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["SlotConfigurationOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["UserOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	uid?: GraphQLTypes["SortOrder"] | undefined,
	email?: GraphQLTypes["SortOrder"] | undefined,
	image?: GraphQLTypes["SortOrder"] | undefined,
	phoneNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	otherDetails?: GraphQLTypes["SortOrder"] | undefined,
	stripe?: GraphQLTypes["UserStripeOrderByInput"] | undefined,
	roles?: GraphQLTypes["SortOrder"] | undefined,
	refunds?: GraphQLTypes["BookingRefundOrderByCompositeAggregateInput"] | undefined,
	sharedBookingIds?: GraphQLTypes["SortOrder"] | undefined,
	disclaimerAccepted?: GraphQLTypes["SortOrder"] | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceOrderByInput"] | undefined,
	leadWorkspaceIds?: GraphQLTypes["SortOrder"] | undefined,
	bookings?: GraphQLTypes["BookingOrderByRelationAggregateInput"] | undefined,
	createdFiles?: GraphQLTypes["FileOrderByRelationAggregateInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileOrderByRelationAggregateInput"] | undefined,
	orders?: GraphQLTypes["OrderOrderByRelationAggregateInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogOrderByRelationAggregateInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	audits?: GraphQLTypes["AuditOrderByRelationAggregateInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignOrderByRelationAggregateInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingOrderByRelationAggregateInput"] | undefined,
	memberships?: GraphQLTypes["MembershipOrderByRelationAggregateInput"] | undefined,
	notifications?: GraphQLTypes["NotificationOrderByRelationAggregateInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentOrderByRelationAggregateInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceOrderByRelationAggregateInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceOrderByRelationAggregateInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewOrderByRelationAggregateInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionOrderByRelationAggregateInput"] | undefined
};
	["UserStripeOrderByInput"]: {
		customerId?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingRefundOrderByCompositeAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["NotificationPreferenceOrderByInput"]: {
		type?: GraphQLTypes["SortOrder"] | undefined,
	channels?: GraphQLTypes["SortOrder"] | undefined,
	pushSetting?: GraphQLTypes["PushSettingOrderByInput"] | undefined,
	enabled?: GraphQLTypes["SortOrder"] | undefined
};
	["PushSettingOrderByInput"]: {
		fcmToken?: GraphQLTypes["SortOrder"] | undefined
};
	["FileOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["ChangelogOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["AuditOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["MembershipOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["NotificationOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardPublishHistoryOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardReviewCommentOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardPublicReviewOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["UserPositionOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	key?: GraphQLTypes["SortOrder"] | undefined,
	userIds?: GraphQLTypes["SortOrder"] | undefined,
	ownerId?: GraphQLTypes["SortOrder"] | undefined,
	avatar?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined,
	leadIds?: GraphQLTypes["SortOrder"] | undefined,
	owner?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	memberships?: GraphQLTypes["MembershipOrderByRelationAggregateInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistOrderByRelationAggregateInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined,
	audits?: GraphQLTypes["AuditOrderByRelationAggregateInput"] | undefined,
	bookings?: GraphQLTypes["BookingOrderByRelationAggregateInput"] | undefined,
	files?: GraphQLTypes["FileOrderByRelationAggregateInput"] | undefined,
	players?: GraphQLTypes["PlayerOrderByRelationAggregateInput"] | undefined,
	transactions?: GraphQLTypes["TransactionOrderByRelationAggregateInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionOrderByWithRelationInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerOrderByRelationAggregateInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleOrderByRelationAggregateInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogOrderByRelationAggregateInput"] | undefined,
	leads?: GraphQLTypes["UserOrderByRelationAggregateInput"] | undefined
};
	["PlaylistOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardGroupOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["PlayerOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["TransactionOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["SubscriptionOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	packageId?: GraphQLTypes["SortOrder"] | undefined,
	packageName?: GraphQLTypes["SortOrder"] | undefined,
	priceLookupKey?: GraphQLTypes["SortOrder"] | undefined,
	startDate?: GraphQLTypes["SortOrder"] | undefined,
	endDate?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: GraphQLTypes["SortOrder"] | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsOrderByInput"] | undefined,
	activeWorkspaceId?: GraphQLTypes["SortOrder"] | undefined,
	activeWorkspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined
};
	["CancellationDetailsOrderByInput"]: {
		canceledAt?: GraphQLTypes["SortOrder"] | undefined,
	cancelAt?: GraphQLTypes["SortOrder"] | undefined,
	cancelAtPeriodEnd?: GraphQLTypes["SortOrder"] | undefined,
	reason?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraServerOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceRoleOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["WebhookLogOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["UserOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardGroupOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	schedules?: GraphQLTypes["ScheduleOrderByCompositeAggregateInput"] | undefined,
	scheduledPlaylistsIDs?: GraphQLTypes["SortOrder"] | undefined,
	adsPlaylistID?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	fitImage?: GraphQLTypes["SortOrder"] | undefined,
	fitVideo?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	adBoard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistOrderByRelationAggregateInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistOrderByWithRelationInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerOrderByRelationAggregateInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined
};
	["ScheduleOrderByCompositeAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["PlaylistOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	assets?: GraphQLTypes["PisignageFileOrderByCompositeAggregateInput"] | undefined,
	isAdsPlaylist?: GraphQLTypes["SortOrder"] | undefined,
	footerPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	layout?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroupIds?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistOrderByWithRelationInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistOrderByWithRelationInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupOrderByRelationAggregateInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistOrderByRelationAggregateInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistOrderByRelationAggregateInput"] | undefined
};
	["PisignageFileOrderByCompositeAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardWhereUniqueInput"]: {
		id?: string | undefined,
	publishedVersionId?: string | undefined,
	workspaceId_alias?: GraphQLTypes["AdBoardWorkspaceIdAliasCompoundUniqueInput"] | undefined,
	AND?: Array<GraphQLTypes["AdBoardWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardWhereInput"]> | undefined,
	address?: GraphQLTypes["StringFilter"] | undefined,
	state?: GraphQLTypes["StringFilter"] | undefined,
	city?: GraphQLTypes["StringFilter"] | undefined,
	country?: GraphQLTypes["StringFilter"] | undefined,
	map?: GraphQLTypes["MapCompositeFilter"] | undefined,
	outdoor?: GraphQLTypes["BoolFilter"] | undefined,
	locationCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	locationSubCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	alias?: GraphQLTypes["StringNullableFilter"] | undefined,
	title?: GraphQLTypes["StringFilter"] | undefined,
	pricePerDay?: GraphQLTypes["IntFilter"] | undefined,
	originalPricePerDay?: GraphQLTypes["IntNullableFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationObjectEqualityInput"]> | undefined,
	adBoardTypeId?: GraphQLTypes["StringNullableFilter"] | undefined,
	tags?: GraphQLTypes["StringNullableListFilter"] | undefined,
	featuredImage?: GraphQLTypes["StringNullableFilter"] | undefined,
	galleryImgs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	specifications?: Array<GraphQLTypes["SpecificationObjectEqualityInput"]> | undefined,
	notes?: GraphQLTypes["StringNullableListFilter"] | undefined,
	impressionMultiplier?: GraphQLTypes["FloatNullableFilter"] | undefined,
	resolution?: GraphQLTypes["ResolutionNullableCompositeFilter"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeNullableCompositeFilter"] | undefined,
	categoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	restrictedBusinessIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	targetAudiences?: GraphQLTypes["StringNullableListFilter"] | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeObjectEqualityInput"]> | undefined,
	status?: GraphQLTypes["EnumAdBoardStatusFilter"] | undefined,
	adboardDraftStatus?: GraphQLTypes["EnumAdboardDraftStatusNullableFilter"] | undefined,
	isLive?: GraphQLTypes["BoolFilter"] | undefined,
	webhooks?: Array<GraphQLTypes["WebhookObjectEqualityInput"]> | undefined,
	numberOfDisplays?: GraphQLTypes["IntFilter"] | undefined,
	dailyOperationHours?: GraphQLTypes["IntFilter"] | undefined,
	adsPerLoop?: GraphQLTypes["IntFilter"] | undefined,
	adsDuration?: GraphQLTypes["IntFilter"] | undefined,
	monthlyPrice?: GraphQLTypes["FloatNullableFilter"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["BoolFilter"] | undefined,
	offlineBookingContactId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	href?: GraphQLTypes["StringNullableFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	playerIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	publisherScreenId?: GraphQLTypes["StringNullableFilter"] | undefined,
	additionalData?: GraphQLTypes["JsonNullableFilter"] | undefined,
	isDraft?: GraphQLTypes["BoolFilter"] | undefined,
	lastPublishedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	isArchived?: GraphQLTypes["BoolFilter"] | undefined,
	archivedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	archivedByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryNullableRelationFilter"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryNullableRelationFilter"] | undefined,
	type?: GraphQLTypes["AdBoardTypeNullableRelationFilter"] | undefined,
	category?: GraphQLTypes["BusinessCategoryNullableRelationFilter"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	orders?: GraphQLTypes["OrderListRelationFilter"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationListRelationFilter"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	createdBy?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined,
	players?: GraphQLTypes["PlayerListRelationFilter"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupNullableRelationFilter"] | undefined,
	cameras?: GraphQLTypes["CameraListRelationFilter"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardNullableRelationFilter"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardNullableRelationFilter"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryListRelationFilter"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentListRelationFilter"] | undefined,
	archivedBy?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewListRelationFilter"] | undefined
};
	["AdBoardWorkspaceIdAliasCompoundUniqueInput"]: {
		workspaceId: string,
	alias: string
};
	["AdBoard"]: {
	__typename: "AdBoard",
	id: string,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["Map"],
	outdoor: boolean,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications: Array<GraphQLTypes["Specification"]>,
	adBoardTypeId?: string | undefined,
	tags: Array<string>,
	featuredImage?: string | undefined,
	galleryImgs: Array<string>,
	/** @dperecated Use `targetAudiences` instead. */
	specifications: Array<GraphQLTypes["Specification"]>,
	notes: Array<string>,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["Resolution"] | undefined,
	screenSize?: GraphQLTypes["ScreenSize"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds: Array<string>,
	targetAudiences: Array<string>,
	playbackTimes: Array<GraphQLTypes["PlaybackTime"]>,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive: boolean,
	webhooks: Array<GraphQLTypes["Webhook"]>,
	numberOfDisplays: number,
	dailyOperationHours: number,
	adsPerLoop: number,
	adsDuration: number,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled: boolean,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds: Array<string>,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft: boolean,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived: boolean,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	_count?: GraphQLTypes["AdBoardCount"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategory"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategory"] | undefined,
	type?: GraphQLTypes["AdBoardType"] | undefined,
	category?: GraphQLTypes["BusinessCategory"] | undefined,
	restrictedBusines: Array<GraphQLTypes["BusinessCategory"]>,
	bookings: Array<GraphQLTypes["Booking"]>,
	orders: Array<GraphQLTypes["Order"]>,
	slotConfigurations: Array<GraphQLTypes["SlotConfiguration"]>,
	offlineBookingsContact?: GraphQLTypes["User"] | undefined,
	createdBy?: GraphQLTypes["User"] | undefined,
	workspace: GraphQLTypes["Workspace"],
	players: Array<GraphQLTypes["Player"]>,
	adBoardGroup?: GraphQLTypes["AdBoardGroup"] | undefined,
	cameras: Array<GraphQLTypes["Camera"]>,
	publishedVersion?: GraphQLTypes["AdBoard"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoard"] | undefined,
	history: Array<GraphQLTypes["AdBoardPublishHistory"]>,
	reviewComments: Array<GraphQLTypes["AdBoardReviewComment"]>,
	archivedBy?: GraphQLTypes["User"] | undefined,
	publicReviews: Array<GraphQLTypes["AdBoardPublicReview"]>
};
	["Map"]: {
	__typename: "Map",
	lat: number,
	lng: number,
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["Specification"]: {
	__typename: "Specification",
	name: string,
	value?: string | undefined
};
	["Resolution"]: {
	__typename: "Resolution",
	height: number,
	width: number
};
	["ScreenSize"]: {
	__typename: "ScreenSize",
	height: number,
	unit: GraphQLTypes["MeasurementUnit"],
	width: number
};
	["PlaybackTime"]: {
	__typename: "PlaybackTime",
	name?: string | undefined,
	startTime: string,
	endTime: string
};
	["Webhook"]: {
	__typename: "Webhook",
	url: string,
	headers?: GraphQLTypes["JSON"] | undefined,
	body?: GraphQLTypes["JSON"] | undefined,
	method: string
};
	["AdBoardCount"]: {
	__typename: "AdBoardCount",
	restrictedBusines: number,
	bookings: number,
	orders: number,
	slotConfigurations: number,
	players: number,
	cameras: number,
	history: number,
	reviewComments: number,
	publicReviews: number
};
	["LocationCategory"]: {
	__typename: "LocationCategory",
	id: string,
	name: string,
	_count?: GraphQLTypes["LocationCategoryCount"] | undefined,
	adBoards: Array<GraphQLTypes["AdBoard"]>,
	locationSubCategory: Array<GraphQLTypes["LocationSubCategory"]>
};
	["LocationCategoryCount"]: {
	__typename: "LocationCategoryCount",
	adBoards: number,
	locationSubCategory: number
};
	["AdBoardScalarFieldEnum"]: AdBoardScalarFieldEnum;
	["LocationSubCategory"]: {
	__typename: "LocationSubCategory",
	id: string,
	name: string,
	locationCategoryId?: string | undefined,
	_count?: GraphQLTypes["LocationSubCategoryCount"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategory"] | undefined,
	adBoards: Array<GraphQLTypes["AdBoard"]>
};
	["LocationSubCategoryCount"]: {
	__typename: "LocationSubCategoryCount",
	adBoards: number
};
	["LocationSubCategoryWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["LocationSubCategoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["LocationSubCategoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["LocationSubCategoryWhereInput"]> | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	locationCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryNullableRelationFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined
};
	["LocationSubCategoryScalarFieldEnum"]: LocationSubCategoryScalarFieldEnum;
	["AdBoardType"]: {
	__typename: "AdBoardType",
	id: string,
	name: string,
	description?: string | undefined,
	_count?: GraphQLTypes["AdBoardTypeCount"] | undefined,
	adBoards: Array<GraphQLTypes["AdBoard"]>
};
	["AdBoardTypeCount"]: {
	__typename: "AdBoardTypeCount",
	adBoards: number
};
	["BusinessCategory"]: {
	__typename: "BusinessCategory",
	id: string,
	name: string,
	description?: string | undefined,
	restrictedByAdBoardIds: Array<string>,
	_count?: GraphQLTypes["BusinessCategoryCount"] | undefined,
	adBoards: Array<GraphQLTypes["AdBoard"]>,
	restrictedByAdBoards: Array<GraphQLTypes["AdBoard"]>,
	campaigns: Array<GraphQLTypes["Campaign"]>,
	bookings: Array<GraphQLTypes["Booking"]>
};
	["BusinessCategoryCount"]: {
	__typename: "BusinessCategoryCount",
	adBoards: number,
	restrictedByAdBoards: number,
	campaigns: number,
	bookings: number
};
	["Campaign"]: {
	__typename: "Campaign",
	id: string,
	name: string,
	userId: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	bookingFor: string,
	purpose: string,
	businessCategoryId: string,
	adCategoryId: Array<string>,
	payment?: GraphQLTypes["BookingPayment"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummary"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDate"],
	origin: string,
	assetsIDs: Array<string>,
	_count?: GraphQLTypes["CampaignCount"] | undefined,
	user: GraphQLTypes["User"],
	businessCategory: GraphQLTypes["BusinessCategory"],
	adCategory: Array<GraphQLTypes["AdvertisementCategory"]>,
	bookings: Array<GraphQLTypes["Booking"]>,
	payments: Array<GraphQLTypes["Payment"]>,
	assets: Array<GraphQLTypes["File"]>
};
	["BookingPayment"]: {
	__typename: "BookingPayment",
	liveMode: boolean,
	checkoutSessionId?: string | undefined,
	customer?: GraphQLTypes["BookingCustomer"] | undefined,
	paymentGateway: GraphQLTypes["PaymentGatewayName"],
	paymentId: string,
	sessionId?: string | undefined,
	totalDetails?: GraphQLTypes["PaymentTotalDetails"] | undefined,
	razorpay?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["JSON"] | undefined
};
	["BookingCustomer"]: {
	__typename: "BookingCustomer",
	customerId: string,
	customerDetails?: GraphQLTypes["PaymentCustomerDetails"] | undefined
};
	["PaymentCustomerDetails"]: {
	__typename: "PaymentCustomerDetails",
	email: string,
	name: string,
	phone?: string | undefined
};
	["PaymentTotalDetails"]: {
	__typename: "PaymentTotalDetails",
	discount: number,
	shipping: number,
	subtotal: number,
	tax: number,
	total: number
};
	["PriceSummary"]: {
	__typename: "PriceSummary",
	days: number,
	discount?: number | undefined,
	payableRent: number,
	rentPerDay: number,
	serviceCharge: number,
	totalRent: number
};
	["OrderSelectedDate"]: {
	__typename: "OrderSelectedDate",
	endDate: GraphQLTypes["DateTime"],
	startDate: GraphQLTypes["DateTime"]
};
	["CampaignCount"]: {
	__typename: "CampaignCount",
	adCategory: number,
	bookings: number,
	payments: number,
	assets: number
};
	["User"]: {
	__typename: "User",
	id: string,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripe"] | undefined,
	roles: Array<GraphQLTypes["Role"]>,
	refunds: Array<GraphQLTypes["BookingRefund"]>,
	sharedBookingIds: Array<string>,
	disclaimerAccepted: boolean,
	notificationPreference?: GraphQLTypes["NotificationPreference"] | undefined,
	leadWorkspaceIds: Array<string>,
	_count?: GraphQLTypes["UserCount"] | undefined,
	bookings: Array<GraphQLTypes["Booking"]>,
	createdFiles: Array<GraphQLTypes["File"]>,
	updatedFiles: Array<GraphQLTypes["File"]>,
	orders: Array<GraphQLTypes["Order"]>,
	changelogs: Array<GraphQLTypes["Changelog"]>,
	adBoards: Array<GraphQLTypes["AdBoard"]>,
	audits: Array<GraphQLTypes["Audit"]>,
	campaigns: Array<GraphQLTypes["Campaign"]>,
	sharedBookings: Array<GraphQLTypes["Booking"]>,
	memberships: Array<GraphQLTypes["Membership"]>,
	notifications: Array<GraphQLTypes["Notification"]>,
	publishAdboardVersion: Array<GraphQLTypes["AdBoardPublishHistory"]>,
	offlineBookingAdboards: Array<GraphQLTypes["AdBoard"]>,
	reviewComments: Array<GraphQLTypes["AdBoardReviewComment"]>,
	archivedAdboards: Array<GraphQLTypes["AdBoard"]>,
	ownedWorkspaces: Array<GraphQLTypes["Workspace"]>,
	leadWorspaces: Array<GraphQLTypes["Workspace"]>,
	publicReviews: Array<GraphQLTypes["AdBoardPublicReview"]>,
	userPositions: Array<GraphQLTypes["UserPosition"]>
};
	["UserStripe"]: {
	__typename: "UserStripe",
	customerId: string
};
	["BookingRefund"]: {
	__typename: "BookingRefund",
	id: string,
	razorpay?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["JSON"] | undefined,
	createdByUserId: string
};
	["NotificationPreference"]: {
	__typename: "NotificationPreference",
	type: Array<GraphQLTypes["NotificationType"]>,
	channels: Array<GraphQLTypes["NotificationChannel"]>,
	pushSetting?: GraphQLTypes["PushSetting"] | undefined,
	enabled: boolean
};
	["PushSetting"]: {
	__typename: "PushSetting",
	fcmToken: string
};
	["UserCount"]: {
	__typename: "UserCount",
	bookings: number,
	createdFiles: number,
	updatedFiles: number,
	orders: number,
	changelogs: number,
	adBoards: number,
	audits: number,
	campaigns: number,
	sharedBookings: number,
	memberships: number,
	notifications: number,
	publishAdboardVersion: number,
	offlineBookingAdboards: number,
	reviewComments: number,
	archivedAdboards: number,
	ownedWorkspaces: number,
	leadWorspaces: number,
	publicReviews: number,
	userPositions: number
};
	["Booking"]: {
	__typename: "Booking",
	id: string,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs: Array<string>,
	bookingCode: string,
	bookingDate: GraphQLTypes["DateTime"],
	bookingFor: string,
	purpose: string,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId: Array<string>,
	bookingDetails: GraphQLTypes["BookingDetails"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDate"],
	payment?: GraphQLTypes["BookingPayment"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummary"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageData"] | undefined,
	userId: string,
	refund?: GraphQLTypes["BookingRefund"] | undefined,
	sharedUserIds: Array<string>,
	isOffline: boolean,
	isPaid: boolean,
	isScheduled: boolean,
	isDeployed: boolean,
	isCompleted: boolean,
	_count?: GraphQLTypes["BookingCount"] | undefined,
	assets: Array<GraphQLTypes["File"]>,
	businessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	adCategory: Array<GraphQLTypes["AdvertisementCategory"]>,
	proofs: Array<GraphQLTypes["File"]>,
	user: GraphQLTypes["User"],
	adBoard: GraphQLTypes["AdBoard"],
	changelogs: Array<GraphQLTypes["Changelog"]>,
	workspace?: GraphQLTypes["Workspace"] | undefined,
	campaign?: GraphQLTypes["Campaign"] | undefined,
	payouts: Array<GraphQLTypes["Payout"]>,
	sharedUsers: Array<GraphQLTypes["User"]>,
	webhookLogs: Array<GraphQLTypes["WebhookLog"]>,
	slotUsage: Array<GraphQLTypes["SlotUsage"]>
};
	["BookingDetails"]: {
	__typename: "BookingDetails",
	additionalInformation?: string | undefined,
	email: string,
	name: string,
	phone?: string | undefined
};
	["BookingPisignageData"]: {
	__typename: "BookingPisignageData",
	deployedFileName?: string | undefined,
	playlistId?: string | undefined
};
	["BookingCount"]: {
	__typename: "BookingCount",
	assets: number,
	adCategory: number,
	proofs: number,
	changelogs: number,
	payouts: number,
	sharedUsers: number,
	webhookLogs: number,
	slotUsage: number
};
	["File"]: {
	__typename: "File",
	id: string,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMeta"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt: GraphQLTypes["DateTime"],
	createdAt: GraphQLTypes["DateTime"],
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs: Array<string>,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds: Array<string>,
	_count?: GraphQLTypes["FileCount"] | undefined,
	updatedBy?: GraphQLTypes["User"] | undefined,
	createdBy: GraphQLTypes["User"],
	bookings: Array<GraphQLTypes["Booking"]>,
	workspace?: GraphQLTypes["Workspace"] | undefined,
	proofBooking?: GraphQLTypes["Booking"] | undefined,
	campaigns: Array<GraphQLTypes["Campaign"]>
};
	["FileMeta"]: {
	__typename: "FileMeta",
	duration: number,
	height: number,
	width: number
};
	["FileCount"]: {
	__typename: "FileCount",
	bookings: number,
	campaigns: number
};
	["BookingOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	additionalInformation?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	assetsIDs?: GraphQLTypes["SortOrder"] | undefined,
	bookingCode?: GraphQLTypes["SortOrder"] | undefined,
	bookingDate?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	campaignId?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	adCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsOrderByInput"] | undefined,
	bookingStatus?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	deploymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	contentApprovalStatus?: GraphQLTypes["SortOrder"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateOrderByInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentOrderByInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryOrderByInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataOrderByInput"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	refund?: GraphQLTypes["BookingRefundOrderByInput"] | undefined,
	sharedUserIds?: GraphQLTypes["SortOrder"] | undefined,
	isOffline?: GraphQLTypes["SortOrder"] | undefined,
	isPaid?: GraphQLTypes["SortOrder"] | undefined,
	isScheduled?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	isCompleted?: GraphQLTypes["SortOrder"] | undefined,
	assets?: GraphQLTypes["FileOrderByRelationAggregateInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryOrderByWithRelationInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryOrderByRelationAggregateInput"] | undefined,
	proofs?: GraphQLTypes["FileOrderByRelationAggregateInput"] | undefined,
	user?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogOrderByRelationAggregateInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	campaign?: GraphQLTypes["CampaignOrderByWithRelationInput"] | undefined,
	payouts?: GraphQLTypes["PayoutOrderByRelationAggregateInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserOrderByRelationAggregateInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogOrderByRelationAggregateInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageOrderByRelationAggregateInput"] | undefined
};
	["BookingDetailsOrderByInput"]: {
		additionalInformation?: GraphQLTypes["SortOrder"] | undefined,
	email?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	phone?: GraphQLTypes["SortOrder"] | undefined
};
	["OrderSelectedDateOrderByInput"]: {
		endDate?: GraphQLTypes["SortOrder"] | undefined,
	startDate?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingPaymentOrderByInput"]: {
		liveMode?: GraphQLTypes["SortOrder"] | undefined,
	checkoutSessionId?: GraphQLTypes["SortOrder"] | undefined,
	customer?: GraphQLTypes["BookingCustomerOrderByInput"] | undefined,
	paymentGateway?: GraphQLTypes["SortOrder"] | undefined,
	paymentId?: GraphQLTypes["SortOrder"] | undefined,
	sessionId?: GraphQLTypes["SortOrder"] | undefined,
	totalDetails?: GraphQLTypes["PaymentTotalDetailsOrderByInput"] | undefined,
	razorpay?: GraphQLTypes["SortOrder"] | undefined,
	stripe?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingCustomerOrderByInput"]: {
		customerId?: GraphQLTypes["SortOrder"] | undefined,
	customerDetails?: GraphQLTypes["PaymentCustomerDetailsOrderByInput"] | undefined
};
	["PaymentCustomerDetailsOrderByInput"]: {
		email?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	phone?: GraphQLTypes["SortOrder"] | undefined
};
	["PaymentTotalDetailsOrderByInput"]: {
		discount?: GraphQLTypes["SortOrder"] | undefined,
	shipping?: GraphQLTypes["SortOrder"] | undefined,
	subtotal?: GraphQLTypes["SortOrder"] | undefined,
	tax?: GraphQLTypes["SortOrder"] | undefined,
	total?: GraphQLTypes["SortOrder"] | undefined
};
	["PriceSummaryOrderByInput"]: {
		days?: GraphQLTypes["SortOrder"] | undefined,
	discount?: GraphQLTypes["SortOrder"] | undefined,
	payableRent?: GraphQLTypes["SortOrder"] | undefined,
	rentPerDay?: GraphQLTypes["SortOrder"] | undefined,
	serviceCharge?: GraphQLTypes["SortOrder"] | undefined,
	totalRent?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingPisignageDataOrderByInput"]: {
		deployedFileName?: GraphQLTypes["SortOrder"] | undefined,
	playlistId?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingRefundOrderByInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	razorpay?: GraphQLTypes["SortOrder"] | undefined,
	stripe?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined
};
	["AdvertisementCategoryOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["CampaignOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	adCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	payment?: GraphQLTypes["BookingPaymentOrderByInput"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryOrderByInput"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateOrderByInput"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	assetsIDs?: GraphQLTypes["SortOrder"] | undefined,
	user?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryOrderByWithRelationInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryOrderByRelationAggregateInput"] | undefined,
	bookings?: GraphQLTypes["BookingOrderByRelationAggregateInput"] | undefined,
	payments?: GraphQLTypes["PaymentOrderByRelationAggregateInput"] | undefined,
	assets?: GraphQLTypes["FileOrderByRelationAggregateInput"] | undefined
};
	["PaymentOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["PayoutOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["SlotUsageOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingWhereUniqueInput"]: {
		id?: string | undefined,
	bookingCode?: string | undefined,
	AND?: Array<GraphQLTypes["BookingWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingWhereInput"]> | undefined,
	additionalInformation?: GraphQLTypes["StringNullableFilter"] | undefined,
	origin?: GraphQLTypes["StringNullableFilter"] | undefined,
	assetsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingDate?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingFor?: GraphQLTypes["StringFilter"] | undefined,
	purpose?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	campaignId?: GraphQLTypes["StringNullableFilter"] | undefined,
	businessCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	adCategoryId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCompositeFilter"] | undefined,
	bookingStatus?: GraphQLTypes["EnumBookingStatusFilter"] | undefined,
	paymentStatus?: GraphQLTypes["EnumPaymentStatusNullableFilter"] | undefined,
	deploymentStatus?: GraphQLTypes["EnumDeploymentStatusFilter"] | undefined,
	contentApprovalStatus?: GraphQLTypes["EnumContentApprovalStatusFilter"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCompositeFilter"] | undefined,
	payment?: GraphQLTypes["BookingPaymentNullableCompositeFilter"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryNullableCompositeFilter"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataNullableCompositeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	refund?: GraphQLTypes["BookingRefundNullableCompositeFilter"] | undefined,
	sharedUserIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	isOffline?: GraphQLTypes["BoolFilter"] | undefined,
	isPaid?: GraphQLTypes["BoolFilter"] | undefined,
	isScheduled?: GraphQLTypes["BoolFilter"] | undefined,
	isDeployed?: GraphQLTypes["BoolFilter"] | undefined,
	isCompleted?: GraphQLTypes["BoolFilter"] | undefined,
	assets?: GraphQLTypes["FileListRelationFilter"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryNullableRelationFilter"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryListRelationFilter"] | undefined,
	proofs?: GraphQLTypes["FileListRelationFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	changelogs?: GraphQLTypes["ChangelogListRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	campaign?: GraphQLTypes["CampaignNullableRelationFilter"] | undefined,
	payouts?: GraphQLTypes["PayoutListRelationFilter"] | undefined,
	sharedUsers?: GraphQLTypes["UserListRelationFilter"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogListRelationFilter"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageListRelationFilter"] | undefined
};
	["BookingScalarFieldEnum"]: BookingScalarFieldEnum;
	["Workspace"]: {
	__typename: "Workspace",
	id: string,
	type: GraphQLTypes["WorkspaceType"],
	name: string,
	key: string,
	userIds: Array<string>,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	stripeSubscriptionId?: string | undefined,
	leadIds: Array<string>,
	_count?: GraphQLTypes["WorkspaceCount"] | undefined,
	owner?: GraphQLTypes["User"] | undefined,
	memberships: Array<GraphQLTypes["Membership"]>,
	adBoards: Array<GraphQLTypes["AdBoard"]>,
	playlists: Array<GraphQLTypes["Playlist"]>,
	adBoardGroups: Array<GraphQLTypes["AdBoardGroup"]>,
	audits: Array<GraphQLTypes["Audit"]>,
	bookings: Array<GraphQLTypes["Booking"]>,
	files: Array<GraphQLTypes["File"]>,
	players: Array<GraphQLTypes["Player"]>,
	transactions: Array<GraphQLTypes["Transaction"]>,
	activeSubscription?: GraphQLTypes["Subscription"] | undefined,
	cameraServers: Array<GraphQLTypes["CameraServer"]>,
	workspaceRoles: Array<GraphQLTypes["WorkspaceRole"]>,
	webhookLogs: Array<GraphQLTypes["WebhookLog"]>,
	leads: Array<GraphQLTypes["User"]>
};
	["WorkspaceCount"]: {
	__typename: "WorkspaceCount",
	memberships: number,
	adBoards: number,
	playlists: number,
	adBoardGroups: number,
	audits: number,
	bookings: number,
	files: number,
	players: number,
	transactions: number,
	cameraServers: number,
	workspaceRoles: number,
	webhookLogs: number,
	leads: number
};
	["Membership"]: {
	__typename: "Membership",
	id: string,
	createdAt: GraphQLTypes["DateTime"],
	userId: string,
	workspaceId: string,
	roleId: string,
	user: GraphQLTypes["User"],
	workspace: GraphQLTypes["Workspace"],
	role: GraphQLTypes["WorkspaceRole"]
};
	["WorkspaceRole"]: {
	__typename: "WorkspaceRole",
	id: string,
	name: string,
	permissions: Array<GraphQLTypes["Permission"]>,
	workspaceId?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	_count?: GraphQLTypes["WorkspaceRoleCount"] | undefined,
	workspace?: GraphQLTypes["Workspace"] | undefined,
	memberships: Array<GraphQLTypes["Membership"]>
};
	["WorkspaceRoleCount"]: {
	__typename: "WorkspaceRoleCount",
	memberships: number
};
	["MembershipOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	roleId?: GraphQLTypes["SortOrder"] | undefined,
	user?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	role?: GraphQLTypes["WorkspaceRoleOrderByWithRelationInput"] | undefined
};
	["WorkspaceRoleOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	permissions?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	memberships?: GraphQLTypes["MembershipOrderByRelationAggregateInput"] | undefined
};
	["MembershipWhereUniqueInput"]: {
		id?: string | undefined,
	userId_workspaceId?: GraphQLTypes["MembershipUserIdWorkspaceIdCompoundUniqueInput"] | undefined,
	AND?: Array<GraphQLTypes["MembershipWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["MembershipWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["MembershipWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	roleId?: GraphQLTypes["StringFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined,
	role?: GraphQLTypes["WorkspaceRoleRelationFilter"] | undefined
};
	["MembershipUserIdWorkspaceIdCompoundUniqueInput"]: {
		userId: string,
	workspaceId: string
};
	["MembershipScalarFieldEnum"]: MembershipScalarFieldEnum;
	["Playlist"]: {
	__typename: "Playlist",
	id: string,
	name: string,
	displayName?: string | undefined,
	assets: Array<GraphQLTypes["PisignageFile"]>,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout: GraphQLTypes["ScreenLayout"],
	isDeployed?: boolean | undefined,
	adBoardGroupIds: Array<string>,
	workspaceId: string,
	_count?: GraphQLTypes["PlaylistCount"] | undefined,
	footerPlaylist?: GraphQLTypes["Playlist"] | undefined,
	sidebarPlaylist?: GraphQLTypes["Playlist"] | undefined,
	adBoardGroupsForAds: Array<GraphQLTypes["AdBoardGroup"]>,
	adBoardGroups: Array<GraphQLTypes["AdBoardGroup"]>,
	workspace: GraphQLTypes["Workspace"],
	footerParentPlaylists: Array<GraphQLTypes["Playlist"]>,
	sidebarParentPlaylists: Array<GraphQLTypes["Playlist"]>
};
	["PisignageFile"]: {
	__typename: "PisignageFile",
	name: string,
	duration?: number | undefined,
	muteAudio?: boolean | undefined,
	isVideo?: boolean | undefined
};
	["PlaylistCount"]: {
	__typename: "PlaylistCount",
	adBoardGroupsForAds: number,
	adBoardGroups: number,
	footerParentPlaylists: number,
	sidebarParentPlaylists: number
};
	["AdBoardGroup"]: {
	__typename: "AdBoardGroup",
	id: string,
	name: string,
	adBoardId?: string | undefined,
	schedules: Array<GraphQLTypes["Schedule"]>,
	scheduledPlaylistsIDs: Array<string>,
	adsPlaylistID?: string | undefined,
	pisignageGroupId: string,
	fitImage: GraphQLTypes["FitType"],
	fitVideo: GraphQLTypes["FitType"],
	workspaceId: string,
	_count?: GraphQLTypes["AdBoardGroupCount"] | undefined,
	adBoard?: GraphQLTypes["AdBoard"] | undefined,
	scheduledPlaylists: Array<GraphQLTypes["Playlist"]>,
	adsPlaylist?: GraphQLTypes["Playlist"] | undefined,
	pisignagePlayers: Array<GraphQLTypes["Player"]>,
	workspace: GraphQLTypes["Workspace"]
};
	["Schedule"]: {
	__typename: "Schedule",
	playlistId: string,
	playlistType?: string | undefined,
	skipForSchedule?: boolean | undefined,
	startTime?: string | undefined,
	endTime?: string | undefined
};
	["AdBoardGroupCount"]: {
	__typename: "AdBoardGroupCount",
	scheduledPlaylists: number,
	pisignagePlayers: number
};
	["PlaylistWhereUniqueInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	AND?: Array<GraphQLTypes["PlaylistWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlaylistWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlaylistWhereInput"]> | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	assets?: Array<GraphQLTypes["PisignageFileObjectEqualityInput"]> | undefined,
	isAdsPlaylist?: GraphQLTypes["BoolNullableFilter"] | undefined,
	footerPlaylistId?: GraphQLTypes["StringNullableFilter"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["StringNullableFilter"] | undefined,
	layout?: GraphQLTypes["EnumScreenLayoutFilter"] | undefined,
	isDeployed?: GraphQLTypes["BoolNullableFilter"] | undefined,
	adBoardGroupIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistNullableRelationFilter"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistNullableRelationFilter"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupListRelationFilter"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupListRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistListRelationFilter"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistListRelationFilter"] | undefined
};
	["PlaylistScalarFieldEnum"]: PlaylistScalarFieldEnum;
	["Player"]: {
	__typename: "Player",
	id: string,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	playlistIds: Array<string>,
	workpaceId?: string | undefined,
	resolution?: GraphQLTypes["Resolution"] | undefined,
	screenSize?: GraphQLTypes["ScreenSize"] | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId: Array<string>,
	playbackDetails: Array<GraphQLTypes["PlaybackTime"]>,
	aspectRatioType: GraphQLTypes["AspectRatioType"],
	galleryImgs: Array<GraphQLTypes["GalleryFile"]>,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["Map"] | undefined,
	isOnline: boolean,
	adBoardIds: Array<string>,
	_count?: GraphQLTypes["PlayerCount"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroup"] | undefined,
	workspace?: GraphQLTypes["Workspace"] | undefined,
	status: Array<GraphQLTypes["PlayerUptimeStatus"]>,
	cameras: Array<GraphQLTypes["Camera"]>,
	signageType?: GraphQLTypes["DigitalSignage"] | undefined,
	audienceType: Array<GraphQLTypes["Audience"]>,
	adBoards: Array<GraphQLTypes["AdBoard"]>
};
	["GalleryFile"]: {
	__typename: "GalleryFile",
	name: string,
	url: string,
	contentType: string,
	width: number,
	height: number,
	duration?: number | undefined
};
	["PlayerCount"]: {
	__typename: "PlayerCount",
	status: number,
	cameras: number,
	audienceType: number,
	adBoards: number
};
	["PlayerUptimeStatus"]: {
	__typename: "PlayerUptimeStatus",
	id: string,
	playerId: string,
	cpuSerialNumber: string,
	date: string,
	uptime: number,
	player: GraphQLTypes["Player"]
};
	["PlayerUptimeStatusOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	playerId?: GraphQLTypes["SortOrder"] | undefined,
	cpuSerialNumber?: GraphQLTypes["SortOrder"] | undefined,
	date?: GraphQLTypes["SortOrder"] | undefined,
	uptime?: GraphQLTypes["SortOrder"] | undefined,
	player?: GraphQLTypes["PlayerOrderByWithRelationInput"] | undefined
};
	["PlayerOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	configLocation?: GraphQLTypes["SortOrder"] | undefined,
	pisignagePlayerId?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	cpuSerialNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroupId?: GraphQLTypes["SortOrder"] | undefined,
	playlistIds?: GraphQLTypes["SortOrder"] | undefined,
	workpaceId?: GraphQLTypes["SortOrder"] | undefined,
	resolution?: GraphQLTypes["ResolutionOrderByInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeOrderByInput"] | undefined,
	signageTypeId?: GraphQLTypes["SortOrder"] | undefined,
	audienceTypeId?: GraphQLTypes["SortOrder"] | undefined,
	playbackDetails?: GraphQLTypes["PlaybackTimeOrderByCompositeAggregateInput"] | undefined,
	aspectRatioType?: GraphQLTypes["SortOrder"] | undefined,
	galleryImgs?: GraphQLTypes["GalleryFileOrderByCompositeAggregateInput"] | undefined,
	customAspectRatioWidth?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["SortOrder"] | undefined,
	map?: GraphQLTypes["MapOrderByInput"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	adBoardIds?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupOrderByWithRelationInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusOrderByRelationAggregateInput"] | undefined,
	cameras?: GraphQLTypes["CameraOrderByRelationAggregateInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageOrderByWithRelationInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceOrderByRelationAggregateInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardOrderByRelationAggregateInput"] | undefined
};
	["GalleryFileOrderByCompositeAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["PlayerUptimeStatusOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["DigitalSignageOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	players?: GraphQLTypes["PlayerOrderByRelationAggregateInput"] | undefined
};
	["AudienceOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["PlayerUptimeStatusWhereUniqueInput"]: {
		id?: string | undefined,
	cpuSerialNumber_date?: GraphQLTypes["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"] | undefined,
	AND?: Array<GraphQLTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlayerUptimeStatusWhereInput"]> | undefined,
	playerId?: GraphQLTypes["StringFilter"] | undefined,
	cpuSerialNumber?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["StringFilter"] | undefined,
	uptime?: GraphQLTypes["FloatFilter"] | undefined,
	player?: GraphQLTypes["PlayerRelationFilter"] | undefined
};
	["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"]: {
		cpuSerialNumber: string,
	date: string
};
	["PlayerUptimeStatusScalarFieldEnum"]: PlayerUptimeStatusScalarFieldEnum;
	["Camera"]: {
	__typename: "Camera",
	id: string,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers: Array<string>,
	playerId?: string | undefined,
	updatedAt: GraphQLTypes["DateTime"],
	isPublic: boolean,
	adBoardId?: string | undefined,
	_count?: GraphQLTypes["CameraCount"] | undefined,
	cameraServer: GraphQLTypes["CameraServer"],
	player?: GraphQLTypes["Player"] | undefined,
	feeds: Array<GraphQLTypes["CameraFeed"]>,
	adBoard?: GraphQLTypes["AdBoard"] | undefined
};
	["CameraCount"]: {
	__typename: "CameraCount",
	feeds: number
};
	["CameraServer"]: {
	__typename: "CameraServer",
	id: string,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	workspaceId?: string | undefined,
	publicIp: string,
	privateIp: string,
	_count?: GraphQLTypes["CameraServerCount"] | undefined,
	workspace?: GraphQLTypes["Workspace"] | undefined,
	cameras: Array<GraphQLTypes["Camera"]>,
	feeds: Array<GraphQLTypes["CameraFeed"]>
};
	["CameraServerCount"]: {
	__typename: "CameraServerCount",
	cameras: number,
	feeds: number
};
	["CameraOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined,
	cameraUrl?: GraphQLTypes["SortOrder"] | undefined,
	rtspUrl?: GraphQLTypes["SortOrder"] | undefined,
	cameraIp?: GraphQLTypes["SortOrder"] | undefined,
	connectedCpuSerialNumbers?: GraphQLTypes["SortOrder"] | undefined,
	playerId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	isPublic?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	cameraServer?: GraphQLTypes["CameraServerOrderByWithRelationInput"] | undefined,
	player?: GraphQLTypes["PlayerOrderByWithRelationInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedOrderByRelationAggregateInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined
};
	["CameraServerOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	hostName?: GraphQLTypes["SortOrder"] | undefined,
	userName?: GraphQLTypes["SortOrder"] | undefined,
	deviceId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	publicIp?: GraphQLTypes["SortOrder"] | undefined,
	privateIp?: GraphQLTypes["SortOrder"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	cameras?: GraphQLTypes["CameraOrderByRelationAggregateInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedOrderByRelationAggregateInput"] | undefined
};
	["CameraFeedOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraWhereUniqueInput"]: {
		id?: string | undefined,
	cameraUrl?: string | undefined,
	cameraIp_cameraServerId?: GraphQLTypes["CameraCameraIpCameraServerIdCompoundUniqueInput"] | undefined,
	AND?: Array<GraphQLTypes["CameraWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraWhereInput"]> | undefined,
	name?: GraphQLTypes["StringNullableFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolFilter"] | undefined,
	cameraServerId?: GraphQLTypes["StringFilter"] | undefined,
	rtspUrl?: GraphQLTypes["StringFilter"] | undefined,
	cameraIp?: GraphQLTypes["StringFilter"] | undefined,
	connectedCpuSerialNumbers?: GraphQLTypes["StringNullableListFilter"] | undefined,
	playerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	isPublic?: GraphQLTypes["BoolFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringNullableFilter"] | undefined,
	cameraServer?: GraphQLTypes["CameraServerRelationFilter"] | undefined,
	player?: GraphQLTypes["PlayerNullableRelationFilter"] | undefined,
	feeds?: GraphQLTypes["CameraFeedListRelationFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardNullableRelationFilter"] | undefined
};
	["CameraCameraIpCameraServerIdCompoundUniqueInput"]: {
		cameraIp: string,
	cameraServerId: string
};
	["CameraScalarFieldEnum"]: CameraScalarFieldEnum;
	["CameraFeed"]: {
	__typename: "CameraFeed",
	id: string,
	cameraId: string,
	timestamp: GraphQLTypes["DateTime"],
	cameraServerId: string,
	data: GraphQLTypes["CameraFeedData"],
	camera: GraphQLTypes["Camera"],
	cameraServer: GraphQLTypes["CameraServer"]
};
	["CameraFeedData"]: {
	__typename: "CameraFeedData",
	person?: number | undefined,
	car?: number | undefined,
	truck?: number | undefined,
	motorcycle?: number | undefined
};
	["CameraFeedOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	cameraId?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined,
	data?: GraphQLTypes["CameraFeedDataOrderByInput"] | undefined,
	camera?: GraphQLTypes["CameraOrderByWithRelationInput"] | undefined,
	cameraServer?: GraphQLTypes["CameraServerOrderByWithRelationInput"] | undefined
};
	["CameraFeedDataOrderByInput"]: {
		person?: GraphQLTypes["SortOrder"] | undefined,
	car?: GraphQLTypes["SortOrder"] | undefined,
	truck?: GraphQLTypes["SortOrder"] | undefined,
	motorcycle?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraFeedWhereUniqueInput"]: {
		id?: string | undefined,
	cameraId_timestamp?: GraphQLTypes["CameraFeedCameraIdTimestampCompoundUniqueInput"] | undefined,
	AND?: Array<GraphQLTypes["CameraFeedWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraFeedWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraFeedWhereInput"]> | undefined,
	cameraId?: GraphQLTypes["StringFilter"] | undefined,
	timestamp?: GraphQLTypes["DateTimeFilter"] | undefined,
	cameraServerId?: GraphQLTypes["StringFilter"] | undefined,
	data?: GraphQLTypes["CameraFeedDataCompositeFilter"] | undefined,
	camera?: GraphQLTypes["CameraRelationFilter"] | undefined,
	cameraServer?: GraphQLTypes["CameraServerRelationFilter"] | undefined
};
	["CameraFeedCameraIdTimestampCompoundUniqueInput"]: {
		cameraId: string,
	timestamp: GraphQLTypes["DateTime"]
};
	["CameraFeedScalarFieldEnum"]: CameraFeedScalarFieldEnum;
	["DigitalSignage"]: {
	__typename: "DigitalSignage",
	id: string,
	type: GraphQLTypes["SignageType"],
	name: string,
	description?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	_count?: GraphQLTypes["DigitalSignageCount"] | undefined,
	players: Array<GraphQLTypes["Player"]>
};
	["DigitalSignageCount"]: {
	__typename: "DigitalSignageCount",
	players: number
};
	["PlayerWhereUniqueInput"]: {
		id?: string | undefined,
	cpuSerialNumber?: string | undefined,
	AND?: Array<GraphQLTypes["PlayerWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlayerWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlayerWhereInput"]> | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	configLocation?: GraphQLTypes["StringNullableFilter"] | undefined,
	pisignagePlayerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: GraphQLTypes["StringNullableFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	adBoardGroupId?: GraphQLTypes["StringNullableFilter"] | undefined,
	playlistIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workpaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	resolution?: GraphQLTypes["ResolutionNullableCompositeFilter"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeNullableCompositeFilter"] | undefined,
	signageTypeId?: GraphQLTypes["StringNullableFilter"] | undefined,
	audienceTypeId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeObjectEqualityInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["EnumAspectRatioTypeFilter"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileObjectEqualityInput"]> | undefined,
	customAspectRatioWidth?: GraphQLTypes["IntNullableFilter"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["IntNullableFilter"] | undefined,
	map?: GraphQLTypes["MapNullableCompositeFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolFilter"] | undefined,
	adBoardIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupNullableRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusListRelationFilter"] | undefined,
	cameras?: GraphQLTypes["CameraListRelationFilter"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageNullableRelationFilter"] | undefined,
	audienceType?: GraphQLTypes["AudienceListRelationFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined
};
	["PlayerScalarFieldEnum"]: PlayerScalarFieldEnum;
	["Audience"]: {
	__typename: "Audience",
	id: string,
	type: GraphQLTypes["AudienceType"],
	name: string,
	description?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	playerIDs: Array<string>,
	_count?: GraphQLTypes["AudienceCount"] | undefined,
	players: Array<GraphQLTypes["Player"]>
};
	["AudienceCount"]: {
	__typename: "AudienceCount",
	players: number
};
	["AudienceOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	playerIDs?: GraphQLTypes["SortOrder"] | undefined,
	players?: GraphQLTypes["PlayerOrderByRelationAggregateInput"] | undefined
};
	["AudienceWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["AudienceWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AudienceWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AudienceWhereInput"]> | undefined,
	type?: GraphQLTypes["EnumAudienceTypeFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	playerIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	players?: GraphQLTypes["PlayerListRelationFilter"] | undefined
};
	["AudienceScalarFieldEnum"]: AudienceScalarFieldEnum;
	["AdBoardGroupWhereUniqueInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	adBoardId?: string | undefined,
	pisignageGroupId?: string | undefined,
	AND?: Array<GraphQLTypes["AdBoardGroupWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardGroupWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardGroupWhereInput"]> | undefined,
	schedules?: Array<GraphQLTypes["ScheduleObjectEqualityInput"]> | undefined,
	scheduledPlaylistsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	adsPlaylistID?: GraphQLTypes["StringNullableFilter"] | undefined,
	fitImage?: GraphQLTypes["EnumFitTypeFilter"] | undefined,
	fitVideo?: GraphQLTypes["EnumFitTypeFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardNullableRelationFilter"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistListRelationFilter"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistNullableRelationFilter"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerListRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined
};
	["AdBoardGroupScalarFieldEnum"]: AdBoardGroupScalarFieldEnum;
	["Audit"]: {
	__typename: "Audit",
	id: string,
	createdAt: GraphQLTypes["DateTime"],
	message: string,
	action: string,
	entity?: GraphQLTypes["Entity"] | undefined,
	userId: string,
	workspaceId?: string | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	user: GraphQLTypes["User"],
	workspace?: GraphQLTypes["Workspace"] | undefined
};
	["Entity"]: {
	__typename: "Entity",
	type: string,
	id: string,
	name?: string | undefined
};
	["AuditOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	message?: GraphQLTypes["SortOrder"] | undefined,
	action?: GraphQLTypes["SortOrder"] | undefined,
	entity?: GraphQLTypes["EntityOrderByInput"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	updates?: GraphQLTypes["SortOrder"] | undefined,
	user?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined
};
	["EntityOrderByInput"]: {
		type?: GraphQLTypes["SortOrder"] | undefined,
	id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined
};
	["AuditWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["AuditWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AuditWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AuditWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	message?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["StringFilter"] | undefined,
	entity?: GraphQLTypes["EntityNullableCompositeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updates?: GraphQLTypes["JsonNullableFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined
};
	["AuditScalarFieldEnum"]: AuditScalarFieldEnum;
	["FileOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	contentType?: GraphQLTypes["SortOrder"] | undefined,
	ctime?: GraphQLTypes["SortOrder"] | undefined,
	filename?: GraphQLTypes["SortOrder"] | undefined,
	filepath?: GraphQLTypes["SortOrder"] | undefined,
	isDirectory?: GraphQLTypes["SortOrder"] | undefined,
	meta?: GraphQLTypes["FileMetaOrderByInput"] | undefined,
	mtime?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	size?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	url?: GraphQLTypes["SortOrder"] | undefined,
	bookingsIDs?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	proofBookingId?: GraphQLTypes["SortOrder"] | undefined,
	campaignIds?: GraphQLTypes["SortOrder"] | undefined,
	updatedBy?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	createdBy?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined,
	bookings?: GraphQLTypes["BookingOrderByRelationAggregateInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingOrderByWithRelationInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignOrderByRelationAggregateInput"] | undefined
};
	["FileMetaOrderByInput"]: {
		duration?: GraphQLTypes["SortOrder"] | undefined,
	height?: GraphQLTypes["SortOrder"] | undefined,
	width?: GraphQLTypes["SortOrder"] | undefined
};
	["FileWhereUniqueInput"]: {
		id?: string | undefined,
	url?: string | undefined,
	AND?: Array<GraphQLTypes["FileWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["FileWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["FileWhereInput"]> | undefined,
	contentType?: GraphQLTypes["StringFilter"] | undefined,
	ctime?: GraphQLTypes["StringFilter"] | undefined,
	filename?: GraphQLTypes["StringFilter"] | undefined,
	filepath?: GraphQLTypes["StringNullableFilter"] | undefined,
	isDirectory?: GraphQLTypes["BoolFilter"] | undefined,
	meta?: GraphQLTypes["FileMetaCompositeFilter"] | undefined,
	mtime?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	size?: GraphQLTypes["IntFilter"] | undefined,
	status?: GraphQLTypes["EnumFileStatusNullableFilter"] | undefined,
	type?: GraphQLTypes["StringFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringFilter"] | undefined,
	bookingsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	proofBookingId?: GraphQLTypes["StringNullableFilter"] | undefined,
	campaignIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	updatedBy?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	createdBy?: GraphQLTypes["UserRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	proofBooking?: GraphQLTypes["BookingNullableRelationFilter"] | undefined,
	campaigns?: GraphQLTypes["CampaignListRelationFilter"] | undefined
};
	["FileScalarFieldEnum"]: FileScalarFieldEnum;
	["Transaction"]: {
	__typename: "Transaction",
	workspaceId?: string | undefined,
	id: string,
	amount: number,
	createAt: GraphQLTypes["DateTime"],
	processedAt?: GraphQLTypes["DateTime"] | undefined,
	relatedEntityId: string,
	transactionStatus: GraphQLTypes["TransactionStatus"],
	details?: GraphQLTypes["JSON"] | undefined,
	_count?: GraphQLTypes["TransactionCount"] | undefined,
	workspace?: GraphQLTypes["Workspace"] | undefined,
	payouts: Array<GraphQLTypes["Payout"]>
};
	["TransactionCount"]: {
	__typename: "TransactionCount",
	payouts: number
};
	["Payout"]: {
	__typename: "Payout",
	id: string,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt: GraphQLTypes["DateTime"],
	bookingId: string,
	status: GraphQLTypes["PayoutStatus"],
	transactionId?: string | undefined,
	booking: GraphQLTypes["Booking"],
	transaction?: GraphQLTypes["Transaction"] | undefined
};
	["PayoutOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	totalAmount?: GraphQLTypes["SortOrder"] | undefined,
	commissionRate?: GraphQLTypes["SortOrder"] | undefined,
	commissionAmount?: GraphQLTypes["SortOrder"] | undefined,
	netAmount?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	bookingId?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	transactionId?: GraphQLTypes["SortOrder"] | undefined,
	booking?: GraphQLTypes["BookingOrderByWithRelationInput"] | undefined,
	transaction?: GraphQLTypes["TransactionOrderByWithRelationInput"] | undefined
};
	["TransactionOrderByWithRelationInput"]: {
		workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	id?: GraphQLTypes["SortOrder"] | undefined,
	amount?: GraphQLTypes["SortOrder"] | undefined,
	createAt?: GraphQLTypes["SortOrder"] | undefined,
	processedAt?: GraphQLTypes["SortOrder"] | undefined,
	relatedEntityId?: GraphQLTypes["SortOrder"] | undefined,
	transactionStatus?: GraphQLTypes["SortOrder"] | undefined,
	details?: GraphQLTypes["SortOrder"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined,
	payouts?: GraphQLTypes["PayoutOrderByRelationAggregateInput"] | undefined
};
	["PayoutWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["PayoutWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PayoutWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PayoutWhereInput"]> | undefined,
	totalAmount?: GraphQLTypes["FloatFilter"] | undefined,
	commissionRate?: GraphQLTypes["FloatFilter"] | undefined,
	commissionAmount?: GraphQLTypes["FloatFilter"] | undefined,
	netAmount?: GraphQLTypes["FloatFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	status?: GraphQLTypes["EnumPayoutStatusFilter"] | undefined,
	transactionId?: GraphQLTypes["StringNullableFilter"] | undefined,
	booking?: GraphQLTypes["BookingRelationFilter"] | undefined,
	transaction?: GraphQLTypes["TransactionNullableRelationFilter"] | undefined
};
	["PayoutScalarFieldEnum"]: PayoutScalarFieldEnum;
	["TransactionWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["TransactionWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["TransactionWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["TransactionWhereInput"]> | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	amount?: GraphQLTypes["FloatFilter"] | undefined,
	createAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	processedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	relatedEntityId?: GraphQLTypes["StringFilter"] | undefined,
	transactionStatus?: GraphQLTypes["EnumTransactionStatusFilter"] | undefined,
	details?: GraphQLTypes["JsonNullableFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	payouts?: GraphQLTypes["PayoutListRelationFilter"] | undefined
};
	["TransactionScalarFieldEnum"]: TransactionScalarFieldEnum;
	["Subscription"]: {
	__typename: "Subscription",
	id: string,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status: string,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId: string,
	stripeCheckoutSessionId: string,
	cancellationDetails?: GraphQLTypes["CancellationDetails"] | undefined,
	activeWorkspaceId: string,
	activeWorkspace: GraphQLTypes["Workspace"]
};
	["CancellationDetails"]: {
	__typename: "CancellationDetails",
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	cancelAt?: GraphQLTypes["DateTime"] | undefined,
	cancelAtPeriodEnd: boolean,
	reason?: string | undefined
};
	["CameraServerWhereUniqueInput"]: {
		id?: string | undefined,
	deviceId?: string | undefined,
	AND?: Array<GraphQLTypes["CameraServerWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraServerWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraServerWhereInput"]> | undefined,
	name?: GraphQLTypes["StringNullableFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolNullableFilter"] | undefined,
	hostName?: GraphQLTypes["StringNullableFilter"] | undefined,
	userName?: GraphQLTypes["StringNullableFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	publicIp?: GraphQLTypes["StringFilter"] | undefined,
	privateIp?: GraphQLTypes["StringFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	cameras?: GraphQLTypes["CameraListRelationFilter"] | undefined,
	feeds?: GraphQLTypes["CameraFeedListRelationFilter"] | undefined
};
	["CameraServerScalarFieldEnum"]: CameraServerScalarFieldEnum;
	["WorkspaceRoleWhereUniqueInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	name_workspaceId?: GraphQLTypes["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"] | undefined,
	AND?: Array<GraphQLTypes["WorkspaceRoleWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WorkspaceRoleWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WorkspaceRoleWhereInput"]> | undefined,
	permissions?: GraphQLTypes["EnumPermissionNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined,
	memberships?: GraphQLTypes["MembershipListRelationFilter"] | undefined
};
	["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"]: {
		name: string,
	workspaceId: string
};
	["WorkspaceRoleScalarFieldEnum"]: WorkspaceRoleScalarFieldEnum;
	["WebhookLog"]: {
	__typename: "WebhookLog",
	id: string,
	bookingId: string,
	response?: GraphQLTypes["JSON"] | undefined,
	request?: GraphQLTypes["JSON"] | undefined,
	status: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	workspaceId?: string | undefined,
	booking: GraphQLTypes["Booking"],
	workspace?: GraphQLTypes["Workspace"] | undefined
};
	["WebhookLogOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	bookingId?: GraphQLTypes["SortOrder"] | undefined,
	response?: GraphQLTypes["SortOrder"] | undefined,
	request?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	booking?: GraphQLTypes["BookingOrderByWithRelationInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderByWithRelationInput"] | undefined
};
	["WebhookLogWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["WebhookLogWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WebhookLogWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WebhookLogWhereInput"]> | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	response?: GraphQLTypes["JsonNullableFilter"] | undefined,
	request?: GraphQLTypes["JsonNullableFilter"] | undefined,
	status?: GraphQLTypes["IntFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	booking?: GraphQLTypes["BookingRelationFilter"] | undefined,
	workspace?: GraphQLTypes["WorkspaceNullableRelationFilter"] | undefined
};
	["WebhookLogScalarFieldEnum"]: WebhookLogScalarFieldEnum;
	["UserWhereUniqueInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email?: string | undefined,
	AND?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	image?: GraphQLTypes["StringNullableFilter"] | undefined,
	phoneNumber?: GraphQLTypes["StringNullableFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	otherDetails?: GraphQLTypes["JsonNullableFilter"] | undefined,
	stripe?: GraphQLTypes["UserStripeNullableCompositeFilter"] | undefined,
	roles?: GraphQLTypes["EnumRoleNullableListFilter"] | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundObjectEqualityInput"]> | undefined,
	sharedBookingIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	disclaimerAccepted?: GraphQLTypes["BoolFilter"] | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceNullableCompositeFilter"] | undefined,
	leadWorkspaceIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	createdFiles?: GraphQLTypes["FileListRelationFilter"] | undefined,
	updatedFiles?: GraphQLTypes["FileListRelationFilter"] | undefined,
	orders?: GraphQLTypes["OrderListRelationFilter"] | undefined,
	changelogs?: GraphQLTypes["ChangelogListRelationFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	audits?: GraphQLTypes["AuditListRelationFilter"] | undefined,
	campaigns?: GraphQLTypes["CampaignListRelationFilter"] | undefined,
	sharedBookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	memberships?: GraphQLTypes["MembershipListRelationFilter"] | undefined,
	notifications?: GraphQLTypes["NotificationListRelationFilter"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryListRelationFilter"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentListRelationFilter"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceListRelationFilter"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceListRelationFilter"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewListRelationFilter"] | undefined,
	userPositions?: GraphQLTypes["UserPositionListRelationFilter"] | undefined
};
	["UserScalarFieldEnum"]: UserScalarFieldEnum;
	["CampaignWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["CampaignWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CampaignWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CampaignWhereInput"]> | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingFor?: GraphQLTypes["StringFilter"] | undefined,
	purpose?: GraphQLTypes["StringFilter"] | undefined,
	businessCategoryId?: GraphQLTypes["StringFilter"] | undefined,
	adCategoryId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	payment?: GraphQLTypes["BookingPaymentNullableCompositeFilter"] | undefined,
	paymentStatus?: GraphQLTypes["EnumPaymentStatusFilter"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryNullableCompositeFilter"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCompositeFilter"] | undefined,
	origin?: GraphQLTypes["StringFilter"] | undefined,
	assetsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryRelationFilter"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	payments?: GraphQLTypes["PaymentListRelationFilter"] | undefined,
	assets?: GraphQLTypes["FileListRelationFilter"] | undefined
};
	["CampaignScalarFieldEnum"]: CampaignScalarFieldEnum;
	["AdvertisementCategory"]: {
	__typename: "AdvertisementCategory",
	id: string,
	name: string,
	type: GraphQLTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	campaignIds: Array<string>,
	bookingIds: Array<string>,
	_count?: GraphQLTypes["AdvertisementCategoryCount"] | undefined,
	campaigns: Array<GraphQLTypes["Campaign"]>,
	bookings: Array<GraphQLTypes["Booking"]>
};
	["AdvertisementCategoryCount"]: {
	__typename: "AdvertisementCategoryCount",
	campaigns: number,
	bookings: number
};
	["AdvertisementCategoryOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	campaignIds?: GraphQLTypes["SortOrder"] | undefined,
	bookingIds?: GraphQLTypes["SortOrder"] | undefined,
	campaigns?: GraphQLTypes["CampaignOrderByRelationAggregateInput"] | undefined,
	bookings?: GraphQLTypes["BookingOrderByRelationAggregateInput"] | undefined
};
	["AdvertisementCategoryWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["AdvertisementCategoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdvertisementCategoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdvertisementCategoryWhereInput"]> | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumAdvertisementCategoryEnumFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	campaignIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	campaigns?: GraphQLTypes["CampaignListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined
};
	["AdvertisementCategoryScalarFieldEnum"]: AdvertisementCategoryScalarFieldEnum;
	["Changelog"]: {
	__typename: "Changelog",
	id: string,
	createdAt: GraphQLTypes["DateTime"],
	message: string,
	action: string,
	bookingId?: string | undefined,
	userId: string,
	updates?: GraphQLTypes["JSON"] | undefined,
	booking?: GraphQLTypes["Booking"] | undefined,
	user: GraphQLTypes["User"]
};
	["ChangelogOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	message?: GraphQLTypes["SortOrder"] | undefined,
	action?: GraphQLTypes["SortOrder"] | undefined,
	bookingId?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	updates?: GraphQLTypes["SortOrder"] | undefined,
	booking?: GraphQLTypes["BookingOrderByWithRelationInput"] | undefined,
	user?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined
};
	["ChangelogWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["ChangelogWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["ChangelogWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["ChangelogWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	message?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["StringFilter"] | undefined,
	bookingId?: GraphQLTypes["StringNullableFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	updates?: GraphQLTypes["JsonNullableFilter"] | undefined,
	booking?: GraphQLTypes["BookingNullableRelationFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["ChangelogScalarFieldEnum"]: ChangelogScalarFieldEnum;
	["SlotUsage"]: {
	__typename: "SlotUsage",
	id: string,
	bookingId: string,
	date: GraphQLTypes["DateTime"],
	slotUsed: number,
	booking: GraphQLTypes["Booking"]
};
	["SlotUsageOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	bookingId?: GraphQLTypes["SortOrder"] | undefined,
	date?: GraphQLTypes["SortOrder"] | undefined,
	slotUsed?: GraphQLTypes["SortOrder"] | undefined,
	booking?: GraphQLTypes["BookingOrderByWithRelationInput"] | undefined
};
	["SlotUsageWhereUniqueInput"]: {
		id?: string | undefined,
	bookingId_date?: GraphQLTypes["SlotUsageBookingIdDateCompoundUniqueInput"] | undefined,
	AND?: Array<GraphQLTypes["SlotUsageWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["SlotUsageWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SlotUsageWhereInput"]> | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["DateTimeFilter"] | undefined,
	slotUsed?: GraphQLTypes["IntFilter"] | undefined,
	booking?: GraphQLTypes["BookingRelationFilter"] | undefined
};
	["SlotUsageBookingIdDateCompoundUniqueInput"]: {
		bookingId: string,
	date: GraphQLTypes["DateTime"]
};
	["SlotUsageScalarFieldEnum"]: SlotUsageScalarFieldEnum;
	["Order"]: {
	__typename: "Order",
	id: string,
	adBoardId: string,
	createdDate: GraphQLTypes["DateTime"],
	createdByUserId?: string | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: GraphQLTypes["PriceSummary"],
	selectedDate: GraphQLTypes["OrderSelectedDate"],
	adBoard: GraphQLTypes["AdBoard"],
	createdBy?: GraphQLTypes["User"] | undefined
};
	["OrderOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	createdDate?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	paymentId?: GraphQLTypes["SortOrder"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryOrderByInput"] | undefined,
	selectedDate?: GraphQLTypes["OrderSelectedDateOrderByInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined,
	createdBy?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined
};
	["OrderWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["OrderWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["OrderWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["OrderWhereInput"]> | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	createdDate?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	origin?: GraphQLTypes["StringFilter"] | undefined,
	paymentId?: GraphQLTypes["StringNullableFilter"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCompositeFilter"] | undefined,
	selectedDate?: GraphQLTypes["OrderSelectedDateCompositeFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	createdBy?: GraphQLTypes["UserNullableRelationFilter"] | undefined
};
	["OrderScalarFieldEnum"]: OrderScalarFieldEnum;
	["Notification"]: {
	__typename: "Notification",
	id: string,
	type: GraphQLTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	readAt?: GraphQLTypes["DateTime"] | undefined,
	recipientId: string,
	channels: Array<GraphQLTypes["NotificationChannel"]>,
	recipient: GraphQLTypes["User"]
};
	["NotificationOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	content?: GraphQLTypes["SortOrder"] | undefined,
	metadata?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	readAt?: GraphQLTypes["SortOrder"] | undefined,
	recipientId?: GraphQLTypes["SortOrder"] | undefined,
	channels?: GraphQLTypes["SortOrder"] | undefined,
	recipient?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined
};
	["NotificationWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["NotificationWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["NotificationWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["NotificationWhereInput"]> | undefined,
	type?: GraphQLTypes["EnumNotificationTypeFilter"] | undefined,
	title?: GraphQLTypes["StringFilter"] | undefined,
	content?: GraphQLTypes["StringFilter"] | undefined,
	metadata?: GraphQLTypes["JsonNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	readAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	recipientId?: GraphQLTypes["StringFilter"] | undefined,
	channels?: GraphQLTypes["EnumNotificationChannelNullableListFilter"] | undefined,
	recipient?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["NotificationScalarFieldEnum"]: NotificationScalarFieldEnum;
	["AdBoardPublishHistory"]: {
	__typename: "AdBoardPublishHistory",
	id: string,
	adBoardId: string,
	publishedAt: GraphQLTypes["DateTime"],
	publishedByUserId: string,
	changesSnapshot: GraphQLTypes["JSON"],
	adBoard: GraphQLTypes["AdBoard"],
	publishedBy: GraphQLTypes["User"]
};
	["AdBoardPublishHistoryOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	publishedAt?: GraphQLTypes["SortOrder"] | undefined,
	publishedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	changesSnapshot?: GraphQLTypes["SortOrder"] | undefined,
	adBoard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined,
	publishedBy?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined
};
	["AdBoardPublishHistoryWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardPublishHistoryWhereInput"]> | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	publishedByUserId?: GraphQLTypes["StringFilter"] | undefined,
	changesSnapshot?: GraphQLTypes["JsonFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	publishedBy?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["AdBoardPublishHistoryScalarFieldEnum"]: AdBoardPublishHistoryScalarFieldEnum;
	["AdBoardReviewComment"]: {
	__typename: "AdBoardReviewComment",
	id: string,
	adBoardId: string,
	userId: string,
	comment: string,
	action: GraphQLTypes["ReviewAction"],
	createdAt: GraphQLTypes["DateTime"],
	adBoard: GraphQLTypes["AdBoard"],
	user: GraphQLTypes["User"]
};
	["AdBoardReviewCommentOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	comment?: GraphQLTypes["SortOrder"] | undefined,
	action?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	adBoard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined,
	user?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined
};
	["AdBoardReviewCommentWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardReviewCommentWhereInput"]> | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	comment?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["EnumReviewActionFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["AdBoardReviewCommentScalarFieldEnum"]: AdBoardReviewCommentScalarFieldEnum;
	["WorkspaceWhereUniqueInput"]: {
		id?: string | undefined,
	key?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	AND?: Array<GraphQLTypes["WorkspaceWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WorkspaceWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WorkspaceWhereInput"]> | undefined,
	type?: GraphQLTypes["EnumWorkspaceTypeFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	userIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	ownerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	avatar?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	leadIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	owner?: GraphQLTypes["UserNullableRelationFilter"] | undefined,
	memberships?: GraphQLTypes["MembershipListRelationFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	playlists?: GraphQLTypes["PlaylistListRelationFilter"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupListRelationFilter"] | undefined,
	audits?: GraphQLTypes["AuditListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined,
	files?: GraphQLTypes["FileListRelationFilter"] | undefined,
	players?: GraphQLTypes["PlayerListRelationFilter"] | undefined,
	transactions?: GraphQLTypes["TransactionListRelationFilter"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionNullableRelationFilter"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerListRelationFilter"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleListRelationFilter"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogListRelationFilter"] | undefined,
	leads?: GraphQLTypes["UserListRelationFilter"] | undefined
};
	["WorkspaceScalarFieldEnum"]: WorkspaceScalarFieldEnum;
	["AdBoardPublicReview"]: {
	__typename: "AdBoardPublicReview",
	id: string,
	adBoardId: string,
	userId: string,
	rating: number,
	comment?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	adBoard: GraphQLTypes["AdBoard"],
	user: GraphQLTypes["User"]
};
	["AdBoardPublicReviewOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	rating?: GraphQLTypes["SortOrder"] | undefined,
	comment?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	adBoard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined,
	user?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined
};
	["AdBoardPublicReviewWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardPublicReviewWhereInput"]> | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	rating?: GraphQLTypes["IntFilter"] | undefined,
	comment?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["AdBoardPublicReviewScalarFieldEnum"]: AdBoardPublicReviewScalarFieldEnum;
	["UserPosition"]: {
	__typename: "UserPosition",
	id: string,
	timestamp: GraphQLTypes["DateTime"],
	createdAt: GraphQLTypes["DateTime"],
	userId: string,
	coords: GraphQLTypes["UserPositionCoords"],
	location?: GraphQLTypes["UserPositionLocation"] | undefined,
	user: GraphQLTypes["User"]
};
	["UserPositionCoords"]: {
	__typename: "UserPositionCoords",
	accuracy?: number | undefined,
	altitude?: number | undefined,
	altitudeAccuracy?: number | undefined,
	heading?: number | undefined,
	latitude: number,
	longitude: number,
	speed?: number | undefined
};
	["UserPositionLocation"]: {
	__typename: "UserPositionLocation",
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["UserPositionOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	coords?: GraphQLTypes["UserPositionCoordsOrderByInput"] | undefined,
	location?: GraphQLTypes["UserPositionLocationOrderByInput"] | undefined,
	user?: GraphQLTypes["UserOrderByWithRelationInput"] | undefined
};
	["UserPositionCoordsOrderByInput"]: {
		accuracy?: GraphQLTypes["SortOrder"] | undefined,
	altitude?: GraphQLTypes["SortOrder"] | undefined,
	altitudeAccuracy?: GraphQLTypes["SortOrder"] | undefined,
	heading?: GraphQLTypes["SortOrder"] | undefined,
	latitude?: GraphQLTypes["SortOrder"] | undefined,
	longitude?: GraphQLTypes["SortOrder"] | undefined,
	speed?: GraphQLTypes["SortOrder"] | undefined
};
	["UserPositionLocationOrderByInput"]: {
		address?: GraphQLTypes["SortOrder"] | undefined,
	city?: GraphQLTypes["SortOrder"] | undefined,
	state?: GraphQLTypes["SortOrder"] | undefined,
	country?: GraphQLTypes["SortOrder"] | undefined
};
	["UserPositionWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["UserPositionWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserPositionWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserPositionWhereInput"]> | undefined,
	timestamp?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	coords?: GraphQLTypes["UserPositionCoordsCompositeFilter"] | undefined,
	location?: GraphQLTypes["UserPositionLocationNullableCompositeFilter"] | undefined,
	user?: GraphQLTypes["UserRelationFilter"] | undefined
};
	["UserPositionScalarFieldEnum"]: UserPositionScalarFieldEnum;
	["Payment"]: {
	__typename: "Payment",
	id: string,
	paymentIntentId: string,
	checkoutSessionId: string,
	eventId: string,
	liveMode: boolean,
	paymentGateway: GraphQLTypes["PaymentGatewayName"],
	subtotal: number,
	total: number,
	tax: number,
	discount: number,
	campaignId?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	campaign?: GraphQLTypes["Campaign"] | undefined
};
	["PaymentOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	paymentIntentId?: GraphQLTypes["SortOrder"] | undefined,
	checkoutSessionId?: GraphQLTypes["SortOrder"] | undefined,
	eventId?: GraphQLTypes["SortOrder"] | undefined,
	liveMode?: GraphQLTypes["SortOrder"] | undefined,
	paymentGateway?: GraphQLTypes["SortOrder"] | undefined,
	subtotal?: GraphQLTypes["SortOrder"] | undefined,
	total?: GraphQLTypes["SortOrder"] | undefined,
	tax?: GraphQLTypes["SortOrder"] | undefined,
	discount?: GraphQLTypes["SortOrder"] | undefined,
	campaignId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	campaign?: GraphQLTypes["CampaignOrderByWithRelationInput"] | undefined
};
	["PaymentWhereUniqueInput"]: {
		id?: string | undefined,
	paymentIntentId?: string | undefined,
	checkoutSessionId?: string | undefined,
	eventId?: string | undefined,
	AND?: Array<GraphQLTypes["PaymentWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PaymentWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PaymentWhereInput"]> | undefined,
	liveMode?: GraphQLTypes["BoolFilter"] | undefined,
	paymentGateway?: GraphQLTypes["EnumPaymentGatewayNameFilter"] | undefined,
	subtotal?: GraphQLTypes["FloatFilter"] | undefined,
	total?: GraphQLTypes["FloatFilter"] | undefined,
	tax?: GraphQLTypes["FloatFilter"] | undefined,
	discount?: GraphQLTypes["FloatFilter"] | undefined,
	campaignId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	campaign?: GraphQLTypes["CampaignNullableRelationFilter"] | undefined
};
	["PaymentScalarFieldEnum"]: PaymentScalarFieldEnum;
	["BusinessCategoryWhereUniqueInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	AND?: Array<GraphQLTypes["BusinessCategoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BusinessCategoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BusinessCategoryWhereInput"]> | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	restrictedByAdBoardIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	campaigns?: GraphQLTypes["CampaignListRelationFilter"] | undefined,
	bookings?: GraphQLTypes["BookingListRelationFilter"] | undefined
};
	["BusinessCategoryScalarFieldEnum"]: BusinessCategoryScalarFieldEnum;
	["SlotConfiguration"]: {
	__typename: "SlotConfiguration",
	id: string,
	adBoardId: string,
	date: GraphQLTypes["DateTime"],
	availableSlots: number,
	adBoard: GraphQLTypes["AdBoard"]
};
	["SlotConfigurationOrderByWithRelationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	date?: GraphQLTypes["SortOrder"] | undefined,
	availableSlots?: GraphQLTypes["SortOrder"] | undefined,
	adBoard?: GraphQLTypes["AdBoardOrderByWithRelationInput"] | undefined
};
	["SlotConfigurationWhereUniqueInput"]: {
		id?: string | undefined,
	adBoardId_date?: GraphQLTypes["SlotConfigurationAdBoardIdDateCompoundUniqueInput"] | undefined,
	AND?: Array<GraphQLTypes["SlotConfigurationWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["SlotConfigurationWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SlotConfigurationWhereInput"]> | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["DateTimeFilter"] | undefined,
	availableSlots?: GraphQLTypes["IntFilter"] | undefined,
	adBoard?: GraphQLTypes["AdBoardRelationFilter"] | undefined
};
	["SlotConfigurationAdBoardIdDateCompoundUniqueInput"]: {
		adBoardId: string,
	date: GraphQLTypes["DateTime"]
};
	["SlotConfigurationScalarFieldEnum"]: SlotConfigurationScalarFieldEnum;
	["AdBoardGroupBy"]: {
	__typename: "AdBoardGroupBy",
	id: string,
	address: string,
	state: string,
	city: string,
	country: string,
	outdoor: boolean,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive: boolean,
	numberOfDisplays: number,
	dailyOperationHours: number,
	adsPerLoop: number,
	adsDuration: number,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled: boolean,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft: boolean,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived: boolean,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	_count?: GraphQLTypes["AdBoardCountAggregate"] | undefined,
	_avg?: GraphQLTypes["AdBoardAvgAggregate"] | undefined,
	_sum?: GraphQLTypes["AdBoardSumAggregate"] | undefined,
	_min?: GraphQLTypes["AdBoardMinAggregate"] | undefined,
	_max?: GraphQLTypes["AdBoardMaxAggregate"] | undefined
};
	["AdBoardOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	address?: GraphQLTypes["SortOrder"] | undefined,
	state?: GraphQLTypes["SortOrder"] | undefined,
	city?: GraphQLTypes["SortOrder"] | undefined,
	country?: GraphQLTypes["SortOrder"] | undefined,
	outdoor?: GraphQLTypes["SortOrder"] | undefined,
	locationCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	locationSubCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	alias?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	pricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	originalPricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	adBoardTypeId?: GraphQLTypes["SortOrder"] | undefined,
	tags?: GraphQLTypes["SortOrder"] | undefined,
	featuredImage?: GraphQLTypes["SortOrder"] | undefined,
	galleryImgs?: GraphQLTypes["SortOrder"] | undefined,
	notes?: GraphQLTypes["SortOrder"] | undefined,
	impressionMultiplier?: GraphQLTypes["SortOrder"] | undefined,
	categoryId?: GraphQLTypes["SortOrder"] | undefined,
	restrictedBusinessIds?: GraphQLTypes["SortOrder"] | undefined,
	targetAudiences?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	adboardDraftStatus?: GraphQLTypes["SortOrder"] | undefined,
	isLive?: GraphQLTypes["SortOrder"] | undefined,
	numberOfDisplays?: GraphQLTypes["SortOrder"] | undefined,
	dailyOperationHours?: GraphQLTypes["SortOrder"] | undefined,
	adsPerLoop?: GraphQLTypes["SortOrder"] | undefined,
	adsDuration?: GraphQLTypes["SortOrder"] | undefined,
	monthlyPrice?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingContactId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	href?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	playerIds?: GraphQLTypes["SortOrder"] | undefined,
	publisherScreenId?: GraphQLTypes["SortOrder"] | undefined,
	additionalData?: GraphQLTypes["SortOrder"] | undefined,
	isDraft?: GraphQLTypes["SortOrder"] | undefined,
	publishedVersionId?: GraphQLTypes["SortOrder"] | undefined,
	lastPublishedAt?: GraphQLTypes["SortOrder"] | undefined,
	isArchived?: GraphQLTypes["SortOrder"] | undefined,
	archivedAt?: GraphQLTypes["SortOrder"] | undefined,
	archivedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["AdBoardCountOrderByAggregateInput"] | undefined,
	_avg?: GraphQLTypes["AdBoardAvgOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["AdBoardMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["AdBoardMinOrderByAggregateInput"] | undefined,
	_sum?: GraphQLTypes["AdBoardSumOrderByAggregateInput"] | undefined
};
	["AdBoardCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	address?: GraphQLTypes["SortOrder"] | undefined,
	state?: GraphQLTypes["SortOrder"] | undefined,
	city?: GraphQLTypes["SortOrder"] | undefined,
	country?: GraphQLTypes["SortOrder"] | undefined,
	outdoor?: GraphQLTypes["SortOrder"] | undefined,
	locationCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	locationSubCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	alias?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	pricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	originalPricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	adBoardTypeId?: GraphQLTypes["SortOrder"] | undefined,
	tags?: GraphQLTypes["SortOrder"] | undefined,
	featuredImage?: GraphQLTypes["SortOrder"] | undefined,
	galleryImgs?: GraphQLTypes["SortOrder"] | undefined,
	notes?: GraphQLTypes["SortOrder"] | undefined,
	impressionMultiplier?: GraphQLTypes["SortOrder"] | undefined,
	categoryId?: GraphQLTypes["SortOrder"] | undefined,
	restrictedBusinessIds?: GraphQLTypes["SortOrder"] | undefined,
	targetAudiences?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	adboardDraftStatus?: GraphQLTypes["SortOrder"] | undefined,
	isLive?: GraphQLTypes["SortOrder"] | undefined,
	numberOfDisplays?: GraphQLTypes["SortOrder"] | undefined,
	dailyOperationHours?: GraphQLTypes["SortOrder"] | undefined,
	adsPerLoop?: GraphQLTypes["SortOrder"] | undefined,
	adsDuration?: GraphQLTypes["SortOrder"] | undefined,
	monthlyPrice?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingContactId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	href?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	playerIds?: GraphQLTypes["SortOrder"] | undefined,
	publisherScreenId?: GraphQLTypes["SortOrder"] | undefined,
	additionalData?: GraphQLTypes["SortOrder"] | undefined,
	isDraft?: GraphQLTypes["SortOrder"] | undefined,
	publishedVersionId?: GraphQLTypes["SortOrder"] | undefined,
	lastPublishedAt?: GraphQLTypes["SortOrder"] | undefined,
	isArchived?: GraphQLTypes["SortOrder"] | undefined,
	archivedAt?: GraphQLTypes["SortOrder"] | undefined,
	archivedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardAvgOrderByAggregateInput"]: {
		pricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	originalPricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	impressionMultiplier?: GraphQLTypes["SortOrder"] | undefined,
	numberOfDisplays?: GraphQLTypes["SortOrder"] | undefined,
	dailyOperationHours?: GraphQLTypes["SortOrder"] | undefined,
	adsPerLoop?: GraphQLTypes["SortOrder"] | undefined,
	adsDuration?: GraphQLTypes["SortOrder"] | undefined,
	monthlyPrice?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	address?: GraphQLTypes["SortOrder"] | undefined,
	state?: GraphQLTypes["SortOrder"] | undefined,
	city?: GraphQLTypes["SortOrder"] | undefined,
	country?: GraphQLTypes["SortOrder"] | undefined,
	outdoor?: GraphQLTypes["SortOrder"] | undefined,
	locationCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	locationSubCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	alias?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	pricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	originalPricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	adBoardTypeId?: GraphQLTypes["SortOrder"] | undefined,
	featuredImage?: GraphQLTypes["SortOrder"] | undefined,
	impressionMultiplier?: GraphQLTypes["SortOrder"] | undefined,
	categoryId?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	adboardDraftStatus?: GraphQLTypes["SortOrder"] | undefined,
	isLive?: GraphQLTypes["SortOrder"] | undefined,
	numberOfDisplays?: GraphQLTypes["SortOrder"] | undefined,
	dailyOperationHours?: GraphQLTypes["SortOrder"] | undefined,
	adsPerLoop?: GraphQLTypes["SortOrder"] | undefined,
	adsDuration?: GraphQLTypes["SortOrder"] | undefined,
	monthlyPrice?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingContactId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	href?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	publisherScreenId?: GraphQLTypes["SortOrder"] | undefined,
	isDraft?: GraphQLTypes["SortOrder"] | undefined,
	publishedVersionId?: GraphQLTypes["SortOrder"] | undefined,
	lastPublishedAt?: GraphQLTypes["SortOrder"] | undefined,
	isArchived?: GraphQLTypes["SortOrder"] | undefined,
	archivedAt?: GraphQLTypes["SortOrder"] | undefined,
	archivedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	address?: GraphQLTypes["SortOrder"] | undefined,
	state?: GraphQLTypes["SortOrder"] | undefined,
	city?: GraphQLTypes["SortOrder"] | undefined,
	country?: GraphQLTypes["SortOrder"] | undefined,
	outdoor?: GraphQLTypes["SortOrder"] | undefined,
	locationCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	locationSubCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	alias?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	pricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	originalPricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	adBoardTypeId?: GraphQLTypes["SortOrder"] | undefined,
	featuredImage?: GraphQLTypes["SortOrder"] | undefined,
	impressionMultiplier?: GraphQLTypes["SortOrder"] | undefined,
	categoryId?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	adboardDraftStatus?: GraphQLTypes["SortOrder"] | undefined,
	isLive?: GraphQLTypes["SortOrder"] | undefined,
	numberOfDisplays?: GraphQLTypes["SortOrder"] | undefined,
	dailyOperationHours?: GraphQLTypes["SortOrder"] | undefined,
	adsPerLoop?: GraphQLTypes["SortOrder"] | undefined,
	adsDuration?: GraphQLTypes["SortOrder"] | undefined,
	monthlyPrice?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["SortOrder"] | undefined,
	offlineBookingContactId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	href?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	publisherScreenId?: GraphQLTypes["SortOrder"] | undefined,
	isDraft?: GraphQLTypes["SortOrder"] | undefined,
	publishedVersionId?: GraphQLTypes["SortOrder"] | undefined,
	lastPublishedAt?: GraphQLTypes["SortOrder"] | undefined,
	isArchived?: GraphQLTypes["SortOrder"] | undefined,
	archivedAt?: GraphQLTypes["SortOrder"] | undefined,
	archivedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardSumOrderByAggregateInput"]: {
		pricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	originalPricePerDay?: GraphQLTypes["SortOrder"] | undefined,
	impressionMultiplier?: GraphQLTypes["SortOrder"] | undefined,
	numberOfDisplays?: GraphQLTypes["SortOrder"] | undefined,
	dailyOperationHours?: GraphQLTypes["SortOrder"] | undefined,
	adsPerLoop?: GraphQLTypes["SortOrder"] | undefined,
	adsDuration?: GraphQLTypes["SortOrder"] | undefined,
	monthlyPrice?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	address?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	state?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	city?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	country?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	outdoor?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	locationCategoryId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	locationSubCategoryId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	alias?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	title?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	pricePerDay?: GraphQLTypes["IntWithAggregatesFilter"] | undefined,
	originalPricePerDay?: GraphQLTypes["IntNullableWithAggregatesFilter"] | undefined,
	description?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	adBoardTypeId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	tags?: GraphQLTypes["StringNullableListFilter"] | undefined,
	featuredImage?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	galleryImgs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	notes?: GraphQLTypes["StringNullableListFilter"] | undefined,
	impressionMultiplier?: GraphQLTypes["FloatNullableWithAggregatesFilter"] | undefined,
	categoryId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	restrictedBusinessIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	targetAudiences?: GraphQLTypes["StringNullableListFilter"] | undefined,
	status?: GraphQLTypes["EnumAdBoardStatusWithAggregatesFilter"] | undefined,
	adboardDraftStatus?: GraphQLTypes["EnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined,
	isLive?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	numberOfDisplays?: GraphQLTypes["IntWithAggregatesFilter"] | undefined,
	dailyOperationHours?: GraphQLTypes["IntWithAggregatesFilter"] | undefined,
	adsPerLoop?: GraphQLTypes["IntWithAggregatesFilter"] | undefined,
	adsDuration?: GraphQLTypes["IntWithAggregatesFilter"] | undefined,
	monthlyPrice?: GraphQLTypes["FloatNullableWithAggregatesFilter"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	offlineBookingContactId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	href?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	playerIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	publisherScreenId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	additionalData?: GraphQLTypes["JsonNullableWithAggregatesFilter"] | undefined,
	isDraft?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	publishedVersionId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	lastPublishedAt?: GraphQLTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	isArchived?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	archivedAt?: GraphQLTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	archivedByUserId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeNullableWithAggregatesFilter"] | undefined
};
	["StringWithAggregatesFilter"]: {
		equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	mode?: GraphQLTypes["QueryMode"] | undefined,
	not?: GraphQLTypes["NestedStringWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedStringFilter"] | undefined,
	_max?: GraphQLTypes["NestedStringFilter"] | undefined
};
	["NestedStringWithAggregatesFilter"]: {
		equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	not?: GraphQLTypes["NestedStringWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedStringFilter"] | undefined,
	_max?: GraphQLTypes["NestedStringFilter"] | undefined
};
	["BoolWithAggregatesFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["NestedBoolWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedBoolFilter"] | undefined,
	_max?: GraphQLTypes["NestedBoolFilter"] | undefined
};
	["NestedBoolWithAggregatesFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["NestedBoolWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedBoolFilter"] | undefined,
	_max?: GraphQLTypes["NestedBoolFilter"] | undefined
};
	["StringNullableWithAggregatesFilter"]: {
		equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	mode?: GraphQLTypes["QueryMode"] | undefined,
	not?: GraphQLTypes["NestedStringNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedStringNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedStringNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedStringNullableWithAggregatesFilter"]: {
		equals?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	not?: GraphQLTypes["NestedStringNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedStringNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedStringNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["IntWithAggregatesFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedIntWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_avg?: GraphQLTypes["NestedFloatFilter"] | undefined,
	_sum?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedIntFilter"] | undefined,
	_max?: GraphQLTypes["NestedIntFilter"] | undefined
};
	["NestedIntWithAggregatesFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedIntWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_avg?: GraphQLTypes["NestedFloatFilter"] | undefined,
	_sum?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedIntFilter"] | undefined,
	_max?: GraphQLTypes["NestedIntFilter"] | undefined
};
	["IntNullableWithAggregatesFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedIntNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_avg?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	_sum?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedIntNullableWithAggregatesFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedIntNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_avg?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	_sum?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["FloatNullableWithAggregatesFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedFloatNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_avg?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	_sum?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedFloatNullableWithAggregatesFilter"]: {
		equals?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	not?: GraphQLTypes["NestedFloatNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_avg?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	_sum?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedFloatNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["EnumAdBoardStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["AdBoardStatus"] | undefined,
	in?: Array<GraphQLTypes["AdBoardStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["AdBoardStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdBoardStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumAdBoardStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumAdBoardStatusFilter"] | undefined
};
	["NestedEnumAdBoardStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["AdBoardStatus"] | undefined,
	in?: Array<GraphQLTypes["AdBoardStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["AdBoardStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdBoardStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumAdBoardStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumAdBoardStatusFilter"] | undefined
};
	["EnumAdboardDraftStatusNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	in?: Array<GraphQLTypes["AdboardDraftStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["AdboardDraftStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	in?: Array<GraphQLTypes["AdboardDraftStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["AdboardDraftStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumAdboardDraftStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["DateTimeNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["NestedDateTimeNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedDateTimeNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedDateTimeNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedDateTimeNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["NestedDateTimeNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedDateTimeNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedDateTimeNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["JsonNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not?: GraphQLTypes["JSON"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedJsonNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedJsonNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedJsonNullableFilter"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not?: GraphQLTypes["JSON"] | undefined,
	isSet?: boolean | undefined
};
	["AggregateAdBoardGroup"]: {
	__typename: "AggregateAdBoardGroup",
	_count?: GraphQLTypes["AdBoardGroupCountAggregate"] | undefined,
	_min?: GraphQLTypes["AdBoardGroupMinAggregate"] | undefined,
	_max?: GraphQLTypes["AdBoardGroupMaxAggregate"] | undefined
};
	["AdBoardGroupCountAggregate"]: {
	__typename: "AdBoardGroupCountAggregate",
	id: number,
	name: number,
	adBoardId: number,
	scheduledPlaylistsIDs: number,
	adsPlaylistID: number,
	pisignageGroupId: number,
	fitImage: number,
	fitVideo: number,
	workspaceId: number,
	_all: number
};
	["AdBoardGroupMinAggregate"]: {
	__typename: "AdBoardGroupMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	adBoardId?: string | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	workspaceId?: string | undefined
};
	["AdBoardGroupMaxAggregate"]: {
	__typename: "AdBoardGroupMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	adBoardId?: string | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	workspaceId?: string | undefined
};
	["AdBoardGroupGroupBy"]: {
	__typename: "AdBoardGroupGroupBy",
	id: string,
	name: string,
	adBoardId?: string | undefined,
	scheduledPlaylistsIDs?: Array<string> | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId: string,
	fitImage: GraphQLTypes["FitType"],
	fitVideo: GraphQLTypes["FitType"],
	workspaceId: string,
	_count?: GraphQLTypes["AdBoardGroupCountAggregate"] | undefined,
	_min?: GraphQLTypes["AdBoardGroupMinAggregate"] | undefined,
	_max?: GraphQLTypes["AdBoardGroupMaxAggregate"] | undefined
};
	["AdBoardGroupOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	scheduledPlaylistsIDs?: GraphQLTypes["SortOrder"] | undefined,
	adsPlaylistID?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	fitImage?: GraphQLTypes["SortOrder"] | undefined,
	fitVideo?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["AdBoardGroupCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["AdBoardGroupMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["AdBoardGroupMinOrderByAggregateInput"] | undefined
};
	["AdBoardGroupCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	scheduledPlaylistsIDs?: GraphQLTypes["SortOrder"] | undefined,
	adsPlaylistID?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	fitImage?: GraphQLTypes["SortOrder"] | undefined,
	fitVideo?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardGroupMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	adsPlaylistID?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	fitImage?: GraphQLTypes["SortOrder"] | undefined,
	fitVideo?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardGroupMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	adsPlaylistID?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	fitImage?: GraphQLTypes["SortOrder"] | undefined,
	fitVideo?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardGroupScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardGroupScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	scheduledPlaylistsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	adsPlaylistID?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	pisignageGroupId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	fitImage?: GraphQLTypes["EnumFitTypeWithAggregatesFilter"] | undefined,
	fitVideo?: GraphQLTypes["EnumFitTypeWithAggregatesFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined
};
	["EnumFitTypeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["FitType"] | undefined,
	in?: Array<GraphQLTypes["FitType"]> | undefined,
	notIn?: Array<GraphQLTypes["FitType"]> | undefined,
	not?: GraphQLTypes["NestedEnumFitTypeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumFitTypeFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumFitTypeFilter"] | undefined
};
	["NestedEnumFitTypeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["FitType"] | undefined,
	in?: Array<GraphQLTypes["FitType"]> | undefined,
	notIn?: Array<GraphQLTypes["FitType"]> | undefined,
	not?: GraphQLTypes["NestedEnumFitTypeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumFitTypeFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumFitTypeFilter"] | undefined
};
	["AggregateAdBoardType"]: {
	__typename: "AggregateAdBoardType",
	_count?: GraphQLTypes["AdBoardTypeCountAggregate"] | undefined,
	_min?: GraphQLTypes["AdBoardTypeMinAggregate"] | undefined,
	_max?: GraphQLTypes["AdBoardTypeMaxAggregate"] | undefined
};
	["AdBoardTypeCountAggregate"]: {
	__typename: "AdBoardTypeCountAggregate",
	id: number,
	name: number,
	description: number,
	_all: number
};
	["AdBoardTypeMinAggregate"]: {
	__typename: "AdBoardTypeMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["AdBoardTypeMaxAggregate"]: {
	__typename: "AdBoardTypeMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["AdBoardTypeWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["AdBoardTypeWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardTypeWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardTypeWhereInput"]> | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined
};
	["AdBoardTypeScalarFieldEnum"]: AdBoardTypeScalarFieldEnum;
	["AdBoardTypeGroupBy"]: {
	__typename: "AdBoardTypeGroupBy",
	id: string,
	name: string,
	description?: string | undefined,
	_count?: GraphQLTypes["AdBoardTypeCountAggregate"] | undefined,
	_min?: GraphQLTypes["AdBoardTypeMinAggregate"] | undefined,
	_max?: GraphQLTypes["AdBoardTypeMaxAggregate"] | undefined
};
	["AdBoardTypeOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["AdBoardTypeCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["AdBoardTypeMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["AdBoardTypeMinOrderByAggregateInput"] | undefined
};
	["AdBoardTypeCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardTypeMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardTypeMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined
};
	["AdBoardTypeScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardTypeScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	description?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined
};
	["AggregateAdvertisementCategory"]: {
	__typename: "AggregateAdvertisementCategory",
	_count?: GraphQLTypes["AdvertisementCategoryCountAggregate"] | undefined,
	_min?: GraphQLTypes["AdvertisementCategoryMinAggregate"] | undefined,
	_max?: GraphQLTypes["AdvertisementCategoryMaxAggregate"] | undefined
};
	["AdvertisementCategoryCountAggregate"]: {
	__typename: "AdvertisementCategoryCountAggregate",
	id: number,
	name: number,
	type: number,
	description: number,
	createdAt: number,
	updatedAt: number,
	campaignIds: number,
	bookingIds: number,
	_all: number
};
	["AdvertisementCategoryMinAggregate"]: {
	__typename: "AdvertisementCategoryMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	type?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdvertisementCategoryMaxAggregate"]: {
	__typename: "AdvertisementCategoryMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	type?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdvertisementCategoryGroupBy"]: {
	__typename: "AdvertisementCategoryGroupBy",
	id: string,
	name: string,
	type: GraphQLTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	campaignIds?: Array<string> | undefined,
	bookingIds?: Array<string> | undefined,
	_count?: GraphQLTypes["AdvertisementCategoryCountAggregate"] | undefined,
	_min?: GraphQLTypes["AdvertisementCategoryMinAggregate"] | undefined,
	_max?: GraphQLTypes["AdvertisementCategoryMaxAggregate"] | undefined
};
	["AdvertisementCategoryOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	campaignIds?: GraphQLTypes["SortOrder"] | undefined,
	bookingIds?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["AdvertisementCategoryCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["AdvertisementCategoryMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["AdvertisementCategoryMinOrderByAggregateInput"] | undefined
};
	["AdvertisementCategoryCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	campaignIds?: GraphQLTypes["SortOrder"] | undefined,
	bookingIds?: GraphQLTypes["SortOrder"] | undefined
};
	["AdvertisementCategoryMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["AdvertisementCategoryMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["AdvertisementCategoryScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	type?: GraphQLTypes["EnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined,
	description?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	campaignIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["EnumAdvertisementCategoryEnumWithAggregatesFilter"]: {
		equals?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	in?: Array<GraphQLTypes["AdvertisementCategoryEnum"]> | undefined,
	notIn?: Array<GraphQLTypes["AdvertisementCategoryEnum"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined
};
	["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"]: {
		equals?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	in?: Array<GraphQLTypes["AdvertisementCategoryEnum"]> | undefined,
	notIn?: Array<GraphQLTypes["AdvertisementCategoryEnum"]> | undefined,
	not?: GraphQLTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumAdvertisementCategoryEnumFilter"] | undefined
};
	["DateTimeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["NestedDateTimeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedDateTimeFilter"] | undefined,
	_max?: GraphQLTypes["NestedDateTimeFilter"] | undefined
};
	["NestedDateTimeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["NestedDateTimeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedDateTimeFilter"] | undefined,
	_max?: GraphQLTypes["NestedDateTimeFilter"] | undefined
};
	["AggregateAudit"]: {
	__typename: "AggregateAudit",
	_count?: GraphQLTypes["AuditCountAggregate"] | undefined,
	_min?: GraphQLTypes["AuditMinAggregate"] | undefined,
	_max?: GraphQLTypes["AuditMaxAggregate"] | undefined
};
	["AuditCountAggregate"]: {
	__typename: "AuditCountAggregate",
	id: number,
	createdAt: number,
	message: number,
	action: number,
	userId: number,
	workspaceId: number,
	updates: number,
	_all: number
};
	["AuditMinAggregate"]: {
	__typename: "AuditMinAggregate",
	id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	userId?: string | undefined,
	workspaceId?: string | undefined
};
	["AuditMaxAggregate"]: {
	__typename: "AuditMaxAggregate",
	id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	userId?: string | undefined,
	workspaceId?: string | undefined
};
	["AuditGroupBy"]: {
	__typename: "AuditGroupBy",
	id: string,
	createdAt: GraphQLTypes["DateTime"],
	message: string,
	action: string,
	userId: string,
	workspaceId?: string | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	_count?: GraphQLTypes["AuditCountAggregate"] | undefined,
	_min?: GraphQLTypes["AuditMinAggregate"] | undefined,
	_max?: GraphQLTypes["AuditMaxAggregate"] | undefined
};
	["AuditOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	message?: GraphQLTypes["SortOrder"] | undefined,
	action?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	updates?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["AuditCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["AuditMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["AuditMinOrderByAggregateInput"] | undefined
};
	["AuditCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	message?: GraphQLTypes["SortOrder"] | undefined,
	action?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	updates?: GraphQLTypes["SortOrder"] | undefined
};
	["AuditMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	message?: GraphQLTypes["SortOrder"] | undefined,
	action?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["AuditMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	message?: GraphQLTypes["SortOrder"] | undefined,
	action?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["AuditScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["AuditScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["AuditScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AuditScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	message?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	action?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	userId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	updates?: GraphQLTypes["JsonNullableWithAggregatesFilter"] | undefined
};
	["AggregateBooking"]: {
	__typename: "AggregateBooking",
	_count?: GraphQLTypes["BookingCountAggregate"] | undefined,
	_min?: GraphQLTypes["BookingMinAggregate"] | undefined,
	_max?: GraphQLTypes["BookingMaxAggregate"] | undefined
};
	["BookingCountAggregate"]: {
	__typename: "BookingCountAggregate",
	id: number,
	additionalInformation: number,
	origin: number,
	assetsIDs: number,
	bookingCode: number,
	bookingDate: number,
	bookingFor: number,
	purpose: number,
	adBoardId: number,
	workspaceId: number,
	campaignId: number,
	businessCategoryId: number,
	adCategoryId: number,
	bookingStatus: number,
	paymentStatus: number,
	deploymentStatus: number,
	contentApprovalStatus: number,
	userId: number,
	sharedUserIds: number,
	isOffline: number,
	isPaid: number,
	isScheduled: number,
	isDeployed: number,
	isCompleted: number,
	_all: number
};
	["BookingMinAggregate"]: {
	__typename: "BookingMinAggregate",
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId?: string | undefined,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	userId?: string | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["BookingMaxAggregate"]: {
	__typename: "BookingMaxAggregate",
	id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId?: string | undefined,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	userId?: string | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["BookingGroupBy"]: {
	__typename: "BookingGroupBy",
	id: string,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate: GraphQLTypes["DateTime"],
	bookingFor: string,
	purpose: string,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	userId: string,
	sharedUserIds?: Array<string> | undefined,
	isOffline: boolean,
	isPaid: boolean,
	isScheduled: boolean,
	isDeployed: boolean,
	isCompleted: boolean,
	_count?: GraphQLTypes["BookingCountAggregate"] | undefined,
	_min?: GraphQLTypes["BookingMinAggregate"] | undefined,
	_max?: GraphQLTypes["BookingMaxAggregate"] | undefined
};
	["BookingOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	additionalInformation?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	assetsIDs?: GraphQLTypes["SortOrder"] | undefined,
	bookingCode?: GraphQLTypes["SortOrder"] | undefined,
	bookingDate?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	campaignId?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	adCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	bookingStatus?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	deploymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	contentApprovalStatus?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	sharedUserIds?: GraphQLTypes["SortOrder"] | undefined,
	isOffline?: GraphQLTypes["SortOrder"] | undefined,
	isPaid?: GraphQLTypes["SortOrder"] | undefined,
	isScheduled?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	isCompleted?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["BookingCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["BookingMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["BookingMinOrderByAggregateInput"] | undefined
};
	["BookingCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	additionalInformation?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	assetsIDs?: GraphQLTypes["SortOrder"] | undefined,
	bookingCode?: GraphQLTypes["SortOrder"] | undefined,
	bookingDate?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	campaignId?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	adCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	bookingStatus?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	deploymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	contentApprovalStatus?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	sharedUserIds?: GraphQLTypes["SortOrder"] | undefined,
	isOffline?: GraphQLTypes["SortOrder"] | undefined,
	isPaid?: GraphQLTypes["SortOrder"] | undefined,
	isScheduled?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	isCompleted?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	additionalInformation?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	bookingCode?: GraphQLTypes["SortOrder"] | undefined,
	bookingDate?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	campaignId?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	bookingStatus?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	deploymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	contentApprovalStatus?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	isOffline?: GraphQLTypes["SortOrder"] | undefined,
	isPaid?: GraphQLTypes["SortOrder"] | undefined,
	isScheduled?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	isCompleted?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	additionalInformation?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	bookingCode?: GraphQLTypes["SortOrder"] | undefined,
	bookingDate?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	campaignId?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	bookingStatus?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	deploymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	contentApprovalStatus?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	isOffline?: GraphQLTypes["SortOrder"] | undefined,
	isPaid?: GraphQLTypes["SortOrder"] | undefined,
	isScheduled?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	isCompleted?: GraphQLTypes["SortOrder"] | undefined
};
	["BookingScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["BookingScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	additionalInformation?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	origin?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	assetsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingCode?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	bookingDate?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	bookingFor?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	purpose?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	campaignId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	businessCategoryId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	adCategoryId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingStatus?: GraphQLTypes["EnumBookingStatusWithAggregatesFilter"] | undefined,
	paymentStatus?: GraphQLTypes["EnumPaymentStatusNullableWithAggregatesFilter"] | undefined,
	deploymentStatus?: GraphQLTypes["EnumDeploymentStatusWithAggregatesFilter"] | undefined,
	contentApprovalStatus?: GraphQLTypes["EnumContentApprovalStatusWithAggregatesFilter"] | undefined,
	userId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	sharedUserIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	isOffline?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	isPaid?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	isScheduled?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	isDeployed?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	isCompleted?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined
};
	["EnumBookingStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["BookingStatus"] | undefined,
	in?: Array<GraphQLTypes["BookingStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["BookingStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumBookingStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumBookingStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumBookingStatusFilter"] | undefined
};
	["NestedEnumBookingStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["BookingStatus"] | undefined,
	in?: Array<GraphQLTypes["BookingStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["BookingStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumBookingStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumBookingStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumBookingStatusFilter"] | undefined
};
	["EnumPaymentStatusNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["PaymentStatus"] | undefined,
	in?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumPaymentStatusNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["PaymentStatus"] | undefined,
	in?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumPaymentStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["EnumDeploymentStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["DeploymentStatus"] | undefined,
	in?: Array<GraphQLTypes["DeploymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["DeploymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumDeploymentStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumDeploymentStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumDeploymentStatusFilter"] | undefined
};
	["NestedEnumDeploymentStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["DeploymentStatus"] | undefined,
	in?: Array<GraphQLTypes["DeploymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["DeploymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumDeploymentStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumDeploymentStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumDeploymentStatusFilter"] | undefined
};
	["EnumContentApprovalStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	in?: Array<GraphQLTypes["ContentApprovalStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentApprovalStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumContentApprovalStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumContentApprovalStatusFilter"] | undefined
};
	["NestedEnumContentApprovalStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	in?: Array<GraphQLTypes["ContentApprovalStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentApprovalStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumContentApprovalStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumContentApprovalStatusFilter"] | undefined
};
	["AggregateBusinessCategory"]: {
	__typename: "AggregateBusinessCategory",
	_count?: GraphQLTypes["BusinessCategoryCountAggregate"] | undefined,
	_min?: GraphQLTypes["BusinessCategoryMinAggregate"] | undefined,
	_max?: GraphQLTypes["BusinessCategoryMaxAggregate"] | undefined
};
	["BusinessCategoryCountAggregate"]: {
	__typename: "BusinessCategoryCountAggregate",
	id: number,
	name: number,
	description: number,
	restrictedByAdBoardIds: number,
	_all: number
};
	["BusinessCategoryMinAggregate"]: {
	__typename: "BusinessCategoryMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["BusinessCategoryMaxAggregate"]: {
	__typename: "BusinessCategoryMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	description?: string | undefined
};
	["BusinessCategoryGroupBy"]: {
	__typename: "BusinessCategoryGroupBy",
	id: string,
	name: string,
	description?: string | undefined,
	restrictedByAdBoardIds?: Array<string> | undefined,
	_count?: GraphQLTypes["BusinessCategoryCountAggregate"] | undefined,
	_min?: GraphQLTypes["BusinessCategoryMinAggregate"] | undefined,
	_max?: GraphQLTypes["BusinessCategoryMaxAggregate"] | undefined
};
	["BusinessCategoryOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	restrictedByAdBoardIds?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["BusinessCategoryCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["BusinessCategoryMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["BusinessCategoryMinOrderByAggregateInput"] | undefined
};
	["BusinessCategoryCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined,
	restrictedByAdBoardIds?: GraphQLTypes["SortOrder"] | undefined
};
	["BusinessCategoryMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined
};
	["BusinessCategoryMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	description?: GraphQLTypes["SortOrder"] | undefined
};
	["BusinessCategoryScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BusinessCategoryScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	description?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	restrictedByAdBoardIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["AggregateCamera"]: {
	__typename: "AggregateCamera",
	_count?: GraphQLTypes["CameraCountAggregate"] | undefined,
	_min?: GraphQLTypes["CameraMinAggregate"] | undefined,
	_max?: GraphQLTypes["CameraMaxAggregate"] | undefined
};
	["CameraCountAggregate"]: {
	__typename: "CameraCountAggregate",
	id: number,
	name: number,
	isOnline: number,
	cameraServerId: number,
	cameraUrl: number,
	rtspUrl: number,
	cameraIp: number,
	connectedCpuSerialNumbers: number,
	playerId: number,
	updatedAt: number,
	isPublic: number,
	adBoardId: number,
	_all: number
};
	["CameraMinAggregate"]: {
	__typename: "CameraMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraServerId?: string | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	playerId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["CameraMaxAggregate"]: {
	__typename: "CameraMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraServerId?: string | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	playerId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["CameraGroupBy"]: {
	__typename: "CameraGroupBy",
	id: string,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	playerId?: string | undefined,
	updatedAt: GraphQLTypes["DateTime"],
	isPublic: boolean,
	adBoardId?: string | undefined,
	_count?: GraphQLTypes["CameraCountAggregate"] | undefined,
	_min?: GraphQLTypes["CameraMinAggregate"] | undefined,
	_max?: GraphQLTypes["CameraMaxAggregate"] | undefined
};
	["CameraOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined,
	cameraUrl?: GraphQLTypes["SortOrder"] | undefined,
	rtspUrl?: GraphQLTypes["SortOrder"] | undefined,
	cameraIp?: GraphQLTypes["SortOrder"] | undefined,
	connectedCpuSerialNumbers?: GraphQLTypes["SortOrder"] | undefined,
	playerId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	isPublic?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["CameraCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["CameraMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["CameraMinOrderByAggregateInput"] | undefined
};
	["CameraCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined,
	cameraUrl?: GraphQLTypes["SortOrder"] | undefined,
	rtspUrl?: GraphQLTypes["SortOrder"] | undefined,
	cameraIp?: GraphQLTypes["SortOrder"] | undefined,
	connectedCpuSerialNumbers?: GraphQLTypes["SortOrder"] | undefined,
	playerId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	isPublic?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined,
	cameraUrl?: GraphQLTypes["SortOrder"] | undefined,
	rtspUrl?: GraphQLTypes["SortOrder"] | undefined,
	cameraIp?: GraphQLTypes["SortOrder"] | undefined,
	playerId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	isPublic?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined,
	cameraUrl?: GraphQLTypes["SortOrder"] | undefined,
	rtspUrl?: GraphQLTypes["SortOrder"] | undefined,
	cameraIp?: GraphQLTypes["SortOrder"] | undefined,
	playerId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	isPublic?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["CameraScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	cameraServerId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	cameraUrl?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	rtspUrl?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	cameraIp?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	connectedCpuSerialNumbers?: GraphQLTypes["StringNullableListFilter"] | undefined,
	playerId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	isPublic?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined
};
	["AggregateCameraFeed"]: {
	__typename: "AggregateCameraFeed",
	_count?: GraphQLTypes["CameraFeedCountAggregate"] | undefined,
	_min?: GraphQLTypes["CameraFeedMinAggregate"] | undefined,
	_max?: GraphQLTypes["CameraFeedMaxAggregate"] | undefined
};
	["CameraFeedCountAggregate"]: {
	__typename: "CameraFeedCountAggregate",
	id: number,
	cameraId: number,
	timestamp: number,
	cameraServerId: number,
	_all: number
};
	["CameraFeedMinAggregate"]: {
	__typename: "CameraFeedMinAggregate",
	id?: string | undefined,
	cameraId?: string | undefined,
	timestamp?: GraphQLTypes["DateTime"] | undefined,
	cameraServerId?: string | undefined
};
	["CameraFeedMaxAggregate"]: {
	__typename: "CameraFeedMaxAggregate",
	id?: string | undefined,
	cameraId?: string | undefined,
	timestamp?: GraphQLTypes["DateTime"] | undefined,
	cameraServerId?: string | undefined
};
	["CameraFeedGroupBy"]: {
	__typename: "CameraFeedGroupBy",
	id: string,
	cameraId: string,
	timestamp: GraphQLTypes["DateTime"],
	cameraServerId: string,
	_count?: GraphQLTypes["CameraFeedCountAggregate"] | undefined,
	_min?: GraphQLTypes["CameraFeedMinAggregate"] | undefined,
	_max?: GraphQLTypes["CameraFeedMaxAggregate"] | undefined
};
	["CameraFeedOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	cameraId?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["CameraFeedCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["CameraFeedMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["CameraFeedMinOrderByAggregateInput"] | undefined
};
	["CameraFeedCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	cameraId?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraFeedMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	cameraId?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraFeedMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	cameraId?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	cameraServerId?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraFeedScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraFeedScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	cameraId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	timestamp?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	cameraServerId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined
};
	["AggregateCameraServer"]: {
	__typename: "AggregateCameraServer",
	_count?: GraphQLTypes["CameraServerCountAggregate"] | undefined,
	_min?: GraphQLTypes["CameraServerMinAggregate"] | undefined,
	_max?: GraphQLTypes["CameraServerMaxAggregate"] | undefined
};
	["CameraServerCountAggregate"]: {
	__typename: "CameraServerCountAggregate",
	id: number,
	name: number,
	isOnline: number,
	hostName: number,
	userName: number,
	deviceId: number,
	workspaceId: number,
	publicIp: number,
	privateIp: number,
	_all: number
};
	["CameraServerMinAggregate"]: {
	__typename: "CameraServerMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	workspaceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined
};
	["CameraServerMaxAggregate"]: {
	__typename: "CameraServerMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	workspaceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined
};
	["CameraServerGroupBy"]: {
	__typename: "CameraServerGroupBy",
	id: string,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	workspaceId?: string | undefined,
	publicIp: string,
	privateIp: string,
	_count?: GraphQLTypes["CameraServerCountAggregate"] | undefined,
	_min?: GraphQLTypes["CameraServerMinAggregate"] | undefined,
	_max?: GraphQLTypes["CameraServerMaxAggregate"] | undefined
};
	["CameraServerOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	hostName?: GraphQLTypes["SortOrder"] | undefined,
	userName?: GraphQLTypes["SortOrder"] | undefined,
	deviceId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	publicIp?: GraphQLTypes["SortOrder"] | undefined,
	privateIp?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["CameraServerCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["CameraServerMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["CameraServerMinOrderByAggregateInput"] | undefined
};
	["CameraServerCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	hostName?: GraphQLTypes["SortOrder"] | undefined,
	userName?: GraphQLTypes["SortOrder"] | undefined,
	deviceId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	publicIp?: GraphQLTypes["SortOrder"] | undefined,
	privateIp?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraServerMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	hostName?: GraphQLTypes["SortOrder"] | undefined,
	userName?: GraphQLTypes["SortOrder"] | undefined,
	deviceId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	publicIp?: GraphQLTypes["SortOrder"] | undefined,
	privateIp?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraServerMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	hostName?: GraphQLTypes["SortOrder"] | undefined,
	userName?: GraphQLTypes["SortOrder"] | undefined,
	deviceId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	publicIp?: GraphQLTypes["SortOrder"] | undefined,
	privateIp?: GraphQLTypes["SortOrder"] | undefined
};
	["CameraServerScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraServerScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolNullableWithAggregatesFilter"] | undefined,
	hostName?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	userName?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	deviceId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	publicIp?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	privateIp?: GraphQLTypes["StringWithAggregatesFilter"] | undefined
};
	["BoolNullableWithAggregatesFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["NestedBoolNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedBoolNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedBoolNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedBoolNullableWithAggregatesFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["NestedBoolNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedBoolNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedBoolNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["AggregateCampaign"]: {
	__typename: "AggregateCampaign",
	_count?: GraphQLTypes["CampaignCountAggregate"] | undefined,
	_min?: GraphQLTypes["CampaignMinAggregate"] | undefined,
	_max?: GraphQLTypes["CampaignMaxAggregate"] | undefined
};
	["CampaignCountAggregate"]: {
	__typename: "CampaignCountAggregate",
	id: number,
	name: number,
	userId: number,
	createdAt: number,
	updatedAt: number,
	bookingFor: number,
	purpose: number,
	businessCategoryId: number,
	adCategoryId: number,
	paymentStatus: number,
	origin: number,
	assetsIDs: number,
	_all: number
};
	["CampaignMinAggregate"]: {
	__typename: "CampaignMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	userId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	businessCategoryId?: string | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	origin?: string | undefined
};
	["CampaignMaxAggregate"]: {
	__typename: "CampaignMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	userId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	businessCategoryId?: string | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	origin?: string | undefined
};
	["CampaignGroupBy"]: {
	__typename: "CampaignGroupBy",
	id: string,
	name: string,
	userId: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	bookingFor: string,
	purpose: string,
	businessCategoryId: string,
	adCategoryId?: Array<string> | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	origin: string,
	assetsIDs?: Array<string> | undefined,
	_count?: GraphQLTypes["CampaignCountAggregate"] | undefined,
	_min?: GraphQLTypes["CampaignMinAggregate"] | undefined,
	_max?: GraphQLTypes["CampaignMaxAggregate"] | undefined
};
	["CampaignOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	adCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	assetsIDs?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["CampaignCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["CampaignMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["CampaignMinOrderByAggregateInput"] | undefined
};
	["CampaignCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	adCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	assetsIDs?: GraphQLTypes["SortOrder"] | undefined
};
	["CampaignMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined
};
	["CampaignMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	bookingFor?: GraphQLTypes["SortOrder"] | undefined,
	purpose?: GraphQLTypes["SortOrder"] | undefined,
	businessCategoryId?: GraphQLTypes["SortOrder"] | undefined,
	paymentStatus?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined
};
	["CampaignScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CampaignScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	userId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	bookingFor?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	purpose?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	businessCategoryId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	adCategoryId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	paymentStatus?: GraphQLTypes["EnumPaymentStatusWithAggregatesFilter"] | undefined,
	origin?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	assetsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["EnumPaymentStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["PaymentStatus"] | undefined,
	in?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumPaymentStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumPaymentStatusFilter"] | undefined
};
	["NestedEnumPaymentStatusWithAggregatesFilter"]: {
		equals?: GraphQLTypes["PaymentStatus"] | undefined,
	in?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumPaymentStatusWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumPaymentStatusFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumPaymentStatusFilter"] | undefined
};
	["AggregateFile"]: {
	__typename: "AggregateFile",
	_count?: GraphQLTypes["FileCountAggregate"] | undefined,
	_avg?: GraphQLTypes["FileAvgAggregate"] | undefined,
	_sum?: GraphQLTypes["FileSumAggregate"] | undefined,
	_min?: GraphQLTypes["FileMinAggregate"] | undefined,
	_max?: GraphQLTypes["FileMaxAggregate"] | undefined
};
	["FileCountAggregate"]: {
	__typename: "FileCountAggregate",
	id: number,
	contentType: number,
	ctime: number,
	filename: number,
	filepath: number,
	isDirectory: number,
	mtime: number,
	name: number,
	size: number,
	status: number,
	type: number,
	updatedAt: number,
	createdAt: number,
	updatedByUserId: number,
	createdByUserId: number,
	url: number,
	bookingsIDs: number,
	workspaceId: number,
	proofBookingId: number,
	campaignIds: number,
	_all: number
};
	["FileAvgAggregate"]: {
	__typename: "FileAvgAggregate",
	size?: number | undefined
};
	["FileSumAggregate"]: {
	__typename: "FileSumAggregate",
	size?: number | undefined
};
	["FileMinAggregate"]: {
	__typename: "FileMinAggregate",
	id?: string | undefined,
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId?: string | undefined,
	url?: string | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined
};
	["FileMaxAggregate"]: {
	__typename: "FileMaxAggregate",
	id?: string | undefined,
	contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId?: string | undefined,
	url?: string | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined
};
	["FileGroupBy"]: {
	__typename: "FileGroupBy",
	id: string,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt: GraphQLTypes["DateTime"],
	createdAt: GraphQLTypes["DateTime"],
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined,
	_count?: GraphQLTypes["FileCountAggregate"] | undefined,
	_avg?: GraphQLTypes["FileAvgAggregate"] | undefined,
	_sum?: GraphQLTypes["FileSumAggregate"] | undefined,
	_min?: GraphQLTypes["FileMinAggregate"] | undefined,
	_max?: GraphQLTypes["FileMaxAggregate"] | undefined
};
	["FileOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	contentType?: GraphQLTypes["SortOrder"] | undefined,
	ctime?: GraphQLTypes["SortOrder"] | undefined,
	filename?: GraphQLTypes["SortOrder"] | undefined,
	filepath?: GraphQLTypes["SortOrder"] | undefined,
	isDirectory?: GraphQLTypes["SortOrder"] | undefined,
	mtime?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	size?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	url?: GraphQLTypes["SortOrder"] | undefined,
	bookingsIDs?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	proofBookingId?: GraphQLTypes["SortOrder"] | undefined,
	campaignIds?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["FileCountOrderByAggregateInput"] | undefined,
	_avg?: GraphQLTypes["FileAvgOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["FileMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["FileMinOrderByAggregateInput"] | undefined,
	_sum?: GraphQLTypes["FileSumOrderByAggregateInput"] | undefined
};
	["FileCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	contentType?: GraphQLTypes["SortOrder"] | undefined,
	ctime?: GraphQLTypes["SortOrder"] | undefined,
	filename?: GraphQLTypes["SortOrder"] | undefined,
	filepath?: GraphQLTypes["SortOrder"] | undefined,
	isDirectory?: GraphQLTypes["SortOrder"] | undefined,
	mtime?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	size?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	url?: GraphQLTypes["SortOrder"] | undefined,
	bookingsIDs?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	proofBookingId?: GraphQLTypes["SortOrder"] | undefined,
	campaignIds?: GraphQLTypes["SortOrder"] | undefined
};
	["FileAvgOrderByAggregateInput"]: {
		size?: GraphQLTypes["SortOrder"] | undefined
};
	["FileMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	contentType?: GraphQLTypes["SortOrder"] | undefined,
	ctime?: GraphQLTypes["SortOrder"] | undefined,
	filename?: GraphQLTypes["SortOrder"] | undefined,
	filepath?: GraphQLTypes["SortOrder"] | undefined,
	isDirectory?: GraphQLTypes["SortOrder"] | undefined,
	mtime?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	size?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	url?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	proofBookingId?: GraphQLTypes["SortOrder"] | undefined
};
	["FileMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	contentType?: GraphQLTypes["SortOrder"] | undefined,
	ctime?: GraphQLTypes["SortOrder"] | undefined,
	filename?: GraphQLTypes["SortOrder"] | undefined,
	filepath?: GraphQLTypes["SortOrder"] | undefined,
	isDirectory?: GraphQLTypes["SortOrder"] | undefined,
	mtime?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	size?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedByUserId?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	url?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	proofBookingId?: GraphQLTypes["SortOrder"] | undefined
};
	["FileSumOrderByAggregateInput"]: {
		size?: GraphQLTypes["SortOrder"] | undefined
};
	["FileScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["FileScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["FileScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["FileScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	contentType?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	ctime?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	filename?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	filepath?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	isDirectory?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	mtime?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	size?: GraphQLTypes["IntWithAggregatesFilter"] | undefined,
	status?: GraphQLTypes["EnumFileStatusNullableWithAggregatesFilter"] | undefined,
	type?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedByUserId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	url?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	bookingsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	proofBookingId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	campaignIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["EnumFileStatusNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["FileStatus"] | undefined,
	in?: Array<GraphQLTypes["FileStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["FileStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumFileStatusNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["NestedEnumFileStatusNullableWithAggregatesFilter"]: {
		equals?: GraphQLTypes["FileStatus"] | undefined,
	in?: Array<GraphQLTypes["FileStatus"]> | undefined,
	notIn?: Array<GraphQLTypes["FileStatus"]> | undefined,
	not?: GraphQLTypes["NestedEnumFileStatusNullableWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntNullableFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumFileStatusNullableFilter"] | undefined,
	isSet?: boolean | undefined
};
	["AggregateMembership"]: {
	__typename: "AggregateMembership",
	_count?: GraphQLTypes["MembershipCountAggregate"] | undefined,
	_min?: GraphQLTypes["MembershipMinAggregate"] | undefined,
	_max?: GraphQLTypes["MembershipMaxAggregate"] | undefined
};
	["MembershipCountAggregate"]: {
	__typename: "MembershipCountAggregate",
	id: number,
	createdAt: number,
	userId: number,
	workspaceId: number,
	roleId: number,
	_all: number
};
	["MembershipMinAggregate"]: {
	__typename: "MembershipMinAggregate",
	id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	userId?: string | undefined,
	workspaceId?: string | undefined,
	roleId?: string | undefined
};
	["MembershipMaxAggregate"]: {
	__typename: "MembershipMaxAggregate",
	id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	userId?: string | undefined,
	workspaceId?: string | undefined,
	roleId?: string | undefined
};
	["MembershipGroupBy"]: {
	__typename: "MembershipGroupBy",
	id: string,
	createdAt: GraphQLTypes["DateTime"],
	userId: string,
	workspaceId: string,
	roleId: string,
	_count?: GraphQLTypes["MembershipCountAggregate"] | undefined,
	_min?: GraphQLTypes["MembershipMinAggregate"] | undefined,
	_max?: GraphQLTypes["MembershipMaxAggregate"] | undefined
};
	["MembershipOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	roleId?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["MembershipCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["MembershipMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["MembershipMinOrderByAggregateInput"] | undefined
};
	["MembershipCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	roleId?: GraphQLTypes["SortOrder"] | undefined
};
	["MembershipMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	roleId?: GraphQLTypes["SortOrder"] | undefined
};
	["MembershipMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	roleId?: GraphQLTypes["SortOrder"] | undefined
};
	["MembershipScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["MembershipScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	userId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	roleId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined
};
	["AggregateNotification"]: {
	__typename: "AggregateNotification",
	_count?: GraphQLTypes["NotificationCountAggregate"] | undefined,
	_min?: GraphQLTypes["NotificationMinAggregate"] | undefined,
	_max?: GraphQLTypes["NotificationMaxAggregate"] | undefined
};
	["NotificationCountAggregate"]: {
	__typename: "NotificationCountAggregate",
	id: number,
	type: number,
	title: number,
	content: number,
	metadata: number,
	createdAt: number,
	readAt: number,
	recipientId: number,
	channels: number,
	_all: number
};
	["NotificationMinAggregate"]: {
	__typename: "NotificationMinAggregate",
	id?: string | undefined,
	type?: GraphQLTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	recipientId?: string | undefined
};
	["NotificationMaxAggregate"]: {
	__typename: "NotificationMaxAggregate",
	id?: string | undefined,
	type?: GraphQLTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	recipientId?: string | undefined
};
	["NotificationGroupBy"]: {
	__typename: "NotificationGroupBy",
	id: string,
	type: GraphQLTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	readAt?: GraphQLTypes["DateTime"] | undefined,
	recipientId: string,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined,
	_count?: GraphQLTypes["NotificationCountAggregate"] | undefined,
	_min?: GraphQLTypes["NotificationMinAggregate"] | undefined,
	_max?: GraphQLTypes["NotificationMaxAggregate"] | undefined
};
	["NotificationOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	content?: GraphQLTypes["SortOrder"] | undefined,
	metadata?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	readAt?: GraphQLTypes["SortOrder"] | undefined,
	recipientId?: GraphQLTypes["SortOrder"] | undefined,
	channels?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["NotificationCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["NotificationMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["NotificationMinOrderByAggregateInput"] | undefined
};
	["NotificationCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	content?: GraphQLTypes["SortOrder"] | undefined,
	metadata?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	readAt?: GraphQLTypes["SortOrder"] | undefined,
	recipientId?: GraphQLTypes["SortOrder"] | undefined,
	channels?: GraphQLTypes["SortOrder"] | undefined
};
	["NotificationMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	content?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	readAt?: GraphQLTypes["SortOrder"] | undefined,
	recipientId?: GraphQLTypes["SortOrder"] | undefined
};
	["NotificationMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	title?: GraphQLTypes["SortOrder"] | undefined,
	content?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	readAt?: GraphQLTypes["SortOrder"] | undefined,
	recipientId?: GraphQLTypes["SortOrder"] | undefined
};
	["NotificationScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["NotificationScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	type?: GraphQLTypes["EnumNotificationTypeWithAggregatesFilter"] | undefined,
	title?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	content?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	metadata?: GraphQLTypes["JsonNullableWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	readAt?: GraphQLTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	recipientId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	channels?: GraphQLTypes["EnumNotificationChannelNullableListFilter"] | undefined
};
	["EnumNotificationTypeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["NotificationType"] | undefined,
	in?: Array<GraphQLTypes["NotificationType"]> | undefined,
	notIn?: Array<GraphQLTypes["NotificationType"]> | undefined,
	not?: GraphQLTypes["NestedEnumNotificationTypeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumNotificationTypeFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumNotificationTypeFilter"] | undefined
};
	["NestedEnumNotificationTypeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["NotificationType"] | undefined,
	in?: Array<GraphQLTypes["NotificationType"]> | undefined,
	notIn?: Array<GraphQLTypes["NotificationType"]> | undefined,
	not?: GraphQLTypes["NestedEnumNotificationTypeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumNotificationTypeFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumNotificationTypeFilter"] | undefined
};
	["AggregateOrder"]: {
	__typename: "AggregateOrder",
	_count?: GraphQLTypes["OrderCountAggregate"] | undefined,
	_min?: GraphQLTypes["OrderMinAggregate"] | undefined,
	_max?: GraphQLTypes["OrderMaxAggregate"] | undefined
};
	["OrderCountAggregate"]: {
	__typename: "OrderCountAggregate",
	id: number,
	adBoardId: number,
	createdDate: number,
	createdByUserId: number,
	origin: number,
	paymentId: number,
	_all: number
};
	["OrderMinAggregate"]: {
	__typename: "OrderMinAggregate",
	id?: string | undefined,
	adBoardId?: string | undefined,
	createdDate?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined
};
	["OrderMaxAggregate"]: {
	__typename: "OrderMaxAggregate",
	id?: string | undefined,
	adBoardId?: string | undefined,
	createdDate?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined
};
	["OrderGroupBy"]: {
	__typename: "OrderGroupBy",
	id: string,
	adBoardId: string,
	createdDate: GraphQLTypes["DateTime"],
	createdByUserId?: string | undefined,
	origin: string,
	paymentId?: string | undefined,
	_count?: GraphQLTypes["OrderCountAggregate"] | undefined,
	_min?: GraphQLTypes["OrderMinAggregate"] | undefined,
	_max?: GraphQLTypes["OrderMaxAggregate"] | undefined
};
	["OrderOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	createdDate?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	paymentId?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["OrderCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["OrderMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["OrderMinOrderByAggregateInput"] | undefined
};
	["OrderCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	createdDate?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	paymentId?: GraphQLTypes["SortOrder"] | undefined
};
	["OrderMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	createdDate?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	paymentId?: GraphQLTypes["SortOrder"] | undefined
};
	["OrderMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	createdDate?: GraphQLTypes["SortOrder"] | undefined,
	createdByUserId?: GraphQLTypes["SortOrder"] | undefined,
	origin?: GraphQLTypes["SortOrder"] | undefined,
	paymentId?: GraphQLTypes["SortOrder"] | undefined
};
	["OrderScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["OrderScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["OrderScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["OrderScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	createdDate?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	origin?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	paymentId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined
};
	["AggregatePlayer"]: {
	__typename: "AggregatePlayer",
	_count?: GraphQLTypes["PlayerCountAggregate"] | undefined,
	_avg?: GraphQLTypes["PlayerAvgAggregate"] | undefined,
	_sum?: GraphQLTypes["PlayerSumAggregate"] | undefined,
	_min?: GraphQLTypes["PlayerMinAggregate"] | undefined,
	_max?: GraphQLTypes["PlayerMaxAggregate"] | undefined
};
	["PlayerCountAggregate"]: {
	__typename: "PlayerCountAggregate",
	id: number,
	name: number,
	configLocation: number,
	pisignagePlayerId: number,
	pisignageGroupId: number,
	cpuSerialNumber: number,
	displayName: number,
	adBoardGroupId: number,
	playlistIds: number,
	workpaceId: number,
	signageTypeId: number,
	audienceTypeId: number,
	aspectRatioType: number,
	customAspectRatioWidth: number,
	customAspectRatioHeight: number,
	isOnline: number,
	adBoardIds: number,
	_all: number
};
	["PlayerAvgAggregate"]: {
	__typename: "PlayerAvgAggregate",
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined
};
	["PlayerSumAggregate"]: {
	__typename: "PlayerSumAggregate",
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined
};
	["PlayerMinAggregate"]: {
	__typename: "PlayerMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	workpaceId?: string | undefined,
	signageTypeId?: string | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	isOnline?: boolean | undefined
};
	["PlayerMaxAggregate"]: {
	__typename: "PlayerMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	workpaceId?: string | undefined,
	signageTypeId?: string | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	isOnline?: boolean | undefined
};
	["PlayerGroupBy"]: {
	__typename: "PlayerGroupBy",
	id: string,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	playlistIds?: Array<string> | undefined,
	workpaceId?: string | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId?: Array<string> | undefined,
	aspectRatioType: GraphQLTypes["AspectRatioType"],
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	isOnline: boolean,
	adBoardIds?: Array<string> | undefined,
	_count?: GraphQLTypes["PlayerCountAggregate"] | undefined,
	_avg?: GraphQLTypes["PlayerAvgAggregate"] | undefined,
	_sum?: GraphQLTypes["PlayerSumAggregate"] | undefined,
	_min?: GraphQLTypes["PlayerMinAggregate"] | undefined,
	_max?: GraphQLTypes["PlayerMaxAggregate"] | undefined
};
	["PlayerOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	configLocation?: GraphQLTypes["SortOrder"] | undefined,
	pisignagePlayerId?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	cpuSerialNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroupId?: GraphQLTypes["SortOrder"] | undefined,
	playlistIds?: GraphQLTypes["SortOrder"] | undefined,
	workpaceId?: GraphQLTypes["SortOrder"] | undefined,
	signageTypeId?: GraphQLTypes["SortOrder"] | undefined,
	audienceTypeId?: GraphQLTypes["SortOrder"] | undefined,
	aspectRatioType?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioWidth?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	adBoardIds?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["PlayerCountOrderByAggregateInput"] | undefined,
	_avg?: GraphQLTypes["PlayerAvgOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["PlayerMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["PlayerMinOrderByAggregateInput"] | undefined,
	_sum?: GraphQLTypes["PlayerSumOrderByAggregateInput"] | undefined
};
	["PlayerCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	configLocation?: GraphQLTypes["SortOrder"] | undefined,
	pisignagePlayerId?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	cpuSerialNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroupId?: GraphQLTypes["SortOrder"] | undefined,
	playlistIds?: GraphQLTypes["SortOrder"] | undefined,
	workpaceId?: GraphQLTypes["SortOrder"] | undefined,
	signageTypeId?: GraphQLTypes["SortOrder"] | undefined,
	audienceTypeId?: GraphQLTypes["SortOrder"] | undefined,
	aspectRatioType?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioWidth?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined,
	adBoardIds?: GraphQLTypes["SortOrder"] | undefined
};
	["PlayerAvgOrderByAggregateInput"]: {
		customAspectRatioWidth?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["SortOrder"] | undefined
};
	["PlayerMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	configLocation?: GraphQLTypes["SortOrder"] | undefined,
	pisignagePlayerId?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	cpuSerialNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroupId?: GraphQLTypes["SortOrder"] | undefined,
	workpaceId?: GraphQLTypes["SortOrder"] | undefined,
	signageTypeId?: GraphQLTypes["SortOrder"] | undefined,
	aspectRatioType?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioWidth?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined
};
	["PlayerMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	configLocation?: GraphQLTypes["SortOrder"] | undefined,
	pisignagePlayerId?: GraphQLTypes["SortOrder"] | undefined,
	pisignageGroupId?: GraphQLTypes["SortOrder"] | undefined,
	cpuSerialNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroupId?: GraphQLTypes["SortOrder"] | undefined,
	workpaceId?: GraphQLTypes["SortOrder"] | undefined,
	signageTypeId?: GraphQLTypes["SortOrder"] | undefined,
	aspectRatioType?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioWidth?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["SortOrder"] | undefined,
	isOnline?: GraphQLTypes["SortOrder"] | undefined
};
	["PlayerSumOrderByAggregateInput"]: {
		customAspectRatioWidth?: GraphQLTypes["SortOrder"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["SortOrder"] | undefined
};
	["PlayerScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlayerScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	configLocation?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	pisignagePlayerId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	pisignageGroupId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	cpuSerialNumber?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	adBoardGroupId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	playlistIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workpaceId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	signageTypeId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	audienceTypeId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	aspectRatioType?: GraphQLTypes["EnumAspectRatioTypeWithAggregatesFilter"] | undefined,
	customAspectRatioWidth?: GraphQLTypes["IntNullableWithAggregatesFilter"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["IntNullableWithAggregatesFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	adBoardIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["EnumAspectRatioTypeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["AspectRatioType"] | undefined,
	in?: Array<GraphQLTypes["AspectRatioType"]> | undefined,
	notIn?: Array<GraphQLTypes["AspectRatioType"]> | undefined,
	not?: GraphQLTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumAspectRatioTypeFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumAspectRatioTypeFilter"] | undefined
};
	["NestedEnumAspectRatioTypeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["AspectRatioType"] | undefined,
	in?: Array<GraphQLTypes["AspectRatioType"]> | undefined,
	notIn?: Array<GraphQLTypes["AspectRatioType"]> | undefined,
	not?: GraphQLTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumAspectRatioTypeFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumAspectRatioTypeFilter"] | undefined
};
	["AggregatePlaylist"]: {
	__typename: "AggregatePlaylist",
	_count?: GraphQLTypes["PlaylistCountAggregate"] | undefined,
	_min?: GraphQLTypes["PlaylistMinAggregate"] | undefined,
	_max?: GraphQLTypes["PlaylistMaxAggregate"] | undefined
};
	["PlaylistCountAggregate"]: {
	__typename: "PlaylistCountAggregate",
	id: number,
	name: number,
	displayName: number,
	isAdsPlaylist: number,
	footerPlaylistId: number,
	sidebarPlaylistId: number,
	layout: number,
	isDeployed: number,
	adBoardGroupIds: number,
	workspaceId: number,
	_all: number
};
	["PlaylistMinAggregate"]: {
	__typename: "PlaylistMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	displayName?: string | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	workspaceId?: string | undefined
};
	["PlaylistMaxAggregate"]: {
	__typename: "PlaylistMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	displayName?: string | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	workspaceId?: string | undefined
};
	["PlaylistGroupBy"]: {
	__typename: "PlaylistGroupBy",
	id: string,
	name: string,
	displayName?: string | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout: GraphQLTypes["ScreenLayout"],
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined,
	workspaceId: string,
	_count?: GraphQLTypes["PlaylistCountAggregate"] | undefined,
	_min?: GraphQLTypes["PlaylistMinAggregate"] | undefined,
	_max?: GraphQLTypes["PlaylistMaxAggregate"] | undefined
};
	["PlaylistOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	isAdsPlaylist?: GraphQLTypes["SortOrder"] | undefined,
	footerPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	layout?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroupIds?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["PlaylistCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["PlaylistMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["PlaylistMinOrderByAggregateInput"] | undefined
};
	["PlaylistCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	isAdsPlaylist?: GraphQLTypes["SortOrder"] | undefined,
	footerPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	layout?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	adBoardGroupIds?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["PlaylistMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	isAdsPlaylist?: GraphQLTypes["SortOrder"] | undefined,
	footerPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	layout?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["PlaylistMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	isAdsPlaylist?: GraphQLTypes["SortOrder"] | undefined,
	footerPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["SortOrder"] | undefined,
	layout?: GraphQLTypes["SortOrder"] | undefined,
	isDeployed?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["PlaylistScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlaylistScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	isAdsPlaylist?: GraphQLTypes["BoolNullableWithAggregatesFilter"] | undefined,
	footerPlaylistId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	layout?: GraphQLTypes["EnumScreenLayoutWithAggregatesFilter"] | undefined,
	isDeployed?: GraphQLTypes["BoolNullableWithAggregatesFilter"] | undefined,
	adBoardGroupIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined
};
	["EnumScreenLayoutWithAggregatesFilter"]: {
		equals?: GraphQLTypes["ScreenLayout"] | undefined,
	in?: Array<GraphQLTypes["ScreenLayout"]> | undefined,
	notIn?: Array<GraphQLTypes["ScreenLayout"]> | undefined,
	not?: GraphQLTypes["NestedEnumScreenLayoutWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumScreenLayoutFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumScreenLayoutFilter"] | undefined
};
	["NestedEnumScreenLayoutWithAggregatesFilter"]: {
		equals?: GraphQLTypes["ScreenLayout"] | undefined,
	in?: Array<GraphQLTypes["ScreenLayout"]> | undefined,
	notIn?: Array<GraphQLTypes["ScreenLayout"]> | undefined,
	not?: GraphQLTypes["NestedEnumScreenLayoutWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumScreenLayoutFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumScreenLayoutFilter"] | undefined
};
	["AggregateSlotConfiguration"]: {
	__typename: "AggregateSlotConfiguration",
	_count?: GraphQLTypes["SlotConfigurationCountAggregate"] | undefined,
	_avg?: GraphQLTypes["SlotConfigurationAvgAggregate"] | undefined,
	_sum?: GraphQLTypes["SlotConfigurationSumAggregate"] | undefined,
	_min?: GraphQLTypes["SlotConfigurationMinAggregate"] | undefined,
	_max?: GraphQLTypes["SlotConfigurationMaxAggregate"] | undefined
};
	["SlotConfigurationCountAggregate"]: {
	__typename: "SlotConfigurationCountAggregate",
	id: number,
	adBoardId: number,
	date: number,
	availableSlots: number,
	_all: number
};
	["SlotConfigurationAvgAggregate"]: {
	__typename: "SlotConfigurationAvgAggregate",
	availableSlots?: number | undefined
};
	["SlotConfigurationSumAggregate"]: {
	__typename: "SlotConfigurationSumAggregate",
	availableSlots?: number | undefined
};
	["SlotConfigurationMinAggregate"]: {
	__typename: "SlotConfigurationMinAggregate",
	id?: string | undefined,
	adBoardId?: string | undefined,
	date?: GraphQLTypes["DateTime"] | undefined,
	availableSlots?: number | undefined
};
	["SlotConfigurationMaxAggregate"]: {
	__typename: "SlotConfigurationMaxAggregate",
	id?: string | undefined,
	adBoardId?: string | undefined,
	date?: GraphQLTypes["DateTime"] | undefined,
	availableSlots?: number | undefined
};
	["SlotConfigurationGroupBy"]: {
	__typename: "SlotConfigurationGroupBy",
	id: string,
	adBoardId: string,
	date: GraphQLTypes["DateTime"],
	availableSlots: number,
	_count?: GraphQLTypes["SlotConfigurationCountAggregate"] | undefined,
	_avg?: GraphQLTypes["SlotConfigurationAvgAggregate"] | undefined,
	_sum?: GraphQLTypes["SlotConfigurationSumAggregate"] | undefined,
	_min?: GraphQLTypes["SlotConfigurationMinAggregate"] | undefined,
	_max?: GraphQLTypes["SlotConfigurationMaxAggregate"] | undefined
};
	["SlotConfigurationOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	date?: GraphQLTypes["SortOrder"] | undefined,
	availableSlots?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["SlotConfigurationCountOrderByAggregateInput"] | undefined,
	_avg?: GraphQLTypes["SlotConfigurationAvgOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["SlotConfigurationMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["SlotConfigurationMinOrderByAggregateInput"] | undefined,
	_sum?: GraphQLTypes["SlotConfigurationSumOrderByAggregateInput"] | undefined
};
	["SlotConfigurationCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	date?: GraphQLTypes["SortOrder"] | undefined,
	availableSlots?: GraphQLTypes["SortOrder"] | undefined
};
	["SlotConfigurationAvgOrderByAggregateInput"]: {
		availableSlots?: GraphQLTypes["SortOrder"] | undefined
};
	["SlotConfigurationMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	date?: GraphQLTypes["SortOrder"] | undefined,
	availableSlots?: GraphQLTypes["SortOrder"] | undefined
};
	["SlotConfigurationMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	adBoardId?: GraphQLTypes["SortOrder"] | undefined,
	date?: GraphQLTypes["SortOrder"] | undefined,
	availableSlots?: GraphQLTypes["SortOrder"] | undefined
};
	["SlotConfigurationSumOrderByAggregateInput"]: {
		availableSlots?: GraphQLTypes["SortOrder"] | undefined
};
	["SlotConfigurationScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SlotConfigurationScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	date?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	availableSlots?: GraphQLTypes["IntWithAggregatesFilter"] | undefined
};
	["AggregateSubscription"]: {
	__typename: "AggregateSubscription",
	_count?: GraphQLTypes["SubscriptionCountAggregate"] | undefined,
	_min?: GraphQLTypes["SubscriptionMinAggregate"] | undefined,
	_max?: GraphQLTypes["SubscriptionMaxAggregate"] | undefined
};
	["SubscriptionCountAggregate"]: {
	__typename: "SubscriptionCountAggregate",
	id: number,
	packageId: number,
	packageName: number,
	priceLookupKey: number,
	startDate: number,
	endDate: number,
	status: number,
	stripePaymentIntentId: number,
	stripeSubscriptionId: number,
	stripeCheckoutSessionId: number,
	activeWorkspaceId: number,
	_all: number
};
	["SubscriptionMinAggregate"]: {
	__typename: "SubscriptionMinAggregate",
	id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	activeWorkspaceId?: string | undefined
};
	["SubscriptionMaxAggregate"]: {
	__typename: "SubscriptionMaxAggregate",
	id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	activeWorkspaceId?: string | undefined
};
	["SubscriptionWhereUniqueInput"]: {
		id?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	activeWorkspaceId?: string | undefined,
	AND?: Array<GraphQLTypes["SubscriptionWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["SubscriptionWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SubscriptionWhereInput"]> | undefined,
	packageId?: GraphQLTypes["StringNullableFilter"] | undefined,
	packageName?: GraphQLTypes["StringNullableFilter"] | undefined,
	priceLookupKey?: GraphQLTypes["StringNullableFilter"] | undefined,
	startDate?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	endDate?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	status?: GraphQLTypes["StringFilter"] | undefined,
	stripePaymentIntentId?: GraphQLTypes["StringNullableFilter"] | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsNullableCompositeFilter"] | undefined,
	activeWorkspace?: GraphQLTypes["WorkspaceRelationFilter"] | undefined
};
	["SubscriptionScalarFieldEnum"]: SubscriptionScalarFieldEnum;
	["SubscriptionGroupBy"]: {
	__typename: "SubscriptionGroupBy",
	id: string,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status: string,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId: string,
	stripeCheckoutSessionId: string,
	activeWorkspaceId: string,
	_count?: GraphQLTypes["SubscriptionCountAggregate"] | undefined,
	_min?: GraphQLTypes["SubscriptionMinAggregate"] | undefined,
	_max?: GraphQLTypes["SubscriptionMaxAggregate"] | undefined
};
	["SubscriptionOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	packageId?: GraphQLTypes["SortOrder"] | undefined,
	packageName?: GraphQLTypes["SortOrder"] | undefined,
	priceLookupKey?: GraphQLTypes["SortOrder"] | undefined,
	startDate?: GraphQLTypes["SortOrder"] | undefined,
	endDate?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: GraphQLTypes["SortOrder"] | undefined,
	activeWorkspaceId?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["SubscriptionCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["SubscriptionMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["SubscriptionMinOrderByAggregateInput"] | undefined
};
	["SubscriptionCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	packageId?: GraphQLTypes["SortOrder"] | undefined,
	packageName?: GraphQLTypes["SortOrder"] | undefined,
	priceLookupKey?: GraphQLTypes["SortOrder"] | undefined,
	startDate?: GraphQLTypes["SortOrder"] | undefined,
	endDate?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: GraphQLTypes["SortOrder"] | undefined,
	activeWorkspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["SubscriptionMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	packageId?: GraphQLTypes["SortOrder"] | undefined,
	packageName?: GraphQLTypes["SortOrder"] | undefined,
	priceLookupKey?: GraphQLTypes["SortOrder"] | undefined,
	startDate?: GraphQLTypes["SortOrder"] | undefined,
	endDate?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: GraphQLTypes["SortOrder"] | undefined,
	activeWorkspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["SubscriptionMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	packageId?: GraphQLTypes["SortOrder"] | undefined,
	packageName?: GraphQLTypes["SortOrder"] | undefined,
	priceLookupKey?: GraphQLTypes["SortOrder"] | undefined,
	startDate?: GraphQLTypes["SortOrder"] | undefined,
	endDate?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	stripePaymentIntentId?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined,
	stripeCheckoutSessionId?: GraphQLTypes["SortOrder"] | undefined,
	activeWorkspaceId?: GraphQLTypes["SortOrder"] | undefined
};
	["SubscriptionScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SubscriptionScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	packageId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	packageName?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	priceLookupKey?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	startDate?: GraphQLTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	endDate?: GraphQLTypes["DateTimeNullableWithAggregatesFilter"] | undefined,
	status?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	stripePaymentIntentId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	stripeCheckoutSessionId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	activeWorkspaceId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined
};
	["AggregateUser"]: {
	__typename: "AggregateUser",
	_count?: GraphQLTypes["UserCountAggregate"] | undefined,
	_min?: GraphQLTypes["UserMinAggregate"] | undefined,
	_max?: GraphQLTypes["UserMaxAggregate"] | undefined
};
	["UserCountAggregate"]: {
	__typename: "UserCountAggregate",
	id: number,
	uid: number,
	email: number,
	image: number,
	phoneNumber: number,
	displayName: number,
	otherDetails: number,
	roles: number,
	sharedBookingIds: number,
	disclaimerAccepted: number,
	leadWorkspaceIds: number,
	_all: number
};
	["UserMinAggregate"]: {
	__typename: "UserMinAggregate",
	id?: string | undefined,
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	disclaimerAccepted?: boolean | undefined
};
	["UserMaxAggregate"]: {
	__typename: "UserMaxAggregate",
	id?: string | undefined,
	uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	disclaimerAccepted?: boolean | undefined
};
	["UserGroupBy"]: {
	__typename: "UserGroupBy",
	id: string,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	sharedBookingIds?: Array<string> | undefined,
	disclaimerAccepted: boolean,
	leadWorkspaceIds?: Array<string> | undefined,
	_count?: GraphQLTypes["UserCountAggregate"] | undefined,
	_min?: GraphQLTypes["UserMinAggregate"] | undefined,
	_max?: GraphQLTypes["UserMaxAggregate"] | undefined
};
	["UserOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	uid?: GraphQLTypes["SortOrder"] | undefined,
	email?: GraphQLTypes["SortOrder"] | undefined,
	image?: GraphQLTypes["SortOrder"] | undefined,
	phoneNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	otherDetails?: GraphQLTypes["SortOrder"] | undefined,
	roles?: GraphQLTypes["SortOrder"] | undefined,
	sharedBookingIds?: GraphQLTypes["SortOrder"] | undefined,
	disclaimerAccepted?: GraphQLTypes["SortOrder"] | undefined,
	leadWorkspaceIds?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["UserCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["UserMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["UserMinOrderByAggregateInput"] | undefined
};
	["UserCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	uid?: GraphQLTypes["SortOrder"] | undefined,
	email?: GraphQLTypes["SortOrder"] | undefined,
	image?: GraphQLTypes["SortOrder"] | undefined,
	phoneNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	otherDetails?: GraphQLTypes["SortOrder"] | undefined,
	roles?: GraphQLTypes["SortOrder"] | undefined,
	sharedBookingIds?: GraphQLTypes["SortOrder"] | undefined,
	disclaimerAccepted?: GraphQLTypes["SortOrder"] | undefined,
	leadWorkspaceIds?: GraphQLTypes["SortOrder"] | undefined
};
	["UserMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	uid?: GraphQLTypes["SortOrder"] | undefined,
	email?: GraphQLTypes["SortOrder"] | undefined,
	image?: GraphQLTypes["SortOrder"] | undefined,
	phoneNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	disclaimerAccepted?: GraphQLTypes["SortOrder"] | undefined
};
	["UserMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	uid?: GraphQLTypes["SortOrder"] | undefined,
	email?: GraphQLTypes["SortOrder"] | undefined,
	image?: GraphQLTypes["SortOrder"] | undefined,
	phoneNumber?: GraphQLTypes["SortOrder"] | undefined,
	displayName?: GraphQLTypes["SortOrder"] | undefined,
	disclaimerAccepted?: GraphQLTypes["SortOrder"] | undefined
};
	["UserScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["UserScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	uid?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	email?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	image?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	phoneNumber?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	otherDetails?: GraphQLTypes["JsonNullableWithAggregatesFilter"] | undefined,
	roles?: GraphQLTypes["EnumRoleNullableListFilter"] | undefined,
	sharedBookingIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	disclaimerAccepted?: GraphQLTypes["BoolWithAggregatesFilter"] | undefined,
	leadWorkspaceIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["AggregateUserPosition"]: {
	__typename: "AggregateUserPosition",
	_count?: GraphQLTypes["UserPositionCountAggregate"] | undefined,
	_min?: GraphQLTypes["UserPositionMinAggregate"] | undefined,
	_max?: GraphQLTypes["UserPositionMaxAggregate"] | undefined
};
	["UserPositionCountAggregate"]: {
	__typename: "UserPositionCountAggregate",
	id: number,
	timestamp: number,
	createdAt: number,
	userId: number,
	_all: number
};
	["UserPositionMinAggregate"]: {
	__typename: "UserPositionMinAggregate",
	id?: string | undefined,
	timestamp?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	userId?: string | undefined
};
	["UserPositionMaxAggregate"]: {
	__typename: "UserPositionMaxAggregate",
	id?: string | undefined,
	timestamp?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	userId?: string | undefined
};
	["UserPositionGroupBy"]: {
	__typename: "UserPositionGroupBy",
	id: string,
	timestamp: GraphQLTypes["DateTime"],
	createdAt: GraphQLTypes["DateTime"],
	userId: string,
	_count?: GraphQLTypes["UserPositionCountAggregate"] | undefined,
	_min?: GraphQLTypes["UserPositionMinAggregate"] | undefined,
	_max?: GraphQLTypes["UserPositionMaxAggregate"] | undefined
};
	["UserPositionOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["UserPositionCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["UserPositionMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["UserPositionMinOrderByAggregateInput"] | undefined
};
	["UserPositionCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined
};
	["UserPositionMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined
};
	["UserPositionMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	timestamp?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	userId?: GraphQLTypes["SortOrder"] | undefined
};
	["UserPositionScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserPositionScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	timestamp?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	userId?: GraphQLTypes["StringWithAggregatesFilter"] | undefined
};
	["AggregateWorkspace"]: {
	__typename: "AggregateWorkspace",
	_count?: GraphQLTypes["WorkspaceCountAggregate"] | undefined,
	_min?: GraphQLTypes["WorkspaceMinAggregate"] | undefined,
	_max?: GraphQLTypes["WorkspaceMaxAggregate"] | undefined
};
	["WorkspaceCountAggregate"]: {
	__typename: "WorkspaceCountAggregate",
	id: number,
	type: number,
	name: number,
	key: number,
	userIds: number,
	ownerId: number,
	avatar: number,
	createdAt: number,
	updatedAt: number,
	stripeSubscriptionId: number,
	leadIds: number,
	_all: number
};
	["WorkspaceMinAggregate"]: {
	__typename: "WorkspaceMinAggregate",
	id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined
};
	["WorkspaceMaxAggregate"]: {
	__typename: "WorkspaceMaxAggregate",
	id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined
};
	["WorkspaceGroupBy"]: {
	__typename: "WorkspaceGroupBy",
	id: string,
	type: GraphQLTypes["WorkspaceType"],
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	stripeSubscriptionId?: string | undefined,
	leadIds?: Array<string> | undefined,
	_count?: GraphQLTypes["WorkspaceCountAggregate"] | undefined,
	_min?: GraphQLTypes["WorkspaceMinAggregate"] | undefined,
	_max?: GraphQLTypes["WorkspaceMaxAggregate"] | undefined
};
	["WorkspaceOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	key?: GraphQLTypes["SortOrder"] | undefined,
	userIds?: GraphQLTypes["SortOrder"] | undefined,
	ownerId?: GraphQLTypes["SortOrder"] | undefined,
	avatar?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined,
	leadIds?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["WorkspaceCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["WorkspaceMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["WorkspaceMinOrderByAggregateInput"] | undefined
};
	["WorkspaceCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	key?: GraphQLTypes["SortOrder"] | undefined,
	userIds?: GraphQLTypes["SortOrder"] | undefined,
	ownerId?: GraphQLTypes["SortOrder"] | undefined,
	avatar?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined,
	leadIds?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	key?: GraphQLTypes["SortOrder"] | undefined,
	ownerId?: GraphQLTypes["SortOrder"] | undefined,
	avatar?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	type?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	key?: GraphQLTypes["SortOrder"] | undefined,
	ownerId?: GraphQLTypes["SortOrder"] | undefined,
	avatar?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WorkspaceScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	type?: GraphQLTypes["EnumWorkspaceTypeWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	key?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	userIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	ownerId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	avatar?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	leadIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["EnumWorkspaceTypeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["WorkspaceType"] | undefined,
	in?: Array<GraphQLTypes["WorkspaceType"]> | undefined,
	notIn?: Array<GraphQLTypes["WorkspaceType"]> | undefined,
	not?: GraphQLTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumWorkspaceTypeFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumWorkspaceTypeFilter"] | undefined
};
	["NestedEnumWorkspaceTypeWithAggregatesFilter"]: {
		equals?: GraphQLTypes["WorkspaceType"] | undefined,
	in?: Array<GraphQLTypes["WorkspaceType"]> | undefined,
	notIn?: Array<GraphQLTypes["WorkspaceType"]> | undefined,
	not?: GraphQLTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"] | undefined,
	_count?: GraphQLTypes["NestedIntFilter"] | undefined,
	_min?: GraphQLTypes["NestedEnumWorkspaceTypeFilter"] | undefined,
	_max?: GraphQLTypes["NestedEnumWorkspaceTypeFilter"] | undefined
};
	["AggregateWorkspaceRole"]: {
	__typename: "AggregateWorkspaceRole",
	_count?: GraphQLTypes["WorkspaceRoleCountAggregate"] | undefined,
	_min?: GraphQLTypes["WorkspaceRoleMinAggregate"] | undefined,
	_max?: GraphQLTypes["WorkspaceRoleMaxAggregate"] | undefined
};
	["WorkspaceRoleCountAggregate"]: {
	__typename: "WorkspaceRoleCountAggregate",
	id: number,
	name: number,
	permissions: number,
	workspaceId: number,
	createdAt: number,
	updatedAt: number,
	_all: number
};
	["WorkspaceRoleMinAggregate"]: {
	__typename: "WorkspaceRoleMinAggregate",
	id?: string | undefined,
	name?: string | undefined,
	workspaceId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["WorkspaceRoleMaxAggregate"]: {
	__typename: "WorkspaceRoleMaxAggregate",
	id?: string | undefined,
	name?: string | undefined,
	workspaceId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["WorkspaceRoleGroupBy"]: {
	__typename: "WorkspaceRoleGroupBy",
	id: string,
	name: string,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	workspaceId?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	_count?: GraphQLTypes["WorkspaceRoleCountAggregate"] | undefined,
	_min?: GraphQLTypes["WorkspaceRoleMinAggregate"] | undefined,
	_max?: GraphQLTypes["WorkspaceRoleMaxAggregate"] | undefined
};
	["WorkspaceRoleOrderByWithAggregationInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	permissions?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	_count?: GraphQLTypes["WorkspaceRoleCountOrderByAggregateInput"] | undefined,
	_max?: GraphQLTypes["WorkspaceRoleMaxOrderByAggregateInput"] | undefined,
	_min?: GraphQLTypes["WorkspaceRoleMinOrderByAggregateInput"] | undefined
};
	["WorkspaceRoleCountOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	permissions?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceRoleMaxOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceRoleMinOrderByAggregateInput"]: {
		id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	workspaceId?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["WorkspaceRoleScalarWhereWithAggregatesInput"]: {
		AND?: Array<GraphQLTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined,
	OR?: Array<GraphQLTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WorkspaceRoleScalarWhereWithAggregatesInput"]> | undefined,
	id?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	name?: GraphQLTypes["StringWithAggregatesFilter"] | undefined,
	permissions?: GraphQLTypes["EnumPermissionNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableWithAggregatesFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeWithAggregatesFilter"] | undefined
};
	["Slot"]: {
	__typename: "Slot",
	date: string,
	slots: number
};
	["Mutation"]: {
	__typename: "Mutation",
	createManyAdBoard: GraphQLTypes["AffectedRowsOutput"],
	createOneAdBoard: GraphQLTypes["AdBoard"],
	deleteManyAdBoard: GraphQLTypes["AffectedRowsOutput"],
	deleteOneAdBoard?: GraphQLTypes["AdBoard"] | undefined,
	updateManyAdBoard: GraphQLTypes["AffectedRowsOutput"],
	updateOneAdBoard?: GraphQLTypes["AdBoard"] | undefined,
	upsertOneAdBoard: GraphQLTypes["AdBoard"],
	createManyAdBoardGroup: GraphQLTypes["AffectedRowsOutput"],
	createOneAdBoardGroup: GraphQLTypes["AdBoardGroup"],
	deleteManyAdBoardGroup: GraphQLTypes["AffectedRowsOutput"],
	deleteOneAdBoardGroup?: GraphQLTypes["AdBoardGroup"] | undefined,
	updateManyAdBoardGroup: GraphQLTypes["AffectedRowsOutput"],
	updateOneAdBoardGroup?: GraphQLTypes["AdBoardGroup"] | undefined,
	upsertOneAdBoardGroup: GraphQLTypes["AdBoardGroup"],
	createManyAdBoardType: GraphQLTypes["AffectedRowsOutput"],
	createOneAdBoardType: GraphQLTypes["AdBoardType"],
	deleteManyAdBoardType: GraphQLTypes["AffectedRowsOutput"],
	deleteOneAdBoardType?: GraphQLTypes["AdBoardType"] | undefined,
	updateManyAdBoardType: GraphQLTypes["AffectedRowsOutput"],
	updateOneAdBoardType?: GraphQLTypes["AdBoardType"] | undefined,
	upsertOneAdBoardType: GraphQLTypes["AdBoardType"],
	createManyAdvertisementCategory: GraphQLTypes["AffectedRowsOutput"],
	createOneAdvertisementCategory: GraphQLTypes["AdvertisementCategory"],
	deleteManyAdvertisementCategory: GraphQLTypes["AffectedRowsOutput"],
	deleteOneAdvertisementCategory?: GraphQLTypes["AdvertisementCategory"] | undefined,
	updateManyAdvertisementCategory: GraphQLTypes["AffectedRowsOutput"],
	updateOneAdvertisementCategory?: GraphQLTypes["AdvertisementCategory"] | undefined,
	upsertOneAdvertisementCategory: GraphQLTypes["AdvertisementCategory"],
	createManyAudit: GraphQLTypes["AffectedRowsOutput"],
	createOneAudit: GraphQLTypes["Audit"],
	deleteManyAudit: GraphQLTypes["AffectedRowsOutput"],
	deleteOneAudit?: GraphQLTypes["Audit"] | undefined,
	updateManyAudit: GraphQLTypes["AffectedRowsOutput"],
	updateOneAudit?: GraphQLTypes["Audit"] | undefined,
	upsertOneAudit: GraphQLTypes["Audit"],
	createManyBooking: GraphQLTypes["AffectedRowsOutput"],
	createOneBooking: GraphQLTypes["Booking"],
	deleteManyBooking: GraphQLTypes["AffectedRowsOutput"],
	deleteOneBooking?: GraphQLTypes["Booking"] | undefined,
	updateManyBooking: GraphQLTypes["AffectedRowsOutput"],
	updateOneBooking?: GraphQLTypes["Booking"] | undefined,
	upsertOneBooking: GraphQLTypes["Booking"],
	createManyBusinessCategory: GraphQLTypes["AffectedRowsOutput"],
	createOneBusinessCategory: GraphQLTypes["BusinessCategory"],
	deleteManyBusinessCategory: GraphQLTypes["AffectedRowsOutput"],
	deleteOneBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	updateManyBusinessCategory: GraphQLTypes["AffectedRowsOutput"],
	updateOneBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	upsertOneBusinessCategory: GraphQLTypes["BusinessCategory"],
	createManyCamera: GraphQLTypes["AffectedRowsOutput"],
	createOneCamera: GraphQLTypes["Camera"],
	deleteManyCamera: GraphQLTypes["AffectedRowsOutput"],
	deleteOneCamera?: GraphQLTypes["Camera"] | undefined,
	updateManyCamera: GraphQLTypes["AffectedRowsOutput"],
	updateOneCamera?: GraphQLTypes["Camera"] | undefined,
	upsertOneCamera: GraphQLTypes["Camera"],
	createManyCameraFeed: GraphQLTypes["AffectedRowsOutput"],
	createOneCameraFeed: GraphQLTypes["CameraFeed"],
	deleteManyCameraFeed: GraphQLTypes["AffectedRowsOutput"],
	deleteOneCameraFeed?: GraphQLTypes["CameraFeed"] | undefined,
	updateManyCameraFeed: GraphQLTypes["AffectedRowsOutput"],
	updateOneCameraFeed?: GraphQLTypes["CameraFeed"] | undefined,
	upsertOneCameraFeed: GraphQLTypes["CameraFeed"],
	createManyCameraServer: GraphQLTypes["AffectedRowsOutput"],
	createOneCameraServer: GraphQLTypes["CameraServer"],
	deleteManyCameraServer: GraphQLTypes["AffectedRowsOutput"],
	deleteOneCameraServer?: GraphQLTypes["CameraServer"] | undefined,
	updateManyCameraServer: GraphQLTypes["AffectedRowsOutput"],
	updateOneCameraServer?: GraphQLTypes["CameraServer"] | undefined,
	upsertOneCameraServer: GraphQLTypes["CameraServer"],
	createManyCampaign: GraphQLTypes["AffectedRowsOutput"],
	createOneCampaign: GraphQLTypes["Campaign"],
	deleteManyCampaign: GraphQLTypes["AffectedRowsOutput"],
	deleteOneCampaign?: GraphQLTypes["Campaign"] | undefined,
	updateManyCampaign: GraphQLTypes["AffectedRowsOutput"],
	updateOneCampaign?: GraphQLTypes["Campaign"] | undefined,
	upsertOneCampaign: GraphQLTypes["Campaign"],
	createManyFile: GraphQLTypes["AffectedRowsOutput"],
	createOneFile: GraphQLTypes["File"],
	deleteManyFile: GraphQLTypes["AffectedRowsOutput"],
	deleteOneFile?: GraphQLTypes["File"] | undefined,
	updateManyFile: GraphQLTypes["AffectedRowsOutput"],
	updateOneFile?: GraphQLTypes["File"] | undefined,
	upsertOneFile: GraphQLTypes["File"],
	createManyMembership: GraphQLTypes["AffectedRowsOutput"],
	createOneMembership?: GraphQLTypes["Membership"] | undefined,
	deleteManyMembership: GraphQLTypes["AffectedRowsOutput"],
	deleteOneMembership?: GraphQLTypes["Membership"] | undefined,
	updateManyMembership: GraphQLTypes["AffectedRowsOutput"],
	updateOneMembership?: GraphQLTypes["Membership"] | undefined,
	upsertOneMembership: GraphQLTypes["Membership"],
	createManyNotification: GraphQLTypes["AffectedRowsOutput"],
	createOneNotification: GraphQLTypes["Notification"],
	deleteManyNotification: GraphQLTypes["AffectedRowsOutput"],
	deleteOneNotification?: GraphQLTypes["Notification"] | undefined,
	updateManyNotification: GraphQLTypes["AffectedRowsOutput"],
	updateOneNotification?: GraphQLTypes["Notification"] | undefined,
	upsertOneNotification: GraphQLTypes["Notification"],
	createManyOrder: GraphQLTypes["AffectedRowsOutput"],
	createOneOrder: GraphQLTypes["Order"],
	deleteManyOrder: GraphQLTypes["AffectedRowsOutput"],
	deleteOneOrder?: GraphQLTypes["Order"] | undefined,
	updateManyOrder: GraphQLTypes["AffectedRowsOutput"],
	updateOneOrder?: GraphQLTypes["Order"] | undefined,
	upsertOneOrder: GraphQLTypes["Order"],
	createManyPlayer: GraphQLTypes["AffectedRowsOutput"],
	createOnePlayer: GraphQLTypes["Player"],
	deleteManyPlayer: GraphQLTypes["AffectedRowsOutput"],
	deleteOnePlayer?: GraphQLTypes["Player"] | undefined,
	updateManyPlayer: GraphQLTypes["AffectedRowsOutput"],
	updateOnePlayer?: GraphQLTypes["Player"] | undefined,
	upsertOnePlayer: GraphQLTypes["Player"],
	createManyPlaylist: GraphQLTypes["AffectedRowsOutput"],
	createOnePlaylist: GraphQLTypes["Playlist"],
	deleteManyPlaylist: GraphQLTypes["AffectedRowsOutput"],
	deleteOnePlaylist?: GraphQLTypes["Playlist"] | undefined,
	updateManyPlaylist: GraphQLTypes["AffectedRowsOutput"],
	updateOnePlaylist: GraphQLTypes["Playlist"],
	upsertOnePlaylist: GraphQLTypes["Playlist"],
	createManySlotConfiguration: GraphQLTypes["AffectedRowsOutput"],
	createOneSlotConfiguration: GraphQLTypes["SlotConfiguration"],
	deleteManySlotConfiguration: GraphQLTypes["AffectedRowsOutput"],
	deleteOneSlotConfiguration?: GraphQLTypes["SlotConfiguration"] | undefined,
	updateManySlotConfiguration: GraphQLTypes["AffectedRowsOutput"],
	updateOneSlotConfiguration?: GraphQLTypes["SlotConfiguration"] | undefined,
	upsertOneSlotConfiguration: GraphQLTypes["SlotConfiguration"],
	createManySubscription: GraphQLTypes["AffectedRowsOutput"],
	createOneSubscription: GraphQLTypes["Subscription"],
	deleteManySubscription: GraphQLTypes["AffectedRowsOutput"],
	deleteOneSubscription?: GraphQLTypes["Subscription"] | undefined,
	updateManySubscription: GraphQLTypes["AffectedRowsOutput"],
	updateOneSubscription?: GraphQLTypes["Subscription"] | undefined,
	upsertOneSubscription: GraphQLTypes["Subscription"],
	createManyUser: GraphQLTypes["AffectedRowsOutput"],
	createOneUser: GraphQLTypes["User"],
	deleteManyUser: GraphQLTypes["AffectedRowsOutput"],
	deleteOneUser?: GraphQLTypes["User"] | undefined,
	updateManyUser: GraphQLTypes["AffectedRowsOutput"],
	updateOneUser?: GraphQLTypes["User"] | undefined,
	upsertOneUser: GraphQLTypes["User"],
	createManyUserPosition: GraphQLTypes["AffectedRowsOutput"],
	createOneUserPosition: GraphQLTypes["UserPosition"],
	deleteManyUserPosition: GraphQLTypes["AffectedRowsOutput"],
	deleteOneUserPosition?: GraphQLTypes["UserPosition"] | undefined,
	updateManyUserPosition: GraphQLTypes["AffectedRowsOutput"],
	updateOneUserPosition?: GraphQLTypes["UserPosition"] | undefined,
	upsertOneUserPosition: GraphQLTypes["UserPosition"],
	createManyWorkspace: GraphQLTypes["AffectedRowsOutput"],
	createOneWorkspace: GraphQLTypes["Workspace"],
	deleteManyWorkspace: GraphQLTypes["AffectedRowsOutput"],
	deleteOneWorkspace?: GraphQLTypes["Workspace"] | undefined,
	updateManyWorkspace: GraphQLTypes["AffectedRowsOutput"],
	updateOneWorkspace?: GraphQLTypes["Workspace"] | undefined,
	upsertOneWorkspace: GraphQLTypes["Workspace"],
	createManyWorkspaceRole: GraphQLTypes["AffectedRowsOutput"],
	createOneWorkspaceRole: GraphQLTypes["WorkspaceRole"],
	deleteManyWorkspaceRole: GraphQLTypes["AffectedRowsOutput"],
	deleteOneWorkspaceRole?: GraphQLTypes["WorkspaceRole"] | undefined,
	updateManyWorkspaceRole: GraphQLTypes["AffectedRowsOutput"],
	updateOneWorkspaceRole?: GraphQLTypes["WorkspaceRole"] | undefined,
	upsertOneWorkspaceRole: GraphQLTypes["WorkspaceRole"],
	updateUser?: GraphQLTypes["User"] | undefined,
	createCustomUser?: GraphQLTypes["User"] | undefined,
	deleteUserCustom?: GraphQLTypes["GenericResponse"] | undefined,
	sendMail?: GraphQLTypes["EmailOutput"] | undefined,
	createOneCampaignBooking: GraphQLTypes["Campaign"],
	createOneAdboardPublicReview: GraphQLTypes["AdBoardPublicReview"],
	refreshSubscription: GraphQLTypes["TypedGenericResponse"],
	cancelSubscription: GraphQLTypes["TypedGenericResponse"],
	updateBookingStatus: GraphQLTypes["Booking"],
	updateBookingAsset: boolean,
	prepareBooking: GraphQLTypes["Booking"],
	cancelBooking: GraphQLTypes["Booking"],
	deployBooking: GraphQLTypes["Booking"],
	updateDraft: GraphQLTypes["AdBoard"],
	deleteDraft: boolean,
	addReviewComment: GraphQLTypes["AdBoardReviewComment"],
	updateAdBoardStatus: GraphQLTypes["AdBoard"],
	publishApprovedDraft: GraphQLTypes["AdBoard"],
	addPlayersToAdboard: GraphQLTypes["AdBoard"],
	createOneAdboardWithGroup: GraphQLTypes["AdBoard"],
	deleteOneAdboardWithGroup: GraphQLTypes["AdBoard"],
	updateOneScheduler?: GraphQLTypes["AdBoardGroup"] | undefined,
	setupOnePlayer: GraphQLTypes["Player"],
	updatePlayerCode: GraphQLTypes["Player"],
	addPlaylistToPlayer: GraphQLTypes["Player"],
	deletePlaylistInPlayer: GraphQLTypes["Player"]
};
	["AffectedRowsOutput"]: {
	__typename: "AffectedRowsOutput",
	count: number
};
	["AdBoardCreateManyInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["MapCreateInput"]: {
		lat: number,
	lng: number,
	address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["SpecificationCreateInput"]: {
		name: string,
	value?: string | undefined
};
	["ResolutionCreateInput"]: {
		height: number,
	width: number
};
	["ScreenSizeCreateInput"]: {
		height: number,
	unit: GraphQLTypes["MeasurementUnit"],
	width: number
};
	["PlaybackTimeCreateInput"]: {
		name?: string | undefined,
	startTime: string,
	endTime: string
};
	["WebhookCreateInput"]: {
		url: string,
	headers?: GraphQLTypes["JSON"] | undefined,
	body?: GraphQLTypes["JSON"] | undefined,
	method: string
};
	["AdBoardCreateInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["LocationCategoryCreateNestedOneWithoutAdBoardsInput"]: {
		create?: GraphQLTypes["LocationCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: GraphQLTypes["LocationCategoryWhereUniqueInput"] | undefined
};
	["LocationCategoryCreateWithoutAdBoardsInput"]: {
		id?: string | undefined,
	name: string,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"] | undefined
};
	["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"]: {
		create?: Array<GraphQLTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["LocationSubCategoryWhereUniqueInput"]> | undefined
};
	["LocationSubCategoryCreateWithoutLocationCategoryInput"]: {
		id?: string | undefined,
	name: string,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutLocationSubCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutLocationSubCategoryInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"]: {
		create?: GraphQLTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardTypeWhereUniqueInput"] | undefined
};
	["AdBoardTypeCreateWithoutAdBoardsInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined
};
	["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"]: {
		where: GraphQLTypes["AdBoardTypeWhereUniqueInput"],
	create: GraphQLTypes["AdBoardTypeCreateWithoutAdBoardsInput"]
};
	["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"]: {
		create?: GraphQLTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: GraphQLTypes["BusinessCategoryWhereUniqueInput"] | undefined
};
	["BusinessCategoryCreateWithoutAdBoardsInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutRestrictedBusinesInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutRestrictedBusinesInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"]: {
		create?: GraphQLTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: GraphQLTypes["LocationSubCategoryWhereUniqueInput"] | undefined
};
	["LocationSubCategoryCreateWithoutAdBoardsInput"]: {
		id?: string | undefined,
	name: string,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"] | undefined
};
	["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"]: {
		create?: GraphQLTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | undefined,
	connectOrCreate?: GraphQLTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"] | undefined,
	connect?: GraphQLTypes["LocationCategoryWhereUniqueInput"] | undefined
};
	["LocationCategoryCreateWithoutLocationSubCategoryInput"]: {
		id?: string | undefined,
	name: string,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutLocationCategoryInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutLocationCategoryInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutLocationCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyLocationCategoryInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutLocationCategoryInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"]: {
		create?: Array<GraphQLTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]> | undefined,
	connect?: Array<GraphQLTypes["BusinessCategoryWhereUniqueInput"]> | undefined
};
	["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutCategoryInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyCategoryInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutCategoryInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["BookingCreateNestedManyWithoutAdBoardInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutAdBoardInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["BookingDetailsCreateInput"]: {
		additionalInformation?: string | undefined,
	email: string,
	name: string,
	phone?: string | undefined
};
	["OrderSelectedDateCreateInput"]: {
		endDate: GraphQLTypes["DateTime"],
	startDate: GraphQLTypes["DateTime"]
};
	["BookingPaymentCreateInput"]: {
		liveMode?: boolean | undefined,
	checkoutSessionId?: string | undefined,
	customer?: GraphQLTypes["BookingCustomerCreateInput"] | undefined,
	paymentGateway: GraphQLTypes["PaymentGatewayName"],
	paymentId: string,
	sessionId?: string | undefined,
	totalDetails?: GraphQLTypes["PaymentTotalDetailsCreateInput"] | undefined,
	razorpay?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["JSON"] | undefined
};
	["BookingCustomerCreateInput"]: {
		customerId: string,
	customerDetails?: GraphQLTypes["PaymentCustomerDetailsCreateInput"] | undefined
};
	["PaymentCustomerDetailsCreateInput"]: {
		email: string,
	name: string,
	phone?: string | undefined
};
	["PaymentTotalDetailsCreateInput"]: {
		discount: number,
	shipping: number,
	subtotal: number,
	tax: number,
	total: number
};
	["PriceSummaryCreateInput"]: {
		days: number,
	discount?: number | undefined,
	payableRent: number,
	rentPerDay: number,
	serviceCharge: number,
	totalRent: number
};
	["BookingPisignageDataCreateInput"]: {
		deployedFileName?: string | undefined,
	playlistId?: string | undefined
};
	["BookingRefundCreateInput"]: {
		id: string,
	razorpay?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["JSON"] | undefined,
	createdByUserId: string
};
	["FileCreateNestedManyWithoutBookingsInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutBookingsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutBookingsInput"]> | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutBookingsInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: GraphQLTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: GraphQLTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["FileMetaCreateInput"]: {
		duration: number,
	height: number,
	width: number
};
	["UserCreateNestedOneWithoutUpdatedFilesInput"]: {
		create?: GraphQLTypes["UserCreateWithoutUpdatedFilesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutUpdatedFilesInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutUpdatedFilesInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["UserStripeCreateInput"]: {
		customerId: string
};
	["NotificationPreferenceCreateInput"]: {
		type?: Array<GraphQLTypes["NotificationType"]> | undefined,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined,
	pushSetting?: GraphQLTypes["PushSettingCreateInput"] | undefined,
	enabled?: boolean | undefined
};
	["PushSettingCreateInput"]: {
		fcmToken: string
};
	["BookingCreateNestedManyWithoutUserInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutUserInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["BusinessCategoryCreateNestedOneWithoutBookingsInput"]: {
		create?: GraphQLTypes["BusinessCategoryCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: GraphQLTypes["BusinessCategoryWhereUniqueInput"] | undefined
};
	["BusinessCategoryCreateWithoutBookingsInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["CampaignCreateNestedManyWithoutBusinessCategoryInput"]: {
		create?: Array<GraphQLTypes["CampaignCreateWithoutBusinessCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["CampaignCreateManyBusinessCategoryInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined
};
	["CampaignCreateWithoutBusinessCategoryInput"]: {
		id?: string | undefined,
	name: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: GraphQLTypes["UserCreateNestedOneWithoutCampaignsInput"],
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: GraphQLTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["UserCreateNestedOneWithoutCampaignsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutCampaignsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutCampaignsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutCampaignsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["FileCreateNestedManyWithoutCreatedByInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutCreatedByInput"]> | undefined,
	createMany?: GraphQLTypes["FileCreateManyCreatedByInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutCreatedByInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: GraphQLTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["BookingCreateNestedManyWithoutAssetsInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutAssetsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutAssetsInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutAssetsInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"]: {
		create?: Array<GraphQLTypes["AdvertisementCategoryCreateWithoutBookingsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined
};
	["AdvertisementCategoryCreateWithoutBookingsInput"]: {
		id?: string | undefined,
	name: string,
	type: GraphQLTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutAdCategoryInput"] | undefined
};
	["CampaignCreateNestedManyWithoutAdCategoryInput"]: {
		create?: Array<GraphQLTypes["CampaignCreateWithoutAdCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CampaignCreateOrConnectWithoutAdCategoryInput"]> | undefined,
	connect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined
};
	["CampaignCreateWithoutAdCategoryInput"]: {
		id?: string | undefined,
	name: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: GraphQLTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: GraphQLTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["BusinessCategoryCreateNestedOneWithoutCampaignsInput"]: {
		create?: GraphQLTypes["BusinessCategoryCreateWithoutCampaignsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"] | undefined,
	connect?: GraphQLTypes["BusinessCategoryWhereUniqueInput"] | undefined
};
	["BusinessCategoryCreateWithoutCampaignsInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["BookingCreateNestedManyWithoutBusinessCategoryInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutBusinessCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyBusinessCategoryInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutBusinessCategoryInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["FileCreateNestedManyWithoutProofBookingInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutProofBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutProofBookingInput"]> | undefined,
	createMany?: GraphQLTypes["FileCreateManyProofBookingInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutProofBookingInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: GraphQLTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: GraphQLTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["UserCreateNestedOneWithoutCreatedFilesInput"]: {
		create?: GraphQLTypes["UserCreateWithoutCreatedFilesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutCreatedFilesInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutCreatedFilesInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["FileCreateNestedManyWithoutUpdatedByInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutUpdatedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutUpdatedByInput"]> | undefined,
	createMany?: GraphQLTypes["FileCreateManyUpdatedByInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutUpdatedByInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url: string,
	createdBy: GraphQLTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutFilesInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutFilesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutFilesInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutFilesInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["UserCreateNestedOneWithoutOwnedWorkspacesInput"]: {
		create?: GraphQLTypes["UserCreateWithoutOwnedWorkspacesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutOwnedWorkspacesInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["OrderCreateNestedManyWithoutCreatedByInput"]: {
		create?: Array<GraphQLTypes["OrderCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["OrderCreateOrConnectWithoutCreatedByInput"]> | undefined,
	createMany?: GraphQLTypes["OrderCreateManyCreatedByInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined
};
	["OrderCreateWithoutCreatedByInput"]: {
		id?: string | undefined,
	createdDate?: GraphQLTypes["DateTime"] | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: GraphQLTypes["PriceSummaryCreateInput"],
	selectedDate: GraphQLTypes["OrderSelectedDateCreateInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutOrdersInput"]
};
	["AdBoardCreateNestedOneWithoutOrdersInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutOrdersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutOrdersInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutOrdersInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["SlotConfigurationCreateNestedManyWithoutAdBoardInput"]: {
		create?: Array<GraphQLTypes["SlotConfigurationCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["SlotConfigurationWhereUniqueInput"]> | undefined
};
	["SlotConfigurationCreateWithoutAdBoardInput"]: {
		id?: string | undefined,
	date: GraphQLTypes["DateTime"],
	availableSlots: number
};
	["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]: {
		where: GraphQLTypes["SlotConfigurationWhereUniqueInput"],
	create: GraphQLTypes["SlotConfigurationCreateWithoutAdBoardInput"]
};
	["SlotConfigurationCreateManyAdBoardInputEnvelope"]: {
		data: Array<GraphQLTypes["SlotConfigurationCreateManyAdBoardInput"]>
};
	["SlotConfigurationCreateManyAdBoardInput"]: {
		id?: string | undefined,
	date: GraphQLTypes["DateTime"],
	availableSlots: number
};
	["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutOfflineBookingAdboardsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["ChangelogCreateNestedManyWithoutUserInput"]: {
		create?: Array<GraphQLTypes["ChangelogCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["ChangelogCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["ChangelogCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined
};
	["ChangelogCreateWithoutUserInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	updates?: GraphQLTypes["JSON"] | undefined,
	booking?: GraphQLTypes["BookingCreateNestedOneWithoutChangelogsInput"] | undefined
};
	["BookingCreateNestedOneWithoutChangelogsInput"]: {
		create?: GraphQLTypes["BookingCreateWithoutChangelogsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BookingCreateOrConnectWithoutChangelogsInput"] | undefined,
	connect?: GraphQLTypes["BookingWhereUniqueInput"] | undefined
};
	["BookingCreateWithoutChangelogsInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["UserCreateNestedOneWithoutBookingsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutBookingsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutCreatedByInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutCreatedByInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyCreatedByInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutCreatedByInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["OrderCreateNestedManyWithoutAdBoardInput"]: {
		create?: Array<GraphQLTypes["OrderCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["OrderCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["OrderCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined
};
	["OrderCreateWithoutAdBoardInput"]: {
		id?: string | undefined,
	createdDate?: GraphQLTypes["DateTime"] | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: GraphQLTypes["PriceSummaryCreateInput"],
	selectedDate: GraphQLTypes["OrderSelectedDateCreateInput"],
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutOrdersInput"] | undefined
};
	["UserCreateNestedOneWithoutOrdersInput"]: {
		create?: GraphQLTypes["UserCreateWithoutOrdersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutOrdersInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutOrdersInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AuditCreateNestedManyWithoutUserInput"]: {
		create?: Array<GraphQLTypes["AuditCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AuditCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["AuditCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined
};
	["AuditCreateWithoutUserInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutAuditsInput"] | undefined
};
	["EntityCreateInput"]: {
		type: string,
	id: string,
	name?: string | undefined
};
	["WorkspaceCreateNestedOneWithoutAuditsInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutAuditsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutAuditsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutAuditsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["MembershipCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["MembershipCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["MembershipCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["MembershipCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined
};
	["MembershipCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutMembershipsInput"],
	role: GraphQLTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["UserCreateNestedOneWithoutMembershipsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutMembershipsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutMembershipsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["CampaignCreateNestedManyWithoutUserInput"]: {
		create?: Array<GraphQLTypes["CampaignCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CampaignCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["CampaignCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined
};
	["CampaignCreateWithoutUserInput"]: {
		id?: string | undefined,
	name: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	businessCategory: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: GraphQLTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"]: {
		create?: Array<GraphQLTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined
};
	["AdvertisementCategoryCreateWithoutCampaignsInput"]: {
		id?: string | undefined,
	name: string,
	type: GraphQLTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdCategoryInput"] | undefined
};
	["BookingCreateNestedManyWithoutAdCategoryInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutAdCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutAdCategoryInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutAdCategoryInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["AdBoardCreateNestedOneWithoutBookingsInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutBookingsInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["UserCreateNestedOneWithoutAdBoardsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutAdBoardsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["BookingCreateNestedManyWithoutSharedUsersInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutSharedUsersInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutSharedUsersInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutSharedUsersInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["ChangelogCreateNestedManyWithoutBookingInput"]: {
		create?: Array<GraphQLTypes["ChangelogCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["ChangelogCreateOrConnectWithoutBookingInput"]> | undefined,
	createMany?: GraphQLTypes["ChangelogCreateManyBookingInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined
};
	["ChangelogCreateWithoutBookingInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	updates?: GraphQLTypes["JSON"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutChangelogsInput"]
};
	["UserCreateNestedOneWithoutChangelogsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutChangelogsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutChangelogsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutChangelogsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["MembershipCreateNestedManyWithoutUserInput"]: {
		create?: Array<GraphQLTypes["MembershipCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["MembershipCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["MembershipCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined
};
	["MembershipCreateWithoutUserInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"],
	role: GraphQLTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["WorkspaceCreateNestedOneWithoutMembershipsInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutMembershipsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["PlayerCreateNestedManyWithoutAdBoardsInput"]: {
		create?: Array<GraphQLTypes["PlayerCreateWithoutAdBoardsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlayerCreateOrConnectWithoutAdBoardsInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined
};
	["PlayerCreateWithoutAdBoardsInput"]: {
		id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined
};
	["GalleryFileCreateInput"]: {
		name: string,
	url: string,
	contentType: string,
	width: number,
	height: number,
	duration?: number | undefined
};
	["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"]: {
		create?: GraphQLTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"] | undefined,
	connect?: GraphQLTypes["AdBoardGroupWhereUniqueInput"] | undefined
};
	["AdBoardGroupCreateWithoutPisignagePlayersInput"]: {
		id?: string | undefined,
	name: string,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["ScheduleCreateInput"]: {
		playlistId: string,
	playlistType?: string | undefined,
	skipForSchedule?: boolean | undefined,
	startTime?: string | undefined,
	endTime?: string | undefined
};
	["AdBoardCreateNestedOneWithoutAdBoardGroupInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutAdBoardGroupInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutAdBoardGroupInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutAdBoardsInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutAdBoardsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["PlaylistCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["PlaylistCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["PlaylistCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined
};
	["PlaylistCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PisignageFileCreateInput"]: {
		name: string,
	duration?: number | undefined,
	muteAudio?: boolean | undefined,
	isVideo?: boolean | undefined
};
	["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"]: {
		create?: GraphQLTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"] | undefined,
	connect?: GraphQLTypes["PlaylistWhereUniqueInput"] | undefined
};
	["PlaylistCreateWithoutFooterParentPlaylistsInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	sidebarParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"]: {
		create?: GraphQLTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"] | undefined,
	connect?: GraphQLTypes["PlaylistWhereUniqueInput"] | undefined
};
	["PlaylistCreateWithoutSidebarParentPlaylistsInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined
};
	["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"]: {
		create?: Array<GraphQLTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined
};
	["AdBoardGroupCreateWithoutAdsPlaylistInput"]: {
		id?: string | undefined,
	name: string,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"]: {
		create?: Array<GraphQLTypes["PlaylistCreateWithoutAdBoardGroupsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined
};
	["PlaylistCreateWithoutAdBoardGroupsInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutPlaylistsInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutPlaylistsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutPlaylistsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["AdBoardGroupCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined
};
	["AdBoardGroupCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	name: string,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined
};
	["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"]: {
		create?: GraphQLTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"] | undefined,
	connect?: GraphQLTypes["PlaylistWhereUniqueInput"] | undefined
};
	["PlaylistCreateWithoutAdBoardGroupsForAdsInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"]: {
		create?: Array<GraphQLTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined
};
	["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]: {
		id?: string | undefined,
	name: string,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["PlayerCreateNestedManyWithoutAdBoardGroupInput"]: {
		create?: Array<GraphQLTypes["PlayerCreateWithoutAdBoardGroupInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"]> | undefined,
	createMany?: GraphQLTypes["PlayerCreateManyAdBoardGroupInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined
};
	["PlayerCreateWithoutAdBoardGroupInput"]: {
		id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutPlayersInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutPlayersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutPlayersInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutPlayersInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["AuditCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["AuditCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AuditCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["AuditCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined
};
	["AuditCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutAuditsInput"]
};
	["UserCreateNestedOneWithoutAuditsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutAuditsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutAuditsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutAuditsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["NotificationCreateNestedManyWithoutRecipientInput"]: {
		create?: Array<GraphQLTypes["NotificationCreateWithoutRecipientInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["NotificationCreateOrConnectWithoutRecipientInput"]> | undefined,
	createMany?: GraphQLTypes["NotificationCreateManyRecipientInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["NotificationWhereUniqueInput"]> | undefined
};
	["NotificationCreateWithoutRecipientInput"]: {
		id?: string | undefined,
	type: GraphQLTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined
};
	["NotificationCreateOrConnectWithoutRecipientInput"]: {
		where: GraphQLTypes["NotificationWhereUniqueInput"],
	create: GraphQLTypes["NotificationCreateWithoutRecipientInput"]
};
	["NotificationCreateManyRecipientInputEnvelope"]: {
		data: Array<GraphQLTypes["NotificationCreateManyRecipientInput"]>
};
	["NotificationCreateManyRecipientInput"]: {
		id?: string | undefined,
	type: GraphQLTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined
};
	["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"]: {
		create?: Array<GraphQLTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined
};
	["AdBoardPublishHistoryCreateWithoutPublishedByInput"]: {
		id?: string | undefined,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	changesSnapshot: GraphQLTypes["JSON"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutHistoryInput"]
};
	["AdBoardCreateNestedOneWithoutHistoryInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutHistoryInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutHistoryInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutHistoryInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardGroupCreateNestedOneWithoutAdBoardInput"]: {
		create?: GraphQLTypes["AdBoardGroupCreateWithoutAdBoardInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"] | undefined,
	connect?: GraphQLTypes["AdBoardGroupWhereUniqueInput"] | undefined
};
	["AdBoardGroupCreateWithoutAdBoardInput"]: {
		id?: string | undefined,
	name: string,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutAdBoardGroupsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["BookingCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["CampaignCreateNestedOneWithoutBookingsInput"]: {
		create?: GraphQLTypes["CampaignCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["CampaignCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: GraphQLTypes["CampaignWhereUniqueInput"] | undefined
};
	["CampaignCreateWithoutBookingsInput"]: {
		id?: string | undefined,
	name: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: GraphQLTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	payments?: GraphQLTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["PaymentCreateNestedManyWithoutCampaignInput"]: {
		create?: Array<GraphQLTypes["PaymentCreateWithoutCampaignInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PaymentCreateOrConnectWithoutCampaignInput"]> | undefined,
	createMany?: GraphQLTypes["PaymentCreateManyCampaignInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PaymentWhereUniqueInput"]> | undefined
};
	["PaymentCreateWithoutCampaignInput"]: {
		id?: string | undefined,
	paymentIntentId: string,
	checkoutSessionId: string,
	eventId: string,
	liveMode?: boolean | undefined,
	paymentGateway: GraphQLTypes["PaymentGatewayName"],
	subtotal: number,
	total: number,
	tax: number,
	discount: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["PaymentCreateOrConnectWithoutCampaignInput"]: {
		where: GraphQLTypes["PaymentWhereUniqueInput"],
	create: GraphQLTypes["PaymentCreateWithoutCampaignInput"]
};
	["PaymentCreateManyCampaignInputEnvelope"]: {
		data: Array<GraphQLTypes["PaymentCreateManyCampaignInput"]>
};
	["PaymentCreateManyCampaignInput"]: {
		id?: string | undefined,
	paymentIntentId: string,
	checkoutSessionId: string,
	eventId: string,
	liveMode?: boolean | undefined,
	paymentGateway: GraphQLTypes["PaymentGatewayName"],
	subtotal: number,
	total: number,
	tax: number,
	discount: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["FileCreateNestedManyWithoutCampaignsInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutCampaignsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutCampaignsInput"]> | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutCampaignsInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: GraphQLTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: GraphQLTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined
};
	["BookingCreateNestedOneWithoutProofsInput"]: {
		create?: GraphQLTypes["BookingCreateWithoutProofsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BookingCreateOrConnectWithoutProofsInput"] | undefined,
	connect?: GraphQLTypes["BookingWhereUniqueInput"] | undefined
};
	["BookingCreateWithoutProofsInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutBookingsInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutBookingsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutBookingsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["FileCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["FileCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined
};
	["FileCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: GraphQLTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: GraphQLTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["CampaignCreateNestedManyWithoutAssetsInput"]: {
		create?: Array<GraphQLTypes["CampaignCreateWithoutAssetsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CampaignCreateOrConnectWithoutAssetsInput"]> | undefined,
	connect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined
};
	["CampaignCreateWithoutAssetsInput"]: {
		id?: string | undefined,
	name: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: GraphQLTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: GraphQLTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined
};
	["BookingCreateNestedManyWithoutCampaignInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutCampaignInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutCampaignInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyCampaignInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined
};
	["BookingCreateWithoutCampaignInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["PayoutCreateNestedManyWithoutBookingInput"]: {
		create?: Array<GraphQLTypes["PayoutCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PayoutCreateOrConnectWithoutBookingInput"]> | undefined,
	createMany?: GraphQLTypes["PayoutCreateManyBookingInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined
};
	["PayoutCreateWithoutBookingInput"]: {
		id?: string | undefined,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	status: GraphQLTypes["PayoutStatus"],
	transaction?: GraphQLTypes["TransactionCreateNestedOneWithoutPayoutsInput"] | undefined
};
	["TransactionCreateNestedOneWithoutPayoutsInput"]: {
		create?: GraphQLTypes["TransactionCreateWithoutPayoutsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["TransactionCreateOrConnectWithoutPayoutsInput"] | undefined,
	connect?: GraphQLTypes["TransactionWhereUniqueInput"] | undefined
};
	["TransactionCreateWithoutPayoutsInput"]: {
		id?: string | undefined,
	amount: number,
	createAt?: GraphQLTypes["DateTime"] | undefined,
	processedAt?: GraphQLTypes["DateTime"] | undefined,
	relatedEntityId: string,
	transactionStatus: GraphQLTypes["TransactionStatus"],
	details?: GraphQLTypes["JSON"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutTransactionsInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutTransactionsInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutTransactionsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutTransactionsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["PlayerCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["PlayerCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlayerCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["PlayerCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined
};
	["PlayerCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined
};
	["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"]: {
		create?: Array<GraphQLTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]> | undefined,
	createMany?: GraphQLTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined
};
	["PlayerUptimeStatusCreateWithoutPlayerInput"]: {
		id?: string | undefined,
	cpuSerialNumber: string,
	date: string,
	uptime: number
};
	["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]: {
		where: GraphQLTypes["PlayerUptimeStatusWhereUniqueInput"],
	create: GraphQLTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]
};
	["PlayerUptimeStatusCreateManyPlayerInputEnvelope"]: {
		data: Array<GraphQLTypes["PlayerUptimeStatusCreateManyPlayerInput"]>
};
	["PlayerUptimeStatusCreateManyPlayerInput"]: {
		id?: string | undefined,
	cpuSerialNumber: string,
	date: string,
	uptime: number
};
	["CameraCreateNestedManyWithoutPlayerInput"]: {
		create?: Array<GraphQLTypes["CameraCreateWithoutPlayerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraCreateOrConnectWithoutPlayerInput"]> | undefined,
	createMany?: GraphQLTypes["CameraCreateManyPlayerInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined
};
	["CameraCreateWithoutPlayerInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer: GraphQLTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	feeds?: GraphQLTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined
};
	["CameraServerCreateNestedOneWithoutCamerasInput"]: {
		create?: GraphQLTypes["CameraServerCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: GraphQLTypes["CameraServerCreateOrConnectWithoutCamerasInput"] | undefined,
	connect?: GraphQLTypes["CameraServerWhereUniqueInput"] | undefined
};
	["CameraServerCreateWithoutCamerasInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutCameraServersInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutCameraServersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutCameraServersInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["TransactionCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["TransactionCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["TransactionCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["TransactionCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["TransactionWhereUniqueInput"]> | undefined
};
	["TransactionCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	amount: number,
	createAt?: GraphQLTypes["DateTime"] | undefined,
	processedAt?: GraphQLTypes["DateTime"] | undefined,
	relatedEntityId: string,
	transactionStatus: GraphQLTypes["TransactionStatus"],
	details?: GraphQLTypes["JSON"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutTransactionInput"] | undefined
};
	["PayoutCreateNestedManyWithoutTransactionInput"]: {
		create?: Array<GraphQLTypes["PayoutCreateWithoutTransactionInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PayoutCreateOrConnectWithoutTransactionInput"]> | undefined,
	createMany?: GraphQLTypes["PayoutCreateManyTransactionInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined
};
	["PayoutCreateWithoutTransactionInput"]: {
		id?: string | undefined,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	status: GraphQLTypes["PayoutStatus"],
	booking: GraphQLTypes["BookingCreateNestedOneWithoutPayoutsInput"]
};
	["BookingCreateNestedOneWithoutPayoutsInput"]: {
		create?: GraphQLTypes["BookingCreateWithoutPayoutsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BookingCreateOrConnectWithoutPayoutsInput"] | undefined,
	connect?: GraphQLTypes["BookingWhereUniqueInput"] | undefined
};
	["BookingCreateWithoutPayoutsInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["UserCreateNestedManyWithoutSharedBookingsInput"]: {
		create?: Array<GraphQLTypes["UserCreateWithoutSharedBookingsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["UserCreateOrConnectWithoutSharedBookingsInput"]> | undefined,
	connect?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined
};
	["UserCreateWithoutSharedBookingsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutOfflineBookingsContactInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["CameraCreateNestedManyWithoutAdBoardInput"]: {
		create?: Array<GraphQLTypes["CameraCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["CameraCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined
};
	["CameraCreateWithoutAdBoardInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer: GraphQLTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: GraphQLTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined
};
	["PlayerCreateNestedOneWithoutCamerasInput"]: {
		create?: GraphQLTypes["PlayerCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: GraphQLTypes["PlayerCreateOrConnectWithoutCamerasInput"] | undefined,
	connect?: GraphQLTypes["PlayerWhereUniqueInput"] | undefined
};
	["PlayerCreateWithoutCamerasInput"]: {
		id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined
};
	["DigitalSignageCreateNestedOneWithoutPlayersInput"]: {
		create?: GraphQLTypes["DigitalSignageCreateWithoutPlayersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"] | undefined,
	connect?: GraphQLTypes["DigitalSignageWhereUniqueInput"] | undefined
};
	["DigitalSignageCreateWithoutPlayersInput"]: {
		id?: string | undefined,
	type: GraphQLTypes["SignageType"],
	name: string,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["DigitalSignageCreateOrConnectWithoutPlayersInput"]: {
		where: GraphQLTypes["DigitalSignageWhereUniqueInput"],
	create: GraphQLTypes["DigitalSignageCreateWithoutPlayersInput"]
};
	["DigitalSignageWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["DigitalSignageWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["DigitalSignageWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["DigitalSignageWhereInput"]> | undefined,
	type?: GraphQLTypes["EnumSignageTypeFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	players?: GraphQLTypes["PlayerListRelationFilter"] | undefined
};
	["AudienceCreateNestedManyWithoutPlayersInput"]: {
		create?: Array<GraphQLTypes["AudienceCreateWithoutPlayersInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AudienceCreateOrConnectWithoutPlayersInput"]> | undefined,
	connect?: Array<GraphQLTypes["AudienceWhereUniqueInput"]> | undefined
};
	["AudienceCreateWithoutPlayersInput"]: {
		id?: string | undefined,
	type: GraphQLTypes["AudienceType"],
	name: string,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AudienceCreateOrConnectWithoutPlayersInput"]: {
		where: GraphQLTypes["AudienceWhereUniqueInput"],
	create: GraphQLTypes["AudienceCreateWithoutPlayersInput"]
};
	["AdBoardCreateNestedManyWithoutPlayersInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutPlayersInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutPlayersInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutPlayersInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardCreateNestedOneWithoutDraftAdboardInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutDraftAdboardInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutDraftAdboardInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"]: {
		create?: Array<GraphQLTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined
};
	["AdBoardPublishHistoryCreateWithoutAdBoardInput"]: {
		id?: string | undefined,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	changesSnapshot: GraphQLTypes["JSON"],
	publishedBy: GraphQLTypes["UserCreateNestedOneWithoutPublishAdboardVersionInput"]
};
	["UserCreateNestedOneWithoutPublishAdboardVersionInput"]: {
		create?: GraphQLTypes["UserCreateWithoutPublishAdboardVersionInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutPublishAdboardVersionInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AdBoardReviewCommentCreateNestedManyWithoutUserInput"]: {
		create?: Array<GraphQLTypes["AdBoardReviewCommentCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined
};
	["AdBoardReviewCommentCreateWithoutUserInput"]: {
		id?: string | undefined,
	comment: string,
	action: GraphQLTypes["ReviewAction"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutReviewCommentsInput"]
};
	["AdBoardCreateNestedOneWithoutReviewCommentsInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutReviewCommentsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutReviewCommentsInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardCreateNestedOneWithoutPublishedVersionInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutPublishedVersionInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutPublishedVersionInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"]: {
		create?: Array<GraphQLTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined
};
	["AdBoardReviewCommentCreateWithoutAdBoardInput"]: {
		id?: string | undefined,
	comment: string,
	action: GraphQLTypes["ReviewAction"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutReviewCommentsInput"]
};
	["UserCreateNestedOneWithoutReviewCommentsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutReviewCommentsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutReviewCommentsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutReviewCommentsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutArchivedByInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutArchivedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutArchivedByInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyArchivedByInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutArchivedByInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"]: {
		create?: Array<GraphQLTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined
};
	["AdBoardPublicReviewCreateWithoutAdBoardInput"]: {
		id?: string | undefined,
	rating: number,
	comment?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutPublicReviewsInput"]
};
	["UserCreateNestedOneWithoutPublicReviewsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutPublicReviewsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutPublicReviewsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutPublicReviewsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["WorkspaceCreateNestedManyWithoutOwnerInput"]: {
		create?: Array<GraphQLTypes["WorkspaceCreateWithoutOwnerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WorkspaceCreateOrConnectWithoutOwnerInput"]> | undefined,
	createMany?: GraphQLTypes["WorkspaceCreateManyOwnerInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined
};
	["WorkspaceCreateWithoutOwnerInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"]: {
		create?: GraphQLTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | undefined,
	connectOrCreate?: GraphQLTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"] | undefined,
	connect?: GraphQLTypes["SubscriptionWhereUniqueInput"] | undefined
};
	["SubscriptionCreateWithoutActiveWorkspaceInput"]: {
		id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsCreateInput"] | undefined
};
	["CancellationDetailsCreateInput"]: {
		canceledAt?: GraphQLTypes["DateTime"] | undefined,
	cancelAt?: GraphQLTypes["DateTime"] | undefined,
	cancelAtPeriodEnd: boolean,
	reason?: string | undefined
};
	["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"]: {
		where: GraphQLTypes["SubscriptionWhereUniqueInput"],
	create: GraphQLTypes["SubscriptionCreateWithoutActiveWorkspaceInput"]
};
	["CameraServerCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["CameraServerCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["CameraServerCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["CameraServerWhereUniqueInput"]> | undefined
};
	["CameraServerCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined
};
	["CameraCreateNestedManyWithoutCameraServerInput"]: {
		create?: Array<GraphQLTypes["CameraCreateWithoutCameraServerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraCreateOrConnectWithoutCameraServerInput"]> | undefined,
	createMany?: GraphQLTypes["CameraCreateManyCameraServerInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined
};
	["CameraCreateWithoutCameraServerInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	player?: GraphQLTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined
};
	["CameraFeedCreateNestedManyWithoutCameraInput"]: {
		create?: Array<GraphQLTypes["CameraFeedCreateWithoutCameraInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraFeedCreateOrConnectWithoutCameraInput"]> | undefined,
	createMany?: GraphQLTypes["CameraFeedCreateManyCameraInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined
};
	["CameraFeedCreateWithoutCameraInput"]: {
		id?: string | undefined,
	timestamp: GraphQLTypes["DateTime"],
	data: GraphQLTypes["CameraFeedDataCreateInput"],
	cameraServer: GraphQLTypes["CameraServerCreateNestedOneWithoutFeedsInput"]
};
	["CameraFeedDataCreateInput"]: {
		person?: number | undefined,
	car?: number | undefined,
	truck?: number | undefined,
	motorcycle?: number | undefined
};
	["CameraServerCreateNestedOneWithoutFeedsInput"]: {
		create?: GraphQLTypes["CameraServerCreateWithoutFeedsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["CameraServerCreateOrConnectWithoutFeedsInput"] | undefined,
	connect?: GraphQLTypes["CameraServerWhereUniqueInput"] | undefined
};
	["CameraServerCreateWithoutFeedsInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined
};
	["CameraServerCreateOrConnectWithoutFeedsInput"]: {
		where: GraphQLTypes["CameraServerWhereUniqueInput"],
	create: GraphQLTypes["CameraServerCreateWithoutFeedsInput"]
};
	["CameraFeedCreateOrConnectWithoutCameraInput"]: {
		where: GraphQLTypes["CameraFeedWhereUniqueInput"],
	create: GraphQLTypes["CameraFeedCreateWithoutCameraInput"]
};
	["CameraFeedCreateManyCameraInputEnvelope"]: {
		data: Array<GraphQLTypes["CameraFeedCreateManyCameraInput"]>
};
	["CameraFeedCreateManyCameraInput"]: {
		id?: string | undefined,
	timestamp: GraphQLTypes["DateTime"],
	cameraServerId: string,
	data: GraphQLTypes["CameraFeedDataCreateInput"]
};
	["AdBoardCreateNestedOneWithoutCamerasInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutCamerasInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutCamerasInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["UserCreateNestedOneWithoutArchivedAdboardsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutArchivedAdboardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutArchivedAdboardsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["WorkspaceCreateNestedManyWithoutLeadsInput"]: {
		create?: Array<GraphQLTypes["WorkspaceCreateWithoutLeadsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WorkspaceCreateOrConnectWithoutLeadsInput"]> | undefined,
	connect?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined
};
	["WorkspaceCreateWithoutLeadsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined
};
	["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["WorkspaceRoleWhereUniqueInput"]> | undefined
};
	["WorkspaceRoleCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	name: string,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutRoleInput"] | undefined
};
	["MembershipCreateNestedManyWithoutRoleInput"]: {
		create?: Array<GraphQLTypes["MembershipCreateWithoutRoleInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["MembershipCreateOrConnectWithoutRoleInput"]> | undefined,
	createMany?: GraphQLTypes["MembershipCreateManyRoleInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined
};
	["MembershipCreateWithoutRoleInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutMembershipsInput"],
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutRoleInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	create: GraphQLTypes["MembershipCreateWithoutRoleInput"]
};
	["MembershipCreateManyRoleInputEnvelope"]: {
		data: Array<GraphQLTypes["MembershipCreateManyRoleInput"]>
};
	["MembershipCreateManyRoleInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	userId: string,
	workspaceId: string
};
	["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["WorkspaceRoleWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]
};
	["WorkspaceRoleCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["WorkspaceRoleCreateManyWorkspaceInput"]>
};
	["WorkspaceRoleCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	name: string,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["WebhookLogCreateNestedManyWithoutWorkspaceInput"]: {
		create?: Array<GraphQLTypes["WebhookLogCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["WebhookLogCreateManyWorkspaceInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined
};
	["WebhookLogCreateWithoutWorkspaceInput"]: {
		id?: string | undefined,
	response?: GraphQLTypes["JSON"] | undefined,
	request?: GraphQLTypes["JSON"] | undefined,
	status: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	booking: GraphQLTypes["BookingCreateNestedOneWithoutWebhookLogsInput"]
};
	["BookingCreateNestedOneWithoutWebhookLogsInput"]: {
		create?: GraphQLTypes["BookingCreateWithoutWebhookLogsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BookingCreateOrConnectWithoutWebhookLogsInput"] | undefined,
	connect?: GraphQLTypes["BookingWhereUniqueInput"] | undefined
};
	["BookingCreateWithoutWebhookLogsInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["SlotUsageCreateNestedManyWithoutBookingInput"]: {
		create?: Array<GraphQLTypes["SlotUsageCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["SlotUsageCreateOrConnectWithoutBookingInput"]> | undefined,
	createMany?: GraphQLTypes["SlotUsageCreateManyBookingInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["SlotUsageWhereUniqueInput"]> | undefined
};
	["SlotUsageCreateWithoutBookingInput"]: {
		id?: string | undefined,
	date: GraphQLTypes["DateTime"],
	slotUsed?: number | undefined
};
	["SlotUsageCreateOrConnectWithoutBookingInput"]: {
		where: GraphQLTypes["SlotUsageWhereUniqueInput"],
	create: GraphQLTypes["SlotUsageCreateWithoutBookingInput"]
};
	["SlotUsageCreateManyBookingInputEnvelope"]: {
		data: Array<GraphQLTypes["SlotUsageCreateManyBookingInput"]>
};
	["SlotUsageCreateManyBookingInput"]: {
		id?: string | undefined,
	date: GraphQLTypes["DateTime"],
	slotUsed?: number | undefined
};
	["BookingCreateOrConnectWithoutWebhookLogsInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutWebhookLogsInput"]
};
	["WebhookLogCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["WebhookLogWhereUniqueInput"],
	create: GraphQLTypes["WebhookLogCreateWithoutWorkspaceInput"]
};
	["WebhookLogCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["WebhookLogCreateManyWorkspaceInput"]>
};
	["WebhookLogCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	bookingId: string,
	response?: GraphQLTypes["JSON"] | undefined,
	request?: GraphQLTypes["JSON"] | undefined,
	status: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["WorkspaceCreateOrConnectWithoutLeadsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutLeadsInput"]
};
	["AdBoardPublicReviewCreateNestedManyWithoutUserInput"]: {
		create?: Array<GraphQLTypes["AdBoardPublicReviewCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined
};
	["AdBoardPublicReviewCreateWithoutUserInput"]: {
		id?: string | undefined,
	rating: number,
	comment?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"]
};
	["AdBoardCreateNestedOneWithoutPublicReviewsInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutPublicReviewsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutPublicReviewsInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined
};
	["AdBoardCreateOrConnectWithoutPublicReviewsInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]: {
		where: GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"],
	create: GraphQLTypes["AdBoardPublicReviewCreateWithoutUserInput"]
};
	["AdBoardPublicReviewCreateManyUserInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardPublicReviewCreateManyUserInput"]>
};
	["AdBoardPublicReviewCreateManyUserInput"]: {
		id?: string | undefined,
	adBoardId: string,
	rating: number,
	comment?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["UserPositionCreateNestedManyWithoutUserInput"]: {
		create?: Array<GraphQLTypes["UserPositionCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["UserPositionCreateOrConnectWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["UserPositionCreateManyUserInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["UserPositionWhereUniqueInput"]> | undefined
};
	["UserPositionCreateWithoutUserInput"]: {
		id?: string | undefined,
	timestamp: GraphQLTypes["DateTime"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	coords: GraphQLTypes["UserPositionCoordsCreateInput"],
	location?: GraphQLTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserPositionCoordsCreateInput"]: {
		accuracy?: number | undefined,
	altitude?: number | undefined,
	altitudeAccuracy?: number | undefined,
	heading?: number | undefined,
	latitude: number,
	longitude: number,
	speed?: number | undefined
};
	["UserPositionLocationCreateInput"]: {
		address?: string | undefined,
	city?: string | undefined,
	state?: string | undefined,
	country?: string | undefined
};
	["UserPositionCreateOrConnectWithoutUserInput"]: {
		where: GraphQLTypes["UserPositionWhereUniqueInput"],
	create: GraphQLTypes["UserPositionCreateWithoutUserInput"]
};
	["UserPositionCreateManyUserInputEnvelope"]: {
		data: Array<GraphQLTypes["UserPositionCreateManyUserInput"]>
};
	["UserPositionCreateManyUserInput"]: {
		id?: string | undefined,
	timestamp: GraphQLTypes["DateTime"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	coords: GraphQLTypes["UserPositionCoordsCreateInput"],
	location?: GraphQLTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserCreateOrConnectWithoutArchivedAdboardsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutArchivedAdboardsInput"]
};
	["AdBoardCreateOrConnectWithoutCamerasInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutCameraServerInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	create: GraphQLTypes["CameraCreateWithoutCameraServerInput"]
};
	["CameraCreateManyCameraServerInputEnvelope"]: {
		data: Array<GraphQLTypes["CameraCreateManyCameraServerInput"]>
};
	["CameraCreateManyCameraServerInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	playerId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["CameraFeedCreateNestedManyWithoutCameraServerInput"]: {
		create?: Array<GraphQLTypes["CameraFeedCreateWithoutCameraServerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"]> | undefined,
	createMany?: GraphQLTypes["CameraFeedCreateManyCameraServerInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined
};
	["CameraFeedCreateWithoutCameraServerInput"]: {
		id?: string | undefined,
	timestamp: GraphQLTypes["DateTime"],
	data: GraphQLTypes["CameraFeedDataCreateInput"],
	camera: GraphQLTypes["CameraCreateNestedOneWithoutFeedsInput"]
};
	["CameraCreateNestedOneWithoutFeedsInput"]: {
		create?: GraphQLTypes["CameraCreateWithoutFeedsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["CameraCreateOrConnectWithoutFeedsInput"] | undefined,
	connect?: GraphQLTypes["CameraWhereUniqueInput"] | undefined
};
	["CameraCreateWithoutFeedsInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer: GraphQLTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: GraphQLTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined
};
	["CameraCreateOrConnectWithoutFeedsInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	create: GraphQLTypes["CameraCreateWithoutFeedsInput"]
};
	["CameraFeedCreateOrConnectWithoutCameraServerInput"]: {
		where: GraphQLTypes["CameraFeedWhereUniqueInput"],
	create: GraphQLTypes["CameraFeedCreateWithoutCameraServerInput"]
};
	["CameraFeedCreateManyCameraServerInputEnvelope"]: {
		data: Array<GraphQLTypes["CameraFeedCreateManyCameraServerInput"]>
};
	["CameraFeedCreateManyCameraServerInput"]: {
		id?: string | undefined,
	cameraId: string,
	timestamp: GraphQLTypes["DateTime"],
	data: GraphQLTypes["CameraFeedDataCreateInput"]
};
	["CameraServerCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["CameraServerWhereUniqueInput"],
	create: GraphQLTypes["CameraServerCreateWithoutWorkspaceInput"]
};
	["CameraServerCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["CameraServerCreateManyWorkspaceInput"]>
};
	["CameraServerCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string
};
	["UserCreateNestedManyWithoutLeadWorspacesInput"]: {
		create?: Array<GraphQLTypes["UserCreateWithoutLeadWorspacesInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["UserCreateOrConnectWithoutLeadWorspacesInput"]> | undefined,
	connect?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined
};
	["UserCreateWithoutLeadWorspacesInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["UserCreateOrConnectWithoutLeadWorspacesInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutLeadWorspacesInput"]
};
	["WorkspaceCreateOrConnectWithoutOwnerInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutOwnerInput"]
};
	["WorkspaceCreateManyOwnerInputEnvelope"]: {
		data: Array<GraphQLTypes["WorkspaceCreateManyOwnerInput"]>
};
	["WorkspaceCreateManyOwnerInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	leadIds?: Array<string> | undefined
};
	["UserCreateOrConnectWithoutPublicReviewsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"],
	create: GraphQLTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardPublicReviewCreateManyAdBoardInput"]>
};
	["AdBoardPublicReviewCreateManyAdBoardInput"]: {
		id?: string | undefined,
	userId: string,
	rating: number,
	comment?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdBoardCreateOrConnectWithoutArchivedByInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutArchivedByInput"]
};
	["AdBoardCreateManyArchivedByInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardCreateManyArchivedByInput"]>
};
	["AdBoardCreateManyArchivedByInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["UserCreateOrConnectWithoutReviewCommentsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"],
	create: GraphQLTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardReviewCommentCreateManyAdBoardInput"]>
};
	["AdBoardReviewCommentCreateManyAdBoardInput"]: {
		id?: string | undefined,
	userId: string,
	comment: string,
	action: GraphQLTypes["ReviewAction"],
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdBoardCreateOrConnectWithoutPublishedVersionInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutPublishedVersionInput"]
};
	["AdBoardCreateOrConnectWithoutReviewCommentsInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]: {
		where: GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"],
	create: GraphQLTypes["AdBoardReviewCommentCreateWithoutUserInput"]
};
	["AdBoardReviewCommentCreateManyUserInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardReviewCommentCreateManyUserInput"]>
};
	["AdBoardReviewCommentCreateManyUserInput"]: {
		id?: string | undefined,
	adBoardId: string,
	comment: string,
	action: GraphQLTypes["ReviewAction"],
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["UserCreateOrConnectWithoutPublishAdboardVersionInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutPublishAdboardVersionInput"]
};
	["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"],
	create: GraphQLTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardPublishHistoryCreateManyAdBoardInput"]>
};
	["AdBoardPublishHistoryCreateManyAdBoardInput"]: {
		id?: string | undefined,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	publishedByUserId: string,
	changesSnapshot: GraphQLTypes["JSON"]
};
	["AdBoardCreateOrConnectWithoutDraftAdboardInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutDraftAdboardInput"]
};
	["AdBoardCreateOrConnectWithoutPlayersInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutPlayersInput"]
};
	["PlayerCreateOrConnectWithoutCamerasInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	create: GraphQLTypes["PlayerCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutAdBoardInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	create: GraphQLTypes["CameraCreateWithoutAdBoardInput"]
};
	["CameraCreateManyAdBoardInputEnvelope"]: {
		data: Array<GraphQLTypes["CameraCreateManyAdBoardInput"]>
};
	["CameraCreateManyAdBoardInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	playerId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined
};
	["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]
};
	["AdBoardCreateManyOfflineBookingsContactInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardCreateManyOfflineBookingsContactInput"]>
};
	["AdBoardCreateManyOfflineBookingsContactInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["UserCreateOrConnectWithoutSharedBookingsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutSharedBookingsInput"]
};
	["WebhookLogCreateNestedManyWithoutBookingInput"]: {
		create?: Array<GraphQLTypes["WebhookLogCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WebhookLogCreateOrConnectWithoutBookingInput"]> | undefined,
	createMany?: GraphQLTypes["WebhookLogCreateManyBookingInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined
};
	["WebhookLogCreateWithoutBookingInput"]: {
		id?: string | undefined,
	response?: GraphQLTypes["JSON"] | undefined,
	request?: GraphQLTypes["JSON"] | undefined,
	status: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutWebhookLogsInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutWebhookLogsInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutWebhookLogsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutWebhookLogsInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["WorkspaceCreateOrConnectWithoutWebhookLogsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutWebhookLogsInput"]
};
	["WebhookLogCreateOrConnectWithoutBookingInput"]: {
		where: GraphQLTypes["WebhookLogWhereUniqueInput"],
	create: GraphQLTypes["WebhookLogCreateWithoutBookingInput"]
};
	["WebhookLogCreateManyBookingInputEnvelope"]: {
		data: Array<GraphQLTypes["WebhookLogCreateManyBookingInput"]>
};
	["WebhookLogCreateManyBookingInput"]: {
		id?: string | undefined,
	response?: GraphQLTypes["JSON"] | undefined,
	request?: GraphQLTypes["JSON"] | undefined,
	status: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	workspaceId?: string | undefined
};
	["BookingCreateOrConnectWithoutPayoutsInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutPayoutsInput"]
};
	["PayoutCreateOrConnectWithoutTransactionInput"]: {
		where: GraphQLTypes["PayoutWhereUniqueInput"],
	create: GraphQLTypes["PayoutCreateWithoutTransactionInput"]
};
	["PayoutCreateManyTransactionInputEnvelope"]: {
		data: Array<GraphQLTypes["PayoutCreateManyTransactionInput"]>
};
	["PayoutCreateManyTransactionInput"]: {
		id?: string | undefined,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	bookingId: string,
	status: GraphQLTypes["PayoutStatus"]
};
	["TransactionCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["TransactionWhereUniqueInput"],
	create: GraphQLTypes["TransactionCreateWithoutWorkspaceInput"]
};
	["TransactionCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["TransactionCreateManyWorkspaceInput"]>
};
	["TransactionCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	amount: number,
	createAt?: GraphQLTypes["DateTime"] | undefined,
	processedAt?: GraphQLTypes["DateTime"] | undefined,
	relatedEntityId: string,
	transactionStatus: GraphQLTypes["TransactionStatus"],
	details?: GraphQLTypes["JSON"] | undefined
};
	["WorkspaceCreateOrConnectWithoutCameraServersInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutCameraServersInput"]
};
	["CameraServerCreateOrConnectWithoutCamerasInput"]: {
		where: GraphQLTypes["CameraServerWhereUniqueInput"],
	create: GraphQLTypes["CameraServerCreateWithoutCamerasInput"]
};
	["CameraCreateOrConnectWithoutPlayerInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	create: GraphQLTypes["CameraCreateWithoutPlayerInput"]
};
	["CameraCreateManyPlayerInputEnvelope"]: {
		data: Array<GraphQLTypes["CameraCreateManyPlayerInput"]>
};
	["CameraCreateManyPlayerInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["PlayerCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	create: GraphQLTypes["PlayerCreateWithoutWorkspaceInput"]
};
	["PlayerCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["PlayerCreateManyWorkspaceInput"]>
};
	["PlayerCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId?: Array<string> | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardIds?: Array<string> | undefined
};
	["WorkspaceCreateOrConnectWithoutTransactionsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutTransactionsInput"]
};
	["TransactionCreateOrConnectWithoutPayoutsInput"]: {
		where: GraphQLTypes["TransactionWhereUniqueInput"],
	create: GraphQLTypes["TransactionCreateWithoutPayoutsInput"]
};
	["PayoutCreateOrConnectWithoutBookingInput"]: {
		where: GraphQLTypes["PayoutWhereUniqueInput"],
	create: GraphQLTypes["PayoutCreateWithoutBookingInput"]
};
	["PayoutCreateManyBookingInputEnvelope"]: {
		data: Array<GraphQLTypes["PayoutCreateManyBookingInput"]>
};
	["PayoutCreateManyBookingInput"]: {
		id?: string | undefined,
	totalAmount: number,
	commissionRate: number,
	commissionAmount: number,
	netAmount: number,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	status: GraphQLTypes["PayoutStatus"],
	transactionId?: string | undefined
};
	["BookingCreateOrConnectWithoutCampaignInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutCampaignInput"]
};
	["BookingCreateManyCampaignInputEnvelope"]: {
		data: Array<GraphQLTypes["BookingCreateManyCampaignInput"]>
};
	["BookingCreateManyCampaignInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	workspaceId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["CampaignCreateOrConnectWithoutAssetsInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	create: GraphQLTypes["CampaignCreateWithoutAssetsInput"]
};
	["FileCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	create: GraphQLTypes["FileCreateWithoutWorkspaceInput"]
};
	["FileCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["FileCreateManyWorkspaceInput"]>
};
	["FileCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["WorkspaceCreateOrConnectWithoutBookingsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutProofsInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutProofsInput"]
};
	["FileCreateOrConnectWithoutCampaignsInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	create: GraphQLTypes["FileCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutBookingsInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	create: GraphQLTypes["CampaignCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutWorkspaceInput"]
};
	["BookingCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["BookingCreateManyWorkspaceInput"]>
};
	["BookingCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutAdBoardInput"]
};
	["AdBoardCreateOrConnectWithoutHistoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutHistoryInput"]
};
	["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]: {
		where: GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"],
	create: GraphQLTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardPublishHistoryCreateManyPublishedByInput"]>
};
	["AdBoardPublishHistoryCreateManyPublishedByInput"]: {
		id?: string | undefined,
	adBoardId: string,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	changesSnapshot: GraphQLTypes["JSON"]
};
	["UserCreateOrConnectWithoutAuditsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutAuditsInput"]
};
	["AuditCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AuditWhereUniqueInput"],
	create: GraphQLTypes["AuditCreateWithoutWorkspaceInput"]
};
	["AuditCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["AuditCreateManyWorkspaceInput"]>
};
	["AuditCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	userId: string,
	updates?: GraphQLTypes["JSON"] | undefined
};
	["WorkspaceCreateOrConnectWithoutPlayersInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutPlayersInput"]
};
	["PlayerCreateOrConnectWithoutAdBoardGroupInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	create: GraphQLTypes["PlayerCreateWithoutAdBoardGroupInput"]
};
	["PlayerCreateManyAdBoardGroupInputEnvelope"]: {
		data: Array<GraphQLTypes["PlayerCreateManyAdBoardGroupInput"]>
};
	["PlayerCreateManyAdBoardGroupInput"]: {
		id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	workpaceId?: string | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId?: Array<string> | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardIds?: Array<string> | undefined
};
	["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]
};
	["PlaylistCreateNestedManyWithoutFooterPlaylistInput"]: {
		create?: Array<GraphQLTypes["PlaylistCreateWithoutFooterPlaylistInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]> | undefined,
	createMany?: GraphQLTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined
};
	["PlaylistCreateWithoutFooterPlaylistInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"]: {
		create?: Array<GraphQLTypes["PlaylistCreateWithoutSidebarPlaylistInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]> | undefined,
	createMany?: GraphQLTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined
};
	["PlaylistCreateWithoutSidebarPlaylistInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	create: GraphQLTypes["PlaylistCreateWithoutSidebarPlaylistInput"]
};
	["PlaylistCreateManySidebarPlaylistInputEnvelope"]: {
		data: Array<GraphQLTypes["PlaylistCreateManySidebarPlaylistInput"]>
};
	["PlaylistCreateManySidebarPlaylistInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	create: GraphQLTypes["PlaylistCreateWithoutFooterPlaylistInput"]
};
	["PlaylistCreateManyFooterPlaylistInputEnvelope"]: {
		data: Array<GraphQLTypes["PlaylistCreateManyFooterPlaylistInput"]>
};
	["PlaylistCreateManyFooterPlaylistInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	create: GraphQLTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutWorkspaceInput"]
};
	["AdBoardGroupCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardGroupCreateManyWorkspaceInput"]>
};
	["AdBoardGroupCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	name: string,
	adBoardId?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	scheduledPlaylistsIDs?: Array<string> | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined
};
	["WorkspaceCreateOrConnectWithoutPlaylistsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	create: GraphQLTypes["PlaylistCreateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardGroupCreateManyAdsPlaylistInput"]>
};
	["AdBoardGroupCreateManyAdsPlaylistInput"]: {
		id?: string | undefined,
	name: string,
	adBoardId?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	scheduledPlaylistsIDs?: Array<string> | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	workspaceId: string
};
	["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	create: GraphQLTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	create: GraphQLTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"]
};
	["PlaylistCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	create: GraphQLTypes["PlaylistCreateWithoutWorkspaceInput"]
};
	["PlaylistCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["PlaylistCreateManyWorkspaceInput"]>
};
	["PlaylistCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined
};
	["WorkspaceCreateOrConnectWithoutAdBoardsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutAdBoardGroupInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutAdBoardGroupInput"]
};
	["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"]
};
	["PlayerCreateOrConnectWithoutAdBoardsInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	create: GraphQLTypes["PlayerCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutWorkspaceInput"]
};
	["AdBoardCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardCreateManyWorkspaceInput"]>
};
	["AdBoardCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["WorkspaceCreateOrConnectWithoutMembershipsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutMembershipsInput"]
};
	["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]: {
		create?: GraphQLTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceRoleWhereUniqueInput"] | undefined
};
	["WorkspaceRoleCreateWithoutMembershipsInput"]: {
		id?: string | undefined,
	name: string,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"] | undefined
};
	["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutWorkspaceRolesInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutWorkspaceRolesInput"]
};
	["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"]: {
		where: GraphQLTypes["WorkspaceRoleWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceRoleCreateWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutUserInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	create: GraphQLTypes["MembershipCreateWithoutUserInput"]
};
	["MembershipCreateManyUserInputEnvelope"]: {
		data: Array<GraphQLTypes["MembershipCreateManyUserInput"]>
};
	["MembershipCreateManyUserInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspaceId: string,
	roleId: string
};
	["UserCreateOrConnectWithoutChangelogsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutChangelogsInput"]
};
	["ChangelogCreateOrConnectWithoutBookingInput"]: {
		where: GraphQLTypes["ChangelogWhereUniqueInput"],
	create: GraphQLTypes["ChangelogCreateWithoutBookingInput"]
};
	["ChangelogCreateManyBookingInputEnvelope"]: {
		data: Array<GraphQLTypes["ChangelogCreateManyBookingInput"]>
};
	["ChangelogCreateManyBookingInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	userId: string,
	updates?: GraphQLTypes["JSON"] | undefined
};
	["BookingCreateOrConnectWithoutSharedUsersInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutSharedUsersInput"]
};
	["UserCreateOrConnectWithoutAdBoardsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutBookingsInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAdCategoryInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutAdCategoryInput"]
};
	["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]: {
		where: GraphQLTypes["AdvertisementCategoryWhereUniqueInput"],
	create: GraphQLTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutUserInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	create: GraphQLTypes["CampaignCreateWithoutUserInput"]
};
	["CampaignCreateManyUserInputEnvelope"]: {
		data: Array<GraphQLTypes["CampaignCreateManyUserInput"]>
};
	["CampaignCreateManyUserInput"]: {
		id?: string | undefined,
	name: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	businessCategoryId: string,
	adCategoryId?: Array<string> | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined
};
	["UserCreateOrConnectWithoutMembershipsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutMembershipsInput"]
};
	["MembershipCreateOrConnectWithoutWorkspaceInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	create: GraphQLTypes["MembershipCreateWithoutWorkspaceInput"]
};
	["MembershipCreateManyWorkspaceInputEnvelope"]: {
		data: Array<GraphQLTypes["MembershipCreateManyWorkspaceInput"]>
};
	["MembershipCreateManyWorkspaceInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	userId: string,
	roleId: string
};
	["WorkspaceCreateOrConnectWithoutAuditsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutAuditsInput"]
};
	["AuditCreateOrConnectWithoutUserInput"]: {
		where: GraphQLTypes["AuditWhereUniqueInput"],
	create: GraphQLTypes["AuditCreateWithoutUserInput"]
};
	["AuditCreateManyUserInputEnvelope"]: {
		data: Array<GraphQLTypes["AuditCreateManyUserInput"]>
};
	["AuditCreateManyUserInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	workspaceId?: string | undefined,
	updates?: GraphQLTypes["JSON"] | undefined
};
	["UserCreateOrConnectWithoutOrdersInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutOrdersInput"]
};
	["OrderCreateOrConnectWithoutAdBoardInput"]: {
		where: GraphQLTypes["OrderWhereUniqueInput"],
	create: GraphQLTypes["OrderCreateWithoutAdBoardInput"]
};
	["OrderCreateManyAdBoardInputEnvelope"]: {
		data: Array<GraphQLTypes["OrderCreateManyAdBoardInput"]>
};
	["OrderCreateManyAdBoardInput"]: {
		id?: string | undefined,
	createdDate?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: GraphQLTypes["PriceSummaryCreateInput"],
	selectedDate: GraphQLTypes["OrderSelectedDateCreateInput"]
};
	["AdBoardCreateOrConnectWithoutCreatedByInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutCreatedByInput"]
};
	["AdBoardCreateManyCreatedByInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardCreateManyCreatedByInput"]>
};
	["AdBoardCreateManyCreatedByInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["UserCreateOrConnectWithoutBookingsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutChangelogsInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutChangelogsInput"]
};
	["ChangelogCreateOrConnectWithoutUserInput"]: {
		where: GraphQLTypes["ChangelogWhereUniqueInput"],
	create: GraphQLTypes["ChangelogCreateWithoutUserInput"]
};
	["ChangelogCreateManyUserInputEnvelope"]: {
		data: Array<GraphQLTypes["ChangelogCreateManyUserInput"]>
};
	["ChangelogCreateManyUserInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	bookingId?: string | undefined,
	updates?: GraphQLTypes["JSON"] | undefined
};
	["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutOfflineBookingAdboardsInput"]
};
	["AdBoardCreateOrConnectWithoutOrdersInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutOrdersInput"]
};
	["OrderCreateOrConnectWithoutCreatedByInput"]: {
		where: GraphQLTypes["OrderWhereUniqueInput"],
	create: GraphQLTypes["OrderCreateWithoutCreatedByInput"]
};
	["OrderCreateManyCreatedByInputEnvelope"]: {
		data: Array<GraphQLTypes["OrderCreateManyCreatedByInput"]>
};
	["OrderCreateManyCreatedByInput"]: {
		id?: string | undefined,
	adBoardId: string,
	createdDate?: GraphQLTypes["DateTime"] | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: GraphQLTypes["PriceSummaryCreateInput"],
	selectedDate: GraphQLTypes["OrderSelectedDateCreateInput"]
};
	["UserCreateOrConnectWithoutOwnedWorkspacesInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutOwnedWorkspacesInput"]
};
	["WorkspaceCreateOrConnectWithoutFilesInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutFilesInput"]
};
	["FileCreateOrConnectWithoutUpdatedByInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	create: GraphQLTypes["FileCreateWithoutUpdatedByInput"]
};
	["FileCreateManyUpdatedByInputEnvelope"]: {
		data: Array<GraphQLTypes["FileCreateManyUpdatedByInput"]>
};
	["FileCreateManyUpdatedByInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["UserCreateOrConnectWithoutCreatedFilesInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutCreatedFilesInput"]
};
	["FileCreateOrConnectWithoutProofBookingInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	create: GraphQLTypes["FileCreateWithoutProofBookingInput"]
};
	["FileCreateManyProofBookingInputEnvelope"]: {
		data: Array<GraphQLTypes["FileCreateManyProofBookingInput"]>
};
	["FileCreateManyProofBookingInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["BookingCreateOrConnectWithoutBusinessCategoryInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutBusinessCategoryInput"]
};
	["BookingCreateManyBusinessCategoryInputEnvelope"]: {
		data: Array<GraphQLTypes["BookingCreateManyBusinessCategoryInput"]>
};
	["BookingCreateManyBusinessCategoryInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["BusinessCategoryCreateOrConnectWithoutCampaignsInput"]: {
		where: GraphQLTypes["BusinessCategoryWhereUniqueInput"],
	create: GraphQLTypes["BusinessCategoryCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutAdCategoryInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	create: GraphQLTypes["CampaignCreateWithoutAdCategoryInput"]
};
	["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]: {
		where: GraphQLTypes["AdvertisementCategoryWhereUniqueInput"],
	create: GraphQLTypes["AdvertisementCategoryCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAssetsInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutAssetsInput"]
};
	["FileCreateOrConnectWithoutCreatedByInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	create: GraphQLTypes["FileCreateWithoutCreatedByInput"]
};
	["FileCreateManyCreatedByInputEnvelope"]: {
		data: Array<GraphQLTypes["FileCreateManyCreatedByInput"]>
};
	["FileCreateManyCreatedByInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["UserCreateOrConnectWithoutCampaignsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutCampaignsInput"]
};
	["CampaignCreateOrConnectWithoutBusinessCategoryInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	create: GraphQLTypes["CampaignCreateWithoutBusinessCategoryInput"]
};
	["CampaignCreateManyBusinessCategoryInputEnvelope"]: {
		data: Array<GraphQLTypes["CampaignCreateManyBusinessCategoryInput"]>
};
	["CampaignCreateManyBusinessCategoryInput"]: {
		id?: string | undefined,
	name: string,
	userId: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined
};
	["BusinessCategoryCreateOrConnectWithoutBookingsInput"]: {
		where: GraphQLTypes["BusinessCategoryWhereUniqueInput"],
	create: GraphQLTypes["BusinessCategoryCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutUserInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutUserInput"]
};
	["BookingCreateManyUserInputEnvelope"]: {
		data: Array<GraphQLTypes["BookingCreateManyUserInput"]>
};
	["BookingCreateManyUserInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["UserCreateOrConnectWithoutUpdatedFilesInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutUpdatedFilesInput"]
};
	["FileCreateOrConnectWithoutBookingsInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	create: GraphQLTypes["FileCreateWithoutBookingsInput"]
};
	["BookingCreateOrConnectWithoutAdBoardInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	create: GraphQLTypes["BookingCreateWithoutAdBoardInput"]
};
	["BookingCreateManyAdBoardInputEnvelope"]: {
		data: Array<GraphQLTypes["BookingCreateManyAdBoardInput"]>
};
	["BookingCreateManyAdBoardInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["AdBoardCreateOrConnectWithoutCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutCategoryInput"]
};
	["AdBoardCreateManyCategoryInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardCreateManyCategoryInput"]>
};
	["AdBoardCreateManyCategoryInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]: {
		where: GraphQLTypes["BusinessCategoryWhereUniqueInput"],
	create: GraphQLTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutLocationCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutLocationCategoryInput"]
};
	["AdBoardCreateManyLocationCategoryInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardCreateManyLocationCategoryInput"]>
};
	["AdBoardCreateManyLocationCategoryInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"]: {
		where: GraphQLTypes["LocationCategoryWhereUniqueInput"],
	create: GraphQLTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"]
};
	["LocationCategoryWhereUniqueInput"]: {
		id?: string | undefined,
	AND?: Array<GraphQLTypes["LocationCategoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["LocationCategoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["LocationCategoryWhereInput"]> | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	adBoards?: GraphQLTypes["AdBoardListRelationFilter"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryListRelationFilter"] | undefined
};
	["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"]: {
		where: GraphQLTypes["LocationSubCategoryWhereUniqueInput"],
	create: GraphQLTypes["LocationSubCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutRestrictedBusinesInput"]
};
	["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"]: {
		where: GraphQLTypes["BusinessCategoryWhereUniqueInput"],
	create: GraphQLTypes["BusinessCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutLocationSubCategoryInput"]
};
	["AdBoardCreateManyLocationSubCategoryInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardCreateManyLocationSubCategoryInput"]>
};
	["AdBoardCreateManyLocationSubCategoryInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	adBoardTypeId?: string | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]: {
		where: GraphQLTypes["LocationSubCategoryWhereUniqueInput"],
	create: GraphQLTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"]: {
		data: Array<GraphQLTypes["LocationSubCategoryCreateManyLocationCategoryInput"]>
};
	["LocationSubCategoryCreateManyLocationCategoryInput"]: {
		id?: string | undefined,
	name: string
};
	["LocationCategoryCreateOrConnectWithoutAdBoardsInput"]: {
		where: GraphQLTypes["LocationCategoryWhereUniqueInput"],
	create: GraphQLTypes["LocationCategoryCreateWithoutAdBoardsInput"]
};
	["AdBoardUpdateManyMutationInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdBoardUpdateInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
		create?: GraphQLTypes["LocationCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: GraphQLTypes["LocationCategoryUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["LocationCategoryWhereInput"] | undefined,
	connect?: GraphQLTypes["LocationCategoryWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["LocationCategoryUpsertWithoutAdBoardsInput"]: {
		update: GraphQLTypes["LocationCategoryUpdateWithoutAdBoardsInput"],
	create: GraphQLTypes["LocationCategoryCreateWithoutAdBoardsInput"],
	where?: GraphQLTypes["LocationCategoryWhereInput"] | undefined
};
	["LocationCategoryUpdateWithoutAdBoardsInput"]: {
		name?: string | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"] | undefined
};
	["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"]: {
		create?: Array<GraphQLTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]> | undefined,
	upsert?: Array<GraphQLTypes["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["LocationSubCategoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["LocationSubCategoryWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["LocationSubCategoryWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["LocationSubCategoryWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["LocationSubCategoryScalarWhereInput"]> | undefined
};
	["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]: {
		where: GraphQLTypes["LocationSubCategoryWhereUniqueInput"],
	update: GraphQLTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"],
	create: GraphQLTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryUpdateWithoutLocationCategoryInput"]: {
		name?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutLocationSubCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutLocationSubCategoryInput"],
	create: GraphQLTypes["AdBoardCreateWithoutLocationSubCategoryInput"]
};
	["AdBoardUpdateWithoutLocationSubCategoryInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"]: {
		create?: GraphQLTypes["AdBoardTypeCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardTypeUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["AdBoardTypeWhereInput"] | undefined,
	connect?: GraphQLTypes["AdBoardTypeWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["AdBoardTypeUpsertWithoutAdBoardsInput"]: {
		update: GraphQLTypes["AdBoardTypeUpdateWithoutAdBoardsInput"],
	create: GraphQLTypes["AdBoardTypeCreateWithoutAdBoardsInput"],
	where?: GraphQLTypes["AdBoardTypeWhereInput"] | undefined
};
	["AdBoardTypeUpdateWithoutAdBoardsInput"]: {
		name?: string | undefined,
	description?: string | undefined
};
	["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"]: {
		where?: GraphQLTypes["AdBoardTypeWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardTypeUpdateWithoutAdBoardsInput"]
};
	["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
		create?: GraphQLTypes["BusinessCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: GraphQLTypes["BusinessCategoryUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	connect?: GraphQLTypes["BusinessCategoryWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["BusinessCategoryUpsertWithoutAdBoardsInput"]: {
		update: GraphQLTypes["BusinessCategoryUpdateWithoutAdBoardsInput"],
	create: GraphQLTypes["BusinessCategoryCreateWithoutAdBoardsInput"],
	where?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined
};
	["BusinessCategoryUpdateWithoutAdBoardsInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutRestrictedBusinesInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]> | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutRestrictedBusinesInput"],
	create: GraphQLTypes["AdBoardCreateWithoutRestrictedBusinesInput"]
};
	["AdBoardUpdateWithoutRestrictedBusinesInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"]: {
		create?: GraphQLTypes["LocationSubCategoryCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: GraphQLTypes["LocationSubCategoryUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["LocationSubCategoryWhereInput"] | undefined,
	connect?: GraphQLTypes["LocationSubCategoryWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["LocationSubCategoryUpsertWithoutAdBoardsInput"]: {
		update: GraphQLTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"],
	create: GraphQLTypes["LocationSubCategoryCreateWithoutAdBoardsInput"],
	where?: GraphQLTypes["LocationSubCategoryWhereInput"] | undefined
};
	["LocationSubCategoryUpdateWithoutAdBoardsInput"]: {
		name?: string | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"] | undefined
};
	["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"]: {
		create?: GraphQLTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"] | undefined,
	connectOrCreate?: GraphQLTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"] | undefined,
	upsert?: GraphQLTypes["LocationCategoryUpsertWithoutLocationSubCategoryInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["LocationCategoryWhereInput"] | undefined,
	connect?: GraphQLTypes["LocationCategoryWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"] | undefined
};
	["LocationCategoryUpsertWithoutLocationSubCategoryInput"]: {
		update: GraphQLTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"],
	create: GraphQLTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"],
	where?: GraphQLTypes["LocationCategoryWhereInput"] | undefined
};
	["LocationCategoryUpdateWithoutLocationSubCategoryInput"]: {
		name?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutLocationCategoryNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutLocationCategoryNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutLocationCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyLocationCategoryInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutLocationCategoryInput"],
	create: GraphQLTypes["AdBoardCreateWithoutLocationCategoryInput"]
};
	["AdBoardUpdateWithoutLocationCategoryInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"]: {
		create?: Array<GraphQLTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]> | undefined,
	set?: Array<GraphQLTypes["BusinessCategoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BusinessCategoryWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BusinessCategoryWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BusinessCategoryWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BusinessCategoryScalarWhereInput"]> | undefined
};
	["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: {
		where: GraphQLTypes["BusinessCategoryWhereUniqueInput"],
	update: GraphQLTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"],
	create: GraphQLTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]
};
	["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutCategoryNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutCategoryInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyCategoryInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutCategoryInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutCategoryInput"],
	create: GraphQLTypes["AdBoardCreateWithoutCategoryInput"]
};
	["AdBoardUpdateWithoutCategoryInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["BookingUpdateManyWithoutAdBoardNestedInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BookingUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	update: GraphQLTypes["BookingUpdateWithoutAdBoardInput"],
	create: GraphQLTypes["BookingCreateWithoutAdBoardInput"]
};
	["BookingUpdateWithoutAdBoardInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["FileUpdateManyWithoutBookingsNestedInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutBookingsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutBookingsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["FileUpsertWithWhereUniqueWithoutBookingsInput"]> | undefined,
	set?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["FileUpdateWithWhereUniqueWithoutBookingsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["FileUpdateManyWithWhereWithoutBookingsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutBookingsInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	update: GraphQLTypes["FileUpdateWithoutBookingsInput"],
	create: GraphQLTypes["FileCreateWithoutBookingsInput"]
};
	["FileUpdateWithoutBookingsInput"]: {
		contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: GraphQLTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: GraphQLTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["UserUpdateOneWithoutUpdatedFilesNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutUpdatedFilesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutUpdatedFilesInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutUpdatedFilesInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["UserWhereInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"] | undefined
};
	["UserUpsertWithoutUpdatedFilesInput"]: {
		update: GraphQLTypes["UserUpdateWithoutUpdatedFilesInput"],
	create: GraphQLTypes["UserCreateWithoutUpdatedFilesInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutUpdatedFilesInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["BookingUpdateManyWithoutUserNestedInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BookingUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyUserInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BookingUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BookingUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	update: GraphQLTypes["BookingUpdateWithoutUserInput"],
	create: GraphQLTypes["BookingCreateWithoutUserInput"]
};
	["BookingUpdateWithoutUserInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["BusinessCategoryUpdateOneWithoutBookingsNestedInput"]: {
		create?: GraphQLTypes["BusinessCategoryCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: GraphQLTypes["BusinessCategoryUpsertWithoutBookingsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	connect?: GraphQLTypes["BusinessCategoryWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["BusinessCategoryUpsertWithoutBookingsInput"]: {
		update: GraphQLTypes["BusinessCategoryUpdateWithoutBookingsInput"],
	create: GraphQLTypes["BusinessCategoryCreateWithoutBookingsInput"],
	where?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined
};
	["BusinessCategoryUpdateWithoutBookingsInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["CampaignUpdateManyWithoutBusinessCategoryNestedInput"]: {
		create?: Array<GraphQLTypes["CampaignCreateWithoutBusinessCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["CampaignCreateManyBusinessCategoryInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CampaignScalarWhereInput"]> | undefined
};
	["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	update: GraphQLTypes["CampaignUpdateWithoutBusinessCategoryInput"],
	create: GraphQLTypes["CampaignCreateWithoutBusinessCategoryInput"]
};
	["CampaignUpdateWithoutBusinessCategoryInput"]: {
		name?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: GraphQLTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutCampaignsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutCampaignsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutCampaignsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutCampaignsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutCampaignsInput"] | undefined
};
	["UserUpsertWithoutCampaignsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutCampaignsInput"],
	create: GraphQLTypes["UserCreateWithoutCampaignsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutCampaignsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["FileUpdateManyWithoutCreatedByNestedInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutCreatedByInput"]> | undefined,
	upsert?: Array<GraphQLTypes["FileUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	createMany?: GraphQLTypes["FileCreateManyCreatedByInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["FileUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["FileUpdateManyWithWhereWithoutCreatedByInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutCreatedByInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	update: GraphQLTypes["FileUpdateWithoutCreatedByInput"],
	create: GraphQLTypes["FileCreateWithoutCreatedByInput"]
};
	["FileUpdateWithoutCreatedByInput"]: {
		contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: GraphQLTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: GraphQLTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["BookingUpdateManyWithoutAssetsNestedInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutAssetsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutAssetsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BookingUpsertWithWhereUniqueWithoutAssetsInput"]> | undefined,
	set?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BookingUpdateWithWhereUniqueWithoutAssetsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BookingUpdateManyWithWhereWithoutAssetsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutAssetsInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	update: GraphQLTypes["BookingUpdateWithoutAssetsInput"],
	create: GraphQLTypes["BookingCreateWithoutAssetsInput"]
};
	["BookingUpdateWithoutAssetsInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"]: {
		create?: Array<GraphQLTypes["AdvertisementCategoryCreateWithoutBookingsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]> | undefined,
	set?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdvertisementCategoryScalarWhereInput"]> | undefined
};
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]: {
		where: GraphQLTypes["AdvertisementCategoryWhereUniqueInput"],
	update: GraphQLTypes["AdvertisementCategoryUpdateWithoutBookingsInput"],
	create: GraphQLTypes["AdvertisementCategoryCreateWithoutBookingsInput"]
};
	["AdvertisementCategoryUpdateWithoutBookingsInput"]: {
		name?: string | undefined,
	type?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"] | undefined
};
	["CampaignUpdateManyWithoutAdCategoryNestedInput"]: {
		create?: Array<GraphQLTypes["CampaignCreateWithoutAdCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CampaignCreateOrConnectWithoutAdCategoryInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]> | undefined,
	set?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CampaignScalarWhereInput"]> | undefined
};
	["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	update: GraphQLTypes["CampaignUpdateWithoutAdCategoryInput"],
	create: GraphQLTypes["CampaignCreateWithoutAdCategoryInput"]
};
	["CampaignUpdateWithoutAdCategoryInput"]: {
		name?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: GraphQLTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"]: {
		create?: GraphQLTypes["BusinessCategoryCreateWithoutCampaignsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"] | undefined,
	upsert?: GraphQLTypes["BusinessCategoryUpsertWithoutCampaignsInput"] | undefined,
	connect?: GraphQLTypes["BusinessCategoryWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"] | undefined
};
	["BusinessCategoryUpsertWithoutCampaignsInput"]: {
		update: GraphQLTypes["BusinessCategoryUpdateWithoutCampaignsInput"],
	create: GraphQLTypes["BusinessCategoryCreateWithoutCampaignsInput"],
	where?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined
};
	["BusinessCategoryUpdateWithoutCampaignsInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["BookingUpdateManyWithoutBusinessCategoryNestedInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutBusinessCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyBusinessCategoryInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	update: GraphQLTypes["BookingUpdateWithoutBusinessCategoryInput"],
	create: GraphQLTypes["BookingCreateWithoutBusinessCategoryInput"]
};
	["BookingUpdateWithoutBusinessCategoryInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["FileUpdateManyWithoutProofBookingNestedInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutProofBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutProofBookingInput"]> | undefined,
	upsert?: Array<GraphQLTypes["FileUpsertWithWhereUniqueWithoutProofBookingInput"]> | undefined,
	createMany?: GraphQLTypes["FileCreateManyProofBookingInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["FileUpdateWithWhereUniqueWithoutProofBookingInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["FileUpdateManyWithWhereWithoutProofBookingInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutProofBookingInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	update: GraphQLTypes["FileUpdateWithoutProofBookingInput"],
	create: GraphQLTypes["FileCreateWithoutProofBookingInput"]
};
	["FileUpdateWithoutProofBookingInput"]: {
		contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: GraphQLTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: GraphQLTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutCreatedFilesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutCreatedFilesInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutCreatedFilesInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutCreatedFilesInput"] | undefined
};
	["UserUpsertWithoutCreatedFilesInput"]: {
		update: GraphQLTypes["UserUpdateWithoutCreatedFilesInput"],
	create: GraphQLTypes["UserCreateWithoutCreatedFilesInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutCreatedFilesInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["FileUpdateManyWithoutUpdatedByNestedInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutUpdatedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutUpdatedByInput"]> | undefined,
	upsert?: Array<GraphQLTypes["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]> | undefined,
	createMany?: GraphQLTypes["FileCreateManyUpdatedByInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["FileUpdateManyWithWhereWithoutUpdatedByInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	update: GraphQLTypes["FileUpdateWithoutUpdatedByInput"],
	create: GraphQLTypes["FileCreateWithoutUpdatedByInput"]
};
	["FileUpdateWithoutUpdatedByInput"]: {
		contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: GraphQLTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutFilesNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutFilesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutFilesInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutFilesInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutFilesInput"] | undefined
};
	["WorkspaceUpsertWithoutFilesInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutFilesInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutFilesInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutFilesInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["UserUpdateOneWithoutOwnedWorkspacesNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutOwnedWorkspacesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutOwnedWorkspacesInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["UserWhereInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"] | undefined
};
	["UserUpsertWithoutOwnedWorkspacesInput"]: {
		update: GraphQLTypes["UserUpdateWithoutOwnedWorkspacesInput"],
	create: GraphQLTypes["UserCreateWithoutOwnedWorkspacesInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutOwnedWorkspacesInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["OrderUpdateManyWithoutCreatedByNestedInput"]: {
		create?: Array<GraphQLTypes["OrderCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["OrderCreateOrConnectWithoutCreatedByInput"]> | undefined,
	upsert?: Array<GraphQLTypes["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	createMany?: GraphQLTypes["OrderCreateManyCreatedByInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["OrderUpdateManyWithWhereWithoutCreatedByInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["OrderScalarWhereInput"]> | undefined
};
	["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]: {
		where: GraphQLTypes["OrderWhereUniqueInput"],
	update: GraphQLTypes["OrderUpdateWithoutCreatedByInput"],
	create: GraphQLTypes["OrderCreateWithoutCreatedByInput"]
};
	["OrderUpdateWithoutCreatedByInput"]: {
		createdDate?: GraphQLTypes["DateTime"] | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDate?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutOrdersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutOrdersInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutOrdersInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutOrdersInput"] | undefined
};
	["AdBoardUpsertWithoutOrdersInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutOrdersInput"],
	create: GraphQLTypes["AdBoardCreateWithoutOrdersInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutOrdersInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"]: {
		create?: Array<GraphQLTypes["SlotConfigurationCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<GraphQLTypes["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["SlotConfigurationWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["SlotConfigurationWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["SlotConfigurationWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["SlotConfigurationWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["SlotConfigurationScalarWhereInput"]> | undefined
};
	["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["SlotConfigurationWhereUniqueInput"],
	update: GraphQLTypes["SlotConfigurationUpdateWithoutAdBoardInput"],
	create: GraphQLTypes["SlotConfigurationCreateWithoutAdBoardInput"]
};
	["SlotConfigurationUpdateWithoutAdBoardInput"]: {
		date?: GraphQLTypes["DateTime"] | undefined,
	availableSlots?: number | undefined
};
	["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["SlotConfigurationWhereUniqueInput"],
	data: GraphQLTypes["SlotConfigurationUpdateWithoutAdBoardInput"]
};
	["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]: {
		where: GraphQLTypes["SlotConfigurationScalarWhereInput"],
	data: GraphQLTypes["SlotConfigurationUpdateManyMutationInput"]
};
	["SlotConfigurationScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["SlotConfigurationScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["SlotConfigurationScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SlotConfigurationScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["DateTimeFilter"] | undefined,
	availableSlots?: GraphQLTypes["IntFilter"] | undefined
};
	["SlotConfigurationUpdateManyMutationInput"]: {
		date?: GraphQLTypes["DateTime"] | undefined,
	availableSlots?: number | undefined
};
	["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutOfflineBookingAdboardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutOfflineBookingAdboardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["UserWhereInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"] | undefined
};
	["UserUpsertWithoutOfflineBookingAdboardsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutOfflineBookingAdboardsInput"],
	create: GraphQLTypes["UserCreateWithoutOfflineBookingAdboardsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutOfflineBookingAdboardsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["ChangelogUpdateManyWithoutUserNestedInput"]: {
		create?: Array<GraphQLTypes["ChangelogCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["ChangelogCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<GraphQLTypes["ChangelogUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["ChangelogCreateManyUserInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["ChangelogUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["ChangelogUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["ChangelogScalarWhereInput"]> | undefined
};
	["ChangelogUpsertWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["ChangelogWhereUniqueInput"],
	update: GraphQLTypes["ChangelogUpdateWithoutUserInput"],
	create: GraphQLTypes["ChangelogCreateWithoutUserInput"]
};
	["ChangelogUpdateWithoutUserInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	booking?: GraphQLTypes["BookingUpdateOneWithoutChangelogsNestedInput"] | undefined
};
	["BookingUpdateOneWithoutChangelogsNestedInput"]: {
		create?: GraphQLTypes["BookingCreateWithoutChangelogsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BookingCreateOrConnectWithoutChangelogsInput"] | undefined,
	upsert?: GraphQLTypes["BookingUpsertWithoutChangelogsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["BookingWhereInput"] | undefined,
	connect?: GraphQLTypes["BookingWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["BookingUpdateToOneWithWhereWithoutChangelogsInput"] | undefined
};
	["BookingUpsertWithoutChangelogsInput"]: {
		update: GraphQLTypes["BookingUpdateWithoutChangelogsInput"],
	create: GraphQLTypes["BookingCreateWithoutChangelogsInput"],
	where?: GraphQLTypes["BookingWhereInput"] | undefined
};
	["BookingUpdateWithoutChangelogsInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutBookingsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutBookingsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["UserUpsertWithoutBookingsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutBookingsInput"],
	create: GraphQLTypes["UserCreateWithoutBookingsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutBookingsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutCreatedByNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutCreatedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutCreatedByInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyCreatedByInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutCreatedByInput"],
	create: GraphQLTypes["AdBoardCreateWithoutCreatedByInput"]
};
	["AdBoardUpdateWithoutCreatedByInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["OrderUpdateManyWithoutAdBoardNestedInput"]: {
		create?: Array<GraphQLTypes["OrderCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["OrderCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<GraphQLTypes["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["OrderCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["OrderWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["OrderUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["OrderScalarWhereInput"]> | undefined
};
	["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["OrderWhereUniqueInput"],
	update: GraphQLTypes["OrderUpdateWithoutAdBoardInput"],
	create: GraphQLTypes["OrderCreateWithoutAdBoardInput"]
};
	["OrderUpdateWithoutAdBoardInput"]: {
		createdDate?: GraphQLTypes["DateTime"] | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDate?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutOrdersNestedInput"] | undefined
};
	["UserUpdateOneWithoutOrdersNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutOrdersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutOrdersInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutOrdersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["UserWhereInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutOrdersInput"] | undefined
};
	["UserUpsertWithoutOrdersInput"]: {
		update: GraphQLTypes["UserUpdateWithoutOrdersInput"],
	create: GraphQLTypes["UserCreateWithoutOrdersInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutOrdersInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AuditUpdateManyWithoutUserNestedInput"]: {
		create?: Array<GraphQLTypes["AuditCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AuditCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AuditUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["AuditCreateManyUserInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AuditUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AuditUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AuditScalarWhereInput"]> | undefined
};
	["AuditUpsertWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["AuditWhereUniqueInput"],
	update: GraphQLTypes["AuditUpdateWithoutUserInput"],
	create: GraphQLTypes["AuditCreateWithoutUserInput"]
};
	["AuditUpdateWithoutUserInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutAuditsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutAuditsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutAuditsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutAuditsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"] | undefined
};
	["WorkspaceUpsertWithoutAuditsInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutAuditsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutAuditsInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutAuditsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["MembershipUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["MembershipCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["MembershipCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["MembershipCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["MembershipScalarWhereInput"]> | undefined
};
	["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	update: GraphQLTypes["MembershipUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["MembershipCreateWithoutWorkspaceInput"]
};
	["MembershipUpdateWithoutWorkspaceInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	role?: GraphQLTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutMembershipsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutMembershipsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutMembershipsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutMembershipsInput"] | undefined
};
	["UserUpsertWithoutMembershipsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutMembershipsInput"],
	create: GraphQLTypes["UserCreateWithoutMembershipsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutMembershipsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["CampaignUpdateManyWithoutUserNestedInput"]: {
		create?: Array<GraphQLTypes["CampaignCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CampaignCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CampaignUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["CampaignCreateManyUserInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CampaignUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CampaignUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CampaignScalarWhereInput"]> | undefined
};
	["CampaignUpsertWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	update: GraphQLTypes["CampaignUpdateWithoutUserInput"],
	create: GraphQLTypes["CampaignCreateWithoutUserInput"]
};
	["CampaignUpdateWithoutUserInput"]: {
		name?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: GraphQLTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"]: {
		create?: Array<GraphQLTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]> | undefined,
	set?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdvertisementCategoryWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdvertisementCategoryScalarWhereInput"]> | undefined
};
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]: {
		where: GraphQLTypes["AdvertisementCategoryWhereUniqueInput"],
	update: GraphQLTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"],
	create: GraphQLTypes["AdvertisementCategoryCreateWithoutCampaignsInput"]
};
	["AdvertisementCategoryUpdateWithoutCampaignsInput"]: {
		name?: string | undefined,
	type?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdCategoryNestedInput"] | undefined
};
	["BookingUpdateManyWithoutAdCategoryNestedInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutAdCategoryInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutAdCategoryInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]> | undefined,
	set?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BookingUpdateManyWithWhereWithoutAdCategoryInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	update: GraphQLTypes["BookingUpdateWithoutAdCategoryInput"],
	create: GraphQLTypes["BookingCreateWithoutAdCategoryInput"]
};
	["BookingUpdateWithoutAdCategoryInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutBookingsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["AdBoardUpsertWithoutBookingsInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutBookingsInput"],
	create: GraphQLTypes["AdBoardCreateWithoutBookingsInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutBookingsInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["UserUpdateOneWithoutAdBoardsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutAdBoardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["UserWhereInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["UserUpsertWithoutAdBoardsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutAdBoardsInput"],
	create: GraphQLTypes["UserCreateWithoutAdBoardsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutAdBoardsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["BookingUpdateManyWithoutSharedUsersNestedInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutSharedUsersInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutSharedUsersInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]> | undefined,
	set?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BookingUpdateManyWithWhereWithoutSharedUsersInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	update: GraphQLTypes["BookingUpdateWithoutSharedUsersInput"],
	create: GraphQLTypes["BookingCreateWithoutSharedUsersInput"]
};
	["BookingUpdateWithoutSharedUsersInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["ChangelogUpdateManyWithoutBookingNestedInput"]: {
		create?: Array<GraphQLTypes["ChangelogCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["ChangelogCreateOrConnectWithoutBookingInput"]> | undefined,
	upsert?: Array<GraphQLTypes["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]> | undefined,
	createMany?: GraphQLTypes["ChangelogCreateManyBookingInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["ChangelogWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["ChangelogUpdateManyWithWhereWithoutBookingInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["ChangelogScalarWhereInput"]> | undefined
};
	["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]: {
		where: GraphQLTypes["ChangelogWhereUniqueInput"],
	update: GraphQLTypes["ChangelogUpdateWithoutBookingInput"],
	create: GraphQLTypes["ChangelogCreateWithoutBookingInput"]
};
	["ChangelogUpdateWithoutBookingInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutChangelogsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutChangelogsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutChangelogsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutChangelogsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutChangelogsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutChangelogsInput"] | undefined
};
	["UserUpsertWithoutChangelogsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutChangelogsInput"],
	create: GraphQLTypes["UserCreateWithoutChangelogsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutChangelogsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["MembershipUpdateManyWithoutUserNestedInput"]: {
		create?: Array<GraphQLTypes["MembershipCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["MembershipCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<GraphQLTypes["MembershipUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["MembershipCreateManyUserInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["MembershipUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["MembershipUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["MembershipScalarWhereInput"]> | undefined
};
	["MembershipUpsertWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	update: GraphQLTypes["MembershipUpdateWithoutUserInput"],
	create: GraphQLTypes["MembershipCreateWithoutUserInput"]
};
	["MembershipUpdateWithoutUserInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	role?: GraphQLTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutMembershipsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"] | undefined
};
	["WorkspaceUpsertWithoutMembershipsInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutMembershipsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutMembershipsInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutMembershipsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["AdBoardCreateWithoutWorkspaceInput"]
};
	["AdBoardUpdateWithoutWorkspaceInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["PlayerUpdateManyWithoutAdBoardsNestedInput"]: {
		create?: Array<GraphQLTypes["PlayerCreateWithoutAdBoardsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlayerCreateOrConnectWithoutAdBoardsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]> | undefined,
	set?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PlayerScalarWhereInput"]> | undefined
};
	["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	update: GraphQLTypes["PlayerUpdateWithoutAdBoardsInput"],
	create: GraphQLTypes["PlayerCreateWithoutAdBoardsInput"]
};
	["PlayerUpdateWithoutAdBoardsInput"]: {
		name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"]: {
		create?: GraphQLTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardGroupUpsertWithoutPisignagePlayersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined,
	connect?: GraphQLTypes["AdBoardGroupWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"] | undefined
};
	["AdBoardGroupUpsertWithoutPisignagePlayersInput"]: {
		update: GraphQLTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"],
	where?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined
};
	["AdBoardGroupUpdateWithoutPisignagePlayersInput"]: {
		name?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutAdBoardGroupInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutAdBoardGroupInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"] | undefined
};
	["AdBoardUpsertWithoutAdBoardGroupInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutAdBoardGroupInput"],
	create: GraphQLTypes["AdBoardCreateWithoutAdBoardGroupInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutAdBoardGroupInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutAdBoardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutAdBoardsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"] | undefined
};
	["WorkspaceUpsertWithoutAdBoardsInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutAdBoardsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutAdBoardsInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutAdBoardsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["PlaylistUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["PlaylistCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["PlaylistCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PlaylistScalarWhereInput"]> | undefined
};
	["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	update: GraphQLTypes["PlaylistUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["PlaylistCreateWithoutWorkspaceInput"]
};
	["PlaylistUpdateWithoutWorkspaceInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"]: {
		create?: GraphQLTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"] | undefined,
	upsert?: GraphQLTypes["PlaylistUpsertWithoutFooterParentPlaylistsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	connect?: GraphQLTypes["PlaylistWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"] | undefined
};
	["PlaylistUpsertWithoutFooterParentPlaylistsInput"]: {
		update: GraphQLTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"],
	create: GraphQLTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"],
	where?: GraphQLTypes["PlaylistWhereInput"] | undefined
};
	["PlaylistUpdateWithoutFooterParentPlaylistsInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"]: {
		create?: GraphQLTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"] | undefined,
	upsert?: GraphQLTypes["PlaylistUpsertWithoutSidebarParentPlaylistsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	connect?: GraphQLTypes["PlaylistWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"] | undefined
};
	["PlaylistUpsertWithoutSidebarParentPlaylistsInput"]: {
		update: GraphQLTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"],
	create: GraphQLTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"],
	where?: GraphQLTypes["PlaylistWhereInput"] | undefined
};
	["PlaylistUpdateWithoutSidebarParentPlaylistsInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined
};
	["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardGroupScalarWhereInput"]> | undefined
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	update: GraphQLTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupUpdateWithoutAdsPlaylistInput"]: {
		name?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"]: {
		create?: Array<GraphQLTypes["PlaylistCreateWithoutAdBoardGroupsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]> | undefined,
	set?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PlaylistScalarWhereInput"]> | undefined
};
	["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	update: GraphQLTypes["PlaylistUpdateWithoutAdBoardGroupsInput"],
	create: GraphQLTypes["PlaylistCreateWithoutAdBoardGroupsInput"]
};
	["PlaylistUpdateWithoutAdBoardGroupsInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutPlaylistsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutPlaylistsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"] | undefined
};
	["WorkspaceUpsertWithoutPlaylistsInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutPlaylistsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutPlaylistsInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutPlaylistsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardGroupCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardGroupScalarWhereInput"]> | undefined
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	update: GraphQLTypes["AdBoardGroupUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutWorkspaceInput"]
};
	["AdBoardGroupUpdateWithoutWorkspaceInput"]: {
		name?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined
};
	["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"]: {
		create?: GraphQLTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"] | undefined,
	upsert?: GraphQLTypes["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	connect?: GraphQLTypes["PlaylistWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"] | undefined
};
	["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"]: {
		update: GraphQLTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"],
	create: GraphQLTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"],
	where?: GraphQLTypes["PlaylistWhereInput"] | undefined
};
	["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]> | undefined,
	set?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardGroupWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardGroupScalarWhereInput"]> | undefined
};
	["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	update: GraphQLTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]
};
	["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"]: {
		name?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["PlayerUpdateManyWithoutAdBoardGroupNestedInput"]: {
		create?: Array<GraphQLTypes["PlayerCreateWithoutAdBoardGroupInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]> | undefined,
	createMany?: GraphQLTypes["PlayerCreateManyAdBoardGroupInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PlayerScalarWhereInput"]> | undefined
};
	["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	update: GraphQLTypes["PlayerUpdateWithoutAdBoardGroupInput"],
	create: GraphQLTypes["PlayerCreateWithoutAdBoardGroupInput"]
};
	["PlayerUpdateWithoutAdBoardGroupInput"]: {
		name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutPlayersNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutPlayersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutPlayersInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutPlayersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"] | undefined
};
	["WorkspaceUpsertWithoutPlayersInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutPlayersInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutPlayersInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutPlayersInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["AuditUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["AuditCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AuditCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["AuditCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AuditWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AuditUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AuditScalarWhereInput"]> | undefined
};
	["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AuditWhereUniqueInput"],
	update: GraphQLTypes["AuditUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["AuditCreateWithoutWorkspaceInput"]
};
	["AuditUpdateWithoutWorkspaceInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutAuditsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutAuditsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutAuditsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutAuditsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutAuditsInput"] | undefined
};
	["UserUpsertWithoutAuditsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutAuditsInput"],
	create: GraphQLTypes["UserCreateWithoutAuditsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutAuditsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["NotificationUpdateManyWithoutRecipientNestedInput"]: {
		create?: Array<GraphQLTypes["NotificationCreateWithoutRecipientInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["NotificationCreateOrConnectWithoutRecipientInput"]> | undefined,
	upsert?: Array<GraphQLTypes["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]> | undefined,
	createMany?: GraphQLTypes["NotificationCreateManyRecipientInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["NotificationWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["NotificationWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["NotificationWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["NotificationWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["NotificationUpdateManyWithWhereWithoutRecipientInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["NotificationScalarWhereInput"]> | undefined
};
	["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]: {
		where: GraphQLTypes["NotificationWhereUniqueInput"],
	update: GraphQLTypes["NotificationUpdateWithoutRecipientInput"],
	create: GraphQLTypes["NotificationCreateWithoutRecipientInput"]
};
	["NotificationUpdateWithoutRecipientInput"]: {
		type?: GraphQLTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined
};
	["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]: {
		where: GraphQLTypes["NotificationWhereUniqueInput"],
	data: GraphQLTypes["NotificationUpdateWithoutRecipientInput"]
};
	["NotificationUpdateManyWithWhereWithoutRecipientInput"]: {
		where: GraphQLTypes["NotificationScalarWhereInput"],
	data: GraphQLTypes["NotificationUpdateManyMutationInput"]
};
	["NotificationScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["NotificationScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["NotificationScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["NotificationScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumNotificationTypeFilter"] | undefined,
	title?: GraphQLTypes["StringFilter"] | undefined,
	content?: GraphQLTypes["StringFilter"] | undefined,
	metadata?: GraphQLTypes["JsonNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	readAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	recipientId?: GraphQLTypes["StringFilter"] | undefined,
	channels?: GraphQLTypes["EnumNotificationChannelNullableListFilter"] | undefined
};
	["NotificationUpdateManyMutationInput"]: {
		type?: GraphQLTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined
};
	["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined
};
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]: {
		where: GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"],
	update: GraphQLTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"],
	create: GraphQLTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryUpdateWithoutPublishedByInput"]: {
		publishedAt?: GraphQLTypes["DateTime"] | undefined,
	changesSnapshot?: GraphQLTypes["JSON"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutHistoryInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutHistoryInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutHistoryInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutHistoryInput"] | undefined
};
	["AdBoardUpsertWithoutHistoryInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutHistoryInput"],
	create: GraphQLTypes["AdBoardCreateWithoutHistoryInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutHistoryInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"]: {
		create?: GraphQLTypes["AdBoardGroupCreateWithoutAdBoardInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardGroupUpsertWithoutAdBoardInput"] | undefined,
	disconnect?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined,
	delete?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined,
	connect?: GraphQLTypes["AdBoardGroupWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"] | undefined
};
	["AdBoardGroupUpsertWithoutAdBoardInput"]: {
		update: GraphQLTypes["AdBoardGroupUpdateWithoutAdBoardInput"],
	create: GraphQLTypes["AdBoardGroupCreateWithoutAdBoardInput"],
	where?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined
};
	["AdBoardGroupUpdateWithoutAdBoardInput"]: {
		name?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutAdBoardGroupsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutAdBoardGroupsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"] | undefined
};
	["WorkspaceUpsertWithoutAdBoardGroupsInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutAdBoardGroupsInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutAdBoardGroupsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["BookingUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BookingUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	update: GraphQLTypes["BookingUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["BookingCreateWithoutWorkspaceInput"]
};
	["BookingUpdateWithoutWorkspaceInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["CampaignUpdateOneWithoutBookingsNestedInput"]: {
		create?: GraphQLTypes["CampaignCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["CampaignCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: GraphQLTypes["CampaignUpsertWithoutBookingsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["CampaignWhereInput"] | undefined,
	connect?: GraphQLTypes["CampaignWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["CampaignUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["CampaignUpsertWithoutBookingsInput"]: {
		update: GraphQLTypes["CampaignUpdateWithoutBookingsInput"],
	create: GraphQLTypes["CampaignCreateWithoutBookingsInput"],
	where?: GraphQLTypes["CampaignWhereInput"] | undefined
};
	["CampaignUpdateWithoutBookingsInput"]: {
		name?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	payments?: GraphQLTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["PaymentUpdateManyWithoutCampaignNestedInput"]: {
		create?: Array<GraphQLTypes["PaymentCreateWithoutCampaignInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PaymentCreateOrConnectWithoutCampaignInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]> | undefined,
	createMany?: GraphQLTypes["PaymentCreateManyCampaignInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PaymentWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PaymentWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PaymentWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PaymentWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PaymentUpdateManyWithWhereWithoutCampaignInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PaymentScalarWhereInput"]> | undefined
};
	["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]: {
		where: GraphQLTypes["PaymentWhereUniqueInput"],
	update: GraphQLTypes["PaymentUpdateWithoutCampaignInput"],
	create: GraphQLTypes["PaymentCreateWithoutCampaignInput"]
};
	["PaymentUpdateWithoutCampaignInput"]: {
		paymentIntentId?: string | undefined,
	checkoutSessionId?: string | undefined,
	eventId?: string | undefined,
	liveMode?: boolean | undefined,
	paymentGateway?: GraphQLTypes["PaymentGatewayName"] | undefined,
	subtotal?: number | undefined,
	total?: number | undefined,
	tax?: number | undefined,
	discount?: number | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]: {
		where: GraphQLTypes["PaymentWhereUniqueInput"],
	data: GraphQLTypes["PaymentUpdateWithoutCampaignInput"]
};
	["PaymentUpdateManyWithWhereWithoutCampaignInput"]: {
		where: GraphQLTypes["PaymentScalarWhereInput"],
	data: GraphQLTypes["PaymentUpdateManyMutationInput"]
};
	["PaymentScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["PaymentScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PaymentScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PaymentScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	paymentIntentId?: GraphQLTypes["StringFilter"] | undefined,
	checkoutSessionId?: GraphQLTypes["StringFilter"] | undefined,
	eventId?: GraphQLTypes["StringFilter"] | undefined,
	liveMode?: GraphQLTypes["BoolFilter"] | undefined,
	paymentGateway?: GraphQLTypes["EnumPaymentGatewayNameFilter"] | undefined,
	subtotal?: GraphQLTypes["FloatFilter"] | undefined,
	total?: GraphQLTypes["FloatFilter"] | undefined,
	tax?: GraphQLTypes["FloatFilter"] | undefined,
	discount?: GraphQLTypes["FloatFilter"] | undefined,
	campaignId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["PaymentUpdateManyMutationInput"]: {
		paymentIntentId?: string | undefined,
	checkoutSessionId?: string | undefined,
	eventId?: string | undefined,
	liveMode?: boolean | undefined,
	paymentGateway?: GraphQLTypes["PaymentGatewayName"] | undefined,
	subtotal?: number | undefined,
	total?: number | undefined,
	tax?: number | undefined,
	discount?: number | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["FileUpdateManyWithoutCampaignsNestedInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutCampaignsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutCampaignsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["FileUpsertWithWhereUniqueWithoutCampaignsInput"]> | undefined,
	set?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["FileUpdateWithWhereUniqueWithoutCampaignsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["FileUpdateManyWithWhereWithoutCampaignsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutCampaignsInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	update: GraphQLTypes["FileUpdateWithoutCampaignsInput"],
	create: GraphQLTypes["FileCreateWithoutCampaignsInput"]
};
	["FileUpdateWithoutCampaignsInput"]: {
		contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: GraphQLTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: GraphQLTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined
};
	["BookingUpdateOneWithoutProofsNestedInput"]: {
		create?: GraphQLTypes["BookingCreateWithoutProofsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BookingCreateOrConnectWithoutProofsInput"] | undefined,
	upsert?: GraphQLTypes["BookingUpsertWithoutProofsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["BookingWhereInput"] | undefined,
	connect?: GraphQLTypes["BookingWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["BookingUpdateToOneWithWhereWithoutProofsInput"] | undefined
};
	["BookingUpsertWithoutProofsInput"]: {
		update: GraphQLTypes["BookingUpdateWithoutProofsInput"],
	create: GraphQLTypes["BookingCreateWithoutProofsInput"],
	where?: GraphQLTypes["BookingWhereInput"] | undefined
};
	["BookingUpdateWithoutProofsInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutBookingsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutBookingsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutBookingsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutBookingsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"] | undefined
};
	["WorkspaceUpsertWithoutBookingsInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutBookingsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutBookingsInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutBookingsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["FileUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["FileCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["FileCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["FileCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["FileWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["FileUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined
};
	["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	update: GraphQLTypes["FileUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["FileCreateWithoutWorkspaceInput"]
};
	["FileUpdateWithoutWorkspaceInput"]: {
		contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: GraphQLTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: GraphQLTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["CampaignUpdateManyWithoutAssetsNestedInput"]: {
		create?: Array<GraphQLTypes["CampaignCreateWithoutAssetsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CampaignCreateOrConnectWithoutAssetsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]> | undefined,
	set?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CampaignWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CampaignUpdateManyWithWhereWithoutAssetsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CampaignScalarWhereInput"]> | undefined
};
	["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	update: GraphQLTypes["CampaignUpdateWithoutAssetsInput"],
	create: GraphQLTypes["CampaignCreateWithoutAssetsInput"]
};
	["CampaignUpdateWithoutAssetsInput"]: {
		name?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: GraphQLTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined
};
	["BookingUpdateManyWithoutCampaignNestedInput"]: {
		create?: Array<GraphQLTypes["BookingCreateWithoutCampaignInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["BookingCreateOrConnectWithoutCampaignInput"]> | undefined,
	upsert?: Array<GraphQLTypes["BookingUpsertWithWhereUniqueWithoutCampaignInput"]> | undefined,
	createMany?: GraphQLTypes["BookingCreateManyCampaignInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["BookingWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["BookingUpdateWithWhereUniqueWithoutCampaignInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["BookingUpdateManyWithWhereWithoutCampaignInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined
};
	["BookingUpsertWithWhereUniqueWithoutCampaignInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	update: GraphQLTypes["BookingUpdateWithoutCampaignInput"],
	create: GraphQLTypes["BookingCreateWithoutCampaignInput"]
};
	["BookingUpdateWithoutCampaignInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["PayoutUpdateManyWithoutBookingNestedInput"]: {
		create?: Array<GraphQLTypes["PayoutCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PayoutCreateOrConnectWithoutBookingInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PayoutUpsertWithWhereUniqueWithoutBookingInput"]> | undefined,
	createMany?: GraphQLTypes["PayoutCreateManyBookingInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PayoutUpdateWithWhereUniqueWithoutBookingInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PayoutUpdateManyWithWhereWithoutBookingInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PayoutScalarWhereInput"]> | undefined
};
	["PayoutUpsertWithWhereUniqueWithoutBookingInput"]: {
		where: GraphQLTypes["PayoutWhereUniqueInput"],
	update: GraphQLTypes["PayoutUpdateWithoutBookingInput"],
	create: GraphQLTypes["PayoutCreateWithoutBookingInput"]
};
	["PayoutUpdateWithoutBookingInput"]: {
		totalAmount?: number | undefined,
	commissionRate?: number | undefined,
	commissionAmount?: number | undefined,
	netAmount?: number | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	status?: GraphQLTypes["PayoutStatus"] | undefined,
	transaction?: GraphQLTypes["TransactionUpdateOneWithoutPayoutsNestedInput"] | undefined
};
	["TransactionUpdateOneWithoutPayoutsNestedInput"]: {
		create?: GraphQLTypes["TransactionCreateWithoutPayoutsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["TransactionCreateOrConnectWithoutPayoutsInput"] | undefined,
	upsert?: GraphQLTypes["TransactionUpsertWithoutPayoutsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["TransactionWhereInput"] | undefined,
	connect?: GraphQLTypes["TransactionWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["TransactionUpdateToOneWithWhereWithoutPayoutsInput"] | undefined
};
	["TransactionUpsertWithoutPayoutsInput"]: {
		update: GraphQLTypes["TransactionUpdateWithoutPayoutsInput"],
	create: GraphQLTypes["TransactionCreateWithoutPayoutsInput"],
	where?: GraphQLTypes["TransactionWhereInput"] | undefined
};
	["TransactionUpdateWithoutPayoutsInput"]: {
		amount?: number | undefined,
	createAt?: GraphQLTypes["DateTime"] | undefined,
	processedAt?: GraphQLTypes["DateTime"] | undefined,
	relatedEntityId?: string | undefined,
	transactionStatus?: GraphQLTypes["TransactionStatus"] | undefined,
	details?: GraphQLTypes["JSON"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutTransactionsNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutTransactionsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutTransactionsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutTransactionsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"] | undefined
};
	["WorkspaceUpsertWithoutTransactionsInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutTransactionsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutTransactionsInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutTransactionsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["PlayerUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["PlayerCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlayerCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["PlayerCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlayerWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PlayerScalarWhereInput"]> | undefined
};
	["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	update: GraphQLTypes["PlayerUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["PlayerCreateWithoutWorkspaceInput"]
};
	["PlayerUpdateWithoutWorkspaceInput"]: {
		name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"]: {
		create?: Array<GraphQLTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]> | undefined,
	createMany?: GraphQLTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlayerUptimeStatusWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined
};
	["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]: {
		where: GraphQLTypes["PlayerUptimeStatusWhereUniqueInput"],
	update: GraphQLTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"],
	create: GraphQLTypes["PlayerUptimeStatusCreateWithoutPlayerInput"]
};
	["PlayerUptimeStatusUpdateWithoutPlayerInput"]: {
		cpuSerialNumber?: string | undefined,
	date?: string | undefined,
	uptime?: number | undefined
};
	["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]: {
		where: GraphQLTypes["PlayerUptimeStatusWhereUniqueInput"],
	data: GraphQLTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"]
};
	["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]: {
		where: GraphQLTypes["PlayerUptimeStatusScalarWhereInput"],
	data: GraphQLTypes["PlayerUptimeStatusUpdateManyMutationInput"]
};
	["PlayerUptimeStatusScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlayerUptimeStatusScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	playerId?: GraphQLTypes["StringFilter"] | undefined,
	cpuSerialNumber?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["StringFilter"] | undefined,
	uptime?: GraphQLTypes["FloatFilter"] | undefined
};
	["PlayerUptimeStatusUpdateManyMutationInput"]: {
		cpuSerialNumber?: string | undefined,
	date?: string | undefined,
	uptime?: number | undefined
};
	["CameraUpdateManyWithoutPlayerNestedInput"]: {
		create?: Array<GraphQLTypes["CameraCreateWithoutPlayerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraCreateOrConnectWithoutPlayerInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CameraUpsertWithWhereUniqueWithoutPlayerInput"]> | undefined,
	createMany?: GraphQLTypes["CameraCreateManyPlayerInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CameraUpdateWithWhereUniqueWithoutPlayerInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CameraUpdateManyWithWhereWithoutPlayerInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CameraScalarWhereInput"]> | undefined
};
	["CameraUpsertWithWhereUniqueWithoutPlayerInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	update: GraphQLTypes["CameraUpdateWithoutPlayerInput"],
	create: GraphQLTypes["CameraCreateWithoutPlayerInput"]
};
	["CameraUpdateWithoutPlayerInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer?: GraphQLTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined
};
	["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"]: {
		create?: GraphQLTypes["CameraServerCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: GraphQLTypes["CameraServerCreateOrConnectWithoutCamerasInput"] | undefined,
	upsert?: GraphQLTypes["CameraServerUpsertWithoutCamerasInput"] | undefined,
	connect?: GraphQLTypes["CameraServerWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["CameraServerUpdateToOneWithWhereWithoutCamerasInput"] | undefined
};
	["CameraServerUpsertWithoutCamerasInput"]: {
		update: GraphQLTypes["CameraServerUpdateWithoutCamerasInput"],
	create: GraphQLTypes["CameraServerCreateWithoutCamerasInput"],
	where?: GraphQLTypes["CameraServerWhereInput"] | undefined
};
	["CameraServerUpdateWithoutCamerasInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutCameraServersNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutCameraServersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutCameraServersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"] | undefined
};
	["WorkspaceUpsertWithoutCameraServersInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutCameraServersInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutCameraServersInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutCameraServersInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["TransactionUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["TransactionCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["TransactionCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["TransactionCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["TransactionWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["TransactionWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["TransactionWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["TransactionWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["TransactionScalarWhereInput"]> | undefined
};
	["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["TransactionWhereUniqueInput"],
	update: GraphQLTypes["TransactionUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["TransactionCreateWithoutWorkspaceInput"]
};
	["TransactionUpdateWithoutWorkspaceInput"]: {
		amount?: number | undefined,
	createAt?: GraphQLTypes["DateTime"] | undefined,
	processedAt?: GraphQLTypes["DateTime"] | undefined,
	relatedEntityId?: string | undefined,
	transactionStatus?: GraphQLTypes["TransactionStatus"] | undefined,
	details?: GraphQLTypes["JSON"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutTransactionNestedInput"] | undefined
};
	["PayoutUpdateManyWithoutTransactionNestedInput"]: {
		create?: Array<GraphQLTypes["PayoutCreateWithoutTransactionInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PayoutCreateOrConnectWithoutTransactionInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]> | undefined,
	createMany?: GraphQLTypes["PayoutCreateManyTransactionInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PayoutWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PayoutUpdateManyWithWhereWithoutTransactionInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PayoutScalarWhereInput"]> | undefined
};
	["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]: {
		where: GraphQLTypes["PayoutWhereUniqueInput"],
	update: GraphQLTypes["PayoutUpdateWithoutTransactionInput"],
	create: GraphQLTypes["PayoutCreateWithoutTransactionInput"]
};
	["PayoutUpdateWithoutTransactionInput"]: {
		totalAmount?: number | undefined,
	commissionRate?: number | undefined,
	commissionAmount?: number | undefined,
	netAmount?: number | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	status?: GraphQLTypes["PayoutStatus"] | undefined,
	booking?: GraphQLTypes["BookingUpdateOneRequiredWithoutPayoutsNestedInput"] | undefined
};
	["BookingUpdateOneRequiredWithoutPayoutsNestedInput"]: {
		create?: GraphQLTypes["BookingCreateWithoutPayoutsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BookingCreateOrConnectWithoutPayoutsInput"] | undefined,
	upsert?: GraphQLTypes["BookingUpsertWithoutPayoutsInput"] | undefined,
	connect?: GraphQLTypes["BookingWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["BookingUpdateToOneWithWhereWithoutPayoutsInput"] | undefined
};
	["BookingUpsertWithoutPayoutsInput"]: {
		update: GraphQLTypes["BookingUpdateWithoutPayoutsInput"],
	create: GraphQLTypes["BookingCreateWithoutPayoutsInput"],
	where?: GraphQLTypes["BookingWhereInput"] | undefined
};
	["BookingUpdateWithoutPayoutsInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["UserUpdateManyWithoutSharedBookingsNestedInput"]: {
		create?: Array<GraphQLTypes["UserCreateWithoutSharedBookingsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["UserCreateOrConnectWithoutSharedBookingsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]> | undefined,
	set?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["UserUpdateManyWithWhereWithoutSharedBookingsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["UserScalarWhereInput"]> | undefined
};
	["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	update: GraphQLTypes["UserUpdateWithoutSharedBookingsInput"],
	create: GraphQLTypes["UserCreateWithoutSharedBookingsInput"]
};
	["UserUpdateWithoutSharedBookingsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"],
	create: GraphQLTypes["AdBoardCreateWithoutOfflineBookingsContactInput"]
};
	["AdBoardUpdateWithoutOfflineBookingsContactInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["CameraUpdateManyWithoutAdBoardNestedInput"]: {
		create?: Array<GraphQLTypes["CameraCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["CameraCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CameraUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CameraScalarWhereInput"]> | undefined
};
	["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	update: GraphQLTypes["CameraUpdateWithoutAdBoardInput"],
	create: GraphQLTypes["CameraCreateWithoutAdBoardInput"]
};
	["CameraUpdateWithoutAdBoardInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer?: GraphQLTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined,
	player?: GraphQLTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined
};
	["PlayerUpdateOneWithoutCamerasNestedInput"]: {
		create?: GraphQLTypes["PlayerCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: GraphQLTypes["PlayerCreateOrConnectWithoutCamerasInput"] | undefined,
	upsert?: GraphQLTypes["PlayerUpsertWithoutCamerasInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["PlayerWhereInput"] | undefined,
	connect?: GraphQLTypes["PlayerWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["PlayerUpdateToOneWithWhereWithoutCamerasInput"] | undefined
};
	["PlayerUpsertWithoutCamerasInput"]: {
		update: GraphQLTypes["PlayerUpdateWithoutCamerasInput"],
	create: GraphQLTypes["PlayerCreateWithoutCamerasInput"],
	where?: GraphQLTypes["PlayerWhereInput"] | undefined
};
	["PlayerUpdateWithoutCamerasInput"]: {
		name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["DigitalSignageUpdateOneWithoutPlayersNestedInput"]: {
		create?: GraphQLTypes["DigitalSignageCreateWithoutPlayersInput"] | undefined,
	connectOrCreate?: GraphQLTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"] | undefined,
	upsert?: GraphQLTypes["DigitalSignageUpsertWithoutPlayersInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["DigitalSignageWhereInput"] | undefined,
	connect?: GraphQLTypes["DigitalSignageWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"] | undefined
};
	["DigitalSignageUpsertWithoutPlayersInput"]: {
		update: GraphQLTypes["DigitalSignageUpdateWithoutPlayersInput"],
	create: GraphQLTypes["DigitalSignageCreateWithoutPlayersInput"],
	where?: GraphQLTypes["DigitalSignageWhereInput"] | undefined
};
	["DigitalSignageUpdateWithoutPlayersInput"]: {
		type?: GraphQLTypes["SignageType"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"]: {
		where?: GraphQLTypes["DigitalSignageWhereInput"] | undefined,
	data: GraphQLTypes["DigitalSignageUpdateWithoutPlayersInput"]
};
	["AudienceUpdateManyWithoutPlayersNestedInput"]: {
		create?: Array<GraphQLTypes["AudienceCreateWithoutPlayersInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AudienceCreateOrConnectWithoutPlayersInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]> | undefined,
	set?: Array<GraphQLTypes["AudienceWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AudienceWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AudienceWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AudienceWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AudienceUpdateManyWithWhereWithoutPlayersInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AudienceScalarWhereInput"]> | undefined
};
	["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]: {
		where: GraphQLTypes["AudienceWhereUniqueInput"],
	update: GraphQLTypes["AudienceUpdateWithoutPlayersInput"],
	create: GraphQLTypes["AudienceCreateWithoutPlayersInput"]
};
	["AudienceUpdateWithoutPlayersInput"]: {
		type?: GraphQLTypes["AudienceType"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]: {
		where: GraphQLTypes["AudienceWhereUniqueInput"],
	data: GraphQLTypes["AudienceUpdateWithoutPlayersInput"]
};
	["AudienceUpdateManyWithWhereWithoutPlayersInput"]: {
		where: GraphQLTypes["AudienceScalarWhereInput"],
	data: GraphQLTypes["AudienceUpdateManyMutationInput"]
};
	["AudienceScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["AudienceScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AudienceScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AudienceScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumAudienceTypeFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	playerIDs?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["AudienceUpdateManyMutationInput"]: {
		type?: GraphQLTypes["AudienceType"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdBoardUpdateManyWithoutPlayersNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutPlayersInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutPlayersInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]> | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutPlayersInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutPlayersInput"],
	create: GraphQLTypes["AdBoardCreateWithoutPlayersInput"]
};
	["AdBoardUpdateWithoutPlayersInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardUpdateOneWithoutDraftAdboardNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutDraftAdboardInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutDraftAdboardInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"] | undefined
};
	["AdBoardUpsertWithoutDraftAdboardInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutDraftAdboardInput"],
	create: GraphQLTypes["AdBoardCreateWithoutDraftAdboardInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutDraftAdboardInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined
};
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"],
	update: GraphQLTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"],
	create: GraphQLTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryUpdateWithoutAdBoardInput"]: {
		publishedAt?: GraphQLTypes["DateTime"] | undefined,
	changesSnapshot?: GraphQLTypes["JSON"] | undefined,
	publishedBy?: GraphQLTypes["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutPublishAdboardVersionInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutPublishAdboardVersionInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"] | undefined
};
	["UserUpsertWithoutPublishAdboardVersionInput"]: {
		update: GraphQLTypes["UserUpdateWithoutPublishAdboardVersionInput"],
	create: GraphQLTypes["UserCreateWithoutPublishAdboardVersionInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutPublishAdboardVersionInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardReviewCommentCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined
};
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"],
	update: GraphQLTypes["AdBoardReviewCommentUpdateWithoutUserInput"],
	create: GraphQLTypes["AdBoardReviewCommentCreateWithoutUserInput"]
};
	["AdBoardReviewCommentUpdateWithoutUserInput"]: {
		comment?: string | undefined,
	action?: GraphQLTypes["ReviewAction"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutReviewCommentsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutReviewCommentsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"] | undefined
};
	["AdBoardUpsertWithoutReviewCommentsInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutReviewCommentsInput"],
	create: GraphQLTypes["AdBoardCreateWithoutReviewCommentsInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutReviewCommentsInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardUpdateOneWithoutPublishedVersionNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutPublishedVersionInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutPublishedVersionInput"] | undefined,
	disconnect?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	delete?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"] | undefined
};
	["AdBoardUpsertWithoutPublishedVersionInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutPublishedVersionInput"],
	create: GraphQLTypes["AdBoardCreateWithoutPublishedVersionInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutPublishedVersionInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined
};
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"],
	update: GraphQLTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"],
	create: GraphQLTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentUpdateWithoutAdBoardInput"]: {
		comment?: string | undefined,
	action?: GraphQLTypes["ReviewAction"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutReviewCommentsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutReviewCommentsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutReviewCommentsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutReviewCommentsInput"] | undefined
};
	["UserUpsertWithoutReviewCommentsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutReviewCommentsInput"],
	create: GraphQLTypes["UserCreateWithoutReviewCommentsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutReviewCommentsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutArchivedByNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutArchivedByInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutArchivedByInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyArchivedByInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutArchivedByInput"],
	create: GraphQLTypes["AdBoardCreateWithoutArchivedByInput"]
};
	["AdBoardUpdateWithoutArchivedByInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined
};
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"],
	update: GraphQLTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"],
	create: GraphQLTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewUpdateWithoutAdBoardInput"]: {
		rating?: number | undefined,
	comment?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutPublicReviewsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutPublicReviewsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutPublicReviewsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutPublicReviewsInput"] | undefined
};
	["UserUpsertWithoutPublicReviewsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutPublicReviewsInput"],
	create: GraphQLTypes["UserCreateWithoutPublicReviewsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutPublicReviewsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["WorkspaceUpdateManyWithoutOwnerNestedInput"]: {
		create?: Array<GraphQLTypes["WorkspaceCreateWithoutOwnerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WorkspaceCreateOrConnectWithoutOwnerInput"]> | undefined,
	upsert?: Array<GraphQLTypes["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]> | undefined,
	createMany?: GraphQLTypes["WorkspaceCreateManyOwnerInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["WorkspaceScalarWhereInput"]> | undefined
};
	["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	update: GraphQLTypes["WorkspaceUpdateWithoutOwnerInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutOwnerInput"]
};
	["WorkspaceUpdateWithoutOwnerInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"]: {
		create?: GraphQLTypes["SubscriptionCreateWithoutActiveWorkspaceInput"] | undefined,
	connectOrCreate?: GraphQLTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"] | undefined,
	upsert?: GraphQLTypes["SubscriptionUpsertWithoutActiveWorkspaceInput"] | undefined,
	disconnect?: GraphQLTypes["SubscriptionWhereInput"] | undefined,
	delete?: GraphQLTypes["SubscriptionWhereInput"] | undefined,
	connect?: GraphQLTypes["SubscriptionWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"] | undefined
};
	["SubscriptionUpsertWithoutActiveWorkspaceInput"]: {
		update: GraphQLTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"],
	create: GraphQLTypes["SubscriptionCreateWithoutActiveWorkspaceInput"],
	where?: GraphQLTypes["SubscriptionWhereInput"] | undefined
};
	["SubscriptionUpdateWithoutActiveWorkspaceInput"]: {
		packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsCreateInput"] | undefined
};
	["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"]: {
		where?: GraphQLTypes["SubscriptionWhereInput"] | undefined,
	data: GraphQLTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"]
};
	["CameraServerUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["CameraServerCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["CameraServerCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["CameraServerWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CameraServerWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CameraServerWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CameraServerWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CameraServerScalarWhereInput"]> | undefined
};
	["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["CameraServerWhereUniqueInput"],
	update: GraphQLTypes["CameraServerUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["CameraServerCreateWithoutWorkspaceInput"]
};
	["CameraServerUpdateWithoutWorkspaceInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined
};
	["CameraUpdateManyWithoutCameraServerNestedInput"]: {
		create?: Array<GraphQLTypes["CameraCreateWithoutCameraServerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraCreateOrConnectWithoutCameraServerInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]> | undefined,
	createMany?: GraphQLTypes["CameraCreateManyCameraServerInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CameraWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CameraUpdateManyWithWhereWithoutCameraServerInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CameraScalarWhereInput"]> | undefined
};
	["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	update: GraphQLTypes["CameraUpdateWithoutCameraServerInput"],
	create: GraphQLTypes["CameraCreateWithoutCameraServerInput"]
};
	["CameraUpdateWithoutCameraServerInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	player?: GraphQLTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined
};
	["CameraFeedUpdateManyWithoutCameraNestedInput"]: {
		create?: Array<GraphQLTypes["CameraFeedCreateWithoutCameraInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraFeedCreateOrConnectWithoutCameraInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]> | undefined,
	createMany?: GraphQLTypes["CameraFeedCreateManyCameraInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CameraFeedUpdateManyWithWhereWithoutCameraInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CameraFeedScalarWhereInput"]> | undefined
};
	["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]: {
		where: GraphQLTypes["CameraFeedWhereUniqueInput"],
	update: GraphQLTypes["CameraFeedUpdateWithoutCameraInput"],
	create: GraphQLTypes["CameraFeedCreateWithoutCameraInput"]
};
	["CameraFeedUpdateWithoutCameraInput"]: {
		timestamp?: GraphQLTypes["DateTime"] | undefined,
	data?: GraphQLTypes["CameraFeedDataCreateInput"] | undefined,
	cameraServer?: GraphQLTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"] | undefined
};
	["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"]: {
		create?: GraphQLTypes["CameraServerCreateWithoutFeedsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["CameraServerCreateOrConnectWithoutFeedsInput"] | undefined,
	upsert?: GraphQLTypes["CameraServerUpsertWithoutFeedsInput"] | undefined,
	connect?: GraphQLTypes["CameraServerWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["CameraServerUpdateToOneWithWhereWithoutFeedsInput"] | undefined
};
	["CameraServerUpsertWithoutFeedsInput"]: {
		update: GraphQLTypes["CameraServerUpdateWithoutFeedsInput"],
	create: GraphQLTypes["CameraServerCreateWithoutFeedsInput"],
	where?: GraphQLTypes["CameraServerWhereInput"] | undefined
};
	["CameraServerUpdateWithoutFeedsInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined
};
	["CameraServerUpdateToOneWithWhereWithoutFeedsInput"]: {
		where?: GraphQLTypes["CameraServerWhereInput"] | undefined,
	data: GraphQLTypes["CameraServerUpdateWithoutFeedsInput"]
};
	["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]: {
		where: GraphQLTypes["CameraFeedWhereUniqueInput"],
	data: GraphQLTypes["CameraFeedUpdateWithoutCameraInput"]
};
	["CameraFeedUpdateManyWithWhereWithoutCameraInput"]: {
		where: GraphQLTypes["CameraFeedScalarWhereInput"],
	data: GraphQLTypes["CameraFeedUpdateManyMutationInput"]
};
	["CameraFeedScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["CameraFeedScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraFeedScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraFeedScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	cameraId?: GraphQLTypes["StringFilter"] | undefined,
	timestamp?: GraphQLTypes["DateTimeFilter"] | undefined,
	cameraServerId?: GraphQLTypes["StringFilter"] | undefined
};
	["CameraFeedUpdateManyMutationInput"]: {
		timestamp?: GraphQLTypes["DateTime"] | undefined,
	data?: GraphQLTypes["CameraFeedDataCreateInput"] | undefined
};
	["AdBoardUpdateOneWithoutCamerasNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutCamerasInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutCamerasInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutCamerasInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutCamerasInput"] | undefined
};
	["AdBoardUpsertWithoutCamerasInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutCamerasInput"],
	create: GraphQLTypes["AdBoardCreateWithoutCamerasInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutCamerasInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["UserUpdateOneWithoutArchivedAdboardsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutArchivedAdboardsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutArchivedAdboardsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["UserWhereInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"] | undefined
};
	["UserUpsertWithoutArchivedAdboardsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutArchivedAdboardsInput"],
	create: GraphQLTypes["UserCreateWithoutArchivedAdboardsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutArchivedAdboardsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["WorkspaceUpdateManyWithoutLeadsNestedInput"]: {
		create?: Array<GraphQLTypes["WorkspaceCreateWithoutLeadsInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WorkspaceCreateOrConnectWithoutLeadsInput"]> | undefined,
	upsert?: Array<GraphQLTypes["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]> | undefined,
	set?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["WorkspaceWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["WorkspaceScalarWhereInput"]> | undefined
};
	["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	update: GraphQLTypes["WorkspaceUpdateWithoutLeadsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutLeadsInput"]
};
	["WorkspaceUpdateWithoutLeadsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined
};
	["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["WorkspaceRoleWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WorkspaceRoleWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["WorkspaceRoleWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["WorkspaceRoleWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["WorkspaceRoleScalarWhereInput"]> | undefined
};
	["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["WorkspaceRoleWhereUniqueInput"],
	update: GraphQLTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["WorkspaceRoleCreateWithoutWorkspaceInput"]
};
	["WorkspaceRoleUpdateWithoutWorkspaceInput"]: {
		name?: string | undefined,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutRoleNestedInput"] | undefined
};
	["MembershipUpdateManyWithoutRoleNestedInput"]: {
		create?: Array<GraphQLTypes["MembershipCreateWithoutRoleInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["MembershipCreateOrConnectWithoutRoleInput"]> | undefined,
	upsert?: Array<GraphQLTypes["MembershipUpsertWithWhereUniqueWithoutRoleInput"]> | undefined,
	createMany?: GraphQLTypes["MembershipCreateManyRoleInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["MembershipWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["MembershipUpdateWithWhereUniqueWithoutRoleInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["MembershipUpdateManyWithWhereWithoutRoleInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["MembershipScalarWhereInput"]> | undefined
};
	["MembershipUpsertWithWhereUniqueWithoutRoleInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	update: GraphQLTypes["MembershipUpdateWithoutRoleInput"],
	create: GraphQLTypes["MembershipCreateWithoutRoleInput"]
};
	["MembershipUpdateWithoutRoleInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined
};
	["MembershipUpdateWithWhereUniqueWithoutRoleInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	data: GraphQLTypes["MembershipUpdateWithoutRoleInput"]
};
	["MembershipUpdateManyWithWhereWithoutRoleInput"]: {
		where: GraphQLTypes["MembershipScalarWhereInput"],
	data: GraphQLTypes["MembershipUpdateManyMutationInput"]
};
	["MembershipScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["MembershipScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["MembershipScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["MembershipScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	roleId?: GraphQLTypes["StringFilter"] | undefined
};
	["MembershipUpdateManyMutationInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["WorkspaceRoleWhereUniqueInput"],
	data: GraphQLTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"]
};
	["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["WorkspaceRoleScalarWhereInput"],
	data: GraphQLTypes["WorkspaceRoleUpdateManyMutationInput"]
};
	["WorkspaceRoleScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["WorkspaceRoleScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WorkspaceRoleScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WorkspaceRoleScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	permissions?: GraphQLTypes["EnumPermissionNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["WorkspaceRoleUpdateManyMutationInput"]: {
		name?: string | undefined,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["WebhookLogUpdateManyWithoutWorkspaceNestedInput"]: {
		create?: Array<GraphQLTypes["WebhookLogCreateWithoutWorkspaceInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"]> | undefined,
	upsert?: Array<GraphQLTypes["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	createMany?: GraphQLTypes["WebhookLogCreateManyWorkspaceInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["WebhookLogScalarWhereInput"]> | undefined
};
	["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["WebhookLogWhereUniqueInput"],
	update: GraphQLTypes["WebhookLogUpdateWithoutWorkspaceInput"],
	create: GraphQLTypes["WebhookLogCreateWithoutWorkspaceInput"]
};
	["WebhookLogUpdateWithoutWorkspaceInput"]: {
		response?: GraphQLTypes["JSON"] | undefined,
	request?: GraphQLTypes["JSON"] | undefined,
	status?: number | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	booking?: GraphQLTypes["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"] | undefined
};
	["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"]: {
		create?: GraphQLTypes["BookingCreateWithoutWebhookLogsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["BookingCreateOrConnectWithoutWebhookLogsInput"] | undefined,
	upsert?: GraphQLTypes["BookingUpsertWithoutWebhookLogsInput"] | undefined,
	connect?: GraphQLTypes["BookingWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"] | undefined
};
	["BookingUpsertWithoutWebhookLogsInput"]: {
		update: GraphQLTypes["BookingUpdateWithoutWebhookLogsInput"],
	create: GraphQLTypes["BookingCreateWithoutWebhookLogsInput"],
	where?: GraphQLTypes["BookingWhereInput"] | undefined
};
	["BookingUpdateWithoutWebhookLogsInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["SlotUsageUpdateManyWithoutBookingNestedInput"]: {
		create?: Array<GraphQLTypes["SlotUsageCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["SlotUsageCreateOrConnectWithoutBookingInput"]> | undefined,
	upsert?: Array<GraphQLTypes["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]> | undefined,
	createMany?: GraphQLTypes["SlotUsageCreateManyBookingInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["SlotUsageWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["SlotUsageWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["SlotUsageWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["SlotUsageWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["SlotUsageUpdateManyWithWhereWithoutBookingInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["SlotUsageScalarWhereInput"]> | undefined
};
	["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]: {
		where: GraphQLTypes["SlotUsageWhereUniqueInput"],
	update: GraphQLTypes["SlotUsageUpdateWithoutBookingInput"],
	create: GraphQLTypes["SlotUsageCreateWithoutBookingInput"]
};
	["SlotUsageUpdateWithoutBookingInput"]: {
		date?: GraphQLTypes["DateTime"] | undefined,
	slotUsed?: number | undefined
};
	["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]: {
		where: GraphQLTypes["SlotUsageWhereUniqueInput"],
	data: GraphQLTypes["SlotUsageUpdateWithoutBookingInput"]
};
	["SlotUsageUpdateManyWithWhereWithoutBookingInput"]: {
		where: GraphQLTypes["SlotUsageScalarWhereInput"],
	data: GraphQLTypes["SlotUsageUpdateManyMutationInput"]
};
	["SlotUsageScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["SlotUsageScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["SlotUsageScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["SlotUsageScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	date?: GraphQLTypes["DateTimeFilter"] | undefined,
	slotUsed?: GraphQLTypes["IntFilter"] | undefined
};
	["SlotUsageUpdateManyMutationInput"]: {
		date?: GraphQLTypes["DateTime"] | undefined,
	slotUsed?: number | undefined
};
	["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"]: {
		where?: GraphQLTypes["BookingWhereInput"] | undefined,
	data: GraphQLTypes["BookingUpdateWithoutWebhookLogsInput"]
};
	["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["WebhookLogWhereUniqueInput"],
	data: GraphQLTypes["WebhookLogUpdateWithoutWorkspaceInput"]
};
	["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["WebhookLogScalarWhereInput"],
	data: GraphQLTypes["WebhookLogUpdateManyMutationInput"]
};
	["WebhookLogScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["WebhookLogScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WebhookLogScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WebhookLogScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	response?: GraphQLTypes["JsonNullableFilter"] | undefined,
	request?: GraphQLTypes["JsonNullableFilter"] | undefined,
	status?: GraphQLTypes["IntFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["WebhookLogUpdateManyMutationInput"]: {
		response?: GraphQLTypes["JSON"] | undefined,
	request?: GraphQLTypes["JSON"] | undefined,
	status?: number | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	data: GraphQLTypes["WorkspaceUpdateWithoutLeadsInput"]
};
	["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]: {
		where: GraphQLTypes["WorkspaceScalarWhereInput"],
	data: GraphQLTypes["WorkspaceUpdateManyMutationInput"]
};
	["WorkspaceScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["WorkspaceScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WorkspaceScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WorkspaceScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumWorkspaceTypeFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	key?: GraphQLTypes["StringFilter"] | undefined,
	userIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	ownerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	avatar?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	stripeSubscriptionId?: GraphQLTypes["StringNullableFilter"] | undefined,
	leadIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["WorkspaceUpdateManyMutationInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined
};
	["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardPublicReviewCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined
};
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"],
	update: GraphQLTypes["AdBoardPublicReviewUpdateWithoutUserInput"],
	create: GraphQLTypes["AdBoardPublicReviewCreateWithoutUserInput"]
};
	["AdBoardPublicReviewUpdateWithoutUserInput"]: {
		rating?: number | undefined,
	comment?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutPublicReviewsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutPublicReviewsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"] | undefined
};
	["AdBoardUpsertWithoutPublicReviewsInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutPublicReviewsInput"],
	create: GraphQLTypes["AdBoardCreateWithoutPublicReviewsInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutPublicReviewsInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined
};
	["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"],
	data: GraphQLTypes["AdBoardPublicReviewUpdateWithoutUserInput"]
};
	["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]: {
		where: GraphQLTypes["AdBoardPublicReviewScalarWhereInput"],
	data: GraphQLTypes["AdBoardPublicReviewUpdateManyMutationInput"]
};
	["AdBoardPublicReviewScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardPublicReviewScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	rating?: GraphQLTypes["IntFilter"] | undefined,
	comment?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["AdBoardPublicReviewUpdateManyMutationInput"]: {
		rating?: number | undefined,
	comment?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["UserPositionUpdateManyWithoutUserNestedInput"]: {
		create?: Array<GraphQLTypes["UserPositionCreateWithoutUserInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["UserPositionCreateOrConnectWithoutUserInput"]> | undefined,
	upsert?: Array<GraphQLTypes["UserPositionUpsertWithWhereUniqueWithoutUserInput"]> | undefined,
	createMany?: GraphQLTypes["UserPositionCreateManyUserInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["UserPositionWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["UserPositionWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["UserPositionWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["UserPositionWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["UserPositionUpdateWithWhereUniqueWithoutUserInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["UserPositionUpdateManyWithWhereWithoutUserInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["UserPositionScalarWhereInput"]> | undefined
};
	["UserPositionUpsertWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["UserPositionWhereUniqueInput"],
	update: GraphQLTypes["UserPositionUpdateWithoutUserInput"],
	create: GraphQLTypes["UserPositionCreateWithoutUserInput"]
};
	["UserPositionUpdateWithoutUserInput"]: {
		timestamp?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	coords?: GraphQLTypes["UserPositionCoordsCreateInput"] | undefined,
	location?: GraphQLTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserPositionUpdateWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["UserPositionWhereUniqueInput"],
	data: GraphQLTypes["UserPositionUpdateWithoutUserInput"]
};
	["UserPositionUpdateManyWithWhereWithoutUserInput"]: {
		where: GraphQLTypes["UserPositionScalarWhereInput"],
	data: GraphQLTypes["UserPositionUpdateManyMutationInput"]
};
	["UserPositionScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["UserPositionScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserPositionScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserPositionScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	timestamp?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined
};
	["UserPositionUpdateManyMutationInput"]: {
		timestamp?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	coords?: GraphQLTypes["UserPositionCoordsCreateInput"] | undefined,
	location?: GraphQLTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutArchivedAdboardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutCamerasInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	data: GraphQLTypes["CameraUpdateWithoutCameraServerInput"]
};
	["CameraUpdateManyWithWhereWithoutCameraServerInput"]: {
		where: GraphQLTypes["CameraScalarWhereInput"],
	data: GraphQLTypes["CameraUpdateManyMutationInput"]
};
	["CameraScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["CameraScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringNullableFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolFilter"] | undefined,
	cameraServerId?: GraphQLTypes["StringFilter"] | undefined,
	cameraUrl?: GraphQLTypes["StringFilter"] | undefined,
	rtspUrl?: GraphQLTypes["StringFilter"] | undefined,
	cameraIp?: GraphQLTypes["StringFilter"] | undefined,
	connectedCpuSerialNumbers?: GraphQLTypes["StringNullableListFilter"] | undefined,
	playerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	isPublic?: GraphQLTypes["BoolFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["CameraUpdateManyMutationInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined
};
	["CameraFeedUpdateManyWithoutCameraServerNestedInput"]: {
		create?: Array<GraphQLTypes["CameraFeedCreateWithoutCameraServerInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"]> | undefined,
	upsert?: Array<GraphQLTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]> | undefined,
	createMany?: GraphQLTypes["CameraFeedCreateManyCameraServerInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["CameraFeedWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["CameraFeedScalarWhereInput"]> | undefined
};
	["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]: {
		where: GraphQLTypes["CameraFeedWhereUniqueInput"],
	update: GraphQLTypes["CameraFeedUpdateWithoutCameraServerInput"],
	create: GraphQLTypes["CameraFeedCreateWithoutCameraServerInput"]
};
	["CameraFeedUpdateWithoutCameraServerInput"]: {
		timestamp?: GraphQLTypes["DateTime"] | undefined,
	data?: GraphQLTypes["CameraFeedDataCreateInput"] | undefined,
	camera?: GraphQLTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"] | undefined
};
	["CameraUpdateOneRequiredWithoutFeedsNestedInput"]: {
		create?: GraphQLTypes["CameraCreateWithoutFeedsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["CameraCreateOrConnectWithoutFeedsInput"] | undefined,
	upsert?: GraphQLTypes["CameraUpsertWithoutFeedsInput"] | undefined,
	connect?: GraphQLTypes["CameraWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["CameraUpdateToOneWithWhereWithoutFeedsInput"] | undefined
};
	["CameraUpsertWithoutFeedsInput"]: {
		update: GraphQLTypes["CameraUpdateWithoutFeedsInput"],
	create: GraphQLTypes["CameraCreateWithoutFeedsInput"],
	where?: GraphQLTypes["CameraWhereInput"] | undefined
};
	["CameraUpdateWithoutFeedsInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer?: GraphQLTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined,
	player?: GraphQLTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined
};
	["CameraUpdateToOneWithWhereWithoutFeedsInput"]: {
		where?: GraphQLTypes["CameraWhereInput"] | undefined,
	data: GraphQLTypes["CameraUpdateWithoutFeedsInput"]
};
	["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]: {
		where: GraphQLTypes["CameraFeedWhereUniqueInput"],
	data: GraphQLTypes["CameraFeedUpdateWithoutCameraServerInput"]
};
	["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]: {
		where: GraphQLTypes["CameraFeedScalarWhereInput"],
	data: GraphQLTypes["CameraFeedUpdateManyMutationInput"]
};
	["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["CameraServerWhereUniqueInput"],
	data: GraphQLTypes["CameraServerUpdateWithoutWorkspaceInput"]
};
	["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["CameraServerScalarWhereInput"],
	data: GraphQLTypes["CameraServerUpdateManyMutationInput"]
};
	["CameraServerScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["CameraServerScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CameraServerScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CameraServerScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringNullableFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolNullableFilter"] | undefined,
	hostName?: GraphQLTypes["StringNullableFilter"] | undefined,
	userName?: GraphQLTypes["StringNullableFilter"] | undefined,
	deviceId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	publicIp?: GraphQLTypes["StringFilter"] | undefined,
	privateIp?: GraphQLTypes["StringFilter"] | undefined
};
	["CameraServerUpdateManyMutationInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined
};
	["UserUpdateManyWithoutLeadWorspacesNestedInput"]: {
		create?: Array<GraphQLTypes["UserCreateWithoutLeadWorspacesInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["UserCreateOrConnectWithoutLeadWorspacesInput"]> | undefined,
	upsert?: Array<GraphQLTypes["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]> | undefined,
	set?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["UserWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["UserScalarWhereInput"]> | undefined
};
	["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	update: GraphQLTypes["UserUpdateWithoutLeadWorspacesInput"],
	create: GraphQLTypes["UserCreateWithoutLeadWorspacesInput"]
};
	["UserUpdateWithoutLeadWorspacesInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	data: GraphQLTypes["UserUpdateWithoutLeadWorspacesInput"]
};
	["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]: {
		where: GraphQLTypes["UserScalarWhereInput"],
	data: GraphQLTypes["UserUpdateManyMutationInput"]
};
	["UserScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["UserScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	uid?: GraphQLTypes["StringNullableFilter"] | undefined,
	email?: GraphQLTypes["StringFilter"] | undefined,
	image?: GraphQLTypes["StringNullableFilter"] | undefined,
	phoneNumber?: GraphQLTypes["StringNullableFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	otherDetails?: GraphQLTypes["JsonNullableFilter"] | undefined,
	roles?: GraphQLTypes["EnumRoleNullableListFilter"] | undefined,
	sharedBookingIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	disclaimerAccepted?: GraphQLTypes["BoolFilter"] | undefined,
	leadWorkspaceIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["UserUpdateManyMutationInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined
};
	["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	data: GraphQLTypes["WorkspaceUpdateWithoutOwnerInput"]
};
	["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]: {
		where: GraphQLTypes["WorkspaceScalarWhereInput"],
	data: GraphQLTypes["WorkspaceUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutPublicReviewsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutPublicReviewsInput"]
};
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardPublicReviewWhereUniqueInput"],
	data: GraphQLTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"]
};
	["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardPublicReviewScalarWhereInput"],
	data: GraphQLTypes["AdBoardPublicReviewUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutArchivedByInput"]
};
	["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["AdBoardScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	address?: GraphQLTypes["StringFilter"] | undefined,
	state?: GraphQLTypes["StringFilter"] | undefined,
	city?: GraphQLTypes["StringFilter"] | undefined,
	country?: GraphQLTypes["StringFilter"] | undefined,
	outdoor?: GraphQLTypes["BoolFilter"] | undefined,
	locationCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	locationSubCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	alias?: GraphQLTypes["StringNullableFilter"] | undefined,
	title?: GraphQLTypes["StringFilter"] | undefined,
	pricePerDay?: GraphQLTypes["IntFilter"] | undefined,
	originalPricePerDay?: GraphQLTypes["IntNullableFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	adBoardTypeId?: GraphQLTypes["StringNullableFilter"] | undefined,
	tags?: GraphQLTypes["StringNullableListFilter"] | undefined,
	featuredImage?: GraphQLTypes["StringNullableFilter"] | undefined,
	galleryImgs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	notes?: GraphQLTypes["StringNullableListFilter"] | undefined,
	impressionMultiplier?: GraphQLTypes["FloatNullableFilter"] | undefined,
	categoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	restrictedBusinessIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	targetAudiences?: GraphQLTypes["StringNullableListFilter"] | undefined,
	status?: GraphQLTypes["EnumAdBoardStatusFilter"] | undefined,
	adboardDraftStatus?: GraphQLTypes["EnumAdboardDraftStatusNullableFilter"] | undefined,
	isLive?: GraphQLTypes["BoolFilter"] | undefined,
	numberOfDisplays?: GraphQLTypes["IntFilter"] | undefined,
	dailyOperationHours?: GraphQLTypes["IntFilter"] | undefined,
	adsPerLoop?: GraphQLTypes["IntFilter"] | undefined,
	adsDuration?: GraphQLTypes["IntFilter"] | undefined,
	monthlyPrice?: GraphQLTypes["FloatNullableFilter"] | undefined,
	offlineBookingsEnabled?: GraphQLTypes["BoolFilter"] | undefined,
	offlineBookingContactId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	href?: GraphQLTypes["StringNullableFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined,
	playerIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	publisherScreenId?: GraphQLTypes["StringNullableFilter"] | undefined,
	additionalData?: GraphQLTypes["JsonNullableFilter"] | undefined,
	isDraft?: GraphQLTypes["BoolFilter"] | undefined,
	publishedVersionId?: GraphQLTypes["StringNullableFilter"] | undefined,
	lastPublishedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	isArchived?: GraphQLTypes["BoolFilter"] | undefined,
	archivedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	archivedByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined
};
	["UserUpdateToOneWithWhereWithoutReviewCommentsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"],
	data: GraphQLTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"]
};
	["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardReviewCommentScalarWhereInput"],
	data: GraphQLTypes["AdBoardReviewCommentUpdateManyMutationInput"]
};
	["AdBoardReviewCommentScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardReviewCommentScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	comment?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["EnumReviewActionFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["AdBoardReviewCommentUpdateManyMutationInput"]: {
		comment?: string | undefined,
	action?: GraphQLTypes["ReviewAction"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutPublishedVersionInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutReviewCommentsInput"]
};
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["AdBoardReviewCommentWhereUniqueInput"],
	data: GraphQLTypes["AdBoardReviewCommentUpdateWithoutUserInput"]
};
	["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]: {
		where: GraphQLTypes["AdBoardReviewCommentScalarWhereInput"],
	data: GraphQLTypes["AdBoardReviewCommentUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutPublishAdboardVersionInput"]
};
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"],
	data: GraphQLTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"]
};
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]: {
		where: GraphQLTypes["AdBoardPublishHistoryScalarWhereInput"],
	data: GraphQLTypes["AdBoardPublishHistoryUpdateManyMutationInput"]
};
	["AdBoardPublishHistoryScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardPublishHistoryScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	publishedByUserId?: GraphQLTypes["StringFilter"] | undefined,
	changesSnapshot?: GraphQLTypes["JsonFilter"] | undefined
};
	["AdBoardPublishHistoryUpdateManyMutationInput"]: {
		publishedAt?: GraphQLTypes["DateTime"] | undefined,
	changesSnapshot?: GraphQLTypes["JSON"] | undefined
};
	["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutDraftAdboardInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutPlayersInput"]
};
	["AdBoardUpdateManyWithWhereWithoutPlayersInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["PlayerUpdateToOneWithWhereWithoutCamerasInput"]: {
		where?: GraphQLTypes["PlayerWhereInput"] | undefined,
	data: GraphQLTypes["PlayerUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	data: GraphQLTypes["CameraUpdateWithoutAdBoardInput"]
};
	["CameraUpdateManyWithWhereWithoutAdBoardInput"]: {
		where: GraphQLTypes["CameraScalarWhereInput"],
	data: GraphQLTypes["CameraUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"]
};
	["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	data: GraphQLTypes["UserUpdateWithoutSharedBookingsInput"]
};
	["UserUpdateManyWithWhereWithoutSharedBookingsInput"]: {
		where: GraphQLTypes["UserScalarWhereInput"],
	data: GraphQLTypes["UserUpdateManyMutationInput"]
};
	["WebhookLogUpdateManyWithoutBookingNestedInput"]: {
		create?: Array<GraphQLTypes["WebhookLogCreateWithoutBookingInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["WebhookLogCreateOrConnectWithoutBookingInput"]> | undefined,
	upsert?: Array<GraphQLTypes["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]> | undefined,
	createMany?: GraphQLTypes["WebhookLogCreateManyBookingInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["WebhookLogWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["WebhookLogUpdateManyWithWhereWithoutBookingInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["WebhookLogScalarWhereInput"]> | undefined
};
	["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]: {
		where: GraphQLTypes["WebhookLogWhereUniqueInput"],
	update: GraphQLTypes["WebhookLogUpdateWithoutBookingInput"],
	create: GraphQLTypes["WebhookLogCreateWithoutBookingInput"]
};
	["WebhookLogUpdateWithoutBookingInput"]: {
		response?: GraphQLTypes["JSON"] | undefined,
	request?: GraphQLTypes["JSON"] | undefined,
	status?: number | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutWebhookLogsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutWebhookLogsInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"] | undefined
};
	["WorkspaceUpsertWithoutWebhookLogsInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutWebhookLogsInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutWebhookLogsInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutWebhookLogsInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutWebhookLogsInput"]
};
	["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]: {
		where: GraphQLTypes["WebhookLogWhereUniqueInput"],
	data: GraphQLTypes["WebhookLogUpdateWithoutBookingInput"]
};
	["WebhookLogUpdateManyWithWhereWithoutBookingInput"]: {
		where: GraphQLTypes["WebhookLogScalarWhereInput"],
	data: GraphQLTypes["WebhookLogUpdateManyMutationInput"]
};
	["BookingUpdateToOneWithWhereWithoutPayoutsInput"]: {
		where?: GraphQLTypes["BookingWhereInput"] | undefined,
	data: GraphQLTypes["BookingUpdateWithoutPayoutsInput"]
};
	["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]: {
		where: GraphQLTypes["PayoutWhereUniqueInput"],
	data: GraphQLTypes["PayoutUpdateWithoutTransactionInput"]
};
	["PayoutUpdateManyWithWhereWithoutTransactionInput"]: {
		where: GraphQLTypes["PayoutScalarWhereInput"],
	data: GraphQLTypes["PayoutUpdateManyMutationInput"]
};
	["PayoutScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["PayoutScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PayoutScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PayoutScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	totalAmount?: GraphQLTypes["FloatFilter"] | undefined,
	commissionRate?: GraphQLTypes["FloatFilter"] | undefined,
	commissionAmount?: GraphQLTypes["FloatFilter"] | undefined,
	netAmount?: GraphQLTypes["FloatFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingId?: GraphQLTypes["StringFilter"] | undefined,
	status?: GraphQLTypes["EnumPayoutStatusFilter"] | undefined,
	transactionId?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["PayoutUpdateManyMutationInput"]: {
		totalAmount?: number | undefined,
	commissionRate?: number | undefined,
	commissionAmount?: number | undefined,
	netAmount?: number | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	status?: GraphQLTypes["PayoutStatus"] | undefined
};
	["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["TransactionWhereUniqueInput"],
	data: GraphQLTypes["TransactionUpdateWithoutWorkspaceInput"]
};
	["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["TransactionScalarWhereInput"],
	data: GraphQLTypes["TransactionUpdateManyMutationInput"]
};
	["TransactionScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["TransactionScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["TransactionScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["TransactionScalarWhereInput"]> | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	amount?: GraphQLTypes["FloatFilter"] | undefined,
	createAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	processedAt?: GraphQLTypes["DateTimeNullableFilter"] | undefined,
	relatedEntityId?: GraphQLTypes["StringFilter"] | undefined,
	transactionStatus?: GraphQLTypes["EnumTransactionStatusFilter"] | undefined,
	details?: GraphQLTypes["JsonNullableFilter"] | undefined
};
	["TransactionUpdateManyMutationInput"]: {
		amount?: number | undefined,
	createAt?: GraphQLTypes["DateTime"] | undefined,
	processedAt?: GraphQLTypes["DateTime"] | undefined,
	relatedEntityId?: string | undefined,
	transactionStatus?: GraphQLTypes["TransactionStatus"] | undefined,
	details?: GraphQLTypes["JSON"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutCameraServersInput"]
};
	["CameraServerUpdateToOneWithWhereWithoutCamerasInput"]: {
		where?: GraphQLTypes["CameraServerWhereInput"] | undefined,
	data: GraphQLTypes["CameraServerUpdateWithoutCamerasInput"]
};
	["CameraUpdateWithWhereUniqueWithoutPlayerInput"]: {
		where: GraphQLTypes["CameraWhereUniqueInput"],
	data: GraphQLTypes["CameraUpdateWithoutPlayerInput"]
};
	["CameraUpdateManyWithWhereWithoutPlayerInput"]: {
		where: GraphQLTypes["CameraScalarWhereInput"],
	data: GraphQLTypes["CameraUpdateManyMutationInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	data: GraphQLTypes["PlayerUpdateWithoutWorkspaceInput"]
};
	["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["PlayerScalarWhereInput"],
	data: GraphQLTypes["PlayerUpdateManyMutationInput"]
};
	["PlayerScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["PlayerScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlayerScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlayerScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	configLocation?: GraphQLTypes["StringNullableFilter"] | undefined,
	pisignagePlayerId?: GraphQLTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: GraphQLTypes["StringNullableFilter"] | undefined,
	cpuSerialNumber?: GraphQLTypes["StringNullableFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	adBoardGroupId?: GraphQLTypes["StringNullableFilter"] | undefined,
	playlistIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workpaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	signageTypeId?: GraphQLTypes["StringNullableFilter"] | undefined,
	audienceTypeId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	aspectRatioType?: GraphQLTypes["EnumAspectRatioTypeFilter"] | undefined,
	customAspectRatioWidth?: GraphQLTypes["IntNullableFilter"] | undefined,
	customAspectRatioHeight?: GraphQLTypes["IntNullableFilter"] | undefined,
	isOnline?: GraphQLTypes["BoolFilter"] | undefined,
	adBoardIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["PlayerUpdateManyMutationInput"]: {
		name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutTransactionsInput"]
};
	["TransactionUpdateToOneWithWhereWithoutPayoutsInput"]: {
		where?: GraphQLTypes["TransactionWhereInput"] | undefined,
	data: GraphQLTypes["TransactionUpdateWithoutPayoutsInput"]
};
	["PayoutUpdateWithWhereUniqueWithoutBookingInput"]: {
		where: GraphQLTypes["PayoutWhereUniqueInput"],
	data: GraphQLTypes["PayoutUpdateWithoutBookingInput"]
};
	["PayoutUpdateManyWithWhereWithoutBookingInput"]: {
		where: GraphQLTypes["PayoutScalarWhereInput"],
	data: GraphQLTypes["PayoutUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutCampaignInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	data: GraphQLTypes["BookingUpdateWithoutCampaignInput"]
};
	["BookingUpdateManyWithWhereWithoutCampaignInput"]: {
		where: GraphQLTypes["BookingScalarWhereInput"],
	data: GraphQLTypes["BookingUpdateManyMutationInput"]
};
	["BookingScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BookingScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	additionalInformation?: GraphQLTypes["StringNullableFilter"] | undefined,
	origin?: GraphQLTypes["StringNullableFilter"] | undefined,
	assetsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingCode?: GraphQLTypes["StringFilter"] | undefined,
	bookingDate?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingFor?: GraphQLTypes["StringFilter"] | undefined,
	purpose?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	campaignId?: GraphQLTypes["StringNullableFilter"] | undefined,
	businessCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined,
	adCategoryId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingStatus?: GraphQLTypes["EnumBookingStatusFilter"] | undefined,
	paymentStatus?: GraphQLTypes["EnumPaymentStatusNullableFilter"] | undefined,
	deploymentStatus?: GraphQLTypes["EnumDeploymentStatusFilter"] | undefined,
	contentApprovalStatus?: GraphQLTypes["EnumContentApprovalStatusFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	sharedUserIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	isOffline?: GraphQLTypes["BoolFilter"] | undefined,
	isPaid?: GraphQLTypes["BoolFilter"] | undefined,
	isScheduled?: GraphQLTypes["BoolFilter"] | undefined,
	isDeployed?: GraphQLTypes["BoolFilter"] | undefined,
	isCompleted?: GraphQLTypes["BoolFilter"] | undefined
};
	["BookingUpdateManyMutationInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	data: GraphQLTypes["CampaignUpdateWithoutAssetsInput"]
};
	["CampaignUpdateManyWithWhereWithoutAssetsInput"]: {
		where: GraphQLTypes["CampaignScalarWhereInput"],
	data: GraphQLTypes["CampaignUpdateManyMutationInput"]
};
	["CampaignScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["CampaignScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["CampaignScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["CampaignScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	bookingFor?: GraphQLTypes["StringFilter"] | undefined,
	purpose?: GraphQLTypes["StringFilter"] | undefined,
	businessCategoryId?: GraphQLTypes["StringFilter"] | undefined,
	adCategoryId?: GraphQLTypes["StringNullableListFilter"] | undefined,
	paymentStatus?: GraphQLTypes["EnumPaymentStatusFilter"] | undefined,
	origin?: GraphQLTypes["StringFilter"] | undefined,
	assetsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["CampaignUpdateManyMutationInput"]: {
		name?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined
};
	["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	data: GraphQLTypes["FileUpdateWithoutWorkspaceInput"]
};
	["FileUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["FileScalarWhereInput"],
	data: GraphQLTypes["FileUpdateManyMutationInput"]
};
	["FileScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["FileScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	contentType?: GraphQLTypes["StringFilter"] | undefined,
	ctime?: GraphQLTypes["StringFilter"] | undefined,
	filename?: GraphQLTypes["StringFilter"] | undefined,
	filepath?: GraphQLTypes["StringNullableFilter"] | undefined,
	isDirectory?: GraphQLTypes["BoolFilter"] | undefined,
	mtime?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	size?: GraphQLTypes["IntFilter"] | undefined,
	status?: GraphQLTypes["EnumFileStatusNullableFilter"] | undefined,
	type?: GraphQLTypes["StringFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringFilter"] | undefined,
	url?: GraphQLTypes["StringFilter"] | undefined,
	bookingsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	proofBookingId?: GraphQLTypes["StringNullableFilter"] | undefined,
	campaignIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["FileUpdateManyMutationInput"]: {
		contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: GraphQLTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutBookingsInput"]
};
	["BookingUpdateToOneWithWhereWithoutProofsInput"]: {
		where?: GraphQLTypes["BookingWhereInput"] | undefined,
	data: GraphQLTypes["BookingUpdateWithoutProofsInput"]
};
	["FileUpdateWithWhereUniqueWithoutCampaignsInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	data: GraphQLTypes["FileUpdateWithoutCampaignsInput"]
};
	["FileUpdateManyWithWhereWithoutCampaignsInput"]: {
		where: GraphQLTypes["FileScalarWhereInput"],
	data: GraphQLTypes["FileUpdateManyMutationInput"]
};
	["CampaignUpdateToOneWithWhereWithoutBookingsInput"]: {
		where?: GraphQLTypes["CampaignWhereInput"] | undefined,
	data: GraphQLTypes["CampaignUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	data: GraphQLTypes["BookingUpdateWithoutWorkspaceInput"]
};
	["BookingUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["BookingScalarWhereInput"],
	data: GraphQLTypes["BookingUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"]: {
		where?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardGroupUpdateWithoutAdBoardInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutHistoryInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutHistoryInput"]
};
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]: {
		where: GraphQLTypes["AdBoardPublishHistoryWhereUniqueInput"],
	data: GraphQLTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"]
};
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]: {
		where: GraphQLTypes["AdBoardPublishHistoryScalarWhereInput"],
	data: GraphQLTypes["AdBoardPublishHistoryUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutAuditsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutAuditsInput"]
};
	["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AuditWhereUniqueInput"],
	data: GraphQLTypes["AuditUpdateWithoutWorkspaceInput"]
};
	["AuditUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AuditScalarWhereInput"],
	data: GraphQLTypes["AuditUpdateManyMutationInput"]
};
	["AuditScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["AuditScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AuditScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AuditScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	message?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["StringFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringNullableFilter"] | undefined,
	updates?: GraphQLTypes["JsonNullableFilter"] | undefined
};
	["AuditUpdateManyMutationInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	updates?: GraphQLTypes["JSON"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutPlayersInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	data: GraphQLTypes["PlayerUpdateWithoutAdBoardGroupInput"]
};
	["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]: {
		where: GraphQLTypes["PlayerScalarWhereInput"],
	data: GraphQLTypes["PlayerUpdateManyMutationInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	data: GraphQLTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]: {
		where: GraphQLTypes["AdBoardGroupScalarWhereInput"],
	data: GraphQLTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["AdBoardGroupScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["AdBoardGroupScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdBoardGroupScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdBoardGroupScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringNullableFilter"] | undefined,
	scheduledPlaylistsIDs?: GraphQLTypes["StringNullableListFilter"] | undefined,
	adsPlaylistID?: GraphQLTypes["StringNullableFilter"] | undefined,
	pisignageGroupId?: GraphQLTypes["StringFilter"] | undefined,
	fitImage?: GraphQLTypes["EnumFitTypeFilter"] | undefined,
	fitVideo?: GraphQLTypes["EnumFitTypeFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined
};
	["AdBoardGroupUpdateManyMutationInput"]: {
		name?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined
};
	["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"]: {
		create?: Array<GraphQLTypes["PlaylistCreateWithoutFooterPlaylistInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]> | undefined,
	createMany?: GraphQLTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PlaylistScalarWhereInput"]> | undefined
};
	["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	update: GraphQLTypes["PlaylistUpdateWithoutFooterPlaylistInput"],
	create: GraphQLTypes["PlaylistCreateWithoutFooterPlaylistInput"]
};
	["PlaylistUpdateWithoutFooterPlaylistInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"]: {
		create?: Array<GraphQLTypes["PlaylistCreateWithoutSidebarPlaylistInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]> | undefined,
	upsert?: Array<GraphQLTypes["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]> | undefined,
	createMany?: GraphQLTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["PlaylistWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["PlaylistScalarWhereInput"]> | undefined
};
	["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	update: GraphQLTypes["PlaylistUpdateWithoutSidebarPlaylistInput"],
	create: GraphQLTypes["PlaylistCreateWithoutSidebarPlaylistInput"]
};
	["PlaylistUpdateWithoutSidebarPlaylistInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	data: GraphQLTypes["PlaylistUpdateWithoutSidebarPlaylistInput"]
};
	["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]: {
		where: GraphQLTypes["PlaylistScalarWhereInput"],
	data: GraphQLTypes["PlaylistUpdateManyMutationInput"]
};
	["PlaylistScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["PlaylistScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlaylistScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlaylistScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	displayName?: GraphQLTypes["StringNullableFilter"] | undefined,
	isAdsPlaylist?: GraphQLTypes["BoolNullableFilter"] | undefined,
	footerPlaylistId?: GraphQLTypes["StringNullableFilter"] | undefined,
	sidebarPlaylistId?: GraphQLTypes["StringNullableFilter"] | undefined,
	layout?: GraphQLTypes["EnumScreenLayoutFilter"] | undefined,
	isDeployed?: GraphQLTypes["BoolNullableFilter"] | undefined,
	adBoardGroupIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	workspaceId?: GraphQLTypes["StringFilter"] | undefined
};
	["PlaylistUpdateManyMutationInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined
};
	["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	data: GraphQLTypes["PlaylistUpdateWithoutFooterPlaylistInput"]
};
	["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]: {
		where: GraphQLTypes["PlaylistScalarWhereInput"],
	data: GraphQLTypes["PlaylistUpdateManyMutationInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"]: {
		where?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	data: GraphQLTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	data: GraphQLTypes["AdBoardGroupUpdateWithoutWorkspaceInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AdBoardGroupScalarWhereInput"],
	data: GraphQLTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutPlaylistsInput"]
};
	["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	data: GraphQLTypes["PlaylistUpdateWithoutAdBoardGroupsInput"]
};
	["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]: {
		where: GraphQLTypes["PlaylistScalarWhereInput"],
	data: GraphQLTypes["PlaylistUpdateManyMutationInput"]
};
	["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]: {
		where: GraphQLTypes["AdBoardGroupWhereUniqueInput"],
	data: GraphQLTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"]
};
	["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]: {
		where: GraphQLTypes["AdBoardGroupScalarWhereInput"],
	data: GraphQLTypes["AdBoardGroupUpdateManyMutationInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"]: {
		where?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	data: GraphQLTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"]
};
	["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"]: {
		where?: GraphQLTypes["PlaylistWhereInput"] | undefined,
	data: GraphQLTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"]
};
	["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["PlaylistWhereUniqueInput"],
	data: GraphQLTypes["PlaylistUpdateWithoutWorkspaceInput"]
};
	["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["PlaylistScalarWhereInput"],
	data: GraphQLTypes["PlaylistUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutAdBoardGroupInput"]
};
	["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"]: {
		where?: GraphQLTypes["AdBoardGroupWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"]
};
	["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]: {
		where: GraphQLTypes["PlayerWhereUniqueInput"],
	data: GraphQLTypes["PlayerUpdateWithoutAdBoardsInput"]
};
	["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]: {
		where: GraphQLTypes["PlayerScalarWhereInput"],
	data: GraphQLTypes["PlayerUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutWorkspaceInput"]
};
	["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutMembershipsInput"]
};
	["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"]: {
		create?: GraphQLTypes["WorkspaceRoleCreateWithoutMembershipsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceRoleUpsertWithoutMembershipsInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceRoleWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"] | undefined
};
	["WorkspaceRoleUpsertWithoutMembershipsInput"]: {
		update: GraphQLTypes["WorkspaceRoleUpdateWithoutMembershipsInput"],
	create: GraphQLTypes["WorkspaceRoleCreateWithoutMembershipsInput"],
	where?: GraphQLTypes["WorkspaceRoleWhereInput"] | undefined
};
	["WorkspaceRoleUpdateWithoutMembershipsInput"]: {
		name?: string | undefined,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"] | undefined
};
	["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutWorkspaceRolesInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutWorkspaceRolesInput"] | undefined,
	disconnect?: boolean | undefined,
	delete?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"] | undefined
};
	["WorkspaceUpsertWithoutWorkspaceRolesInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutWorkspaceRolesInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutWorkspaceRolesInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"]
};
	["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"]: {
		where?: GraphQLTypes["WorkspaceRoleWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceRoleUpdateWithoutMembershipsInput"]
};
	["MembershipUpdateWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	data: GraphQLTypes["MembershipUpdateWithoutUserInput"]
};
	["MembershipUpdateManyWithWhereWithoutUserInput"]: {
		where: GraphQLTypes["MembershipScalarWhereInput"],
	data: GraphQLTypes["MembershipUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutChangelogsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutChangelogsInput"]
};
	["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]: {
		where: GraphQLTypes["ChangelogWhereUniqueInput"],
	data: GraphQLTypes["ChangelogUpdateWithoutBookingInput"]
};
	["ChangelogUpdateManyWithWhereWithoutBookingInput"]: {
		where: GraphQLTypes["ChangelogScalarWhereInput"],
	data: GraphQLTypes["ChangelogUpdateManyMutationInput"]
};
	["ChangelogScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["ChangelogScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["ChangelogScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["ChangelogScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	message?: GraphQLTypes["StringFilter"] | undefined,
	action?: GraphQLTypes["StringFilter"] | undefined,
	bookingId?: GraphQLTypes["StringNullableFilter"] | undefined,
	userId?: GraphQLTypes["StringFilter"] | undefined,
	updates?: GraphQLTypes["JsonNullableFilter"] | undefined
};
	["ChangelogUpdateManyMutationInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	updates?: GraphQLTypes["JSON"] | undefined
};
	["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	data: GraphQLTypes["BookingUpdateWithoutSharedUsersInput"]
};
	["BookingUpdateManyWithWhereWithoutSharedUsersInput"]: {
		where: GraphQLTypes["BookingScalarWhereInput"],
	data: GraphQLTypes["BookingUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutAdBoardsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutBookingsInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	data: GraphQLTypes["BookingUpdateWithoutAdCategoryInput"]
};
	["BookingUpdateManyWithWhereWithoutAdCategoryInput"]: {
		where: GraphQLTypes["BookingScalarWhereInput"],
	data: GraphQLTypes["BookingUpdateManyMutationInput"]
};
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]: {
		where: GraphQLTypes["AdvertisementCategoryWhereUniqueInput"],
	data: GraphQLTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"]
};
	["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]: {
		where: GraphQLTypes["AdvertisementCategoryScalarWhereInput"],
	data: GraphQLTypes["AdvertisementCategoryUpdateManyMutationInput"]
};
	["AdvertisementCategoryScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["AdvertisementCategoryScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["AdvertisementCategoryScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["AdvertisementCategoryScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["EnumAdvertisementCategoryEnumFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	campaignIds?: GraphQLTypes["StringNullableListFilter"] | undefined,
	bookingIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["AdvertisementCategoryUpdateManyMutationInput"]: {
		name?: string | undefined,
	type?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["CampaignUpdateWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	data: GraphQLTypes["CampaignUpdateWithoutUserInput"]
};
	["CampaignUpdateManyWithWhereWithoutUserInput"]: {
		where: GraphQLTypes["CampaignScalarWhereInput"],
	data: GraphQLTypes["CampaignUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutMembershipsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutMembershipsInput"]
};
	["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]: {
		where: GraphQLTypes["MembershipWhereUniqueInput"],
	data: GraphQLTypes["MembershipUpdateWithoutWorkspaceInput"]
};
	["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]: {
		where: GraphQLTypes["MembershipScalarWhereInput"],
	data: GraphQLTypes["MembershipUpdateManyMutationInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutAuditsInput"]
};
	["AuditUpdateWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["AuditWhereUniqueInput"],
	data: GraphQLTypes["AuditUpdateWithoutUserInput"]
};
	["AuditUpdateManyWithWhereWithoutUserInput"]: {
		where: GraphQLTypes["AuditScalarWhereInput"],
	data: GraphQLTypes["AuditUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOrdersInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutOrdersInput"]
};
	["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["OrderWhereUniqueInput"],
	data: GraphQLTypes["OrderUpdateWithoutAdBoardInput"]
};
	["OrderUpdateManyWithWhereWithoutAdBoardInput"]: {
		where: GraphQLTypes["OrderScalarWhereInput"],
	data: GraphQLTypes["OrderUpdateManyMutationInput"]
};
	["OrderScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["OrderScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["OrderScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["OrderScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	adBoardId?: GraphQLTypes["StringFilter"] | undefined,
	createdDate?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdByUserId?: GraphQLTypes["StringNullableFilter"] | undefined,
	origin?: GraphQLTypes["StringFilter"] | undefined,
	paymentId?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["OrderUpdateManyMutationInput"]: {
		createdDate?: GraphQLTypes["DateTime"] | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDate?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined
};
	["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutCreatedByInput"]
};
	["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutBookingsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutBookingsInput"]
};
	["BookingUpdateToOneWithWhereWithoutChangelogsInput"]: {
		where?: GraphQLTypes["BookingWhereInput"] | undefined,
	data: GraphQLTypes["BookingUpdateWithoutChangelogsInput"]
};
	["ChangelogUpdateWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["ChangelogWhereUniqueInput"],
	data: GraphQLTypes["ChangelogUpdateWithoutUserInput"]
};
	["ChangelogUpdateManyWithWhereWithoutUserInput"]: {
		where: GraphQLTypes["ChangelogScalarWhereInput"],
	data: GraphQLTypes["ChangelogUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutOfflineBookingAdboardsInput"]
};
	["AdBoardUpdateToOneWithWhereWithoutOrdersInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutOrdersInput"]
};
	["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]: {
		where: GraphQLTypes["OrderWhereUniqueInput"],
	data: GraphQLTypes["OrderUpdateWithoutCreatedByInput"]
};
	["OrderUpdateManyWithWhereWithoutCreatedByInput"]: {
		where: GraphQLTypes["OrderScalarWhereInput"],
	data: GraphQLTypes["OrderUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutOwnedWorkspacesInput"]
};
	["WorkspaceUpdateToOneWithWhereWithoutFilesInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	data: GraphQLTypes["FileUpdateWithoutUpdatedByInput"]
};
	["FileUpdateManyWithWhereWithoutUpdatedByInput"]: {
		where: GraphQLTypes["FileScalarWhereInput"],
	data: GraphQLTypes["FileUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutCreatedFilesInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutCreatedFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutProofBookingInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	data: GraphQLTypes["FileUpdateWithoutProofBookingInput"]
};
	["FileUpdateManyWithWhereWithoutProofBookingInput"]: {
		where: GraphQLTypes["FileScalarWhereInput"],
	data: GraphQLTypes["FileUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	data: GraphQLTypes["BookingUpdateWithoutBusinessCategoryInput"]
};
	["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]: {
		where: GraphQLTypes["BookingScalarWhereInput"],
	data: GraphQLTypes["BookingUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"]: {
		where?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	data: GraphQLTypes["BusinessCategoryUpdateWithoutCampaignsInput"]
};
	["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	data: GraphQLTypes["CampaignUpdateWithoutAdCategoryInput"]
};
	["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]: {
		where: GraphQLTypes["CampaignScalarWhereInput"],
	data: GraphQLTypes["CampaignUpdateManyMutationInput"]
};
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]: {
		where: GraphQLTypes["AdvertisementCategoryWhereUniqueInput"],
	data: GraphQLTypes["AdvertisementCategoryUpdateWithoutBookingsInput"]
};
	["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]: {
		where: GraphQLTypes["AdvertisementCategoryScalarWhereInput"],
	data: GraphQLTypes["AdvertisementCategoryUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAssetsInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	data: GraphQLTypes["BookingUpdateWithoutAssetsInput"]
};
	["BookingUpdateManyWithWhereWithoutAssetsInput"]: {
		where: GraphQLTypes["BookingScalarWhereInput"],
	data: GraphQLTypes["BookingUpdateManyMutationInput"]
};
	["FileUpdateWithWhereUniqueWithoutCreatedByInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	data: GraphQLTypes["FileUpdateWithoutCreatedByInput"]
};
	["FileUpdateManyWithWhereWithoutCreatedByInput"]: {
		where: GraphQLTypes["FileScalarWhereInput"],
	data: GraphQLTypes["FileUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutCampaignsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutCampaignsInput"]
};
	["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: {
		where: GraphQLTypes["CampaignWhereUniqueInput"],
	data: GraphQLTypes["CampaignUpdateWithoutBusinessCategoryInput"]
};
	["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]: {
		where: GraphQLTypes["CampaignScalarWhereInput"],
	data: GraphQLTypes["CampaignUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"]: {
		where?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	data: GraphQLTypes["BusinessCategoryUpdateWithoutBookingsInput"]
};
	["BookingUpdateWithWhereUniqueWithoutUserInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	data: GraphQLTypes["BookingUpdateWithoutUserInput"]
};
	["BookingUpdateManyWithWhereWithoutUserInput"]: {
		where: GraphQLTypes["BookingScalarWhereInput"],
	data: GraphQLTypes["BookingUpdateManyMutationInput"]
};
	["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutUpdatedFilesInput"]
};
	["FileUpdateWithWhereUniqueWithoutBookingsInput"]: {
		where: GraphQLTypes["FileWhereUniqueInput"],
	data: GraphQLTypes["FileUpdateWithoutBookingsInput"]
};
	["FileUpdateManyWithWhereWithoutBookingsInput"]: {
		where: GraphQLTypes["FileScalarWhereInput"],
	data: GraphQLTypes["FileUpdateManyMutationInput"]
};
	["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]: {
		where: GraphQLTypes["BookingWhereUniqueInput"],
	data: GraphQLTypes["BookingUpdateWithoutAdBoardInput"]
};
	["BookingUpdateManyWithWhereWithoutAdBoardInput"]: {
		where: GraphQLTypes["BookingScalarWhereInput"],
	data: GraphQLTypes["BookingUpdateManyMutationInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutCategoryInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: {
		where: GraphQLTypes["BusinessCategoryWhereUniqueInput"],
	data: GraphQLTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"]
};
	["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]: {
		where: GraphQLTypes["BusinessCategoryScalarWhereInput"],
	data: GraphQLTypes["BusinessCategoryUpdateManyMutationInput"]
};
	["BusinessCategoryScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["BusinessCategoryScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BusinessCategoryScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BusinessCategoryScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	description?: GraphQLTypes["StringNullableFilter"] | undefined,
	restrictedByAdBoardIds?: GraphQLTypes["StringNullableListFilter"] | undefined
};
	["BusinessCategoryUpdateManyMutationInput"]: {
		name?: string | undefined,
	description?: string | undefined
};
	["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutLocationCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"]: {
		where?: GraphQLTypes["LocationCategoryWhereInput"] | undefined,
	data: GraphQLTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"]
};
	["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
		where?: GraphQLTypes["LocationSubCategoryWhereInput"] | undefined,
	data: GraphQLTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutRestrictedBusinesInput"]
};
	["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
		where?: GraphQLTypes["BusinessCategoryWhereInput"] | undefined,
	data: GraphQLTypes["BusinessCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutLocationSubCategoryInput"]
};
	["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]: {
		where: GraphQLTypes["LocationSubCategoryWhereUniqueInput"],
	data: GraphQLTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"]
};
	["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]: {
		where: GraphQLTypes["LocationSubCategoryScalarWhereInput"],
	data: GraphQLTypes["LocationSubCategoryUpdateManyMutationInput"]
};
	["LocationSubCategoryScalarWhereInput"]: {
		AND?: Array<GraphQLTypes["LocationSubCategoryScalarWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["LocationSubCategoryScalarWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["LocationSubCategoryScalarWhereInput"]> | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	locationCategoryId?: GraphQLTypes["StringNullableFilter"] | undefined
};
	["LocationSubCategoryUpdateManyMutationInput"]: {
		name?: string | undefined
};
	["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: {
		where?: GraphQLTypes["LocationCategoryWhereInput"] | undefined,
	data: GraphQLTypes["LocationCategoryUpdateWithoutAdBoardsInput"]
};
	["AdBoardGroupCreateManyInput"]: {
		id?: string | undefined,
	name: string,
	adBoardId?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	scheduledPlaylistsIDs?: Array<string> | undefined,
	adsPlaylistID?: string | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	workspaceId: string
};
	["AdBoardGroupCreateInput"]: {
		id?: string | undefined,
	name: string,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId: string,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]
};
	["AdBoardGroupUpdateInput"]: {
		name?: string | undefined,
	schedules?: Array<GraphQLTypes["ScheduleCreateInput"]> | undefined,
	pisignageGroupId?: string | undefined,
	fitImage?: GraphQLTypes["FitType"] | undefined,
	fitVideo?: GraphQLTypes["FitType"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"] | undefined,
	scheduledPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"] | undefined,
	adsPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"] | undefined,
	pisignagePlayers?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"] | undefined
};
	["AdBoardTypeCreateManyInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined
};
	["AdBoardTypeCreateInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutTypeInput"] | undefined
};
	["AdBoardCreateNestedManyWithoutTypeInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutTypeInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutTypeInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyTypeInputEnvelope"] | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined
};
	["AdBoardCreateWithoutTypeInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardCreateOrConnectWithoutTypeInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutTypeInput"]
};
	["AdBoardCreateManyTypeInputEnvelope"]: {
		data: Array<GraphQLTypes["AdBoardCreateManyTypeInput"]>
};
	["AdBoardCreateManyTypeInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	locationCategoryId?: string | undefined,
	locationSubCategoryId?: string | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	categoryId?: string | undefined,
	restrictedBusinessIds?: Array<string> | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	offlineBookingContactId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	href?: string | undefined,
	workspaceId: string,
	playerIds?: Array<string> | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	publishedVersionId?: string | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	archivedByUserId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AdBoardTypeUpdateManyMutationInput"]: {
		name?: string | undefined,
	description?: string | undefined
};
	["AdBoardTypeUpdateInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutTypeNestedInput"] | undefined
};
	["AdBoardUpdateManyWithoutTypeNestedInput"]: {
		create?: Array<GraphQLTypes["AdBoardCreateWithoutTypeInput"]> | undefined,
	connectOrCreate?: Array<GraphQLTypes["AdBoardCreateOrConnectWithoutTypeInput"]> | undefined,
	upsert?: Array<GraphQLTypes["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]> | undefined,
	createMany?: GraphQLTypes["AdBoardCreateManyTypeInputEnvelope"] | undefined,
	set?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	connect?: Array<GraphQLTypes["AdBoardWhereUniqueInput"]> | undefined,
	update?: Array<GraphQLTypes["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]> | undefined,
	updateMany?: Array<GraphQLTypes["AdBoardUpdateManyWithWhereWithoutTypeInput"]> | undefined,
	deleteMany?: Array<GraphQLTypes["AdBoardScalarWhereInput"]> | undefined
};
	["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	update: GraphQLTypes["AdBoardUpdateWithoutTypeInput"],
	create: GraphQLTypes["AdBoardCreateWithoutTypeInput"]
};
	["AdBoardUpdateWithoutTypeInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	slotConfigurations?: GraphQLTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	data: GraphQLTypes["AdBoardUpdateWithoutTypeInput"]
};
	["AdBoardUpdateManyWithWhereWithoutTypeInput"]: {
		where: GraphQLTypes["AdBoardScalarWhereInput"],
	data: GraphQLTypes["AdBoardUpdateManyMutationInput"]
};
	["AdvertisementCategoryCreateManyInput"]: {
		id?: string | undefined,
	name: string,
	type: GraphQLTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	campaignIds?: Array<string> | undefined,
	bookingIds?: Array<string> | undefined
};
	["AdvertisementCategoryCreateInput"]: {
		id?: string | undefined,
	name: string,
	type: GraphQLTypes["AdvertisementCategoryEnum"],
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutAdCategoryInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdCategoryInput"] | undefined
};
	["AdvertisementCategoryUpdateInput"]: {
		name?: string | undefined,
	type?: GraphQLTypes["AdvertisementCategoryEnum"] | undefined,
	description?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdCategoryNestedInput"] | undefined
};
	["AuditCreateManyInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	userId: string,
	workspaceId?: string | undefined,
	updates?: GraphQLTypes["JSON"] | undefined
};
	["AuditCreateInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	message: string,
	action: string,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutAuditsInput"],
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutAuditsInput"] | undefined
};
	["AuditUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	message?: string | undefined,
	action?: string | undefined,
	entity?: GraphQLTypes["EntityCreateInput"] | undefined,
	updates?: GraphQLTypes["JSON"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"] | undefined
};
	["BookingCreateManyInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	assetsIDs?: Array<string> | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	adBoardId: string,
	workspaceId?: string | undefined,
	campaignId?: string | undefined,
	businessCategoryId?: string | undefined,
	adCategoryId?: Array<string> | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	userId: string,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	sharedUserIds?: Array<string> | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined
};
	["BookingCreateInput"]: {
		id?: string | undefined,
	additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode: string,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails: GraphQLTypes["BookingDetailsCreateInput"],
	bookingStatus: GraphQLTypes["BookingStatus"],
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus: GraphQLTypes["DeploymentStatus"],
	contentApprovalStatus: GraphQLTypes["ContentApprovalStatus"],
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutBookingsInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"] | undefined,
	proofs?: GraphQLTypes["FileCreateNestedManyWithoutProofBookingInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutBookingsInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutBookingsInput"],
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutBookingInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutBookingsInput"] | undefined,
	campaign?: GraphQLTypes["CampaignCreateNestedOneWithoutBookingsInput"] | undefined,
	payouts?: GraphQLTypes["PayoutCreateNestedManyWithoutBookingInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserCreateNestedManyWithoutSharedBookingsInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutBookingInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageCreateNestedManyWithoutBookingInput"] | undefined
};
	["BookingUpdateInput"]: {
		additionalInformation?: string | undefined,
	origin?: string | undefined,
	bookingCode?: string | undefined,
	bookingDate?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	bookingDetails?: GraphQLTypes["BookingDetailsCreateInput"] | undefined,
	bookingStatus?: GraphQLTypes["BookingStatus"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	deploymentStatus?: GraphQLTypes["DeploymentStatus"] | undefined,
	contentApprovalStatus?: GraphQLTypes["ContentApprovalStatus"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	pisignage?: GraphQLTypes["BookingPisignageDataCreateInput"] | undefined,
	refund?: GraphQLTypes["BookingRefundCreateInput"] | undefined,
	isOffline?: boolean | undefined,
	isPaid?: boolean | undefined,
	isScheduled?: boolean | undefined,
	isDeployed?: boolean | undefined,
	isCompleted?: boolean | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutBookingsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"] | undefined,
	proofs?: GraphQLTypes["FileUpdateManyWithoutProofBookingNestedInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutBookingNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"] | undefined,
	campaign?: GraphQLTypes["CampaignUpdateOneWithoutBookingsNestedInput"] | undefined,
	payouts?: GraphQLTypes["PayoutUpdateManyWithoutBookingNestedInput"] | undefined,
	sharedUsers?: GraphQLTypes["UserUpdateManyWithoutSharedBookingsNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutBookingNestedInput"] | undefined,
	slotUsage?: GraphQLTypes["SlotUsageUpdateManyWithoutBookingNestedInput"] | undefined
};
	["BusinessCategoryCreateManyInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined,
	restrictedByAdBoardIds?: Array<string> | undefined
};
	["BusinessCategoryCreateInput"]: {
		id?: string | undefined,
	name: string,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCategoryInput"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"] | undefined
};
	["BusinessCategoryUpdateInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCategoryNestedInput"] | undefined,
	restrictedByAdBoards?: GraphQLTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"] | undefined
};
	["CameraCreateManyInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraServerId: string,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	playerId?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	adBoardId?: string | undefined
};
	["CameraCreateInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline: boolean,
	cameraUrl: string,
	rtspUrl: string,
	cameraIp: string,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer: GraphQLTypes["CameraServerCreateNestedOneWithoutCamerasInput"],
	player?: GraphQLTypes["PlayerCreateNestedOneWithoutCamerasInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedCreateNestedManyWithoutCameraInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardCreateNestedOneWithoutCamerasInput"] | undefined
};
	["CameraUpdateInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	cameraUrl?: string | undefined,
	rtspUrl?: string | undefined,
	cameraIp?: string | undefined,
	connectedCpuSerialNumbers?: Array<string> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	isPublic?: boolean | undefined,
	cameraServer?: GraphQLTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"] | undefined,
	player?: GraphQLTypes["PlayerUpdateOneWithoutCamerasNestedInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedUpdateManyWithoutCameraNestedInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneWithoutCamerasNestedInput"] | undefined
};
	["CameraFeedCreateManyInput"]: {
		id?: string | undefined,
	cameraId: string,
	timestamp: GraphQLTypes["DateTime"],
	cameraServerId: string,
	data: GraphQLTypes["CameraFeedDataCreateInput"]
};
	["CameraFeedCreateInput"]: {
		id?: string | undefined,
	timestamp: GraphQLTypes["DateTime"],
	data: GraphQLTypes["CameraFeedDataCreateInput"],
	camera: GraphQLTypes["CameraCreateNestedOneWithoutFeedsInput"],
	cameraServer: GraphQLTypes["CameraServerCreateNestedOneWithoutFeedsInput"]
};
	["CameraFeedUpdateInput"]: {
		timestamp?: GraphQLTypes["DateTime"] | undefined,
	data?: GraphQLTypes["CameraFeedDataCreateInput"] | undefined,
	camera?: GraphQLTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"] | undefined,
	cameraServer?: GraphQLTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"] | undefined
};
	["CameraServerCreateManyInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	workspaceId?: string | undefined,
	publicIp: string,
	privateIp: string
};
	["CameraServerCreateInput"]: {
		id?: string | undefined,
	name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId: string,
	publicIp: string,
	privateIp: string,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutCameraServerInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"] | undefined
};
	["CameraServerUpdateInput"]: {
		name?: string | undefined,
	isOnline?: boolean | undefined,
	hostName?: string | undefined,
	userName?: string | undefined,
	deviceId?: string | undefined,
	publicIp?: string | undefined,
	privateIp?: string | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutCameraServerNestedInput"] | undefined,
	feeds?: GraphQLTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"] | undefined
};
	["CampaignCreateManyInput"]: {
		id?: string | undefined,
	name: string,
	userId: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	businessCategoryId: string,
	adCategoryId?: Array<string> | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	assetsIDs?: Array<string> | undefined
};
	["CampaignCreateInput"]: {
		id?: string | undefined,
	name: string,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus: GraphQLTypes["PaymentStatus"],
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates: GraphQLTypes["OrderSelectedDateCreateInput"],
	origin: string,
	user: GraphQLTypes["UserCreateNestedOneWithoutCampaignsInput"],
	businessCategory: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"],
	adCategory?: GraphQLTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutCampaignInput"] | undefined,
	payments?: GraphQLTypes["PaymentCreateNestedManyWithoutCampaignInput"] | undefined,
	assets?: GraphQLTypes["FileCreateNestedManyWithoutCampaignsInput"] | undefined
};
	["CampaignUpdateInput"]: {
		name?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	bookingFor?: string | undefined,
	purpose?: string | undefined,
	payment?: GraphQLTypes["BookingPaymentCreateInput"] | undefined,
	paymentStatus?: GraphQLTypes["PaymentStatus"] | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDates?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	origin?: string | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"] | undefined,
	adCategory?: GraphQLTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutCampaignNestedInput"] | undefined,
	payments?: GraphQLTypes["PaymentUpdateManyWithoutCampaignNestedInput"] | undefined,
	assets?: GraphQLTypes["FileUpdateManyWithoutCampaignsNestedInput"] | undefined
};
	["FileCreateManyInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedByUserId?: string | undefined,
	createdByUserId: string,
	url: string,
	bookingsIDs?: Array<string> | undefined,
	workspaceId?: string | undefined,
	proofBookingId?: string | undefined,
	campaignIds?: Array<string> | undefined
};
	["FileCreateInput"]: {
		id?: string | undefined,
	contentType: string,
	ctime: string,
	filename: string,
	filepath?: string | undefined,
	isDirectory: boolean,
	meta: GraphQLTypes["FileMetaCreateInput"],
	mtime: string,
	name: string,
	size: number,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type: string,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url: string,
	updatedBy?: GraphQLTypes["UserCreateNestedOneWithoutUpdatedFilesInput"] | undefined,
	createdBy: GraphQLTypes["UserCreateNestedOneWithoutCreatedFilesInput"],
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAssetsInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutFilesInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingCreateNestedOneWithoutProofsInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutAssetsInput"] | undefined
};
	["FileUpdateInput"]: {
		contentType?: string | undefined,
	ctime?: string | undefined,
	filename?: string | undefined,
	filepath?: string | undefined,
	isDirectory?: boolean | undefined,
	meta?: GraphQLTypes["FileMetaCreateInput"] | undefined,
	mtime?: string | undefined,
	name?: string | undefined,
	size?: number | undefined,
	status?: GraphQLTypes["FileStatus"] | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	updatedBy?: GraphQLTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAssetsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutFilesNestedInput"] | undefined,
	proofBooking?: GraphQLTypes["BookingUpdateOneWithoutProofsNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutAssetsNestedInput"] | undefined
};
	["MembershipCreateManyInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	userId: string,
	workspaceId: string,
	roleId: string
};
	["MembershipCreateInput"]: {
		id?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutMembershipsInput"],
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"],
	role: GraphQLTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]
};
	["MembershipUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined,
	role?: GraphQLTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"] | undefined
};
	["NotificationCreateManyInput"]: {
		id?: string | undefined,
	type: GraphQLTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	recipientId: string,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined
};
	["NotificationCreateInput"]: {
		id?: string | undefined,
	type: GraphQLTypes["NotificationType"],
	title: string,
	content: string,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined,
	recipient: GraphQLTypes["UserCreateNestedOneWithoutNotificationsInput"]
};
	["UserCreateNestedOneWithoutNotificationsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutNotificationsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutNotificationsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutNotificationsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["UserCreateOrConnectWithoutNotificationsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutNotificationsInput"]
};
	["NotificationUpdateInput"]: {
		type?: GraphQLTypes["NotificationType"] | undefined,
	title?: string | undefined,
	content?: string | undefined,
	metadata?: GraphQLTypes["JSON"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	readAt?: GraphQLTypes["DateTime"] | undefined,
	channels?: Array<GraphQLTypes["NotificationChannel"]> | undefined,
	recipient?: GraphQLTypes["UserUpdateOneRequiredWithoutNotificationsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutNotificationsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutNotificationsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutNotificationsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutNotificationsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutNotificationsInput"] | undefined
};
	["UserUpsertWithoutNotificationsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutNotificationsInput"],
	create: GraphQLTypes["UserCreateWithoutNotificationsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutNotificationsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["UserUpdateToOneWithWhereWithoutNotificationsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutNotificationsInput"]
};
	["OrderCreateManyInput"]: {
		id?: string | undefined,
	adBoardId: string,
	createdDate?: GraphQLTypes["DateTime"] | undefined,
	createdByUserId?: string | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: GraphQLTypes["PriceSummaryCreateInput"],
	selectedDate: GraphQLTypes["OrderSelectedDateCreateInput"]
};
	["OrderCreateInput"]: {
		id?: string | undefined,
	createdDate?: GraphQLTypes["DateTime"] | undefined,
	origin: string,
	paymentId?: string | undefined,
	priceSummary: GraphQLTypes["PriceSummaryCreateInput"],
	selectedDate: GraphQLTypes["OrderSelectedDateCreateInput"],
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutOrdersInput"],
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutOrdersInput"] | undefined
};
	["OrderUpdateInput"]: {
		createdDate?: GraphQLTypes["DateTime"] | undefined,
	origin?: string | undefined,
	paymentId?: string | undefined,
	priceSummary?: GraphQLTypes["PriceSummaryCreateInput"] | undefined,
	selectedDate?: GraphQLTypes["OrderSelectedDateCreateInput"] | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutOrdersNestedInput"] | undefined
};
	["PlayerCreateManyInput"]: {
		id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	adBoardGroupId?: string | undefined,
	playlistIds?: Array<string> | undefined,
	workpaceId?: string | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	signageTypeId?: string | undefined,
	audienceTypeId?: Array<string> | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardIds?: Array<string> | undefined
};
	["PlayerCreateInput"]: {
		id?: string | undefined,
	name: string,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlayersInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutPlayerInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceCreateNestedManyWithoutPlayersInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutPlayersInput"] | undefined
};
	["PlayerUpdateInput"]: {
		name?: string | undefined,
	configLocation?: string | undefined,
	pisignagePlayerId?: string | undefined,
	pisignageGroupId?: string | undefined,
	cpuSerialNumber?: string | undefined,
	displayName?: string | undefined,
	playlistIds?: Array<string> | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	playbackDetails?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	aspectRatioType?: GraphQLTypes["AspectRatioType"] | undefined,
	galleryImgs?: Array<GraphQLTypes["GalleryFileCreateInput"]> | undefined,
	customAspectRatioWidth?: number | undefined,
	customAspectRatioHeight?: number | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	isOnline?: boolean | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"] | undefined,
	status?: GraphQLTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutPlayerNestedInput"] | undefined,
	signageType?: GraphQLTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"] | undefined,
	audienceType?: GraphQLTypes["AudienceUpdateManyWithoutPlayersNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutPlayersNestedInput"] | undefined
};
	["PlaylistCreateManyInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	footerPlaylistId?: string | undefined,
	sidebarPlaylistId?: string | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	adBoardGroupIds?: Array<string> | undefined,
	workspaceId: string
};
	["PlaylistCreateInput"]: {
		id?: string | undefined,
	name: string,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"],
	footerParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"] | undefined
};
	["PlaylistUpdateInput"]: {
		name?: string | undefined,
	displayName?: string | undefined,
	assets?: Array<GraphQLTypes["PisignageFileCreateInput"]> | undefined,
	isAdsPlaylist?: boolean | undefined,
	layout?: GraphQLTypes["ScreenLayout"] | undefined,
	isDeployed?: boolean | undefined,
	footerPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"] | undefined,
	sidebarPlaylist?: GraphQLTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"] | undefined,
	adBoardGroupsForAds?: GraphQLTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"] | undefined,
	footerParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"] | undefined,
	sidebarParentPlaylists?: GraphQLTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"] | undefined
};
	["SlotConfigurationCreateManyInput"]: {
		id?: string | undefined,
	adBoardId: string,
	date: GraphQLTypes["DateTime"],
	availableSlots: number
};
	["SlotConfigurationCreateInput"]: {
		id?: string | undefined,
	date: GraphQLTypes["DateTime"],
	availableSlots: number,
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"]
};
	["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined
};
	["AdBoardCreateWithoutSlotConfigurationsInput"]: {
		id?: string | undefined,
	address: string,
	state: string,
	city: string,
	country: string,
	map: GraphQLTypes["MapCreateInput"],
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title: string,
	pricePerDay: number,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status: GraphQLTypes["AdBoardStatus"],
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutAdBoardInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutAdBoardInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"] | undefined,
	createdBy?: GraphQLTypes["UserCreateNestedOneWithoutAdBoardsInput"] | undefined,
	workspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"],
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutAdBoardsInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"] | undefined,
	cameras?: GraphQLTypes["CameraCreateNestedManyWithoutAdBoardInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"] | undefined,
	archivedBy?: GraphQLTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"] | undefined
};
	["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"]: {
		where: GraphQLTypes["AdBoardWhereUniqueInput"],
	create: GraphQLTypes["AdBoardCreateWithoutSlotConfigurationsInput"]
};
	["SlotConfigurationUpdateInput"]: {
		date?: GraphQLTypes["DateTime"] | undefined,
	availableSlots?: number | undefined,
	adBoard?: GraphQLTypes["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"] | undefined
};
	["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"]: {
		create?: GraphQLTypes["AdBoardCreateWithoutSlotConfigurationsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"] | undefined,
	upsert?: GraphQLTypes["AdBoardUpsertWithoutSlotConfigurationsInput"] | undefined,
	connect?: GraphQLTypes["AdBoardWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"] | undefined
};
	["AdBoardUpsertWithoutSlotConfigurationsInput"]: {
		update: GraphQLTypes["AdBoardUpdateWithoutSlotConfigurationsInput"],
	create: GraphQLTypes["AdBoardCreateWithoutSlotConfigurationsInput"],
	where?: GraphQLTypes["AdBoardWhereInput"] | undefined
};
	["AdBoardUpdateWithoutSlotConfigurationsInput"]: {
		address?: string | undefined,
	state?: string | undefined,
	city?: string | undefined,
	country?: string | undefined,
	map?: GraphQLTypes["MapCreateInput"] | undefined,
	outdoor?: boolean | undefined,
	alias?: string | undefined,
	title?: string | undefined,
	pricePerDay?: number | undefined,
	originalPricePerDay?: number | undefined,
	description?: string | undefined,
	aboutSpecifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	tags?: Array<string> | undefined,
	featuredImage?: string | undefined,
	galleryImgs?: Array<string> | undefined,
	specifications?: Array<GraphQLTypes["SpecificationCreateInput"]> | undefined,
	notes?: Array<string> | undefined,
	impressionMultiplier?: number | undefined,
	resolution?: GraphQLTypes["ResolutionCreateInput"] | undefined,
	screenSize?: GraphQLTypes["ScreenSizeCreateInput"] | undefined,
	targetAudiences?: Array<string> | undefined,
	playbackTimes?: Array<GraphQLTypes["PlaybackTimeCreateInput"]> | undefined,
	status?: GraphQLTypes["AdBoardStatus"] | undefined,
	adboardDraftStatus?: GraphQLTypes["AdboardDraftStatus"] | undefined,
	isLive?: boolean | undefined,
	webhooks?: Array<GraphQLTypes["WebhookCreateInput"]> | undefined,
	numberOfDisplays?: number | undefined,
	dailyOperationHours?: number | undefined,
	adsPerLoop?: number | undefined,
	adsDuration?: number | undefined,
	monthlyPrice?: number | undefined,
	offlineBookingsEnabled?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	href?: string | undefined,
	publisherScreenId?: string | undefined,
	additionalData?: GraphQLTypes["JSON"] | undefined,
	isDraft?: boolean | undefined,
	lastPublishedAt?: GraphQLTypes["DateTime"] | undefined,
	isArchived?: boolean | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	locationCategory?: GraphQLTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	locationSubCategory?: GraphQLTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	type?: GraphQLTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	category?: GraphQLTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	restrictedBusines?: GraphQLTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutAdBoardNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutAdBoardNestedInput"] | undefined,
	offlineBookingsContact?: GraphQLTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"] | undefined,
	createdBy?: GraphQLTypes["UserUpdateOneWithoutAdBoardsNestedInput"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"] | undefined,
	adBoardGroup?: GraphQLTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"] | undefined,
	cameras?: GraphQLTypes["CameraUpdateManyWithoutAdBoardNestedInput"] | undefined,
	publishedVersion?: GraphQLTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"] | undefined,
	draftAdboard?: GraphQLTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"] | undefined,
	history?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"] | undefined,
	archivedBy?: GraphQLTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"] | undefined
};
	["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"]: {
		where?: GraphQLTypes["AdBoardWhereInput"] | undefined,
	data: GraphQLTypes["AdBoardUpdateWithoutSlotConfigurationsInput"]
};
	["SubscriptionCreateManyInput"]: {
		id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsCreateInput"] | undefined,
	activeWorkspaceId: string
};
	["SubscriptionCreateInput"]: {
		id?: string | undefined,
	packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsCreateInput"] | undefined,
	activeWorkspace: GraphQLTypes["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"]
};
	["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined
};
	["WorkspaceCreateWithoutActiveSubscriptionInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"]: {
		where: GraphQLTypes["WorkspaceWhereUniqueInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutActiveSubscriptionInput"]
};
	["SubscriptionUpdateManyMutationInput"]: {
		packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsCreateInput"] | undefined
};
	["SubscriptionUpdateInput"]: {
		packageId?: string | undefined,
	packageName?: string | undefined,
	priceLookupKey?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	stripePaymentIntentId?: string | undefined,
	stripeSubscriptionId?: string | undefined,
	stripeCheckoutSessionId?: string | undefined,
	cancellationDetails?: GraphQLTypes["CancellationDetailsCreateInput"] | undefined,
	activeWorkspace?: GraphQLTypes["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"] | undefined
};
	["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"]: {
		create?: GraphQLTypes["WorkspaceCreateWithoutActiveSubscriptionInput"] | undefined,
	connectOrCreate?: GraphQLTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"] | undefined,
	upsert?: GraphQLTypes["WorkspaceUpsertWithoutActiveSubscriptionInput"] | undefined,
	connect?: GraphQLTypes["WorkspaceWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"] | undefined
};
	["WorkspaceUpsertWithoutActiveSubscriptionInput"]: {
		update: GraphQLTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"],
	create: GraphQLTypes["WorkspaceCreateWithoutActiveSubscriptionInput"],
	where?: GraphQLTypes["WorkspaceWhereInput"] | undefined
};
	["WorkspaceUpdateWithoutActiveSubscriptionInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"]: {
		where?: GraphQLTypes["WorkspaceWhereInput"] | undefined,
	data: GraphQLTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"]
};
	["UserCreateManyInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	sharedBookingIds?: Array<string> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	leadWorkspaceIds?: Array<string> | undefined
};
	["UserCreateInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionCreateNestedManyWithoutUserInput"] | undefined
};
	["UserUpdateInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined,
	userPositions?: GraphQLTypes["UserPositionUpdateManyWithoutUserNestedInput"] | undefined
};
	["UserPositionCreateManyInput"]: {
		id?: string | undefined,
	timestamp: GraphQLTypes["DateTime"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	userId: string,
	coords: GraphQLTypes["UserPositionCoordsCreateInput"],
	location?: GraphQLTypes["UserPositionLocationCreateInput"] | undefined
};
	["UserPositionCreateInput"]: {
		id?: string | undefined,
	timestamp: GraphQLTypes["DateTime"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	coords: GraphQLTypes["UserPositionCoordsCreateInput"],
	location?: GraphQLTypes["UserPositionLocationCreateInput"] | undefined,
	user: GraphQLTypes["UserCreateNestedOneWithoutUserPositionsInput"]
};
	["UserCreateNestedOneWithoutUserPositionsInput"]: {
		create?: GraphQLTypes["UserCreateWithoutUserPositionsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutUserPositionsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined
};
	["UserCreateWithoutUserPositionsInput"]: {
		id?: string | undefined,
	uid?: string | undefined,
	email: string,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutUserInput"] | undefined,
	createdFiles?: GraphQLTypes["FileCreateNestedManyWithoutCreatedByInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileCreateNestedManyWithoutUpdatedByInput"] | undefined,
	orders?: GraphQLTypes["OrderCreateNestedManyWithoutCreatedByInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogCreateNestedManyWithoutUserInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutCreatedByInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutUserInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignCreateNestedManyWithoutUserInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingCreateNestedManyWithoutSharedUsersInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutUserInput"] | undefined,
	notifications?: GraphQLTypes["NotificationCreateNestedManyWithoutRecipientInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardCreateNestedManyWithoutArchivedByInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutOwnerInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceCreateNestedManyWithoutLeadsInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"] | undefined
};
	["UserCreateOrConnectWithoutUserPositionsInput"]: {
		where: GraphQLTypes["UserWhereUniqueInput"],
	create: GraphQLTypes["UserCreateWithoutUserPositionsInput"]
};
	["UserPositionUpdateInput"]: {
		timestamp?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	coords?: GraphQLTypes["UserPositionCoordsCreateInput"] | undefined,
	location?: GraphQLTypes["UserPositionLocationCreateInput"] | undefined,
	user?: GraphQLTypes["UserUpdateOneRequiredWithoutUserPositionsNestedInput"] | undefined
};
	["UserUpdateOneRequiredWithoutUserPositionsNestedInput"]: {
		create?: GraphQLTypes["UserCreateWithoutUserPositionsInput"] | undefined,
	connectOrCreate?: GraphQLTypes["UserCreateOrConnectWithoutUserPositionsInput"] | undefined,
	upsert?: GraphQLTypes["UserUpsertWithoutUserPositionsInput"] | undefined,
	connect?: GraphQLTypes["UserWhereUniqueInput"] | undefined,
	update?: GraphQLTypes["UserUpdateToOneWithWhereWithoutUserPositionsInput"] | undefined
};
	["UserUpsertWithoutUserPositionsInput"]: {
		update: GraphQLTypes["UserUpdateWithoutUserPositionsInput"],
	create: GraphQLTypes["UserCreateWithoutUserPositionsInput"],
	where?: GraphQLTypes["UserWhereInput"] | undefined
};
	["UserUpdateWithoutUserPositionsInput"]: {
		uid?: string | undefined,
	email?: string | undefined,
	image?: string | undefined,
	phoneNumber?: string | undefined,
	displayName?: string | undefined,
	otherDetails?: GraphQLTypes["JSON"] | undefined,
	stripe?: GraphQLTypes["UserStripeCreateInput"] | undefined,
	roles?: Array<GraphQLTypes["Role"]> | undefined,
	refunds?: Array<GraphQLTypes["BookingRefundCreateInput"]> | undefined,
	disclaimerAccepted?: boolean | undefined,
	notificationPreference?: GraphQLTypes["NotificationPreferenceCreateInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutUserNestedInput"] | undefined,
	createdFiles?: GraphQLTypes["FileUpdateManyWithoutCreatedByNestedInput"] | undefined,
	updatedFiles?: GraphQLTypes["FileUpdateManyWithoutUpdatedByNestedInput"] | undefined,
	orders?: GraphQLTypes["OrderUpdateManyWithoutCreatedByNestedInput"] | undefined,
	changelogs?: GraphQLTypes["ChangelogUpdateManyWithoutUserNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutUserNestedInput"] | undefined,
	campaigns?: GraphQLTypes["CampaignUpdateManyWithoutUserNestedInput"] | undefined,
	sharedBookings?: GraphQLTypes["BookingUpdateManyWithoutSharedUsersNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutUserNestedInput"] | undefined,
	notifications?: GraphQLTypes["NotificationUpdateManyWithoutRecipientNestedInput"] | undefined,
	publishAdboardVersion?: GraphQLTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"] | undefined,
	offlineBookingAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"] | undefined,
	reviewComments?: GraphQLTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"] | undefined,
	archivedAdboards?: GraphQLTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"] | undefined,
	ownedWorkspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"] | undefined,
	leadWorspaces?: GraphQLTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"] | undefined,
	publicReviews?: GraphQLTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"] | undefined
};
	["UserUpdateToOneWithWhereWithoutUserPositionsInput"]: {
		where?: GraphQLTypes["UserWhereInput"] | undefined,
	data: GraphQLTypes["UserUpdateWithoutUserPositionsInput"]
};
	["WorkspaceCreateManyInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	ownerId?: string | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	leadIds?: Array<string> | undefined
};
	["WorkspaceCreateInput"]: {
		id?: string | undefined,
	type?: GraphQLTypes["WorkspaceType"] | undefined,
	name: string,
	key: string,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"] | undefined,
	audits?: GraphQLTypes["AuditCreateNestedManyWithoutWorkspaceInput"] | undefined,
	bookings?: GraphQLTypes["BookingCreateNestedManyWithoutWorkspaceInput"] | undefined,
	files?: GraphQLTypes["FileCreateNestedManyWithoutWorkspaceInput"] | undefined,
	players?: GraphQLTypes["PlayerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	transactions?: GraphQLTypes["TransactionCreateNestedManyWithoutWorkspaceInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"] | undefined,
	leads?: GraphQLTypes["UserCreateNestedManyWithoutLeadWorspacesInput"] | undefined
};
	["WorkspaceUpdateInput"]: {
		type?: GraphQLTypes["WorkspaceType"] | undefined,
	name?: string | undefined,
	key?: string | undefined,
	userIds?: Array<string> | undefined,
	avatar?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	stripeSubscriptionId?: string | undefined,
	owner?: GraphQLTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoards?: GraphQLTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	playlists?: GraphQLTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	adBoardGroups?: GraphQLTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	audits?: GraphQLTypes["AuditUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	bookings?: GraphQLTypes["BookingUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	files?: GraphQLTypes["FileUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	players?: GraphQLTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	transactions?: GraphQLTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	activeSubscription?: GraphQLTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"] | undefined,
	cameraServers?: GraphQLTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	workspaceRoles?: GraphQLTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	webhookLogs?: GraphQLTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"] | undefined,
	leads?: GraphQLTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"] | undefined
};
	["WorkspaceRoleCreateManyInput"]: {
		id?: string | undefined,
	name: string,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	workspaceId?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined
};
	["WorkspaceRoleCreateInput"]: {
		id?: string | undefined,
	name: string,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"] | undefined,
	memberships?: GraphQLTypes["MembershipCreateNestedManyWithoutRoleInput"] | undefined
};
	["WorkspaceRoleUpdateInput"]: {
		name?: string | undefined,
	permissions?: Array<GraphQLTypes["Permission"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	workspace?: GraphQLTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"] | undefined,
	memberships?: GraphQLTypes["MembershipUpdateManyWithoutRoleNestedInput"] | undefined
};
	["AccountDetails"]: {
		displayName: string,
	phoneNumber: string,
	password: string
};
	["UserInput"]: {
		displayName: string,
	phoneNumber: string,
	password: string,
	email: string
};
	["GenericResponse"]: {
	__typename: "GenericResponse",
	success: boolean
};
	["EmailOutput"]: {
	__typename: "EmailOutput",
	success: boolean
};
	["TemplateIds"]: TemplateIds;
	["AdBoardPublicReviewCreateInput"]: {
		id?: string | undefined,
	rating: number,
	comment?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	adBoard: GraphQLTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"],
	user: GraphQLTypes["UserCreateNestedOneWithoutPublicReviewsInput"]
};
	/** The recipe model */
["TypedGenericResponse"]: {
	__typename: "TypedGenericResponse",
	success: boolean
};
	["BookingRequest"]: {
		assets: Array<string>,
	bookingDetails: GraphQLTypes["BookingDetailsInput"],
	orderId: string
};
	["BookingDetailsInput"]: {
		email: string,
	name: string,
	phone: string
}
    }
export const enum AdBoardStatus {
	DRAFT = "DRAFT",
	IN_REVIEW = "IN_REVIEW",
	APPROVED = "APPROVED",
	REJECTED = "REJECTED",
	PUBLISHED = "PUBLISHED",
	COMING_SOON = "COMING_SOON",
	ARCHIVED = "ARCHIVED",
	ENABLED = "ENABLED",
	DISABLED = "DISABLED"
}
export const enum AdboardDraftStatus {
	DRAFT = "DRAFT",
	IN_REVIEW = "IN_REVIEW",
	APPROVED = "APPROVED",
	REJECTED = "REJECTED"
}
export const enum QueryMode {
	default = "default",
	insensitive = "insensitive"
}
export const enum MeasurementUnit {
	FT = "FT",
	INCH = "INCH"
}
export const enum PaymentGatewayName {
	RAZORPAY = "RAZORPAY",
	STRIPE = "STRIPE",
	INSTAMOJO = "INSTAMOJO"
}
export const enum PaymentStatus {
	PENDING = "PENDING",
	PARTIALLY_PAID = "PARTIALLY_PAID",
	PAID = "PAID",
	FAILED = "FAILED",
	REFUNDED = "REFUNDED",
	PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED"
}
export const enum Role {
	SUPER_ADMIN = "SUPER_ADMIN",
	USER = "USER",
	GUEST = "GUEST"
}
export const enum NotificationType {
	BOOKING_STATUS_CHANGE = "BOOKING_STATUS_CHANGE",
	WORKFLOW_STEP_ASSIGNED = "WORKFLOW_STEP_ASSIGNED",
	WORKFLOW_STEP_COMPLETED = "WORKFLOW_STEP_COMPLETED",
	NEW_COMMENT = "NEW_COMMENT",
	SYSTEM_ALERT = "SYSTEM_ALERT",
	CUSTOM = "CUSTOM"
}
export const enum NotificationChannel {
	IN_APP = "IN_APP",
	EMAIL = "EMAIL",
	SMS = "SMS",
	PUSH = "PUSH"
}
export const enum BookingStatus {
	ENQUIRY = "ENQUIRY",
	PENDING_APPROVAL = "PENDING_APPROVAL",
	APPROVED = "APPROVED",
	REJECTED = "REJECTED",
	PAYMENT_PENDING = "PAYMENT_PENDING",
	PAYMENT_RECIEVED = "PAYMENT_RECIEVED",
	SCHEDULED = "SCHEDULED",
	DEPLOYED = "DEPLOYED",
	LIVE = "LIVE",
	COMPLETED = "COMPLETED",
	CANCELLED = "CANCELLED",
	REFUND_REQUESTED = "REFUND_REQUESTED",
	REFUND_PROCESSED = "REFUND_PROCESSED"
}
export const enum DeploymentStatus {
	NOT_STARTED = "NOT_STARTED",
	PENDING = "PENDING",
	IN_PROGRESS = "IN_PROGRESS",
	DEPLOYED = "DEPLOYED",
	FAILED = "FAILED"
}
export const enum ContentApprovalStatus {
	NOT_SUBMITTED = "NOT_SUBMITTED",
	PENDING_REVIEW = "PENDING_REVIEW",
	APPROVED = "APPROVED",
	REJECTED = "REJECTED"
}
export const enum FileStatus {
	REQUEST_APPROVAL = "REQUEST_APPROVAL",
	APPROVED = "APPROVED",
	REJECTED = "REJECTED"
}
export const enum WorkspaceType {
	TEST = "TEST",
	PROD = "PROD"
}
export const enum Permission {
	SUPER_ADMIN = "SUPER_ADMIN",
	VIEW_PLAYERS = "VIEW_PLAYERS",
	MANAGE_PLAYERS = "MANAGE_PLAYERS",
	MANAGE_USERS = "MANAGE_USERS",
	VIEW_USERS = "VIEW_USERS",
	MANAGE_FILES = "MANAGE_FILES",
	VIEW_FILES = "VIEW_FILES",
	MANAGE_ROLES = "MANAGE_ROLES",
	VIEW_ROLES = "VIEW_ROLES",
	MANAGE_ADBOARDS = "MANAGE_ADBOARDS",
	VIEW_ADBOARDS = "VIEW_ADBOARDS",
	MANAGE_BOOKINGS = "MANAGE_BOOKINGS",
	VIEW_BOOKINGS = "VIEW_BOOKINGS",
	MANAGE_WORKSPACES = "MANAGE_WORKSPACES",
	VIEW_WORKSPACES = "VIEW_WORKSPACES",
	MANAGE_PLAYLISTS = "MANAGE_PLAYLISTS",
	VIEW_PLAYLISTS = "VIEW_PLAYLISTS",
	MANAGE_ADBOARD_GROUPS = "MANAGE_ADBOARD_GROUPS",
	VIEW_ADBOARD_GROUPS = "VIEW_ADBOARD_GROUPS",
	MANAGE_ACCESS_LABELS = "MANAGE_ACCESS_LABELS",
	VIEW_ACCESS_LABELS = "VIEW_ACCESS_LABELS",
	MANAGE_BUSINESS_CATEGORIES = "MANAGE_BUSINESS_CATEGORIES",
	VIEW_BUSINESS_CATEGORIES = "VIEW_BUSINESS_CATEGORIES",
	MANAGE_ADS = "MANAGE_ADS",
	VIEW_CAMERA = "VIEW_CAMERA",
	MANAGE_CAMERA = "MANAGE_CAMERA"
}
export const enum ScreenLayout {
	DEFAULT = "DEFAULT",
	SIDEBAR_AND_FOOTER = "SIDEBAR_AND_FOOTER",
	SIDEBAR_ONLY = "SIDEBAR_ONLY",
	FOOTER_ONLY = "FOOTER_ONLY"
}
export const enum FitType {
	STRETCHED = "STRETCHED",
	LETTERBOX = "LETTERBOX"
}
export const enum AspectRatioType {
	STANDARD_4_3 = "STANDARD_4_3",
	WIDE_16_9 = "WIDE_16_9",
	ULTRA_WIDE_21_9 = "ULTRA_WIDE_21_9",
	SQUARE_1_1 = "SQUARE_1_1",
	VERTICAL_9_16 = "VERTICAL_9_16",
	CUSTOM = "CUSTOM",
	VERTICAL_2_3 = "VERTICAL_2_3",
	WIDE_16_10 = "WIDE_16_10",
	ULTRA_WIDE_32_9 = "ULTRA_WIDE_32_9"
}
export const enum SignageType {
	INDOOR_LED = "INDOOR_LED",
	INDOOR_LCD = "INDOOR_LCD",
	INDOOR_TOUCHSCREEN = "INDOOR_TOUCHSCREEN",
	INDOOR_VIDEO_WALL = "INDOOR_VIDEO_WALL",
	OUTDOOR_BILLBOARD = "OUTDOOR_BILLBOARD",
	OUTDOOR_LED = "OUTDOOR_LED",
	WEATHERPROOF_DISPLAY = "WEATHERPROOF_DISPLAY",
	RETAIL_POS_DISPLAY = "RETAIL_POS_DISPLAY",
	RETAIL_SHELF_EDGE = "RETAIL_SHELF_EDGE",
	DIGITAL_MENU = "DIGITAL_MENU",
	VIRTUAL_PROMOTIONS = "VIRTUAL_PROMOTIONS",
	TRANSPORT_SCHEDULE = "TRANSPORT_SCHEDULE",
	TRANSPORT_WAYFINDING = "TRANSPORT_WAYFINDING",
	CORPORATE_COMMUNICATION = "CORPORATE_COMMUNICATION",
	CORPORATE_MEETING_ROOM = "CORPORATE_MEETING_ROOM",
	VIRTUAL_PROJECTION = "VIRTUAL_PROJECTION",
	AUGMENTED_REALITY = "AUGMENTED_REALITY"
}
export const enum AudienceType {
	GENERAL_PUBLIC = "GENERAL_PUBLIC",
	CONSUMERS = "CONSUMERS",
	EMPLOYEES = "EMPLOYEES",
	TRAVELERS = "TRAVELERS",
	EVENT_GOERS = "EVENT_GOERS",
	SHOPPERS = "SHOPPERS",
	BUSINESS_PARTNERS = "BUSINESS_PARTNERS",
	TOURISTS = "TOURISTS",
	STUDENTS = "STUDENTS",
	VIP_AUDIENCE = "VIP_AUDIENCE"
}
export const enum TransactionStatus {
	PENDING = "PENDING",
	PROCESSED = "PROCESSED",
	FAILED = "FAILED"
}
export const enum PayoutStatus {
	PENDING = "PENDING",
	SCHEDULED = "SCHEDULED",
	PROCESSED = "PROCESSED"
}
export const enum AdvertisementCategoryEnum {
	PRODUCT_LAUNCH = "PRODUCT_LAUNCH",
	PROMOTIONS_DISCOUNTS = "PROMOTIONS_DISCOUNTS",
	BRAND_AWARENESS = "BRAND_AWARENESS",
	EVENT_PROMOTION = "EVENT_PROMOTION",
	SEASONAL_CAMPAIGNS = "SEASONAL_CAMPAIGNS",
	PUBLIC_SERVICE_ANNOUNCEMENTS = "PUBLIC_SERVICE_ANNOUNCEMENTS",
	POLITICAL_CAMPAIGNS = "POLITICAL_CAMPAIGNS",
	ENTERTAINMENT = "ENTERTAINMENT",
	EDUCATIONAL_INFORMATIONAL = "EDUCATIONAL_INFORMATIONAL",
	COMMUNITY_ENGAGEMENT = "COMMUNITY_ENGAGEMENT",
	ECOMMERCE_RETAIL = "ECOMMERCE_RETAIL",
	TECHNOLOGY = "TECHNOLOGY",
	FINANCE_INSURANCE = "FINANCE_INSURANCE",
	AUTOMOTIVE = "AUTOMOTIVE",
	TRAVEL_TOURISM = "TRAVEL_TOURISM",
	REAL_ESTATE = "REAL_ESTATE",
	FOOD_BEVERAGE = "FOOD_BEVERAGE",
	LUXURY_LIFESTYLE = "LUXURY_LIFESTYLE",
	SPORTS_FITNESS = "SPORTS_FITNESS",
	SOCIAL_CAUSES_NONPROFITS = "SOCIAL_CAUSES_NONPROFITS",
	MOBILE_APPS_GAMES = "MOBILE_APPS_GAMES",
	HEALTHCARE_WELLNESS = "HEALTHCARE_WELLNESS",
	SUSTAINABILITY_ENVIRONMENTAL = "SUSTAINABILITY_ENVIRONMENTAL",
	GAMING = "GAMING"
}
export const enum ReviewAction {
	COMMENT = "COMMENT",
	APPROVE = "APPROVE",
	REJECT = "REJECT"
}
export const enum SortOrder {
	asc = "asc",
	desc = "desc"
}
export const enum AdBoardScalarFieldEnum {
	id = "id",
	address = "address",
	state = "state",
	city = "city",
	country = "country",
	outdoor = "outdoor",
	locationCategoryId = "locationCategoryId",
	locationSubCategoryId = "locationSubCategoryId",
	alias = "alias",
	title = "title",
	pricePerDay = "pricePerDay",
	originalPricePerDay = "originalPricePerDay",
	description = "description",
	adBoardTypeId = "adBoardTypeId",
	tags = "tags",
	featuredImage = "featuredImage",
	galleryImgs = "galleryImgs",
	notes = "notes",
	impressionMultiplier = "impressionMultiplier",
	categoryId = "categoryId",
	restrictedBusinessIds = "restrictedBusinessIds",
	targetAudiences = "targetAudiences",
	status = "status",
	adboardDraftStatus = "adboardDraftStatus",
	isLive = "isLive",
	numberOfDisplays = "numberOfDisplays",
	dailyOperationHours = "dailyOperationHours",
	adsPerLoop = "adsPerLoop",
	adsDuration = "adsDuration",
	monthlyPrice = "monthlyPrice",
	offlineBookingsEnabled = "offlineBookingsEnabled",
	offlineBookingContactId = "offlineBookingContactId",
	createdAt = "createdAt",
	createdByUserId = "createdByUserId",
	href = "href",
	workspaceId = "workspaceId",
	playerIds = "playerIds",
	publisherScreenId = "publisherScreenId",
	additionalData = "additionalData",
	isDraft = "isDraft",
	publishedVersionId = "publishedVersionId",
	lastPublishedAt = "lastPublishedAt",
	isArchived = "isArchived",
	archivedAt = "archivedAt",
	archivedByUserId = "archivedByUserId",
	updatedAt = "updatedAt"
}
export const enum LocationSubCategoryScalarFieldEnum {
	id = "id",
	name = "name",
	locationCategoryId = "locationCategoryId"
}
export const enum BookingScalarFieldEnum {
	id = "id",
	additionalInformation = "additionalInformation",
	origin = "origin",
	assetsIDs = "assetsIDs",
	bookingCode = "bookingCode",
	bookingDate = "bookingDate",
	bookingFor = "bookingFor",
	purpose = "purpose",
	adBoardId = "adBoardId",
	workspaceId = "workspaceId",
	campaignId = "campaignId",
	businessCategoryId = "businessCategoryId",
	adCategoryId = "adCategoryId",
	bookingStatus = "bookingStatus",
	paymentStatus = "paymentStatus",
	deploymentStatus = "deploymentStatus",
	contentApprovalStatus = "contentApprovalStatus",
	userId = "userId",
	sharedUserIds = "sharedUserIds",
	isOffline = "isOffline",
	isPaid = "isPaid",
	isScheduled = "isScheduled",
	isDeployed = "isDeployed",
	isCompleted = "isCompleted"
}
export const enum MembershipScalarFieldEnum {
	id = "id",
	createdAt = "createdAt",
	userId = "userId",
	workspaceId = "workspaceId",
	roleId = "roleId"
}
export const enum PlaylistScalarFieldEnum {
	id = "id",
	name = "name",
	displayName = "displayName",
	isAdsPlaylist = "isAdsPlaylist",
	footerPlaylistId = "footerPlaylistId",
	sidebarPlaylistId = "sidebarPlaylistId",
	layout = "layout",
	isDeployed = "isDeployed",
	adBoardGroupIds = "adBoardGroupIds",
	workspaceId = "workspaceId"
}
export const enum PlayerUptimeStatusScalarFieldEnum {
	id = "id",
	playerId = "playerId",
	cpuSerialNumber = "cpuSerialNumber",
	date = "date",
	uptime = "uptime"
}
export const enum CameraScalarFieldEnum {
	id = "id",
	name = "name",
	isOnline = "isOnline",
	cameraServerId = "cameraServerId",
	cameraUrl = "cameraUrl",
	rtspUrl = "rtspUrl",
	cameraIp = "cameraIp",
	connectedCpuSerialNumbers = "connectedCpuSerialNumbers",
	playerId = "playerId",
	updatedAt = "updatedAt",
	isPublic = "isPublic",
	adBoardId = "adBoardId"
}
export const enum CameraFeedScalarFieldEnum {
	id = "id",
	cameraId = "cameraId",
	timestamp = "timestamp",
	cameraServerId = "cameraServerId"
}
export const enum PlayerScalarFieldEnum {
	id = "id",
	name = "name",
	configLocation = "configLocation",
	pisignagePlayerId = "pisignagePlayerId",
	pisignageGroupId = "pisignageGroupId",
	cpuSerialNumber = "cpuSerialNumber",
	displayName = "displayName",
	adBoardGroupId = "adBoardGroupId",
	playlistIds = "playlistIds",
	workpaceId = "workpaceId",
	signageTypeId = "signageTypeId",
	audienceTypeId = "audienceTypeId",
	aspectRatioType = "aspectRatioType",
	customAspectRatioWidth = "customAspectRatioWidth",
	customAspectRatioHeight = "customAspectRatioHeight",
	isOnline = "isOnline",
	adBoardIds = "adBoardIds"
}
export const enum AudienceScalarFieldEnum {
	id = "id",
	type = "type",
	name = "name",
	description = "description",
	createdAt = "createdAt",
	updatedAt = "updatedAt",
	playerIDs = "playerIDs"
}
export const enum AdBoardGroupScalarFieldEnum {
	id = "id",
	name = "name",
	adBoardId = "adBoardId",
	scheduledPlaylistsIDs = "scheduledPlaylistsIDs",
	adsPlaylistID = "adsPlaylistID",
	pisignageGroupId = "pisignageGroupId",
	fitImage = "fitImage",
	fitVideo = "fitVideo",
	workspaceId = "workspaceId"
}
export const enum AuditScalarFieldEnum {
	id = "id",
	createdAt = "createdAt",
	message = "message",
	action = "action",
	userId = "userId",
	workspaceId = "workspaceId",
	updates = "updates"
}
export const enum FileScalarFieldEnum {
	id = "id",
	contentType = "contentType",
	ctime = "ctime",
	filename = "filename",
	filepath = "filepath",
	isDirectory = "isDirectory",
	mtime = "mtime",
	name = "name",
	size = "size",
	status = "status",
	type = "type",
	updatedAt = "updatedAt",
	createdAt = "createdAt",
	updatedByUserId = "updatedByUserId",
	createdByUserId = "createdByUserId",
	url = "url",
	bookingsIDs = "bookingsIDs",
	workspaceId = "workspaceId",
	proofBookingId = "proofBookingId",
	campaignIds = "campaignIds"
}
export const enum PayoutScalarFieldEnum {
	id = "id",
	totalAmount = "totalAmount",
	commissionRate = "commissionRate",
	commissionAmount = "commissionAmount",
	netAmount = "netAmount",
	createdAt = "createdAt",
	bookingId = "bookingId",
	status = "status",
	transactionId = "transactionId"
}
export const enum TransactionScalarFieldEnum {
	workspaceId = "workspaceId",
	id = "id",
	amount = "amount",
	createAt = "createAt",
	processedAt = "processedAt",
	relatedEntityId = "relatedEntityId",
	transactionStatus = "transactionStatus",
	details = "details"
}
export const enum CameraServerScalarFieldEnum {
	id = "id",
	name = "name",
	isOnline = "isOnline",
	hostName = "hostName",
	userName = "userName",
	deviceId = "deviceId",
	workspaceId = "workspaceId",
	publicIp = "publicIp",
	privateIp = "privateIp"
}
export const enum WorkspaceRoleScalarFieldEnum {
	id = "id",
	name = "name",
	permissions = "permissions",
	workspaceId = "workspaceId",
	createdAt = "createdAt",
	updatedAt = "updatedAt"
}
export const enum WebhookLogScalarFieldEnum {
	id = "id",
	bookingId = "bookingId",
	response = "response",
	request = "request",
	status = "status",
	createdAt = "createdAt",
	updatedAt = "updatedAt",
	workspaceId = "workspaceId"
}
export const enum UserScalarFieldEnum {
	id = "id",
	uid = "uid",
	email = "email",
	image = "image",
	phoneNumber = "phoneNumber",
	displayName = "displayName",
	otherDetails = "otherDetails",
	roles = "roles",
	sharedBookingIds = "sharedBookingIds",
	disclaimerAccepted = "disclaimerAccepted",
	leadWorkspaceIds = "leadWorkspaceIds"
}
export const enum CampaignScalarFieldEnum {
	id = "id",
	name = "name",
	userId = "userId",
	createdAt = "createdAt",
	updatedAt = "updatedAt",
	bookingFor = "bookingFor",
	purpose = "purpose",
	businessCategoryId = "businessCategoryId",
	adCategoryId = "adCategoryId",
	paymentStatus = "paymentStatus",
	origin = "origin",
	assetsIDs = "assetsIDs"
}
export const enum AdvertisementCategoryScalarFieldEnum {
	id = "id",
	name = "name",
	type = "type",
	description = "description",
	createdAt = "createdAt",
	updatedAt = "updatedAt",
	campaignIds = "campaignIds",
	bookingIds = "bookingIds"
}
export const enum ChangelogScalarFieldEnum {
	id = "id",
	createdAt = "createdAt",
	message = "message",
	action = "action",
	bookingId = "bookingId",
	userId = "userId",
	updates = "updates"
}
export const enum SlotUsageScalarFieldEnum {
	id = "id",
	bookingId = "bookingId",
	date = "date",
	slotUsed = "slotUsed"
}
export const enum OrderScalarFieldEnum {
	id = "id",
	adBoardId = "adBoardId",
	createdDate = "createdDate",
	createdByUserId = "createdByUserId",
	origin = "origin",
	paymentId = "paymentId"
}
export const enum NotificationScalarFieldEnum {
	id = "id",
	type = "type",
	title = "title",
	content = "content",
	metadata = "metadata",
	createdAt = "createdAt",
	readAt = "readAt",
	recipientId = "recipientId",
	channels = "channels"
}
export const enum AdBoardPublishHistoryScalarFieldEnum {
	id = "id",
	adBoardId = "adBoardId",
	publishedAt = "publishedAt",
	publishedByUserId = "publishedByUserId",
	changesSnapshot = "changesSnapshot"
}
export const enum AdBoardReviewCommentScalarFieldEnum {
	id = "id",
	adBoardId = "adBoardId",
	userId = "userId",
	comment = "comment",
	action = "action",
	createdAt = "createdAt"
}
export const enum WorkspaceScalarFieldEnum {
	id = "id",
	type = "type",
	name = "name",
	key = "key",
	userIds = "userIds",
	ownerId = "ownerId",
	avatar = "avatar",
	createdAt = "createdAt",
	updatedAt = "updatedAt",
	stripeSubscriptionId = "stripeSubscriptionId",
	leadIds = "leadIds"
}
export const enum AdBoardPublicReviewScalarFieldEnum {
	id = "id",
	adBoardId = "adBoardId",
	userId = "userId",
	rating = "rating",
	comment = "comment",
	createdAt = "createdAt"
}
export const enum UserPositionScalarFieldEnum {
	id = "id",
	timestamp = "timestamp",
	createdAt = "createdAt",
	userId = "userId"
}
export const enum PaymentScalarFieldEnum {
	id = "id",
	paymentIntentId = "paymentIntentId",
	checkoutSessionId = "checkoutSessionId",
	eventId = "eventId",
	liveMode = "liveMode",
	paymentGateway = "paymentGateway",
	subtotal = "subtotal",
	total = "total",
	tax = "tax",
	discount = "discount",
	campaignId = "campaignId",
	createdAt = "createdAt",
	updatedAt = "updatedAt"
}
export const enum BusinessCategoryScalarFieldEnum {
	id = "id",
	name = "name",
	description = "description",
	restrictedByAdBoardIds = "restrictedByAdBoardIds"
}
export const enum SlotConfigurationScalarFieldEnum {
	id = "id",
	adBoardId = "adBoardId",
	date = "date",
	availableSlots = "availableSlots"
}
export const enum AdBoardTypeScalarFieldEnum {
	id = "id",
	name = "name",
	description = "description"
}
export const enum SubscriptionScalarFieldEnum {
	id = "id",
	packageId = "packageId",
	packageName = "packageName",
	priceLookupKey = "priceLookupKey",
	startDate = "startDate",
	endDate = "endDate",
	status = "status",
	stripePaymentIntentId = "stripePaymentIntentId",
	stripeSubscriptionId = "stripeSubscriptionId",
	stripeCheckoutSessionId = "stripeCheckoutSessionId",
	activeWorkspaceId = "activeWorkspaceId"
}
export const enum TemplateIds {
	ORDER_CONFIRMATION = "ORDER_CONFIRMATION",
	STATUS_UPDATE = "STATUS_UPDATE",
	ORDER_CANCELLED = "ORDER_CANCELLED",
	BOOKING_CONFIRMED = "BOOKING_CONFIRMED",
	ASSET_UPDATED = "ASSET_UPDATED"
}

type ZEUS_VARIABLES = {
	["AdBoardStatus"]: ValueTypes["AdBoardStatus"];
	["AdboardDraftStatus"]: ValueTypes["AdboardDraftStatus"];
	["DateTime"]: ValueTypes["DateTime"];
	["AdBoardWhereInput"]: ValueTypes["AdBoardWhereInput"];
	["StringFilter"]: ValueTypes["StringFilter"];
	["QueryMode"]: ValueTypes["QueryMode"];
	["NestedStringFilter"]: ValueTypes["NestedStringFilter"];
	["MapCompositeFilter"]: ValueTypes["MapCompositeFilter"];
	["MapObjectEqualityInput"]: ValueTypes["MapObjectEqualityInput"];
	["MapWhereInput"]: ValueTypes["MapWhereInput"];
	["FloatFilter"]: ValueTypes["FloatFilter"];
	["NestedFloatFilter"]: ValueTypes["NestedFloatFilter"];
	["StringNullableFilter"]: ValueTypes["StringNullableFilter"];
	["NestedStringNullableFilter"]: ValueTypes["NestedStringNullableFilter"];
	["BoolFilter"]: ValueTypes["BoolFilter"];
	["NestedBoolFilter"]: ValueTypes["NestedBoolFilter"];
	["IntFilter"]: ValueTypes["IntFilter"];
	["NestedIntFilter"]: ValueTypes["NestedIntFilter"];
	["IntNullableFilter"]: ValueTypes["IntNullableFilter"];
	["NestedIntNullableFilter"]: ValueTypes["NestedIntNullableFilter"];
	["SpecificationObjectEqualityInput"]: ValueTypes["SpecificationObjectEqualityInput"];
	["StringNullableListFilter"]: ValueTypes["StringNullableListFilter"];
	["FloatNullableFilter"]: ValueTypes["FloatNullableFilter"];
	["NestedFloatNullableFilter"]: ValueTypes["NestedFloatNullableFilter"];
	["ResolutionNullableCompositeFilter"]: ValueTypes["ResolutionNullableCompositeFilter"];
	["ResolutionObjectEqualityInput"]: ValueTypes["ResolutionObjectEqualityInput"];
	["ResolutionWhereInput"]: ValueTypes["ResolutionWhereInput"];
	["ScreenSizeNullableCompositeFilter"]: ValueTypes["ScreenSizeNullableCompositeFilter"];
	["ScreenSizeObjectEqualityInput"]: ValueTypes["ScreenSizeObjectEqualityInput"];
	["MeasurementUnit"]: ValueTypes["MeasurementUnit"];
	["ScreenSizeWhereInput"]: ValueTypes["ScreenSizeWhereInput"];
	["EnumMeasurementUnitFilter"]: ValueTypes["EnumMeasurementUnitFilter"];
	["NestedEnumMeasurementUnitFilter"]: ValueTypes["NestedEnumMeasurementUnitFilter"];
	["PlaybackTimeObjectEqualityInput"]: ValueTypes["PlaybackTimeObjectEqualityInput"];
	["EnumAdBoardStatusFilter"]: ValueTypes["EnumAdBoardStatusFilter"];
	["NestedEnumAdBoardStatusFilter"]: ValueTypes["NestedEnumAdBoardStatusFilter"];
	["EnumAdboardDraftStatusNullableFilter"]: ValueTypes["EnumAdboardDraftStatusNullableFilter"];
	["NestedEnumAdboardDraftStatusNullableFilter"]: ValueTypes["NestedEnumAdboardDraftStatusNullableFilter"];
	["WebhookObjectEqualityInput"]: ValueTypes["WebhookObjectEqualityInput"];
	["JSON"]: ValueTypes["JSON"];
	["DateTimeNullableFilter"]: ValueTypes["DateTimeNullableFilter"];
	["NestedDateTimeNullableFilter"]: ValueTypes["NestedDateTimeNullableFilter"];
	["JsonNullableFilter"]: ValueTypes["JsonNullableFilter"];
	["LocationCategoryNullableRelationFilter"]: ValueTypes["LocationCategoryNullableRelationFilter"];
	["LocationCategoryWhereInput"]: ValueTypes["LocationCategoryWhereInput"];
	["AdBoardListRelationFilter"]: ValueTypes["AdBoardListRelationFilter"];
	["LocationSubCategoryListRelationFilter"]: ValueTypes["LocationSubCategoryListRelationFilter"];
	["LocationSubCategoryWhereInput"]: ValueTypes["LocationSubCategoryWhereInput"];
	["LocationSubCategoryNullableRelationFilter"]: ValueTypes["LocationSubCategoryNullableRelationFilter"];
	["AdBoardTypeNullableRelationFilter"]: ValueTypes["AdBoardTypeNullableRelationFilter"];
	["AdBoardTypeWhereInput"]: ValueTypes["AdBoardTypeWhereInput"];
	["BusinessCategoryNullableRelationFilter"]: ValueTypes["BusinessCategoryNullableRelationFilter"];
	["BusinessCategoryWhereInput"]: ValueTypes["BusinessCategoryWhereInput"];
	["CampaignListRelationFilter"]: ValueTypes["CampaignListRelationFilter"];
	["CampaignWhereInput"]: ValueTypes["CampaignWhereInput"];
	["DateTimeFilter"]: ValueTypes["DateTimeFilter"];
	["NestedDateTimeFilter"]: ValueTypes["NestedDateTimeFilter"];
	["BookingPaymentNullableCompositeFilter"]: ValueTypes["BookingPaymentNullableCompositeFilter"];
	["BookingPaymentObjectEqualityInput"]: ValueTypes["BookingPaymentObjectEqualityInput"];
	["BookingCustomerObjectEqualityInput"]: ValueTypes["BookingCustomerObjectEqualityInput"];
	["PaymentCustomerDetailsObjectEqualityInput"]: ValueTypes["PaymentCustomerDetailsObjectEqualityInput"];
	["PaymentGatewayName"]: ValueTypes["PaymentGatewayName"];
	["PaymentTotalDetailsObjectEqualityInput"]: ValueTypes["PaymentTotalDetailsObjectEqualityInput"];
	["BookingPaymentWhereInput"]: ValueTypes["BookingPaymentWhereInput"];
	["BookingCustomerNullableCompositeFilter"]: ValueTypes["BookingCustomerNullableCompositeFilter"];
	["BookingCustomerWhereInput"]: ValueTypes["BookingCustomerWhereInput"];
	["PaymentCustomerDetailsNullableCompositeFilter"]: ValueTypes["PaymentCustomerDetailsNullableCompositeFilter"];
	["PaymentCustomerDetailsWhereInput"]: ValueTypes["PaymentCustomerDetailsWhereInput"];
	["EnumPaymentGatewayNameFilter"]: ValueTypes["EnumPaymentGatewayNameFilter"];
	["NestedEnumPaymentGatewayNameFilter"]: ValueTypes["NestedEnumPaymentGatewayNameFilter"];
	["PaymentTotalDetailsNullableCompositeFilter"]: ValueTypes["PaymentTotalDetailsNullableCompositeFilter"];
	["PaymentTotalDetailsWhereInput"]: ValueTypes["PaymentTotalDetailsWhereInput"];
	["EnumPaymentStatusFilter"]: ValueTypes["EnumPaymentStatusFilter"];
	["PaymentStatus"]: ValueTypes["PaymentStatus"];
	["NestedEnumPaymentStatusFilter"]: ValueTypes["NestedEnumPaymentStatusFilter"];
	["PriceSummaryNullableCompositeFilter"]: ValueTypes["PriceSummaryNullableCompositeFilter"];
	["PriceSummaryObjectEqualityInput"]: ValueTypes["PriceSummaryObjectEqualityInput"];
	["PriceSummaryWhereInput"]: ValueTypes["PriceSummaryWhereInput"];
	["OrderSelectedDateCompositeFilter"]: ValueTypes["OrderSelectedDateCompositeFilter"];
	["OrderSelectedDateObjectEqualityInput"]: ValueTypes["OrderSelectedDateObjectEqualityInput"];
	["OrderSelectedDateWhereInput"]: ValueTypes["OrderSelectedDateWhereInput"];
	["UserRelationFilter"]: ValueTypes["UserRelationFilter"];
	["UserWhereInput"]: ValueTypes["UserWhereInput"];
	["UserStripeNullableCompositeFilter"]: ValueTypes["UserStripeNullableCompositeFilter"];
	["UserStripeObjectEqualityInput"]: ValueTypes["UserStripeObjectEqualityInput"];
	["UserStripeWhereInput"]: ValueTypes["UserStripeWhereInput"];
	["EnumRoleNullableListFilter"]: ValueTypes["EnumRoleNullableListFilter"];
	["Role"]: ValueTypes["Role"];
	["BookingRefundObjectEqualityInput"]: ValueTypes["BookingRefundObjectEqualityInput"];
	["NotificationPreferenceNullableCompositeFilter"]: ValueTypes["NotificationPreferenceNullableCompositeFilter"];
	["NotificationPreferenceObjectEqualityInput"]: ValueTypes["NotificationPreferenceObjectEqualityInput"];
	["NotificationType"]: ValueTypes["NotificationType"];
	["NotificationChannel"]: ValueTypes["NotificationChannel"];
	["PushSettingObjectEqualityInput"]: ValueTypes["PushSettingObjectEqualityInput"];
	["NotificationPreferenceWhereInput"]: ValueTypes["NotificationPreferenceWhereInput"];
	["EnumNotificationTypeNullableListFilter"]: ValueTypes["EnumNotificationTypeNullableListFilter"];
	["EnumNotificationChannelNullableListFilter"]: ValueTypes["EnumNotificationChannelNullableListFilter"];
	["PushSettingNullableCompositeFilter"]: ValueTypes["PushSettingNullableCompositeFilter"];
	["PushSettingWhereInput"]: ValueTypes["PushSettingWhereInput"];
	["BookingListRelationFilter"]: ValueTypes["BookingListRelationFilter"];
	["BookingWhereInput"]: ValueTypes["BookingWhereInput"];
	["BookingDetailsCompositeFilter"]: ValueTypes["BookingDetailsCompositeFilter"];
	["BookingDetailsObjectEqualityInput"]: ValueTypes["BookingDetailsObjectEqualityInput"];
	["BookingDetailsWhereInput"]: ValueTypes["BookingDetailsWhereInput"];
	["EnumBookingStatusFilter"]: ValueTypes["EnumBookingStatusFilter"];
	["BookingStatus"]: ValueTypes["BookingStatus"];
	["NestedEnumBookingStatusFilter"]: ValueTypes["NestedEnumBookingStatusFilter"];
	["EnumPaymentStatusNullableFilter"]: ValueTypes["EnumPaymentStatusNullableFilter"];
	["NestedEnumPaymentStatusNullableFilter"]: ValueTypes["NestedEnumPaymentStatusNullableFilter"];
	["EnumDeploymentStatusFilter"]: ValueTypes["EnumDeploymentStatusFilter"];
	["DeploymentStatus"]: ValueTypes["DeploymentStatus"];
	["NestedEnumDeploymentStatusFilter"]: ValueTypes["NestedEnumDeploymentStatusFilter"];
	["EnumContentApprovalStatusFilter"]: ValueTypes["EnumContentApprovalStatusFilter"];
	["ContentApprovalStatus"]: ValueTypes["ContentApprovalStatus"];
	["NestedEnumContentApprovalStatusFilter"]: ValueTypes["NestedEnumContentApprovalStatusFilter"];
	["BookingPisignageDataNullableCompositeFilter"]: ValueTypes["BookingPisignageDataNullableCompositeFilter"];
	["BookingPisignageDataObjectEqualityInput"]: ValueTypes["BookingPisignageDataObjectEqualityInput"];
	["BookingPisignageDataWhereInput"]: ValueTypes["BookingPisignageDataWhereInput"];
	["BookingRefundNullableCompositeFilter"]: ValueTypes["BookingRefundNullableCompositeFilter"];
	["BookingRefundWhereInput"]: ValueTypes["BookingRefundWhereInput"];
	["FileListRelationFilter"]: ValueTypes["FileListRelationFilter"];
	["FileWhereInput"]: ValueTypes["FileWhereInput"];
	["FileMetaCompositeFilter"]: ValueTypes["FileMetaCompositeFilter"];
	["FileMetaObjectEqualityInput"]: ValueTypes["FileMetaObjectEqualityInput"];
	["FileMetaWhereInput"]: ValueTypes["FileMetaWhereInput"];
	["EnumFileStatusNullableFilter"]: ValueTypes["EnumFileStatusNullableFilter"];
	["FileStatus"]: ValueTypes["FileStatus"];
	["NestedEnumFileStatusNullableFilter"]: ValueTypes["NestedEnumFileStatusNullableFilter"];
	["UserNullableRelationFilter"]: ValueTypes["UserNullableRelationFilter"];
	["WorkspaceNullableRelationFilter"]: ValueTypes["WorkspaceNullableRelationFilter"];
	["WorkspaceWhereInput"]: ValueTypes["WorkspaceWhereInput"];
	["EnumWorkspaceTypeFilter"]: ValueTypes["EnumWorkspaceTypeFilter"];
	["WorkspaceType"]: ValueTypes["WorkspaceType"];
	["NestedEnumWorkspaceTypeFilter"]: ValueTypes["NestedEnumWorkspaceTypeFilter"];
	["MembershipListRelationFilter"]: ValueTypes["MembershipListRelationFilter"];
	["MembershipWhereInput"]: ValueTypes["MembershipWhereInput"];
	["WorkspaceRelationFilter"]: ValueTypes["WorkspaceRelationFilter"];
	["WorkspaceRoleRelationFilter"]: ValueTypes["WorkspaceRoleRelationFilter"];
	["WorkspaceRoleWhereInput"]: ValueTypes["WorkspaceRoleWhereInput"];
	["EnumPermissionNullableListFilter"]: ValueTypes["EnumPermissionNullableListFilter"];
	["Permission"]: ValueTypes["Permission"];
	["PlaylistListRelationFilter"]: ValueTypes["PlaylistListRelationFilter"];
	["PlaylistWhereInput"]: ValueTypes["PlaylistWhereInput"];
	["PisignageFileObjectEqualityInput"]: ValueTypes["PisignageFileObjectEqualityInput"];
	["BoolNullableFilter"]: ValueTypes["BoolNullableFilter"];
	["NestedBoolNullableFilter"]: ValueTypes["NestedBoolNullableFilter"];
	["EnumScreenLayoutFilter"]: ValueTypes["EnumScreenLayoutFilter"];
	["ScreenLayout"]: ValueTypes["ScreenLayout"];
	["NestedEnumScreenLayoutFilter"]: ValueTypes["NestedEnumScreenLayoutFilter"];
	["PlaylistNullableRelationFilter"]: ValueTypes["PlaylistNullableRelationFilter"];
	["AdBoardGroupListRelationFilter"]: ValueTypes["AdBoardGroupListRelationFilter"];
	["AdBoardGroupWhereInput"]: ValueTypes["AdBoardGroupWhereInput"];
	["ScheduleObjectEqualityInput"]: ValueTypes["ScheduleObjectEqualityInput"];
	["EnumFitTypeFilter"]: ValueTypes["EnumFitTypeFilter"];
	["FitType"]: ValueTypes["FitType"];
	["NestedEnumFitTypeFilter"]: ValueTypes["NestedEnumFitTypeFilter"];
	["AdBoardNullableRelationFilter"]: ValueTypes["AdBoardNullableRelationFilter"];
	["PlayerListRelationFilter"]: ValueTypes["PlayerListRelationFilter"];
	["PlayerWhereInput"]: ValueTypes["PlayerWhereInput"];
	["EnumAspectRatioTypeFilter"]: ValueTypes["EnumAspectRatioTypeFilter"];
	["AspectRatioType"]: ValueTypes["AspectRatioType"];
	["NestedEnumAspectRatioTypeFilter"]: ValueTypes["NestedEnumAspectRatioTypeFilter"];
	["GalleryFileObjectEqualityInput"]: ValueTypes["GalleryFileObjectEqualityInput"];
	["MapNullableCompositeFilter"]: ValueTypes["MapNullableCompositeFilter"];
	["AdBoardGroupNullableRelationFilter"]: ValueTypes["AdBoardGroupNullableRelationFilter"];
	["PlayerUptimeStatusListRelationFilter"]: ValueTypes["PlayerUptimeStatusListRelationFilter"];
	["PlayerUptimeStatusWhereInput"]: ValueTypes["PlayerUptimeStatusWhereInput"];
	["PlayerRelationFilter"]: ValueTypes["PlayerRelationFilter"];
	["CameraListRelationFilter"]: ValueTypes["CameraListRelationFilter"];
	["CameraWhereInput"]: ValueTypes["CameraWhereInput"];
	["CameraServerRelationFilter"]: ValueTypes["CameraServerRelationFilter"];
	["CameraServerWhereInput"]: ValueTypes["CameraServerWhereInput"];
	["CameraFeedListRelationFilter"]: ValueTypes["CameraFeedListRelationFilter"];
	["CameraFeedWhereInput"]: ValueTypes["CameraFeedWhereInput"];
	["CameraFeedDataCompositeFilter"]: ValueTypes["CameraFeedDataCompositeFilter"];
	["CameraFeedDataObjectEqualityInput"]: ValueTypes["CameraFeedDataObjectEqualityInput"];
	["CameraFeedDataWhereInput"]: ValueTypes["CameraFeedDataWhereInput"];
	["CameraRelationFilter"]: ValueTypes["CameraRelationFilter"];
	["PlayerNullableRelationFilter"]: ValueTypes["PlayerNullableRelationFilter"];
	["DigitalSignageNullableRelationFilter"]: ValueTypes["DigitalSignageNullableRelationFilter"];
	["DigitalSignageWhereInput"]: ValueTypes["DigitalSignageWhereInput"];
	["EnumSignageTypeFilter"]: ValueTypes["EnumSignageTypeFilter"];
	["SignageType"]: ValueTypes["SignageType"];
	["NestedEnumSignageTypeFilter"]: ValueTypes["NestedEnumSignageTypeFilter"];
	["AudienceListRelationFilter"]: ValueTypes["AudienceListRelationFilter"];
	["AudienceWhereInput"]: ValueTypes["AudienceWhereInput"];
	["EnumAudienceTypeFilter"]: ValueTypes["EnumAudienceTypeFilter"];
	["AudienceType"]: ValueTypes["AudienceType"];
	["NestedEnumAudienceTypeFilter"]: ValueTypes["NestedEnumAudienceTypeFilter"];
	["AuditListRelationFilter"]: ValueTypes["AuditListRelationFilter"];
	["AuditWhereInput"]: ValueTypes["AuditWhereInput"];
	["EntityNullableCompositeFilter"]: ValueTypes["EntityNullableCompositeFilter"];
	["EntityObjectEqualityInput"]: ValueTypes["EntityObjectEqualityInput"];
	["EntityWhereInput"]: ValueTypes["EntityWhereInput"];
	["TransactionListRelationFilter"]: ValueTypes["TransactionListRelationFilter"];
	["TransactionWhereInput"]: ValueTypes["TransactionWhereInput"];
	["EnumTransactionStatusFilter"]: ValueTypes["EnumTransactionStatusFilter"];
	["TransactionStatus"]: ValueTypes["TransactionStatus"];
	["NestedEnumTransactionStatusFilter"]: ValueTypes["NestedEnumTransactionStatusFilter"];
	["PayoutListRelationFilter"]: ValueTypes["PayoutListRelationFilter"];
	["PayoutWhereInput"]: ValueTypes["PayoutWhereInput"];
	["EnumPayoutStatusFilter"]: ValueTypes["EnumPayoutStatusFilter"];
	["PayoutStatus"]: ValueTypes["PayoutStatus"];
	["NestedEnumPayoutStatusFilter"]: ValueTypes["NestedEnumPayoutStatusFilter"];
	["BookingRelationFilter"]: ValueTypes["BookingRelationFilter"];
	["TransactionNullableRelationFilter"]: ValueTypes["TransactionNullableRelationFilter"];
	["SubscriptionNullableRelationFilter"]: ValueTypes["SubscriptionNullableRelationFilter"];
	["SubscriptionWhereInput"]: ValueTypes["SubscriptionWhereInput"];
	["CancellationDetailsNullableCompositeFilter"]: ValueTypes["CancellationDetailsNullableCompositeFilter"];
	["CancellationDetailsObjectEqualityInput"]: ValueTypes["CancellationDetailsObjectEqualityInput"];
	["CancellationDetailsWhereInput"]: ValueTypes["CancellationDetailsWhereInput"];
	["CameraServerListRelationFilter"]: ValueTypes["CameraServerListRelationFilter"];
	["WorkspaceRoleListRelationFilter"]: ValueTypes["WorkspaceRoleListRelationFilter"];
	["WebhookLogListRelationFilter"]: ValueTypes["WebhookLogListRelationFilter"];
	["WebhookLogWhereInput"]: ValueTypes["WebhookLogWhereInput"];
	["UserListRelationFilter"]: ValueTypes["UserListRelationFilter"];
	["BookingNullableRelationFilter"]: ValueTypes["BookingNullableRelationFilter"];
	["AdvertisementCategoryListRelationFilter"]: ValueTypes["AdvertisementCategoryListRelationFilter"];
	["AdvertisementCategoryWhereInput"]: ValueTypes["AdvertisementCategoryWhereInput"];
	["EnumAdvertisementCategoryEnumFilter"]: ValueTypes["EnumAdvertisementCategoryEnumFilter"];
	["AdvertisementCategoryEnum"]: ValueTypes["AdvertisementCategoryEnum"];
	["NestedEnumAdvertisementCategoryEnumFilter"]: ValueTypes["NestedEnumAdvertisementCategoryEnumFilter"];
	["AdBoardRelationFilter"]: ValueTypes["AdBoardRelationFilter"];
	["ChangelogListRelationFilter"]: ValueTypes["ChangelogListRelationFilter"];
	["ChangelogWhereInput"]: ValueTypes["ChangelogWhereInput"];
	["CampaignNullableRelationFilter"]: ValueTypes["CampaignNullableRelationFilter"];
	["SlotUsageListRelationFilter"]: ValueTypes["SlotUsageListRelationFilter"];
	["SlotUsageWhereInput"]: ValueTypes["SlotUsageWhereInput"];
	["OrderListRelationFilter"]: ValueTypes["OrderListRelationFilter"];
	["OrderWhereInput"]: ValueTypes["OrderWhereInput"];
	["PriceSummaryCompositeFilter"]: ValueTypes["PriceSummaryCompositeFilter"];
	["NotificationListRelationFilter"]: ValueTypes["NotificationListRelationFilter"];
	["NotificationWhereInput"]: ValueTypes["NotificationWhereInput"];
	["EnumNotificationTypeFilter"]: ValueTypes["EnumNotificationTypeFilter"];
	["NestedEnumNotificationTypeFilter"]: ValueTypes["NestedEnumNotificationTypeFilter"];
	["AdBoardPublishHistoryListRelationFilter"]: ValueTypes["AdBoardPublishHistoryListRelationFilter"];
	["AdBoardPublishHistoryWhereInput"]: ValueTypes["AdBoardPublishHistoryWhereInput"];
	["JsonFilter"]: ValueTypes["JsonFilter"];
	["AdBoardReviewCommentListRelationFilter"]: ValueTypes["AdBoardReviewCommentListRelationFilter"];
	["AdBoardReviewCommentWhereInput"]: ValueTypes["AdBoardReviewCommentWhereInput"];
	["EnumReviewActionFilter"]: ValueTypes["EnumReviewActionFilter"];
	["ReviewAction"]: ValueTypes["ReviewAction"];
	["NestedEnumReviewActionFilter"]: ValueTypes["NestedEnumReviewActionFilter"];
	["WorkspaceListRelationFilter"]: ValueTypes["WorkspaceListRelationFilter"];
	["AdBoardPublicReviewListRelationFilter"]: ValueTypes["AdBoardPublicReviewListRelationFilter"];
	["AdBoardPublicReviewWhereInput"]: ValueTypes["AdBoardPublicReviewWhereInput"];
	["UserPositionListRelationFilter"]: ValueTypes["UserPositionListRelationFilter"];
	["UserPositionWhereInput"]: ValueTypes["UserPositionWhereInput"];
	["UserPositionCoordsCompositeFilter"]: ValueTypes["UserPositionCoordsCompositeFilter"];
	["UserPositionCoordsObjectEqualityInput"]: ValueTypes["UserPositionCoordsObjectEqualityInput"];
	["UserPositionCoordsWhereInput"]: ValueTypes["UserPositionCoordsWhereInput"];
	["UserPositionLocationNullableCompositeFilter"]: ValueTypes["UserPositionLocationNullableCompositeFilter"];
	["UserPositionLocationObjectEqualityInput"]: ValueTypes["UserPositionLocationObjectEqualityInput"];
	["UserPositionLocationWhereInput"]: ValueTypes["UserPositionLocationWhereInput"];
	["BusinessCategoryRelationFilter"]: ValueTypes["BusinessCategoryRelationFilter"];
	["PaymentListRelationFilter"]: ValueTypes["PaymentListRelationFilter"];
	["PaymentWhereInput"]: ValueTypes["PaymentWhereInput"];
	["BusinessCategoryListRelationFilter"]: ValueTypes["BusinessCategoryListRelationFilter"];
	["SlotConfigurationListRelationFilter"]: ValueTypes["SlotConfigurationListRelationFilter"];
	["SlotConfigurationWhereInput"]: ValueTypes["SlotConfigurationWhereInput"];
	["AdBoardOrderByWithRelationInput"]: ValueTypes["AdBoardOrderByWithRelationInput"];
	["SortOrder"]: ValueTypes["SortOrder"];
	["MapOrderByInput"]: ValueTypes["MapOrderByInput"];
	["SpecificationOrderByCompositeAggregateInput"]: ValueTypes["SpecificationOrderByCompositeAggregateInput"];
	["ResolutionOrderByInput"]: ValueTypes["ResolutionOrderByInput"];
	["ScreenSizeOrderByInput"]: ValueTypes["ScreenSizeOrderByInput"];
	["PlaybackTimeOrderByCompositeAggregateInput"]: ValueTypes["PlaybackTimeOrderByCompositeAggregateInput"];
	["WebhookOrderByCompositeAggregateInput"]: ValueTypes["WebhookOrderByCompositeAggregateInput"];
	["LocationCategoryOrderByWithRelationInput"]: ValueTypes["LocationCategoryOrderByWithRelationInput"];
	["AdBoardOrderByRelationAggregateInput"]: ValueTypes["AdBoardOrderByRelationAggregateInput"];
	["LocationSubCategoryOrderByRelationAggregateInput"]: ValueTypes["LocationSubCategoryOrderByRelationAggregateInput"];
	["LocationSubCategoryOrderByWithRelationInput"]: ValueTypes["LocationSubCategoryOrderByWithRelationInput"];
	["AdBoardTypeOrderByWithRelationInput"]: ValueTypes["AdBoardTypeOrderByWithRelationInput"];
	["BusinessCategoryOrderByWithRelationInput"]: ValueTypes["BusinessCategoryOrderByWithRelationInput"];
	["CampaignOrderByRelationAggregateInput"]: ValueTypes["CampaignOrderByRelationAggregateInput"];
	["BookingOrderByRelationAggregateInput"]: ValueTypes["BookingOrderByRelationAggregateInput"];
	["BusinessCategoryOrderByRelationAggregateInput"]: ValueTypes["BusinessCategoryOrderByRelationAggregateInput"];
	["OrderOrderByRelationAggregateInput"]: ValueTypes["OrderOrderByRelationAggregateInput"];
	["SlotConfigurationOrderByRelationAggregateInput"]: ValueTypes["SlotConfigurationOrderByRelationAggregateInput"];
	["UserOrderByWithRelationInput"]: ValueTypes["UserOrderByWithRelationInput"];
	["UserStripeOrderByInput"]: ValueTypes["UserStripeOrderByInput"];
	["BookingRefundOrderByCompositeAggregateInput"]: ValueTypes["BookingRefundOrderByCompositeAggregateInput"];
	["NotificationPreferenceOrderByInput"]: ValueTypes["NotificationPreferenceOrderByInput"];
	["PushSettingOrderByInput"]: ValueTypes["PushSettingOrderByInput"];
	["FileOrderByRelationAggregateInput"]: ValueTypes["FileOrderByRelationAggregateInput"];
	["ChangelogOrderByRelationAggregateInput"]: ValueTypes["ChangelogOrderByRelationAggregateInput"];
	["AuditOrderByRelationAggregateInput"]: ValueTypes["AuditOrderByRelationAggregateInput"];
	["MembershipOrderByRelationAggregateInput"]: ValueTypes["MembershipOrderByRelationAggregateInput"];
	["NotificationOrderByRelationAggregateInput"]: ValueTypes["NotificationOrderByRelationAggregateInput"];
	["AdBoardPublishHistoryOrderByRelationAggregateInput"]: ValueTypes["AdBoardPublishHistoryOrderByRelationAggregateInput"];
	["AdBoardReviewCommentOrderByRelationAggregateInput"]: ValueTypes["AdBoardReviewCommentOrderByRelationAggregateInput"];
	["WorkspaceOrderByRelationAggregateInput"]: ValueTypes["WorkspaceOrderByRelationAggregateInput"];
	["AdBoardPublicReviewOrderByRelationAggregateInput"]: ValueTypes["AdBoardPublicReviewOrderByRelationAggregateInput"];
	["UserPositionOrderByRelationAggregateInput"]: ValueTypes["UserPositionOrderByRelationAggregateInput"];
	["WorkspaceOrderByWithRelationInput"]: ValueTypes["WorkspaceOrderByWithRelationInput"];
	["PlaylistOrderByRelationAggregateInput"]: ValueTypes["PlaylistOrderByRelationAggregateInput"];
	["AdBoardGroupOrderByRelationAggregateInput"]: ValueTypes["AdBoardGroupOrderByRelationAggregateInput"];
	["PlayerOrderByRelationAggregateInput"]: ValueTypes["PlayerOrderByRelationAggregateInput"];
	["TransactionOrderByRelationAggregateInput"]: ValueTypes["TransactionOrderByRelationAggregateInput"];
	["SubscriptionOrderByWithRelationInput"]: ValueTypes["SubscriptionOrderByWithRelationInput"];
	["CancellationDetailsOrderByInput"]: ValueTypes["CancellationDetailsOrderByInput"];
	["CameraServerOrderByRelationAggregateInput"]: ValueTypes["CameraServerOrderByRelationAggregateInput"];
	["WorkspaceRoleOrderByRelationAggregateInput"]: ValueTypes["WorkspaceRoleOrderByRelationAggregateInput"];
	["WebhookLogOrderByRelationAggregateInput"]: ValueTypes["WebhookLogOrderByRelationAggregateInput"];
	["UserOrderByRelationAggregateInput"]: ValueTypes["UserOrderByRelationAggregateInput"];
	["AdBoardGroupOrderByWithRelationInput"]: ValueTypes["AdBoardGroupOrderByWithRelationInput"];
	["ScheduleOrderByCompositeAggregateInput"]: ValueTypes["ScheduleOrderByCompositeAggregateInput"];
	["PlaylistOrderByWithRelationInput"]: ValueTypes["PlaylistOrderByWithRelationInput"];
	["PisignageFileOrderByCompositeAggregateInput"]: ValueTypes["PisignageFileOrderByCompositeAggregateInput"];
	["CameraOrderByRelationAggregateInput"]: ValueTypes["CameraOrderByRelationAggregateInput"];
	["AdBoardWhereUniqueInput"]: ValueTypes["AdBoardWhereUniqueInput"];
	["AdBoardWorkspaceIdAliasCompoundUniqueInput"]: ValueTypes["AdBoardWorkspaceIdAliasCompoundUniqueInput"];
	["AdBoardScalarFieldEnum"]: ValueTypes["AdBoardScalarFieldEnum"];
	["LocationSubCategoryWhereUniqueInput"]: ValueTypes["LocationSubCategoryWhereUniqueInput"];
	["LocationSubCategoryScalarFieldEnum"]: ValueTypes["LocationSubCategoryScalarFieldEnum"];
	["BookingOrderByWithRelationInput"]: ValueTypes["BookingOrderByWithRelationInput"];
	["BookingDetailsOrderByInput"]: ValueTypes["BookingDetailsOrderByInput"];
	["OrderSelectedDateOrderByInput"]: ValueTypes["OrderSelectedDateOrderByInput"];
	["BookingPaymentOrderByInput"]: ValueTypes["BookingPaymentOrderByInput"];
	["BookingCustomerOrderByInput"]: ValueTypes["BookingCustomerOrderByInput"];
	["PaymentCustomerDetailsOrderByInput"]: ValueTypes["PaymentCustomerDetailsOrderByInput"];
	["PaymentTotalDetailsOrderByInput"]: ValueTypes["PaymentTotalDetailsOrderByInput"];
	["PriceSummaryOrderByInput"]: ValueTypes["PriceSummaryOrderByInput"];
	["BookingPisignageDataOrderByInput"]: ValueTypes["BookingPisignageDataOrderByInput"];
	["BookingRefundOrderByInput"]: ValueTypes["BookingRefundOrderByInput"];
	["AdvertisementCategoryOrderByRelationAggregateInput"]: ValueTypes["AdvertisementCategoryOrderByRelationAggregateInput"];
	["CampaignOrderByWithRelationInput"]: ValueTypes["CampaignOrderByWithRelationInput"];
	["PaymentOrderByRelationAggregateInput"]: ValueTypes["PaymentOrderByRelationAggregateInput"];
	["PayoutOrderByRelationAggregateInput"]: ValueTypes["PayoutOrderByRelationAggregateInput"];
	["SlotUsageOrderByRelationAggregateInput"]: ValueTypes["SlotUsageOrderByRelationAggregateInput"];
	["BookingWhereUniqueInput"]: ValueTypes["BookingWhereUniqueInput"];
	["BookingScalarFieldEnum"]: ValueTypes["BookingScalarFieldEnum"];
	["MembershipOrderByWithRelationInput"]: ValueTypes["MembershipOrderByWithRelationInput"];
	["WorkspaceRoleOrderByWithRelationInput"]: ValueTypes["WorkspaceRoleOrderByWithRelationInput"];
	["MembershipWhereUniqueInput"]: ValueTypes["MembershipWhereUniqueInput"];
	["MembershipUserIdWorkspaceIdCompoundUniqueInput"]: ValueTypes["MembershipUserIdWorkspaceIdCompoundUniqueInput"];
	["MembershipScalarFieldEnum"]: ValueTypes["MembershipScalarFieldEnum"];
	["PlaylistWhereUniqueInput"]: ValueTypes["PlaylistWhereUniqueInput"];
	["PlaylistScalarFieldEnum"]: ValueTypes["PlaylistScalarFieldEnum"];
	["PlayerUptimeStatusOrderByWithRelationInput"]: ValueTypes["PlayerUptimeStatusOrderByWithRelationInput"];
	["PlayerOrderByWithRelationInput"]: ValueTypes["PlayerOrderByWithRelationInput"];
	["GalleryFileOrderByCompositeAggregateInput"]: ValueTypes["GalleryFileOrderByCompositeAggregateInput"];
	["PlayerUptimeStatusOrderByRelationAggregateInput"]: ValueTypes["PlayerUptimeStatusOrderByRelationAggregateInput"];
	["DigitalSignageOrderByWithRelationInput"]: ValueTypes["DigitalSignageOrderByWithRelationInput"];
	["AudienceOrderByRelationAggregateInput"]: ValueTypes["AudienceOrderByRelationAggregateInput"];
	["PlayerUptimeStatusWhereUniqueInput"]: ValueTypes["PlayerUptimeStatusWhereUniqueInput"];
	["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"]: ValueTypes["PlayerUptimeStatusCpuSerialNumberDateCompoundUniqueInput"];
	["PlayerUptimeStatusScalarFieldEnum"]: ValueTypes["PlayerUptimeStatusScalarFieldEnum"];
	["CameraOrderByWithRelationInput"]: ValueTypes["CameraOrderByWithRelationInput"];
	["CameraServerOrderByWithRelationInput"]: ValueTypes["CameraServerOrderByWithRelationInput"];
	["CameraFeedOrderByRelationAggregateInput"]: ValueTypes["CameraFeedOrderByRelationAggregateInput"];
	["CameraWhereUniqueInput"]: ValueTypes["CameraWhereUniqueInput"];
	["CameraCameraIpCameraServerIdCompoundUniqueInput"]: ValueTypes["CameraCameraIpCameraServerIdCompoundUniqueInput"];
	["CameraScalarFieldEnum"]: ValueTypes["CameraScalarFieldEnum"];
	["CameraFeedOrderByWithRelationInput"]: ValueTypes["CameraFeedOrderByWithRelationInput"];
	["CameraFeedDataOrderByInput"]: ValueTypes["CameraFeedDataOrderByInput"];
	["CameraFeedWhereUniqueInput"]: ValueTypes["CameraFeedWhereUniqueInput"];
	["CameraFeedCameraIdTimestampCompoundUniqueInput"]: ValueTypes["CameraFeedCameraIdTimestampCompoundUniqueInput"];
	["CameraFeedScalarFieldEnum"]: ValueTypes["CameraFeedScalarFieldEnum"];
	["PlayerWhereUniqueInput"]: ValueTypes["PlayerWhereUniqueInput"];
	["PlayerScalarFieldEnum"]: ValueTypes["PlayerScalarFieldEnum"];
	["AudienceOrderByWithRelationInput"]: ValueTypes["AudienceOrderByWithRelationInput"];
	["AudienceWhereUniqueInput"]: ValueTypes["AudienceWhereUniqueInput"];
	["AudienceScalarFieldEnum"]: ValueTypes["AudienceScalarFieldEnum"];
	["AdBoardGroupWhereUniqueInput"]: ValueTypes["AdBoardGroupWhereUniqueInput"];
	["AdBoardGroupScalarFieldEnum"]: ValueTypes["AdBoardGroupScalarFieldEnum"];
	["AuditOrderByWithRelationInput"]: ValueTypes["AuditOrderByWithRelationInput"];
	["EntityOrderByInput"]: ValueTypes["EntityOrderByInput"];
	["AuditWhereUniqueInput"]: ValueTypes["AuditWhereUniqueInput"];
	["AuditScalarFieldEnum"]: ValueTypes["AuditScalarFieldEnum"];
	["FileOrderByWithRelationInput"]: ValueTypes["FileOrderByWithRelationInput"];
	["FileMetaOrderByInput"]: ValueTypes["FileMetaOrderByInput"];
	["FileWhereUniqueInput"]: ValueTypes["FileWhereUniqueInput"];
	["FileScalarFieldEnum"]: ValueTypes["FileScalarFieldEnum"];
	["PayoutOrderByWithRelationInput"]: ValueTypes["PayoutOrderByWithRelationInput"];
	["TransactionOrderByWithRelationInput"]: ValueTypes["TransactionOrderByWithRelationInput"];
	["PayoutWhereUniqueInput"]: ValueTypes["PayoutWhereUniqueInput"];
	["PayoutScalarFieldEnum"]: ValueTypes["PayoutScalarFieldEnum"];
	["TransactionWhereUniqueInput"]: ValueTypes["TransactionWhereUniqueInput"];
	["TransactionScalarFieldEnum"]: ValueTypes["TransactionScalarFieldEnum"];
	["CameraServerWhereUniqueInput"]: ValueTypes["CameraServerWhereUniqueInput"];
	["CameraServerScalarFieldEnum"]: ValueTypes["CameraServerScalarFieldEnum"];
	["WorkspaceRoleWhereUniqueInput"]: ValueTypes["WorkspaceRoleWhereUniqueInput"];
	["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"]: ValueTypes["WorkspaceRoleNameWorkspaceIdCompoundUniqueInput"];
	["WorkspaceRoleScalarFieldEnum"]: ValueTypes["WorkspaceRoleScalarFieldEnum"];
	["WebhookLogOrderByWithRelationInput"]: ValueTypes["WebhookLogOrderByWithRelationInput"];
	["WebhookLogWhereUniqueInput"]: ValueTypes["WebhookLogWhereUniqueInput"];
	["WebhookLogScalarFieldEnum"]: ValueTypes["WebhookLogScalarFieldEnum"];
	["UserWhereUniqueInput"]: ValueTypes["UserWhereUniqueInput"];
	["UserScalarFieldEnum"]: ValueTypes["UserScalarFieldEnum"];
	["CampaignWhereUniqueInput"]: ValueTypes["CampaignWhereUniqueInput"];
	["CampaignScalarFieldEnum"]: ValueTypes["CampaignScalarFieldEnum"];
	["AdvertisementCategoryOrderByWithRelationInput"]: ValueTypes["AdvertisementCategoryOrderByWithRelationInput"];
	["AdvertisementCategoryWhereUniqueInput"]: ValueTypes["AdvertisementCategoryWhereUniqueInput"];
	["AdvertisementCategoryScalarFieldEnum"]: ValueTypes["AdvertisementCategoryScalarFieldEnum"];
	["ChangelogOrderByWithRelationInput"]: ValueTypes["ChangelogOrderByWithRelationInput"];
	["ChangelogWhereUniqueInput"]: ValueTypes["ChangelogWhereUniqueInput"];
	["ChangelogScalarFieldEnum"]: ValueTypes["ChangelogScalarFieldEnum"];
	["SlotUsageOrderByWithRelationInput"]: ValueTypes["SlotUsageOrderByWithRelationInput"];
	["SlotUsageWhereUniqueInput"]: ValueTypes["SlotUsageWhereUniqueInput"];
	["SlotUsageBookingIdDateCompoundUniqueInput"]: ValueTypes["SlotUsageBookingIdDateCompoundUniqueInput"];
	["SlotUsageScalarFieldEnum"]: ValueTypes["SlotUsageScalarFieldEnum"];
	["OrderOrderByWithRelationInput"]: ValueTypes["OrderOrderByWithRelationInput"];
	["OrderWhereUniqueInput"]: ValueTypes["OrderWhereUniqueInput"];
	["OrderScalarFieldEnum"]: ValueTypes["OrderScalarFieldEnum"];
	["NotificationOrderByWithRelationInput"]: ValueTypes["NotificationOrderByWithRelationInput"];
	["NotificationWhereUniqueInput"]: ValueTypes["NotificationWhereUniqueInput"];
	["NotificationScalarFieldEnum"]: ValueTypes["NotificationScalarFieldEnum"];
	["AdBoardPublishHistoryOrderByWithRelationInput"]: ValueTypes["AdBoardPublishHistoryOrderByWithRelationInput"];
	["AdBoardPublishHistoryWhereUniqueInput"]: ValueTypes["AdBoardPublishHistoryWhereUniqueInput"];
	["AdBoardPublishHistoryScalarFieldEnum"]: ValueTypes["AdBoardPublishHistoryScalarFieldEnum"];
	["AdBoardReviewCommentOrderByWithRelationInput"]: ValueTypes["AdBoardReviewCommentOrderByWithRelationInput"];
	["AdBoardReviewCommentWhereUniqueInput"]: ValueTypes["AdBoardReviewCommentWhereUniqueInput"];
	["AdBoardReviewCommentScalarFieldEnum"]: ValueTypes["AdBoardReviewCommentScalarFieldEnum"];
	["WorkspaceWhereUniqueInput"]: ValueTypes["WorkspaceWhereUniqueInput"];
	["WorkspaceScalarFieldEnum"]: ValueTypes["WorkspaceScalarFieldEnum"];
	["AdBoardPublicReviewOrderByWithRelationInput"]: ValueTypes["AdBoardPublicReviewOrderByWithRelationInput"];
	["AdBoardPublicReviewWhereUniqueInput"]: ValueTypes["AdBoardPublicReviewWhereUniqueInput"];
	["AdBoardPublicReviewScalarFieldEnum"]: ValueTypes["AdBoardPublicReviewScalarFieldEnum"];
	["UserPositionOrderByWithRelationInput"]: ValueTypes["UserPositionOrderByWithRelationInput"];
	["UserPositionCoordsOrderByInput"]: ValueTypes["UserPositionCoordsOrderByInput"];
	["UserPositionLocationOrderByInput"]: ValueTypes["UserPositionLocationOrderByInput"];
	["UserPositionWhereUniqueInput"]: ValueTypes["UserPositionWhereUniqueInput"];
	["UserPositionScalarFieldEnum"]: ValueTypes["UserPositionScalarFieldEnum"];
	["PaymentOrderByWithRelationInput"]: ValueTypes["PaymentOrderByWithRelationInput"];
	["PaymentWhereUniqueInput"]: ValueTypes["PaymentWhereUniqueInput"];
	["PaymentScalarFieldEnum"]: ValueTypes["PaymentScalarFieldEnum"];
	["BusinessCategoryWhereUniqueInput"]: ValueTypes["BusinessCategoryWhereUniqueInput"];
	["BusinessCategoryScalarFieldEnum"]: ValueTypes["BusinessCategoryScalarFieldEnum"];
	["SlotConfigurationOrderByWithRelationInput"]: ValueTypes["SlotConfigurationOrderByWithRelationInput"];
	["SlotConfigurationWhereUniqueInput"]: ValueTypes["SlotConfigurationWhereUniqueInput"];
	["SlotConfigurationAdBoardIdDateCompoundUniqueInput"]: ValueTypes["SlotConfigurationAdBoardIdDateCompoundUniqueInput"];
	["SlotConfigurationScalarFieldEnum"]: ValueTypes["SlotConfigurationScalarFieldEnum"];
	["AdBoardOrderByWithAggregationInput"]: ValueTypes["AdBoardOrderByWithAggregationInput"];
	["AdBoardCountOrderByAggregateInput"]: ValueTypes["AdBoardCountOrderByAggregateInput"];
	["AdBoardAvgOrderByAggregateInput"]: ValueTypes["AdBoardAvgOrderByAggregateInput"];
	["AdBoardMaxOrderByAggregateInput"]: ValueTypes["AdBoardMaxOrderByAggregateInput"];
	["AdBoardMinOrderByAggregateInput"]: ValueTypes["AdBoardMinOrderByAggregateInput"];
	["AdBoardSumOrderByAggregateInput"]: ValueTypes["AdBoardSumOrderByAggregateInput"];
	["AdBoardScalarWhereWithAggregatesInput"]: ValueTypes["AdBoardScalarWhereWithAggregatesInput"];
	["StringWithAggregatesFilter"]: ValueTypes["StringWithAggregatesFilter"];
	["NestedStringWithAggregatesFilter"]: ValueTypes["NestedStringWithAggregatesFilter"];
	["BoolWithAggregatesFilter"]: ValueTypes["BoolWithAggregatesFilter"];
	["NestedBoolWithAggregatesFilter"]: ValueTypes["NestedBoolWithAggregatesFilter"];
	["StringNullableWithAggregatesFilter"]: ValueTypes["StringNullableWithAggregatesFilter"];
	["NestedStringNullableWithAggregatesFilter"]: ValueTypes["NestedStringNullableWithAggregatesFilter"];
	["IntWithAggregatesFilter"]: ValueTypes["IntWithAggregatesFilter"];
	["NestedIntWithAggregatesFilter"]: ValueTypes["NestedIntWithAggregatesFilter"];
	["IntNullableWithAggregatesFilter"]: ValueTypes["IntNullableWithAggregatesFilter"];
	["NestedIntNullableWithAggregatesFilter"]: ValueTypes["NestedIntNullableWithAggregatesFilter"];
	["FloatNullableWithAggregatesFilter"]: ValueTypes["FloatNullableWithAggregatesFilter"];
	["NestedFloatNullableWithAggregatesFilter"]: ValueTypes["NestedFloatNullableWithAggregatesFilter"];
	["EnumAdBoardStatusWithAggregatesFilter"]: ValueTypes["EnumAdBoardStatusWithAggregatesFilter"];
	["NestedEnumAdBoardStatusWithAggregatesFilter"]: ValueTypes["NestedEnumAdBoardStatusWithAggregatesFilter"];
	["EnumAdboardDraftStatusNullableWithAggregatesFilter"]: ValueTypes["EnumAdboardDraftStatusNullableWithAggregatesFilter"];
	["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"]: ValueTypes["NestedEnumAdboardDraftStatusNullableWithAggregatesFilter"];
	["DateTimeNullableWithAggregatesFilter"]: ValueTypes["DateTimeNullableWithAggregatesFilter"];
	["NestedDateTimeNullableWithAggregatesFilter"]: ValueTypes["NestedDateTimeNullableWithAggregatesFilter"];
	["JsonNullableWithAggregatesFilter"]: ValueTypes["JsonNullableWithAggregatesFilter"];
	["NestedJsonNullableFilter"]: ValueTypes["NestedJsonNullableFilter"];
	["AdBoardGroupOrderByWithAggregationInput"]: ValueTypes["AdBoardGroupOrderByWithAggregationInput"];
	["AdBoardGroupCountOrderByAggregateInput"]: ValueTypes["AdBoardGroupCountOrderByAggregateInput"];
	["AdBoardGroupMaxOrderByAggregateInput"]: ValueTypes["AdBoardGroupMaxOrderByAggregateInput"];
	["AdBoardGroupMinOrderByAggregateInput"]: ValueTypes["AdBoardGroupMinOrderByAggregateInput"];
	["AdBoardGroupScalarWhereWithAggregatesInput"]: ValueTypes["AdBoardGroupScalarWhereWithAggregatesInput"];
	["EnumFitTypeWithAggregatesFilter"]: ValueTypes["EnumFitTypeWithAggregatesFilter"];
	["NestedEnumFitTypeWithAggregatesFilter"]: ValueTypes["NestedEnumFitTypeWithAggregatesFilter"];
	["AdBoardTypeWhereUniqueInput"]: ValueTypes["AdBoardTypeWhereUniqueInput"];
	["AdBoardTypeScalarFieldEnum"]: ValueTypes["AdBoardTypeScalarFieldEnum"];
	["AdBoardTypeOrderByWithAggregationInput"]: ValueTypes["AdBoardTypeOrderByWithAggregationInput"];
	["AdBoardTypeCountOrderByAggregateInput"]: ValueTypes["AdBoardTypeCountOrderByAggregateInput"];
	["AdBoardTypeMaxOrderByAggregateInput"]: ValueTypes["AdBoardTypeMaxOrderByAggregateInput"];
	["AdBoardTypeMinOrderByAggregateInput"]: ValueTypes["AdBoardTypeMinOrderByAggregateInput"];
	["AdBoardTypeScalarWhereWithAggregatesInput"]: ValueTypes["AdBoardTypeScalarWhereWithAggregatesInput"];
	["AdvertisementCategoryOrderByWithAggregationInput"]: ValueTypes["AdvertisementCategoryOrderByWithAggregationInput"];
	["AdvertisementCategoryCountOrderByAggregateInput"]: ValueTypes["AdvertisementCategoryCountOrderByAggregateInput"];
	["AdvertisementCategoryMaxOrderByAggregateInput"]: ValueTypes["AdvertisementCategoryMaxOrderByAggregateInput"];
	["AdvertisementCategoryMinOrderByAggregateInput"]: ValueTypes["AdvertisementCategoryMinOrderByAggregateInput"];
	["AdvertisementCategoryScalarWhereWithAggregatesInput"]: ValueTypes["AdvertisementCategoryScalarWhereWithAggregatesInput"];
	["EnumAdvertisementCategoryEnumWithAggregatesFilter"]: ValueTypes["EnumAdvertisementCategoryEnumWithAggregatesFilter"];
	["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"]: ValueTypes["NestedEnumAdvertisementCategoryEnumWithAggregatesFilter"];
	["DateTimeWithAggregatesFilter"]: ValueTypes["DateTimeWithAggregatesFilter"];
	["NestedDateTimeWithAggregatesFilter"]: ValueTypes["NestedDateTimeWithAggregatesFilter"];
	["AuditOrderByWithAggregationInput"]: ValueTypes["AuditOrderByWithAggregationInput"];
	["AuditCountOrderByAggregateInput"]: ValueTypes["AuditCountOrderByAggregateInput"];
	["AuditMaxOrderByAggregateInput"]: ValueTypes["AuditMaxOrderByAggregateInput"];
	["AuditMinOrderByAggregateInput"]: ValueTypes["AuditMinOrderByAggregateInput"];
	["AuditScalarWhereWithAggregatesInput"]: ValueTypes["AuditScalarWhereWithAggregatesInput"];
	["BookingOrderByWithAggregationInput"]: ValueTypes["BookingOrderByWithAggregationInput"];
	["BookingCountOrderByAggregateInput"]: ValueTypes["BookingCountOrderByAggregateInput"];
	["BookingMaxOrderByAggregateInput"]: ValueTypes["BookingMaxOrderByAggregateInput"];
	["BookingMinOrderByAggregateInput"]: ValueTypes["BookingMinOrderByAggregateInput"];
	["BookingScalarWhereWithAggregatesInput"]: ValueTypes["BookingScalarWhereWithAggregatesInput"];
	["EnumBookingStatusWithAggregatesFilter"]: ValueTypes["EnumBookingStatusWithAggregatesFilter"];
	["NestedEnumBookingStatusWithAggregatesFilter"]: ValueTypes["NestedEnumBookingStatusWithAggregatesFilter"];
	["EnumPaymentStatusNullableWithAggregatesFilter"]: ValueTypes["EnumPaymentStatusNullableWithAggregatesFilter"];
	["NestedEnumPaymentStatusNullableWithAggregatesFilter"]: ValueTypes["NestedEnumPaymentStatusNullableWithAggregatesFilter"];
	["EnumDeploymentStatusWithAggregatesFilter"]: ValueTypes["EnumDeploymentStatusWithAggregatesFilter"];
	["NestedEnumDeploymentStatusWithAggregatesFilter"]: ValueTypes["NestedEnumDeploymentStatusWithAggregatesFilter"];
	["EnumContentApprovalStatusWithAggregatesFilter"]: ValueTypes["EnumContentApprovalStatusWithAggregatesFilter"];
	["NestedEnumContentApprovalStatusWithAggregatesFilter"]: ValueTypes["NestedEnumContentApprovalStatusWithAggregatesFilter"];
	["BusinessCategoryOrderByWithAggregationInput"]: ValueTypes["BusinessCategoryOrderByWithAggregationInput"];
	["BusinessCategoryCountOrderByAggregateInput"]: ValueTypes["BusinessCategoryCountOrderByAggregateInput"];
	["BusinessCategoryMaxOrderByAggregateInput"]: ValueTypes["BusinessCategoryMaxOrderByAggregateInput"];
	["BusinessCategoryMinOrderByAggregateInput"]: ValueTypes["BusinessCategoryMinOrderByAggregateInput"];
	["BusinessCategoryScalarWhereWithAggregatesInput"]: ValueTypes["BusinessCategoryScalarWhereWithAggregatesInput"];
	["CameraOrderByWithAggregationInput"]: ValueTypes["CameraOrderByWithAggregationInput"];
	["CameraCountOrderByAggregateInput"]: ValueTypes["CameraCountOrderByAggregateInput"];
	["CameraMaxOrderByAggregateInput"]: ValueTypes["CameraMaxOrderByAggregateInput"];
	["CameraMinOrderByAggregateInput"]: ValueTypes["CameraMinOrderByAggregateInput"];
	["CameraScalarWhereWithAggregatesInput"]: ValueTypes["CameraScalarWhereWithAggregatesInput"];
	["CameraFeedOrderByWithAggregationInput"]: ValueTypes["CameraFeedOrderByWithAggregationInput"];
	["CameraFeedCountOrderByAggregateInput"]: ValueTypes["CameraFeedCountOrderByAggregateInput"];
	["CameraFeedMaxOrderByAggregateInput"]: ValueTypes["CameraFeedMaxOrderByAggregateInput"];
	["CameraFeedMinOrderByAggregateInput"]: ValueTypes["CameraFeedMinOrderByAggregateInput"];
	["CameraFeedScalarWhereWithAggregatesInput"]: ValueTypes["CameraFeedScalarWhereWithAggregatesInput"];
	["CameraServerOrderByWithAggregationInput"]: ValueTypes["CameraServerOrderByWithAggregationInput"];
	["CameraServerCountOrderByAggregateInput"]: ValueTypes["CameraServerCountOrderByAggregateInput"];
	["CameraServerMaxOrderByAggregateInput"]: ValueTypes["CameraServerMaxOrderByAggregateInput"];
	["CameraServerMinOrderByAggregateInput"]: ValueTypes["CameraServerMinOrderByAggregateInput"];
	["CameraServerScalarWhereWithAggregatesInput"]: ValueTypes["CameraServerScalarWhereWithAggregatesInput"];
	["BoolNullableWithAggregatesFilter"]: ValueTypes["BoolNullableWithAggregatesFilter"];
	["NestedBoolNullableWithAggregatesFilter"]: ValueTypes["NestedBoolNullableWithAggregatesFilter"];
	["CampaignOrderByWithAggregationInput"]: ValueTypes["CampaignOrderByWithAggregationInput"];
	["CampaignCountOrderByAggregateInput"]: ValueTypes["CampaignCountOrderByAggregateInput"];
	["CampaignMaxOrderByAggregateInput"]: ValueTypes["CampaignMaxOrderByAggregateInput"];
	["CampaignMinOrderByAggregateInput"]: ValueTypes["CampaignMinOrderByAggregateInput"];
	["CampaignScalarWhereWithAggregatesInput"]: ValueTypes["CampaignScalarWhereWithAggregatesInput"];
	["EnumPaymentStatusWithAggregatesFilter"]: ValueTypes["EnumPaymentStatusWithAggregatesFilter"];
	["NestedEnumPaymentStatusWithAggregatesFilter"]: ValueTypes["NestedEnumPaymentStatusWithAggregatesFilter"];
	["FileOrderByWithAggregationInput"]: ValueTypes["FileOrderByWithAggregationInput"];
	["FileCountOrderByAggregateInput"]: ValueTypes["FileCountOrderByAggregateInput"];
	["FileAvgOrderByAggregateInput"]: ValueTypes["FileAvgOrderByAggregateInput"];
	["FileMaxOrderByAggregateInput"]: ValueTypes["FileMaxOrderByAggregateInput"];
	["FileMinOrderByAggregateInput"]: ValueTypes["FileMinOrderByAggregateInput"];
	["FileSumOrderByAggregateInput"]: ValueTypes["FileSumOrderByAggregateInput"];
	["FileScalarWhereWithAggregatesInput"]: ValueTypes["FileScalarWhereWithAggregatesInput"];
	["EnumFileStatusNullableWithAggregatesFilter"]: ValueTypes["EnumFileStatusNullableWithAggregatesFilter"];
	["NestedEnumFileStatusNullableWithAggregatesFilter"]: ValueTypes["NestedEnumFileStatusNullableWithAggregatesFilter"];
	["MembershipOrderByWithAggregationInput"]: ValueTypes["MembershipOrderByWithAggregationInput"];
	["MembershipCountOrderByAggregateInput"]: ValueTypes["MembershipCountOrderByAggregateInput"];
	["MembershipMaxOrderByAggregateInput"]: ValueTypes["MembershipMaxOrderByAggregateInput"];
	["MembershipMinOrderByAggregateInput"]: ValueTypes["MembershipMinOrderByAggregateInput"];
	["MembershipScalarWhereWithAggregatesInput"]: ValueTypes["MembershipScalarWhereWithAggregatesInput"];
	["NotificationOrderByWithAggregationInput"]: ValueTypes["NotificationOrderByWithAggregationInput"];
	["NotificationCountOrderByAggregateInput"]: ValueTypes["NotificationCountOrderByAggregateInput"];
	["NotificationMaxOrderByAggregateInput"]: ValueTypes["NotificationMaxOrderByAggregateInput"];
	["NotificationMinOrderByAggregateInput"]: ValueTypes["NotificationMinOrderByAggregateInput"];
	["NotificationScalarWhereWithAggregatesInput"]: ValueTypes["NotificationScalarWhereWithAggregatesInput"];
	["EnumNotificationTypeWithAggregatesFilter"]: ValueTypes["EnumNotificationTypeWithAggregatesFilter"];
	["NestedEnumNotificationTypeWithAggregatesFilter"]: ValueTypes["NestedEnumNotificationTypeWithAggregatesFilter"];
	["OrderOrderByWithAggregationInput"]: ValueTypes["OrderOrderByWithAggregationInput"];
	["OrderCountOrderByAggregateInput"]: ValueTypes["OrderCountOrderByAggregateInput"];
	["OrderMaxOrderByAggregateInput"]: ValueTypes["OrderMaxOrderByAggregateInput"];
	["OrderMinOrderByAggregateInput"]: ValueTypes["OrderMinOrderByAggregateInput"];
	["OrderScalarWhereWithAggregatesInput"]: ValueTypes["OrderScalarWhereWithAggregatesInput"];
	["PlayerOrderByWithAggregationInput"]: ValueTypes["PlayerOrderByWithAggregationInput"];
	["PlayerCountOrderByAggregateInput"]: ValueTypes["PlayerCountOrderByAggregateInput"];
	["PlayerAvgOrderByAggregateInput"]: ValueTypes["PlayerAvgOrderByAggregateInput"];
	["PlayerMaxOrderByAggregateInput"]: ValueTypes["PlayerMaxOrderByAggregateInput"];
	["PlayerMinOrderByAggregateInput"]: ValueTypes["PlayerMinOrderByAggregateInput"];
	["PlayerSumOrderByAggregateInput"]: ValueTypes["PlayerSumOrderByAggregateInput"];
	["PlayerScalarWhereWithAggregatesInput"]: ValueTypes["PlayerScalarWhereWithAggregatesInput"];
	["EnumAspectRatioTypeWithAggregatesFilter"]: ValueTypes["EnumAspectRatioTypeWithAggregatesFilter"];
	["NestedEnumAspectRatioTypeWithAggregatesFilter"]: ValueTypes["NestedEnumAspectRatioTypeWithAggregatesFilter"];
	["PlaylistOrderByWithAggregationInput"]: ValueTypes["PlaylistOrderByWithAggregationInput"];
	["PlaylistCountOrderByAggregateInput"]: ValueTypes["PlaylistCountOrderByAggregateInput"];
	["PlaylistMaxOrderByAggregateInput"]: ValueTypes["PlaylistMaxOrderByAggregateInput"];
	["PlaylistMinOrderByAggregateInput"]: ValueTypes["PlaylistMinOrderByAggregateInput"];
	["PlaylistScalarWhereWithAggregatesInput"]: ValueTypes["PlaylistScalarWhereWithAggregatesInput"];
	["EnumScreenLayoutWithAggregatesFilter"]: ValueTypes["EnumScreenLayoutWithAggregatesFilter"];
	["NestedEnumScreenLayoutWithAggregatesFilter"]: ValueTypes["NestedEnumScreenLayoutWithAggregatesFilter"];
	["SlotConfigurationOrderByWithAggregationInput"]: ValueTypes["SlotConfigurationOrderByWithAggregationInput"];
	["SlotConfigurationCountOrderByAggregateInput"]: ValueTypes["SlotConfigurationCountOrderByAggregateInput"];
	["SlotConfigurationAvgOrderByAggregateInput"]: ValueTypes["SlotConfigurationAvgOrderByAggregateInput"];
	["SlotConfigurationMaxOrderByAggregateInput"]: ValueTypes["SlotConfigurationMaxOrderByAggregateInput"];
	["SlotConfigurationMinOrderByAggregateInput"]: ValueTypes["SlotConfigurationMinOrderByAggregateInput"];
	["SlotConfigurationSumOrderByAggregateInput"]: ValueTypes["SlotConfigurationSumOrderByAggregateInput"];
	["SlotConfigurationScalarWhereWithAggregatesInput"]: ValueTypes["SlotConfigurationScalarWhereWithAggregatesInput"];
	["SubscriptionWhereUniqueInput"]: ValueTypes["SubscriptionWhereUniqueInput"];
	["SubscriptionScalarFieldEnum"]: ValueTypes["SubscriptionScalarFieldEnum"];
	["SubscriptionOrderByWithAggregationInput"]: ValueTypes["SubscriptionOrderByWithAggregationInput"];
	["SubscriptionCountOrderByAggregateInput"]: ValueTypes["SubscriptionCountOrderByAggregateInput"];
	["SubscriptionMaxOrderByAggregateInput"]: ValueTypes["SubscriptionMaxOrderByAggregateInput"];
	["SubscriptionMinOrderByAggregateInput"]: ValueTypes["SubscriptionMinOrderByAggregateInput"];
	["SubscriptionScalarWhereWithAggregatesInput"]: ValueTypes["SubscriptionScalarWhereWithAggregatesInput"];
	["UserOrderByWithAggregationInput"]: ValueTypes["UserOrderByWithAggregationInput"];
	["UserCountOrderByAggregateInput"]: ValueTypes["UserCountOrderByAggregateInput"];
	["UserMaxOrderByAggregateInput"]: ValueTypes["UserMaxOrderByAggregateInput"];
	["UserMinOrderByAggregateInput"]: ValueTypes["UserMinOrderByAggregateInput"];
	["UserScalarWhereWithAggregatesInput"]: ValueTypes["UserScalarWhereWithAggregatesInput"];
	["UserPositionOrderByWithAggregationInput"]: ValueTypes["UserPositionOrderByWithAggregationInput"];
	["UserPositionCountOrderByAggregateInput"]: ValueTypes["UserPositionCountOrderByAggregateInput"];
	["UserPositionMaxOrderByAggregateInput"]: ValueTypes["UserPositionMaxOrderByAggregateInput"];
	["UserPositionMinOrderByAggregateInput"]: ValueTypes["UserPositionMinOrderByAggregateInput"];
	["UserPositionScalarWhereWithAggregatesInput"]: ValueTypes["UserPositionScalarWhereWithAggregatesInput"];
	["WorkspaceOrderByWithAggregationInput"]: ValueTypes["WorkspaceOrderByWithAggregationInput"];
	["WorkspaceCountOrderByAggregateInput"]: ValueTypes["WorkspaceCountOrderByAggregateInput"];
	["WorkspaceMaxOrderByAggregateInput"]: ValueTypes["WorkspaceMaxOrderByAggregateInput"];
	["WorkspaceMinOrderByAggregateInput"]: ValueTypes["WorkspaceMinOrderByAggregateInput"];
	["WorkspaceScalarWhereWithAggregatesInput"]: ValueTypes["WorkspaceScalarWhereWithAggregatesInput"];
	["EnumWorkspaceTypeWithAggregatesFilter"]: ValueTypes["EnumWorkspaceTypeWithAggregatesFilter"];
	["NestedEnumWorkspaceTypeWithAggregatesFilter"]: ValueTypes["NestedEnumWorkspaceTypeWithAggregatesFilter"];
	["WorkspaceRoleOrderByWithAggregationInput"]: ValueTypes["WorkspaceRoleOrderByWithAggregationInput"];
	["WorkspaceRoleCountOrderByAggregateInput"]: ValueTypes["WorkspaceRoleCountOrderByAggregateInput"];
	["WorkspaceRoleMaxOrderByAggregateInput"]: ValueTypes["WorkspaceRoleMaxOrderByAggregateInput"];
	["WorkspaceRoleMinOrderByAggregateInput"]: ValueTypes["WorkspaceRoleMinOrderByAggregateInput"];
	["WorkspaceRoleScalarWhereWithAggregatesInput"]: ValueTypes["WorkspaceRoleScalarWhereWithAggregatesInput"];
	["AdBoardCreateManyInput"]: ValueTypes["AdBoardCreateManyInput"];
	["MapCreateInput"]: ValueTypes["MapCreateInput"];
	["SpecificationCreateInput"]: ValueTypes["SpecificationCreateInput"];
	["ResolutionCreateInput"]: ValueTypes["ResolutionCreateInput"];
	["ScreenSizeCreateInput"]: ValueTypes["ScreenSizeCreateInput"];
	["PlaybackTimeCreateInput"]: ValueTypes["PlaybackTimeCreateInput"];
	["WebhookCreateInput"]: ValueTypes["WebhookCreateInput"];
	["AdBoardCreateInput"]: ValueTypes["AdBoardCreateInput"];
	["LocationCategoryCreateNestedOneWithoutAdBoardsInput"]: ValueTypes["LocationCategoryCreateNestedOneWithoutAdBoardsInput"];
	["LocationCategoryCreateWithoutAdBoardsInput"]: ValueTypes["LocationCategoryCreateWithoutAdBoardsInput"];
	["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"]: ValueTypes["LocationSubCategoryCreateNestedManyWithoutLocationCategoryInput"];
	["LocationSubCategoryCreateWithoutLocationCategoryInput"]: ValueTypes["LocationSubCategoryCreateWithoutLocationCategoryInput"];
	["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"]: ValueTypes["AdBoardCreateNestedManyWithoutLocationSubCategoryInput"];
	["AdBoardCreateWithoutLocationSubCategoryInput"]: ValueTypes["AdBoardCreateWithoutLocationSubCategoryInput"];
	["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"]: ValueTypes["AdBoardTypeCreateNestedOneWithoutAdBoardsInput"];
	["AdBoardTypeCreateWithoutAdBoardsInput"]: ValueTypes["AdBoardTypeCreateWithoutAdBoardsInput"];
	["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"]: ValueTypes["AdBoardTypeCreateOrConnectWithoutAdBoardsInput"];
	["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"]: ValueTypes["BusinessCategoryCreateNestedOneWithoutAdBoardsInput"];
	["BusinessCategoryCreateWithoutAdBoardsInput"]: ValueTypes["BusinessCategoryCreateWithoutAdBoardsInput"];
	["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"]: ValueTypes["AdBoardCreateNestedManyWithoutRestrictedBusinesInput"];
	["AdBoardCreateWithoutRestrictedBusinesInput"]: ValueTypes["AdBoardCreateWithoutRestrictedBusinesInput"];
	["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"]: ValueTypes["LocationSubCategoryCreateNestedOneWithoutAdBoardsInput"];
	["LocationSubCategoryCreateWithoutAdBoardsInput"]: ValueTypes["LocationSubCategoryCreateWithoutAdBoardsInput"];
	["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"]: ValueTypes["LocationCategoryCreateNestedOneWithoutLocationSubCategoryInput"];
	["LocationCategoryCreateWithoutLocationSubCategoryInput"]: ValueTypes["LocationCategoryCreateWithoutLocationSubCategoryInput"];
	["AdBoardCreateNestedManyWithoutLocationCategoryInput"]: ValueTypes["AdBoardCreateNestedManyWithoutLocationCategoryInput"];
	["AdBoardCreateWithoutLocationCategoryInput"]: ValueTypes["AdBoardCreateWithoutLocationCategoryInput"];
	["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"]: ValueTypes["BusinessCategoryCreateNestedManyWithoutRestrictedByAdBoardsInput"];
	["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"]: ValueTypes["BusinessCategoryCreateWithoutRestrictedByAdBoardsInput"];
	["AdBoardCreateNestedManyWithoutCategoryInput"]: ValueTypes["AdBoardCreateNestedManyWithoutCategoryInput"];
	["AdBoardCreateWithoutCategoryInput"]: ValueTypes["AdBoardCreateWithoutCategoryInput"];
	["BookingCreateNestedManyWithoutAdBoardInput"]: ValueTypes["BookingCreateNestedManyWithoutAdBoardInput"];
	["BookingCreateWithoutAdBoardInput"]: ValueTypes["BookingCreateWithoutAdBoardInput"];
	["BookingDetailsCreateInput"]: ValueTypes["BookingDetailsCreateInput"];
	["OrderSelectedDateCreateInput"]: ValueTypes["OrderSelectedDateCreateInput"];
	["BookingPaymentCreateInput"]: ValueTypes["BookingPaymentCreateInput"];
	["BookingCustomerCreateInput"]: ValueTypes["BookingCustomerCreateInput"];
	["PaymentCustomerDetailsCreateInput"]: ValueTypes["PaymentCustomerDetailsCreateInput"];
	["PaymentTotalDetailsCreateInput"]: ValueTypes["PaymentTotalDetailsCreateInput"];
	["PriceSummaryCreateInput"]: ValueTypes["PriceSummaryCreateInput"];
	["BookingPisignageDataCreateInput"]: ValueTypes["BookingPisignageDataCreateInput"];
	["BookingRefundCreateInput"]: ValueTypes["BookingRefundCreateInput"];
	["FileCreateNestedManyWithoutBookingsInput"]: ValueTypes["FileCreateNestedManyWithoutBookingsInput"];
	["FileCreateWithoutBookingsInput"]: ValueTypes["FileCreateWithoutBookingsInput"];
	["FileMetaCreateInput"]: ValueTypes["FileMetaCreateInput"];
	["UserCreateNestedOneWithoutUpdatedFilesInput"]: ValueTypes["UserCreateNestedOneWithoutUpdatedFilesInput"];
	["UserCreateWithoutUpdatedFilesInput"]: ValueTypes["UserCreateWithoutUpdatedFilesInput"];
	["UserStripeCreateInput"]: ValueTypes["UserStripeCreateInput"];
	["NotificationPreferenceCreateInput"]: ValueTypes["NotificationPreferenceCreateInput"];
	["PushSettingCreateInput"]: ValueTypes["PushSettingCreateInput"];
	["BookingCreateNestedManyWithoutUserInput"]: ValueTypes["BookingCreateNestedManyWithoutUserInput"];
	["BookingCreateWithoutUserInput"]: ValueTypes["BookingCreateWithoutUserInput"];
	["BusinessCategoryCreateNestedOneWithoutBookingsInput"]: ValueTypes["BusinessCategoryCreateNestedOneWithoutBookingsInput"];
	["BusinessCategoryCreateWithoutBookingsInput"]: ValueTypes["BusinessCategoryCreateWithoutBookingsInput"];
	["CampaignCreateNestedManyWithoutBusinessCategoryInput"]: ValueTypes["CampaignCreateNestedManyWithoutBusinessCategoryInput"];
	["CampaignCreateWithoutBusinessCategoryInput"]: ValueTypes["CampaignCreateWithoutBusinessCategoryInput"];
	["UserCreateNestedOneWithoutCampaignsInput"]: ValueTypes["UserCreateNestedOneWithoutCampaignsInput"];
	["UserCreateWithoutCampaignsInput"]: ValueTypes["UserCreateWithoutCampaignsInput"];
	["FileCreateNestedManyWithoutCreatedByInput"]: ValueTypes["FileCreateNestedManyWithoutCreatedByInput"];
	["FileCreateWithoutCreatedByInput"]: ValueTypes["FileCreateWithoutCreatedByInput"];
	["BookingCreateNestedManyWithoutAssetsInput"]: ValueTypes["BookingCreateNestedManyWithoutAssetsInput"];
	["BookingCreateWithoutAssetsInput"]: ValueTypes["BookingCreateWithoutAssetsInput"];
	["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"]: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutBookingsInput"];
	["AdvertisementCategoryCreateWithoutBookingsInput"]: ValueTypes["AdvertisementCategoryCreateWithoutBookingsInput"];
	["CampaignCreateNestedManyWithoutAdCategoryInput"]: ValueTypes["CampaignCreateNestedManyWithoutAdCategoryInput"];
	["CampaignCreateWithoutAdCategoryInput"]: ValueTypes["CampaignCreateWithoutAdCategoryInput"];
	["BusinessCategoryCreateNestedOneWithoutCampaignsInput"]: ValueTypes["BusinessCategoryCreateNestedOneWithoutCampaignsInput"];
	["BusinessCategoryCreateWithoutCampaignsInput"]: ValueTypes["BusinessCategoryCreateWithoutCampaignsInput"];
	["BookingCreateNestedManyWithoutBusinessCategoryInput"]: ValueTypes["BookingCreateNestedManyWithoutBusinessCategoryInput"];
	["BookingCreateWithoutBusinessCategoryInput"]: ValueTypes["BookingCreateWithoutBusinessCategoryInput"];
	["FileCreateNestedManyWithoutProofBookingInput"]: ValueTypes["FileCreateNestedManyWithoutProofBookingInput"];
	["FileCreateWithoutProofBookingInput"]: ValueTypes["FileCreateWithoutProofBookingInput"];
	["UserCreateNestedOneWithoutCreatedFilesInput"]: ValueTypes["UserCreateNestedOneWithoutCreatedFilesInput"];
	["UserCreateWithoutCreatedFilesInput"]: ValueTypes["UserCreateWithoutCreatedFilesInput"];
	["FileCreateNestedManyWithoutUpdatedByInput"]: ValueTypes["FileCreateNestedManyWithoutUpdatedByInput"];
	["FileCreateWithoutUpdatedByInput"]: ValueTypes["FileCreateWithoutUpdatedByInput"];
	["WorkspaceCreateNestedOneWithoutFilesInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutFilesInput"];
	["WorkspaceCreateWithoutFilesInput"]: ValueTypes["WorkspaceCreateWithoutFilesInput"];
	["UserCreateNestedOneWithoutOwnedWorkspacesInput"]: ValueTypes["UserCreateNestedOneWithoutOwnedWorkspacesInput"];
	["UserCreateWithoutOwnedWorkspacesInput"]: ValueTypes["UserCreateWithoutOwnedWorkspacesInput"];
	["OrderCreateNestedManyWithoutCreatedByInput"]: ValueTypes["OrderCreateNestedManyWithoutCreatedByInput"];
	["OrderCreateWithoutCreatedByInput"]: ValueTypes["OrderCreateWithoutCreatedByInput"];
	["AdBoardCreateNestedOneWithoutOrdersInput"]: ValueTypes["AdBoardCreateNestedOneWithoutOrdersInput"];
	["AdBoardCreateWithoutOrdersInput"]: ValueTypes["AdBoardCreateWithoutOrdersInput"];
	["SlotConfigurationCreateNestedManyWithoutAdBoardInput"]: ValueTypes["SlotConfigurationCreateNestedManyWithoutAdBoardInput"];
	["SlotConfigurationCreateWithoutAdBoardInput"]: ValueTypes["SlotConfigurationCreateWithoutAdBoardInput"];
	["SlotConfigurationCreateOrConnectWithoutAdBoardInput"]: ValueTypes["SlotConfigurationCreateOrConnectWithoutAdBoardInput"];
	["SlotConfigurationCreateManyAdBoardInputEnvelope"]: ValueTypes["SlotConfigurationCreateManyAdBoardInputEnvelope"];
	["SlotConfigurationCreateManyAdBoardInput"]: ValueTypes["SlotConfigurationCreateManyAdBoardInput"];
	["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"]: ValueTypes["UserCreateNestedOneWithoutOfflineBookingAdboardsInput"];
	["UserCreateWithoutOfflineBookingAdboardsInput"]: ValueTypes["UserCreateWithoutOfflineBookingAdboardsInput"];
	["ChangelogCreateNestedManyWithoutUserInput"]: ValueTypes["ChangelogCreateNestedManyWithoutUserInput"];
	["ChangelogCreateWithoutUserInput"]: ValueTypes["ChangelogCreateWithoutUserInput"];
	["BookingCreateNestedOneWithoutChangelogsInput"]: ValueTypes["BookingCreateNestedOneWithoutChangelogsInput"];
	["BookingCreateWithoutChangelogsInput"]: ValueTypes["BookingCreateWithoutChangelogsInput"];
	["UserCreateNestedOneWithoutBookingsInput"]: ValueTypes["UserCreateNestedOneWithoutBookingsInput"];
	["UserCreateWithoutBookingsInput"]: ValueTypes["UserCreateWithoutBookingsInput"];
	["AdBoardCreateNestedManyWithoutCreatedByInput"]: ValueTypes["AdBoardCreateNestedManyWithoutCreatedByInput"];
	["AdBoardCreateWithoutCreatedByInput"]: ValueTypes["AdBoardCreateWithoutCreatedByInput"];
	["OrderCreateNestedManyWithoutAdBoardInput"]: ValueTypes["OrderCreateNestedManyWithoutAdBoardInput"];
	["OrderCreateWithoutAdBoardInput"]: ValueTypes["OrderCreateWithoutAdBoardInput"];
	["UserCreateNestedOneWithoutOrdersInput"]: ValueTypes["UserCreateNestedOneWithoutOrdersInput"];
	["UserCreateWithoutOrdersInput"]: ValueTypes["UserCreateWithoutOrdersInput"];
	["AuditCreateNestedManyWithoutUserInput"]: ValueTypes["AuditCreateNestedManyWithoutUserInput"];
	["AuditCreateWithoutUserInput"]: ValueTypes["AuditCreateWithoutUserInput"];
	["EntityCreateInput"]: ValueTypes["EntityCreateInput"];
	["WorkspaceCreateNestedOneWithoutAuditsInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutAuditsInput"];
	["WorkspaceCreateWithoutAuditsInput"]: ValueTypes["WorkspaceCreateWithoutAuditsInput"];
	["MembershipCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["MembershipCreateNestedManyWithoutWorkspaceInput"];
	["MembershipCreateWithoutWorkspaceInput"]: ValueTypes["MembershipCreateWithoutWorkspaceInput"];
	["UserCreateNestedOneWithoutMembershipsInput"]: ValueTypes["UserCreateNestedOneWithoutMembershipsInput"];
	["UserCreateWithoutMembershipsInput"]: ValueTypes["UserCreateWithoutMembershipsInput"];
	["CampaignCreateNestedManyWithoutUserInput"]: ValueTypes["CampaignCreateNestedManyWithoutUserInput"];
	["CampaignCreateWithoutUserInput"]: ValueTypes["CampaignCreateWithoutUserInput"];
	["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"]: ValueTypes["AdvertisementCategoryCreateNestedManyWithoutCampaignsInput"];
	["AdvertisementCategoryCreateWithoutCampaignsInput"]: ValueTypes["AdvertisementCategoryCreateWithoutCampaignsInput"];
	["BookingCreateNestedManyWithoutAdCategoryInput"]: ValueTypes["BookingCreateNestedManyWithoutAdCategoryInput"];
	["BookingCreateWithoutAdCategoryInput"]: ValueTypes["BookingCreateWithoutAdCategoryInput"];
	["AdBoardCreateNestedOneWithoutBookingsInput"]: ValueTypes["AdBoardCreateNestedOneWithoutBookingsInput"];
	["AdBoardCreateWithoutBookingsInput"]: ValueTypes["AdBoardCreateWithoutBookingsInput"];
	["UserCreateNestedOneWithoutAdBoardsInput"]: ValueTypes["UserCreateNestedOneWithoutAdBoardsInput"];
	["UserCreateWithoutAdBoardsInput"]: ValueTypes["UserCreateWithoutAdBoardsInput"];
	["BookingCreateNestedManyWithoutSharedUsersInput"]: ValueTypes["BookingCreateNestedManyWithoutSharedUsersInput"];
	["BookingCreateWithoutSharedUsersInput"]: ValueTypes["BookingCreateWithoutSharedUsersInput"];
	["ChangelogCreateNestedManyWithoutBookingInput"]: ValueTypes["ChangelogCreateNestedManyWithoutBookingInput"];
	["ChangelogCreateWithoutBookingInput"]: ValueTypes["ChangelogCreateWithoutBookingInput"];
	["UserCreateNestedOneWithoutChangelogsInput"]: ValueTypes["UserCreateNestedOneWithoutChangelogsInput"];
	["UserCreateWithoutChangelogsInput"]: ValueTypes["UserCreateWithoutChangelogsInput"];
	["MembershipCreateNestedManyWithoutUserInput"]: ValueTypes["MembershipCreateNestedManyWithoutUserInput"];
	["MembershipCreateWithoutUserInput"]: ValueTypes["MembershipCreateWithoutUserInput"];
	["WorkspaceCreateNestedOneWithoutMembershipsInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutMembershipsInput"];
	["WorkspaceCreateWithoutMembershipsInput"]: ValueTypes["WorkspaceCreateWithoutMembershipsInput"];
	["AdBoardCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["AdBoardCreateNestedManyWithoutWorkspaceInput"];
	["AdBoardCreateWithoutWorkspaceInput"]: ValueTypes["AdBoardCreateWithoutWorkspaceInput"];
	["PlayerCreateNestedManyWithoutAdBoardsInput"]: ValueTypes["PlayerCreateNestedManyWithoutAdBoardsInput"];
	["PlayerCreateWithoutAdBoardsInput"]: ValueTypes["PlayerCreateWithoutAdBoardsInput"];
	["GalleryFileCreateInput"]: ValueTypes["GalleryFileCreateInput"];
	["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"]: ValueTypes["AdBoardGroupCreateNestedOneWithoutPisignagePlayersInput"];
	["AdBoardGroupCreateWithoutPisignagePlayersInput"]: ValueTypes["AdBoardGroupCreateWithoutPisignagePlayersInput"];
	["ScheduleCreateInput"]: ValueTypes["ScheduleCreateInput"];
	["AdBoardCreateNestedOneWithoutAdBoardGroupInput"]: ValueTypes["AdBoardCreateNestedOneWithoutAdBoardGroupInput"];
	["AdBoardCreateWithoutAdBoardGroupInput"]: ValueTypes["AdBoardCreateWithoutAdBoardGroupInput"];
	["WorkspaceCreateNestedOneWithoutAdBoardsInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardsInput"];
	["WorkspaceCreateWithoutAdBoardsInput"]: ValueTypes["WorkspaceCreateWithoutAdBoardsInput"];
	["PlaylistCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["PlaylistCreateNestedManyWithoutWorkspaceInput"];
	["PlaylistCreateWithoutWorkspaceInput"]: ValueTypes["PlaylistCreateWithoutWorkspaceInput"];
	["PisignageFileCreateInput"]: ValueTypes["PisignageFileCreateInput"];
	["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"]: ValueTypes["PlaylistCreateNestedOneWithoutFooterParentPlaylistsInput"];
	["PlaylistCreateWithoutFooterParentPlaylistsInput"]: ValueTypes["PlaylistCreateWithoutFooterParentPlaylistsInput"];
	["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"]: ValueTypes["PlaylistCreateNestedOneWithoutSidebarParentPlaylistsInput"];
	["PlaylistCreateWithoutSidebarParentPlaylistsInput"]: ValueTypes["PlaylistCreateWithoutSidebarParentPlaylistsInput"];
	["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"]: ValueTypes["AdBoardGroupCreateNestedManyWithoutAdsPlaylistInput"];
	["AdBoardGroupCreateWithoutAdsPlaylistInput"]: ValueTypes["AdBoardGroupCreateWithoutAdsPlaylistInput"];
	["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"]: ValueTypes["PlaylistCreateNestedManyWithoutAdBoardGroupsInput"];
	["PlaylistCreateWithoutAdBoardGroupsInput"]: ValueTypes["PlaylistCreateWithoutAdBoardGroupsInput"];
	["WorkspaceCreateNestedOneWithoutPlaylistsInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutPlaylistsInput"];
	["WorkspaceCreateWithoutPlaylistsInput"]: ValueTypes["WorkspaceCreateWithoutPlaylistsInput"];
	["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["AdBoardGroupCreateNestedManyWithoutWorkspaceInput"];
	["AdBoardGroupCreateWithoutWorkspaceInput"]: ValueTypes["AdBoardGroupCreateWithoutWorkspaceInput"];
	["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"]: ValueTypes["PlaylistCreateNestedOneWithoutAdBoardGroupsForAdsInput"];
	["PlaylistCreateWithoutAdBoardGroupsForAdsInput"]: ValueTypes["PlaylistCreateWithoutAdBoardGroupsForAdsInput"];
	["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"]: ValueTypes["AdBoardGroupCreateNestedManyWithoutScheduledPlaylistsInput"];
	["AdBoardGroupCreateWithoutScheduledPlaylistsInput"]: ValueTypes["AdBoardGroupCreateWithoutScheduledPlaylistsInput"];
	["PlayerCreateNestedManyWithoutAdBoardGroupInput"]: ValueTypes["PlayerCreateNestedManyWithoutAdBoardGroupInput"];
	["PlayerCreateWithoutAdBoardGroupInput"]: ValueTypes["PlayerCreateWithoutAdBoardGroupInput"];
	["WorkspaceCreateNestedOneWithoutPlayersInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutPlayersInput"];
	["WorkspaceCreateWithoutPlayersInput"]: ValueTypes["WorkspaceCreateWithoutPlayersInput"];
	["AuditCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["AuditCreateNestedManyWithoutWorkspaceInput"];
	["AuditCreateWithoutWorkspaceInput"]: ValueTypes["AuditCreateWithoutWorkspaceInput"];
	["UserCreateNestedOneWithoutAuditsInput"]: ValueTypes["UserCreateNestedOneWithoutAuditsInput"];
	["UserCreateWithoutAuditsInput"]: ValueTypes["UserCreateWithoutAuditsInput"];
	["NotificationCreateNestedManyWithoutRecipientInput"]: ValueTypes["NotificationCreateNestedManyWithoutRecipientInput"];
	["NotificationCreateWithoutRecipientInput"]: ValueTypes["NotificationCreateWithoutRecipientInput"];
	["NotificationCreateOrConnectWithoutRecipientInput"]: ValueTypes["NotificationCreateOrConnectWithoutRecipientInput"];
	["NotificationCreateManyRecipientInputEnvelope"]: ValueTypes["NotificationCreateManyRecipientInputEnvelope"];
	["NotificationCreateManyRecipientInput"]: ValueTypes["NotificationCreateManyRecipientInput"];
	["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"]: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutPublishedByInput"];
	["AdBoardPublishHistoryCreateWithoutPublishedByInput"]: ValueTypes["AdBoardPublishHistoryCreateWithoutPublishedByInput"];
	["AdBoardCreateNestedOneWithoutHistoryInput"]: ValueTypes["AdBoardCreateNestedOneWithoutHistoryInput"];
	["AdBoardCreateWithoutHistoryInput"]: ValueTypes["AdBoardCreateWithoutHistoryInput"];
	["AdBoardGroupCreateNestedOneWithoutAdBoardInput"]: ValueTypes["AdBoardGroupCreateNestedOneWithoutAdBoardInput"];
	["AdBoardGroupCreateWithoutAdBoardInput"]: ValueTypes["AdBoardGroupCreateWithoutAdBoardInput"];
	["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutAdBoardGroupsInput"];
	["WorkspaceCreateWithoutAdBoardGroupsInput"]: ValueTypes["WorkspaceCreateWithoutAdBoardGroupsInput"];
	["BookingCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["BookingCreateNestedManyWithoutWorkspaceInput"];
	["BookingCreateWithoutWorkspaceInput"]: ValueTypes["BookingCreateWithoutWorkspaceInput"];
	["CampaignCreateNestedOneWithoutBookingsInput"]: ValueTypes["CampaignCreateNestedOneWithoutBookingsInput"];
	["CampaignCreateWithoutBookingsInput"]: ValueTypes["CampaignCreateWithoutBookingsInput"];
	["PaymentCreateNestedManyWithoutCampaignInput"]: ValueTypes["PaymentCreateNestedManyWithoutCampaignInput"];
	["PaymentCreateWithoutCampaignInput"]: ValueTypes["PaymentCreateWithoutCampaignInput"];
	["PaymentCreateOrConnectWithoutCampaignInput"]: ValueTypes["PaymentCreateOrConnectWithoutCampaignInput"];
	["PaymentCreateManyCampaignInputEnvelope"]: ValueTypes["PaymentCreateManyCampaignInputEnvelope"];
	["PaymentCreateManyCampaignInput"]: ValueTypes["PaymentCreateManyCampaignInput"];
	["FileCreateNestedManyWithoutCampaignsInput"]: ValueTypes["FileCreateNestedManyWithoutCampaignsInput"];
	["FileCreateWithoutCampaignsInput"]: ValueTypes["FileCreateWithoutCampaignsInput"];
	["BookingCreateNestedOneWithoutProofsInput"]: ValueTypes["BookingCreateNestedOneWithoutProofsInput"];
	["BookingCreateWithoutProofsInput"]: ValueTypes["BookingCreateWithoutProofsInput"];
	["WorkspaceCreateNestedOneWithoutBookingsInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutBookingsInput"];
	["WorkspaceCreateWithoutBookingsInput"]: ValueTypes["WorkspaceCreateWithoutBookingsInput"];
	["FileCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["FileCreateNestedManyWithoutWorkspaceInput"];
	["FileCreateWithoutWorkspaceInput"]: ValueTypes["FileCreateWithoutWorkspaceInput"];
	["CampaignCreateNestedManyWithoutAssetsInput"]: ValueTypes["CampaignCreateNestedManyWithoutAssetsInput"];
	["CampaignCreateWithoutAssetsInput"]: ValueTypes["CampaignCreateWithoutAssetsInput"];
	["BookingCreateNestedManyWithoutCampaignInput"]: ValueTypes["BookingCreateNestedManyWithoutCampaignInput"];
	["BookingCreateWithoutCampaignInput"]: ValueTypes["BookingCreateWithoutCampaignInput"];
	["PayoutCreateNestedManyWithoutBookingInput"]: ValueTypes["PayoutCreateNestedManyWithoutBookingInput"];
	["PayoutCreateWithoutBookingInput"]: ValueTypes["PayoutCreateWithoutBookingInput"];
	["TransactionCreateNestedOneWithoutPayoutsInput"]: ValueTypes["TransactionCreateNestedOneWithoutPayoutsInput"];
	["TransactionCreateWithoutPayoutsInput"]: ValueTypes["TransactionCreateWithoutPayoutsInput"];
	["WorkspaceCreateNestedOneWithoutTransactionsInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutTransactionsInput"];
	["WorkspaceCreateWithoutTransactionsInput"]: ValueTypes["WorkspaceCreateWithoutTransactionsInput"];
	["PlayerCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["PlayerCreateNestedManyWithoutWorkspaceInput"];
	["PlayerCreateWithoutWorkspaceInput"]: ValueTypes["PlayerCreateWithoutWorkspaceInput"];
	["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"]: ValueTypes["PlayerUptimeStatusCreateNestedManyWithoutPlayerInput"];
	["PlayerUptimeStatusCreateWithoutPlayerInput"]: ValueTypes["PlayerUptimeStatusCreateWithoutPlayerInput"];
	["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"]: ValueTypes["PlayerUptimeStatusCreateOrConnectWithoutPlayerInput"];
	["PlayerUptimeStatusCreateManyPlayerInputEnvelope"]: ValueTypes["PlayerUptimeStatusCreateManyPlayerInputEnvelope"];
	["PlayerUptimeStatusCreateManyPlayerInput"]: ValueTypes["PlayerUptimeStatusCreateManyPlayerInput"];
	["CameraCreateNestedManyWithoutPlayerInput"]: ValueTypes["CameraCreateNestedManyWithoutPlayerInput"];
	["CameraCreateWithoutPlayerInput"]: ValueTypes["CameraCreateWithoutPlayerInput"];
	["CameraServerCreateNestedOneWithoutCamerasInput"]: ValueTypes["CameraServerCreateNestedOneWithoutCamerasInput"];
	["CameraServerCreateWithoutCamerasInput"]: ValueTypes["CameraServerCreateWithoutCamerasInput"];
	["WorkspaceCreateNestedOneWithoutCameraServersInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutCameraServersInput"];
	["WorkspaceCreateWithoutCameraServersInput"]: ValueTypes["WorkspaceCreateWithoutCameraServersInput"];
	["TransactionCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["TransactionCreateNestedManyWithoutWorkspaceInput"];
	["TransactionCreateWithoutWorkspaceInput"]: ValueTypes["TransactionCreateWithoutWorkspaceInput"];
	["PayoutCreateNestedManyWithoutTransactionInput"]: ValueTypes["PayoutCreateNestedManyWithoutTransactionInput"];
	["PayoutCreateWithoutTransactionInput"]: ValueTypes["PayoutCreateWithoutTransactionInput"];
	["BookingCreateNestedOneWithoutPayoutsInput"]: ValueTypes["BookingCreateNestedOneWithoutPayoutsInput"];
	["BookingCreateWithoutPayoutsInput"]: ValueTypes["BookingCreateWithoutPayoutsInput"];
	["UserCreateNestedManyWithoutSharedBookingsInput"]: ValueTypes["UserCreateNestedManyWithoutSharedBookingsInput"];
	["UserCreateWithoutSharedBookingsInput"]: ValueTypes["UserCreateWithoutSharedBookingsInput"];
	["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"]: ValueTypes["AdBoardCreateNestedManyWithoutOfflineBookingsContactInput"];
	["AdBoardCreateWithoutOfflineBookingsContactInput"]: ValueTypes["AdBoardCreateWithoutOfflineBookingsContactInput"];
	["CameraCreateNestedManyWithoutAdBoardInput"]: ValueTypes["CameraCreateNestedManyWithoutAdBoardInput"];
	["CameraCreateWithoutAdBoardInput"]: ValueTypes["CameraCreateWithoutAdBoardInput"];
	["PlayerCreateNestedOneWithoutCamerasInput"]: ValueTypes["PlayerCreateNestedOneWithoutCamerasInput"];
	["PlayerCreateWithoutCamerasInput"]: ValueTypes["PlayerCreateWithoutCamerasInput"];
	["DigitalSignageCreateNestedOneWithoutPlayersInput"]: ValueTypes["DigitalSignageCreateNestedOneWithoutPlayersInput"];
	["DigitalSignageCreateWithoutPlayersInput"]: ValueTypes["DigitalSignageCreateWithoutPlayersInput"];
	["DigitalSignageCreateOrConnectWithoutPlayersInput"]: ValueTypes["DigitalSignageCreateOrConnectWithoutPlayersInput"];
	["DigitalSignageWhereUniqueInput"]: ValueTypes["DigitalSignageWhereUniqueInput"];
	["AudienceCreateNestedManyWithoutPlayersInput"]: ValueTypes["AudienceCreateNestedManyWithoutPlayersInput"];
	["AudienceCreateWithoutPlayersInput"]: ValueTypes["AudienceCreateWithoutPlayersInput"];
	["AudienceCreateOrConnectWithoutPlayersInput"]: ValueTypes["AudienceCreateOrConnectWithoutPlayersInput"];
	["AdBoardCreateNestedManyWithoutPlayersInput"]: ValueTypes["AdBoardCreateNestedManyWithoutPlayersInput"];
	["AdBoardCreateWithoutPlayersInput"]: ValueTypes["AdBoardCreateWithoutPlayersInput"];
	["AdBoardCreateNestedOneWithoutDraftAdboardInput"]: ValueTypes["AdBoardCreateNestedOneWithoutDraftAdboardInput"];
	["AdBoardCreateWithoutDraftAdboardInput"]: ValueTypes["AdBoardCreateWithoutDraftAdboardInput"];
	["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"]: ValueTypes["AdBoardPublishHistoryCreateNestedManyWithoutAdBoardInput"];
	["AdBoardPublishHistoryCreateWithoutAdBoardInput"]: ValueTypes["AdBoardPublishHistoryCreateWithoutAdBoardInput"];
	["UserCreateNestedOneWithoutPublishAdboardVersionInput"]: ValueTypes["UserCreateNestedOneWithoutPublishAdboardVersionInput"];
	["UserCreateWithoutPublishAdboardVersionInput"]: ValueTypes["UserCreateWithoutPublishAdboardVersionInput"];
	["AdBoardReviewCommentCreateNestedManyWithoutUserInput"]: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutUserInput"];
	["AdBoardReviewCommentCreateWithoutUserInput"]: ValueTypes["AdBoardReviewCommentCreateWithoutUserInput"];
	["AdBoardCreateNestedOneWithoutReviewCommentsInput"]: ValueTypes["AdBoardCreateNestedOneWithoutReviewCommentsInput"];
	["AdBoardCreateWithoutReviewCommentsInput"]: ValueTypes["AdBoardCreateWithoutReviewCommentsInput"];
	["AdBoardCreateNestedOneWithoutPublishedVersionInput"]: ValueTypes["AdBoardCreateNestedOneWithoutPublishedVersionInput"];
	["AdBoardCreateWithoutPublishedVersionInput"]: ValueTypes["AdBoardCreateWithoutPublishedVersionInput"];
	["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"]: ValueTypes["AdBoardReviewCommentCreateNestedManyWithoutAdBoardInput"];
	["AdBoardReviewCommentCreateWithoutAdBoardInput"]: ValueTypes["AdBoardReviewCommentCreateWithoutAdBoardInput"];
	["UserCreateNestedOneWithoutReviewCommentsInput"]: ValueTypes["UserCreateNestedOneWithoutReviewCommentsInput"];
	["UserCreateWithoutReviewCommentsInput"]: ValueTypes["UserCreateWithoutReviewCommentsInput"];
	["AdBoardCreateNestedManyWithoutArchivedByInput"]: ValueTypes["AdBoardCreateNestedManyWithoutArchivedByInput"];
	["AdBoardCreateWithoutArchivedByInput"]: ValueTypes["AdBoardCreateWithoutArchivedByInput"];
	["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"]: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutAdBoardInput"];
	["AdBoardPublicReviewCreateWithoutAdBoardInput"]: ValueTypes["AdBoardPublicReviewCreateWithoutAdBoardInput"];
	["UserCreateNestedOneWithoutPublicReviewsInput"]: ValueTypes["UserCreateNestedOneWithoutPublicReviewsInput"];
	["UserCreateWithoutPublicReviewsInput"]: ValueTypes["UserCreateWithoutPublicReviewsInput"];
	["WorkspaceCreateNestedManyWithoutOwnerInput"]: ValueTypes["WorkspaceCreateNestedManyWithoutOwnerInput"];
	["WorkspaceCreateWithoutOwnerInput"]: ValueTypes["WorkspaceCreateWithoutOwnerInput"];
	["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"]: ValueTypes["SubscriptionCreateNestedOneWithoutActiveWorkspaceInput"];
	["SubscriptionCreateWithoutActiveWorkspaceInput"]: ValueTypes["SubscriptionCreateWithoutActiveWorkspaceInput"];
	["CancellationDetailsCreateInput"]: ValueTypes["CancellationDetailsCreateInput"];
	["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"]: ValueTypes["SubscriptionCreateOrConnectWithoutActiveWorkspaceInput"];
	["CameraServerCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["CameraServerCreateNestedManyWithoutWorkspaceInput"];
	["CameraServerCreateWithoutWorkspaceInput"]: ValueTypes["CameraServerCreateWithoutWorkspaceInput"];
	["CameraCreateNestedManyWithoutCameraServerInput"]: ValueTypes["CameraCreateNestedManyWithoutCameraServerInput"];
	["CameraCreateWithoutCameraServerInput"]: ValueTypes["CameraCreateWithoutCameraServerInput"];
	["CameraFeedCreateNestedManyWithoutCameraInput"]: ValueTypes["CameraFeedCreateNestedManyWithoutCameraInput"];
	["CameraFeedCreateWithoutCameraInput"]: ValueTypes["CameraFeedCreateWithoutCameraInput"];
	["CameraFeedDataCreateInput"]: ValueTypes["CameraFeedDataCreateInput"];
	["CameraServerCreateNestedOneWithoutFeedsInput"]: ValueTypes["CameraServerCreateNestedOneWithoutFeedsInput"];
	["CameraServerCreateWithoutFeedsInput"]: ValueTypes["CameraServerCreateWithoutFeedsInput"];
	["CameraServerCreateOrConnectWithoutFeedsInput"]: ValueTypes["CameraServerCreateOrConnectWithoutFeedsInput"];
	["CameraFeedCreateOrConnectWithoutCameraInput"]: ValueTypes["CameraFeedCreateOrConnectWithoutCameraInput"];
	["CameraFeedCreateManyCameraInputEnvelope"]: ValueTypes["CameraFeedCreateManyCameraInputEnvelope"];
	["CameraFeedCreateManyCameraInput"]: ValueTypes["CameraFeedCreateManyCameraInput"];
	["AdBoardCreateNestedOneWithoutCamerasInput"]: ValueTypes["AdBoardCreateNestedOneWithoutCamerasInput"];
	["AdBoardCreateWithoutCamerasInput"]: ValueTypes["AdBoardCreateWithoutCamerasInput"];
	["UserCreateNestedOneWithoutArchivedAdboardsInput"]: ValueTypes["UserCreateNestedOneWithoutArchivedAdboardsInput"];
	["UserCreateWithoutArchivedAdboardsInput"]: ValueTypes["UserCreateWithoutArchivedAdboardsInput"];
	["WorkspaceCreateNestedManyWithoutLeadsInput"]: ValueTypes["WorkspaceCreateNestedManyWithoutLeadsInput"];
	["WorkspaceCreateWithoutLeadsInput"]: ValueTypes["WorkspaceCreateWithoutLeadsInput"];
	["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["WorkspaceRoleCreateNestedManyWithoutWorkspaceInput"];
	["WorkspaceRoleCreateWithoutWorkspaceInput"]: ValueTypes["WorkspaceRoleCreateWithoutWorkspaceInput"];
	["MembershipCreateNestedManyWithoutRoleInput"]: ValueTypes["MembershipCreateNestedManyWithoutRoleInput"];
	["MembershipCreateWithoutRoleInput"]: ValueTypes["MembershipCreateWithoutRoleInput"];
	["MembershipCreateOrConnectWithoutRoleInput"]: ValueTypes["MembershipCreateOrConnectWithoutRoleInput"];
	["MembershipCreateManyRoleInputEnvelope"]: ValueTypes["MembershipCreateManyRoleInputEnvelope"];
	["MembershipCreateManyRoleInput"]: ValueTypes["MembershipCreateManyRoleInput"];
	["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["WorkspaceRoleCreateOrConnectWithoutWorkspaceInput"];
	["WorkspaceRoleCreateManyWorkspaceInputEnvelope"]: ValueTypes["WorkspaceRoleCreateManyWorkspaceInputEnvelope"];
	["WorkspaceRoleCreateManyWorkspaceInput"]: ValueTypes["WorkspaceRoleCreateManyWorkspaceInput"];
	["WebhookLogCreateNestedManyWithoutWorkspaceInput"]: ValueTypes["WebhookLogCreateNestedManyWithoutWorkspaceInput"];
	["WebhookLogCreateWithoutWorkspaceInput"]: ValueTypes["WebhookLogCreateWithoutWorkspaceInput"];
	["BookingCreateNestedOneWithoutWebhookLogsInput"]: ValueTypes["BookingCreateNestedOneWithoutWebhookLogsInput"];
	["BookingCreateWithoutWebhookLogsInput"]: ValueTypes["BookingCreateWithoutWebhookLogsInput"];
	["SlotUsageCreateNestedManyWithoutBookingInput"]: ValueTypes["SlotUsageCreateNestedManyWithoutBookingInput"];
	["SlotUsageCreateWithoutBookingInput"]: ValueTypes["SlotUsageCreateWithoutBookingInput"];
	["SlotUsageCreateOrConnectWithoutBookingInput"]: ValueTypes["SlotUsageCreateOrConnectWithoutBookingInput"];
	["SlotUsageCreateManyBookingInputEnvelope"]: ValueTypes["SlotUsageCreateManyBookingInputEnvelope"];
	["SlotUsageCreateManyBookingInput"]: ValueTypes["SlotUsageCreateManyBookingInput"];
	["BookingCreateOrConnectWithoutWebhookLogsInput"]: ValueTypes["BookingCreateOrConnectWithoutWebhookLogsInput"];
	["WebhookLogCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["WebhookLogCreateOrConnectWithoutWorkspaceInput"];
	["WebhookLogCreateManyWorkspaceInputEnvelope"]: ValueTypes["WebhookLogCreateManyWorkspaceInputEnvelope"];
	["WebhookLogCreateManyWorkspaceInput"]: ValueTypes["WebhookLogCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutLeadsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutLeadsInput"];
	["AdBoardPublicReviewCreateNestedManyWithoutUserInput"]: ValueTypes["AdBoardPublicReviewCreateNestedManyWithoutUserInput"];
	["AdBoardPublicReviewCreateWithoutUserInput"]: ValueTypes["AdBoardPublicReviewCreateWithoutUserInput"];
	["AdBoardCreateNestedOneWithoutPublicReviewsInput"]: ValueTypes["AdBoardCreateNestedOneWithoutPublicReviewsInput"];
	["AdBoardCreateWithoutPublicReviewsInput"]: ValueTypes["AdBoardCreateWithoutPublicReviewsInput"];
	["AdBoardCreateOrConnectWithoutPublicReviewsInput"]: ValueTypes["AdBoardCreateOrConnectWithoutPublicReviewsInput"];
	["AdBoardPublicReviewCreateOrConnectWithoutUserInput"]: ValueTypes["AdBoardPublicReviewCreateOrConnectWithoutUserInput"];
	["AdBoardPublicReviewCreateManyUserInputEnvelope"]: ValueTypes["AdBoardPublicReviewCreateManyUserInputEnvelope"];
	["AdBoardPublicReviewCreateManyUserInput"]: ValueTypes["AdBoardPublicReviewCreateManyUserInput"];
	["UserPositionCreateNestedManyWithoutUserInput"]: ValueTypes["UserPositionCreateNestedManyWithoutUserInput"];
	["UserPositionCreateWithoutUserInput"]: ValueTypes["UserPositionCreateWithoutUserInput"];
	["UserPositionCoordsCreateInput"]: ValueTypes["UserPositionCoordsCreateInput"];
	["UserPositionLocationCreateInput"]: ValueTypes["UserPositionLocationCreateInput"];
	["UserPositionCreateOrConnectWithoutUserInput"]: ValueTypes["UserPositionCreateOrConnectWithoutUserInput"];
	["UserPositionCreateManyUserInputEnvelope"]: ValueTypes["UserPositionCreateManyUserInputEnvelope"];
	["UserPositionCreateManyUserInput"]: ValueTypes["UserPositionCreateManyUserInput"];
	["UserCreateOrConnectWithoutArchivedAdboardsInput"]: ValueTypes["UserCreateOrConnectWithoutArchivedAdboardsInput"];
	["AdBoardCreateOrConnectWithoutCamerasInput"]: ValueTypes["AdBoardCreateOrConnectWithoutCamerasInput"];
	["CameraCreateOrConnectWithoutCameraServerInput"]: ValueTypes["CameraCreateOrConnectWithoutCameraServerInput"];
	["CameraCreateManyCameraServerInputEnvelope"]: ValueTypes["CameraCreateManyCameraServerInputEnvelope"];
	["CameraCreateManyCameraServerInput"]: ValueTypes["CameraCreateManyCameraServerInput"];
	["CameraFeedCreateNestedManyWithoutCameraServerInput"]: ValueTypes["CameraFeedCreateNestedManyWithoutCameraServerInput"];
	["CameraFeedCreateWithoutCameraServerInput"]: ValueTypes["CameraFeedCreateWithoutCameraServerInput"];
	["CameraCreateNestedOneWithoutFeedsInput"]: ValueTypes["CameraCreateNestedOneWithoutFeedsInput"];
	["CameraCreateWithoutFeedsInput"]: ValueTypes["CameraCreateWithoutFeedsInput"];
	["CameraCreateOrConnectWithoutFeedsInput"]: ValueTypes["CameraCreateOrConnectWithoutFeedsInput"];
	["CameraFeedCreateOrConnectWithoutCameraServerInput"]: ValueTypes["CameraFeedCreateOrConnectWithoutCameraServerInput"];
	["CameraFeedCreateManyCameraServerInputEnvelope"]: ValueTypes["CameraFeedCreateManyCameraServerInputEnvelope"];
	["CameraFeedCreateManyCameraServerInput"]: ValueTypes["CameraFeedCreateManyCameraServerInput"];
	["CameraServerCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["CameraServerCreateOrConnectWithoutWorkspaceInput"];
	["CameraServerCreateManyWorkspaceInputEnvelope"]: ValueTypes["CameraServerCreateManyWorkspaceInputEnvelope"];
	["CameraServerCreateManyWorkspaceInput"]: ValueTypes["CameraServerCreateManyWorkspaceInput"];
	["UserCreateNestedManyWithoutLeadWorspacesInput"]: ValueTypes["UserCreateNestedManyWithoutLeadWorspacesInput"];
	["UserCreateWithoutLeadWorspacesInput"]: ValueTypes["UserCreateWithoutLeadWorspacesInput"];
	["UserCreateOrConnectWithoutLeadWorspacesInput"]: ValueTypes["UserCreateOrConnectWithoutLeadWorspacesInput"];
	["WorkspaceCreateOrConnectWithoutOwnerInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutOwnerInput"];
	["WorkspaceCreateManyOwnerInputEnvelope"]: ValueTypes["WorkspaceCreateManyOwnerInputEnvelope"];
	["WorkspaceCreateManyOwnerInput"]: ValueTypes["WorkspaceCreateManyOwnerInput"];
	["UserCreateOrConnectWithoutPublicReviewsInput"]: ValueTypes["UserCreateOrConnectWithoutPublicReviewsInput"];
	["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"]: ValueTypes["AdBoardPublicReviewCreateOrConnectWithoutAdBoardInput"];
	["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"]: ValueTypes["AdBoardPublicReviewCreateManyAdBoardInputEnvelope"];
	["AdBoardPublicReviewCreateManyAdBoardInput"]: ValueTypes["AdBoardPublicReviewCreateManyAdBoardInput"];
	["AdBoardCreateOrConnectWithoutArchivedByInput"]: ValueTypes["AdBoardCreateOrConnectWithoutArchivedByInput"];
	["AdBoardCreateManyArchivedByInputEnvelope"]: ValueTypes["AdBoardCreateManyArchivedByInputEnvelope"];
	["AdBoardCreateManyArchivedByInput"]: ValueTypes["AdBoardCreateManyArchivedByInput"];
	["UserCreateOrConnectWithoutReviewCommentsInput"]: ValueTypes["UserCreateOrConnectWithoutReviewCommentsInput"];
	["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"]: ValueTypes["AdBoardReviewCommentCreateOrConnectWithoutAdBoardInput"];
	["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"]: ValueTypes["AdBoardReviewCommentCreateManyAdBoardInputEnvelope"];
	["AdBoardReviewCommentCreateManyAdBoardInput"]: ValueTypes["AdBoardReviewCommentCreateManyAdBoardInput"];
	["AdBoardCreateOrConnectWithoutPublishedVersionInput"]: ValueTypes["AdBoardCreateOrConnectWithoutPublishedVersionInput"];
	["AdBoardCreateOrConnectWithoutReviewCommentsInput"]: ValueTypes["AdBoardCreateOrConnectWithoutReviewCommentsInput"];
	["AdBoardReviewCommentCreateOrConnectWithoutUserInput"]: ValueTypes["AdBoardReviewCommentCreateOrConnectWithoutUserInput"];
	["AdBoardReviewCommentCreateManyUserInputEnvelope"]: ValueTypes["AdBoardReviewCommentCreateManyUserInputEnvelope"];
	["AdBoardReviewCommentCreateManyUserInput"]: ValueTypes["AdBoardReviewCommentCreateManyUserInput"];
	["UserCreateOrConnectWithoutPublishAdboardVersionInput"]: ValueTypes["UserCreateOrConnectWithoutPublishAdboardVersionInput"];
	["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"]: ValueTypes["AdBoardPublishHistoryCreateOrConnectWithoutAdBoardInput"];
	["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"]: ValueTypes["AdBoardPublishHistoryCreateManyAdBoardInputEnvelope"];
	["AdBoardPublishHistoryCreateManyAdBoardInput"]: ValueTypes["AdBoardPublishHistoryCreateManyAdBoardInput"];
	["AdBoardCreateOrConnectWithoutDraftAdboardInput"]: ValueTypes["AdBoardCreateOrConnectWithoutDraftAdboardInput"];
	["AdBoardCreateOrConnectWithoutPlayersInput"]: ValueTypes["AdBoardCreateOrConnectWithoutPlayersInput"];
	["PlayerCreateOrConnectWithoutCamerasInput"]: ValueTypes["PlayerCreateOrConnectWithoutCamerasInput"];
	["CameraCreateOrConnectWithoutAdBoardInput"]: ValueTypes["CameraCreateOrConnectWithoutAdBoardInput"];
	["CameraCreateManyAdBoardInputEnvelope"]: ValueTypes["CameraCreateManyAdBoardInputEnvelope"];
	["CameraCreateManyAdBoardInput"]: ValueTypes["CameraCreateManyAdBoardInput"];
	["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"]: ValueTypes["AdBoardCreateOrConnectWithoutOfflineBookingsContactInput"];
	["AdBoardCreateManyOfflineBookingsContactInputEnvelope"]: ValueTypes["AdBoardCreateManyOfflineBookingsContactInputEnvelope"];
	["AdBoardCreateManyOfflineBookingsContactInput"]: ValueTypes["AdBoardCreateManyOfflineBookingsContactInput"];
	["UserCreateOrConnectWithoutSharedBookingsInput"]: ValueTypes["UserCreateOrConnectWithoutSharedBookingsInput"];
	["WebhookLogCreateNestedManyWithoutBookingInput"]: ValueTypes["WebhookLogCreateNestedManyWithoutBookingInput"];
	["WebhookLogCreateWithoutBookingInput"]: ValueTypes["WebhookLogCreateWithoutBookingInput"];
	["WorkspaceCreateNestedOneWithoutWebhookLogsInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutWebhookLogsInput"];
	["WorkspaceCreateWithoutWebhookLogsInput"]: ValueTypes["WorkspaceCreateWithoutWebhookLogsInput"];
	["WorkspaceCreateOrConnectWithoutWebhookLogsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutWebhookLogsInput"];
	["WebhookLogCreateOrConnectWithoutBookingInput"]: ValueTypes["WebhookLogCreateOrConnectWithoutBookingInput"];
	["WebhookLogCreateManyBookingInputEnvelope"]: ValueTypes["WebhookLogCreateManyBookingInputEnvelope"];
	["WebhookLogCreateManyBookingInput"]: ValueTypes["WebhookLogCreateManyBookingInput"];
	["BookingCreateOrConnectWithoutPayoutsInput"]: ValueTypes["BookingCreateOrConnectWithoutPayoutsInput"];
	["PayoutCreateOrConnectWithoutTransactionInput"]: ValueTypes["PayoutCreateOrConnectWithoutTransactionInput"];
	["PayoutCreateManyTransactionInputEnvelope"]: ValueTypes["PayoutCreateManyTransactionInputEnvelope"];
	["PayoutCreateManyTransactionInput"]: ValueTypes["PayoutCreateManyTransactionInput"];
	["TransactionCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["TransactionCreateOrConnectWithoutWorkspaceInput"];
	["TransactionCreateManyWorkspaceInputEnvelope"]: ValueTypes["TransactionCreateManyWorkspaceInputEnvelope"];
	["TransactionCreateManyWorkspaceInput"]: ValueTypes["TransactionCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutCameraServersInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutCameraServersInput"];
	["CameraServerCreateOrConnectWithoutCamerasInput"]: ValueTypes["CameraServerCreateOrConnectWithoutCamerasInput"];
	["CameraCreateOrConnectWithoutPlayerInput"]: ValueTypes["CameraCreateOrConnectWithoutPlayerInput"];
	["CameraCreateManyPlayerInputEnvelope"]: ValueTypes["CameraCreateManyPlayerInputEnvelope"];
	["CameraCreateManyPlayerInput"]: ValueTypes["CameraCreateManyPlayerInput"];
	["PlayerCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["PlayerCreateOrConnectWithoutWorkspaceInput"];
	["PlayerCreateManyWorkspaceInputEnvelope"]: ValueTypes["PlayerCreateManyWorkspaceInputEnvelope"];
	["PlayerCreateManyWorkspaceInput"]: ValueTypes["PlayerCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutTransactionsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutTransactionsInput"];
	["TransactionCreateOrConnectWithoutPayoutsInput"]: ValueTypes["TransactionCreateOrConnectWithoutPayoutsInput"];
	["PayoutCreateOrConnectWithoutBookingInput"]: ValueTypes["PayoutCreateOrConnectWithoutBookingInput"];
	["PayoutCreateManyBookingInputEnvelope"]: ValueTypes["PayoutCreateManyBookingInputEnvelope"];
	["PayoutCreateManyBookingInput"]: ValueTypes["PayoutCreateManyBookingInput"];
	["BookingCreateOrConnectWithoutCampaignInput"]: ValueTypes["BookingCreateOrConnectWithoutCampaignInput"];
	["BookingCreateManyCampaignInputEnvelope"]: ValueTypes["BookingCreateManyCampaignInputEnvelope"];
	["BookingCreateManyCampaignInput"]: ValueTypes["BookingCreateManyCampaignInput"];
	["CampaignCreateOrConnectWithoutAssetsInput"]: ValueTypes["CampaignCreateOrConnectWithoutAssetsInput"];
	["FileCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["FileCreateOrConnectWithoutWorkspaceInput"];
	["FileCreateManyWorkspaceInputEnvelope"]: ValueTypes["FileCreateManyWorkspaceInputEnvelope"];
	["FileCreateManyWorkspaceInput"]: ValueTypes["FileCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutBookingsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutBookingsInput"];
	["BookingCreateOrConnectWithoutProofsInput"]: ValueTypes["BookingCreateOrConnectWithoutProofsInput"];
	["FileCreateOrConnectWithoutCampaignsInput"]: ValueTypes["FileCreateOrConnectWithoutCampaignsInput"];
	["CampaignCreateOrConnectWithoutBookingsInput"]: ValueTypes["CampaignCreateOrConnectWithoutBookingsInput"];
	["BookingCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["BookingCreateOrConnectWithoutWorkspaceInput"];
	["BookingCreateManyWorkspaceInputEnvelope"]: ValueTypes["BookingCreateManyWorkspaceInputEnvelope"];
	["BookingCreateManyWorkspaceInput"]: ValueTypes["BookingCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutAdBoardGroupsInput"];
	["AdBoardGroupCreateOrConnectWithoutAdBoardInput"]: ValueTypes["AdBoardGroupCreateOrConnectWithoutAdBoardInput"];
	["AdBoardCreateOrConnectWithoutHistoryInput"]: ValueTypes["AdBoardCreateOrConnectWithoutHistoryInput"];
	["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"]: ValueTypes["AdBoardPublishHistoryCreateOrConnectWithoutPublishedByInput"];
	["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"]: ValueTypes["AdBoardPublishHistoryCreateManyPublishedByInputEnvelope"];
	["AdBoardPublishHistoryCreateManyPublishedByInput"]: ValueTypes["AdBoardPublishHistoryCreateManyPublishedByInput"];
	["UserCreateOrConnectWithoutAuditsInput"]: ValueTypes["UserCreateOrConnectWithoutAuditsInput"];
	["AuditCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["AuditCreateOrConnectWithoutWorkspaceInput"];
	["AuditCreateManyWorkspaceInputEnvelope"]: ValueTypes["AuditCreateManyWorkspaceInputEnvelope"];
	["AuditCreateManyWorkspaceInput"]: ValueTypes["AuditCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutPlayersInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutPlayersInput"];
	["PlayerCreateOrConnectWithoutAdBoardGroupInput"]: ValueTypes["PlayerCreateOrConnectWithoutAdBoardGroupInput"];
	["PlayerCreateManyAdBoardGroupInputEnvelope"]: ValueTypes["PlayerCreateManyAdBoardGroupInputEnvelope"];
	["PlayerCreateManyAdBoardGroupInput"]: ValueTypes["PlayerCreateManyAdBoardGroupInput"];
	["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"]: ValueTypes["AdBoardGroupCreateOrConnectWithoutScheduledPlaylistsInput"];
	["PlaylistCreateNestedManyWithoutFooterPlaylistInput"]: ValueTypes["PlaylistCreateNestedManyWithoutFooterPlaylistInput"];
	["PlaylistCreateWithoutFooterPlaylistInput"]: ValueTypes["PlaylistCreateWithoutFooterPlaylistInput"];
	["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"]: ValueTypes["PlaylistCreateNestedManyWithoutSidebarPlaylistInput"];
	["PlaylistCreateWithoutSidebarPlaylistInput"]: ValueTypes["PlaylistCreateWithoutSidebarPlaylistInput"];
	["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"]: ValueTypes["PlaylistCreateOrConnectWithoutSidebarPlaylistInput"];
	["PlaylistCreateManySidebarPlaylistInputEnvelope"]: ValueTypes["PlaylistCreateManySidebarPlaylistInputEnvelope"];
	["PlaylistCreateManySidebarPlaylistInput"]: ValueTypes["PlaylistCreateManySidebarPlaylistInput"];
	["PlaylistCreateOrConnectWithoutFooterPlaylistInput"]: ValueTypes["PlaylistCreateOrConnectWithoutFooterPlaylistInput"];
	["PlaylistCreateManyFooterPlaylistInputEnvelope"]: ValueTypes["PlaylistCreateManyFooterPlaylistInputEnvelope"];
	["PlaylistCreateManyFooterPlaylistInput"]: ValueTypes["PlaylistCreateManyFooterPlaylistInput"];
	["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"]: ValueTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsForAdsInput"];
	["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["AdBoardGroupCreateOrConnectWithoutWorkspaceInput"];
	["AdBoardGroupCreateManyWorkspaceInputEnvelope"]: ValueTypes["AdBoardGroupCreateManyWorkspaceInputEnvelope"];
	["AdBoardGroupCreateManyWorkspaceInput"]: ValueTypes["AdBoardGroupCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutPlaylistsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutPlaylistsInput"];
	["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"]: ValueTypes["PlaylistCreateOrConnectWithoutAdBoardGroupsInput"];
	["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"]: ValueTypes["AdBoardGroupCreateOrConnectWithoutAdsPlaylistInput"];
	["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"]: ValueTypes["AdBoardGroupCreateManyAdsPlaylistInputEnvelope"];
	["AdBoardGroupCreateManyAdsPlaylistInput"]: ValueTypes["AdBoardGroupCreateManyAdsPlaylistInput"];
	["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"]: ValueTypes["PlaylistCreateOrConnectWithoutSidebarParentPlaylistsInput"];
	["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"]: ValueTypes["PlaylistCreateOrConnectWithoutFooterParentPlaylistsInput"];
	["PlaylistCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["PlaylistCreateOrConnectWithoutWorkspaceInput"];
	["PlaylistCreateManyWorkspaceInputEnvelope"]: ValueTypes["PlaylistCreateManyWorkspaceInputEnvelope"];
	["PlaylistCreateManyWorkspaceInput"]: ValueTypes["PlaylistCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutAdBoardsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutAdBoardsInput"];
	["AdBoardCreateOrConnectWithoutAdBoardGroupInput"]: ValueTypes["AdBoardCreateOrConnectWithoutAdBoardGroupInput"];
	["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"]: ValueTypes["AdBoardGroupCreateOrConnectWithoutPisignagePlayersInput"];
	["PlayerCreateOrConnectWithoutAdBoardsInput"]: ValueTypes["PlayerCreateOrConnectWithoutAdBoardsInput"];
	["AdBoardCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["AdBoardCreateOrConnectWithoutWorkspaceInput"];
	["AdBoardCreateManyWorkspaceInputEnvelope"]: ValueTypes["AdBoardCreateManyWorkspaceInputEnvelope"];
	["AdBoardCreateManyWorkspaceInput"]: ValueTypes["AdBoardCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutMembershipsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutMembershipsInput"];
	["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"]: ValueTypes["WorkspaceRoleCreateNestedOneWithoutMembershipsInput"];
	["WorkspaceRoleCreateWithoutMembershipsInput"]: ValueTypes["WorkspaceRoleCreateWithoutMembershipsInput"];
	["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutWorkspaceRolesInput"];
	["WorkspaceCreateWithoutWorkspaceRolesInput"]: ValueTypes["WorkspaceCreateWithoutWorkspaceRolesInput"];
	["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutWorkspaceRolesInput"];
	["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"]: ValueTypes["WorkspaceRoleCreateOrConnectWithoutMembershipsInput"];
	["MembershipCreateOrConnectWithoutUserInput"]: ValueTypes["MembershipCreateOrConnectWithoutUserInput"];
	["MembershipCreateManyUserInputEnvelope"]: ValueTypes["MembershipCreateManyUserInputEnvelope"];
	["MembershipCreateManyUserInput"]: ValueTypes["MembershipCreateManyUserInput"];
	["UserCreateOrConnectWithoutChangelogsInput"]: ValueTypes["UserCreateOrConnectWithoutChangelogsInput"];
	["ChangelogCreateOrConnectWithoutBookingInput"]: ValueTypes["ChangelogCreateOrConnectWithoutBookingInput"];
	["ChangelogCreateManyBookingInputEnvelope"]: ValueTypes["ChangelogCreateManyBookingInputEnvelope"];
	["ChangelogCreateManyBookingInput"]: ValueTypes["ChangelogCreateManyBookingInput"];
	["BookingCreateOrConnectWithoutSharedUsersInput"]: ValueTypes["BookingCreateOrConnectWithoutSharedUsersInput"];
	["UserCreateOrConnectWithoutAdBoardsInput"]: ValueTypes["UserCreateOrConnectWithoutAdBoardsInput"];
	["AdBoardCreateOrConnectWithoutBookingsInput"]: ValueTypes["AdBoardCreateOrConnectWithoutBookingsInput"];
	["BookingCreateOrConnectWithoutAdCategoryInput"]: ValueTypes["BookingCreateOrConnectWithoutAdCategoryInput"];
	["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"]: ValueTypes["AdvertisementCategoryCreateOrConnectWithoutCampaignsInput"];
	["CampaignCreateOrConnectWithoutUserInput"]: ValueTypes["CampaignCreateOrConnectWithoutUserInput"];
	["CampaignCreateManyUserInputEnvelope"]: ValueTypes["CampaignCreateManyUserInputEnvelope"];
	["CampaignCreateManyUserInput"]: ValueTypes["CampaignCreateManyUserInput"];
	["UserCreateOrConnectWithoutMembershipsInput"]: ValueTypes["UserCreateOrConnectWithoutMembershipsInput"];
	["MembershipCreateOrConnectWithoutWorkspaceInput"]: ValueTypes["MembershipCreateOrConnectWithoutWorkspaceInput"];
	["MembershipCreateManyWorkspaceInputEnvelope"]: ValueTypes["MembershipCreateManyWorkspaceInputEnvelope"];
	["MembershipCreateManyWorkspaceInput"]: ValueTypes["MembershipCreateManyWorkspaceInput"];
	["WorkspaceCreateOrConnectWithoutAuditsInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutAuditsInput"];
	["AuditCreateOrConnectWithoutUserInput"]: ValueTypes["AuditCreateOrConnectWithoutUserInput"];
	["AuditCreateManyUserInputEnvelope"]: ValueTypes["AuditCreateManyUserInputEnvelope"];
	["AuditCreateManyUserInput"]: ValueTypes["AuditCreateManyUserInput"];
	["UserCreateOrConnectWithoutOrdersInput"]: ValueTypes["UserCreateOrConnectWithoutOrdersInput"];
	["OrderCreateOrConnectWithoutAdBoardInput"]: ValueTypes["OrderCreateOrConnectWithoutAdBoardInput"];
	["OrderCreateManyAdBoardInputEnvelope"]: ValueTypes["OrderCreateManyAdBoardInputEnvelope"];
	["OrderCreateManyAdBoardInput"]: ValueTypes["OrderCreateManyAdBoardInput"];
	["AdBoardCreateOrConnectWithoutCreatedByInput"]: ValueTypes["AdBoardCreateOrConnectWithoutCreatedByInput"];
	["AdBoardCreateManyCreatedByInputEnvelope"]: ValueTypes["AdBoardCreateManyCreatedByInputEnvelope"];
	["AdBoardCreateManyCreatedByInput"]: ValueTypes["AdBoardCreateManyCreatedByInput"];
	["UserCreateOrConnectWithoutBookingsInput"]: ValueTypes["UserCreateOrConnectWithoutBookingsInput"];
	["BookingCreateOrConnectWithoutChangelogsInput"]: ValueTypes["BookingCreateOrConnectWithoutChangelogsInput"];
	["ChangelogCreateOrConnectWithoutUserInput"]: ValueTypes["ChangelogCreateOrConnectWithoutUserInput"];
	["ChangelogCreateManyUserInputEnvelope"]: ValueTypes["ChangelogCreateManyUserInputEnvelope"];
	["ChangelogCreateManyUserInput"]: ValueTypes["ChangelogCreateManyUserInput"];
	["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"]: ValueTypes["UserCreateOrConnectWithoutOfflineBookingAdboardsInput"];
	["AdBoardCreateOrConnectWithoutOrdersInput"]: ValueTypes["AdBoardCreateOrConnectWithoutOrdersInput"];
	["OrderCreateOrConnectWithoutCreatedByInput"]: ValueTypes["OrderCreateOrConnectWithoutCreatedByInput"];
	["OrderCreateManyCreatedByInputEnvelope"]: ValueTypes["OrderCreateManyCreatedByInputEnvelope"];
	["OrderCreateManyCreatedByInput"]: ValueTypes["OrderCreateManyCreatedByInput"];
	["UserCreateOrConnectWithoutOwnedWorkspacesInput"]: ValueTypes["UserCreateOrConnectWithoutOwnedWorkspacesInput"];
	["WorkspaceCreateOrConnectWithoutFilesInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutFilesInput"];
	["FileCreateOrConnectWithoutUpdatedByInput"]: ValueTypes["FileCreateOrConnectWithoutUpdatedByInput"];
	["FileCreateManyUpdatedByInputEnvelope"]: ValueTypes["FileCreateManyUpdatedByInputEnvelope"];
	["FileCreateManyUpdatedByInput"]: ValueTypes["FileCreateManyUpdatedByInput"];
	["UserCreateOrConnectWithoutCreatedFilesInput"]: ValueTypes["UserCreateOrConnectWithoutCreatedFilesInput"];
	["FileCreateOrConnectWithoutProofBookingInput"]: ValueTypes["FileCreateOrConnectWithoutProofBookingInput"];
	["FileCreateManyProofBookingInputEnvelope"]: ValueTypes["FileCreateManyProofBookingInputEnvelope"];
	["FileCreateManyProofBookingInput"]: ValueTypes["FileCreateManyProofBookingInput"];
	["BookingCreateOrConnectWithoutBusinessCategoryInput"]: ValueTypes["BookingCreateOrConnectWithoutBusinessCategoryInput"];
	["BookingCreateManyBusinessCategoryInputEnvelope"]: ValueTypes["BookingCreateManyBusinessCategoryInputEnvelope"];
	["BookingCreateManyBusinessCategoryInput"]: ValueTypes["BookingCreateManyBusinessCategoryInput"];
	["BusinessCategoryCreateOrConnectWithoutCampaignsInput"]: ValueTypes["BusinessCategoryCreateOrConnectWithoutCampaignsInput"];
	["CampaignCreateOrConnectWithoutAdCategoryInput"]: ValueTypes["CampaignCreateOrConnectWithoutAdCategoryInput"];
	["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"]: ValueTypes["AdvertisementCategoryCreateOrConnectWithoutBookingsInput"];
	["BookingCreateOrConnectWithoutAssetsInput"]: ValueTypes["BookingCreateOrConnectWithoutAssetsInput"];
	["FileCreateOrConnectWithoutCreatedByInput"]: ValueTypes["FileCreateOrConnectWithoutCreatedByInput"];
	["FileCreateManyCreatedByInputEnvelope"]: ValueTypes["FileCreateManyCreatedByInputEnvelope"];
	["FileCreateManyCreatedByInput"]: ValueTypes["FileCreateManyCreatedByInput"];
	["UserCreateOrConnectWithoutCampaignsInput"]: ValueTypes["UserCreateOrConnectWithoutCampaignsInput"];
	["CampaignCreateOrConnectWithoutBusinessCategoryInput"]: ValueTypes["CampaignCreateOrConnectWithoutBusinessCategoryInput"];
	["CampaignCreateManyBusinessCategoryInputEnvelope"]: ValueTypes["CampaignCreateManyBusinessCategoryInputEnvelope"];
	["CampaignCreateManyBusinessCategoryInput"]: ValueTypes["CampaignCreateManyBusinessCategoryInput"];
	["BusinessCategoryCreateOrConnectWithoutBookingsInput"]: ValueTypes["BusinessCategoryCreateOrConnectWithoutBookingsInput"];
	["BookingCreateOrConnectWithoutUserInput"]: ValueTypes["BookingCreateOrConnectWithoutUserInput"];
	["BookingCreateManyUserInputEnvelope"]: ValueTypes["BookingCreateManyUserInputEnvelope"];
	["BookingCreateManyUserInput"]: ValueTypes["BookingCreateManyUserInput"];
	["UserCreateOrConnectWithoutUpdatedFilesInput"]: ValueTypes["UserCreateOrConnectWithoutUpdatedFilesInput"];
	["FileCreateOrConnectWithoutBookingsInput"]: ValueTypes["FileCreateOrConnectWithoutBookingsInput"];
	["BookingCreateOrConnectWithoutAdBoardInput"]: ValueTypes["BookingCreateOrConnectWithoutAdBoardInput"];
	["BookingCreateManyAdBoardInputEnvelope"]: ValueTypes["BookingCreateManyAdBoardInputEnvelope"];
	["BookingCreateManyAdBoardInput"]: ValueTypes["BookingCreateManyAdBoardInput"];
	["AdBoardCreateOrConnectWithoutCategoryInput"]: ValueTypes["AdBoardCreateOrConnectWithoutCategoryInput"];
	["AdBoardCreateManyCategoryInputEnvelope"]: ValueTypes["AdBoardCreateManyCategoryInputEnvelope"];
	["AdBoardCreateManyCategoryInput"]: ValueTypes["AdBoardCreateManyCategoryInput"];
	["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"]: ValueTypes["BusinessCategoryCreateOrConnectWithoutRestrictedByAdBoardsInput"];
	["AdBoardCreateOrConnectWithoutLocationCategoryInput"]: ValueTypes["AdBoardCreateOrConnectWithoutLocationCategoryInput"];
	["AdBoardCreateManyLocationCategoryInputEnvelope"]: ValueTypes["AdBoardCreateManyLocationCategoryInputEnvelope"];
	["AdBoardCreateManyLocationCategoryInput"]: ValueTypes["AdBoardCreateManyLocationCategoryInput"];
	["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"]: ValueTypes["LocationCategoryCreateOrConnectWithoutLocationSubCategoryInput"];
	["LocationCategoryWhereUniqueInput"]: ValueTypes["LocationCategoryWhereUniqueInput"];
	["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"]: ValueTypes["LocationSubCategoryCreateOrConnectWithoutAdBoardsInput"];
	["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"]: ValueTypes["AdBoardCreateOrConnectWithoutRestrictedBusinesInput"];
	["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"]: ValueTypes["BusinessCategoryCreateOrConnectWithoutAdBoardsInput"];
	["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"]: ValueTypes["AdBoardCreateOrConnectWithoutLocationSubCategoryInput"];
	["AdBoardCreateManyLocationSubCategoryInputEnvelope"]: ValueTypes["AdBoardCreateManyLocationSubCategoryInputEnvelope"];
	["AdBoardCreateManyLocationSubCategoryInput"]: ValueTypes["AdBoardCreateManyLocationSubCategoryInput"];
	["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"]: ValueTypes["LocationSubCategoryCreateOrConnectWithoutLocationCategoryInput"];
	["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"]: ValueTypes["LocationSubCategoryCreateManyLocationCategoryInputEnvelope"];
	["LocationSubCategoryCreateManyLocationCategoryInput"]: ValueTypes["LocationSubCategoryCreateManyLocationCategoryInput"];
	["LocationCategoryCreateOrConnectWithoutAdBoardsInput"]: ValueTypes["LocationCategoryCreateOrConnectWithoutAdBoardsInput"];
	["AdBoardUpdateManyMutationInput"]: ValueTypes["AdBoardUpdateManyMutationInput"];
	["AdBoardUpdateInput"]: ValueTypes["AdBoardUpdateInput"];
	["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"]: ValueTypes["LocationCategoryUpdateOneWithoutAdBoardsNestedInput"];
	["LocationCategoryUpsertWithoutAdBoardsInput"]: ValueTypes["LocationCategoryUpsertWithoutAdBoardsInput"];
	["LocationCategoryUpdateWithoutAdBoardsInput"]: ValueTypes["LocationCategoryUpdateWithoutAdBoardsInput"];
	["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"]: ValueTypes["LocationSubCategoryUpdateManyWithoutLocationCategoryNestedInput"];
	["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"]: ValueTypes["LocationSubCategoryUpsertWithWhereUniqueWithoutLocationCategoryInput"];
	["LocationSubCategoryUpdateWithoutLocationCategoryInput"]: ValueTypes["LocationSubCategoryUpdateWithoutLocationCategoryInput"];
	["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutLocationSubCategoryNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutLocationSubCategoryInput"];
	["AdBoardUpdateWithoutLocationSubCategoryInput"]: ValueTypes["AdBoardUpdateWithoutLocationSubCategoryInput"];
	["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"]: ValueTypes["AdBoardTypeUpdateOneWithoutAdBoardsNestedInput"];
	["AdBoardTypeUpsertWithoutAdBoardsInput"]: ValueTypes["AdBoardTypeUpsertWithoutAdBoardsInput"];
	["AdBoardTypeUpdateWithoutAdBoardsInput"]: ValueTypes["AdBoardTypeUpdateWithoutAdBoardsInput"];
	["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"]: ValueTypes["AdBoardTypeUpdateToOneWithWhereWithoutAdBoardsInput"];
	["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"]: ValueTypes["BusinessCategoryUpdateOneWithoutAdBoardsNestedInput"];
	["BusinessCategoryUpsertWithoutAdBoardsInput"]: ValueTypes["BusinessCategoryUpsertWithoutAdBoardsInput"];
	["BusinessCategoryUpdateWithoutAdBoardsInput"]: ValueTypes["BusinessCategoryUpdateWithoutAdBoardsInput"];
	["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutRestrictedBusinesNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutRestrictedBusinesInput"];
	["AdBoardUpdateWithoutRestrictedBusinesInput"]: ValueTypes["AdBoardUpdateWithoutRestrictedBusinesInput"];
	["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"]: ValueTypes["LocationSubCategoryUpdateOneWithoutAdBoardsNestedInput"];
	["LocationSubCategoryUpsertWithoutAdBoardsInput"]: ValueTypes["LocationSubCategoryUpsertWithoutAdBoardsInput"];
	["LocationSubCategoryUpdateWithoutAdBoardsInput"]: ValueTypes["LocationSubCategoryUpdateWithoutAdBoardsInput"];
	["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"]: ValueTypes["LocationCategoryUpdateOneWithoutLocationSubCategoryNestedInput"];
	["LocationCategoryUpsertWithoutLocationSubCategoryInput"]: ValueTypes["LocationCategoryUpsertWithoutLocationSubCategoryInput"];
	["LocationCategoryUpdateWithoutLocationSubCategoryInput"]: ValueTypes["LocationCategoryUpdateWithoutLocationSubCategoryInput"];
	["AdBoardUpdateManyWithoutLocationCategoryNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutLocationCategoryNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutLocationCategoryInput"];
	["AdBoardUpdateWithoutLocationCategoryInput"]: ValueTypes["AdBoardUpdateWithoutLocationCategoryInput"];
	["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"]: ValueTypes["BusinessCategoryUpdateManyWithoutRestrictedByAdBoardsNestedInput"];
	["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: ValueTypes["BusinessCategoryUpsertWithWhereUniqueWithoutRestrictedByAdBoardsInput"];
	["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"]: ValueTypes["BusinessCategoryUpdateWithoutRestrictedByAdBoardsInput"];
	["AdBoardUpdateManyWithoutCategoryNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutCategoryNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutCategoryInput"];
	["AdBoardUpdateWithoutCategoryInput"]: ValueTypes["AdBoardUpdateWithoutCategoryInput"];
	["BookingUpdateManyWithoutAdBoardNestedInput"]: ValueTypes["BookingUpdateManyWithoutAdBoardNestedInput"];
	["BookingUpsertWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["BookingUpsertWithWhereUniqueWithoutAdBoardInput"];
	["BookingUpdateWithoutAdBoardInput"]: ValueTypes["BookingUpdateWithoutAdBoardInput"];
	["FileUpdateManyWithoutBookingsNestedInput"]: ValueTypes["FileUpdateManyWithoutBookingsNestedInput"];
	["FileUpsertWithWhereUniqueWithoutBookingsInput"]: ValueTypes["FileUpsertWithWhereUniqueWithoutBookingsInput"];
	["FileUpdateWithoutBookingsInput"]: ValueTypes["FileUpdateWithoutBookingsInput"];
	["UserUpdateOneWithoutUpdatedFilesNestedInput"]: ValueTypes["UserUpdateOneWithoutUpdatedFilesNestedInput"];
	["UserUpsertWithoutUpdatedFilesInput"]: ValueTypes["UserUpsertWithoutUpdatedFilesInput"];
	["UserUpdateWithoutUpdatedFilesInput"]: ValueTypes["UserUpdateWithoutUpdatedFilesInput"];
	["BookingUpdateManyWithoutUserNestedInput"]: ValueTypes["BookingUpdateManyWithoutUserNestedInput"];
	["BookingUpsertWithWhereUniqueWithoutUserInput"]: ValueTypes["BookingUpsertWithWhereUniqueWithoutUserInput"];
	["BookingUpdateWithoutUserInput"]: ValueTypes["BookingUpdateWithoutUserInput"];
	["BusinessCategoryUpdateOneWithoutBookingsNestedInput"]: ValueTypes["BusinessCategoryUpdateOneWithoutBookingsNestedInput"];
	["BusinessCategoryUpsertWithoutBookingsInput"]: ValueTypes["BusinessCategoryUpsertWithoutBookingsInput"];
	["BusinessCategoryUpdateWithoutBookingsInput"]: ValueTypes["BusinessCategoryUpdateWithoutBookingsInput"];
	["CampaignUpdateManyWithoutBusinessCategoryNestedInput"]: ValueTypes["CampaignUpdateManyWithoutBusinessCategoryNestedInput"];
	["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: ValueTypes["CampaignUpsertWithWhereUniqueWithoutBusinessCategoryInput"];
	["CampaignUpdateWithoutBusinessCategoryInput"]: ValueTypes["CampaignUpdateWithoutBusinessCategoryInput"];
	["UserUpdateOneRequiredWithoutCampaignsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutCampaignsNestedInput"];
	["UserUpsertWithoutCampaignsInput"]: ValueTypes["UserUpsertWithoutCampaignsInput"];
	["UserUpdateWithoutCampaignsInput"]: ValueTypes["UserUpdateWithoutCampaignsInput"];
	["FileUpdateManyWithoutCreatedByNestedInput"]: ValueTypes["FileUpdateManyWithoutCreatedByNestedInput"];
	["FileUpsertWithWhereUniqueWithoutCreatedByInput"]: ValueTypes["FileUpsertWithWhereUniqueWithoutCreatedByInput"];
	["FileUpdateWithoutCreatedByInput"]: ValueTypes["FileUpdateWithoutCreatedByInput"];
	["BookingUpdateManyWithoutAssetsNestedInput"]: ValueTypes["BookingUpdateManyWithoutAssetsNestedInput"];
	["BookingUpsertWithWhereUniqueWithoutAssetsInput"]: ValueTypes["BookingUpsertWithWhereUniqueWithoutAssetsInput"];
	["BookingUpdateWithoutAssetsInput"]: ValueTypes["BookingUpdateWithoutAssetsInput"];
	["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"]: ValueTypes["AdvertisementCategoryUpdateManyWithoutBookingsNestedInput"];
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"]: ValueTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutBookingsInput"];
	["AdvertisementCategoryUpdateWithoutBookingsInput"]: ValueTypes["AdvertisementCategoryUpdateWithoutBookingsInput"];
	["CampaignUpdateManyWithoutAdCategoryNestedInput"]: ValueTypes["CampaignUpdateManyWithoutAdCategoryNestedInput"];
	["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"]: ValueTypes["CampaignUpsertWithWhereUniqueWithoutAdCategoryInput"];
	["CampaignUpdateWithoutAdCategoryInput"]: ValueTypes["CampaignUpdateWithoutAdCategoryInput"];
	["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"]: ValueTypes["BusinessCategoryUpdateOneRequiredWithoutCampaignsNestedInput"];
	["BusinessCategoryUpsertWithoutCampaignsInput"]: ValueTypes["BusinessCategoryUpsertWithoutCampaignsInput"];
	["BusinessCategoryUpdateWithoutCampaignsInput"]: ValueTypes["BusinessCategoryUpdateWithoutCampaignsInput"];
	["BookingUpdateManyWithoutBusinessCategoryNestedInput"]: ValueTypes["BookingUpdateManyWithoutBusinessCategoryNestedInput"];
	["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"]: ValueTypes["BookingUpsertWithWhereUniqueWithoutBusinessCategoryInput"];
	["BookingUpdateWithoutBusinessCategoryInput"]: ValueTypes["BookingUpdateWithoutBusinessCategoryInput"];
	["FileUpdateManyWithoutProofBookingNestedInput"]: ValueTypes["FileUpdateManyWithoutProofBookingNestedInput"];
	["FileUpsertWithWhereUniqueWithoutProofBookingInput"]: ValueTypes["FileUpsertWithWhereUniqueWithoutProofBookingInput"];
	["FileUpdateWithoutProofBookingInput"]: ValueTypes["FileUpdateWithoutProofBookingInput"];
	["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutCreatedFilesNestedInput"];
	["UserUpsertWithoutCreatedFilesInput"]: ValueTypes["UserUpsertWithoutCreatedFilesInput"];
	["UserUpdateWithoutCreatedFilesInput"]: ValueTypes["UserUpdateWithoutCreatedFilesInput"];
	["FileUpdateManyWithoutUpdatedByNestedInput"]: ValueTypes["FileUpdateManyWithoutUpdatedByNestedInput"];
	["FileUpsertWithWhereUniqueWithoutUpdatedByInput"]: ValueTypes["FileUpsertWithWhereUniqueWithoutUpdatedByInput"];
	["FileUpdateWithoutUpdatedByInput"]: ValueTypes["FileUpdateWithoutUpdatedByInput"];
	["WorkspaceUpdateOneWithoutFilesNestedInput"]: ValueTypes["WorkspaceUpdateOneWithoutFilesNestedInput"];
	["WorkspaceUpsertWithoutFilesInput"]: ValueTypes["WorkspaceUpsertWithoutFilesInput"];
	["WorkspaceUpdateWithoutFilesInput"]: ValueTypes["WorkspaceUpdateWithoutFilesInput"];
	["UserUpdateOneWithoutOwnedWorkspacesNestedInput"]: ValueTypes["UserUpdateOneWithoutOwnedWorkspacesNestedInput"];
	["UserUpsertWithoutOwnedWorkspacesInput"]: ValueTypes["UserUpsertWithoutOwnedWorkspacesInput"];
	["UserUpdateWithoutOwnedWorkspacesInput"]: ValueTypes["UserUpdateWithoutOwnedWorkspacesInput"];
	["OrderUpdateManyWithoutCreatedByNestedInput"]: ValueTypes["OrderUpdateManyWithoutCreatedByNestedInput"];
	["OrderUpsertWithWhereUniqueWithoutCreatedByInput"]: ValueTypes["OrderUpsertWithWhereUniqueWithoutCreatedByInput"];
	["OrderUpdateWithoutCreatedByInput"]: ValueTypes["OrderUpdateWithoutCreatedByInput"];
	["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"]: ValueTypes["AdBoardUpdateOneRequiredWithoutOrdersNestedInput"];
	["AdBoardUpsertWithoutOrdersInput"]: ValueTypes["AdBoardUpsertWithoutOrdersInput"];
	["AdBoardUpdateWithoutOrdersInput"]: ValueTypes["AdBoardUpdateWithoutOrdersInput"];
	["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"]: ValueTypes["SlotConfigurationUpdateManyWithoutAdBoardNestedInput"];
	["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["SlotConfigurationUpsertWithWhereUniqueWithoutAdBoardInput"];
	["SlotConfigurationUpdateWithoutAdBoardInput"]: ValueTypes["SlotConfigurationUpdateWithoutAdBoardInput"];
	["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["SlotConfigurationUpdateWithWhereUniqueWithoutAdBoardInput"];
	["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"]: ValueTypes["SlotConfigurationUpdateManyWithWhereWithoutAdBoardInput"];
	["SlotConfigurationScalarWhereInput"]: ValueTypes["SlotConfigurationScalarWhereInput"];
	["SlotConfigurationUpdateManyMutationInput"]: ValueTypes["SlotConfigurationUpdateManyMutationInput"];
	["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"]: ValueTypes["UserUpdateOneWithoutOfflineBookingAdboardsNestedInput"];
	["UserUpsertWithoutOfflineBookingAdboardsInput"]: ValueTypes["UserUpsertWithoutOfflineBookingAdboardsInput"];
	["UserUpdateWithoutOfflineBookingAdboardsInput"]: ValueTypes["UserUpdateWithoutOfflineBookingAdboardsInput"];
	["ChangelogUpdateManyWithoutUserNestedInput"]: ValueTypes["ChangelogUpdateManyWithoutUserNestedInput"];
	["ChangelogUpsertWithWhereUniqueWithoutUserInput"]: ValueTypes["ChangelogUpsertWithWhereUniqueWithoutUserInput"];
	["ChangelogUpdateWithoutUserInput"]: ValueTypes["ChangelogUpdateWithoutUserInput"];
	["BookingUpdateOneWithoutChangelogsNestedInput"]: ValueTypes["BookingUpdateOneWithoutChangelogsNestedInput"];
	["BookingUpsertWithoutChangelogsInput"]: ValueTypes["BookingUpsertWithoutChangelogsInput"];
	["BookingUpdateWithoutChangelogsInput"]: ValueTypes["BookingUpdateWithoutChangelogsInput"];
	["UserUpdateOneRequiredWithoutBookingsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutBookingsNestedInput"];
	["UserUpsertWithoutBookingsInput"]: ValueTypes["UserUpsertWithoutBookingsInput"];
	["UserUpdateWithoutBookingsInput"]: ValueTypes["UserUpdateWithoutBookingsInput"];
	["AdBoardUpdateManyWithoutCreatedByNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutCreatedByNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutCreatedByInput"];
	["AdBoardUpdateWithoutCreatedByInput"]: ValueTypes["AdBoardUpdateWithoutCreatedByInput"];
	["OrderUpdateManyWithoutAdBoardNestedInput"]: ValueTypes["OrderUpdateManyWithoutAdBoardNestedInput"];
	["OrderUpsertWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["OrderUpsertWithWhereUniqueWithoutAdBoardInput"];
	["OrderUpdateWithoutAdBoardInput"]: ValueTypes["OrderUpdateWithoutAdBoardInput"];
	["UserUpdateOneWithoutOrdersNestedInput"]: ValueTypes["UserUpdateOneWithoutOrdersNestedInput"];
	["UserUpsertWithoutOrdersInput"]: ValueTypes["UserUpsertWithoutOrdersInput"];
	["UserUpdateWithoutOrdersInput"]: ValueTypes["UserUpdateWithoutOrdersInput"];
	["AuditUpdateManyWithoutUserNestedInput"]: ValueTypes["AuditUpdateManyWithoutUserNestedInput"];
	["AuditUpsertWithWhereUniqueWithoutUserInput"]: ValueTypes["AuditUpsertWithWhereUniqueWithoutUserInput"];
	["AuditUpdateWithoutUserInput"]: ValueTypes["AuditUpdateWithoutUserInput"];
	["WorkspaceUpdateOneWithoutAuditsNestedInput"]: ValueTypes["WorkspaceUpdateOneWithoutAuditsNestedInput"];
	["WorkspaceUpsertWithoutAuditsInput"]: ValueTypes["WorkspaceUpsertWithoutAuditsInput"];
	["WorkspaceUpdateWithoutAuditsInput"]: ValueTypes["WorkspaceUpdateWithoutAuditsInput"];
	["MembershipUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["MembershipUpdateManyWithoutWorkspaceNestedInput"];
	["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["MembershipUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["MembershipUpdateWithoutWorkspaceInput"]: ValueTypes["MembershipUpdateWithoutWorkspaceInput"];
	["UserUpdateOneRequiredWithoutMembershipsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutMembershipsNestedInput"];
	["UserUpsertWithoutMembershipsInput"]: ValueTypes["UserUpsertWithoutMembershipsInput"];
	["UserUpdateWithoutMembershipsInput"]: ValueTypes["UserUpdateWithoutMembershipsInput"];
	["CampaignUpdateManyWithoutUserNestedInput"]: ValueTypes["CampaignUpdateManyWithoutUserNestedInput"];
	["CampaignUpsertWithWhereUniqueWithoutUserInput"]: ValueTypes["CampaignUpsertWithWhereUniqueWithoutUserInput"];
	["CampaignUpdateWithoutUserInput"]: ValueTypes["CampaignUpdateWithoutUserInput"];
	["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"]: ValueTypes["AdvertisementCategoryUpdateManyWithoutCampaignsNestedInput"];
	["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"]: ValueTypes["AdvertisementCategoryUpsertWithWhereUniqueWithoutCampaignsInput"];
	["AdvertisementCategoryUpdateWithoutCampaignsInput"]: ValueTypes["AdvertisementCategoryUpdateWithoutCampaignsInput"];
	["BookingUpdateManyWithoutAdCategoryNestedInput"]: ValueTypes["BookingUpdateManyWithoutAdCategoryNestedInput"];
	["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"]: ValueTypes["BookingUpsertWithWhereUniqueWithoutAdCategoryInput"];
	["BookingUpdateWithoutAdCategoryInput"]: ValueTypes["BookingUpdateWithoutAdCategoryInput"];
	["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"]: ValueTypes["AdBoardUpdateOneRequiredWithoutBookingsNestedInput"];
	["AdBoardUpsertWithoutBookingsInput"]: ValueTypes["AdBoardUpsertWithoutBookingsInput"];
	["AdBoardUpdateWithoutBookingsInput"]: ValueTypes["AdBoardUpdateWithoutBookingsInput"];
	["UserUpdateOneWithoutAdBoardsNestedInput"]: ValueTypes["UserUpdateOneWithoutAdBoardsNestedInput"];
	["UserUpsertWithoutAdBoardsInput"]: ValueTypes["UserUpsertWithoutAdBoardsInput"];
	["UserUpdateWithoutAdBoardsInput"]: ValueTypes["UserUpdateWithoutAdBoardsInput"];
	["BookingUpdateManyWithoutSharedUsersNestedInput"]: ValueTypes["BookingUpdateManyWithoutSharedUsersNestedInput"];
	["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"]: ValueTypes["BookingUpsertWithWhereUniqueWithoutSharedUsersInput"];
	["BookingUpdateWithoutSharedUsersInput"]: ValueTypes["BookingUpdateWithoutSharedUsersInput"];
	["ChangelogUpdateManyWithoutBookingNestedInput"]: ValueTypes["ChangelogUpdateManyWithoutBookingNestedInput"];
	["ChangelogUpsertWithWhereUniqueWithoutBookingInput"]: ValueTypes["ChangelogUpsertWithWhereUniqueWithoutBookingInput"];
	["ChangelogUpdateWithoutBookingInput"]: ValueTypes["ChangelogUpdateWithoutBookingInput"];
	["UserUpdateOneRequiredWithoutChangelogsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutChangelogsNestedInput"];
	["UserUpsertWithoutChangelogsInput"]: ValueTypes["UserUpsertWithoutChangelogsInput"];
	["UserUpdateWithoutChangelogsInput"]: ValueTypes["UserUpdateWithoutChangelogsInput"];
	["MembershipUpdateManyWithoutUserNestedInput"]: ValueTypes["MembershipUpdateManyWithoutUserNestedInput"];
	["MembershipUpsertWithWhereUniqueWithoutUserInput"]: ValueTypes["MembershipUpsertWithWhereUniqueWithoutUserInput"];
	["MembershipUpdateWithoutUserInput"]: ValueTypes["MembershipUpdateWithoutUserInput"];
	["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"]: ValueTypes["WorkspaceUpdateOneRequiredWithoutMembershipsNestedInput"];
	["WorkspaceUpsertWithoutMembershipsInput"]: ValueTypes["WorkspaceUpsertWithoutMembershipsInput"];
	["WorkspaceUpdateWithoutMembershipsInput"]: ValueTypes["WorkspaceUpdateWithoutMembershipsInput"];
	["AdBoardUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutWorkspaceNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["AdBoardUpdateWithoutWorkspaceInput"]: ValueTypes["AdBoardUpdateWithoutWorkspaceInput"];
	["PlayerUpdateManyWithoutAdBoardsNestedInput"]: ValueTypes["PlayerUpdateManyWithoutAdBoardsNestedInput"];
	["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"]: ValueTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardsInput"];
	["PlayerUpdateWithoutAdBoardsInput"]: ValueTypes["PlayerUpdateWithoutAdBoardsInput"];
	["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"]: ValueTypes["AdBoardGroupUpdateOneWithoutPisignagePlayersNestedInput"];
	["AdBoardGroupUpsertWithoutPisignagePlayersInput"]: ValueTypes["AdBoardGroupUpsertWithoutPisignagePlayersInput"];
	["AdBoardGroupUpdateWithoutPisignagePlayersInput"]: ValueTypes["AdBoardGroupUpdateWithoutPisignagePlayersInput"];
	["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"]: ValueTypes["AdBoardUpdateOneWithoutAdBoardGroupNestedInput"];
	["AdBoardUpsertWithoutAdBoardGroupInput"]: ValueTypes["AdBoardUpsertWithoutAdBoardGroupInput"];
	["AdBoardUpdateWithoutAdBoardGroupInput"]: ValueTypes["AdBoardUpdateWithoutAdBoardGroupInput"];
	["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"]: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardsNestedInput"];
	["WorkspaceUpsertWithoutAdBoardsInput"]: ValueTypes["WorkspaceUpsertWithoutAdBoardsInput"];
	["WorkspaceUpdateWithoutAdBoardsInput"]: ValueTypes["WorkspaceUpdateWithoutAdBoardsInput"];
	["PlaylistUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["PlaylistUpdateManyWithoutWorkspaceNestedInput"];
	["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["PlaylistUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["PlaylistUpdateWithoutWorkspaceInput"]: ValueTypes["PlaylistUpdateWithoutWorkspaceInput"];
	["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"]: ValueTypes["PlaylistUpdateOneWithoutFooterParentPlaylistsNestedInput"];
	["PlaylistUpsertWithoutFooterParentPlaylistsInput"]: ValueTypes["PlaylistUpsertWithoutFooterParentPlaylistsInput"];
	["PlaylistUpdateWithoutFooterParentPlaylistsInput"]: ValueTypes["PlaylistUpdateWithoutFooterParentPlaylistsInput"];
	["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"]: ValueTypes["PlaylistUpdateOneWithoutSidebarParentPlaylistsNestedInput"];
	["PlaylistUpsertWithoutSidebarParentPlaylistsInput"]: ValueTypes["PlaylistUpsertWithoutSidebarParentPlaylistsInput"];
	["PlaylistUpdateWithoutSidebarParentPlaylistsInput"]: ValueTypes["PlaylistUpdateWithoutSidebarParentPlaylistsInput"];
	["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"]: ValueTypes["AdBoardGroupUpdateManyWithoutAdsPlaylistNestedInput"];
	["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"]: ValueTypes["AdBoardGroupUpsertWithWhereUniqueWithoutAdsPlaylistInput"];
	["AdBoardGroupUpdateWithoutAdsPlaylistInput"]: ValueTypes["AdBoardGroupUpdateWithoutAdsPlaylistInput"];
	["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"]: ValueTypes["PlaylistUpdateManyWithoutAdBoardGroupsNestedInput"];
	["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"]: ValueTypes["PlaylistUpsertWithWhereUniqueWithoutAdBoardGroupsInput"];
	["PlaylistUpdateWithoutAdBoardGroupsInput"]: ValueTypes["PlaylistUpdateWithoutAdBoardGroupsInput"];
	["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"]: ValueTypes["WorkspaceUpdateOneRequiredWithoutPlaylistsNestedInput"];
	["WorkspaceUpsertWithoutPlaylistsInput"]: ValueTypes["WorkspaceUpsertWithoutPlaylistsInput"];
	["WorkspaceUpdateWithoutPlaylistsInput"]: ValueTypes["WorkspaceUpdateWithoutPlaylistsInput"];
	["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["AdBoardGroupUpdateManyWithoutWorkspaceNestedInput"];
	["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["AdBoardGroupUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["AdBoardGroupUpdateWithoutWorkspaceInput"]: ValueTypes["AdBoardGroupUpdateWithoutWorkspaceInput"];
	["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"]: ValueTypes["PlaylistUpdateOneWithoutAdBoardGroupsForAdsNestedInput"];
	["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"]: ValueTypes["PlaylistUpsertWithoutAdBoardGroupsForAdsInput"];
	["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"]: ValueTypes["PlaylistUpdateWithoutAdBoardGroupsForAdsInput"];
	["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"]: ValueTypes["AdBoardGroupUpdateManyWithoutScheduledPlaylistsNestedInput"];
	["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"]: ValueTypes["AdBoardGroupUpsertWithWhereUniqueWithoutScheduledPlaylistsInput"];
	["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"]: ValueTypes["AdBoardGroupUpdateWithoutScheduledPlaylistsInput"];
	["PlayerUpdateManyWithoutAdBoardGroupNestedInput"]: ValueTypes["PlayerUpdateManyWithoutAdBoardGroupNestedInput"];
	["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"]: ValueTypes["PlayerUpsertWithWhereUniqueWithoutAdBoardGroupInput"];
	["PlayerUpdateWithoutAdBoardGroupInput"]: ValueTypes["PlayerUpdateWithoutAdBoardGroupInput"];
	["WorkspaceUpdateOneWithoutPlayersNestedInput"]: ValueTypes["WorkspaceUpdateOneWithoutPlayersNestedInput"];
	["WorkspaceUpsertWithoutPlayersInput"]: ValueTypes["WorkspaceUpsertWithoutPlayersInput"];
	["WorkspaceUpdateWithoutPlayersInput"]: ValueTypes["WorkspaceUpdateWithoutPlayersInput"];
	["AuditUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["AuditUpdateManyWithoutWorkspaceNestedInput"];
	["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["AuditUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["AuditUpdateWithoutWorkspaceInput"]: ValueTypes["AuditUpdateWithoutWorkspaceInput"];
	["UserUpdateOneRequiredWithoutAuditsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutAuditsNestedInput"];
	["UserUpsertWithoutAuditsInput"]: ValueTypes["UserUpsertWithoutAuditsInput"];
	["UserUpdateWithoutAuditsInput"]: ValueTypes["UserUpdateWithoutAuditsInput"];
	["NotificationUpdateManyWithoutRecipientNestedInput"]: ValueTypes["NotificationUpdateManyWithoutRecipientNestedInput"];
	["NotificationUpsertWithWhereUniqueWithoutRecipientInput"]: ValueTypes["NotificationUpsertWithWhereUniqueWithoutRecipientInput"];
	["NotificationUpdateWithoutRecipientInput"]: ValueTypes["NotificationUpdateWithoutRecipientInput"];
	["NotificationUpdateWithWhereUniqueWithoutRecipientInput"]: ValueTypes["NotificationUpdateWithWhereUniqueWithoutRecipientInput"];
	["NotificationUpdateManyWithWhereWithoutRecipientInput"]: ValueTypes["NotificationUpdateManyWithWhereWithoutRecipientInput"];
	["NotificationScalarWhereInput"]: ValueTypes["NotificationScalarWhereInput"];
	["NotificationUpdateManyMutationInput"]: ValueTypes["NotificationUpdateManyMutationInput"];
	["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"]: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutPublishedByNestedInput"];
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"]: ValueTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutPublishedByInput"];
	["AdBoardPublishHistoryUpdateWithoutPublishedByInput"]: ValueTypes["AdBoardPublishHistoryUpdateWithoutPublishedByInput"];
	["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"]: ValueTypes["AdBoardUpdateOneRequiredWithoutHistoryNestedInput"];
	["AdBoardUpsertWithoutHistoryInput"]: ValueTypes["AdBoardUpsertWithoutHistoryInput"];
	["AdBoardUpdateWithoutHistoryInput"]: ValueTypes["AdBoardUpdateWithoutHistoryInput"];
	["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"]: ValueTypes["AdBoardGroupUpdateOneWithoutAdBoardNestedInput"];
	["AdBoardGroupUpsertWithoutAdBoardInput"]: ValueTypes["AdBoardGroupUpsertWithoutAdBoardInput"];
	["AdBoardGroupUpdateWithoutAdBoardInput"]: ValueTypes["AdBoardGroupUpdateWithoutAdBoardInput"];
	["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"]: ValueTypes["WorkspaceUpdateOneRequiredWithoutAdBoardGroupsNestedInput"];
	["WorkspaceUpsertWithoutAdBoardGroupsInput"]: ValueTypes["WorkspaceUpsertWithoutAdBoardGroupsInput"];
	["WorkspaceUpdateWithoutAdBoardGroupsInput"]: ValueTypes["WorkspaceUpdateWithoutAdBoardGroupsInput"];
	["BookingUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["BookingUpdateManyWithoutWorkspaceNestedInput"];
	["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["BookingUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["BookingUpdateWithoutWorkspaceInput"]: ValueTypes["BookingUpdateWithoutWorkspaceInput"];
	["CampaignUpdateOneWithoutBookingsNestedInput"]: ValueTypes["CampaignUpdateOneWithoutBookingsNestedInput"];
	["CampaignUpsertWithoutBookingsInput"]: ValueTypes["CampaignUpsertWithoutBookingsInput"];
	["CampaignUpdateWithoutBookingsInput"]: ValueTypes["CampaignUpdateWithoutBookingsInput"];
	["PaymentUpdateManyWithoutCampaignNestedInput"]: ValueTypes["PaymentUpdateManyWithoutCampaignNestedInput"];
	["PaymentUpsertWithWhereUniqueWithoutCampaignInput"]: ValueTypes["PaymentUpsertWithWhereUniqueWithoutCampaignInput"];
	["PaymentUpdateWithoutCampaignInput"]: ValueTypes["PaymentUpdateWithoutCampaignInput"];
	["PaymentUpdateWithWhereUniqueWithoutCampaignInput"]: ValueTypes["PaymentUpdateWithWhereUniqueWithoutCampaignInput"];
	["PaymentUpdateManyWithWhereWithoutCampaignInput"]: ValueTypes["PaymentUpdateManyWithWhereWithoutCampaignInput"];
	["PaymentScalarWhereInput"]: ValueTypes["PaymentScalarWhereInput"];
	["PaymentUpdateManyMutationInput"]: ValueTypes["PaymentUpdateManyMutationInput"];
	["FileUpdateManyWithoutCampaignsNestedInput"]: ValueTypes["FileUpdateManyWithoutCampaignsNestedInput"];
	["FileUpsertWithWhereUniqueWithoutCampaignsInput"]: ValueTypes["FileUpsertWithWhereUniqueWithoutCampaignsInput"];
	["FileUpdateWithoutCampaignsInput"]: ValueTypes["FileUpdateWithoutCampaignsInput"];
	["BookingUpdateOneWithoutProofsNestedInput"]: ValueTypes["BookingUpdateOneWithoutProofsNestedInput"];
	["BookingUpsertWithoutProofsInput"]: ValueTypes["BookingUpsertWithoutProofsInput"];
	["BookingUpdateWithoutProofsInput"]: ValueTypes["BookingUpdateWithoutProofsInput"];
	["WorkspaceUpdateOneWithoutBookingsNestedInput"]: ValueTypes["WorkspaceUpdateOneWithoutBookingsNestedInput"];
	["WorkspaceUpsertWithoutBookingsInput"]: ValueTypes["WorkspaceUpsertWithoutBookingsInput"];
	["WorkspaceUpdateWithoutBookingsInput"]: ValueTypes["WorkspaceUpdateWithoutBookingsInput"];
	["FileUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["FileUpdateManyWithoutWorkspaceNestedInput"];
	["FileUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["FileUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["FileUpdateWithoutWorkspaceInput"]: ValueTypes["FileUpdateWithoutWorkspaceInput"];
	["CampaignUpdateManyWithoutAssetsNestedInput"]: ValueTypes["CampaignUpdateManyWithoutAssetsNestedInput"];
	["CampaignUpsertWithWhereUniqueWithoutAssetsInput"]: ValueTypes["CampaignUpsertWithWhereUniqueWithoutAssetsInput"];
	["CampaignUpdateWithoutAssetsInput"]: ValueTypes["CampaignUpdateWithoutAssetsInput"];
	["BookingUpdateManyWithoutCampaignNestedInput"]: ValueTypes["BookingUpdateManyWithoutCampaignNestedInput"];
	["BookingUpsertWithWhereUniqueWithoutCampaignInput"]: ValueTypes["BookingUpsertWithWhereUniqueWithoutCampaignInput"];
	["BookingUpdateWithoutCampaignInput"]: ValueTypes["BookingUpdateWithoutCampaignInput"];
	["PayoutUpdateManyWithoutBookingNestedInput"]: ValueTypes["PayoutUpdateManyWithoutBookingNestedInput"];
	["PayoutUpsertWithWhereUniqueWithoutBookingInput"]: ValueTypes["PayoutUpsertWithWhereUniqueWithoutBookingInput"];
	["PayoutUpdateWithoutBookingInput"]: ValueTypes["PayoutUpdateWithoutBookingInput"];
	["TransactionUpdateOneWithoutPayoutsNestedInput"]: ValueTypes["TransactionUpdateOneWithoutPayoutsNestedInput"];
	["TransactionUpsertWithoutPayoutsInput"]: ValueTypes["TransactionUpsertWithoutPayoutsInput"];
	["TransactionUpdateWithoutPayoutsInput"]: ValueTypes["TransactionUpdateWithoutPayoutsInput"];
	["WorkspaceUpdateOneWithoutTransactionsNestedInput"]: ValueTypes["WorkspaceUpdateOneWithoutTransactionsNestedInput"];
	["WorkspaceUpsertWithoutTransactionsInput"]: ValueTypes["WorkspaceUpsertWithoutTransactionsInput"];
	["WorkspaceUpdateWithoutTransactionsInput"]: ValueTypes["WorkspaceUpdateWithoutTransactionsInput"];
	["PlayerUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["PlayerUpdateManyWithoutWorkspaceNestedInput"];
	["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["PlayerUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["PlayerUpdateWithoutWorkspaceInput"]: ValueTypes["PlayerUpdateWithoutWorkspaceInput"];
	["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"]: ValueTypes["PlayerUptimeStatusUpdateManyWithoutPlayerNestedInput"];
	["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"]: ValueTypes["PlayerUptimeStatusUpsertWithWhereUniqueWithoutPlayerInput"];
	["PlayerUptimeStatusUpdateWithoutPlayerInput"]: ValueTypes["PlayerUptimeStatusUpdateWithoutPlayerInput"];
	["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"]: ValueTypes["PlayerUptimeStatusUpdateWithWhereUniqueWithoutPlayerInput"];
	["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"]: ValueTypes["PlayerUptimeStatusUpdateManyWithWhereWithoutPlayerInput"];
	["PlayerUptimeStatusScalarWhereInput"]: ValueTypes["PlayerUptimeStatusScalarWhereInput"];
	["PlayerUptimeStatusUpdateManyMutationInput"]: ValueTypes["PlayerUptimeStatusUpdateManyMutationInput"];
	["CameraUpdateManyWithoutPlayerNestedInput"]: ValueTypes["CameraUpdateManyWithoutPlayerNestedInput"];
	["CameraUpsertWithWhereUniqueWithoutPlayerInput"]: ValueTypes["CameraUpsertWithWhereUniqueWithoutPlayerInput"];
	["CameraUpdateWithoutPlayerInput"]: ValueTypes["CameraUpdateWithoutPlayerInput"];
	["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"]: ValueTypes["CameraServerUpdateOneRequiredWithoutCamerasNestedInput"];
	["CameraServerUpsertWithoutCamerasInput"]: ValueTypes["CameraServerUpsertWithoutCamerasInput"];
	["CameraServerUpdateWithoutCamerasInput"]: ValueTypes["CameraServerUpdateWithoutCamerasInput"];
	["WorkspaceUpdateOneWithoutCameraServersNestedInput"]: ValueTypes["WorkspaceUpdateOneWithoutCameraServersNestedInput"];
	["WorkspaceUpsertWithoutCameraServersInput"]: ValueTypes["WorkspaceUpsertWithoutCameraServersInput"];
	["WorkspaceUpdateWithoutCameraServersInput"]: ValueTypes["WorkspaceUpdateWithoutCameraServersInput"];
	["TransactionUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["TransactionUpdateManyWithoutWorkspaceNestedInput"];
	["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["TransactionUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["TransactionUpdateWithoutWorkspaceInput"]: ValueTypes["TransactionUpdateWithoutWorkspaceInput"];
	["PayoutUpdateManyWithoutTransactionNestedInput"]: ValueTypes["PayoutUpdateManyWithoutTransactionNestedInput"];
	["PayoutUpsertWithWhereUniqueWithoutTransactionInput"]: ValueTypes["PayoutUpsertWithWhereUniqueWithoutTransactionInput"];
	["PayoutUpdateWithoutTransactionInput"]: ValueTypes["PayoutUpdateWithoutTransactionInput"];
	["BookingUpdateOneRequiredWithoutPayoutsNestedInput"]: ValueTypes["BookingUpdateOneRequiredWithoutPayoutsNestedInput"];
	["BookingUpsertWithoutPayoutsInput"]: ValueTypes["BookingUpsertWithoutPayoutsInput"];
	["BookingUpdateWithoutPayoutsInput"]: ValueTypes["BookingUpdateWithoutPayoutsInput"];
	["UserUpdateManyWithoutSharedBookingsNestedInput"]: ValueTypes["UserUpdateManyWithoutSharedBookingsNestedInput"];
	["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"]: ValueTypes["UserUpsertWithWhereUniqueWithoutSharedBookingsInput"];
	["UserUpdateWithoutSharedBookingsInput"]: ValueTypes["UserUpdateWithoutSharedBookingsInput"];
	["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutOfflineBookingsContactNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutOfflineBookingsContactInput"];
	["AdBoardUpdateWithoutOfflineBookingsContactInput"]: ValueTypes["AdBoardUpdateWithoutOfflineBookingsContactInput"];
	["CameraUpdateManyWithoutAdBoardNestedInput"]: ValueTypes["CameraUpdateManyWithoutAdBoardNestedInput"];
	["CameraUpsertWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["CameraUpsertWithWhereUniqueWithoutAdBoardInput"];
	["CameraUpdateWithoutAdBoardInput"]: ValueTypes["CameraUpdateWithoutAdBoardInput"];
	["PlayerUpdateOneWithoutCamerasNestedInput"]: ValueTypes["PlayerUpdateOneWithoutCamerasNestedInput"];
	["PlayerUpsertWithoutCamerasInput"]: ValueTypes["PlayerUpsertWithoutCamerasInput"];
	["PlayerUpdateWithoutCamerasInput"]: ValueTypes["PlayerUpdateWithoutCamerasInput"];
	["DigitalSignageUpdateOneWithoutPlayersNestedInput"]: ValueTypes["DigitalSignageUpdateOneWithoutPlayersNestedInput"];
	["DigitalSignageUpsertWithoutPlayersInput"]: ValueTypes["DigitalSignageUpsertWithoutPlayersInput"];
	["DigitalSignageUpdateWithoutPlayersInput"]: ValueTypes["DigitalSignageUpdateWithoutPlayersInput"];
	["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"]: ValueTypes["DigitalSignageUpdateToOneWithWhereWithoutPlayersInput"];
	["AudienceUpdateManyWithoutPlayersNestedInput"]: ValueTypes["AudienceUpdateManyWithoutPlayersNestedInput"];
	["AudienceUpsertWithWhereUniqueWithoutPlayersInput"]: ValueTypes["AudienceUpsertWithWhereUniqueWithoutPlayersInput"];
	["AudienceUpdateWithoutPlayersInput"]: ValueTypes["AudienceUpdateWithoutPlayersInput"];
	["AudienceUpdateWithWhereUniqueWithoutPlayersInput"]: ValueTypes["AudienceUpdateWithWhereUniqueWithoutPlayersInput"];
	["AudienceUpdateManyWithWhereWithoutPlayersInput"]: ValueTypes["AudienceUpdateManyWithWhereWithoutPlayersInput"];
	["AudienceScalarWhereInput"]: ValueTypes["AudienceScalarWhereInput"];
	["AudienceUpdateManyMutationInput"]: ValueTypes["AudienceUpdateManyMutationInput"];
	["AdBoardUpdateManyWithoutPlayersNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutPlayersNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutPlayersInput"];
	["AdBoardUpdateWithoutPlayersInput"]: ValueTypes["AdBoardUpdateWithoutPlayersInput"];
	["AdBoardUpdateOneWithoutDraftAdboardNestedInput"]: ValueTypes["AdBoardUpdateOneWithoutDraftAdboardNestedInput"];
	["AdBoardUpsertWithoutDraftAdboardInput"]: ValueTypes["AdBoardUpsertWithoutDraftAdboardInput"];
	["AdBoardUpdateWithoutDraftAdboardInput"]: ValueTypes["AdBoardUpdateWithoutDraftAdboardInput"];
	["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"]: ValueTypes["AdBoardPublishHistoryUpdateManyWithoutAdBoardNestedInput"];
	["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["AdBoardPublishHistoryUpsertWithWhereUniqueWithoutAdBoardInput"];
	["AdBoardPublishHistoryUpdateWithoutAdBoardInput"]: ValueTypes["AdBoardPublishHistoryUpdateWithoutAdBoardInput"];
	["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutPublishAdboardVersionNestedInput"];
	["UserUpsertWithoutPublishAdboardVersionInput"]: ValueTypes["UserUpsertWithoutPublishAdboardVersionInput"];
	["UserUpdateWithoutPublishAdboardVersionInput"]: ValueTypes["UserUpdateWithoutPublishAdboardVersionInput"];
	["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"]: ValueTypes["AdBoardReviewCommentUpdateManyWithoutUserNestedInput"];
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"]: ValueTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutUserInput"];
	["AdBoardReviewCommentUpdateWithoutUserInput"]: ValueTypes["AdBoardReviewCommentUpdateWithoutUserInput"];
	["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"]: ValueTypes["AdBoardUpdateOneRequiredWithoutReviewCommentsNestedInput"];
	["AdBoardUpsertWithoutReviewCommentsInput"]: ValueTypes["AdBoardUpsertWithoutReviewCommentsInput"];
	["AdBoardUpdateWithoutReviewCommentsInput"]: ValueTypes["AdBoardUpdateWithoutReviewCommentsInput"];
	["AdBoardUpdateOneWithoutPublishedVersionNestedInput"]: ValueTypes["AdBoardUpdateOneWithoutPublishedVersionNestedInput"];
	["AdBoardUpsertWithoutPublishedVersionInput"]: ValueTypes["AdBoardUpsertWithoutPublishedVersionInput"];
	["AdBoardUpdateWithoutPublishedVersionInput"]: ValueTypes["AdBoardUpdateWithoutPublishedVersionInput"];
	["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"]: ValueTypes["AdBoardReviewCommentUpdateManyWithoutAdBoardNestedInput"];
	["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["AdBoardReviewCommentUpsertWithWhereUniqueWithoutAdBoardInput"];
	["AdBoardReviewCommentUpdateWithoutAdBoardInput"]: ValueTypes["AdBoardReviewCommentUpdateWithoutAdBoardInput"];
	["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutReviewCommentsNestedInput"];
	["UserUpsertWithoutReviewCommentsInput"]: ValueTypes["UserUpsertWithoutReviewCommentsInput"];
	["UserUpdateWithoutReviewCommentsInput"]: ValueTypes["UserUpdateWithoutReviewCommentsInput"];
	["AdBoardUpdateManyWithoutArchivedByNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutArchivedByNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutArchivedByInput"];
	["AdBoardUpdateWithoutArchivedByInput"]: ValueTypes["AdBoardUpdateWithoutArchivedByInput"];
	["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"]: ValueTypes["AdBoardPublicReviewUpdateManyWithoutAdBoardNestedInput"];
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutAdBoardInput"];
	["AdBoardPublicReviewUpdateWithoutAdBoardInput"]: ValueTypes["AdBoardPublicReviewUpdateWithoutAdBoardInput"];
	["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutPublicReviewsNestedInput"];
	["UserUpsertWithoutPublicReviewsInput"]: ValueTypes["UserUpsertWithoutPublicReviewsInput"];
	["UserUpdateWithoutPublicReviewsInput"]: ValueTypes["UserUpdateWithoutPublicReviewsInput"];
	["WorkspaceUpdateManyWithoutOwnerNestedInput"]: ValueTypes["WorkspaceUpdateManyWithoutOwnerNestedInput"];
	["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"]: ValueTypes["WorkspaceUpsertWithWhereUniqueWithoutOwnerInput"];
	["WorkspaceUpdateWithoutOwnerInput"]: ValueTypes["WorkspaceUpdateWithoutOwnerInput"];
	["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"]: ValueTypes["SubscriptionUpdateOneWithoutActiveWorkspaceNestedInput"];
	["SubscriptionUpsertWithoutActiveWorkspaceInput"]: ValueTypes["SubscriptionUpsertWithoutActiveWorkspaceInput"];
	["SubscriptionUpdateWithoutActiveWorkspaceInput"]: ValueTypes["SubscriptionUpdateWithoutActiveWorkspaceInput"];
	["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"]: ValueTypes["SubscriptionUpdateToOneWithWhereWithoutActiveWorkspaceInput"];
	["CameraServerUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["CameraServerUpdateManyWithoutWorkspaceNestedInput"];
	["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["CameraServerUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["CameraServerUpdateWithoutWorkspaceInput"]: ValueTypes["CameraServerUpdateWithoutWorkspaceInput"];
	["CameraUpdateManyWithoutCameraServerNestedInput"]: ValueTypes["CameraUpdateManyWithoutCameraServerNestedInput"];
	["CameraUpsertWithWhereUniqueWithoutCameraServerInput"]: ValueTypes["CameraUpsertWithWhereUniqueWithoutCameraServerInput"];
	["CameraUpdateWithoutCameraServerInput"]: ValueTypes["CameraUpdateWithoutCameraServerInput"];
	["CameraFeedUpdateManyWithoutCameraNestedInput"]: ValueTypes["CameraFeedUpdateManyWithoutCameraNestedInput"];
	["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"]: ValueTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraInput"];
	["CameraFeedUpdateWithoutCameraInput"]: ValueTypes["CameraFeedUpdateWithoutCameraInput"];
	["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"]: ValueTypes["CameraServerUpdateOneRequiredWithoutFeedsNestedInput"];
	["CameraServerUpsertWithoutFeedsInput"]: ValueTypes["CameraServerUpsertWithoutFeedsInput"];
	["CameraServerUpdateWithoutFeedsInput"]: ValueTypes["CameraServerUpdateWithoutFeedsInput"];
	["CameraServerUpdateToOneWithWhereWithoutFeedsInput"]: ValueTypes["CameraServerUpdateToOneWithWhereWithoutFeedsInput"];
	["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"]: ValueTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraInput"];
	["CameraFeedUpdateManyWithWhereWithoutCameraInput"]: ValueTypes["CameraFeedUpdateManyWithWhereWithoutCameraInput"];
	["CameraFeedScalarWhereInput"]: ValueTypes["CameraFeedScalarWhereInput"];
	["CameraFeedUpdateManyMutationInput"]: ValueTypes["CameraFeedUpdateManyMutationInput"];
	["AdBoardUpdateOneWithoutCamerasNestedInput"]: ValueTypes["AdBoardUpdateOneWithoutCamerasNestedInput"];
	["AdBoardUpsertWithoutCamerasInput"]: ValueTypes["AdBoardUpsertWithoutCamerasInput"];
	["AdBoardUpdateWithoutCamerasInput"]: ValueTypes["AdBoardUpdateWithoutCamerasInput"];
	["UserUpdateOneWithoutArchivedAdboardsNestedInput"]: ValueTypes["UserUpdateOneWithoutArchivedAdboardsNestedInput"];
	["UserUpsertWithoutArchivedAdboardsInput"]: ValueTypes["UserUpsertWithoutArchivedAdboardsInput"];
	["UserUpdateWithoutArchivedAdboardsInput"]: ValueTypes["UserUpdateWithoutArchivedAdboardsInput"];
	["WorkspaceUpdateManyWithoutLeadsNestedInput"]: ValueTypes["WorkspaceUpdateManyWithoutLeadsNestedInput"];
	["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"]: ValueTypes["WorkspaceUpsertWithWhereUniqueWithoutLeadsInput"];
	["WorkspaceUpdateWithoutLeadsInput"]: ValueTypes["WorkspaceUpdateWithoutLeadsInput"];
	["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["WorkspaceRoleUpdateManyWithoutWorkspaceNestedInput"];
	["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["WorkspaceRoleUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["WorkspaceRoleUpdateWithoutWorkspaceInput"]: ValueTypes["WorkspaceRoleUpdateWithoutWorkspaceInput"];
	["MembershipUpdateManyWithoutRoleNestedInput"]: ValueTypes["MembershipUpdateManyWithoutRoleNestedInput"];
	["MembershipUpsertWithWhereUniqueWithoutRoleInput"]: ValueTypes["MembershipUpsertWithWhereUniqueWithoutRoleInput"];
	["MembershipUpdateWithoutRoleInput"]: ValueTypes["MembershipUpdateWithoutRoleInput"];
	["MembershipUpdateWithWhereUniqueWithoutRoleInput"]: ValueTypes["MembershipUpdateWithWhereUniqueWithoutRoleInput"];
	["MembershipUpdateManyWithWhereWithoutRoleInput"]: ValueTypes["MembershipUpdateManyWithWhereWithoutRoleInput"];
	["MembershipScalarWhereInput"]: ValueTypes["MembershipScalarWhereInput"];
	["MembershipUpdateManyMutationInput"]: ValueTypes["MembershipUpdateManyMutationInput"];
	["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["WorkspaceRoleUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["WorkspaceRoleUpdateManyWithWhereWithoutWorkspaceInput"];
	["WorkspaceRoleScalarWhereInput"]: ValueTypes["WorkspaceRoleScalarWhereInput"];
	["WorkspaceRoleUpdateManyMutationInput"]: ValueTypes["WorkspaceRoleUpdateManyMutationInput"];
	["WebhookLogUpdateManyWithoutWorkspaceNestedInput"]: ValueTypes["WebhookLogUpdateManyWithoutWorkspaceNestedInput"];
	["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["WebhookLogUpsertWithWhereUniqueWithoutWorkspaceInput"];
	["WebhookLogUpdateWithoutWorkspaceInput"]: ValueTypes["WebhookLogUpdateWithoutWorkspaceInput"];
	["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"]: ValueTypes["BookingUpdateOneRequiredWithoutWebhookLogsNestedInput"];
	["BookingUpsertWithoutWebhookLogsInput"]: ValueTypes["BookingUpsertWithoutWebhookLogsInput"];
	["BookingUpdateWithoutWebhookLogsInput"]: ValueTypes["BookingUpdateWithoutWebhookLogsInput"];
	["SlotUsageUpdateManyWithoutBookingNestedInput"]: ValueTypes["SlotUsageUpdateManyWithoutBookingNestedInput"];
	["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"]: ValueTypes["SlotUsageUpsertWithWhereUniqueWithoutBookingInput"];
	["SlotUsageUpdateWithoutBookingInput"]: ValueTypes["SlotUsageUpdateWithoutBookingInput"];
	["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"]: ValueTypes["SlotUsageUpdateWithWhereUniqueWithoutBookingInput"];
	["SlotUsageUpdateManyWithWhereWithoutBookingInput"]: ValueTypes["SlotUsageUpdateManyWithWhereWithoutBookingInput"];
	["SlotUsageScalarWhereInput"]: ValueTypes["SlotUsageScalarWhereInput"];
	["SlotUsageUpdateManyMutationInput"]: ValueTypes["SlotUsageUpdateManyMutationInput"];
	["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"]: ValueTypes["BookingUpdateToOneWithWhereWithoutWebhookLogsInput"];
	["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["WebhookLogUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["WebhookLogUpdateManyWithWhereWithoutWorkspaceInput"];
	["WebhookLogScalarWhereInput"]: ValueTypes["WebhookLogScalarWhereInput"];
	["WebhookLogUpdateManyMutationInput"]: ValueTypes["WebhookLogUpdateManyMutationInput"];
	["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"]: ValueTypes["WorkspaceUpdateWithWhereUniqueWithoutLeadsInput"];
	["WorkspaceUpdateManyWithWhereWithoutLeadsInput"]: ValueTypes["WorkspaceUpdateManyWithWhereWithoutLeadsInput"];
	["WorkspaceScalarWhereInput"]: ValueTypes["WorkspaceScalarWhereInput"];
	["WorkspaceUpdateManyMutationInput"]: ValueTypes["WorkspaceUpdateManyMutationInput"];
	["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"]: ValueTypes["AdBoardPublicReviewUpdateManyWithoutUserNestedInput"];
	["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"]: ValueTypes["AdBoardPublicReviewUpsertWithWhereUniqueWithoutUserInput"];
	["AdBoardPublicReviewUpdateWithoutUserInput"]: ValueTypes["AdBoardPublicReviewUpdateWithoutUserInput"];
	["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"]: ValueTypes["AdBoardUpdateOneRequiredWithoutPublicReviewsNestedInput"];
	["AdBoardUpsertWithoutPublicReviewsInput"]: ValueTypes["AdBoardUpsertWithoutPublicReviewsInput"];
	["AdBoardUpdateWithoutPublicReviewsInput"]: ValueTypes["AdBoardUpdateWithoutPublicReviewsInput"];
	["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutPublicReviewsInput"];
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"]: ValueTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutUserInput"];
	["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"]: ValueTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutUserInput"];
	["AdBoardPublicReviewScalarWhereInput"]: ValueTypes["AdBoardPublicReviewScalarWhereInput"];
	["AdBoardPublicReviewUpdateManyMutationInput"]: ValueTypes["AdBoardPublicReviewUpdateManyMutationInput"];
	["UserPositionUpdateManyWithoutUserNestedInput"]: ValueTypes["UserPositionUpdateManyWithoutUserNestedInput"];
	["UserPositionUpsertWithWhereUniqueWithoutUserInput"]: ValueTypes["UserPositionUpsertWithWhereUniqueWithoutUserInput"];
	["UserPositionUpdateWithoutUserInput"]: ValueTypes["UserPositionUpdateWithoutUserInput"];
	["UserPositionUpdateWithWhereUniqueWithoutUserInput"]: ValueTypes["UserPositionUpdateWithWhereUniqueWithoutUserInput"];
	["UserPositionUpdateManyWithWhereWithoutUserInput"]: ValueTypes["UserPositionUpdateManyWithWhereWithoutUserInput"];
	["UserPositionScalarWhereInput"]: ValueTypes["UserPositionScalarWhereInput"];
	["UserPositionUpdateManyMutationInput"]: ValueTypes["UserPositionUpdateManyMutationInput"];
	["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutArchivedAdboardsInput"];
	["AdBoardUpdateToOneWithWhereWithoutCamerasInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutCamerasInput"];
	["CameraUpdateWithWhereUniqueWithoutCameraServerInput"]: ValueTypes["CameraUpdateWithWhereUniqueWithoutCameraServerInput"];
	["CameraUpdateManyWithWhereWithoutCameraServerInput"]: ValueTypes["CameraUpdateManyWithWhereWithoutCameraServerInput"];
	["CameraScalarWhereInput"]: ValueTypes["CameraScalarWhereInput"];
	["CameraUpdateManyMutationInput"]: ValueTypes["CameraUpdateManyMutationInput"];
	["CameraFeedUpdateManyWithoutCameraServerNestedInput"]: ValueTypes["CameraFeedUpdateManyWithoutCameraServerNestedInput"];
	["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"]: ValueTypes["CameraFeedUpsertWithWhereUniqueWithoutCameraServerInput"];
	["CameraFeedUpdateWithoutCameraServerInput"]: ValueTypes["CameraFeedUpdateWithoutCameraServerInput"];
	["CameraUpdateOneRequiredWithoutFeedsNestedInput"]: ValueTypes["CameraUpdateOneRequiredWithoutFeedsNestedInput"];
	["CameraUpsertWithoutFeedsInput"]: ValueTypes["CameraUpsertWithoutFeedsInput"];
	["CameraUpdateWithoutFeedsInput"]: ValueTypes["CameraUpdateWithoutFeedsInput"];
	["CameraUpdateToOneWithWhereWithoutFeedsInput"]: ValueTypes["CameraUpdateToOneWithWhereWithoutFeedsInput"];
	["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"]: ValueTypes["CameraFeedUpdateWithWhereUniqueWithoutCameraServerInput"];
	["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"]: ValueTypes["CameraFeedUpdateManyWithWhereWithoutCameraServerInput"];
	["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["CameraServerUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["CameraServerUpdateManyWithWhereWithoutWorkspaceInput"];
	["CameraServerScalarWhereInput"]: ValueTypes["CameraServerScalarWhereInput"];
	["CameraServerUpdateManyMutationInput"]: ValueTypes["CameraServerUpdateManyMutationInput"];
	["UserUpdateManyWithoutLeadWorspacesNestedInput"]: ValueTypes["UserUpdateManyWithoutLeadWorspacesNestedInput"];
	["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"]: ValueTypes["UserUpsertWithWhereUniqueWithoutLeadWorspacesInput"];
	["UserUpdateWithoutLeadWorspacesInput"]: ValueTypes["UserUpdateWithoutLeadWorspacesInput"];
	["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"]: ValueTypes["UserUpdateWithWhereUniqueWithoutLeadWorspacesInput"];
	["UserUpdateManyWithWhereWithoutLeadWorspacesInput"]: ValueTypes["UserUpdateManyWithWhereWithoutLeadWorspacesInput"];
	["UserScalarWhereInput"]: ValueTypes["UserScalarWhereInput"];
	["UserUpdateManyMutationInput"]: ValueTypes["UserUpdateManyMutationInput"];
	["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"]: ValueTypes["WorkspaceUpdateWithWhereUniqueWithoutOwnerInput"];
	["WorkspaceUpdateManyWithWhereWithoutOwnerInput"]: ValueTypes["WorkspaceUpdateManyWithWhereWithoutOwnerInput"];
	["UserUpdateToOneWithWhereWithoutPublicReviewsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutPublicReviewsInput"];
	["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["AdBoardPublicReviewUpdateWithWhereUniqueWithoutAdBoardInput"];
	["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"]: ValueTypes["AdBoardPublicReviewUpdateManyWithWhereWithoutAdBoardInput"];
	["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutArchivedByInput"];
	["AdBoardUpdateManyWithWhereWithoutArchivedByInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutArchivedByInput"];
	["AdBoardScalarWhereInput"]: ValueTypes["AdBoardScalarWhereInput"];
	["UserUpdateToOneWithWhereWithoutReviewCommentsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutReviewCommentsInput"];
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutAdBoardInput"];
	["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"]: ValueTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutAdBoardInput"];
	["AdBoardReviewCommentScalarWhereInput"]: ValueTypes["AdBoardReviewCommentScalarWhereInput"];
	["AdBoardReviewCommentUpdateManyMutationInput"]: ValueTypes["AdBoardReviewCommentUpdateManyMutationInput"];
	["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutPublishedVersionInput"];
	["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutReviewCommentsInput"];
	["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"]: ValueTypes["AdBoardReviewCommentUpdateWithWhereUniqueWithoutUserInput"];
	["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"]: ValueTypes["AdBoardReviewCommentUpdateManyWithWhereWithoutUserInput"];
	["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutPublishAdboardVersionInput"];
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutAdBoardInput"];
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"]: ValueTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutAdBoardInput"];
	["AdBoardPublishHistoryScalarWhereInput"]: ValueTypes["AdBoardPublishHistoryScalarWhereInput"];
	["AdBoardPublishHistoryUpdateManyMutationInput"]: ValueTypes["AdBoardPublishHistoryUpdateManyMutationInput"];
	["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutDraftAdboardInput"];
	["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutPlayersInput"];
	["AdBoardUpdateManyWithWhereWithoutPlayersInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutPlayersInput"];
	["PlayerUpdateToOneWithWhereWithoutCamerasInput"]: ValueTypes["PlayerUpdateToOneWithWhereWithoutCamerasInput"];
	["CameraUpdateWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["CameraUpdateWithWhereUniqueWithoutAdBoardInput"];
	["CameraUpdateManyWithWhereWithoutAdBoardInput"]: ValueTypes["CameraUpdateManyWithWhereWithoutAdBoardInput"];
	["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutOfflineBookingsContactInput"];
	["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutOfflineBookingsContactInput"];
	["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"]: ValueTypes["UserUpdateWithWhereUniqueWithoutSharedBookingsInput"];
	["UserUpdateManyWithWhereWithoutSharedBookingsInput"]: ValueTypes["UserUpdateManyWithWhereWithoutSharedBookingsInput"];
	["WebhookLogUpdateManyWithoutBookingNestedInput"]: ValueTypes["WebhookLogUpdateManyWithoutBookingNestedInput"];
	["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"]: ValueTypes["WebhookLogUpsertWithWhereUniqueWithoutBookingInput"];
	["WebhookLogUpdateWithoutBookingInput"]: ValueTypes["WebhookLogUpdateWithoutBookingInput"];
	["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"]: ValueTypes["WorkspaceUpdateOneWithoutWebhookLogsNestedInput"];
	["WorkspaceUpsertWithoutWebhookLogsInput"]: ValueTypes["WorkspaceUpsertWithoutWebhookLogsInput"];
	["WorkspaceUpdateWithoutWebhookLogsInput"]: ValueTypes["WorkspaceUpdateWithoutWebhookLogsInput"];
	["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutWebhookLogsInput"];
	["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"]: ValueTypes["WebhookLogUpdateWithWhereUniqueWithoutBookingInput"];
	["WebhookLogUpdateManyWithWhereWithoutBookingInput"]: ValueTypes["WebhookLogUpdateManyWithWhereWithoutBookingInput"];
	["BookingUpdateToOneWithWhereWithoutPayoutsInput"]: ValueTypes["BookingUpdateToOneWithWhereWithoutPayoutsInput"];
	["PayoutUpdateWithWhereUniqueWithoutTransactionInput"]: ValueTypes["PayoutUpdateWithWhereUniqueWithoutTransactionInput"];
	["PayoutUpdateManyWithWhereWithoutTransactionInput"]: ValueTypes["PayoutUpdateManyWithWhereWithoutTransactionInput"];
	["PayoutScalarWhereInput"]: ValueTypes["PayoutScalarWhereInput"];
	["PayoutUpdateManyMutationInput"]: ValueTypes["PayoutUpdateManyMutationInput"];
	["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["TransactionUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["TransactionUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["TransactionUpdateManyWithWhereWithoutWorkspaceInput"];
	["TransactionScalarWhereInput"]: ValueTypes["TransactionScalarWhereInput"];
	["TransactionUpdateManyMutationInput"]: ValueTypes["TransactionUpdateManyMutationInput"];
	["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutCameraServersInput"];
	["CameraServerUpdateToOneWithWhereWithoutCamerasInput"]: ValueTypes["CameraServerUpdateToOneWithWhereWithoutCamerasInput"];
	["CameraUpdateWithWhereUniqueWithoutPlayerInput"]: ValueTypes["CameraUpdateWithWhereUniqueWithoutPlayerInput"];
	["CameraUpdateManyWithWhereWithoutPlayerInput"]: ValueTypes["CameraUpdateManyWithWhereWithoutPlayerInput"];
	["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["PlayerUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["PlayerUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["PlayerUpdateManyWithWhereWithoutWorkspaceInput"];
	["PlayerScalarWhereInput"]: ValueTypes["PlayerScalarWhereInput"];
	["PlayerUpdateManyMutationInput"]: ValueTypes["PlayerUpdateManyMutationInput"];
	["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutTransactionsInput"];
	["TransactionUpdateToOneWithWhereWithoutPayoutsInput"]: ValueTypes["TransactionUpdateToOneWithWhereWithoutPayoutsInput"];
	["PayoutUpdateWithWhereUniqueWithoutBookingInput"]: ValueTypes["PayoutUpdateWithWhereUniqueWithoutBookingInput"];
	["PayoutUpdateManyWithWhereWithoutBookingInput"]: ValueTypes["PayoutUpdateManyWithWhereWithoutBookingInput"];
	["BookingUpdateWithWhereUniqueWithoutCampaignInput"]: ValueTypes["BookingUpdateWithWhereUniqueWithoutCampaignInput"];
	["BookingUpdateManyWithWhereWithoutCampaignInput"]: ValueTypes["BookingUpdateManyWithWhereWithoutCampaignInput"];
	["BookingScalarWhereInput"]: ValueTypes["BookingScalarWhereInput"];
	["BookingUpdateManyMutationInput"]: ValueTypes["BookingUpdateManyMutationInput"];
	["CampaignUpdateWithWhereUniqueWithoutAssetsInput"]: ValueTypes["CampaignUpdateWithWhereUniqueWithoutAssetsInput"];
	["CampaignUpdateManyWithWhereWithoutAssetsInput"]: ValueTypes["CampaignUpdateManyWithWhereWithoutAssetsInput"];
	["CampaignScalarWhereInput"]: ValueTypes["CampaignScalarWhereInput"];
	["CampaignUpdateManyMutationInput"]: ValueTypes["CampaignUpdateManyMutationInput"];
	["FileUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["FileUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["FileUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["FileUpdateManyWithWhereWithoutWorkspaceInput"];
	["FileScalarWhereInput"]: ValueTypes["FileScalarWhereInput"];
	["FileUpdateManyMutationInput"]: ValueTypes["FileUpdateManyMutationInput"];
	["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutBookingsInput"];
	["BookingUpdateToOneWithWhereWithoutProofsInput"]: ValueTypes["BookingUpdateToOneWithWhereWithoutProofsInput"];
	["FileUpdateWithWhereUniqueWithoutCampaignsInput"]: ValueTypes["FileUpdateWithWhereUniqueWithoutCampaignsInput"];
	["FileUpdateManyWithWhereWithoutCampaignsInput"]: ValueTypes["FileUpdateManyWithWhereWithoutCampaignsInput"];
	["CampaignUpdateToOneWithWhereWithoutBookingsInput"]: ValueTypes["CampaignUpdateToOneWithWhereWithoutBookingsInput"];
	["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["BookingUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["BookingUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["BookingUpdateManyWithWhereWithoutWorkspaceInput"];
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardGroupsInput"];
	["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"]: ValueTypes["AdBoardGroupUpdateToOneWithWhereWithoutAdBoardInput"];
	["AdBoardUpdateToOneWithWhereWithoutHistoryInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutHistoryInput"];
	["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"]: ValueTypes["AdBoardPublishHistoryUpdateWithWhereUniqueWithoutPublishedByInput"];
	["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"]: ValueTypes["AdBoardPublishHistoryUpdateManyWithWhereWithoutPublishedByInput"];
	["UserUpdateToOneWithWhereWithoutAuditsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutAuditsInput"];
	["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["AuditUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["AuditUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["AuditUpdateManyWithWhereWithoutWorkspaceInput"];
	["AuditScalarWhereInput"]: ValueTypes["AuditScalarWhereInput"];
	["AuditUpdateManyMutationInput"]: ValueTypes["AuditUpdateManyMutationInput"];
	["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutPlayersInput"];
	["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"]: ValueTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardGroupInput"];
	["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"]: ValueTypes["PlayerUpdateManyWithWhereWithoutAdBoardGroupInput"];
	["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"]: ValueTypes["AdBoardGroupUpdateWithWhereUniqueWithoutScheduledPlaylistsInput"];
	["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"]: ValueTypes["AdBoardGroupUpdateManyWithWhereWithoutScheduledPlaylistsInput"];
	["AdBoardGroupScalarWhereInput"]: ValueTypes["AdBoardGroupScalarWhereInput"];
	["AdBoardGroupUpdateManyMutationInput"]: ValueTypes["AdBoardGroupUpdateManyMutationInput"];
	["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"]: ValueTypes["PlaylistUpdateManyWithoutFooterPlaylistNestedInput"];
	["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"]: ValueTypes["PlaylistUpsertWithWhereUniqueWithoutFooterPlaylistInput"];
	["PlaylistUpdateWithoutFooterPlaylistInput"]: ValueTypes["PlaylistUpdateWithoutFooterPlaylistInput"];
	["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"]: ValueTypes["PlaylistUpdateManyWithoutSidebarPlaylistNestedInput"];
	["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"]: ValueTypes["PlaylistUpsertWithWhereUniqueWithoutSidebarPlaylistInput"];
	["PlaylistUpdateWithoutSidebarPlaylistInput"]: ValueTypes["PlaylistUpdateWithoutSidebarPlaylistInput"];
	["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"]: ValueTypes["PlaylistUpdateWithWhereUniqueWithoutSidebarPlaylistInput"];
	["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"]: ValueTypes["PlaylistUpdateManyWithWhereWithoutSidebarPlaylistInput"];
	["PlaylistScalarWhereInput"]: ValueTypes["PlaylistScalarWhereInput"];
	["PlaylistUpdateManyMutationInput"]: ValueTypes["PlaylistUpdateManyMutationInput"];
	["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"]: ValueTypes["PlaylistUpdateWithWhereUniqueWithoutFooterPlaylistInput"];
	["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"]: ValueTypes["PlaylistUpdateManyWithWhereWithoutFooterPlaylistInput"];
	["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"]: ValueTypes["PlaylistUpdateToOneWithWhereWithoutAdBoardGroupsForAdsInput"];
	["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["AdBoardGroupUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["AdBoardGroupUpdateManyWithWhereWithoutWorkspaceInput"];
	["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutPlaylistsInput"];
	["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"]: ValueTypes["PlaylistUpdateWithWhereUniqueWithoutAdBoardGroupsInput"];
	["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"]: ValueTypes["PlaylistUpdateManyWithWhereWithoutAdBoardGroupsInput"];
	["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"]: ValueTypes["AdBoardGroupUpdateWithWhereUniqueWithoutAdsPlaylistInput"];
	["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"]: ValueTypes["AdBoardGroupUpdateManyWithWhereWithoutAdsPlaylistInput"];
	["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"]: ValueTypes["PlaylistUpdateToOneWithWhereWithoutSidebarParentPlaylistsInput"];
	["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"]: ValueTypes["PlaylistUpdateToOneWithWhereWithoutFooterParentPlaylistsInput"];
	["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["PlaylistUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["PlaylistUpdateManyWithWhereWithoutWorkspaceInput"];
	["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutAdBoardsInput"];
	["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutAdBoardGroupInput"];
	["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"]: ValueTypes["AdBoardGroupUpdateToOneWithWhereWithoutPisignagePlayersInput"];
	["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"]: ValueTypes["PlayerUpdateWithWhereUniqueWithoutAdBoardsInput"];
	["PlayerUpdateManyWithWhereWithoutAdBoardsInput"]: ValueTypes["PlayerUpdateManyWithWhereWithoutAdBoardsInput"];
	["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutWorkspaceInput"];
	["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutMembershipsInput"];
	["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"]: ValueTypes["WorkspaceRoleUpdateOneRequiredWithoutMembershipsNestedInput"];
	["WorkspaceRoleUpsertWithoutMembershipsInput"]: ValueTypes["WorkspaceRoleUpsertWithoutMembershipsInput"];
	["WorkspaceRoleUpdateWithoutMembershipsInput"]: ValueTypes["WorkspaceRoleUpdateWithoutMembershipsInput"];
	["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"]: ValueTypes["WorkspaceUpdateOneWithoutWorkspaceRolesNestedInput"];
	["WorkspaceUpsertWithoutWorkspaceRolesInput"]: ValueTypes["WorkspaceUpsertWithoutWorkspaceRolesInput"];
	["WorkspaceUpdateWithoutWorkspaceRolesInput"]: ValueTypes["WorkspaceUpdateWithoutWorkspaceRolesInput"];
	["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutWorkspaceRolesInput"];
	["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"]: ValueTypes["WorkspaceRoleUpdateToOneWithWhereWithoutMembershipsInput"];
	["MembershipUpdateWithWhereUniqueWithoutUserInput"]: ValueTypes["MembershipUpdateWithWhereUniqueWithoutUserInput"];
	["MembershipUpdateManyWithWhereWithoutUserInput"]: ValueTypes["MembershipUpdateManyWithWhereWithoutUserInput"];
	["UserUpdateToOneWithWhereWithoutChangelogsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutChangelogsInput"];
	["ChangelogUpdateWithWhereUniqueWithoutBookingInput"]: ValueTypes["ChangelogUpdateWithWhereUniqueWithoutBookingInput"];
	["ChangelogUpdateManyWithWhereWithoutBookingInput"]: ValueTypes["ChangelogUpdateManyWithWhereWithoutBookingInput"];
	["ChangelogScalarWhereInput"]: ValueTypes["ChangelogScalarWhereInput"];
	["ChangelogUpdateManyMutationInput"]: ValueTypes["ChangelogUpdateManyMutationInput"];
	["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"]: ValueTypes["BookingUpdateWithWhereUniqueWithoutSharedUsersInput"];
	["BookingUpdateManyWithWhereWithoutSharedUsersInput"]: ValueTypes["BookingUpdateManyWithWhereWithoutSharedUsersInput"];
	["UserUpdateToOneWithWhereWithoutAdBoardsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutAdBoardsInput"];
	["AdBoardUpdateToOneWithWhereWithoutBookingsInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutBookingsInput"];
	["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"]: ValueTypes["BookingUpdateWithWhereUniqueWithoutAdCategoryInput"];
	["BookingUpdateManyWithWhereWithoutAdCategoryInput"]: ValueTypes["BookingUpdateManyWithWhereWithoutAdCategoryInput"];
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"]: ValueTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutCampaignsInput"];
	["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"]: ValueTypes["AdvertisementCategoryUpdateManyWithWhereWithoutCampaignsInput"];
	["AdvertisementCategoryScalarWhereInput"]: ValueTypes["AdvertisementCategoryScalarWhereInput"];
	["AdvertisementCategoryUpdateManyMutationInput"]: ValueTypes["AdvertisementCategoryUpdateManyMutationInput"];
	["CampaignUpdateWithWhereUniqueWithoutUserInput"]: ValueTypes["CampaignUpdateWithWhereUniqueWithoutUserInput"];
	["CampaignUpdateManyWithWhereWithoutUserInput"]: ValueTypes["CampaignUpdateManyWithWhereWithoutUserInput"];
	["UserUpdateToOneWithWhereWithoutMembershipsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutMembershipsInput"];
	["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"]: ValueTypes["MembershipUpdateWithWhereUniqueWithoutWorkspaceInput"];
	["MembershipUpdateManyWithWhereWithoutWorkspaceInput"]: ValueTypes["MembershipUpdateManyWithWhereWithoutWorkspaceInput"];
	["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutAuditsInput"];
	["AuditUpdateWithWhereUniqueWithoutUserInput"]: ValueTypes["AuditUpdateWithWhereUniqueWithoutUserInput"];
	["AuditUpdateManyWithWhereWithoutUserInput"]: ValueTypes["AuditUpdateManyWithWhereWithoutUserInput"];
	["UserUpdateToOneWithWhereWithoutOrdersInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutOrdersInput"];
	["OrderUpdateWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["OrderUpdateWithWhereUniqueWithoutAdBoardInput"];
	["OrderUpdateManyWithWhereWithoutAdBoardInput"]: ValueTypes["OrderUpdateManyWithWhereWithoutAdBoardInput"];
	["OrderScalarWhereInput"]: ValueTypes["OrderScalarWhereInput"];
	["OrderUpdateManyMutationInput"]: ValueTypes["OrderUpdateManyMutationInput"];
	["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutCreatedByInput"];
	["AdBoardUpdateManyWithWhereWithoutCreatedByInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutCreatedByInput"];
	["UserUpdateToOneWithWhereWithoutBookingsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutBookingsInput"];
	["BookingUpdateToOneWithWhereWithoutChangelogsInput"]: ValueTypes["BookingUpdateToOneWithWhereWithoutChangelogsInput"];
	["ChangelogUpdateWithWhereUniqueWithoutUserInput"]: ValueTypes["ChangelogUpdateWithWhereUniqueWithoutUserInput"];
	["ChangelogUpdateManyWithWhereWithoutUserInput"]: ValueTypes["ChangelogUpdateManyWithWhereWithoutUserInput"];
	["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutOfflineBookingAdboardsInput"];
	["AdBoardUpdateToOneWithWhereWithoutOrdersInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutOrdersInput"];
	["OrderUpdateWithWhereUniqueWithoutCreatedByInput"]: ValueTypes["OrderUpdateWithWhereUniqueWithoutCreatedByInput"];
	["OrderUpdateManyWithWhereWithoutCreatedByInput"]: ValueTypes["OrderUpdateManyWithWhereWithoutCreatedByInput"];
	["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutOwnedWorkspacesInput"];
	["WorkspaceUpdateToOneWithWhereWithoutFilesInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutFilesInput"];
	["FileUpdateWithWhereUniqueWithoutUpdatedByInput"]: ValueTypes["FileUpdateWithWhereUniqueWithoutUpdatedByInput"];
	["FileUpdateManyWithWhereWithoutUpdatedByInput"]: ValueTypes["FileUpdateManyWithWhereWithoutUpdatedByInput"];
	["UserUpdateToOneWithWhereWithoutCreatedFilesInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutCreatedFilesInput"];
	["FileUpdateWithWhereUniqueWithoutProofBookingInput"]: ValueTypes["FileUpdateWithWhereUniqueWithoutProofBookingInput"];
	["FileUpdateManyWithWhereWithoutProofBookingInput"]: ValueTypes["FileUpdateManyWithWhereWithoutProofBookingInput"];
	["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: ValueTypes["BookingUpdateWithWhereUniqueWithoutBusinessCategoryInput"];
	["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"]: ValueTypes["BookingUpdateManyWithWhereWithoutBusinessCategoryInput"];
	["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"]: ValueTypes["BusinessCategoryUpdateToOneWithWhereWithoutCampaignsInput"];
	["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"]: ValueTypes["CampaignUpdateWithWhereUniqueWithoutAdCategoryInput"];
	["CampaignUpdateManyWithWhereWithoutAdCategoryInput"]: ValueTypes["CampaignUpdateManyWithWhereWithoutAdCategoryInput"];
	["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"]: ValueTypes["AdvertisementCategoryUpdateWithWhereUniqueWithoutBookingsInput"];
	["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"]: ValueTypes["AdvertisementCategoryUpdateManyWithWhereWithoutBookingsInput"];
	["BookingUpdateWithWhereUniqueWithoutAssetsInput"]: ValueTypes["BookingUpdateWithWhereUniqueWithoutAssetsInput"];
	["BookingUpdateManyWithWhereWithoutAssetsInput"]: ValueTypes["BookingUpdateManyWithWhereWithoutAssetsInput"];
	["FileUpdateWithWhereUniqueWithoutCreatedByInput"]: ValueTypes["FileUpdateWithWhereUniqueWithoutCreatedByInput"];
	["FileUpdateManyWithWhereWithoutCreatedByInput"]: ValueTypes["FileUpdateManyWithWhereWithoutCreatedByInput"];
	["UserUpdateToOneWithWhereWithoutCampaignsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutCampaignsInput"];
	["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"]: ValueTypes["CampaignUpdateWithWhereUniqueWithoutBusinessCategoryInput"];
	["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"]: ValueTypes["CampaignUpdateManyWithWhereWithoutBusinessCategoryInput"];
	["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"]: ValueTypes["BusinessCategoryUpdateToOneWithWhereWithoutBookingsInput"];
	["BookingUpdateWithWhereUniqueWithoutUserInput"]: ValueTypes["BookingUpdateWithWhereUniqueWithoutUserInput"];
	["BookingUpdateManyWithWhereWithoutUserInput"]: ValueTypes["BookingUpdateManyWithWhereWithoutUserInput"];
	["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutUpdatedFilesInput"];
	["FileUpdateWithWhereUniqueWithoutBookingsInput"]: ValueTypes["FileUpdateWithWhereUniqueWithoutBookingsInput"];
	["FileUpdateManyWithWhereWithoutBookingsInput"]: ValueTypes["FileUpdateManyWithWhereWithoutBookingsInput"];
	["BookingUpdateWithWhereUniqueWithoutAdBoardInput"]: ValueTypes["BookingUpdateWithWhereUniqueWithoutAdBoardInput"];
	["BookingUpdateManyWithWhereWithoutAdBoardInput"]: ValueTypes["BookingUpdateManyWithWhereWithoutAdBoardInput"];
	["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutCategoryInput"];
	["AdBoardUpdateManyWithWhereWithoutCategoryInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutCategoryInput"];
	["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"]: ValueTypes["BusinessCategoryUpdateWithWhereUniqueWithoutRestrictedByAdBoardsInput"];
	["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"]: ValueTypes["BusinessCategoryUpdateManyWithWhereWithoutRestrictedByAdBoardsInput"];
	["BusinessCategoryScalarWhereInput"]: ValueTypes["BusinessCategoryScalarWhereInput"];
	["BusinessCategoryUpdateManyMutationInput"]: ValueTypes["BusinessCategoryUpdateManyMutationInput"];
	["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutLocationCategoryInput"];
	["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutLocationCategoryInput"];
	["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"]: ValueTypes["LocationCategoryUpdateToOneWithWhereWithoutLocationSubCategoryInput"];
	["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: ValueTypes["LocationSubCategoryUpdateToOneWithWhereWithoutAdBoardsInput"];
	["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutRestrictedBusinesInput"];
	["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutRestrictedBusinesInput"];
	["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: ValueTypes["BusinessCategoryUpdateToOneWithWhereWithoutAdBoardsInput"];
	["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutLocationSubCategoryInput"];
	["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutLocationSubCategoryInput"];
	["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"]: ValueTypes["LocationSubCategoryUpdateWithWhereUniqueWithoutLocationCategoryInput"];
	["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"]: ValueTypes["LocationSubCategoryUpdateManyWithWhereWithoutLocationCategoryInput"];
	["LocationSubCategoryScalarWhereInput"]: ValueTypes["LocationSubCategoryScalarWhereInput"];
	["LocationSubCategoryUpdateManyMutationInput"]: ValueTypes["LocationSubCategoryUpdateManyMutationInput"];
	["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"]: ValueTypes["LocationCategoryUpdateToOneWithWhereWithoutAdBoardsInput"];
	["AdBoardGroupCreateManyInput"]: ValueTypes["AdBoardGroupCreateManyInput"];
	["AdBoardGroupCreateInput"]: ValueTypes["AdBoardGroupCreateInput"];
	["AdBoardGroupUpdateInput"]: ValueTypes["AdBoardGroupUpdateInput"];
	["AdBoardTypeCreateManyInput"]: ValueTypes["AdBoardTypeCreateManyInput"];
	["AdBoardTypeCreateInput"]: ValueTypes["AdBoardTypeCreateInput"];
	["AdBoardCreateNestedManyWithoutTypeInput"]: ValueTypes["AdBoardCreateNestedManyWithoutTypeInput"];
	["AdBoardCreateWithoutTypeInput"]: ValueTypes["AdBoardCreateWithoutTypeInput"];
	["AdBoardCreateOrConnectWithoutTypeInput"]: ValueTypes["AdBoardCreateOrConnectWithoutTypeInput"];
	["AdBoardCreateManyTypeInputEnvelope"]: ValueTypes["AdBoardCreateManyTypeInputEnvelope"];
	["AdBoardCreateManyTypeInput"]: ValueTypes["AdBoardCreateManyTypeInput"];
	["AdBoardTypeUpdateManyMutationInput"]: ValueTypes["AdBoardTypeUpdateManyMutationInput"];
	["AdBoardTypeUpdateInput"]: ValueTypes["AdBoardTypeUpdateInput"];
	["AdBoardUpdateManyWithoutTypeNestedInput"]: ValueTypes["AdBoardUpdateManyWithoutTypeNestedInput"];
	["AdBoardUpsertWithWhereUniqueWithoutTypeInput"]: ValueTypes["AdBoardUpsertWithWhereUniqueWithoutTypeInput"];
	["AdBoardUpdateWithoutTypeInput"]: ValueTypes["AdBoardUpdateWithoutTypeInput"];
	["AdBoardUpdateWithWhereUniqueWithoutTypeInput"]: ValueTypes["AdBoardUpdateWithWhereUniqueWithoutTypeInput"];
	["AdBoardUpdateManyWithWhereWithoutTypeInput"]: ValueTypes["AdBoardUpdateManyWithWhereWithoutTypeInput"];
	["AdvertisementCategoryCreateManyInput"]: ValueTypes["AdvertisementCategoryCreateManyInput"];
	["AdvertisementCategoryCreateInput"]: ValueTypes["AdvertisementCategoryCreateInput"];
	["AdvertisementCategoryUpdateInput"]: ValueTypes["AdvertisementCategoryUpdateInput"];
	["AuditCreateManyInput"]: ValueTypes["AuditCreateManyInput"];
	["AuditCreateInput"]: ValueTypes["AuditCreateInput"];
	["AuditUpdateInput"]: ValueTypes["AuditUpdateInput"];
	["BookingCreateManyInput"]: ValueTypes["BookingCreateManyInput"];
	["BookingCreateInput"]: ValueTypes["BookingCreateInput"];
	["BookingUpdateInput"]: ValueTypes["BookingUpdateInput"];
	["BusinessCategoryCreateManyInput"]: ValueTypes["BusinessCategoryCreateManyInput"];
	["BusinessCategoryCreateInput"]: ValueTypes["BusinessCategoryCreateInput"];
	["BusinessCategoryUpdateInput"]: ValueTypes["BusinessCategoryUpdateInput"];
	["CameraCreateManyInput"]: ValueTypes["CameraCreateManyInput"];
	["CameraCreateInput"]: ValueTypes["CameraCreateInput"];
	["CameraUpdateInput"]: ValueTypes["CameraUpdateInput"];
	["CameraFeedCreateManyInput"]: ValueTypes["CameraFeedCreateManyInput"];
	["CameraFeedCreateInput"]: ValueTypes["CameraFeedCreateInput"];
	["CameraFeedUpdateInput"]: ValueTypes["CameraFeedUpdateInput"];
	["CameraServerCreateManyInput"]: ValueTypes["CameraServerCreateManyInput"];
	["CameraServerCreateInput"]: ValueTypes["CameraServerCreateInput"];
	["CameraServerUpdateInput"]: ValueTypes["CameraServerUpdateInput"];
	["CampaignCreateManyInput"]: ValueTypes["CampaignCreateManyInput"];
	["CampaignCreateInput"]: ValueTypes["CampaignCreateInput"];
	["CampaignUpdateInput"]: ValueTypes["CampaignUpdateInput"];
	["FileCreateManyInput"]: ValueTypes["FileCreateManyInput"];
	["FileCreateInput"]: ValueTypes["FileCreateInput"];
	["FileUpdateInput"]: ValueTypes["FileUpdateInput"];
	["MembershipCreateManyInput"]: ValueTypes["MembershipCreateManyInput"];
	["MembershipCreateInput"]: ValueTypes["MembershipCreateInput"];
	["MembershipUpdateInput"]: ValueTypes["MembershipUpdateInput"];
	["NotificationCreateManyInput"]: ValueTypes["NotificationCreateManyInput"];
	["NotificationCreateInput"]: ValueTypes["NotificationCreateInput"];
	["UserCreateNestedOneWithoutNotificationsInput"]: ValueTypes["UserCreateNestedOneWithoutNotificationsInput"];
	["UserCreateWithoutNotificationsInput"]: ValueTypes["UserCreateWithoutNotificationsInput"];
	["UserCreateOrConnectWithoutNotificationsInput"]: ValueTypes["UserCreateOrConnectWithoutNotificationsInput"];
	["NotificationUpdateInput"]: ValueTypes["NotificationUpdateInput"];
	["UserUpdateOneRequiredWithoutNotificationsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutNotificationsNestedInput"];
	["UserUpsertWithoutNotificationsInput"]: ValueTypes["UserUpsertWithoutNotificationsInput"];
	["UserUpdateWithoutNotificationsInput"]: ValueTypes["UserUpdateWithoutNotificationsInput"];
	["UserUpdateToOneWithWhereWithoutNotificationsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutNotificationsInput"];
	["OrderCreateManyInput"]: ValueTypes["OrderCreateManyInput"];
	["OrderCreateInput"]: ValueTypes["OrderCreateInput"];
	["OrderUpdateInput"]: ValueTypes["OrderUpdateInput"];
	["PlayerCreateManyInput"]: ValueTypes["PlayerCreateManyInput"];
	["PlayerCreateInput"]: ValueTypes["PlayerCreateInput"];
	["PlayerUpdateInput"]: ValueTypes["PlayerUpdateInput"];
	["PlaylistCreateManyInput"]: ValueTypes["PlaylistCreateManyInput"];
	["PlaylistCreateInput"]: ValueTypes["PlaylistCreateInput"];
	["PlaylistUpdateInput"]: ValueTypes["PlaylistUpdateInput"];
	["SlotConfigurationCreateManyInput"]: ValueTypes["SlotConfigurationCreateManyInput"];
	["SlotConfigurationCreateInput"]: ValueTypes["SlotConfigurationCreateInput"];
	["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"]: ValueTypes["AdBoardCreateNestedOneWithoutSlotConfigurationsInput"];
	["AdBoardCreateWithoutSlotConfigurationsInput"]: ValueTypes["AdBoardCreateWithoutSlotConfigurationsInput"];
	["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"]: ValueTypes["AdBoardCreateOrConnectWithoutSlotConfigurationsInput"];
	["SlotConfigurationUpdateInput"]: ValueTypes["SlotConfigurationUpdateInput"];
	["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"]: ValueTypes["AdBoardUpdateOneRequiredWithoutSlotConfigurationsNestedInput"];
	["AdBoardUpsertWithoutSlotConfigurationsInput"]: ValueTypes["AdBoardUpsertWithoutSlotConfigurationsInput"];
	["AdBoardUpdateWithoutSlotConfigurationsInput"]: ValueTypes["AdBoardUpdateWithoutSlotConfigurationsInput"];
	["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"]: ValueTypes["AdBoardUpdateToOneWithWhereWithoutSlotConfigurationsInput"];
	["SubscriptionCreateManyInput"]: ValueTypes["SubscriptionCreateManyInput"];
	["SubscriptionCreateInput"]: ValueTypes["SubscriptionCreateInput"];
	["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"]: ValueTypes["WorkspaceCreateNestedOneWithoutActiveSubscriptionInput"];
	["WorkspaceCreateWithoutActiveSubscriptionInput"]: ValueTypes["WorkspaceCreateWithoutActiveSubscriptionInput"];
	["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"]: ValueTypes["WorkspaceCreateOrConnectWithoutActiveSubscriptionInput"];
	["SubscriptionUpdateManyMutationInput"]: ValueTypes["SubscriptionUpdateManyMutationInput"];
	["SubscriptionUpdateInput"]: ValueTypes["SubscriptionUpdateInput"];
	["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"]: ValueTypes["WorkspaceUpdateOneRequiredWithoutActiveSubscriptionNestedInput"];
	["WorkspaceUpsertWithoutActiveSubscriptionInput"]: ValueTypes["WorkspaceUpsertWithoutActiveSubscriptionInput"];
	["WorkspaceUpdateWithoutActiveSubscriptionInput"]: ValueTypes["WorkspaceUpdateWithoutActiveSubscriptionInput"];
	["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"]: ValueTypes["WorkspaceUpdateToOneWithWhereWithoutActiveSubscriptionInput"];
	["UserCreateManyInput"]: ValueTypes["UserCreateManyInput"];
	["UserCreateInput"]: ValueTypes["UserCreateInput"];
	["UserUpdateInput"]: ValueTypes["UserUpdateInput"];
	["UserPositionCreateManyInput"]: ValueTypes["UserPositionCreateManyInput"];
	["UserPositionCreateInput"]: ValueTypes["UserPositionCreateInput"];
	["UserCreateNestedOneWithoutUserPositionsInput"]: ValueTypes["UserCreateNestedOneWithoutUserPositionsInput"];
	["UserCreateWithoutUserPositionsInput"]: ValueTypes["UserCreateWithoutUserPositionsInput"];
	["UserCreateOrConnectWithoutUserPositionsInput"]: ValueTypes["UserCreateOrConnectWithoutUserPositionsInput"];
	["UserPositionUpdateInput"]: ValueTypes["UserPositionUpdateInput"];
	["UserUpdateOneRequiredWithoutUserPositionsNestedInput"]: ValueTypes["UserUpdateOneRequiredWithoutUserPositionsNestedInput"];
	["UserUpsertWithoutUserPositionsInput"]: ValueTypes["UserUpsertWithoutUserPositionsInput"];
	["UserUpdateWithoutUserPositionsInput"]: ValueTypes["UserUpdateWithoutUserPositionsInput"];
	["UserUpdateToOneWithWhereWithoutUserPositionsInput"]: ValueTypes["UserUpdateToOneWithWhereWithoutUserPositionsInput"];
	["WorkspaceCreateManyInput"]: ValueTypes["WorkspaceCreateManyInput"];
	["WorkspaceCreateInput"]: ValueTypes["WorkspaceCreateInput"];
	["WorkspaceUpdateInput"]: ValueTypes["WorkspaceUpdateInput"];
	["WorkspaceRoleCreateManyInput"]: ValueTypes["WorkspaceRoleCreateManyInput"];
	["WorkspaceRoleCreateInput"]: ValueTypes["WorkspaceRoleCreateInput"];
	["WorkspaceRoleUpdateInput"]: ValueTypes["WorkspaceRoleUpdateInput"];
	["AccountDetails"]: ValueTypes["AccountDetails"];
	["UserInput"]: ValueTypes["UserInput"];
	["TemplateIds"]: ValueTypes["TemplateIds"];
	["AdBoardPublicReviewCreateInput"]: ValueTypes["AdBoardPublicReviewCreateInput"];
	["BookingRequest"]: ValueTypes["BookingRequest"];
	["BookingDetailsInput"]: ValueTypes["BookingDetailsInput"];
}