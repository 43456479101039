import { GraphQLTypes, InputType, Selector } from "@/generated/zeus";

export const adBoardSelector = Selector('AdBoard')({
  id:true,
  status:true,
  pricePerDay:true,
  href:true,
  offlineBookingsEnabled:true,
  title:  true,
  featuredImage:true,
  originalPricePerDay:true,
  address:true,
  adsPerLoop:true,
  adsDuration:true,
  city:true,
  galleryImgs:true,
  players:[{},{
    map:{
      lat:true,
      lng:true
    }
  }],
  resolution:{
    width : true,
    height: true
  },
  type:[{},{
    name:true
  }],
  screenSize:{
    width:true,
    height:true,
    unit:true
  },
  workspaceId:true,
  workspace:{
    name:true,
  },
  map :{
    lat:true,
    lng:true
  }
});

export type AdboardFragment = InputType<GraphQLTypes['AdBoard'], typeof adBoardSelector>;