import { AdboardFragment } from '@/hooks/useAdboards';
import React from 'react';

export function getBillboardInfo(adBoard: AdboardFragment) {
  const adDuration = adBoard.adsDuration; // seconds
  const adsPerLoop = adBoard.adsPerLoop;
  const loopTime = adsPerLoop* adDuration; // seconds
  const dailyOperationHours = 10;
  const daysPerMonth = 30;
  const dailyCost = adBoard.pricePerDay; // ₹

  // Derived calculations
  const loopsPerHour = 60 * 60 / loopTime;
  const screens = adBoard.players.length;

  const dailyImpressions = loopsPerHour * dailyOperationHours * adsPerLoop * screens;
  const monthlyImpressions = dailyImpressions * daysPerMonth * screens;
  const monthlyCost = dailyCost * daysPerMonth; 
  const costPerImpression = (monthlyCost / monthlyImpressions).toFixed(2);
  const costPerThousandImpressions = ((monthlyCost / monthlyImpressions) * 1000).toFixed(2);
  const impressionPerHour = loopsPerHour * adsPerLoop * screens;

  return {
    loopTime,
    dailyOperationHours,
    screens,
    impressionPerHour,
    loopsPerHour,
    dailyImpressions,
    monthlyImpressions,
    dailyCost,
    monthlyCost,
    costPerImpression,
    costPerThousandImpressions
  }
}

const BillboardInfo = (props:{
    adBoard:AdboardFragment
}) => {
  // Constants

  const {adsDuration,adsPerLoop} = props.adBoard;

  const {
    loopTime,
    dailyOperationHours,
    screens,
    loopsPerHour,
    dailyImpressions,
    monthlyImpressions,
    dailyCost,
    costPerImpression,
    costPerThousandImpressions
  } = getBillboardInfo(props.adBoard);

  return (
    <div className="max-w-3xl mx-auto bg-white rounded-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Key Features:</h2>
          <ul className="list-disc list-inside space-y-2">
            <li><span className="font-medium">Ad Duration:</span> {adsDuration} seconds</li>
            <li><span className="font-medium">Max advertiser per day:</span> {adsPerLoop}</li>
            <li><span className="font-medium">Loop Time:</span> {loopTime} seconds</li>
            <li><span className="font-medium">Daily Operation:</span> ~{dailyOperationHours} hours</li>
            <li><span className="font-medium">No of screens: </span> {screens}</li>
          </ul>
        </div>
        <div className="bg-blue-100 p-4 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Performance Metrics (for all screens):</h2>
          <ul className="list-none space-y-2">
            <li><span className="font-medium">Daily Impressions:</span> {dailyImpressions.toLocaleString()}</li>
            <li><span className="font-medium">Monthly Impressions:</span> {monthlyImpressions.toLocaleString()}</li>
            <li><span className="font-medium">Cost per Day:</span> ₹{dailyCost.toLocaleString()}</li>
            {/* <li><span className="font-medium">Cost per Month:</span> ₹{monthlyCost.toLocaleString()}</li> */}
            <li><span className="font-medium">Cost per Impressions:</span> <b>₹{costPerImpression}</b></li>
            <li><span className="font-medium">Cost per 1000 Impressions:</span> <b>₹{costPerThousandImpressions}</b></li>
          </ul>
        </div>
      </div>

      <h2 className="text-xl font-semibold mb-2">Why Choose This Billboard?</h2>
      <ol className="list-decimal list-inside space-y-2 mb-6">
        <li><span className="font-medium">High Frequency:</span> Your ad appears every {(loopTime/60).toFixed(1)} minute, ensuring {loopsPerHour} impressions per hour</li>
        <li><span className="font-medium">Ample View Time:</span> {adsDuration}-second duration captures attention effectively</li>
        <li><span className="font-medium">Limited Competition:</span> Stand out as one of only {adsPerLoop} ads in rotation</li>
        <li><span className="font-medium">Massive Reach:</span> Potential to reach {monthlyImpressions.toLocaleString()} viewers monthly</li>
        <li><span className="font-medium">Cost-Effective:</span> Achieve thousands of impressions at just ₹{costPerThousandImpressions} per 1000 views</li>
      </ol>
      
    </div>
  );
};

export default BillboardInfo;