import Label from "@/components/Label/Label";
import useUserContext from "@/hooks/useUserContext";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Avatar from "@/shared/Avatar/Avatar";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import Input from "@/shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useTypedMutation } from "@/hooks/useZeus";
import { $ } from "@/generated/zeus";

export interface AccountPageProps {
  className?: string;
}

type Inputs={
  displayName:string,
  email:string,
  phoneNumber:string,
  password:string,
  confirmPassworkd:string
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const {user} = useUserContext();
  const [updateProfile,{loading}] = useTypedMutation({
    updateUser:[{
      accountDetails:$('accountDetails','AccountDetails!')
    },{
      id:true
    }]
  })
   
  // const providerData = user?.providerData[0]

  // const {providerId} = providerData||{};
  
  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues:{
      displayName:user?.displayName,
      email:user?.email,
      phoneNumber:user?.phoneNumber
    } 
  });

  if(!user) return null;
  
  const handleUpdate: SubmitHandler<Inputs> = async data => {

    if(data.password != data.confirmPassworkd){
      return toast.error('Passwords do not match')
    }

    updateProfile({
      variables:{
        accountDetails:{
          password:data.password,
          phoneNumber:data.phoneNumber,
          displayName:data.displayName,
        }
        
      }
    }).then(()=>{
      toast.success('Profile updated successfully')
    }).catch(e=>{
      toast.error(e.message)
    })
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account | AdBoard Booking</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={user.image}/>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>Name</Label>
                <Input readOnly className="mt-1.5 " {...register('displayName',{
                  value:user.displayName,
                })} disabled={true} />
              </div>
              <div>
                <Label>Email</Label>
                <Input readOnly className="mt-1.5" {...register('email',{
                  value:user.email,
                })} disabled={true} />
              </div>
              <div>
                <Label>Phone number</Label>
                <Input placeholder="Phone number (+919999999999)"
                  {...register('phoneNumber',{
                    value:user.phoneNumber,
                  })} className="mt-1.5"/>
              </div>
              <div className="pt-2">
                <ButtonPrimary loading={loading} onClick={handleSubmit(handleUpdate)}>Update info</ButtonPrimary>
              </div>
            </div> 
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
