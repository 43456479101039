
import { DateRage } from '@/components/HeroSearchForm/StaySearchForm'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { AdboardFragment } from '@/hooks/useAdboards'
import { formatPrice } from '@/lib/utils'
import NcImage from '@/shared/NcImage/NcImage'

import LoadingButton from '@/components/LoadingButton'
import { FEES, FEES_AFTER_DISCOUNT, GST } from '@/contains/contants'
import { Link } from 'react-router-dom'
import { AssetFile } from '../CheckOutPage/types'

export default function CampaignSummary(props: {
    selectedAdboards?: AdboardFragment[],
    priceSummary: any,
    creatives: AssetFile[],
    selectedDate: DateRage,
    onClick: () => void
}) {

  const { selectedAdboards, priceSummary, selectedDate } = props;

  return (
    <div className='flex flex-col justify-between h-full' >
      <div className=' flex-1 overflow-auto'>
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold">Campaign Summary</h2>
        </div>
        <div className="flex gap-4 flex-col mt-4">
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold">Selected Adboards</h3>
            <Table className=''>
              <TableHeader>
                <TableRow>
                  <TableHead>Title</TableHead>
                  <TableHead>City</TableHead>
                  <TableHead className="min-w-28 text-center">Price per day</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className=''>
                {selectedAdboards?.map((item) => (
                  <TableRow key="user1">
                    <TableCell>
                      <div className="flex gap-2">
                        <div className="h-16 w-16 shrink-0" >
                          <NcImage src={item.galleryImgs[0]} />
                        </div>
                        <Link target="_blank" to={`/hoardings/${item.title}/${item.id}`} className="flex items-center gap-2">
                          {item.title}
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell className="whitespace-nowrap">{item.city}</TableCell>
                    <TableCell className="text-center ">{formatPrice(item.pricePerDay)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold">Selected dates</h3>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Start date</span>
                <span>{selectedDate.startDate?.format('LL')}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>End date</span>
                <span>{selectedDate.endDate?.format('LL')}</span>
              </div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Number of days</span>
                <span>{(selectedDate.endDate?.diff(selectedDate.startDate, 'days') || 0) + 1} days</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold">Price summary</h3>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>₹{priceSummary.pricePerDay} x {priceSummary.days} days</span>
                <span>₹{priceSummary.total}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>GST (@{GST})</span>
                <span>₹{priceSummary.gst}</span>
              </div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>₹{priceSummary.totalWithGST}</span>
              </div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Payable now (platform fees)</span>
                <span><s>{formatPrice(FEES)} </s>{formatPrice(FEES_AFTER_DISCOUNT)}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Payable on booking approval</span>
                <span>₹{priceSummary.totalWithGST}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className='text-center mt-4 py-4 border-t'>
        <LoadingButton onClick={props.onClick}>
            Submit for approval
        </LoadingButton>
      </div>

    </div>
  )
}
