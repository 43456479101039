import React from "react";
import { Route, BrowserRouter,Routes as ReactDomRoutes } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "@/shared/Footer/Footer";
import PageHome from "@/containers/PageHome/PageHome";
import Page404 from "@/containers/Page404/Page404";
import ListingStayPage from "@/containers/ListingStayPage/ListingStayPage";
import AdboardMapPage from "@/containers/ListingStayPage/AdboardMapPage";
import AdboardDetailPage from "@/containers/ListingDetailPage/AdboardDetailPage";
import PayDonePage from "@/containers/PayPage/PayDonePage";
import AccountPage from "@/containers/AccountPage/AccountPage";
import AccountPass from "@/containers/AccountPage/AccountPass";
import AccountSavelists from "@/containers/AccountPage/AccountSavelists";
import AccountBilling from "@/containers/AccountPage/AccountBilling";
import PageContact from "@/containers/PageContact/PageContact";
import PageAbout from "@/containers/PageAbout/PageAbout";
import PageSignUp from "@/containers/PageSignUp/PageSignUp";
import PageLogin from "@/containers/PageLogin/PageLogin";
import BlogPage from "@/containers/BlogPage/BlogPage";
import BlogSingle from "@/containers/BlogPage/BlogSingle";
import PageHome2 from "@/containers/PageHome/PageHome2";
import SiteHeader from "@/containers/SiteHeader";
import FooterNav from "@/components/FooterNav";
import useWindowSize from "@/hooks/useWindowResize";
import PageHomePublisher from "@/containers/PageHome/PageHomePublisher";
import PageLogout from "@/containers/PageLogin/PageLogout";
import BookingsPage from "@/containers/Bookings/Bookings";
import {BookingDetails} from "@/containers/Bookings/BookingDetailsPage";
import MediaAssetsPage from "@/containers/MediaAssetsPage/MediaAssetsPage";
import MediaAsset from "@/containers/MediaAssetsPage/MediaAsset/MediaAsset";
import FAQ from "@/containers/BlogPage/FAQ";
import Campaigns from "@/containers/Campaign/Campaigns";
import PayDoneCampaignPage from "@/containers/PayPage/PayDoneCampaignPage";
import ManageCampaignPage from "@/containers/Campaign/ManageCampaignPage";
import PageSubcription from "@/containers/PageHome/PageSubcription";
import CampaignNew from "@/containers/Campaign/CampaignNew";
// import Blogs from "@/containers/BlogPage/Blogs";
import Sitemap from "@/containers/sitemap/Sitemap";
import NotificationsPage from "@/containers/NotificationsPage/NotificationsPage";
import PublisherPage from "@/containers/PublisherPage/PublisherPage";
import MediaPlayerPage from "@/containers/MediaPlayerPage/MediaPlayerPage";
import RetailBusinessPage from "@/containers/MediaPlayerPage/RetailPage";
import EnquiriesPage from "@/containers/Bookings/EnquiriesPage";
const Blogs = React.lazy(() => import("@/containers/BlogPage/Blogs"));

export const pages: Page[] = [ 
  { path: "/", exact: true, component: PageHome2 },
  { path: "/billboard-booking", exact: true, component: PageHome2 },
  { path: "/#", exact: true, component: PageHome2 },
  { path: "/sitemap", exact: true, component: Sitemap },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/publisher", component: PageHomePublisher },
  { path: "/home-3", component: PageHomePublisher }, 
  { path: "/listing-stay", component: ListingStayPage },
  //listing
  { path: "/listing-stay-map", component: AdboardMapPage },
  { path: "/adboards", component: AdboardMapPage },
  { path: "/billboards/:location", component: AdboardMapPage },
  { path: "/billboards", component: AdboardMapPage },
  { path: "/notifications", component: NotificationsPage },
  { path: "/listing-stay-detail/:id", component: AdboardDetailPage },
  { path: "/hoardings/:title/:id", component: AdboardDetailPage },
  { path: "/pay-done", component: PayDonePage },
  { path: "/pay-done-campaign/:campaignId", component: PayDoneCampaignPage },
  { path: "/publisher/:workspaceKey", component: PublisherPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/contact", component: PageContact },
  { path: "/contact/:state", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/logout", component: PageLogout },
  { path: "/subscription", component: PageSubcription },
  { path: "/bookings", component: BookingsPage },
  { path: "/enquiries", component: EnquiriesPage },
  { path: "/booking/:id", component: BookingDetails },
  { path: "/bookings/:id", component: BookingDetails },
  { path: "/media-assets", component: MediaAssetsPage },
  { path: "/asset/:id", component: MediaAsset },
  { path: "/faqs", component: FAQ },
  { path: "/campaigns", component: Campaigns },
  { path: "/campaigns/new", component: CampaignNew},
  { path: "/campaign/:id", component: ManageCampaignPage },
  { path: "/campaigns/:id", component: ManageCampaignPage },
  { path: "/blogs/:id", component: Blogs },
  { path: "/blogs", component: Blogs },
  { path: "/media-player", component: MediaPlayerPage },
  { path: "/media-player-for-retail", component: RetailBusinessPage },
  { path: "/*", component: PageHome2 },
];

export function PageRoutes() {
  return <ReactDomRoutes>
    {pages.map(({ component:Component, path }) => {
      return (
        <Route
          key={path}
          element={ <React.Suspense fallback={<>...</>}><Component/></React.Suspense>}
          // exact={!!exact}
          path={path}
        />
      );
    })}
    <Route element={<Page404/>} />
  </ReactDomRoutes>
}

const Routes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;

  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* <Analytics/> */}
      <SiteHeader />
      <PageRoutes />
      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
