
export default function AssetPreview({ className,url,type,name }: {className?:string,url: string | null,type?:string,name?:string}) {

  if (!url) return null;

  return <div className={className}>
    {(type === 'video') ? <video src={url} controls></video> : <img src={url} alt={name} />}
  </div>;

}
