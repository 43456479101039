
import { BookingFragment } from '@/containers/Bookings/useBookingDetails'
import { BookingStatus, PaymentStatus } from '@/generated/zeus'
import React from 'react'

const SelectedIndicator = ({label,isEnd}:{label?:string,isEnd?:boolean,pendingLabel?:string}) => {
  return  <li className="relative w-full lg:mb-0 mb-6">
    <div className="flex items-center">
      <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-600 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
        <svg className="w-2.5 h-2.5 text-blue-100 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
        </svg>
      </div>
      {!isEnd && <div className="hidden lg:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>}
    </div>
    <div className="mt-3">
      <h3 className="font-medium text-gray-900 dark:text-white">{label||"Step"}</h3>
    </div>
  </li>
}

const UnselectedIndicator = ({pendingLabel,label}:{pendingLabel?:string,label:string}) => {
  return  <li className="relative w-full lg:mb-0 mb-6">
    <div className="flex items-center">
      <div className="z-10 flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full ring-0 ring-white dark:bg-gray-700 sm:ring-8 dark:ring-gray-900 shrink-0">
        <svg className="w-2.5 h-2.5 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
        </svg>
      </div>
      {/* {!isLast && <div className="flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>} */}
    </div>
    <div className="mt-3">
      <h3 className="font-medium text-gray-900 dark:text-white">{pendingLabel||label}</h3>
    </div>
  </li>
}

type Step = {
  label:string,
  pendingLabel?:string,
  isDone?:()=>boolean
}

export default function StepIndicator({booking}:{booking?:BookingFragment}) {
  
  const steps:Step[] = [{
    pendingLabel:"Approval pending",
    label:"Booking approved",
    isDone:()=>{
      return booking?.bookingStatus == BookingStatus.APPROVED
    }
  },{
    pendingLabel:"Payment pending",
    label:"Payment done",
    isDone:()=>{
      return booking?.paymentStatus==PaymentStatus.PAID || booking?.paymentStatus==PaymentStatus.PARTIALLY_PAID 
    }
  },{
    label:"Campaign scheduled",
    isDone:()=>{
      return booking?.bookingStatus == BookingStatus.SCHEDULED
    }
  },{ 
    label:"Campaign live",
    isDone:()=>{
      return booking?.bookingStatus == BookingStatus.LIVE
    }
  },{
    label:"Campaign completed",
    isDone:()=>{
      return booking?.bookingStatus == BookingStatus.COMPLETED
    }
  }];

  const currentStep = steps.findIndex(step=>{
    if(step.isDone) return step.isDone()
  })

  function isDone(step){
    if(step.isDone) return step.isDone()
    return step.bookingStatus===booking?.bookingStatus
  }

  return (
    <ol className="flex items-center flex-col lg:flex-row pl-2">
      {steps.map((step,index)=>{
        if(isDone(step)) return <SelectedIndicator key={index} {...step} isEnd={index==currentStep}/>
        return <UnselectedIndicator key={index} {...step}/>
      })}
      
    </ol>
  )
}
